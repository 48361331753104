<template>
    <components
        :is="parentComponent"
        class="measurement-view w-100"
    >
        <slot name="radio"></slot>
        <div 
            class="measurement-overview row-between pointer"
            @click="!previewMode ? open = !open : ''"
        >
            <div class="lg-6 md-12 sm-12 pr-4 s-align-4">
                <h2 class="f-16-black f-sbold">{{ measurement.name }}</h2>
            </div>
            <div class="lg-5 md-12 sm-12 mt-sm-1 mt-md-1 column-start">
                <div class="w-100 row-start">
                    <span class="f-12-grey md-12 sm-12 mr-20">
                        Created: 
                        <span class="f-12-black pl-1">{{ measurement.created_at | date }}</span>
                    </span>
                    <span class="f-12-grey md-12 sm-12">
                        Generated by:
                        <span class="f-12-black pl-1">{{ measurement.user.fullname }}</span>
                    </span>
                </div>
                <div class="w-100">
                    <span class="f-12-grey md-12 sm-12">
                       Type:
                        <span class="f-12-black pl-1">{{ measurement.type.name }}</span>
                    </span>
                </div>
            </div>
            <div class="lg-1 md-12 sm-12 s-align-6">
                <button 
                    class="icon-dark"
                    @click.stop="open = !open"
                >
                    <arrow-icon 
                        :transform="open ? 270 : 90"
                        :size="mScreen ? 12 : 10"
                    />
                </button>
            </div>
        </div>
        <div
            class="measurement-details" 
            v-if="open"
        >
            <div class="row-start pb-30">
                <div 
                    class="lg-2 md-6 sm-12 mr-100 row-between"
                    v-for="(type, index) in measurement.attributes"
                    :key="index"
                >
                    <span class="f-12-grey pb-1">
                        {{ type.name }}:
                    </span>
                    <span class="f-12-black w-30 row-between">
                        {{ type.pivot.value | numberWithCommas}} 
                        <span>
                            {{ type.attribute_type }}
                        </span>
                    </span>
                </div>
            </div>
            <div class="row-between">
                <div 
                    :class="[
                        'column-start',
                        previewMode ? 'lg-12' : 'lg-8 md-12 sm-12'
                    ]"
                >
                    <h3 class="f-12-grey f-normal">Comment:</h3>
                    <p class="f-12-black pt-1">{{ measurement.notes }}</p>
                </div>
                <div
                    v-if="!previewMode"
                    v-can:some="[$permissions.EDIT_MEASUREMENTS, $permissions.DELETE_MEASUREMENTS]"
                    class="lg-4 md-12 sm-12 s-align-9 mt-sm-1"
                >
                    <router-link 
                        class="icon-light mr-2"
                        v-can="[$permissions.EDIT_MEASUREMENTS]"
                        :to="{ name: createMeasurementRoute, params: { measurement_id: measurement.id }}"
                    >
                        <edit-icon/>
                    </router-link>
                    <button 
                        class="icon-light mr-2"
                        v-can="[$permissions.DELETE_MEASUREMENTS]"
                        @click="$emit('remove', measurement)"
                    >
                        <trash-icon/>
                    </button>
                </div>
            </div>
        </div>
    </components>    
</template>

<script>
    import { EditIcon, TrashIcon, ArrowIcon } from '@/icons';
    import { ENTITY_TYPES } from '@/constants';

    export default {
        name: 'MeasurementView',
        props: {
            measurement: {
                type: Object,
                default: function() {
                    return {};
                }
            },
            previewMode: {
                type: Boolean,
                default: false
            },
            entityType: {
                type: String
            }
        },
        data() {
            return {
                open: false
            }
        },
        components: {
            EditIcon, 
            TrashIcon, 
            ArrowIcon
        },
        computed: {
            createMeasurementRoute() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: 
                        return 'ResidentialMeasurementCreate';
                        break;
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return 'CommercialJobMeasurementCreate';
                        break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return 'CommercialLeadMeasurementCreate';
                        break;
                }
            },
            parentComponent() {
                if (this.previewMode) {
                    return 'label';
                }

                return 'div';
            }
        }
    }
</script>