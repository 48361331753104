export default {
    data() {
        return {
            attributes: [
                // styles for selected day
                {
                    key: 'selected',
                    highlight: {
                        backgroundColor: '#006bfd', // $el-darkblue
                        opacity: 1
                    },
                    contentStyle: {
                        color: 'white'
                    },
                    dates: this.selectedDate
                },
                // styles for current date
                {
                    key: 'today',
                    highlight: {
                        backgroundColor: '#e5f0ff', // $el-darkblue-10
                    },
                    contentStyle: {
                        color: '#006bfd' // $el-darkblue
                    },
                    dates: new Date()
                },
            ],
            // color of selected date, $el-darkblue-10
            tintColor: '#006bfd', 
            // styles for disabled days
            disabledAttribute: {
                contentStyle: {
                    cursor: 'not-allowed',
                    backgroundColor: 'transparent',
                    color: '#e1e2e6' // grey color
                }
            },
            // styles for days not in month
            themeStyles: {
                dayCellNotInMonth: { 
                    color: '#cfd2d8', // $el-dark-20
                    opacity: 1 
                }
            }
        }
    }
}