<template>
    <perfect-scrollbar class="commercial-list-details ps-grey w-100">
        <div 
            class="w-100" 
            v-if="loading"
        >
            <loader/>
        </div>
        <div 
            class="w-100" 
            v-if="!loading && Object.keys(lead).length"
        >
            <div class="s-align-4 mb-2 md-12">
                <clock-icon
                    class="i-grey mr-2"
                    size="12"
                />
                <span class="f-11-grey">
                    {{ lead.created_at | timeAgo }}
                </span>
            </div>
            <div class="row-between flex-no-wrap">
                <h2 class="f-20-darkgrey f-sbold mr-2">
                    {{ lead.property.property_name }}
                </h2>
                <div class="s-align-6  md-12">
                    <priority 
                        v-if="lead.priority"
                        :priority="lead.priority.name"
                        :priorityColor="lead.priority.color_code"
                    />
                </div>
            </div>
            <div class="w-100 pt-20">
                <router-link
                    class="primary-button lead-details-button"
                    :to="{ name: 'CreateNewCommercialLead', params: { property_id: lead.property_id }}"
                >
                    Create a new lead for {{ lead.property.property_name }}
                </router-link>
            </div>
            <div class="column-start pv-25 w-100 bb-grey">
                <h2 class="f-16-darkgrey f-sbold mb-8">Demographics</h2>
                <div 
                    class="mb-3"
                    v-if="lead.customer"
                >
                    <span class="f-13-grey">Customer:</span>
                    <span class="f-13-black ml-1">{{ lead.customer.name }}</span>
                </div>
                <div 
                    class="mb-3"
                    v-if="lead.requestedBy"
                >
                    <span class="f-13-grey">Requested by:</span>
                    <span class="f-13-black ml-1">
                        {{ `${lead.requestedBy.first_name} ${lead.requestedBy.last_name}` }}
                    </span>
                </div>
                <div 
                    class="mb-3"
                    v-if="lead.createdBy"
                >
                    <span class="f-13-grey">Created by:</span>
                    <span class="f-13-black ml-1">
                        {{ `${lead.createdBy.first_name} ${lead.createdBy.last_name}` }}
                    </span>
                </div>
            </div>
            <div class="column-start pv-25 w-100 bb-grey">
                <div class="row-between">
                    <h2 class="f-16-darkgrey f-sbold mb-8">Address</h2>
                    <button 
                        class="show-on-map"
                        @click="$emit('showMap', { lat: +lead.address.lat, lng: +lead.address.lng })"
                        v-if="addressIsCorrect"
                    >
                        <map-icon/>
                        Show on Map
                    </button>
                </div>
                <div class="mb-3">
                    <span class="f-13-grey">Address:</span>
                    <a
                        :href="getGoogleMapLink(lead.address.lat, lead.address.lng)"
                        :class="[
                            'f-13-blue ml-1',
                            {'none-events': !getGoogleMapLink(lead.address.lat, lead.address.lng)}
                        ]"
                        target="_blank"
                    >
                        {{ getAddress(lead.address) }}
                        <link-icon 
                            v-if="getGoogleMapLink(lead.address.lat, lead.address.lng)"
                            class="i-blue"
                        />
                    </a>
                </div>
                <div 
                    class="w-100"
                    v-if="lead.appointment"
                >
                    <span class="f-13-grey">Appointment:</span>
                    <a
                        :href="getGoogleMapLink(lead.appointment.lat, lead.appointment.lng)"
                        :class="[
                            'f-13-blue ml-1',
                            {'none-events': !getGoogleMapLink(lead.appointment.lat, lead.appointment.lng)}
                        ]"
                        target="_blank"
                    >
                        {{ getAddress(lead.appointment) }}
                        <link-icon 
                            v-if="getGoogleMapLink(lead.appointment.lat, lead.appointment.lng)"
                            class="i-blue"
                        />
                    </a>
                </div>
            </div>
            <div 
                class="column-start pv-25 w-100 bb-grey"
                v-if="lead.property"
            >
                <h2 class="f-16-darkgrey f-sbold mb-8">Property</h2>
                <div class="mb-3">
                    <span class="f-13-grey">Property name:</span>
                    <span class="f-13-black ml-1">{{ lead.property.property_name }}</span>
                </div>
            </div>
            <representatives-details-list 
                title="Property"
                :representatives="lead.representatives"
                :entityId="lead.id"
            />
            <div class="column-start pv-25 w-100 bb-grey">
                <h2 class="f-16-darkgrey f-sbold mb-8">Work Items</h2>
                <div 
                    class="mb-3"
                    v-if="lead.material"
                >
                    <span class="f-13-grey">Material:</span>
                    <span class="f-13-black ml-1">{{ lead.material.name }}</span>
                </div>
                <div 
                    class="mb-3"
                    v-if="lead.source"
                >
                    <span class="f-13-grey">Lead Source:</span>
                    <span class="f-13-black ml-1">{{ lead.source.name }}</span>
                </div>
                <div 
                    class="mb-3"
                    v-if="lead.homeType"
                >
                    <span class="f-13-grey">Home Type:</span>
                    <span class="f-13-black ml-1">{{ lead.homeType.name }}</span>
                </div>
                <div 
                    class="mb-3"
                    v-if="lead.type"
                >
                    <span class="f-13-grey">Job Type:</span>
                    <span class="f-13-black ml-1">{{ lead.type.name }}</span>
                </div>
            </div>
            <div class="column-start pv-25 w-100">
                <h2 class="f-16-darkgrey f-sbold mb-8">
                    {{ lead.trades.length > 1 ? 'Trades' : 'Trade' }}
                </h2>
                <div class="row-start">
                    <trade
                        v-for="(trade, index) in lead.trades"
                        :key="`trade-${index}`"
                        :label="trade.name"
                        :disabled="true"
                        :icon="trade.icon_code"
                    >
                        <input 
                            type="checkbox" 
                            :name="`trade-${index}`"
                            disabled
                        />
                    </trade>
                </div>
            </div>
            <div 
                class="column-start pv-25 w-100 bt-grey"
                v-if="tags.length"
            >
                <h2 class="f-16-darkgrey f-sbold pb-8">Labels</h2>
                <div class="row-start">
                    <tag
                        v-for="(tag, index) in tags"
                        :key="`tag-${index}`"
                        :tag="tag"
                        :disabled="true"
                    />
                </div>
            </div>
        </div>
    </perfect-scrollbar>
</template>

<script>
    import { Priority } from '@/components';
    import Tag from '@/components/Tag.vue';
    import { ClockIcon, MapIcon, LinkIcon } from '@/icons';
    import { Loader, Trade } from '@/ui';
    import { showFullAddress, getGoogleMapLink } from '@/helpers';
    import RepresentativesDetailsList from '@/components/RepresentativesDetailsList.vue';
    import api from '@/headers.js';

    export default {
        name: 'CommercialLeadListDetails',
        props: {
            leadId: {
                type: Number,
                default: 0
            },
            tags: {
                type: Array,
                default: function() {
                    return [];
                }
            }
        },
        components: {
            Priority,
            ClockIcon,
            Loader,
            MapIcon,
            LinkIcon,
            Trade,            
            RepresentativesDetailsList,
            Tag
        },
        data() {
            return {
                loading: false,
                lead: {}
            }
        },
        computed: {
            addressIsCorrect() {
                return this.lead.address.lat != null && this.lead.address.lng != null;
            }
        },
        methods: {
            // Method imported from helpers.js
            getGoogleMapLink,
            getLead() {
                this.loading = true;

                api.get(`/commercial/leads/${this.leadId}`)
                    .then(response => {
                        this.lead = response.data.data;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            getAddress(lead) {
                return showFullAddress(lead);
            },
            defineContacts(representative) {
                if (representative.emails.length) {
                    return representative.emails[0].email;
                } else if (representative.phones.length) {
                    return representative.phones[0].number.toString().replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                } else {
                    return 'Some of the contact details are missing.'
                }
            }
        },
        created() {
            this.getLead();
        }
    }
</script>