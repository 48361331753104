<template>
    <svg
	    viewBox="0 0 390.2455749511719 353.8839416503906"
        :width="width"
        :height="height"
        class="residential-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="390.2455749511719" 
            height="353.8839416503906" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(7.840000152587891, 0, 0, 7.840000152587891, 311.3133850097656, -630.8457336425781)">
            <g>
                <path d="M5.9,96.9h-6.4v-6.4h2.7v-2.2l-17.1-7.4l0,0L-32,88.3l-0.1,0v2.2h2.7v6.4h-6.4l-3.6,13.9h3.5v14.3H6v-14.3h3.5L5.9,96.9z M-24.9,114.7v9.1h-5.3v-9.1H-24.9z M-31.5,113.4v10.4h-3.1v-13H-20v13h-3.6v-10.4H-31.5z M-1.3,113v10.8h-6.9V113H-1.3z M-9.6,113v10.8h-6.9V113H-9.6z M-17.8,111.7v12.1h-0.9v-13H0.9v13H0v-12.1H-17.8z M2.2,123.8v-13h2.5v13H2.2z M-29.3,98.2v3.8h28.8v-3.8h5.4l3,11.3h-45.6l3-11.3H-29.3z M0.9,89.2h-2l-13.8-5.5l0,0l-13.9,5.5h-2v0l15.8-6.9L0.9,89.2L0.9,89.2z M-1.9,90.3v10.5H-28V90.3L-15,85L-1.9,90.3z"/>
                <path d="M-25.8,115.6h-3.5v3.5h3.5V115.6z M-28,117.8v-0.9h0.9v0.9H-28z"/>
                <path d="M-26.6,99.3h23.3v-9h-23.3V99.3z M-4.6,91.6V98h-3.6v-6.4H-4.6z M-9.6,91.6V98h-10.8v-6.4H-9.6z M-21.7,91.6V98h-3.6v-6.4H-21.7z"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'ResidentialIcon',
        props: {
            width: {
                type: [Number, String],
                default: 48
            },
            height: {
                type: [Number, String],
                default: 40
            }
        }
    }
</script>