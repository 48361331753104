<template>
    <div
        v-if="loading"
        class="bidders w-100 pv-30"
    >
        <loader/>
    </div>
    <div
        v-else
        class="w-100"
    >
        <div
            v-if="!biddersLength"
            class="bidders bidders-empty row-center row-baseline pv-30 ph-8"
        >
            <div class="lg-2 sm-6 s-align-6">
                <img
                    src="@/assets/images/bidders_empty_new.svg"
                    width="146"
                    height="99"
                    alt="Bidders empty"
                >
            </div>
            <div class="lg-4 sm-6 column-start pl-20">
                <p class="f-16-darkgrey f-sbold mb-7">Information on other bidders</p>
                <p class="f-13-darkgrey">
                    Here you can enter information on other bidders, such as their name, bid amount, warranty, and unique offering.
                    <button
                        class="transparent-button f-13-blue"
                        @click="editBidderModalShow = true"
                    >
                        Add information.
                    </button>
                </p>
            </div>
        </div>
        <div
            v-else
            class="bidders w-100"
        >
            <p class="f-16-darkgrey f-sbold mb-8">Bidders information</p>
            <div class="row-start pv-4 ph-20">
                <span class="lg-3 pl-20 f-12-black f-sbold">Bidders</span>
                <span class="lg-2 f-12-black f-sbold">Bid amount</span>
                <span class="lg-2 f-12-black f-sbold">Warranty Manf</span>
                <span class="lg-2 f-12-black f-sbold">Roofing type</span>
                <span class="lg-3 f-12-black f-sbold">Unique Offerings</span>
            </div>
            <div class="bidders-table w-100 ph-20">
                <div
                    v-for="(bid, index) in bidders"
                    :key="index"
                    class="bid-row row-start row-baseline pv-20"
                >
                    <div class="lg-3 sm-12 bid-name pl-20 relative">
                        <button
                            v-if="bid.is_offer_overflow || mScreen"
                            class="transparent-button p-2"
                            @click="openedRowIndex = openedRowIndex === index ? null : index"
                        >
                            <arrow-icon
                                class="i-grey"
                                :transform="openedRowIndex === index ? 270 : 90"
                            />
                        </button>
                        <span class="f-13-black w-100 pr-2">
                            {{ bid.name }}
                        </span>
                    </div>
                    <template v-if="mScreen ? openedRowIndex === index : true">
                        <div class="lg-2 sm-12 column-start mt-sm-1">
                            <span class="f-13-black ellipsis 2-100 pr-2">
                                {{ bid.amount | currency }}
                            </span>
                            <span
                                v-if="mScreen"
                                class="f-12-grey pb-4"
                            >
                                Bid amount
                            </span>
                        </div>
                        <div class="lg-2 sm-12 column-start">
                            <span
                                v-if="bid.warranty"
                                class="f-13-black w-100 pr-2"
                            >
                                {{ bid.warranty }}
                            </span>
                            <span
                                v-else
                                class="f-13-grey f-italic ellipsis w-100 pr-2"
                            >
                                No information
                            </span>
                            <span
                                v-if="mScreen"
                                class="f-12-grey pb-4"
                            >
                                Warranty Manf
                            </span>
                        </div>
                        <div class="lg-2 sm-12 column-start">
                            <span :class="['roofing-type', `roofing-type-${bid.roofing_type.toLowerCase()}`]">
                                {{ bid.roofing_type }}
                            </span>
                            <span
                                v-if="mScreen"
                                class="f-12-grey"
                            >
                                Roofing Type
                            </span>
                        </div>
                        <div
                            v-if="!mScreen"
                            class="lg-2 column-start"
                        >
                            <span
                                v-if="bid.offerings"
                                :id="`bid-offer-${index}`"
                                class="f-13-black no-wrap ellipsis w-100"
                            >
                                {{ bid.offerings }}
                            </span>
                            <span
                                v-else
                                class="f-13-grey f-italic ellipsis w-100 pr-2"
                            >
                                No information
                            </span>
                            <button
                                v-if="bid.is_offer_overflow"
                                class="transparent-button f-11-blue"
                                @click="openedRowIndex = openedRowIndex === index ? null : index"
                            >
                                View {{ openedRowIndex === index ? 'less' : 'more' }}
                            </button>
                        </div>
                        <div
                            v-if="!mScreen"
                            class="lg-1 s-align-6"
                        >
                            <button
                                class="transparent-button"
                                @click="editableBidder = bid; editBidderModalShow = true"
                            >
                                <edit-icon class="i-grey"/>
                            </button>
                        </div>
                        <div
                            v-if="mScreen ? true : openedRowIndex === index"
                            class="col-12 mt-20"
                        >
                            <p class="f-13-black">
                                <span class="f-13-grey">Unique Offerings: </span>
                                {{ bid.offerings }}
                            </p>
                        </div>
                    </template>
                </div>
                <div
                    v-if="biddersLength <= 4"
                    class="row-start pl-20 pv-20"
                >
                    <button
                        class="transparent-button f-13-blue"
                        @click="editBidderModalShow = true"
                    >
                        <add-icon
                            size="10"
                            class="i-blue mr-4"
                        />
                        Add new item
                    </button>
                </div>
            </div>
        </div>
        <edit-bidder-modal
            v-if="editBidderModalShow"
            :entityId="entityId"
            :entityType="entityType"
            :existingBidder="editableBidder"
            @onUpdate="onUpdate"
            @onCreate="onCreate"
            @onDelete="onDelete"
            @close="editBidderModalShow = false; editableBidder = null"
        />
    </div>   
</template>

<script>
    import { ArrowIcon, AddIcon, EditIcon } from '@/icons';
    import { EditBidderModal } from '@/modals';
    import debounce from 'lodash/debounce';
    import api from '@/headers.js';
    import { Loader } from '@/ui';

    export default {
        name: 'Bidders',
        components: {
            ArrowIcon,
            AddIcon,
            EditIcon,
            EditBidderModal,
            Loader
        },
        props: {
            entityId: {
                type: [String, Number],
                required: true
            },
            entityType: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                bidders: [],
                loading: false,
                openedRowIndex: null,
                editBidderModalShow: false,
                editableBidder: null
            }
        },
        computed: {
            biddersLength() {
                return this.bidders.length;
            }
        },
        methods: {
            getBidders() {
                const params = {
                    entity_id: this.entityId,
                    entity_type: this.entityType
                };

                this.loading = true;
                api.get('/bidders', { params })
                    .then(response => {
                        this.bidders.push(...response.data.data);
                    })
                    .catch((error) => this.notifyRequestErrors(error))
                    .finally(() => {
                        this.loading = false;
                        this.calculateIfOverflow();
                    });
            },
            calculateIfOverflow() {
                this.$nextTick(() => {
                    this.bidders.forEach((i, index) => {
                        let item = document.getElementById(`bid-offer-${index}`);
                        if (item) {
                            this.$set(
                                this.bidders[index],
                                'is_offer_overflow',
                                this.mScreen ? false : item.scrollWidth > item.offsetWidth
                            );
                        }
                    });
                });
            },
            onResize: debounce(function() {
                this.openedRowIndex = null;
                this.calculateIfOverflow();
            }, 500),
            onCreate(payload) {
                this.bidders.push(payload);
                this.editBidderModalShow = false;
                this.editableBidder = null;
                this.calculateIfOverflow();
            },
            onUpdate(payload) {
                const index = this.bidders.findIndex(i => i.id == payload.id);
                this.editBidderModalShow = false;
                this.editableBidder = null;

                if (index !== -1) {
                    this.bidders.splice(index, 1, payload);
                    this.calculateIfOverflow();
                }
            },
            onDelete(id) {
                const index = this.bidders.findIndex(i => i.id == id);
                this.editBidderModalShow = false;
                this.editableBidder = null;

                if (index !== -1) {
                    this.bidders.splice(index, 1);
                }
            }
        },
        created() {
            this.getBidders();
            window.addEventListener('resize', this.onResize);
        }
    }
</script>