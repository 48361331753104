<template>
    <div class="card">
        <div class="bb-grey pv-8 ph-20">
            <div class="w-100 row-between flex-no-wrap s-align-4">
                <h2>Milestones</h2>
                <div class="row-end">
                    <div
                        v-if="boundaryIndex > 0"
                        class="s-align-4 pr-20 mr-25 br-grey"
                    >
                        <checkbox-button
                            color="grey"
                            label="Show only job"
                            class="f-12-black"
                        >
                            <input
                                type="checkbox"
                                name="is_job_field_show"
                                v-model="isShowOnlyJob"
                            />
                        </checkbox-button>
                    </div>
                    <button
                        class="edit-button"
                        v-can:some="[$permissions.MILESTONE_ADVANCED_EDITING]"
                        @click="editMilestoneShowModal = !editMilestoneShowModal"
                    >
                        <edit-icon
                            class="i-grey mr-2"
                            size="16"
                        />
                        <span>{{ canceled || completed ? 'Re-open' : 'Edit' }}</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="w-100 relative">
                <div v-if="isShowNavigationButtons()">
                    <button
                        v-if="!atTheBeginning"
                        class="mr-2 navigation-button prev z-index-2"
                        @click="scrollMilestones(SCROLL_TO_RIGHT)"
                    >
                        <arrow-icon
                            transform="180"
                            size="9"
                            class="i-grey"
                        />
                    </button>
                    <button
                        v-if="!atTheEnd"
                        class="navigation-button next z-index-2"
                        @click="scrollMilestones(SCROLL_TO_LEFT)"
                    >
                        <arrow-icon
                            transform="0"
                            size="9"
                            class="i-grey"
                        />
                    </button>
                </div>
                <div class="milestones-box">
                    <div
                        :class="[
                            'milestones',
                            { 'milestones-to-center' : isShowMilestoneCenter() }
                        ]"
                        id="milestones-list"
                        ref="milestones"
                    >
                        <template v-for="(milestone, index) in milestones">
                            <div
                                v-if="!loadMilestones && showOnlyJob(index)"
                                :class="[
                                    'milestone', `milestone-${milestone.status}`,
                                    isInprogressMilestone(milestone.status, index),
                                    { 'milestone-dead' : milestone.status == MILESTONE_STATUSES.DEAD },
                                    { 'none-events' : milestone.status === MILESTONE_STATUSES.AWAIT }
                                ]"
                                :id="`milestone-${index}`"
                                :key="index"
                                @click="startConvert(milestone, index)"
                            >
                                <div :class="[
                                    'milestone-content column-center t-center',
                                    milestone.status == MILESTONE_STATUSES.INPROGRESS && 'milestone-inprogress-container',
                                    milestone.status == MILESTONE_STATUSES.DONE && 'milestone-done-container',
                                    milestone.status == MILESTONE_STATUSES.COMPLETED && 'milestone-completed-container'
                                ]">
                                    <div class="relative">
                                        <div
                                            :style="{'background-color' : getColor(milestone.milestone.color, index)}"
                                            class="milestone-icon s-align-5 m-1"
                                        >
                                            <mark-icon
                                                v-if="milestone.status === MILESTONE_STATUSES.DONE
                                                || milestone.status === MILESTONE_STATUSES.COMPLETED"
                                                class="i-white"
                                                size="12"
                                            />
                                            <stop-icon
                                                v-else-if="milestone.status == MILESTONE_STATUSES.DEAD"
                                                class="i-white"
                                            />
                                            <span
                                                class="f-14-white f-sbold capitalize"
                                                v-else
                                            >
                                                {{ getAbbreviation(milestone.milestone.title) }}
                                            </span>
                                        </div>
                                        <div
                                            v-if="!isOpportunity"
                                            :class="[
                                                'milestone-badge s-align-5 uppercase f-bold',
                                                index < boundaryIndex ? 'lead' : 'job',
                                                {'cancel': milestone.status == MILESTONE_STATUSES.DEAD}
                                            ]"
                                        >
                                            <template v-if="milestone.status != MILESTONE_STATUSES.DEAD">
                                                {{ index < boundaryIndex ? 'lead' : 'job' }}
                                            </template>
                                            <template v-else>
                                                Cancel
                                            </template>
                                        </div>
                                        <div
                                            v-if="isOpportunity && milestone.status == MILESTONE_STATUSES.DEAD"
                                            class="milestone-badge s-align-5 uppercase f-bold cancel"
                                        >
                                            Cancel
                                        </div>
                                    </div>
                                    <span class="milestone-title f-sbold mt-6">
                                        {{ milestone.milestone.title }}
                                    </span>
                                    <div class="s-align-5">
                                        <span :class="[
                                            'milestone-date', 'f-12-grey',
                                            {'f-italic' : !getMilestoneDate(milestone) }
                                        ]">
                                            {{
                                                !getMilestoneDate(milestone)
                                                    ? 'No date'
                                                    : getMilestoneDate(milestone)
                                            }}
                                        </span>
                                        <button
                                            v-if="milestone.status === MILESTONE_STATUSES.DONE && index !== lastDoneIndex"
                                            class="transparent-button"
                                            @click="showEditMilestoneDateModal(index)"
                                        >
                                            <edit-icon
                                                class="i-grey ml-1"
                                                size="11"
                                            />
                                        </button>
                                    </div>
                                    <span
                                        v-if="milestone.last_updated"
                                        class="f-10-grey"
                                    >
                                        Updated {{ milestone.last_updated | date }}
                                    </span>
                                    <span class="f-14-white f-sbold milestone-convert mt-4">
                                        Convert to <br/>
                                        {{ next ? next.milestone.title : lastMilestone.milestone.title }}
                                    </span>
                                    <div
                                        v-if="milestone.status == MILESTONE_STATUSES.DEAD"
                                        :class="[
                                            's-align-5 mt-1',
                                            { 'column-start' : milestone.milestone.milestone_dead.length >= 10 }
                                        ]"
                                    >
                                        <span :class="[
                                            'f-10-grey',
                                            { 'mr-2' : milestone.milestone.milestone_dead.length <= 10 }
                                        ]">
                                            {{ milestone.milestone.milestone_dead }}
                                        </span>
                                        <button
                                            @click="scrollToDeadDetails()"
                                            class="f-10-blue transparent-button"
                                        >
                                            View details
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <loader
                            v-if="loadMilestones"
                            size="small"
                        />
                    </div>
                </div>
                <modal
                    v-if="openDateModal"
                    @close="openDateModal = false, editedMilestoneIndex = null"
                >
                    <div
                        slot="modal-body"
                        class="w-100"
                    >
                        <div class="column-start pb-4">
                            <p class="f-14-black pv-2 w-100">
                                Pick the date for "{{ milestoneTitle }}" milestone:
                            </p>
                            <form-datepicker
                                v-model="date"
                                placeholder="Select date"
                                keyDatePicker="select_date_picker"
                                class="w-100"
                                :isFullWidth="true"
                            />
                            <template v-if="milestonesIsSync">
                                <p class="f-14-black pb-2 mt-2">Contract date:</p>
                                <form-datepicker
                                    v-model="contractDate"
                                    keyDatePicker="select_contract_date_picker"
                                    placeholder="Select date"
                                    :isFullWidth="true"
                                />
                                <p class="f-14-black pb-2 mt-2">Approximate Completion Date:</p>
                                <form-datepicker
                                    v-model="approximateCompletionDate"
                                    keyDatePicker="select_aproximate_date_picker"
                                    placeholder="Select date"
                                    :isFullWidth="true"
                                />
                            </template>
                        </div>
                    </div>
                    <div slot="modal-footer">
                        <div class="row-between">
                            <button
                                class="add-button"
                                @click="openDateModal = false, editedMilestoneIndex = null"
                            >
                                Cancel
                            </button>
                            <button
                                v-if="showConvertToJobBtn && editedMilestoneIndex === null"
                                class="primary-button convert-to-job-button"
                                @click="$emit('redirectToConvertLeadPage')"
                            >
                                <loading v-if="checkingQueryToRedirect"/>
                                <template v-else>
                                    <next-icon class="i-white mr-5"/>
                                    Convert to Job
                                </template>
                            </button>
                            <button
                                v-if="showConvertToLeadBtn && editedMilestoneIndex === null"
                                class="primary-button convert-to-job-button"
                                @click="$emit('redirectToConvertLeadPage', date)"
                            >
                                <loading v-if="checkingQueryToRedirect"/>
                                <template v-else>
                                    <next-icon class="i-white mr-5"/>
                                    Convert to Lead
                                </template>
                            </button>
                            <button
                                v-if="!showConvertToJobBtn && !showConvertToLeadBtn"
                                class="primary-button"
                                @click="editedMilestoneIndex !== null ? updateMilestoneDate() : save()"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </modal>
            </div>
        </div>
        <edit-milestones-modal
            v-if="editMilestoneShowModal"
            :entityId="entityId"
            :entityType="entityType"
            @close="closeMilestoneModal"
            @save="updateMilestoneModal"
        />
        <loader
            size="full"
            v-if="updating"
        />
    </div>
</template>

<script>
    import {FormDatepicker, Loader} from '@/ui';
    import {getWidth, scrollToElement, getCoords, getAbbreviation} from '@/helpers';
    import {MILESTONE_STATUSES, BASE_ENTITY_TYPES} from '@/constants';
    import moment from 'moment/src/moment';
    import api from '@/headers.js';
    import {CheckboxButton} from '@/ui';
    
    export default {
        name: 'Milestones',
        components: {
            CheckboxButton,
            EditMilestonesModal: () => import('@/modals/EditMilestonesModal.vue'),
            MarkIcon: () => import('@/icons/MarkIcon.vue'),
            PointerIcon: () => import('@/icons/PointerIcon.vue'),
            StopIcon: () => import('@/icons/StopIcon.vue'),
            ArrowIcon: () => import('@/icons/ArrowIcon.vue'),
            NextIcon: () => import('@/icons/NextIcon.vue'),
            EditIcon: () => import('@/icons/EditIcon.vue'),
            Loading: () => import('@/icons/Loading.vue'),
            Modal: () => import('@/ui/Modal/Modal.vue'),
            FormDatepicker,
            Loader,
        },
        props: {
            entityType: {
                type: String,
                required: true
            },
            checkingQueryToRedirect: {
                type: Boolean,
                required: false
            },
            isOpportunity: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                openDateModal: false,
                date: '',
                approximateCompletionDate: '',
                contractDate: '',
                step: 0,
                atTheBeginning: true,
                atTheEnd: false,
                active: null,
                updating: false,
                BASE_ENTITY_TYPES: BASE_ENTITY_TYPES,
                MILESTONE_STATUSES: MILESTONE_STATUSES,
                editedMilestoneIndex: null,
                milestonesIsSync: null,
                milestoneTitle: '',
                editMilestoneShowModal: false,
                loadMilestones: false,
                isShowOnlyJob: false,
                showMilestone: 6,
                getAbbreviation
            }
        },
        computed: {
            completed() {
                return this.milestones
                    && this.milestones.length
                    && this.milestones[this.milestones.length - 1].status
                    && this.milestones[this.milestones.length - 1].status == MILESTONE_STATUSES.COMPLETED
                    || this.milestones[this.milestones.length - 1].status == MILESTONE_STATUSES.DONE;
            },
            canceled() {
                return this.$store.getters['milestones/getCanceledStatus'];
            },
            boundaryIndex() {
                return this.$store.getters['milestones/getBoundary'];
            },
            milestones() {
                return this.$store.getters['milestones/getMilestones'];
            },
            inprogressIndex() {
                return this.$store.getters['milestones/getMilestoneInprogressIndex'];
            },
            lastDoneIndex() {
                return this.$store.getters['milestones/getMilestoneLastDoneIndex'];
            },
            current() {
                if (this.inprogressIndex !== -1) {
                    return this.milestones[this.inprogressIndex];
                }
                
                if (this.lastDoneIndex !== -1) {
                    return this.milestones[this.lastDoneIndex];
                }
            },
            next() {
                if (this.inprogressIndex !== -1 && this.milestones[this.inprogressIndex + 1]) {
                    return this.milestones[this.inprogressIndex + 1];
                }
                
                if (this.lastDoneIndex !== -1 && this.milestones[this.lastDoneIndex + 1]) {
                    return this.milestones[this.lastDoneIndex + 1];
                }
            },
            lastMilestone() {
                return this.$store.getters['milestones/getMilestoneLast'];
            },
            dead() {
                return this.$store.getters['milestones/getMilestoneDead'];
            },
            entityId() {
                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL:
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.$route.params.lead_id;
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.$route.params.job_id;
                    case BASE_ENTITY_TYPES.OPPORTUNITY:
                        return this.$route.params.opportunity_id;
                    case BASE_ENTITY_TYPES.WARRANTY_JOB:
                        return this.$route.params.job_id;
                }
            },
            overviewKanbanLink() {
                return this.$store.getters['lead/getKanbanLink'];
            },
            showConvertToJobBtn() {
                return this.entityType === BASE_ENTITY_TYPES.COMMERCIAL_LEAD
                    && this.next.milestone.is_boundary;
            },
            showConvertToLeadBtn(){
                if (this.entityType !== BASE_ENTITY_TYPES.OPPORTUNITY){
                    return false;
                }
                const milestones = this.milestones;
                if (milestones.length > 1){
                    if (milestones[milestones.length - 2].status === MILESTONE_STATUSES.INPROGRESS){
                        return true
                    }
                }else{
                    return true;
                }
            },
            countShowMilestone() {
                return this.milestones.length - this.boundaryIndex;
            }
        },
        watch: {
            inprogressIndex(newVal) {
                if (newVal) {
                    this.$emit('milestoneUpdated');
                }
            },
            next: {
                handler(newValue, oldValue) {
                    if (
                        !newValue &&
                        oldValue &&
                        (oldValue.status === MILESTONE_STATUSES.DONE || oldValue.status === MILESTONE_STATUSES.AWAIT)
                    ) {
                        this.$emit('statusDone');
                    }
                },
                deep: true
            }
        },
        methods: {
            isInprogressMilestone(status, index) {
                return status == MILESTONE_STATUSES.DONE
                    && index == this.lastDoneIndex
                    && this.inprogressIndex == -1
                    && this.lastDoneIndex !== this.milestones.length - 1
                    && 'milestone-inprogress';
            },
            getColor(color, index) {
                return this.inprogressIndex >= index
                    ? color
                    : '';
            },
            isShowNavigationButtons() {
                return (this.milestones.length > this.showMilestone
                    || this.countShowMilestone > this.showMilestone)
                    && this.isShowOnlyJobNavigationButton();
            },
            isShowOnlyJobNavigationButton() {
                if (this.isShowOnlyJob) {
                    return this.showOnlyJobMilestone(this.milestones).length > this.showMilestone;
                }
                
                return true;
            },
            isShowMilestoneCenter() {
                return this.milestones.length < this.showMilestone
                    && this.dScreen
                    || this.isShowOnlyJob
                    && this.countShowMilestone < this.showMilestone;
            },
            showOnlyJob(index) {
                if (this.isShowOnlyJob) {
                    return this.boundaryIndex <= index;
                } else {
                    return true;
                }
            },
            showOnlyJobMilestone(milestone) {
                return milestone.filter((el, index) => {
                    return this.boundaryIndex <= index;
                });
            },
            scrollMilestones(duration) {
                let milestone = getWidth(this.$el.querySelector('.milestone'));
                let milestones = getWidth(this.$el.querySelector('.milestones'));
                let limit = (milestone * this.milestones.length) - milestones;
                
                switch (duration) {
                    case this.SCROLL_TO_RIGHT:
                        this.step = this.step <= 0 ? 0 : this.step - milestone;
                        this.atTheEnd = false;
                        this.atTheBeginning = this.step == 0;
                        break;
                    case this.SCROLL_TO_LEFT:
                        this.step = this.step >= limit ? limit : this.step + milestone;
                        this.atTheBeginning = false;
                        this.atTheEnd = this.step >= limit;
                        break;
                }
                
                this.$refs.milestones.scrollTo({
                    left: this.step,
                    behavior: "smooth"
                });
            },
            getMilestoneDate(milestone) {
                if (!milestone.started_at) {
                    return null;
                }
                
                // if date with time & time !== 00:00, show in local timezone.
                return milestone.last_updated && milestone.started_at
                    ? moment(milestone.started_at).format('D MMM YYYY')
                    : moment(milestone.started_at).format('HH:mm:ss') == '00:00:00'
                        ? moment(milestone.started_at).format('D MMM YYYY')
                        : moment.utc(milestone.started_at).local().format('D MMM YYYY')
            },
            scrollToDeadDetails() {
                scrollToElement(document.querySelector('.milestone-dead-info'));
            },
            startConvert(milestone, index) {
                if (this.inprogressIndex !== -1 && milestone.status === MILESTONE_STATUSES.DONE) {
                    return;
                }
                
                if (this.lastDoneIndex !== index && this.inprogressIndex == -1) {
                    return;
                }
                
                if (this.next) {
                    this.milestonesIsSync = this.next.milestone.is_sync;
                    this.milestoneTitle = this.next.milestone.title;
                }
                
                if (
                    milestone.status != this.MILESTONE_STATUSES.INPROGRESS
                    && (milestone.status != this.MILESTONE_STATUSES.DONE && this.inprogressIndex == -1)
                    || !this.$can([this.$permissions.EDIT_LEAD])
                ) {
                    return false;
                }
                
                this.openDateModal = true;
            },
            closeMilestoneModal() {
                this.editMilestoneShowModal = false;
            },
            updateMilestoneModal(isError = false) {
                if(isError) {
                    this.notifyError('Choose at least one milestone!');
                } else {
                    this.getLeadPrimaryInformation();
                    if (this.approximateCompletionDate || this.contractDate) {
                        this.updateDate();
                    }
                }
            },
            getLeadPrimaryInformation() {
                const leadPrimaryInformation = this.$store.getters['lead/getLeadPrimaryInformation'];
                
                if (leadPrimaryInformation.completion_at) {
                    this.approximateCompletionDate = moment(leadPrimaryInformation.completion_at).format('YYYY-MM-DD');
                }
                
                if (leadPrimaryInformation.contract_date) {
                    this.contractDate = moment(leadPrimaryInformation.contract_date).format('YYYY-MM-DD');
                }
            },
            showEditMilestoneDateModal(index) {
                this.getLeadPrimaryInformation();
                this.editedMilestoneIndex = index;
                
                this.milestonesIsSync = this.milestones[index].milestone.is_sync;
                this.milestoneTitle = this.milestones[index].milestone.title;
                
                this.date = moment(this.milestones[index].started_at).format('YYYY-MM-DD');
                this.openDateModal = true;
            },
            save() {
                this.updating = true;
                
                this.current.completed_at = this.date;
                if (this.next)
                    this.next.started_at = this.date;
                
                let params = {
                    updated: {
                        next: this.next,
                        current: this.current
                    },
                    milestones: this.milestones,
                    id: this.entityId,
                    entityType: this.entityType
                };
                
                if (this.approximateCompletionDate || this.contractDate) {
                    this.updateDate();
                }
                
                this.$store.dispatch('milestones/UPDATE_MILESTONES', params)
                    .finally(() => {
                        // get Project management card link if next milestone is trigger and card not created yet
                        // delay to wait while back-end create card
                        if (!this.overviewKanbanLink
                            && this.current
                            && this.current.milestone.is_card_starting
                            && this.entityType === BASE_ENTITY_TYPES.RESIDENTIAL) {
                            setTimeout(() => this.$store.dispatch('lead/GET_KANBAN_LINK', this.entityId), 3000);
                        }
                        
                        // update job number (after updating milestones) on Residential
                        if (this.current
                            && this.current
                            && this.current.milestone.is_boundary
                            && this.entityType === BASE_ENTITY_TYPES.RESIDENTIAL) {
                            this.$store.dispatch('lead/GET_LEAD_PREVIEW', this.entityId);
                        }
                        
                        this.updating = false;
                    });
                
                this.openDateModal = false;
                this.editedMilestoneIndex = null;
                this.date = new Date();
            },
            updateDate() {
                const params = {
                    completion_at: this.approximateCompletionDate,
                    contract_date: this.contractDate
                }
                
                api.put(`/leads/${this.entityId}`, params)
                    .then(() => {
                        this.$store.dispatch('lead/GET_LEAD_PREVIEW', this.entityId);
                    })
                    .catch((error) => {
                        this.notifyError('Changes have not been saved.');
                    });
            },
            updateMilestoneDate() {
                const params = {
                    'entityId': this.entityId,
                    'entityType': this.entityType,
                    'milestone_id': this.milestones[this.editedMilestoneIndex].milestone.id,
                    'date': this.date
                };
                
                this.updating = true;
                this.$store.dispatch('milestones/UPDATE_MILESTONE_DATE', params)
                    .finally(() => {
                        this.$store.dispatch('milestones/GET_MILESTONES_STATISTICS', {
                            id: this.entityId,
                            entityType: this.entityType
                        });
                        this.updating = false;
                        this.openDateModal = false;
                        this.editedMilestoneIndex = null;
                        this.date = new Date();
                    });
            },
            scrollToInprogressMilestone() {
                if (this.inprogressIndex >= 0) {
                    let milestone = getWidth(this.$el.querySelector('.milestone'));
                    let milestones = getWidth(this.$el.querySelector('.milestones'));
                    this.showMilestone = Math.floor(milestones / milestone);
                    const inprogressElement = getCoords(document.getElementById(`milestone-${this.inprogressIndex}`));
                    let scrollLeft = inprogressElement.left - milestone;
                    this.atTheBeginning = false;
                    this.atTheEnd = false;
                    document.getElementById('milestones-list').scrollTo({
                        left: scrollLeft,
                        behavior: "smooth"
                    });
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.scrollToInprogressMilestone();
            });
        },
        created() {
            this.SCROLL_TO_RIGHT = 'right';
            this.SCROLL_TO_LEFT = 'left';
            
            this.date = moment().format('YYYY-MM-DD');
        }
    }
</script>
