<template>
     <div>
         <full-modal @close="closeModal">
             <div slot="title">Edit primary information</div>
             <div
                 slot="body"
                 v-if="Object.keys(lead).length"
             >
                 <p class="f-16-darkgrey pb-2">Primary Contact</p>
                 <div class="pb-30 row-start">
                     <div class="lg-6 md-6 sm-12 mb-4 pr-2 pr-sm-0">
                         <form-input
                             label="First Name"
                             :class="[{ 'form-input-danger' : errors.has('first_name') }]"
                         >
                             <input
                                 type="text"
                                 name="first_name"
                                 id="first_name"
                                 class="form-input-field"
                                 placeholder="First Name"
                                 v-input-style="lead.first_name"
                                 v-model="lead.first_name"
                                 @blur="addFieldToSave('first_name', 'field')"
                                 maxlength="31"
                                 v-validate="{ required: true, max: 30, regex: titleRegex }"
                             />
                         </form-input>
                     </div>
                     <div class="lg-6 md-6 sm-12 mb-4 pl-2 pl-sm-0">
                         <form-input
                             label="Last Name"
                             :class="[{ 'form-input-danger' : errors.has('last_name') }]"
                         >
                             <input
                                 type="text"
                                 name="last_name"
                                 id="last_name"
                                 class="form-input-field"
                                 placeholder="Last Name"
                                 v-input-style="lead.last_name"
                                 v-model="lead.last_name"
                                 @blur="addFieldToSave('last_name', 'field')"
                                 maxlength="31"
                                 v-validate="{ required: true, max: 30, regex: titleRegex }"
                             />
                         </form-input>
                     </div>
                 </div>
                 <p class="f-16-darkgrey pb-2">Phones</p>
                 <div class="pb-30 row-start">
                     <div
                         class="s-align-4 lg-8 md-7 sm-10 mb-4"
                         v-for="(phone, index) in phones"
                         :key="index"
                         :id="`tel_${index + 1}`"
                     >
                         <div class="column-start">
                             <form-input
                                 v-if="!showTooltipInPrmaryFields"
                                 :label="index == 0 ? 'Phone primary' : 'Phone'"
                                 class="form-input-with-select"
                                 :class="[{ 'form-input-danger' : errors.has(`tel_${index + 1}`) }]"
                             >
                                 <input
                                     type="text"
                                     :name="`tel_${index + 1}`"
                                     class="form-input-field"
                                     v-input-style="phone.value"
                                     v-model="phone.value"
                                     v-mask="'(###) ###-####'"
                                     @blur="addFieldToSave(phone.name, 'phone', index)"
                                     :placeholder="index == 0 ? 'Phone primary' : 'Phone'"
                                     v-validate="'required|min:14'"
                                 />
                                 <multiselect
                                     :options="phoneTypes"
                                     placeholder="Type"
                                     :showLabels="false"
                                     :max-height="150"
                                     :searchable="false"
                                     label="type_name"
                                     track-by="id"
                                     :name="`phone_type_${index + 1}`"
                                     :data-name="`phone_type_${index + 1}`"
                                     v-model="phone.type"
                                     @input="addFieldToSave(phone.name, 'phone', index)"
                                     v-validate="'required'"
                                     :class="[{ 'multiselect-danger' : errors.has(`phone_type_${index + 1}`) }]"
                                 />
                             </form-input>
                             <tooltip
                                 class="w-100"
                                 v-if="showTooltipInPrmaryFields"
                                 direction="top"
                                 mobileDescription="Field info"
                             >
                                 <form-input
                                     :label="index == 0 ? 'Phone primary' : 'Phone'"
                                     class="form-input-with-select"
                                     :class="[{ 'form-input-danger' : errors.has(`tel_${index + 1}`) }]"
                                 >
                                     <input
                                         type="text"
                                         :name="`tel_${index + 1}`"
                                         class="form-input-field"
                                         v-input-style="phone.value"
                                         v-model="phone.value"
                                         v-mask="'(###) ###-####'"
                                         @blur="addFieldToSave(phone.name, 'phone', index)"
                                         :placeholder="index == 0 ? 'Phone primary' : 'Phone'"
                                         v-validate="'required|min:14'"
                                     />
                                     <multiselect
                                         :options="phoneTypes"
                                         placeholder="Type"
                                         :showLabels="false"
                                         :max-height="150"
                                         :searchable="false"
                                         label="type_name"
                                         track-by="id"
                                         :name="`phone_type_${index + 1}`"
                                         :data-name="`phone_type_${index + 1}`"
                                         v-model="phone.type"
                                         @input="addFieldToSave(phone.name, 'phone', index)"
                                         v-validate="'required'"
                                         :class="[{ 'multiselect-danger' : errors.has(`phone_type_${index + 1}`) }]"
                                     />
                                 </form-input>
                                 <template v-slot:content>
                                     <p class="f-16-white">
                                         {{ showTooltipInPrmaryFields }}
                                     </p>
                                 </template>
                             </tooltip>
                             <form-input
                                 v-if="showPhoneExtInPrmaryFields || phone.phone_extension"
                                 label="Phone extension"
                                 :class="[{ 'form-input-danger' : errors.has(`extension-${index}`) }]"
                             >
                                 <input
                                     type="text"
                                     :name="`extension-${index}`"
                                     class="form-input-field mt-2"
                                     placeholder="Phone extension"
                                     v-model="phone.phone_extension"
                                     v-validate="'integer'"
                                     @blur="addFieldToSave(phone.name, 'phone', index)"
                                 />
                             </form-input>
                         </div>
                         <div class="static-col-2 s-align-5">
                             <button
                                 class="icon-light"
                                 v-if="index > 0"
                                 @click="removeContacts(phones, phone.name, 'phone', index)"
                             >
                                 <trash-icon/>
                             </button>
                         </div>
                     </div>
                     <div class="s-align-6 lg-4 md-5 sm-2 mb-4">
                         <button
                             class="service-secondary-button"
                             v-if="phones.length <= 2"
                             @click="addPhoneNumber"
                         >
                             <add-icon size="10"/>
                         </button>
                     </div>
                 </div>
                 <p class="f-16-darkgrey pb-2">Emails</p>
                 <div class="pb-30 row-start">
                     <div
                         class="s-align-4 lg-8 md-7 sm-10 mb-4"
                         v-for="(email, index) in emails"
                         :key="index"
                         :id="`email_${index + 1}`"
                     >
                         <form-input
                             :label="index == 0 ? 'Email Primary' : 'Email'"
                             :class="[{ 'form-input-danger' : errors.has(`email_${index + 1}`) }]"
                         >
                             <input
                                 type="email"
                                 :name="`email_${index + 1}`"
                                 class="form-input-field"
                                 :placeholder="index == 0 ? 'Email Primary' : 'Email'"
                                 v-input-style="email.value"
                                 v-model="email.value"
                                 @blur="addFieldToSave(email.name, 'email', index)"
                                 v-validate="'required|email'"
                             />
                         </form-input>
                         <div class="static-col-2 s-align-5">
                             <button
                                 class="icon-light"
                                 v-if="index > 0"
                                 @click="removeContacts(emails, email.name, 'email', index)"
                             >
                                 <trash-icon/>
                             </button>
                         </div>
                     </div>
                     <div class="s-align-6 lg-4 md-5 sm-2 mb-4">
                         <button
                             class="service-secondary-button"
                             v-if="emails.length <= 2"
                             @click="addEmail"
                         >
                             <add-icon size="10"/>
                         </button>
                     </div>
                 </div>
                 <p class="f-16-darkgrey pb-2">Work Items</p>
                 <div class="pb-30 row-start">
                     <div
                         class="column-start lg-6 md-6 sm-12 mb-4 pr-2 pr-sm-0"
                         id="priority"
                     >
                         <p class="f-13-black pb-2">Priority:</p>
                         <multiselect
                             :options="priorities"
                             track-by="id"
                             label="name"
                             placeholder="Priorities"
                             :showLabels="false"
                             :max-height="200"
                             :searchable="false"
                             name="priority"
                             data-name="priority"
                             v-model="priority"
                             @input="addFieldToSave('priority_id', 'priority_id')"
                             :class="['capitalize', { 'multiselect-danger' : errors.has('priority') }]"
                             v-validate="'required'"
                         />
                     </div>
                     <div class="column-start lg-6 md-6 sm-12 mb-4 pl-2 pl-sm-0">
                         <p class="f-13-black pb-2">Home type:</p>
                         <multiselect
                             :options="homeTypes"
                             placeholder="Home Types"
                             :showLabels="false"
                             :max-height="200"
                             :searchable="false"
                             label="name"
                             track-by="id"
                             name="home_types"
                             data-name="home_types"
                             v-model="homeType"
                             @input="addFieldToSave('home_type_id', 'home_type')"
                             :class="[{ 'multiselect-danger' : errors.has('home_types') }]"
                             v-validate="'required'"
                             id="home_types"
                         />
                     </div>
                     <div
                         class="column-start lg-6 md-6 sm-12 mb-4 pr-2 pr-sm-0"
                         id="lead_type"
                     >
                         <p class="f-13-black pb-2">Job type:</p>
                         <multiselect
                             :options="leadTypes"
                             placeholder="Job Type"
                             :showLabels="false"
                             :max-height="200"
                             :searchable="false"
                             label="name"
                             track-by="id"
                             name="lead_type"
                             data-name="lead_type"
                             v-model="leadType"
                             @input="addFieldToSave('lead_type_id', 'lead_type')"
                             :class="[{ 'multiselect-danger' : errors.has('lead_type') }]"
                             v-validate="'required'"
                         />
                     </div>
                     <div
                         class="column-start lg-6 md-6 sm-12 mb-4 pl-2 pl-sm-0"
                         id="materials"
                     >
                         <p class="f-13-black pb-2">Materials:</p>
                         <multiselect
                             :options="materials"
                             placeholder="Materials"
                             :showLabels="false"
                             :max-height="200"
                             :searchable="false"
                             label="name"
                             track-by="id"
                             name="materials"
                             data-name="materials"
                             v-model="material"
                             @input="addFieldToSave('material_id', 'materials')"
                             :class="[{ 'multiselect-danger' : errors.has('materials') }]"
                             v-validate="'required'"
                         />
                     </div>
                     <div
                         class="column-start lg-6 md-6 sm-12 mb-4 pr-2 pr-sm-0"
                         id="lead_source"
                     >
                         <p class="f-13-black pb-2">Lead source:</p>
                         <multiselect
                             :options="leadSources"
                             placeholder="Lead Source"
                             :showLabels="false"
                             :max-height="200"
                             :searchable="false"
                             label="name"
                             track-by="id"
                             name="lead_source"
                             data-name="lead_source"
                             v-model="leadSource"
                             @input="addFieldToSave('lead_source_id', 'lead_source')"
                             :class="[{ 'multiselect-danger' : errors.has('lead_source') }]"
                             v-validate="'required'"
                         />
                         <form-input
                             v-if="!selectedPartner && isReferral"
                             label="Referral Name"
                             :class="[{ 'form-input-danger' : errors.has('referral_name') }, 'mt-4']"
                         >
                             <input
                                 type="text"
                                 name="referral_name"
                                 id="referral_name"
                                 class="form-input-field"
                                 placeholder="Referral Name"
                                 v-model="referralName"
                                 maxlength="255"
                                 v-validate="{regex: titleRegex }"
                                 ref="ReferralName"
                                 @blur="addFieldToSave('referral_name', 'referral_name')"
                             />
                         </form-input>
                         <multiselect
                             v-if="isReferral && !referralName"
                             :options="partners"
                             placeholder="Select from Contacts"
                             :show-labels="false"
                             :max-height="200"
                             :searchable="false"
                             :custom-label="partnerCustomLabel"
                             track-by="id"
                             name="lead_partner"
                             data-name="lead_partner"
                             v-model="selectedPartner"
                             @input="addFieldToSave('partner_id', 'lead_partner')"
                             :class="[
                            {'selected-referral' : selectedPartner},
                            { 'multiselect-danger' : errors.has('lead_partner') },
                            'mt-5 multiselect-small'
                        ]"
                             v-validate="{required: Boolean(!referralName)}"
                         />
                     </div>
                 </div>
                 <template v-if="!lead.job_number">
                     <p class="f-16-darkgrey pb-2">Bid Due Date</p>
                     <div class="pb-30 row-start">
                         <div class="s-align-4 lg-7 md-7 sm-10 mb-4">
                             <form-date-time-input
                                 v-model="lead.bid_due_date"
                                 v-validate="'required'"
                                 name="bid_due_date"
                                 placeholder="Bid Due Date"
                                 size="medium"
                                 @input="addFieldToSave(lead.bid_due_date,'bid_due_date')"
                                 :minDate="new Date()"
                                 :isFullWidth="true"
                                 :class="{ 'form-date-time-input-error' : errors.has('bid_due_date') }"
                             />
                         </div>
                     </div>
                 </template>
                 <p class="f-16-darkgrey pb-2">Trades</p>
                 <div class="pb-30 row-start">
                     <trade
                         v-for="(trade, index) in trades"
                         :key="trade.id"
                         :label="trade.name"
                         :class="[{ 'trade-danger' : errors.has(`trade-${index}`) }]"
                         :id="`trade-${index}`"
                         :icon="trade.icon_code"
                     >
                         <input
                             type="checkbox"
                             :name="`trade-${index}`"
                             v-model="selectedTrades"
                             :value="trade.id"
                             v-validate="'required'"
                             @change="addFieldToSave('trades', 'trade')"
                         />
                         <div slot="isSalesRabbit">
                             <div
                                 v-if="trade.is_sales_rabbit"
                                 class="row-start s-align-4 ml-4"
                             >
                                 <storm-icon
                                     width="10"
                                     height="12"
                                     class="ml-2"
                                 />
                                 <toggle-switch
                                     class="ml-1 pointer"
                                     smallSize
                                 >
                                     <input
                                         :checked="isSalesRabbit(trade.id)"
                                         @change="toggleSalesRabbit(trade.id, index)"
                                         type="checkbox"
                                     />
                                 </toggle-switch>
                             </div>
                         </div>
                     </trade>
                 </div>
                 <p class="f-16-darkgrey pb-2">Location Info</p>
                 <div class="w-100 pb-30">
                     <address-box
                         type="address"
                         :key="'address'"
                         :existing="address"
                         @address="getMainAddress"
                     />
                 </div>
                 <div
                     class="w-100 pb-30"
                     v-if="!isBillingAddress"
                 >
                     <button
                         class="transparent-button mt-mob-1"
                         @click="isBillingAddress = true"

                     >
                         <span class="f-13-blue">+ Add Billing Address</span>
                     </button>
                 </div>
                 <div
                     class="w-100 pb-30"
                     v-else
                 >
                     <div class="row-between">
                         <p class="f-16-darkgrey pb-2">Billing Address</p>
                         <button
                             class="icon-light mt-mob-1"
                             @click="removeBillingAddress"
                         >
                             <trash-icon/>
                         </button>
                     </div>
                     <div class="w-100">
                         <address-box
                             type="billing"
                             :key="'billing'"
                             :existing="billing"
                             @address="getBillingAddress"
                         />
                     </div>
                 </div>
             </div>
             <div slot="footer">
                 <button
                     class="add-button"
                     @click="closeModal"
                 >
                     Cancel
                 </button>
                 <button
                     class="primary-button"
                     @click="save"
                     ref="save_button"
                 >
                     Save
                 </button>
             </div>
         </full-modal>
         <zip-code-modal
             v-if="outOfArea"
             @continue="updateLead"
             @cancel="cancel"
             @close="cancel"
         />
     </div>
</template>

<script>
    import { FullModal, FormInput, Trade, FormDateTimeInput, Tooltip, ToggleSwitch } from '@/ui';
    import { Autocomplete } from '@/GoogleApi';
    import { clearObject, copyFieldsValueFromObjectToObject } from '@/helpers.js';
    import { mask } from 'vue-the-mask';
    import { TrashIcon, AddIcon, StormIcon } from '@/icons';
    import AddressBox from '@/components/AddressBox.vue';
    import { LEAD_TEL_TYPES, NOT_ALLOWED_CHARACTERS } from '@/constants';
    import Multiselect from 'vue-multiselect';
    import api from '@/api';
    import { customFieldInPrimarySection } from '@/components/CreateLead/mixins/customFieldInPrimarySection.js';
    import { mapActions, mapGetters } from 'vuex';

    const VIEW_URL = '/residential-job-details';

    export default {
        name: 'EditResidentialJobModal',
        components: {
            ToggleSwitch,
            StormIcon,
            FullModal,
            FormInput,
            Multiselect,
            Trade,
            Autocomplete,
            TrashIcon,
            AddIcon,
            AddressBox,
            FormDateTimeInput,
            Tooltip,
            zipCodeModal: () => import('@/modals/ZipCodeModal.vue')
        },
        props: {
            id: {
                type: [String, Number],
                default: 0
            },
            lead: {
                type: Object,
                required: true
            },
            phonePrimarySectionParameters: {
                type: Object,
                required: false
            }
        },
        directives: {
            mask
        },
        mixins: [customFieldInPrimarySection],
        data() {
            return {
                salesRabbit: [],
                edited: {},
                emails: [],
                phones: [],
                phoneTypes: [
                    { type_name: LEAD_TEL_TYPES.HOME, id: 1 },
                    { type_name: LEAD_TEL_TYPES.MOBILE, id: 2 },
                    { type_name: LEAD_TEL_TYPES.WORK, id: 3 }
                ],
                homeTypes: [],
                homeType: null,
                leadTypes: [],
                leadType: null,
                leadSources: [],
                leadSource: null,
                trades: [],
                tradesSalesRabbit: [],
                selectedTrades: [],
                priorities: [],
                priority: null,
                materials: [],
                material: null,
                tradeList: [],
                address: {
                    address: '',
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                    lat: null,
                    lng: null
                },
                billing: {
                    address_billing: '',
                    street_billing: '',
                    city_billing: '',
                    state_billing: '',
                    zip_billing: '',
                    lat_billing: null,
                    lng_billing: null
                },                
                isBillingAddress: false,
                validationErrorMessages: [
                    { name: 'first_name', rule: 'required', message: 'First name is a required field' },
                    {   name: 'first_name', 
                        rule: 'regex', 
                        message: 'Invalid First name field. Special characters are not accepted.' 
                    },
                    { name: 'last_name', rule: 'required', message: 'Last name is a required field' },
                    {   name: 'last_name', 
                        rule: 'regex', 
                        message: 'Invalid Last name field. Special characters are not accepted.' 
                    },
                    { name: 'mailing-city', message: 'Choose a valid city name'},
                    { name: 'mailing-street', message: 'Choose a valid street name'},
                    { name: 'mailing-state', message: 'Choose a valid state name' },
                    { name: 'billing-state', message: 'Choose a valid state name' },
                    { name: 'mailing-zip', message: 'ZIP code is 5 digits long' },
                    { name: 'billing-zip', message: 'ZIP code is 5 digits long' },
                    { name: 'mailing-address', message: 'Check the validity of your address' },
                    { name: 'billing-address', message: 'Check the validity of your address' },
                    { name: 'lead_type_id', rule: 'required', message: 'Choose an option from a Job type list' },
                    { name: 'material_id', rule: 'required', message: 'Choose an option from a Material list' },
                    { name: 'home_type_id', rule: 'required', message: 'Choose an option from a Home type list' },
                    { name: 'lead_source_id', rule: 'required', message: 'Choose an option from a Lead source list' },
                    { name: 'priorities_id', rule: 'required', message: 'Choose an option from a Priorities list' },
                    { name: 'bid_due_date', rule: 'required', message: 'Bid due date is a required field' }
                ],
                titleRegex: NOT_ALLOWED_CHARACTERS,
                referralName: null,
                selectedPartner: null,
            }
        },
        computed: {
            ...mapGetters('zipCodes', [
                'outOfArea'
            ]),
            ...mapGetters('partners', [
                'partners'
            ]),
            isReferral() {
                if (!this.leadSource) {
                    return false;
                }

                let source = this.leadSources.find(item => {
                    return item.id === this.leadSource.id;
                }) || false;

                return source && source.has_children;

            }
        },
        methods: {
            ...mapActions('zipCodes', [
                'checkZipCodeArea'
            ]),
            ...mapActions('partners', [
                'getPartners'
            ]),
            isSalesRabbit(id) {
                return this.tradeList.find(el => el.id === id && el.is_sales_rabbit);
            },
            toggleSalesRabbit(id, index) {
                const findElIndex = this.salesRabbit.findIndex(el => el === id);
                if (findElIndex !== -1) {
                    this.salesRabbit.splice(findElIndex, 1);
                    return;
                }

                this.salesRabbit.push(id);
                this.addFieldToSave(this.salesRabbit, 'salesRabbit', index);
            },
            setLeadInfo() {
                // TODO 
                // need to add to the request for editing lead
                // 1) array of phones (only with existing numbers)
                let phoneIndex = -1;
                [this.lead.tel_1, this.lead.tel_2, this.lead.tel_3].map((phone, phoneIndex) => {
                    if (phone == null) return false;
                    phoneIndex = ++phoneIndex;
                    let pnoheExt = `tel_${phoneIndex}_extension`;
                    this.phones.push({
                        name: `tel_${phoneIndex}`, 
                        value: phone,
                        phone_extension: this.lead[pnoheExt],
                        type: this.phoneTypes[this.lead[`tel_${phoneIndex}_type`] - 1]
                    });
                });

                // TODO 
                // 2) array of emails (only with existing emails)
                let emailIndex = -1;
                [this.lead.email_1, this.lead.email_2, this.lead.email_3].map((email, emailIndex) => {
                    if (email == null) return false;
                    emailIndex = ++emailIndex;
                    this.emails.push({
                        name: `email_${emailIndex}`, 
                        value: email
                    });
                });

                this.seller = this.lead.salesman;
                this.homeType = this.lead.home_type;
                this.leadType = this.lead.lead_type;
                this.leadSource = this.lead.lead_source;
                this.tradeList = this.lead.trade_list;
                this.salesRabbit = (this.lead.trade_list || []).filter(el => el.is_sales_rabbit).map(el => el.id);
                this.priority = this.lead.priority;
                this.material = this.lead.material;

                if (this.lead.partner && this.lead.partner.referral_name) {
                    this.referralName = this.lead.partner.referral_name;
                }

                if (! this.referralName) {
                    this.selectedPartner = this.lead.partner || null;
                }

                copyFieldsValueFromObjectToObject(this.lead, this.address);

                if (this.lead.address_billing != null)
                    copyFieldsValueFromObjectToObject(this.lead, this.billing);
            },
            addPhoneNumber() {
                this.phones.push({
                    name: `tel_${this.phones.length + 1}`, 
                    value: '',
                    phone_extension: '',
                    type: this.phoneTypes[1]
                });
            },
            addEmail() {
                this.emails.push({ name: `email_${this.emails.length + 1}`, value: '' })
            },
            removeContacts(arr, field, type, index) {
                arr.splice(index, 1);
                this.addFieldToSave(field, type, index);
            },
            getHandbooks() {
                const params = {
                    needed: [
                        'home_types',
                        'lead_types',
                        'lead_sources',
                        'trades',
                        'priorities',
                        'materials'
                    ]
                };

                api.handbooks(params, {'filter[is_active]': 1})
                    .then(response => response.data.data)
                    .then(data => {
                        this.homeTypes = data.home_types;
                        this.leadSources = data.lead_sources;
                        this.trades = data.trades;
                        this.tradesSalesRabbit = data.trades;
                        this.priorities = data.priorities;
                        this.materials = data.materials;

                        // show job types with the same group id
                        this.leadTypes = data.lead_types.filter(i => i.group_id === this.lead.lead_type.group_id);

                        // to define selected trades
                        this.lead.trades.split(', ').map((t) => {
                            for (let i = 0; i < this.trades.length; i++) {
                                if (this.trades[i].name == t) {
                                    this.selectedTrades.push(this.trades[i].id);
                                };
                            };
                        });
                    });
            },
            addFieldToSave(field, type, index) {
                switch(type) {
                    case 'field':
                        this.edited[field] = this.lead[field];
                        break;
                    case 'email':
                        !this.edited.hasOwnProperty('emails')
                            ? this.edited.emails = []
                            : '';
                    
                        let email = {};
                        this.emails[index] == undefined
                            ? email[field] = null
                            : email[field] = this.emails[index].value;

                        this.edited.emails.push(email);
                        break;
                    case 'phone': 
                        !this.edited.hasOwnProperty('phones')
                            ? this.edited.phones = []
                            : '';
                        
                        let phone = {};
                        if (this.phones[index] == undefined) {
                            phone[field] = null;
                        } else {
                            phone[field] = this.phones[index].value.replace(/\D+/g, '');

                            if (field == 'tel_1') {
                                phone.tel_1_type = this.phones[index].type.id;
                                phone.tel_1_extension = this.phones[index].phone_extension;                         
                            } else if (field == 'tel_2') {
                                phone.tel_2_type = this.phones[index].type.id;
                                phone.tel_2_extension = this.phones[index].phone_extension;
                            } else if (field == 'tel_3') {
                                phone.tel_3_type = this.phones[index].type.id;
                                phone.tel_3_extension = this.phones[index].phone_extension;
                            };
                        };
                        
                        this.edited.phones.push(phone);                        
                        break;
                    case 'salesman': 
                        if (!this.seller) return false;
                        this.edited[field] = this.seller.id;
                        break;
                    case 'home_type': 
                        if (!this.homeType) return false;
                        this.edited[field] = this.homeType.id;
                        break;
                    case 'lead_type':
                        if (!this.leadType) return false;
                        this.edited[field] = this.leadType.id;
                        break;
                    case 'lead_source':
                        if (!this.leadSource) return false;
                        this.edited[field] = this.leadSource.id;

                        // Clear Referral if source was changed from referral to not referral
                        if (! this.leadSource.has_children) {

                            if (this.selectedPartner) {
                                this.selectedPartner = null;
                                this.edited['partner_id'] = null;
                            }

                            if (this.referralName) {
                                this.referralName = null;
                                this.edited['referral_name'] = null;
                            }
                        }
                        break;
                    case 'lead_partner':
                        let partnerId = this.selectedPartner ? this.selectedPartner.id : null;
                        this.edited[field] = partnerId;
                        break;
                    case 'referral_name':
                        this.edited[field] = this.referralName;
                        break;
                    case 'trade':
                        !this.edited.hasOwnProperty('trades')
                            ? this.edited[field] = []
                            : '';
                        break;
                    case 'priority_id':
                        if (!this.priority) return false;
                        this.edited[field] = this.priority.id;
                        break;
                    case 'materials':
                        if (!this.material) return false;
                        this.edited[field] = this.material.id;
                        break;
                    case 'bid_due_date':
                        this.edited[type] = this.lead.bid_due_date;
                        break;
                    default:
                            break;
                };
            },
            getMainAddress(mailingData) {
                this.address = mailingData;
                this.edited.address = this.address;
            },
            getBillingAddress(billingData) {
                this.billing = billingData;
                this.edited.billing = this.billing;
            },
            removeBillingAddress() {
                this.isBillingAddress = false;
                clearObject(this.billing);
            },
            partnerCustomLabel ({ first_name, last_name }) {
                return `${first_name} ${last_name}`
            },
            updateLead() {
                this.cancel();
                this.$refs.save_button.disabled = true;
                this.edited.redirect_url = window.location.origin + VIEW_URL;
                this.edited['salesRabbit'] = this.salesRabbit;
                this.edited['trades'] = this.selectedTrades;

                api.Residential.update(this.id, this.edited)
                    .then((res) => {
                        this.notifySuccess('Changes have been saved!');

                        this.$emit('refresh');
                        this.$store.dispatch('lead/GET_LEAD_PREVIEW', this.id);
                        this.closeModal();
                    })
                    .catch((error) => {
                        this.notifyError('Changes have not been saved.');

                        this.$refs.save_button.disabled = false;
                    });
            },
            save() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        if (!Object.keys(this.edited).includes('address')) {
                            this.updateLead();
                            return;
                        }

                        this.checkZipCodeArea(this.edited.address.zip)
                            .then(response => {
                                if (response) {
                                    this.updateLead();
                                }
                            });
                    } else {
                        // generate validate error notify for trades fields
                        if (this.errors.items.some(item => item.field.includes('trade'))) {
                            this.notifyError('Choose at least one trade for a job');
                        }
                        // generate validate error notify for phone numbers fields
                        if (this.errors.items.some(item => item.field.includes('tel'))) {
                            this.notifyError('Phone format is (111)111-1111');
                        }
                        // generate validate error notify for email fields
                        if (this.errors.items.some(item => item.field.includes('email'))) {
                            this.notifyError('E-mail format example name.lastname@gmail.com');
                        }
                        // generate validate error notify for other fields
                        this.errors.items.forEach(function(errorItem) {
                            this.validationErrorMessages.forEach(function(fieldItem) {  
                                if (errorItem.field == fieldItem.name && errorItem.rule == fieldItem.rule) {
                                    this.notifyError(fieldItem.message);
                                }   
                            }.bind(this));
                        }.bind(this));
                        // generate validate error notify for bid due date fields
                        if (this.errors.items.some(item => item.field.includes('bid_due_date'))) {
                            this.notifyError('Fill in required fields!');
                        }

                        let domRect = document.getElementById(this.$validator.errors.items[0].field);

                        if (domRect != null || domRect != undefined) {
                            this.$el.querySelector('.full-modal-content').scrollTo({
                                top: domRect.offsetTop - 100,
                                behavior: "smooth"
                            })
                        };
                    }
                })
            },
            closeModal() {
                this.$emit('close');
                
                for (let i in this.edited) {
                    delete this.edited[i];
                }
            },
            cancel() {
                this.$store.commit('zipCodes/SET_OUT_OF_AREA', false);
            }
        },
        created() {
            this.isBillingAddress = !!this.lead.address_billing;
            this.getHandbooks();
            this.getPartners();
            this.setLeadInfo();
        }
    }
</script>
