import {
    SET_ACTIVE_TABS
} from "../actions/tasks";

export default {
    namespaced: true,
    state: {
        activeTabs: 0
    },
    getters: {
        getActiveTabs: state => state.activeTabs
    },
    mutations: {
        [SET_ACTIVE_TABS] (state, payload) {
            state.activeTabs = payload;
        }
    }
}