import api from './headers'

export default {
    checkZipCodeArea(params) {
        return api.post('/zipcodes', params);
    },
    handbooks(params, query = {}) {
        let url = '/handbooks';
        if (Object.keys(query).length) {
            const queryParams = new URLSearchParams(query);
            url = `${url}?${queryParams.toString()}`
        }

        return api.get(url, { params });
    },
    uploadFile(formData) {
        return api.post('/upload', formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    },
    Residential: {
        create(params) {
            return api.post('/leads', params);
        },
        update(Id, formData) {
            return api.put(`/leads/${Id}`, formData);
        },
        findById(Id) {
            return api.get(`/leads/${Id}`);
        }
    },
    Commercial: {
        contactTypes(params) {
            return api.get(`/commercial/contact-types`, { params });
        },
        Lead: {
            create(formData) {
                return api.post('/commercial/leads', formData);
            },
            update(Id, formData) {
                return api.put(`/commercial/leads/${Id}`, formData);
            }
        },
        Property: {
            list() {
                return api.get('/commercial/properties/list');
            },
            parentRepresentatives(Id) {
                return api.get(`/commercial/properties/${Id}/parent-representatives`);
            }
        },
        Customer: {
            findById(Id) {
                return api.get(`/commercial/customers/${Id}`);
            },
            list(perPage, onlyNames) {
                return api.get(`/commercial/customers?perPage=${perPage}&onlyNames=${onlyNames}`)
            }
        }
    },
    Calendar: {
        list(params) {
            return api.get('/calendars/calendar-list', { params });
        }
    },
    Opportunity: {
        findById(Id) {
            return api.get(`/opportunities/${Id}`);
        },
        create(formData) {
            return api.post('/opportunities', formData);
        },
        update(Id, formData) {
            return api.put(`/opportunities/${Id}`, formData);
        }
    },
    Contact: {
        list() {
            return api.get('/contacts/list');
        },
        findById(Id) {
            return api.get(`/contacts/${Id}`);
        },
        create(formData) {
            return api.post(`/contacts`, formData)
        },
        update(Id, formData) {
            return api.put(`/contacts/${Id}`, formData)
        }
    },
    Partner: {
        list() {
            return api.get('/partners');
        }
    },
    Audit: {
        getData(params) {
            return api.get('/audit', params)
        }
    }
}
