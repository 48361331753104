<template>
    <svg 
        viewBox="0 0 821.6963500976562 743.2142944335938"
        class="write-icon"
        :height="size"
        :width="size"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="821.6963500976562" 
            height="743.2142944335938" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(1.53125, 0, 0, 1.53125, 1079.71435546875, -944.2991065979004)">
            <path d="M-211.8,631.8c-8.9-9-20.7-14-33.3-14.1c-12.6,0-24.5,4.8-33.4,13.8l-152.8,152.8c-5.7,5.7-9.8,12.8-12,20.5l-26.6,94.2c-1.7,6,0,12.4,4.5,16.8c3.2,3.1,7.5,4.8,11.9,4.8c1.7,0,3.4-0.2,5-0.8l91.6-28.2c7.3-2.2,14-6.3,19.4-11.7l0,0l153.4-153.4c18.2-18.2,18.3-47.8,0.3-66.2L-211.8,631.8z M-287.8,688.9l46.2,47.2l-11.3,11.3l-46.2-47.2L-287.8,688.9z M-361.6,856c-1.5,1.5-3.3,2.6-5.4,3.2l-61.8,19l18.1-64.2c0.6-2.1,1.8-4.1,3.3-5.7l84.1-84.1l46.2,47.2L-361.6,856z M-208.1,702.5l-9.5,9.5l-46.2-47.2l9.2-9.1c2.5-2.5,5.7-3.7,9.2-3.7c0,0,0,0,0.1,0c3.5,0,6.8,1.3,9.2,3.8l28,28.5C-203.1,689.3-203.1,697.5-208.1,702.5z"/>
            <path d="M-628.4,884.5h75.4c11.9,0,21.6-9.6,21.6-21.6c0-11.9-9.6-21.6-21.6-21.6h-75.4c-41.7,0-75.4,33.8-75.4,75.4c0,41.7,33.8,75.4,75.4,75.4h301.7c17.9,0,32.3,14.5,32.3,32.3c0,17.9-14.5,32.3-32.3,32.3h-140.1c-11.9,0-21.6,9.6-21.6,21.6s9.6,21.6,21.6,21.6h140.1c41.7,0,75.4-33.8,75.4-75.4c0-41.7-33.8-75.4-75.4-75.4h-301.7c-17.9,0-32.3-14.5-32.3-32.3C-660.8,898.9-646.3,884.5-628.4,884.5z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'WriteIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>