<template>
<svg 
    viewBox="0 0 5.896590709686279 26.450637817382812" 
    style="enable-background:new 0 0 336 235.2;" 
    :width="size" 
    :height="size"
    class="info-icon">
	<g transform="matrix(2.7798008918762207, 0, 0, 2.7798008918762207, 1267.7173256874084, -758.849853515625)">
		<path 
            class="st0" 
            d="M-455,281.5v-4.9" 
            style="fill: #000; stroke: #fff; stroke-width: 2; stroke-linecap: round; stroke-linejoin: round;"
        />
        <path d="M-455,275c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S-454.4,275-455,275z"/>
    </g>
</svg>
</template>

<script>
    export default {
        name: 'InfoIcon',
        props: {
            size: {
                type: [Number, String],
                default: 30
            }
        }
    }
</script>
