<template>
    <svg 
	    viewBox="0 0 779.8111572265625 780.094482421875"
        :width="size" 
        :height="size"
        class="draft-email-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="779.8111572265625" 
            height="780.094482421875" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(12.43654727935791, 0, 0, 12.43654727935791, 10793.679595947266, -10711.59765625)">
            <g>
                <path 
                    class="st0" 
                    d="M-837.2,897L-837.2,897l-4.6-3.8c-0.9-0.8-2.4-0.8-3.4,0l-20.7,18.2c0.2,0.1,0.5,0.1,0.8,0.1h25.3c-0.3-1.3-0.5-2.7-0.5-4.2C-840.3,903.5-839.2,900-837.2,897z"
                />
                <path 
                    class="st0" 
                    d="M-867.5,881.5v28.1c0,0.2,0,0.3,0.1,0.5l16.4-14.4L-867.5,881.5z"
                />
                <path 
                    class="st0" 
                    d="M-849.5,894.5l3.1-2.7c1.7-1.4,4.3-1.4,5.9,0l4.1,3.4l18.2-15.7l-22.4-17c-1.1-0.9-2.9-0.9-4,0l-22.3,17L-849.5,894.5z"
                />
                <path 
                    class="st0" 
                    d="M-827,889.7c1.6-0.5,3.3-0.7,5.1-0.7c1.5,0,2.9,0.2,4.3,0.5v-7.9L-827,889.7z"
                />
            </g>
            <g>
                <path 
                    class="st0" 
                    d="M -820.2000122070312 903.2999877929688 L -817.5 906 L -816.9000244140625 905.2999877929688 L -819.5999755859375 902.5999755859375 Z"
                />
                <path 
                    class="st0" 
                    d="M-816.5,900.7c-0.1-0.1-0.3-0.2-0.5-0.2c0,0,0,0,0,0c-0.2,0-0.4,0.1-0.5,0.2l-0.5,0.5l2.7,2.8l0.6-0.6c0.3-0.3,0.3-0.8,0-1.1L-816.5,900.7z"
                />
                <path 
                    class="st0" 
                    d="M-826.5,909.6c-0.1,0.1-0.2,0.2-0.2,0.3l-1.1,3.7l3.6-1.1c0.1,0,0.2-0.1,0.3-0.2l4.9-4.9l-2.7-2.8L-826.5,909.6z"
                />
                <path 
                    class="st0" 
                    d="M-821.9,891c-9.1,0-16.4,7.4-16.4,16.4c0,9.1,7.4,16.4,16.4,16.4s16.4-7.4,16.4-16.4C-805.5,898.3-812.9,891-821.9,891z M-813.5,904.8l-8.9,8.9c-0.3,0.3-0.7,0.6-1.1,0.7l-5.3,1.6c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5-0.1-0.7-0.3c-0.3-0.3-0.4-0.6-0.3-1l1.6-5.5c0.1-0.4,0.4-0.9,0.7-1.2l8.9-8.9c0.5-0.5,1.2-0.8,1.9-0.8c0.7,0,1.4,0.3,1.9,0.8l1.6,1.7C-812.4,902-812.4,903.7-813.5,904.8z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'DraftEmailIcon',
        props: {
            size: {
                type: [Number, String],
                default: 30
            }
        }
    }
</script>