<template>
    <label
        :id="id"
        :class="[
            'estimate-preview-box w-100 relative pv-25 ph-20',
            { 'estimate-preview-box-active' : active }
        ]"
    >
        <slot name="radio"></slot>
        <div
            :class="[
                'preview-box-headline row-between pointer',
                { 'flex-no-wrap' : dScreen },
                { 'flex-column' : mScreen || this.nScreen }
            ]"
        >
            <div
                class="preview-box-captions column-start lg-9 sm-12 ls-sm-9 mr-20"
                @click="moveToItemPage"
            >
                <ul class="row-start align-center">
                    <li class="sm-12 f-12-grey pr-6 pb-1 estimate-list-item">
                        {{ item.created_at | date }}
                    </li>
                    <li
                        v-if="item.user"
                        class="sm-12 f-12-grey pr-6 pb-1 estimate-list-item"
                    >
                        by {{ item.user.fullname }}
                    </li>
                </ul>
                <div class="row-start row-baseline mb-2 flex-no-wrap">
                    <div class="title-icon-container s-align-5 mr-3">
                        <edit-icon
                            v-if="item.type === ESTIMATE_TYPES.MANUAL"
                            class="i-green"
                            size="11"
                        />
                        <template-icon
                            v-else
                            size="15"
                            class="inverted-template-icon"
                        />
                    </div>
                    <p class="f-16-darkgrey f-sbold">
                        {{ item.name }}
                    </p>
                </div>
                <div
                    class="lg-12 sm-12 mt-sm-1 s-align-4 pb-2"
                    v-if="item.status == item.all_statuses.outdated"
                >
                    <round-warning-icon class="i-orange"/>
                    <div class="f-12-orange opacity-8 pl-2">Material or Labor substitute needed</div>
                </div>
                <div class="row-start">
                    <p
                        v-if="item.type !== ESTIMATE_TYPES.MANUAL"
                        class="sm-12 f-12-grey pr-6 pb-1"
                    >
                        Status:
                        <span :class="[
                            'capitalize relative', `preview-box-${defineEstimateStatus(item.status)}`
                        ]">
                            {{ defineEstimateStatus(item.status) }}
                        </span>
                    </p>
                    <p
                        class="sm-12 f-12-grey pb-1"
                        v-if="item.valid_until"
                    >
                        Valid until:
                        <span class="f-12-black">{{ item.valid_until | date }}</span>
                    </p>
                  <p
                      class="sm-12 f-12-grey pr-6 pb-1"
                      v-if="item.trades && item.trades.length"
                  >
                    Trades:
                    <span class="capitalize relative">{{ item.trades.map(el => el.name).join(', ') }}</span>
                  </p>
                </div>
                <div
                    class="row-start s-align-4 f-12-grey"
                    v-if="item.from_library_updated_date"
                >
                    <calendar-icon
                        class="i-blue mr-1"
                        size="12"
                    />
                    Price updated:
                    <span class="f-12-black ph-1">{{ item.from_library_updated_date | date }} </span>
                    <span class="f-12-grey">({{ item.from_library_updated_date | timeAgo }})</span>
                </div>
            </div>
            <div class="preview-box-buttons sm-12 ls-sm-3">
                <slot name="buttons"></slot>
            </div>
        </div>
        <!-- changing the type of cursor is necessary for different operating modes of the component. 
        if the 'selectMode' is on, we need to show this through the cursor pointer -->
        <div
            :class="[
                'column-start w-100 ph-8', 
                disabled ? 'pointer' : 'cursor-default',
                { 'pb-4' : !item.description }
            ]"
            v-if="page == ESTIMATE && item.versions && versionsLength"
        >
            <div class="s-align-4 pb-3">
                <button
                    class="transparent-button"
                    @click="versionListIsOpen = !versionListIsOpen"
                    :disabled="!versionsActively"
                >
                    <arrow-icon
                        class="i-asphalt mr-1"
                        :transform="versionListIsOpen ? 270 : 90"
                        v-if="versionsActively"
                    />
                    <span class="f-12-black f-sbold">
                        Versions
                        <span
                            class="f-12-grey"
                            v-if="versionsActively"
                        >
                            ({{ versionsLength }})
                        </span>
                    </span>
                </button>
            </div>
            <ul class="pl-8 w-100">
                <li
                    v-for="(version, index) in versions"
                    :key="`version-${index}`"
                    class="estimate-version p-6"
                >
                    <component
                        :is="versionTag"
                        :to="{ name: editEstimateRoute, params: { estimate_id: version.id }}"
                        class="row-start row-baseline"
                    >
                        <div :class="['column-start sm-12', showBaseBidLabel ? 'lg-10' : 'lg-12']">
                            <p class="f-14-blue relative pb-1">
                                {{ version.comment ? version.comment : version.name }}
                            </p>
                            <div class="row-start">
                                <span
                                    class="sm-12 f-12-grey pr-8"
                                    v-if="version.valid_until"
                                >
                                    Valid until:
                                    <span class="f-12-black">{{ version.valid_until | date }}</span>
                                </span>
                                <span class="sm-12 f-12-grey">
                                    Status:
                                    <span
                                        :class="[
                                            'capitalize relative',
                                            `preview-box-${defineEstimateStatus(version.status)}`
                                        ]"
                                    >
                                        {{ defineEstimateStatus(version.status) }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div
                            v-if="showBaseBidLabel"
                            class="lg-2 sm-12 s-align-3"
                        >
                            <button
                                v-if="version.status === item.all_statuses.final
                                    || version.type === ESTIMATE_TYPES.MANUAL"
                                :class="[
                                    'base-bid-version-label transparent-button f-10-white',
                                    { 'active' : version.is_base_bid }
                                ]"
                                @click.prevent="$emit('setBaseBid', version.id)"
                                :disabled="baseBidLabelDisabled"
                            >
                                BASE BID
                            </button>
                        </div>
                    </component>
                </li>
            </ul>
        </div>
        <div
            v-if="page === ESTIMATE && item.type === ESTIMATE_TYPES.MANUAL"
            class="row-end pl-8 pr-8 pb-8"
            @click.stop.prevent
        >
            <div
                v-if="filesLength"
                class="lg-9 sm-12 row-start row-baseline"
            >
                <attach-icon class="i-grey mr-2"/>
                <span class="f-12-grey mr-3">Files:</span>
                <button
                    v-for="(file, index) in item.files"
                    :key="index"
                    class="transparent-button break-all mb-sm-1"
                    @click.stop.prevent="previewFileIndex = index, previewModalOpened = true"
                >
                    <span class="f-12-black mr-1">
                        {{ file.original_name }}
                    </span>
                    <span class="f-12-grey mr-3">
                        {{ `${Math.round(file.size/1024*100)/100}Kb${index !== filesLength -1 ? ',' : ''}` }}
                    </span>
                </button>
            </div>
            <div class="lg-3 sm-12 s-align-9">
                <span class="f-12-grey mr-1">Total:</span>
                <span class="f-15-black f-sbold">
                    {{ item.amount | currency }}
                </span>
            </div>
        </div>
        <div
            class="w-100"
            v-if="item.description"
        >
            <div
                :class="[
                    'column-start w-100 f-12-grey pointer',
                    { 'pb-8' : !buttonsVisible }
                ]"
                @click="moveToItemPage"
            >
                <span :class="[
                    'preview-box-description preserve-whitespaces hidden break-word w-100',
                    { 'line-clamp-5' : toggle }
                ]">
                    {{ item.description }}
                </span>
            </div>
            <div
                class="s-align-5 mt-3 w-100 pointer"
                v-if="buttonsVisible"
            >
                <button
                    @click="toggleDescription"
                    :class="[
                        'transparent-button pb-8 row-start',
                        toggle ? 'f-12-blue' : 'f-12-grey'
                    ]"
                    :style="readMoreLessButtonWidth"
                    :disabled="disabled"
                >
                    <div class="s-align-4 w-100">
                        {{ toggle ? 'Read more' : 'Show less' }}
                        <arrow-icon
                            :class="[
                                'ml-1',
                                toggle ? 'i-blue' : 'i-grey'
                            ]"
                            :transform="toggle ? 90 : 270"
                        />
                    </div>
                </button>
            </div>
        </div>
        <file-preview-modal
            v-if="previewModalOpened"
            :file="item.files[previewFileIndex]"
            @close="previewModalOpened = false, previewFileIndex = null"
        />
        <button
            v-if="showBaseBidLabel && (item.status === item.all_statuses.final || item.type === ESTIMATE_TYPES.MANUAL)"
            :class="[
                'base-bid-label transparent-button f-10-white',
                { 'active' : item.is_base_bid }
            ]"
            @click.prevent="$emit('setBaseBid', item.id)"
            :disabled="baseBidLabelDisabled"
        >
            BASE BID
        </button>
    </label>
</template>

<script>
    import { ArrowIcon, CalendarIcon, RoundWarningIcon, EditIcon, TemplateIcon, AttachIcon } from '@/icons';
    import { ENTITY_TYPES, ESTIMATE_TYPES } from '@/constants.js';
    import FilePreviewModal from '@/modals/FilePreviewModal.vue';
    import { scrollToElementInBlock } from '@/helpers';

    const ESTIMATE = 'Estimate';
    const TEMPLATE_MANAGER = 'TemplateManager';

    export default {
        name: 'EstimatePreview',
        props: {
            item: {
                type: Object,
                required: true
            },
            page: {
                type: String,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            },
            itemIndex: {
                type: Number,
                required: true
            },
            entityType: {
                type: String,
                default: ENTITY_TYPES.RESIDENTIAL_LEAD
            },
            disabled: {
                type: Boolean,
                default: false
            },
            showBaseBidLabel: {
                type: Boolean,
                default: false
            },
            baseBidLabelDisabled: {
                type: Boolean,
                default: false
            }
        },
        components: {
            ArrowIcon,
            CalendarIcon,
            RoundWarningIcon,
            EditIcon,
            TemplateIcon,
            AttachIcon,
            FilePreviewModal
        },
        data() {
            return {
                id: 'estimate-preview-box-' + this.itemIndex,
                ESTIMATE: ESTIMATE,
                toggle: true,
                buttonsVisible: false,
                versionListIsOpen: false,
                ESTIMATE_TYPES: ESTIMATE_TYPES,
                previewModalOpened: false,
                previewFileIndex: null
            }
        },
        computed: {
            versionsLength() {
                return this.item.versions.length;
            },
            versionsActively() {
                return this.versionsLength > 3;
            },
            versions() {
                return this.versionListIsOpen
                    ? this.item.versions
                    : this.item.versions.slice(0, 3);
            },
            editEstimateRoute() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: {
                        return 'ResidentialEstimateEdit';
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_JOB: {
                        return 'CommercialJobEstimateEdit';
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return 'CommercialLeadEstimateEdit';
                    } break;
                }
            },
            editManualEstimateRoute() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: {
                        return 'ResidentialManualEstimateCreate';
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_JOB: {
                        return 'CommercialJobManualEstimateCreate';
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return 'CommercialLeadManualEstimateCreate';
                    } break;
                }
            },
            readMoreLessButtonWidth() {
                return { width: this.disabled ? 'auto' : '100%' }
            },
            versionTag() {
                return this.disabled ? 'div' : 'router-link';
            },
            filesLength() {
                return this.item.files.length;
            }
        },
        methods: {
            toggleDescription() {
                if (!this.toggle) {
                    scrollToElementInBlock(document.getElementById(this.id));
                }
                this.toggle = !this.toggle;
            },
            moveToItemPage(event) {
                if (this.disabled ||
                    this.page === ESTIMATE && !this.$can(this.$permissions.EDIT_ESTIMATE)) {
                    return false;
                }

                if (this.page == ESTIMATE || this.page == TEMPLATE_MANAGER) {
                    event.preventDefault();
                }

                const buttons = document.querySelector('.preview-box-buttons').contains(event.target);

                if (buttons) return false;

                switch (this.page) {
                    case ESTIMATE: {
                        if (this.item.type === ESTIMATE_TYPES.MANUAL) {
                            this.$router.push({
                                name: this.editManualEstimateRoute,
                                params: { estimate_id: this.item.id }
                            });
                        } else {
                            this.$router.push({
                                name: this.editEstimateRoute,
                                params: { estimate_id: this.item.id }
                            });
                        }
                    }; break;
                    case TEMPLATE_MANAGER: {
                        this.$router.push({
                            name: 'TemplateEdit',
                            params: { template_id: this.item.id }
                        });
                    }; break;
                }
            },
            defineEstimateStatus(status) {
                for (let field in this.item.all_statuses) {
                    if (this.item.all_statuses[field] == status) {
                        return field;
                    }
                }
                return '';
            }
        },
        mounted() {
            let description = document.querySelector('.preview-box-description');

            if (description && description.scrollHeight > 135) {
                description.classList.add('line-clamp-5');
                this.buttonsVisible = true;
            }
        }
    }
</script>