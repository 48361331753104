<template>
    <full-modal @close="$emit('close')">
        <div slot="title">
            <div class="f-24-black f-sbold">
                {{ sectionItem.type_id == MATERIAL ? 'Material Details' : 'Labor Details' }}
            </div>
        </div>
        <div slot="body">
            <div class="w-100 pb-30 s-align-4">
                <span class="f-16-darkgrey mr-2">
                    Name:
                    <span class="f-16-blue f-sbold">
                        {{ sectionItem.name }}
                    </span>
                </span>
            </div>
            <div class="w-100 pb-30">
                <form-input
                    label="Estimate Item Name"
                    :class="[{ 'form-input-danger' : errors.has('estimate_name') }]"
                >
                    <input
                        type="text"
                        name="estimate_name"
                        class="form-input-field"
                        placeholder="Estimate Item Name"
                        v-input-style="sectionItem.estimate_name"
                        v-model="sectionItem.estimate_name"
                        v-validate="'min:3|max:250' + (isCustom ? '|required' : '')"
                    />
                </form-input>
                <div class="pt-8">
                    <button
                        class="transparent-button"
                        v-if="!editingDescription && !sectionItem.description"
                        @click="editingDescription = true"
                    >
                        <edit-icon
                            class="mr-1 i-blue"
                            v-if="sectionItem.description != null"
                        />
                        <span class="f-14-blue f-sbold">
                            + Add {{ sectionItem.type_id == MATERIAL ? 'Material' : 'Labor' }} Description
                        </span>
                    </button>
                    <div
                        class="w-100 relative"
                        v-if="editingDescription || sectionItem.description"
                    >
                        <form-input-textarea
                            label="Description"
                            v-input-counter="2000"
                        >
                            <textarea
                                class="form-input-field"
                                v-resize-textarea
                                name="item_description"
                                placeholder="Description"
                                maxlength="2000"
                                v-input-style="sectionItem.description"
                                rows="1"
                                v-model="sectionItem.description"
                            />
                        </form-input-textarea>
                    </div>
                </div>
            </div>
            <div class="pb-30 row-start">
                <div class="s-align-4 lg-8 sm-12 ls-sm-8">
                    <div v-if="isCustom" class="pr-2">
                        <multiselect
                            :options="CUSTOM_ITEM_UNITS_TYPES"
                            name="custom_item_per_unit"
                            data-name="custom_item_per_unit"
                            v-model="sectionItem.per_unit"
                            :max-height="200"
                            placeholder="Unit"
                            :show-labels="false"
                            :allow-empty="false"
                            :searchable="false"
                            v-validate="'required'"
                            :class="[
                                {'multiselect-danger' : errors.has('custom_item_per_unit')}
                            ]"
                        />
                    </div>
                    <div class="w-50 pr-2">
                        <form-input
                            label="Measurement"
                            :maskAfter="'/ ' + sectionItem.per_unit"
                            :maskValue="sectionItem.quantity"
                        >
                            <input
                                type="text"
                                name="item_quantity"
                                class="form-input-field"
                                placeholder="Measurement"
                                v-input-style="sectionItem.quantity"
                                v-model="sectionItem.quantity"
                                v-limiter
                            />
                        </form-input>
                    </div>
                    <div class="w-50 pl-2">
                        <form-input
                            label="Waste"
                            maskAfter="%"
                            :maskValue="sectionItem.waste"
                        >
                            <input
                                type="text"
                                name="item_waste"
                                class="form-input-field"
                                placeholder="Waste"
                                v-input-style="sectionItem.waste"
                                v-model="sectionItem.waste"
                                v-limiter
                            />
                        </form-input>
                    </div>
                </div>
                <div class="lg-1 sm-12 ls-sm-1 s-align-5 p-4">
                    =
                </div>
                <div class="lg-3 sm-12 ls-sm-3">
                    <form-input
                        label="Quantity"
                        :maskAfter="'/ ' + sectionItem.per_unit"
                        :maskValue="totalQuantity"
                    >
                        <input
                            type="text"
                            name="total_quantity"
                            class="form-input-transparent"
                            placeholder="Quantity"
                            v-input-style="totalQuantity"
                            v-model="totalQuantity"
                            disabled
                            v-limiter
                        />
                    </form-input>
                </div>
            </div>
            <div class="pb-30 row-between">
                <div class="column-start lg-6 sm-12 pr-4 pr-sm-0 mb-sm-1">
                    <div class="w-100 pb-4">
                        <radio-button
                            color="dark-blue"
                            label="Cost"
                        >
                            <input
                                type="radio"
                                name="fixed_price"
                                :value="0"
                                v-model="sectionItem.fixed_price"
                            />
                        </radio-button>
                    </div>
                    <div class="w-100">
                        <form-input
                            label="Cost / Unit"
                            maskBefore="$"
                            :maskAfter="'/ ' + sectionItem.per_unit"
                            :maskValue="sectionItem.cost"
                            maskFilter="numberWithCommas"
                        >
                            <input
                                type="text"
                                name="cost_unit"
                                class="form-input-field"
                                placeholder="Cost / Unit"
                                v-input-style="sectionItem.cost"
                                v-model="sectionItem.cost"
                                :disabled="sectionItem.fixed_price == 1"
                                v-limiter
                            />
                        </form-input>
                    </div>
                </div>
                <div class="column-start lg-6 sm-12 pl-4 pl-sm-0">
                    <div class="w-100 pb-4">
                        <radio-button
                            color="dark-blue"
                            label="Price"
                        >
                            <input
                                type="radio"
                                name="fixed_price"
                                :value="1"
                                v-model="sectionItem.fixed_price"
                            />
                        </radio-button>
                    </div>
                    <div class="w-100">
                        <form-input
                            label="Price / Unit"
                            maskBefore="$"
                            :maskAfter="'/ ' + sectionItem.per_unit"
                            :maskValue="sectionItem.fixed_price_value"
                            maskFilter="numberWithCommas"
                        >
                            <input
                                type="text"
                                name="fixed_price_value"
                                class="form-input-field"
                                placeholder="Price / Unit"
                                v-input-style="sectionItem.fixed_price_value"
                                v-model="sectionItem.fixed_price_value"
                                :disabled="sectionItem.fixed_price == 0"
                                v-limiter
                            />
                        </form-input>
                    </div>
                </div>
            </div>
            <div class="w-100 pb-30">
                <div class="blue-box pv-30 row-center">
                    <div class="column-start lg-3 sm-12 ls-sm-3 ph-8 mb-sm-1">
                        <span class="f-13-grey">Total</span>
                        <span class="f-20-black f-sbold">
                            {{ totalQuantity }}
                        </span>
                    </div>
                    <div class="s-align-5 lg-1 sm-12 ls-sm-1 ph-8 mb-sm-1">
                        <span class="f-16-darkgrey">x</span>
                    </div>
                    <div
                        class="column-start lg-3 sm-12 ls-sm-3 ph-8 mb-sm-1"
                        v-if="!sectionItem.fixed_price"
                    >
                        <span class="f-13-grey">Cost/Unit</span>
                        <span class="f-20-black f-sbold">
                            {{ +sectionItem.cost | currency }}
                        </span>
                    </div>
                    <div
                        class="s-align-5 lg-1 sm-12 ls-sm-1 ph-8 mb-sm-1"
                        v-if="!sectionItem.fixed_price"
                    >
                        <span class="f-16-darkgrey">@</span>
                    </div>
                    <div
                        class="column-start lg-4 sm-12 ls-sm-4 ph-8 mb-sm-1"
                        v-if="!sectionItem.fixed_price"
                    >
                        <span class="f-13-grey">Section Margin</span>
                        <span class="f-20-black f-sbold">{{ margin }}%</span>
                    </div>
                    <div
                        class="column-start lg-3 sm-12 ls-sm-3 ph-8 mb-sm-1"
                        v-if="sectionItem.fixed_price"
                    >
                        <span class="f-13-grey">Price/Unit</span>
                        <span class="f-20-black f-sbold">
                            {{ +sectionItem.fixed_price_value | currency }}
                        </span>
                    </div>
                </div>
            </div>
            <div
                class="w-100 pb-30 column-center pt-8"
                data-name="total_container"
            >
                <span class="f-16-grey pb-2">Price</span>
                <span class="f-30-blue f-sbold">{{ price | currency }}</span>
            </div>
            <div class="bt-grey pv-30 row-between" v-if="isCustom === false">
                <div class="s-align-5 sm-12 mb-sm-1">
                    <span class="f-14-darkgrey f-sbold">
                        Want to remove this {{ sectionItem.type_id == MATERIAL ? 'material' : 'labor' }}?
                    </span>
                </div>
                <div class="s-align-5 sm-12">
                    <tooltip
                        direction="left"
                        color="white"
                        :isShow="isDeleteTooltipShow"
                    >
                        <button
                            class="transparent-button s-align-7"
                            @click="isDeleteTooltipShow = !isDeleteTooltipShow"
                        >
                            <trash-icon class="mr-1 i-red"/>
                            <span class="f-13-red f-sbold">
                                Remove
                                {{ sectionItem.type_id == MATERIAL ? 'Material' : 'Labor' }}
                            </span>
                        </button>
                        <template v-slot:content>
                            <div class="f-16-black f-bold">Are you sure?</div>
                            <p class="f-14-black mb-6 mt-2">
                                Are you sure to delete this item?
                                This action is irreversible.
                            </p>
                            <div class="row-between">
                                <button
                                    class="add-button"
                                    @click="isDeleteTooltipShow = false"
                                >
                                    Cancel
                                </button>
                                <button
                                    class="red-button"
                                    @click="deleteItem"
                                    :disabled="requestIsGoing"
                                >
                                    Yes, delete
                                </button>
                            </div>
                        </template>
                    </tooltip>
                </div>
            </div>
        </div>
        <div slot="footer">
            <button
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                @click="save"
                :disabled="requestIsGoing"
            >
                Done
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, FormInput, FormInputTextarea, RadioButton, Tooltip } from '@/ui';
    import { MarkIcon, EditIcon, TrashIcon } from '@/icons';
    import { MATERIAL, CUSTOM_ITEM_UNITS_TYPES } from '@/constants';
    import api from '@/headers.js';
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'EstimateEditItemModal',
        components: {
            FullModal,
            FormInput,
            FormInputTextarea,
            MarkIcon,
            RadioButton,
            EditIcon,
            TrashIcon,
            Tooltip,
            Multiselect
        },
        props: {
            item: {
                type: Object,
                default: function() {
                    return {};
                }
            },
            margin: {
                type: [String, Number],
                default: 0
            },
            isCustom: {
              type: Boolean,
              default: false
            }
        },
        data() {
            return {
                editingDescription: false,
                requestIsGoing: false,
                isDeleteTooltipShow: false,
                sectionItem: {},
                CUSTOM_ITEM_UNITS_TYPES: CUSTOM_ITEM_UNITS_TYPES,
            }
        },
        computed: {
            totalQuantity() {
                let waste = (+this.sectionItem.quantity * +this.sectionItem.waste) / 100;
                return Math.ceil(parseFloat((+this.sectionItem.quantity + waste).toFixed(2)));
            },
            price() {
                if (this.sectionItem.fixed_price) {
                    return parseFloat((this.totalQuantity * +this.sectionItem.fixed_price_value).toFixed(2));
                } else {
                    let margin = 100 - this.margin;

                    let result = (this.totalQuantity * +this.sectionItem.cost * 100)
                        / (margin == 0 ? 1 : margin);

                    return parseFloat(result).toFixed(2);
                }
            }
        },
        methods: {
            save() {
                this.requestIsGoing = true;

                // if the user deleted the value from optional fields,
                // it is necessary to keep 0, not an empty string
                this.sectionItem.quantity = this.sectionItem.quantity == '' ? 0 : this.sectionItem.quantity;
                this.sectionItem.waste = this.sectionItem.waste == '' ? 0 : this.sectionItem.waste;
                this.sectionItem.cost = this.sectionItem.cost == '' ? 0 : this.sectionItem.cost;
                this.sectionItem.fixed_price_value = this.sectionItem.fixed_price_value == '' ? 0 : this.sectionItem.fixed_price_value;

                this.$validator.validateAll().then(result => {
                    if (! result) {
                        this.requestIsGoing = false;
                        return false;
                    }

                    if (this.isCustom) {
                        this.sectionItem.name = this.sectionItem.estimate_name;
                        this.$emit('onAddCustomItem', this.sectionItem);
                        return;
                    }

                    api.put(`/estimate-items?id=${this.sectionItem.id}`, this.sectionItem)
                        .then(response => {
                            this.$emit('update');
                            this.notifySuccess('Item has been updated!');
                        })
                        .catch(error => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.$emit('close');
                            this.requestIsGoing = false;
                        });
                });
            },
            deleteItem() {
                this.requestIsGoing = true;

                api.delete(`estimate-items?id=${this.sectionItem.id}`)
                    .then(response => {
                        this.notifySuccess('Item has been deleted!');

                        this.$emit('update');
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.$emit('close');
                        this.requestIsGoing = false;
                    });
            }
        },
        created() {
            this.MATERIAL = MATERIAL;

            const defaultItem = {
                description: null,
                estimate_name: null,
                fixed_price: 0,
                fixed_price_value: 0,
                cost: 0,
                price: 0,
                quantity: 1,
                per_unit: CUSTOM_ITEM_UNITS_TYPES[0],
                unit: CUSTOM_ITEM_UNITS_TYPES[0],
                waste: 0,
                type_id: MATERIAL,
            }

            this.sectionItem = Object.assign(defaultItem, this.item);
        }
    }
</script>
