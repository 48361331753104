<template>
    <full-modal @close="$emit('close')">
        <div slot="title">Mark as Dead</div>
        <div slot="body">
            <div class="w-100 pb-2">
                <multiselect
                    :options="reasons"
                    name="reason"
                    data-name="reason"
                    v-model="selectedReason"
                    :showLabels="false"                        
                    :max-height="200"
                    placeholder="Select a reasons"
                    track-by="id" 
                    label="title"
                    v-validate="'required'"
                    :class="[{ 'multiselect-danger' : errors.has('reason') }]"
                />
            </div>
            <form-input-textarea 
                label="Comment"
                v-input-counter="1000"
            >
                <textarea 
                    class="form-input-field"
                    rows="1"
                    maxlength="1000"
                    v-input-style="extraReason"
                    v-resize-textarea
                    v-model="extraReason"
                    name="extra_reason"
                    id="extra_reason"
                    placeholder="Comment"
                />
            </form-input-textarea>
            <loader
                size="full"
                v-if="requestIsGoing"
            />
        </div>
        <div slot="footer">
            <button
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                :disabled="requestIsGoing"
                @click="saveMilestoneAsDead"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, FormInputTextarea, Loader } from '@/ui';
    import { BASE_ENTITY_TYPES } from '@/constants';
    import Multiselect from 'vue-multiselect';
    import cloneDeep from 'lodash/cloneDeep';
    import moment from 'moment/src/moment';
    import api from '@/headers.js';

    const OVERVIEW = 'Overview';

    export default {
        name: 'MilestoneCancelModal',
        components: {
            FullModal,
            FormInputTextarea,
            Multiselect,
            Loader
        },
        props: {
            entityType: {
                type: String,
                required: true
            },
            groupId: {
                type: [String, Number],
                default: ''
            },
            jobNumber: {
                type: [String, Number],
                required: false
            }
        },
        data() {
            return {
                requestIsGoing: false,
                selectedReason: null,
                extraReason: '',
                reasons: [],
            }
        },
        computed: {
            entityId() {
                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL:
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.$route.params.lead_id;
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.$route.params.job_id;
                    case BASE_ENTITY_TYPES.OPPORTUNITY:
                        return this.$route.params.opportunity_id;
                    case BASE_ENTITY_TYPES.WARRANTY_JOB:
                        return this.$route.params.job_id;
                }
            },
            isSettings() {
                return this.$route.name === 'ResidentialSettings'
                || this.$route.name === 'CommercialJobSettings'
                || this.$route.name === 'CommercialLeadSettings';
            },
            jobOrLead() {
                if(!this.jobNumber) {
                    return 'Lead';
                } else {
                    return 'Job';
                }
            }
        },
        methods: {
            getReasons() {
                const isOpportunity = this.entityType === BASE_ENTITY_TYPES.OPPORTUNITY;
                const isWarranty = this.entityType === BASE_ENTITY_TYPES.WARRANTY_JOB;
                let type = this.jobNumber ? 'job' : 'lead';
        
                const params = {
                    'filter[group_id]': this.groupId,
                    'filter[type]': type,
                    'is_active': 1
                };
                
                if (isOpportunity){
                    params.entityType = BASE_ENTITY_TYPES.OPPORTUNITY;
                    params['filter[type]'] = BASE_ENTITY_TYPES.OPPORTUNITY;
                }else if (isWarranty){
                    params.entityType = BASE_ENTITY_TYPES.WARRANTY_JOB;
                    params['filter[type]'] = BASE_ENTITY_TYPES.WARRANTY_JOB;
                }
                
                api.get('/company/milestone-reasons', { params })
                    .then(response => {
                        this.reasons = response.data.data.filter(el => el.is_active);
                    });
            },
            saveMilestoneAsDead() {
                this.$validator.validateAll().then(result => {
                    if (!result) return;

                    let canceled = {
                        entity_id: this.entityId,
                        reason: this.selectedReason.title,
                        extra_reason: this.extraReason,
                        entityType: this.entityType
                    };

                    this.requestIsGoing = true;
                    this.$store.dispatch('milestones/CANCEL_ENTITY', canceled)
                        .then(response => {
                            this.notifySuccess(`${this.jobOrLead} was successfully marked as dead`);
                            this.$store.dispatch('milestones/GET_MILESTONES', {
                                entityId: this.entityId,
                                entityType: this.entityType
                            });
                            
                            // Update Millestone Messages if page is open
                            if (this.isSettings) {
                                this.$store.dispatch('milestones/GET_MILESTONES_STATISTICS', {
                                    id: this.entityId,
                                    entityType: this.entityType
                                });
                            }
                        })
                        .catch(error => this.notifyRequestErrors(error))
                        .finally(() => {
                            this.requestIsGoing = false;
                            this.$emit('close');
                        });
                });
            },
        },
        created() {            
            this.getReasons();
        }
    }
</script>
