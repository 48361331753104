<template>
    <div>
        <full-modal @close="$emit('close')">
            <div slot="title">{{ titleToModal }}</div>
            <div slot="body">
                <div class="w-100 mb-20">
                    <div class="s-align-6">
                        <toggle-switch>
                            <input
                                v-model="contactData.is_partner"
                                type="checkbox"
                                name="partners"
                            />
                        </toggle-switch>
                        <span class="f-18-asphalt f-sbold ml-5">
                        Partner
                    </span>
                    </div>
                </div>
                <p class="f-14-asphalt f-sbold pb-2">Contact Name</p>
                <div class="pb-30 row-start">
                    <div class="lg-6 md-6 sm-12 mb-4 pr-2 pr-sm-0">
                        <form-input
                            label="First Name"
                            :class="[{ 'form-input-danger' : errors.has('first_name') }]"
                        >
                            <input
                                type="text"
                                name="first_name"
                                id="first_name"
                                class="form-input-field"
                                placeholder="First Name"
                                v-input-style="contactData.first_name"
                                v-model="contactData.first_name"
                                maxlength="31"
                                v-validate="{ required: !contactData.company, max: 30 }"
                            />
                        </form-input>
                    </div>
                    <div class="lg-6 md-6 sm-12 mb-4 pl-2 pl-sm-0">
                        <form-input
                            label="Last Name"
                            :class="[{ 'form-input-danger' : errors.has('last_name') }]"
                        >
                            <input
                                type="text"
                                name="last_name"
                                id="last_name"
                                class="form-input-field"
                                placeholder="Last Name"
                                v-input-style="contactData.last_name"
                                v-model="contactData.last_name"
                                maxlength="31"
                            />
                        </form-input>
                    </div>
                </div>
                <p class="f-14-asphalt f-sbold pb-2">Contact phones</p>
                <div class="pb-30 row-start">
                    <div
                        class="s-align-4 lg-8 md-7 sm-10 mb-4"
                        v-for="(phone, index) in phones"
                        :key="index"
                        :id="`tel_${index + 1}`"
                    >
                        <div class="column-start">
                            <form-input
                                :label="index == 0 ? 'Phone primary' : 'Phone'"
                                class="form-input-with-select"
                                :class="[{ 'form-input-danger' : errors.has(`tel_${index + 1}`) }]"
                            >
                                <input
                                    type="text"
                                    :name="`tel_${index + 1}`"
                                    class="form-input-field"
                                    v-input-style="phone.number"
                                    v-model="phone.number"
                                    v-mask="'(###) ###-####'"
                                    :placeholder="index == 0 ? 'Phone primary' : 'Phone'"
                                    v-validate="'min:14'"
                                />
                                <multiselect
                                    :options="phoneTypes"
                                    placeholder="Type"
                                    :showLabels="false"
                                    :max-height="150"
                                    :searchable="false"
                                    :name="`phone_type_${index + 1}`"
                                    :data-name="`phone_type_${index + 1}`"
                                    v-model="phone.type"
                                    v-validate="'required'"
                                    :class="[{ 'multiselect-danger' : errors.has(`phone_type_${index + 1}`) }]"
                                />
                            </form-input>
                            <form-input
                                label="Phone extension"
                                :class="[{ 'form-input-danger' : errors.has(`extension-${index}`) }]"
                            >
                                <input
                                    type="text"
                                    :name="`extension-${index}`"
                                    class="form-input-field mt-2"
                                    placeholder="Phone extension"
                                    v-model="phone.extension"
                                    v-validate="'integer'"
                                />
                            </form-input>
                        </div>
                        <div class="static-col-2 s-align-5">
                            <button
                                class="icon-light"
                                @click="removePhone(index)"
                            >
                                <trash-icon/>
                            </button>
                        </div>
                    </div>
                    <div class="s-align-6 lg-4 md-5 sm-2 mb-4">
                        <button
                            class="service-secondary-button"
                            v-if="phones.length <= 2"
                            @click="addPhoneNumber"
                        >
                            <add-icon size="10"/>
                        </button>
                    </div>
                </div>
                <p class="f-14-asphalt f-sbold pb-2">Contact Emails</p>
                <div class="pb-30 row-start">
                    <div
                        class="s-align-4 lg-8 md-7 sm-10 mb-4"
                        v-for="(email, index) in emails"
                        :key="index"
                        :id="`email_${index + 1}`"
                    >
                        <form-input
                            :label="index == 0 ? 'Email Primary' : 'Email'"
                            :class="[{ 'form-input-danger' : errors.has(`email_${index + 1}`) }]"
                        >
                            <input
                                type="email"
                                :name="`email_${index + 1}`"
                                class="form-input-field"
                                :placeholder="index == 0 ? 'Email Primary' : 'Email'"
                                v-input-style="email.email"
                                v-model="email.email"
                                v-validate="'email'"
                            />
                        </form-input>
                        <div class="static-col-2 s-align-5">
                            <button
                                class="icon-light"
                                @click="removeEmail(index)"
                            >
                                <trash-icon/>
                            </button>
                        </div>
                    </div>
                    <div class="s-align-6 lg-4 md-5 sm-2 mb-4">
                        <button
                            class="service-secondary-button"
                            v-if="emails.length <= 2"
                            @click="addEmail"
                        >
                            <add-icon size="10"/>
                        </button>
                    </div>
                </div>
                <p class="f-14-asphalt f-sbold pb-2">Other information</p>
                <div class="row-start">
                    <div class="lg-12 md-12 sm-12 mb-4 pr-sm-0">
                        <form-input
                            label="Company"
                            :class="[{ 'form-input-danger' : errors.has('company') }]"
                        >
                            <input
                                type="text"
                                name="company"
                                id="company"
                                class="form-input-field"
                                placeholder="Company"
                                v-input-style="contactData.company"
                                v-model="contactData.company"
                                maxlength="256"
                                v-validate="{ required: !contactData.first_name, max: 256, regex: titleRegex }"
                            />
                        </form-input>
                    </div>
                </div>
                <div class="pb-30 row-start">
                    <div class="lg-12 md-12 sm-12 mb-4">
                        <form-input
                            label="Position"
                            :class="[{ 'form-input-danger' : errors.has('position') }]"
                        >
                            <input
                                type="text"
                                name="position"
                                id="position"
                                class="form-input-field"
                                placeholder="Position"
                                v-input-style="contactData.position"
                                v-model="contactData.position"
                                maxlength="255"
                                v-validate="{ regex: titleRegex }"
                            />
                        </form-input>
                    </div>
                </div>
                <p class="f-14-asphalt f-sbold pb-2">Contact address</p>
                <div class="pb-30 w-100">
                    <ClearInputValue
                        @clearValue="clearAddress"
                        :value="checkValueAddress"
                        :icon-name="'trash'"
                        :class-for-icon="'i-grey'"
                    >
                        <address-box
                            type="address"
                            :key="'address'"
                            :existing="address"
                            validate=""
                            :apartment="true"
                            @address="getMainAddress"
                        >
                            <div
                                class="w-100 mb-4"
                                slot="apartment"
                            >
                                <form-input
                                    label="Suite"
                                    :class="[{ 'form-input-danger' : errors.has('suite') }]"
                                >
                                    <input
                                        type="text"
                                        name="suite"
                                        id="suite"
                                        class="form-input-field"
                                        placeholder="Suite"
                                        v-input-style="address.apartment"
                                        v-model="address.apartment"
                                        v-validate="{ max: 256, regex: titleRegex }"
                                    />
                                </form-input>
                            </div>
                        </address-box>
                    </ClearInputValue>
                </div>
            </div>
            <div slot="footer">
                <button
                    class="add-button"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button
                    class="primary-button"
                    @click="save"
                    :disabled="requestIsGoing"
                >
                    Create
                </button>
            </div>
        </full-modal>
        <zip-code-modal
            v-if="outOfArea"
            @continue="continueAction"
            @cancel="cancel"
            @close="cancel"
        />
    </div>
</template>

<script>
    import {FullModal, ToggleSwitch, FormInput} from '@/ui';
    import {TrashIcon, AddIcon} from '@/icons';
    import Multiselect from 'vue-multiselect';
    import AddressBox from '@/components/AddressBox.vue';
    import UserBox from '@/components/User/UserBox.vue';
    import {LEAD_TEL_TYPES, NOT_ALLOWED_CHARACTERS} from '@/constants';
    import {mask} from 'vue-the-mask';
    import api from '@/api';
    import {removePhoneMask, validationErrorNotification} from '@/helpers';
    import cloneDeep from 'lodash/cloneDeep';
    import ClearInputValue from "../ui/ClearInputValue";
    import {ADDRESS_TYPES} from "../constants";
    import { mapActions, mapGetters } from 'vuex';
    
    export default {
        name: 'AddContactModal',
        components: {
            FullModal,
            ToggleSwitch,
            FormInput,
            Multiselect,
            AddressBox,
            UserBox,
            TrashIcon,
            AddIcon,
            ClearInputValue,
            zipCodeModal: () => import('@/modals/ZipCodeModal.vue')
        },
        directives: {
            mask
        },
        props: {
            editedContact: {
                type: Object,
                required: false
            },
            salesmansList: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                contactData: {
                    is_partner: false,
                    first_name: "",
                    last_name: "",
                    company: "",
                    position: "",
                    created_by: "",
                    phones: [],
                    emails: [],
                    addresses: []
                },
                phones: [
                    {
                        number: "",
                        extension: '',
                        type: LEAD_TEL_TYPES.MOBILE
                    }
                ],
                phoneTypes: [LEAD_TEL_TYPES.MOBILE,LEAD_TEL_TYPES.WORK],
                emails: [
                    {email: ""}
                ],
                address: {
                    address: '',
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                    lat: null,
                    lng: null,
                    apartment: ''
                },
                titleRegex: NOT_ALLOWED_CHARACTERS,
                requestIsGoing: false
            }
        },
        computed: {
            ...mapGetters('zipCodes', [
                'outOfArea'
            ]),
            editContactMode() {
                return !!(this.editedContact && Object.keys(this.editedContact).length);
            },
            titleToModal() {
                return this.editContactMode ? 'Edit contact' : 'Create new contact';
            },
            checkValueAddress() {
                const copyAddress = cloneDeep(this.address);
                delete copyAddress.apartment;
                return Object.values(copyAddress).some(el => el)
            }
        },
        methods: {
            ...mapActions('zipCodes', [
                'checkZipCodeArea'
            ]),
            continueAction() {
                return !this.editContactMode ? this.createContact() : this.editContact();
            },
            clearAddress() {
                this.address = null;
                this.changeAddress();
            },
            removePhone(index) {
                if (this.phones.length === 1) {
                    const phone = this.phones[0];
                    phone.extension = '';
                    phone.number = '';
                    phone.type = LEAD_TEL_TYPES.MOBILE;
                } else {
                    this.phones.splice(index, 1);
                }
            },
            removeEmail(index) {
                if (this.emails.length === 1){
                    const email = this.emails[0];
                    email.email = '';
                }else{
                    this.emails.splice(index, 1);
                }
            },
            changeAddress(obj) {
                this.address = {
                    address: obj ? obj.address : '',
                    street: obj ? obj.street : '',
                    city: obj ? obj.city : '',
                    state: obj ? obj.state : '',
                    zip: obj ? obj.zip : '',
                    lat: obj ? obj.lat : null,
                    lng: obj ? obj.lng : null,
                    type: obj ? obj.type : null,
                    apartment: obj ? obj.apartment : '',
                }
            },
            addPhoneNumber() {
                this.phones.push({
                    extension: '',
                    number: '',
                    type: LEAD_TEL_TYPES.WORK
                });
            },
            addEmail() {
                this.emails.push({email: ''});
            },
            getMainAddress(mailingData) {
                this.address = mailingData;
            },
            customLabel({first_name, last_name}) {
                return `${first_name} – ${last_name}`;
            },
            selectCreatedBy() {
                if (this.contactData && this.contactData.created_by && this.salesmansList.length) {
                    this.createdBy = this.salesmansList.find(item => {
                        return this.contactData.created_by.id === item.id;
                    });
                }
            },
            validateBeforeSubmit() {
                return new Promise((resolve, reject) => {
                    this.$children.forEach(vm => {
                        vm.$validator.validateAll();

                        this.$validator.validateAll().then(() => {
                            if (this.$validator.errors.items.length) {
                                const context = this;
                                validationErrorNotification(this.$validator, context);
                                reject();
                            }

                            resolve();
                        })
                    });
                });
            },
            save() {
                this.validateBeforeSubmit()
                    .then(() => {
                        this.cancel();
                        if (!this.address.zip) {
                           this.continueAction();
                        } else {
                            this.checkZipCodeArea(this.address.zip).then(response => {
                                if (response) {
                                    this.continueAction();
                                }
                            });
                        }
                    })
            },
            prepareData() {
                const contactData = cloneDeep(this.contactData);

                this.requestIsGoing = true;
                let phones = this.phones ? this.phones.filter(el => el.number) : [];
                if (phones.length) {
                    removePhoneMask(phones);
                    contactData.phones = phones.map(p => {
                        const objForNumber = {
                            number: p.number,
                            type: p.type
                        }
                        if (p.id) {
                            objForNumber.id = p.id;
                        }
                        if (p.extension){
                            objForNumber.extension = p.extension;
                        } else {
                            objForNumber.extension = null;
                        }
                        return objForNumber;
                    });
                } else {
                    if (!this.editContactMode) {
                        delete contactData.phones;
                    }else{
                        contactData.phones = [];
                    }
                }
                const emails = this.emails.filter(el => el.email);
                if (emails.length) {
                    contactData.emails = emails;
                } else {
                    if (!this.editContactMode) {
                        delete contactData.emails;
                    }else{
                        contactData.emails = [];
                    }
                }
                const checkAddress = this.address && Object.values(this.address).some(el => el);
                if (checkAddress) {
                    this.address.type = ADDRESS_TYPES.MAIN;
                    contactData.addresses = [this.address];
                } else {
                    if (!this.editContactMode) {
                        delete contactData.addresses;
                    } else{
                        contactData.addresses = [];
                    }
                }

                return contactData;
            },
            createContact() {
                this.requestIsGoing = true;
                const payload = this.prepareData();

                api.Contact.create(payload)
                    .then((response) => {
                        this.notifySuccess('Contact has been added!');
                        this.$emit('refreshContact', response.data.data, true);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => this.requestIsGoing = false);
            },
            editContact() {
                this.requestIsGoing = true;
                const payload = this.prepareData();
                api.Contact.update(this.contactData.id, payload)
                    .then((response) => {
                        this.notifySuccess('Contact has been updated!');
                        this.$emit('refreshContact', response.data.data);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => this.requestIsGoing = false);
            },
            cancel() {
                this.$store.commit('zipCodes/SET_OUT_OF_AREA', false);
            }
        },
        created() {
            this.cancel();
            if (this.editContactMode) {
                this.contactData = cloneDeep(this.editedContact);
                this.changeAddress(cloneDeep(this.editedContact.addresses[0]));
                const phones = cloneDeep(this.editedContact.phones);
                const emails = cloneDeep(this.editedContact.emails);
                if (phones.length){
                    this.phones = phones.map(el => {
                        return {
                            id: el.id,
                            extension: el.extension,
                            number: el.number,
                            type: el.type
                        }
                    });
                }
                if (emails.length){
                    this.emails = cloneDeep(this.editedContact.emails);
                }
                this.selectCreatedBy();
            }
        }
    }
</script>
