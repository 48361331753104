<template>
    <full-modal @close="$emit('close')">
        <div slot="title">Custom Item Details</div>
        <div slot="body">
            <div class="w-100 pb-30">
                <form-input 
                    label="Item Name" 
                    :class="{'form-input-danger': errors.first('item_name')}"
                >
                    <input 
                        class="form-input-field"
                        name="item_name"
                        v-input-style="formData.name"
                        v-model="formData.name"
                        type="text"
                        placeholder="Item Name"
                        v-validate="'required|min:3|max:250'"
                        autocomplete="off"
                    />
                </form-input>
                <multiselect
                    v-if="trades.length"
                    :show-labels="false"
                    :allow-empty="false"
                    :options="trades.map(item => item.id)"
                    label="Trade"
                    placeholder="Select trade"
                    :custom-label="opt => trades.find(x => x.id == opt).name"
                    :max-height="200"
                    name="trade"
                    data-name="trades"
                    v-model="formData.trade_id"
                    v-validate="'required'"
                    :class="[{'multiselect-danger' : errors.has('trade')}, 'mt-1']"
                />
            </div>
            <div 
                class="w-100 pb-30"
                v-if="isEditingConversion"
            >
                <p class="f-16-darkgrey pb-2">Conversion</p>
                <div class="row-between row-baseline">   
                    <div class="lg-5 sm-12">
                        <form-input 
                            label="Estimate"
                            :class="[
                                'form-input-with-select',
                                {'form-input-danger' : errors.first('estimate')}
                            ]"
                        >
                            <input 
                                class="form-input-field"
                                name="estimate"
                                v-input-style="formData.estimate"
                                v-model.number="formData.estimate"
                                type="text"
                                placeholder="Estimate"
                                v-validate="'required'"
                                autocomplete="off"
                                v-limiter
                            />
                            <multiselect
                                :options="unitTypes"
                                name="estimate_type"
                                data-name="estimate_type"
                                v-model="formData.estimate_unit"
                                :max-height="200"
                                placeholder="Unit"
                                :show-labels="false"
                                :allow-empty="false"
                                :searchable="false"
                                @select="onEstimateType"
                                v-validate="'required'"
                                :class="[{'multiselect-danger' : errors.has('estimate_type')}, 'estimate-type']"
                            />    
                        </form-input>
                    </div>
                    <div class="s-align-5 p-2">
                        <p class="f-16-darkgrey">=</p>
                    </div>
                    <div class="lg-5 sm-12">
                        <form-input 
                            label="Order"
                            class="form-input-with-select"
                        >
                            <input 
                                class="form-input-field"
                                name="order"
                                v-input-style="formData.order"
                                v-model.number="formData.order"
                                type="text"
                                placeholder="Order"
                                autocomplete="off"
                                v-limiter
                            />
                            <multiselect
                                :options="unitTypes"
                                name="order_type"
                                data-name="order_type"
                                v-model="formData.order_unit"
                                :show-labels="false"
                                :max-height="200"
                                :allow-empty="false"
                                placeholder="Unit"
                                :searchable="false"
                                @select="onOrderType"
                                class="order-type"
                            /> 
                        </form-input>
                    </div>
                </div>
            </div>
            <div 
                class="w-100 pb-30"
                v-else
            >
                <div class="row-between">
                    <p class="f-16-darkgrey">
                        Conversion: 
                        {{`${item.estimate} ${item.estimate_unit} per ${item.order} ${item.order_unit}`}}
                    </p>
                    <a 
                        @click.prevent="() => isEditingConversion = true"
                        class="f-16-blue edit-link"
                        href=""
                    >
                        Edit
                    </a>
                </div>
            </div>
            <div class="w-100 pb-30">
                <p class="f-16-darkgrey pb-2">Cost Title</p>
                <form-input
                    label="Cost"
                    :class="[
                        'form-input-with-select',
                        {'form-input-danger' : errors.first('cost')}
                    ]"
                    maskBefore="$"
                    :maskValue="formData.cost"
                    maskFilter="numberWithCommas"
                >
                    <input 
                        class="form-input-field"
                        name="cost"
                        v-input-style="formData.cost"
                        v-model="formData.cost"
                        type="text"
                        placeholder="Cost"
                        autocomplete="off"
                        v-validate="'required'"
                        v-limiter
                    />
                    <multiselect
                        :options="costTypes"
                        class="per-unit"
                        name="per_unit"
                        data-name="per_unit"
                        placeholder="Unit"
                        :show-labels="false"
                        :allow-empty="false"
                        :disabled="costTypes.length === 0"
                        v-model="formData.per_unit"
                        @input="recomputeCost"
                        :searchable="false"
                    />
                </form-input>
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                @click="onDone"
                :disabled="disableActions || errors.any()"
            >
                {{ isEdit ? 'Done' : 'Create' }}
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { Modal, FormInput, CheckboxButton, FullModal } from '@/ui';
    import Multiselect from 'vue-multiselect';
    import { CUSTOM_ITEM_UNITS_TYPES } from '@/constants.js';
    import api from '@/headers.js';

    const MATERIALS = 'Materials';
    
    export default {
        name: 'CustomItemCreateEdit',
        components: {
            Modal,
            FullModal,
            FormInput,
            Multiselect
        },
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            item: {
                type: Object,
                required: false
            },
            trades: {
                type: Array,
                required: false
            },
            activeFilter: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                formData: {
                    id: '',
                    name: '',
                    estimate: '',
                    estimate_unit: '',
                    order: '',
                    order_unit: '',
                    cost: '',
                    per_unit: '',
                    is_custom: 1,
                    trade_id: null,
                },
                materials: MATERIALS,
                disableActions: false,
                isEditingConversion: true,
                unitTypes: CUSTOM_ITEM_UNITS_TYPES
            }
        },
        created() {
            if (this.isEdit) {
                this.formData = Object.assign(this.formData, this.item);
                this.isEditingConversion = false;
            } else {
                this.formData.trade_id = this.activeFilter;
            }
        },
        methods: {
            onDone() {
                let destination = this.materialsPage ? '/materials' : '/labors';

                this.$validator.validateAll()
                    .then(result => {
                        if (result) {
                            if (this.formData.order_unit === '') {
                                this.formData.order_unit = this.formData.estimate_unit;
                            }

                            if (this.formData.order === '') {
                                this.formData.order = 1;
                            }

                            if (this.formData.cost === '') {
                                this.formData.cost = 0;
                            }

                            this.disableActions =  true;

                            this.sendRequest(this.isEdit, destination);
                        }
                    })
            },
            onEstimateType(value) {
                if (this.formData.per_unit === '') {
                    this.formData.per_unit = value;
                }

                if (this.formData.per_unit === this.formData.estimate_unit) {
                    this.formData.per_unit = value;
                }
            },
            onOrderType(value) {
                if (this.formData.per_unit === '') {
                    this.formData.per_unit = value;
                }
                
                if (this.formData.per_unit === this.formData.order_unit) {
                    this.formData.per_unit = value;
                }
            },
            recomputeCost(value) {
                if ([this.formData.cost, this.formData.estimate, this.formData.order].some(i => i === '')) {
                    // no need to recompute cost
                    return;
                } else {
                    if (value === this.formData.order_unit) {
                        this.formData.cost = Math.round((this.formData.cost * this.formData.order / this.formData.estimate) * 100) / 100
                    }
                    if (value === this.formData.estimate_unit) {
                        this.formData.cost = Math.round((this.formData.cost * this.formData.estimate / this.formData.order) * 100) / 100
                    }
                }
            },
            sendRequest(isEdit, destination) {
                let method = isEdit ? 'put' : 'post';
                let action = isEdit ? 'updated' : 'created'
                delete this.formData.provide_id;

                api[method](destination, this.formData)
                    .then(response => {
                        if (this.materialsPage) {
                            this.$emit(action, response.data.data);
                        } else {
                            let data = response.data.data;
                            this.$emit(action, data);
                        }
                        this.notifySuccess(`Item has been ${action}`);
                        this.$emit('close');
                    })
                    .catch(error => {
                        this.notifyError(`Item ${action.slice(0, -1)}ing error`);
                    })
                    .finally(() => {
                        this.disableActions = false;
                    });
            }
        },
        computed: {
            materialsPage() {
                return this.$route.name === this.materials;
            },
            costTypes() {
                let items = [];
                if (this.formData.estimate_unit !== '') {
                    items = [this.formData.estimate_unit]
                }
                if (this.formData.order_unit !== '') {
                    items = [...items, this.formData.order_unit]
                }
                return items.filter(function(item, pos) {
                    return items.indexOf(item) == pos;
                });
            }
        }
    }
</script>