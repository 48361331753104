import { SET_NOTIFICATION_MARK } from "../actions/notifications";

export default {
    namespaced: true,
    state: {
        notificationMark: false
    },
    mutations: {
        [SET_NOTIFICATION_MARK] (state,data){
            state.notificationMark = data
        }
    }
}