<template>
    <full-modal
        @close="$emit('close')"
        class="send-new-email-modal"
        :full="true"
    >
        <div slot="title">{{ title }}</div>
        <div slot="body">
            <div class="column-start mb-8 bb-grey relative">
                <div class="s-align-1 f-13-black w-100 f-sbold mb-4 flex-no-wrap bb-grey">
                    <span class="mt-6 no-wrap">
                        Send to:
                    </span>
                    <div class="w-90 ml-4">
                        <lead-emails-chips
                            :leadChips="sendChips"
                            :entityType="entityType"
                            :account-id="account_id"
                            name="send-to-email"
                        />
                    </div>
                    <div class="s-align-1 mt-6 no-wrap">
                        <button
                            v-if="!showCC"
                            @click="showCC = true"
                            class="transparent-button mr-1 f-13-blue"
                        >
                            + CC
                        </button>
                        <button
                            v-if="!showBCC"
                            @click="showBCC = true"
                            class="transparent-button f-13-blue"
                        >
                            + BCC
                        </button>
                    </div>
                </div>
                <div
                    v-if="showCC"
                    class="s-align-1 f-13-black w-100 f-sbold mb-4 no-wrap bb-grey"
                >
                    <span class="mt-6">
                        Carbon copy:
                    </span>
                    <div class="w-90 ml-4">
                        <lead-emails-chips
                            :leadChips="copyChips"
                            :entityType="entityType"
                            :account-id="account_id"
                            :is-get-emails-outlook="false"
                            name="send-to-copy-email"
                        />
                    </div>
                </div>
                <div
                    v-if="showBCC"
                    class="s-align-1 w-100 f-13-black f-sbold no-wrap bb-grey"
                >
                    <span class="mt-6">
                        Blind copy:
                    </span>
                    <div class="w-90 ml-4">
                        <lead-emails-chips
                            :leadChips="blindChips"
                            :entityType="entityType"
                            :account-id="account_id"
                            :is-get-emails-outlook="false"
                            name="send-to-bcopy-email"
                        />
                    </div>
                </div>
                <div class="s-align-1 w-100 f-13-black f-sbold no-wrap subject-wrapper">
                    <span :class="['mt-8', {'f-13-red' : errors.has('subject') }]">
                        Subject:
                    </span>
                    <div class="w-90 ml-4">
                        <input
                            type="text"
                            name="subject"
                            class="form-input-transparent w-100"
                            v-model="subject"
                            v-validate="'required'"
                        />
                    </div>
                </div>
            </div>
            <div class="relative">
                <text-editor
                    placeholder="Type your message here"
                    v-model="message"
                    toolbarName="notesToolbar"
                />
                <div class="row-start send-new-email-button transparent-button">
                    <button
                        v-if="isTemplateShow"
                        @click="showEmailtemplatesList = !showEmailtemplatesList"
                        class="transparent-button mr-8"
                    >
                        <points-icon class="i-grey" />
                    </button>
                    <dropdown-menu
                        v-if="showEmailtemplatesList && isTemplateShow"
                        :items="templates"
                        trackBy="title"
                        max-height="100"
                        max-width="200"
                        @select="selectTemplate"
                    >
                        <template v-slot:header>
                            <div class="f-sbold f-16-black ph-8 pv-4 w-100">Use Template</div>
                        </template>
                        <template v-slot:content>
                            <div
                                v-if="loading"
                                class="column-center s-align-5 w-100 mb-8"
                            >
                                <loader size="mini"/>
                            </div>
                            <div
                                v-if="!templates.length && !loading"
                                class="ph-8 pv-4 mb-4"
                            >
                                <router-link
                                    class="f-13-blue f-sbold"
                                    :to="{ name: 'EmailTemplates' }"
                                >
                                    + Create Template
                                </router-link>
                            </div>
                        </template>
                    </dropdown-menu>
                    <button
                        class="primary-button"
                        @click="sendMessage()"
                        :disabled="requestIsGoing"
                    >
                        <send-icon
                            class="mr-1"
                            size="15"
                        />
                        Send
                    </button>
                </div>
                <button
                    class="transparent-button send-attachment-button"
                    @click="addAttachment()"
                >
                    <attach-icon
                        class="i-grey"
                        size="16"
                    />
                </button>
                <input
                    type="file"
                    name="file_upload"
                    ref="uploadInput"
                    @change="fileInputHandler"
                    hidden
                />
            </div>
            <div class="row-start mt-8">
                <div
                    v-for="(attachment, index) in attachments"
                    :key="index"
                    class="s-align-1 mb-4 mr-8"
                >
                    <div class="s-align-4">
                        <div class="attachment-preview s-align-5">
                            <span class="f-13-grey f-sbold">
                                .{{ getFileType(attachment.name || attachment.Name || attachment.title) }}
                            </span>
                        </div>
                        <div class="attachment-name max-w-80 column-start ml-4">
                            <span class="f-14-black break-all f-sbold">
                                {{ attachment.name || attachment.Name }}
                            </span>
                            <div class="row-start">
                                <span
                                    v-if="attachment.size"
                                    class="f-12-grey"
                                >
                                    {{ attachment.size && attachment.size / 1000 }} kb
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="ml-1">
                        <button
                            class="transparent-button p-1"
                            @click="removeAttachment(index)"
                        >
                            <close-icon
                                class="i-red"
                                size="5"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, FormInput, DropdownMenu, Loader } from '@/ui';
    import { SendIcon, AttachIcon, CloseIcon, PointsIcon } from '@/icons';
    import TextEditor from '@/components/TextEditor.vue';
    import IMAPapi from '@/IMAPheaders';
    import api from '@/headers.js';
    import LeadEmailsChips  from '../components/LeadEmailsChips.vue';
    import { BASE_ENTITY_TYPES, BASE_PATH, MODEL_CLASSES, KEY_TEMPLATE, MAX_SIZE_FILES } from '@/constants';

    export default {
        name: 'SendNewEmailModal',
        components: {
            Loader,
            DropdownMenu,
            PointsIcon,
            FullModal,
            FormInput,
            TextEditor,
            SendIcon,
            LeadEmailsChips,
            AttachIcon,
            CloseIcon
        },
        props: {
            event: {
              type: Object,
              default: null
            },
            account_id: {
                type: [Number, String],
                required: true
            },
            defaultSubject: {
                type: String,
                required: false
            },
            defaultSendTo: {
                type: String,
                required: false
            },
            attachment: {
                required: false
            },
            entityType: {
                type: String,
                required: true
            },
            title: {
                type: String,
                default: 'New Email'
            },
            isTemplateShow: {
                type: Boolean,
                default: true
            },
            informChangeDates: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                sendChips: [],
                copyChips: [],
                blindChips: [],
                subject: '',
                message: '',
                requestIsGoing: false,
                showCC: false,
                showBCC: false,
                attachmentsList: [],
                sendingAttachments: [],
                showEmailtemplatesList: false,
                templates: [],
                selectedTemplate: '',
                loading: false,
                uploadAttachmentsList: []
            }
        },
        computed: {
            entityId() {
                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL:
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                    case BASE_ENTITY_TYPES.RESIDENTIAL_JOB:
                        if (this.$route.name === 'OrderPreview') {
                            return this.$route.params.entity_id;
                        } else {
                            return this.$route.params.lead_id;
                        }
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        if (this.$route.name === 'OrderPreview') {
                            return this.$route.params.entity_id;
                        } else {
                            return this.$route.params.job_id;
                        }
                    case BASE_ENTITY_TYPES.WARRANTY_JOB:
                        if (this.$route.name === 'OrderPreview') {
                            return this.$route.params.entity_id;
                        } else if (this.$route.name === 'WarrantyPreview') {
                            return this.$route.params.id;
                        } else {
                            return this.$route.params.job_id;
                        }
                }
            },
            entityNumber() {
                switch (this.entityType) {
                case BASE_ENTITY_TYPES.RESIDENTIAL:
                    return this.$store.getters['lead/getJobNumber'];
                case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                    return this.$store.getters['commercial/getCommercialJobNumber'];
                case BASE_ENTITY_TYPES.WARRANTY_JOB:
                    return this.$store.getters['warranty/getWarrantyJobNumber'];
                default:
                    return null;
                }
            },
            userSignature() {
                return this.$store.getters['user/getUserSignature'];
            },
            basePath() {
                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL:
                        return BASE_PATH.RESIDENTIAL;
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        return BASE_PATH.COMMERCIAL_JOB;
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                        return BASE_PATH.COMMERCIAL_LEAD;
                }
            },
            modelClass() {
                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL:
                        return MODEL_CLASSES.RESIDENTIAL_LEAD;
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                        return MODEL_CLASSES.COMMERCIAL_LEAD;
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        return MODEL_CLASSES.COMMERCIAL_JOB.JOB;
                }
            },
            attachments() {
                return this.uploadAttachmentsList.concat(this.attachmentsList);
            },
            attachmentsForSendInEmail() {
                return [...this.sendingAttachments, ...this.attachmentsList];
            },
            residentialJob() {
                if (this.entityNumber
                    && this.entityType === BASE_ENTITY_TYPES.RESIDENTIAL) {
                    return 'residentialJob';
                }
            }
        },
        methods: {
            selectTemplate(data) {
                this.attachmentsList = data.attachments;
                this.message = data.description;
                this.subject = data.subject;
                this.selectedTemplate = data.title;
                this.showEmailtemplatesList = false;
            },
            addAttachment() {
                this.$refs.uploadInput.click();
            },
            removeAttachment(index) {
                this.attachmentsList.splice(index, 1);
                this.sendingAttachments.splice(index, 1);
            },
            getFileType(name) {
                if (name) {
                    let fileType = name.split('.');
                    return fileType[fileType.length -1];
                }
            },
            fileInputHandler(event) {
                if (!this.$refs.uploadInput.files[0]) return false;
                let file = event.target.files[0];

                if (MAX_SIZE_FILES.FILE_EMAIL_MESSSAGE < file.size) {
                    this.notifyError('Maximum size of the file can be 30.0MB');
                    return;
                }

                var reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = () => {
                    let attachment = {
                        Name: file.name,
                        ContentBytes: btoa(reader.result),
                        contentType: file.type,
                        size: file.size
                    }
                    this.attachmentsList.push(attachment);
                };
            },
            sendMessage() {
                this.requestIsGoing = true;
                let entityIds = [];
                let toRecipients = [];
                let ccRecipients = [];
                let bccRecipients = [];
                const sizeAllFiles = this.attachmentsList.reduce(function(sum, current) {
                    return sum + current.size;
                }, 0);

                if (MAX_SIZE_FILES.FILE_EMAIL_MESSSAGE < sizeAllFiles) {
                    this.notifyAttachmentsFileLimit();
                    return;
                }

                entityIds.push(Number(this.entityId || this.event.entity_id));

                this.$validator.validateAll().then(result => {
                    if (this.$validator.errors.items.length || !this.message.length) {
                        this.notifyError('Fill all required fields!');
                        this.requestIsGoing = false;
                        return;
                    }

                    if (this.sendChips.length) {
                        this.sendChips.forEach(mail => {
                            let emailAddress = {
                                "emailAddress": {
                                    "address": mail
                                }
                            };
                            toRecipients.push(emailAddress);
                        });
                    }

                    if (this.copyChips.length) {
                        this.copyChips.forEach(mail => {
                            let emailAddress = {
                                "emailAddress": {
                                    "address": mail
                                }
                            };
                            ccRecipients.push(emailAddress);
                        });
                    }

                    if (this.blindChips.length) {
                        this.blindChips.forEach(mail => {
                            let emailAddress = {
                                "emailAddress": {
                                    "address": mail
                                }
                            };
                            bccRecipients.push(emailAddress);
                        });
                    }

                    let params = {
                        "account_id": this.account_id,
                        "message": {
                            "subject": this.subject,
                            "body": {
                                "contentType": "HTML",
                                "content": this.message
                            },
                            "toRecipients": toRecipients,
                            "ccRecipients": ccRecipients,
                            "bccRecipients": bccRecipients,
                            "attachments": this.attachmentsForSendInEmail
                        },
                        "entity_ids": entityIds,
                        "entity_type": this.residentialJob ? this.residentialJob : this.entityType
                    };

                    if (this.informChangeDates) {
                        this.$emit('sendInformChangeDates', params);
                    } else {
                        params.entity_number = this.entityNumber ? this.entityNumber.toString() : this.entityNumber;
                    }

                    IMAPapi.post(`microsoft/message`, params)
                        .then(response => {
                            this.notifySuccess('Message is send');
                            this.$emit('onSend')
                            this.$emit('close');
                        })
                        .catch(error => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.requestIsGoing = false;
                        });
                });
            },
            getEmailTemplates() {
                this.loading = true;

                const params = {
                    key: KEY_TEMPLATE.EMAIL_TEMPLATE,
                }

                api.get('/message-template', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        let keys, values;
                        keys = Object.keys(data);
                        values = Object.values(data);
                        (values || []).forEach((el, index) => {
                            keys[index] ? el.name = keys[index] : null;
                        });

                        this.templates = values;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        created() {
            if (this.isTemplateShow) {
                this.getEmailTemplates();
            }
            if (this.userSignature) {
                // if signature exist insert with 3 line break
                // .slice() remove <div id='main'></div> wrapper
                this.message = `<p><br></p><p><br></p><p><br></p>${this.userSignature.slice(15, -6)}`;
            }

            if (this.defaultSendTo) {
                this.sendChips.push(this.defaultSendTo);
            }

            if (this.defaultSubject) {
                this.subject = this.defaultSubject;
            }

            if (this.attachment) {
                fetch(`data:application/pdf;base64,${this.attachment}`)
                    .then(res => res.blob())
                    .then(blob => {
                        let reader = new FileReader();
                        let file = new File([blob], `${this.defaultSubject}.pdf`, { type: 'application/pdf' });

                        this.uploadAttachmentsList.push(file);
                        reader.readAsBinaryString(file);
                        reader.onload = () => {
                            let attachment = {
                                Name: file.name,
                                ContentBytes: btoa(reader.result),
                                contentType: file.type,
                                size: file.size
                            };
                            this.sendingAttachments.push(attachment);
                        };
                    });
            }
        },
        destroyed() {
            this.$store.commit('scheduler/SET_IS_INFORM_CHANGE_DATES', true);
        }
    }
</script>
