import { PROJECT_COLORS } from '@/constants';

export const getEventDescription = {
    data() {
        return {
            colors: PROJECT_COLORS
        }
    },
    methods: {
        getEventDescription(summary, location, user) {
            let description = '';
            if (summary) {
                description =  `<b>${summary}</b>`;
            }

            if (location) {
                description += `<br> ${location}`;
            }
            if (user && user.thumbnail) {
                description += `<br>
                    <span class="user-avatar">
                        <img class="b-grey" src="${user.thumbnail}"/>
                    </span>`;
            } else if (user) {
                const firstLetter = user.first_name.charCodeAt() - 64;
                const color = this.colors[firstLetter];
                description +=`<br>
                    <span class="user-initials f-10-white b-grey" style="background-color: ${color}">
                        ${user.first_name.charAt(0) + user.last_name.charAt(0)}
                    </span>`;
            }

            if (!summary && !location && !user) {
                description =  `<b>No subject</b>`;
            }

            return description;
        }
    }
}