import api from '@/headers.js';
import {
    GET_ALL_STATE,
    SET_ALL_STATE,
    GET_COUNTY_BY_STATE,
    SET_COUNTY_BY_STATE
} from './../actions/location';

export default {
    namespaced: true,
    state: {
        allStates: [],
        countyInState: {}
    },
    getters: {
        getAllStates: state => state.allStates,
        getAllCounty: state => state.countyInState
    },
    mutations: {
        [SET_ALL_STATE] (state, payload) {
            state.allStates = payload;
        },
        [SET_COUNTY_BY_STATE] (state, payload) {
            for (const [key, value] of Object.entries(payload)) {
                state.countyInState[key] = value;
            }
        }
    },
    actions: {
        [GET_ALL_STATE] ({commit}) {
            return new Promise((resolve, reject) => {
                api.get(`/states`)
                    .then(response => response.data.data)
                    .then(data => {
                        commit(SET_ALL_STATE, data);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        [GET_COUNTY_BY_STATE] ({commit}, params) {
            return new Promise((resolve, reject) => {
                api.get(`/states/${params.id}`, { params })
                    .then(response => response.data.data)
                    .then(data => {
                        let key = params.id;
                        let county = {
                            [key]: data
                        };
                        commit(SET_COUNTY_BY_STATE, county);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    }
}