import api from '@/api';

export default {
    namespaced: true,
    state: {
        partners: [],
    },
    getters: {
        partners: state => state.partners,
    },
    mutations: {
        SET_PARTNERS(state, partners) {
            state.partners = partners;
        }
    },
    actions: {
        getPartners({ commit }) {
            return api.Partner.list()
                .then((response) => response.data)
                .then((response) => {
                    commit('SET_PARTNERS', response.data);
                });
        }
    }
}
