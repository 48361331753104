export default {
    namespaced: true,
    state: {
        modalOpen: false,
        modalType: null,
        headerTitle: 'New Email',
        outlook_id: null,
        manualMessage: {},
        editMode: false,
        collapsedModal: false,
        updateMessages: false,
        isResidential: false,
        isEmailSent: false
    },
    mutations: {
        openModal(state, payload) {
            state.modalOpen = payload.show;
            state.modalType = payload.modalType;
            state.headerTitle = payload.title;
            state.outlook_id = payload.outlook_id;
            state.manualMessage = payload.editedMessage;
            state.editMode = payload.editMode ? payload.editMode : false;
        },
        collapseModal(state, payload) {
            state.collapsedModal = payload;
        },
        updateMessages(state, payload) {
            state.updateMessages = payload;
        },
        isResidential(state, payload) {
            state.isResidential = payload;
        },
        isEmailSent(state, payload) {
            state.isEmailSent = payload;
        }
    },
    getters: {
        getEmailModalState: state => state.modalOpen,
        getEmailModalTitle: state => state.headerTitle,
        getEmailModalType: state => state.modalType,
        getOutlookId: state => state.outlook_id,
        getMessageForEdit: state => state.manualMessage,
        isEditMode: state => state.editMode,
        isCollapsedModal: state => state.collapsedModal,
        updateMessages: state => state.updateMessages,
        isResidential: state => state.isResidential,
        isEmailSent: state => state.isEmailSent
    }
}