<template>
    <div :class="['list-empty w-100', `list-empty-${viewType}`]">
        <div class="row-start h-100">
            <div class="list-empty-text lg-7 sm-12 ls-sm-8 s-align-4 h-100">
                <div class="column-start w-100">
                    <slot name="default"></slot>
                </div>
            </div>
            <div class="list-empty-img lg-5 sm-12 ls-sm-4 s-align-6">
                <img 
                    src="@/assets/images/documents.svg" 
                    alt="Items not found"
                    v-if="viewType == 'common'"
                />
                <img 
                    src="@/assets/images/measurement-empty.svg" 
                    alt="Items not found"
                    v-else-if="viewType == 'map'"
                />
                <img 
                    src="@/assets/images/orders-empty.svg" 
                    alt="Items not found"
                    v-else-if="viewType == 'order'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    /*
    * viewType:
    * common - documents page
    * map - measurements page
    * order - orders page
    */
   
    export default {
        name: 'ListEmpty',
        props: {
            viewType: {
                type: String,
                default: 'common'
            }
        }
    }
</script>