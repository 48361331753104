<template>
    <div :class="alignClass">
        <span 
            :class="textClass"
            v-for="(contact, index) in contacts"
            :key="`${type}-${index}`"
        >
            <template v-if="type == 'email'">
                {{ contact.email }}
            </template>
            <template v-if="type == 'phone' && showCallNumberButton">
                <make-call-from-number-button
                    :phone="contact"
                    :showPhoneType="false"
                    :entityId="entityId"
                    :entityType="entityType"
                    :iconBtn="iconBtn"
                    :class="isContact ? '' : 'mb-1'"
                />
            </template>
            <template v-else>
                {{ contact.number | phone }}
            </template>
            {{ contact.extension ? `Ext - ${contact.extension}` : '' }}
        </span>
        <button
            class="transparent-button f-10-blue f-sbold"
            @click="open = true"
            v-if="!open && itemsLength > 1"
        >
            {{ button }}
        </button>
    </div>
</template>

<script>
    /*
    * type can be 'email' or 'phone'
    * !! use for commercial representatives !!
    */
    import MakeCallFromNumberButton from '../Telephony/MakeCallFromNumberButton.vue';
    import { ENTITY_TYPES } from '@/constants';

    export default {
        name: 'ContactList',
        components: {
            MakeCallFromNumberButton
        },
        props: {
            items: {
                type: Array,
                required: true
            },
            type: {
                type: String,
                required: true
            },
            alignClass: {
                type: String,
                default: 'w-100 column-start'
            },
            textClass: {
                type: String,
                default: 'f-12-asphalt'
            },
            entityId: {
                type: [String, Number],
                required: false
            },
            representativesEntity: {
                type: String,
                required: false
            },
            iconBtn: {
                type: String,
                default: 'fill-phone-icon'
            },
            isContact: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                open: false
            }
        },
        computed: {
            contacts() {
                return this.open ? this.items : [this.items[0]];
            },
            itemsLength() {
                return this.items.length;
            },
            button() {
                let count = this.itemsLength - 1;
                return `+ ${count} ${this.type + (count == 1? '' : 's')}`;
            },
            showCallNumberButton() {
                return !(this.$route.name === "CreateNewCommercialLead"
                    || this.$route.name === "CommercialCustomer"
                    || this.$route.name === "CommercialProperty"
                    || this.$route.name === "CommercialJob"
                    || this.$route.name === "Contacts"
                    || this.$route.name === "NewOpportunity"
                    || this.$route.name === "CreateNewWarranty"
                );
            },
            entityType() {
                if (this.representativesEntity) {
                    return this.representativesEntity;
                }
                switch(this.$route.name) {
                    case "ResidentialCalls": {
                        return ENTITY_TYPES.RESIDENTIAL_LEAD;
                    }
                    case "CommercialLeads":
                    case "CommercialLeadCalls":
                    case "CommercialLeadOverview": {
                        return ENTITY_TYPES.COMMERCIAL_LEAD;
                    }
                    case "CommercialJobList":
                    case "CommercialJobCalls":
                    case "CommercialJobOverview": {
                        return ENTITY_TYPES.COMMERCIAL_JOB;
                    }
                    case "CommercialPropertyList": {
                        return ENTITY_TYPES.COMMERCIAL_PROPERTY;
                    }
                    case "CommercialCustomerList": {
                        return ENTITY_TYPES.COMMERCIAL_CUSTOMER;
                    }
                    case "ContactMessages":
                    case "ContactCalls":
                    case "ContactDocuments": {
                        return ENTITY_TYPES.CONTACT;
                    }
                    case "CommercialDetails": {
                        return ENTITY_TYPES.COMMERCIAL_CUSTOMER;
                    }
                    case "Opportunities" : {
                        return ENTITY_TYPES.OPPORTUNITY
                    }
                }
            }
        }
    }
</script>
