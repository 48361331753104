<template>
    <full-modal @close="$emit('close')">
        <div slot="title">{{ titleToModal }}</div>
        <div slot="body">
            <div
                v-for="formItem in modalContent.warranty.fields"
                :key="formItem.id"
                class="mb-5"
            >
                <template v-if="formItem.type.name === CUSTOM_FIELDS_TYPES.SINGLE_LINE">
                    <p class="f-14-asphalt f-sbold pb-2">{{ formItem.label }}</p>
                    <form-input
                        :label="formItem.parameters.tooltip_text"
                        :class="[{ 'form-input-danger' : errors.has(nameForInput(formItem.label, formItem.id)) }]"
                    >
                        <input
                            type="text"
                            :name="nameForInput(formItem.label, formItem.id)"
                            :id="formItem.id"
                            class="form-input-field"
                            v-model="formData[formItem.id]"
                            :placeholder="formItem.parameters.tooltip_text"
                            v-validate="{required: formItem.parameters.required}"
                        />
                    </form-input>
                </template>
                <template v-else>
                    <p class="f-14-asphalt f-sbold pb-2">{{ formItem.label }}</p>
                    <multiselect
                        :options="arrForOptions(formItem)"
                        :track-by="getFieldForTrackBy(formItem)"
                        label="name"
                        :showLabels="false"
                        :max-height="200"
                        :multiple="formItem.type.name === CUSTOM_FIELDS_TYPES.MULTISELECT"
                        v-model="formData[formItem.id]"
                        :placeholder="formItem.parameters.tooltip_text"
                        :name="nameForInput(formItem.label, formItem.id)"
                        :data-name="nameForInput(formItem.label, formItem.id)"
                        v-validate="{required: formItem.parameters.required}"
                        @input="changeCntrl($event, formItem.key)"
                        :class="[{ 'multiselect-danger' : errors.has(nameForInput(formItem.label, formItem.id)) }]"
                    />
                </template>
            </div>

            <div class="mt-20" v-if="buildings">
                <div
                    v-for="(building, index) in buildings"
                    :key="index"
                >
                    <p class="f-16-asphalt f-sbold pb-2 mt-8">Building #{{ index + 1 }}</p>

                    <p class="f-14-asphalt f-sbold pb-2 mt-8">Building name</p>
                    <form-input
                        label="Building name"
                        :class="[{ 'form-input-danger' : errors.has(`building_name_${index}`) }]"
                    >
                        <input
                            type="text"
                            :name="`building_name_${index}`"
                            :id="building.name"
                            class="form-input-field"
                            v-model="building.name"
                            placeholder="Building name"
                            v-validate="{required: true}"
                        />
                    </form-input>

                    <p class="f-14-asphalt f-sbold pb-2 mt-8">Building address</p>
                    <address-box-one-line
                        type="address"
                        :key="'address'"
                        :name="`building_address_${index}`"
                        :existing="building.building_address"
                        placeholder="Building address"
                        @address="getBuildingAddress($event, building.id)"
                    />

                    <p class="f-14-asphalt f-sbold pb-2 mt-8">Building registration number</p>
                    <form-input
                        label="Building registration number"
                        :class="[{ 'form-input-danger' : errors.has(`building_registration_number_${index}`) }]"
                    >
                        <input
                            type="text"
                            :name="`building_registration_number_${index}`"
                            :id="building.registration_number"
                            class="form-input-field"
                            v-model="building.registration_number"
                            placeholder="Building address"
                            v-validate="{required: true}"
                        />
                    </form-input>
                </div>
            </div>
        </div>
        <div slot="footer">
            <button
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                @click="save"
                :disabled="loadingPost"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import {FullModal, FormInput} from '@/ui';
    import AddressBox from '@/components/AddressBox.vue';
    import AddressBoxOneLine from '@/components/AddressBoxOneLine';
    import {
        CUSTOM_FIELDS_TYPES,
        ENTITY_TYPES,
        BASE_ENTITY_TYPES,
        WARRANTY_FIELD_TYPE,
        ADDRESS_TYPES
    } from '@/constants';
    import {TrashIcon, AddIcon} from '@/icons';
    import Multiselect from 'vue-multiselect';
    import {mask} from 'vue-the-mask';
    import api from '@/headers.js';
    import { getFieldForTrackBy } from '@/helpers.js';
    
    export default {
        name: 'EditWarrantyModal',
        components: {
            FullModal,
            FormInput,
            AddressBox,
            TrashIcon,
            AddIcon,
            AddressBoxOneLine,
            Multiselect
        },
        directives: {
            mask
        },
        data: () => ({
            CUSTOM_FIELDS_TYPES,
            BASE_ENTITY_TYPES,
            ENTITY_TYPES,
            WARRANTY_FIELD_TYPE,
            formData: {},
            loadingPost: false,
            manufacturerTypeId: null,
            checkedManufacture: null,
            buildings: []
        }),
        props: {
            titleToModal: {
                type: String,
                default: 'Edit Warranty'
            },
            modalContent: {
                type: Object,
                required: true
            },
            entityType: {
                type: String,
            }
        },
        computed: {
            jobId() {
                switch (this.entityType) {
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.$route.params.job_id;
                    case ENTITY_TYPES.RESIDENTIAL_LEAD:
                        return this.$route.params.lead_id;
                }
            }
        },
        methods: {
            getBuildingAddress(data, id) {
                this.buildings.forEach(el => {
                    if (el.id === id) el.address = data.address;
                })
            },
            getFieldForTrackBy,
            changeCntrl(val, key){
                if (key === WARRANTY_FIELD_TYPE.MANUFACTURER && this.manufacturerTypeId){
                    this.formData[this.manufacturerTypeId] = '';
                    this.checkedManufacture = val ? val.id : null;
                }
            },
            arrForOptions(form) {
                if (form.key === WARRANTY_FIELD_TYPE.MANUFACTURER_TYPE){
                    return this.checkedManufacture
                        ? form.values.filter(el => el.manufacturer_id === this.checkedManufacture)
                        : [];
                }
                return form.values ? form.values.map((el) => {
                    return el.value ? {name: el.value} : el
                }) : [];
            },
            nameForInput(text, id) {
                return text
                    .split(' ')
                    .join('_')
                    .toLowerCase() + id;
            },
            save() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('All fields are required.');
                        return;
                    }
                    const data = this.formData;
                    const fields = [];
                    Object.keys(data).forEach(key => {
                        const el = data[key];
                        const field = {};
                        field.field_id = +key;
                        if (!el) {
                            field.value = null;
                        } else if (el.id) {
                            field.value = String(el.id);
                        } else if (el.name) {
                            field.value = el.name
                        } else {
                            field.value = el;
                        }
                        fields.push(field);
                    })

                    this.buildings.forEach(el => {
                        delete el.building_address;
                    });

                    const formData = {
                        types: this.modalContent.types.map(el => el.id),
                        buildings: this.buildings,
                        fields
                    };

                    this.loadingPost = true;
                    api.put(`/warranties/${this.modalContent.id}`, formData)
                        .then(() => {
                            this.$emit('updateData');
                        })
                        .catch(err => this.notifyRequestErrors(err))
                        .finally(() => this.loadingPost = false);
                })
            }
        },
        created() {
            this.modalContent.warranty.fields.forEach(el => {
                if (el.key === WARRANTY_FIELD_TYPE.MANUFACTURER_TYPE){
                    this.manufacturerTypeId = el.id;
                }
                if (el.key === WARRANTY_FIELD_TYPE.MANUFACTURER){
                    const checkedObj = el.values.find(elVal => elVal.name === el.value);
                    this.checkedManufacture = checkedObj ? checkedObj.id: null;
                }
                if (el.type.name === CUSTOM_FIELDS_TYPES.SINGLE_LINE) {
                    this.formData[el.id] = el.value;
                } else {
                    if (el.parameters.type) {
                        if (!el.value) {
                            this.formData[el.id] = ''
                        } else {
                            this.formData[el.id] = {
                                id: +el.parameters.value,
                                name: el.value
                            }
                        }
                    } else {
                        this.formData[el.id] = {name: el.value ? el.value : ''}
                    }
                }
            })

            this.modalContent.warranty.buildings.forEach(building => {
                this.buildings.push({
                    id: building.id,
                    name: building.name,
                    address: building.address,
                    registration_number: building.registration_number,
                    warranty_type_id: building.warranty_type_id,
                    building_address: {
                        address: building.address
                    }
                });
            })
        }
    }
</script>
