<template>
    <div class="card">
        <div class="pv-8 ph-20">
            <h2>Warranty action</h2>
        </div>
        <div class="card-body">
            <div class="w-100 relative">
                <div>
                    <button
                        v-if="isLeftBtn"
                        class="mr-2 navigation-button prev z-index-2"
                        @click="scrollMilestones(SCROLL_TO_LEFT)"
                    >
                        <arrow-icon
                            transform="180"
                            size="9"
                            class="i-grey"
                        />
                    </button>
                    <button
                        v-if="isRightBtn"
                        class="navigation-button next z-index-2"
                        @click="scrollMilestones(SCROLL_TO_RIGHT)"
                    >
                        <arrow-icon
                            transform="0"
                            size="9"
                            class="i-grey"
                        />
                    </button>
                </div>
                <div class="milestones-box warranty">
                    <div
                        @scroll="scrollBtnControl"
                        :class="[
                            'milestones',
                               { 'milestones-to-center' : isShowMilestoneCenter() }
                            ]"
                        id="milestones-list"
                        ref="milestones"
                    >
                        <router-link
                            class="milestone"
                            :class="caseItem.end_at ? 'milestone-done' : 'milestone-inprogress'"
                            v-for="(caseItem, idx) in warrantyCases"
                            :key="caseItem.id"
                            :id="`milestone-${idx}`"
                            :to="{
                                name:'WarrantyCaseCreate',
                                query: {
                                    case_id: caseItem.id,
                                    warranty_id: warrantyId,
                                     ...$route.params
                                }
                            }"
                        >
                            <div :class="[
                                    'milestone-content column-center t-center',
                                    caseItem.end_at ? 'milestone-done-container' : 'milestone-inprogress-container',
                                ]">
                                <div class="relative">
                                    <div
                                        v-if="caseItem.end_at "
                                        :style="{'background-color' : '#2ECC71'}"
                                        class="milestone-icon s-align-5"
                                    >
                                        <mark-icon
                                            class="i-white"
                                            size="12"
                                        />
                                    </div>
                                    <WatchProgressIcon
                                        v-else
                                        class="icon-progress s-align-5"
                                    />
                                </div>
                                <span class="milestone-title f-sbold mt-2">
                                   {{ caseItem.name }}
                                </span>
                                <span class="f-12-grey w-case-name">
                                    {{ caseItem.type ? caseItem.type.name : '-' }}
                                </span>
                                <div class="s-align-5 mt-2">
                                    <span :class="[
                                       'milestone-date', 'f-12-grey',
                                    ]">
                                        {{ caseItem.start_at | date }}
                                    </span>
                                </div>
                            </div>
                        </router-link>

                        <tooltip
                            v-if="countWarrantyTypes === 0 && entityType !== ENTITY_TYPES.WARRANTY"
                            direction="bottom"
                            mobileDescription="Field info"
                        >
                            <div
                                class="milestone case-add"
                                id="milestone-add"
                            >
                                <div :class="[
                                    'milestone-content column-center t-center',
                                    'milestone-await'
                                ]">
                                    <div class="add-warranty-icon disabled-warranty-icon">
                                        <AddIcon :size="18"/>
                                    </div>
                                    <span class="f-16-grey">Add</span>
                                </div>
                            </div>
                            <template v-slot:content>
                                <p class="f-16-white t-center">
                                    You need to create warranty type first
                                </p>
                            </template>
                        </tooltip>

                        <router-link
                            v-else
                            class="milestone case-add"
                            :to="redirectTo"
                            id="milestone-add"
                        >
                            <div :class="[
                                    'milestone-content column-center t-center',
                                    'milestone-inprogress-container'
                                ]">
                                <div class="add-warranty-icon">
                                    <AddIcon :size="18"/>
                                </div>
                                <span class="f-16-blue">Add</span>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {MarkIcon, EditIcon, ArrowIcon, AddIcon, WatchProgressIcon} from '@/icons';
    import {Tooltip} from '@/ui';
    import {getWidth} from '@/helpers';
    import {ENTITY_TYPES} from "@/constants";
    
    export default {
        name: 'WarrantyActions',
        components: {
            MarkIcon,
            EditIcon,
            ArrowIcon,
            AddIcon,
            WatchProgressIcon,
            Tooltip
        },
        data(){
            return {
                SCROLL_TO_RIGHT : 'right',
                SCROLL_TO_LEFT: 'left',
                isLeftBtn: false,
                isRightBtn: false,
                step: 0,
                actions: [],
                showMilestone: 6,
                ENTITY_TYPES
            }
        },
        props: {
            warrantyId: {
                type: Number,
                required: true
            },
            entityType: {
                type: String,
                default: null
            },
            warrantyCases: {
                type: Array,
                default: []
            },
            countWarrantyTypes: {
                type: Number,
                default: 0
            }
        },
        computed: {
            redirectTo() {
                return {
                    name: 'WarrantyCaseCreate',
                    query: {
                        ...this.$route.params,
                        case_number: this.warrantyCases.length + 1,
                        warranty_id: this.warrantyId
                    }
                };
            },
        },
        methods: {
            scrollBtnControl(e){
                const scrollLeft = e.target.scrollLeft;
                const clientWidth = e.target.clientWidth;
                const scrollWidth = e.target.scrollWidth;
                this.isLeftBtn = scrollLeft > 0;
                this.isRightBtn = scrollLeft + clientWidth !== scrollWidth;
            },
            isShowMilestoneCenter() {
                return this.warrantyCases.length < this.showMilestone
                    && this.dScreen
            },
            scrollMilestones(duration) {
                const milestonesEl = this.$el.querySelector('.milestones');
                const scrollWidth = milestonesEl.scrollWidth;
                const clientWidth = milestonesEl.clientWidth;
                const scrollLeft = milestonesEl.scrollLeft;
                const milestone = getWidth(this.$el.querySelector('.milestone'));
                
                if (duration === this.SCROLL_TO_RIGHT){
                    if (scrollLeft + clientWidth === scrollWidth){
                        this.isRightBtn = false;
                    }else{
                        this.scrollHandler(scrollLeft + (milestone + 20));
                    }
                }else if (duration === this.SCROLL_TO_LEFT){
                    if (scrollLeft > 0){
                        this.scrollHandler(scrollLeft - (milestone + 20));
                    }else{
                        this.isLeftBtn = false;
                    }
                }else{
                    this.scrollHandler(scrollWidth - milestone);
                    this.isLeftBtn = scrollWidth > clientWidth;
                }
            },
            scrollHandler(left){
                this.$refs.milestones.scrollTo({
                    left,
                    behavior: "smooth"
                });
            }
        },
        mounted() {
            this.scrollMilestones(true);
        }
    }
</script>
