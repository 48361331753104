<template>
    <svg 
        viewBox="0 0 16 16"
        class="round-question-icon"
        :width="size"
        :height="size"
    >
        <path d="M7.1 9.5v-.3c0-.4.1-.7.2-1s.4-.5.8-.8c.4-.4.7-.6.8-.8.1-.2.2-.4.2-.7 0-.3-.1-.5-.3-.7-.2-.1-.5-.2-.9-.2-.3 0-.6 0-.9.1s-.6.3-.9.4l-.4-1c.7-.4 1.5-.6 2.3-.6.7 0 1.3.2 1.7.5.4.3.6.8.6 1.4 0 .3 0 .5-.1.7-.1.3-.2.5-.4.7s-.4.4-.8.7l-.7.7c-.1.2-.1.4-.1.7v.2H7.1zm-.2 1.7c0-.5.3-.8.8-.8.3 0 .5.1.6.2s.2.3.2.6-.1.5-.2.6c-.1.1-.3.2-.6.2s-.5 0-.6-.2c-.1-.1-.2-.3-.2-.6z"/>
        <path d="M8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zM8 1C4.1 1 1 4.1 1 8s3.1 7 7 7 7-3.1 7-7-3.1-7-7-7z"/>
    </svg>
</template>

<script>
    export default {
        name: 'RoundQuestionIcon',
        props: {
            size: {
                type: [String, Number],
                default: 16
            }
        }
    }
</script>