<template>
    <full-modal @close="$emit('close')">
        <div slot="title">Address settings</div>
        <div slot="body">
            <div class="column-start">
                <p class="f-14-darkgrey f-sbold mb-3">First address label</p>
                <form-input 
                    label="Label"
                    :class="[
                        'mb-25',
                        {'form-input-danger': errors.first('first_address_label')}
                    ]"
                >
                    <input 
                        class="form-input-field"
                        name="first_address_label"
                        type="text"
                        placeholder="Label"
                        v-model="firstAddressData.label"
                        v-input-style="firstAddressData.label"
                        :maxlength="31"
                        v-validate="'required|min:3|max:30'"
                    >
                </form-input>
                <checkbox-button 
                    class="w-100 mb-8"
                    label="Add unit number"
                    color="dark-blue"
                >
                    <input 
                        name="add_unit_number"
                        v-model="firstAddressParameters.add_unit_number"
                        type="checkbox"
                    />
                </checkbox-button>
                <checkbox-button 
                    class="w-100 mb-8"
                    label="Required"
                    color="dark-blue"
                >
                    <input 
                        name="required"
                        v-model="firstAddressParameters.required"
                        type="checkbox"
                    />
                </checkbox-button>
                <checkbox-button 
                    class="w-100"
                    label="Show tooltip"
                    color="dark-blue"
                >
                    <input 
                        name="show_tooltip"
                        v-model="firstAddressParameters.show_tooltip"
                        type="checkbox"
                    />
                </checkbox-button>
                <div 
                    v-if="firstAddressParameters.show_tooltip"
                    class="row-start mt-6"
                >
                    <form-input 
                        label="Enter help text here"
                        :class="[{'form-input-danger': errors.first('first_address_tooltip_text')}]"
                    >
                        <input 
                            class="form-input-field"
                            name="first_address_tooltip_text"
                            type="text"
                            placeholder="Enter help text here"
                            v-model="firstAddressParameters.tooltip_text"
                            v-input-style="firstAddressParameters.tooltip_text"
                            maxlength="100"
                            v-validate="'required|min:3|max:99'"
                        >
                    </form-input>
                </div>
                <div class="row-between mt-40 mb-20">
                    <p class="f-14-darkgrey f-sbold mb-3">Add other address</p>
                    <toggle-switch>
                        <input 
                            type="checkbox"
                            name="add_other_address"
                            v-model="firstAddressParameters.add_other_address"
                        />
                    </toggle-switch>
                </div>
                <template v-if="firstAddressParameters.add_other_address">
                    <form-input 
                        label="Label"
                        :class="[
                            'mb-25',
                            {'form-input-danger': errors.first('second_address_label')}
                        ]"
                    >
                        <input 
                            class="form-input-field"
                            name="second_address_label"
                            type="text"
                            placeholder="Label"
                            v-model="secondAddressData.label"
                            v-input-style="secondAddressData.label"
                            :maxlength="31"
                            v-validate="'required|min:3|max:30'"
                        >
                    </form-input>
                    <checkbox-button 
                        class="w-100 mb-8"
                        label="Add unit number"
                        color="dark-blue"
                    >
                        <input 
                            name="add_unit_number"
                            v-model="secondAddressParameters.add_unit_number"
                            type="checkbox"
                        />
                    </checkbox-button>
                    <checkbox-button 
                        class="w-100 mb-8"
                        label="Required"
                        color="dark-blue"
                    >
                        <input 
                            name="required"
                            v-model="secondAddressParameters.required"
                            type="checkbox"
                        />
                    </checkbox-button>
                    <checkbox-button 
                        class="w-100"
                        label="Show tooltip"
                        color="dark-blue"
                    >
                        <input 
                            name="show_tooltip"
                            v-model="secondAddressParameters.show_tooltip"
                            type="checkbox"
                        />
                    </checkbox-button>
                    <div 
                        v-if="secondAddressParameters.show_tooltip"
                        class="row-start mt-6"
                    >
                        <form-input 
                            label="Enter help text here"
                            :class="[{'form-input-danger': errors.first('second_address_tooltip_text')}]"
                        >
                            <input 
                                class="form-input-field"
                                name="second_address_tooltip_text"
                                type="text"
                                placeholder="Enter help text here"
                                v-model="secondAddressParameters.tooltip_text"
                                v-input-style="secondAddressParameters.tooltip_text"
                                maxlength="100"
                                v-validate="'required|min:3|max:99'"
                            >
                        </form-input>
                    </div>
                </template>
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button mr-4"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                @click="updateSection"
                :disabled="requestIsGoing"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import api from '@/headers.js';
    import { FormInput, CheckboxButton, ToggleSwitch } from '@/ui';
    import FullModal from '@/ui/Modal/FullModal.vue';

    export default {
        name: 'CustomFieldsAddressModal',
        components: {
            FormInput, CheckboxButton, FullModal, ToggleSwitch
        },
        props: {
            editingSection: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                firstAddressData: {
                    label: '',
                    id: null
                },
                firstAddressParameters: {
                    add_unit_number: false,
                    required: false,
                    show_tooltip: false,
                    tooltip_text: '',
                    add_other_address: false
                },
                secondAddressData: {
                    label: '',
                    id: null
                },
                secondAddressParameters: {
                    add_unit_number: false,
                    required: false,
                    show_tooltip: false,
                    tooltip_text: ''
                },
                requestIsGoing: false
            }
        },
        created() {
            // array always has 2 values
            this.firstAddressData.label = this.editingSection[0].label;
            this.firstAddressData.id = this.editingSection[0].id;
            this.secondAddressData.label = this.editingSection[1].label;
            this.secondAddressData.id = this.editingSection[1].id;

            if (this.editingSection[0].parameters) {
                this.firstAddressParameters = JSON.parse(this.editingSection[0].parameters);
            }

            if (this.editingSection[1].parameters) {
                this.secondAddressParameters = JSON.parse(this.editingSection[1].parameters);
            }
        },
        methods: {
            updateSection() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('Please fill out all required fields!');

                        return;
                    }

                    const firstAddressParams =  { 
                        ...this.firstAddressData,
                        parameters: JSON.stringify(this.firstAddressParameters)
                    };
                    const secondAddressParams =  { 
                        ...this.secondAddressData,
                        parameters: JSON.stringify(this.secondAddressParameters)
                    };

                    this.requestIsGoing = true;
                    Promise.all([
                        api.put('/layouts/custom-fields', firstAddressParams),
                        api.put('/layouts/custom-fields', secondAddressParams)
                    ]).then(response => {
                        this.notifySuccess('Address field has been updated.');
                        
                        this.$emit('updateSection', [
                            response[0].data.data,
                            response[1].data.data
                        ]);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {                                
                        this.requestIsGoing = false;
                    });
                });
            }
        }
    }
</script>

