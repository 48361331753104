<template>
    <div class="w-100 client-call-info h-100 bg-white">
        <loader
            v-if="loading"
        />
        <template v-else-if="!loading && Object.keys(callInfo).length">
            <div class="client-call-info__card first-block">
                <div class="first-block__header">
                    <p class="f-11-grey row-baseline">
                        <ClockIcon :size="12" class="mr-2 grey-icon"/>
                        {{ daysAgo }}
                    </p>
                    <p :class="!callInfo.contact_name ? 'f-20-grey f-italic' : 'f-20-black'">
                        {{ callInfo.contact_name || no_info }}
                    </p>
                </div>
                <div class="first-block__address pt-20">
                    <p class="f-12-grey pb-10">
                        Address:
                        <a
                            v-if="address"
                            :href="getGoogleMapLink(callInfo.entity.address.lat, callInfo.entity.address.lng)"
                            :class="[
                                'f-12-blue',
                                {'none-events': !getGoogleMapLink(callInfo.entity.address.lat, callInfo.entity.address.lng)}
                            ]"
                            target="_blank"
                        >
                            {{ address }}
                            <link-icon 
                                v-if="getGoogleMapLink(callInfo.entity.address.lat, callInfo.entity.address.lng)"
                                class="i-blue"
                            />
                        </a>
                        <span
                            v-else
                            class="f-12-grey f-italic"
                        >
                           {{ no_info }}
                        </span>
                    </p>
                    <google-map
                        :coords="{ lat: +callInfo.entity.address.lat, lng: +callInfo.entity.address.lng }"
                        :zoom="14"
                        v-if="callInfo.entity.address && callInfo.entity.address.lat && callInfo.entity.address.lng"
                    />
                    <span
                        class="f-12-grey f-sbold opacity-8"
                        v-else
                    >
                            Sorry, map is not available now
                    </span>
                </div>
            </div>
            <div class="client-call-info__card">
                <p class="f-14-black f-sbold">Information:</p>
                <div class="row-between">
                    <div class="lg-6 column-start">
                        <p class="f-12-grey">
                            Salesman:
                            <span :class="salesman? 'f-12-blue' : 'f-12-grey f-italic'">
                                {{ salesman || no_info }}
                            </span>
                        </p>
                        <p
                            class="f-12-grey"
                            v-if="is_job_number"
                        >
                            Job number:
                            <router-link
                                v-if="jobNumber && path"
                                :to="{
                                    name: path.name,
                                    params: path.params
                                }"
                                class="f-12-blue"
                            >
                                {{ jobNumber }}
                            </router-link>
                            <span
                                v-else
                                class="f-12-grey f-italic"
                            >
                                {{ no_info }}
                            </span>
                        </p>
                        <div class="row-baseline">
                            <p class="f-12-grey mr-2">{{ titlePhone }}:</p>
                            <contact-list
                                v-if="callInfo.caller_number_bare"
                                :items="[callInfo.caller_number_bare]"
                                :entityId="+callInfo.call_id"
                                :text-class="'f-12-blue'"
                                :icon-btn="'call-btn'"
                                :isContact="true"
                                type="phone"
                                alignClass="column-center"
                            />
                            <span v-else class="f-normal f-12-grey f-italic">
                                {{ no_info }}
                            </span>
                        </div>
                        <div class="row-baseline">
                            <p class="f-12-grey mr-2">Customer number:</p>
                            <contact-list
                                v-if="callInfo.tracking_number_bare"
                                :items="[callInfo.tracking_number_bare]"
                                :entityId="+callInfo.call_id"
                                :text-class="'f-12-blue'"
                                :icon-btn="'call-btn'"
                                :isContact="true"
                                type="phone"
                                alignClass="column-center"
                            />
                            <span v-else class="f-normal f-12-grey f-italic">
                                {{ no_info }}
                            </span>
                        </div>
                    </div>
                    <div class="lg-6 column-start">
                        <p class="f-12-grey">
                            Call duration:
                            <span :class="callDuration ? 'f-12-black' : 'f-12-grey f-italic'">
                                {{ callDuration || no_info }}
                            </span>
                        </p>
                        <p class="f-12-grey">
                            Date and time:
                            <span
                                v-if="unixTime"
                                class="f-12-black"
                            >
                                {{ unixTime | date }} at {{ unixTime | time }}
                            </span>
                            <span
                                v-else
                                class="f-12-grey f-italic"
                            >
                                {{ no_info }}
                            </span>
                        </p>
                        <p class="f-12-grey">
                            Status:
                            <span :class="callInfo.direction ? 'f-12-black' : 'f-12-grey f-italic'">
                                {{ callInfo.direction || no_info }}
                            </span>
                        </p>
                        <p
                            class="f-12-grey"
                            v-if="is_property"
                        >
                            Property:
                            <span :class="property? 'f-12-back' : 'f-12-grey f-italic'">
                                {{ property || no_info }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div
                class="client-call-info__card w-60"
                v-if="callInfo.audio"
            >
                <p class="f-14-black f-sbold">Recording:</p>
                <AudioPlayer
                    :value="callInfo.audio"
                    :key-audio="callInfo.id"
                    :is-download="true"
                />
            </div>
            <div class="client-call-info__card">
                <p class="f-14-black f-sbold">Notes:</p>
                <p
                    :class="!callInfo.notes ? 'f-12-grey f-italic' : 'f-12-black'"
                >
                    {{ callInfo.notes || no_info }}
                </p>
            </div>
        </template>
        <div
            v-else
            class="w-100 s-align-4 column-start pt-20 bg-white h-100"
        >
            <no-messages size="100"/>
            <div class="f-16-darkgrey f-sbold">This has no details.</div>
        </div>
    </div>
</template>

<script>
    import api from '@/headers.js';
    import {NoMessages, ClockIcon, LinkIcon} from '@/icons';
    import {Loader} from '@/ui';
    import AudioPlayer from "../../ui/AudioPlayer";
    import moment from "moment";
    import {showFullAddress, getGoogleMapLink} from '@/helpers';
    import {GoogleMap} from '@/GoogleApi';
    import ContactList from '@/components/CommercialJobs/ContactList.vue';
    import {ENTITY_TYPES} from '@/constants';
    
    export default {
        name: 'CallClientInfo',
        components: {
            NoMessages,
            Loader,
            AudioPlayer,
            ClockIcon,
            LinkIcon,
            GoogleMap,
            ContactList
        },
        data() {
            return {
                loading: false,
                callInfo: {test: 1},
                no_info: 'No information',
                ENTITY_TYPES
            }
        },
        props: {
            activeId: {
                type: String | Number,
                required: true
            },
            titlePhone: {
                type: String,
                default: 'POC Phone'
            },
            is_property: {
                type: Boolean,
                default: true
            },
            is_job_number: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            path() {
                const data = this.callInfo;
                const path = {};
                switch (data.entity_type) {
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        path.name = 'CommercialJobDetails';
                        path.params = {
                            property_id: data.entity.property.id,
                            job_id: data.entity.id,
                        };
                        break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        path.name = 'CommercialLeadDetails';
                        path.params = {
                            lead_id: data.entity.id,
                        };
                        break;
                    case ENTITY_TYPES.RESIDENTIAL_LEAD:
                    case ENTITY_TYPES.RESIDENTIAL_JOB:
                        path.name = 'ResidentialJobDetails';
                        path.params = {
                            lead_id: data.entity.id,
                        };
                }
                if (!data.entity.id){
                    return null;
                }
                return path.name ? path : null;
            },
            property() {
                const property = this.callInfo.entity.property;
                if (property) {
                    return property.name;
                }
            },
            jobNumber() {
                const entityType = this.callInfo.entity_type;
                let jobNumber = this.callInfo.entity.job_number || null;
                const isNoJobNumber = entityType === ENTITY_TYPES.COMMERCIAL_LEAD
                    || entityType === ENTITY_TYPES.RESIDENTIAL_LEAD
                    || entityType === ENTITY_TYPES.RESIDENTIAL_JOB;
    
                if (isNoJobNumber){
                    jobNumber = '-'
                }
                return jobNumber
            },
            callDuration() {
                const time = this.callInfo.duration;
                if (time) {
                    return moment.utc(time * 1000).format('HH:mm:ss')
                }
                return this.no_info;
            },
            salesman() {
                const salesmanObj = this.callInfo.entity.salesman || null;
                if (salesmanObj) {
                    return `${salesmanObj.first_name || ''} ${salesmanObj.last_name || ''}`
                }
                return null;
            },
            address() {
                const addressObj = this.callInfo.entity.address;
                if (addressObj) {
                    return showFullAddress(addressObj);
                }
            },
            unixTime() {
                if (this.callInfo.unix_time) {
                    return moment.unix(this.callInfo.unix_time);
                }
                return '';
            },
            daysAgo() {
                if (this.unixTime) {
                    const calledDate = this.unixTime;
                    return calledDate.fromNow();
                }
                return this.no_info;
            },
        },
        methods: {
            // Method imported from helpers.js
            getGoogleMapLink,
            getCallInfo() {
                this.loading = true;
                api.get(`/telephony/calls/${this.activeId}`)
                    .then(res => {
                        this.callInfo = res.data.data;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            }
        },
        created() {
            this.getCallInfo();
        }
    }
</script>
