import { 
    STORE_REPORT_DATA,
    STORE_REPORT_ID,
    STORE_REPORT_TYPE,
    STORE_DASHBOARD_REPORT_STATUS
} from '../actions/reports';

export default {
    namespaced: true,
    state: {
        report: null,
        reportId: null,
        reportForDashboard: false,
        reportStatus: true
    },
    getters: {
        getReportData: state => state.report,
        getReportId: state => state.reportId,
        isDashboardReport: state => state.reportForDashboard,
        getDashboardReportStatus: state => state.reportStatus
    },
    mutations: {
        [STORE_REPORT_DATA] (state, payload) {
            state.report = payload;
        },
        [STORE_REPORT_ID] (state, payload) {
            state.reportId = payload;
        },
        [STORE_REPORT_TYPE] (state, payload) {
            state.reportForDashboard = payload;
        },
        [STORE_DASHBOARD_REPORT_STATUS] (state, payload) {
            state.reportStatus = payload;
        }
    }
}