<template>
    <svg
	    viewBox="0 0 123.6 126.2"
        :width="size" 
        :height="size"
        class="rotate-icon"
    >
        <path d="M111.3,118.9V47.5H35.9v71.4H111.3z M43.8,111V55.5h59.5V111H43.8z"/>
        <path d="M45.1,2.1c-1.5,1.5-1.5,4.1,0,5.6l9.1,9.1H36c-15.3,0-27.8,12.5-27.8,27.8v11.9c0,2.2,1.8,4,4,4s4-1.8,4-4V44.6 c0-10.9,8.9-19.8,19.8-19.8h18.2l-9.1,9.1c-1.5,1.5-1.5,4.1,0,5.6s4.1,1.5,5.6,0l15.9-15.9c0.8-0.8,1.2-1.8,1.2-2.8s-0.4-2-1.2-2.8 L50.7,2.1C49.1,0.5,46.6,0.5,45.1,2.1z"/>
    </svg>
</template>

<script>
    export default {
        name: 'RotateIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>
