<template>
    <svg
        viewBox="0 0 90 70"
        :width="size"
        :height="size"
        class="pictures-list-empty"
    >
        <g>
            <path
                fill="#2B92F9"
                d="M38.7,23l-0.2-1c-0.3-1.4-1.5-2.3-2.9-2.3l-13.2,0c-1.7,0-3,1.3-3,3l0.1,32c0,1.1,0.9,2,2.1,2l47.1,0c1.1,0,2-0.9,2-2L70.7,27c0-1.1-0.9-2-2.1-2l-27.3,0C40.1,25,39,24.1,38.7,23z"
            />
            <g>
                <g>
                    <rect
                        x="28.3"
                        y="4.1"
                        fill="#FFFFFF"
                        width="37.3"
                        height="33.9"
                    />
                </g>
                <path
                    fill="#CED0D3"
                    d="M86.7,66.9h-3.3c-1.8,0-3.3-1.5-3.3-3.3v-3c0-1.8,1.5-3.3,3.3-3.3h3.3c1.8,0,3.3,1.5,3.3,3.3v3C90,65.5,88.5,66.9,86.7,66.9z"
                />
                <path
                    fill="#006BFD"
                    d="M7.6,5.2H5.8C4.8,5.2,4,4.4,4,3.4V1.8C4,0.8,4.8,0,5.8,0h1.8c1,0,1.8,0.8,1.8,1.8v1.6C9.4,4.4,8.6,5.2,7.6,5.2z"
                />
                <g>
                    <path
                        fill="#BCD6F9"
                        d="M56.9,9.9c-1.9-0.1-3.6,1.3-3.7,3.2c0,0.1,0,0.1,0,0.1c-0.1,1.9,1.4,3.6,3.3,3.7l0,0c1.9,0.1,3.6-1.3,3.7-3.2c0-0.1,0-0.1,0-0.1C60.3,11.6,58.8,10,56.9,9.9L56.9,9.9z"
                    />
                    <path
                        fill="#BCD6F9"
                        d="M54.3,21c-2.1,0-4,1.1-6.2,2.4c-2.2,1.4-4.4,3-7.1,4.1c-2.8,1.1-5.9,1.2-8.8,0.4l0.2,10.7L61.8,38l-0.2-13.5C58.6,21.8,56.3,21,54.3,21z"
                    />
                    <path
                        fill="#BCD6F9"
                        d="M39.9,21.6c-2.1,0.1-4.4,1.1-7.4,4.2l-0.1,0c2.6,0.9,5.3,0.8,7.8-0.2c1.6-0.7,3.1-1.5,4.6-2.5C43.1,22.2,41.5,21.5,39.9,21.6z"
                    />
                </g>
            </g>
            <g>
                <path
                    fill="#006BFD"
                    d="M42.8,24l-0.1-0.9c-0.2-1.3-1.3-2.2-2.7-2.2l-13.1,0c-1.7,0-3.1,1.3-3.2,2.9l-2.8,31c-0.1,1.1,0.7,2,1.9,2l47.1,0c1.1,0,2.1-0.9,2.2-2l2.5-26.8c0.1-1.1-0.7-2-1.9-2l-27.4,0C44,26,43,25.2,42.8,24z"
                />
                <path
                    fill="#006BFD"
                    opacity="0.3"
                    d="M42.8,24l-0.1-0.9c-0.2-1.3-1.3-2.2-2.7-2.2l-13.1,0c-1.7,0-3.1,1.3-3.2,2.9l-2.8,31c-0.1,1.1,0.7,2,1.9,2l47.1,0c1.1,0,2.1-0.9,2.2-2l2.5-26.8c0.1-1.1-0.7-2-1.9-2l-27.4,0C44,26,43,25.2,42.8,24z"
                />
            </g>
            <g>
                <polygon
                    fill="#FFFFFF"
                    points="13.7,70 41.6,59.7 27.8,22.8 0,33.1"
                />
                <path
                    fill="#BCD6F9"
                    d="M13.3,39.4c-1.5,0.5-2.4,2.1-1.9,3.6c0,0.1,0,0.1,0,0.1c0.5,1.6,2.2,2.4,3.7,1.9l0,0c1.5-0.5,2.4-2.1,1.9-3.6c0-0.1,0-0.1,0-0.1C16.6,39.7,14.9,38.9,13.3,39.4L13.3,39.4z"
                />
                <path
                    fill="#BCD6F9"
                    d="M15.1,50.2c1.7-0.6,3.5-0.4,5.7,0c2.1,0.4,4.4,1,6.9,1.1c2.6,0,5-0.8,7.1-2.4l3.2,8.5L14.4,66l-4-10.7C12,52.1,13.5,50.8,15.1,50.2z"
                />
                <path
                    fill="#BCD6F9"
                    d="M26.7,46.2c1.7-0.6,3.8-0.5,7.2,1l0.1,0c-1.8,1.5-4,2.3-6.3,2.2c-1.5,0-2.9-0.2-4.4-0.6C24.4,47.7,25.4,46.7,26.7,46.2z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'PicturesListEmpty',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>
