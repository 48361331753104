import api from '@/headers.js';

import { 
    SET_LOADING,
    ATTACH_PERMISSION_TO_ROLE,
    DETACH_PERMISSION_FROM_ROLE,
    SET_GROUPS,
    SET_ROLES,
    GET_GROUPS,
    GET_ROLES,
    RESET_PERMITIONS_TO_ROLE
} from '../actions/permissions';

export default {
    namespaced: true,
    state: {
        loading: false,
        permissionGroups: [],
        roles: []
    },
    mutations: {
        [SET_LOADING] (state, payload) {
            state.loading = payload;
        },
        [SET_GROUPS] (state, payload) {
            state.permissionGroups = payload;
        },
        [SET_ROLES] (state, payload) {
            state.roles = payload;
        },
        [RESET_PERMITIONS_TO_ROLE] (state, payload) {
            let { roleId } = payload;
            let role = state.roles.find(role => {
                return role.id === roleId;
            })
            role.permissions = payload.permissions;
        }
    },
    getters: {
        isLoading(state) {
            return state.loading;
        },
        getGroupNames(state) {
            let groupNames = [];
            state.permissionGroups.forEach(el => {
                if (el.permissions.length) {
                    groupNames.push(el.name);
                }
            });
            return groupNames;
        },
        groupsWithPermissions(state) {
            return state.permissionGroups.filter(el => {
                if (el.permissions.length) {
                    return el;
                }
            })
        },
        getRoles(state) {
            return state.roles;
        },
        getRolesNames(state) {
            return state.roles.map(el => {
                return el.name;
            })
        }
    },
    actions: {
        [GET_GROUPS] ({ commit }) {
            let params = {
                "select": "id,name",
                "with": "permissions[id|name|permission_group_id]"
            };
            commit(SET_LOADING, true);
            return new Promise((resolve, reject) => {
                api.get('/permission-groups', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        commit(SET_GROUPS, data);
                        resolve(data);
                    })
                    .catch(error => reject(error))
                    .finally(() => commit(SET_LOADING, false));
            })
        },
        [GET_ROLES] ({ commit }) {
            let params = {
                "select": "id,name",
                "with": "permissions[id|name]"
            };
            commit('SET_LOADING', true);
            return new Promise((resolve, reject) => {
                api.get('/roles', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        commit(SET_ROLES, data);
                        resolve(data);
                    })
                    .catch(error => reject(error))
                    .finally(() => commit(SET_LOADING, false));
            })
        },
        [ATTACH_PERMISSION_TO_ROLE] ({ commit }, params) {
            return new Promise((resolve, reject) => {
                api.post(`/roles/${params.roleId}/permissions`, {permissions: [params.permissionId]})
                    .then(response => response.data.data)
                    .then(data => {
                        commit(RESET_PERMITIONS_TO_ROLE, { roleId: data.id, permissions: data.permissions });
                        resolve(data);
                    })
                    .catch(error => reject(error));
            })
        },
        [DETACH_PERMISSION_FROM_ROLE] ({ commit }, params) {
            return new Promise((resolve, reject) => {
                // TODO create query halper users=[1, 2] convert to ?users[]=1&users[]=2
                const query = `?permissions[]=${params.permissionId}`;
                api.delete(`/roles/${params.roleId}/permissions${query}`)
                    .then(response => response.data.data)
                    .then(data => {
                        commit(RESET_PERMITIONS_TO_ROLE, { roleId: data.id, permissions: data.permissions });
                        resolve(data);
                    })
                    .catch(error => reject(error));
            })
        }
    }
}