<template>
    <div class="modal s-align-5">
        <div class="add-new-section-modal crew-modal bg-white w-95 column-static">
            <div class="modal-crew-content w-100 relative">
                <div
                    class="w-100"
                    v-if="loading"
                >
                    <loader/>
                </div>
                <div
                    :class="[
                        'row-between pv-30 flex-no-wrap',
                        mScreen ? 'ph-20' : 'ph-30'
                    ]"
                    v-if="!loading"
                >
                    <div class="column-start">
                        <div :class="['f-24-asphalt f-sbold', { 'w-100': mScreen }]">
                            Material Order Details
                        </div>
                        <div class="f-13-darkgrey">
                            <span class="f-13-grey mr-2">Client Job details:</span>
                            <span
                                v-if="calendarType == CALENDAR_TYPES.CREW && order.entity"
                                class="f-13-blue"
                            >
                                <template v-if="order.entity.job_number">
                                    {{ order.entity.job_number }}
                                </template>
                                <template v-else>
                                    {{ order.entity.first_name }}
                                    {{ order.entity.last_name }}
                                </template>
                            </span>
                            <span
                                v-if="calendarType == CALENDAR_TYPES.JOB"
                                class="f-13-blue"
                            >
                                {{ crew.job_number }}
                            </span>
                        </div>
                    </div>
                    <button
                        class="transparent-button small-button mt-sm-1"
                        @click="$emit('close')"
                    >
                        <close-icon
                            class="i-grey"
                            size="15"
                        />
                    </button>
                </div>
            </div>
            <div
                v-if="!loading"
                class="column-start ph-30"
            >
                <div class="bt-grey w-100 pv-8">
                    <div class="row-between pointer">
                        <div
                            class="s-align-4"
                            @click="deliveryIsOpen = !deliveryIsOpen"
                        >
                            <arrow-icon
                                class="i-grey mr-4"
                                :transform="deliveryIsOpen ? 270 : 90"
                            />
                            <div class="f-14-darkgrey f-sbold">Delivery</div>
                        </div>
                    </div>
                    <div
                        v-if="deliveryIsOpen"
                        class="mt-8 column-start s-align-1 w-100"
                    >
                        <p class="f-14-grey mb-4">
                            Date:
                            <span class="f-14-black">
                                {{ order.delivery_date | date }}
                            </span>
                        </p>
                        <p class="f-14-grey mb-4">
                            Time:
                            <span class="f-14-black">
                                {{ order.delivery_time }}
                            </span>
                        </p>
                        <p class="f-14-grey mb-4">
                            Method:
                            <span class="f-14-black">
                                {{
                                    order.receiving === 'Pickup'
                                        ? 'Pickup'
                                        : order.delivery_method
                                }}
                            </span>
                        </p>
                        <p
                            v-if="selectedVendor"
                            class="f-14-grey mb-4"
                        >
                            Vendor:
                            <span class="f-14-black">{{ selectedVendor }}</span>
                        </p>
                    </div>
                </div>
                <div class="bt-grey w-100 pv-8">
                    <div
                        v-if="order.has_material"
                        class="row-between pointer"
                    >
                        <div
                            class="s-align-4"
                            @click="laborContactsOpen = !laborContactsOpen"
                        >
                            <arrow-icon
                                class="i-grey mr-4"
                                :transform="laborContactsOpen ? 270 : 90"
                            />
                            <div class="f-14-darkgrey f-sbold">Customer</div>
                        </div>
                    </div>
                    <div
                        v-if="laborContactsOpen"
                        class="mt-8 column-start s-align-1 w-100"
                    >
                        <p class="f-14-grey mb-4">
                            Name:
                            <span class="f-14-black">
                                {{ getNameCustomer() }}
                            </span>
                        </p>
                        <p
                            v-if="order.entity"
                            class="f-14-grey mb-4"
                        >
                            Trade:
                            <span class="f-14-black">
                                {{ order.trade.name }}
                            </span>
                        </p>
                        <p
                            v-if="order.entity"
                            class="f-14-grey mb-4"
                        >
                            Address:
                            <span class="f-14-blue">
                                {{ getAddress(order.entity) }}
                            </span>
                        </p>
                    </div>
                </div>
                <div
                    class="bt-grey w-100 pv-8"
                    v-if="order.quality_controls && order.quality_controls.length"
                >
                    <div class="row-between pointer">
                        <div
                            class="s-align-4"
                            @click="qcIsOpen = !qcIsOpen"
                        >
                            <arrow-icon
                                class="i-grey mr-4"
                                :transform="qcIsOpen ? 270 : 90"
                            />
                            <div class="f-14-darkgrey f-sbold">Qality Controls</div>
                        </div>
                    </div>
                    <div
                        v-if="qcIsOpen"
                        class="row-start s-align-5 pv-8"
                    >
                        <div class="column-start lg-4">
                            <template v-for="qc in order.quality_controls">
                                <p class="mb-1 w-100">
                                    <span class="f-14-darkgrey mr-4">
                                        {{ qc.fullname }}
                                    </span>
                                </p>
                            </template>
                        </div>
                        <div
                            class="lg-8 s-align-4"
                            v-if="order.appointment_at"
                        >
                            <calendar-icon
                                size="14"
                                class="i-blue mr-4"
                            />
                            <span class="f-14-black">
                                {{ order.appointment_at | date }}
                                {{ order.appointment_at | time }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="bt-grey w-100 pv-8">
                    <div class="row-between pointer">
                        <div class="column-start">
                            <div class="f-24-darkgrey f-sbold">
                                {{ materialsTotalOrder() | currency }}
                            </div>
                            <div class="f-13-grey">Material order cost</div>
                        </div>
                        <button
                            :class="disabledRedirectButton ? 'filter-button' : 'edit-button small-edit-button'"
                            :disabled="disabledRedirectButton"
                            @click="redirectToEditPage"
                        >
                            <edit-icon
                                size="11"
                                class="i-blue mr-2"
                            />
                            Edit order
                        </button>
                    </div>
                    <div class="row-start s-align-4">
                        <div
                            v-if="order.has_material"
                            class="mt-8 column-start s-align-1 w-100"
                        >
                            <p class="f-14-grey mb-4">
                                Labor order:
                                <span
                                    class="pointer f-14-blue"
                                    @click="$emit('openLabor')"
                                >
                                    View Labor Ticket
                                    <arrow-icon
                                        class="i-blue"
                                        :transform="360"
                                    />
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="!loading && materials.length"
                :class="[
                    'w-100 pt-8 bt-grey bg-grey-2 pb-20',
                    mScreen ? 'ph-20' : 'ph-30'
                ]"
            >
                <div
                    v-for="(el, index) in materials"
                    :key="index"
                    :class="[
                        'row-between f-14-black pv-4',
                        index < materials.length - 1 && 'bb-grey'
                    ]"
                >
                    <div class="lg-4 s-align-4">{{ el.name }}</div>
                    <div class="lg-4 s-align-5">
                        {{ el.quantity }}/
                        <span class="f-14-grey">CY</span>
                    </div>
                    <div class="lg-4 column-end">
                        <div class="f-16-black f-sbold">
                            {{ (Number(el.cost) * el.quantity) | currency }}
                        </div>
                        <div class="f-14-black">
                            ${{ el.cost }} /
                            <span class="f-14-grey">CY</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Loader } from '@/ui';
    import { CloseIcon, EnvelopeIcon, ArrowIcon, EditIcon, CalendarIcon } from '@/icons';
    import api from '@/headers.js';
    import { showFullAddress } from '@/helpers';
    import { ORDER_ITEMS_TYPE_ID, ENTITY_TYPES, CALENDAR_TYPES } from '@/constants.js';

    export default {
        name: 'SchedulerMaterialModal',
        components: {
            CalendarIcon,
            EditIcon,
            ArrowIcon,
            EnvelopeIcon,
            CloseIcon,
            Loader
        },
        props: {
            order: {
                type: Object,
                required: true
            },
            colorOrder: {
                type: String
            },
            crew: {
                type: Object,
                required: true
            },
            calendarType: {
                type: String,
                default: CALENDAR_TYPES.CREW
            }
        },
        data() {
            return {
                loading: false,
                deliveryIsOpen: true,
                laborContactsOpen: true,
                crewAssignmentOpen: true,
                qcIsOpen: true,
                materials: [],
                ENTITY_TYPES,
                CALENDAR_TYPES,
                vendors: [],
                selectedVendor: null,
                disabledRedirectButton: false
            }
        },
        created() {
            if (this.order) {
                this.getMaterial(this.order.items);
            }

            this.getVendors();
        },
        methods: {
            getNameCustomer() {
                if (this.calendarType == CALENDAR_TYPES.CREW && this.order.entity) {
                    switch (this.order.entity_type) {
                        case ENTITY_TYPES.COMMERCIAL_JOB:
                            return this.order.entity.property && this.order.entity.property.property_name;
                        case ENTITY_TYPES.RESIDENTIAL_LEAD:
                            return `${this.order.entity.first_name} ${this.order.entity.last_name}`;
                    }
                }

                if (this.calendarType == CALENDAR_TYPES.JOB) {
                    switch (this.crew.entity_type) {
                        case ENTITY_TYPES.COMMERCIAL_JOB:
                            return this.crew.property && this.crew.property.property_name;
                        case ENTITY_TYPES.RESIDENTIAL_LEAD:
                            return `${this.crew.first_name} ${this.crew.last_name}`;
                    }
                }
            },
            getAddress(address) {
                return showFullAddress(address);
            },
            getVendors() {
                const params = {
                    display: 'active',
                    per_page: 999
                };

                this.loading = true;
                api.get('/vendors', { params })
                    .then(response => {
                        this.vendors.push(...response.data.data.data);

                        if (this.order.vendor_id) {
                            this.selectedVendor = this.vendors.find(i => i.id == this.order.vendor_id).name;
                        }
                    })
                    .catch(error => {
                        this.notify(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            materialsTotalOrder() {
                return this.order.items.reduce((sum, current) => {
                    if (current.type_id !== ORDER_ITEMS_TYPE_ID.MATERIALS) {
                        return sum;
                    }

                    return sum + Number(current.cost) * current.quantity;
                }, 0).toFixed(2);
            },
            getMaterial(items) {
                items.map(el => {
                    if (el.type_id == ORDER_ITEMS_TYPE_ID.MATERIALS) {
                        this.materials.push(el);
                    }
                });
            },
            redirectToEditPage() {
                if (this.calendarType == CALENDAR_TYPES.CREW) {
                    switch (this.order.entity_type) {
                        case ENTITY_TYPES.RESIDENTIAL_LEAD:
                            return this.$router.push({
                                name: 'ResidentialOrderCreate',
                                params: {
                                    lead_id: this.order.entity_id,
                                    order_id: this.order.id
                                }
                            });
                        case ENTITY_TYPES.COMMERCIAL_JOB:
                            return this.$router.push({
                                name: 'CommercialJobOrderCreate',
                                params: {
                                    job_id: this.order.entity_id,
                                    property_id: this.order.crew_id,
                                    order_id: this.order.id
                                }
                            });
                    }
                }

                if (this.calendarType == CALENDAR_TYPES.JOB) {
                    switch (this.order.entity_type) {
                        case ENTITY_TYPES.RESIDENTIAL_LEAD:
                            return this.$router.push({
                                name: 'ResidentialOrderCreate',
                                params: {
                                    lead_id: this.crew.id,
                                    order_id: this.order.id
                                }
                            });
                        case ENTITY_TYPES.COMMERCIAL_JOB:
                            return this.$router.push({
                                name: 'CommercialJobOrderCreate',
                                params: {
                                    job_id: this.crew.id,
                                    property_id: this.crew.property_id,
                                    order_id: this.order.id
                                }
                            });
                    }
                }
            }
        }
    }
</script>