<template>
    <svg 
        :width="size" 
        :height="size"
        viewBox="0 0 35 35"
        class="outlook-logo"
    >
        <defs>
            <path id="outlook-logo__SVGID_1_" d="M0 0h35v35H0z"/>
        </defs>
        <clipPath id="outlook-logo__SVGID_2_">
            <use xlink:href="#outlook-logo__SVGID_1_" overflow="visible"/>
        </clipPath>
        <path class="outlook-logo__st0" d="M21.9 7.4v6.8l2.4 1.5h.3L35 8.8c0-.8-.8-1.4-1.2-1.4H21.9zM21.9 16.8l2.2 1.5c.3.2.7 0 .7 0-.4.2 10.2-6.7 10.2-6.7v12.6c0 1.4-.9 1.9-1.9 1.9H21.9v-9.3zM10.5 13.7c-.8 0-1.4.4-1.8 1-.5.7-.7 1.6-.7 2.8 0 1.2.2 2.1.7 2.8.4.7 1 1 1.7 1s1.3-.3 1.8-1c.4-.7.7-1.6.7-2.7 0-1.2-.2-2.2-.6-2.9-.5-.7-1.1-1-1.8-1"/>
        <path class="outlook-logo__st0" d="M0 3.9v26.8L20.6 35V0L0 3.9zm13.8 18c-.9 1.1-2 1.7-3.4 1.7S7.9 23 7 21.9c-.9-1.1-1.3-2.5-1.3-4.3 0-1.9.4-3.4 1.3-4.5.9-1.2 2-1.7 3.5-1.7 1.4 0 2.5.5 3.3 1.7.8 1.1 1.3 2.6 1.3 4.4 0 1.7-.4 3.2-1.3 4.4"/>
    </svg>
</template>

<script>
    export default {
        name: 'OutlookIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>