<template>
    <button
        v-if="data.length == 0"
        class="transparent-button"
        @click="selectAll"
    >
        <span :class="primaryColor ? 'f-14-blue' : 'f-14-grey'">
            Select all
        </span>
    </button>
    <button
        v-else
        class="transparent-button"
        @click="deselectAll(data)"
    >
        <span class="f-14-grey">Deselect all</span>
    </button>
</template>

<script>
    export default {
        name: 'SelectOrDeselect',
        props: {
            data: {
                type: Array,
                required: true
            },
            valuesFromAdd: {
                type: Array,
                required: true
            },
            primaryColor: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            selectAll() {
                this.valuesFromAdd.map(item => {
                    this.data.push(item.id);
                });

                this.$emit('changed');
            },
            deselectAll(values) {
                values.splice(0, values.length);
                this.$emit('changed');
            }
        }
    }
</script>