<template>
    <svg
	    viewBox="0 0 335.60272216796875 331.1160888671875"
        :width="width"
        :height="height"
        class="commercial-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="335.60272216796875" 
            height="331.1160888671875" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(7.464395523071289, 0, 0, 7.464395523071289, 447.19354248046875, -563.7957515716553)">
            <g>
                <path d="M-27.8,79.4h-1.1l-1.9-3.2c-0.1-0.2-0.4-0.3-0.6-0.3h-12.4c-0.2,0-0.5,0.1-0.6,0.3l-1.9,3.2h-1.1c-0.4,0-0.7,0.3-0.7,0.7v9.8h-9.2c-0.4,0-0.7,0.3-0.7,0.7v27.8h-1.6v1.4c17.9,0,24.2,0,43.9,0v-1.4h-1.6V90.6c0-0.4-0.3-0.7-0.7-0.7h-9.2v-9.8C-27.1,79.7-27.4,79.4-27.8,79.4z M-43.3,77.3h11.6l1.3,2.1h-14.1L-43.3,77.3z M-56.5,91.3h8.5v27.1h-8.5V91.3z M-46.6,80.8h18.1c0,11.7,0,26.4,0,37.6h-2.3v-9.9c0-0.4-0.3-0.7-0.7-0.7h-6.1h-6.1c-0.4,0-0.7,0.3-0.7,0.7v9.9h-2.3C-46.6,107.1-46.6,92.3-46.6,80.8z M-42.9,109.2h4.7v9.2h-4.7V109.2z M-36.9,118.4v-9.2h4.7v9.2H-36.9z M-18.6,91.3v27.1h-8.5V91.3H-18.6z"/>
                <path d="M -44.5 82.199997 H -42.2 V 83.599997 H -44.5 V 82.199997 Z"/>
                <path d="M -40.700001 82.199997 H -38.400001 V 83.599997 H -40.700001 V 82.199997 Z"/>
                <path d="M -36.900002 82.199997 H -34.600002 V 83.599997 H -36.900002 V 82.199997 Z"/>
                <path d="M -33.200001 82.199997 H -30.900001 V 83.599997 H -33.200001 V 82.199997 Z"/>
                <path d="M -44.5 86.5 H -42.2 V 87.9 H -44.5 V 86.5 Z"/>
                <path d="M -40.700001 86.5 H -38.400001 V 87.9 H -40.700001 V 86.5 Z"/>
                <path d="M -36.900002 86.5 H -34.600002 V 87.9 H -36.900002 V 86.5 Z"/>
                <path d="M -33.200001 86.5 H -30.900001 V 87.9 H -33.200001 V 86.5 Z"/>
                <path d="M -44.5 90.900002 H -42.2 V 92.300002 H -44.5 V 90.900002 Z"/>
                <path d="M -40.700001 90.900002 H -38.400001 V 92.300002 H -40.700001 V 90.900002 Z"/>
                <path d="M -36.900002 90.900002 H -34.600002 V 92.300002 H -36.900002 V 90.900002 Z"/>
                <path d="M -33.200001 90.900002 H -30.900001 V 92.300002 H -33.200001 V 90.900002 Z"/>
                <path d="M -44.5 95.199997 H -42.2 V 96.599997 H -44.5 V 95.199997 Z"/>
                <path d="M -40.700001 95.199997 H -38.400001 V 96.599997 H -40.700001 V 95.199997 Z"/>
                <path d="M -36.900002 95.199997 H -34.600002 V 96.599997 H -36.900002 V 95.199997 Z"/>
                <path d="M -33.200001 95.199997 H -30.900001 V 96.599997 H -33.200001 V 95.199997 Z"/>
                <path d="M -44.5 99.5 H -42.2 V 100.9 H -44.5 V 99.5 Z"/>
                <path d="M -40.700001 99.5 H -38.400001 V 100.9 H -40.700001 V 99.5 Z"/>
                <path d="M -36.900002 99.5 H -34.600002 V 100.9 H -36.900002 V 99.5 Z"/>
                <path d="M -33.200001 99.5 H -30.900001 V 100.9 H -33.200001 V 99.5 Z"/>
                <path d="M -44.5 103.900002 H -42.2 V 105.300002 H -44.5 V 103.900002 Z"/>
                <path d="M -40.700001 103.900002 H -38.400001 V 105.300002 H -40.700001 V 103.900002 Z"/>
                <path d="M -36.900002 103.900002 H -34.600002 V 105.300002 H -36.900002 V 103.900002 Z"/>
                <path d="M -33.200001 103.900002 H -30.900001 V 105.300002 H -33.200001 V 103.900002 Z"/>
                <path d="M -55.299999 93.599998 H -52.999999 V 94.999998 H -55.299999 V 93.599998 Z"/>
                <path d="M -51.5 93.599998 H -49.2 V 94.999998 H -51.5 V 93.599998 Z"/>
                <path d="M -55.299999 97.199997 H -52.999999 V 98.599997 H -55.299999 V 97.199997 Z"/>
                <path d="M -51.5 97.199997 H -49.2 V 98.599997 H -51.5 V 97.199997 Z"/>
                <path d="M -55.299999 100.800003 H -52.999999 V 102.200003 H -55.299999 V 100.800003 Z"/>
                <path d="M -51.5 100.800003 H -49.2 V 102.200003 H -51.5 V 100.800003 Z"/>
                <path d="M -55.299999 104.5 H -52.999999 V 105.9 H -55.299999 V 104.5 Z"/>
                <path d="M -51.5 104.5 H -49.2 V 105.9 H -51.5 V 104.5 Z"/>
                <path d="M -55.299999 108.099998 H -52.999999 V 109.499998 H -55.299999 V 108.099998 Z"/>
                <path d="M -51.5 108.099998 H -49.2 V 109.499998 H -51.5 V 108.099998 Z"/>
                <path d="M -55.299999 111.800003 H -52.999999 V 113.200003 H -55.299999 V 111.800003 Z"/>
                <path d="M -51.5 111.800003 H -49.2 V 113.200003 H -51.5 V 111.800003 Z"/>
                <path d="M -25.9 93.599998 H -23.6 V 94.999998 H -25.9 V 93.599998 Z"/>
                <path d="M -22.1 93.599998 H -19.8 V 94.999998 H -22.1 V 93.599998 Z"/>
                <path d="M -25.9 97.199997 H -23.6 V 98.599997 H -25.9 V 97.199997 Z"/>
                <path d="M -22.1 97.199997 H -19.8 V 98.599997 H -22.1 V 97.199997 Z"/>
                <path d="M -25.9 100.800003 H -23.6 V 102.200003 H -25.9 V 100.800003 Z"/>
                <path d="M -22.1 100.800003 H -19.8 V 102.200003 H -22.1 V 100.800003 Z"/>
                <path d="M -25.9 104.5 H -23.6 V 105.9 H -25.9 V 104.5 Z"/>
                <path d="M -22.1 104.5 H -19.8 V 105.9 H -22.1 V 104.5 Z"/>
                <path d="M -25.9 108.099998 H -23.6 V 109.499998 H -25.9 V 108.099998 Z"/>
                <path d="M -22.1 108.099998 H -19.8 V 109.499998 H -22.1 V 108.099998 Z"/>
                <path d="M -25.9 111.800003 H -23.6 V 113.200003 H -25.9 V 111.800003 Z"/>
                <path d="M -22.1 111.800003 H -19.8 V 113.200003 H -22.1 V 111.800003 Z"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'CommercialIcon',
        props: {
            width: {
                type: [Number, String],
                default: 48
            },
            height: {
                type: [Number, String],
                default: 40
            }
        }
    }
</script>