<template>
    <svg 
        :height="height" 
        :width="width"
        viewBox="0 0 604.3697509765625 781.0924682617188"
        class="zip-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="604.3697509765625" 
            height="781.0924682617188" 
            style="fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(11.878788948059082, 0, 0, 11.878788948059082, 5203.587188720703, -2841.1884315013885)">
            <path 
                class="st0" 
                d="M-389.6,255.4l-15.1-14.2c-1.2-1.2-2.8-1.7-4.4-1.7h-22.7c-3.2,0-5.8,2.6-5.8,5.8v53.5c0,3.2,2.6,5.8,5.8,5.8h38.4c3.2,0,5.8-2.6,5.8-5.8V260C-387.6,258.3-388.3,256.6-389.6,255.4z"
            />
            <path 
                class="st0" 
                d="M-407.9,259.8h20.2c-0.1-1.7-0.7-3.3-2-4.4l-15.1-14.2c-1.1-1.1-2.7-1.7-4.3-1.7v19.2C-409,259.3-408.5,259.8-407.9,259.8z"
            />
            <path 
                class="st1" 
                d="M-407.9,259.8h20.2c-0.1-1.7-0.7-3.3-2-4.4l-15.1-14.2c-1.1-1.1-2.7-1.7-4.3-1.7v19.2C-409,259.3-408.5,259.8-407.9,259.8z" 
                style="opacity: 0.3;"
            />
            <path 
                class="st2" 
                d="M-423.9,280c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5C-422.4,280.7-423.1,280-423.9,280z"
            />
            <path 
                class="st2" 
                d="M -414.6000061035156 276.79998779296875 L -414.6000061035156 275 L -421.1000061035156 275 L -421.1000061035156 277.29998779296875 L -418 277.29998779296875 L -421.1000061035156 281.1000061035156 L -421.1000061035156 283 L -414.5 283 L -414.5 280.70001220703125 L -417.79998779296875 280.70001220703125 Z"
            />
            <path 
                class="st2" 
                d="M-411.7,271.4c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.7,1.4-1.4C-410.3,272.1-410.9,271.4-411.7,271.4z"
            />
            <path 
                class="st2" 
                d="M -413 275 H -410.4 V 282.9 H -413 V 275 Z"
            />
            <path 
                class="st2" 
                d="M-403.8,274.8c-0.9,0-1.7,0.3-2.3,1.2V275h-2.6v10.8h2.6v-4c0.7,0.8,1.4,1.2,2.3,1.2c2,0,3.5-1.5,3.5-4.2C-400.3,276.3-401.8,274.8-403.8,274.8z M-404.6,280.9c-0.6,0-1.2-0.3-1.5-0.7v-2.3c0.3-0.5,0.9-0.7,1.5-0.7c0.9,0,1.7,0.7,1.7,1.9C-402.9,280.2-403.7,280.9-404.6,280.9z"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'ZipIcon',
        props: {
            width: {
                type: [Number, String],
                default: 65
            },
            height: {
                type: [Number, String],
                default: 50
            }
        }
    }
</script>