<template>
    <modal
        @close="$emit('close')"
    >
        <div slot="modal-header">
            <slot name="header"></slot>
        </div>
        <div slot="modal-body">
            <slot name="body"></slot>
        </div>
        <div slot="modal-footer">
            <div class="row-between">
                <button
                    class="delete-button"
                    @click="$emit('confirmAction', true)"
                >
                    Yes
                </button>
                <button
                    class="add-button ml-2"
                    @click="$emit('close')"
                >
                    No
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import { Modal } from '@/ui';

export default {
    name: 'ConfirmActionsModal',
    components: { Modal }
}
</script>