import api from '@/headers.js';

import {
    GET_FOLDERS,
    ADD_FOLDER,
    FOLDER_DELETE,
    UPDATE_FOLDER,
    SEND_NEW_FOLDER,
    UPDATE_PARENT_ID_FOLDER
} from "../actions/defaultFolder";

export default {
    namespaced: true,
    state: {
        folderOverId: '',
        dragSource: '',
        folders: []
    },
    getters: {
        getDragSource: state => state.dragSource,
        getFolderOverId: state => state.folderOverId,
        getFolders: state => state.folders
    },
    mutations: {
        [ADD_FOLDER] (state, payload) {
            if (payload.parent_id) {
                state.folders.map((el, index) => {
                    if (el.id === payload.parent_id) {
                        return el.children.push(payload);
                    }
                });
            } else {
                payload['children'] = [];
                return state.folders.push(payload);
            }
        },
        [GET_FOLDERS] (state, payload) {
            state.folders = payload;
        },
        [FOLDER_DELETE] (state, payload) {
            state.folders = state.folders.filter(el => el.id !== payload);
            function deleteFolder(arr) {
                (arr || []).map((el, index) => {
                    if (el.id == payload) {
                        arr.splice(index, 1);
                    } else {
                        el.children.length && deleteFolder(el.children);
                    }
                })
            }
            deleteFolder(state.folders);
        },
        [UPDATE_PARENT_ID_FOLDER] (state, payload) {
            (state.folders[payload.folderDragStart].children || []).map((el, i) => {
                if (el.id == payload.params.target_id) {
                    return state.folders[payload.folderDragStart].children.splice(i, 1);
                }
            });
            state.folders[payload.folderDragOver].children.push(payload.params);
        },
        [UPDATE_FOLDER] (state, payload) {
            function updateFolder(arr) {
                (arr || []).map((el, index) => {
                    if (payload.params.target_id == el.id) {
                        el.id = payload.params.parent_id
                            ? payload.params.parent_id + '/' + payload.params.name
                            : '/' + payload.params.name;
                        el.name = payload.params.name;
                        (el.children || []).map(item => {
                            item.parent_id =  payload.params.id;
                            item.id = item.parent_id + '/' + item.name;
                        });

                        el.is_job = payload.params.is_job;
                        el.is_lead = payload.params.is_lead;

                        return el;
                    } else {
                        updateFolder(el.children)
                    }
                });
            }
            updateFolder(state.folders);
        }
    },
    actions: {
        [GET_FOLDERS]({ commit }) {
            return new Promise((resolve, reject) => {
                api.get('/company/default-folders/all')
                    .then(response => {
                        resolve(response.data.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        [FOLDER_DELETE]({ commit }, el) {
            const query = `?id=${el}`;
            return new Promise((resolve, reject) => {
                api.delete(`/company/default-folders/delete${query}`)
                    .then(response => {
                        resolve(response);
                        return response.data.data;
                    })
                    .then(data => {
                        commit(FOLDER_DELETE, el);
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        [SEND_NEW_FOLDER]({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                api.post('/company/default-folders/create', data)
                    .then(response => {
                        resolve(response);
                        commit(ADD_FOLDER, response.data.data);
                        return response.data;
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        [UPDATE_FOLDER]({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                api.put('/company/default-folders/update', data)
                    .then(response => {
                        resolve(response);
                        commit(UPDATE_FOLDER, response.data.data);
                        return response.data.data;
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    },
}