<template>
    <form
        class="address-box w-100"
        autocomplete="off"
        ref="formContainer"
        @submit.prevent
    >
        <div class="row-start">
            <div
                class="w-100"
                :id="`${type}-address-${unicId}`"
            >
                <p class="f-14-asphalt f-sbold mb-1 mt-sm-1">{{title}}</p>
                <form-input
                    :label="`${placeholder}`"
                    :class="[{
                        'form-input-danger' : errors.has(`${type}-address-${unicId}`),
                        'required-field-mark' : requiredMark
                    }]"
                >
                    <input
                        type="text"
                        :name="`${type}-address-${unicId}`"
                        class="form-input-field"
                        :placeholder="`${placeholder}`"
                        v-input-style="address.address"
                        v-model="address.address"
                        v-validate="`${validate}|max:250`"
                        ref="autocomplete"
                        @change="clearCoordinates"
                        @input="clearCoordinates"
                        :disabled="disabled"
                    />
                </form-input>
            </div>
        </div>
    </form>
</template>

<script>
/*
* apartment: props for changing styles
* if it needs add the additional field 'apartment'
* (slot name="apartment")
*
* unicId: use if there are two address-box components
* with the same type on the page
*/

import { FormInput, CheckboxButton } from '@/ui';
import { STATES, ADDRESS_TYPES } from '@/constants';

const ADDRESS_COMPONENTS = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    postal_code: 'short_name'
};

export default {
    name: 'AddressBoxOneLine',
    components: {
        FormInput,
        CheckboxButton
    },
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            required: true
        },
        existing: {
            type: Object,
            required: false
        },
        validate: {
            type: String,
            default: 'required'
        },
        apartment: {
            type: Boolean,
            default: false
        },
        requiredMark: {
            type: Boolean,
            default: false
        },
        inFocus: {
            type: Boolean,
            default: false
        },
        unicId: {
            type: String,
            default: 'field'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            address: {
                address: '',
                street: '',
                city: '',
                state: '',
                zip: '',
                lat: null,
                lng: null
            },
            initialized: false,
            isShowHintState: false,
            states: STATES
        }
    },
    inject: ['$validator'],
    computed: {
        searchStates() {
            return this.states.filter(el =>
                el.name.toLowerCase().indexOf(this.address.state.toLowerCase())  !== -1
                || el.code.toLowerCase().indexOf(this.address.state.toLowerCase()) !== -1);
        },
        billingAddress: function() {
            return {
                address_billing: this.address.address,
                city_billing: this.address.city,
                lat_billing: this.address.lat,
                lng_billing: this.address.lng,
                state_billing: this.address.state,
                street_billing: this.address.street,
                zip_billing: this.address.zip
            }
        },
        mailingAddress: function() {
            return {
                address_mailing: this.address.address,
                city_mailing: this.address.city,
                lat_mailing: this.address.lat,
                lng_mailing: this.address.lng,
                state_mailing: this.address.state,
                street_mailing: this.address.street,
                zip_mailing: this.address.zip
            }
        }
    },
    watch: {
        address: {
            handler: function() {
                if (!this.initialized) {
                    return;
                }

                switch (this.type) {
                    case ADDRESS_TYPES.BILLING:
                        this.$emit('address', this.billingAddress);
                        break;
                    case ADDRESS_TYPES.MAILING:
                        this.$emit('address', this.mailingAddress);
                        break;
                    case 'address':
                        this.$emit('address', this.address);
                        break;
                    default:
                        this.$emit('address', this.address);
                        break;
                }
            },
            deep: true
        },
        existing: {
            immediate: true,
            deep: true,
            handler (newValue, oldValue) {
                if (newValue && this.type == 'billing') {
                    for (let field in newValue) {
                        // remove '_billing' from name
                        this.address[field.slice(0, -8)] = newValue[field];
                    }
                } else if (newValue && this.type == 'mailing') {
                    for (let field in newValue) {
                        // remove '_mailing' from name
                        this.address[field.slice(0, -8)] = newValue[field];
                    }
                } else if (newValue) {
                    for (let field in newValue) {
                        this.address[field] = newValue[field];
                    }
                }
            }
        }
    },
    methods: {
        selectState(state) {
            this.initialized = true;
            this.address.state = state.code;
            this.isShowHintState = false;
        },
        clearCoordinates () {
            this.initialized = true;
            this.address.lat = null;
            this.address.lng = null;
        },
        showHint() {
            this.isShowHintState = true;
            this.clearCoordinates();
        }
    },
    destroyed() {
        document.body.removeEventListener('click', this.outside, false);
    },
    created() {
        let self = this;
        // create validation rule for check input state name
        // is equal harcoded state name.
        this.$validator.extend('stateName', {
            getMessage(field, args) {
                return `State name isn't correct! Select from the list.`;
            },
            validate(value, field) {
                return self.states.some(s => {
                    return s.code === value;
                });
            }
        });
    },
    mounted() {
        document.body.addEventListener('click', this.outside, false);
        const input = this.$refs.autocomplete;

        let address = new google.maps.places.Autocomplete((input), {
            types: ['geocode'],
            componentRestrictions: { country: 'us' }
        });

        const result = (place) => {
            if (!place.hasOwnProperty('address_components')) return false;

            let data = {};
            for (let i = 0; i < place.address_components.length; i++) {
                let addressType = place.address_components[i].types[0];
                if (ADDRESS_COMPONENTS[addressType]) {
                    let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
                    data[addressType] = val;
                }
            }

            this.address.address = `${data.street_number || ''} ${data.route || ''} ${data.locality || ''}, ${data.administrative_area_level_1 || ''}` || '';

            this.initialized = true;
        };

        google.maps.event.addListener(address, 'place_changed', function () {
            result(address.getPlace());
        });

        // autocomplete off no remove last version
        //   var observerHack = new MutationObserver(function(mutationsList, observer) {
        //     for (const mutation of mutationsList) {
        //       console.log(mutation);
        //     }
        //       // observerHack.disconnect();
        //       // input.setAttribute('autocomplete', `${Date.now()}-0`);
        //   });
        // input.autocomplete = `${Date.now()}-0`;
        // // observerHack.observe(input, {
        //   //     attributes: true,
        //   //     attributeFilter: ['autocomplete']
        //   // });
        const observerHack = new MutationObserver(function (mutationsList, observer) {
            for (const mutation of mutationsList) {
                if (mutation.attributeName === 'autocomplete' && mutation.target.getAttribute('autocomplete') == 'off') {
                    mutation.target.setAttribute('autocomplete', `${Date.now()}-0`)
                }
            }
        });
        observerHack.observe(input, { attributes: true });
        if (this.inFocus) {
            input.focus();
        }
        const formElCon = this.$refs.formContainer.getElementsByTagName('input');
        Array.from(formElCon).forEach((el, idx) => {
            el.autocomplete = `new-${el.name}-${idx + 1}`;
            el.name = `${Date.now()}-${idx}`;
        });
    }
}
</script>
