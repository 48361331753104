<template>
    <div class="settings-item w-100">
        <div class="item-title row-between row-baseline mb-6">
            <div class="s-align-4">
                <menu-icon 
                    class="i-lightblue mr-4" 
                    size="20"
                />
                <p class="f-18-darkgrey f-sbold">Customer portal</p>
            </div>
            <button 
                class="icon-light"
                v-if="mScreen || tScreen"
                @click="isModalShow = true"
            >
                <edit-icon size="15"/>
            </button>
        </div>
        <div
            v-if="isLoading" 
            class="w-box"
        >
            <loader size="small"/>
        </div>
        <div 
            v-else
            class="w-box"
        >
            <div class="row-between row-baseline">
                <div class="lg-8 md-12 sm-12">
                    <p class="f-14-grey mr-1">State:
                        <span 
                            v-if="customerPortalSettings.inviteMode === INVITE_MODES.MANUAL"
                            class="f-14-black f-sbold"
                        >
                            Send invitations manually
                        </span>
                        <span 
                            v-if="customerPortalSettings.inviteMode === INVITE_MODES.NONE"
                            class="f-14-black f-sbold"
                        >
                            Never send an invitation
                        </span>
                        <span 
                            v-if="customerPortalSettings.inviteMode === INVITE_MODES.MILESTONE"
                            class="f-14-black f-sbold"
                        >
                            Invite customer to his personal account:
                        </span>
                    </p>
                </div>
                <button
                    v-can="[$permissions.EDIT_COMPANY_SETTINGS]"
                    class="edit-button"
                    @click="isModalShow = true"
                    v-if="nScreen || dScreen"
                >
                    Edit
                </button>
            </div>
            <div 
                v-if="customerPortalSettings.inviteMode === INVITE_MODES.MILESTONE"
                class="row-start row-baseline mt-5"
            >
                    <span class="milestone-status-marker capitalize mr-4">
                        {{ getAbbreviation(customerPortalSettings.inviteMilestone) }}
                    </span>
                    <div class="column-start">
                        <span class="f-12-grey">When job reaches milestone</span>
                        <span class="f-14-black">
                            {{ customerPortalSettings.inviteMilestone }}
                        </span>
                    </div>
            </div>
            <div 
                v-if="isEmailShow"
                class="column-start mt-20"
            >
                <span class="f-14-black f-sbold">Receive e-mails from a contact form:</span>
                <span class="f-14-blue">{{ customerPortalSettings.recipientEmail }}</span>
            </div>
        </div>
        <customer-portal-settings-modal
            v-if="isModalShow"
            :customerPortalSettings="customerPortalSettings"
            @updateSettingsData='updateSettingsData'
            @close="isModalShow = false"
        />
    </div>
</template>

<script>
    import { MenuIcon, EditIcon } from '@/icons';
    import { Loader } from '@/ui';
    import { CustomerPortalSettingsModal } from '@/modals';
    import { CUSTOMER_INVITE_MODES } from '@/constants.js';
    import api from '@/headers.js';
    import { getAbbreviation } from '@/helpers';

    export default {
        name: 'CustomerPortalSettings',
        components: {
            MenuIcon,
            Loader,
            CustomerPortalSettingsModal,
            EditIcon
        },
        data() {
            return {
                isLoading: false,
                customerPortalSettings: null,
                isModalShow: false,
                INVITE_MODES: CUSTOMER_INVITE_MODES,
                getAbbreviation
            }
        },
        computed: {
            isEmailShow() {
                return (this.customerPortalSettings.inviteMode === this.INVITE_MODES.MILESTONE 
                    || this.customerPortalSettings.inviteMode === this.INVITE_MODES.MANUAL)
                    && this.customerPortalSettings.recipientEmail;
            }
        },
        methods: {
            getCustomerPortalSettings() {
                this.isLoading = true;

                api.get('settings/customer-portal')
                    .then(response => response.data.data)
                    .then(data => {
                        this.customerPortalSettings = data;
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            updateSettingsData(data) {
                Object.assign(this.customerPortalSettings, data)
                this.isModalShow = false;
            }
        },
        created() {
            this.getCustomerPortalSettings();
        }
    }
</script>