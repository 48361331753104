<template>
    <svg
        x="0px"
        y="0px"
        viewBox="0 0 13 13"
        :width="size"
        :height="size"
        class="redaction-icon"
    >
        <path d="M12.8,4.4c-0.2-0.2-0.5-0.2-0.7,0L7.3,9.2C7.2,9.1,7,9,6.9,9C6.7,8.9,6.6,8.9,6.4,8.7C6.2,8.5,6.2,8.4,6.1,8.2 C6.1,8.1,6,8,5.9,7.8l4-4c0.2-0.2,0.2-0.5,0-0.7C9.8,2.8,9.5,2.8,9.3,3l-4,4C5,7,4.9,6.9,4.8,6.9C4.6,6.8,4.5,6.8,4.3,6.6C4.1,6.4,4.1,6.3,4,6.1C4,6,3.9,5.8,3.8,5.7l4.8-4.8c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0L2.8,5.3c0,0,0,0,0,0s0,0,0,0C2.7,5.3,2.7,5.4,2.6,5.4c0,0,0,0,0,0l-1.6,4c0,0,0,0,0,0l-1,2.6c-0.1,0.2,0,0.3,0.1,0.5l0.2,0.2C0.4,12.9,0.5,13,0.7,13 c0.1,0,0.1,0,0.2,0l6.7-2.6c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1l5.1-5.1C13.1,4.9,13.1,4.6,12.8,4.4z M4.4,7.8 c0.2,0.1,0.3,0.1,0.5,0.3c0.2,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.2,0.5,0.5,0.9C5.9,9.6,6,9.7,6.2,9.8l-2.7,1.1L2.1,9.5l1.1-2.7 C3.3,7,3.4,7.1,3.6,7.3C3.9,7.6,4.2,7.7,4.4,7.8z M1.7,10.6l0.7,0.7l-1.1,0.4L1.7,10.6z"/>
    </svg>
</template>
<script>
    export default {
        name: 'RedactionIcon',
        props: {
            size: {
                type: [Number, String],
                default: 15
            }
        }
    }
</script>