<template>
  <button
      @click="toggleCallWidget"
      class="phone-call-rounded-button"
      :class="statusWidget && 'close-button'"
  >
    <fill-phone-icon
        v-if="!statusWidget"
        class="i-white"
        size="30"
    />
    <close-icon
        v-else
        class="i-white"
        size="30"
    />
  </button>
</template>

<script>
import {FillPhoneIcon, CloseIcon} from '@/icons';

export default {
  name: 'PhoneCallRoundedButton',
  components: {FillPhoneIcon, CloseIcon},
  props: {
    statusWidget: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    toggleCallWidget() {
      this.$emit('toggleCallWidget');
    }
  }
}
</script>
