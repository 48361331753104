<template>
    <svg
        x="0px"
        y="0px"
        viewBox="0 0 28 25"
        :width="size"
        :height="size"
        class="use-template-icon"
    >
        <path
            fill="#006BFD"
            d="M0.9,8.2h26.2c0.5,0,0.9-0.4,0.9-0.9V0.9C28,0.4,27.6,0,27.1,0H0.9C0.4,0,0,0.4,0,0.9v6.4C0,7.8,0.4,8.2,0.9,8.2z M1.8,1.8 h24.4v4.5H1.8V1.8z"
        />
        <path
            fill="#006BFD"
            d="M27.1,10.5H21c-0.5,0-0.9,0.4-0.9,0.9v12.7c0,0.5,0.4,0.9,0.9,0.9h6.1c0.5,0,0.9-0.4,0.9-0.9V11.4 C28,10.9,27.6,10.5,27.1,10.5z M26.2,23.2h-4.3V12.3h4.3L26.2,23.2z"
        />
        <path
            fill="#006BFD"
            d="M0.9,25H17c0.5,0,0.9-0.4,0.9-0.9V11.4c0-0.5-0.4-0.9-0.9-0.9H0.9c-0.5,0-0.9,0.4-0.9,0.9v12.7C0,24.6,0.4,25,0.9,25z M1.8,12.3h14.3v10.9H1.8V12.3z"
        />
    </svg>
</template>
<script>
    export default {
        name: 'UseTemplateIcon',
        props: {
            size: {
                type: [Number, String],
                default: 15
            }
        }
    }
</script>