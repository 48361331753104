import { GET_IMAP_ACCOUNTS } from '../actions/imap';
import IMAPapi from '@/IMAPheaders';

export default {
    namespaced: true,
    state: {
        imapConfig: [],
        imapConfigLoaded: false
    },
    getters: {
        getImapConfig: state => state.imapConfig,
        isImapConfigLoaded: state => state.imapConfigLoaded
    },
    mutations: {
        [GET_IMAP_ACCOUNTS] (state, payload) {
            const accounts = payload.filter(account => account.is_active);
            state.imapConfig = accounts;
            state.imapConfigLoaded = true;
        }
    },
    actions: {
        [GET_IMAP_ACCOUNTS]({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                IMAPapi.get(`accounts?user_id=${id}`)
                    .then(response => {
                        commit(GET_IMAP_ACCOUNTS, response.data.data)
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        }
    }
}