<template>
    <svg 
        viewBox="0 0 14.9 11.2"
        :width="size"
        :height="size"
        class="archive-icon"
    >
        <g>
            <path d="M14.9,5.5V5.3l-2.6-4.1c-0.4-0.6-1.1-1-1.8-1h-6c-0.7,0-1.4,0.4-1.8,1L0.1,5.3v0.2L0,5.7V9c0,1.2,1,2.2,2.1,2.2h10.7c1.2,0,2.1-1,2.1-2.2L14.9,5.5L14.9,5.5z M10.9,5.1c-0.8,0-1.6,0.5-1.9,1.3c-0.2,0.6-0.8,1-1.5,1S6.3,7,6,6.4C5.7,5.6,4.9,5.1,4.1,5.1H1.7l2-3.2c0.2-0.3,0.5-0.4,0.8-0.4h6c0.3,0,0.6,0.2,0.8,0.4l2,3.2H10.9z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'ArchiveIcon',
        props: {
            size: {
                type: [Number, String],
                default: 15
            }
        }
    }
</script>
