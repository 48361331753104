<template>
    <div class="base-worksheet">
        <div class="base-worksheet-header row-between">
            <div class="s-align-1 column-start">
                <span class="f-sbold f-20-darkgrey">Contract Worksheet</span>
                <div class="f-13-grey row-start">
                    Last change: 
                    <span class="f-13-black ml-1"> {{ worksheet.updated_at | date }} </span>.
                    to access list of all changes click
                    <button 
                        class="transparent-button f-13-blue ml-1"
                        @click="viewLogModalIsOpen = true"
                    >
                        View log
                    </button>
                </div>
            </div>
            <div class="s-align-6">
                <button 
                    class="view-details-primary p-2 worksheet-button"
                    @click="addFromEstimateModalOpen = true"
                > 
                    Import from estimate 
                </button>
                <button class="primary-button ml-4 worksheet-button mr-4 in-dev"> 
                    Send to approve 
                </button>
                <context-menu
                    :unicId="`worksheet-menu`"
                    width="200"
                >
                    <button 
                        class="s-align-4 bb-grey pb-4"
                        @click="viewLogModalIsOpen = true"
                    >
                        <span class="icon-container mr-6">
                            <paper-icon size="13"/>
                        </span>
                        <span>View log</span>
                    </button>
                    <button 
                        class="s-align-4"
                        @click="openConfirmDeleteModal = true"
                    >
                        <span class="icon-container mr-6">
                            <trash-icon width="15"/>
                        </span>
                        <span>Delete Contract</span>
                    </button>
                </context-menu>
            </div>
        </div>
        <div class="base-worksheet-body mt-35">
            <div class="base-worksheet-body-title row-start row-baseline pl-45">
                <div class="lg-3 pl-7">
                    <span class="f-12-black f-sbold">
                        Description
                    </span>
                </div>
                <div class="lg-3">
                    <span class="f-12-black f-sbold">
                        Contract amount
                    </span>
                </div>
                <div class="lg-3">
                    <span class="f-12-black f-sbold t-center">
                        Change order
                    </span>
                </div>
                <div class="lg-1 s-align-6">
                    <span class="f-12-black f-sbold t-center">
                        Total
                    </span>
                </div>
            </div>
            <div 
                class="base-contract-worksheets column-static mv-8"
                @dragover.prevent
            >
                <!-- ↑↑↑ prevent HTML5 Drag Ghost Image Flying Back -->
                <div v-for="(item, index) in worksheet.items" :key="`worksheet_${index}`">
                    <div 
                        :class="[
                            'base-contract-worksheet relative row-start row-baseline worksheet-separator',
                            {
                                'base-contract-worksheet-selected': (propertiesDraggingIndex === index)
                            }
                        ]"
                        :draggable="false"
                        @dragover="propertiesDragOver(item, index)" 
                        @dragend="propertiesDragEnd(item, index)"
                        @dragstart="propertiesDragStart(item, index, $event)"
                    >
                        <div
                            @mousedown="enableDrag"
                            @mouseup="disableDrag"
                            class="base-contract-worksheet-drag-place grabbable s-align-5 on-desktop-visible"
                        >
                            <drag-icon size="10"/>
                        </div>
                        <div class="base-contract-worksheet-mobile-control on-mobile-visible sm-1 column-center">
                            <button 
                                class="transparent-button mb-8"
                                @click="moveWorksheetUp(index, item)"
                                :disabled="index === 0"
                            >
                                <arrow-icon :transform="270"/>
                            </button>
                            <button 
                                class="transparent-button"
                                @click="moveWorksheetDown(index, item)"
                                :disabled="index === worksheet.items.length - 1"
                            >
                                <arrow-icon/>
                            </button>
                        </div>
                        <div class="lg-3 sm-12 base-contract-worksheet-name-wrapper">
                            <span class="pl-8 f-12-dark f-sbold on-mobile-visible">
                                Description
                            </span>
                            <div class="base-contract-worksheet-name base-contract-worksheet-description hidden w-100">
                                <div>
                                    <input 
                                        type="text"
                                        class="worksheet-description-field w-100 mt-2"
                                        :name="'base_contract_worksheet_name_' + index"
                                        placeholder="Add description here"
                                        @input="updateWorksheet(item)"
                                        v-model="item.description"
                                        maxlength="30"
                                        v-validate="'required'"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="lg-3 sm-12 base-contract-worksheet-name-wrapper">
                            <span class="pl-8 f-12-dark f-sbold on-mobile-visible">
                                Contract amount
                            </span>
                            <div class="base-contract-worksheet-name hidden row-baseline">
                                <div class="f-13-black s-align-2">
                                    {{item.contract_amount.length == 0 ? '$':''}}
                                    <input 
                                        class="worksheet-price-input"
                                        type="text"
                                        :name="'base_contract_worksheet_amount_' + index"
                                        placeholder="0.00"
                                        autocomplete="off"
                                        @input="replaceAmountCurrency(item ,$event.target.value), updateWorksheet(item)"
                                        :value="item.contract_amount | inputCurrency | numberWithCommas"
                                        v-limiter
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="lg-3 sm-12 base-contract-worksheet-name-wrapper">
                            <span class="pl-8 f-12-dark f-sbold on-mobile-visible">
                                Change order
                            </span>
                            <div class="base-contract-worksheet-name hidden row-baseline">
                                <div class="f-13-black">
                                    {{item.change_order.length == 0 ? '$':''}}
                                    <input 
                                        class="worksheet-price-input"
                                        type="text"
                                        :name="'base_contract_worksheet_order_' + index"
                                        placeholder="0.00"
                                        autocomplete="off"
                                        @input="replaceOrderCurrency(item ,$event.target.value), updateWorksheet(item)"
                                        :value="item.change_order | inputCurrency | numberWithCommas"
                                        v-limiter
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="base-contract-worksheet-total row-center row-baseline lg-2 sm-12 mb-sm-1">
                            <span class="pl-8 f-12-dark f-sbold on-mobile-visible">
                                Total
                            </span>
                            <div 
                                :class="[
                                    'f-13-black',
                                    { 'pl-8' : mScreen }
                                ]"
                            >
                                {{ getItemTotal(item) | currency}}
                            </div>
                        </div>
                        <div class="lg-1 sm-12 base-contract-worksheet-delete row-end mt-sm-2">
                            <tooltip 
                                direction="left"
                                :fixedDirection="true"
                                :isShow="activeWorksheetTooltip === index"
                                color="white"
                            >
                                <button 
                                    class="transparent-button s-align-6"
                                    @click="activeWorksheetTooltip = activeWorksheetTooltip == index ? null : index"
                                >
                                    <trash-icon/>
                                </button>
                                <template v-slot:content>
                                    <p class="f-14-dark f-sbold t-center mb-8">
                                        Are you sure to want to delete this item?
                                    </p>
                                    <div class="row-between">
                                        <button 
                                            class="add-button"
                                            @click="activeWorksheetTooltip = null"
                                        >
                                            No
                                        </button>
                                        <button 
                                            class="red-button"
                                            @click="deleteItem(item, index)"
                                            :disabled="requestIsGoing"
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </template>
                            </tooltip>
                        </div>
                    </div>
                </div>
                <div class="add-base-contract-worksheet-wrapper">
                    <button 
                        :class="[
                            'transparent-button',
                            ifEmptyItem ? 'f-13-grey add-new-item-button-disabled' : 'f-13-blue'
                        ]"
                        @click="addNewItem()"
                        :disabled="ifEmptyItem"
                    >
                        <plus-icon 
                            :class="[
                                'mr-7',
                                ifEmptyItem ? 'i-grey' : 'i-blue'
                            ]" 
                            :size="16"
                        /> 
                        Add new item
                    </button>
                </div>
            </div>
        </div>
        <div class="page-settings s-align-5">
            <div class="footer-totals row-start f-sbold"> 
                <span 
                    v-if="!mScreen" 
                    class="lg-3 sm-12 pl-45"
                >
                    Total amount
                </span>
                <span class="lg-3 sm-12 pl-25">
                    <span class="f-12-dark f-sbold on-mobile-visible">
                        Total contract amount
                    </span>
                    {{totalContractAmount | currency}}
                </span>
                <span class="lg-3 sm-12 pl-20">
                    <span class="f-12-dark f-sbold on-mobile-visible">
                        Total change order
                    </span>
                    {{totalChangeOrder | currency}}
                </span>
                <span class="lg-2 sm-12 s-align-5">
                    <span class="f-12-dark f-sbold on-mobile-visible">
                        Total amount
                    </span>
                    {{totalAmount | currency}}
                </span>
            </div>
        </div>
        <view-log-modal
            v-if="viewLogModalIsOpen"
            type="worksheet"
            :id="worksheet.id"
            @close="viewLogModalIsOpen = false"
            v-body-scroll-lock:reserve-scroll-bar-gap="true"
        />
        <worksheet-add-from-estimate-modal
            v-if="addFromEstimateModalOpen"
            :items="worksheet.items"
            :worksheetId="worksheet.id"
            @onAddItems="items => worksheet.items.push(...items)"
            @onDeleteItems="onDeleteItems"
            @close="addFromEstimateModalOpen = false"
        />
        <modal
            v-if="openConfirmDeleteModal"
            @close="openConfirmDeleteModal = false"
        >
            <div slot="modal-body">
                <div class="w-100 t-center pb-4">
                    <span class="f-14-black">
                        Do you want to delete worksheet?
                    </span>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="row-between">
                    <button 
                        class="add-button"
                        @click="openConfirmDeleteModal = false"
                    >
                        Cancel
                    </button>
                    <button 
                        class="delete-button"
                        @click="$emit('deleteWorksheet')"
                    >
                        Delete
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import { Loader, Tooltip, FormInputTextarea, Modal } from '@/ui';
import { PointsIcon, DragIcon, TrashIcon, ArrowIcon, PlusIcon, PaperIcon } from '@/icons';
import api from '@/headers.js';
import debounce from 'lodash/debounce';
import ContextMenu from '@/components/ContextMenu.vue';
import { deleteCommas } from '@/helpers';

export default {
    name: 'BaseContractWorksheet',
    components: {
        Loader,
        Tooltip,
        PointsIcon,
        DragIcon,
        PlusIcon,
        TrashIcon,
        PaperIcon,
        ArrowIcon,
        Modal,
        FormInputTextarea,
        ContextMenu,
        ViewLogModal: () => import('@/modals/ViewLogModal.vue'),
        WorksheetAddFromEstimateModal: () => import('@/modals/WorksheetAddFromEstimateModal.vue')
    },
    props: {
        worksheet: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            propertiesOverIndex: null,
            propertiesDragging: '',
            propertiesDraggingIndex: null,
            activeWorksheetTooltip: null,
            requestIsGoing: false,
            viewLogModalIsOpen: false,
            addFromEstimateModalOpen: false,
            openConfirmDeleteModal: false
        }
    },
    watch: {
        ifEmptyWorksheet(val, oldVal) {
            if (!val) {
                this.$emit('deleteWorksheet');
            }
        }
    },
    computed: {
        totalContractAmount() {
            return this.worksheet.items.reduce((s,c) => { 
                if(!isNaN(c.contract_amount)) {
                    return s + Number(c.contract_amount); 
                }
                return s;
            },0);
        },
        totalChangeOrder() {
            return this.worksheet.items.reduce((s,c) => { 
                if(!isNaN(c.change_order)) {
                    return s + Number(c.change_order); 
                }
                return s;
            },0);
        },
        totalAmount() {
            return this.totalContractAmount + this.totalChangeOrder;
        },
        ifEmptyItem() {
            return this.worksheet.items.some(el => !el.id);
        },
        ifEmptyWorksheet() {
            return this.worksheet.items.length;
        }
    },
    methods: {
        replaceOrderCurrency(itemValue,value) {
            itemValue.change_order = deleteCommas(value.replace('$',''));
        },
        replaceAmountCurrency(itemValue,value) {
            itemValue.contract_amount = deleteCommas(value.replace('$',''));
        },
        getItemTotal(item) {
            let amount = item.contract_amount;
            let order = item.change_order;
            if(isNaN(amount)) {
                amount = 0;
            }
            if(isNaN(order)) {
                order = 0;
            }

            return Number(amount) + Number(order);
        },
        checkMinusValidation(value) {
            if (value.includes('-')) {
                let arrayValue = value.split('');

                return arrayValue[0] != '-' || (arrayValue[0] == '-' && value.length == 1);
            }
        },
        checkDotValidation(value) {
            if (value.includes('.')) {
                let arrayValue = value.split('');

                return arrayValue[0] == '.' || (arrayValue[0] == '.' && value.length == 1) || 
                        arrayValue[arrayValue.length -1] == '.';
            }
        },
        updateWorksheet: debounce(function(item, isOrder = false, nextId, prevId) {
            const index = this.worksheet.items.findIndex(el => el.id === item.id);
            let amount = item.contract_amount.toString();
            let order = item.change_order.toString();

            if(this.checkMinusValidation(amount) || this.checkMinusValidation(order)) {
                this.notifyError('Value entered is invalid. Check the value and try again.')
                return
            }

            if(this.checkDotValidation(amount) || this.checkDotValidation(order)) {
                this.notifyError('Value entered is invalid. Check the value and try again.')
                return
            }
            
            this.$validator.validate('base_contract_worksheet_name_' + index).then(result => {
                if (!result) {
                    this.notifyError('Please fill Description field for save item')
                    return
                } else if (item.id) {
                    let params = {
                        description: item.description,
                        contract_amount: item.contract_amount ? item.contract_amount : 0,
                        change_order: item.change_order ? item.change_order : 0,
                        is_order: isOrder
                    }

                    if(isOrder) {
                        params.next_id = nextId;
                        params.prev_id = prevId;
                    }
                    
                    api.put(`worksheet/${item.id}`, params)
                        .then(response => {
                            this.notifySuccess('Worksheet updated');
                        })
                        .catch(error => {
                            this.notifyRequestErrors(error);
                        });
                } else {
                    let params = {
                        description: item.description,
                        contract_amount: item.contract_amount ? item.contract_amount : 0,
                        change_order: item.change_order ? item.change_order : 0
                    }
                    api.post(`worksheet/create-item/${this.worksheet.id}`, params )
                        .then( response => {
                            this.notifySuccess('Worksheet created');
                            this.worksheet.items = response.data.data.items
                        })
                        .catch(error => {
                            this.notifyRequestErrors(error);
                        });
                }
            });
        }, 1000),
        addNewItem() {
            this.worksheet.items.push({
                description: '',
                contract_amount: '',
                change_order: ''
            })
            
            this.$nextTick(() => {
                let newItem = document.querySelectorAll('.base-contract-worksheet')[this.worksheet.items.length -1];
                let textarea = newItem.querySelector('.worksheet-description-field');
                textarea.focus();
            })
        },
        deleteItem(item, index) {
            this.worksheet.items.splice(index, 1);
            this.activeWorksheetTooltip = null;
            if (item.id) {
                api.delete(`worksheet/item/${item.id}`)
                    .then(response => {
                        this.notifySuccess('Item has been deleted')
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            }
        },
        onDeleteItems(ids) {
            for (const id in ids) {
                let index = this.worksheet.items.findIndex(i => i.id === ids[id]);
                this.worksheet.items.splice(index, 1);
            }
        },
        propertiesDragStart(item, index, event) {
            this.propertiesDragging = item;
            this.propertiesDraggingIndex = index;
            this.activeIconLibrary = null;
        },
        propertiesDragOver(item, index) {
            this.propertiesOverIndex = index;

            if (this.propertiesOverIndex !== this.propertiesDraggingIndex) {
                this.worksheet.items.splice(this.propertiesDraggingIndex, 1);
                this.worksheet.items.splice(this.propertiesOverIndex, 0, this.propertiesDragging);
                this.propertiesDraggingIndex = index;
            }     
        },
        propertiesDragEnd(item, index) {
            if(index != this.propertiesOverIndex) {
            let nextId = this.propertiesOverIndex == 0 ? 0 : this.worksheet.items[this.propertiesOverIndex - 1].id;
            let prevId = this.propertiesOverIndex == this.worksheet.items.length -1 ? 0 : this.worksheet.items[this.propertiesOverIndex + 1].id;
            let isOrder = true;
            this.updateWorksheet(this.propertiesDragging, isOrder, nextId, prevId);
            }
            this.propertiesDragging = '';
            this.propertiesDraggingIndex = null;
            this.propertiesOverIndex = null;

        },
        enableDrag() {
            let worksheet = document.querySelectorAll('.base-contract-worksheet');
            worksheet.forEach(function(item) {
                item.draggable = true;
            })
        },
        disableDrag() {
            let worksheet = document.querySelectorAll('.base-contract-worksheet');
            worksheet.forEach(function(item) {
                item.draggable = false;
            });
        },
        moveWorksheetUp(index, item) {
            const prevIndex = index - 1;
            // swap array items
            let rows = [this.worksheet.items[prevIndex], this.worksheet.items[index]];
            this.worksheet.items.splice(prevIndex, 2, rows[1], rows[0]);
            // change order
            let send_rows = [this.worksheet.items[index], this.worksheet.items[index - 2]]
            let isOrder = true;
            let next_id = send_rows[1].id ? send_rows[1].id : 0;
            let prev_id = send_rows[0].id ? send_rows[0].id : 0;
            this.updateWorksheet(item, isOrder, next_id, prev_id)
        },
        moveWorksheetDown(index, item) {
            const nextIndex = index + 1;
            // swap array items
            let rows = [this.worksheet.items[index], this.worksheet.items[nextIndex]];
            this.worksheet.items.splice(index, 2, rows[1], rows[0]);
            // change order
            let send_rows = [this.worksheet.items[index], this.worksheet.items[index + 2]]
            let isOrder = true;
            let next_id = send_rows[1].id ? send_rows[1].id : 0;
            let prev_id = send_rows[0].id ? send_rows[0].id : 0;
            this.updateWorksheet(item, isOrder, next_id, prev_id);
        }
    }
}
</script>