<template>
    <svg
        :width="size"
        :height="size"
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_3_1350)">
            <circle
                cx="15.1082"
                cy="15.0785"
                r="10.3002"
                fill="#2ECC71"
            />
        </g>
        <path
            d="M18.4687 19.2956L17.082 20.6713C16.8773 20.8812 16.6059 20.9523 16.3417 20.9526C15.1731 20.9176 14.0686 20.3436 13.1616 19.7542C11.673 18.6712 10.3071 17.3283 9.44983 15.7055C9.12104 15.025 8.73525 14.1568 8.77211 13.3972C8.77539 13.1115 8.85239 12.8311 9.05362 12.6469L10.4403 11.2609C10.7283 11.0159 11.0068 11.1006 11.191 11.3859L12.3067 13.5014C12.4241 13.7521 12.3567 14.0206 12.1815 14.1997L11.6706 14.7103C11.6391 14.7535 11.619 14.8027 11.6185 14.8562C11.8144 15.6146 12.4079 16.3135 12.9322 16.7946C13.4565 17.2756 14.0201 17.9273 14.7517 18.0816C14.8421 18.1068 14.9528 18.1157 15.0175 18.0555L15.6119 17.4511C15.8167 17.2958 16.1125 17.2199 16.3313 17.3469H16.3417L18.354 18.5349C18.6494 18.7201 18.6802 19.0779 18.4687 19.2956V19.2956Z"
            fill="white"/>
        <path
            d="M16.809 12.0253C16.6543 12.18 16.4035 12.18 16.2487 12.0253L14.9922 10.7687C14.7426 10.5192 14.3159 10.6959 14.3159 11.0489V15.2425C14.3159 15.4613 14.4933 15.6387 14.7121 15.6387H18.9058C19.2587 15.6387 19.4354 15.212 19.1859 14.9624L17.9293 13.7059C17.7746 13.5512 17.7746 13.3003 17.9293 13.1456L20.9345 10.1404C21.0892 9.98571 21.0892 9.73488 20.9345 9.58017L20.3744 9.02008C20.2197 8.86537 19.9689 8.86537 19.8142 9.02008L16.809 12.0253Z"
            fill="white"/>
        <defs>
            <filter id="filter0_d_3_1350" x="1.6387" y="1.60904" width="26.9389" height="26.9389"
                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                               result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="1.58464"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.1625 0 0 0 0 0.03575 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_1350"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_1350" result="shape"/>
            </filter>
        </defs>
    </svg>
</template>

<script>
    export default {
        name: 'IncomingCall',
        props: {
            size: {
                type: [Number, String],
                default: 30
            }
        }
    }
</script>
