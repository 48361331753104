<template>
    <label class="trade-button relative">
        <slot name="default"/>
        <span :class="['trade s-align-5', disabled ? 'cursor-default' : 'pointer']">
            <span
                v-if="isIcon" 
                v-html="icon"
                class="trade-button-icon mr-2"
            />
            <span 
                v-if="label.length"
                :class="[
                    'f-sbold flex-no-wrap row-start s-align-4',
                    { 'pr-1': isIcon }
                ]"
            >
                <span>{{ label }}</span>
                <slot name="isSalesRabbit"/>
            </span>
        </span>
    </label>
</template>

<script>
    export default {
        name: 'Trade',
        props: {
            label: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String,
                default: ''
            }
        },
        computed: {
            isIcon() {
                return this.icon && this.icon.length;
            }
        }
    }
</script>
