<template>
    <svg 
	    viewBox="0 0 84.01788330078125 436.98663330078125"
        :width="width" 
        :height="height"
        class="points-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="84.01788330078125" 
            height="436.98663330078125" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(19.600000381469727, 0, 0, 19.600000381469727, -379.6875, -114.97767639160163)">
            <path 
                d="M 23.5 8 A 2 2 0 0 1 21.5 10 A 2 2 0 0 1 19.5 8 A 2 2 0 0 1 21.5 6 A 2 2 0 0 1 23.5 8 Z" 
                id="XMLID_1_"
            />
            <path 
                d="M 23.5 17 A 2 2 0 0 1 21.5 19 A 2 2 0 0 1 19.5 17 A 2 2 0 0 1 21.5 15 A 2 2 0 0 1 23.5 17 Z" 
                id="XMLID_2_"
            />
            <path 
                d="M 23.5 26 A 2 2 0 0 1 21.5 28 A 2 2 0 0 1 19.5 26 A 2 2 0 0 1 21.5 24 A 2 2 0 0 1 23.5 26 Z" id="XMLID_3_"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'PointsIcon',
        props: {
            width: {
                type: [Number, String],
                default: 15
            },
            height: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>