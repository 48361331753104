<template>
    <svg 
        viewBox="-247 483.9 15 19"
        :width="size" 
        :height="size"
        class="add-document-icon"
    >
        <path d="M-232,499.4c0,0.4-0.4,0.8-0.8,0.8h-1.9v1.9c0,0.4-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8v-1.9h-1.9c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8h1.9v-1.9c0-0.4,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v1.9h1.9C-232.4,498.6-232,499-232,499.4z"/>
        <g>
            <path d="M-233.5,483.9h-7.5c-0.2,0-0.4,0.1-0.6,0.2l-5.2,5.2c-0.1,0.1-0.2,0.3-0.2,0.6v11.5c0,0.4,0.4,0.8,0.8,0.8h5.2c0.4,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8h-4.4v-10h4.4c0.4,0,0.7-0.3,0.7-0.7v-4.4h6v8.4c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-9.2C-232.7,484.2-233.1,483.9-233.5,483.9z M-241.7,489.2h-2.7l2.7-2.7V489.2z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'AddDocumentIcon',
        props: {
            size: {
                type: [Number, String],
                default: 18
            }
        }
    }
</script>