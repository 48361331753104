<template>
    <div class="percent-count row-between">
        <button 
            class="transparent-button"
            @click="changeByClick(DECREASE)"
            :disabled="disabled"
        >
            <dash-icon 
                size="10" 
                class="i-grey"
            />
        </button>
        <input 
            type="text" 
            name="percent_count"
            v-model="value"
            class="percent-count-input"
            @blur="finishChangingManually"
            :disabled="disabled"
            v-limiter
        />
        <div 
            :class="['percent-count-mask s-align-5', { 'pointer' : !disabled }]"
            @click="inputInFocus"
            v-if="showMask"
        >
            <span :class="[disabled ? 'f-13-grey' : 'f-13-darkgrey']">
                {{ value }}%
            </span>
        </div>
        <button 
            class="transparent-button"
            @click="changeByClick(INCREASE)"
            :disabled="disabled"
        >
            <add-icon 
                size="10" 
                class="i-grey"
            />
        </button>
    </div>
</template>

<script>
    import { DashIcon, AddIcon } from '@/icons';

    export default {
        name: 'PercentCount',
        components: {
            DashIcon, 
            AddIcon
        },
        props: {
            profitMargin: {
                type: [String, Number],
                required: true
            },
            sectionIndex: {
                type: Number,
                default: 0
            },
            disabled: {
                type: Boolean,
                default: false
            },
            reset: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {                
                value: 0,
                showMask: true
            }
        },
        watch: {
            reset: {
                handler(newValue, oldValue) {
                    this.value = 0;
                    this.sendResult();
                },
                immediate: true
            },
            value(newValue, oldValue) {
                if (newValue < 0) {
                    this.value = 0;  
                    this.sendResult();                  
                } else if (newValue > 99) {
                    this.value = 99;
                    this.sendResult();
                } 
            }
        },
        methods: {
            changeByClick(direction) {
                direction == this.INCREASE 
                    ? this.value++
                    : this.value--;   
                
                this.sendResult();
            },
            finishChangingManually() {
                this.showMask = true;  
                this.sendResult();
            },
            inputInFocus() {
                if (this.disabled) return false;

                this.$el.querySelector('.percent-count-input').focus();
                this.showMask = false;
            },
            sendResult() {
                this.$nextTick(function() {
                    this.$emit('set', this.value, this.sectionIndex);
                });
            }
        },
        created() {
            this.value = +this.profitMargin;

            this.DECREASE = 'decrease';
            this.INCREASE = 'increase';
        }
    }
</script>
