<template>
    <full-modal
        @close="$emit('close')"
        class="pick-list-modal"
    >
        <div slot="title">
            {{ 
                `${editingField.type ? editingField.type.label : editingField.label} properties` 
            }}
        </div>
        <div slot="body">
            <p class="f-14-darkgrey f-sbold mb-3">Field label</p>
            <form-input 
                label="Field label"
                :class="[
                    'mb-25',
                    {'form-input-danger': errors.first('pick_list_name')}
                ]"
            >
                <input 
                    class="form-input-field"
                    name="pick_list_name"
                    type="text"
                    placeholder="Field label"
                    v-model="formData.label"
                    v-input-style="formData.label"
                    maxlength="31"
                    v-validate="'required|min:3|max:30'"
                >
            </form-input>
            <div class="mb-8">
                <p class="f-14-darkgrey f-sbold mb-3">Add and prioritize options</p>
                <ul class="w-100">
                    <li
                        v-for="(item, index) in parameters.properties"   
                        :key="index"  
                        class="pick-list-property row-start row-baseline mb-1"
                        @dragover="propertiesDragOver(item, index)" 
                        @dragend="propertiesDragEnd(item, index)"
                        @dragstart="propertiesDragStart(item, index, $event)"
                        :class="{
                            selected: (propertiesDraggingIndex === index),
                            over: (index === propertiesOverIndex && index !== propertiesDraggingIndex)
                        }"
                        draggable="true"  
                    >
                        <line-icon 
                            size="15"
                            class="i-grey grabbable"
                        />
                        <form-input
                            :class="[{ 'form-input-danger': errors.has(`pick_list_property_${index}`) }]"
                        >
                            <input 
                                class="form-input-field"
                                type="text"
                                :name="`pick_list_property_${index}`"
                                placeholder="Enter value"
                                v-model="parameters.properties[index].value"
                                maxlength="31"
                                v-validate="'required|max:30'"
                            >
                        </form-input>
                        <button 
                            class="pick-list-delete transparent-button"
                            @click="parameters.properties.splice(index, 1)"
                        >
                            <trash-icon 
                                :width="17"
                                :height="17"
                                class="i-grey"
                            />
                        </button>
                    </li>
                </ul>
                <button 
                    class="custom-fields-new-item s-align-5 w-100"
                    @click="addNewOption"
                >
                    <div class="round s-align-5 mr-5">
                        <add-icon size="9"/>
                    </div>
                    <span class="f-13-blue f-sbold">New option</span>
                </button>
            </div>
            <checkbox-button 
                class="mb-8"
                label="Required"
                color="dark-blue"
            >
                <input 
                    name="required"
                    v-model="parameters.required"
                    type="checkbox"
                />
            </checkbox-button>
            <checkbox-button 
                label="Show Tooltip"
                color="dark-blue"
            >
                <input 
                    name="show_tooltip"
                    v-model="parameters.show_tooltip"
                    type="checkbox"
                />
            </checkbox-button>
            <form-input 
                v-if="parameters.show_tooltip"
                label="Enter help text here"
                :class="[
                    'mt-6',
                    {'form-input-danger': errors.first('custom-field-tooltip-text')}
                ]"
            >
                <input 
                    class="form-input-field"
                    name="custom-field-tooltip-text"
                    type="text"
                    placeholder="Enter help text here"
                    v-model="parameters.tooltip_text"
                    v-input-style="parameters.tooltip_text"
                    maxlength="100"
                    v-validate="'required|min:3|max:99'"
                >
            </form-input>
        </div>
        <div slot="footer">
            <button 
                class="add-button mb-2"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button mb-2"
                @click="updateField"
                :disabled="requestIsGoing"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import api from '@/headers.js';
    import { LineIcon, TrashIcon, AddIcon } from '@/icons';
    import { FullModal, CheckboxButton, FormInput } from '@/ui';
    import { CUSTOM_FIELDS_TYPES, CUSTOM_FIELDS_DRAG_SOURCES } from '@/constants.js';
    import uniq from 'lodash/uniq';

    export default {
        name: 'CustomFieldsMultiselectModal',
        components: {
            LineIcon, 
            TrashIcon, 
            FullModal,
            CheckboxButton,
            FormInput,
            AddIcon
        },
        props: {
            editingField: {
                type: Object,
                required: true
            },
            sectionId: {
                type: [String, Number],
                required: true
            },
            order: {
                type: [String, Number],
                required: false
            }
        },
        data() {
            return {
                isEdit: false,
                propertiesOverIndex: null,
                propertiesDragging: '',
                propertiesDraggingIndex: null,
                formData: {
                    label: '',
                    section_id: this.sectionId,
                    type_id: this.editingField.id,
                    order: null
                },
                parameters: {
                    properties: [],
                    required: false,
                    show_tooltip: false,
                    tooltip_text: ''
                },
                requestIsGoing: false
            }
        },
        computed: {
            isMultiselectOrPickList: function() {
                if (this.isEdit) {
                    return this.editingField.type.name === CUSTOM_FIELDS_TYPES.PICK_LIST
                        || this.editingField.type.name === CUSTOM_FIELDS_TYPES.MULTISELECT;
                }

                return this.editingField.name === CUSTOM_FIELDS_TYPES.PICK_LIST
                    || this.editingField.name === CUSTOM_FIELDS_TYPES.MULTISELECT;
            }
        },
        created() {
            if (this.editingField.parameters) {
                this.formData.label = this.editingField.label;
                this.formData.id = this.editingField.id,
                this.formData.section_id = this.editingField.section_id;
                this.formData.type_id = this.editingField.type.id;
                this.formData.order = this.editingField.order;
                this.parameters = JSON.parse(this.editingField.parameters);
                this.isEdit = true;
            }

            this.formData.order = this.order;
        },
        methods: {
            saveFieldProps() {
                this.$emit('close');
            },
            propertiesDragStart(item, index, event) {                
                this.$store.commit('customFields/SET_DRAG_SOURCE', CUSTOM_FIELDS_DRAG_SOURCES.PICK_LIST);
                this.propertiesDragging = item;
                this.propertiesDraggingIndex = index;
            },
            propertiesDragOver(item, index) {
                this.propertiesOverIndex = index;

                if (this.propertiesOverIndex !== this.propertiesDraggingIndex) {
                    this.parameters.properties.splice(this.propertiesDraggingIndex, 1)
                    this.parameters.properties.splice(this.propertiesOverIndex, 0, this.propertiesDragging);

                    this.propertiesDraggingIndex = index;
                }     
            },
            propertiesDragEnd(item, index) {
                this.$store.commit('customFields/SET_DRAG_SOURCE', '');
                this.propertiesDragging = '';
                this.propertiesDraggingIndex = null;
                this.propertiesOverIndex = null;
            },
            updateField() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('Please fill out all fields!');

                        return;
                    };

                    if (this.isMultiselectOrPickList) {
                        let uniqLength = uniq(this.parameters.properties.map(i => i.value)).length;
                        
                        if (uniqLength !== this.parameters.properties.length) {
                            this.notifyWarning('Values must be unique!');
                            return;
                        }
                    }

                    this.requestIsGoing = true;
                
                    let method = this.isEdit ? 'put' : 'post';
                    let action = this.isEdit ? 'updated' : 'created'
                    let params = this.formData;
                    params.parameters = JSON.stringify(this.parameters);

                    api[method]('/layouts/custom-fields', params)
                        .then((response) => {
                            this.notifySuccess(`Custom field has been ${action}.`);

                            if (this.isEdit) {
                                this.$emit('updateField', response.data.data)
                            } else {
                                this.$emit('createField', response.data.data);
                            }
                        })
                        .catch(error => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.requestIsGoing = false;
                        });
                    })
            },
            addNewOption() {
                // generate random id
                const id = `f${(+new Date).toString(16)}`;
                this.parameters.properties.push({
                    id,
                    value: ''
                });
            }
        }
    }
</script>

