<template>
    <div class="search-box s-align-4">
        <button 
            class="search-box-icon icon-dark mh-8"
            @click="$refs.search.getSearchResult(needToFind, 4)"
        >
            <search-icon size="18"/>
        </button>
        <input 
            type="text"
            class="search-box-input"
            name="search"
            placeholder="Search..."
            v-model="needToFind"
            @keyup.enter="$refs.search.getSearchResult(needToFind, 4)"
            maxlength="99"
            autocomplete="off"
        />
        <search-result
            showAllResultBtn
            showCloseBtn
            class="search-box-result w-box" 
            ref="search"
            @click.native="$emit('closeMenuOnRedirect'), needToFind = ''"
        />
    </div>
</template>

<script>
    import { SearchIcon } from '@/icons';
    import SearchResult from './SearchResult.vue';

    export default {
        name: 'SearchBox',
        components: {
            SearchIcon,
            SearchResult
        },
        data() {
            return {
                needToFind: '',
            }
        }
    }
</script>

