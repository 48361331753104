<template>
    <svg 
        viewBox="0 0 685.76123046875 581.0772705078125"
        :width="width" 
        :height="height"
        class="camera-icon"
    >
        <rect 
            width="685.76" 
            height="581.08" 
            fill-opacity="0" 
            pointer-events="none"
        />
        <g transform="matrix(7.84 0 0 7.84 3314.2 -1808.3)">
            <path d="m-336.2 254.5c0-5.5-4.5-10-10-10h-12.1l-1.3-5.2c-1.1-4.4-5.1-7.6-9.7-7.6h-19.4c-4.6 0-8.6 3.1-9.7 7.6l-1.3 5.2h-12.1c-5.5 0-10 4.5-10 10v39.7c0 5.5 4.5 10 10 10h65.7c5.5 0 10-4.5 10-10v-39.7zm-4 39.7c0 3.3-2.7 6-6 6h-65.7c-3.3 0-6-2.7-6-6v-39.7c0-3.3 2.7-6 6-6h13.7c0.9 0 1.7-0.6 1.9-1.5l1.7-6.7c0.7-2.7 3.1-4.5 5.8-4.5h19.4c2.8 0 5.2 1.9 5.8 4.5l1.7 6.7c0.2 0.9 1 1.5 1.9 1.5h13.7c3.3 0 6 2.7 6 6v39.7z"/>
            <path d="m-379 251.2c-11.6 0-21 9.4-21 21s9.4 21 21 21 21-9.4 21-21-9.4-21-21-21zm0 38.1c-9.4 0-17-7.6-17-17s7.6-17 17-17 17 7.6 17 17-7.6 17-17 17z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'CameraIcon',
        props: {
            width: {
                type: [Number, String],
                default: 33
            },
            height: {
                type: [Number, String],
                default: 28
            }
        }
    }
</script>
