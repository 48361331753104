<template>
    <svg 
        viewBox="0 0 265 300"
        :width="size"
        :height="size"
        class="admin-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="265" 
            height="300" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(14.480177879333496, 0, 0, 14.480177879333496, 5621.116378857654, -3917.220998842936)">
            <g>
                <path 
                    class="st0" 
                    d="M-370.4,275c0-0.4-0.4-0.8-0.8-0.8c-2.7,0-5.2-1-7.2-2.8c-0.3-0.3-0.8-0.3-1.1,0c-1.9,1.8-4.5,2.7-7.2,2.7c-0.4,0-0.8,0.4-0.8,0.8v9.7c0,0.1,0,0.2,0.1,0.3l0,0.1c1.3,2.9,3.9,5.1,7.1,5.9c0.1,0,1.3,0.3,1.3,0.3l0,0l0,0c0,0,1.2-0.3,1.3-0.3c3.2-0.8,5.8-3,7.1-6l0,0c0-0.1,0.1-0.2,0.1-0.3L-370.4,275z M-372,275.7v8.8c-1.1,2.4-3.3,4.2-6,4.9l-0.9,0.2l-0.9-0.2c-2.7-0.7-4.8-2.5-6-4.9v-8.8c2.5-0.2,4.9-1.1,6.9-2.7C-377,274.6-374.6,275.6-372,275.7z"
                />
                <path 
                    class="st0" 
                    d="M-379.8,279.3c0,0.3,0.1,0.7,0.3,1l-3.3,3.2l-0.1,0.1l2,1.9l1.1-1.1l-0.8-0.8l0.2-0.2l0.8,0.8l1.1-1.1l-0.8-0.8l1-1c0.3,0.1,0.7,0.2,1,0.2c1.3,0,2.4-1,2.4-2.3c0-1.3-1.1-2.3-2.4-2.3C-378.7,277-379.8,278-379.8,279.3z M-378.1,279.3c0-0.4,0.3-0.8,0.8-0.8c0.5,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8C-377.8,280.1-378.1,279.7-378.1,279.3z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'AdminIcon',
        props: {
            size: {
                type: [Number, String],
                default: 18
            }
        }
    }
</script>

