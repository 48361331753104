<template>
    <svg 
        viewBox="-475.06 251.43 48 48"
        :width="size" 
        :height="size"
        :style="{ transform: 'rotate('+ transform + 'deg)' }"
        class="move-icon"
    >
        <path d="M-427.4,274.43l-13.73-12.75v25.51L-427.4,274.43L-427.4,274.43z"/>
        <path d="M-474.73,274.43l13.73-12.75v25.51L-474.73,274.43L-474.73,274.43z"/>
    </svg>
</template>

<script>
    export default {
        name: 'MoveIcon',
        props: {
            size: {
                type: [Number, String],
                default: 10
            },
            transform: {
                type: [Number, String],
                default: 180
            }
        }
    }
</script>
