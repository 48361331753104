var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"board-card w-100",on:{"dragstart":function($event){return _vm.$emit('dragstart')},"dragenter":function($event){return _vm.$emit('dragenter')},"dragend":function($event){return _vm.$emit('dragend')},"drop":function($event){return _vm.$emit('drop')}}},[(!_vm.archiveCard)?[(!_vm.columnIsArchive)?_c('button',{directives:[{name:"can",rawName:"v-can:some",value:([_vm.$permissions.DELETE_CARDS, _vm.$permissions.EDIT_CARDS]),expression:"[$permissions.DELETE_CARDS, $permissions.EDIT_CARDS]",arg:"some"}],staticClass:"card-context-menu-icon icon-light",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('showCardContextMenu', _vm.card.id)}}},[_c('points-icon',{attrs:{"width":"5","height":"15"}})],1):_vm._e(),(_vm.cardContextMenuId === _vm.card.id)?_c('div',{staticClass:"card-context-menu context-menu"},[(_vm.card.is_archive)?_c('button',{directives:[{name:"can",rawName:"v-can",value:([_vm.$permissions.DELETE_CARDS]),expression:"[$permissions.DELETE_CARDS]"}],staticClass:"delete-field s-align-4",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('showDeleteCardConfirm', _vm.card, _vm.columnIndex, _vm.cardIndex)}}},[_c('span',{staticClass:"icon-container mr-6"},[_c('trash-icon',{staticClass:"mr-8"})],1),_c('span',[_vm._v("Delete Card")])]):_c('button',{directives:[{name:"can",rawName:"v-can",value:([_vm.$permissions.EDIT_CARDS]),expression:"[$permissions.EDIT_CARDS]"}],staticClass:"delete-field s-align-4",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('showArchiveCardConfirm', _vm.card, _vm.columnIndex, _vm.cardIndex)}}},[_c('span',{staticClass:"icon-container mr-6"},[_c('archive-icon',{staticClass:"mr-8"})],1),_c('span',[_vm._v("Archive Card")])])]):_vm._e()]:_vm._e(),(_vm.card.date_of_completion || _vm.archiveCard)?_c('div',{class:[
            'row-baseline mb-2',
            _vm.archiveCard ? 'row-end' : 'row-start'
        ]},[(_vm.card.date_of_completion)?_c('div',{staticClass:"w-50"},[_c('clock-icon',{staticClass:"i-grey mr-1",attrs:{"size":"11"}}),_c('span',{staticClass:"f-11-grey mr-1"},[_vm._v("Due")]),_c('span',{staticClass:"f-11-grey"},[_vm._v(_vm._s(_vm._f("date")(_vm.card.date_of_completion)))])],1):_vm._e(),(_vm.archiveCard)?_c('div',{staticClass:"w-50 row-end"},[_c('button',{staticClass:"unarchive-btn transparent-button s-align-4",attrs:{"disabled":_vm.requestIsGoing},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('restoreCard')}}},[_c('next-icon',{staticClass:"i-blue mr-2",attrs:{"transform":"180","width":"9","height":"7"}}),_c('span',{staticClass:"f-11-blue"},[_vm._v("Unarchive")])],1),_c('button',{staticClass:"delete-btn transparent-button ml-1 s-align-5",attrs:{"disabled":_vm.requestIsGoing},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('showDeleteCardConfirm')}}},[_c('trash-icon',{staticClass:"i-grey",attrs:{"width":"16","height":"12"}})],1)]):_vm._e()]):_vm._e(),_c('p',{staticClass:"card-title f-14-black f-sbold mb-3"},[_vm._v("\n        "+_vm._s(_vm.card.title)+"\n    ")]),(_vm.card.tags.length)?_c('div',{staticClass:"card-tags row-start mb-4"},_vm._l((_vm.card.tags),function(tag,index){return _c('span',{key:index,class:['card-tag', ("card-tag-" + (tag.color))]},[(_vm.isTagsNameShow)?_c('p',{staticClass:"p-2"},[_vm._v("\n                "+_vm._s(tag.name)+"\n            ")]):_vm._e()])}),0):_vm._e(),_c('div',{staticClass:"card-footer row-between row-baseline"},[_c('div',{staticClass:"card-badges"},[_c('div',{class:['badge', {'badge-highlight' : _vm.card.count_comments}]},[_c('notes-icon',{attrs:{"size":"14"}}),_c('span',[_vm._v(_vm._s(_vm.card.count_comments))])],1),_c('div',{class:['badge', {'badge-highlight' : _vm.card.count_files}]},[_c('attach-icon',{attrs:{"size":"13"}}),_c('span',[_vm._v(_vm._s(_vm.card.count_files))])],1),_c('div',{class:[
                    'badge',
                    { 'badge-completed' : 
                        _vm.card.count_todo_completed === _vm.card.count_todo_total
                        && _vm.card.count_todo_total !== 0
                    }
                ]},[_c('checkbox-icon',{attrs:{"size":"14"}}),_c('span',[_vm._v(_vm._s(((_vm.card.count_todo_completed) + "/" + (_vm.card.count_todo_total))))])],1)]),(_vm.card.designated_user)?_c('avatar',{staticClass:"card-avatar",attrs:{"url":_vm.card.designated_user.thumbnail,"name":((_vm.card.designated_user.first_name) + " " + (_vm.card.designated_user.last_name)),"border":false,"size":"30"}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }