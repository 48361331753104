<template>
    <svg 
        viewBox="0 0 500 500"
        class="warning-icon"
        :height="size"
        :width="size"
    >
        <g>
            <g>
                <path d="M246.2,340.2c-13.6,0-25,11.4-25,25c0,13.6,11.4,25,25,25c13.1,0,25-11.4,24.4-24.4C271.2,351.5,259.9,340.2,246.2,340.2z"/>
		        <path d="M477.6,428.8c15.7-27.1,15.8-59.4,0.2-86.4L321.2,71.2c-15.5-27.3-43.5-43.5-74.9-43.5S186.9,44,171.4,71.1L14.6,342.5c-15.6,27.3-15.5,59.8,0.3,86.9c15.6,26.8,43.5,42.9,74.7,42.9h312.8C433.7,472.4,461.8,456.1,477.6,428.8z M443.6,409.2c-8.7,15-24.1,23.9-41.3,23.9H89.5c-17,0-32.3-8.7-40.8-23.4C40.1,394.8,40,377,48.6,362L205.4,90.6c8.5-14.9,23.7-23.7,40.9-23.7c17.1,0,32.4,8.9,40.9,23.8L443.9,362C452.3,376.7,452.2,394.2,443.6,409.2z"/>
		        <path d="M240,164.7c-11.9,3.4-19.3,14.2-19.3,27.3c0.6,7.9,1.1,15.9,1.7,23.8c1.7,30.1,3.4,59.6,5.1,89.7c0.6,10.2,8.5,17.6,18.7,17.6s18.2-7.9,18.7-18.2c0-6.2,0-11.9,0.6-18.2c1.1-19.3,2.3-38.6,3.4-57.9c0.6-12.5,1.7-25,2.3-37.5c0-4.5-0.6-8.5-2.3-12.5C263.8,167.6,251.9,161.9,240,164.7z"/>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'WarningIcon',
    props: {
        size: {
            type: [Number, String],
            default: 20
        }
    }
}
</script>

