<template>
    <div class="form-builder-templates-nav row-between">
        <div class="templates-menu">
            <tabs-menu 
                class="tabs-menu-colored"
                :scrollToStep="scrollToStep"
                :backlight="true"
                identifier="form-builder-templates-tabs"
                v-if="canCreateNewTemplate"
            >
                <li
                    v-for="link in layouts"
                    :key="link.id"                
                    v-on:click="changeLayout(link)"
                    :class="[{ 'pointer' : !loading }]"
                    slot="links"
                >
                    <span 
                        :class="[
                            'tabs-menu-item s-align-5 h-100', 
                            { 'tabs-menu-item-active' : link.name === currentLayout.name }
                        ]"
                    >
                        <span class="tabs-menu-colored-area">{{ link.name }}</span>
                    </span>
                </li>
            </tabs-menu>
        </div>
        <div :class="['edit-templates s-align-5 relative', { 'bb-grey': canCreateNewTemplate }]">
            <context-menu unicId="form-builder-edit">
                <button
                    class="s-align-4"
                    @click="showEditTemplatesModal = true"
                    v-if="layoutsLength"
                >
                    <span class="icon-container mr-6">
                        <edit-icon/>
                    </span>
                    <span>Edit Templates</span>
                </button>
                <button
                    class="s-align-4"
                    @click="showAddTemplateModal = true"
                >
                    <span class="icon-container mr-6">
                        <add-icon size="12"/>
                    </span>
                    <span>Add Template</span>
                </button>
            </context-menu>
        </div>
        <custom-fields-create-layout-modal
            v-if="showAddTemplateModal"
            type="formBuilder"
            :order="lastOrder"
            @close="showAddTemplateModal = false"
            @created="addTemplate"
        />
        <custom-fields-edit-form-builder-modal
            v-if="showEditTemplatesModal"
            type="formBuilder"
            :items="layouts"
            @close="closeModal"
        />
    </div>
</template>

<script>
    import { TabsMenu } from '@/components';
    import { EditIcon, AddIcon } from '@/icons';
    import { MODEL_NAMES } from '@/constants';
    import ContextMenu from '@/components/ContextMenu.vue';
    import api from '@/headers.js';

    export default {
        name: "FormBuilderNav",
        components: {
            CustomFieldsCreateLayoutModal: () => import('@/modals/CustomFieldsCreateLayoutModal.vue'),
            CustomFieldsEditFormBuilderModal: () => import('@/modals/CustomFieldsEditFormBuilderModal.vue'),
            TabsMenu,
            EditIcon,            
            AddIcon,
            ContextMenu
        },
        data() {
            return {
                layouts: [],
                currentLayout: null,
                showAddTemplateModal: false,
                showEditTemplatesModal: false,
                lastOrder: 0,
                canCreateNewTemplate : false,
                contextMenuIsOpen: false,
                scrollToStep: 0
            }
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            }  
        },
        computed: {
            layoutsLength() {
                return this.layouts.length;
            }
        },
        methods: {
            getLayouts() {
                api.get(`/layouts/model-name?model_name=${MODEL_NAMES.REPORT}`)
                    .then(response => {
                        this.layouts = response.data.data;

                        if (this.layoutsLength) {
                            this.lastOrder = this.layouts.reduce(function(prev,cur) {
                                return cur.main_section_order > prev.main_section_order ? cur : prev;
                            }).main_section_order;
                            this.checkCurrentLayout();
                        } else {
                            this.$emit('getLayoutSections', null);
                        }

                        this.canCreateNewTemplate = true;
                    });
            },
            changeLayout(layout) {
                if (layout.name === this.currentLayout.name || this.loading)
                    return;
                this.currentLayout= layout;
                this.$router.push({ query: { report: layout.name } });
                this.$emit('getLayoutSections', this.currentLayout.id);
            },
            checkCurrentLayout(){
                this.currentLayout = this.layouts.find(layout => layout.name === this.$route.query.report);
                if (!this.currentLayout) {
                    this.$router.push({ query: { report: this.layouts[0].name } });
                    this.currentLayout = this.layouts[0];
                }
                this.$emit('getLayoutSections', this.currentLayout.id);

                this.scrollToStep = this.layouts.findIndex(c => c.id === this.currentLayout.id);
            },
            addTemplate(data) {
                this.layouts.push(data);
                this.lastOrder = data.main_section_order;
                this.showAddTemplateModal = false;
            },
            closeModal(data) {
                this.showEditTemplatesModal = false;
                if (data != null) {
                    this.layouts = data;
                    this.checkCurrentLayout();
                }
            }
        },
        created() {
            this.getLayouts();
        }
    }
</script>