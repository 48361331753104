<template>
    <svg 
        viewBox="0 0 530.6256103515625 403.0515441894531" 
        :width="size" 
        :height="size"
        class="menu-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="530.6256103515625" 
            height="403.0515441894531" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(7.840000152587891, 0, 0, 7.840000152587891, 3237.4632568359375, -1898.8016662597656)">
            <path d="M-409.1,286.5c20.2,0,30.3,0,50.5,0c3.4,0.1,4.2,5.4,1,6.5c-2.4,0.5-4.9,0.2-7.3,0.3c-18.2,0-26.4,0-44.6,0c-3.4-0.1-4.2-5.4-1.1-6.5C-410,286.5-409.6,286.5-409.1,286.5z M-409.1,264.6c20.2,0,40.3,0,60.5,0c3.4,0.1,4.2,5.4,1,6.5c-2.4,0.5-4.9,0.2-7.3,0.3c-18.2,0-36.4,0-54.6,0c-3.4-0.1-4.2-5.4-1-6.5C-410,264.7-409.5,264.6-409.1,264.6z M-409.1,242.7c20.2,0,10.3,0,30.5,0c3.4,0.1,4.2,5.4,1,6.5c-2.4,0.5-4.9,0.2-7.3,0.3c-18.2,0-6.4,0-24.6,0c-3.4-0.1-4.2-5.4-1-6.5C-410,242.8-409.6,242.7-409.1,242.7z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'MenuIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>
