<template>
    <div class="make-call-widget">
        <ctm-phone
            id="make-call-widget"
            @callWidgetIsLoaded="widgetIframeIsLoaded"
            @incoming="takeIncomingCall"
            @end="callEnd"
        ></ctm-phone>
        <phone-call-rounded-button
            :statusWidget="statusWidget"
            @toggleCallWidget="toggleCallWidget"
        />
    </div>
</template>

<script>
import CtmPhone from '@/components/Telephony/CtmPhone.vue';
import PhoneCallRoundedButton from "../../ui/PhoneCallRoundedButton";

export default {
    name: 'MakeCallWidget',
    components: { 
        PhoneCallRoundedButton,
        CtmPhone
    },
    data() {
        return {}
    },
  computed: {
    statusWidget() {
      return this.$store.getters["telephony/widgetStatus"];
    }
  },
    methods: {
        toggleCallWidget() {
            this.$store.commit('telephony/OPEN_WIDGET');
        },
        takeIncomingCall(event) {
            this.$store.dispatch('telephony/TAKE_INCOME_CALL');
        },
        callEnd(event) {
            let callID = event.detail.data.callId;

            if (callID) {
                this.$store.dispatch('telephony/STORE_CALL_ID', callID)
                    .then((response) => {
                        this.$store.dispatch('telephony/STORE_LINKED_CALL', callID)
                            .catch(error => this.notifyRequestErrors(error));
                    })
                    .catch(error => this.notifyRequestErrors(error));
            }
        },
        widgetIframeIsLoaded() {
            this.$emit('callWidgetIsLoaded', true);
        }
    },
    destroyed() {
        this.$emit('callWidgetIsLoaded', false);
    }
}
</script>
