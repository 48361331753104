<template>
    <svg
        :width="size"
        :height="size"
        viewBox="0 0 18 17"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.5297 5.576L8.96324 0L5.59899 5.57829H7.86251V7.64229C7.86251 8.488 8.13137 8.96914 8.98163 8.96914C9.82843 8.96914 10.1249 8.488 10.1249 7.64229V5.57714L12.5297 5.576ZM11.3564 17L17.9999 16.9932L15.0677 11.1794L13.8555 13.0789L12.1033 11.9726C11.3851 11.5189 10.8336 11.488 10.3775 12.2012C9.92247 12.912 10.173 13.4183 10.8911 13.872L12.6433 14.9783L11.3564 17ZM0 16.8971L6.64348 16.904L5.3566 14.8823L7.10882 13.776C7.82694 13.3223 8.07627 12.816 7.62242 12.1051C7.16742 11.392 6.61475 11.4229 5.89663 11.8766L4.14442 12.9829L2.93223 11.0834L0 16.8971Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'OpportunityIcon',
        props: {
            size: {
                type: [Number, String],
                default: 18
            }
        }
    }
</script>

<style scoped>

</style>