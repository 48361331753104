<template>
    <full-modal 
        class="milestones-settings-modal"
        :full="true"
        :withSearch="true"
        :searchRequestLength="!!needToFind.length"
        @cancelSearch="needToFind = ''"
        @close="$emit('close')"
    >
        <div slot="title">Milestones settings</div>
        <div slot="search">
            <input 
                type="text" 
                name="tag_search"
                placeholder="Search settings..."
                class="w-100 ph-2"
                v-model="needToFind"
            />
        </div>
        <div slot="body">
            <div 
                class="column-start w-100 pb-20"
                v-for="(setting, index) in settings"
                :key="`setting-${index}`"
                v-show="searchSetting(setting.title)"
            >
                <p
                    class="f-18-black pb-4 f-sbold"
                    v-if="setting.type === RE_DISPATCH_STATUSES.DISPATCHED_FROM"
                >
                    Enable Re-Dispatch
                </p>
                <p class="f-14-black pb-4">
                    {{ setting.title }}
                    <span v-if="index <= 1">*</span>
                </p>
                <div class="row-between">
                    <div :class="['relative', setting.type != 'cancelled' ? 'lg-11 sm-10' : 'lg-12']">
                        <div 
                            class="milestone-setting-loader"
                            v-if="activeSetting == setting.type"
                        >
                            <loader size="mini"/>
                        </div>
                        <multiselect
                            v-model="setting.value"
                            placeholder="Select milestone"
                            label="title"
                            track-by="id"
                            :name="setting.type"
                            :data-name="setting.type"
                            :options="milestoneList(setting.type)"
                            :option-height="104" 
                            :show-labels="false"
                            :allow-empty="false"
                            @select="(data) => selectSetting(data, setting.type)"
                        >  
                            <template 
                                slot="singleLabel" 
                                slot-scope="props"
                            >      
                                <template v-if="props.option.title !== undefined">
                                    <div class="s-align-4">
                                        <span
                                            :style="{'background-color': props.option.color}"
                                            :class="[
                                                'milestone-status-marker capitalize mr-5 transition-01',
                                                { 'status-marker-grey': activeSetting == setting.type }
                                            ]"
                                        >
                                            {{ getAbbreviation(props.option.title) }}
                                        </span>
                                        <span>{{ props.option.title }}</span>
                                    </div>
                                </template>
                            </template>
                            <template 
                                slot="option" 
                                slot-scope="props"
                            >   
                                <div class="s-align-4">
                                    <span
                                        class="milestone-status-marker capitalize mr-5"
                                        :style="{'background-color': props.option.color}"
                                    >
                                        {{ getAbbreviation(props.option.title) }}
                                    </span>
                                    <span>{{ props.option.title }}</span>
                                </div>
                            </template>  
                        </multiselect>
                    </div>
                    <div 
                        class="lg-1 sm-2 s-align-6"
                        v-if="setting.type != 'cancelled'"
                    >
                        <button 
                            class="icon-light"
                            @click="removeMark(setting)"
                            :disabled="!setting.value"
                        >
                            <trash-icon/>
                        </button>
                    </div>
                </div>
                <div 
                    class="w-100 pv-6 bb-grey" 
                    v-if="setting.description"
                >
                    <p class="f-12-darkgrey">
                        * A job can be created in the New RoofGeek 
                        only once it gets assigned the job number in the CRM. 
                        A milestone with settings 
                        <span class="f-italic">"{{ settings[0].title }}"</span>
                        has to come before the milestone with settings 
                        <span class="f-italic">"{{ settings[1].title }}"</span>.
                    </p>
                </div>
            </div>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, Loader } from '@/ui';
    import { TrashIcon } from '@/icons';
    import Multiselect from 'vue-multiselect';
    import { getAbbreviation } from '@/helpers';
    import { RE_DISPATCH_STATUSES } from '@/constants';

    export default {
        name: 'MilestonesSettingsModal',
        components: {
            FullModal,
            Multiselect,
            Loader,
            TrashIcon
        },
        props: {
            milestones: {
                type: Array,
                required: true
            },
            requestIsGoing: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                activeSetting: '',
                settings: [
                    {
                        title: 'Assign a job number starting this milestone',
                        type: 'boundary',
                        value: null,
                        description: false
                    },
                    {
                        title: 'Sync with RoofGeek',
                        type: 'sync_with_rg',
                        value: null,
                        description: true
                    },
                    {
                        title: 'Proposal delivered at this milestone',
                        type: 'is_proposal_delivered',
                        value: null,
                        description: false
                    },
                    {
                        title: 'Milestone marks job as dead',
                        type: 'cancelled',
                        value: null,
                        description: false
                    },
                    {
                        title: 'Change milestone from',
                        type: RE_DISPATCH_STATUSES.DISPATCHED_FROM,
                        value: null,
                        description: false
                    },
                    {
                        title: 'Change milestone to',
                        type: RE_DISPATCH_STATUSES.DISPATCHED_TO,
                        value: null,
                        description: false
                    }
                ],
                needToFind: '',
                getAbbreviation,
                RE_DISPATCH_STATUSES
            }
        },
        watch: {
            requestIsGoing: {
                immediate: true,
                handler (val, oldVal) {
                    if (val != oldVal, !val) {
                        this.activeSetting = '';
                    }
                }
            }
        },
        computed: {
            withoutCancelled() {
                return this.milestones.filter(m => !m.is_cancelled);
            },
            selectedDispatchFromMilestone() {
                return this.settings[5].value;
            }
        },
        methods: {
            searchSetting(name) {                
                return name.toLowerCase().includes(this.needToFind.toLowerCase());
            },
            selectSetting(data, type) {
                let reDispatchMode = type === RE_DISPATCH_STATUSES.DISPATCHED_FROM 
                    || type === RE_DISPATCH_STATUSES.DISPATCHED_TO;

                this.activeSetting = reDispatchMode ? '' : type;

                if (!reDispatchMode) {
                    this.$emit('change', type, data.id, 'change');
                    return;
                }

                if (reDispatchMode) {
                    this.$nextTick(() => {
                        if (!this.selectedDispatchFromMilestone) {
                            return;
                        }
                        
                        let redispatchParams = {
                            redispatch_status: RE_DISPATCH_STATUSES.DISPATCHED_FROM,
                            redispatch_to: this.settings[5].value.id
                        };

                        this.$emit('milestoneRedispatch', this.settings[4].value.id, redispatchParams);
                    });
                }
            },
            removeMark(setting) {
                if (!setting.value) return;

                let reDispatchMode = setting.type === RE_DISPATCH_STATUSES.DISPATCHED_FROM 
                    || setting.type === RE_DISPATCH_STATUSES.DISPATCHED_TO;

                if (!reDispatchMode) {
                    this.$emit('change', setting.type, setting.value.id, 'remove');
                    setting.value = null;
                    return;
                }

                let redispatchParams = {
                    redispatch_status: RE_DISPATCH_STATUSES.NOT_DISPATCH
                };

                this.$emit('milestoneRedispatch', setting.value.id, redispatchParams);
                this.settings[4].value = null;
                this.settings[5].value = null;
            },
            milestoneList(type) {
                switch(type) {
                    case 'boundary': {
                        return this.settings[1].value
                            ? this.milestones.filter(m => m.order <= this.settings[1].value.order && !m.is_cancelled)
                            : this.withoutCancelled;
                    };
                    case 'sync_with_rg': {
                        return this.settings[0].value
                            ? this.milestones.filter(m => m.order >= this.settings[0].value.order && !m.is_cancelled)
                            : this.withoutCancelled;
                    };
                    case 'is_proposal_delivered': {
                        return this.settings[0].value
                            ? this.milestones.filter(m => m.order >= this.settings[0].value.order && !m.is_proposal_delivered)
                            : this.withoutCancelled;
                    };
                    case 'sync_with_rg': {
                        return this.settings[0].value
                            ? this.milestones.filter(m => m.order >= this.settings[0].value.order && !m.is_cancelled)
                            : this.withoutCancelled;
                    };
                    case 'cancelled': {
                        return this.milestones.filter(m => !m.is_boundary && !m.is_sync && !m.is_proposal_delivered);
                    };
                    case RE_DISPATCH_STATUSES.DISPATCHED_FROM: {
                        return this.milestones;
                    };
                    case RE_DISPATCH_STATUSES.DISPATCHED_TO: {
                        return this.milestones;
                    };
                };
            }
        },
        created() {
            this.settings[0].value = this.milestones.find(m => m.is_boundary) ? this.milestones.find(m => m.is_boundary) : null;
            this.settings[1].value = this.milestones.find(m => m.is_sync) ? this.milestones.find(m => m.is_sync) : null;
            this.settings[2].value = this.milestones.find(m => m.is_proposal_delivered) ? this.milestones.find(m => m.is_proposal_delivered) : null;
            this.settings[3].value = this.milestones.find(m => m.is_cancelled) ? this.milestones.find(m => m.is_cancelled) : null;
            this.settings[4].value = this.milestones.find(m => m.redispatch_status === RE_DISPATCH_STATUSES.DISPATCHED_FROM) ? this.milestones.find(m => m.redispatch_status === RE_DISPATCH_STATUSES.DISPATCHED_FROM) : null;
            this.settings[5].value = this.milestones.find(m => m.redispatch_status === RE_DISPATCH_STATUSES.DISPATCHED_TO) ? this.milestones.find(m => m.redispatch_status === RE_DISPATCH_STATUSES.DISPATCHED_TO) : null;
        }
    }
</script>