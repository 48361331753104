import store from '../store/store'

export default {
    data() {
        return {
            prevLink: null
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevLink = from              
        });

        let path = to.fullPath, showPanel;
        path.slice(1, 5) === 'auth' || path.slice(1, 11) === 'job-report'
            ? showPanel = false 
            : showPanel = true;

        store.commit('views/SHOW_PANEL', showPanel);
    },
    methods: {
        $back() {
            this.prevLink.name == null 
                ? this.$router.push({ name: 'Dashboard' }) 
                : this.$router.push(this.prevLink.fullPath);
        }            
    }
}