<template>
    <div
        class="modal s-align-5"
        v-body-scroll-lock:reserveScrollBarGap="true"
    >
        <div class="review-order-labor-modal column-start">
            <p class="f-24-black f-sbold t-center">Crew labor and price settings</p>
            <div class="tabs-menu bb-grey w-100">
                <div class="tabs-menu-panel row-start">
                    <button
                        v-if="differentLaborsPriceDataLength"
                        :class="[
                            'tabs-menu-item h-100 transparent-button',
                            { 'tabs-menu-item-active': activeTab === TABS.CREW_PRICE }
                        ]"
                        @click="activeTab = TABS.CREW_PRICE"
                    >
                        Price difference
                    </button>
                    <button
                        v-if="laborsNotInPriceListDataLength"
                        :class="[
                            'tabs-menu-item h-100 transparent-button',
                            { 'tabs-menu-item-active': activeTab === TABS.NOT_IN_CREW_PRICE }
                        ]"
                        @click="activeTab = TABS.NOT_IN_CREW_PRICE"
                    >
                        Labor difference
                    </button>
                </div>
            </div>
            <div
                v-if="activeTab === TABS.CREW_PRICE"
                class="table-container column-start w-100"
            >
                <div class="row-between">
                    <span class="f-13-grey pv-25">
                        Total items:
                        <span class="f-13-darkgrey f-sbold ml-1">
                            {{ differentLaborsPriceDataLength }}
                        </span>
                    </span>
                    <checkbox-button
                        color="blue"
                        label="Accept all order price "
                        class="w-50 s-align-6"
                    >
                        <input 
                            type="checkbox" 
                            name="accept_all_order_price"
                            v-model="acceptAllOrderPrice"
                            @click="setOrderPrice"
                        />
                    </checkbox-button>
                </div>
                <div class="row-start row-baseline pv-6 ph-4 on-desktop-visible">
                    <span class="lg-6 f-12-black f-sbold">Labor</span>
                    <span class="lg-2 f-12-black f-sbold">
                        Crew price
                        <span class="f-12-grey ml-1">(editable)</span>
                    </span>
                    <span class="lg-2 f-12-black f-sbold">Order price</span>
                </div>
                <perfect-scrollbar class="ps-grey w-100">
                    <div
                        v-for="(item, index) in differentLaborsPriceData"
                        :key="index"
                        class="row-start row-baseline pv-6 ph-4 bt-grey"
                    >
                        <div class="lg-6 sm-12 row-start row-baseline-start">
                            <div
                                v-if="mScreen"
                                class="s-align-4 mr-2"
                            >
                                <button
                                    class="transparent-button p-2"
                                    @click="openedItemIndex = openedItemIndex === index ? null : index"
                                >
                                    <arrow-icon
                                        class="i-grey"
                                        :transform="openedItemIndex === index ? 270 : 90"
                                    />
                                </button>
                                <span class="item-status-icon s-align-5 f-9-white">
                                    {{ item.state === LABOR_STATUSES.ACCEPT_CREW_COST ? 'C' : 'M' }}
                                </span>
                            </div>
                            <p class="f-13-black mb-1">{{ item.name }}</p>
                        </div>
                        <template v-if="openedItemIndex === index || !mScreen">
                            <div class="lg-2 sm-12 s-align-4 mt-sm-2">
                                <radio-button
                                    color="dark-blue"
                                    class="mr-1 mr-sm-1"
                                >
                                    <input 
                                        type="radio" 
                                        :name="`item_state_${index}`" 
                                        :value="LABOR_STATUSES.ACCEPT_CREW_COST"
                                        v-model="item.state"
                                    />
                                </radio-button>
                                <div class="column-start">
                                    <span
                                        v-if="mScreen"
                                        class="f-10-grey"
                                    >
                                        CREW PRICE
                                    </span>
                                    <div class="s-align-4">
                                        <span class="f-13-black f-sbold">$</span>
                                        <input
                                            type="text"
                                            class="transparent-input f-13-black f-sbold"
                                            placeholder="0"
                                            v-limiter
                                            v-model="item.crew_cost"
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="lg-3 sm-12 mt-sm-2">
                                <radio-button
                                    color="dark-blue"
                                    class="mr-1 mr-sm-1"
                                >
                                    <input 
                                        type="radio" 
                                        :name="`item_state_${index}`"
                                        :value="LABOR_STATUSES.ACCEPT_ITEM_COST"
                                        v-model="item.state"
                                    />
                                </radio-button>
                                <div class="column-start">
                                    <span
                                        v-if="mScreen"
                                        class="f-10-grey"
                                    >
                                        ORDER PRICE
                                    </span>
                                    <span class="f-13-black f-sbold">
                                        {{ item.item_cost | currency }}
                                    </span>
                                </div>
                            </div>
                            <div class="lg-1 sm-12 mt-sm-1 column-start">
                                <span class="f-11-grey mb-1">Difference</span>
                                <div class="row-start row-baseline">
                                    <pointer-icon
                                        v-if="+item.crew_cost != +item.item_cost"
                                        size="14"
                                        :transform="+item.crew_cost > +item.item_cost ? 315 : 135"
                                        :class="['mr-1', +item.crew_cost > +item.item_cost ? 'i-green' : 'i-red']"
                                    />
                                    <span
                                        :class="+item.crew_cost != +item.item_cost 
                                            ? +item.crew_cost > +item.item_cost 
                                            ? 'f-12-green' 
                                            : 'f-12-red'
                                            : 'f-12-black'
                                        "
                                    >
                                        {{ Math.round((+item.crew_cost - +item.item_cost) * 100) / 100 }}
                                    </span>
                                </div>
                            </div>
                        </template>
                    </div>
                </perfect-scrollbar>
            </div>
            <div
                v-if="activeTab === TABS.NOT_IN_CREW_PRICE"
                class="w-100 table-container column-start"
            >
                <p class="f-13-grey pv-25">
                    Total items:
                    <span class="f-13-darkgrey f-sbold ml-1">
                        {{ laborsNotInPriceListDataLength }}
                    </span>
                </p>
                <div class="row-start row-baseline pv-6 ph-4 on-desktop-visible">
                    <span class="lg-6 f-12-black f-sbold">Labor</span>
                    <span class="lg-2 f-12-black f-sbold">
                        Crew price
                        <span class="f-12-grey ml-1">(editable)</span>
                    </span>
                    <span class="lg-2 f-12-black f-sbold">Order price</span>
                </div>
                <perfect-scrollbar class="ps-grey w-100">
                    <div
                        v-for="(item, index) in laborsNotInPriceListData"
                        :key="index"
                        :class="[
                            'row-start row-baseline pv-6 ph-4 bt-grey',
                            { 'removed-item' : item.for_remove }
                        ]"
                    >
                        <div
                            :class="[
                                'lg-6 sm-12 row-start row-baseline-start',
                                { 'opacity-5 none-events' : item.for_remove }
                            ]"
                        >
                            <div
                                v-if="mScreen"
                                class="s-align-4 mr-2"
                            >
                                <button
                                    class="transparent-button p-2"
                                    @click="openedItemIndex = openedItemIndex === index ? null : index"
                                >
                                    <arrow-icon
                                        class="i-grey"
                                        :transform="openedItemIndex === index ? 270 : 90"
                                    />
                                </button>
                                <span class="item-status-icon s-align-5 f-9-white">
                                    {{ item.state === LABOR_STATUSES.ACCEPT_CREW_COST ? 'C' : 'M' }}
                                </span>
                            </div>
                            <div class="column-start">
                                <p class="f-13-black mb-1">{{ item.name }}</p>
                                <div class="row-start">
                                    <round-warning-icon
                                        size="17"
                                        class="mr-2 i-yellow"
                                    />
                                    <span class="f-12-grey">Crew does not have this labor on their price list</span>
                                </div>
                            </div>
                        </div>
                        <template v-if="openedItemIndex === index || !mScreen">
                            <div
                                :class="[
                                    'lg-2 sm-12 s-align-4 mt-sm-2',
                                    { 'opacity-5 none-events' : item.for_remove }
                                ]"
                            >
                                <radio-button
                                    color="dark-blue"
                                    class="mr-1 mr-sm-1"
                                >
                                    <input 
                                        type="radio" 
                                        :name="`item_state_${index}`" 
                                        :value="LABOR_STATUSES.ACCEPT_CREW_COST"
                                        v-model="item.state"
                                    />
                                </radio-button>
                                <div class="column-start">
                                    <span
                                        v-if="mScreen"
                                        class="f-10-grey"
                                    >
                                        CREW PRICE
                                    </span>
                                    <div class="s-align-4">
                                        <span class="f-13-black f-sbold">$</span>
                                        <input
                                            type="text"
                                            class="transparent-input f-13-black f-sbold"
                                            placeholder="0"
                                            v-limiter
                                            v-model="item.crew_cost"
                                        >
                                    </div>
                                </div>
                            </div>
                            <div
                                :class="[
                                    'lg-2 sm-12 mt-sm-2',
                                    { 'opacity-5 none-events' : item.for_remove }
                                ]"
                            >
                                <radio-button
                                    color="dark-blue"
                                    class="mr-1 mr-sm-1"
                                >
                                    <input 
                                        type="radio" 
                                        :name="`item_state_${index}`"
                                        :value="LABOR_STATUSES.ACCEPT_ITEM_COST"
                                        v-model="item.state"
                                    />
                                </radio-button>
                                <div class="column-start">
                                    <span
                                        v-if="mScreen"
                                        class="f-10-grey"
                                    >
                                        ORDER PRICE
                                    </span>
                                    <span class="f-13-black f-sbold">
                                        {{ item.item_cost | currency }}
                                    </span>
                                </div>
                            </div>
                            <div class="lg-2 sm-12 mt-sm-1 s-align-6">
                                <button
                                    class="add-button s-align-5"
                                    @click="item.for_remove = !item.for_remove"
                                >
                                    <close-icon class="i-asphalt mr-1"/>
                                    <span>{{ item.for_remove ? 'Removed' : 'Remove '}}</span>
                                </button>
                            </div>
                        </template>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="row-between pt-30 bt-grey">
                <button
                    class="add-button"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button
                    class="primary-button"
                    @click="saveOrder"
                    :disabled="requestIsGoing"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { RoundWarningIcon, ArrowIcon, PointerIcon, CloseIcon } from '@/icons';
    import { RadioButton, CheckboxButton } from '@/ui';
    import api from '@/headers.js';

    export default {
        name: 'ReviewOrderLaborModal',
        components: {
            RoundWarningIcon,
            ArrowIcon,
            PointerIcon,
            CloseIcon,
            RadioButton,
            CheckboxButton
        },
        props: {
            laborsNotInPriceList: {
                type: Array,
                default: () => []
            },
            differentLaborsPrice: {
                type: Array,
                default: () => []
            },
            crewId: {
                type: [String, Number],
                required: true
            }
        },
        data() {
            return {
                TABS: {
                    CREW_PRICE: 'crew_price',
                    NOT_IN_CREW_PRICE: 'not_in_crew_price'
                },
                LABOR_STATUSES: {
                    ACCEPT_CREW_COST: 'AcceptCrewCost',
                    NO_CHECK: 'NoCheck',
                    ACCEPT_ITEM_COST: 'AcceptItemCost'
                },
                activeTab: null,
                openedItemIndex: null,
                laborsNotInPriceListData: [],
                differentLaborsPriceData: [],
                requestIsGoing: false,
                acceptAllOrderPrice: false,
            }
        },
        computed: {
            orderId() {
                return this.$route.params.order_id;
            },
            laborsNotInPriceListDataLength() {
                return this.laborsNotInPriceListData.length;
            },
            differentLaborsPriceDataLength() {
                return this.differentLaborsPriceData.length;
            }
        },
        methods: {
            setOrderPrice(e) {
                if (e.target.checked) {
                    this.differentLaborsPriceData.forEach((item, index) => {
                        this.differentLaborsPriceData[index].state = this.LABOR_STATUSES.ACCEPT_ITEM_COST;
                    });
                }
            },
            saveOrder() {
                let items = [];
                let items_for_remove = [];

                this.laborsNotInPriceListData.forEach(item => {
                    if (item.for_remove) {
                        items_for_remove.push(item.item_id);
                    } else {
                        items.push({
                            id: item.item_id,
                            state: item.state,
                            cost: item.state === this.LABOR_STATUSES.ACCEPT_CREW_COST 
                                ? item.crew_cost : item.item_cost
                        });
                    }
                });

                this.differentLaborsPriceData.forEach(item => {
                    items.push({
                        id: item.item_id,
                        state: this.acceptAllOrderPrice ? this.LABOR_STATUSES.ACCEPT_ITEM_COST : item.state,
                        cost: item.state === this.LABOR_STATUSES.ACCEPT_CREW_COST && !this.acceptAllOrderPrice 
                            ? item.crew_cost : item.item_cost
                    });
                });

                this.requestIsGoing = true;
                api.put(`/orders/${this.orderId}`, { crew_id: this.crewId, items, items_for_remove })
                    .then(response => {
                        if (response.data.data) {
                            this.$emit('onSuccess');
                        }
                    })
                    .catch(error => {
                        this.notifyRequestError(error);
                    })
                    .finally(() => { this.requestIsGoing = false });
            }
        },
        created() {
            this.laborsNotInPriceList.forEach(item => {
                // By default accept cost from master price list
                this.laborsNotInPriceListData.push({
                    ...item,
                    state: this.LABOR_STATUSES.ACCEPT_ITEM_COST,
                    for_remove: false
                });
            });

            this.differentLaborsPrice.forEach((item, index) => {
                // By default accept cost from master price list
                this.differentLaborsPriceData.push({
                    ...item,
                    state: this.LABOR_STATUSES.ACCEPT_ITEM_COST
                });
            });

            if (this.differentLaborsPriceDataLength) {
                this.activeTab = this.TABS.CREW_PRICE;
            } else {
                this.activeTab = this.TABS.NOT_IN_CREW_PRICE;
            }
        }
    }
</script>