<template>
    <perfect-scrollbar class="commercial-list-details ps-grey w-100">
        <div 
            class="w-100" 
            v-if="loading"
        >
            <loader/>
        </div>
        <div 
            class="w-100" 
            v-else
        >
            <div class="s-align-4 w-100 pb-4">
                <clock-icon 
                    class="i-grey mr-2"
                    size="12"
                />
                <span class="f-11-grey">{{ property.created_at | timeAgo }}</span>
            </div>
            <div class="column-start w-100">
                <h2 class="f-20-darkgrey f-sbold pb-4">
                    <router-link
                        :to="{ 
                            name: 'CommercialDetails', 
                            params: { 
                                customer_id: property.customer_id, 
                                property_id: property.id 
                            }
                        }"
                        v-if="property.customer"
                    >
                        {{ property.property_name }}
                    </router-link>
                    <router-link
                        :to="{ 
                            name: 'CommercialPropertyWithoutCustomer', 
                            params: { property_id: property.id }
                        }"
                        v-else
                    >
                        {{ property.property_name }}
                    </router-link>
                </h2>
                <div class="row-start pb-1">
                    <div :class="['md-12', addressIsCorrect ? 'lg-8 nb-7' : 'lg-12 nb-12']">
                        <span class="f-12-grey">
                            Address:
                            <a
                                :href="getGoogleMapLink(property.addresses.main[0].lat, property.addresses.main[0].lng)"
                                :class="[
                                    property.addresses.main.length ? 'f-12-blue' : 'f-italic',
                                    {'none-events': !getGoogleMapLink(property.addresses.main[0].lat, property.addresses.main[0].lng)}
                                ]"
                                target="_blank"
                            >
                                {{
                                    property.addresses.main.length 
                                    ? getAddress(property.addresses.main[0]) 
                                    : 'No information'
                                }}
                                <link-icon 
                                    v-if="getGoogleMapLink(property.addresses.main[0].lat, property.addresses.main[0].lng)"
                                    class="i-blue"
                                />
                            </a>
                        </span>
                    </div>
                    <div 
                        class="s-align-3 lg-4 nb-5 md-12 mt-md-1"
                        v-if="addressIsCorrect"
                    >
                        <button 
                            class="show-on-map"
                            @click="$emit(
                                'showMap', 
                                { 
                                    lat: +property.addresses.main[0].lat, 
                                    lng: +property.addresses.main[0].lng 
                                }
                            )"
                        >
                            <map-icon/>
                            Show on Map
                        </button>
                    </div>
                </div>
            </div>
            <div class="column-start pv-30 bb-grey w-100">
                <h2 class="f-16-darkgrey f-sbold mb-8">Information</h2>
                <p class="s-align-4 f-12-grey pb-3">
                    Responsible billing party:
                    <span 
                        class="pl-1 f-12-black"
                        v-if="property.customer"
                    >
                        <router-link
                            :to="{ name: 'CommercialDetails', 
                            params: { customer_id: property.customer.id }}"
                            class="black-link-underlined relative"
                        >
                            {{ property.customer.name }}
                        </router-link>
                    </span>
                    <span
                        class="dark-label s-align-5 ml-4"
                        v-else
                    >
                        <span class="f-10-white f-sbold">Not created</span>
                    </span>
                </p>
                <p 
                    class="f-12-grey pb-3"
                    v-if="property.address_mailing"
                >
                    Mailing Address:
                    <a
                        :href="getGoogleMapLink(property.addresses.mailing[0].lat, property.addresses.mailing[0].lng)"
                        :class="[
                            property.addresses.mailing.length ? 'f-12-blue' : 'f-italic',
                            {'none-events': !getGoogleMapLink(property.addresses.mailing[0].lat, property.addresses.mailing[0].lng)}
                        ]"
                        target="_blank"
                    >
                        {{
                            property.addresses.mailing.length 
                            ? getAddress(property.addresses.mailing[0]) 
                            : 'No information'
                        }}
                        <link-icon 
                            v-if="getGoogleMapLink(property.addresses.mailing[0].lat, property.addresses.mailing[0].lng)"
                            class="i-blue"
                        />
                    </a>
                </p>
                <p 
                    class="s-align-4 f-12-grey pb-3"
                    v-if="property.division"
                >
                    Division: 
                    <span class="orange-label s-align-5 f-11-white f-sbold ml-2">{{ property.division }}</span>
                </p>
                <p 
                    class="s-align-4 f-12-grey pb-3"
                    v-if="property.designation"
                >
                    Designation:
                    <span class="blue-label s-align-5 f-11-white f-sbold ml-2">{{ property.designation }}</span> 
                </p>
                <!-- ↓↓↓ if there is no customer, this property was created in the lead ↓↓↓ -->
                <!-- ↓↓↓ without 'is selected location' setting ↓↓↓ -->
                <p 
                    class="f-12-grey"
                    v-if="property.customer"
                >
                    Is selected location: 
                    <span class="f-12-black pl-1">{{ property.is_selected_location ? 'Yes' : 'No' }}</span>
                </p>      
            </div>
            <representatives-details-list 
                title="Property"
                :representatives="property.representatives"
                :entityId="property.id"
            />
            <div 
                class="column-start pv-30 w-100"
                v-if="property.units && unitsLength"
            >
                <button 
                    class="transparent-button mb-8"
                    @click="unitsIsOpen =! unitsIsOpen"
                    :disabled="!unitsActively"
                >
                    <arrow-icon 
                        class="i-blue mr-1"
                        :transform="unitsIsOpen ? 270 : 90"
                        size="10"
                        v-if="unitsActively"
                    />
                    <span class="f-16-darkgrey f-sbold pr-4">Units</span>
                    <span 
                        class="count-blue f-11-blue f-bold s-align-5"
                        v-if="unitsActively"
                    >
                        {{ unitsLength }}
                    </span>
                </button>
                <div 
                    class="lead-details-bordered column-start pv-5"
                    v-for="(unit, index) in units"
                    :key="`unit-${index}`"
                >
                    <p class="f-13-grey">
                        Name:
                        <span class="f-13-black pl-1">
                            <router-link
                                :to="{ 
                                    name: 'CommercialDetails', 
                                    params: { 
                                        customer_id: property.customer_id, 
                                        property_id: unit.property_id,
                                        unit_id: unit.id
                                    }
                                }"
                                class="black-link-underlined relative"
                            >
                                {{ unit.name }}
                            </router-link>
                        </span>
                    </p> 
                    <p class="f-13-grey">
                        Date:
                        <span class="f-13-black">{{ unit.created_at | date }}</span> 
                    </p>
                    <p class="f-13-grey">
                        Address:
                        <a
                            :href="getGoogleMapLink(unit.lat, unit.lng)"
                            :class="[
                                unit.full_address ? 'f-12-blue' : 'f-italic',
                                {'none-events': !getGoogleMapLink(unit.lat, unit.lng)}
                            ]"
                            target="_blank"
                        >
                            {{
                                unit.full_address 
                                ? unit.full_address 
                                : 'No information'
                            }}
                            <link-icon 
                                v-if="getGoogleMapLink(unit.lat, unit.lng)"
                                class="i-blue"
                            />
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </perfect-scrollbar>
</template>

<script>
    import { Loader } from '@/ui';
    import { ClockIcon, MapIcon, ArrowIcon, LinkIcon } from '@/icons';
    import { showFullAddress, getGoogleMapLink } from '@/helpers';
    import RepresentativesDetailsList from '@/components/RepresentativesDetailsList.vue';
    import api from '@/headers.js';

    export default {
        name: 'CommercialPropertyListDetails',
        components: {
            Loader,
            ClockIcon,
            MapIcon,
            ArrowIcon,
            LinkIcon,
            RepresentativesDetailsList
        },
        props: {
            propertyId: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                loading: false,
                property: {},
                unitsIsOpen: false
            }
        },
        computed: {
            unitsLength() {
                return this.property.units.length;
            },
            unitsActively() {
                return this.unitsLength > 3;
            },
            units() {
                return this.unitsIsOpen 
                    ? this.property.units
                    : this.property.units.slice(0, 3);
            },
            addressIsCorrect() {
                return this.property.addresses.main.length
                    && this.property.addresses.main[0].lat != null
                    && this.property.addresses.main[0].lng != null;
            }
        },
        methods: {
            // Method imported from helpers.js
            getGoogleMapLink,
            getProperty() {
                this.loading = true;

                api.get(`/commercial/properties/${this.propertyId}`)
                    .then(response => {
                        this.property = response.data.data;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error); 
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            getAddress(obj, type) {
                return showFullAddress(obj, type);
            }
        },
        created() {
            this.getProperty();
        }
    }
</script>