<template>
    <div class="import-step-one">
        <div class="mb-30 mt-100">
            <p class="f-24-black f-sbold t-center">
                Please choose Lead/Job Type you'd like to import records to:
            </p>
        </div>
        <div class="row-between mb-20">
            <div
                v-for="(item,index) in entityTypes" 
                :key="index"
                class="choose-import-lead-type relative s-align-5 pointer"
                @click="selectedType = item.type"
            >
                <div class="angle-input">
                    <input 
                        type="checkbox" 
                        :name="`selectType-${index}`"
                        v-model="selectedType"
                        :value="item.type"
                    />
                    <span
                        v-if="selectedType == item.type" 
                        class="angle-input-icon"
                    >
                        <mark-icon size="12"/>
                    </span>
                </div>
                <span class="w-50 t-center f-15-black f-sbold">
                    {{ item.name }}
                </span>
            </div>
        </div>
        <div class="s-align-5">
            <multiselect
                :options="layouts"
                track-by="id"
                label="name"
                :showLabels="false"
                placeholder="Select Job"
                name="select_job"
                data-name="select_job"
                :allow-empty="false"
                :searchable="false"
                v-model="selectedLayout"
            />
            <import-search-property @setPropertyId="setPropertyId"/>
        </div>
        <div class="mb-25 mt-50">
            <p :class="['f-sbold', selectedType ? 'f-24-black' : 'f-24-lgrey']">
                Upload your .csv file
            </p>
        </div>
        <div class="file-upload-wrapper">    
            <div 
                v-if="!file && selectedType"
                :class="[
                    's-align-5 t-center drop-area-full column-center pointer', 
                    { 'drop-area-active' : dragActive }
                ]"
                @drop.prevent="dropHandler"
                @dragenter.prevent="dragActive = true"
                @dragover.prevent="dragActive = true"
                @dragleave.prevent="dragActive = false"
                @click="startUpload()"
            >
                <full-upload-icon class="i-blue mr-2"/>
                <span class="f-13-grey mt-4">
                    Drop file here or click
                    <span class="f-13-blue">
                        to select file to upload
                    </span>
                </span>
            </div>
            <div 
                v-if="file && selectedType" 
                class="file-wrapper row-start row-baseline"
            >
                <div class="lg-10 s-align-4">
                    <document-icon
                        width="24"
                        height="30"
                        class="flex-shrink-0"
                    />
                    <div class="column-start hidden ml-6">
                        <span class="f-13-black ellipsis no-wrap">
                            {{ file.name }}
                        </span>
                        <span class="f-11-grey ellipsis">
                            <span>
                                {{ file.size/1000 }}
                            </span>
                            KB
                        </span>
                    </div>
                </div>
                <div class="lg-2 s-align-6">
                    <button
                        class="file-delete transparent-button ml-6"
                        @click="deleteFile()"
                    >
                        <close-icon
                            class="i-grey"
                            size="8"
                        />
                    </button>
                </div>
            </div>
        </div>
        <div :class="selectedType ? 'row-between' : 'row-end'">
            <button
                v-if="selectedType"
                class="transparent-button f-12-asphalt f-sbold"
                @click="cancelType()"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                @click="$emit('next')"
                :disabled="!file"
            >
                Continue
                <arrow-icon 
                    size="8"
                    class="i-white ml-8"
                    transform="0"
                />
            </button>
        </div>
        <input 
            type="file" 
            accept=".csv"
            name="file_upload"
            ref="uploadEventAttachment"
            @change="fileInputHandler"
            hidden
        />
    </div>
</template>

<script>
    import { MarkIcon, FullUploadIcon, DocumentIcon, CloseIcon, ArrowIcon } from '@/icons';
    import { BASE_ENTITY_TYPES, MODEL_NAMES } from '@/constants';
    import Multiselect from 'vue-multiselect';
    import api from '@/headers.js';
    import ImportSearchProperty from './ImportSearchProperty.vue';

    export default {
        name: "ImportStepOne",
        components: {
            MarkIcon,
            FullUploadIcon,
            DocumentIcon,
            CloseIcon,
            ArrowIcon,
            Multiselect,
            ImportSearchProperty
        },
        data() {
            return {
                entityTypes: [
                    {
                        name: 'Residential',
                        type: BASE_ENTITY_TYPES.RESIDENTIAL
                    },
                    {
                        name: `Jobs Commercial`,
                        type: BASE_ENTITY_TYPES.COMMERCIAL_JOB
                    },
                    {
                        name: 'Leads Commercial',
                        type: BASE_ENTITY_TYPES.COMMERCIAL_LEAD
                    }
                ],
                selectedType: '',
                dragActive: false,
                file: null,
                layouts: [],
                selectedLayout: null,
                propertyId: null
            }
        },
        methods: {
            setPropertyId(id) {
                this.propertyId = id;
            },
            getLayouts() {
                this.customFieldsLoading = true;

                api.get(`/layouts/model-name?model_name=${MODEL_NAMES.COMMERCIAL_JOB}&filter[is_active]=1`)
                    .then(response => {
                        this.layouts = response.data.data;
                        // select first by default
                        this.selectedLayout = this.layouts[0];
                    });
            },
            startUpload() {
                this.$refs.uploadEventAttachment.click();
            },
            deleteFile() {
                this.file = null;
                this.$refs.uploadEventAttachment.value = '';
            },
            fileInputHandler(event) {
                this.file = event.target.files[0];
                
                if (!this.file) return false;

                this.papaParse(this.file);
            },
            dropHandler(event) {
                let files = event.dataTransfer.files;
                let type = files[0].name.split('.')[1];
                this.dragActive = false;
                
                if (type != 'csv') {
                    this.notifyError('This is not .csv file');
                } else {
                    this.file = files[0];
                    this.papaParse(this.file);
                }
            },
            papaParse(file) {
                this.$papa.parse(file, {
                    header: true,
                    complete: (results) => {
                        this.$emit('setCsvData', results);
                        this.$emit('selectedType', this.selectedType);
                        this.$emit('setLayoutPropertyIds', { 
                            layoutId: this.selectedLayout.id,
                            propertyId: this.propertyId
                        });
                    }
                });
            },
            cancelType() {
                this.selectedType = '';
                this.file = null;
                this.$refs.uploadEventAttachment.value = '';
            }
        },
        created() {
            this.getLayouts();
        }
    }
</script>