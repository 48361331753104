<template>
    <div class="auth-envelope row-start">
        <div class="auth-form lg-6 md-12 ls-md-6 sm-12">
            <div class="column-between-start w-100">
                <div class="auth-logo row-start s-align-4">
                    <crm-logo class="mr-4" type="color" :width="115" :height="32"/>
                </div>
                <slot name="default"></slot>
                <div>
                    <p class="f-12-grey">©{{ year }} All Rights Reserved. CRM Dash</p>
                    <p class="f-12-grey">Cookie Preferences. Privacy and Terms.</p>
                </div>
            </div>
        </div>
        <div class="description lg-6 md-12 ls-md-6 sm-12">
            <div class="column-end s-align-8">
                <h1 class="f-sbold">An essential tool <br/> for managing your jobs</h1>
                <p class="f-16-white">
                    Create, manage, track jobs with an effective and smart customer relationship management system. 
                    Automate your workflow to save time and get better results!
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment/src/moment';
    import { CrmLogo } from '@/icons';

    export default {
        name: 'AuthEnvelope',
        components: {
            CrmLogo
        },
        computed: {
            year() {
                return moment().format("YYYY");
            }
        }
    }
</script>
