<template>
    <div 
        :class="[
            'form-time-input row-baseline',
            `popover-direction-${popoverDirection}`,
            { 'disabled-form-time-input cursor-not-allowed' : disabled }
        ]"
        :style="{ width: isFullWidth ? '100%' : 'auto' }"
    >
        <div class="lg-6 ph-4 row-start row-baseline h-100 relative">
            <clock-icon :class="disabled ? 'i-grey' : 'i-lightblue'"/>
            <span 
                v-if="!value.startTime"
                @click="disabled ? '' : initStartTime()"
                :class="[
                    'ml-2 form-time-input-placeholder ellipsis h-100 s-align-4',
                    disabled ? 'cursor-not-allowed' : 'pointer'
                ]"
            >
                From
            </span>
            <template v-else>
                <input 
                    class="form-input-field h-100 ml-2"
                    type="text"
                    v-mask="'##:##'"
                    :value="startTime"
                    @change="changeStartTime($event)"
                    @focus="showTimeSelect('start')"
                >
                <span 
                    class="f-14-black division pointer"
                    @click="toggleStartTimePeriod"
                >
                    {{ startTimePeriod }}
                </span>
            </template>
            <perfect-scrollbar 
                v-if="isTimeStartSelectShow"
                class="time-input-select ps-grey w-100 pv-1"
            >
                <button
                    v-for="(time, index) in timeList"
                    :key="index"
                    :class="[
                        'w-100 f-13-black t-left',
                        startTimeWithPeriod === time ? 'selected' : ''
                    ]"
                    @click="setTime(time, 'start'), hideTimeSelect('start')"
                >
                    {{ time }}
                </button>
            </perfect-scrollbar>
        </div>
        <div class="lg-6 row-start row-baseline h-100 ph-4 relative">
            <span
                v-if="!value.endTime"
                @click="disabled ? '' : initEndTime()"
                :class="[
                    'ml-2 form-time-input-placeholder ellipsis pointer h-100 s-align-4',
                    disabled ? 'cursor-not-allowed' : 'pointer'
                ]"
            >
                To
            </span>
            <template v-else>
                <input 
                    class="form-input-field h-100 ml-2"
                    type="text"
                    v-mask="'##:##'"
                    :value="endTime"
                    @change="changeEndTime($event)"
                    @focus="showTimeSelect('end')"
                >
                <span 
                    class="f-14-black division pointer"
                    @click="toggleEndTimePeriod"
                >
                    {{ endTimePeriod }}
                </span>
            </template>
            <perfect-scrollbar  
                v-if="isTimeEndSelectShow"
                class="time-input-select ps-grey w-100 pv-1"
            >
                <button
                    v-for="(time, index) in timeList"
                    :key="index"
                    :class="[
                        'w-100 f-13-black t-left',
                        endTimeWithPeriod === time ? 'selected' : ''
                    ]"
                    @click.stop="setTime(time, 'end'), hideTimeSelect('end')"
                >
                    {{ time }}
                </button>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script>
    import moment from 'moment/src/moment';
    import { ClockIcon } from '@/icons';
    import { mask } from 'vue-the-mask';
    import timeList from '@/mixins/timeList';

    export default {
        name: 'FormTimeInput',
        components: {
            ClockIcon
        },
        props: {
            value: {
                required: true,
                type: Object
            },
            isFullWidth: {
                type: Boolean,
                default: false
            },
            popoverDirection: {
                type: String,
                default: 'bottom'
            },
            disabled: {
                type: [Boolean, Number],
                default: false
            }
        },
        mixins: [timeList],
        data() {
            return {
                isTimeStartSelectShow: false,
                isTimeEndSelectShow: false
            }
        },
        directives: {
            mask
        },
        methods: {
            toggleStartTimePeriod: function() {
                if (this.getTimeFormat(this.value.startTime).format('A') === 'AM') {
                    let time = this.getTimeFormat(this.value.startTime).add(12, 'h').format('HH:mm');
                    this.$emit('input', { startTime: time, endTime: this.value.endTime });
                } else {
                    let time = this.getTimeFormat(this.value.startTime).subtract(12, 'h').format('HH:mm');
                    this.$emit('input', { startTime: time, endTime: this.value.endTime });
                }
            },
            toggleEndTimePeriod: function() {
                if (this.getTimeFormat(this.value.endTime).format('A') === 'AM') {
                    let time = this.getTimeFormat(this.value.endTime).add(12, 'h').format('HH:mm');
                    this.$emit('input', { endTime: time, startTime: this.value.startTime });
                } else {
                    let time = this.getTimeFormat(this.value.endTime).subtract(12, 'h').format('HH:mm');
                    this.$emit('input', { endTime: time, startTime: this.value.startTime });
                }
            },
            changeStartTime: function(event) {
                if (this.getTimeFormat(event.target.value).isBefore(moment({ hours: 12, minutes: 0 })) 
                    && (this.getTimeFormat(this.value.startTime).format('A') === 'PM')) {
                    
                    let time = this.getTimeFormat(event.target.value).add(12, 'h').format('HH:mm');
                    this.$emit('input', { startTime: time, endTime: this.value.endTime });
                } else {
                    this.$emit('input', { startTime: event.target.value, endTime: this.value.endTime });
                }
            },
            changeEndTime: function(event) {
                if (this.getTimeFormat(event.target.value).isBefore(moment({ hours: 12, minutes: 0 })) 
                    && (this.getTimeFormat(this.value.endTime).format('A') === 'PM')) {
                    
                    let time = this.getTimeFormat(event.target.value).add(12, 'h').format('HH:mm');
                    this.$emit('input', { startTime: this.value.startTime, endTime: time });
                } else {
                    this.$emit('input', { startTime: this.value.startTime, endTime: event.target.value });
                }
            },
            initStartTime: function() {
                if (this.value.endTime) {
                    this.$emit('input', { startTime: this.defaultStartTime, endTime: this.value.endTime });
                } else {
                    this.$emit('input', { startTime: '10:00', endTime: this.value.endTime });
                }
            },
            initEndTime: function() {
                if (this.value.startTime) {
                    this.$emit('input', { startTime: this.value.startTime, endTime: this.defaultEndTime });
                } else {
                    this.isTimeEndSelectShow = true;
                }
            },
            getTimeFormat(value) {
                return moment(value, 'HH:mm');
            },
            showTimeSelect(inputType) {
                let time;
                const timesInMillis = this.timeList.map(t => +moment(t, 'hh:mm A').format('x'));

                switch (inputType) {
                    case 'start':
                        this.isTimeStartSelectShow = true;
                        time = moment(this.startTimeWithPeriod, 'hh:mm A').format('x');
                        break;
                    case 'end':
                        this.isTimeEndSelectShow = true;
                        time = moment(this.endTimeWithPeriod, 'hh:mm A').format('x');
                        break;
                }

                const closest = moment(this.closestTime(timesInMillis, time)).format('hh:mm A');
                const closestTimeIndex = this.timeList.findIndex(item => item === closest);

                this.$nextTick(function() {
                    // scroll to closest element in select, 38 - height of one element in px, 76 offset to center position 
                    document.querySelector('.time-input-select').scrollTop = closestTimeIndex * 38 - 76;
                });
            },
            hideTimeSelect(inputType) {
                switch (inputType) {
                    case 'start':
                        setTimeout(function() {
                            this.isTimeStartSelectShow = false;
                        }.bind(this), 100);
                        break;
                    case 'end':
                        setTimeout(function() {
                            this.isTimeEndSelectShow = false;
                            if (!this.value.startTime) {
                                this.initStartTime();
                            }
                        }.bind(this), 100);
                        break;
                }
            },
            setTime(time, inputType) {
                switch (inputType) {
                    case 'start':
                        this.$emit('input', { 
                            startTime: moment(time, 'hh:mm A').format('HH:mm'), 
                            endTime: this.value.endTime 
                        });
                        break;
                    case 'end':
                        this.$emit('input', { 
                            startTime: this.value.startTime, 
                            endTime: moment(time, 'hh:mm A').format('HH:mm') 
                        });
                        break;
                }
            },
            outside(event) {
                if (!this.$el.contains(event.target) && this.isTimeStartSelectShow) {
                    this.isTimeStartSelectShow = false;
                }
                if (!this.$el.contains(event.target) && this.isTimeEndSelectShow) {
                    this.isTimeEndSelectShow = false;
                }
            }
        },
        computed: {
            defaultEndTime() {
                return this.getTimeFormat(this.value.startTime).add(2, 'h').format('HH:mm');
            },
            defaultStartTime() {
                return this.getTimeFormat(this.value.endTime).subtract(2, 'h').format('HH:mm');
            },
            startTime() {
                return this.getTimeFormat(this.value.startTime).format('hh:mm');
            },
            startTimePeriod() {
                return this.getTimeFormat(this.value.startTime).format('A');
            },
            startTimeWithPeriod() {
                return this.getTimeFormat(this.value.startTime).format('hh:mm A');
            },
            endTime() {
                return this.getTimeFormat(this.value.endTime).format('hh:mm');
            },
            endTimePeriod() {
                return this.getTimeFormat(this.value.endTime).format('A');
            },
            endTimeWithPeriod() {
                return this.getTimeFormat(this.value.endTime).format('hh:mm A');
            }
        },
        mounted() {
            document.body.addEventListener('click', this.outside, false);
        },
        destroyed() {
            document.body.removeEventListener('click', this.outside, false);
        }
    }
</script>

