<template>
    <label :class="['radio-button', { 'reverse' : labelDirection == 'left' }]">
        <slot name="default"></slot>
        <span :class="['radio-button-mark', color]"/>
        <slot name="custom-label"></slot>
        <span 
            :class="['radio-button-label', { 'mr-4' : labelDirection == 'left' }]"
            v-if="label"
        >
            {{ label }}
        </span>
    </label>
</template>

<script>
    export default {
        name: 'RadioButton',
        props: {
            color: {
                type: [String],
                default: 'violet'
            },
            label: {
                type: [String],
                default: ''
            },
            labelDirection: {
                type: String,
                default: 'right'
            }
        }
    }
</script>
