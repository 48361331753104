import { SET_EXPAND_ALL } from "../actions/filters";

export default {
    namespaced: true,
    state: {
        expandAll: true
    },
    getters: {
        getExpandAll: state => state.expandAll
    },
    mutations: {
        [SET_EXPAND_ALL] (state, payload) {
            state.expandAll = payload;
        }
    }
}