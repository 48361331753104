<template>
    <svg 
	    viewBox="0 0 559.9298706054688 598.6915893554688"
        :width="width" 
        :height="height"
        class="pdf-icon"
    >
        <g transform="matrix(32.66666793823242, 0, 0, 32.66666793823242, 15136.744049072266, -8888.328125)">
            <switch>
                <foreignObject requiredExtensions="&amp;ns_ai;" x="0" y="0" width="1" height="1"></foreignObject>
                <g>
                    <g>
                        <path d="M-446.8,277.5l-5.8-4.9c-0.3-0.2-0.7-0.4-1.1-0.4h-7c-1.4,0-2.6,1.1-2.6,2.5v13.1c0,1.4,1.2,2.5,2.6,2.5h11.7c1.5,0,2.7-1.1,2.7-2.5v-9.1C-446.3,278.3-446.4,277.9-446.8,277.5z M-452.7,273.8l4.5,3.9h-2.9c-0.8,0-1.6-0.6-1.6-1.5V273.8z M-447.4,287.7c0,0.8-0.7,1.5-1.6,1.5h-11.7c-0.8,0-1.6-0.6-1.6-1.5v-13.1c0-0.8,0.7-1.5,1.6-1.5h6.9v3c0,1.4,1.2,2.5,2.6,2.5h3.7V287.7z"/>
                        <path d="M-451.9,282.6c-1-0.8-2-1.9-2.7-2.8c0.4-1.4,0.2-1.9,0-2.1c-0.2-0.2-0.4-0.3-0.6-0.3c0,0,0,0-0.1,0c-0.5,0-0.7,0.3-0.8,0.5c-0.2,0.4,0,1.1,0.6,2.1c-0.4,1.2-1.2,2.8-2,4.1c-2.3,0.9-2.4,1.6-2.5,1.8c0,0.3,0.1,0.5,0.3,0.7c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.9,0,2.2-2.1c1.5-0.6,3.4-1,4.8-1.2c0.8,0.6,1.4,0.8,1.8,0.8h0c0.4,0,0.7-0.2,0.8-0.6c0.1-0.2,0-0.5-0.1-0.7c-0.2-0.3-0.7-0.4-1.5-0.4C-451.4,282.5-451.6,282.6-451.9,282.6z M-459.2,285.9C-459.2,285.9-459.2,285.9-459.2,285.9c0-0.1,0.2-0.3,0.7-0.6C-458.8,285.7-459.1,285.8-459.2,285.9z M-455.4,278.2c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0.2,0,0.7C-455.5,278.4-455.4,278.2-455.4,278.2z M-456.4,283.6c0.5-0.9,1-2,1.4-2.9c0.6,0.7,1.3,1.4,2,2.1C-454,282.9-455.3,283.2-456.4,283.6z M-450.3,283.4C-450.3,283.4-450.3,283.4-450.3,283.4C-450.3,283.4-450.3,283.4-450.3,283.4C-450.3,283.5-450.3,283.5-450.3,283.4c-0.1,0-0.3,0-0.5-0.1C-450.5,283.3-450.4,283.4-450.3,283.4z"/>
                    </g>
                </g>
            </switch>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'PdfIcon',
        props: {
            width: {
                type: [Number, String],
                default: 17
            },
            height: {
                type: [Number, String],
                default: 18
            }
        }
    }
</script>