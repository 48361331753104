<template>
    <svg
        viewBox="0 0 75 58"
        :width="size" 
        :height="height || size"
        fill="none"
    >
        <g>
            <path d="M73.7901 58H67.2377C66.5321 58 65.9272 57.401 65.9272 56.7022C65.9272 56.0034 66.5321 55.4045 67.2377 55.4045H73.7901C74.4958 55.4045 75.1006 56.0034 75.1006 56.7022C74.9998 57.401 74.4958 58 73.7901 58Z" fill="#E5E1F9"/>
            <path d="M11.7944 3.29432H1.31048C0.604839 3.29432 0 2.69535 0 1.99655C0 1.29776 0.604839 0.698792 1.31048 0.698792H11.8952C12.5 0.798619 13.1048 1.29776 13.1048 1.99655C13.1048 2.69535 12.5 3.29432 11.7944 3.29432Z" fill="#E5E1F9"/>
            <path d="M22.5807 45.4217V7.68675H19.9597C18.246 7.68675 16.9355 8.98451 16.9355 10.6816V52.9088C16.9355 54.6059 18.246 55.9036 19.9597 55.9036H55.5444C57.2581 55.9036 58.5686 54.6059 58.5686 52.9088V50.5129H27.7218C24.8993 50.5129 22.5807 48.2169 22.5807 45.4217Z" fill="#BCD6F9"/>
            <path d="M55.8468 12.8778H66.4315L53.3267 0V0.0998279V10.4819C53.3267 11.7797 54.5363 12.8778 55.8468 12.8778Z" fill="#EFF1F4"/>
            <path d="M51.3107 10.4821V0.100006H27.7219C26.0082 0.100006 24.6978 1.39777 24.6978 3.09484V45.322C24.6978 47.0191 26.0082 48.3169 27.7219 48.3169H63.3066C65.0203 48.3169 66.3308 47.0191 66.3308 45.322V14.9744H55.847C53.3268 14.9744 51.3107 12.9778 51.3107 10.4821Z" fill="#EFF1F4"/>
            <path d="M35.7861 14.7745H45.2619C45.8668 14.7745 46.27 15.1738 46.27 15.7728C46.27 16.3718 45.8668 16.7711 45.2619 16.7711H35.7861C35.1813 16.7711 34.778 16.3718 34.778 15.7728C34.6772 15.1738 35.1813 14.7745 35.7861 14.7745Z" fill="#BCD6F9"/>
            <path d="M55.3428 34.9398H35.7864C35.1815 34.9398 34.7783 34.5405 34.7783 33.9415C34.7783 33.3425 35.1815 32.9432 35.7864 32.9432H55.4436C56.0485 32.9432 56.4517 33.3425 56.4517 33.9415C56.4517 34.4406 55.9477 34.9398 55.3428 34.9398Z" fill="#BCD6F9"/>
            <path d="M56.4517 24.8571C56.4517 25.4561 56.0485 25.8554 55.4436 25.8554H35.7864C35.1815 25.8554 34.7783 25.4561 34.7783 24.8571C34.7783 24.2582 35.1815 23.8589 35.7864 23.8589H55.4436C55.9477 23.759 56.4517 24.2582 56.4517 24.8571Z" fill="#BCD6F9"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'EmptyPaperIcon',
        props: {
            size: {
                type: [Number, String],
                default: 75
            },
            height: {
                type: [Number, String],
                default: 58
            }
        }
    }
</script>