<template>
    <div class="modal s-align-5">
        <div class="configure-job-numbering-modal cm-fond cm-scroll">
            <h2 class="f-24-black f-sbold t-center">Configure job numbering</h2>
            <p class="f-14-black pb-2">
                Set a starting point for sequential job numbers
            </p>
            <div class="w-100 pb-8">
                <form-input 
                    label="Start number"
                    :class="[{ 'form-input-danger' : errors.has('start_number') }]"
                >
                    <input 
                        type="number"
                        name="start_number"
                        class="form-input-field"
                        placeholder="Start number"
                        v-model="startNumber"
                        v-input-style="startNumber"
                        v-validate="'required'"
                    />
                </form-input>
            </div>
            <div class="custom-modal-footer row-between">
                <button 
                    class="add-button"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button 
                    class="primary-button"
                    :disabled="updating"
                    @click="save"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { FormInput } from '@/ui';

    export default {
        name: 'ConfigureJobNumberingModal',
        components: {
            FormInput            
        },
        props: {
            startingNum: {
                type: [String, Number],
                required: true
            },
            updating: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                startNumber: 0
            }
        },
        methods: {
            save() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('Start number is required field.');
                        return;
                    }

                    this.$emit('saveStartingNum', this.startNumber);
                });
            }
        },
        created() {
            this.startNumber = this.startingNum;
        }
    }
</script>