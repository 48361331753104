<template>
    <svg
        viewBox="0 0 13 11"
        :width="size" 
        :height="size"
        class="text-left-icon"
    >
        <path d="M12.6 1.4H.3c-.1 0-.3-.1-.3-.3V.3C0 .1.2 0 .3 0h12.3c.2 0 .4.1.4.3V1c0 .3-.2.4-.4.4zM8 4.6H.3c-.1 0-.3-.1-.3-.3v-.8c0-.2.2-.4.3-.4H8c.2 0 .3.2.3.4v.7c0 .3-.1.4-.3.4zM12.6 7.8H.3c-.1 0-.3-.2-.3-.4v-.7c0-.2.2-.3.3-.3h12.3c.2 0 .4.2.4.3v.7c0 .2-.2.4-.4.4zM8 11H.3c-.1 0-.3-.2-.3-.4v-.7c0-.2.2-.3.3-.3H8c.2 0 .3.2.3.3v.7c0 .2-.1.4-.3.4z"/>
    </svg>
</template>

<script>
    export default {
        name: 'TextLeftIcon',
        props: {
            size: {
                type: [Number, String],
                default: 13
            }
        }
    }
</script>