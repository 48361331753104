<template>
    <svg 
        viewBox="0 0 20 20" 
        :width="size" 
        :height="size"
        class="multiline-icon"
    >
        <g transform="matrix(0.06782950460910797, 0, 0, 0.06782950460910797, 11.583470344543443, -4.944252014160163)">
            <g>
                <path d="M92.5,320H-143c-13.2,0-24-10.8-24-24V136c0-13.2,10.8-24,24-24H92.5c13.2,0,24,10.8,24,24v160C116.5,309.2,105.7,320,92.5,320z M-143,128c-4.4,0-8,3.6-8,8v160c0,4.4,3.6,8,8,8H92.5c4.4,0,8-3.6,8-8V136c0-4.4-3.6-8-8-8H-143z"/>
            </g>
            <g>
                <g>
                    <path d="M -116.5 160 H -52.5 V 176 H -116.5 V 160 Z"/>
                </g>
                <g>
                    <path d="M -116.5 256 H -52.5 V 272 H -116.5 V 256 Z"/>
                </g>
                <g>
                    <path d="M -92.5 168 H -76.5 V 264 H -92.5 V 168 Z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M 21.799999237060547 201.39999389648438 L 70.19999694824219 201.39999389648438 L 46.29999923706055 177.6999969482422 Z"/>
                </g>
                <g>
                    <path d="M 46.29999923706055 253.39999389648438 L 70.19999694824219 229.60000610351562 L 21.799999237060547 229.60000610351562 Z"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'MultilineIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>
