<template>
    <div class="modal s-align-5">
        <div class="create-new-folder-modal cm-fond">
            <h2 class="f-24-black f-sbold t-center">Create new folder</h2>
            <div class="w-100">
                <form-input 
                    label="New folder name"
                    :class="[{ 'form-input-danger' : errors.has('folder_name') }]"
                >
                    <input 
                        type="text" 
                        name="folder_name"
                        class="form-input-field"
                        placeholder="New folder name"
                        v-input-style="folderName"
                        v-model="folderName"
                        v-validate="{ required: true, min: 3, max: 30, regex: titleRegex }"
                    />
                </form-input>
            </div>
            <div class="custom-modal-footer row-between">
                <button 
                    class="add-button"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button 
                    class="primary-button"
                    @click="save"
                    :disabled="requestIsGoing"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { FormInput } from '@/ui';
    import api from '@/headers.js';
    import { ALLOWED_CHARACTERS_FOLDER } from '@/constants';

    export default {
        name: 'CreateNewFolderModal',
        components: {
            FormInput
        },
        props: {
            folder: {
                type: String,
                required: true
            },
            modelClass: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                folderName: '',
                requestIsGoing: false,
                titleRegex: ALLOWED_CHARACTERS_FOLDER,
            }
        },
        watch: {
            folderName(newValue, oldValue) {
                if (newValue.length) {
                    this.folderName = newValue.replace('/', '');
                }
            }
        },
        methods: {
            save() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        const params = {
                            name: this.folderName,
                            path: this.folder,
                            model_class: this.modelClass
                        };
                        
                        this.requestIsGoing = true;
                        api.post('/document/create-folder', params)
                            .then(response => {
                                this.notifySuccess('Folder has been created!');
                                
                                this.$emit('update');
                                this.$emit('updateFolders');
                                this.$emit('close');
                            })
                            .catch(error => {
                                this.notifyRequestErrors(error);
                            })
                            .finally(() => {
                                this.requestIsGoing = false;
                            });

                    } else if (this.folderName === '') {
                        this.notifyError('"New folder name" is a required field.');
                    } else {
                        this.notifyError('Valid characters include A-z, 0-9, and (_-)');
                    };
                });
            }
        }
    }
</script>
