<template>
    <div 
        class="pagination w-100 row-between" 
        v-if="quantity >= 2"
    >
        <div 
            v-if="showDesc"
            class="lg-2 sm-3 s-align-4"
        >
            <span class="f-12-grey">
                Page {{ currentPage.number }} of {{ quantity }}
            </span>
        </div>
        <div 
            :class="[
                'pagination-nav',
                showDesc ? 'lg-10 sm-9 s-align-6' : 'lg-12 s-align-5'
            ]"
        >
            <button
                class="transparent-button pagination-button mr-1"
                :disabled="prevPage == null"
                @click="switchPage(prevPage)"
            >
                <arrow-icon transform="180"/>
            </button>
            <button 
                v-for="(page, index) in pagination"
                :key="index"
                :class="[
                    'transparent-button pagination-button mr-1 ph-1',
                    { 'pagination-active-button' : page.status && theme == 'default' },
                    { 'pagination-active-minimal-button' : page.status && theme == MINIMAL }
                ]"
                @click="switchPage(page)"
                :disabled="page.number == DOTS || page.number == LINE"
            >
                <div v-if="page.number != LINE">
                    {{ page.number }}
                </div>
                <div 
                    v-else
                    v-html="page.number"
                    class="s-align-5"
                >
                </div>
            </button>
            <button
                class="transparent-button pagination-button"
                :disabled="nextPage == null"
                @click="switchPage(nextPage)"
            >
                <arrow-icon transform="0"/>
            </button>
        </div>
    </div>
</template>

<script>
    import { ArrowIcon } from '@/icons';
    const DOTS = '...';
    const LINE = '<div class="pagination-line"></div>';
    const MINIMAL = 'minimal';

    export default {
        name: 'Pagination',
        components: {
            ArrowIcon
        },
        props: {
            quantity: {
                type: Number,
                default: 0
            },
            showDesc: {
                type: Boolean,
                default: true
            },
            activePage:{
                type: Number,
                default: 1
            },
            theme: {
                type: String,
                default: 'default'
            }
        },
        data() {
            return {
                pages: [],
                LINE: LINE,
                MINIMAL: MINIMAL,
                ellipsis: {
                    number: DOTS,
                    status: false
                },
                line: {
                    number: LINE,
                    status: false
                }
            }
        },
        computed: {
            firstPage() {
                return this.pages[0];
            },
            prevPage() {
                return this.currentPage.number - 1 == 0 
                    ? null
                    : this.pages.find(p => p.number == this.currentPage.number - 1);
            },
            currentPage() {
                return this.pages.find(p => p.status);
            },
            nextPage() {
                return this.currentPage.number == this.pages.length
                    ? null
                    : this.pages.find(p => p.number == this.currentPage.number + 1);
            },
            lastPage() {
                return this.pages[this.pages.length -1];
            },
            pagination() {
                if (this.pages.length <= 5) 
                    return this.pages;
                    
                if (this.prevPage == null) {
                    const displayed = this.pages.slice(0, 3);
                    displayed.push(this.theme == MINIMAL ? this.line : this.ellipsis, this.lastPage);
                    return displayed;
                }

                if (this.nextPage == null) {
                    const displayed = this.pages.slice(-3);
                    displayed.unshift(this.firstPage, this.theme == MINIMAL ? this.line : this.ellipsis);
                    return displayed;
                } 

                const displayed = [this.prevPage, this.currentPage, this.nextPage];

                if (this.nextPage.number <= this.quantity / 2) {
                    displayed.push(this.theme == MINIMAL ? this.line : this.ellipsis, this.lastPage);
                } else {
                    displayed.unshift(this.firstPage, this.theme == MINIMAL ? this.line : this.ellipsis);
                }

                return displayed;
            }
        },
        watch: {
            quantity(val,oldVal) {
                if (val != oldVal) {
                    this.pages = [];
                    this.addPageQuantity();
                }
            },
            activePage(val,oldVal) {
                if (val != oldVal) {
                    this.pages = [];
                    this.addPageQuantity();
                }
            }
        },
        methods: {
            switchPage(page) {
                this.pages.map((p) => {
                    p.status = p.number == page.number;
                });

                this.$emit('click', page.number)
            },
            addPageQuantity() {
                for (let i = 1; i <= this.quantity; i++) {
                    this.pages.push({
                        number: i,
                        status: i == this.activePage
                    }); 
                };
            }
        },
        created() {
            this.DOTS = DOTS;
            this.addPageQuantity();
        }
    }
</script>