var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-tag-modal"},[_c('div',{staticClass:"row-between mb-6 p-8"},[_c('span',{staticClass:"f-16-black"},[_vm._v("Labels")]),_c('button',{staticClass:"icon-light",on:{"click":function($event){return _vm.$emit('closeModal')}}},[_c('close-icon',{attrs:{"size":"15"}})],1)]),(_vm.isLoading)?_c('div',{staticClass:"w-100"},[_c('loader',{attrs:{"size":"small"}})],1):_c('div',{staticClass:"available-tags column-start"},[_vm._l((_vm.allTags),function(tag,index){return [(_vm.editingTagId !== tag.id)?_c('div',{key:("label_" + index),staticClass:"row-baseline ph-8 mb-1"},[_c('button',{class:[
                        'row-between row-baseline tag lg-10',
                        ("tag-" + (tag.color)),
                        _vm.isTagSelected(tag.id) ? 'tag-selected' : ''
                    ],attrs:{"disabled":_vm.requestIsGoing},on:{"click":function($event){return _vm.toggleTag(tag.id)}}},[_c('span',{staticClass:"ellipsis t-left"},[_vm._v(_vm._s(tag.name))]),(_vm.isTagSelected(tag.id))?_c('mark-icon',{attrs:{"size":"10"}}):_vm._e()],1),_c('button',{staticClass:"icon-light lg-2",on:{"click":function($event){return _vm.showEditField(tag)}}},[_c('edit-icon')],1)]):_c('div',{key:("input_" + index),staticClass:"tag-edit w-100 bt-grey bb-grey mv-1"},[_c('div',{staticClass:"column-start p-4"},[_c('div',{staticClass:"row-between mb-4"},[_c('span',{staticClass:"f-11-black f-sbold"},[_vm._v("Edit label")]),_c('button',{staticClass:"icon-light",on:{"click":function($event){return _vm.hideEditField(tag.id)}}},[_c('close-icon',{attrs:{"size":"9"}})],1)]),_c('form-input',{class:[
                            'mb-6',
                            _vm.errors.has(("tag_" + (tag.id) + "_name")) ? 'form-input-danger' : ''
                        ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editingTagName),expression:"editingTagName"},{name:"validate",rawName:"v-validate.persist",value:('required|min:3|max:99'),expression:"'required|min:3|max:99'",modifiers:{"persist":true}}],staticClass:"form-input-field",attrs:{"type":"text","name":("tag_" + (tag.id) + "_name"),"placeholder":"Tag name","maxlength":"100"},domProps:{"value":(_vm.editingTagName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.editingTagName=$event.target.value}}})]),_c('p',{staticClass:"f-11-black f-sbold mb-3"},[_vm._v("Color")]),_c('div',{staticClass:"row-between"},_vm._l((_vm.colors),function(color,index){return _c('label',{key:index,class:[
                                'tag-color',
                                ("tag-color-" + color)
                            ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editingTagColor),expression:"editingTagColor"}],attrs:{"type":"radio","name":"tag_color"},domProps:{"value":color,"checked":_vm._q(_vm.editingTagColor,color)},on:{"change":function($event){_vm.editingTagColor=color}}}),_c('div',{staticClass:"color-label pointer"})])}),0)],1),_c('div',{staticClass:"row-between bt-grey p-5"},[_c('button',{staticClass:"transparent-button f-12-grey",attrs:{"disabled":_vm.requestIsGoing},on:{"click":function($event){return _vm.deleteTag(tag.id)}}},[_c('trash-icon',{attrs:{"width":"18","height":"12"}}),_vm._v("\n                        Delete\n                    ")],1),_c('button',{staticClass:"transparent-button f-12-blue f-sbold",attrs:{"disabled":_vm.requestIsGoing},on:{"click":function($event){return _vm.updateTag()}}},[_vm._v("\n                        Save\n                    ")])])])]}),(!_vm.isCreateNewShow)?_c('button',{staticClass:"w-100 transparent-button p-8 f-13-blue f-sbold t-center",on:{"click":function($event){_vm.hideEditField(), _vm.isCreateNewShow = true}}},[_vm._v("\n            + Create a new label\n        ")]):_c('div',{staticClass:"tag-edit w-100 bt-grey mt-1"},[_c('div',{staticClass:"column-start p-4"},[_c('div',{staticClass:"row-between mb-4"},[_c('span',{staticClass:"f-11-black f-sbold"},[_vm._v("Create new label")]),_c('button',{staticClass:"icon-light",on:{"click":function($event){_vm.isCreateNewShow = false}}},[_c('close-icon',{attrs:{"size":"9"}})],1)]),_c('form-input',{class:[
                        'mb-6',
                        _vm.errors.has('new_tag_name') ? 'form-input-danger' : ''
                    ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newTagName),expression:"newTagName"},{name:"validate",rawName:"v-validate.persist",value:('required|min:3|max:99'),expression:"'required|min:3|max:99'",modifiers:{"persist":true}}],staticClass:"form-input-field",attrs:{"type":"text","name":"new_tag_name","placeholder":"Tag name","maxlength":"100"},domProps:{"value":(_vm.newTagName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.newTagName=$event.target.value}}})]),_c('p',{staticClass:"f-11-black f-sbold mb-3"},[_vm._v("Color")]),_c('div',{staticClass:"row-between"},_vm._l((_vm.colors),function(color,index){return _c('label',{key:index,class:[
                            'tag-color',
                            ("tag-color-" + color)
                        ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newTagColor),expression:"newTagColor"}],attrs:{"type":"radio","name":"new_tag_color"},domProps:{"value":color,"checked":_vm._q(_vm.newTagColor,color)},on:{"change":function($event){_vm.newTagColor=color}}}),_c('div',{staticClass:"color-label pointer"})])}),0)],1),_c('div',{staticClass:"row-end bt-grey p-5"},[_c('button',{staticClass:"transparent-button f-12-grey f-sbold",on:{"click":function($event){_vm.isCreateNewShow = false}}},[_vm._v("\n                    Cancel\n                ")]),_c('button',{staticClass:"transparent-button f-12-blue f-sbold ml-8",attrs:{"disabled":_vm.requestIsGoing},on:{"click":_vm.createTag}},[_vm._v("\n                    Create\n                ")])])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }