import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store.js'
import qs from 'qs';
import {PERMISSIONS} from '@/plugins/ACL/constants';
import somePermission from '@/plugins/ACL/middleware/somePermission.js';
import hasPermissions from '@/plugins/ACL/middleware/hasPermissions.js';
import everySomePermissions from "@/plugins/ACL/middleware/everySomePermissions";

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters['auth/isAuth']) {
        next();
        return;
    }
    
    next('/');
};

const ifAuthenticated = (to, from, next) => {
    store.commit('setReferrer', from.name === null ? to.fullPath : from.fullPath);
    localStorage.setItem('set-referrer', from.name === null ? to.fullPath : from.fullPath);
    
    if (store.getters['auth/isAuth']) {
        next();
        return;
    }
    
    next('/auth/login');
};

let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, saved) {
        if (to.matched.some(m => m.meta.disableScroll)) {
            return;
        }
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/auth/login',
            name: 'Login',
            component: () => import('./views/Auth/Login.vue'),
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/auth/forgot',
            name: 'ForgotPassword',
            component: () => import('./views/Auth/ForgotPassword.vue'),
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/auth/check',
            name: 'CheckEmail',
            component: () => import('./views/Auth/CheckEmail.vue'),
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/auth/reset',
            name: 'ResetPassword',
            component: () => import('./views/Auth/ResetPassword.vue'),
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/auth/welcome',
            name: 'Welcome',
            component: () => import('./views/Auth/Welcome.vue'),
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/',
            name: 'Dashboard',
            component: () => import('./views/Dashboard.vue'),
            beforeEnter: ifAuthenticated
        },
        {
            path: '/contacts',
            name: 'Contacts',
            component: () => import('./views/Contacts/Contacts.vue'),
            beforeEnter: ifAuthenticated
        },
        {
            path: '/point-of-contact-details/:contact_id',
            name: 'PointOfContactDetails',
            component: () => import('./views/PointOfContactDetails/PointOfContactDetails.vue'),
            beforeEnter: ifAuthenticated
        },
        {
            path: '/opportunities',
            name: 'Opportunities',
            component: () => import('./views/Opportunities/Opportunities.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_ALL_LEADS,
                    PERMISSIONS.VIEW_MY_LEADS,
                ],
                middleware: somePermission
            }
        },
        {
            path: '/opportunity-details/:opportunity_id',
            name: 'OpportunityDetails',
            component: () => import('./views/Opportunities/OpportunityDetails.vue'),
            beforeEnter: ifAuthenticated,
            redirect: {name: 'OpportunityOverview'},
            children: [
                {
                    path: 'overview',
                    name: 'OpportunityOverview',
                    component: () => import('./views/Opportunities/OpportunityOverview.vue'),
                    meta: {
                        related_to: 'OpportunityOverview',
                        title: 'Overview',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'documents',
                    name: 'OpportunityDocuments',
                    component: () => import('./views/LeadModules/Documents.vue'),
                    meta: {
                        related_to: 'OpportunityDocuments',
                        title: 'Documents',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_DOCUMENTS,
                            PERMISSIONS.VIEW_MY_DOCUMENTS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'messages',
                    name: 'OpportunityMessages',
                    props: true,
                    component: () => import('./views/LeadModules/Messages.vue'),
                    meta: {
                        related_to: 'OpportunityMessages',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_EMAILS,
                            PERMISSIONS.VIEW_MY_EMAILS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'calls',
                    name: 'OpportunityCalls',
                    props: true,
                    component: () => import('./views/LeadModules/Calls.vue'),
                    meta: {
                        related_to: 'OpportunityCalls',
                    }
                },
                {
                    path: 'tasks',
                    name: 'OpportunityTasks',
                    component: () => import('./views/LeadModules/Tasks.vue'),
                    meta: {
                        related_to: 'OpportunityTasks',
                        title: 'Tasks',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_TASKS,
                            PERMISSIONS.VIEW_MY_TASKS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'events',
                    name: 'OpportunityEvents',
                    component: () => import('./views/LeadModules/Events.vue'),
                    meta: {
                        related_to: 'OpportunityEvents',
                        title: 'Events',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS,
                            PERMISSIONS.VIEW_MY_CALENDAR,
                            PERMISSIONS.CREATE_EVENT_FOR_OTHERS
                        ],
                        middleware: somePermission
                    }
                },
            ]
        },
        {
            path: '/new-opportunity',
            name: 'NewOpportunity',
            component: () => import('./views/Opportunities/OpportunityCreate.vue'),
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/contact-information/:contact_id',
            name: 'ContactInformation',
            component: () => import('./views/Contacts/ContactInformation'),
            beforeEnter: ifAuthenticated,
            redirect: {name: 'ContactMessagesAndCalls'},
            children: [
                {
                    path: 'documents',
                    name: 'ContactDocuments',
                    component: () => import('./views/LeadModules/Documents.vue'),
                    meta: {
                        related_to: 'ContactDocuments',
                        title: 'Documents',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_DOCUMENTS,
                            PERMISSIONS.VIEW_MY_DOCUMENTS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'opportunities',
                    name: 'ContactOpportunities',
                    component: () => import('./views/Contacts/ContactOpportunities.vue'),
                    meta: {
                        related_to: 'ContactOpportunities',
                        title: 'Opportunities',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_DOCUMENTS,
                            PERMISSIONS.VIEW_MY_DOCUMENTS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'leads',
                    name: 'ContactLeads',
                    component: () => import('./views/Contacts/ContactLeads.vue'),
                    meta: {
                        related_to: 'ContactLeads',
                        title: 'Leads',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_DOCUMENTS,
                            PERMISSIONS.VIEW_MY_DOCUMENTS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: '',
                    name: 'ContactMessagesAndCalls',
                    component: () => import('./views/Contacts/ContactMessagesAndCalls'),
                    meta: {
                        related_to: 'ContactMessagesAndCalls',
                        title: 'ContactMessagesAndCalls'
                    },
                    redirect: {name: 'ContactMessages'},
                    children: [
                        {
                            path: 'messages',
                            name: 'ContactMessages',
                            component: () => import('./views/Contacts/ContactsMessages'),
                            meta: {
                                related_to: 'ContactMessages',
                                title: 'Messages'
                            }
                        },
                        {
                            path: 'calls',
                            name: 'ContactCalls',
                            component: () => import('./views/Contacts/ContactsCalls'),
                            meta: {
                                related_to: 'ContactCalls',
                                title: 'Calls'
                            }
                        },
                    ]
                }
            ]
        },
        {
            path: '/new-lead',
            name: 'CreateNewLead',
            component: () => import('./views/CreateLead/CreateNewLead.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.CREATE_LEAD
                ],
                middleware: hasPermissions
            }
        },
        {
            path: '/new-residential',
            name: 'CreateNewResidential',
            component: () => import('./views/CreateLead/CreateNewResidential.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.CREATE_LEAD
                ],
                middleware: hasPermissions
            }
        },
        {
            path: '/new-warranty-job',
            name: 'CreateNewWarranty',
            component: () => import('./views/Warranty/CreateNewWarranty'),
            beforeEnter: ifAuthenticated,
            meta: {
                middleware: hasPermissions
            }
        },
        {
            path: '/new-commercial',
            name: 'CreateNewCommercial',
            component: () => import('./views/CreateLead/CreateNewCommercial.vue'),
            redirect: {
                name: 'CommercialCustomer',
                params: {customer_id: 'create-new'}
            },
            beforeEnter: ifAuthenticated,
            children: [
                {
                    path: 'customer/:customer_id',
                    name: 'CommercialCustomer',
                    component: () => import('./views/CreateLead/CreateCommercialSteps/CommercialCustomer.vue'),
                    meta: {
                        related_to: 'commercial',
                        permissions: [
                            PERMISSIONS.CREATE_LEAD
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'property/:customer_id',
                    name: 'CommercialProperty',
                    component: () => import('./views/CreateLead/CreateCommercialSteps/CommercialProperty.vue'),
                    meta: {
                        related_to: 'commercial',
                        permissions: [
                            PERMISSIONS.CREATE_LEAD
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'job/:customer_id/:property_id/:unit_id?',
                    name: 'CommercialJob',
                    component: () => import('./views/CreateLead/CreateCommercialSteps/CommercialJob.vue'),
                    meta: {
                        related_to: 'commercial',
                        permissions: [
                            PERMISSIONS.CREATE_LEAD
                        ],
                        middleware: hasPermissions
                    }
                }
            ]
        },
        {
            path: '/new-commercial-lead/:property_id?',
            name: 'CreateNewCommercialLead',
            component: () => import('./views/CreateLead/CreateNewCommercialLead.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                related_to: 'commercial',
                permissions: [
                    PERMISSIONS.CREATE_LEAD
                ],
                middleware: hasPermissions
            }
        },
        {
            path: '/residential-job-details',
            redirect: {name: 'ResidentialLeads'}
        },
        {
            path: '/residential-job-details/:lead_id',
            name: 'ResidentialJobDetails',
            component: () => import('./views/ResidentialJob/ResidentialJobDetails.vue'),
            redirect: {name: 'Overview'},
            beforeEnter: ifAuthenticated,
            children: [
                {
                    path: 'overview',
                    name: 'Overview',
                    component: () => import('./views/ResidentialJob/Overview.vue'),
                    meta: {
                        related_to: 'Overview',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'documents',
                    name: 'ResidentialDocuments',
                    component: () => import('./views/LeadModules/Documents.vue'),
                    meta: {
                        related_to: 'ResidentialDocuments',
                        title: 'Documents',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_DOCUMENTS,
                            PERMISSIONS.VIEW_MY_DOCUMENTS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'measurements',
                    name: 'ResidentialMeasurements',
                    component: () => import('./views/LeadModules/Measurements.vue'),
                    meta: {
                        related_to: 'ResidentialMeasurements',
                        title: 'Measurements',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_MEASUREMENTS,
                            PERMISSIONS.VIEW_MY_MEASUREMENTS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'measurements/:measurement_id',
                    name: 'ResidentialMeasurementCreate',
                    component: () => import('./views/LeadModules/MeasurementCreate.vue'),
                    meta: {
                        related_to: 'ResidentialMeasurements',
                        title: 'Measurements',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_MEASUREMENTS,
                            PERMISSIONS.VIEW_MY_MEASUREMENTS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'order',
                    name: 'ResidentialMeasurementOrder',
                    component: () => import('./views/LeadModules/MeasurementOrder.vue'),
                    meta: {
                        related_to: 'ResidentialMeasurements',
                        title: 'Measurements',
                        permissions: [
                            PERMISSIONS.ORDER_EAGLEVIEW
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'estimates',
                    name: 'ResidentialEstimates',
                    component: () => import('./views/LeadModules/Estimates.vue'),
                    meta: {
                        related_to: 'ResidentialEstimates',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_ESTIMATE,
                            PERMISSIONS.VIEW_MY_ESTIMATE
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'estimate-create',
                    name: 'ResidentialEstimateCreate',
                    component: () => import('./views/LeadModules/EstimateCreate.vue'),
                    meta: {
                        related_to: 'ResidentialEstimates',
                        permissions: [
                            PERMISSIONS.CREATE_ESTIMATE,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'manual-estimate-create/:estimate_id?',
                    name: 'ResidentialManualEstimateCreate',
                    component: () => import('./views/LeadModules/ManualEstimateCreate.vue'),
                    meta: {
                        related_to: 'ResidentialEstimates',
                        permissions: [
                            PERMISSIONS.CREATE_ESTIMATE,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'estimate-edit/:estimate_id',
                    name: 'ResidentialEstimateEdit',
                    component: () => import('./views/LeadModules/EstimateEdit.vue'),
                    meta: {
                        related_to: 'ResidentialEstimates',
                        permissions: [
                            [PERMISSIONS.VIEW_ALL_ESTIMATE, PERMISSIONS.VIEW_MY_ESTIMATE],
                            PERMISSIONS.EDIT_ESTIMATE,
                        ],
                        middleware: everySomePermissions
                    }
                },
                {
                    path: 'packets',
                    name: 'ResidentialPackets',
                    component: () => import('./views/LeadModules/Packets.vue'),
                    meta: {
                        related_to: 'ResidentialPackets',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'packet-create/:packet_id?',
                    name: 'CreateResidentialPacket',
                    component: () => import('./views/LeadModules/PacketCreate.vue'),
                    meta: {
                        related_to: 'ResidentialPackets',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'tasks',
                    name: 'ResidentialTasks',
                    component: () => import('./views/LeadModules/Tasks.vue'),
                    meta: {
                        related_to: 'ResidentialTasks',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_TASKS,
                            PERMISSIONS.VIEW_MY_TASKS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'messages',
                    name: 'ResidentialMessages',
                    props: true,
                    component: () => import('./views/LeadModules/Messages.vue'),
                    meta: {
                        related_to: 'ResidentialMessages',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_EMAILS,
                            PERMISSIONS.VIEW_MY_EMAILS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'calls',
                    name: 'ResidentialCalls',
                    props: true,
                    component: () => import('./views/LeadModules/Calls.vue'),
                    meta: {
                        related_to: 'ResidentialCalls',
                    }
                },
                {
                    path: 'form-builder',
                    name: 'ResidentialFormBuilder',
                    component: () => import('./views/LeadModules/FormBuilder.vue'),
                    meta: {
                        related_to: 'ResidentialFormBuilder',
                        title: 'Form Builder',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'all-reports',
                    name: 'ResidentialFormBuilderReportList',
                    component: () => import('./views/LeadModules/FormBuilderReportList.vue'),
                    meta: {
                        related_to: 'ResidentialFormBuilder',
                        title: 'All Reports',
                        permissions: [
                            PERMISSIONS.EDIT_CUSTOM_FIELDS,
                            PERMISSIONS.EDIT_CUSTOM_FIELDS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'lead-settings',
                    name: 'ResidentialSettings',
                    component: () => import('./views/LeadModules/Settings.vue'),
                    meta: {
                        related_to: 'ResidentialSettings',
                        title: 'Settings',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'events',
                    name: 'Events',
                    component: () => import('./views/LeadModules/Events.vue'),
                    meta: {
                        related_to: 'Events',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS,
                            PERMISSIONS.VIEW_MY_CALENDAR,
                            PERMISSIONS.CREATE_EVENT_FOR_OTHERS
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'contract-worksheet',
                    name: 'ContractWorksheet',
                    component: () => import('./views/LeadModules/ContractWorksheet.vue'),
                    meta: {
                        related_to: 'ContractWorksheet',
                        permissions: [
                            PERMISSIONS.EDIT_LEAD,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'orders',
                    name: 'ResidentialOrders',
                    component: () => import('./views/LeadModules/Orders.vue'),
                    meta: {
                        related_to: 'ResidentialOrders',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_ORDERS,
                            PERMISSIONS.VIEW_MY_ORDERS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'order-create/:order_id?',
                    name: 'ResidentialOrderCreate',
                    component: () => import('./views/LeadModules/OrderCreate.vue'),
                    meta: {
                        related_to: 'ResidentialOrders',
                        permissions: [
                            PERMISSIONS.EDIT_LEAD,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'warranty',
                    name: 'ResidentialJobWarranty',
                    props: true,
                    component: () => import('./views/Warranty/WarrantyOverview.vue'),
                    meta: {
                        related_to: 'ResidentialJobWarranty',
                    }
                },
                {
                    path: 'postcards',
                    name: 'ResidentialJobPostcards',
                    props: true,
                    component: () => import('./views/Warranty/Postcards.vue'),
                    meta: {
                        related_to: 'ResidentialJobPostcards',
                    }
                },
                {
                    path: 'warranty-create',
                    name: 'ResidentialJobWarrantyCreate',
                    props: true,
                    component: () => import('./views/Warranty/WarrantyCreate.vue'),
                    meta: {
                        related_to: 'ResidentialJobWarrantyCreate',
                    }
                },
            ]
        },
        {
            path: '/residential-estimate-preview/:lead_id/:estimate_id',
            name: 'ResidentialEstimatePreview',
            component: () => import('./views/ResidentialJob/ResidentialEstimatePreview.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                related_to: 'ResidentialEstimates',
                permissions: [
                    PERMISSIONS.VIEW_ALL_ESTIMATE,
                    PERMISSIONS.VIEW_MY_ESTIMATE,
                ],
                middleware: somePermission
            }
        },
        {
            path: '/commercial-job-estimate-preview/:property_id/:job_id/:estimate_id',
            name: 'CommercialJobEstimatePreview',
            component: () => import('./views/CommercialJob/CommercialEstimatePreview.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                related_to: 'CommercialJobEstimates',
                permissions: [
                    PERMISSIONS.VIEW_ALL_ESTIMATE,
                    PERMISSIONS.VIEW_MY_ESTIMATE,
                ],
                middleware: somePermission
            }
        },
        {
            path: '/commercial-lead-estimate-preview/:lead_id/:estimate_id',
            name: 'CommercialLeadEstimatePreview',
            component: () => import('./views/CommercialJob/CommercialEstimatePreview.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                related_to: 'CommercialLeadEstimates',
                permissions: [
                    PERMISSIONS.VIEW_ALL_ESTIMATE,
                    PERMISSIONS.VIEW_MY_ESTIMATE,
                ],
                middleware: somePermission
            }
        },
        {
            path: '/order-preview/:property_id?/:entity_type/:entity_id/:order_id',
            name: 'OrderPreview',
            component: () => import('./views/LeadModules/OrderPreview.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                related_to: 'Orders',
                permissions: [
                    PERMISSIONS.VIEW_ALL_ESTIMATE,
                    PERMISSIONS.VIEW_MY_ESTIMATE,
                ],
                middleware: somePermission
            }
        },
        {
            path: '/warranty-preview/:id',
            name: 'WarrantyPreview',
            component: () => import('./views/Warranty/WarrantyPreview.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                related_to: 'CommercialJobDetails',
                permissions: [
                    PERMISSIONS.VIEW_ALL_ESTIMATE,
                    PERMISSIONS.VIEW_MY_ESTIMATE,
                ],
                middleware: somePermission
            }
        },
        {
            path: '/commercial-details/:customer_id/:property_id?/:unit_id?/:job_id?',
            name: 'CommercialDetails',
            component: () => import('./views/CommercialJob/CommercialDetails.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_ALL_LEADS,
                    PERMISSIONS.VIEW_MY_LEADS,
                ],
                middleware: somePermission
            },
            children: [
                {
                    path: 'documents',
                    name: 'PropertyDocuments',
                    component: () => import('./views/LeadModules/Documents.vue'),
                    meta: {
                        related_to: 'PropertyDocuments',
                        title: 'Documents',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_DOCUMENTS,
                            PERMISSIONS.VIEW_MY_DOCUMENTS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'events',
                    name: 'PropertyEvents',
                    component: () => import('./views/LeadModules/Events.vue'),
                    meta: {
                        related_to: 'PropertyEvents',
                        title: 'Events',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS,
                            PERMISSIONS.VIEW_MY_CALENDAR,
                            PERMISSIONS.CREATE_EVENT_FOR_OTHERS
                        ],
                        middleware: somePermission
                    }
                }
            ]
        },
        {
            path: '/commercial-job-details/:property_id/:job_id',
            name: 'CommercialJobDetails',
            component: () => import('./views/CommercialJob/CommercialJobDetails.vue'),
            redirect: {name: 'CommercialJobOverview'},
            beforeEnter: ifAuthenticated,
            children: [
                {
                    path: 'overview',
                    name: 'CommercialJobOverview',
                    component: () => import('./views/CommercialJob/CommercialJobOverview.vue'),
                    meta: {
                        related_to: 'CommercialJobOverview',
                        title: 'Overview',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'documents',
                    name: 'CommercialJobDocuments',
                    component: () => import('./views/LeadModules/Documents.vue'),
                    meta: {
                        related_to: 'CommercialJobDocuments',
                        title: 'Documents',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_DOCUMENTS,
                            PERMISSIONS.VIEW_MY_DOCUMENTS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'measurements',
                    name: 'CommercialJobMeasurements',
                    component: () => import('./views/LeadModules/Measurements.vue'),
                    meta: {
                        related_to: 'CommercialJobMeasurements',
                        title: 'Measurements',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_MEASUREMENTS,
                            PERMISSIONS.VIEW_MY_MEASUREMENTS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'measurements/:measurement_id',
                    name: 'CommercialJobMeasurementCreate',
                    component: () => import('./views/LeadModules/MeasurementCreate.vue'),
                    meta: {
                        related_to: 'CommercialJobMeasurements',
                        title: 'Measurements',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_MEASUREMENTS,
                            PERMISSIONS.VIEW_MY_MEASUREMENTS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'order',
                    name: 'CommercialJobMeasurementOrder',
                    component: () => import('./views/LeadModules/MeasurementOrder.vue'),
                    meta: {
                        related_to: 'CommercialJobMeasurements',
                        title: 'Measurements',
                        permissions: [
                            PERMISSIONS.ORDER_EAGLEVIEW,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'events',
                    name: 'CommercialJobEvents',
                    component: () => import('./views/LeadModules/Events.vue'),
                    meta: {
                        related_to: 'CommercialJobEvents',
                        title: 'Events',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS,
                            PERMISSIONS.VIEW_MY_CALENDAR,
                            PERMISSIONS.CREATE_EVENT_FOR_OTHERS
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'estimates',
                    name: 'CommercialJobEstimates',
                    component: () => import('./views/LeadModules/Estimates.vue'),
                    meta: {
                        related_to: 'CommercialJobEstimates',
                        title: 'Estimates',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_ESTIMATE,
                            PERMISSIONS.VIEW_MY_ESTIMATE
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'estimate-create',
                    name: 'CommercialJobEstimateCreate',
                    component: () => import('./views/LeadModules/EstimateCreate.vue'),
                    meta: {
                        related_to: 'CommercialJobEstimates',
                        title: 'Estimates',
                        permissions: [
                            PERMISSIONS.CREATE_ESTIMATE,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'manual-estimate-create/:estimate_id?',
                    name: 'CommercialJobManualEstimateCreate',
                    component: () => import('./views/LeadModules/ManualEstimateCreate.vue'),
                    meta: {
                        related_to: 'CommercialJobEstimates',
                        permissions: [
                            PERMISSIONS.CREATE_ESTIMATE,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'estimate-edit/:estimate_id',
                    name: 'CommercialJobEstimateEdit',
                    component: () => import('./views/LeadModules/EstimateEdit.vue'),
                    meta: {
                        related_to: 'CommercialJobEstimates',
                        title: 'Estimates',
                        permissions: [
                            [PERMISSIONS.VIEW_ALL_ESTIMATE, PERMISSIONS.VIEW_MY_ESTIMATE],
                            PERMISSIONS.EDIT_ESTIMATE,
                        ],
                        middleware: everySomePermissions
                    }
                },
                {
                    path: 'tasks',
                    name: 'CommercialJobTasks',
                    component: () => import('./views/LeadModules/Tasks.vue'),
                    meta: {
                        related_to: 'CommercialJobTasks',
                        title: 'Tasks',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_TASKS,
                            PERMISSIONS.VIEW_MY_TASKS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'orders',
                    name: 'CommercialJobOrders',
                    component: () => import('./views/LeadModules/Orders.vue'),
                    meta: {
                        related_to: 'CommercialJobOrders',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_ORDERS,
                            PERMISSIONS.VIEW_MY_ORDERS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'order-create/:order_id?',
                    name: 'CommercialJobOrderCreate',
                    component: () => import('./views/LeadModules/OrderCreate.vue'),
                    meta: {
                        related_to: 'CommercialJobOrders',
                        permissions: [
                            PERMISSIONS.EDIT_LEAD,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'packets',
                    name: 'CommercialJobPackets',
                    component: () => import('./views/LeadModules/Packets.vue'),
                    meta: {
                        related_to: 'CommercialJobPackets',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'packet-create/:packet_id?',
                    name: 'CreateCommercialJobPacket',
                    component: () => import('./views/LeadModules/PacketCreate.vue'),
                    meta: {
                        related_to: 'CommercialJobPackets',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'form-builder',
                    name: 'CommercialJobFormBuilder',
                    component: () => import('./views/LeadModules/FormBuilder.vue'),
                    meta: {
                        related_to: 'CommercialJobFormBuilder',
                        title: 'Form Builder',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'job-settings',
                    name: 'CommercialJobSettings',
                    component: () => import('./views/LeadModules/Settings.vue'),
                    meta: {
                        related_to: 'CommercialJobSettings',
                        title: 'Settings',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'all-reports',
                    name: 'CommercialJobFormBuilderReportList',
                    component: () => import('./views/LeadModules/FormBuilderReportList.vue'),
                    meta: {
                        related_to: 'CommercialJobFormBuilder',
                        title: 'All Reports',
                        permissions: [
                            PERMISSIONS.EDIT_CUSTOM_FIELDS,
                            PERMISSIONS.EDIT_CUSTOM_FIELDS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'messages',
                    name: 'CommercialJobMessages',
                    props: true,
                    component: () => import('./views/LeadModules/Messages.vue'),
                    meta: {
                        related_to: 'CommercialJobMessages',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_EMAILS,
                            PERMISSIONS.VIEW_MY_EMAILS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'calls',
                    name: 'CommercialJobCalls',
                    props: true,
                    component: () => import('./views/LeadModules/Calls.vue'),
                    meta: {
                        related_to: 'CommercialJobCalls',
                    }
                },
                {
                    path: 'warranty',
                    name: 'CommercialJobWarranty',
                    props: true,
                    component: () => import('./views/Warranty/WarrantyOverview.vue'),
                    meta: {
                        related_to: 'CommercialJobWarranty',
                    }
                },
                {
                    path: 'postcards',
                    name: 'CommercialJobPostcards',
                    props: true,
                    component: () => import('./views/Warranty/Postcards.vue'),
                    meta: {
                        related_to: 'CommercialJobPostcards',
                    }
                },
                {
                    path: 'warranty-create',
                    name: 'CommercialJobWarrantyCreate',
                    props: true,
                    component: () => import('./views/Warranty/WarrantyCreate.vue'),
                    meta: {
                        related_to: 'CommercialJobWarrantyCreate',
                    }
                },
            ]
        },
        {
            path: '/commercial-lead-details/:lead_id',
            name: 'CommercialLeadDetails',
            component: () => import('./views/CommercialLead/CommercialLeadDetails.vue'),
            redirect: {name: 'CommercialLeadOverview'},
            beforeEnter: ifAuthenticated,
            children: [
                {
                    path: 'overview',
                    name: 'CommercialLeadOverview',
                    component: () => import('./views/CommercialLead/CommercialLeadOverview.vue'),
                    meta: {
                        related_to: 'CommercialLeadDetails',
                        title: 'Overview',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'measurements',
                    name: 'CommercialLeadMeasurements',
                    component: () => import('./views/LeadModules/Measurements.vue'),
                    meta: {
                        related_to: 'CommercialLeadMeasurements',
                        title: 'Measurements',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_MEASUREMENTS,
                            PERMISSIONS.VIEW_MY_MEASUREMENTS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'orders',
                    name: 'CommercialLeadOrders',
                    component: () => import('./views/LeadModules/Orders.vue'),
                    meta: {
                        related_to: 'CommercialLeadOrders',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_ORDERS,
                            PERMISSIONS.VIEW_MY_ORDERS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'order-create/:order_id?',
                    name: 'CommercialLeadOrderCreate',
                    component: () => import('./views/LeadModules/OrderCreate.vue'),
                    meta: {
                        related_to: 'CommercialLeadOrders',
                        permissions: [
                            PERMISSIONS.EDIT_LEAD,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'measurements/:measurement_id',
                    name: 'CommercialLeadMeasurementCreate',
                    component: () => import('./views/LeadModules/MeasurementCreate.vue'),
                    meta: {
                        related_to: 'CommercialLeadMeasurements',
                        title: 'Measurements',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_MEASUREMENTS,
                            PERMISSIONS.VIEW_MY_MEASUREMENTS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'order',
                    name: 'CommercialLeadMeasurementOrder',
                    component: () => import('./views/LeadModules/MeasurementOrder.vue'),
                    meta: {
                        related_to: 'CommercialLeadMeasurements',
                        title: 'Measurements',
                        permissions: [
                            PERMISSIONS.ORDER_EAGLEVIEW,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'packets',
                    name: 'CommercialLeadPackets',
                    component: () => import('./views/LeadModules/Packets.vue'),
                    meta: {
                        related_to: 'CommercialLeadPackets',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'packet-create/:packet_id?',
                    name: 'CreateCommercialLeadPacket',
                    component: () => import('./views/LeadModules/PacketCreate.vue'),
                    meta: {
                        related_to: 'CommercialLeadPackets',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'tasks',
                    name: 'CommercialLeadTasks',
                    component: () => import('./views/LeadModules/Tasks.vue'),
                    meta: {
                        related_to: 'CommercialLeadTasks',
                        title: 'Tasks',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_TASKS,
                            PERMISSIONS.VIEW_MY_TASKS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'documents',
                    name: 'CommercialLeadDocuments',
                    component: () => import('./views/LeadModules/Documents.vue'),
                    meta: {
                        related_to: 'CommercialLeadDocuments',
                        title: 'Documents',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_DOCUMENTS,
                            PERMISSIONS.VIEW_MY_DOCUMENTS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'events',
                    name: 'CommercialLeadEvents',
                    component: () => import('./views/LeadModules/Events.vue'),
                    meta: {
                        related_to: 'CommercialLeadEvents',
                        title: 'Events',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS,
                            PERMISSIONS.VIEW_MY_CALENDAR,
                            PERMISSIONS.CREATE_EVENT_FOR_OTHERS
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'form-builder',
                    name: 'CommercialLeadFormBuilder',
                    component: () => import('./views/LeadModules/FormBuilder.vue'),
                    meta: {
                        related_to: 'CommercialLeadFormBuilder',
                        title: 'Form Builder',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'lead-settings',
                    name: 'CommercialLeadSettings',
                    component: () => import('./views/LeadModules/Settings.vue'),
                    meta: {
                        related_to: 'CommercialLeadSettings',
                        title: 'Settings',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'all-reports',
                    name: 'CommercialLeadFormBuilderReportList',
                    component: () => import('./views/LeadModules/FormBuilderReportList.vue'),
                    meta: {
                        related_to: 'CommercialLeadFormBuilder',
                        title: 'All Reports',
                        permissions: [
                            PERMISSIONS.EDIT_CUSTOM_FIELDS,
                            PERMISSIONS.EDIT_CUSTOM_FIELDS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'estimates',
                    name: 'CommercialLeadEstimates',
                    component: () => import('./views/LeadModules/Estimates.vue'),
                    meta: {
                        related_to: 'CommercialLeadEstimates',
                        title: 'Estimates',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_ESTIMATE,
                            PERMISSIONS.VIEW_MY_ESTIMATE
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'estimate-create',
                    name: 'CommercialLeadEstimateCreate',
                    component: () => import('./views/LeadModules/EstimateCreate.vue'),
                    meta: {
                        related_to: 'CommercialLeadEstimates',
                        title: 'Estimates',
                        permissions: [
                            PERMISSIONS.CREATE_ESTIMATE,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'manual-estimate-create/:estimate_id?',
                    name: 'CommercialLeadManualEstimateCreate',
                    component: () => import('./views/LeadModules/ManualEstimateCreate.vue'),
                    meta: {
                        related_to: 'CommercialLeadEstimates',
                        permissions: [
                            PERMISSIONS.CREATE_ESTIMATE,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'estimate-edit/:estimate_id',
                    name: 'CommercialLeadEstimateEdit',
                    component: () => import('./views/LeadModules/EstimateEdit.vue'),
                    meta: {
                        related_to: 'CommercialLeadEstimates',
                        title: 'Estimates',
                        permissions: [
                            [PERMISSIONS.VIEW_ALL_ESTIMATE, PERMISSIONS.VIEW_MY_ESTIMATE],
                            PERMISSIONS.EDIT_ESTIMATE,
                        ],
                        middleware: everySomePermissions
                    }
                },
                {
                    path: 'messages',
                    name: 'CommercialLeadMessages',
                    props: true,
                    component: () => import('./views/LeadModules/Messages.vue'),
                    meta: {
                        related_to: 'CommercialLeadMessages',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_EMAILS,
                            PERMISSIONS.VIEW_MY_EMAILS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'calls',
                    name: 'CommercialLeadCalls',
                    props: true,
                    component: () => import('./views/LeadModules/Calls.vue'),
                    meta: {
                        related_to: 'CommercialLeadCalls',
                    }
                },
            ]
        },
        {
            path: '/commercial-property/:property_id',
            name: 'CommercialPropertyWithoutCustomer',
            component: () => import('./views/CommercialLead/CommercialPropertyWithoutCustomer.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_ALL_LEADS,
                    PERMISSIONS.VIEW_MY_LEADS,
                ],
                middleware: somePermission
            },
            children: [
                {
                    path: 'documents',
                    name: 'PropertyDocumentsList',
                    component: () => import('./views/LeadModules/Documents.vue'),
                    meta: {
                        related_to: 'PropertyDocumentsList',
                        title: 'Documents',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_DOCUMENTS,
                            PERMISSIONS.VIEW_MY_DOCUMENTS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'events',
                    name: 'PropertyEventsWithoutCustomer',
                    component: () => import('./views/LeadModules/Events.vue'),
                    meta: {
                        related_to: 'PropertyEventsWithoutCustomer',
                        title: 'Events',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS,
                            PERMISSIONS.VIEW_MY_CALENDAR,
                            PERMISSIONS.CREATE_EVENT_FOR_OTHERS
                        ],
                        middleware: somePermission
                    }
                }
            ]
        },
        {
            path: '/residential-leads',
            name: 'ResidentialLeads',
            component: () => import('./views/LeadList/ResidentialLeads.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_ALL_LEADS,
                    PERMISSIONS.VIEW_MY_LEADS,
                ],
                middleware: somePermission
            }
        },
        {
            path: '/commercial-leads',
            name: 'CommercialLeads',
            component: () => import('./views/LeadList/CommercialLeads.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_ALL_LEADS,
                    PERMISSIONS.VIEW_MY_LEADS,
                ],
                middleware: somePermission
            }
        },
        {
            path: '/commercial-customers',
            name: 'CommercialCustomerList',
            component: () => import('./views/LeadList/CommercialCustomerList.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_ALL_LEADS,
                    PERMISSIONS.VIEW_MY_LEADS,
                ],
                middleware: somePermission
            }
        },
        {
            path: '/commercial-properties',
            name: 'CommercialPropertyList',
            component: () => import('./views/LeadList/CommercialPropertyList.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_ALL_LEADS,
                    PERMISSIONS.VIEW_MY_LEADS,
                ],
                middleware: somePermission
            }
        },
        {
            path: '/commercial-units',
            name: 'CommercialUnitList',
            component: () => import('./views/LeadList/CommercialUnitList.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_ALL_LEADS,
                    PERMISSIONS.VIEW_MY_LEADS,
                ],
                middleware: somePermission
            }
        },
        {
            path: '/commercial-jobs',
            name: 'CommercialJobList',
            component: () => import('./views/LeadList/CommercialJobList.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_ALL_LEADS,
                    PERMISSIONS.VIEW_MY_LEADS,
                ],
                middleware: somePermission
                
            }
        },
        {
            path: '/warranty-case-create',
            name: 'WarrantyCaseCreate',
            component: () => import('./views/Warranty/WarrantyCaseCreate.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_ALL_LEADS,
                    PERMISSIONS.VIEW_MY_LEADS,
                ],
                middleware: somePermission
            
            }
        },
        {
            path: '/warranty-jobs',
            name: 'WarrantyJobs',
            component: () => import('./views/Warranty/WarrantyJobs.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_ALL_LEADS,
                    PERMISSIONS.VIEW_MY_LEADS,
                ],
                middleware: somePermission
            
            }
        },
        {
            path: '/warranty-job-details/:job_id',
            name: 'WarrantyJobDetails',
            component: () => import('./views/Warranty/WarrantyJobDetails.vue'),
            redirect: {name: 'WarrantyOverview'},
            beforeEnter: ifAuthenticated,
            children: [
                {
                    path: 'overview',
                    name: 'WarrantyOverview',
                    component: () => import('./views/Warranty/WarrantyJobOverview.vue'),
                    meta: {
                        related_to: 'WarrantyOverview',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'messages',
                    name: 'WarrantyJobMessages',
                    props: true,
                    component: () => import('./views/LeadModules/Messages.vue'),
                    meta: {
                        related_to: 'WarrantyJobMessages',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_EMAILS,
                            PERMISSIONS.VIEW_MY_EMAILS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'documents',
                    name: 'WarrantyDocuments',
                    component: () => import('./views/LeadModules/Documents.vue'),
                    meta: {
                        related_to: 'WarrantyDocuments',
                        title: 'Documents',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_DOCUMENTS,
                            PERMISSIONS.VIEW_MY_DOCUMENTS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'orders',
                    name: 'WarrantyJobOrders',
                    component: () => import('./views/LeadModules/Orders.vue'),
                    meta: {
                        related_to: 'WarrantyJobOrders',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_ORDERS,
                            PERMISSIONS.VIEW_MY_ORDERS,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'order-create/:order_id?',
                    name: 'WarrantyJobOrderCreate',
                    component: () => import('./views/LeadModules/OrderCreate.vue'),
                    meta: {
                        related_to: 'WarrantyJobOrders',
                        permissions: [
                            PERMISSIONS.EDIT_LEAD,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'events',
                    name: 'WarrantyJobEvents',
                    component: () => import('./views/LeadModules/Events.vue'),
                    meta: {
                        related_to: 'WarrantyJobEvents',
                        title: 'Events',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS,
                            PERMISSIONS.VIEW_MY_CALENDAR,
                            PERMISSIONS.CREATE_EVENT_FOR_OTHERS
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'job-settings',
                    name: 'WarrantyJobSettings',
                    component: () => import('./views/LeadModules/Settings.vue'),
                    meta: {
                        related_to: 'WarrantyJobSettings',
                        title: 'Settings',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_LEADS,
                            PERMISSIONS.VIEW_MY_LEADS,
                        ],
                        middleware: somePermission
                    }
                },
            ]
        },
        {
            path: '/search',
            name: 'Search',
            component: () => import('./views/Search.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_ALL_LEADS,
                    PERMISSIONS.VIEW_MY_LEADS,
                ],
                middleware: somePermission
            }
        },
        {
            path: '/user-list',
            name: 'UsersList',
            component: () => import('./views/UsersList.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_USER_LIST
                ],
                middleware: hasPermissions
            }
        },
        {
            path: '/permission-settings',
            name: 'PermissionSettings',
            component: () => import('./views/PermissionSettings.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_PERMISSION_SETTINGS
                ],
                middleware: hasPermissions
            }
        },
        {
            path: '/audit-list',
            name: 'AuditList',
            component: () => import('./views/AuditList.vue'),
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/call-log',
            name: 'CallLog',
            component: () => import('./views/Admin/CallLog.vue'),
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/warranty-cases',
            name: 'WarrantyCases',
            component: () => import('./views/Admin/WarrantyCases.vue'),
            beforeEnter: ifAuthenticated
        },
        {
            path: '/old-warranty-create',
            name: 'OldWarrantyCreate',
            props: true,
            component: () => import('./views/Warranty/WarrantyCreate.vue'),
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/old-warranty/:warranty_id',
            name: 'OldWarrantyOverview',
            props: true,
            component: () => import('./views/Warranty/WarrantyOverview.vue'),
            meta: {
                related_to: 'OldWarrantyOverview',
            }
        },
        {
            path: '/company-library-manager',
            name: 'CompanyLibrary',
            component: () => import('./views/CompanyLibraryManager/CompanyLibrary.vue'),
            redirect: {name: 'Materials'},
            beforeEnter: ifAuthenticated,
            children: [
                {
                    path: 'materials',
                    name: 'Materials',
                    component: () => import('./views/CompanyLibraryManager/Materials.vue'),
                },
                {
                    path: 'materials-catalog',
                    name: 'MaterialsCatalog',
                    component: () => import('./views/CompanyLibraryManager/MaterialsCatalog.vue'),
                },
                {
                    path: 'labors',
                    name: 'Labors',
                    component: () => import('./views/CompanyLibraryManager/Labors.vue'),
                }
            ]
        },
        {
            path: '/settings',
            name: 'Settings',
            component: () => import('./views/Settings/Settings.vue'),
            beforeEnter: ifAuthenticated,
            redirect: {name: 'CompanySetting'},
            children: [
                {
                    path: 'company-setting',
                    name: 'CompanySetting',
                    component: () => import('./views/Settings/CompanySetting.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'integration-eagleview',
                    name: 'IntegrationEagleView',
                    component: () => import('./views/Settings/IntegrationEagleView.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'integration-esignature',
                    name: 'IntegrationEsignature',
                    component: () => import('./views/Settings/IntegrationEsignature.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'integration-ecalltrackingmetrics',
                    name: 'IntegrationEcallTrackingMetrics',
                    component: () => import('./views/Settings/IntegrationEcallTrackingMetrics'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'trades',
                    name: 'Trades',
                    component: () => import('./views/Settings/Trades.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'milestones',
                    name: 'Milestones',
                    component: () => import('./views/Settings/Milestones.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'tax',
                    name: 'Tax',
                    component: () => import('./views/Settings/Tax.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'advertising-cost',
                    name: 'AdvertisingCost',
                    component: () => import('./views/Settings/AdvertisingCost.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'company-documents',
                    name: 'CompanyDocuments',
                    component: () => import('./views/Settings/CompanyDocuments.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.VIEW_AND_EDIT_DEFAULT_DOCUMENTS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'crew',
                    name: 'CrewList',
                    component: () => import('./views/Settings/CrewList.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'crew-settings/:crew_id',
                    name: 'CrewSettings',
                    component: () => import('./views/Settings/CrewSettings.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'vendors',
                    name: 'VendorList',
                    component: () => import('./views/Settings/VendorList.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'vendor-details/:vendor_id',
                    name: 'VendorDetails',
                    component: () => import('./views/Settings/VendorDetails.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'manufacturers',
                    name: 'Manufacturers',
                    component: () => import('./views/Settings/ManufacturersList.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'manufacturers/:manufacturerId',
                    name: 'CreateOrEditManufacturer',
                    component: () => import('./views/Settings/CreateOrEditManufacturer.vue'),
                    props: true,
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'tags',
                    name: 'Tags',
                    component: () => import('./views/Settings/Tags.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'technicians',
                    name: 'Technicians',
                    component: () => import('./views/Settings/Technicians.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'technician/:technician_id',
                    name: 'Technician',
                    component: () => import('./views/Settings/Technician.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'rules',
                    name: 'WorkflowRules',
                    component: () => import('./views/Settings/WorkflowRules.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'email-templates',
                    name: 'EmailTemplates',
                    component: () => import('./views/Settings/EmailTemplates.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'rule-settings/:id',
                    name: 'WorkflowRuleSettings',
                    component: () => import('./views/Settings/WorkflowRuleSettings.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_COMPANY_SETTINGS,
                            PERMISSIONS.EDIT_COMPANY_SETTINGS,
                        ],
                        middleware: hasPermissions
                    }
                }
            ]
        },
        {
            path: '/smart-document-builder/:id',
            name: 'SmartDocBuilder',
            component: () => import('./views/SmartDocBuilder.vue'),
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_COMPANY_SETTINGS,
                    PERMISSIONS.EDIT_COMPANY_SETTINGS,
                ],
                middleware: hasPermissions
            }
        },
        {
            path: '/custom-fields',
            name: 'CustomFields',
            redirect: {name: 'CustomFieldsResidential'},
            component: () => import('./views/CustomFields'),
            children: [
                {
                    path: 'residential',
                    name: 'CustomFieldsResidential',
                    component: () => import('./components/CustomFields/SectionsList.vue'),
                    meta: {
                        breadcrumbs: [
                            {
                                link: 'Settings',
                                name: 'Settings'
                            },
                            {
                                link: 'CustomFields',
                                name: 'Create Custom Fields'
                            }
                        ],
                        permissions: [
                            PERMISSIONS.VIEW_CUSTOM_FIELDS,
                        ],
                        middleware: hasPermissions
                    },
                },
                {
                    path: 'commercial-job',
                    name: 'CustomFieldsCommercialJobItems',
                    redirect: {name: 'CustomFieldsCommercialJobCustomer'},
                    component: () => import('./components/CustomFields/SectionsList.vue'),
                    children: [
                        {
                            path: 'customer',
                            name: 'CustomFieldsCommercialJobCustomer',
                            component: () => import('./components/CustomFields/SectionsList.vue'),
                            meta: {
                                permissions: [
                                    PERMISSIONS.VIEW_CUSTOM_FIELDS,
                                ],
                                middleware: hasPermissions
                            }
                        },
                        {
                            path: 'property',
                            name: 'CustomFieldsCommercialJobProperty',
                            component: () => import('./components/CustomFields/SectionsList.vue'),
                            meta: {
                                permissions: [
                                    PERMISSIONS.VIEW_CUSTOM_FIELDS,
                                ],
                                middleware: hasPermissions
                            }
                        },
                        {
                            path: 'unit',
                            name: 'CustomFieldsCommercialJobUnit',
                            component: () => import('./components/CustomFields/SectionsList.vue'),
                            meta: {
                                permissions: [
                                    PERMISSIONS.VIEW_CUSTOM_FIELDS,
                                ],
                                middleware: hasPermissions
                            }
                        },
                        {
                            path: 'job',
                            name: 'CustomFieldsCommercialJob',
                            component: () => import('./components/CustomFields/SectionsList.vue'),
                            meta: {
                                permissions: [
                                    PERMISSIONS.VIEW_CUSTOM_FIELDS,
                                ],
                                middleware: hasPermissions
                            }
                        }
                    ],
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_CUSTOM_FIELDS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'commercial-lead',
                    name: 'CustomFieldsCommercialLead',
                    component: () => import('./components/CustomFields/SectionsList.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_CUSTOM_FIELDS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'form-builder',
                    name: 'CustomFieldsFormBuilder',
                    component: () => import('./components/CustomFields/SectionsList.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_CUSTOM_FIELDS,
                        ],
                        middleware: hasPermissions
                    }
                },
                {
                    path: 'warranty',
                    name: 'CustomFieldsWarranty',
                    component: () => import('./components/CustomFields/SectionsList.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_CUSTOM_FIELDS,
                        ],
                        middleware: hasPermissions
                    }
                }
            ],
            beforeEnter: ifAuthenticated
        },
        {
            path: '/profile',
            name: 'Profile',
            component: () => import('./views/Profile.vue'),
            beforeEnter: ifAuthenticated
        },
        {
            path: '/calendar',
            name: 'Calendar',
            component: () => import('./views/Calendar/Calendar.vue'),
            beforeEnter: ifAuthenticated,
            redirect: {name: 'CalendarWeek'},
            meta: {disableScroll: true},
            children: [
                {
                    path: 'month/:date?',
                    name: 'CalendarMonth',
                    component: () => import('./views/Calendar/CalendarMonth.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS,
                            PERMISSIONS.VIEW_MY_CALENDAR
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'week/:date?',
                    name: 'CalendarWeek',
                    component: () => import('./views/Calendar/CalendarWeek.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS,
                            PERMISSIONS.VIEW_MY_CALENDAR,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'day/:date?',
                    name: 'CalendarDay',
                    component: () => import('./views/Calendar/CalendarDay.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS,
                            PERMISSIONS.VIEW_MY_CALENDAR,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'agenda/:date?',
                    name: 'CalendarAgenda',
                    component: () => import('./views/Calendar/CalendarAgenda.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS,
                            PERMISSIONS.VIEW_MY_CALENDAR,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'user-calendar/week/:date?',
                    name: 'UserCalendar',
                    component: () => import('./views/Calendar/UserCalendar.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS,
                            PERMISSIONS.VIEW_MY_CALENDAR,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'user-calendar/day/:date?',
                    name: 'UserCalendarDayView',
                    component: () => import('./views/Calendar/UserCalendarDayView.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS,
                            PERMISSIONS.VIEW_MY_CALENDAR,
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'tecnhician-calendar/week/:date?',
                    name: 'TechnicianCalendar',
                    component: () => import('./views/Calendar/TechnicianCalendar.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'tecnhician-calendar/day/:date?',
                    name: 'TechnicianCalendarDayView',
                    component: () => import('./views/Calendar/TechnicianCalendarDayView.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'crew-scheduler/week/:date?',
                    name: 'CrewScheduler',
                    component: () => import('./views/Calendar/CrewScheduler.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS
                        ],
                        middleware: somePermission
                    }
                },
                {
                    path: 'job-scheduler/week/:date?',
                    name: 'JobScheduler',
                    component: () => import('./views/Calendar/JobScheduler.vue'),
                    meta: {
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS
                        ],
                        middleware: somePermission
                    }
                }
            ]
        },
        {
            path: '/template-manager',
            name: 'TemplateManager',
            component: () => import('./views/Templates/TemplateManager.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_ALL_TEMPLATE,
                    PERMISSIONS.VIEW_MY_TEMPLATE,
                ],
                middleware: somePermission
            }
        },
        {
            path: '/template/:template_id',
            name: 'TemplateEdit',
            component: () => import('./views/Templates/TemplateEdit.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_ALL_TEMPLATE,
                    PERMISSIONS.VIEW_MY_TEMPLATE
                ],
                middleware: somePermission
            }
        },
        {
            path: '/project-management',
            name: 'ProjectManagement',
            component: () => import('./views/ProjectManagement.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.VIEW_PROJECT_MANAGEMENT_BOARD,
                ],
                middleware: hasPermissions
            }
        },
        {
            path: '/reports',
            name: 'Reports',
            component: () => import('./views/Reports/Reports.vue'),
            redirect: {name: 'CustomReports'},
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.SEE_ALL_REPORTS,
                    PERMISSIONS.SEE_MY_REPORTS
                ],
                middleware: somePermission
            },
            children: [
                {
                    path: 'custom',
                    name: 'CustomReports',
                    component: () => import('./views/Reports/CustomReports.vue'),
                },
                {
                    path: 'dashboard',
                    name: 'DashboardReports',
                    component: () => import('./views/Reports/DashboardReports.vue'),
                }
            ]
        },
        {
            path: '/report-overview',
            redirect: {name: 'Reports'}
        },
        {
            path: '/report-overview/:report_id?',
            name: 'ReportOverview',
            component: () => import('./views/Reports/ReportOverview.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.SEE_ALL_REPORTS,
                    PERMISSIONS.SEE_MY_REPORTS
                ],
                middleware: somePermission
            }
        },
        {
            path: '/create-new-report',
            name: 'CreateNewReport',
            component: () => import('./views/Reports/CreateNewReport.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.CREATE_EDIT_REPORTS
                ],
                middleware: hasPermissions
            }
        },
        {
            path: '/import-leads-jobs',
            name: 'ImportLeadsJobs',
            component: () => import('./views/ImportLeadsJobs.vue'),
            beforeEnter: ifAuthenticated,
            meta: {
                permissions: [
                    PERMISSIONS.CREATE_LEAD
                ],
                middleware: hasPermissions
            }
        },
        {
            path: '/versions',
            name: 'Versions',
            component: () => import('./views/Versions.vue'),
            beforeEnter: ifAuthenticated
        },
        {
            path: '/tutorials',
            name: 'Tutorials',
            component: () => import('./views/Tutorials.vue'),
            beforeEnter: ifAuthenticated
        },
        // service page
        {
            path: '/style-guide',
            name: 'StyleGuide',
            component: () => import('./views/StyleGuide.vue'),
            meta: {
                breadcrumbs: [
                    {
                        link: 'Dashboard',
                        name: 'Dashboard'
                    },
                    {
                        link: 'StyleGuide',
                        name: 'Style Guide'
                    }
                ]
            },
            beforeEnter: ifAuthenticated
        },
        
        // not found page
        {
            path: '*',
            name: 'NotFound',
            component: () => import('./views/404.vue'),
            beforeEnter: ifAuthenticated
        },
        // Access Denied Page
        {
            path: '/access-denied',
            name: 'AccessDeniedPage',
            component: () => import('./views/AccessDeniedPage.vue'),
            beforeEnter: ifAuthenticated
        },
        // Under Construction Page
        {
            path: '/under-construction',
            name: 'UnderConstructionPage',
            component: () => import('./views/UnderConstructionPage.vue'),
            beforeEnter: ifAuthenticated
        }
    ],
    
    // set custom query resolver
    parseQuery(query) {
        return qs.parse(query);
    },
    
    stringifyQuery(query) {
        var result = qs.stringify(query);
        return result ? ('?' + result) : '';
    }
});
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;
    
    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Than run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

router.beforeEach((to, from, next) => {
    const getUserIfNotReceived = (callback) => {
        const user = store.getters['user/getUserData'];
        const isAuth = store.getters['auth/isAuth'];
        
        if (!Object.keys(user).length
            && isAuth 
            && to.name !== 'UnderConstructionPage'
        ) {
            store.dispatch('user/GET_USER_DATA').then(callback);
            return;
        }
        
        callback();
    }
    
    if (to.meta.middleware) {
        // check admin role, success redirect
        // if (store.getters['user/getUserRoles'].some(item => {return item.name == ROLES.ADMIN})) {
        //     return next();
        // }
        
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];
        
        const redirect = to.meta.redirect || '/access-denied';
        
        const context = {from, next, router, to};
        
        const nextMiddleware = nextFactory(context, middleware, 1);
        
        getUserIfNotReceived(() => middleware[0]({...context, next: nextMiddleware}, redirect));
        
        return;
    }
    
    getUserIfNotReceived(next);
});

export default router
