import { 
    GET_LEAD_PREVIEW, 
    GET_LEAD_PRIMARY_INFORMATION,
    GET_KANBAN_LINK,
    SET_RESIDENTIAL_ASSIGNMENT,
    CLEAR_LEAD_PREVIEW,
    UPDATE_QUALITY_CONTROL,
    SET_CONVERTING_LEAD
} from '../actions/lead';
import api from '@/headers.js';

export default {
    namespaced: true,
    state: {
        leadPreview: {},
        uploaded: false,
        leadPrimaryInformation: {},
        kanbanLink: null,
        convertingLead: false
    },
    getters: {
        getConvertingLeadStatus: state => state.convertingLead,
        getLeadPreview: state => state.leadPreview,
        getLeadAddress: state => { 
            return {
                address: state.leadPreview.address,
                city: state.leadPreview.city,
                state: state.leadPreview.state,
                street: state.leadPreview.street,
                zip: state.leadPreview.zip,
                lat: state.leadPreview.lat,
                lng: state.leadPreview.lng
            }
        },
        getClippedLeadAddress: state => { 
            return {
                address: state.leadPreview.address,
                city: state.leadPreview.city,
                state: state.leadPreview.state,
                street: state.leadPreview.street,
                zip: state.leadPreview.zip
            }
        },
        getLeadPreviewStatus: state => state.uploaded,
        getLeadPrimaryInformation: state => state.leadPrimaryInformation,
        getJobNumber: state => state.leadPreview.job_number,
        getKanbanLink: state => state.kanbanLink
    },
    mutations: {
        [SET_CONVERTING_LEAD] (state, payload) {
            state.convertingLead = payload;
        },
        [GET_LEAD_PREVIEW] (state, payload) {
            state.leadPreview = payload;
            state.uploaded = true;
        },
        [UPDATE_QUALITY_CONTROL] (state, payload) {
            state.leadPrimaryInformation.quality_controls = payload;
        },
        [GET_LEAD_PRIMARY_INFORMATION] (state, payload) {
            state.leadPrimaryInformation = payload;
        },
        [GET_KANBAN_LINK] (state, payload) {
            state.kanbanLink = payload ? payload.slug : null;
        },
        [SET_RESIDENTIAL_ASSIGNMENT] (state, payload) {
            if (payload.id) {
                state.leadPreview.salesman = payload;
                state.leadPreview.salesman_id = payload.id;
            } else {
                // unassign
                state.leadPreview.salesman = null;
                state.leadPreview.salesman_id = null;
            }
        },
        [CLEAR_LEAD_PREVIEW] (state) {
            state.leadPreview = {};
            state.uploaded = false;
        }
    },
    actions: {
        [GET_LEAD_PREVIEW]({ commit, dispatch }, id) {
            commit(CLEAR_LEAD_PREVIEW);
            return new Promise((resolve, reject) => {
                api.get(`/leads/${id}?preview=Y`)
                    .then(response => {
                        commit(GET_LEAD_PREVIEW, response.data.data);
                        resolve(response.data.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        [GET_LEAD_PRIMARY_INFORMATION]({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                api.get(`/leads/${id}`)
                    .then(response => {
                            commit(GET_LEAD_PRIMARY_INFORMATION, response.data.data);
                            resolve(response.data.data);
                        })
                        .catch(error => {
                            reject(error);
                        });
            })
        },
        [GET_KANBAN_LINK]({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                api.get(`/kanban/link?entityId=${id}`)
                    .then(response => {
                        commit(GET_KANBAN_LINK, response.data.data);
                        resolve(response.data.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        }
    }
}