<template>
    <div 
        :style="{ width: width + 'px', height: height + 'px' }" 
        class="upload-screen-saver none-events"
    >
        <img src="@/assets/images/documents.svg" 
            alt="documents" 
            class="layer-base"
        />
        <img 
            src="@/assets/images/documents-animation/layer-1.svg" 
            alt="document" 
            class="layer-1"
        />
        <img 
            src="@/assets/images/documents-animation/layer-2.svg" 
            alt="document" 
            class="layer-2"
        />
        <img 
            src="@/assets/images/documents-animation/layer-3.svg" 
            alt="document" 
            class="layer-3"
        />
        <pointer-icon
            transform="270"
            size="18"
            class="i-blue "
        />
    </div>
</template>

<script>
    import { PointerIcon } from '@/icons';

    export default {
        name: 'UploadScreenSaver',
        props: {
            width: {
                type: [Number, String],
                default: 0
            },
            height: {
                type: [Number, String],
                default: 0
            }
        },
        components: {
            PointerIcon
        }
    }
</script>
