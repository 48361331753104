<template>
    <full-modal @close="$emit('close')">
        <div slot="title">{{ editingField.type.label }} field</div>
        <div slot="body">
            <p class="f-14-darkgrey f-sbold mb-3">Add description</p>
            <form-input-textarea 
                class="w-100 mb-25"
                v-input-counter="50"
            >
                <textarea
                    :class="[
                        'form-input-field',
                        { 'form-input-textarea-danger' : errors.has('field_description') }
                    ]"
                    name="field_description"
                    v-model="formData.label"
                    rows="1"
                    placeholder="Description"
                    maxlength="51"
                    v-resize-textarea
                    v-validate="'required|max:50'"
                />
            </form-input-textarea>
            <checkbox-button 
                class="w-100 mb-8"
                label="Required"
                color="dark-blue"
            >
                <input 
                    name="required"
                    v-model="parameters.required"
                    type="checkbox"
                />
            </checkbox-button>
            <checkbox-button 
                class="w-100"
                label="Show tooltip"
                color="dark-blue"
            >
                <input 
                    name="show_tooltip"
                    v-model="parameters.show_tooltip"
                    type="checkbox"
                />
            </checkbox-button>
            <div 
                v-if="parameters.show_tooltip"
                class="row-start mt-6"
            >
                <form-input 
                    label="Enter help text here"
                    :class="[{'form-input-danger': errors.first('first_address_tooltip_text')}]"
                >
                    <input 
                        class="form-input-field"
                        name="first_address_tooltip_text"
                        type="text"
                        placeholder="Enter help text here"
                        v-model="parameters.tooltip_text"
                        v-input-style="parameters.tooltip_text"
                        maxlength="100"
                        v-validate="'required|min:3|max:99'"
                    >
                </form-input>
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button mr-4"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                @click="updateSection"
                :disabled="requestIsGoing"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import api from '@/headers.js';
    import { FormInput, CheckboxButton, FormInputTextarea } from '@/ui';
    import FullModal from '@/ui/Modal/FullModal.vue';

    export default {
        name: 'CustomFieldsMultilineModal',
        components: {
            FormInput, CheckboxButton, FullModal, FormInputTextarea
        },
        props: {
            editingField: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                formData: {
                    label: '',
                    id: this.editingField.id,
                },
                parameters: {
                    required: false,
                    show_tooltip: false,
                    tooltip_text: ''
                },
                requestIsGoing: false
            }
        },
        created() {
            if (this.editingField.parameters) {
                this.formData.label = this.editingField.label;
                this.formData.id = this.editingField.id,
                this.formData.section_id = this.editingField.section_id;
                this.formData.type_id = this.editingField.type.id;
                this.parameters = JSON.parse(this.editingField.parameters);
            }
        },
        methods: {
            updateSection() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('Please fill out all required fields!');

                        return;
                    }

                    const params = {
                        ...this.formData,
                        parameters: JSON.stringify(this.parameters)
                    };

                    this.requestIsGoing = true;
                    api.put('/layouts/custom-fields', params)
                        .then(response => response.data.data)
                        .then(data => {
                            this.notifySuccess('Custom field has been updated.');

                            this.$emit('updateSection', [data]);
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {                                
                            this.requestIsGoing = false;
                        });
                    });
            }
        }
    }
</script>

