<template>
    <full-modal 
        @close="$emit('close')"
        :withSearch="false"
        class="configure-id-numbering-modal"
    >
        <div slot="title">Configure ID numbering</div>
        <div slot="body">
            <div v-if="loading">
                <loader size="normal"/>
            </div>
            <div 
                class="column-start w-100" 
                v-else
            >
                <div 
                    v-for="(type, index) in withoutDoubleLeadType"
                    :key="`type-${index}`"
                    class=" w-100 mb-8"
                >
                    <div class="row-start row-baseline">
                        <div class="mb-2 mr-6">
                            <span class="f-12-black f-sbold">{{ type.name }} #</span>
                        </div>
                        <span
                            v-if="defineType(type.name)"
                            class="s-align-5 mb-2 ml-2 bg-asphalt br-5 ph-1 pv-1 f-9-white f-bold uppercase"
                        >
                            {{ defineType(type.name) }}
                        </span>
                        <span
                            v-if="defineResidentialType(type.name)"
                            class="s-align-5 mb-2 ml-2 bg-blue br-5 ph-1 pv-1 f-9-white f-bold uppercase"
                        >
                            {{ defineResidentialType(type.name) }}
                        </span>
                    </div>
                    <div class="row-start pt-2">
                        <div class="column-start w-100">
                            <input
                                type="text" 
                                :name="`starting-num-${index}`"
                                :class="['input', { 'input-danger' : errors.has(`starting-num-${index}`) }]"
                                placeholder="ID"
                                v-model.number="type.starting_num"
                                @input="setJobNumber(type)"
                                v-validate="getValidationRule(type.name)"
                                :key="index"
                                v-limiter
                            />
                        </div>
                    </div>
                </div>
                <div class="row-start row-baseline mt-20">
                    <div class="column-start lg-6 pr-5">
                        <p class="f-12-black f-sbold mb-6">Next Commercial Job Prefix</p>
                        <input
                            type="text" 
                            name="commercial_prefix"
                            :class="['input', { 'input-danger' : errors.has(`commercial_prefix`) }]"
                            placeholder="Prefix"
                            v-model="editedJobNumbers.commercial_prefix"
                            v-validate="'required'"
                        />
                    </div>
                    <span class="column-start lg-6">
                        <p class="f-12-black f-sbold mb-6">Next Residential Job Prefix</p>
                        <input
                            type="text" 
                            name="residential_prefix"
                            :class="['input', { 'input-danger' : errors.has(`residential_prefix`) }]"
                            placeholder="Prefix"
                            v-model="editedJobNumbers.residential_prefix"
                            v-validate="'required'"
                        />
                    </span>
                </div>
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                @click="save"
                ref="save"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, RadioButton, Loader, FormInput } from '@/ui';
    import { SearchIcon, ArrowIcon } from '@/icons';
    import api from '@/headers.js';

    const RESIDENTIAL = 'residential';
    const COMMERCIAL = 'commercial';

    export default {
        name: 'ConfigureIdNumberingModal',
        components: {
            FullModal,
            RadioButton,
            SearchIcon,
            ArrowIcon,
            Loader,
            FormInput
        },
        data() {
            return {
                leadTypes: [],
                loading: false,
                editedJobNumbers: null
            }
        },
        props: {
            jobNumbers: {
                type: Object,
                required: true
            }
        },
        computed: {
            withoutDoubleLeadType() {
                let typeNames = [];
                let typeResult = [];

                typeNames = this.leadTypes.map(el => {
                    return el.name;
                });

                typeNames = [...new Set(typeNames)];

                typeResult = typeNames.map(typeName => {
                    let leadType = this.leadTypes.find(lead => lead.name === typeName);
                    return {
                        name: typeName,
                        starting_num: leadType.starting_num
                    }
                });

                return typeResult;
            }
        },
        methods: {
            getLeadTypes() {
                this.loading = true;

                const params = {
                    needed: ['lead_types']
                };

                api.get('/handbooks?filter[is_active]=1&entityType=mix', { params })
                    .then(response => {
                        this.leadTypes = response.data.data.lead_types;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            getValidationRule(type) {
                switch (type) {
                    case 'Full Replacement':
                        return 'required|min_value:1000|max_value:9999';
                    case 'Repairs':
                        return 'required|min_value:10000|max_value:99999';
                    case 'Service': 
                        return 'required|min_value:100000|max_value:999999';
                }
            },
            defineType(type) {
                let leadType = this.leadTypes.find(el => {
                    return el.name == type && el.entity_type == COMMERCIAL;
                });

                return leadType && leadType.entity_type == COMMERCIAL
                    ? COMMERCIAL
                    : '';
            },
            defineResidentialType(type) {
                let leadType = this.leadTypes.find(el => {
                    return el.name == type && el.entity_type == RESIDENTIAL;
                });

                return leadType && leadType.entity_type == RESIDENTIAL
                    ? RESIDENTIAL
                    : '';
            },
            setJobNumber(type) {
                switch (type.name) {
                    case 'Full Replacement': 
                        this.editedJobNumbers.full_replacement = type.starting_num;
                        break;
                    case 'Repairs': 
                        this.editedJobNumbers.repairs = type.starting_num;
                        break;
                    case 'Service': 
                        this.editedJobNumbers.service = type.starting_num;
                        break;
                    case 'Inspection': 
                        this.editedJobNumbers.inspection = type.starting_num;
                        break;
                }
            },
            save() {
                const params = {
                    full_replacement: this.editedJobNumbers.full_replacement,
                    repairs: this.editedJobNumbers.repairs,
                    service: this.editedJobNumbers.service,
                    residential_prefix: this.editedJobNumbers.residential_prefix,
                    commercial_prefix: this.editedJobNumbers.commercial_prefix,
                    inspection: this.editedJobNumbers.inspection
                };

                this.$validator.validateAll().then(result => {
                    if (result) {
                        api.put('/job-number', params)
                            .then((response) => {
                                this.notifySuccess('Configure ID numbering is updated!');
                                this.$emit('close', response.data.data);
                            })
                            .catch(error => {
                                this.notifyRequestErrors(error);
                            });
                    } else {
                        if (this.$validator.errors.items.length) {
                            this.$validator.errors.items.forEach((el, index) => {
                                let message = el.msg;
                                let field = el.field;
                                let replaceText = '';

                                switch (field) {
                                    case 'starting-num-0':
                                        replaceText = 'Full Replacement';
                                        break;
                                    case 'starting-num-1':
                                        replaceText = 'Repairs';
                                        break;
                                    case 'starting-num-2':
                                        replaceText = 'Service';
                                        break;
                                    case 'commercial_prefix':
                                        replaceText = 'Commercial Prefix';
                                        break;
                                    case 'residential_prefix':
                                        replaceText = 'Residential Prefix';
                                        break;
                                }

                                message = message.replace(field, replaceText); 
                                this.notifyError(`${message}.`);
                            })
                        }
                    };
                });
            }
        },
        created() {
            this.editedJobNumbers = {...this.jobNumbers};
            this.getLeadTypes();
        }
    }
</script>