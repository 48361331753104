<template>
    <div class="row-start">
        <div 
            :class="[
                'column-start ph-2 mb-md-1',
                firstAddressClasses
            ]"
        >
            <div class="row-between row-baseline pb-4">
                <tooltip
                    v-if="firstAddressParameters.show_tooltip"
                    class="w-100"
                    direction="bottom"
                >
                    <p class="f-13-asphalt">
                        {{ value[0].label }}
                    </p>
                    <template v-slot:content>
                        <p class="f-13-white">
                            {{ firstAddressParameters.tooltip_text }}
                        </p>
                    </template>
                </tooltip>
                <p 
                    v-else
                    class="f-13-asphalt"
                >
                    {{ value[0].label }}
                </p>
            </div>
            <address-box 
                :type="`custom-${value[0].id}`"
                :apartment="firstAddressParameters.add_unit_number"
                :existing="firstAddressFormData"
                :validate="firstAddressParameters.required ? 'required' : ''"
                :requiredMark="firstAddressParameters.required"
                @address="data => addressChange('firstAddress', data)"
            >
                <div 
                    v-if="firstAddressParameters.add_unit_number"
                    class="w-100 mb-4" 
                    slot="apartment"
                >
                    <form-input 
                        label="Unit"
                        :class="[{ 'form-input-danger' : errors.has(`custom_apartment_${value[0].id}`) }]"
                    >
                        <input 
                            type="text" 
                            :id="`custom_apartment_${value[0].id}`"
                            :name="`custom_apartment_${value[0].id}`" 
                            class="form-input-field"
                            placeholder="Unit"
                            v-input-style="firstAddressFormData.apartment"
                            :value="firstAddressFormData.apartment"
                            @input="e => addressChange('firstAddressApartment', e.target.value)"
                            v-validate="'max:50'"
                        />
                    </form-input>
                </div>
            </address-box>
        </div>
        <div 
            v-if="firstAddressParameters.add_other_address"
            :class="[
                'column-start ph-2 mb-md-1',
                fullWidth ? 'lg-12 mt-8' : 'lg-6 md-12 sm-12'  
            ]"
        >
            <div class="row-between row-baseline pb-4 mt-sm-2">
                <tooltip
                    v-if="secondAddressParameters.show_tooltip"
                    class="w-100"
                    direction="bottom"
                >
                    <p class="f-13-asphalt">
                        {{ value[1].label }}
                    </p>
                    <template v-slot:content>
                        <p class="f-13-white">
                            {{ secondAddressParameters.tooltip_text }}
                        </p>
                    </template>
                </tooltip>
                <p 
                    v-else
                    class="f-13-asphalt"
                >
                    {{ value[1].label }}
                </p>
            </div>
            <address-box 
                :type="`custom-${value[1].id}`"
                :apartment="secondAddressParameters.add_unit_number"
                :existing="secondAddressFormData"
                :validate="secondAddressParameters.required ? 'required' : ''"
                :requiredMark="secondAddressParameters.required"
                @address="data => addressChange('secondAddress', data)"
            >
                <div 
                    v-if="secondAddressParameters.add_unit_number"
                    class="w-100 mb-4" 
                    slot="apartment"
                >
                    <form-input 
                        label="Unit"
                        :class="[{ 'form-input-danger' : errors.has(`custom_apartment_${value[1].id}`) }]"
                    >
                        <input 
                            type="text" 
                            :id="`custom_apartment_${value[1].id}`"
                            :name="`custom_apartment_${value[1].id}`" 
                            class="form-input-field"
                            placeholder="Unit"
                            v-input-style="secondAddressFormData.apartment"
                            :value="secondAddressFormData.apartment"
                            @input="e => addressChange('secondAddressApartment', e.target.value)"
                            v-validate="'max:50'"
                        />
                    </form-input>
                </div>
            </address-box>
        </div>
    </div>
</template>

<script>
    import AddressBox from '@/components/AddressBox.vue';
    import { FormInput, Tooltip } from '@/ui';
    import cloneDeep from 'lodash/cloneDeep';
    import isString from 'lodash/isString';
    import isEmpty from 'lodash/isEmpty';

    export default {
        name: 'CustomFieldAddressSection',
        components: {
            AddressBox,
            FormInput,
            Tooltip
        },
        props: {
            value: {
                type: Array,
                required: true
            },
            fullWidth: {
                type: Boolean,
                default: false
            },
            singleAddressFullWidth: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                firstAddressParameters: null,
                secondAddressParameters: null
            }
        },
        inject: ['$validator'],
        computed: {
            firstAddressFormData: function() {
                if (this.value[0].values && isString(this.value[0].values[0].object_value)) {
                    return JSON.parse(this.value[0].values[0].object_value);
                } else if (this.value[0].values && !isEmpty(this.value[0].values[0].object_value)) {
                    return this.value[0].values[0].object_value;
                }

                return {
                    lat: null,
                    lng: null,
                    zip: '',
                    city: '',
                    state: '',
                    street: '',
                    address: '',
                    apartment: ''
                }
            },
            secondAddressFormData: function() {
                if (this.value[1].values && isString(this.value[1].values[0].object_value)) {
                    return JSON.parse(this.value[1].values[0].object_value);
                } else if (this.value[1].values && !isEmpty(this.value[1].values[0].object_value)) {
                    return this.value[1].values[0].object_value;
                }

                return {
                    lat: null,
                    lng: null,
                    zip: '',
                    city: '',
                    state: '',
                    street: '',
                    address: '',
                    apartment: ''
                }
            },
            firstAddressClasses: function() {
                if (this.singleAddressFullWidth 
                    && !this.firstAddressParameters.add_other_address
                    || this.fullWidth
                ) {
                    return 'lg-12';
                }

                return 'lg-6 md-12 sm-12';
            }
        },
        methods: {
            addressChange(type, data) {
                let value = cloneDeep(this.value);

                switch (type) {
                    case 'firstAddress':
                        value[0].values[0].object_value = JSON.stringify({
                            ...data,
                            apartment: this.firstAddressFormData.apartment
                        });
                        break;
                    case 'secondAddress':
                        value[1].values[0].object_value = JSON.stringify({
                            ...data,
                            apartment: this.secondAddressFormData.apartment
                        });
                        break;
                    case 'firstAddressApartment':
                        value[0].values[0].object_value = JSON.stringify({
                            ...this.firstAddressFormData,
                            apartment: data
                        });
                        break;
                    case 'secondAddressApartment':
                        value[1].values[0].object_value = JSON.stringify({
                            ...this.secondAddressFormData,
                            apartment: data
                        });
                        break;
                }

                this.$emit('input', value);
            },
            initValues() {
                if (isString(this.value[0].parameters)) {
                    this.firstAddressParameters = JSON.parse(this.value[0].parameters);
                    this.secondAddressParameters = JSON.parse(this.value[1].parameters);
                    this.$watch('value', this.initValues);
                } else {
                    this.firstAddressParameters = {...this.value[0].parameters};
                    this.secondAddressParameters = {...this.value[1].parameters};  
                }
            }
        },
        created() {
            this.initValues();
        }
    }
</script>