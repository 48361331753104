<template>
    <svg
        viewBox="0 0 489.942 489.942" 
        :width="size" 
        :height="size"
        class="sign-icon"
    >
        <g>
            <path d="M461.706,1.777l-376.6,137.3c-6.2,2.1-10.4,7.3-12.5,12.5l-71.8,195.5c-2.1,8.3,0,16.6,5.2,21.8l114.4,114.4
                c8.7,8.8,18.7,6.2,20.8,5.2l195.6-71.8c6.2-2.1,10.4-7.3,12.5-12.5l139.4-375.4C493.706,18.277,483.306-5.823,461.706,1.777z
                M315.006,382.477l-174.8,63.5l-96.8-96.8l64.5-174.8l281.9-103.2l-152.8,152.2c-3.2-0.8-6.5-1.2-9.9-1.2
                c-21.9,0-39.6,17.7-39.6,39.6s17.7,39.6,39.6,39.6s39.6-17.7,39.6-39.6c0-3.3-0.4-6.6-1.2-9.6l152.2-150.7L315.006,382.477z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'SignIcon',
        props: {
            size: {
                type: [Number, String],
                default: 15
            }
        }
    }
</script>