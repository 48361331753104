<template>
    <div class="order-milestones">
        <div class="order-milestones-panel row-start relative">
            <div 
                :class="[
                    'order s-align-5 h-100', 
                    { 'order-without-order': !hasMaterial || !existMaterial }
                ]"
            >
                <span class="f-12-darkgrey">Order</span>
            </div>
            <div 
                :class="[
                    'material s-align-5 h-100 relative', 
                    { 'order-without-material': !hasMaterial || !existMaterial }
                ]"
            >
                <p class="column-center w-100">
                    <span class="f-12-darkgrey">Material</span>
                    <span 
                        class="f-10-blue w-100 ellipsis ph-2 t-center" 
                        v-if="vendor"
                    >
                        {{ vendor.name }}
                    </span>
                </p>
                <div 
                    class="material-message s-align-5 column-center"
                    v-if="!hasMaterial || !existMaterial"
                >
                    <p class="f-12-darkgrey">No Material</p>
                    <router-link
                        v-if="!existMaterial && hasMaterial"
                        class="f-11-blue"
                        :to="{ name: orderCreateRoute, 
                            params: { order_id: orderId },
                            query: { tab: 'material' }
                        }"
                    >
                        + Add Material
                    </router-link>
                </div>
            </div>
            <div 
                :class="[
                    'labor s-align-5 h-100 relative', 
                    { 'order-without-labor': !hasLabor || !existLabor }
                ]"
            >
                <p class="column-center w-100">
                    <span class="f-12-darkgrey">Labor</span>
                    <span 
                        class="f-10-blue w-100 ellipsis ph-2 t-center"
                        v-if="crew"
                    >
                        {{ crew.name }} 
                    </span>
                </p>
                <div 
                    class="labor-message s-align-5 column-center"
                    v-if="!hasLabor || !existLabor"
                >
                    <p class="f-12-darkgrey">No Labor</p>
                    <router-link
                        v-if="!existLabor && hasLabor"
                        class="f-11-blue"
                        :to="{ name: orderCreateRoute, 
                            params: { order_id: orderId },
                            query: { tab: 'labor' }
                        }"
                    >
                        + Add Labor
                    </router-link>
                </div>
            </div>
            <div class="closed h-100"></div>
        </div>
        <div class="order-milestones-items row-start">
            <div 
                class="order-milestones-item"
                v-for="(milestone, index) in milestones"
                :key="`milestone-${index}`"
            >
                <div :class="['milestone-box hidden s-align-8', { 'first-border': index == 0 }]">
                    <div 
                        :class="[
                            'w-100 relative',
                            { 
                                'milestone-status-done': milestone.status == DIAGRAM_STATUSES.DONE,
                                'milestone-status-await': milestone.status == DIAGRAM_STATUSES.AWAIT,
                                'milestone-status-in-progress': milestone.status == DIAGRAM_STATUSES.IN_PROGRESS 
                            }
                        ]" 
                        :style="{ height: Math.round(60 + 19 * index) + 'px' }"
                    >
                        <div class="s-align-5 relative pt-25">
                            <div class="milestone-status-icon s-align-5">
                                <mark-icon
                                    size="9"
                                    class="i-white"
                                    v-if="milestone.status == DIAGRAM_STATUSES.DONE"
                                />
                            </div>
                            <span 
                                :class="[
                                    'f-sbold capitalize', 
                                    milestone.status == DIAGRAM_STATUSES.DONE ? 'f-11-green' : 'f-11-grey'
                                ]"
                            >
                                {{ milestone.name }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="s-align-5 pt-4 f-11-grey">
                    <span :class="['ph-4 t-center', { 'f-italic opacity-5': !milestone.date }]">
                        {{ !milestone.date ? 'no info' : milestone.date | date }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ORDER_MILESTONES, ORDER_STATUSES, ENTITY_TYPES } from '@/constants.js';
    import { MarkIcon, DashIcon } from '@/icons';
    import moment from 'moment/src/moment';

    const DIAGRAM_STATUSES = {
        AWAIT: 'await',
        IN_PROGRESS: 'in progress',
        DONE: 'done'
    };

    export default {
        name: 'OrderMilestones',
        components: {
            MarkIcon,
            DashIcon
        },
        props: {
            entityType: {
                type: String,
                required: true
            },
            orderId: {
                type: Number,
                required: true
            },
            milestonesProps: {
                type: Array,
                required: true
            },
            hasMaterial: {
                type: Boolean,
                default: true
            },
            hasLabor: {
                type: Boolean,
                default: true
            },
            existMaterial: {
                type: Boolean,
                default: true
            },
            existLabor: {
                type: Boolean,
                default: true
            },
            orderStatus: {
                type: String,
                required: true
            },
            vendor: {
                type: Object,
                default: null
            },
            crew: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                DIAGRAM_STATUSES: DIAGRAM_STATUSES
            }
        },
        computed: {
            milestones() {
                return Object.entries(ORDER_MILESTONES)
                    .sort((a, b) => a[1].order - b[1].order)
                    .map(i => {
                        const stageName = i[0]; // key
                        const stageData = i[1]; // value
                        const milestone = this.milestonesProps.find(m => {
                            return m.name === stageData.name && m.group === stageData.group
                        });

                        let object = {
                            name: stageData.name,
                            status: DIAGRAM_STATUSES.AWAIT,
                            date: milestone ? milestone.pivot.date : ''
                        };

                        if (stageName === 'DRAFT') {
                            object.status = this.orderStatus === ORDER_STATUSES.DRAFT_MATERIAL_LABOR
                                ? DIAGRAM_STATUSES.IN_PROGRESS : DIAGRAM_STATUSES.DONE;
                        } else if (stageName === 'ORDERED') {
                            object.status = this.orderStatus === ORDER_STATUSES.SAVE 
                                ? DIAGRAM_STATUSES.DONE : DIAGRAM_STATUSES.AWAIT;
                        } else if (stageName === 'ORDERED_MATERIALS' && this.hasMaterial) {
                            object.status = this.orderStatus === ORDER_STATUSES.SAVE 
                                ? DIAGRAM_STATUSES.DONE : DIAGRAM_STATUSES.AWAIT;
                        } else if (stageName === 'DELIVERY_MATERIALS' && this.hasMaterial) {
                            let status = milestone.pivot.date 
                                ? moment(milestone.pivot.date).isAfter() 
                                ? DIAGRAM_STATUSES.IN_PROGRESS : DIAGRAM_STATUSES.DONE
                                : DIAGRAM_STATUSES.AWAIT;
                            object.status = status;
                        } else if (stageName === 'ASSIGNED' && this.hasLabor) {
                            object.status = this.orderStatus === ORDER_STATUSES.DRAFT_MATERIAL_LABOR 
                                ? DIAGRAM_STATUSES.AWAIT : DIAGRAM_STATUSES.DONE;
                        } else if (stageName === 'STARTED' && this.hasLabor) {
                            object.status = this.orderStatus !== ORDER_STATUSES.DRAFT_MATERIAL_LABOR 
                                ? moment(milestone.pivot.date).isAfter() 
                                ? DIAGRAM_STATUSES.IN_PROGRESS : DIAGRAM_STATUSES.DONE 
                                : DIAGRAM_STATUSES.AWAIT;
                        } else if (stageName === 'COMPLETED' && this.hasLabor) {
                            object.status = milestone.pivot.date 
                                ? DIAGRAM_STATUSES.DONE : DIAGRAM_STATUSES.AWAIT;
                        } else if (stageName === 'CLOSED') {
                            object.status = milestone.pivot.date 
                                ? DIAGRAM_STATUSES.DONE 
                                : DIAGRAM_STATUSES.AWAIT;
                        }

                        return object;
                    });
            },
            orderCreateRoute() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: 
                        return 'ResidentialOrderCreate';
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return 'CommercialJobOrderCreate';
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return 'CommercialLeadOrderCreate';
                    case ENTITY_TYPES.WARRANTY_JOB:
                        return 'WarrantyJobOrderCreate';
                }
            }
        }
    }
</script>