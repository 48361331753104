var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alert-button relative"},[_c('button',{class:[
            's-align-5 alert-normal', 
            { 'alert-saved' : _vm.success, 'alert-error' : _vm.error }
        ],style:({ height: (_vm.height + "px"), width: (_vm.width + "px") }),attrs:{"disabled":_vm.success || _vm.error}},[(_vm.success && !_vm.processing)?_c('mark-icon',{staticClass:"i-white mr-2",attrs:{"size":"10"}}):_vm._e(),(!_vm.processing)?_c('span',{staticClass:"f-13-white f-sbold"},[_vm._v("\n            "+_vm._s(_vm.success ? 'Saved' : _vm.name)+"\n        ")]):_c('loading',{staticClass:"i-white"})],1),(_vm.error)?_c('div',{class:[
            'alert-error-message', 
            { 
                'alert-top-right' : _vm.direction == 'top' && _vm.side == 'right',
                'alert-bottom-right' : _vm.direction == 'bottom' && _vm.side == 'right',
                'alert-top-left' : _vm.direction == 'top' && _vm.side == 'left',
                'alert-bottom-left' : _vm.direction == 'bottom' && _vm.side == 'left'
            }
        ]},[_c('div',{staticClass:"s-align-4 pb-4"},[_c('span',{staticClass:"alert-error-icon s-align-5 mr-4"},[_c('close-icon',{staticClass:"i-white",attrs:{"size":"8"}})],1),_c('span',{staticClass:"f-13-red f-sbold"},[_vm._v("Error!")])]),_c('p',{staticClass:"f-12-black"},[_vm._v(_vm._s(_vm.message))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }