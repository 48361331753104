<template>
    <svg 
        viewBox="0 0 14 18"
        :width="size" 
        :height="size"
        class="smart-doc-icon"
    >
        <path d="M14 12.4c0-.4-.3-.7-.9-.7-.2 0-.3-.1-.4-.2-.1-.1-.1-.2-.1-.4 0-.1.1-.2.2-.3.3-.3.3-.8.1-1.1-.3-.2-.6-.4-.9-.7-.2-.1-.4-.2-.6-.2-.2 0-.4.1-.5.3-.1.1-.3.2-.6.1-.2-.1-.3-.3-.3-.6 0-.4-.3-.8-.7-.8-.3 0-.8 0-1.1.1-.4 0-.7.4-.7.8 0 .2-.1.4-.3.5-.2 0-.5-.1-.6-.2-.1-.1-.3-.2-.5-.2s-.4 0-.6.2c-.1 0-.2.1-.3.2l-.5.5c-.2.2-.2.4-.2.6 0 .2.1.4.3.5.1.2.2.4.1.6-.1.2-.2.3-.5.3-.2 0-.4.1-.6.2-.1.1-.2.3-.2.4-.1.4-.1.8 0 1.2.1.5.4.7.8.7.3 0 .5.2.5.5 0 .1 0 .3-.2.4-.3.3-.3.8-.1 1.1.1.1.2.2.2.3.2.2.3.4.6.5.2.1.4.2.6.2.2 0 .4-.1.5-.3.1-.1.3-.2.6-.1.2.1.3.3.3.5 0 .4.3.8.7.8h1.3c.1 0 .1 0 .2-.1.2-.1.3-.4.3-.7 0-.2.1-.4.3-.5.1-.1.4 0 .6.1.3.3.8.3 1.1.1l.8-.8c.1-.1.2-.3.2-.5s-.1-.5-.3-.6c-.1-.1-.2-.2-.2-.3 0-.1 0-.3.1-.3.1-.1.3-.2.4-.2.4.1.8-.3.9-.7.2-.4.2-.8.2-1.2zm-2.5 2.3c0 .3.1.6.3.9l-.3.3c-.5-.3-1-.4-1.6-.2-.5.2-.9.7-.9 1.3h-.5c-.1-.5-.5-1-.9-1.2-.6-.3-1.2-.2-1.6.2l-.3-.3c.4-.4.4-1 .2-1.5s-.7-.8-1.2-.9v-.5c.6-.1 1.1-.4 1.3-.9.2-.5.1-1.1-.2-1.5 0-.3.1-.4.2-.4.5.3 1 .4 1.6.2.6-.2.9-.7 1-1.3h.5c.1.5.5 1 1 1.3.5.2 1.1.1 1.6-.2l.3.3c-.3.3-.4.6-.4 1 0 .8.6 1.4 1.4 1.5v.4c-.9.1-1.5.7-1.5 1.5z"/>
        <path d="M8.8 10.9c-1.1 0-2.1 1-2.1 2.1s1 2.1 2.1 2.1c1.2 0 2.1-.9 2.1-2.1 0-1.1-1-2.1-2.1-2.1zm1 2.3c-.1.4-.4.7-.8.8-.5.1-1.1-.3-1.3-.9-.1-.2 0-.5.1-.7.1-.2.4-.4.7-.4H9c.3.1.5.2.7.4.1.2.2.5.1.8z"/>
        <path d="M2.9 15.8h-.5c-.5 0-1-.4-1-1V2.4c0-.5.4-1 1-1h5.1c.3 0 .5.1.7.3l3.5 3.6c.3.2.4.5.4.7v1.2c0 .3.1.5.4.7.5.3 1.1-.1 1.1-.6V6c0-.6-.2-1.2-.7-1.6L9.4.7C8.9.3 8.3 0 7.6 0H2.4C1.1 0 0 1.1 0 2.4v12.4c0 1.3 1.1 2.4 2.4 2.4h.4c.3 0 .5-.1.7-.4.3-.5-.1-1-.6-1z"/>
    </svg>
</template>

<script>
    export default {
        name: 'SmartDocIcon',
        props: {
            size: {
                type: [Number, String],
                default: 18
            }
        }
    }
</script>

