<template>
    <label :class="[
        's-align-5 ph-5 pv-3 hidden', 
        {
            'tag-active': active,
            'tag-default': !active,
            'tag-disabled': disabled,
            'pointer': !disabled
        }
    ]">
        <span class="f-sbold">{{ tag.name }}</span>
        <button 
            class="transparent-button ml-3"
            @click="$emit('addTag', tag)"
            v-if="!active && !disabled"
        >
            <span class="f-16-blue f-sbold">+</span>
        </button>
        <button 
            class="transparent-button ml-3"
            @click="$emit('removeTag', tag)"
            v-if="active && !disabled"
        >
            <close-icon
                class="i-white"
                size="7"
            />
        </button>
    </label>
</template>

<script>
    import { CloseIcon } from '@/icons';

    export default {
        name: 'Tag',
        props: {
            tag: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        components: {
            CloseIcon
        }
    }
</script>