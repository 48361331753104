<template>
    <full-modal @close="$emit('close')">
        <div slot="title">Edit company information</div>
        <div class="edit-company-info-modal pt-4" slot="body">
            <div class="column-center mb-7">
                <div class="company-logo mb-2">
                    <template v-if="!isLogoDeleted">
                        <img
                            v-if="newLogo !== null"
                            ref="newLogo"
                        />
                        
                        <img 
                            v-else-if="companyInfo.thumbnail !== null"
                            :src="`${url}${companyInfo.thumbnail}`" 
                            :alt="companyInfo.company_name"
                            :title="companyInfo.company_name"
                        />
                    </template>
                    <span 
                        v-if="(newLogo === null && companyInfo.thumbnail === null) || isLogoDeleted"
                        class="f-28-darkgrey f-sbold"
                    > 
                        {{ companyInitials }}
                    </span>
                    <label 
                        class="logo-upload-btn"
                        id="pick-company-avatar"
                    >
                        <camera-icon/>
                        <span>
                            Change<br>
                            logo
                        </span>
                    </label>
                    <avatar-cropper
                        trigger="#pick-company-avatar"
                        ref="avatarCompanyCrop"
                        :uploadHandler="avatarUpload"
                        :labels="cropButtons"
                        data-name="file-company-upload"
                    />
                </div>
                <button 
                    v-if="!isLogoDeleted && (newLogo !== null || companyInfo.thumbnail !== null)"
                    class="transparent-button"
                    @click="isLogoDeleted = true"
                >
                    <span class="f-11-red">Delete logo</span>
                    <trash-icon class="i-darkgrey ml-1" width="12" height="12"/>
                </button>
            </div>
            <div 
                :class="[
                    'company-name',
                    isTitleEdit ? 'company-name-edit' : '',
                    errors.has('company_name') ? 'company-name-error' : ''
                ]"
            >
                <div class="row-center row-baseline" v-show="!isTitleEdit">
                    <span class="f-22-black f-sbold">
                        {{ companyName }}
                    </span>
                    <button 
                        class="transparent-button"
                        @click="editTitle()"
                    >
                        <edit-icon class="i-darkgrey ml-3" size="14"/>
                    </button>
                </div>
                <input 
                    v-show="isTitleEdit"
                    type="text"
                    name="company_name"
                    v-model="companyName"
                    v-validate="'required|min:3|max:100'"
                    @keypress.enter="isTitleEdit = false"
                    @blur="isTitleEdit = false"
                    ref="companyName"
                >
            </div>
            <div class="company-phones column-static">
                <p class="f-14-black f-sbold mb-4">Phones</p>
                <form-input
                    label="Phone"
                    :class="[{ 'form-input-danger' : errors.has('company_phone') }]"
                >
                    <input 
                        type="text" 
                        name="company_phone" 
                        class="form-input-field"
                        v-input-style="phone"
                        v-model="phone"
                        v-mask="'(###) ###-####'"
                        placeholder="Phone"
                        v-validate="'required|min:14'"
                    />
                    <!-- <multiselect
                        class="in-dev"
                        :options="phoneTypes"
                        placeholder="Type"
                        :showLabels="false"
                        :max-height="150"
                        :searchable="false"
                        name="company_phone_type"
                        data-name="company_phone_type"
                        v-model="phoneType"
                        :class="[{ 'multiselect-danger' : errors.has('company_phone_type') }]"
                    />     -->
                </form-input>
                <form-input
                    label="Fax"
                    class="mt-3"
                    :class="[{ 'form-input-danger' : errors.has('company_fax') }]"
                >
                    <input 
                        type="text" 
                        name="company_fax" 
                        class="form-input-field"
                        v-input-style="fax"
                        v-model="fax"
                        v-mask="'(###) ###-####'"
                        placeholder="Fax"
                        v-validate="'min:14'"
                    />
                    <!-- <multiselect
                        class="in-dev"
                        :options="phoneTypes"
                        placeholder="Type"
                        :showLabels="false"
                        :max-height="150"
                        :searchable="false"
                        name="company_fax_type"
                        data-name="company_fax_type"
                        v-model="faxType"
                        :class="[{ 'multiselect-danger' : errors.has('company_fax_type') }]"
                    />     -->
                </form-input>
            </div>
            <div class="column-static">
                <p class="f-14-black f-sbold mb-4">Address</p>
                <address-box 
                    type="address"
                    :key="'address'"
                    :existing="address"
                    @address="getMailingAddress"
                />
            </div>
            <div class="column-static">
                <p class="f-14-black f-sbold mb-4">Website</p>
                <form-input
                    label="Website"
                >
                    <input
                        type="text"
                        name="website_url"
                        class="form-input-field"
                        v-input-style="websiteUrl"
                        v-model="websiteUrl"
                        placeholder="Website"
                        v-validate="'min:14'"
                    />
                </form-input>
            </div>
            <div class="column-static pv-8">
                <checkbox-button
                    color="violet"
                    label="Start week on Monday"
                    class="mr-4 mb-2"
                >
                    <input 
                        type="checkbox" 
                        name="start_week_on_monday" 
                        id="start_week_on_monday"
                        v-model="startWeekOnMonday"
                    />
                </checkbox-button>
                
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button mr-4"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                @click="saveCompanyInfo()"
                :disabled="requestIsGoing"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import api from '@/headers.js';
    import { FormInput, CheckboxButton } from '@/ui';
    import FullModal from '@/ui/Modal/FullModal.vue';
    import { CameraIcon, EditIcon, TrashIcon } from '@/icons';
    import AddressBox from '@/components/AddressBox.vue';
    import { mask } from 'vue-the-mask';
    import Multiselect from 'vue-multiselect';
    import AvatarCropper from "vue-avatar-cropper";
    import { validationErrorNotification } from '@/helpers';

    export default {
        name: 'EditCompanyInfoModal',
        components: {
            FormInput, 
            FullModal, 
            CameraIcon, 
            EditIcon,
            TrashIcon,
            AddressBox,
            Multiselect,
            CheckboxButton,
            AvatarCropper
        },
        props: {
            companyInfo: {
                type: Object,
                required: true
            }
        },
        directives: {
            mask
        },
        computed: {
            companyInitials() {
                if (this.companyName !== '') {
                    return this.companyName.match(/\b\w/g).join('').toUpperCase();
                }
                
                return this.companyName;
            }
        },
        data() {
            return {
                url: process.env.VUE_APP_AMAZONAWS_URL,
                requestIsGoing: false,
                isTitleEdit: false,
                // phoneTypes: ['Home', 'Mobile', 'Work', 'Fax'],
                // phoneType: 'Work',
                // faxType: 'Fax',
                companyName: '',
                phone: '',
                fax: '',
                address: {},
                newLogo: null,
                websiteUrl: null,
                isLogoDeleted: false,
                startWeekOnMonday: false,
                cropButtons: {
                    submit: 'Save',
                    cancel: 'Cancel'
                }
            }
        },
        methods: {
            editTitle() {
                this.isTitleEdit = true;
                this.$nextTick(function () {
                    this.$refs.companyName.focus();
                });
            },
            avatarUpload(resp) {
                resp.getCroppedCanvas().toBlob(blob => {
                    this.newLogo = blob;
                    let reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = () => {
                        this.$refs.newLogo.src = reader.result;
                    }
                })
            },
            getMailingAddress(mailingData) {
                this.address = mailingData;
            },
            saveCompanyInfo() {
                // validate nested components
                this.$children.forEach(vm => {
                    vm.$validator.validateAll();
                });

                this.$validator.validateAll().then(result => {
                    if (!result || this.$validator.errors.items.length) {
                        const context = this;
                        validationErrorNotification(this.$validator, context);
                        return;
                    }

                    if (this.$validator.errors.items.length === 0) {
                        this.requestIsGoing = true;

                        let formData = new FormData();
                        formData.append('company_name', this.companyName);
                        formData.append('phone', this.phone.replace(/\D+/g, ''));
                        formData.append('fax', this.fax.replace(/\D+/g, ''));
                        formData.append('address', this.address.address);
                        formData.append('street', this.address.street);
                        formData.append('city', this.address.city);
                        formData.append('state', this.address.state);
                        formData.append('zip', this.address.zip);
                        formData.append('start_week_on_monday', this.startWeekOnMonday ? 1 : 0);
                        formData.append('url', this.websiteUrl || '');

                        if (this.isLogoDeleted) {
                            formData.append('logo', '');
                        };

                        if (this.newLogo !== null) {
                            formData.append('logo', this.newLogo, this.$refs.avatarCompanyCrop.filename);
                        };

                        api.post(`/company-info/update`, formData, { 
                            headers: { 'Content-Type': 'multipart/form-data' }
                        }).then(response => response.data.data)
                            .then(data => {
                                this.$emit('update', data);
                                this.notifySuccess('Changes have been saved!');
                            })
                            .catch(error => {
                                this.notifyRequestErrors(error);
                            })
                            .finally(() => {
                                this.requestIsGoing = false;
                            });
                    }
                });
            }
        },
        created() {
            this.companyName = this.companyInfo.company_name;
            this.websiteUrl = this.companyInfo.url;
            this.phone = this.companyInfo.phone;
            this.address = {
                address: this.companyInfo.address,
                street: this.companyInfo.street,
                city: this.companyInfo.city,
                state: this.companyInfo.state,
                zip: this.companyInfo.zip
            };
            this.fax = this.companyInfo.fax === null ? '' : this.companyInfo.fax;
            this.startWeekOnMonday = this.companyInfo.start_week_on_monday ? true : false;
        }
    }
</script>

