<template>
    <svg 
        viewBox="0 0 60 60"
        :width="size"
        :height="size"
        class="load-more-icon"
    >
        <g>
            <path d="M18,26c-2.206,0-4,1.794-4,4s1.794,4,4,4s4-1.794,4-4S20.206,26,18,26z M18,32c-1.103,0-2-0.897-2-2s0.897-2,2-2 s2,0.897,2,2S19.103,32,18,32z"/>
            <path d="M30,26c-2.206,0-4,1.794-4,4s1.794,4,4,4s4-1.794,4-4S32.206,26,30,26z M30,32c-1.103,0-2-0.897-2-2s0.897-2,2-2 s2,0.897,2,2S31.103,32,30,32z"/>
            <path d="M46,30c0-2.206-1.794-4-4-4s-4,1.794-4,4s1.794,4,4,4S46,32.206,46,30z M42,32c-1.103,0-2-0.897-2-2s0.897-2,2-2 s2,0.897,2,2S43.103,32,42,32z"/>
            <path d="M2.414,30l13.293-13.293c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0l-14,14c-0.391,0.391-0.391,1.023,0,1.414 l14,14C14.488,44.902,14.744,45,15,45s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L2.414,30z"/>
            <path d="M59.707,29.293l-14-14c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L57.586,30L44.293,43.293 c-0.391,0.391-0.391,1.023,0,1.414C44.488,44.902,44.744,45,45,45s0.512-0.098,0.707-0.293l14-14 C60.098,30.316,60.098,29.684,59.707,29.293z"/>
        </g>
    </svg>
</template>
<script>
    export default {
        name: 'LoadMoreIcon',
        props: {
            size: {
                type: [Number, String],
                default: 30
            }
        }
    }
</script>