<template>
    <svg
        :height="size"
        :width="size"
        class="estimates-empty"
        viewBox="0 0 155 40"
    >
        <g>
            <g>
                <path
                    fill="#EFF1F4"
                    d="M139.9,59H26.2c-2,0-3.7-1.7-3.7-3.7v-42c0-2,1.7-3.7,3.7-3.7h113.7c2,0,3.7,1.7,3.7,3.7v41.9C143.6,57.4,142,59,139.9,59z"
                />
            </g>
            <g>
                <g>
                    <path
                        fill="#BCD6F9"
                        d="M114.3,48.2c-7.7,0-14-6.2-14-13.9s6.3-13.9,14-13.9s14,6.2,14,13.9S122,48.2,114.3,48.2z M114.3,24.4c-5.6,0-10,4.4-10,9.9s4.4,9.9,10,9.9s10-4.4,10-9.9S119.9,24.4,114.3,24.4z"
                    />
                </g>
            </g>
            <g>
                <path
                    fill="#FFFFFF"
                    d="M34.6,28.2h42.1c1,0,1.9-0.9,1.9-1.9l0,0c0-1-0.9-1.9-1.9-1.9H34.6c-1,0-1.9,0.9-1.9,1.9l0,0C32.7,27.5,33.6,28.2,34.6,28.2z"
                />
                <path
                    fill="#FFFFFF"
                    d="M34.6,36.2h31.2c1,0,1.9-0.9,1.9-1.9l0,0c0-1-0.9-1.9-1.9-1.9H34.6c-1,0-1.9,0.9-1.9,1.9l0,0C32.7,35.4,33.6,36.2,34.6,36.2z"
                />
                <path
                    fill="#FFFFFF"
                    d="M34.6,44.1h53.3c1,0,1.9-0.9,1.9-1.9l0,0c0-1-0.9-1.9-1.9-1.9H34.6c-1,0-1.9,0.9-1.9,1.9l0,0C32.7,43.3,33.6,44.1,34.6,44.1z"
                />
            </g>
            <g>
                <path
                    fill="#BCD6F9"
                    d="M151.5,7.1h-3.7c-1,0-1.9-0.9-1.9-1.9V1.9c0-1,0.9-1.9,1.9-1.9h3.7c1,0,1.9,0.9,1.9,1.9v3.4C153.5,6.3,152.6,7.1,151.5,7.1z"
                />
            </g>
            <g>
                <path
                    fill="#E5E1F9"
                    d="M154,67h-21.4c-0.6,0-1-0.7-1-1.4l0,0c0-0.7,0.4-1.3,1-1.3H154c0.6,0,1,0.6,1,1.3l0,0C155.1,66.3,154.7,67,154,67z"
                />
            </g>
            <g>
                <path
                    fill="#E5E1F9"
                    d="M15.4,21.1H1c-0.6,0-1-0.7-1-1.4l0,0c0-0.7,0.4-1.3,1-1.3h14.4c0.6,0,1,0.6,1,1.3l0,0C16.5,20.4,16.1,21.1,15.4,21.1z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'EstimatesEmpty',
        props: {
            size: {
                type: [Number, String],
                default: 30
            }
        }
    }
</script>