<template>
    <svg 
        viewBox="0 0 19 19"
        :width="size" 
        :height="size"
        class="chain-icon"
    >
        <path d="M18 3.4L15.6 1c-1.3-1.3-3.5-1.3-4.8 0L8.4 3.4c-2.2 2.2-1.1 3.8-.5 4.4.5.5 1.2.5 1.6 0 .3-.2.4-.5.4-.8 0-.3-.1-.6-.3-.8-.2-.2-.2-.2-.2-.3 0 0 .1-.3.6-.9l2.4-2.4c.4-.4 1.2-.4 1.6 0L16.4 5c.2.2.3.5.3.8 0 .3-.1.6-.3.8L14 9c-.5.5-.7.6-.8.6-.1 0-.1 0-.3-.1-.4-.4-1.2-.4-1.6 0-.5.5-.5 1.2 0 1.6.5.5 1.2.8 1.8.8.2 0 .4 0 .6-.1.6-.2 1.2-.6 1.9-1.2L18 8.2c1.3-1.3 1.3-3.5 0-4.8zM9.4 11.3c-.5.5-.5 1.2 0 1.6.1.1.2.1.2.2s-.1.3-.6.9l-2.4 2.4c-.4.4-1.1.4-1.6 0L2.6 14c-.4-.4-.4-1.1 0-1.6L5 10c.8-.8 1-.6 1.1-.5.5.5 1.2.5 1.6 0 .5-.5.5-1.2 0-1.6-.5-.6-2.1-1.7-4.3.5L1 10.8c-1.3 1.3-1.3 3.5 0 4.8L3.4 18c.7.7 1.5 1 2.4 1s1.8-.3 2.4-1l2.4-2.4c2.2-2.2 1.1-3.8.5-4.4-.5-.4-1.2-.4-1.7.1z"/>
    </svg>
</template>

<script>
    export default {
        name: 'ChainIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>