<template>
    <div class="crew-price-list-section mb-4">
        <div 
            class="section-top row-between pointer"
            @click="open = !open"
        >
            <div class="lg-7 sm-9">
                <div class="section-icon s-align-5">
                    <span
                        class="section-icon-svg s-align-5"
                        v-html="section.icon"
                    />
                </div>
                <span class="f-15-black ellipsis section-title pr-4">{{ section.name }}</span>
            </div>
            <div class="lg-5 sm-3">
                <div class="row-between">
                    <span class="f-13-grey">{{ itemsCount }}</span>
                    <div class="section-icon s-align-5">
                        <arrow-icon 
                            :transform="open ? 270 : 90" 
                            class="i-darkgrey"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div 
            class="w-100"
            v-if="open"
        >
            <div 
                :class="[
                    'row-between row-baseline pv-4', 
                    { 'bb-grey': index != laborsLength - 1 }
                ]"
                v-for="(item, index) in section.items"
                :key="`item-${index}`"
            >
                <div :class="['lg-7 sm-12', mScreen ? 'pl-20' : 'pl-50']">
                    <span class="f-13-black">{{ item.name }}</span>
                </div>
                <div :class="['lg-5 sm-12', { 'pl-20': mScreen }]">
                    <div class="row-between">
                        <div class="column-start">
                            <currency-input 
                                v-if="item.pivot.price"
                                :value="+item.pivot.price"
                                :uniqueId="`currency-input-${sectionIndex}-${index}`"
                                :error="errors.has(`labor-price-${sectionIndex}-${index}`)"
                            >
                                <input 
                                    type="text"
                                    :name="`labor-price-${sectionIndex}-${index}`" 
                                    v-model="item.pivot.price"
                                    v-validate="'required'"
                                    v-limiter
                                    @blur="$emit('editLaborCost', { price: item.pivot.price, id: item.id })"
                                />
                            </currency-input>
                            <currency-input 
                                v-else
                                :value="+item.cost"
                                :uniqueId="`currency-input-${sectionIndex}-${index}`"
                                :error="errors.has(`labor-price-${sectionIndex}-${index}`)"
                            >
                                <input 
                                    type="text"
                                    :name="`labor-price-${sectionIndex}-${index}`" 
                                    v-model="item.cost"
                                    v-validate="'required'"
                                    v-limiter
                                    @blur="$emit('editLaborCost', { price: +item.cost, id: item.id })"
                                />
                            </currency-input>
                            <span class="crew-price-list-unit f-13-grey">{{ item.order_unit }}</span>
                        </div>
                        <div class="section-icon s-align-5">
                            <button 
                                class="icon-light"
                                @click="$emit('deleteLabor', item.id)"
                            >
                                <trash-icon/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { GearIcon, TrashIcon, ArrowIcon } from '@/icons'; 
    import { CurrencyInput } from '@/ui';   

    export default {
        name: 'CrewPriceListSection',
        props: {
            section: {
                type: Object,
                required: true
            },
            sectionIndex: {
                type: [Number, String],
                required: true
            }
        },
        components: {
            GearIcon,
            TrashIcon, 
            ArrowIcon,
            CurrencyInput       
        },
        data() {
            return {
                open: true
            }
        },
        computed: {
            laborsLength() {
                return this.section.items.length;
            },
            itemsCount() {
                return this.mScreen 
                    ? `(${this.laborsLength})` 
                    : `${this.laborsLength} labor ${this.laborsLength == 1 ? 'item' : 'items'}`;
            }
        }
    }
</script>