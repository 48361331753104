<template>
    <div class="w-box column-center mt-20">
        <div class="row-between row-baseline mb-60">
            <p 
                :class="[
                    'f-sbold',
                    mScreen ? 'f-14-black' : 'f-24-black'
                ]"
            >
                Choose how to create an order
            </p>
            <router-link
                :to="{ name: allOrdersRoute }"
                class="add-button"
            >
                Cancel
            </router-link>
        </div>
        <p class="f-16-black t-center mb-20">Create an order based on one of these options</p>
        <div class="row-center mb-60">
            <button 
                :class="[
                    'add-medium-button column-center mr-4 m-sm-0 mb-sm-1',
                    { 'add-medium-button-active' : type === TYPES.BLANK }
                ]"
                @click="type = TYPES.BLANK"
            >
                Blank template
                <span class="small-text">(Start from scratch)</span>
            </button>
            <button 
                :class="[
                    'add-medium-button mr-4 m-sm-0 mb-sm-1',
                    { 'add-medium-button-active' : type === TYPES.ESTIMATE }
                ]"
                @click="type = TYPES.ESTIMATE"
            >
                Primary estimate
            </button>
            <button 
                :class="[
                    'add-medium-button mr-4 m-sm-0',
                    { 'add-medium-button-active' : type === TYPES.TEMPLATE }
                ]"
                @click="type = TYPES.TEMPLATE"
            >
                Template
            </button>
        </div>
        <div
            v-if="type !== TYPES.BLANK" 
            :class="[ mScreen || tScreen ? 'w-100' : 'w-70' ]"
        >
            <div class="order-create-search row-between row-baseline mb-30 pv-30 bb-grey">
                <input
                    class="f-16-black"
                    type="text"
                    :placeholder="searchInputPlaceholder"
                    v-model="needToFind"
                >
                <search-icon class="i-grey"/>
            </div>
            <div v-if="type === TYPES.ESTIMATE">
                <loader v-if="estimatesLoading"/>
                <estimate-preview
                    v-else-if="visibleEstimates.length"
                    v-for="(estimate, index) in visibleEstimates"
                    :item="estimate"
                    :key="`trade-estimate-${index}`"
                    :itemIndex="index"
                    :active="selectedEstimateId == estimate.id"
                    page="EstimateCreate"
                    class="mb-30"
                >
                    <div slot="radio">
                        <div class="angle-input">
                            <input 
                                type="radio"
                                name="template"
                                v-model="selectedEstimateId"
                                :value="estimate.id"
                            />
                            <span class="angle-input-icon">
                                <mark-icon size="12"/>
                            </span>
                        </div>
                    </div>
                </estimate-preview>
                <not-found-message
                    v-else
                    custom="Sorry, nothing matches your request"
                />
            </div>
            <div v-if="type === TYPES.TEMPLATE">
                <loader v-if="templatesLoading"/>
                <template v-else-if="visibleTemplates.length || !showAllTemplates">
                    <estimate-preview
                        v-for="(template, index) in visibleTemplates"
                        :item="template"
                        :key="`trade-template-${index}`"
                        :itemIndex="index"
                        :active="selectedTemplateId == template.id"
                        page="EstimateCreate"
                        class="mb-30"
                    >
                        <div slot="radio">
                            <div class="angle-input">
                                <input 
                                    type="radio"
                                    name="template"
                                    v-model="selectedTemplateId"
                                    :value="template.id"
                                />
                                <span class="angle-input-icon">
                                    <mark-icon size="12"/>
                                </span>
                            </div>
                        </div>
                    </estimate-preview>
                    <div class="row-center t-center mb-8">
                        <span class="f-16-black">
                            Not finding what you are looking for?
                        </span>
                    </div>
                    <div class="row-center pt-4">
                        <div class="lg-6 sm-12 s-align-5">
                            <button 
                                class="transparent-button s-align-4"
                                :disabled="showAllTemplates"
                                @click="showAllTemplates = true"
                            >
                                <span class="f-13-blue f-normal">
                                    Show Templates for Other Trades
                                </span>
                                <arrow-icon 
                                    transform="0"
                                    size="9"
                                    class="ml-2 i-blue"
                                />
                            </button>
                        </div>
                        <div class="lg-6 sm-12 s-align-5">
                            <router-link 
                                class="blue-link"
                                :to="{ name: 'TemplateManager' }"
                            >
                                Show Template Library
                                <arrow-icon 
                                    transform="0"
                                    size="9"
                                />
                            </router-link>
                        </div>
                    </div>
                </template>
                <not-found-message
                    v-else
                    custom="Sorry, nothing matches your request"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { SearchIcon, ArrowIcon, MarkIcon } from '@/icons';
    import { EstimatePreview, NotFoundMessage } from '@/components';
    import { Loader } from '@/ui';
    import { ENTITY_TYPES } from '@/constants';
    import api from '@/headers.js';

    export default {
        name: 'OrderCreateStep2',
        components: {
            SearchIcon,
            ArrowIcon,
            MarkIcon,
            EstimatePreview,
            NotFoundMessage,
            Loader
        },
        props: {
            entityType: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                TYPES: {
                    BLANK: 'blank',
                    ESTIMATE: 'estimate',
                    TEMPLATE: 'template'
                },
                type: '',
                needToFind: '',
                templatesLoading: false,
                templates: [],
                showAllTemplates: false,
                selectedTemplateId: null,
                estimatesLoading: false,
                estimates: [],
                selectedEstimateId: null
            }
        },
        computed: {
            entityId() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: 
                        return this.$route.params.lead_id;
                    case ENTITY_TYPES.WARRANTY_JOB:
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.$route.params.job_id;
                }
            },
            allOrdersRoute() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: 
                        return 'ResidentialOrders';
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return 'CommercialJobOrders';
                    case ENTITY_TYPES.WARRANTY_JOB:
                        return 'WarrantyJobOrders';
                }
            },
            formData() {
                return this.$store.getters['orders/getOrderFormData'];
            },
            searchInputPlaceholder() {
                switch (this.type) {
                    case this.TYPES.ESTIMATE:
                        return 'Search estimate...';
                    case this.TYPES.TEMPLATE:
                        return 'Search template...';
                }
            },
            visibleTemplates() {
                return this.templates.filter(template => {
                    return template.name.toLowerCase().includes(this.needToFind.toLowerCase())
                        && (this.showAllTemplates ? true : template.type.id === this.formData.trade_id)
                });
            },
            visibleEstimates() {
                return this.estimates.filter(estimate => {
                    return estimate.name.toLowerCase().includes(this.needToFind.toLowerCase());
                });
            }
        },
        methods: {
            getTemplates() {
                this.templatesLoading = true;
                api.post('/get-templates')
                    .then(response => {
                        this.templates.push(...response.data.data.data);
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.templatesLoading = false;
                    });
            },
            getEstimates() {
                const params = {
                    entity_id: this.entityId,
                    entity_type: this.entityType
                };

                this.estimatesLoading = true;
                api.get('/estimates', { params })
                    .then(response => {
                        const finalEstimates = response.data.data.filter(estimate => {
                            return estimate.status === estimate.all_statuses.final;
                        });
                        this.estimates.push(...finalEstimates);
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.estimatesLoading = false;
                    });
            },
            // validation is calling in parent component, need to return promise with result
            validateFields() {
                let isValid = false;

                if (this.type === 'blank'
                    || (this.type === this.TYPES.ESTIMATE && this.selectedEstimateId)
                    || (this.type === this.TYPES.TEMPLATE && this.selectedTemplateId))
                {
                    isValid = true;
                }

                if (isValid) {
                    this.$store.commit('orders/STORE_ORDER_FORM_DATA', {
                        type: this.type,
                        estimate_id: this.selectedEstimateId,
                        template_id: this.selectedTemplateId
                    });
                }

                return Promise.resolve(isValid);
            }
        },
        created() {
            this.getTemplates();
            this.getEstimates();

            if (this.formData.type === this.TYPES.ESTIMATE) {
                this.type = this.TYPES.ESTIMATE;
                this.selectedEstimateId = this.formData.estimate_id;
            } else if (this.formData.type === this.TYPES.TEMPLATE) {
                this.type = this.TYPES.TEMPLATE;
                this.selectedTemplateId = this.formData.template_id;
            } else {
                this.type = this.TYPES.BLANK;
            }
        }
    }
</script>