<template>
    <input 
        ref="autocomplete"
        :placeholder="placeholder"
        type="text"
        class="form-input-field"
        v-input-style="value"
        :value="value"
        @input="$emit('input', $event.target.value)"
    />
</template>

<script>
    const ADDRESS_COMPONENTS = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        postal_code: 'short_name'
    };

    export default {
        name: 'Autocomplete',
        props: {
            placeholder: {
                type: String,
                default: 'Address'
            },
            identifier: {
                type: String,
                default: ''
            },
            value: {
                type: String,
                default: ''
            }
        },
        data() {
            return {}
        },
        mounted() {
            const input = this.$refs.autocomplete;

            if(!google || !google.maps) {
                return;
            }

            let address = new google.maps.places.Autocomplete((input), { 
                types: ['geocode'],
                componentRestrictions: { country: 'us' }
            });

            const result = (place) => {
                if (!place.hasOwnProperty('address_components')) return false;

                let data = {};
                for (let i = 0; i < place.address_components.length; i++) {
                    let addressType = place.address_components[i].types[0];
                    if (ADDRESS_COMPONENTS[addressType]) {
                        let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
                        data[addressType] = val;
                    };
                };

                let coords = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                };

                this.$emit('address', data, coords, this.identifier);
            };

            google.maps.event.addListener(address, 'place_changed', function () {
                result(address.getPlace());
            });

            // autocomplete off
            var observerHack = new MutationObserver(function() {
                observerHack.disconnect();
                input.setAttribute('autocomplete', 'nope');
            });

            observerHack.observe(input, { 
                attributes: true,
                attributeFilter: ['autocomplete']
            });
        }
    }
</script>
