import Vue from "vue";
import moment from "moment/src/moment";

const formats = {
    date: 'D MMM YYYY',                                 // convert to full date (e.g. 01 Jan 1970)
    ymdDate: 'YYYY-MM-DD',                              // convert (e.g. 1970-01-01)
    day: 'DD',                                          // convert to day number (e.g. 01)
    month: 'MMMM',                                      // convert to month (e.g. January)
    monthAbbreviation: 'MMM',                           // convert to month abbreviation (e.g. Jan)
    year: 'YYYY',                                       // convert to year (e.g. 1970)
    dayName: 'dddd',                                    // convert to day name (e.g. Monday)
    time: 'hh:mm A',                                    // convert time to am/pm (e.g. 10:00 AM)
    monthShortened: 'MMM',                              // convert to month (e.g. Jan)
    createdAt: 'MMM DD, YYYY [at] hh:mm a',             // convert to month, day and time (e.g. Jan 01, 1970 at 10:00 AM)
    createdAtWithSeconds: 'D MMM YYYY hh:mm:ss',         // convert to month, day and time with seconds (e.g. 01 Jan 1970 10:49:17)
    updatedAt: 'YYYY-MM-DD HH:mm:ss',
}

Object.keys(formats).map(format => {
    Vue.filter(format, function (date, local = true) {
        if (moment(date).isValid()) {
            let getDate = moment.utc(date);
            getDate = local ? getDate.local() : getDate;
            return getDate.format(formats[format]);
        }
        return date;
    });

    Vue.filter(`${format}_ignore_tz`, function (date) {
        if (moment(date).isValid()) {
            return moment(date).format(formats[format]);
        }
        return date;
    });
});

Vue.filter('phone', function (phone) {
    try {
        return phone.toString()
            .replace(/[^0-9]/g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    } catch (err) {
        return phone;
    }
});

Vue.filter('onlyTime', function (time) {
    let formatTime = moment(time, formats['time']);
    if (formatTime.isValid()) {
        return formatTime.format(formats['time']);
    }
    return time;
});

Vue.filter('timeAgo', function (date) {
    if (moment(date).isValid()) {
        return moment.utc(date).local().fromNow();
    }
    return date;
});

Vue.filter('monthAndDay', function (date) { // convert to month and day (e.g. Jan 01)
    if (moment(date).isValid()) {
        return `${moment.utc(date).local().format('MMM')} ${moment.utc(date).local().format('DD')}`;
    }
    return date;
});

Vue.filter('monthDayYear', function (date) { // convert to month and day (e.g. Jan 01, 2020)
    if (moment(date).isValid()) {
        return `
            ${moment.utc(date).local().format('MMM')} 
            ${moment.utc(date).local().format('DD')}, 
            ${moment.utc(date).local().format('YYYY')}
        `;
    }
    return date;
});

Vue.filter('currency', function(amount) {
    try {
        const moneyFormat = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const parsedAmount = moneyFormat.format(amount);

        if (Number.isNaN(parsedAmount)) {
            throw new Error('Number is NaN');
        }

        return parsedAmount;
    } catch (err) {
        return amount;
    }
});

Vue.filter('inputCurrency', function(amount) {
    try {
        const parsedAmount = amount.toString();

        if (isNaN(parsedAmount)) {
            throw new Error('Number is NaN');
        }

        if (parsedAmount < 0 && parsedAmount.includes('-') && parsedAmount.includes('.')) {
            let includes = parsedAmount.split('.');
            return `-$${-includes[0]}.${includes[1]}`;
        }

        return parsedAmount.length > 0 ? parsedAmount >= 0 ? `$${parsedAmount}` : `-$${-parsedAmount}` : '';
    } catch (err) {
        return amount;
    }
});

Vue.filter('numberWithSpaces', function(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
});

Vue.filter('numberWithCommas', function(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

Vue.filter('considerTimeZone', function(stringDate) {
    // Accepting a stringDate parametr e.g. "01 Feb, 2021 22:17 at PM"
    // parses the time and returns in the same format  but with local tz.
    let date = stringDate.substring(0, stringDate.length - 6);
    if (moment(date).isValid()) {
        return moment.utc(date, 'DD MMM, YYYY HH:mm').local().format('DD MMM, YYYY HH:mm [at] A');
    }
    return stringDate;
});

Vue.filter('fileSize', function(bytes, decimals = 2) {
    if (bytes === 0) return '0';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
});

Vue.filter('replaceManufacturerWebsiteText', function (websiteStr) {
    const regExp = /(http(s?)):\/\//;
    const getItemFromRegExp = regExp.exec(websiteStr);

    return getItemFromRegExp ? websiteStr.replace(getItemFromRegExp[0], '') : websiteStr;
});
