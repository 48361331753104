<template>
    <div class="pinned s-align-6">
        <!-- <button class="icon-dark relative">
            <pin-icon size="20"/>
        </button> -->
        <button
            class="icon-dark relative is-info"
            @click="calendarNotificationIsOpen = !calendarNotificationIsOpen,
            notificationIsOpen = false"
        >
            <calendar-icon/>
        </button>
        <button 
            @click="notificationIsOpen = !notificationIsOpen,
            calendarNotificationIsOpen = false" 
            :class="['icon-dark relative', !!notificationMark ? 'is-info' : '']"
        >
            <bell-icon size="20"/>
        </button>
        <calendar-notification 
            v-if="calendarNotificationIsOpen"
            @close="calendarNotificationIsOpen = false"
        />
        <notifications 
            v-if="notificationIsOpen"
            @close="notificationIsOpen = false"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: 'Pinned',
        components: {
            BellIcon: () => import('@/icons/BellIcon.vue'),
            CalendarIcon: () => import('@/icons/CalendarIcon.vue'),
            PinIcon: () => import('@/icons/PinIcon.vue'),
            CalendarNotification: () => import('@/components/Calendar/CalendarNotification.vue'),
            Notifications: () => import('@/components/Notifications.vue'),
        },
        data() {
            return {
                calendarNotificationIsOpen: false,
                notificationIsOpen: false
            }
        },
        computed:{
            ...mapState("notifications", ["notificationMark"])
        },
        methods: {
            outside(event) {
                if (!this.$el.contains(event.target) && this.calendarNotificationIsOpen) {
                    this.calendarNotificationIsOpen = false;
                }
                if (!this.$el.contains(event.target) && this.notificationIsOpen) {
                    this.notificationIsOpen = false;
                }
            },
        },
        mounted() {
            document.body.addEventListener('click', this.outside, false);
        },
        destroyed() {
            document.body.removeEventListener('click', this.outside, false);
        }
    }
</script>