<template>
    <svg 
        viewBox="0 0 15 15"
        :width="size"
        :height="size"
        class="resize-icon"
    >
        <path d="M10.9 0H15v4.1h-1V1.8L9.7 6.1l-.8-.8L13.2 1h-2.3zM5.3 8.9l.8.8L1.8 14h2.3v1H0v-4.1h1v2.3z"/>
    </svg>
</template>

<script>
    export default {
        name: 'ResizeIcon',
        props: {
            size: {
                type: [Number, String],
                default: 15
            }
        }
    }
</script>