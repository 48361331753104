<template>
    <full-modal 
        @close="$emit('close')"
        :full="true"
    >
        <div slot="title">
            <div class="row-baseline">
                <span class="f-18-black f-sbold mr-2">Attachments</span>
                <attach-icon
                    :size="15" 
                    class="i-blue mr-1"
                />
                <span class="f-13-blue">
                    {{ files.length }}
                </span>
            </div>
        </div>
        <div slot="body">
            <button
                class="transparent-button f-12-blue mb-8"
                @click="$emit('downloadAllAttachment')"
            >
                <download-icon
                    class="i-blue mr-2"
                    size="13"
                />
                Download all files
            </button>
            <file
                v-for="(attachment, index) in files"
                :key="index"
                :file="attachment.files[0]"
                :showDownloadBtn="true"
            />
        </div>
    </full-modal>
</template>

<script>
    import { AttachIcon, DownloadIcon } from '@/icons';
    import { FullModal } from '@/ui';
    import File from '@/ui/File.vue';

    export default {
        name: 'ViewAllFilesModal',
        components: {
            FullModal,
            File,
            AttachIcon,
            DownloadIcon
        },
        props: {
            files: {
                type: Array,
                required: true
            }
        },
    }
</script>