import {hasPermissions, somePermission} from './utils/helpers';
import {PERMISSIONS} from "./constants";
import store from '@/store/store';

const ACL = {

    install(Vue, options) {

        Vue.prototype.$permissions = PERMISSIONS;

        Vue.directive('can', {
            inserted(el, binding, vnode, oldVnode) {
                const userPermissions = vnode.context.$store.getters['user/getUserPermissions'];
                let result = false;

                if (binding.arg === 'some') {
                    result = somePermission(binding.value, userPermissions);
                } else {
                    result = hasPermissions(binding.value, userPermissions);
                }

                if (result === false) {
                    vnode.elm.parentElement.removeChild(vnode.elm)
                }

                return true;
            }
        });

        Vue.prototype.$can = (permissions) => {
            const userPermissions = store.getters['user/getUserPermissions'];
            return hasPermissions(permissions, userPermissions);
        }
    }

};

export default ACL;
