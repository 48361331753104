<template>
    <div :class="['h-100', menuIsOpen ? 'properties-menu-open' : 'properties-menu']">
        <button 
            class="properties-menu-button s-align-5"
            @click="openMenu"
            v-if="!dScreen"
        >
            <double-arrow-icon :transform="menuIsOpen ? 180 : 0"/>
        </button>
        <div 
            class="properties-menu-title w-100 s-align-5"
            v-if="!menuIsOpen"
        >
            <span class="f-16-asphalt f-sbold">Properties</span>
        </div>
        <div 
            class="properties-list-wrapper"
            v-else
        >
            <p class="f-16-asphalt f-sbold pl-4 pb-6">Properties</p>
            <ul 
                class="w-100 properties-list"
                v-if="list.length"
            >
                <li
                    v-for="(item, index) in list"
                    :key="`property-item-${index}`"
                    :class="[
                        'pointer row-between row-baseline',
                        { 'active': selected === item.id }
                    ]"
                    @click="selectMenuItem(item)"
                >
                    <span class="f-sbold w-80">{{ item.property_name }}</span> 
                    <span 
                        class="property-item-count s-align-5"
                        v-if="getSum(item.leads_count, item.units_count, item.jobs_count, item.unit_jobs_count) > 0"
                    >
                        {{ getSum(item.leads_count, item.units_count, item.jobs_count, item.unit_jobs_count)}}
                    </span>
                </li>
            </ul>
            <div 
                class="w-100"
                v-else
            >
                <span class="f-12-grey pl-4">There are no properties.</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { DoubleArrowIcon } from '@/icons';

    export default {
        name: 'PropertiesMenu',
        props: {
            list: {
                type: Array,
                required: true
            },
            selected: {
                type: Number,
                required: false
            }
        },
        components: {
            DoubleArrowIcon
        },
        data() {
            return {
                menuIsOpen: false
            }
        },
        methods: {
            openMenu() {
                if (this.dScreen) return false;

                this.menuIsOpen = !this.menuIsOpen;
            },
            selectMenuItem(item) {
                this.$emit('select', item);

                if (!this.dScreen) {
                    this.menuIsOpen = false;
                }
            },
            getSum(...args) {
                return args.reduce((totalSum,currentValue) => {
                    if (isNaN(currentValue)) {
                        return totalSum;
                    }

                    return totalSum + currentValue;
                }, 0);
            }
        },
        created() {
            this.menuIsOpen = this.dScreen;
        }
    }
</script>