var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-jobs-location row-start relative"},[_c('div',{staticClass:"assigned-user-calendar"},[_c('div',{staticClass:"row-start p-20 bb-grey"},[_c('span',{staticClass:"f-16-darkgrey f-sbold mr-2"},[_vm._v(_vm._s(_vm._f("dayName")(_vm.date)))]),_c('span',{staticClass:"f-16-grey f-sbold"},[_vm._v(_vm._s(_vm._f("monthAndDay")(_vm.date)))])]),_c('perfect-scrollbar',{ref:"events",staticClass:"calendar ps-grey w-100 pr-5"},[(_vm.isLoading)?_c('loader',{staticClass:"s-align-5",attrs:{"size":"small"}}):(_vm.events.length || _vm.multiDayEvents.length)?_c('div',{staticClass:"day-view relative"},[(_vm.multiDayEvents.length)?_c('div',{staticClass:"multi-day-events row-start bb-grey"},[_c('div',{staticClass:"multi-day-events-title"},[_vm._v("\n                        All Day \n                    ")]),_c('div',{staticClass:"multi-day-events-row"},[_vm._l((_vm.multiDayEvents),function(event,index){return [_c('div',{key:index,class:[
                                    'event',
                                    _vm.checkIfEventPast(event.dtend) ? 'event-past' : 'event-violet'
                                ],attrs:{"id":("event-" + (event.id))}},[_c('div',{staticClass:"row-between"},[_c('span',{staticClass:"f-12-darkgrey f-sbold"},[_vm._v("\n                                        "+_vm._s(event.summary)+"\n                                    ")]),_c('span',{staticClass:"f-12-darkgrey"},[_vm._v("\n                                        "+_vm._s(_vm._f("time")(event.dtstart))+"\n                                        -\n                                        "+_vm._s(_vm._f("time")(event.dtend))+"\n                                    ")])]),(event.location)?_c('div',{staticClass:"row-start row-baseline mt-1"},[_c('map-icon',{staticClass:"i-blue mr-1",attrs:{"size":"12"}}),_c('span',{staticClass:"f-12-blue"},[_vm._v("\n                                        "+_vm._s(event.location)+"\n                                    ")])],1):_vm._e()])]})],2)]):_vm._e(),_c('div',{staticClass:"single-day-events row-start relative"},[_c('div',{staticClass:"timeline"},[_vm._l((_vm.timeCount),function(index,val){return [_c('div',{key:index,staticClass:"hour"},[_c('span',[_vm._v(_vm._s(_vm.timeFilter(val)))])])]})],2),_c('div',{staticClass:"column-static day-item relative"},[_c('div',{staticClass:"day-hours"},[_vm._l((_vm.timeCount),function(index){return [_c('div',{key:index,staticClass:"hour-item bb-grey"})]})],2),_c('div',[_vm._l((_vm.events),function(event,index){return [_c('div',{key:index,class:[
                                        'event',
                                        _vm.checkIfEventPast(event.dtend) ? 'event-past' : 'event-violet'
                                    ],style:(_vm.getEventStyles(event, _vm.date)),attrs:{"id":("event-" + (event.id))}},[_c('div',{staticClass:"row-between"},[_c('span',{staticClass:"f-12-darkgrey f-sbold"},[_vm._v("\n                                            "+_vm._s(event.summary)+"\n                                        ")]),_c('span',{staticClass:"f-12-darkgrey"},[_vm._v("\n                                            "+_vm._s(_vm._f("time")(event.dtstart))+"\n                                            -\n                                            "+_vm._s(_vm._f("time")(event.dtend))+"\n                                        ")])]),(event.location)?_c('div',{staticClass:"row-start row-baseline mt-1"},[_c('map-icon',{staticClass:"i-blue mr-1",attrs:{"size":"12"}}),_c('span',{staticClass:"f-12-blue"},[_vm._v("\n                                            "+_vm._s(event.location)+"\n                                        ")])],1):_vm._e()])]})],2)])])]):_c('div',{staticClass:"column-center pt-50 pb-50"},[_c('img',{staticClass:"mb-20",attrs:{"src":require("@/assets/images/calendar-notifications-empty.svg"),"width":"99","height":"85","alt":"Calendar notifications not found"}}),_c('p',{staticClass:"f-13-darkgrey t-center"},[_vm._v("\n                    Seems like there are no events"),_c('br'),_vm._v(" \n                    for this day\n                ")])])],1)],1),_c('div',{class:[
            'assigned-user-map',
            _vm.isMapFullWidth ? 'map-full-width' : ''
        ]},[_c('button',{staticClass:"map-toggle s-align-5 b-grey",on:{"click":function($event){_vm.isMapFullWidth = !_vm.isMapFullWidth}}},[_c('double-arrow-icon',{staticClass:"i-grey",attrs:{"transform":_vm.isMapFullWidth ? '0' : '180'}})],1),_c('google-map',{ref:"map",attrs:{"zoom":14}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }