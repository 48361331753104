<template>
    <svg
        viewBox="0 0 13 11"
        :width="size" 
        :height="size"
        class="text-center-icon"
    >
        <path d="M12.6 1.4H.3c-.1 0-.3-.1-.3-.3V.3C0 .2.2 0 .3 0h12.3c.2 0 .4.2.4.3V1c0 .3-.2.4-.4.4zM10.3 4.6H2.7c-.2 0-.4-.2-.4-.3v-.8c0-.2.2-.4.4-.4h7.6c.2 0 .3.2.3.4v.7c.1.3-.1.4-.3.4zM12.6 7.8H.3c-.1 0-.3-.1-.3-.3v-.8c0-.2.2-.3.3-.3h12.3c.2 0 .4.2.4.3v.7c0 .3-.2.4-.4.4zM10.3 11H2.7c-.2 0-.4-.2-.4-.4v-.7c0-.2.2-.3.4-.3h7.6c.2 0 .3.2.3.3v.7c.1.2-.1.4-.3.4z"/>
    </svg>
</template>

<script>
    export default {
        name: 'TextCenterIcon',
        props: {
            size: {
                type: [Number, String],
                default: 13
            }
        }
    }
</script>