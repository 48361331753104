var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('perfect-scrollbar',{staticClass:"commercial-list-details ps-grey w-100"},[(_vm.loading)?_c('div',{staticClass:"w-100"},[_c('loader')],1):_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"s-align-4 w-100 pb-4"},[_c('clock-icon',{staticClass:"i-grey mr-2",attrs:{"size":"12"}}),_c('span',{staticClass:"f-11-grey"},[_vm._v(_vm._s(_vm._f("timeAgo")(_vm.unit.created_at)))])],1),_c('div',{staticClass:"column-start w-100"},[_c('h2',{staticClass:"f-20-darkgrey f-sbold pb-4"},[(_vm.unit.property.customer)?_c('router-link',{attrs:{"to":{ 
                        name: 'CommercialDetails', 
                        params: { 
                            customer_id: _vm.unit.property.customer_id, 
                            property_id: _vm.unit.property.id,
                            unit_id: _vm.unit.id
                        }
                    }}},[_vm._v("\n                    "+_vm._s(_vm.unit.name)+"\n                ")]):_c('router-link',{staticClass:"black-link-underlined relative",attrs:{"to":{ 
                        name: 'CommercialPropertyWithoutCustomer', 
                        params: { property_id: _vm.unit.property.id }
                    }}},[_vm._v("\n                    "+_vm._s(_vm.unit.name)+"\n                ")])],1),_c('div',{staticClass:"row-start pb-3"},[_c('div',{class:['md-12', _vm.addressIsCorrect ? 'lg-8 nb-7' : 'lg-12 nb-12']},[_c('span',{staticClass:"f-12-grey"},[_vm._v("\n                        Address:\n                        "),_c('a',{class:[
                                _vm.unit.full_address ? 'f-12-blue' : 'f-italic',
                                {'none-events': !_vm.getGoogleMapLink(_vm.unit.lat, _vm.unit.lng)}
                            ],attrs:{"href":_vm.getGoogleMapLink(_vm.unit.lat, _vm.unit.lng),"target":"_blank"}},[_vm._v("\n                            "+_vm._s(_vm.unit.full_address 
                                ? _vm.unit.full_address 
                                : 'No information')+"\n                            "),(_vm.getGoogleMapLink(_vm.unit.lat, _vm.unit.lng))?_c('link-icon',{staticClass:"i-blue"}):_vm._e()],1)])]),(_vm.addressIsCorrect)?_c('div',{staticClass:"s-align-3 lg-4 nb-5 md-12 mt-md-1"},[_c('button',{staticClass:"show-on-map",on:{"click":function($event){return _vm.$emit('showMap', { lat: +_vm.unit.lat, lng: +_vm.unit.lng })}}},[_c('map-icon'),_vm._v("\n                        Show on Map\n                    ")],1)]):_vm._e()])]),_c('div',{staticClass:"column-start pv-30 w-100 bb-grey"},[_c('h2',{staticClass:"f-16-darkgrey f-sbold mb-8"},[_vm._v("Information")]),(_vm.unit.property.customer)?_c('p',{staticClass:"f-12-grey pb-3"},[_vm._v("\n                Responsible billing party:\n                "),_c('span',{staticClass:"f-12-black pl-1"},[_c('router-link',{staticClass:"black-link-underlined relative",attrs:{"to":{ name: 'CommercialDetails', 
                        params: { customer_id: _vm.unit.property.customer_id }}}},[_vm._v("\n                        "+_vm._s(_vm.unit.property.customer.name)+"\n                    ")])],1)]):_vm._e(),(_vm.unit.state)?_c('p',{staticClass:"f-12-grey"},[_vm._v("\n                State: \n                "),_c('span',{staticClass:"f-12-black pl-1"},[_vm._v(_vm._s(_vm.unit.state))])]):_vm._e()]),_c('representatives-details-list',{attrs:{"title":"Unit","representatives":_vm.unit.representatives,"entityId":_vm.unit.id}}),_c('div',{staticClass:"column-start pv-30 w-100 bb-grey"},[_c('h2',{staticClass:"f-16-darkgrey f-sbold mb-8"},[_vm._v("Property")]),_c('p',{staticClass:"f-12-grey pb-3"},[_vm._v("\n                Property: \n                "),_c('span',{staticClass:"f-12-black pl-1"},[(_vm.unit.property.customer)?_c('router-link',{staticClass:"black-link-underlined relative",attrs:{"to":{ 
                            name: 'CommercialDetails', 
                            params: { 
                                customer_id: _vm.unit.property.customer_id, 
                                property_id: _vm.unit.property.id 
                            }
                        }}},[_vm._v("\n                        "+_vm._s(_vm.unit.property.property_name)+"\n                    ")]):_c('router-link',{staticClass:"black-link-underlined relative",attrs:{"to":{ 
                            name: 'CommercialPropertyWithoutCustomer', 
                            params: { property_id: _vm.unit.property.id }
                        }}},[_vm._v("\n                        "+_vm._s(_vm.unit.property.property_name)+"\n                    ")])],1)]),_c('p',{staticClass:"f-12-grey"},[_vm._v("\n                Address:\n                "),_c('a',{class:[
                        'pl-1',
                        _vm.unit.property.addresses.main.length ? 'f-12-blue' : 'f-italic',
                        {'none-events': !_vm.getGoogleMapLink(_vm.unit.property.addresses.main[0].lat, _vm.unit.property.addresses.main[0].lng)}
                    ],attrs:{"href":_vm.getGoogleMapLink(_vm.unit.property.addresses.main[0].lat, _vm.unit.property.addresses.main[0].lng),"target":"_blank"}},[_vm._v("\n                    "+_vm._s(_vm.unit.property.addresses.main.length 
                        ? _vm.getAddress(_vm.unit.property.addresses.main[0])
                        : 'No information')+"\n                    "),(_vm.getGoogleMapLink(_vm.unit.property.addresses.main[0].lat, _vm.unit.property.addresses.main[0].lng))?_c('link-icon',{staticClass:"i-blue"}):_vm._e()],1)])]),(_vm.unit.jobs && _vm.jobsLength)?_c('div',{staticClass:"column-start pv-30 w-100"},[_c('button',{staticClass:"transparent-button mb-8",attrs:{"disabled":!_vm.jobsActively},on:{"click":function($event){_vm.jobsIsOpen = !_vm.jobsIsOpen}}},[(_vm.jobsActively)?_c('arrow-icon',{staticClass:"i-blue mr-1",attrs:{"transform":_vm.jobsIsOpen ? 270 : 90,"size":"10"}}):_vm._e(),_c('span',{staticClass:"f-16-darkgrey f-sbold pr-4"},[_vm._v("Jobs")]),(_vm.jobsActively)?_c('span',{staticClass:"count-blue f-11-blue f-bold s-align-5"},[_vm._v("\n                    "+_vm._s(_vm.jobsLength)+"\n                ")]):_vm._e()],1),_vm._l((_vm.jobs),function(job,index){return _c('div',{key:("job-" + index),staticClass:"lead-details-bordered row-between pv-3"},[_c('div',{staticClass:"lg-4 md-12 pv-1"},[_c('p',{staticClass:"f-13-grey"},[_vm._v("\n                        ID:\n                        "),_c('span',{staticClass:"f-13-black pl-1"},[_c('router-link',{staticClass:"black-link-underlined relative",attrs:{"to":{ 
                                    name: 'CommercialJobOverview', 
                                    params: { property_id: job.property_id, job_id: job.id }
                                }}},[_vm._v("\n                                "+_vm._s(job.job_number)+"\n                            ")])],1)])]),_c('div',{staticClass:"lg-4 md-12 pv-1"},[_c('p',{staticClass:"f-13-grey"},[_vm._v("\n                        Date:\n                        "),_c('span',{staticClass:"f-13-black pl-1"},[_vm._v(_vm._s(_vm._f("date")(job.created_at)))])])]),_c('div',{class:['lg-4 md-12 pv-1', _vm.dScreen ? 's-align-6' : 's-align-4']},[(job.type_name)?_c('span',{staticClass:"major-green-label f-11-white f-sbold s-aling-5"},[_vm._v("\n                    "+_vm._s(job.type_name)+"\n                ")]):_vm._e()])])})],2):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }