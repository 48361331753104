var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['mt-8 row-stretch', _vm.dScreen && 'flex-no-wrap']},_vm._l((_vm.reasons),function(reason,index){return _c('div',{key:index,class:[
            'cancel-job-reasons flex-grow-1',
            index && !_vm.mScreen && 'ml-8',
            _vm.mScreen && 'mb-8'
        ]},[_c('div',{staticClass:"reasons-container p-20"},[_c('div',{staticClass:"row-start s-align-4"},[(!_vm.isOpportunity && !_vm.isWarranty)?_c('div',{class:[
                        'badge s-align-5 uppercase f-bold mr-4',
                        reason.type == 'job' ? 'job' : 'lead'
                    ]},[_vm._v("\n                    "+_vm._s(reason.name)+"\n                ")]):_vm._e(),_c('div',{staticClass:"f-14-black f-sbold"},[_vm._v("\n                    "+_vm._s(reason.name)+"\n                ")])]),_c('p',{staticClass:"f-12-grey mb-8 f-normal mt-1"},[_vm._v("\n                "+_vm._s(reason.subtitle)+"\n            ")]),(_vm.isLoading)?_c('loader',{attrs:{"size":"small"}}):_vm._l((reason.reasons),function(el,index){return _c('div',{key:index,staticClass:"reasons-item row-between row-baseline mt-7"},[_c('checkbox-button',{attrs:{"color":reason.color}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(el.is_active),expression:"el.is_active"}],attrs:{"type":"checkbox","disabled":el.isNew},domProps:{"checked":Array.isArray(el.is_active)?_vm._i(el.is_active,null)>-1:(el.is_active)},on:{"change":[function($event){var $$a=el.is_active,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(el, "is_active", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(el, "is_active", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(el, "is_active", $$c)}},function($event){return _vm.updateReason(index, el, $event)}]}})]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(el.title),expression:"el.title"},{name:"resize-textarea",rawName:"v-resize-textarea"},{name:"validate",rawName:"v-validate",value:('required|min:3|max:256'),expression:"'required|min:3|max:256'"}],ref:("milestone_reason_" + index + "_" + (reason.type)),refInFor:true,class:[
                        'reason-title',
                        _vm.errors.has(("milestone_reason_" + index + "_" + (reason.type))) ? 'reason-title-danger' : ''
                    ],attrs:{"name":("milestone_reason_" + index + "_" + (reason.type)),"id":("milestone_reason_" + index + "_" + (reason.type)),"placeholder":"Enter item title...","maxlength":"255","rows":"1"},domProps:{"value":(el.title)},on:{"blur":function($event){return _vm.updateReasonDelay(el, index, reason.type)},"input":function($event){if($event.target.composing){ return; }_vm.$set(el, "title", $event.target.value)}}}),_c('tooltip',{attrs:{"direction":"left","fixedDirection":true,"isShow":_vm.showDeleteModal(index, reason.type),"color":"white"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"f-14-dark f-sbold t-center mb-8"},[_vm._v("\n                            Are you sure to want to delete this item?\n                        ")]),_c('div',{staticClass:"row-between"},[_c('button',{staticClass:"add-button",on:{"click":function($event){_vm.activeReasonTooltip = null}}},[_vm._v("\n                                No\n                            ")]),_c('button',{staticClass:"red-button",attrs:{"disabled":_vm.requestIsGoing},on:{"click":function($event){return _vm.deleteReason(reason.reasons, index)}}},[_vm._v("\n                                Yes\n                            ")])])]},proxy:true}],null,true)},[_c('button',{staticClass:"transparent-button s-align-6",on:{"click":function($event){return _vm.toggleactiveReasonTooltip(index + reason.type)}}},[_c('trash-icon',{staticClass:"i-grey"})],1)])],1)})],2),_c('button',{staticClass:"add-new-reason transparent-button",on:{"click":function($event){return _vm.addNewReason(reason.reasons, reason.type)}}},[_c('span',{staticClass:"icon-container s-align-5 mr-6"},[_c('add-icon',{attrs:{"size":"9"}})],1),_c('span',{staticClass:"f-13-blue"},[_vm._v("New Reason")])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }