<template>
    <div 
        :class="['uploading-file', place == 'modal' ? 'row-between' : 'row-start']"
        v-if="preview != null"
    >
        <div :class="['s-align-4', place == 'modal' ? 'w-85' : 'w-100']">
            <div class="uploading-file-icon">
                <zip-icon 
                    v-if="preview.type == 'zip'"
                    width="30"
                    height="40"
                />
                <document-icon
                    v-if="preview.type == 'document'"
                    width="30"
                    height="40"
                />
                <span 
                    v-if="preview.type == 'img'"
                    :class="['uploading-img mr-2', { 'uploading-img-blur': loading }]"
                    :style="{ backgroundImage: `url(${preview.img})` }"
                />
                <video-icon
                    v-if="preview.type == 'video'"
                    size="35"
                />
            </div>
            <span class="uploading-file-description column-start">
                <span class="f-12-black f-sbold">
                    {{ formatFileName(preview.name) }}
                </span>
                <span class="f-12-grey" v-if="preview.size.length">{{ preview.size }} MB</span>
            </span>
        </div>
        <div 
            class="s-align-4"
            v-if="place == 'modal'"
        >
            <loader 
                size="mini" 
                v-if="loading"
            />
            <mark-icon v-else/>
        </div>
    </div>
</template>

<script>
    import { DocumentIcon, FolderIcon, ZipIcon, VideoIcon, MarkIcon } from '@/icons';
    import { Loader } from '@/ui';
    import { MEGABYTE_SIZE, ZIP_TYPE, IMAGE_TYPE, VIDEO_TYPE } from '@/constants';

    export default {
        name: 'DocumentsUploadPreview',
        props: {
            file: {
                validator: function (value) {
                    return typeof value === 'object';
                }
            },
            place: {
                type: String,
                default: 'page'
            },
            filesInProgress: {
                type: Array,
                default: () => []
            }
        },
        components: {
            DocumentIcon,
            FolderIcon,
            ZipIcon,
            VideoIcon,
            MarkIcon,
            Loader
        },
        data() {
            return {
                preview: null
            }
        },
        computed: {
            loading() {
                return !!this.filesInProgress.find(i => i == this.file.name);
            }
        },
        methods: {
            getPreview(file) {              
                let preview = {
                    name: file.name,
                    type: '',
                    size: file.size == undefined ? '' : parseFloat(file.size/MEGABYTE_SIZE).toFixed(2),
                    img: ''
                };                

                if (file.type == ZIP_TYPE) {
                    preview.type = 'zip';
                } else if (file.type.match(IMAGE_TYPE)) {
                    preview.type = 'img';

                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = function() {
                        preview.img = reader.result;
                    };
                } else if (file.type.match(VIDEO_TYPE)) {
                    preview.type = 'video';
                } else {
                    preview.type = 'document';
                };
                
                this.preview = preview;
            },
            formatFileName(name) {
                if (this.place == 'page' && name.length > 30 && window.innerWidth >= 768) {
                    return `${name.slice(0, 30)}...${name.substr(name.lastIndexOf('.') - 2)}`
                } else if (this.place == 'modal' && name.length > 70 && window.innerWidth >= 768) {
                    return `${name.slice(0, 50)}...${name.substr(name.lastIndexOf('.') - 2)}`
                } else if (window.innerWidth < 768 && name.length > 20) {
                    return `${name.slice(0, 10)}...${name.substr(name.lastIndexOf('.') - 2)}`
                } else {
                    return name;
                };
            }
        },
        mounted() {
            if (this.file)
                this.getPreview(this.file);
        }
    }
</script>
