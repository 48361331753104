<template>
    <div class="modal s-align-5">
        <div class="documents-move-to-modal cm-fond">
            <h2 class="f-24-black f-sbold t-center">Move the file to...</h2>
            <div class="s-align-4 pb-2">
                <span class="f-20-grey f-sbold">Choose a folder</span>
            </div>
            <div class="w-100">
                <multiselect
                    :options="folders"
                    track-by="name" 
                    label="name"
                    v-model="selectedFolder"
                    :showLabels="false"
                    :max-height="160"
                    placeholder="Folder"
                    v-validate="'required'"
                    name="folders"
                    data-name="folders"
                    :class="[{ 'multiselect-danger' : errors.has('folders') }]"
                />
            </div>
            <div class="custom-modal-footer row-between">
                <button 
                    class="add-button"
                    @click="$emit('cancelMoveFile')"
                >
                    Cancel
                </button>
                <button 
                    class="primary-button"
                    @click="save"
                    :disabled="requestIsGoing"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'DocumentsMoveToModal',
        components: {
            Multiselect
        },
        props: {
            folders: {
                type: Array,
                default: function() {
                    return []
                }
            }
        },
        data() {
            return {
                selectedFolder: null,
                requestIsGoing: false
            }
        },
        methods: {
            save() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        this.requestIsGoing = true;
                        this.$emit('moveFile', this.selectedFolder.path);
                    } else {
                        this.notifyError('Choose a folder, please.');
                    }
                });
            }
        }
    }
</script>
