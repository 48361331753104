<template>
    <svg
        viewBox="0 0 20 20"
        :width="size"
        :height="size"
        class="file-upload-icon"
    >
        <g transform="matrix(0.28570356965065, 0, 0, 0.28570356965065, 117.50161353922742, -66.8287582397461)">
            <path d="M-405.2,286.5c-1.4,0-2.5,1.1-2.5,2.5v5c0,3.7,3,6.7,6.8,6.7h45.5c5.1,0,9.2-4.1,9.2-9.2V289c0-1.4-1.1-2.5-2.5-2.5l0,0c-1.4,0-2.5,1.1-2.5,2.5v2.5c0,2.3-1.9,4.2-4.2,4.2h-45.5c-1,0-1.8-0.8-1.8-1.7v-5C-402.7,287.6-403.8,286.5-405.2,286.5L-405.2,286.5z"/>
            <g transform="translate(0,-952.36218)">
                <path d="M-376.9,1189.9c0.5,0,1.1,0.3,1.4,0.6l17,16c0.8,0.7,0.9,2,0.1,2.8c-0.7,0.8-2.1,0.8-2.8,0.1l-13.6-12.8v37.5c0,1.1-0.9,2-2,2s-2-0.9-2-2v-37.5l-13.6,12.8c-0.8,0.7-2.1,0.7-2.8-0.1c-0.8-0.8-0.7-2.1,0.1-2.8l17-16C-377.8,1190-377.4,1189.9-376.9,1189.9L-376.9,1189.9z"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'FileUploadIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>
