<template>
    <div 
        :class="[
            'field-container row-center row-baseline',
            { 'required': requiredField }
        ]"
    >
        <div class="field-type row-start row-baseline">
            <div class="field-icon s-align-4 mr-2">
                <component 
                    v-if="field.type"
                    :is="currentIcon(field.type.label)"
                /> 
            </div>
            <span class="f-14-grey">
                {{ field.type ? field.type.label : field.label }}
            </span>
        </div>
        <div 
            class="field-name" 
            ref="fieldName"
        >
            <tooltip
                v-if="isFieldLabelOverflow"
                direction="top"
                mobileDescription="Field info"
                class="max-w-100"
            >
                <span class="f-14-darkgrey ellipsis max-w-100 inline-block">
                    {{ field.label }}
                </span>
                <template v-slot:content>
                    <p class="f-16-white break-all t-center">
                        {{ field.label }}
                    </p>
                </template>
            </tooltip>
            <span
                v-else 
                class="f-14-darkgrey"
            >
                {{ field.label }}
            </span>
        </div>
        <div class="context-menu-toggle s-align-6">
            <context-menu
                :unicId="`custom-field-menu-${field.id}`"
                width="200"
                v-if="!fieldInPrimarySection"
            >
                <button 
                    v-if="notCheckbox(field)"
                    class="mark-as-required s-align-4 bb-grey pb-4"
                    @click="markAsRequired"
                >
                    <span class="icon-container mr-6">
                        <mark-icon size="8"/>
                    </span>
                    <span>Mark as required</span>
                </button>
                <button 
                    class="s-align-4"
                    @click="$emit('edit')"
                >
                    <div class="icon-container mr-6">
                        <edit-icon/>
                    </div>
                    <span>Edit Properties</span>
                </button>
                <button class="s-align-4 in-dev">
                    <div class="icon-container mr-6"></div>
                    <span>Create Layout Rule</span>
                </button>
                <button
                    class="s-align-4 bt-grey pt-4"
                    @click="showFieldDeleteConfirm = true"
                >
                    <div class="icon-container mr-6">
                        <trash-icon 
                            width="15" 
                            height="12"
                        />
                    </div>
                    <span>Delete Field</span>
                </button>
            </context-menu>
            <button
                v-else
                class="context-menu-toggle icon-light" 
                @click="$emit('edit')"
            >
                <edit-icon size="15"/>
            </button>
        </div>
        <!-- wrapper to prevent drag&drop on child -->
        <div @mousedown="preventDrag($event)">
            <modal
                v-if="showFieldDeleteConfirm"
                @close="showFieldDeleteConfirm = !showFieldDeleteConfirm"
                class="section-delete-confirm"
            >
                <div slot="modal-body">
                    <div class="w-100 column-center pb-4">
                        <span class="f-14-black pb-2">
                            Are you sure want to delete '{{ field.label }}' field?
                        </span>
                        <!-- <span class="f-13-grey">
                            This field will be moved to 
                            <span class="f-13-darkgrey f-sbold">'Unused Fields'</span>
                            section
                        </span> -->
                    </div>
                </div>
                <div slot="modal-footer">
                    <div class="row-between">
                        <button 
                            class="add-button"
                            @click="showFieldDeleteConfirm = false"
                            :disabled="disableBtns"
                        >
                            Cancel
                        </button>
                        <button 
                            class="delete-button mr-4"
                            @click="deleteField"
                            :disabled="disableBtns"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    import api from '@/headers.js';
    import { Modal, Tooltip } from '@/ui';
    import { 
        SingleLineIcon, MultilineIcon, EnvelopeIcon as EmailIcon, PhoneIcon, PickListIcon,
        MultiselectIcon, CalendarIcon as DateIcon, ClockIcon as DateTimeIcon, CurrencyIcon, DecimalIcon, 
        PercentIcon, LongIntegerIcon, CheckboxIcon, UrlIcon, SearchIcon as LookupIcon,
        FileUploadIcon, GearIcon, PersonIcon as MultiUserIcon, CloseIcon, EyeIcon, LineIcon, EditIcon,
        PointsIcon, MarkIcon, TrashIcon, DbTableIcon
    } from '@/icons';
    import { CUSTOM_FIELDS_TYPES } from '@/constants.js';
    import ContextMenu from '@/components/ContextMenu.vue';

    export default {
        name: 'Field',
        components: {
            PointsIcon, Modal, MarkIcon, TrashIcon, Tooltip,  EyeIcon, LineIcon,SingleLineIcon, 
            MultilineIcon, EmailIcon, PhoneIcon, PickListIcon, MultiselectIcon,
            DateIcon, DateTimeIcon, CurrencyIcon, DecimalIcon, PercentIcon,
            LongIntegerIcon, CheckboxIcon, UrlIcon, LookupIcon, FileUploadIcon, 
            GearIcon, MultiUserIcon, CloseIcon, EditIcon, ContextMenu, DbTableIcon
        },
        props: {
            field: {
                type: Object,
                required: true
            },
            sectionId: {
                type: [Number, String],
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            fieldInPrimarySection: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {                
                showFieldDeleteConfirm: false,
                disableBtns: false,
                parameters: JSON.parse(this.field.parameters),
                fieldLabelWidth: 0
            }
        },
        methods: {
            deleteField() {
                this.disableBtns = true;
                api.delete(`/layouts/custom-fields?id=${this.field.id}`)
                    .then(() => {
                        this.showFieldDeleteConfirm = false;
                        this.disableBtns = false;
                        this.$emit('deleted', this.field);
                        this.notifySuccess('Field deleted.');
                    })
            },
            markAsRequired() {
                this.parameters = this.parsedField.parameters;
                this.parameters.required = !this.parameters.required;
                
                const params = {
                    id: this.field.id,
                    parameters: JSON.stringify(this.parameters)
                };

                api.put('/layouts/custom-fields', params)
                    .then(response => {
                        this.notifySuccess('Field has been updated.');
                        this.$emit('updated', response.data.data)
                    });
            },
            preventDrag(event) {
                event.preventDefault();
                return false;
            },
            currentIcon(name) {
                let words = name.split(' ').map(el => el.toLowerCase());
                words.push('icon');
                return words.join('-');
            },
            getFieldWidth() {
                this.fieldLabelWidth = this.$refs.fieldName.offsetWidth;
            },
            notCheckbox(field) {
                return field.type.name != CUSTOM_FIELDS_TYPES.CHECKBOX; 
            }
        },
        computed: {
            requiredField() {
                return this.parsedField.parameters.required || this.fieldInPrimarySection;
            },
            parsedField() {
                return {
                    ...this.field,
                    parameters: JSON.parse(this.field.parameters)
                }
            },
            isFieldLabelOverflow() {
                return this.fieldLabelWidth / 8 < this.field.label.length;
            }
        },
        mounted() {
            document.body.addEventListener('click', this.outside, false);
            window.addEventListener('resize', this.getFieldWidth);
            this.getFieldWidth();
        },
        destroyed() {
            document.body.removeEventListener('click', this.outside, false);
            window.removeEventListener('resize', this.getFieldWidth);
        }
    }
</script>

