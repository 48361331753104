<template>
    <full-modal 
        @close="$emit('close')"
        class="create-new-event-modal"
    >
        <div slot="title">
            {{ updateEventMode ? 'Event settings' : 'Create new event' }}
        </div>
        <div slot="body">
            <div class="w-100 pb-4">
                <form-input
                    label="Title"
                    :class="[{ 'form-input-danger' : errors.has('event_summary') }]"
                >
                    <input
                        type="text"
                        class="form-input-field"
                        name="event_summary"
                        v-model="event.summary"
                        v-input-style="event.summary"
                        placeholder="Title"                        
                        maxlength="251"
                        v-validate="'min:3|max:250'"
                    />
                </form-input>
            </div>
            <div
                v-if="!showFieldForProperty"
                class="w-100 pb-4"
            >
                <form-input label="Job">
                    <input
                        type="text"
                        class="form-input-field"
                        name="job"
                        v-input-style="preview.fullname"
                        v-model="preview.fullname"
                        placeholder="Job"
                        disabled
                    />
                </form-input>
            </div>
            <div class="w-100 pb-8">
                <form-input
                    label="Location"
                    :class="[{ 'form-input-danger' : errors.has('location_address') }]"
                >
                    <autocomplete
                        id="address"
                        identifier="address"
                        placeholder="Location"
                        name="location_address"
                        :value="event.location.address"
                        @address="setAddress"
                        @input="manualLocationInput"
                        v-validate="'required'"
                    />
                </form-input>
            </div>
            <p
                v-if="showFieldForProperty"
                class="f-14-asphalt f-sbold mb-4"
            >
                Primary of contact for property event.
            </p>
            <div class="relative mb-5">
                <div
                    class="w-100 row-baseline"
                    v-for="(person, index) in representativeForPropertyEvent"
                    :key="index"
                >
                    <div class="lg-1">
                        <avatar
                            :name="`${person.first_name && person.first_name} ${person.last_name && person.last_name}`"
                            :border="false"
                            size="35"
                        />
                    </div>
                    <div class="lg-10 column-start">
                        <span
                            v-if="person.first_name || person.last_name"
                            class="f-12-black hidden ellipsis"
                        >
                            {{ `${person.first_name} ${person.last_name}` }}
                        </span>
                        <span
                            v-if="person.emails && person.emails.length"
                            class="f-10-grey hidden ellipsis"
                        >
                            {{ person.emails[0].email }}
                        </span>
                    </div>
                    <div class="lg-1 s-align-5">
                        <button
                            class="transparent-input p-1"
                            @click="representativeForPropertyEvent.splice(index, 1)"
                        >
                            <close-icon
                                size="7"
                                class="i-grey"
                            />
                        </button>
                    </div>
                </div>
                <button
                    @click="openOptionList = !openOptionList"
                    class="transparent-button pv-2 add-responsible"
                    v-if="isPropertyPoc && showFieldForProperty"
                >
                    <add-icon
                        :class="[
                            'i-blue mr-4',
                            {'i-red' : pocPropertyEventError}
                        ]"
                        size="10"
                    />
                    <span
                        :class="[
                            'f-13-blue',
                            {'f-13-red' : pocPropertyEventError}
                        ]"
                    >
                        Choose contact for property event
                    </span>
                </button>
                <div
                    class="responsible-options-wrapper s-align-5"
                    v-if="openOptionList"
                >
                    <div class="responsible-options">
                        <div class="row-between row-baseline p-20">
                            <span class="f-15-asphalt f-sbold">Choose from the List:</span>
                            <button
                                class="icon-light"
                                @click="openOptionList = false"
                            >
                                <close-icon size="13"/>
                            </button>
                        </div>
                        <perfect-scrollbar class="responsible-scroll ps-blue">
                            <ul class="w-100 pb-2">
                                <li
                                    :class="[
                                        'responsible-options-item row-between',
                                        !checkAddedRepresentative(person) && 'pointer'
                                    ]"
                                    v-for="(person, index) in pocForPropertyEvent"
                                    :key="`person-${index}`"
                                    @click="addRepresentativeForPropertyEvent(person)"
                                >
                                    <div class="s-align-4">
                                        <avatar
                                            :url="null"
                                            :name="`${person.first_name} ${person.last_name}`"
                                            :border="false"
                                            size="26"
                                            class="mr-4"
                                        />
                                        <span class="f-12-darkgrey f-sbold">
                                            {{ `${person.first_name} ${person.last_name}` }}
                                        </span>
                                    </div>
                                    <div class="s-align-6 check-representative-action">
                                        <button class="transparent-button pv-2 pl-2 w-100">
                                            <mark-icon
                                                size="15"
                                                class="i-green is-check"
                                                v-if="checkAddedRepresentative(person)"
                                            />
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </perfect-scrollbar>
                    </div>
                </div>
            </div>
            <p class="f-14-asphalt f-sbold mb-4">Select calendar type (user or technician)</p>
            <div class="row-start mb-4 flex-no-wrap">
                <radio-switch 
                    v-for="type in calendarTypes"
                    :key="`calendar-type-${type}`"
                    :label="type"
                    color="blue"
                    width="154"
                    height="35"
                    class="capitalize flex-grow-1"
                >
                    <input
                        type="radio" 
                        :name="`calendar-type-${type}`" 
                        v-model="calendarType"
                        :value="type"
                        @input="selectedUser = null, selectedCalendar = null"
                    />
                </radio-switch>
            </div>
            <div class="w-100 pb-4">
                <multiselect
                    :options="calendarUsersList"
                    track-by="fullname"
                    label="fullname"
                    name="calendar_users_list"
                    placeholder="Assign to"
                    v-model="selectedUser"
                    data-name="calendar_users_list"
                    :show-labels="false"
                    v-validate="'required'"
                    :class="[{ 'multiselect-danger' : errors.has('calendar_users_list') }]"
                    @select="onUserSelect"
                >
                    <template 
                        slot="singleLabel" 
                        slot-scope="props"
                    >      
                        <template v-if="props.option.first_name !== undefined || props.option.name !== undefined">
                            <user-box
                                :firstName="props.option.first_name || props.option.name"
                                :lastName="props.option.last_name || ''"
                                :avatarUrl="props.option.avatar" 
                            />
                        </template>
                        <template v-else>
                            <p class="f-14-grey">Assign to...</p>
                        </template>
                    </template>
                    <template 
                        slot="option" 
                        slot-scope="props"
                    >   
                        <user-box
                            :firstName="props.option.first_name || props.option.name"
                            :lastName="props.option.last_name || ''"
                            :avatarUrl="props.option.avatar" 
                        />
                    </template>  
                </multiselect>
            </div>
            <div class="w-100 pb-4">
                <multiselect
                    :options="userCalendarsList"
                    track-by="id"
                    label="summary"
                    name="user_calendars_list"
                    placeholder="Choose calendar"
                    v-model="selectedCalendar"
                    data-name="user_calendars_list"
                    :show-labels="false"
                    :allow-empty="false"
                    v-validate="'required'"
                    :class="[{ 'multiselect-danger' : errors.has('user_calendars_list') || !isOutlookSynced }]"
                    @select="setCalendarId"
                    :disabled="!selectedUser"
                >
                    <template 
                        slot="singleLabel" 
                        slot-scope="props"
                    >      
                        <template v-if="props.option">
                            <div class="s-align-4">
                                <crm-logo
                                    v-if="props.option.provider == CRM" 
                                    class="mh-3"
                                />
                                <outlook-logo
                                    v-else 
                                    class="mh-3"
                                />
                                <span class="f-13-black ellipsis">{{ props.option.summary }}</span> 
                                <span
                                    v-if="props.option.primary"
                                    class="light-blue-label f-12-white ml-1"
                                >
                                    Primary
                                </span>
                            </div>
                        </template>
                    </template> 
                    <template 
                        slot="option" 
                        slot-scope="props"
                    >   
                        <div class="s-align-4">
                            <crm-logo
                                v-if="props.option.provider == CRM" 
                                class="mr-2"
                            />
                            <outlook-logo
                                v-else 
                                class="mr-2"
                            />
                            <span class="f-13-black ellipsis">{{ props.option.summary }}</span> 
                            <span
                                v-if="props.option.primary"
                                class="light-blue-label f-12-white ml-1"
                            >
                                Primary
                            </span>
                        </div>
                    </template>
                </multiselect>
            </div>
            <div class="row-between row-baseline pt-20 pb-4">
                <span class="f-14-asphalt f-sbold">Date and time</span>
                <checkbox-button label="All Day">
                    <input
                        type="checkbox"
                        name="all_day"
                        v-model="isEventAllDay"
                    />
                </checkbox-button>
            </div>
            <div class="w-100 pb-4">
                <form-date-time-input
                    v-if="!isEventAllDay"
                    class="mb-2"
                    v-model="event.dtstart"
                    v-validate="'eventStartDate|required'"
                    name="event_start_date"
                    placeholder="Start Date"
                    :class="[{ 'form-date-time-input-error' : errors.has('event_start_date') }]"
                />
                <form-datepicker
                    v-else
                    class="mb-2"
                    v-model="event.dtstart"
                    v-validate="'eventStartDate|required'"
                    name="event_start_date"
                    placeholder="Start Date"
                    keyDatePicker="event_start_date_picker"
                    :class="[{ 'form-datepicker-error' : errors.has('event_start_date') }]"
                />
            </div>
            <div class="w-100 pb-4">
                <form-date-time-input
                    v-if="!isEventAllDay"
                    v-model="event.dtend"
                    v-validate="'eventEndDate|required'"
                    name="event_end_date"
                    placeholder="End Date"
                    :class="[{ 'form-date-time-input-error' : errors.has('event_end_date') }]"
                    :minDate="minDateEndDate"
                />
                <form-datepicker
                    v-else
                    v-model="event.dtend"
                    v-validate="'eventEndDate|required'"
                    name="event_end_date"
                    placeholder="End Date"
                    :class="[{ 'form-datepicker-error' : errors.has('event_end_date') }]"
                    :minDate="minDateEndDate"
                    keyDatePicker="event_end_date_picker"
                />
            </div>
            <div class="column-start mt-6">
                <p class="f-14-asphalt f-sbold mb-4">Participants</p>
                <div
                    v-for="(customer, index) in attendees"
                    :key="index"
                    class="row-start row-baseline mb-3"
                >
                    <div class="lg-1">
                        <avatar
                            :name="`${customer.first_name && customer.first_name} ${customer.last_name && customer.last_name}`"
                            :border="false"
                            size="35"
                            :url="customer.thumbnail"
                        />
                    </div>
                    <div class="lg-10 column-start">
                        <span
                            v-if="customer.first_name || customer.last_name"
                            class="f-12-black hidden ellipsis"
                        >
                            {{ `${customer.first_name} ${customer.last_name}` }}
                        </span>
                        <span class="f-10-grey hidden ellipsis">{{ customer.email }}</span>
                    </div>
                    <div class="lg-1 s-align-5">
                        <button
                            class="transparent-input p-1"
                            @click="attendees.splice(index, 1)"
                        >
                            <close-icon
                                size="7"
                                class="i-grey"
                            />
                        </button>
                    </div>
                </div>
                <multiselect
                    :options="calendarsOwnersList"
                    track-by="fullname"
                    label="fullname"
                    name="calendar_new_customer"
                    placeholder="Placeholder to invite..."
                    data-name="calendar_new_customer"
                    :show-labels="false"
                    :taggable="true"
                    tagPlaceholder="Press enter to add customer"
                    @tag="addCustomer"
                    @select="addCustomer"
                >
                    <template 
                        slot="singleLabel" 
                        slot-scope="props"
                    >      
                        <template v-if="props.option.first_name !== undefined || props.option.name !== undefined">
                            <user-box
                                :firstName="props.option.first_name || props.option.name"
                                :lastName="props.option.last_name || ''"
                                :avatarUrl="props.option.avatar" 
                            />
                        </template>
                        <template v-else>
                            <p class="f-14-grey">Placeholder to invite...</p>
                        </template>
                    </template>
                    <template 
                        slot="option" 
                        slot-scope="props"
                    >   
                        <template v-if="props.option.first_name !== undefined || props.option.name !== undefined">
                            <user-box
                                :firstName="props.option.first_name || props.option.name"
                                :lastName="props.option.last_name || ''"
                                :avatarUrl="props.option.avatar" 
                            />
                        </template>
                        <template v-else>
                            {{ props.option.label }}
                        </template>
                    </template>  
                </multiselect>
            </div>
            <div class="pt-20 row-between">
                <div class="row-start row-baseline mb-3">
                    <span class="f-14-black f-sbold mr-2">Attachments</span>
                    <attach-icon 
                        class="i-blue mr-2"
                        size="12"
                    />
                    <span class="f-13-blue"> 
                        {{ attachments.length ? attachments.length : '' }}
                    </span>
                </div>
                <div
                    v-for="(file, index) in attachments"
                    :key="index"
                    class="s-align-4 pv-5 ph-7 event-attachment"
                >
                    <div class="lg-10 s-align-4">
                        <document-icon
                            width="17"
                            height="22"
                        />
                        <div class="event-attachment-title w-90 ml-6">
                            <span class="f-13-black ellipsis">
                                {{ file.files ? file.files[0].original_name : file.name }}
                            </span>
                            <span class="f-13-grey ellipsis">
                                <span>
                                    {{ file.files ? file.files[0].size : file.size /1000 }}
                                </span>
                                KB
                            </span>
                        </div>
                    </div>
                    <div class="lg-2 s-align-6">
                        <div class="event-attachment-delete-wrapper s-align-5">
                            <button
                                class="transparent-button p-1"
                                @click="deleteAttachment(file,index)"
                            >
                                <close-icon
                                    class="i-grey"
                                    size="7"
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row-start">
                    <button 
                        class="transparent-button mt-3"
                        @click="startUploadAttachment()"
                    >
                        <plus-icon class="mr-2 i-blue"/>
                        <span class="f-13-blue">Add attachment</span>
                    </button>
                </div>
                <input 
                    type="file" 
                    name="file_upload"
                    ref="uploadEventAttachment"
                    @change="fileInputHandler"
                    hidden
                />
            </div>
            <p class="f-14-asphalt f-sbold pt-20 mb-1">Description</p>
            <!-- <form-input-textarea v-input-counter="250">
                <textarea
                    class="form-input-field"
                    name="description"
                    v-model="event.description"
                    maxlength="250"
                    v-resize-textarea
                />
            </form-input-textarea> -->
            <text-editor 
                v-model="event.description"
                class="mb-4"
                placeholder="Enter notes..."
                toolbarName="notesToolbar"
                theme="bubble"
                maxLength="2500"
            />
        </div>
        <div slot="footer">
            <template
                v-if="mode === CREATE_REDISPATCH_EVENT 
                    || mode === UPDATE_REDISPATCH_EVENT"
            >
                <button
                    class="colored-large-button"
                    @click="validateBeforeSubmit"
                >
                    Add Re-Dispatch Event
                </button>
            </template>
            <template v-else>
                <button
                    class="add-button"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <submit-button
                    :title="updateEventMode ? 'Save' : 'Create'"
                    :status="requestIsGoing"
                    @click.native.prevent="validateBeforeSubmit"
                />
            </template>
        </div>
    </full-modal>
</template>

<script>
    import {
        FullModal, FormInput, CheckboxButton, FormDateTimeInput,
        FormInputTextarea, SubmitButton, RadioSwitch, FormDatepicker
    } from '@/ui';
    import { Autocomplete } from '@/GoogleApi';
    import UserBox from '@/components/User/UserBox.vue';
    import TextEditor from '@/components/TextEditor.vue';
    import Multiselect from 'vue-multiselect';
    import moment from 'moment/src/moment';
    import api from '@/headers.js';
    import { ENTITY_TYPES, CALENDAR_TYPES, CALENDAR_PROVIDERS, MODEL_CLASSES } from '@/constants';
    import { OutlookLogo, CrmLogo, AttachIcon, PlusIcon, DocumentIcon, CloseIcon, MarkIcon, AddIcon } from '@/icons';
    import Avatar from '@/components/User/Avatar';
    import { getContentWithoutBottomAndTopBrTag, sortRepresentatives } from '@/helpers';
    import IMAPapi from '@/IMAPheaders';

    const CREATE = 'create';
    const UPDATE = 'update';
    const CREATE_REDISPATCH_EVENT = 'create_redispatch_event';
    const UPDATE_REDISPATCH_EVENT = 'update_redispatch_event';
    const UPDATE_FROM_PROPERTY = 'update_from_property';
    const CREATE_PROPERTY_EVENT = 'create_property_event';
    const UPDATE_PROPERTY_EVENT = 'update_property_event';
    const CRM = 'CRM';
    const ENTITY_COMMERCIAL_PROPERTY = 'CommercialProperty';
    const PROPERTY = 'PROPERTY';

    export default {
        name: 'CreateNewEventModal',
        components: {
            FullModal,
            FormInput,
            Autocomplete,
            Multiselect,
            UserBox,
            TextEditor,
            CheckboxButton,
            FormDateTimeInput,
            FormInputTextarea,
            SubmitButton,
            OutlookLogo,
            CrmLogo,
            AttachIcon, 
            PlusIcon, 
            DocumentIcon, 
            CloseIcon,
            MarkIcon,
            AddIcon,
            RadioSwitch,
            Avatar,
            FormDatepicker
        },
        props: {
            calendarList: {
                type: Array,
                default: () => []
            },
            editableEvent: {
                type: Object,
                default: () => {}
            },
            mode: {
                type: String,
                default: CREATE
            },
            entityType: {
                type: String,
                required: true
            },
            pocForPropertyEvent: {
                type: Array,
                required: false
            }
        },
        data() {
            return {
                event: {
                    summary: '',
                    location: {
                        address: '',
                        city: '',
                        lat: '',
                        lng: ''
                    },
                    calendar_id: '',
                    dtstart: null,
                    dtend: null,
                    description: ''
                },
                selectedCalendar: null,
                selectedUser: null,
                requestIsGoing: false,
                UPDATE: UPDATE,
                CREATE_REDISPATCH_EVENT,
                UPDATE_REDISPATCH_EVENT,
                CRM: CRM,
                CALENDAR_TYPES: CALENDAR_TYPES,
                calendarType: '',
                attachments: [],
                sendAttachments: [],
                attachmentsForDelete: [],
                CRM: CALENDAR_PROVIDERS.CRM,
                attendees: [],
                isEventAllDay: false,
                calendarsOwners: [],
                isOutlookSynced: true,
                openOptionList: false,
                representativeForPropertyEvent: [],
                pocPropertyEventError: false
            }
        },
        computed: {
            createTechnicianEvent() {
                return this.$store.getters['calendars/getIsCreateTechnicianEvent'];
            },
            job() {
                return this.$store.getters['commercial/getCommercialJob'];
            },
            calendarTypes() {
                return Object.values(CALENDAR_TYPES).slice(0, 2);
            },
            preview() {
                if (this.eventInPropertyModule) {
                    switch (this.editableEvent.entity_type) {
                        case MODEL_CLASSES.COMMERCIAL_LEAD: {
                            return this.$store.getters['commercial/getCommercialLeadForEvents'];
                        }
                        case MODEL_CLASSES.COMMERCIAL_JOB.JOB: {
                           return this.$store.getters['commercial/getCommercialJobPreview'];
                        }
                    }
                }

                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: 
                        return this.$store.getters['lead/getLeadPreview'];
                        break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.$store.getters['commercial/getCommercialLeadForEvents'];
                        break;
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.$store.getters['commercial/getCommercialJobPreview'];
                        break;
                    case ENTITY_TYPES.OPPORTUNITY:
                        return this.$store.getters['opportunity/getOpportunityForEvents'];
                        break;
                    case ENTITY_TYPES.WARRANTY_JOB:
                        return this.$store.getters['warranty/getWarrantyForEvents'];
                        break;
                }
            },
            address() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD:
                        return this.$store.getters['lead/getLeadPreview'].full_address;
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.$store.getters['commercial/getCommercialLeadAddress'].full_address;
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.$store.getters['commercial/getCommercialJob'].full_address;
                    case ENTITY_TYPES.OPPORTUNITY:
                        return this.$store.getters['opportunity/getOpportunityForEvents'].full_address;
                    case ENTITY_TYPES.WARRANTY_JOB:
                        return this.$store.getters['warranty/getWarrantyForEvents'].full_address;
                }
            },
            entity_responsible() {
                if (this.eventInPropertyModule) {
                    switch (this.editableEvent.entity_type) {
                        case MODEL_CLASSES.COMMERCIAL_LEAD: {
                            let data = {
                                poc: this.preview.poc,
                                email: this.preview.email,
                                phone: this.preview.phone
                            }
                            return data;
                        }
                        case MODEL_CLASSES.COMMERCIAL_JOB.JOB: {
                            let primary = sortRepresentatives(this.preview.representatives, this.entityId)[0];
                            let poc = `${primary.first_name} ${primary.last_name}, ${primary.title}`;
                            let data = {
                                poc: poc,
                                email: primary.emails[0].email || '',
                                phone: primary.phones[0].number || ''
                            }
                            return data;
                        }
                    }
                }

                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: { 
                        let data = {
                            poc: this.preview.fullname,
                            email: this.preview.email_1 || '',
                            phone: this.preview.tel_1 || ''
                        }
                        return data;
                    }
                    case ENTITY_TYPES.COMMERCIAL_LEAD: {
                        let data = {
                            poc: this.preview.poc,
                            email: this.preview.email,
                            phone: this.preview.phone
                        }
                        return data;
                    }
                    case ENTITY_TYPES.COMMERCIAL_JOB: {
                        let primary = sortRepresentatives(this.preview.representatives, this.entityId)[0];
                        let poc = `${primary.first_name} ${primary.last_name}, ${primary.title}`;
                        let data = {
                            poc: poc,
                            email: primary.emails[0].email || '',
                            phone: primary.phones[0].number || ''
                        }
                        return data;
                    }
                    case ENTITY_TYPES.OPPORTUNITY: {
                        let data = {
                            poc: this.preview.poc,
                            email: this.preview.email,
                            phone: this.preview.phone
                        }
                        return data;
                    }
                    case ENTITY_TYPES.WARRANTY_JOB: {
                        let data = {
                            poc: this.preview.poc,
                            email: this.preview.email,
                            phone: this.preview.phone
                        }
                        return data;
                    }
                }
            },
            entityId() {
                if (this.eventInPropertyModule && this.mode === CREATE_PROPERTY_EVENT) {
                    return this.$route.params.property_id;
                }

                if (this.eventInPropertyModule && this.mode === UPDATE_FROM_PROPERTY) {
                    switch (this.editableEvent.entity_type) {
                        case MODEL_CLASSES.COMMERCIAL_JOB.PROPERTY: {
                            return this.$route.params.property_id;
                        }
                        case MODEL_CLASSES.COMMERCIAL_LEAD:
                        case MODEL_CLASSES.COMMERCIAL_JOB.JOB: {
                            return this.editableEvent.entity_id;
                        }
                    }
                }

                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: {
                        return this.$route.params.lead_id;
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_JOB: {
                        return this.$route.params.job_id;
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return this.$route.params.lead_id;
                    } break;
                    case ENTITY_TYPES.OPPORTUNITY: {
                        return this.$route.params.opportunity_id;
                    } break;
                    case ENTITY_TYPES.WARRANTY_JOB: {
                        return this.$route.params.job_id;
                    } break;
                }
            },
            minDateEndDate() {
                return this.event.dtstart ? moment(this.event.dtstart).toDate() : new Date();
            },
            calendarUsersList() {
                return Array.from(
                    this.calendarList.filter(i => i.owner_type === this.calendarType && i.owner.is_active)
                    .map(el => el.owner)
                    .reduce((m, t) => m.set(t.id, t), new Map())
                    .values()
                );
            },
            userCalendarsList() {
                if (this.selectedUser) {
                    return this.calendarList.filter(el => {
                        return el.owner.id == this.selectedUser.id
                            && el.owner_type === this.calendarType
                    });
                }

                return [];
            },
            utcOffset() {
                return moment().utcOffset();
            },
            calendarsOwnersList() {
                if(this.mode === UPDATE) {
                    let selectedCustomer = this.calendarsOwners.findIndex(el => {
                        if (this.selectedUser && this.selectedCalendar) {
                            return el.id === this.selectedUser.id && el.type === this.selectedCalendar.owner_type;
                        }
                    });
                    return this.calendarsOwners.filter((el, index) => index !== selectedCustomer
                        && !this.attendees.some(a => a.attendee_id === el.id
                            && a.attendee_type === el.type
                            || a.id == el.id && a.type === el.type));
                }

                if (this.selectedUser || this.selectedUser && this.attendees.length) {
                    let selectedCustomer = this.calendarsOwners.findIndex(el => el.id === this.selectedUser.id && el.type === this.selectedCalendar.owner_type);
                    return this.calendarsOwners.filter((el, index) => index !== selectedCustomer && !this.attendees.some(a => a.id == el.id && a.type === el.type));
                } else {
                    return this.calendarsOwners.filter(el => !this.attendees.some(a => a.id == el.id && a.type == el.type));
                }
            },
            loggedInUserId() {
                return this.$store.getters['user/getUserId'];
            },
            calendarOwnerId() {
                return this.selectedCalendar.owner_id 
                    ? this.selectedCalendar.owner_id 
                    : this.loggedInUserId;
            },
            eventInPropertyModule() {
                return this.$route.params.property_id && this.entityType === PROPERTY;
            },
            showFieldForProperty() {
                return this.mode === CREATE_PROPERTY_EVENT
                    || this.mode === UPDATE_PROPERTY_EVENT;
            },
            isPropertyPoc() {
                if (this.eventInPropertyModule && this.pocForPropertyEvent) {
                    return !!this.pocForPropertyEvent.length 
                        && !this.representativeForPropertyEvent.length;
                }
            },
            propertyEntityResponsible() {
                if (this.representativeForPropertyEvent && this.representativeForPropertyEvent.length) {
                    let first_name = this.representativeForPropertyEvent[0].first_name;
                    let last_name = this.representativeForPropertyEvent[0].last_name;
                    let poc = `${first_name}${last_name}`;
                    let data = {
                        poc: poc,
                        email: this.representativeForPropertyEvent[0].emails[0].email || '',
                        phone: this.representativeForPropertyEvent[0].phones[0].number || ''
                    }
                    return data;
                }
            },
            updateEventMode() {
                return this.mode == UPDATE || this.mode == UPDATE_FROM_PROPERTY;
            }
        },
        watch: {
            selectedUser(val,oldVal) {
                if (this.calendarType == CALENDAR_TYPES.TECHNICIAN && val) {
                    this.selectedCalendar = this.userCalendarsList[0];
                    this.event.calendar_id = this.selectedCalendar.calendar_id;
                }
            },
            'event.dtstart': function(val) {
                if (!this.isEventAllDay && !this.event.dtend) {
                    this.event.dtend = moment(this.event.dtstart).add(2, 'h').format('YYYY-MM-DD HH:mm:ss');
                }
            },
            isEventAllDay(val,oldVal) {
                if (val != oldVal && !val && !!this.event.dtstart && !!this.event.dtend) {
                    this.$validator.pause();
                    this.event.dtstart = moment(this.event.dtstart).set({ hour:'07', minutes: '00' })
                            .subtract(this.utcOffset, 'm')
                            .format('YYYY-MM-DD HH:mm:ss');
                    this.event.dtend = moment(this.event.dtend).set({ hour:'09', minutes: '00' })
                            .subtract(this.utcOffset, 'm')
                            .format('YYYY-MM-DD HH:mm:ss');
                    this.$nextTick(() => {
                        this.$validator.resume();
                    })
                }
            },
            selectedCalendar(val) {
                this.checkOutlookSync(this.selectedCalendar);
            }
        },
        methods: {
            entityTypeComputed(event) {
                if (this.entityType !== PROPERTY) {
                    return this.entityType;
                }

                if (this.eventInPropertyModule && this.mode === CREATE_PROPERTY_EVENT) {
                    return ENTITY_COMMERCIAL_PROPERTY;
                }

                if (this.eventInPropertyModule) {
                    switch (event.entity_type) {
                        case MODEL_CLASSES.COMMERCIAL_JOB.PROPERTY: {
                            return ENTITY_COMMERCIAL_PROPERTY;
                        }
                        case MODEL_CLASSES.COMMERCIAL_LEAD: {
                             return ENTITY_TYPES.COMMERCIAL_LEAD;
                        }
                        case MODEL_CLASSES.COMMERCIAL_JOB.JOB: {
                            return ENTITY_TYPES.COMMERCIAL_JOB;
                        }
                    }
                }
            },
            startUploadAttachment() {
                this.$refs.uploadEventAttachment.click();
            },
            deleteAttachment(file,index) {
                this.attachments.splice(index, 1);

                // if event allready have attachments
                if (file.id) {
                    this.attachmentsForDelete.push(file.id);
                }

                // delete new uploading attachment
                if (!file.original_name) {
                    let uploadIndex = this.sendAttachments.findIndex(el => el.name == file.name);
                    this.sendAttachments.splice(uploadIndex, 1);
                }
            },
            fileInputHandler(event) {
                if (!this.$refs.uploadEventAttachment.files[0]) return false;
                let file = event.target.files[0];
                this.attachments.push(file);
                this.sendAttachments.push(file);
            },
            onUserSelect(data) {
                this.$nextTick(() => {
                    this.selectedCalendar = this.userCalendarsList.find(c => c.primary) || this.userCalendarsList[0];
                    if (this.selectedCalendar) {
                        this.event.calendar_id = this.selectedCalendar.calendar_id;
                    }
                })
            },
            setCalendarId(data) {
                this.event.calendar_id = data.calendar_id;
            },
            setCalendarType(type) {
                if (this.calendarType == type) {
                    return;
                }
                this.calendarType = type; 
                this.selectedUser = null;
                this.selectedCalendar = null;
            },
            setAddress(data, coords, id) {
                let address = '';
                data.street_number ? address += `${data.street_number} ` : '';
                data.route ? address += `${data.route}, ` : '';
                data.locality ? address += `${data.locality}, ` : '';
                data.administrative_area_level_1 ? address += `${data.administrative_area_level_1}, ` : '';
                data.postal_code  ? address += `${data.postal_code}, ` : '';
                address += 'USA';

                this.event.location.city = data.locality;
                this.event.location.address = address;
                this.event.location.lat = coords.lat;
                this.event.location.lng = coords.lng;
            },
            manualLocationInput(value) {
                this.event.location.address = value;
                this.event.location.city = '';
                this.event.location.lat = null;
                this.event.location.lng = null;
            },
            validateBeforeSubmit() {
                this.$validator.validateAll().then(result => {
                    if (this.$validator.errors.has('event_end_date')) {
                        this.notifyError('End date has to be after the Start date');
                        return;
                    } else if (this.$validator.errors.has('location_address')) {
                        this.notifyError('Location field is required.');
                        return;
                    } else if (!this.isOutlookSynced) {
                        this.notifyError('This Outlook calendar is not synced!');
                        return;
                    } else if (this.isPropertyPoc && this.mode !== UPDATE_FROM_PROPERTY) {
                        this.pocPropertyEventError = true;
                        this.notifyError('Add primary of contact for  property event!');
                        return;
                    } else if (!result) {
                        this.notifyError('Fill in required fields!');
                        return;
                    }

                    let formData = new FormData();

                    if (this.event.summary) {
                        formData.append('summary', this.event.summary);
                    }
                    
                    formData.append('calendar_id', this.event.calendar_id);
                    formData.append('dtstart', this.isEventAllDay ? moment(this.event.dtstart).format('YYYY-MM-DD') : this.event.dtstart);
                    formData.append('dtend', this.isEventAllDay ? moment(this.event.dtend).clone().add(1, 'd').format('YYYY-MM-DD') : this.event.dtend);
                    formData.append('description', getContentWithoutBottomAndTopBrTag(this.event.description));
                    formData.append('entityType', this.entityTypeComputed(this.editableEvent));
                    formData.append('entity_id', this.entityId);

                    if (this.event.location.address !== '') {
                        formData.append('location', this.event.location.address);
                        formData.append('lat', this.event.location.lat);
                        formData.append('lng', this.event.location.lng);
                    }

                    this.mode == CREATE 
                        || this.mode == CREATE_REDISPATCH_EVENT
                        || this.mode == UPDATE_REDISPATCH_EVENT
                        || this.mode == CREATE_PROPERTY_EVENT
                        ? this.saveEvent(formData)
                        : this.updateEvent(formData, this.editableEvent.id);
                });
            },
            addCustomer(data) {
                // add new customer not from CRM
                if (typeof data === 'string') {
                    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data)
                        && !this.attendees.some(u => u.email === data)) {
                        this.attendees.push({
                            email: data,
                            id: null,
                            type: 'user'
                        });
                    } else {
                        this.notifyError('Please enter valid email address');
                    }
                } else {
                    // customer added from existing CRM user
                    this.attendees.push({
                        email: data.email,
                        id: data.id,
                        thumbnail: data.thumbnail,
                        first_name: data.first_name,
                        last_name: data.last_name,
                        type: data.type ? data.type : data.attendee_type
                    });
                }
            },
            saveEvent(params) {
                this.requestIsGoing = true;

                if (this.sendAttachments.length) {
                    this.sendAttachments.forEach(el => {
                        params.append('attachments[]', el);
                    });
                }

                if (this.attendees.length) {
                    this.attendees.forEach((customer, index) => {
                        params.append(`attendees[${index}][attendee_id]`, customer.id ? customer.id : null)
                        params.append(`attendees[${index}][attendee_type]`, customer.type ? customer.type : null)
                        params.append(`attendees[${index}][email]`, customer.email)
                        params.append(
                            `attendees[${index}][cn]`,
                            customer.first_name 
                                ? `${customer.first_name} ${customer.last_name}`
                                : customer.email
                            )
                    });
                }
                // is event all day
                if (this.isEventAllDay) {
                    params.append('all_day', 1);
                }

                params.append('owner_type', this.selectedCalendar.owner_type);
                params.append('owner_id', this.calendarOwnerId);

                if (this.eventInPropertyModule) {
                    params.append('responsible_person[first_name]', this.propertyEntityResponsible.poc);
                    params.append('responsible_person[phone]', this.propertyEntityResponsible.phone.replace(/\D+/g, ''));
                    params.append('responsible_person[email]', this.propertyEntityResponsible.email);
                } else {

                    if (this.entity_responsible) {
                        if (this.entity_responsible.poc) params.append('responsible_person[first_name]', this.entity_responsible.poc);
                        if (this.entity_responsible.email) params.append('responsible_person[email]', this.entity_responsible.email);
                        if (this.entity_responsible.phone) {
                            params.append('responsible_person[phone]', this.entity_responsible.phone.replace(/\D+/g, ''));
                        }
                    }


                }
                

                if (this.mode == CREATE_REDISPATCH_EVENT 
                    || this.mode == UPDATE_REDISPATCH_EVENT) {
                    this.$emit('update', CREATE_REDISPATCH_EVENT, params);
                    return;
                }

                api.post(`calendars/${this.event.calendar_id}/events`, params)
                    .then(response => {
                        this.$emit('update', CREATE, response.data.data);
                        this.notifySuccess('Event is created!');
                        this.$emit('close');
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            },
            updateEvent(params,id) {
                this.requestIsGoing = true;

                if (this.sendAttachments.length) {
                    this.sendAttachments.forEach(el => {
                        params.append('attachments[]', el);
                    });
                }

                if (this.attachmentsForDelete.length) {
                    this.attachmentsForDelete.forEach(el => {
                        params.append('attachments_need_delete[]', el);
                    });
                }

                if (this.attendees.length) {
                    this.attendees.forEach((customer, index) => {
                        params.append(`attendees[${index}][attendee_id]`, customer.attendee_id ? customer.attendee_id : customer.id)
                        params.append(`attendees[${index}][email]`, customer.email)
                        params.append(`attendees[${index}][attendee_type]`, customer.attendee_type ? customer.attendee_type : customer.type)
                        params.append(
                            `attendees[${index}][cn]`,
                            customer.first_name 
                                ? `${customer.first_name} ${customer.last_name}`
                                : customer.email
                            )
                    });
                }

                params.append('all_day',this.isEventAllDay ? 1 : 0);

                params.append('_method','PUT');

                if (this.entity_responsible) {
                    if (this.entity_responsible.poc) params.append('responsible_person[first_name]', this.entity_responsible.poc);
                    if (this.entity_responsible.email) params.append('responsible_person[email]', this.entity_responsible.email);
                    if (this.entity_responsible.phone) {
                        params.append('responsible_person[phone]', this.entity_responsible.phone.replace(/\D+/g, ''));
                    }
                }

                api.post(`/calendars/${this.editableEvent.calendar_id}/events/${id}`, params)
                    .then(response => response.data.data)
                    .then(data => {
                        this.$emit('update', UPDATE, data);
                        this.notifySuccess('Event is updated!');
                        this.$emit('close');
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            },
            getAllCalendarsOwners() {
                api.get(`calendars/owners`)
                    .then(response => response.data.data)
                    .then(data => {
                        this.calendarsOwners = data;
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
            },
            async checkOutlookSync(calendar) {
                this.isOutlookSynced = true;

                if (calendar.provider === CALENDAR_PROVIDERS.OUTLOOK) {
                    let imapConfig = [];
                    let params = {
                        user_id: this.selectedCalendar.owner_id,
                        user_type: this.selectedCalendar.owner_type
                    };

                    await IMAPapi.get(`accounts`, { params })
                        .then(response => {
                            if (response.data.data.length) {
                                imapConfig = response.data.data;
                            } else {
                                this.isOutlookSynced = false;
                                this.notifyError('This Outlook calendar is not synced!');
                                return;
                            }
                        })
                        .catch(error => {
                            this.notifyError(error);
                        })

                    if (imapConfig.length) {
                        await IMAPapi.get(`/calendars/get-sync?account_id=${imapConfig[0]._id}`)
                            .then((response) => {
                                if (!response.data.data.length) {
                                    this.notifyError('This Outlook calendar is not synced!');
                                    this.isOutlookSynced = false;
                                }
                            })
                            .catch(error => {
                                this.notifyError(error);
                            })
                    }
                }
            },
            checkAddedRepresentative(data) {
                return this.representativeForPropertyEvent.find(p => p.id === data.id);
            },
            addRepresentativeForPropertyEvent(person) {
                this.representativeForPropertyEvent.push(person);
                this.openOptionList = false;
                this.pocPropertyEventError = false;
            }
        },
        mounted() {
            this.createTechnicianEvent ? this.calendarType = this.CALENDAR_TYPES.TECHNICIAN : null;
        },
        destroyed() {
            this.$store.commit('calendars/SET_IS_CREATE_TECHNICIAN_CALENDAR', false);
        },
        created() {
            let self = this;

            this.getAllCalendarsOwners();

            // create validation rule for Start date
            this.$validator.extend('eventStartDate', {
                getMessage(field, val) {
                    return 'must be earlier than End date'
                },
                validate(value, field) {
                    if (!self.isEventAllDay) {
                        return !moment(self.event.dtend).isSameOrBefore(moment(self.event.dtstart));
                    } else {
                        let endDate = moment(self.event.dtend).clone().add(1, 'd').format('YYYY-MM-DD');
                        let startDate = moment(self.event.dtstart).format('YYYY-MM-DD');

                        return !moment(endDate).isSameOrBefore(moment(startDate));
                    }
                }
            });

            // create validation rule for End date
            this.$validator.extend('eventEndDate', {
                getMessage(field, val) {
                    return 'must be later than Start date'
                },
                validate(value, field) {
                    if (!self.isEventAllDay) {
                        return !moment(self.event.dtstart).isSameOrAfter(moment(self.event.dtend));
                    } else {
                        let endDate = moment(self.event.dtend).clone().add(1, 'd').format('YYYY-MM-DD');
                        let startDate = moment(self.event.dtstart).format('YYYY-MM-DD');

                        return !moment(startDate).isSameOrAfter(moment(endDate));
                    }
                }
            });

            if (this.mode == UPDATE 
                || this.mode == UPDATE_REDISPATCH_EVENT
                || this.mode === UPDATE_FROM_PROPERTY
                || this.mode === UPDATE_PROPERTY_EVENT) {
                this.event.summary = this.editableEvent.summary;
                this.event.dtstart = this.editableEvent.dtstart;

                if (!+this.editableEvent.all_day) {
                    this.event.dtend = this.editableEvent.dtend;
                } else {
                    this.event.dtend = moment(this.editableEvent.dtend).subtract(1,'days').format('YYYY-MM-DD');
                }

                this.event.description = this.editableEvent.description
                    ? getContentWithoutBottomAndTopBrTag(this.editableEvent.description)
                    : '';
                this.attachments = this.editableEvent.attachments;
                this.attendees.push(...this.editableEvent.attendees);

                if (this.editableEvent.location !== null) {
                    this.event.location.address = this.editableEvent.location;
                    this.event.location.city = '';
                    this.event.location.lat = this.editableEvent.lat;
                    this.event.location.lng = this.editableEvent.lng;
                }

                this.setCalendarId({ calendar_id: this.editableEvent.calendar_id });
                this.selectedCalendar = this.calendarList.find(i => i.calendar_id == this.editableEvent.calendar_id);
                this.selectedUser = this.selectedCalendar.owner;
                this.calendarType = this.selectedCalendar.owner_type;
                this.isEventAllDay = +this.editableEvent.all_day;
            } else {
                if (!this.eventInPropertyModule) {
                    this.event.location = {
                        address: this.address,
                        city: this.preview.city,
                        lat: this.preview.lat,
                        lng: this.preview.lng
                    };
                }

                this.manualLocationInput(this.event.location.address);

                this.calendarType = this.CALENDAR_TYPES.USER;
            }
        }
    }
</script>
