<template>
    <div class="user-calendar-days row-start bt-grey bb-grey">
        <button
            v-if="title"
            class="user-calendar-days-title transparent-button f-13-grey"
            @click="$emit('sortCalendar')"
        >
            {{ title }}
        </button>
        <template v-for="(day, index) in currentPeriod">
            <span
                :class="[
                    'user-calendar-day f-13-grey t-center',
                    checkDay(day) ? 'current' : ''
                ]"
                :key="`${day}_${index}`"
            >
                <p
                    v-if="showDayName"
                    class="f-15-black f-sbold"
                >
                    {{ day | dayName }}
                </p>
                <p class="f-11-grey">{{ day | monthAndDay }}</p>
            </span>
        </template>
    </div>
</template>
<script>
    import moment from 'moment/src/moment';

    export default {
        name: 'CalendarDays',
        props: {
            title: {
                type: String,
                default: ''
            },
            currentPeriod: {
                type: Array,
                default: []
            },
            showDayName: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            checkDay(day) {
                return moment(day).isSame(new Date(), 'day');
            }
        }
    }
</script>