<template>
    <svg 
        viewBox="0 0 16 16"
        :width="size" 
        :height="size"
        class="checkbox-icon"
    >
        <g transform="matrix(0.6571730409518818, 0, 0, 0.6571730409518818, 307.0137557464294, -175.97169479617025)">
            <g>
                <g>
                    <path 
                        class="st0" 
                        d="M-447,278v7l0,0c0,1.1-0.9,2-2,2h-13l0,0c-1.1,0-2-0.9-2-2v-13l0,0c0-1.1,0.9-2,2-2h7 M-446,269.9l-7.7,7.7l-2.8-2.8" 
                        style="stroke-width: 2; stroke-linecap: round; stroke-linejoin: round;"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'CheckboxIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>

<style scoped>
    /* TODO move to css file */
    .checkbox-icon {
        fill-opacity: 0;
    }

</style>

