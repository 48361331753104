import ArrowIcon from './ArrowIcon.vue';
import EnvelopeIcon from './EnvelopeIcon.vue';
import PasswordIcon from './PasswordIcon.vue';
import CloseIcon from './CloseIcon.vue';
import TrashIcon from './TrashIcon.vue';
import AddIcon from './AddIcon.vue';
import GearIcon from './GearIcon.vue';
import IncreaseIcon from './IncreaseIcon.vue';
import MarkIcon from './MarkIcon.vue';
import MapIcon from './MapIcon.vue';
import FilterIcon from './FilterIcon.vue';
import PinIcon from './PinIcon.vue';
import PersonIcon from './PersonIcon.vue';
import CubesIcon from './CubesIcon.vue';
import SearchIcon from './SearchIcon.vue';
import BellIcon from './BellIcon.vue';
import LightningIcon from './LightningIcon.vue';
import DashIcon from './DashIcon.vue';
import DashboardIcon from './DashboardIcon.vue';
import ContactIcon from './ContactIcon.vue';
import BriefcaseIcon from './BriefcaseIcon.vue';
import CalendarIcon from './CalendarIcon.vue';
import MenuIcon from './MenuIcon.vue';
import LogoutIcon from './LogoutIcon.vue';
import NotesIcon from './NotesIcon.vue';
import AttachIcon from './AttachIcon.vue';
import SendIcon from './SendIcon.vue';
import ReplyIcon from './ReplyIcon.vue';
import CameraIcon from './CameraIcon.vue';
import EditIcon from './EditIcon.vue';
import AdminIcon from './AdminIcon.vue';
import LoadMoreIcon from './LoadMoreIcon.vue';
import StopIcon from './StopIcon.vue';
import FolderIcon from './FolderIcon.vue';
import LineIcon from './LineIcon.vue';
import DocumentIcon from './DocumentIcon.vue';
import ZipIcon from './ZipIcon.vue';
import WarningIcon from './WarningIcon.vue';
import VideoIcon from './VideoIcon.vue';
import PointsIcon from './PointsIcon.vue';
import DownloadIcon from './DownloadIcon.vue';
import PointerIcon from './PointerIcon.vue';
import SquarePhotoIcon from './SquarePhotoIcon.vue';
import UploadIcon from './UploadIcon.vue';
import FullUploadIcon from './FullUploadIcon.vue';
import EyeIcon from './EyeIcon.vue';
import LockIcon from './LockIcon.vue';
import PercentIcon from './PercentIcon.vue';
import RotateIcon from './RotateIcon.vue';
import ClockIcon from './ClockIcon.vue';
import LinkIcon from './LinkIcon.vue';
import CopyIcon from './CopyIcon.vue';
import CheckboxIcon from './CheckboxIcon.vue';
import CurrencyIcon from './CurrencyIcon.vue';
import DecimalIcon from './DecimalIcon.vue';
import FileUploadIcon from './FileUploadIcon.vue';
import LongIntegerIcon from './LongIntegerIcon.vue';
import MultilineIcon from './MultilineIcon.vue';
import MultiselectIcon from './MultiselectIcon.vue';
import PhoneIcon from './PhoneIcon.vue';
import PickListIcon from './PickListIcon.vue';
import SingleLineIcon from './SingleLineIcon.vue';
import UrlIcon from './UrlIcon.vue';
import PrintIcon from './PrintIcon.vue';
import FolderAddIcon from './FolderAddIcon.vue';
import PaperIcon from './PaperIcon.vue';
import SignIcon from './SignIcon.vue';
import JobIcon from './JobIcon.vue';
import CardIcon from './CardIcon.vue';
import TagIcon from './TagIcon.vue';
import Loading from './Loading.vue';
import DragIcon from './DragIcon.vue';
import MoveIcon from './MoveIcon.vue';
import SentEmailIcon from './SentEmailIcon.vue';
import InboxEmailIcon from './InboxEmailIcon.vue';
import DraftEmailIcon from './DraftEmailIcon.vue';
import WriteIcon from './WriteIcon.vue';
import PlusIcon from './PlusIcon.vue';
import ResidentialIcon from './ResidentialIcon.vue';
import CommercialIcon from './CommercialIcon.vue';
import AddCustomerIcon from './AddCustomerIcon.vue';
import SettingsIcon from './SettingsIcon.vue';
import InfoIcon from './InfoIcon.vue';
import NextIcon from './NextIcon.vue';
import StarIcon from './StarIcon.vue';
import DollarIcon from './DollarIcon.vue';
import ToolsIcon from './ToolsIcon.vue';
import DoubleArrowIcon from './DoubleArrowIcon.vue';
import PieChartIcon from './PieChartIcon.vue';
import BrokenLightningIcon from './BrokenLightningIcon.vue';
import ColorWheelIcon from './ColorWheelIcon.vue';
import RoundWarningIcon from './RoundWarningIcon.vue';
import InProcessIcon from './InProcessIcon.vue';
import ArchiveIcon from './ArchiveIcon.vue';
import PdfIcon from './PdfIcon.vue';
import TemplateIcon from './TemplateIcon.vue';
import CalculatorIcon from './CalculatorIcon.vue';
import ImagePreviewIcon from './ImagePreviewIcon.vue';
import ProgressIcon from './ProgressIcon.vue';
import TypingIcon from './TypingIcon.vue';
import AddDocumentIcon from './AddDocumentIcon.vue';
import AddNewIcon from './AddNewIcon.vue';
import AddPersonIcon from './AddPersonIcon.vue';
import AgeIcon from './AgeIcon.vue';
import ReportsIcon from './ReportsIcon.vue';
import OutlookLogo from './OutlookLogo.vue';
import CrmLogo from './CrmLogo.vue';
import FlagIcon from './FlagIcon.vue';
import QuestionMark from './QuestionMark.vue';
import RoundPointerIcon from './RoundPointerIcon.vue';
import CropIcon from './CropIcon.vue';
import ResizeIcon from './ResizeIcon.vue';
import DecreaseIcon from './DecreaseIcon.vue';
import CloseRatioReportIcon from './CloseRatioReportIcon.vue';
import LifeBuoyIcon from './LifeBuoyIcon.vue';
import SmartDocIcon from './SmartDocIcon.vue';
import SmartDocPreviewIcon from './SmartDocPreviewIcon.vue';
import LightbulbIcon from './LightbulbIcon.vue';
import SignatureDocIcon from './SignatureDocIcon.vue';
import ChainIcon from './ChainIcon.vue';
import AppIcon from "./AppIcon";
import RedactionIcon from "./RedactionIcon";
import NoTemplateIcon from "./NoTemplateIcon";
import UseTemplateIcon from "./UseTemplateIcon";
import PicturesListEmpty from "./PicturesListEmpty";
import BackIcon from "./BackIcon";
import ForwardIcon from "./ForwardIcon";
import TextLeftIcon from './TextLeftIcon.vue';
import TextCenterIcon from './TextCenterIcon.vue';
import TextRightIcon from './TextRightIcon.vue';
import FontBigIcon from './FontBigIcon.vue';
import FontSmallIcon from './FontSmallIcon.vue';
import NoMessages from './NoMessages.vue';
import TableEmpty from './TableEmpty.vue';
import RoundQuestionIcon from './RoundQuestionIcon.vue';
import EstimatesEmpty from './EstimatesEmpty.vue';
import RotateScreen from './RotateScreen.vue';
import ListEmpty from './ListEmpty.vue';
import EmptyPaperIcon from './EmptyPaperIcon.vue';
import XlsIcon from './XlsIcon.vue';
import ArrowUpIcon from './ArrowUpIcon.vue';
import ManualEmailIcon from './ManualEmailIcon.vue';
import InformationPanel from './InformationPanel.vue';
import SortIcon from './SortIcon.vue';
import MessagesIcon from './MessagesIcon.vue';
import EstimateIcon from './EstimateIcon';
import PackatsIcon from './PackatsIcon';
import OrdersIcon from './OrdersIcon';
import DocumentsIcon from './DocumentsIcon';
import CalendarColorIcon from './CalendarColorIcon';
import ExpiredTasksIcon from './ExpiredTasksIcon';
import MicrosoftLogoIcon from './MicrosoftLogoIcon.vue';
import StormIcon from './StormIcon.vue';
import FillPhoneIcon from './FillPhoneIcon.vue';
import DownIcon from './DownIcon.vue';
import SortCostIcon from './SortCostIcon';
import IncomingCall from './IncomingCall';
import OutgoingCall from './OutgoingCall';
import CallBtn from './CallBtn';
import PauseIcon from './PauseIcon';
import PlayIcon from './PlayIcon';
import CallAgain from './CallAgain';
import OpportunityIcon from './OpportunityIcon';
import FilterIconCenter from './FilterIconCenter';
import DownloadCloudIcon from './DownloadCloudIcon';
import WatchProgressIcon from './WatchProgressIcon';
import DbTableIcon from './DbTableIcon';

export {
    ArrowIcon, EnvelopeIcon, PasswordIcon, CloseIcon, TrashIcon, AddIcon, ForwardIcon,
    GearIcon, IncreaseIcon, MarkIcon, MapIcon, FilterIcon, PinIcon, PersonIcon,
    CubesIcon, SearchIcon, BellIcon, LightningIcon, DashIcon, DashboardIcon, ContactIcon,
    DownIcon, BriefcaseIcon, CalendarIcon, MenuIcon, LogoutIcon, NotesIcon, BackIcon, DocumentsIcon,
    AttachIcon, SendIcon, ReplyIcon, CameraIcon, EditIcon, AdminIcon, LoadMoreIcon,
    StopIcon, FolderIcon, LineIcon, DocumentIcon, ZipIcon, WarningIcon, VideoIcon,
    PointsIcon, DownloadIcon, PointerIcon, SquarePhotoIcon, UploadIcon, EyeIcon, LockIcon,
    PercentIcon, RotateIcon, LinkIcon, CopyIcon, ClockIcon, FullUploadIcon, OrdersIcon,
    CheckboxIcon, CurrencyIcon, DecimalIcon, TableEmpty, MessagesIcon, PackatsIcon,
    FileUploadIcon, LongIntegerIcon, MultilineIcon, MultiselectIcon, EstimateIcon,
    PhoneIcon, PickListIcon, SingleLineIcon, UrlIcon, PrintIcon, FolderAddIcon,
    PaperIcon, SignIcon, JobIcon, CardIcon, TagIcon, Loading, DragIcon, MoveIcon,
    SentEmailIcon, InboxEmailIcon, DraftEmailIcon, WriteIcon, PlusIcon, ResidentialIcon,
    CommercialIcon, AddCustomerIcon, SettingsIcon, InfoIcon, NextIcon, StarIcon,
    DollarIcon, ToolsIcon, DoubleArrowIcon, PieChartIcon, BrokenLightningIcon, CalendarColorIcon,
    ColorWheelIcon, RoundWarningIcon, InProcessIcon, ArchiveIcon, PdfIcon, NoMessages,
    TemplateIcon, CalculatorIcon, ImagePreviewIcon, ProgressIcon, TypingIcon, SortCostIcon,
    AddDocumentIcon, AddNewIcon, AddPersonIcon, AgeIcon, ReportsIcon, OutlookLogo, CrmLogo,
    FlagIcon, QuestionMark, RoundPointerIcon, CropIcon, ResizeIcon, DecreaseIcon, ExpiredTasksIcon,
    CloseRatioReportIcon, LifeBuoyIcon, SmartDocIcon, SmartDocPreviewIcon, LightbulbIcon,
    SignatureDocIcon, ChainIcon, AppIcon, RedactionIcon, NoTemplateIcon, UseTemplateIcon, PicturesListEmpty,
    TextLeftIcon, TextCenterIcon, TextRightIcon, FontBigIcon, FontSmallIcon, RoundQuestionIcon, EstimatesEmpty,
    RotateScreen, ListEmpty, EmptyPaperIcon, XlsIcon, ArrowUpIcon, InformationPanel, ManualEmailIcon, SortIcon,
    MicrosoftLogoIcon, StormIcon, FillPhoneIcon, IncomingCall, OutgoingCall, CallBtn, OpportunityIcon,
    PauseIcon, PlayIcon, CallAgain, FilterIconCenter, DownloadCloudIcon, WatchProgressIcon, DbTableIcon
}
