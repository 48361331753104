<template>
    <div class="w-box order-item">
        <div class="row-between s-align-1">
            <div :class="['nb-12 md-12 sm-12', open ? 'lg-8' : 'lg-12']">
                <div class="column-start w-100">
                    <div class="row-between">
                        <div class="s-align-4 sm-12 order-item-block">
                            <button 
                                class="transparent-button order-item-block-btn"
                                @click="open = !open"
                            >
                                <arrow-icon 
                                    class="i-darkgrey mr-6 order-item-block-btn-svg"
                                    :transform="open ? 270 : 90"
                                />
                                <p class="f-16-black f-sbold ellipsis">{{ `${order.name}` }}</p>
                            </button>
                        </div>
                        <div :class="['sm-12 mt-sm-1', mScreen ? 'column-start pl-20' : 's-align-6']">
                            <p class="f-12-grey pr-8 sm-12">
                                Order #: 
                                <span class="f-12-black">{{ orderNumber }}</span>
                            </p>
                        </div>
                    </div>
                    <div 
                        class="pt-20 hidden w-100"
                        v-show="open"
                    >
                        <perfect-scrollbar class="ps-grey w-100 pb-8">
                            <order-milestones 
                                :entityType="entityType"
                                :orderId="order.id"
                                :milestonesProps="order.statuses"
                                :hasMaterial="!!order.has_material"
                                :hasLabor="!!order.has_labor"
                                :existMaterial="order.exist_material"
                                :existLabor="order.exist_labor"
                                :orderStatus="order.status"
                                :vendor="order.vendor"
                                :crew="order.crew"
                            />
                        </perfect-scrollbar>
                    </div>
                </div>
            </div>
            <div 
                :class="['order-item-summary column-start lg-4 nb-12 md-12 sm-12']"
                v-show="open"
            >
                <div class="row-between pb-30">
                    <p class="f-16-black f-sbold">Summary</p>
                    <context-menu
                        :unicId="`order-menu-${index}`"
                        width="200"
                        btnStyle="icon-dark"
                    >
                        <router-link
                            v-if="!orderSaved"
                            class="s-align-4"
                            :to="{ name: orderCreateRoute, params: { order_id: order.id } }"
                            v-can="[$permissions.EDIT_ORDER]"
                        >
                            <span class="icon-container s-align-5 mr-6">
                                <edit-icon/>
                            </span>
                            <span>Edit Order</span>
                        </router-link>
                        <button 
                            class="s-align-4"
                            @click="$emit('editOverallProgress', index)"
                            v-can="[$permissions.EDIT_ORDER]"
                        >
                            <span class="icon-container s-align-5 mr-6">
                                <progress-icon/>
                            </span>
                            <span>Edit overall progress</span>
                        </button>
                        <button 
                            class="s-align-4"
                            @click="$emit('viewLog', index)"
                        >
                            <span class="icon-container s-align-5 mr-6">
                                <paper-icon size="13"/>
                            </span>
                            <span>View log</span>
                        </button>
                        <button 
                            class="s-align-4"
                            @click="$emit('setProductionNote', index)"
                        >
                            <span class="icon-container s-align-5 mr-6">
                                <typing-icon/>
                            </span>
                            <span>Set production note</span>
                        </button>
                        <!-- <router-link
                            class="s-align-4"
                            :to="{ 
                                name: 'OrderPreview', 
                                params: { lead_id: leadId, order_id: order.id }
                            }"
                        >
                            <span class="icon-container s-align-5 mr-6">
                                <eye-icon size="17"/>
                            </span>
                            <span>Preview</span>
                        </router-link> -->
                        <button
                            v-if="!orderSaved" 
                            class="s-align-4"
                            @click="$emit('deleteOrder', index)"
                            v-can="[$permissions.DELETE_ORDER]"
                        >
                            <span class="icon-container s-align-5 mr-6">
                                <trash-icon
                                    width="14"
                                    height="16"
                                />
                            </span>
                            <span>Delete</span>
                        </button>
                    </context-menu>
                </div>
                <div class="column-start w-100">
                    <div class="pb-20">
                        <p class="f-28-asphalt f-sbold">{{ order.total | currency }}</p>
                        <p class="f-16-grey f-sbold">Order Total</p>
                    </div>
                    <div class="row-between">
                        <div
                            v-if="order.has_material" 
                            class="summary-material-wrapper lg-12 nb-6 md-6 sm-12"
                        >
                            <div class="column-start grey-box w-100">
                                <div class="row-between row-baseline">
                                    <p class="f-14-darkgrey">Material</p>
                                    <context-menu
                                        :unicId="`material-menu-${index}`"
                                        width="200"
                                    >
                                        <router-link
                                            v-if="!orderSaved" 
                                            class="s-align-4"
                                            :to="{ name: orderCreateRoute, 
                                                params: { order_id: order.id },
                                                query: { tab: 'material' }
                                            }"
                                        >
                                            <span class="icon-container mr-6">
                                                <edit-icon/>
                                            </span>
                                            <span>Edit material</span>
                                        </router-link>
                                        <button class="s-align-4 in-dev">
                                            <span class="icon-container mr-6">
                                                <pointer-icon size="12"/>
                                            </span>
                                            <span>Send order request</span>
                                        </button>
                                        <button class="s-align-4 in-dev">
                                            <span class="icon-container mr-6">
                                                <print-icon size="14"/>
                                            </span>
                                            <span>Print material</span>
                                        </button>
                                    </context-menu>
                                </div>
                                <p class="f-24-blue f-sbold">{{ order.material_total | currency }}</p>
                            </div>
                        </div>
                        <div
                            v-if="order.has_labor" 
                            class="summary-labor-wrapper lg-12 nb-6 md-6 sm-12"
                        >
                            <div class="column-start grey-box w-100">
                                <div class="row-between row-baseline">
                                    <p class="f-14-darkgrey">Labor</p>
                                    <context-menu
                                        :unicId="`labor-menu-${index}`"
                                        width="200"
                                    >
                                        <router-link
                                            v-if="!orderSaved" 
                                            class="s-align-4"
                                            :to="{ name: orderCreateRoute, 
                                                params: { order_id: order.id },
                                                query: { tab: 'labor' }
                                            }"
                                        >
                                            <span class="icon-container mr-6">
                                                <edit-icon/>
                                            </span>
                                            <span>Edit labor</span>
                                        </router-link>
                                        <button class="s-align-4 in-dev">
                                            <span class="icon-container mr-6">
                                                <print-icon size="14"/>
                                            </span>
                                            <span>Print labor</span>
                                        </button>
                                    </context-menu>
                                </div>
                                <p class="f-24-blue f-sbold">{{ order.labor_total | currency }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div 
            class="column-start w-100 pt-8"
            v-show="open && order.comment"
        >
            <p class="f-12-black f-sbold pb-1">Comments</p>
            <span class="f-12-grey preserve-whitespaces">
                {{ order.comment }}
            </span>
        </div>
    </div>
</template>

<script>
    import OrderMilestones from '@/components/LeadModules/OrderMilestones.vue';
    import { 
        ArrowIcon, EditIcon, ProgressIcon, PaperIcon, 
        TypingIcon, TrashIcon, PrintIcon, PointerIcon,
        EyeIcon
    } from '@/icons';
    import { ENTITY_TYPES, ORDER_STATUSES } from '@/constants.js';
    import ContextMenu from '@/components/ContextMenu.vue';
    

    export default {
        name: 'OrderInfo',
        props: {
            order: {
                type: Object,
                required: true
            },
            index: {
                type: [String, Number],
                required: true
            },
            entityType: {
                type: String,
                required: true
            }
        },
        components: {
            ArrowIcon,
            ContextMenu,
            EditIcon,
            ProgressIcon,
            PaperIcon,
            TypingIcon,
            TrashIcon,
            PrintIcon,
            PointerIcon,
            OrderMilestones,
            EyeIcon
        },
        data() {
            return {
                open: false,
            }
        },
        computed: {
            leadId() {
                return this.$route.params.lead_id;
            },
            orderNumber() {
                let jobNumber;
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD:
                        jobNumber = this.$store.getters['lead/getLeadPreview'].job_number;
                        return jobNumber ? `${jobNumber}-${this.order.order_number}` : this.order.id;
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        jobNumber = this.$store.getters['commercial/getCommercialJobPreview'].job_number;
                        return jobNumber ? `${jobNumber}-${this.order.order_number}` : this.order.id;
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.order.id;
                    case ENTITY_TYPES.WARRANTY_JOB:
                        return this.order.id;
                }
            },
            orderCreateRoute() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: 
                        return 'ResidentialOrderCreate';
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return 'CommercialJobOrderCreate';
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return 'CommercialLeadOrderCreate';
                    case ENTITY_TYPES.WARRANTY_JOB:
                        return 'WarrantyJobOrderCreate';
                }
            },
            orderSaved() {
                return this.order.status === ORDER_STATUSES.SAVE;
            }
        }
    }
</script>