<template>
    <div 
        :style="popupPosition"
        :class="[
            'calendar-event-popup',
            showScheduler && event.is_material && 'large-size',
            popupOffset.offsetLeft > popupOffset.offsetRight
                ? 'calendar-event-popup-triangle-right' 
                : 'calendar-event-popup-triangle-left',
            popupOffset.offsetTop < popupOffset.offsetBottom 
                ? 'calendar-event-popup-triangle-top' 
                : 'calendar-event-popup-triangle-bottom',
        ]"
    >
        <event-edit
            v-if="isEdit"
            :event="event"
            :calendarList="calendarList"
            :calendarType="calendarType"
            :reAssignMode='true'
            @cancelEdit="cancelEdit"
            @deleteEvent="deleteEvent"
            @onUpdate="onUpdate"
            @updateCachedEvent="updateCachedEvent"
        />
        <template v-else>
            <div v-if="singleEventLoaded">
                <div class="ph-20 pt-20">
                    <div class="row-between flex-no-wrap">
                        <p class="f-18-black">
                            {{ getEventName(event) }}
                            <span class="f-sbold" v-if="calendarType == CALENDAR_TYPES.JOB">
                                {{ event.is_labor ? 'Labor settings' : 'Material settings'}}
                            </span>
                        </p>
                        <button 
                            class="icon-light"
                            @click="$emit('closeEventPopup')"
                        >
                            <close-icon size="14"/>
                        </button>
                    </div>
                    <div class="row-start mt-7">
                        <template v-for="(tag, index) in event.tags">
                            <span 
                                :key="index"
                                :class="[
                                    'event-tag mr-3 mb-3',
                                    tag.type === 'city' ? 'event-tag-violet' : '',
                                    tag.type === 'surname' ? 'event-tag-orange' : '',
                                    tag.type === 'lead_type' ? 'event-tag-green' : '',
                                ]"
                            >
                                {{ tag.label }}
                            </span>
                        </template>
                    </div>
                </div>
                <div
                    v-if="hideScheduler"
                    class="tabs-menu bb-grey"
                >
                    <div class="tabs-menu-panel row-start">
                        <button
                            v-for="(tab, index) in TABS"
                            v-show="tab.active"
                            :key="index"                    
                            :class="[
                                'tabs-menu-item h-100 transparent-button',
                                { 'tabs-menu-item-active' : activeTab === tab.title}
                            ]"
                            @click="activeTab = tab.title"
                        >
                            {{ tab.title }}
                        </button>
                    </div>
                </div>
                <div
                    v-if="activeTab === TABS.INFORMATION.title && hideScheduler"
                    class="p-20"
                >
                    <div 
                        v-if="event.entity"
                        class="row-center mb-4"
                        v-can:some="[$permissions.VIEW_MY_LEADS, $permissions.VIEW_ALL_LEADS]"
                    >
                        <div class="static-col-1">
                            <briefcase-icon
                                size="17"
                                class="i-grey"
                            />
                        </div>
                        <div class="static-col-11 column-start">
                          <template v-if="event.entity.type === ENTITY_TYPES.COMMERCIAL_PROPERTY">
                            <span class="f-13-black">Commercial Property</span>
                            <router-link
                                class="f-13-blue"
                                :to="{
                                    name: 'CommercialPropertyWithoutCustomer',
                                    params: { property_id: event.entity.property_id }
                                }"
                            >
                              {{ event.entity.property }}
                            </router-link>
                          </template>
                            <template v-if="event.entity.type === ENTITY_TYPES.RESIDENTIAL_LEAD">
                                <span class="f-13-black">Residential</span>
                                <router-link
                                    class="f-13-blue" 
                                    :to="{ name: 'Overview', params: { lead_id: event.entity.id }}"
                                >
                                    {{ event.entity.customer }} 
                                </router-link>
                            </template>
                            <template v-if="event.entity.type === ENTITY_TYPES.COMMERCIAL_LEAD">
                                <span class="f-13-black">Commercial Lead</span>
                                <router-link
                                    v-if="event.entity.type === ENTITY_TYPES.COMMERCIAL_LEAD"
                                    class="f-13-blue" 
                                    :to="{ name: 'CommercialLeadOverview', params: { lead_id: event.entity.id }}"
                                >
                                    {{ event.entity.property }} 
                                </router-link>
                            </template>
                            <template v-if="event.entity.type === ENTITY_TYPES.COMMERCIAL_JOB">
                                <span class="f-13-black">Commercial Job</span>
                                <router-link
                                    v-if="event.entity.type === ENTITY_TYPES.COMMERCIAL_JOB"
                                    class="f-13-blue" 
                                    :to="{ name: 'CommercialJobOverview',
                                        params: { 
                                            job_id: event.entity.id,
                                            property_id: event.entity.property_id
                                        }
                                    }"
                                >
                                    {{ `${event.entity.property}, ${event.entity.job_number}` }} 
                                </router-link>
                            </template>
                        </div>
                    </div>
                    <div class="row-start mb-4">
                        <div class="static-col-1">
                            <clock-icon
                                class="i-grey"
                                size="17"
                            />
                        </div>
                        <div 
                            v-if="!+event.all_day"
                            class="static-col-11 column-start"
                        >
                            <span class="f-13-black">
                                {{ event.dtstart | time }}
                                -
                                {{ event.dtend | time }}
                            </span>
                            <span class="f-13-grey">
                                {{ event.dtstart | date }}
                                -
                                {{ event.dtend | date }}
                            </span>
                        </div>
                        <div 
                            v-else
                            class="static-col-11 column-start"
                        >
                            <span class="f-13-black">
                                All day
                            </span>
                            <span
                                v-if="ifOneDay(event.dtstart,event.dtend)"
                                class="f-13-grey"
                            >
                                {{ event.dtstart | date_ignore_tz }}
                            </span>
                            <span 
                                v-else
                                class="f-13-grey"
                            >
                                {{ event.dtstart | date_ignore_tz }} - {{ allDayEndDate(event.dtend) | date_ignore_tz }}
                            </span>
                        </div>
                    </div>
                    <div 
                        v-if="event.location !== null"
                        class="row-start mb-4"
                    >
                        <div class="static-col-1">
                            <map-icon
                                size="17"
                                class="i-grey"
                            />
                        </div>
                        <div 
                            class="static-col-11 f-13-black" 
                            v-html="parseLink(event.location)"
                        >
                        </div>
                    </div>
                    <div
                        v-if="event.responsible && event.responsible.first_name"
                        class="row-start mb-4"
                    >
                        <div class="lg-1">
                            <person-icon
                                class="i-grey"
                                size="17"
                            />
                        </div>
                        <div class="lg-11 f-13-black">
                            {{ event.responsible.first_name }}
                        </div>
                    </div>
                    <div
                        v-if="event.responsible && event.responsible.phone"
                        class="row-start mb-4"
                    >
                        <div class="lg-1">
                            <phone-icon
                                class="i-grey"
                                size="17"
                            />
                        </div>
                        <div class="lg-11 f-13-black">
                            {{ event.responsible.phone | phone }}
                        </div>
                    </div>
                    <div
                        v-if="event.responsible && event.responsible.email"
                        class="row-start mb-4"
                    >
                        <div class="lg-1">
                            <envelope-icon
                                class="i-grey"
                                size="17"
                            />
                        </div>
                        <div class="lg-11 f-13-black">
                            {{ event.responsible.email }}
                        </div>
                    </div>
                    <div 
                        v-if="event.notes || event.description"
                        class="column-start"
                    >
                        <span class="f-13-darkgrey f-sbold mb-1">Notes</span>
                        <router-link
                            v-if="event.notes"
                            class="f-12-blue" 
                            :to="{ 
                                name: 'ProjectManagement', 
                                query: { 
                                    board_id: event.notes.board_id,
                                    card: event.notes.card_slug
                                } 
                            }"
                        >
                            Link to card
                        </router-link>
                        <span
                            v-if="event.description" 
                            class="event-notes list-styled hidden f-12-darkgrey break-word"
                            v-html="event.description"
                            ref="notes"
                        />
                        <button
                            v-if="notesTextOverflow"
                            class="transparent-button f-12-blue"
                            @click="fullNotesModalShown = true"
                        >
                            Read more
                        </button>
                    </div>
                </div>
                <template v-if="activeTab === TABS.WORK_DESCRIPTION.title && calendarType !== CALENDAR_TYPES.CREW">
                    <div
                        v-if="event.attendees && event.attendees.length"
                        class="row-start p-20 bb-grey"
                    >
                        <div class="lg-1">
                            <person-icon
                                size="15"
                                class="i-grey"
                            />
                        </div>
                        <div class="lg-11 column-start">
                            <div
                                v-for="(customer, index) in allUsersShown ? event.attendees : event.attendees.slice(0,2)"
                                :key="index"
                                class="row-start row-baseline mb-2"
                            >
                                <div class="lg-1">
                                    <avatar
                                        :name="getName(customer)"
                                        :border="false"
                                        size="23"
                                        :url="customer.thumbnail"
                                    />
                                </div>
                                <div class="lg-7 column-start">
                                    <span class="f-12-black">
                                        {{ customer.fullname }}
                                    </span>
                                </div>
                                <div
                                    v-if="customer.phone"
                                    class="lg-4 s-align-6"
                                >
                                    <phone-icon size="12"/>
                                    <span class="f-11-black ml-1">
                                        {{ customer.phone | phone }}
                                    </span>
                                </div>
                            </div>
                            <button
                                v-if="event.attendees && event.attendees.length > 2"
                                class="transparent-button mt-1"
                                @click="allUsersShown = !allUsersShown"
                            >
                                <arrow-icon
                                    class="i-blue mr-1"
                                    :transform="allUsersShown ? 270 : 90"
                                />
                                <span class="f-12-blue mr-2">View all users</span>
                                <span class="f-12-grey">
                                    (+{{ event.attendees.length - 2 }})
                                </span>
                            </button>
                        </div>
                    </div>
                    <div
                        v-if="attachments && attachments.length"
                        class="p-20"
                    >
                        <div class="row-between row-baseline mb-6">
                            <div class="s-align-4">
                                <attach-icon
                                    :size="15" 
                                    class="i-grey mr-2"
                                />
                                <span class="f-12-black f-sbold">Attachments</span>
                            </div>
                            <button
                                class="transparent-button f-12-blue"
                                @click="downloadAllAttachment"
                            >
                                <download-icon
                                    class="i-blue mr-2"
                                    size="13"
                                />
                                Download all files
                            </button>
                        </div>
                        <file
                            v-for="(attachment, index) in attachments.slice(0, 2)"
                            :key="index"
                            :file="attachment.files[0]"
                            :showDownloadBtn="true"
                        />
                        <button
                            v-if="attachments && attachments.length > 2"
                            class="transparent-button mt-6"
                            @click="allFilesModalShown = true"
                        >
                            <span class="f-12-blue mr-2">View all attachments</span>
                            <span class="f-12-grey">
                                (+{{ attachments.length - 2 }})
                            </span>
                        </button>
                    </div>
                    <div
                        v-if="event.work_description
                            && (event.work_description.scope_of_work || event.work_description.materials)"
                        class="p-20"
                    >
                        <template v-if="event.work_description && event.work_description.scope_of_work">
                            <p class="f-13-darkgrey f-sbold mb-1">Work description</p>
                            <p
                                class="hidden f-12-darkgrey break-word mb-20"
                                v-html="event.work_description.scope_of_work"
                            />
                        </template>
                        <template v-if="event.work_description && event.work_description.materials">
                            <p class="f-13-darkgrey f-sbold mb-1">Materials</p>
                            <p
                                class="hidden f-12-darkgrey break-word"
                                v-html="event.work_description.materials"
                            />
                        </template>
                    </div>
                </template>
                <div
                    v-if="event.parent_event_id || event.parent_id && !isPastEvent(event)"
                    class="p-20 f-14-black"
                >
                    To edit or delete an event contact 
                    <span class="f-sbold underline">{{ event.main_assignee_fullname }}</span>
                    or responsible manager.
                </div>
                <div
                    v-else-if="isShowCrewEditForm(event)"
                    class="row-between ph-20 pv-8 bt-grey"
                >
                    <tooltip 
                        direction="right"
                        color="white"
                        :fixedDirection="true"
                        :isShow="isDeleteTooltipShow"
                    >
                        <button
                            v-if="!!event.can_edit && showDeleteButton"
                            class="transparent-button s-align-4"
                            @click="isDeleteTooltipShow = !isDeleteTooltipShow"
                        >
                            <trash-icon class="i-red mr-1"/>
                            <span class="f-12-red f-sbold">Delete</span>
                        </button>
                        <template v-slot:content>
                            <p class="f-14-black f-bold t-center mb-6">
                                Are you sure to want to delete this event?
                            </p>
                            <div class="row-between">
                                <button 
                                    class="add-button"
                                    @click="isDeleteTooltipShow = false"
                                >
                                    No
                                </button>
                                <button 
                                    class="red-button"
                                    @click="deleteEvent()"
                                    :disabled="requestIsGoing"
                                >
                                    Yes
                                </button>
                            </div>
                        </template>
                    </tooltip>
                    <button
                        v-if="!!event.can_edit && showEditButton"
                        class="transparent-button f-12-blue f-sbold"
                        @click="isEdit = true"
                    >
                        Edit
                    </button>
                </div>
                <div
                    v-if="showScheduler"
                    class="column-start w-100"
                >
                    <template v-if="event.is_labor">
                        <div class="s-align-4 ph-20 pb-20 w-100">
                            <div class="s-align-4 w-100">
                                <calendar-icon
                                    class="i-grey mr-4"
                                    size="14"
                                />
                                <p class="f-13-darkgrey no-wrap">
                                    Select a delivery date and time
                                </p>
                            </div>
                            <div class="row-end flex-no-wrap">
                                <checkbox-button color="blue">
                                    <input
                                        type="checkbox"
                                        v-model="event.labor_all_day_working"
                                        id="show_all_crew"
                                        name="show_all_crew"
                                    />
                                </checkbox-button>
                                <span class="f-12-darkgrey ml-3">
                                    All day
                                </span>
                            </div>
                        </div>
                    </template>
                    <template v-if="event.is_material">
                        <div class="lg-6 md-12 sm-12 column-start w-100 ph-20">
                            <div class="row-start row-baseline">
                                <div class="lg-6 sm-12 pr-4 pr-sm-0 mb-sm-1">
                                    <form-datepicker
                                        :class="{ 'form-datepicker-error' : errors.has('delivery_date') }"
                                        :isFullWidth="true"
                                        v-model="event.delivery_date"
                                        name="delivery_date"
                                        keyDatePicker="delivery_date_picker"
                                        v-validate="'required'"
                                    />
                                </div>
                                <div class="lg-6 sm-12 s-align-5">
                                    <clock-icon
                                        class="i-blue mr-8"
                                        size="15"
                                    />
                                    <radio-switch
                                        v-for="(item, index) in DELIVERY_TIME"
                                        :key="`delivery-time-${index}`"
                                        :label="item"
                                        color="blue"
                                        :width="60"
                                        :class="{ 'radio-switch-error' : errors.has('delivery-time') }"
                                    >
                                        <input
                                            type="radio"
                                            name="delivery-time"
                                            :value="item"
                                            v-model="event.delivery_time"
                                        />
                                    </radio-switch>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="event.is_labor">
                        <div class="row-start row-baseline mb-2 ph-20">
                            <div class="lg-4">
                                <span class="f-12-black f-sbold">Start</span>
                            </div>
                            <div class="lg-8">
                                <form-date-time-input
                                    v-if="!event.labor_all_day_working"
                                    v-model="dStart"
                                    v-validate="'eventStartDate'"
                                    name="event_start_date"
                                    placeholder="Start Date"
                                    size="small"
                                    :isFullWidth="true"
                                    :class="{ 'form-date-time-input-error' : errors.has('event_start_date') }"
                                />
                                <form-datepicker
                                    v-else
                                    v-model="dStart"
                                    v-validate="'eventStartDate'"
                                    name="event_start_date"
                                    keyDatePicker="event_start_date_picker"
                                    placeholder="Start Date"
                                    size="small"
                                    :isFullWidth="true"
                                    :class="{ 'form-datepicker-error' : errors.has('event_start_date') }"
                                />
                            </div>
                        </div>
                        <div class="row-start row-baseline mb-2 ph-20">
                            <div class="lg-4">
                                <span class="f-12-black f-sbold">End</span>
                            </div>
                            <div class="lg-8">
                                <form-date-time-input
                                    v-if="!event.labor_all_day_working"
                                    v-model="dEnd"
                                    v-validate="'eventEndDate'"
                                    name="event_end_date"
                                    placeholder="End Date"
                                    size="small"
                                    :isFullWidth="true"
                                    :class="{ 'form-date-time-input-error' : errors.has('event_end_date') }"
                                />
                                <form-datepicker
                                    v-else
                                    v-model="dEnd"
                                    v-validate="'eventEndDate'"
                                    name="event_end_date"
                                    keyDatePicker="event_end_date_picker"
                                    placeholder="End Date"
                                    size="small"
                                    :isFullWidth="true"
                                    :class="{ 'form-datepicker-error' : errors.has('event_end_date') }"
                                />
                            </div>
                        </div>
                    </template>
                    <div
                        class="w-100 s-align-6 ph-20 pb-20 pt-20 pointer row-between flex-no-wrap"
                        @click="informChangeDatesModalOpen = true"
                    >
                        <div class="row-start s-align-4 flex-no-wrap">
                            <information-panel size="20" />
                            <div class="f-12-black ml-2 no-wrap">
                                Notify crew about time changes
                            </div>
                        </div>
                        <div class="row-end">
                            <envelope-icon
                                size="20"
                                class="i-blue mr-2"
                            />
                            <div class="pointer f-12-blue pr-10 no-wrap">Send email</div>
                        </div>
                    </div>
                    <div class="row-between ph-20 pv-8 bt-grey s-align-6">
                        <div class="s-align-6">
                            <button
                                class="transparent-button f-12-grey f-sbold"
                                @click="$emit('closeEventPopup')"
                            >
                                Cancel
                            </button>
                            <button
                                class="transparent-button f-sbold ml-8 f-12-blue"
                                :disabled="errors.has('event_start_date') || errors.has('event_end_date')"
                                @click="updateOrder"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <loader
                v-else
                size="small"
            />
        </template>
        <view-full-note-modal
            v-if="fullNotesModalShown"
            :note="event.description"
            @close="fullNotesModalShown = false"
        />
        <view-all-files-modal
            v-if="allFilesModalShown"
            :files="attachments"
            @downloadAllAttachment="downloadAllAttachment"
            @close="allFilesModalShown = false"
        />
        <send-new-email-modal
            v-if="informChangeDatesModalOpen"
            :account_id="outlook_id"
            :entity-type="event.entity_type"
            :is-template-show="false"
            :event="event"
            inform-change-dates
            @sendInformChangeDates="sendInformDates"
            @close="informChangeDatesModalOpen = false"
            v-body-scroll-lock:reserve-scroll-bar-gap="false"
            title="Inform to change the dates"
        />
    </div>
</template>

<script>
    import api from '@/headers.js';
    import {
        CloseIcon, MapIcon, ClockIcon, TrashIcon, BriefcaseIcon, DownloadIcon, AttachIcon, PersonIcon,
        PhoneIcon, EnvelopeIcon, ArrowIcon, CalendarIcon, InformationPanel
    } from '@/icons';
    import { 
        Tooltip, CheckboxButton, FormDateTimeInput, FormDatepicker, RadioSwitch,
        Loader  
    } from '@/ui';
    import File from '@/ui/File.vue';
    import { Avatar, EventEdit } from '@/components';
    import { ENTITY_TYPES, CALENDAR_TYPES, DELIVERY_TIME } from '@/constants.js';
    import { ViewFullNoteModal, ViewAllFilesModal } from '@/modals';
    import moment from 'moment/src/moment';
    const SINGLE_INSTANCE = 'singleInstance';

    export default {
        name: 'EventViewPopup',
        components: {
            InformationPanel,
            SendNewEmailModal: () => import('@/modals/SendNewEmailModal.vue'),
            RadioSwitch,
            FormDatepicker,
            FormDateTimeInput,
            CheckboxButton,
            CalendarIcon,
            CloseIcon,
            MapIcon,
            ClockIcon,
            TrashIcon,
            BriefcaseIcon,
            DownloadIcon,
            AttachIcon,
            Avatar,
            EventEdit,
            Tooltip,
            File,
            ViewFullNoteModal,
            ViewAllFilesModal,
            PersonIcon,
            PhoneIcon,
            EnvelopeIcon,
            ArrowIcon,
            Loader
        },
        props: {
            popupOffset: {
                type: Object,
                required: true
            },
            eventPreview: {
                type: Object,
                required: true
            },
            calendarList: {
                type: Array,
                required: false
            },
            calendarType: {
                type: String,
                default: CALENDAR_TYPES.USER
            },
            isEditable: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                CALENDAR_TYPES,
                ENTITY_TYPES: ENTITY_TYPES,
                isEdit: false,
                requestIsGoing: false,
                isDeleteTooltipShow: false,
                singleInstance: SINGLE_INSTANCE,
                attachments: [],
                url: process.env.VUE_APP_AMAZONAWS_URL,
                previewModalOpened: false,
                selectedFileToPreview: null,
                activeTab: '',
                notesTextOverflow: false,
                fullNotesModalShown: false,
                allFilesModalShown: false,
                allUsersShown: false,
                dStart: '',
                dEnd: '',
                informChangeDatesModalOpen: false,
                outlook_id: null,
                DELIVERY_TIME,
                event: null,
                singleEventLoaded: false
            }
        },
        watch: {
            event: function() {
                this.isEdit = false;
                this.activeTab = this.TABS.INFORMATION.title;
                this.attachments = this.event.attachments;

                if (this.event.is_labor) {

                    const dStart = moment(this.event.labor_delivery_date_from).format('YYYY-MM-DD');
                    const dEnd = moment(this.event.labor_delivery_date_to).format('YYYY-MM-DD');

                    this.dStart = this.event.labor_delivery_time_from
                        ? dStart + ' ' + this.event.labor_delivery_time_from
                        : dStart;
                    this.dEnd = this.event.labor_delivery_time_to
                        ? dEnd + ' ' + this.event.labor_delivery_time_to
                        : dEnd;
                }
            },
            dStart: function() {
                if (this.showScheduler && this.event && this.event.is_labor) {
                    this.$validator.validate('event_end_date');
                }
            },
            dEnd: function() {
                if (this.showScheduler && this.event && this.event.is_labor) {
                    this.$validator.validate('event_start_date');
                }

                if (moment(this.dEnd).isSameOrBefore(moment(this.dStart)) && !this.event.labor_all_day_working) {
                    this.notifyError('End date has to be after the Start date');
                }
            },
            "eventPreview.id": function() {
                this.singleEventLoaded = false;
                this.getSingleEvent();
            }
        },
        computed: {
            hideScheduler() {
                return this.calendarType !== CALENDAR_TYPES.CREW
                    && this.calendarType !== CALENDAR_TYPES.JOB;
            },
            showScheduler() {
                return this.calendarType === CALENDAR_TYPES.CREW
                    || this.calendarType === CALENDAR_TYPES.JOB;
            },
            imapConfig() {
                return this.$store.getters['imap/getImapConfig'];
            },
            isImapConfigLoaded() {
                return this.$store.getters['imap/isImapConfigLoaded'];
            },
            loggedInUserId() {
                return this.$store.getters['user/getUserId'];
            },
            popupPosition: function() {
                let css = '';

                if (this.popupOffset.offsetLeft > this.popupOffset.offsetRight) {
                    css = `right: ${this.popupOffset.offsetRight}px;`
                } else {
                    css =  `left: ${this.popupOffset.offsetLeft}px;`
                }

                if (this.popupOffset.offsetTop < this.popupOffset.offsetBottom) {
                    css += `top: ${this.popupOffset.offsetTop}px;`
                } else {
                    css += `bottom: ${this.popupOffset.offsetBottom}px;`
                }

                return css;
            },
            informChangeDates() {
              return this.$store.getters['scheduler/getInformChangeDates'];
            },
            TABS: function() {
                return {
                    INFORMATION: { title: 'Information', active: true },
                    WORK_DESCRIPTION: {
                        title: 'Work description',
                        active: this.attachments && this.attachments.length
                            || (this.event.work_description && this.event.work_description.scope_of_work)
                            || (this.event.work_description && this.event.work_description.materials)
                            || this.event.attendees && this.event.attendees.length
                    }
                }
            },
            showEditButton() {
                return this.$can([this.$permissions.EDIT_EVENT_OTHERS])
                    || this.$can([this.$permissions.VIEW_MY_CALENDAR]);
            },
            showDeleteButton() {
                return this.$can([this.$permissions.DELETE_EVENT_FOR_OTHERS]) 
                    || this.$can([this.$permissions.VIEW_MY_CALENDAR]);
            }
        },
        methods: {
            getSingleEvent() {
                if (!this.eventPreview.parent_event_id) {
                    return api.get(`/calendars/${this.eventPreview.calendar_id}/events/${this.eventPreview.id}`)
                        .then(response => response.data.data)
                        .then(data => {
                            this.event = data;
                            this.singleEventLoaded = true;
                        })
                        .catch(error => this.notifyRequestErrors(error));
                } else {
                    this.event = this.eventPreview;
                    this.singleEventLoaded = true;
                }
            },
            getEventName(event) {
                if (this.calendarType !== CALENDAR_TYPES.CREW && event.summary) {
                    return event.summary;
                }

                if (event.name) {
                    return event.name;
                }

                return 'No subject';
            },
            getName(customer) {
                return customer.first_name && customer.last_name
                    ? customer.first_name + ' ' + customer.last_name
                    : customer.email;
            },
            getImapConfig() {
                if (!this.isImapConfigLoaded) {
                    this.$store.dispatch('imap/GET_IMAP_ACCOUNTS', this.loggedInUserId);
                    this.$watch('isImapConfigLoaded', this.getImapConfig);
                    return;
                }

                this.outlook_id = this.imapConfig.length ? this.imapConfig[0]._id : null;
            },
            clearInformChangeDates() {
                this.$store.commit('scheduler/SET_IS_INFORM_CHANGE_DATES', false);
            },
            updateOrder(data) {
                if (!this.informChangeDates) {
                    this.informChangeDatesModalOpen = true;
                    return;
                }
                const params = {
                    id: this.event.id,
                    is_message: 0
                };

                if (this.event.is_labor) {
                    params.labor_delivery_date_from = moment(this.dStart).format('YYYY-MM-DD');
                    params.labor_delivery_date_to = moment(this.dEnd).format('YYYY-MM-DD');
                    params.labor_all_day_working = Number(this.event.labor_all_day_working);
                }

                if (!params.labor_all_day_working) {
                    params.labor_delivery_time_from = moment(this.dStart).format('HH:mm');
                    params.labor_delivery_time_to = moment(this.dEnd).format('HH:mm');
                }

                if (this.event.is_material) {
                    params.delivery_date = moment(this.event.delivery_date).format('YYYY-MM-DD');
                    params.delivery_time = this.event.delivery_time;
                }

                if (data.message) {
                    let emails = data.message.toRecipients.map(el => el.emailAddress.address);
                    params.is_message = 1;
                    params.emails = emails;
                    params.body = data.message.body.content;
                    params.subject = data.message.subject;
                }

                this.$store.dispatch('scheduler/UPDATE_SCHEDULER_CREW', params)
                    .then(() => {
                        this.$emit('onUpdate');
                        this.notifySuccess('Changes saved!');
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.$emit('closeEventPopup');
                    });

                this.clearInformChangeDates();
            },
            sendInformDates(params) {
                this.updateOrder(params);
            },
            onUpdate(data, refreshMode) {
                this.$emit('onUpdate', data, refreshMode);
                this.updateCachedEvent(data);

                if (refreshMode) {
                    this.$destroy();
                }
            },
            deleteEvent() {
                this.requestIsGoing = true;

                const calendarId = this.event.calendar_id;
                const eventId = this.event.id;

                api.delete(`/calendars/${calendarId}/events/${eventId}`)
                    .then(() => {
                        this.$emit('onDelete', this.event);
                        this.notifySuccess('Event deleted!');
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                        this.isDeleteTooltipShow = false;
                    });
            },
            downloadAllAttachment() {
                this.attachments.forEach(el => {
                    fetch(`${this.url}${el.files[0].id}`).then(data => data.blob())
                        .then(blob => {
                            let link = document.createElement('a');
                            link.href = URL.createObjectURL(blob);
                            link.download = el.files[0].original_name;
                            link.click();
                        })
                        .catch(error => {
                            this.notifyRequestErrors(error);
                        });
                })
            },
            parseLink(text) {
                let hasLink = false;
                let pattern = /((http|https):\/\/(www\.)?[a-z0-9-]+\.[a-z0-9-]{2,6})/;
                hasLink = pattern.test(text);
                
                if (hasLink) {
                    let link = pattern.exec(text);
                    return `
                        <a 
                            href="${link.input}" 
                            target="_blank"
                            class="f-12-blue"
                        >
                            ${link.input}
                        </a>
                    `
                } else {
                    return text;
                }                
            },
            ifOneDay(start,end) {
                let endDate = moment(end).clone().subtract(1, 'd').format('YYYY-MM-DD');
                return moment(start).isSame(moment(endDate));
            },
            allDayEndDate(end) {
                return moment(end).clone().subtract(1, 'd').format('YYYY-MM-DD');
            },
            isPastEvent(event) {
                return moment.utc(event.dtend).isBefore(moment());
            },
            isShowCrewEditForm(event) {
                return this.isEditable
                    && event.can_edit
                    && !this.isPastEvent(event)
                    && this.hideScheduler;
            },
            cancelEdit() {
                this.isEdit = false;
                this.$emit('closeEventPopup');
            },
            updateCachedEvent(event) {
                this.event = event;
            }
        },
        async mounted() {
            await this.getSingleEvent();

            this.attachments = this.event.attachments;

            this.activeTab = this.TABS.INFORMATION.title;
            this.$nextTick(() => {
                // calculate is there a need to show the 'read more' button
                this.notesTextOverflow = this.$refs.notes
                    && this.$refs.notes.offsetHeight < this.$refs.notes.scrollHeight;

                if (!this.event.labor_delivery_date_from) {
                    this.dStart = this.event.dtstart;
                    this.dEnd = this.event.dtend;
                    return;
                }

                const dStart = moment(this.event.labor_delivery_date_from).format('YYYY-MM-DD');
                const dEnd = moment(this.event.labor_delivery_date_to).format('YYYY-MM-DD');
                
                this.dStart = this.event.labor_delivery_time_from
                    ? dStart + ' ' + this.event.labor_delivery_time_from
                    : dStart;
                this.dEnd = this.event.labor_delivery_time_to
                    ? dEnd + ' ' + this.event.labor_delivery_time_to
                    : dEnd;
            });
        },
        created() {
            this.getImapConfig();
            if (this.showScheduler) {
                let self = this;

                // create validation rule for Start date
                this.$validator.extend('eventStartDate', {
                    validate(value, field) {
                        if (!self.isEventAllDay) {
                            return !moment(self.dEnd).isSameOrBefore(moment(self.dStart));
                        } else {
                            let endDate = moment(self.dEnd).clone().add(1, 'd').format('YYYY-MM-DD');
                            let startDate = moment(self.dStart).format('YYYY-MM-DD');

                            return !moment(endDate).isSameOrBefore(moment(startDate));
                        }
                    }
                });

                // create validation rule for End date
                this.$validator.extend('eventEndDate', {
                    validate(value, field) {
                        if (!self.isEventAllDay) {
                            return !moment(self.dStart).isSameOrAfter(moment(self.dEnd));
                        } else {
                            let endDate = moment(self.dEnd).clone().add(1, 'd').format('YYYY-MM-DD');
                            let startDate = moment(self.dStart).format('YYYY-MM-DD');

                            return !moment(startDate).isSameOrAfter(moment(endDate));
                        }
                    }
                });
            }
        },
        deactivated() {
            // This hook was called for nested components in keep-alive
            this.isEdit = false;
        }
    }
</script>
