<template>
    <svg
        viewBox="0 0 7 12"
        :width="size"
        :height="size"
        class="orders-icon"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.91863 8.68634H6.20755C6.42078 8.68634 6.49788 8.82994 6.37979 9.00707L4.87827 11.2594C4.76011 11.4366 4.56871 11.4365 4.45062 11.2594L2.9491 9.00707C2.83094 8.82984 2.90812 8.68634 3.12134 8.68634H3.41025C3.62348 8.68634 3.79632 8.51328 3.79632 8.29981V4.24994C3.79632 4.03506 3.96883 3.86341 4.18165 3.86341H5.14724C5.35974 3.86341 5.53257 4.03646 5.53257 4.24994V8.29981C5.53257 8.51469 5.70542 8.68634 5.91863 8.68634ZM0.516498 2.70591H0.22758C0.0143539 2.70591 -0.0627497 2.56231 0.0553431 2.38517L1.55686 0.132891C1.67502 -0.044346 1.86642 -0.0442481 1.98451 0.132891L3.48603 2.38517C3.60419 2.56241 3.52701 2.70591 3.3138 2.70591H3.02488C2.81165 2.70591 2.63881 2.87896 2.63881 3.09243V7.14231C2.63881 7.35718 2.4663 7.52883 2.25348 7.52883H1.28789C1.07539 7.52883 0.902561 7.35578 0.902561 7.14231V3.09243C0.902561 2.87756 0.729714 2.70591 0.516498 2.70591Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'OrdersIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>
