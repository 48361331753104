<template>
    <div class="messages-statistics pt-8 w-100">
        <div
            v-if="!loading" 
            class="subtitle row-between"
        >
            <div class="column-start lg-6 sm-12">
                <h2>Messages</h2>
                <p class="f-13-grey">
                    What to see more items?
                <router-link :to="{ name: 'ResidentialMessages' }">
                    <span class="f-13-blue pl-1">View all Messages</span>
                </router-link>
                </p>
            </div>
            <div class="s-align-6 lg-6 sm-12 mt-sm-3">
                <button
                    class="view-details-primary mr-4 ph-4"
                    @click="openEmailModal('message')"
                >
                    + Add Email/Message
                </button>
                <button 
                    class="primary-button"
                    @click="openEmailModal('email')"
                    :disabled="!outlook_id"
                >
                    + Send New Email
                </button>
            </div>
        </div>
        <letters-list 
            :loading="loading"
            :letters="letters"
            :account_id="outlook_id"
            @reply="replyToLetter"
        />
        <div 
            v-if="!letters.length && !loading" 
            class="column-center w-100"
        >
            <span class="f-13-grey t-center">
                This
                {{ jobNumber ? 'job' : 'lead' }} 
                has no messages.<br>
                Click 
                <router-link :to="{ name: 'ResidentialMessages' }">
                    <span class="f-13-blue pl-1">Send New Email</span>
                </router-link>
                to create a first message.
            </span>
        </div>
    </div>
</template>

<script>
    import LettersList from '@/components/Letters/LettersList.vue';
    import IMAPapi from '@/IMAPheaders';
    import { emailMessage } from '@/views/LeadModules/messages-mixin/emailMessage';

    export default {
        name: 'MessagesStatistics',
        components: {
            LettersList,
            SendNewEmailModal: () => import('@/modals/SendNewEmailModal.vue'),
            EmailFullscreenModal: () => import('@/modals/EmailFullscreenModal.vue'),
            ConfirmActionsModal: () => import('@/modals/ConfirmActionsModal.vue')
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            jobNumber: {
                type: [String, Number],
                required: false
            },
            entityType: {
                type: String,
                required: true
            }
        },
        mixins: [emailMessage],
        data() {
            return {
                letters: [],
                outlook_id: null
            }
        },
        computed: {
            entityId() {
                return this.$route.params.lead_id;
            },
            userId() {
                return this.$store.getters['user/getUserId'];
            },
            isUserDataLoaded() {
                return this.$store.getters['user/isUserDataLoaded'];
            },
            emailModal() {
                return this.$store.getters['emailMessage/getEmailModalState'];
            }
        },
        watch: {
            isUserDataLoaded(val) {
                if (val) {
                    this.getMessages();
                }
            }
        },
        methods: {
            updateMessages(data) {
                if (data) {
                    this.getMessages();
                }
            },
            getMessages() {
                let params = {
                    "entity_id": this.entityId,
                    "entity_type": this.entityType,
                    "per_page": 5,
                    "page": 1,
                    "is_preview": true,
                    "user_id": this.userId,
                    "entity_number": this.jobNumber || null,
                    "lead_id": this.jobNumber ? this.entityId : null
                }

                IMAPapi.get(`messages/entity`, {params})
                    .then(response => {
                        let data = response.data.data;
                        this.letters = data.messages.data;
                        this.outlook_id = data.account[0] ? data.account[0]._id : null;

                        this.$emit('onMessagesLoad', {countMessages: this.letters.length || 0});
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            replyToLetter(letter) {
                this.$router.push({
                    name: 'ResidentialMessages',
                    params: { previewLetterReply: letter }
                });
            },
            openEmailModal(type) {
                if (this.emailModal) {
                    this.openCollapsedModal();
                    return;
                }

                let params = {
                    show: true,
                    modalType: type,
                    title: type === 'email' ? 'New Email' : 'Add email/message',
                    outlook_id: this.outlook_id
                }
                this.$store.commit('emailMessage/openModal', params);
            }
        },
        created() {
            if (this.isUserDataLoaded) {
                this.getMessages();
            }
        }
    }
</script>