<template>
  <svg
    :width="width"
    :height="height"
    :style="{ transform: 'rotate('+ transform + 'deg)' }"
    viewBox="0 0 10 6"
  >
    <path
      d="M5 6L4.54116e-07 -5.12573e-07L10 -1.43051e-06L5 6Z"
      fill="#3389FD"
    />
  </svg>
</template>

<script>
export default {
  name: 'DownIcon',
  props: {
    width: {
      type: [Number, String],
      default: 10
    },
    height: {
      type: [Number, String],
      default: 6
    },
    transform: {
      type: [Number, String],
      default: 0
    }
  }
}
</script>
