const toWord = (number) => {
	var gf = "";
	var gg = "";
	var gh = "";
	var gi = "";
	var gj = "";
	var gc = "zero";
	var dd = "";
	var cc = "";
	var minus = "";

	if (number < 0) {
		minus = "Minus ";
	}

	number = number.toString();

	var sNumber = Math.trunc(number).toString();
	var sNumbec = (number % 1).toFixed(2);
	
	if (number > 0) {
		sNumbec = sNumbec.substring(2);
	} else {
		sNumbec = sNumbec.substring(3);
	}

	sNumber = stripBad(sNumber);
	sNumber = parseInt(sNumber, 10);
	var sNum2 = String(sNumber);

	sNumbec = stripBad(sNumbec);
	sNumbec = parseInt(sNumbec, 10);
	var sNumc = String(sNumbec);

 	if (sNumber == 1){
 		dd = " Dollar ";
 	} else {
 		dd = " Dollars ";
 	}
 	if (sNumbec == 1){
 		cc = " Cent";
 	} else {
 		cc = " Cents";
 	}

 	if (sNumbec < 1){gc = "zero";}
	if (sNumc == "") {gc = "zero";}
	if (sNumc > 0) {gc = hto(sNumc);}

	var j =  sNum2.length;
	var hNum2 = sNum2.substring((j-3),((j-3) + 3));

	if (hNum2 > 0) {
	 	gf = hto(hNum2) + "";
	}

	var tNum2 = sNum2.substring((j-6),(j-6) + 3);
	if (tNum2 > 0) {
		gg = hto(tNum2) + " Thousand ";
	}

	var mNum2 = sNum2.substring((j-9), (j-9) + 3);
	if (mNum2 > 0) {
		gh = hto(mNum2) + " Million ";
	}

	var bNum2 = sNum2.substring((j-12),(j-12)+3);
	if (bNum2 > 0) {
		gi = hto(bNum2) + " Billion ";
	}

	var trNum2 = sNum2.substring((j-15), (j-15)+3);
	if (trNum2 > 0) {
		gj = hto(trNum2) + " Trillion ";
	}

	 if (sNumber < 1) {
		gf = "zero";
	}

	if (j > 15) {
		gj = " Your number is too big for me to spell";
		gi = "";
		gh = "";
		gg = "";
		gf = "";
		dd = "";
	}
	var dds = gj + gi + gh + gg + gf;

	if (dds == "") {
		return "";
	}

	return minus + (dds == "zero" ? '' : dds + dd) + (gc == "zero" ? '' : gc + cc);
}

function hto(ff){
	var sNum3 = "";
	var p1="";
	var p2="";
	var p3="";

	var hy="";
 	var n1 = new Array(
 		'', 'One', 'Two', 'Three', 'Four', 'Five', 'Six',
    	'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve',
    	'Thirteen', 'Fourteen', 'Fifteen',  'Sixteen', 'Seventeen',
    	'Eighteen', 'Nineteen'
	);

  	var n2 = new Array(
  		'', '', 'Twenty', 'Thirty', 'Forty',
    	'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
	);

	sNum3 = ff;
	var j =  sNum3.length;
	var h3 = sNum3.substring((j-3),(j-3)+1);

	if (h3 > 0) {
		p3 = n1[h3] + " Hundred ";
	} else {
		p3 = "";
	}

	var t2 = parseInt(sNum3.substring((j-2),(j-2)+1), 10);
	var u1 = parseInt(sNum3.substring((j-1),(j-1)+1), 10);
	var tu21 = parseInt(sNum3.substring((j-2),(j-2)+2), 10);

	if (tu21 == 0) {
	 	p1 = "";
	 	p2 = "";

	} else if ((t2 < 1) && (u1 > 0)) {
		p2 = "";
		p1 = n1[u1];
	
	} else if (tu21 < 20) {
		p2 = "";
		p1 = n1[tu21];
	
	} else if ((t2 > 1) && (u1 == 0)) {
		p2 = n2[t2];
		p1 = "";
	} else {
		p2 = n2[t2] + "-";
		p1 = n1[u1];
	}

	ff = p3 + p2 + p1;

  	return ff;
}

function ohto(ff){
	var sNum3 = "";
	var p1 ="";
	var p2 ="";
	var p3 ="";

	var hy ="";
 	var n1 = new Array(
 		'', 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth',
    	'Seventh', 'Eighth', 'Ninth', 'Tenth', 'Eleventh', 'Twelfth',
    	'Thirteenth', 'Fourteenth', 'Fifteenth',  'Sixteenth', 'Seventeenth',
    	'Eighteenth', 'Nineteenth'
	);

  	var n2 = new Array(
  		'', '', 'Twentieth', 'Thirtieth', 'Fortieth', 'Fiftieth',
  		'Sixtieth', 'Seventieth', 'Eightieth', 'Ninetieth'
	);

  	var n3 = new Array(
  		'', '', 'Twenty', 'Thirty', 'Forty',
    	'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
	);

 	var n4 = new Array(
 		'', 'One', 'Two', 'Three', 'Four', 'Five', 'Six',
    	'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve',
    	'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen',
    	'Eighteen', 'Nineteen'
	);

	sNum3 = ff;
	var j =  sNum3.length;
	var h3 = sNum3.substring((j-3),(j-3)+1);

	if (h3 > 0) {
		p3 = n4[h3] + " Hundred ";
	} else {
		p3 = "";
	}

	var t2 = parseInt(sNum3.substring((j-2),(j-2)+1), 10);
	var u1 = parseInt(sNum3.substring((j-1),(j-1)+1), 10);
	var tu21 = parseInt(sNum3.substring((j-2),(j-2)+2), 10);

	if (tu21 == 0) {
	 	p1 = "";
	 	p2 = "";
	 	p3 = n4[h3] + " Hundredth ";
	
	} else if ((t2 < 1) && (u1 > 0)) {
		p2 = "";
		p1 = n1[u1];
	
	} else if (tu21 < 20) {
		p2 = "";
		p1 = n1[tu21];
	
	} else if ((t2 > 1) && (u1 == 0)) {
		p2= n2[t2];
		p1 = "";
	
	} else {
		p2 = n3[t2] + "-";
		p1 = n1[u1];
	}

	ff = p3 + p2 + p1;

  return ff;
}

function stripBad(string) {
    for (var i = 0, output = '', valid = "0123456789."; i < string.length; i++) {
       if (valid.indexOf(string.charAt(i)) != -1){
          output += string.charAt(i)
       }
   	}

    return output;
}

export default toWord;