<template>
    <svg 
        viewBox="0 0 23.369 23.369"
        :width="size"
        class="increase-icon"
        :style="{ transform: 'rotate('+ transform + 'deg)' }"
    >
        <g>
            <path d="M0.5,23.368c-0.128,0-0.256-0.049-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707L22.516,0.146 c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707L0.854,23.222C0.756,23.319,0.628,23.368,0.5,23.368z"/>
            <path d="M0.502,23.369c-0.133,0-0.26-0.053-0.354-0.146s-0.146-0.221-0.146-0.354V5.141c0-0.276,0.224-0.5,0.5-0.5 s0.5,0.224,0.5,0.5v17.229l17.227-0.002c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5L0.502,23.369z"/>
        </g>
</svg>
</template>

<script>
    export default {
        name: 'IncreaseIcon',
        props: {
            size: {
                type: [Number, String],
                default: 10
            },
            transform: {
                type: [Number, String],
                default: 0
            }
        }
    }
</script>
