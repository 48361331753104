<template>
    <component 
        :is="parentComponent"
        :class="['measurement-view w-100', `measurement-view-${measurementStatus}`]"
        v-if="Object.keys(data).length"
    >
        <slot name="radio"></slot>
        <div 
            class="measurement-overview row-between pointer"
            @click="!previewMode ? open = !open : ''"
        >
            <div class="lg-6 md-12 sm-12 pr-4">
                <h2 class="f-16-black f-sbold">
                    <!-- TODO need to remove -->
                    <!-- temporary check (for existing orders) -->
                    <!-- now this field is required (on front) -->
                    {{ data.name != '' ? data.name : 'EagleView' }}
                </h2>
            </div>
            <div class="lg-5 md-12 sm-12 row-start mt-sm-1 mt-md-1">
                <span class="f-12-grey md-12 sm-12 mr-20">
                    Created: 
                    <span class="f-12-black pl-1">{{ data.created_at | date }}</span>
                </span>
                <span class="f-12-grey md-12 sm-12 mr-20">
                    Status:
                    <span class="measurement-status relative f-12-black capitalize">
                        {{ data.status }}
                    </span>
                </span>
                <span class="f-12-grey md-12 sm-12">
                    Generated by:
                    <span class="f-12-black pl-1">{{ this.measurement.user.fullname }}</span>
                </span>
            </div>
            <div class="lg-1 md-12 sm-12 s-align-6">
                <button 
                    class="icon-dark"
                    @click.stop="open = !open"
                >
                    <arrow-icon 
                        :transform="open ? 270 : 90"
                        :size="mScreen ? 12 : 10"
                    />
                </button>
            </div>
        </div>
        <div 
            class="measurement-details" 
            v-if="open"
        >
            <div class="row-between pb-30 s-align-1">
                <div class="lg-7 md-12 sm-12 pr-4 row-start">
                    <div class="f-12-grey lg-6 sm-12 pb-1">
                        EagleView type: 
                        <span class="f-12-black pl-1">{{ eagleViewType }}</span>
                    </div>
                    <div class="f-12-grey lg-6 sm-12 pb-1">
                        Structural changes in last 4 years:
                        <span class="f-12-black pl-1">{{ data.changes_in_last_4_years ? 'Yes' : 'No' }}</span>
                    </div>
                    <div class="f-12-grey lg-6 sm-12 pb-1">
                        Delivery method:
                        <span class="f-12-black pl-1">{{ deliveryMethod }}</span>
                    </div>
                    <div class="f-12-grey lg-6 sm-12 pb-1">
                        Job number:
                        <span class="f-12-black pl-1">{{ leadId }}</span>
                    </div>
                    <div class="f-12-grey lg-6 sm-12">
                        Number of buildings:
                        <span class="f-12-black pl-1">{{ numberOfBuildings }}</span>
                    </div>
                </div>
                <div class="lg-5 md-12 sm-12 mt-md-2 mt-sm-2">
                    <div class="measurement-details-map w-100">
                        <google-map 
                            :coords="{ lat: +data.latitude, lng: +data.longitude }"
                            :zoom="17"
                            :satellite="true"
                            v-if="data.latitude != null && data.longitude != null"
                        />
                        <div class="measurement-details-no-map s-align-5 h-100 w-100" v-else>
                            <span class="f-12-grey f-sbold opacity-8">
                                Sorry, map is not available now
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div 
                class="row-start pb-30"
                v-if="attributes.length"
            >
                <div 
                    class="lg-2 md-6 sm-12 mr-100 row-between"
                    v-for="(attribute, index) in attributes"
                    :key="index"
                >
                    <span class="f-12-grey pb-1">
                        {{ attribute.name }}:
                    </span>
                    <span class="f-12-black w-30 row-between">
                        {{ attribute.pivot.value | numberWithCommas}}
                        <span>
                            {{ attribute.attribute_type }}
                        </span>
                    </span>
                </div>
            </div>
            <div
                v-if="measurementFiles.length"
                class="column-start"
            >
                <h3 class="f-12-grey f-normal">
                    Report:
                </h3>
                <div class="column-start">
                    <p class="f-12-black pv-1">
                        <button
                            v-for="(file, fileIndex) in measurementFiles"
                            :index="fileIndex"
                            class="transparent-button p-1"
                            @click="downloadFile(file)"
                        >
                            <download-icon
                                size="14"
                                class="i-grey"
                            />
                            <span class="ml-2">{{ file.original_name }}</span>
                        </button>
                    </p>
                </div>
            </div>
            <div class="row-between">
                <div
                    :class="[
                        'column-start',
                        previewMode ? 'lg-12' : 'lg-8 md-12 sm-12'
                    ]" 
                >
                    <h3 class="f-12-grey f-normal">
                        Comment:
                    </h3>
                    <p :class="['pt-1', data.comment == null ? 'f-italic f-12-grey' : 'f-12-black']">
                        {{ data.comment == null ? noCommentMessage : data.comment }}
                    </p>
                </div>
                <div
                    v-if="!previewMode" 
                    class="lg-4 md-12 sm-12 s-align-9 mt-sm-1"
                >
                    <button 
                        class="add-button"
                        @click="$emit('cancel', data.report_id)"
                        v-if="measurementStatus == ORDERED"
                    >
                        Cancel Order
                    </button>
                </div>
            </div>
            <div 
                class="w-100 bt-grey pv-6 mt-8 in-dev"
                v-if="measurementStatus == COMPLETED && data.files && data.files.length"
            >
                <button 
                    class="transparent-button"
                    @click="savePdf"
                >
                    <document-icon 
                        height="22" 
                        width="14"
                    />
                    <div class="s-align-4 pl-2">
                        <span class="f-13-darkgrey">pdf</span>
                        <link-icon class="i-darkgrey ml-2"/>
                    </div>
                </button>
            </div>
        </div>
    </component> 
</template>

<script>
    import { ArrowIcon, DocumentIcon, LinkIcon, DownloadIcon } from '@/icons';
    import { GoogleMap } from '@/GoogleApi';
    import { 
        EAGLE_VIEW_TYPES, DELIVERY_METHODS, 
        INSTRUCTION_TYPES, EAGLE_VIEW_STATUSES 
    } from '@/constants';

    export default {
        name: 'MeasurementsEagleView',
        components: {
            DownloadIcon,
            ArrowIcon,
            GoogleMap,
            DocumentIcon,
            LinkIcon
        },
        props: {
            measurement: {
                type: Object,
                default: function() {
                    return {};
                }
            },
            previewMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                open: false,
                noCommentMessage: 'No comment has been written in this measurement.',
                data: {},
                attributes: [],
                COMPLETED: EAGLE_VIEW_STATUSES.COMPLETED
            }
        },
        computed: {
            eagleViewType() {
                return EAGLE_VIEW_TYPES.find(t => t.id == this.data.product_id).name;
            },
            deliveryMethod() {
                return DELIVERY_METHODS.find(t => t.id == this.data.delivery_id).name;
            },
            leadId() {
                return this.$route.params.lead_id;
            },
            numberOfBuildings() {
                return INSTRUCTION_TYPES.find(t => t.id == this.data.instruction_type).name;
            },
            measurementStatus() {
                if (this.data.status.toLowerCase().includes(this.CLOSED)) {
                    return this.CLOSED;
                }

                if (this.data.status == EAGLE_VIEW_STATUSES.COMPLETED) {
                    return this.COMPLETED;
                }

                return this.ORDERED;
            },
            measurementFiles() {
                return this.measurement.files ? this.measurement.files : [];
            },
            parentComponent() {
                if (this.previewMode) {
                    return 'label';
                }

                return 'div';
            }
        },
        methods: {
            downloadFile(file) {
                fetch(`${process.env.VUE_APP_AMAZONAWS_URL}${file.id}`)
                    .then(data => data.blob())
                    .then(blob => {
                        let link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = file.original_name;
                        link.click();
                    })
                    .catch(error => this.notifyRequestErrors(error));
            },
        },
        created() {
            this.ORDERED = 'ordered';
            this.CLOSED = 'closed';
            this.COMPLETED = 'completed';

            if (this.measurement.hasOwnProperty('order')) {
                this.data = this.measurement.order;
                this.attributes = this.measurement.attributes;
            } else {
                this.data = this.measurement;
            }
        }
    }
</script>

