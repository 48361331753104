<template>
    <header v-scroll="scrollListener">
        <div 
            class="mobile-nav-panel w-100" 
            v-if="!dScreen"
        >
            <div class="mobile-nav-content row-between">
                <button 
                    class="s-align-4 mobile-menu-open"
                    @click="showMenu = !showMenu"
                >
                    <menu-icon/>
                </button>
                <div class="s-align-6">
                    <pinned/>
                    <router-link 
                        class="primary-button-round"
                        :to="{ name: 'CreateNewLead' }"
                    >
                        <add-icon/>
                    </router-link>
                </div>
            </div>
        </div>
        <div 
            class="navbar-mask"
            v-if="showMenu"
        >
            <div class="mobile-menu-close">
                <button
                    class="icon-light"
                    @click="showMenu = !showMenu, activeMenuItem = null"
                >
                    <close-icon size="15"/>
                </button>
            </div>
            <div class="mobile-scroll">
                <div 
                    class="mobile-scroll-mask"
                    v-body-scroll-lock:reserve-scroll-bar-gap="isScrollLocked"
                >
                    <div class="navbar">
                        <router-link 
                            :to="{ name: 'Dashboard' }"
                            :class="['logo s-align-4']"
                            v-if="dScreen"
                        >
                            <crm-logo class="mr-6" type="white" :width="115" :height="32"/>
                        </router-link>
                        <div class="navbar-info relative">
                            <search-box v-on:closeMenuOnRedirect="closeMenuOnRedirect"/>
                            <pinned v-if="dScreen"/>
                        </div>
                        <user-profile v-on:closeMenuOnRedirect="closeMenuOnRedirect"/>
                    </div>
                    <div :class="['menu', 'row-between', 'relative', { 'menu-squeeze' : squeezeMenu }]">
                        <nav class="navigation">
                            <ul class="menu-list">
                                <li 
                                    v-for="(menuItem, menuIndex) in menu"
                                    :key="menuIndex"
                                    :class="['menu-item',` menu-item-${menuIndex}`]"
                                    @mouseover="openCloseDesktopSubMenu(menuIndex)"
                                    @click="openCloseMobileSubMenu(menuIndex)"
                                >
                                    <router-link
                                        v-if="menuItem.link"
                                        :to="{ name: menuItem.link }"
                                        class="menu-item-head row-start"
                                        @click.native="closeMenuOnRedirect"
                                    >
                                        <component :is="menuItem.icon"/>
                                        <span class="menu-item-name">{{ menuItem.name }}</span>
                                    </router-link>
                                    <div 
                                        v-else
                                        :class="['menu-item-head', 'row-start', 
                                            { 'mobile-arrow' : menuItem.submenu },
                                            { 'mobile-arrow-rotate' : menuItem.submenu && activeMenuItem == menuIndex }
                                        ]"
                                    >
                                        <component :is="menuItem.icon"/>
                                        <span class="menu-item-name">{{ menuItem.name }}</span>
                                    </div>
                                    <div 
                                        class="submenu"
                                        v-if="menuItem.submenu && menuItem && activeMenuItem == menuIndex"
                                        :style="{ paddingLeft: getSubmenuPadding(menuIndex) + 'px' }"
                                    >
                                        <ul class="submenu-list">
                                            <li 
                                                class="submenu-item column-start"
                                                v-for="(submenuItem, submenuIndex) in menuItem.submenu"
                                                :key="submenuIndex"
                                            >
                                                <span
                                                    v-if="submenuItem.title"
                                                    class="submenu-item-head"
                                                >
                                                    {{ submenuItem.title }}
                                                </span>
                                                <router-link
                                                    v-for="(link, linkIndex) in submenuItem.links"
                                                    :key="linkIndex"
                                                    :to="{ name: link.link }"
                                                    class="submenu-item-link"
                                                    :class="{ 'in-dev': link.inDev }"
                                                    @click.native="closeMenuOnRedirect"
                                                >
                                                    {{ link.name }}
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                        <div 
                            class="s-align-6 add-new-lead"
                            v-if="dScreen"
                            v-can="[$permissions.CREATE_LEAD]"
                        >
                            <router-link
                                :to="{ name: 'CreateNewLead' }"
                                class="primary-button add-new-lead-button"
                            >
                                <add-new-icon class="mr-4"/> 
                                New
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import { UserProfile, SearchBox, Pinned } from '@/components';
    import { getCoords } from '@/helpers.js'

    export default {
        name: 'Navigation',
        components: {
            UserProfile,
            SearchBox,
            DashboardIcon: () => import('@/icons/DashboardIcon.vue'),
            ContactIcon: () => import('@/icons/ContactIcon.vue'),
            BriefcaseIcon: () => import('@/icons/BriefcaseIcon.vue'),
            CrmLogo: () => import('@/icons/CrmLogo.vue'),
            GearIcon: () => import('@/icons/GearIcon.vue'),
            MenuIcon: () => import('@/icons/MenuIcon.vue'),
            CloseIcon: () => import('@/icons/CloseIcon.vue'),
            AddIcon: () => import('@/icons/AddIcon.vue'),
            AdminIcon: () => import('@/icons/AdminIcon.vue'),
            PersonIcon: () => import('@/icons/PersonIcon.vue'),
            SettingsIcon: () => import('@/icons/SettingsIcon.vue'),
            PaperIcon: () => import('@/icons/PaperIcon.vue'),
            AddNewIcon: () => import('@/icons/AddNewIcon.vue'),
            OpportunityIcon: () => import('@/icons/OpportunityIcon.vue'),
            Pinned
        },
        data() {
            return {
                showMenu: true,
                activeMenuItem: null,
                squeezeMenu: false
            }
        },
        computed: {
            menu() {
                return this.$store.getters['menu/getMenu'].filter(item => {
                    if (item.permissions) {
                        return this.checkPermissions(item.permissions);
                    }
                    return true;
                }).map(item => {
                    if (item.submenu) {
                        return {
                            ...item,
                            submenu: item.submenu.filter(subitem => {
                                if (subitem.permissions) {
                                    return this.checkPermissions(subitem.permissions);
                                }
                                return true;
                            }).map(subitem => {
                                return {
                                    ...subitem,
                                    links: subitem.links.filter(link => {
                                        if (link.permissions) {
                                            return this.checkPermissions(link.permissions);
                                        }
                                        return true;
                                    })
                                };
                            })
                        }
                    }
                    return item;
                });
            },
            isScrollLocked() {
                return this.showMenu && !this.dScreen;
            }
        },
        methods: {
            changeMenuType() {
                this.showMenu = this.dScreen;
            },
            getSubmenuPadding(menuIndex) { 
                if (!this.dScreen) return false; 
                
                let menuItem = this.$el.querySelector(`.menu-item-${menuIndex}`);                
                return menuItem != null 
                    ? Math.round(getCoords(menuItem).left) 
                    : '';
            },
            openCloseDesktopSubMenu(index) {
                this.dScreen && navigator.userAgent.match(/iPad/i) == null 
                    ? this.activeMenuItem = index : '';
            },
            openCloseMobileSubMenu(index) {
                this.activeMenuItem == index 
                    ? this.activeMenuItem = null 
                    : this.activeMenuItem = index;
            },
            closeMenuOnRedirect() {
                !this.dScreen ? this.showMenu = false : '';
            },
            scrollListener: function (event, el) {    
                window.scrollY > 70 && this.dScreen
                    ? this.squeezeMenu = true
                    : this.squeezeMenu = false;
            },
            checkPermissions(permissions) {
                return permissions.some(permission => {
                    return this.$can(permission);
                });
            }
        },
        mounted() {
            this.changeMenuType();
            window.addEventListener('resize', this.changeMenuType);
        },
        destroyed() {
            window.removeEventListener('resize', this.changeMenuType);
        }
    }
</script>
