import Vue from "vue";

export const hasPermissions = (needed, userPermissions) => {
    if (Array.isArray(needed) === false) {
        needed = [needed];
    }

    return needed.every(n => {
        return userPermissions.some(p => {
            return p === n;
        });
    });
};

export const somePermission = (needed, userPermissions) => {

    if (Array.isArray(needed) === false) {
        needed = [needed];
    }

    return needed.some(n => {
        return userPermissions.some(p => {
            return p === n;
        });
    });

};

export const someRoles = (needed, userRoles) => {

    if (Array.isArray(needed) === false) {
        needed = [needed];
    }

    return needed.some(n => {
        return userRoles.some(p => {
            return p === n;
        });
    });

};

export const notifyIfNoAccessToPage = () => {
    Vue.nextTick(() => Vue.prototype.$notify({
        group: 'main',
        type: 'error',
        title: 'Error!',
        text: 'No access to this page!'
    }));
};