<template>
    <div class="button-dropdown relative">
        <button 
            class="button-dropdown-main w-100 s-align-5"
            @click="open = !open"
        >
            <span class="f-13-white f-sbold pr-4">Next to</span>
            <arrow-icon 
                :transform="open ? 90 : 0" 
                size="10"
                class="i-white"
            />
        </button>
        <div 
            class="button-dropdown-list column-start"
            v-if="open"
        >
            <slot name="default"></slot>
        </div>
    </div>
</template>

<script>
    import { ArrowIcon } from '@/icons';

    export default {
        name: 'ButtonDropdown',
        components: {
            ArrowIcon
        },
        data() {
            return {
                open: false
            }
        },
        methods: {
            outside(event) {
                if (!this.$el.contains(event.target) && this.open)
                    this.open = false;
            }
        },
        mounted() {
            document.body.addEventListener('click', this.outside, false);
        },
        destroyed() {
            document.body.removeEventListener('click', this.outside, false);
        }
    }
</script>