<template>
  <div>
    <full-modal @close="$emit('close')">
      <div slot="title">Customer Settings</div>
      <div slot="body">
        <div class="w-100 pb-30">
          <div class="s-align-4 mb-3">
            <radio-switch
                v-for="item in switchMode"
                :key="`receiving-${item.value}`"
                :label="item.label"
                color="blue"
                width="235"
            >
              <input
                  type="radio"
                  :name="`receiving-${item.value}`"
                  v-model="editCustomerMode"
                  :value="item.value"
                  :disabled="editBtnDisabled"
              />
            </radio-switch>
          </div>
          <form-input
              label="Responsible billing party"
              :class="['required-field-mark', { 'form-input-danger' : errors.has('name') }]"
          >
            <input
                v-if="editCustomerMode"
                type="text"
                name="name"
                id="name"
                class="form-input-field"
                placeholder="Responsible billing party"
                v-input-style="customer.name"
                v-model="customer.name"
                maxlength="251"
                v-validate="'required|max:250'"
            />
            <multiselect
                v-else
                :options="customers"
                :showLabels="false"
                track-by="name"
                :value="customer"
                label="name"
                placeholder="Responsible billing party"
                name="responsible_name"
                data-name="responsible_name"
                :taggable="true"
                :multiple="false"
                :allow-empty="true"
                :searchable="true"
                :show-labels="false"
                tagPlaceholder="Press enter to create a customer"
                @tag="addNewCustomer($event)"
                @select="selectCustomer($event)"
                :class="[{ 'multiselect-danger' : errors.has(`responsible_name`)}]"
                v-validate="`required`"
            />
          </form-input>
        </div>
        <p class="f-14-asphalt f-sbold pb-4">Contacts</p>
        <div class="pb-30 row-between">
          <div class="w-100 column-start">
            <p class="f-13-asphalt pb-4">Phones:</p>
            <div
                class="s-align-4 w-100 pb-4"
                v-for="(phone, index) in customer.phones"
                :key="`phone-number-${index}`"
                :id="`phone-number-${index}`"
            >
              <div class="static-col-8">
                <form-input
                    label="Phone"
                    class="form-input-with-select"
                    :class="[{ 'form-input-danger' : errors.has(`phone-number-${index}`) }]"
                >
                  <input
                      type="tel"
                      :name="`phone-number-${index}`"
                      class="form-input-field"
                      placeholder="Phone"
                      v-input-style="phone.number"
                      v-model="phone.number"
                      v-mask="'(###) ###-####'"
                  />
                  <multiselect
                      :options="phoneTypes"
                      :showLabels="false"
                      :max-height="200"
                      placeholder="Types"
                      v-model="phone.type"
                      :name="'phone_type' + index"
                      :data-name="'phone_type' + index"
                      v-validate="'required'"
                      :searchable="false"
                      class="capitalize"
                  />
                </form-input>
              </div>
              <button
                  class="service-add-button ml-2"
                  v-if="index > 0"
                  @click="removeItem(index, customer.phones)"
                  tabindex="-1"
              >
                <trash-icon/>
              </button>
              <button
                  class="service-secondary-button ml-2"
                  v-if="phonesLength == index + 1 && phonesLength < 3"
                  @click="addPhoneNumber"
                  tabindex="-1"
              >
                <add-icon size="10"/>
              </button>
            </div>
          </div>
          <div class="w-100 column-start pt-2">
            <p class="f-13-asphalt pb-4 mt-sm-1">Emails:</p>
            <div
                class="s-align-4 w-100 pb-4"
                v-for="(email, index) in customer.emails"
                :key="email.id"
                :id="`email-${index}`"
            >
              <div class="static-col-8">
                <form-input
                    label="Email"
                    :class="[{ 'form-input-danger' : errors.has(`email-${index}`) }]"
                >
                  <input
                      type="email"
                      :name="`email-${index}`"
                      class="form-input-field"
                      placeholder="Email"
                      v-input-style="email.email"
                      v-model="email.email"
                  />
                </form-input>
              </div>
              <button
                  class="service-add-button ml-2"
                  v-if="index > 0"
                  @click="removeItem(index, customer.emails)"
                  tabindex="-1"
              >
                <trash-icon/>
              </button>
              <button
                  class="service-secondary-button ml-2"
                  v-if="emailsLength == index + 1 && emailsLength < 3"
                  @click="addEmail"
                  tabindex="-1"
              >
                <add-icon size="10"/>
              </button>
            </div>
          </div>
        </div>
        <p class="f-14-asphalt f-sbold pb-4">Address</p>
        <div class="w-100 pb-30">
          <address-box
              type="address"
              :key="'address'"
              :existing="customerAddress"
              :apartment="true"
              :validate="validateAddressRule"
              :requiredMark="requiredAddressMark"
              @address="getCustomerAddress"
          >
            <div
                class="w-100 mb-4"
                slot="apartment"
            >
              <form-input
                  label="Unit"
                  :class="[{ 'form-input-danger' : errors.has('property_unit') }]"
              >
                <input
                    type="text"
                    name="property_unit"
                    id="property_unit"
                    class="form-input-field"
                    placeholder="Unit"
                    v-input-style="customerAddress.apartment"
                    v-model="customerAddress.apartment"
                    v-validate="'max:50'"
                />
              </form-input>
            </div>
          </address-box>
        </div>
      </div>
      <div slot="footer">
        <button
            class="add-button"
            @click="$emit('close')"
        >
          Cancel
        </button>
        <button
            class="primary-button"
            @click="update"
            ref="save"
        >
          Save
        </button>
      </div>
    </full-modal>
    <zip-code-modal
        v-if="outOfArea"
        @continue="updateCustomer"
        @cancel="cancel"
        @close="cancel"
    />
  </div>
</template>

<script>
    import { FullModal, FormInput, RadioSwitch } from '@/ui';
    import { TrashIcon, AddIcon } from '@/icons';
    import { COMMERCIAL_PHONE_TYPES, ADDRESS_TYPES, MODEL_CLASSES, BASE_ENTITY_TYPES, ENTITY_TYPES } from '@/constants';
    import { mask } from 'vue-the-mask';
    import { removePhoneMask, checkContacts, clearObject } from '@/helpers';
    import cloneDeep from 'lodash/cloneDeep';
    import AddressBox from '@/components/AddressBox.vue';
    import Multiselect from 'vue-multiselect';
    import api from '@/headers.js';
    import ZipCodeModal from "./ZipCodeModal";
    import {mapActions, mapGetters} from "vuex";

    const EDIT = 'edit';
    const CREATE = 'create';

    export default {
        name: 'EditCustomerInCommercial',
        components: {
            FullModal,
            FormInput,
            TrashIcon,
            AddIcon,
            Multiselect,
            AddressBox,
            RadioSwitch,
            ZipCodeModal,
        },
        props: {
            entityCustomer: {
                type: Object,
                required: true
            },
            entityId: {
                type: [Number, String],
                required: true
            },
            entityType: {
                type: String,
                required: true
            },
            entityCustomerMode: {
                type: String,
                default: EDIT
            },
            propertyId: {
                type: [Number, String],
                required: true
            }
        },
        data() {
            return {
                phoneTypes: COMMERCIAL_PHONE_TYPES,
                ENTITY_TYPES,
                customerAddress: {
                    address: '',
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                    lat: null,
                    lng: null,
                    apartment: '',
                    type: ADDRESS_TYPES.MAIN
                },
                validateAddressRule: 'required',
                requiredAddressMark: true,
                customers: [],
                customer: null,
                editCustomerMode: true,
                editBtnDisabled: false,
                customerMode: CREATE,
                switchMode: [
                    {
                        value: true,
                        label: 'Edit current'
                    },
                    {
                        value: false,
                        label: 'Choose other'
                    }
                ]
            }
        },
        computed: {
            ...mapGetters('zipCodes', [
              'outOfArea'
            ]),
            phonesLength() {
                return this.customer.phones.length;
            },
            emailsLength() {
                return this.customer.emails.length;
            }
        },
        directives: {
            mask
        },
        methods: {
            ...mapActions('zipCodes', [
              'checkZipCodeArea'
            ]),
            addNewCustomer(item) {
                this.customer = {
                    name: '',
                    emails: [],
                    phones: []
                };
                this.customer.name = item;
                clearObject(this.customerAddress);
                this.addEmail();
                this.addPhoneNumber();
                this.customerMode = CREATE;
                this.editBtnDisabled = false;
                this.editCustomerMode = true;
                this.requiredAddressMark = true;

            },
            selectCustomer(item) {
                this.customer = cloneDeep(item);
                this.getCustomerAddress(item.addresses.main[0]);
                this.customerMode = EDIT;
                this.validateAddressRule = 'required';
                this.requiredAddressMark = true;
                this.editBtnDisabled = false;
                this.editCustomerMode = true;

                if (!this.phonesLength) {
                    this.addPhoneNumber();
                }

                if (!this.emailsLength) {
                    this.addEmail();
                }
            },
            addPhoneNumber() {
                this.customer.phones.push({
                    number: '',
                    type: this.phoneTypes[1],
                    order: this.phonesLength
                });
            },
            addEmail() {
                this.customer.emails.push({
                    email : '',
                    order: this.emailsLength
                });
            },
            removeItem(index, arr) {
                arr.splice(index, 1);
            },
            getCustomerAddress(data) {
                this.customerAddress = data;
                delete this.customerAddress.id;
            },
            cancel() {
              this.$store.commit('zipCodes/SET_OUT_OF_AREA', false);
            },
            updateCustomer() {
              this.cancel();
              this.$refs.save.disabled = true;
              const customer = cloneDeep(this.customer);

              const params = {
                name: this.customer.name,
                property_id: this.propertyId,
                addresses: [{
                  ...this.customerAddress,
                  type: ADDRESS_TYPES.MAIN,
                  entity_id: this.customer.id,
                  entity_type: MODEL_CLASSES.COMMERCIAL_JOB.CUSTOMER
                }]
              };

              params.emails = checkContacts(customer.emails, 'email');
              params.phones = checkContacts(customer.phones, 'number');
              removePhoneMask(customer.phones);

              customer.emails = params.emails;
              customer.phones = params.phones;

              if (this.customerMode == EDIT) {
                params.customer_id = this.customer.id;
                api.put(`/commercial/customers/${this.customer.id}`, params)
                    .then((response) => {
                      this.notifySuccess('Customer is updated!');
                      switch (this.entityType) {
                        case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                          this.$store.commit(
                              'commercial/SET_CUSTOMER_IN_COMMERCIAL_LEAD',
                              response.data.data
                          );
                          break;
                        case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                          this.$store.commit(
                              'commercial/SET_CUSTOMER_IN_COMMERCIAL_JOB',
                              response.data.data
                          );
                          break;
                      }
                      this.$emit('close');
                      this.$emit('refreshData');
                    })
                    .catch(error => {
                      this.notifyRequestErrors(error);
                      this.$refs.save.disabled = false;
                    });
              } else {
                // in this case, the addresses should be sented in strings
                for (let addressField in this.customerAddress) {
                  params[addressField] = this.customerAddress[addressField];
                };

                api.post('/commercial/customers', params)
                    .then(response => {
                      this.notifySuccess('Customer is created!');

                      customer.id = response.data.data.id;
                      switch (this.entityType) {
                        case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                          this.$store.commit(
                              'commercial/SET_CUSTOMER_IN_COMMERCIAL_LEAD',
                              response.data.data
                          );
                          break;
                        case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                          this.$store.commit(
                              'commercial/SET_CUSTOMER_IN_COMMERCIAL_JOB',
                              response.data.data
                          );
                          break;
                      }
                      this.$emit('close');
                    })
                    .catch((error) => {
                      this.notifyRequestErrors(error);
                      this.$refs.save.disabled = false;
                    });
              }
            },
            update() {
                if (this.customerMode != EDIT) {
                    this.validateAddressRule = 'required';
                }
                this.$nextTick(() => {
                    this.$children.forEach(vm => {
                        vm.$validator.validateAll();
                    });

                    this.$validator.validateAll().then(result => {
                        if (this.$validator.errors.items.length) {
                            this.notifyError('Fill in required fields!');

                            return;
                        }
                      this.checkZipCodeArea(this.customerAddress.zip).then(res => {
                        if (res) {
                          this.updateCustomer();
                        }
                      })
                    });
                })
            },
            getCustomersList() {
                api.get('/commercial/customers?perPage=999')
                    .then(response => response.data.data)
                    .then(data => {
                        this.customers = data.data;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            }
        },
        created() {
            this.customer = cloneDeep(this.entityCustomer);
            this.customerMode = this.entityCustomerMode;

            if (this.customerMode == CREATE) {
                this.editCustomerMode = false;
                this.editBtnDisabled = true;
            }

            if (!this.customer.emails.length) {
                this.addEmail();
            }

            if (!this.customer.phones.length) {
                this.addPhoneNumber();
            }

            switch (this.entityType) {
                case ENTITY_TYPES.WARRANTY_JOB:
                    this.customerAddress = cloneDeep(this.entityCustomer.addresses[0]);
                    break;
                default:
                    if (this.entityCustomer.addresses && this.entityCustomer.addresses.main.length) {
                        this.customerAddress = cloneDeep(this.entityCustomer.addresses.main[0]);
                        delete this.customerAddress.id;
                    }
            }

            // the address fields are required to create a new customer now
            if (this.customerMode != EDIT) {
                this.validateAddressRule = 'required';
                this.requiredAddressMark = true;
            }

            this.getCustomersList();
        }
    }
</script>
