<template>
    <div
        class="row-between client-call-card mb-2 pointer"
        @click="$emit('click')"
        :class="isActive ? colorBorder : ''"
    >
        <div
            class="br-left"
            :class="colorBorder"
        />
        <div class="client-call-card__call-btn">
            <IncomingCall
                class="pointer"
                :size="26"
                v-if="callData.direction === STATUS_CALL_TYPES.OUTBOUND"
            />
            <OutgoingCall
                class="pointer"
                :size="26"
                v-else
            />
        </div>
        <div class="lg-6 column-static">
            <p :class="!callData.contact_name ? 'f-14-grey f-italic' : 'f-14-black'">
                {{ callData.contact_name || no_info }}
            </p>
            <p class="f-12-grey">
                Salesman:
                <span :class="salesman? 'f-12-black' : 'f-12-grey f-italic'">
                    {{ salesman || no_info }}
                </span>
            </p>
            <p class="f-12-grey">
                Job number:
                <router-link
                    v-if="jobNumber && path"
                    :to="{
                            name: path.name,
                            params: path.params
                        }"
                    class="f-12-blue"
                >
                    {{ jobNumber }}
                </router-link>
                <span
                    v-else
                    class="f-12-grey f-italic"
                >
                    {{ no_info }}
                </span>
            </p>
            <p class="f-12-grey">
                Phone:
                <span :class="phone !== no_info? 'f-12-black' : 'f-12-grey f-italic'">
                    {{ (phone || no_info) | phone }}
                </span>
            </p>
        </div>
        <div class="lg-6 column-evenly-end s-align-8">
            <p class="f-12-grey ">
                <span
                    class="f-12-grey"
                    :class="daysAgo === no_info ? 'f-italic' : ''"
                >
                    {{ daysAgo }}
                </span>
            </p>
            <p class="f-12-grey ">
                <span
                    v-if="unixTime"
                    class="f-12-grey"
                >
                    {{ unixTime | date }} at {{ unixTime | time }}
                </span>
                <span
                    v-else
                    class="f-12-grey f-italic"
                >
                    {{ no_info }}
                </span>
            </p>
        </div>
    </div>
</template>

<script>
    import {STATUS_CALL_TYPES, ENTITY_TYPES} from '@/constants';
    import {IncomingCall, OutgoingCall} from '@/icons';
    import moment from "moment";
    
    export default {
        name: 'ClientCallCard',
        components: {
            IncomingCall,
            OutgoingCall
        },
        data() {
            return {
                no_info: 'No information',
                STATUS_CALL_TYPES,
                ENTITY_TYPES
            }
        },
        computed: {
            path() {
                const data = this.callData;
                const path = {};
                switch (data.entity_type) {
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        path.name = 'CommercialJobDetails';
                        path.params = {
                            property_id: data.entity.property_id,
                            job_id: data.entity.id,
                        };
                        break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        path.name = 'CommercialLeadDetails';
                        path.params = {
                            lead_id: data.entity.id,
                        };
                        break;
                    case ENTITY_TYPES.RESIDENTIAL_JOB:
                    case ENTITY_TYPES.RESIDENTIAL_LEAD:
                        path.name = 'ResidentialJobDetails';
                        path.params = {
                            lead_id: data.entity.id,
                        };
                }
               if (!data.entity.id){
                   return null;
               }
                return path.name ? path : null;
            },
            unixTime() {
                if (this.callData.unix_time) {
                    return moment.unix(this.callData.unix_time);
                }
                return '';
            },
            daysAgo() {
                if (this.unixTime) {
                    const calledDate = this.unixTime;
                    return calledDate.fromNow();
                }
                return this.no_info;
            },
            salesman() {
                const salesmanObj = this.callData.entity.salesman || null;
                if (salesmanObj) {
                    return `${salesmanObj.first_name || ''} ${salesmanObj.last_name || ''}`
                }
                return null;
            },
            jobNumber() {
                const entityType = this.callData.entity_type;
                let jobNumber = this.callData.entity.job_number || null;
                const isNoJobNumber = entityType === ENTITY_TYPES.COMMERCIAL_LEAD
                    || entityType === ENTITY_TYPES.RESIDENTIAL_LEAD
                    || entityType === ENTITY_TYPES.RESIDENTIAL_JOB;
                
                if (isNoJobNumber){
                    jobNumber = '-'
                }
                return jobNumber
            },
            phone() {
                return this.callData.caller_number_bare || this.no_info;
            },
            colorBorder() {
                switch (this.callData.direction) {
                    case STATUS_CALL_TYPES.OUTBOUND:
                        return 'green'
                    case STATUS_CALL_TYPES.INBOUND:
                        return 'yellow'
                    default:
                        return 'red'
                }
            }
        },
        props: {
            isActive: {
                type: Boolean,
                default: false
            },
            callData: {
                type: Object,
                default: {},
                required: true
            }
        }
    }
</script>
