import moment from 'moment/src/moment';
const EXPECTED_REVENUE_DB_NAME = 'DashboardExpectedRevenueReport';

export const manipulationIndexedDB = {
    methods: {
        createIndexedDBSchema(dbName, dbStoreName) {
            let db;
            let dbReq = indexedDB.open(dbName, 1);

            dbReq.onupgradeneeded = (event) => {
                // Save link on DB
                db = event.target.result;
                // Create an object store or get it if it already exists.
                let reportStore;
                if (!db.objectStoreNames.contains(dbStoreName)) {
                    reportStore = db.createObjectStore(dbStoreName);
                } else {
                    reportStore = dbReq.transaction.objectStore(dbStoreName);
                }
                // Create timestamp index
                if (!reportStore.indexNames.contains('timestamp')) {
                    reportStore.createIndex('timestamp', 'timestamp');
                }
            }

            dbReq.onsuccess = (event) => {}

            dbReq.onerror = (event) => {
                this.notifyError(`Initialization ${dbName} DB: ${event.target.error.message}`);
            }
        },
        makeReportKey() {
            let salesmanKey = this.filters.salesman.value 
            ? `${this.filters.salesman.value}-` 
            : '';
            let defaultDateKey = this.filters.defaultDate
                ? `${this.filters.defaultDate}`
                : '';
            let startDay = defaultDateKey === 'custom' 
                ? moment(this.filters.customDate.start).format('YYYY-MM-DD')
                : '';
            let endDay = defaultDateKey === 'custom' 
                ? moment(this.filters.customDate.end).format('YYYY-MM-DD')
                : '';
            let dateRange = defaultDateKey === 'custom'
                ? `-${startDay}-${endDay}` : '';

            return `${salesmanKey}${defaultDateKey}${dateRange}`;
        },
        addReportToIndexedDB(dbName, dbStoreName) {
            let db;
            let dbReq = indexedDB.open(dbName, 1);

            dbReq.onsuccess = (event) => {
                db = event.target.result;
                // Let's start a Indexed DB transaction and get an object store.
                let tx = db.transaction([dbStoreName], 'readwrite');
                let store = tx.objectStore(dbStoreName);
                // Get all records from the storage by key.
                let reportKey = this.makeReportKey();
                let record = store.get(reportKey);

                record.onsuccess = (event) => {
                    let report = event.target.result;
                    let reportForSave;

                    if (dbName === EXPECTED_REVENUE_DB_NAME) {
                        reportForSave = {
                            total_expected_revenue: this.total_expected_revenue,
                            total_base_bid: this.total_base_bid,
                            timestamp: Date.now()
                        };
                    } else {
                        reportForSave = {
                            ...this.reportData,
                            timestamp: Date.now()
                        };
                    }

                    if (report) {
                        let timeMark = record.result.timestamp;

                        // Delete reports if timestamp has been expired.
                        // Report lives 5 minute (300000 miliseconds).
                        if (Date.now() - timeMark > 300000) {
                            const req = store.getKey(reportKey);

                            req.onsuccess = (event) => {  
                                const key = req.result;
                                // Delete all records from the storage by key.
                                let deleteRequest = store.delete(key);

                                deleteRequest.onsuccess = (event) => {
                                    // Save curent page.
                                    store.add(reportForSave, reportKey);
                                };
                            };
                        }
                    } else {
                        store.add(reportForSave, reportKey);
                    }
                }
                // Handle error
                record.onerror = (event) => {
                    this.notifyError(`${dbName} DB Error: ${event.target.error.message}`);
                }
                
                tx.oncomplete = () => {}

                tx.onerror = (event) => {
                    this.notifyError(`${dbName} DB Error: ${event.target.error.message}`);
                }
            }
        },
        getReportsFromIndexedDB(dbName, dbStoreName) {
            let db;
            let dbReq = indexedDB.open(dbName, 1);

            dbReq.onsuccess = (event) => {
                db = event.target.result;
                // Let's start a Indexed DB transaction and get an object store.
                let tx = db.transaction([dbStoreName], 'readwrite');
                let store = tx.objectStore(dbStoreName);
                // Get all records from the storage by key. The key is the chosen milestone.
                let reportKey = this.makeReportKey();
                let record = store.get(reportKey);

                record.onsuccess = (event) => {
                    let report = event.target.result;

                    if (report) {
                        let timeMark = record.result.timestamp;

                        // If timestamp has been expired or reports not found, get reports from API.
                        // Else get report from Indexed Db.
                        if (Date.now() - timeMark > 300000) {
                            this.getReport();
                        } else {
                            if (dbName === EXPECTED_REVENUE_DB_NAME) {
                                this.total_expected_revenue = report.total_expected_revenue;
                                this.total_base_bid = report.total_base_bid;
                            } else {
                                let savedReport = report;
                                delete report.timestamp;
                                this.reportData = savedReport;
                            }
                        }
                    } else {
                        // If reports by key does not exist, get reports from API.
                        this.getReport();
                    }
                }
                // Handle error
                record.onerror = (event) => {
                    this.notifyError(`${dbName}  DB Error: ${event.target.error.message}`);
                }
                
                tx.oncomplete = (event) => {}

                tx.onerror = (event) => {
                    this.notifyError(`${dbName}  DB Error: ${event.target.error.message}`);
                }
            }
        }
    }
};