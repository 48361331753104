<template>
    <div
        class="bg-white mb-5 pv-15 pl-60 pr-20 row-start contact-call-card pointer"
        :class="isPreviewHandler && isPreview ? 'active_preview' : ''"
        v-on="isPreviewHandler ? {click: openPreview} : {}"
    >
        <div class="contact-call-card__call-btn">
            <IncomingCall
                class="pointer"
                v-if="call.direction === STATUS_CALL_TYPES.OUTBOUND"
            />
            <OutgoingCall
                class="pointer"
                v-else
            />
        </div>
        <div class="lg-3 column-start">
            <p class="f-12-grey mb-2">
                Contact name:
                <span :class="call.contact_name ? 'f-12-darkgrey' : 'f-12-grey f-italic'">
                    {{ call.contact_name || no_info }}
                </span>
            </p>
            <p class="f-12-grey">
                Username:
                <span :class="call.username !== '-' ? 'f-12-darkgrey' : 'f-12-grey f-italic'">
                    {{ call.username === '-' ? no_info : call.username }}
                </span>
            </p>
        </div>
        <div class="lg-3 column-start">
            <p class="f-12-grey w-100 row-baseline">
                <span class="mr-5">Contact number:</span>
                <contact-list
                    v-if="call.caller_number_bare"
                    :items="[call.caller_number_bare]"
                    :entityId="+contactId"
                    :text-class="'f-12-darkgrey'"
                    :icon-btn="'call-btn'"
                    type="phone"
                    :alignClass="''"
                    :representativesEntity="entity_type"
                />
                <span
                    v-else
                    class="f-12-grey f-italic"
                >
                    {{ no_info }}
                </span>
            </p>
            <p class="f-12-grey mb-2">
                Notes:
                <span :class="call.notes ? 'f-12-darkgrey' : 'f-12-grey f-italic'">
                    {{ call.notes || no_info }}
                </span>
            </p>
        </div>
        <div
            class="column-start"
            :class="isPreview ? 'lg-2' : 'lg-3'"
        >
            <p class="f-12-grey mb-2">
                Call duration:
                <span :class="callDuration != no_info ? 'f-12-darkgrey' : 'f-12-grey f-italic'">
                    {{ callDuration }}
                </span>
            </p>
            <p class="f-12-grey">
                Status:
                <span :class="call.direction ? 'f-12-darkgrey' : 'f-12-grey f-italic'">
                    {{ call.direction || no_info }}
                </span>
            </p>
        </div>
        <div class="lg-2 column-start" v-if="isPreviewHandler && isPreview" @click.stop="hideHandler">
            <p class="f-12-grey mb-2">Recording:</p>
            <AudioPlayer
                :keyAudio="call.call_id"
                v-if="call.audio"
                :value="call.audio"
                :is-download="true"
            />
            <span v-else class="f-12-grey f-italic">No recording</span>
        </div>
        <div
            class="column-end contact-call-card__date"
            :class="isPreview ? 'lg-2' : 'lg-3'"
        >
            <p class="f-12-grey mb-2">
                <span
                    class="f-12-grey"
                    :class="daysAgo === no_info ? 'f-italic' : ''"
                >
                    {{ daysAgo }}
                </span>
            </p>
            <p class="f-12-grey mb-2">
                <span
                    v-if="unixTime"
                    class="f-12-grey"
                >
                    {{ unixTime | date }} at {{ unixTime | time }}
                </span>
                <span
                    v-else
                    class="f-12-grey f-italic"
                >
                    {{ no_info }}
                </span>
            </p>
        </div>
    </div>
</template>

<script>
    import {IncomingCall, OutgoingCall, CallAgain} from '@/icons';
    import ContactList from '@/components/CommercialJobs/ContactList.vue';
    import {STATUS_CALL_TYPES} from '@/constants';
    import {AudioPlayer} from '@/ui';
    import moment from "moment";
    
    export default {
        name: 'ContactCallCard',
        data() {
            return {
                no_info: 'No information',
                STATUS_CALL_TYPES
            }
        },
        components: {
            IncomingCall,
            OutgoingCall,
            ContactList,
            AudioPlayer,
            CallAgain
        },
        props: {
            contactId: {
                type: String | Number | null,
                default: null
            },
            call: {
                type: Object,
                default: {},
                required: true
            },
            entity_type: {
                type: String,
                default: ''
            },
            isPreview: {
                type: Boolean,
                default: false
            },
            isPreviewHandler: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            callDuration() {
                const time = this.call.duration;
                if (time) {
                    return moment.utc(time * 1000).format('HH:mm:ss')
                }
                return this.no_info;
            },
            unixTime() {
                if (this.call.unix_time) {
                    return moment.unix(this.call.unix_time);
                }
                return '';
            },
            daysAgo() {
                if (this.unixTime) {
                    const calledDate = this.unixTime;
                    return calledDate.fromNow();
                }
                return this.no_info;
            }
        },
        methods: {
            hideHandler(){}, //A stub for bubbling a parent event
            openPreview() {
                this.$emit('openPreview', this.call.call_id);
            }
        }
    }
</script>
