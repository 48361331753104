<template>
    <full-modal 
        :withSearch="true"
        :searchRequestLength="!!needToFind.length"
        @cancelSearch="cancelSearch"
        @close="$emit('close')"
        class="price-list-modal"
    >
        <div slot="title">Labors</div>
        <div slot="search">
            <input 
                type="text" 
                name="tag_search"
                placeholder="Search labor..."
                class="w-100 ph-2"
                v-model="needToFind"
                @input="searchLabor"
            />
        </div>
        <div 
            slot="body" 
            class="relative"
        >
            <loader 
                v-if="loading || updating"
                class="w-100 h-100"
            />
            <div 
                class="column-start w-100"
                v-if="!loading"
            >
                <p class="f-14-black f-sbold pb-4">Filters:</p>
                <div class="row-start pb-30">
                    <label
                        v-for="trade in tradeList"
                        :key="trade.id"
                        :class="[
                            'filter-button mr-2 mb-2 relative', 
                            { 'active': tradeIsActive(trade.id), 'pointer': laborsLength }
                        ]"
                    >
                        <span 
                            class="mr-4" 
                            v-html="trade.icon_code"
                        /> 
                        <input 
                            type="checkbox" 
                            :name="`filter-button-${trade.id}`"
                            v-model="filterTrades"
                            :value="trade.id"
                            @change="getLabors(FILTER)"
                        />
                        {{ trade.name }}
                    </label>
                </div>
                <div 
                    class="w-100"
                    v-if="laborsLength"
                >
                    <div 
                        class="row-between pt-30 pb-4 bt-grey"
                        v-if="filterTradesLength"
                    >
                        <div class="column-start lg-9 sm-12 pr-4">
                            <p class="f-12-asphalt pb-1">
                                Total labor in 
                                <span
                                    v-for="(tradeId, index) in filterTrades"
                                    :key="`trade-name-${index}`"
                                >
                                    {{ `"${getTradeName(tradeId)}"${filterTradesLength - 1 == index ? ' ' : ','}` }}
                                </span>
                                - {{ totalLabors }}
                            </p>
                            <p class="f-12-grey f-italic">
                                Import All adds only labor that was not added previously to the price list. 
                                If labor is already added to the price list, 
                                Import All will ignore it not to create a duplicate. 
                            </p>
                        </div>
                        <div class="lg-3 sm-12 s-align-6">
                            <button 
                                class="view-details-primary"
                                @click="importAllLabors"
                            >
                                Import All
                            </button>
                        </div>
                    </div>
                    <div class="column-start w-100">
                        <div 
                            class="labor-item mb-4 w-100"
                            v-for="(labor, index) in labors"
                            :key="`labor-${index}`"
                        >
                            <div class="row-between">
                                <div class="column-start lg-8 md-8 sm-12 f-sbold mb-mob-1">
                                    <div class="w-100 s-align-4">
                                        <div class="s-align-5 trade-label mr-8">
                                            <span
                                                class="mr-1"
                                                v-html="getTradeIcon(labor.trade_id)"
                                            />
                                            <span class="f-10-blue f-sbold l-spacing-02">
                                                {{ getTradeName(labor.trade_id) }}
                                            </span>
                                        </div>
                                        <div class="s-align-4">
                                            <span 
                                                class="added-label s-align-5 mr-8"
                                                v-if="laborIsAddedToPriceList(labor.id)"
                                            >
                                                <span class="added-mark s-align-5">
                                                    <mark-icon 
                                                        size="7" 
                                                        class="i-white"
                                                    />
                                                </span>
                                                <span class="f-10-green pl-1">Added</span>
                                            </span>
                                        </div>
                                    </div>
                                    <p class="f-14-black pt-4 w-100">{{ labor.name }}</p>
                                    <p class="f-12-grey w-100">
                                        Cost:
                                        <span class="f-12-black mr-2">
                                            {{ +labor.cost == 0 ? '-' : +labor.cost | currency }}
                                        </span>
                                        Unit:
                                        <span class="f-12-black">
                                            {{ labor.order_unit }}
                                        </span>
                                    </p>
                                </div>
                                <div class="lg-4 md-4 sm-12 s-align-6">
                                    <button 
                                        class="service-middle-button"
                                        @click="addLaborToPriceList(labor)"
                                        :disabled="laborIsAddedToPriceList(labor.id)"
                                    >
                                        <span class="f-12-blue f-sbold">Add</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row-center pv-8">
                        <load-more
                            @click.native="getLabors(LOAD_MORE)" 
                            v-if="isMorePages"
                            :status="loadingStatus"
                        />
                    </div>
                </div>
                <div 
                    class="row-center t-center pv-8"
                    v-else
                >
                    <span class="f-14-grey">There is no labor.</span>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="row-end">
                <button 
                    class="primary-button"
                    @click="$emit('close')"
                >
                    Done!
                </button>
            </div>
        </div>
    </full-modal>
</template>

<script>
    import { CloseIcon, SearchIcon, MarkIcon, GearIcon } from '@/icons';
    import { FullModal, Loader, LoadMore } from '@/ui';
    import debounce from 'lodash/debounce';
    import api from '@/headers.js';

    // actions
    const INIT = 'init';
    const FILTER = 'filter';
    const LOAD_MORE = 'more';
    const SEARCH = 'search';
    const CANCEL = 'cancel';

    export default {
        name: 'PriceListModal',
        props: {
            tradeList: {
                type: Array,
                required: true
            },
            existingLabors: {
                type: Array,
                required: true
            }
        },
        components: {
            FullModal,
            CloseIcon,
            SearchIcon,
            MarkIcon,
            GearIcon,
            Loader,
            LoadMore
        },
        data() {
            return {
                needToFind: '',
                loading: false,
                updating: false,
                loadingStatus: false,
                filterTrades: [],
                labors: [],
                isMorePages: false,
                currentPage: 0,
                selectedLabors: [],
                FILTER: FILTER,
                LOAD_MORE: LOAD_MORE,
                totalLabors: 0,
                defaultRerPage: 50
            }
        },
        computed: {
            laborsLength() {
                return this.labors.length;
            },
            gotTrades() {
                return this.tradeList.map(trade => trade.id);
            },
            filterTradesLength() {
                return this.filterTrades.length;
            }
        },
        methods: {
            getLabors(action) {
                if (action == SEARCH && !this.needToFind.length) {
                    return;
                }
                
                if (action == FILTER || action == SEARCH || action == CANCEL) {
                    this.currentPage = 1;
                    this.updating = true;
                } else {
                    this.currentPage += 1;
                }

                this.loading = action == INIT;
                this.loadingStatus = action == LOAD_MORE;

                let params = {
                    type: 'labors',
                    page: this.currentPage,
                    trades: !this.filterTradesLength ? this.gotTrades : this.filterTrades,
                    per_page: this.defaultRerPage
                };

                if (action == SEARCH) {
                    Object.assign(params, { search_value: this.needToFind });
                }

                api.get(`/company-library`, { params })
                    .then(response => response.data.data)
                    .then(data => {
                        action == LOAD_MORE
                            ? this.labors.push(...data.labors.data)
                            : this.labors = data.labors.data;

                        this.isMorePages = data.labors.current_page != data.labors.last_page;
                        this.totalLabors = data.labors.total;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.loadingStatus = false; 
                        this.updating = false;

                        if (action != SEARCH) {
                            this.needToFind = '';
                        }
                    });
            },
            searchLabor: debounce(function () {
                this.getLabors(SEARCH);
            }, 500),
            cancelSearch() {
                this.currentPage = 0;
                this.getLabors(CANCEL);
            },
            tradeIsActive(id) {
                return !!this.filterTrades.find(t => t == id);
            },
            getTradeName(id) {
                return this.tradeList.find(t => t.id === id).name;
            },
            getTradeIcon(id) {
                return this.tradeList.find(t => t.id === id).icon_code;
            },
            addLaborToPriceList(labor) {
                this.selectedLabors.push(labor.id);
                this.$emit('addLabor', [labor]);
            },
            importAllLabors() {
                if (this.defaultRerPage >= this.totalLabors) {
                    this.addLaborsToPriceList(this.labors);
                    return;
                }

                this.updating = true;

                let params = {
                    type: 'labors',
                    trades: this.filterTrades,
                    per_page: this.totalLabors
                };

                api.get(`/company-library`, { params })
                    .then(response => response.data.data)
                    .then(data => {
                        this.addLaborsToPriceList(data.labors.data);
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.updating = false;
                    });
            },
            addLaborsToPriceList(arr) {
                arr.map((labor) => {
                    this.selectedLabors.push(labor.id);
                });
                this.$emit('addLabor', arr);
            },
            laborIsAddedToPriceList(id) {
                return !!this.selectedLabors.find(l => l == id);
            }
        },
        created() {
            this.getLabors(INIT);
            this.selectedLabors = this.existingLabors;
        }
    }
</script>