<template>
    <full-modal @close="$emit('close')">
        <div slot="title">
            Custom {{ typeName }}
        </div>
        <div slot="body">
            <form-input
                :label="`Custom ${typeName} title`"
                :class="[
                    'mb-7',
                    {'form-input-danger' : errors.first('custom_item_name')}
                ]"
            >
                <input
                    class="form-input-field"
                    type="text"
                    name="custom_item_name"
                    :placeholder="`Custom ${typeName} title`"
                    v-model="name"
                    v-input-style="name"
                    maxlength="256"
                    v-validate="'required|min:3|max:255'"
                >
            </form-input>
            <button
                v-if="!showNotesInput"
                class="transparent-button f-14-blue mb-30"
                @click="showNotesInput = true"
            >
                + Add notes
            </button>
            <form-input-textarea
                v-else
                class="mb-20"
                label="Notes"
                v-input-counter="255"
            >
                <textarea
                    :class="
                        ['form-input-field',
                        { 'form-input-textarea-danger' : errors.has('item_description') }]
                    "
                    name="item_description"
                    rows="5"
                    placeholder="Notes"
                    maxlength="255"
                    v-input-style="description"
                    v-model="description"
                    v-validate="'required|min:3|max:254'"
                />
            </form-input-textarea>
            <form-input
                label="Quantity"
                :class="[
                    'form-input-with-select',
                    {'form-input-danger' : errors.first('custom_item_quantity')}
                ]"
            >
                <input
                    class="form-input-field"
                    name="custom_item_quantity"
                    v-input-style="quantity"
                    v-model.number="quantity"
                    type="text"
                    placeholder="Quantity"
                    v-validate="'required'"
                    autocomplete="off"
                    v-limiter
                />
                <multiselect
                    :options="CUSTOM_ITEM_UNITS_TYPES"
                    name="custom_item_per_unit"
                    data-name="custom_item_per_unit"
                    v-model="perUnit"
                    :max-height="200"
                    placeholder="Unit"
                    :show-labels="false"
                    :allow-empty="false"
                    :searchable="false"
                    v-validate="'required'"
                    :class="[
                        {'multiselect-danger' : errors.has('custom_item_per_unit')}
                    ]"
                />
            </form-input>
            <form-input
                label="Price / Unit"
                :class="[
                    'mt-7',
                    {'form-input-danger' : errors.first('price')}
                ]"
                maskBefore="$"
                :maskValue="cost"
                maskFilter="numberWithCommas"
            >
                <input
                    class="form-input-field"
                    type="text"
                    name="price"
                    placeholder="Price / Unit"
                    v-model.number="cost"
                    v-input-style="cost"
                    v-validate="'required'"
                    v-limiter
                >
            </form-input>
        </div>
        <div slot="footer">
            <div class="row-between">
                <button
                    class="add-button"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button
                    class="primary-button"
                    :disabled="requestIsGoing"
                    @click="createCustomItem"
                >
                    Create
                </button>
            </div>
        </div>
    </full-modal>
</template>

<script>
    import { CUSTOM_ITEM_UNITS_TYPES, MATERIAL, LABOR } from '@/constants.js';
    import { FullModal, FormInput, FormInputTextarea } from '@/ui';
    import Multiselect from 'vue-multiselect';
    import api from '@/headers.js';
    import { mask } from 'vue-the-mask';

    export default {
        name: 'OrderAddCustomItemModal',
        components: {
            FullModal,
            FormInput,
            FormInputTextarea,
            Multiselect
        },
        props: {
            typeId: {
                type: [String, Number],
                required: true
            },
            showInEstimates: {
                type: Boolean,
                required: false
            }
        },
        directives: {
            mask
        },
        data() {
            return {
                requestIsGoing: false,
                showNotesInput: false,
                name: '',
                description: '',
                quantity: 1,
                CUSTOM_ITEM_UNITS_TYPES: CUSTOM_ITEM_UNITS_TYPES,
                perUnit: '',
                cost: 0
            }
        },
        computed: {
            orderId() {
                return this.$route.params.order_id;
            },
            typeName() {
                switch (this.typeId) {
                    case MATERIAL:
                        return 'material';
                        break;
                    case LABOR:
                        return 'labor';
                        break;
                }
            }
        },
        methods: {
            createCustomItem() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('Please fill all required fields');
                        return;
                    }
                    const params = {
                        order_id: this.orderId,
                        name: this.name,
                        description: this.description,
                        quantity: this.quantity,
                        per_unit: this.perUnit,
                        type_id: this.typeId,
                        cost: this.cost
                    };

                    if (this.showInEstimates) {
                        this.$emit('onAddItem', params);
                        return;
                    }

                    this.requestIsGoing = true;
                    api.post('/orders/items', params)
                        .then(response => response.data.data)
                        .then(data => {
                            this.$emit('onAddItem', data);
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.requestIsGoing = false;
                        });
                });
            }
        },
        created() {
            // init default value
            this.perUnit = this.CUSTOM_ITEM_UNITS_TYPES[0];
        }
    }
</script>
