<template>
    <div class="company-library-table">
        <div class="table-head row-start pv-8">
            <div class="lg-7">
                <span class="f-12-black f-sbold">Name</span>
            </div>
            <div class="lg-2">
                <span class="f-12-black f-sbold">Estimate</span>
            </div>
            <div class="lg-2">
                <span class="f-12-black f-sbold">Order</span>
            </div>
        </div>
        <div class="table-body w-box">
            <div
                v-for="(item, index) in tableData"
                :key="index"
                :class="[
                    'table-row row-start row-baseline hidden relative',
                    index !== tableData.length - 1 ? 'bb-grey' : '',
                    index === openedTableIndex ? 'table-open' : '',
                    index === openedMenuIndex ? 'table-menu-open' : ''
                ]"
            >
                <div class="lg-7 nb-12 md-12 sm-12 row-between row-baseline">
                    <button
                        class="table-toggle icon-light"
                        @click="openedTableIndex = openedTableIndex !== index ? index : null"
                    >
                        <arrow-icon
                            :transform="index === openedTableIndex ? 270 : 90"
                        />
                    </button>
                    <div class="table-title f-13-black ellipsis s-align-4">
                        <div
                            v-if="item.trade_id && !isFilter"
                            class="table-title-trade f-sbold f-11-blue mr-6"
                        >
                            <span
                                class="mr-1"
                                v-html="tradeIcon(item.trade_id)"
                            >
                            </span>
                            {{ tradeName(item.trade_id) }}
                        </div>
                        {{ item.name }}
                    </div>
                    <button
                        class="table-context-menu-toggle icon-light"
                        @click="openedMenuIndex = openedMenuIndex !== index ? index : null"
                    >
                        <points-icon/>
                    </button>
                </div>
                <div class="table-cost lg-2 nb-12 md-12 sm-12 column-start">
                    <div class="value">
                        <p class="f-15-black f-sbold">
                            {{ displayCost('estimate_unit', item) | currency }}
                        </p>
                        <p class="description f-11-grey">Cost per {{ item.estimate_unit }}</p>
                    </div>
                    <span class="cost-title f-10-grey">
                        ESTIMATE
                    </span>
                </div>
                <div class="table-cost lg-2 nb-12 md-12 sm-12 column-start">
                    <div class="value">
                        <p class="f-15-black f-sbold">
                            {{ displayCost('order_unit', item) | currency }}
                        </p>
                        <p class="description f-11-grey">Cost per {{ item.order_unit }}</p>
                    </div>
                    <span class="cost-title f-10-grey">
                        ORDER
                    </span>
                </div>
                <div class="table-control row-around lg-1 ph-8">
                    <button
                        v-if="hasAccess"
                        class="icon-light"
                        @click="$emit('showEditModal', item)"
                    >
                        <edit-icon size="15"/>
                    </button>
                    <button
                        v-if="hasAccess"
                        class="icon-light"
                        @click="$emit('showDeleteConfirm', item)"
                    >
                        <trash-icon width="15" height="15"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { EditIcon, TrashIcon, ArrowIcon, PointsIcon } from '@/icons';

    export default {
        name: 'CompanyLibraryTable',
        components: {
            EditIcon, 
            TrashIcon, 
            ArrowIcon, 
            PointsIcon
        },
        props: {
            tableData: {
                type: Array,
                required: true
            },
            trades: {
                type: Array,
                default: function() {
                    return [];
                }
            }
        },
        data() {
            return {
                openedTableIndex: null,
                openedMenuIndex: null,
                hasAccess: false
            }
        },
        methods: {
            displayCost(name, item) {
                if (item[name] === item.per_unit) {
                    return item.cost;
                } else {
                    return Math.round((item.cost * item.estimate / item.order) * 100) / 100;
                }
            },
            tradeIcon(id) {
                return this.trades.find(el => el.id == id).icon_code;
            },
            tradeName(id) {
                return this.trades.find(el => el.id == id).name;
            }
        },
        computed: {
            isFilter() {
                return !!this.$route.query.filter
                    || !!this.$route.query.trade;
            }
        },
        created() {
            this.hasAccess = this.$can(this.$permissions.EDIT_COMPANY_SETTINGS);
        }
    }
</script>