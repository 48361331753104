var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'row-start row-baseline flex-no-wrap',
        _vm.mScreen ? 'p-4' : 'p-8',
        { 'mb-1' : _vm.index === 0 },
        { 'bb-grey' : _vm.index !== _vm.filesLength - 1 }
    ]},[(!_vm.file.is_smart)?_c('checkbox-button',{class:[_vm.mScreen? 'mr-5' : 'mr-30'],attrs:{"color":"blue"}},[_c('input',{attrs:{"type":"checkbox","disabled":_vm.checkIfDisabled(_vm.file.type)},domProps:{"checked":_vm.selectedFiles.some(function (i) { return i.id === _vm.file.id; })},on:{"change":function (e) { return _vm.toggleFileSelection(e, _vm.file); }}})]):_vm._e(),_c('div',{staticClass:"file-description row-start row-baseline"},[_c('p',{staticClass:"lg-5 sm-12 f-14-black"},[_c(_vm.getIcon(_vm.file),{tag:"component",staticClass:"mr-7",attrs:{"height":"19","width":"14"}}),_c('span',{staticClass:"f-13-black ellipsis"},[_vm._v("\n                "+_vm._s(_vm.file.original_name)+"\n            ")])],1),_c('p',{class:[ 
                'f-13-grey sm-12',
                _vm.type === _vm.FOLDER_TYPES.JOB_DOCUMENTS ? 'lg-3' : 'lg-7'
            ]},[_c('span',{staticClass:"ml-2"},[_vm._v("\n                Date:\n            ")]),_c('span',{staticClass:"f-13-black ml-1"},[_vm._v("\n                "+_vm._s(_vm._f("date")(_vm.file.created_at))+"\n            ")])]),(_vm.type === _vm.FOLDER_TYPES.JOB_DOCUMENTS)?_c('p',{staticClass:"lg-4 sm-12 f-13-grey"},[_vm._v("\n            Uploaded by:\n            "),_c('span',{staticClass:"f-13-black ml-1"},[_vm._v("\n                "+_vm._s(_vm.file.user.fullname)+"\n            ")])]):_vm._e()]),(!_vm.file.is_smart)?_c('button',{staticClass:"transparent-button s-align-6 f-13-blue mr-8",on:{"click":function($event){_vm.previewModalOpened = true}}},[_c('eye-icon',{staticClass:"mr-2 i-blue",attrs:{"size":"15"}}),(!_vm.mScreen)?[_vm._v("Preview")]:_vm._e()],2):_vm._e(),(_vm.file.is_smart || _vm.file.parent_id)?_c('button',{staticClass:"transparent-button s-align-6 f-13-blue no-wrap column-start",on:{"click":function($event){return _vm.makeLocalSmart(_vm.file)}}},[_c('div',{staticClass:"row-start s-align-5"},[_c('edit-icon',{staticClass:"mr-2 i-blue",attrs:{"size":"15"}}),_vm._v("\n            "+_vm._s(_vm.file.parent_id ? 'Edit' : 'Make Smart')+"\n        ")],1),_vm._v("    \n        (current record)\n    ")]):_vm._e(),(_vm.previewModalOpened)?_c('file-preview-modal',{attrs:{"file":_vm.file},on:{"close":function($event){_vm.previewModalOpened = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }