<template>
    <div class="form-builder-photo-form w-100">
        <div class="row-between">
            <div class="lg-8 sm-10 column-start">
                <p class="row-start row-baseline mb-1">
                    <attach-icon 
                        class="i-darkgrey mr-2" 
                        size="14"
                    />
                    <tooltip
                        v-if="field.parameters.show_tooltip"
                        direction="right"
                        :fixedDirection="true"
                    >
                        <span class="f-16-asphalt f-sbold">Attachments</span>
                        <template v-slot:content>
                            <p class="f-16-white">
                                {{ field.parameters.tooltip_text }}
                            </p>
                        </template>
                    </tooltip>
                    <span
                        v-else 
                        class="f-16-asphalt f-sbold"
                    >
                        Attachments
                    </span>
                </p>
                <p class="f-14-grey">
                    {{ field.label }}
                </p>
            </div>
            <div class="lg-4 sm-2 s-align-3">
                <label 
                    class="view-details-primary ph-4"
                >
                    Add Pictures
                    <input 
                        class="hide"
                        type="file"
                        name="image"
                        multiple
                        accept="image/*"
                        @change="addImage"
                    >
                </label>
            </div>
        </div>
        <div 
            v-if="images.length"
            class="form-builder-bar row-baseline w-100 mt-30"
        >
            <div class="form-builder-bar-photos">
                <label 
                    class="form-builder-bar-photo s-align-5 relative"
                    v-for="(image, index) in images"
                    :key="`photo-bar-${image.name}`"  
                    :ref="`image-${index}`"       
                >
                    <button 
                        class="delete-photo transparent-button"
                        @click="deleteImage(index)"
                    >
                        <close-icon class="i-red"/>
                    </button>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import { AttachIcon, CloseIcon } from '@/icons';
    import { Tooltip } from '@/ui';

    export default {
        name: 'CustomFieldPictureSection',
        components: {
            AttachIcon,
            CloseIcon,
            Tooltip
        },
        props: {
            field: {
                type: Object,
                required: true
            }
        },
        data() {
            return  {
                images: []
            }
        },
        methods: {
            addImage(e) {
                const maxPhotoSize = 9961472;
                let notification = '';
                let files = Object.values(e.target.files).filter(item => {
                    //9961472 - 9.5 MB in bytes. formula - (maxPhotoSize/1024)/1024
                    if (item.size > maxPhotoSize) {
                        notification += item.name + ', ';
                        return false;
                    }
                    return true;
                });
                if (notification != '') {
                    notification = notification.slice(0, notification.length -2);
                    this.notifyError(`Files with name ${notification} have size more then 10MB! Need less.`);
                }
                
                this.images.push(...files);

                for (let i=0; i < this.images.length; i++) {
                    let reader = new FileReader(); 
                    reader.addEventListener('load', function() {
                        this.$refs[`image-${i}`][0].style.backgroundImage = `url("${reader.result}")`;
                    }.bind(this), false); 
                    reader.readAsDataURL(this.images[i]);
                }

                this.$emit('updateImages', this.images, this.field.id);
            },
            deleteImage(index) {
                this.images.splice(index, 1);
                this.$emit('updateImages', this.images, this.field.id);
            }
        }
    }
</script>