<template>
    <div class="modal s-align-5">
        <div class="create-new-folder-modal cm-fond">
            <div class="f-24-black f-sbold mb-20 row-end">
                <button
                    class="transparent-button"
                    @click="$emit('close')"
                >
                    <close-icon
                        size="13"
                        class="i-grey"
                    />
                </button>
            </div>
            <div class="w-100">
                <div class="row-between">
                    <div class="f-24-black f-sbold mb-2 t-center">
                        {{ modalTitle }}
                    </div>
                </div>
            </div>
            <div class="custom-modal-footer row-center">
                <button
                    class="add-button mr-8"
                    @click="$emit('close')"
                >
                    Close Job
                </button>
                <button
                    class="primary-button"
                    @click="sendEmail"
                >
                    Send email
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { CloseIcon } from '@/icons';
import {BASE_ENTITY_TYPES} from '@/constants';

export default {
    name: 'SendReviewEmailModal',
    data() {
        return {
            BASE_ENTITY_TYPES
        }
    },
    props: {
        entityType: {
            type: String,
            required: true
        }
    },
    components: {
        CloseIcon
    },
    computed: {
        modalTitle() {
            return this.entityType === BASE_ENTITY_TYPES.RESIDENTIAL ?
                'Do you want to send Review email to customer?' :
                'Do you want to send Review email to primary contact?'
        }
    },
    methods: {
        sendEmail() {
            this.$emit('sendEmail');
            this.$emit('close');
        }
    }
}
</script>