<template>
    <svg 
	    viewBox="0 0 982.727294921875 980"
        :height="size" 
        :width="size"
        class="round-warning-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0"
            width="982.727294921875" 
            height="980" 
            style="fill-opacity: 0;"
        />
        <g transform="matrix(61.25, 0, 0, 61.25, 1.363555908203125, 0)">
            <path 
                fill-rule="evenodd" 
                d="M8 0C3.582 0 0 3.582 0 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zm-.868 4.292c-.024-.479.345-.887.824-.911.478-.024.886.344.91.823.002.029.002.059 0 .088v4.423c-.002.476-.389.861-.865.861-.48 0-.869-.389-.869-.868V4.292zm.869 8.328c-.48 0-.869-.389-.869-.868 0-.48.389-.868.869-.868.479 0 .868.388.868.868 0 .479-.389.868-.868.868z"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'RoundWarningIcon',
        props: {
            size: {
                type: [Number, String],
                default: 16
            }
        }
    }
</script>