<template>
    <svg 
        viewBox="0 0 245.66973876953125 244.59825134277344"
        class="video-icon"
        :height="size"
        :width="size"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="245.66973876953125" 
            height="244.59825134277344" 
            style="fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(5.7350006103515625, 0, 0, 5.7350006103515625, 2605.918041229248, -1475.2799072265625)">
            <path 
                class="st0" 
                d="M-433,258.5c-11,0-20,9-20,20c0,11,9,20,20,20c11,0,20-9,20-20C-413,267.5-422,258.5-433,258.5z" 
                style="fill: none; stroke-width: 2; stroke-miterlimit: 10;"
            />
            <path d="M-425.2,279.5l-12,6.5c-0.8,0.4-1.7-0.1-1.7-1v-13c0-0.9,0.9-1.4,1.7-1l12,6.5C-424.4,277.9-424.4,279.1-425.2,279.5z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'VideoIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>