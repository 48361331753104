<template>
    <div class="w-100">
        <div
            :class="[
                'form-builder-section row-start',
                dScreen ? 'ph-30' : 'ph-8'
            ]"
        >
            <div class="row-start row-baseline mb-8">
                <div class="lg-5 md-6 sm-12 column-start">
                    <div class="f-15-darkgrey f-sbold">Dates</div>
                    <div class="f-13-grey mb-2">
                        Select date
                    </div>
                </div>
                <div class="lg-7 md-6 sm-12 row-start flex-no-wrap">
                    <form-datepicker
                        v-model="report.dateOfInvoice"
                        keyDatePicker="report_name_date_picker"
                        :isFullWidth="true"
                        placeholder="Date of Invoice"
                        class="mr-4"
                    />
                    <form-datepicker
                        v-model="report.dateOfService"
                        keyDatePicker="date_of_service_date_picker"
                        :isFullWidth="true"
                        placeholder="Date of Service"
                    />
                </div>
            </div>
            <div class="row-start row-baseline mb-2 s-align-1 bt-grey pt-8">
                <div class="lg-5 md-6 sm-12 column-start pv-8">
                    <div class="f-15-darkgrey f-sbold">Information</div>
                </div>
                <div class="lg-7 md-6 sm-12 row-start">
                    <div class="w-100 pb-4">
                        <form-input label="Invoice to">
                            <input
                                type="text"
                                name="invoice_to"
                                class="form-input-field"
                                placeholder="Invoice to"
                                v-input-style="report.invoiceTo"
                                v-model="report.invoiceTo"
                            />
                        </form-input>
                    </div>
                    <div class="row-start">
                        <div class="lg-6 md-6 sm-12 pr-2 pr-sm-0 pb-4">
                            <form-input label="RAF Reference #">
                                <input
                                    type="text"
                                    name="invoice_number"
                                    class="form-input-field"
                                    placeholder="RAF Reference #"
                                    v-input-style="report.invoiceNumber"
                                    v-model="report.invoiceNumber"
                                />
                            </form-input>
                        </div>
                        <div class="lg-6 md-6 sm-12 pl-2 pl-sm-0 pb-4">
                            <form-input label="Reference Code">
                                <input
                                    type="text"
                                    name="reference_code"
                                    class="form-input-field"
                                    placeholder="Reference Code"
                                    v-input-style="report.referenceCode"
                                    v-model="report.referenceCode"
                                />
                            </form-input>
                        </div>
                    </div>
                    <div class="row-start">
                        <div class="lg-6 md-6 sm-12 pr-2 pr-sm-0 pb-4">
                            <form-input label="Attn">
                                <input
                                    type="text"
                                    name="attn"
                                    class="form-input-field"
                                    placeholder="Attn"
                                    v-input-style="report.attn"
                                    v-model="report.attn"
                                />
                            </form-input>
                        </div>
                        <div class="lg-6 md-6 sm-12 pl-2 pl-sm-0">
                            <form-input
                                label="Email"
                                :class="[{ 'form-input-danger' : errors.has('email') }]"
                            >
                                <input
                                    type="email"
                                    name="email"
                                    class="form-input-field"
                                    placeholder="Email"
                                    v-input-style="report.email"
                                    v-model="report.email"
                                    v-validate="'email'"
                                />
                            </form-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-start row-baseline mb-2 s-align-1 bt-grey pt-8">
                <div class="lg-5 md-6 sm-12 column-start pv-8">
                    <div class="f-15-darkgrey f-sbold">Address</div>
                </div>
                <div class="lg-7 md-6 sm-12 row-start">
                    <address-box
                        type="address"
                        :key="'address'"
                        :existing="report.address"
                        :validate="VALIDATE_NOT_REQUIRED"
                        @address="getAddress"
                    />
                </div>
            </div>
            <div class="row-start row-baseline mb-2 s-align-1 bt-grey pt-8">
                <div class="lg-5 md-6 sm-12 column-start pv-8">
                    <div class="f-15-darkgrey f-sbold">Payment Applied</div>
                </div>
                <div class="lg-7 md-6 sm-12 row-start">
                    <div class="row-between pb-30 w-100">
                        <div class="lg-5 md-5 sm-12 pb-4">
                            <form-input
                                label="Applied"
                                maskBefore="$"
                                :maskValue="report.applied"
                                maskFilter="numberWithCommas"
                            >
                                <input
                                    type="text"
                                    name="applied"
                                    class="form-input-field"
                                    placeholder="$0.00"
                                    v-model.number="report.applied"
                                    v-input-style="report.applied"
                                    v-limiter
                                />
                            </form-input>
                        </div>
                        <div class="lg-7 md-7 sm-12 column-end">
                            <span class="f-16-darkgrey f-sbold pb-2">
                                Total:
                                <span class="f-18-black f-normal pl-4">{{ total | currency }}</span>
                            </span>
                            <span class="f-16-darkgrey f-sbold">
                                Balance DUE:
                                <span class="f-18-black f-normal pl-4">{{ +due | currency }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-builder-section bb-grey">
            <div
                :class="[
                    'services-performed',
                    dScreen ? 'p-30' : 'p-8'
                ]"
            >
                <div class="row-start row-baseline mb-2 s-align-1">
                    <div class="lg-5 md-6 sm-12 column-start pv-8">
                        <div class="f-15-darkgrey f-sbold">Services Performed</div>
                    </div>
                    <div class="lg-7 md-6 sm-12 row-start">
                        <div
                            class="row-start"
                            v-for="(item, index) in report.amount"
                            :key="index"
                        >
                            <div class="lg-11 md-12 sm-12 column-start">
                                <div class="lg-12 md-12 sm-12 pb-4">
                                    <multiselect
                                        :options="descriptionOptions"
                                        name="description"
                                        data-name="description"
                                        placeholder="Description"
                                        v-model="item.descriptionAmount"
                                        :taggable="true"
                                        :multiple="false"
                                        :allow-empty="true"
                                        :searchable="true"
                                        :show-labels="false"
                                        @tag="addNewDescriptionTag($event, index)"
                                    />
                                </div>
                                <div class="row-start">
                                    <div class="lg-4 md-4 sm-12 pb-4 pr-2 pr-sm-0">
                                        <form-input label="Hours">
                                            <input
                                                type="number"
                                                name="hours"
                                                class="form-input-field"
                                                placeholder="Hours"
                                                v-input-style="item.hours"
                                                v-model="item.hours"
                                                @input="checkServicesPerformed(index, +item.hours, +item.rate, item.amount)"
                                            />
                                        </form-input>
                                    </div>
                                    <div class="lg-4 md-4 sm-12 pb-4 pr-2 pr-sm-0">
                                        <form-input
                                            label="Rate"
                                            maskBefore="$"
                                            :maskValue="item.rate"
                                            maskFilter="numberWithCommas"
                                        >
                                            <input
                                                type="text"
                                                name="rate"
                                                class="form-input-field"
                                                placeholder="$0.00"
                                                v-model="item.rate"
                                                v-input-style="item.rate"
                                                v-limiter
                                                @input="checkServicesPerformed(index, +item.hours, +item.rate, item.amount)"
                                            />
                                        </form-input>
                                    </div>
                                    <div class="lg-4 md-4 sm-12 pb-4">
                                        <form-input
                                            label="Amount"
                                            maskBefore="$"
                                            :maskValue="item.amount"
                                            maskFilter="numberWithCommas"
                                        >
                                            <input
                                                type="text"
                                                name="amount"
                                                class="form-input-field"
                                                placeholder="$0.00"
                                                v-model="item.amount"
                                                v-input-style="item.amount"
                                                v-limiter
                                                @input="checkServicesPerformed(index, +item.hours, +item.rate, item.amount)"
                                            />
                                        </form-input>
                                    </div>
                                </div>
                            </div>
                            <div class="lg-1 md-12 sm-12 pb-4 s-align-6">
                                <button
                                    class="icon-light"
                                    :disabled="index == 0"
                                    @click="removeAmountField(index)"
                                >
                                    <trash-icon/>
                                </button>
                            </div>
                        </div>
                        <div class="lg-11 md-12 sm-12">
                            <button
                                class="light-blue-button"
                                @click="addAmountField"
                            >
                                <span class="add-icon s-align-5 mr-8">+</span>
                                Add line item
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row-start row-baseline mb-2 s-align-1">
                    <div class="lg-5 md-6 sm-12 column-start pv-8">
                        <div class="f-15-darkgrey f-sbold">Description of Services</div>
                    </div>
                    <div class="lg-7 md-6 sm-12 row-start">
                        <form-input-textarea
                            label="Description of Services"
                            v-input-counter="1000"
                        >
                            <textarea
                                rows="3"
                                name="description-of-services"
                                class="form-input-field"
                                placeholder="Description of Services..."
                                v-model="report.descriptionOfServices"
                                v-input-style="report.descriptionOfServices"
                                maxlength="1000"
                                id="description-of-services"
                                v-resize-textarea
                            />
                        </form-input-textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-builder-custom-section bt-grey">
            <form-builder-custom-fields
                :layoutId="page.id"
                :updateCustomFields="updateCustomFields"
                :entityType="entityType"
                @changeFields="changeCustomFields"
                :clear="clear"
            />
        </div>
    </div>
</template>

<script>
    import { FormInput, FormDatepicker, FormInputTextarea } from '@/ui';
    import { FORM_BUILDER_REPORTS, INVOICE_REPORT_DESCRIPTION_OPTIONS, ENTITY_TYPES } from '@/constants';
    import { TrashIcon } from '@/icons';
    import AddressBox from '@/components/AddressBox.vue';
    import Multiselect from 'vue-multiselect'; 
    import FormBuilderCustomFields from '@/components/LeadModules/FormBuilder/FormBuilderCustomFields.vue';

    const REPORT_TYPE = 'invoice';
    const FORM_ACTION_INIT = 'init';
    const FORM_ACTION_CLEAR = 'clear';

    export default {
        name: 'FormBuilderInvoiceReport',
        props: {
            clear: {
                type: Boolean,
                default: false
            },
            get: {
                type: Boolean,
                default: false
            },
            selected: {
                type: Object,
                required: false
            },
            entityType: {
                typs: String,
                required: true
            },
            page: {
                type: Object,
                required: false
            }
        },
        components: {
            FormInput,
            FormDatepicker,
            FormInputTextarea,
            AddressBox,
            Multiselect,
            TrashIcon,
            FormBuilderCustomFields
        },
        data() {
            return {
                report: {},
                descriptionOptions: [],
                updateCustomFields: {}
            }
        },
        watch: {
            clear: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal) this.createNewReport(FORM_ACTION_CLEAR);
                }
            },
            get: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal) this.setInvoice();
                }
            },
            selected: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal != null && newVal != oldVal) {
                        this.report = this.selected;
                        this.updateCustomFields = this.selected.customFields;
                    }
                }
            }
        },
        computed: {
            entityAddress() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: {
                        return this.$store.getters['lead/getClippedLeadAddress'];
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_JOB: {
                        return this.$store.getters['commercial/getCommercialJobAddress'];
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return this.$store.getters['commercial/getCommercialLeadAddress'];
                    } break;
                }
            },
            total() {
                return this.report.amount.reduce(function(sum, current) {
                    return sum += +current.amount;
                }, 0);
            },
            due() {
                return (this.total - this.report.applied).toFixed(2);
            },
            jobNumber() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: {
                        return this.$store.getters['lead/getJobNumber'];
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_JOB: {
                        return this.$store.getters['commercial/getCommercialJobNumber'];
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return this.$store.getters['commercial/getCommercialLeadNumber'];
                    } break;
                }
            }
        },
        methods: {
            createNewReport(flag) {
                this.report = {
                    title: FORM_BUILDER_REPORTS.INVOICE,
                    reportType: REPORT_TYPE,
                    reportName: '',
                    invoiceNumber: !this.jobNumber ? '' : this.jobNumber,
                    dateOfInvoice: new Date(),
                    dateOfService: null,
                    invoiceTo: '',
                    referenceCode: '',
                    attn: '',
                    email: '',
                    address: {
                        address: '',
                        street: '',
                        city: '',
                        state: '',
                        zip: ''
                    },
                    descriptionOfServices: '',
                    reportProvided: '',
                    amount: [{
                        descriptionAmount: '',
                        hours: '',
                        rate: '',
                        amount: ''
                    }],
                    total: 0,
                    applied: 0,
                    due: 0,
                    customFields: {}
                }

                if (flag == FORM_ACTION_CLEAR)
                    document.getElementById('description-of-services').style.height = 'auto';
            },
            getAddress(data) {
                this.report.address = data;
            },
            addAmountField() {
                this.report.amount.splice(this.report.amount.length + 1, 0, {
                    descriptionAmount: '',
                    hours: '',
                    rate: '',
                    amount: ''
                });
            },
            removeAmountField(index) {
                this.report.amount.splice(index, 1);
            },
            addNewDescriptionTag(newTag, index) {
                this.descriptionOptions.push(newTag);
                this.report.amount[index].descriptionAmount = newTag;
            },
            setInvoice() {
                this.report.total = this.total;
                this.report.due = this.due;

                this.$emit('receive', this.report);
            },
            checkServicesPerformed(index, hours, rate, amount) {
                if (hours == 0 && rate == 0) {
                    this.report.amount[index].hours = this.report.amount[index].rate = '';
                } else {
                    this.report.amount[index].amount = hours * rate;
                }
            },
            changeCustomFields(data) {
                this.report.customFields = data;
            }
        },
        created() {
            this.VALIDATE_NOT_REQUIRED = '';
            this.descriptionOptions = INVOICE_REPORT_DESCRIPTION_OPTIONS;
            this.createNewReport(FORM_ACTION_INIT);
            this.report.address = this.entityAddress;
        }
    }
</script>