<template>
    <svg 
        viewBox="0 0 512 512"
        :width="size"
        :height="size"
        class="life-buoy-icon"
    >
        <g>
            <path d="M256,23.3v23.3c57.9,0,110.1,23.4,148.1,61.3c37.9,38,61.3,90.2,61.3,148.1c0,57.9-23.4,110.1-61.3,148.1c-38,37.9-90.2,61.3-148.1,61.3c-57.9,0-110.1-23.4-148.1-61.3C70,366.1,46.6,313.9,46.5,256c0-57.9,23.4-110.1,61.3-148.1c38-37.9,90.2-61.3,148.1-61.3V23.3V0C114.6,0,0,114.6,0,256c0,141.4,114.6,256,256,256c141.4,0,256-114.6,256-256C512,114.6,397.4,0,256,0V23.3z"/>
            <path d="M256,162.9v23.3c38.5,0.1,69.7,31.3,69.8,69.8c-0.1,38.5-31.3,69.7-69.8,69.8c-38.5-0.1-69.7-31.3-69.8-69.8c0.1-38.5,31.3-69.7,69.8-69.8V162.9v-23.3c-64.3,0-116.3,52.1-116.4,116.4c0,64.3,52.1,116.3,116.4,116.4c64.3,0,116.3-52.1,116.4-116.4c0-64.3-52.1-116.3-116.4-116.4V162.9z"/>
            <path d="M75,107.9l98.7,98.7c9.1,9.1,23.8,9.1,32.9,0s9.1-23.8,0-32.9L107.9,75c-9.1-9.1-23.8-9.1-32.9,0S65.9,98.8,75,107.9"/>
            <path d="M305.4,338.3l98.7,98.7c9.1,9.1,23.8,9.1,32.9,0c9.1-9.1,9.1-23.8,0-32.9l-98.7-98.7c-9.1-9.1-23.8-9.1-32.9,0C296.3,314.5,296.3,329.2,305.4,338.3"/>
            <path d="M338.3,206.6l98.7-98.7c9.1-9.1,9.1-23.8,0-32.9c-9.1-9.1-23.8-9.1-32.9,0l-98.7,98.7c-9.1,9.1-9.1,23.8,0,32.9S329.2,215.7,338.3,206.6"/>
            <path d="M338.3,206.6l82.1-82.1c9.1-9.1,9.1-23.8,0-32.9c-9.1-9.1-23.8-9.1-32.9,0l-82.1,82.1c-9.1,9.1-9.1,23.8,0,32.9S329.2,215.7,338.3,206.6"/>
            <path d="M107.9,437l98.7-98.7c9.1-9.1,9.1-23.8,0-32.9c-9.1-9.1-23.8-9.1-32.9,0L75,404.1c-9.1,9.1-9.1,23.8,0,32.9C84.1,446.1,98.8,446.1,107.9,437"/>
        </g>
    </svg>
</template>
<script>
    export default {
        name: 'LifeBuoyIcon',
        props: {
            size: {
                type: [Number, String],
                default: 15
            }
        }
    }
</script>