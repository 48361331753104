<template>
    <div class="user-profile relative">
        <div 
            class="user-profile-inner w-100 h-100 relative" 
            v-if="isUserDataLoaded"
        >
            <div 
                class="s-align-6 pointer w-100 h-100"
                @click="openMenu = !openMenu"
            >
                <avatar
                    :url="userThumbnail"
                    :is-active="userData.is_active"
                    :name="`${userFirstName} ${userLastName}`"
                />
                <span class="user-name ellipsis">
                    {{ userFullName }}
                </span>
                <arrow-icon 
                    :transform="openMenu ? 270 : 90"
                    class="i-grey"
                />
            </div>
            <div 
                class="user-profile-dropdown w-100 column-start"
                v-if="openMenu"
                v-scroll="scrollListener"
            >
                <div class="w-100 pb-7" 
                    v-for="(link, index) in visibleProfileLinks"
                    :key="`link-${index}`"
                >
                    <button 
                        class="transparent-button"
                        @click="toProfile(link.query)"
                    >
                        <span class="f-13-asphalt">{{ link.name }}</span>
                    </button>
                </div>
                <div class="w-100 bt-grey bb-grey pv-6">
                    <div class="s-align-4">
                        <div class="srm-icon s-align-5">
                            <crm-logo type="white" :width="32" :height="32"/>
                        </div>
                        <div class="column-between-start pl-4">
                            <template v-if="version">
                                <p class="f-11-grey s-align-4">
                                <span 
                                    class="new-label mr-2"
                                    v-if="versionStatus"
                                >
                                    new
                                </span>
                                    Version
                                    {{ version.version }}
                                </p>
                                <span class="f-9-grey">
                                    Updated by
                                    {{ version.date_release | date }}
                                </span>
                            </template>
                            <router-link
                                :to="{ name: 'Versions' }"
                                v-on:click.native="redirect"
                                class="f-11-blue"
                            >
                                Learn more
                            </router-link>
                        </div>
                    </div>
                    <div class="row-between pt-8">
                        <div class="s-align-4">
                            <span class="tutorials-icon s-align-5 f-9-white">?</span>
                            <span class="f-13-asphalt pl-4">System Tutorials</span>
                        </div>
                        <router-link 
                            class="icon-dark"
                            :to="{name: 'Tutorials'}"
                        >
                            <arrow-icon 
                                size="10"
                                transform="0"
                            />
                        </router-link>
                    </div>
                </div>
                <div class="s-align-4 pt-6">
                    <button 
                        class="transparent-button f-13-asphalt"
                        @click="logout"
                    >
                        Sign Out
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Avatar from './Avatar.vue';
    import { ArrowIcon, CrmLogo } from '@/icons';
    import { PROFILE_PAGE_PARTS } from '@/constants';

    export default {
        name: 'UserProfile',
        components: {
            Avatar,
            ArrowIcon,
            CrmLogo
        },
        data() {
            return {
                openMenu: false,
                profileLinks: [
                    { name: 'Profile', query: PROFILE_PAGE_PARTS.PROFILE },
                    { name: 'Change password', query: PROFILE_PAGE_PARTS.CHANGE_PASSWORD },
                    { name: 'Notifications', query: PROFILE_PAGE_PARTS.NOTIFICATIONS },
                    { name: 'Change email', query: PROFILE_PAGE_PARTS.CHANGE_EMAIL },
                    { 
                        name: 'Configure email',
                        query: PROFILE_PAGE_PARTS.CONFIGURE_EMAIL,
                        permissions: [
                            this.$permissions.VIEW_ALL_EMAILS,
                            this.$permissions.VIEW_MY_EMAILS,
                            this.$permissions.SEND_EMAILS
                        ]
                    }
                ]
            }
        },
        computed: {
            userFullName() {
                return this.$store.getters['user/getUserFullName'];
            },
            userThumbnail() {
                return this.$store.getters['user/getUserThumbnail']
            },
            userData() {
              return this.$store.getters['user/getUserData'];
            },
            userFirstName() {
                return this.$store.getters['user/getUserFirstName'];
            },
            userLastName() {
                return this.$store.getters['user/getUserLastName'];
            },
            isUserDataLoaded() {
                return this.$store.getters['user/isUserDataLoaded'];
            },
            visibleProfileLinks() {
                return this.profileLinks.filter(link => {
                    if (link.permissions) {
                        return link.permissions.some(permission => {
                            return this.$can(permission);
                        });
                    }
                    return true;
                });
            },
            version() {
                return this.$store.getters['user/getVersion'];
            },
            versionStatus() {
                const notify = localStorage.getItem('versionNotify')
                return !notify ? false : notify;
            }
        },
        methods: {
            redirect() {
                this.openMenu = false;
                localStorage.setItem('versionNotify', false);
                this.$emit('closeMenuOnRedirect');
            },
            toProfile(part) {
                if (part == PROFILE_PAGE_PARTS.PROFILE) {
                    this.$router.push({ name: 'Profile' });
                } else {
                    this.$router.push({
                        name: 'Profile',
                        query: { 'part': part }
                    });
                }

                this.redirect();
            },
            logout() {
                this.$store.dispatch('auth/AUTH_LOGOUT')
                    .then(() => this.$router.push('/auth/login'));
            },
            scrollListener(event, el) {
                window.scrollY > 70
                    ? this.openMenu = false
                    : ''
            },
            outside(event) {
                if (!this.$el.contains(event.target) && this.openMenu)
                    this.openMenu = false;
            }
        },
        mounted() {
            document.body.addEventListener('click', this.outside, false);
        },
        destroyed() {
            document.body.removeEventListener('click', this.outside, false);
        }
    }
</script>