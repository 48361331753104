<template>
    <svg
        viewBox="-429 343 15 13"
        :width="width" 
        :height="height"
        class="typing-icon"
    >
        <g>
            <path d="M-416.5,343h-10c-1.4,0-2.5,1.1-2.5,2.5v6.5c0,1,0.9,1.9,1.9,1.9c0,0,0.1,0,0.1,0.1v1c0,0.4,0.2,0.8,0.6,0.9c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.5-0.1,0.6-0.2l2.7-1.9c0,0,0,0,0,0h6.1c1.4,0,2.5-1.1,2.5-2.5v-5.9C-414,344.1-415.1,343-416.5,343z M-415,351.4c0,0.8-0.7,1.5-1.5,1.5h-6.1c-0.2,0-0.4,0.1-0.6,0.2l-2.7,1.9c0,0,0,0-0.1,0c0,0,0,0,0,0v-1c0-0.6-0.5-1.1-1.1-1.1c-0.5,0-0.9-0.4-0.9-0.9v-6.5c0-0.8,0.7-1.5,1.5-1.5h10c0.8,0,1.5,0.7,1.5,1.5V351.4z"/>
            <ellipse cx="-421.5" cy="348.2" rx="0.8" ry="0.7"/>
            <ellipse cx="-425" cy="348.2" rx="0.8" ry="0.7"/>
            <ellipse cx="-418" cy="348.2" rx="0.8" ry="0.7"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'TypingIcon',
    props: {
        width: {
            type: [Number, String],
            default: 15
        },
        height: {
            type: [Number, String],
            default: 13
        }
    }
}
</script>