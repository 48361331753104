<template>
    <div class="w-100">
        <div class="packet-folder w-box row-start row-baseline flex-no-wrap">
            <checkbox-button 
                color="blue"
                class="mr-30"
            >
                <input 
                    type="checkbox"
                    @change="toggleFolderSelection"
                    :checked="allFilesSelected"
                >
            </checkbox-button>
            <div class="row-start row-baseline">
                <p class="lg-5 f-14-black">
                    {{ folderName }}
                </p>
                <p class="lg-5 f-13-grey">
                    {{ `${folder.files.length} ${folder.files.length === 1 ? 'document' : 'documents'}` }}
                </p>
            </div>
            <button 
                class="transparent-button p-4"
                @click="folderOpened = !folderOpened"
            >
                <arrow-icon
                    class="i-grey ml-25"
                    :transform="folderOpened ? 270 : 90"
                />
            </button>
        </div>
        <div
            class="packet-folder-files bl-grey" 
            v-if="folderOpened"
        >
            <packet-folder
                v-for="(folder, index) in folder.children"
                :key="index"
                :folder="folder"
                :entityId="entityId"
                :selectedFiles="selectedFiles"
                @change="(s, f) => $emit('change', s, f)"
            />
            <packet-file
                v-for="(file, index) in folder.files"
                :key="file.id"
                :file="file"
                :index="index"
                :entityId="entityId"
                :filesLength="folder.files.length"
                :selectedFiles="selectedFiles"
                :type="type"
                @change="(s, f) => $emit('change', s, f)"
            />
        </div>
    </div>
</template>

<script>
    import PacketFile from '@/components/LeadModules/PacketFile';
    import { ArrowIcon } from '@/icons';
    import { PacketFolder } from '@/components';
    import { FILE_EXTENSIONS } from '@/constants.js';
    import { CheckboxButton } from '@/ui';

    const FOLDER_TYPES = {
        JOB_DOCUMENTS: 'jobDocuments',
        COMPANY_DOCUMENTS: 'companyDocuments',
        ESTIMATES: 'estimates'
    };
     
    export default {
        name: 'PacketFolder',
        props: {
            folder: {
                type: Object,
                required: true
            },
            selectedFiles: {
                type: Array,
                required: true
            },
            type: {
                type: String,
                default: FOLDER_TYPES.JOB_DOCUMENTS
            },
            entityId: {
                type: [String, Number],
                default: ''
            }
        },
        components: {
            ArrowIcon,
            PacketFolder,
            PacketFile,
            CheckboxButton
        },
        data() {
            return {
                folderOpened: true,
            }
        },
        computed: {
            folderName() {
                return this.folder.id.slice(this.folder.id.lastIndexOf('/') + 1, this.folder.id.length);
            },
            allFilesSelected() {
                let allFilesSelected = true;
                let filesExist = false;
                let allFilesInsideFoldersSelected = true;
                let filesInsodeFolderExist = false;

                this.folder.files.forEach(file => {
                    if (!this.selectedFiles.some(f => file.id === f.id)
                        && FILE_EXTENSIONS.DOCUMENTS.some(t => t === file.type)) {
                        allFilesSelected = false;
                    } else if (FILE_EXTENSIONS.DOCUMENTS.some(t => t === file.type)) {
                        filesExist = true;
                    }
                });

                if (this.folder.children && this.folder.children.length) {
                    this.folder.children.forEach(folder => {
                        folder.files.forEach(file => {
                            if (!this.selectedFiles.some(f => file.id === f.id)
                                && FILE_EXTENSIONS.DOCUMENTS.some(t => t === file.type)) {
                                allFilesInsideFoldersSelected = false;
                            } else if (FILE_EXTENSIONS.DOCUMENTS.some(t => t === file.type)) {
                                filesInsodeFolderExist = true;
                            }
                        });
                    })
                }

                if (!filesExist && !filesInsodeFolderExist) { return false };
                return allFilesSelected && allFilesInsideFoldersSelected;
            }
        },
        methods: {
            toggleFolderSelection() {
                if (!this.allFilesSelected) {
                    this.folder.files.forEach(file => {
                        if (FILE_EXTENSIONS.DOCUMENTS.some(type => type === file.type)) {
                            this.$nextTick(() => {
                                this.$emit('change', true, file);
                            });
                        }
                    });

                    if (this.folder.children && this.folder.children.length) {
                        this.folder.children.forEach(folder => {
                            folder.files.forEach(file => {
                                if (FILE_EXTENSIONS.DOCUMENTS.some(type => type === file.type)) {
                                    this.$nextTick(() => {
                                        this.$emit('change', true, file);
                                    });
                                }
                            });
                        })
                    }
                } else {
                    this.folder.files.forEach(file => {
                        if (FILE_EXTENSIONS.DOCUMENTS.some(type => type === file.type)) {
                            this.$nextTick(() => {
                                this.$emit('change', false, file);
                            });
                        }
                    });

                    if (this.folder.children && this.folder.children.length) {
                        this.folder.children.forEach(folder => {
                            folder.files.forEach(file => {
                                if (FILE_EXTENSIONS.DOCUMENTS.some(type => type === file.type)) {
                                    this.$nextTick(() => {
                                        this.$emit('change', false, file);
                                    });
                                }
                            });
                        })
                    }
                }
            }
        }
    }
</script>