// user
import UserProfile from './User/UserProfile.vue';
import Avatar from './User/Avatar.vue';
import UserBox from './User/UserBox.vue';
import UsersSelect from './UsersSelect.vue';

// lead modules
import DocumentsUploadPreview from './LeadModules/DocumentsUploadPreview.vue';
import FileBox from './LeadModules/FileBox.vue';
import CustomFieldInput from './LeadModules/CustomFieldInput.vue';
import MilestonesUpdate from './Milestones/MilestonesUpdate.vue';
import Milestones from './Milestones/Milestones.vue';
import MilestonesDead from './Milestones/MilestonesDead.vue';
import MilestoneStatistics from './Milestones/MilestoneStatistics.vue';
import GeneralStatistics from './LeadModules/GeneralStatistics.vue';
import MessagesStatistics from './LeadModules/MessagesStatistics.vue';
import MeasurementView from './LeadModules/MeasurementView.vue';
import MeasurementsEagleView from './LeadModules/MeasurementsEagleView.vue';
import FormBuilderNavPanel from './LeadModules/FormBuilder/FormBuilderNavPanel.vue';
import FormBuilderLeakResponseReport from './LeadModules/FormBuilder/FormBuilderLeakResponseReport.vue';
import FormBuilderServiceResponseReport from './LeadModules/FormBuilder/FormBuilderServiceResponseReport.vue';
import FormBuilderFurtherActionReport from './LeadModules/FormBuilder/FormBuilderFurtherActionReport.vue';
import FormBuilderInvoiceReport from './LeadModules/FormBuilder/FormBuilderInvoiceReport.vue';
import FormBuilderCustomReport from './LeadModules/FormBuilder/FormBuilderCustomReport.vue';
import FormBuilderCustomFields from './LeadModules/FormBuilder/FormBuilderCustomFields.vue';
import FormBuilderPhotoForm from './LeadModules/FormBuilder/FormBuilderPhotoForm.vue';
import FormBuilderLeakPhotoForm from './LeadModules/FormBuilder/FormBuilderLeakPhotoForm.vue';
import FormBuilderReportTable from './LeadModules/FormBuilder/FormBuilderReportTable.vue';
import FormBuilderCustomFieldsPictureSection from './LeadModules/FormBuilder/FormBuilderCustomFieldsPictureSection.vue';
import EstimateTotal from './LeadModules/EstimateTotal.vue';
import EstimatePreview from './LeadModules/EstimatePreview.vue';
import EstimateInfo from './LeadModules/EstimateInfo.vue';
import CustomFieldAddressSection from './LeadModules/CustomFieldAddressSection.vue';
import CustomFieldPictureSection from './LeadModules/CustomFieldPictureSection.vue';
import KanbanLinkBox from './LeadModules/KanbanLinkBox.vue';
import TagBox from './LeadModules/TagBox.vue';
import EventsBox from './LeadModules/EventsBox.vue';
import EventsTable from './LeadModules/EventsTable.vue';
import OrderInfo from './LeadModules/OrderInfo.vue';
import OrderMilestones from './LeadModules/OrderMilestones.vue';
import BaseContractWorksheet from './LeadModules/BaseContractWorksheet.vue';
import PacketFolder from './LeadModules/PacketFolder.vue';
import PacketFile from './LeadModules/PacketFile.vue';
import EstimatePreviewTable from './LeadModules/EstimatePreviewTable.vue';
import EstimatePreviewNotesForm from './LeadModules/EstimatePreviewNotesForm.vue';
import EstimatePreviewSignaturePanel from './LeadModules/EstimatePreviewSignaturePanel.vue';
import Bidders from './LeadModules/Bidders.vue';
import MilestonesLog from './LeadModules/Settings/MilestonesLog.vue';
import QualityControl from './Overview/QualityControl.vue';
import PreScheduledCrew from './Overview/PreScheduledCrew.vue';
import ExpectedRevenue from './Overview/ExpectedRevenue.vue';
import ResLeadCard from "./LeadList/ResLeadCard";
import ComLeadCard from "./LeadList/ComLeadCard";

// residential jobs
import ResidentialLeadsFilter from './ResidentialJobs/ResidentialLeadsFilter.vue';
import ResidentialLeadListDetails from './ResidentialJobs/ResidentialLeadListDetails.vue';

// search
import SearchBox from './Search/SearchBox.vue';
import SearchResult from './Search/SearchResult.vue';

// audit tables
import JobMaterialTable from './AuditTable/JobMaterialTable.vue';
import SalesmanPayrollTable from './AuditTable/SalesmanPayrollTable.vue';
import JobsTable from './AuditTable/JobsTable.vue';
import JobsLaborsTable from './AuditTable/JobsLaborsTable.vue';
import NotesList from './AuditTable/NotesListTable.vue';
import UsersTable from './AuditTable/UsersTable.vue';
import ManagerCommisionTable from './AuditTable/ManagerCommisionTable.vue';
import PaymentTable from './AuditTable/PaymentTable.vue';

// other
import AuthEnvelope from './Auth/AuthEnvelope.vue';
import StatusMarker from './StatusMarker.vue';
import ScrollTable from './ScrollTable.vue';
import NotFoundMessage from './NotFoundMessage.vue';
import Notes from './Notes.vue';
import Priority from './Priority.vue';
import UploadScreenSaver from './UploadScreenSaver.vue';
import ListEmpty from './ListEmpty.vue';
import StepsPanel from './StepsPanel.vue';
import AddressBox from './AddressBox.vue';
import AddressBoxOneLine from './AddressBoxOneLine.vue';
import TabsMenu from './TabsMenu.vue';
import ProgressStatus from './ProgressStatus.vue';
import ButtonDropdown from './ButtonDropdown.vue';
import Notifications from './Notifications.vue';
import JobNavigation from './JobNavigation.vue';
import MaterialColorsPopup from './MaterialColorsPopup.vue';
import SelectOrDeselect from './SelectOrDeselect.vue';
import ContextMenu from './ContextMenu.vue';
import ReadMore from './ReadMore.vue';
import RepresentativesDetailsList from './RepresentativesDetailsList.vue';
import LeadEmailsMenu from './LeadEmailsMenu.vue';
import LeadEmailsChips from './LeadEmailsChips.vue';
import LeadFilterSection from './LeadFilterSection.vue';
import ContentItem from './ContentItem.vue';
import FilePreviewContainer from './FilePreviewContainer.vue';
import Tag from './Tag.vue';
import NavigationPanelForEntity from './NavigationPanelForEntity';

// letters
import LettersList from './Letters/LettersList.vue';
import ReplyToLetter from './Letters/ReplyToLetter.vue';

// project management
import ProjectManagementTags from './ProjectManagement/ProjectManagementTags.vue';
import ProjectManagementCard from './ProjectManagement/ProjectManagementCard.vue';
import ProjectManagementCardPreview from './ProjectManagement/ProjectManagementCardPreview.vue';
import ProjectManagementArchive from './ProjectManagement/ProjectManagementArchive.vue';

// text editor
import TextEditor from './TextEditor.vue';

// settings
import NextJobsSetup from './Settings/NextJobsSetup.vue';
import MilestoneReasons from './Settings/MilestoneReasons.vue';
import CustomerPortalSettings from './Settings/CustomerPortalSettings.vue';
import CompanyDocumentsFolder from './Settings/CompanyDocumentsFolder.vue';
import CrewPriceListSection from './Settings/CrewPriceListSection.vue';

// company library
import CompanyLibraryTable from './CompanyLibraryManager/CompanyLibraryTable.vue';

// calendar
import CalendarHeader from './Calendar/CalendarHeader.vue';
import EventEdit from './Calendar/EventEdit.vue';
import EventCreatePopup from './Calendar/EventCreatePopup.vue';
import EventViewPopup from './Calendar/EventViewPopup.vue';
import CalendarNotification from './Calendar/CalendarNotification.vue';
import OrderCreatePopup from './Calendar/OrderCreatePopup.vue';
import CalendarDays from './Calendar/CalendarDays.vue';

// layout
import Pinned from './Layout/Pinned.vue';

// create lead
import Representatives from './CreateLead/Representatives.vue';
import CorrespondenceBody from './CreateLead/CorrespondenceBody.vue';
import CorrespondenceWithClients from './CreateLead/CorrespondenceWithClients.vue';
import LeadSearch from './CreateLead/LeadSearch.vue';
import UserJobsLocation from './CreateLead/UserJobsLocation.vue';
import SalesmanCalendar from './CreateLead/SalesmanCalendar.vue';
import CallsWithClients from './CreateLead/CallsWithClients.vue';
import ClientCallCard from './CreateLead/ClientCallCard.vue';
import ClientCallInfo from './CreateLead/ClientCallInfo.vue';
import Contacts from './CreateLead/Contacts';


// commercial
import PropertiesMenu from './CommercialJobs/PropertiesMenu.vue';
import CustomerSection from './CommercialJobs/CustomerSection.vue';
import PropertySection from './CommercialJobs/PropertySection.vue';
import ContactList from './CommercialJobs/ContactList.vue';
import CommercialJobsTable from './CommercialJobs/CommercialJobsTable.vue';
import CommercialLeadsTable from './CommercialJobs/CommercialLeadsTable.vue';
import CommercialLeadListDetails from './CommercialLeads/CommercialLeadListDetails.vue';
import CommercialLeadsFilter from './CommercialLeads/CommercialLeadsFilter.vue';
import CommercialJobsNav from './CommercialJobs/CommercialJobsNav.vue';
import CommercialCustomerListDetails from './CommercialJobs/CommercialCustomerListDetails.vue';
import CommercialPropertyListDetails from './CommercialJobs/CommercialPropertyListDetails.vue';
import CommercialUnitListDetails from './CommercialJobs/CommercialUnitListDetails.vue';
import CommercialJobListDetails from './CommercialJobs/CommercialJobListDetails.vue';
import PrimaryRepresentative from './LeadList/PrimaryRepresentative';

// reports
import ReportsFilterInput from './Reports/ReportsFilterInput.vue';
import ReportTable from './Reports/ReportTable.vue';

// custom fields
import Field from './CustomFields/Field.vue';
import SectionContainer from './CustomFields/SectionContainer.vue';
import FormBuilderNav from './CustomFields/FormBuilderNav.vue';
import CommercialJobNav from './CustomFields/CommercialJobNav.vue';
import PrimarySection from './CustomFields/PrimarySection.vue';

// orders
import OrderCreateStep1 from './Orders/OrderCreateStep1.vue';
import OrderCreateStep2 from './Orders/OrderCreateStep2.vue';
import OrderCreateStep3 from './Orders/OrderCreateStep3.vue';
import OrderCreateStep4 from './Orders/OrderCreateStep4.vue';
import OrderCreateStep5 from './Orders/OrderCreateStep5.vue';
import OrderCreateStep6 from './Orders/OrderCreateStep6.vue';
import OrderCreateStep7 from './Orders/OrderCreateStep7.vue';
import OrderPdf from "./Orders/OrderPdf";

// Dashboard
import CloseRatioReport from './Dashboard/CloseRatioReport.vue';
import JobsReport from './Dashboard/JobsReport.vue';
import ExpectedRevenueReport from './Dashboard/ExpectedRevenueReport.vue';

// Import Leads Jobs
import ImportStepOne from './ImportLeadsJobs/ImportStepOne.vue';
import ImportStepTwo from './ImportLeadsJobs/ImportStepTwo.vue';
import ImportConflictSettingsPopup from './ImportLeadsJobs/ImportConflictSettingsPopup.vue';
import ImportSearchProperty from './ImportLeadsJobs/ImportSearchProperty.vue';

// SmartDocs
import SmartDocSidebar from './SmartDocBuilder/SmartDocSidebar.vue';
import SmartDocument from './SmartDocBuilder/SmartDocument.vue';

// Telephony
import MakeCallWidget from './Telephony/MakeCallWidget.vue';
import MakeCallFromNumberButton from './Telephony/MakeCallFromNumberButton.vue';
import CtmPhone from './Telephony/CtmPhone.vue';

// Opportunities
import OpportunitiesFilter from './Opportunities/OpportunitiesFilter.vue';
import OpportunitiesCard from './Opportunities/OpportunitiesCard.vue';
import OpportunityPreview from './Opportunities/OpportunityPreview.vue'

//Call log Admin
import CallLogCard from './CallLog/CallLogCard.vue';
import CallLogFilter from './CallLog/CallLogFilter.vue';

//Warranty
import WarrantyActions from './Warranty/WarrantyActions';
import WarrantyJobsFilter from './Warranty/WarrantyJobsFilter';
import WarrantyDetails from './Warranty/WarrantyJobListDetails';
import WarrantyListsCard from './Warranty/WarrantyListsCard';
import WarrantyPdf from './Warranty/WarrantyPdf';

export {
    // user
    UserProfile,
    Avatar,
    UserBox,
    UsersSelect,

    // job details
    DocumentsUploadPreview,
    FileBox,
    CustomFieldInput,
    MilestonesUpdate,
    Milestones,
    MilestonesDead,
    MilestoneStatistics,
    GeneralStatistics,
    MessagesStatistics,
    MeasurementView,
    MeasurementsEagleView,
    FormBuilderNavPanel,
    FormBuilderLeakResponseReport,
    FormBuilderServiceResponseReport,
    FormBuilderFurtherActionReport,
    FormBuilderInvoiceReport,
    FormBuilderCustomReport,
    FormBuilderCustomFields,
    FormBuilderPhotoForm,
    FormBuilderLeakPhotoForm,
    FormBuilderReportTable,
    FormBuilderCustomFieldsPictureSection,
    EstimateTotal,
    EstimatePreview,
    EstimateInfo,
    CustomFieldAddressSection,
    CustomFieldPictureSection,
    KanbanLinkBox,
    TagBox,
    EventsBox,
    EventsTable,
    OrderInfo,
    OrderMilestones,
    BaseContractWorksheet,
    PacketFolder,
    PacketFile,
    EstimatePreviewTable,
    EstimatePreviewNotesForm,
    EstimatePreviewSignaturePanel,
    Bidders,
    MilestonesLog,
    QualityControl,
    PreScheduledCrew,
    ExpectedRevenue,

    // leads
    ResidentialLeadsFilter,
    ResidentialLeadListDetails,
    ResLeadCard,
    ComLeadCard,

    // search
    SearchBox,
    SearchResult,

    // audit tables
    JobMaterialTable,
    SalesmanPayrollTable,
    JobsTable,
    JobsLaborsTable,
    NotesList,
    UsersTable,
    ManagerCommisionTable,
    PaymentTable,

    // other
    AuthEnvelope,
    StatusMarker,
    ScrollTable,
    NotFoundMessage,
    Notes,
    Priority,
    UploadScreenSaver,
    ListEmpty,
    StepsPanel,
    AddressBox,
    AddressBoxOneLine,
    TabsMenu,
    ProgressStatus,
    ButtonDropdown,
    Notifications,
    JobNavigation,
    MaterialColorsPopup,
    SelectOrDeselect,
    ContextMenu,
    ReadMore,
    RepresentativesDetailsList,
    LeadEmailsMenu,
    LeadEmailsChips,
    LeadFilterSection,
    ContentItem,
    FilePreviewContainer,
    Tag,
    NavigationPanelForEntity,

    // Letters
    LettersList,
    ReplyToLetter,

    // project management
    ProjectManagementTags,
    ProjectManagementCard,
    ProjectManagementCardPreview,
    ProjectManagementArchive,
    
    // text editor
    TextEditor,

    // settings
    NextJobsSetup,
    MilestoneReasons,
    CustomerPortalSettings,
    CompanyDocumentsFolder,
    CrewPriceListSection,

    // company library manager
    CompanyLibraryTable,

    // calendar
    CalendarHeader,
    EventEdit,
    EventCreatePopup,
    EventViewPopup,
    CalendarNotification,
    OrderCreatePopup,
    CalendarDays,

    // layout
    Pinned,

    // create lead
    Representatives,
    CorrespondenceWithClients,
    CorrespondenceBody,
    LeadSearch,
    UserJobsLocation,
    SalesmanCalendar,
    CallsWithClients,
    ClientCallCard,
    ClientCallInfo,
    Contacts,

    // commercial
    PropertiesMenu,
    CustomerSection,
    PropertySection,
    ContactList,
    CommercialJobsTable,
    CommercialLeadsTable,
    CommercialLeadListDetails,
    CommercialJobsNav,
    CommercialCustomerListDetails,
    CommercialPropertyListDetails,
    CommercialUnitListDetails,
    CommercialJobListDetails,
    PrimaryRepresentative,
    CommercialLeadsFilter,
    
    // reports
    ReportsFilterInput,
    ReportTable,

    // custom fields
    Field,
    SectionContainer,
    FormBuilderNav,
    CommercialJobNav,
    PrimarySection,

    // orders
    OrderCreateStep1,
    OrderCreateStep2,
    OrderCreateStep3,
    OrderCreateStep4,
    OrderCreateStep5,
    OrderCreateStep6,
    OrderCreateStep7,
    OrderPdf,

    // Dashboard
    CloseRatioReport,
    JobsReport,
    ExpectedRevenueReport,

    // Import Leads Jobs
    ImportStepOne,
    ImportStepTwo,
    ImportConflictSettingsPopup,
    ImportSearchProperty,

    // SmartDocs
    SmartDocSidebar,
    SmartDocument,

    // Telephony
    MakeCallWidget,
    MakeCallFromNumberButton,
    CtmPhone,
    
    // Opportunities
    OpportunitiesFilter,
    OpportunitiesCard,
    OpportunityPreview,
    
    //Call log Admin
    CallLogCard,
    CallLogFilter,
    
    //Warranty
    WarrantyActions,
    WarrantyJobsFilter,
    WarrantyDetails,
    WarrantyListsCard,
    WarrantyPdf
}
