<template>
    <div 
        :class="[
            'form-colorpicker s-align-5 relative ml-1 mr-20 pr-1',
            customActivator && 'transparent'
        ]"
    >
        <div
            v-if="customActivator"
            @click="colorPicker = !colorPicker"
        >
            <slot name="activator">
            </slot>
        </div>
        <div
            v-if="!customActivator"
            @click="colorPicker = !colorPicker"
            class="s-align-5"
        >
            <pie-chart-icon 
                :color="color" 
                class="pointer"
            />
        </div>
        <span
            v-if="!customActivator"
            class="f-14-black ml-4 mr-1"
        >
            #
        </span>
        <input
            v-show="!customActivator"
            class="form-colorpicker-input w-50"
            type="text"
            placeholder="Enter value"
            maxlength="6"
            @input="$emit('input', !$event.target.value.length ? 
                $event.target.value : `#${$event.target.value}`)
            "
            @focus="$emit('input', !$event.target.value.length ? 
                $event.target.value : `#${$event.target.value}`)
            "
            :value="color.replace('#','')"
        >
        <div
            v-if="showArrow && colorPicker"
            class="color-picker-arrow hidden"
        />
        <div
            v-if="colorPicker"
            :class="['color-picker', customPalette && 'color-picker-custom']"
        >
            <div class="z-index-2 row-start">
                <div
                    @click="changeColor(color)"
                    class="color-picker-color pointer"
                    v-for="color in prioritiesColor"
                    :key="color"
                    :style="`background-color: ${color}`"
                >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { PieChartIcon } from '@/icons';
    import { PRIORITIES_COLOR, COLOR_PICKER_PALETTE } from '@/constants';

    export default {
        name: 'FormInput',
        props: {
            color: {
                type: String,
                default: '',
            },
            value: {
                required: true
            },
            customActivator: {
                type: Boolean,
                default: false
            },
            showArrow: {
                type: Boolean,
                default: false
            },
            customPalette: {
                type: Boolean,
                default: false
            }
        },
        components: {
            PieChartIcon
        },
        data() {
            return {
                colorPicker: false,
                prioritiesColor: this.customPalette ? COLOR_PICKER_PALETTE : PRIORITIES_COLOR
            }
        },
        methods: {
            changeColor(color) {
                let colorValue = this.$el.querySelector('input');
                colorValue.value = color.replace('#','');
                if (this.customActivator) {
                    this.$emit('input', !colorValue.value.length ? colorValue.value : `#${colorValue.value}`);
                    this.colorPicker = false;
                } else {
                    colorValue.focus();
                    colorValue.blur();
                }
            },
            outside(event) {
                if (!this.$el.contains(event.target) && this.colorPicker) {
                    this.colorPicker = false;
                }
            }
        },
        mounted() {
            document.body.addEventListener('click', this.outside, false);
        },
        destroyed() {
            document.body.removeEventListener('click', this.outside, false);
        }
    }
</script>
