<template>
    <perfect-scrollbar class="commercial-list-details opportunities-preview ps-grey w-100">
        <div
            class="w-100"
            v-if="loading"
        >
            <loader/>
        </div>
        <div
            class="w-100"
            v-if="!loading && Object.keys(opportunity).length"
        >
            <div class="s-align-4 w-100 pb-4">
                <clock-icon
                    class="i-grey mr-2"
                    size="12"
                />
                <span class="f-11-grey">{{ opportunity.created_at | timeAgo }}</span>
            </div>
            <div class="row-between flex-no-wrap bb-grey pb-10">
                <h2 class="f-20-darkgrey f-sbold mr-2">
                    {{ opportunity.first_name || '' }}
                    {{ opportunity.last_name || '' }}
                </h2>
            </div>
            
            <div class="column-start pv-25 w-100 bb-grey">
                <div class="mb-3">
                    <span class="f-12-grey">Address:</span>
                    <a
                        v-if="address"
                        :href="getGoogleMapLink(address.lat, address.lng)"
                        :class="[
                            'f-12-blue ml-1',
                            {'none-events': !getGoogleMapLink(address.lat, address.lng)}
                        ]"
                        target="_blank"
                    >
                        {{ getAddress(address) }}
                        <link-icon 
                            v-if="getGoogleMapLink(address.lat, address.lng)"
                            class="i-blue"
                        />
                    </a>
                    <span class="f-12-grey f-italic ml-1" v-else>{{ noInfo }}</span>
                </div>
                <div
                    class="s-align-5 h-75 w-100"
                >
                    <google-map
                        :coords="{ lat: +address.lat, lng: +address.lng }"
                        :zoom="14"
                        v-if="addressIsCorrect"
                    />
                    <span
                        class="f-12-grey f-sbold opacity-8"
                        v-else
                    >
                            Sorry, map is not available now
                        </span>
                </div>
            </div>
            <div class="column-start pv-25 w-100 bb-grey">
                <h2 class="f-16-darkgrey f-sbold mb-8">Information:</h2>
                <div class="row-baseline mb-5">
                    <p class="f-12-grey mr-3">Phone:</p>
                    <contact-list
                        v-if="opportunity.phones.length"
                        :items="opportunity.phones"
                        :entityId="opportunity.id"
                        :text-class="'f-12-blue'"
                        :icon-btn="'call-btn'"
                        type="phone"
                        :isContact="true"
                        alignClass="column-start"
                    />
                    <span v-else class="f-normal f-12-grey f-italic">
                        {{ noInfo }}
                    </span>
                </div>
                <div class="row-baseline mb-5">
                    <p class="f-12-grey mr-3">Email:</p>
                    <contact-list
                        v-if="opportunity.emails.length"
                        :items="opportunity.emails"
                        :text-class="'f-12-black'"
                        type="email"
                        alignClass="column-start"
                    />
                    <span v-else class="f-normal f-12-grey f-italic">
                        {{ noInfo }}
                    </span>
                </div>
                <div
                    v-if="opportunity.created_by"
                >
                    <span class="f-12-grey">Created by:</span>
                    <span class="f-12-black ml-1">
                        {{ `${opportunity.created_by.first_name} ${opportunity.created_by.last_name}` }}
                    </span>
                </div>
            </div>
            <div
                v-if="opportunity.trades.length"
                class="column-start pv-25 w-100"
            >
                <h2 class="f-16-darkgrey f-sbold mb-8">
                    {{ opportunity.trades.length > 1 ? 'Trades' : 'Trade' }}
                </h2>
                <div class="row-start">
                    <trade
                        v-for="(trade, index) in opportunity.trades"
                        :key="`trade-${index}`"
                        :label="trade.name"
                        :disabled="true"
                        :icon="trade.icon_code"
                    >
                        <input
                            type="checkbox"
                            :name="`trade-${index}`"
                            disabled
                        />
                    </trade>
                </div>
            </div>
            <div
                class="column-start pv-25 w-100 bt-grey"
                v-if="tags.length"
            >
                <h2 class="f-16-darkgrey f-sbold pb-8">Labels</h2>
                <div class="row-start">
                    <tag
                        v-for="(tag, index) in tags"
                        :key="`tag-${index}`"
                        :tag="tag"
                        :disabled="true"
                    />
                </div>
            </div>
        </div>
    </perfect-scrollbar>
</template>

<script>
    import api from '@/headers';
    import {MapIcon, LinkIcon, ClockIcon} from '@/icons';
    import {showFullAddress, getGoogleMapLink} from '@/helpers';
    import {Loader, Trade} from '@/ui';
    import {GoogleMap} from '@/GoogleApi';
    import ContactList from '@/components/CommercialJobs/ContactList.vue';
    import Tag from '@/components/Tag.vue';
    
    export default {
        name: 'OpportunityPreview',
        components: {
            Loader,
            Trade,
            MapIcon,
            LinkIcon,
            GoogleMap,
            ContactList,
            Tag,
            ClockIcon
        },
        data() {
            return {
                noInfo: 'No information',
                loading: false,
                opportunity: {}
            }
        },
        props: {
            activeId: {
                type: Number,
                required: true
            },
            tags: {
                type: Array,
                default: function() {
                    return [];
                }
            }
        },
        watch: {
            activeId() {
                this.getOpportunitiesId();
            }
        },
        computed: {
            address() {
                if (this.opportunity.addresses && this.opportunity.addresses.length){
                    return this.opportunity.addresses[0];
                }
            },
            addressIsCorrect() {
                if (this.address){
                    return this.address.lat != null && this.address.lng != null;
                }
            },
        },
        methods: {
            // Methods imported from helpers.js
            getGoogleMapLink,
            getAddress(opportunity) {
                return showFullAddress(opportunity);
            },
            getOpportunitiesId() {
                this.loading = true;
                api.get(`/opportunities/${this.activeId}`)
                    .then(res => {
                        this.opportunity = res.data.data;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            }
        },
        created() {
            this.getOpportunitiesId();
        }
    }
</script>
