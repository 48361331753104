<template>
    <div 
        :class="[
            'w-box w-100 events-box',
            { 'events-mock-loading' : loading && !requestDone},
            { 's-align-4 events-box-empty' : !loading && requestDone && !event }
        ]"
    >
        <div
            v-if="loading || !requestDone"
            class="s-align-5"
        >
            <loader size="small" />
        </div>
        <div v-if="!loading && requestDone">
            <div 
                v-if="!event"
                class="events-empty s-align-4"
            >
                <img 
                    src="@/assets/images/events_empty_new.svg" 
                    alt="Calendar Icon"
                    class="ml-30 mr-30"
                >
                <div class="column-start">
                    <p class="f-16-darkgrey f-sbold">Events</p>
                    <div class="f-13-darkgrey">
                        This {{ isJobLeadName }} has no events at this moment. To create a new event
                        <button
                            class="transparent-button f-12-blue f-sbold redirect-button"
                            @click="redirectToRoute"
                        >
                            click here
                        </button>
                    </div>
                </div>
            </div>
            <div
                class="w-100 stretch column-start"
                v-else
            >
                <div class="row-between bb-grey pb-8">
                    <div class="s-align-4">
                        <div
                            v-if="event.is_upcoming"
                            class="events-box-arrow s-align-5"
                        >
                            <next-icon 
                                size="12"
                                class="i-blue"
                            />
                        </div>
                        <div
                            v-else
                            class="events-box-mark s-align-5"
                        >
                            <mark-icon 
                                size="12"
                                class="i-white"
                            />
                        </div>
                        <div class="column-start ml-7">
                            <p class="f-16-asphalt f-sbold">
                                {{ event.is_upcoming ? "Upcoming event" : 'Finished last event'}}
                            </p>
                            <span class="f-13-grey">To access list of all events? click View all</span>
                        </div>
                    </div>
                    <button
                        class="transparent-button f-12-blue f-sbold redirect-button"
                        @click="redirectToRoute"
                    >
                        View all
                        <arrow-icon
                            size="8"
                            class="i-blue ml-2"
                            :transform="0"
                        />
                    </button>
                </div>
                <div :class="[
                    's-align-4 mt-8',
                    { 'white-spirit-block relative' : !event.is_upcoming }
                ]">
                    <avatar
                        :url="event.owner.avatar"
                        :name="event.owner.fullname"
                        size="50"
                        :border="false"
                    />
                    <div class="column-start ml-7">
                        <p class="f-15-black f-sbold"> {{ event.owner.fullname }} </p>
                        <div class="row-start mt-1">
                            <div class="f-13-black s-align-4 mr-30">
                                <calendar-icon 
                                    size="15"
                                    class="i-blue mr-6"
                                />
                                {{ event.dtstart | monthDayYear }}
                            </div>
                            <div class="s-align-4 f-14-black">
                                <clock-icon
                                    size="15" 
                                    class="i-blue mr-6"
                                />
                                <div 
                                    v-if="!+event.all_day"
                                    class="s-align-4" 
                                >
                                    <span class="f-14-grey mr-1">From</span>
                                        {{ event.dtstart | time }}
                                    <div
                                        v-if="checkOneDay" 
                                        class="s-align-4 ml-1"
                                    >
                                        <span class="f-14-grey mr-1">to</span>
                                        {{ event.dtend | time }}
                                    </div>
                                </div>
                                <span v-else>
                                    All day
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/headers.js';
    import Avatar from '@/components/User/Avatar.vue';
    import { NextIcon, ArrowIcon, CalendarIcon, ClockIcon, MarkIcon } from '@/icons';
    import moment from 'moment/src/moment';
    import { ENTITY_TYPES } from '@/constants';
    import { Loader } from '@/ui';
    
    export default {
        name: 'EventsBox',
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            entityType: {
                type: String,
                required: true
            }
        },
        components: {
            Loader,
            Avatar,
            NextIcon,
            ArrowIcon,
            CalendarIcon,
            ClockIcon,
            MarkIcon
        },
        data() {
            return {
                requestDone: false,
                RESIDENTIAL: ENTITY_TYPES.RESIDENTIAL_LEAD
            }
        },
        computed: {
            events() {
              return this.$store.getters['calendars/getEvents'];
            },
            event() {
                return this.$store.getters['calendars/getEvent'];
            },
            entityId() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD:
                        return this.$route.params.lead_id;
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.$route.params.job_id;
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.$route.params.lead_id;
                }
            },
            allEventsRoute() {
                if (this.$can([this.$permissions.VIEW_ALL_CALENDARS]) 
                    || this.$can([this.$permissions.VIEW_MY_CALENDAR])
                    || this.$can([this.$permissions.CREATE_EVENT_FOR_OTHERS]) 
                ) {
                    switch (this.entityType) {
                        case ENTITY_TYPES.RESIDENTIAL_LEAD:
                            return 'Events';
                        case ENTITY_TYPES.COMMERCIAL_JOB:
                            return 'CommercialJobEvents';
                        case ENTITY_TYPES.COMMERCIAL_LEAD:
                            return 'CommercialLeadEvents';
                    }
                }

                return false;
            },
            checkOneDay() {
                return moment(this.event.dtstart).isSame(moment(this.event.dtend), 'day');
            },
            jobNumber() {
                return this.$store.getters['lead/getJobNumber'];
            },
            isJobLeadName() {
                return this.entityType != this.RESIDENTIAL ? 'lead' : this.jobNumber ? 'job' : 'lead'
            },
        },
        methods: {
            getEvents() {
                let params = {
                    entity_id: this.entityId,
                    entity_type: this.entityType
                };

                this.$store.dispatch('calendars/GET_EVENT', params)
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestDone = true;
                    });
            },
            redirectToRoute() {
                if (this.allEventsRoute) {
                    this.$router.push({ name : this.allEventsRoute });
                    return;
                }

                this.notifyError('This action is unauthorized.');
            }
        },
        created() {
            this.getEvents();
        }
    }
</script>