var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"w-100 relative"},[_c('div',[(_vm.isLeftBtn)?_c('button',{staticClass:"mr-2 navigation-button prev z-index-2",on:{"click":function($event){return _vm.scrollMilestones(_vm.SCROLL_TO_LEFT)}}},[_c('arrow-icon',{staticClass:"i-grey",attrs:{"transform":"180","size":"9"}})],1):_vm._e(),(_vm.isRightBtn)?_c('button',{staticClass:"navigation-button next z-index-2",on:{"click":function($event){return _vm.scrollMilestones(_vm.SCROLL_TO_RIGHT)}}},[_c('arrow-icon',{staticClass:"i-grey",attrs:{"transform":"0","size":"9"}})],1):_vm._e()]),_c('div',{staticClass:"milestones-box warranty"},[_c('div',{ref:"milestones",class:[
                        'milestones',
                           { 'milestones-to-center' : _vm.isShowMilestoneCenter() }
                        ],attrs:{"id":"milestones-list"},on:{"scroll":_vm.scrollBtnControl}},[_vm._l((_vm.warrantyCases),function(caseItem,idx){return _c('router-link',{key:caseItem.id,staticClass:"milestone",class:caseItem.end_at ? 'milestone-done' : 'milestone-inprogress',attrs:{"id":("milestone-" + idx),"to":{
                            name:'WarrantyCaseCreate',
                            query: Object.assign({}, {case_id: caseItem.id,
                                warranty_id: _vm.warrantyId},
                                 _vm.$route.params)
                        }}},[_c('div',{class:[
                                'milestone-content column-center t-center',
                                caseItem.end_at ? 'milestone-done-container' : 'milestone-inprogress-container' ]},[_c('div',{staticClass:"relative"},[(caseItem.end_at )?_c('div',{staticClass:"milestone-icon s-align-5",style:({'background-color' : '#2ECC71'})},[_c('mark-icon',{staticClass:"i-white",attrs:{"size":"12"}})],1):_c('WatchProgressIcon',{staticClass:"icon-progress s-align-5"})],1),_c('span',{staticClass:"milestone-title f-sbold mt-2"},[_vm._v("\n                               "+_vm._s(caseItem.name)+"\n                            ")]),_c('span',{staticClass:"f-12-grey w-case-name"},[_vm._v("\n                                "+_vm._s(caseItem.type ? caseItem.type.name : '-')+"\n                            ")]),_c('div',{staticClass:"s-align-5 mt-2"},[_c('span',{class:[
                                   'milestone-date', 'f-12-grey' ]},[_vm._v("\n                                    "+_vm._s(_vm._f("date")(caseItem.start_at))+"\n                                ")])])])])}),(_vm.countWarrantyTypes === 0 && _vm.entityType !== _vm.ENTITY_TYPES.WARRANTY)?_c('tooltip',{attrs:{"direction":"bottom","mobileDescription":"Field info"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"f-16-white t-center"},[_vm._v("\n                                You need to create warranty type first\n                            ")])]},proxy:true}],null,false,1834012097)},[_c('div',{staticClass:"milestone case-add",attrs:{"id":"milestone-add"}},[_c('div',{class:[
                                'milestone-content column-center t-center',
                                'milestone-await'
                            ]},[_c('div',{staticClass:"add-warranty-icon disabled-warranty-icon"},[_c('AddIcon',{attrs:{"size":18}})],1),_c('span',{staticClass:"f-16-grey"},[_vm._v("Add")])])])]):_c('router-link',{staticClass:"milestone case-add",attrs:{"to":_vm.redirectTo,"id":"milestone-add"}},[_c('div',{class:[
                                'milestone-content column-center t-center',
                                'milestone-inprogress-container'
                            ]},[_c('div',{staticClass:"add-warranty-icon"},[_c('AddIcon',{attrs:{"size":18}})],1),_c('span',{staticClass:"f-16-blue"},[_vm._v("Add")])])])],2)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pv-8 ph-20"},[_c('h2',[_vm._v("Warranty action")])])}]

export { render, staticRenderFns }