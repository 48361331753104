<template>
    <svg
        :width="size"
        :height="size"
        viewBox="0 0 30 29"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_3_1378)">
            <circle cx="15.1082" cy="14.4642" r="10.3002" fill="#FFC928"/>
        </g>
        <path d="M18.8238 19.0365L17.4371 20.4121C17.2324 20.622 16.961 20.6932 16.6968 20.6935C15.5282 20.6585 14.4237 20.0845 13.5167 19.495C12.0281 18.4121 10.6622 17.0692 9.80493 15.4464C9.47614 14.7659 9.09035 13.8976 9.12721 13.1381C9.13049 12.8524 9.20749 12.5719 9.40872 12.3878L10.7954 11.0017C11.0834 10.7568 11.3619 10.8415 11.5461 11.1268L12.6618 13.2423C12.7792 13.4929 12.7118 13.7615 12.5366 13.9405L12.0257 14.4512C11.9942 14.4944 11.9741 14.5436 11.9736 14.5971C12.1695 15.3554 12.763 16.0544 13.2873 16.5354C13.8116 17.0165 14.3752 17.6682 15.1068 17.8224C15.1972 17.8477 15.3079 17.8565 15.3726 17.7964L15.967 17.192C16.1718 17.0367 16.4676 16.9608 16.6864 17.0877H16.6968L18.7091 18.2758C19.0045 18.4609 19.0353 18.8188 18.8238 19.0365V19.0365Z" fill="white"/>
        <path d="M19.0767 12.0943C19.2315 11.9396 19.4823 11.9396 19.637 12.0943L20.8935 13.3508C21.1431 13.6004 21.5698 13.4236 21.5698 13.0707L21.5698 8.877C21.5698 8.65821 21.3925 8.48084 21.1737 8.48084L16.98 8.48084C16.6271 8.48084 16.4503 8.90756 16.6999 9.15713L17.9564 10.4137C18.1111 10.5684 18.1111 10.8192 17.9564 10.9739L14.9512 13.9791C14.7965 14.1338 14.7965 14.3847 14.9512 14.5394L15.5113 15.0994C15.666 15.2542 15.9168 15.2542 16.0716 15.0994L19.0767 12.0943Z" fill="white"/>
        <defs>
            <filter id="filter0_d_3_1378" x="1.6387" y="0.994771" width="26.9389" height="26.9389" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="1.58464"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.1625 0 0 0 0 0.03575 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_1378"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_1378" result="shape"/>
            </filter>
        </defs>
    </svg>

</template>

<script>
    export default {
        name: 'OutgoingCall',
        props: {
            size: {
                type: [Number, String],
                default: 30
            }
        }
    }
</script>
