<template>
	<svg 
		viewBox="0 0 103.48700714111328 103.41952514648438"
		:width="size" 
        :height="size"
		class="square-photo-icon"
	>
		<g transform="matrix(8.166666984558105, 0, 0, 8.166666984558105, 3111.8827514648438, -2139.861572265625)">
			<g>
				<path d="M-377,263.9c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c0.4,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.3C-375.3,264.7-376,263.9-377,263.9z M-376.3,265.6c0,0.4-0.3,0.7-0.7,0.7c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.4,0.3-0.7,0.7-0.7S-376.3,265.3-376.3,265.6z"/>
				<path d="M-370,262.1h-9.5c-0.8,0-1.5,0.7-1.5,1.5l0,9.5c0,0.8,0.7,1.5,1.5,1.5h9.5c0.8,0,1.5-0.7,1.5-1.5v-9.5C-368.5,262.8-369.2,262.1-370,262.1z M-369.5,273.1c0,0.2-0.2,0.4-0.4,0.4h-9.5c-0.2,0-0.4-0.2-0.4-0.4v-1.5l2-2l1.8,1.8c0.2,0.2,0.5,0.2,0.8,0l3.4-3.4l2.4,2.4V273.1z M-369.6,268.9l-2-2c-0.2-0.2-0.5-0.2-0.8,0l-3.3,3.4l-1.8-1.8c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.3,0.1-0.4,0.2l-1.7,1.7v-6.5c0-0.2,0.2-0.4,0.4-0.4h9.5c0.2,0,0.4,0.2,0.4,0.4V268.9z"/>
			</g>
		</g>
	</svg>
</template>

<script>
    export default {
        name: 'SquarePhotoIcon',
        props: {
            size: {
                type: [Number, String],
                default: 28
            }
        }
    }
</script>