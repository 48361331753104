<template>
    <div class="modal s-align-5">
        <div class="add-discount-modal cm-fond cm-scroll">
            <h2 class="f-24-black f-sbold t-center">Add discount</h2>
            <div class="w-100 pb-8">
                <form-input 
                    label="Discount name"
                    :class="[{ 'form-input-danger' : errors.has('name') }]"
                >
                    <input 
                        type="text" 
                        name="name" 
                        class="form-input-field"
                        placeholder="Discount name"
                        v-input-style="discount.description"
                        v-model="discount.description"
                        v-validate="'required|min:3|max:250'"
                    />
                </form-input>
            </div>
            <div class="w-100">
                <form-input 
                    label="Discount percentage"
                    :class="[{ 'form-input-danger' : errors.has('percentage') }]"
                    maskAfter="%"
                    :maskValue="discount.value"
                >
                    <input 
                        type="text" 
                        name="percentage" 
                        class="form-input-field"
                        placeholder="Discount percentage"
                        v-input-style="discount.value"
                        v-model="discount.value"
                        v-validate="'required|decimal|min_value:0|max_value:100'"
                        v-limiter
                    />
                </form-input>
            </div>
            <div class="custom-modal-footer row-between">
                <button 
                    class="add-button"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button 
                    class="primary-button"
                    @click="validateBeforeSubmit"
                    :disabled="requestIsGoing"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { FormInput } from '@/ui';
    import api from '@/headers.js';

    export default {
        name: 'AddDiscountModal',
        components: {
            FormInput
        },
        props: {
            discount: {
                type: Object,
                default: function() {
                    return {
                        description: '',
                        value: '',
                        type: ''
                    }
                }
            }
        },
        data() {
            return {
                requestIsGoing: false
            }
        },
        methods: {
            validateBeforeSubmit() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        this.requestIsGoing = true;

                        this.discount.type === 'create'
                            ? this.saveDiscount(this.discount)
                            : this.updateDiscount(this.discount);
                    } else {
                        this.notifyError(`${this.$validator.errors.items[0].msg}.`);
                    };
                });
            },
            saveDiscount(params) {
                api.post('/estimate-discounts', params)
                    .then(() => {
                        this.notifySuccess(`Discounts has been added!`);

                        this.$emit('update');
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.$emit('close');
                        this.requestIsGoing = false;
                    });
            },
            updateDiscount(params) {
                api.put('/estimate-discounts/' + params.id, params)
                    .then(() => {
                        this.notifySuccess('Discount has been updated!');

                        this.$emit('update');
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.$emit('close');
                        this.requestIsGoing = false;
                    });
            }
        }
    }
</script>