var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('full-modal',{staticClass:"pick-list-modal",on:{"close":function($event){return _vm.$emit('close',_vm.editingFields,_vm.fieldIndex)}}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v("\n        Dropdown properties\n    ")]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"row-between mb-3"},[_c('span',{staticClass:"f-14-darkgrey f-sbold"},[_vm._v("\n            Add and prioritize options\n        ")]),_c('span',{staticClass:"f-14-darkgrey f-sbold"},[_vm._v("\n            Choose default value\n        ")])]),_vm._l((_vm.selectData),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"pick-list-property row-start row-baseline mb-1",class:{
                    selected: (_vm.propertiesDraggingIndex === index),
                    over: (index === _vm.propertiesOverIndex && index !== _vm.propertiesDraggingIndex)
                },attrs:{"draggable":"true"},on:{"dragover":function($event){return _vm.propertiesDragOver(item, index)},"dragend":function($event){return _vm.propertiesDragEnd(item, index)},"dragstart":function($event){return _vm.propertiesDragStart(item, index, $event)}}},[_c('line-icon',{staticClass:"i-grey",attrs:{"size":"15"}}),_c('div',{class:[
                    'w-100',
                    _vm.mScreen ? 'pick-list-input-mobile' : 'pick-list-input-desktop row-start'
                ]},[_c('form-input',{class:[{ 
                            'form-input-danger' : 
                            _vm.errors.has('pick_list_property_' + index) 
                        }]},[_c('input',{directives:[{name:"input-style",rawName:"v-input-style",value:(_vm.selectData[index].value),expression:"selectData[index].value"},{name:"model",rawName:"v-model",value:(_vm.selectData[index].value),expression:"selectData[index].value"},{name:"validate",rawName:"v-validate",value:('required|max:30'),expression:"'required|max:30'"}],staticClass:"form-input-field",attrs:{"type":"text","id":'pick_list_property_' + index,"name":'pick_list_property_' + index,"placeholder":"Enter value","maxlength":"30"},domProps:{"value":(_vm.selectData[index].value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selectData[index], "value", $event.target.value)}}})])],1),_c('radio-button',{class:[
                        _vm.selectData.length > 1 ? 'mr-3' : 'mr-25',
                        { 'none-events' : item.default}
                    ],attrs:{"color":"blue"}},[_c('input',{attrs:{"type":"checkbox","name":"default_item"},domProps:{"checked":item.default},on:{"change":function($event){return _vm.selectDefault(item.id)}}})]),(_vm.selectData.length > 1)?_c('button',{staticClass:"pick-list-delete transparent-button",on:{"click":function($event){return _vm.deleteOption(index)}}},[_c('trash-icon',{staticClass:"i-grey",attrs:{"width":17,"height":17}})],1):_vm._e()],1)])}),_c('button',{staticClass:"custom-fields-new-item s-align-5 w-100",on:{"click":function($event){return _vm.addNewField()}}},[_c('div',{staticClass:"round s-align-5 mr-5"},[_c('add-icon',{attrs:{"size":"9"}})],1),_c('span',{staticClass:"f-13-blue f-sbold"},[_vm._v("New option")])])],2),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"add-button mb-2",on:{"click":function($event){return _vm.$emit('close',_vm.editingFields,_vm.fieldIndex)}}},[_vm._v("\n            Cancel\n        ")]),_c('button',{staticClass:"primary-button mb-2",on:{"click":_vm.updateField}},[_vm._v("\n            Save\n        ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }