<template>
    <div 
        :class="[
            'users-select column-start',
            `users-select-${direction}`
        ]"
    >
        <div class="row-between mb-6">
            <span class="f-16-black">Assign to</span>
            <button 
                class="icon-light"
                @click="$emit('close')"
            >
                <close-icon size="15"/>
            </button>
        </div>
        <div class="users-search row-start row-baseline mb-8 p-7">
            <search-icon class="i-grey mr-3"/>
            <input 
                type="text"
                name="search_members"
                id="search_members"
                v-model="needToFind"
                placeholder="Search members"
            >
        </div>
        <slot/>
        <p class="f-13-black f-sbold mb-1">Members</p>
        <Loader v-if="isLoading" size="small"/>
        <perfect-scrollbar 
            v-else
            class="ps-grey column-start user-list w-100 pr-2"
        >   
            <button
                v-for="(user, index) in users || allUsers"
                :key="index"
                :class="[
                    'user row-between row-baseline p-2 bb-grey',
                    checkIfUserSelected(user.id)  ? 'selected' : '',
                    checkUserVisibility(user) ? 'hide' : ''
                ]"
                :disabled="disabled"
                @click="$emit('update', user.id)"
            >
                <user-box
                    :key="index"
                    :firstName="user.first_name"
                    :lastName="user.last_name"
                    :avatarUrl="user.thumbnail"
                />
                <template v-if="checkIfUserSelected(user.id) && icon === 'mark-icon'">
                    <mark-icon 
                        class="i-green"
                        size="10"
                    />
                    <close-icon 
                        size="8"
                        class="i-red"
                    />
                </template>
                <bell-icon
                    v-if="icon === 'bell-icon'"
                    size="15"
                />
            </button>
        </perfect-scrollbar>
    </div>
</template>

<script>
    import { Loader } from '@/ui';
    import { UserBox } from '@/components';
    import { SearchIcon, CloseIcon, MarkIcon, BellIcon } from '@/icons';

    export default {
        name: 'UsersSelect',
        components: {
            Loader,
            UserBox,
            SearchIcon,
            MarkIcon,
            CloseIcon,
            BellIcon
        },
        props: {
            direction: {
                type: String,
                default: 'left'
                // available directions - top, right, bottom, left
            },
            disabled: {
                type: Boolean,
                default: false
            },
            selectedUsers: {
                type: [Array, Object],
                required: true
            },
            users: {
                type: Array,
                required: false
            },
            icon: {
                type: String,
                default: 'mark-icon'
            },
            hideLoggedUser: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                needToFind: ''
            }
        },
        computed: {
            isLoading() {
                return this.$store.getters['users/isLoading'];
            },
            allUsers() {
                return this.$store.getters['users/userList'];
            },
            loggedUserId() {
                return this.$store.getters['user/getUserId'];
            }
        },
        methods: {
            checkUserVisibility(user) {
                if (this.hideLoggedUser && user.id === this.loggedUserId) {
                    return true;
                }

                return !user.fullname.toLowerCase().includes(this.needToFind.toLowerCase());
            },
            checkIfUserSelected(id) {
                if (Array.isArray(this.selectedUsers)) {
                    return this.selectedUsers.some(user => user.id === id || user.user_id === id);
                } else {
                    return id === this.selectedUsers.id;
                }
            }
        },
        created() {
            if (!this.users && !this.allUsers.length) {
                const params = {
                    perPage: 100,
                    is_active: 1
                };

                this.$store.dispatch('users/USERS_GET', params)
                    .then(response => {
                        this.$store.dispatch('users/USERS_PUSH', response.data)
                    });
            }
        }
    }
</script>