<template>
    <div class="column-start w-100 pt-6 pb-2">
        <button 
            class="s-align-4 transparent-button"
            @click="open = !open"
        >
            <arrow-icon 
                class="i-darkgrey mr-4"
                size="6"
                :transform="open ? 270 : 90"
            />
            <span class="f-14-asphalt f-sbold">Point of contacts</span>
            <span 
                class="f-14-grey pl-1"
                v-if="representativesLength >= 1"
            >
                {{ `(${representativesLength})` }}
            </span>
        </button>
        <representatives 
            v-if="open"
            id="representative_unit"
            :options="propertyRepresentatives"
            :existing="representatives"
            :forRemove="representativesForRemove"
            :moduleMode="'update'"
            :saveImmediately="true"
            :entityId="unitId"
            :loadRepresentatives="loadRepresentatives"
            @save="saveUnitRepresentatives"
        />        
    </div>
</template>

<script>
    import { Representatives } from '@/components';
    import { ArrowIcon } from '@/icons';
    import api from '@/headers.js';

    export default {
        name: 'UnitRepresentatives',
        props: {
            representatives: {
                type: Array,
                required: true
            },
            propertyRepresentatives: {
                type: Array,
                required: true
            },
            unitId: {
                type: [String, Number],
                required: true
            }
        },
        components: {
            Representatives,
            ArrowIcon
        },
        data() {
            return {
                representativesForRemove: [],
                open: false,
                loadRepresentatives: false
            }
        },
        computed: {
            representativesLength() {
                return this.representatives.length;
            }
        },
        methods: {
            saveUnitRepresentatives(data) {
                this.loadRepresentatives = true;
                const params = { representatives: data };
                
                if (this.representativesForRemove.length) {
                    Object.assign(params, { representatives_for_remove: this.representativesForRemove });
                }

                api.put(`/commercial/units/${this.unitId}`, params)
                    .then(response => {
                        this.notifySuccess('Point of contacts is updated!');
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => this.loadRepresentatives = false);
            }
        }
    }
</script>