<template>
    <span 
        :class="[
            's-align-5', 's-marker', `m-${getOneWordForMarker}`, 
            { 'sm-finished' : !marker.length }
        ]"
        :style="{
            height: size + 'px', width: size + 'px',
            'background-color': color && color.length ? color : ''
        }"
    >
        <template v-if="!marker.length && !deadMarker.length">
            <warning-icon
                class="i-white"
                size="16"
            />
        </template>
        <template v-else>
            {{ abr }}
        </template>
    </span>
</template>

<script>
    import { MILESTONE_STATUSES } from '@/constants';
    import { getAbbreviation } from '@/helpers';
    import { WarningIcon } from '@/icons';

    export default {
        name: 'StatusMarker',
        components: {WarningIcon},
        props: {
            marker: {
                type: [String],
                default: ''
            },
            deadMarker: {
                type: [String],
                default: ''
            },
            size: {
                type: [String, Number],
                default: 40
            },
            status: {
                type: String,
                required: false
            },
            color: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                dead: MILESTONE_STATUSES.DEAD
            }
        },
        computed: {
            abr() {
                if (this.status == this.dead) {
                    return getAbbreviation(this.deadMarker);
                } else if (this.status != this.dead && !this.marker.length) {
                    return 'DE';
                } else {
                    return getAbbreviation(this.marker);
                }
            },
            getOneWordForMarker() {
                let markerWord = this.marker.split(' ');
                return markerWord && markerWord.length 
                    ? markerWord[0].toLowerCase()
                    : '';
            }
        }
    }
</script>
