<template>
    <svg 
        viewBox="0 0 275 300"
        :width="size"
        :height="size"
        class="dashboard-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="275" 
            height="300" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(17.06829687581378, 0, 0, 17.06829687581378, 6607.788994967267, -4420.454447631295)">
            <path 
                class="st0" 
                d="M-371.7,266.1l-6.7-6.4c-0.1-0.1-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2l-6.7,6.4c-0.2,0.1-0.2,0.3-0.2,0.5v9c0,0.4,0.4,0.8,0.8,0.8l4.6,0c0.4,0,0.8-0.4,0.8-0.8v-3.5h2.6v3.5c0,0.4,0.4,0.8,0.8,0.8h4.6c0.4,0,0.8-0.3,0.8-0.8v-9C-371.5,266.5-371.6,266.3-371.7,266.1z M-376.9,270.7l-4.2,0c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.3-0.3,0.6v3.5h-3V267l5.9-5.6l5.9,5.6v7.9h-3v-3.5C-376.1,271-376.4,270.7-376.9,270.7z"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'DashboardIcon',
        props: {
            size: {
                type: [Number, String],
                default: 18
            }
        }
    }
</script>

