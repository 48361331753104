<template>
    <div class="w-100 pt-20">
        <div class="report-list-table">
            <div 
                class="table-head row-baseline pv-8"
                v-if="dScreen"
            >
                <div class="lg-4">
                    <button 
                        class="transparent-button"
                        @click="changeSort(SORT_BY.NAME)"
                    >
                        <move-icon 
                            transform="90" 
                            :class="[
                                'order-by-icon mr-1', 
                                sortBy === SORT_BY.NAME ? `order-${orderBy}` : ''
                            ]"
                        />
                        <span class="f-12-asphalt f-sbold">Title</span>
                    </button>
                </div>
                <div class="lg-4">
                    <button 
                        class="transparent-button"
                        @click="changeSort(SORT_BY.CREATED_AT)"
                    >
                        <move-icon 
                            transform="90" 
                            :class="[
                                'order-by-icon mr-1', 
                                sortBy === SORT_BY.CREATED_AT ? `order-${orderBy}` : ''
                            ]"
                        />
                        <span class="f-12-asphalt f-sbold">Created date</span>
                    </button>
                </div>
                <div class="lg-4">
                    <button 
                        class="transparent-button"
                        @click="changeSort(SORT_BY.SENT_DATE)"
                    >
                        <move-icon 
                            transform="90" 
                            :class="[
                                'order-by-icon mr-1', 
                                sortBy === SORT_BY.SENT_DATE ? `order-${orderBy}` : ''
                            ]"
                        />
                        <span class="f-12-asphalt f-sbold">Sent date</span>
                    </button>
                </div>
            </div>
            <div class="table-body">
                <div 
                    class="table-row row-between"
                    v-for="(item, index) in data"
                    :key="`item-${index}`"
                >
                    <div 
                        class="table-row-buttons s-align-5 pv-4"
                        v-if="dScreen"
                    >
                        <paper-icon 
                            size="22" 
                            class="i-grey"
                        />
                    </div>
                    <div class="table-row-content row-baseline">
                        <div class="table-row-title row-between lg-4 nb-12 md-12 sm-12 pv-4">
                            <div class="column-start">
                                <button 
                                    class="s-align-4 transparent-button"
                                    @click="selectedRowIndex = selectedRowIndex == index ? null : index"
                                >
                                    <arrow-icon 
                                        v-if="!dScreen"
                                        :transform="selectedRowIndex == index ? 270 : 90"
                                        class="mr-2 i-grey"
                                    />
                                    <div class="column-start">
                                        <span class="f-13-asphalt f-sbold">{{ item.name }}</span>
                                        <span class="f-11-grey">{{ item.layout.name }}</span>
                                    </div>
                                </button>
                            </div>
                            <div 
                                class="relative s-align-6"
                                v-if="!dScreen"
                            >
                                <context-menu :unicId="`table-menu-${index}`">
                                    <button
                                        class="s-align-4"
                                        @click="$emit('remove', item)"                                  
                                    >
                                        <span class="icon-container mr-6">
                                            <trash-icon width="15"/>
                                        </span>
                                        <span>Delete Report</span>
                                    </button>
                                </context-menu>
                            </div>
                        </div>
                        <div 
                            class="column-start lg-4 nb-12 md-12 sm-12 pv-4"
                            v-show="dScreen || selectedRowIndex == index"
                        >
                            <span class="f-13-grey">
                                created by
                                <span class="f-12-blue pl-1">
                                    {{ item.created_by.fullname }}
                                </span>
                            </span>
                            <span class="f-13-grey">
                                on
                                <span class="f-13-asphalt pl-1">{{ item.created_at | date }}</span>
                            </span>
                            <span class="table-hint f-10-grey uppercase">Created date</span>
                        </div>
                        <div 
                            class="column-start lg-4 nb-12 md-12 sm-12 pv-4"
                            v-show="dScreen || selectedRowIndex == index"
                        >
                        <!-- Need generate & send integration -->
                            <!-- <span class="f-13-grey">
                                sent to
                                <span class="f-13-blue pl-1">admin@mail.com</span>
                            </span>
                            <span class="f-13-grey">
                                on
                                <span class="f-13-asphalt pl-1">{{ item.sent_date }}</span>
                            </span>
                            <span class="table-hint f-10-grey uppercase">Sent date</span> -->
                        </div>
                    </div>
                    <div 
                        class="table-row-buttons s-align-5 pv-4"
                        v-if="dScreen"
                    >
                        <tooltip
                            direction="left"
                            :fixedDirection="true"
                            :isShow="activeReportTooltip === index"
                            color="white"
                        >
                            <button 
                                class="icon-light"
                                @click="activeReportTooltip = activeReportTooltip == index ? null : index"
                            >
                                <trash-icon/>
                            </button>
                            <template v-slot:content>
                                <p class="f-14-dark f-sbold t-center mb-8">
                                    Are you sure to want to delete this item?
                                </p>
                                <div class="row-between">
                                    <button 
                                        class="add-button"
                                        @click="activeReportTooltip = null"
                                    >
                                        No
                                    </button>
                                    <button 
                                        class="red-button"
                                        @click="$emit('remove', item.id, index), activeReportTooltip = null"
                                    >
                                        Yes
                                    </button>
                                </div>
                            </template>
                        </tooltip>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { MoveIcon, PaperIcon, TrashIcon, ArrowIcon } from '@/icons';
    import ContextMenu from '@/components/ContextMenu.vue';
    import { Tooltip } from '@/ui';

    export default {
        name: 'FormBuilderReportTable',
        components: {
            MoveIcon,
            PaperIcon,
            TrashIcon,
            ArrowIcon,
            ContextMenu,
            Tooltip
        },
        props: {
            data: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                selectedRowIndex: null,
                selectedMenuIndex: null,
                activeReportTooltip: null,
                SORT_BY: {
                    NAME: 'name',
                    CREATED_AT: 'created_at',
                    SENT_DATE: 'sent_date'
                },
                ORDER_BY: {
                    ASC: 'asc',
                    DESC: 'desc'
                },
                orderBy: '',
                sortBy: ''
            }
        },
        methods: {
            changeSort(sort_by) {
                if (this.sortBy === sort_by) {
                    this.orderBy = this.orderBy === this.ORDER_BY.ASC ? this.ORDER_BY.DESC : this.ORDER_BY.ASC;
                } else {
                    this.sortBy = sort_by;
                    this.orderBy = this.ORDER_BY.ASC;
                }
                
                this.$emit('set', { sortBy: this.sortBy })
            },
        }
    }
</script>