<template>
    <div class="estimate-total-sticky w-100">
        <div class="row-center pb-30">
            <p class="f-24-white f-sbold">Estimate Total</p>
        </div>
        <div class="column-center w-100 pt-4">
            <div class="row-center s-align-8">
                <animate-number 
                    class="f-36-white f-sbold"
                    data-name="total_big"
                    :number="estimate.disc_tax_total"
                    filter="currency"
                />
                <span
                    v-if="estimate.discounts.length"
                    class="blue-label f-12-white ml-4 mb-3"
                >
                    -{{ summDiscount() }}%
                </span>
            </div>
            <span class="f-16-white opacity-5">Total Price</span>
            <span 
                class="f-14-white opacity-5"
                v-if="isDiscount || isTaxes"
            >
                {{ explanation }}
            </span>
        </div>
        <div 
            class="w-100"
            v-if="totalIsOpen"
        >
            <div class="estimate-total-details row-between">
                <div :class="['details-data', { 'details-data-single' : !isDiscount }]">
                    <div class="row-between">
                        <span class="f-14-white opacity-5">Total Cost</span>
                        <span
                            class="f-18-white f-sbold"
                            data-name="total_cost"
                        >
                            {{ estimate.total_cost | currency }}
                        </span>
                    </div>
                </div>
                <div 
                    class="details-data"
                    v-if="isDiscount"
                >
                    <div class="row-between">
                        <span class="f-14-white opacity-5">Total Price</span>
                        <span
                            class="f-18-white f-sbold"
                            data-name="total_price"
                        >
                            {{ estimate.sub_total | currency }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row-start pb-6 pt-4">
                <p class="f-18-white f-sbold">Profit</p>
            </div>
            <div class="estimate-profit-data row-between">
                <div class="profit-data">
                    <div class="row-between">
                        <div class="s-align-4">
                            <span class="f-14-white opacity-5">Net <br/> Profit</span>
                        </div>
                        <div class="s-align-6">
                            <span
                                class="f-24-white f-sbold"
                                data-name="total_net_profit"
                            >
                                {{ estimate.net_profit | currency }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="profit-data">
                    <div class="row-between">
                        <div class="column-start ">
                            <div class="pt-5">
                                <increase-icon
                                    :transform="estimate.t_profit_margin <= 0 ? 0 : 180"
                                    :class="[estimate.t_profit_margin <= 0 ? 'i-red' : 'i-green']"
                                />
                            </div>
                            <span class="f-14-white opacity-8">Profit <br/> Margin</span>
                        </div>
                        <div class="s-align-6">
                            <simple-diagram 
                                :size="85"
                                :segment="estimate.t_profit_margin"
                                data-name="total_profit_margin"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-start">
                <p class="f-18-white f-sbold">Taxes & Discounts</p>
            </div>
            <div class="estimate-total-details row-between">
                <div class="details-data">
                    <div class="row-between">
                        <span class="f-14-white opacity-8">Taxes</span>
                        <span class="f-18-white f-sbold">{{ estimate.total_tax | currency }}</span>
                    </div>
                </div>
                <div class="details-data">
                    <div class="row-between">
                        <span class="f-14-white opacity-8">Discount</span>
                        <span
                            class="f-18-white f-sbold"
                            data-name="total_discount"
                        >
                            {{ estimate.t_discount | currency }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="estimate-total-warn s-align-1 pb-30">
                <lock-icon class="mr-4"/>
                <span class="f-11-white opacity-8">
                    Fixed Pricing items are not included in the Profit Margin
                </span>             
            </div>
        </div>
        <div class="show-total-details">
            <button @click="totalIsOpen = !totalIsOpen">
                <span class="f-12-white">
                    {{ !totalIsOpen ? 'Show all details' : 'Hide all details' }}
                </span><br/>
                <arrow-icon :transform="!totalIsOpen ? 90 : 270"/>
            </button>
        </div>
    </div>
</template>

<script>
    import { AnimateNumber, SimpleDiagram } from '@/ui';
    import { IncreaseIcon, LockIcon, ArrowIcon } from '@/icons';

    export default {
        name: 'EstimateTotal',
        components: {
            AnimateNumber,
            IncreaseIcon,
            SimpleDiagram,
            LockIcon,
            ArrowIcon
        },
        props: {
            estimate: {
                type: Object,
                required: true
            },
            isDiscount: {
                type: Boolean,
                default: false
            },
            isTaxes: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                totalIsOpen: true
            }
        },
        computed: {
            explanation() {
                if (this.isDiscount && !this.isTaxes) {
                    return 'including discount'
                } else if (!this.isDiscount && this.isTaxes) {
                    return 'including tax'
                } else if (this.isDiscount && this.isTaxes) {
                    return 'including discount and tax'
                }
            }
        },
        methods: {
            checkScreenSize() {
                this.totalIsOpen = this.$store.getters['views/getScreenSizeInPixels'] > 1023;
            },
            summDiscount() {
                if(this.estimate.discounts.length) {
                    return this.estimate.discounts.reduce(function(sum, current) {
                        return sum + current.value;
                    }, 0)
                }
            }
        },
        mounted() {
            this.checkScreenSize();
            window.addEventListener('resize', this.checkScreenSize);
        },
        destroyed() {
            window.removeEventListener('resize', this.checkScreenSize);
        }
    }
</script>