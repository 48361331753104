<template>
    <svg
        viewBox="0 0 20 13"
        :width="size"
        :height="size"
        :style="{ transform: 'rotate('+ transform + 'deg)' }"
        class="sort-icon"
    >
        <g>
            <rect
                x="7.7" y="9.6"
                width="12.3"
                height="1.8"
            />
            <rect
                x="7.7"
                y="5.8"
                width="9.3"
                height="1.8"
            />
            <rect
                x="7.7"
                y="1.9"
                width="4.8"
                height="1.8"
            />
            <polygon points="3.3,10.1 3.3,0 1.8,0 1.8,10.1 1,9.3 0,10.4 2.5,13 5,10.4 4,9.3"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'SortIcon',
        props: {
            size: {
                type: [Number, String],
                default: 15
            },
            transform: {
                type: [Number, String],
                default: 0
            }
        }
    }
</script>