<template>
    <div
        class="l-filter column-between-start"
        v-scroll="scrollListener"
    >
        <div class="l-filter-search row-start s-align-4">
            <search-icon/>
            <input
                type="text"
                name="leads_search"
                class="l-filter-input"
                placeholder="Filter Results"
                v-model="filtersInput"
            />
        </div>
        <perfect-scrollbar
            id="container-filters"
            class="ps-grey flex-grow-1"
        >
        </perfect-scrollbar>
        <div class="w-100 l-filter-expand-all">
            <checkbox-button
                class="ph-20 pv-15 s-align-1 w-100 f-12-black"
                color="grey"
                label="Expand all items"
            >
                <input
                    type="checkbox"
                    name="expand_all"
                    id="expand_all"
                    v-model="isExpandAll"
                    @change="checkExpandAll"
                />
            </checkbox-button>
        </div>
    </div>
</template>

<script>
    import {SearchIcon, ArrowIcon} from '@/icons';
    import {CheckboxButton, PriorityCheckbox} from '@/ui';
    import LeadFilterSection from '../LeadFilterSection.vue';
    import api from '@/headers.js';
    import debounce from 'lodash/debounce';
    import isEmpty from 'lodash/isEmpty';
    import {BASE_ENTITY_TYPES} from '@/constants';
    
    export default {
        name: 'WarrantyJobsFilter',
        props: [],
        components: {
            SearchIcon,
            ArrowIcon,
            CheckboxButton,
            LeadFilterSection,
            PriorityCheckbox
        },
        data() {
            return {
                isExpandAll: true,
                filtersInput: ''
            }
        },
     
        methods: {
            checkExpandAll() {
                if (!this.isExpandAll) {
                    document.getElementById('container-filters').scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }
            },
            scrollListener: function (event, el) {
                if (window.innerWidth > 1165) {
                    window.scrollY > 50
                        ? el.style.paddingTop = 70 + 'px'
                        : el.style.paddingTop = 150 + 'px';
                } else {
                    return false;
                }
            }
        }
    }
</script>
