<template>
    <div class="w-100">
        <div class="event-edit-title p-8">
            <input 
                class="transparent-input"
                type="text"
                placeholder="Event Title"
                name="new_event_title"
                v-model="summary"
                v-validate="'min:3|max:99'"
                maxlength="100"
                :class="[{ 'transparent-input-danger' : errors.has('new_event_title') }]"
                ref='sumamry'
            >
        </div>
        <div class="tabs-menu bb-grey">
            <div class="tabs-menu-panel row-start">
                <button
                    v-for="(tab, index) in TABS"
                    :key="index"                    
                    :class="[
                        'tabs-menu-item h-100 transparent-button',
                        { 'tabs-menu-item-active' : activeTab === tab}
                    ]"
                    @click="activeTab = tab"
                >
                    {{ tab }}
                </button>
            </div>
        </div>
        <div
            v-if="activeTab === TABS.INFORMATION"
            class="p-20"
        >
            <div
                v-if="reAssignMode && event.is_organizer && !isMyCalendar"
                class="row-start row-baseline mb-8"
            >
                <div class="lg-4">
                    <person-icon
                        size="15"
                        class="i-grey mr-3"
                    />
                    <span class="f-12-black f-sbold">Assigned</span>
                </div>
                <div class="lg-8 column-start">
                    <multiselect
                        :options="calendarList"
                        :track-by="'fullname'"
                        label="fullname"
                        name="assign_list"
                        placeholder="Assign to"
                        v-model="eventAssignedTo"
                        data-name="assign_list"
                        :show-labels="false"
                        v-validate="'required'"
                        :class="['event-assign-multiselect', { 'multiselect-danger' : errors.has('assign_list') }]"
                        @select="getCalendarIdByOwner($event, true)"
                    >
                        <template 
                            slot="singleLabel" 
                            slot-scope="props"
                        >      
                            <template v-if="props.option.first_name !== undefined || props.option.name !== undefined">
                                <user-box
                                    :firstName="props.option.first_name || props.option.name"
                                    :lastName="props.option.last_name || ''"
                                    :avatarUrl="props.option.avatar" 
                                />
                            </template>
                            <template v-else>
                                <p class="f-14-grey">Assign to...</p>
                            </template>
                        </template>
                        <template 
                            slot="option" 
                            slot-scope="props"
                        >   
                            <user-box
                                :firstName="props.option.first_name || props.option.name"
                                :lastName="props.option.last_name || ''"
                                :avatarUrl="props.option.avatar" 
                            />
                        </template>
                    </multiselect>
                </div>
            </div>
            <checkbox-button
                color="grey"
                label="Link to a record"
                labelDirection="left"
                class="mb-6 f-12-black"
            >
                <input 
                    type="checkbox" 
                    name="is_job_field_show" 
                    v-model="isJobFieldShow"
                />
            </checkbox-button>
            <div 
                v-if="isJobFieldShow"
                class="row-start row-baseline mb-3"
            >
                <div class="lg-4 s-algign-4 ">
                    <briefcase-icon class="i-grey mr-3"/>
                    <span class="f-12-black f-sbold">Record</span>
                </div>
                <div class="lg-8 event-job-search">
                    <search-icon
                        size="15"
                        class="i-grey"
                    />
                    <input 
                        type="text"
                        placeholder="Job"
                        v-model="job"
                        @keyup.enter="searchJobs"
                        maxlength="99"
                    >
                    <div 
                        v-if="searchResultsShown"
                        class="job-search-result"
                    >
                        <Loader size="small" v-if="searchResultsLoading"/>
                        <template v-for="(result, index) in filteredSearchResults">
                            <button
                                v-if="result.type === BASE_ENTITY_TYPES.RESIDENTIAL"
                                :key="index"
                                class="transparent-button t-left f-13-black pv-1 bb-grey"
                                @click="setJob(result)"
                            >
                                {{ result.customer }}
                                (residential)
                            </button>
                            <button
                                v-if="result.type === BASE_ENTITY_TYPES.COMMERCIAL_JOB"
                                :key="index"
                                class="transparent-button t-left f-13-black pv-1 bb-grey"
                                @click="setJob(result)"
                            >
                                {{ `${result.property}, ${result.job_number}` }}
                                (commercial job)
                            </button>
                            <button
                                v-if="result.type === BASE_ENTITY_TYPES.COMMERCIAL_LEAD"
                                :key="index"
                                class="transparent-button t-left f-13-black pv-1 bb-grey"
                                @click="setJob(result)"
                            >
                                {{ result.property }}
                                (commercial lead)
                            </button>
                        </template>
                        <div
                            v-if="!filteredSearchResults.length && !searchResultsLoading"
                            class="column-center"
                        >
                            <p class="f-13-grey f-italic">Nothing to display</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-start row-baseline mb-2">
                <div class="lg-4">
                    <person-icon
                        size="15"
                        class="i-grey mr-3"
                    />
                    <span class="f-12-black f-sbold">POC</span>
                </div>
                <div class="lg-8">
                    <form-input>
                        <input
                            class="form-input-field"
                            type="text"
                            v-model="first_name"
                            placeholder="Point of contacts"
                            :disabled="isJobFieldShow" 
                        >
                    </form-input>
                </div>
            </div>
            <div class="row-start row-baseline mb-2">
                <div class="lg-4">
                    <phone-icon
                        size="17"
                        class="i-grey mr-3"
                    />
                    <span class="f-12-black f-sbold">Phone</span>
                </div>
                <div class="lg-8">
                    <form-input :class="[{ 'form-input-danger' : errors.has('phone') }]">
                        <input
                            class="form-input-field"
                            name="phone"
                            type="tel"
                            v-mask="'(###) ###-####'"
                            v-model="phone"
                            placeholder="Phone"
                            v-validate="'min:14'"
                            :disabled="isJobFieldShow"
                        >
                    </form-input>
                </div>
            </div>
            <div class="row-start row-baseline mb-2">
                <div class="lg-4">
                    <envelope-icon
                        size="17"
                        class="i-grey mr-3"
                    />
                    <span class="f-12-black f-sbold">E-mail</span>
                </div>
                <div class="lg-8">
                    <form-input :class="[{ 'form-input-danger' : errors.has('email') }]">
                        <input
                            class="form-input-field"
                            name="email"
                            type="email"
                            v-model="email"
                            placeholder="E-mail"
                            v-validate="'email'"
                            :disabled="isJobFieldShow"
                        >
                    </form-input>
                </div>
            </div>
            <div class="row-start row-baseline mb-2">
                <div class="lg-4">
                    <map-icon
                        size="17"
                        class="i-grey mr-3"
                    />
                    <span class="f-12-black f-sbold">Address</span>
                </div>
                <div class="lg-8">
                    <form-input>
                        <autocomplete 
                            @address="setAddress"
                            id="address"
                            identifier="address"
                            :value="location.address"
                            @input="onLocationManualInput"
                        >
                        </autocomplete>
                    </form-input>
                </div>
            </div>
            <div
                v-if="calendarType === CALENDAR_TYPES.USER || calendarType === CALENDAR_TYPES.TECHNICIAN"
                class="row-start row-baseline mb-2"
            >
                <div class="lg-4">
                    <pick-list-icon
                        size="17"
                        class="i-grey mr-3"
                    />
                    <span class="f-12-black f-sbold">Calendar</span>
                </div>
                <div class="lg-8">
                    <multiselect
                        :class="[
                            'multiselect-small', 
                            { 'multiselect-small-danger' : !isOutlookSynced }
                        ]"
                        :showLabels="false"
                        :max-height="200"
                        :options="calendarsListSelect"
                        label="summary"
                        placeholder="Calendars"
                        v-model="selectedCalendar"
                        name="new_event_calendar"
                        data-name="new_event_calendar"
                        :searchable="false"
                    >
                        <template 
                            slot="singleLabel" 
                            slot-scope="props"
                        >      
                            <template v-if="props.option">
                                <div class="s-align-4">
                                    <crm-logo
                                        v-if="props.option.provider == CRM" 
                                        class="mr-2"
                                    />
                                    <outlook-logo
                                        v-else 
                                        class="mr-2"
                                    />
                                    <span class="f-11-black ellipsis">{{ props.option.summary }}</span>
                                    <span
                                        v-if="props.option.primary"
                                        class="light-blue-label f-10-white ml-1"
                                    >
                                        Primary
                                    </span>
                                </div>
                            </template>
                        </template> 
                        <template 
                            slot="option" 
                            slot-scope="props"
                        >   
                            <div class="s-align-4">
                                <crm-logo
                                    v-if="props.option.provider == CRM" 
                                    class="mr-2"
                                />
                                <outlook-logo
                                    v-else 
                                    class="mr-2"
                                />
                                <span class="f-11-black ellipsis">{{ props.option.summary }}</span>
                                <span
                                    v-if="props.option.primary"
                                    class="light-blue-label f-10-white ml-1"
                                >
                                    Primary
                                </span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
            <checkbox-button
                color="grey"
                label="All day"
                labelDirection="left"
                class="mt-8 mb-6 f-12-black"
            >
                <input 
                    type="checkbox" 
                    name="is_event_all_day" 
                    v-model="isEventAllDay"
                />
            </checkbox-button>
            <div class="row-start row-baseline mb-2">
                <div class="lg-4">
                    <calendar-icon
                        size="17"
                        class="i-grey mr-3"
                    />
                    <span class="f-12-black f-sbold">Start</span>
                </div>
                <div class="lg-8">
                    <form-date-time-input 
                        v-if="!isEventAllDay"
                        v-model="dtstart"
                        v-validate="'eventStartDate'"
                        name="event_start_date"
                        placeholder="Start Date"
                        size="small"
                        :isFullWidth="true"
                        :class="[{ 'form-date-time-input-error' : errors.has('event_start_date') }]"
                    />
                    <form-datepicker
                        v-else
                        v-model="dtstart"
                        v-validate="'eventStartDate'"
                        name="event_start_date"
                        placeholder="Start Date"
                        size="small"
                        :isFullWidth="true"
                        keyDatePicker="event_start_date_picker"
                        :class="[{ 'form-datepicker-error' : errors.has('event_start_date') }]"
                    />
                </div>
            </div>
            <div class="row-start row-baseline mb-2">
                <div class="lg-4 pl-25">
                    <span class="f-12-black f-sbold">End</span>
                </div>
                <div class="lg-8">
                    <form-date-time-input
                        v-if="!isEventAllDay" 
                        v-model="dtend"
                        v-validate="'eventEndDate'"
                        name="event_end_date"
                        placeholder="End Date"
                        size="small"
                        :isFullWidth="true"
                        :class="[{ 'form-date-time-input-error' : errors.has('event_end_date') }]"
                    />
                    <form-datepicker
                        v-else 
                        v-model="dtend"
                        v-validate="'eventEndDate'"
                        name="event_end_date"
                        placeholder="End Date"
                        size="small"
                        :isFullWidth="true"
                        keyDatePicker="event_end_date_picker"
                        :class="[{ 'form-datepicker-error' : errors.has('event_end_date') }]"
                    />
                </div>
            </div>
            <!-- <div class="row-start row-baseline mb-2">
                <div class="lg-4 pl-25">
                    <span class="f-12-black f-sbold">Repeat</span>
                </div>
                <div class="lg-8">
                    <multiselect
                        class="multiselect-small"
                        :showLabels="false"
                        :max-height="200"
                        :options="repeatOptions"
                        placeholder="Repeat"
                        v-model="repeat"
                        name="repeat"
                        data-name="repeat"
                        :searchable="false"
                    />
                </div>
            </div>
            <div class="row-start row-baseline mb-8">
                <div class="lg-4 pl-25">
                    <span class="f-12-black f-sbold">Every</span>
                </div>
                <div class="lg-3">
                    <form-input>
                        <input
                            class="form-input-field"
                            type="number"
                            v-model="repeatEveryCount"
                        >
                        <button
                            class="decrease-button transparent-button"
                            @click="repeatEveryCount--"
                        >
                            <arrow-icon class="i-grey"/>
                        </button>
                        <button
                            class="increase-button transparent-button"
                            @click="repeatEveryCount++"
                        >
                            <arrow-icon
                                class="i-grey"
                                transform="270"
                            />
                        </button>
                    </form-input>
                </div>
                <div class="lg-5 pl-3">
                    <multiselect
                        class="multiselect-small"
                        :showLabels="false"
                        :max-height="200"
                        :options="repeatEveryOptions"
                        placeholder="Repeat"
                        v-model="repeatEvery"
                        name="repeat"
                        data-name="repeat"
                        :searchable="false"
                    />
                </div>
            </div>
            <div class="row-between row-baseline pl-25 mb-8">
                <label
                    v-for="(day, index) in repeatDays"
                    :key="index"
                    :class="['day-label s-align-5 pointer', { 'active': day === repeatEveryDay }]"
                >
                    <span class="f-12-blue f-sbold">
                        {{ day.charAt(0) }}
                    </span>
                    <input
                        name="repeat_every_day"
                        type="radio"
                        :value="day"
                        v-model="repeatEveryDay"
                    >
                </label>
            </div>
            <div
                v-if="repeatEveryDay"
                class="row-center row-baseline mb-8"
            >   
                <span class="f-12-black f-sbold mr-3">
                    {{ `Occurs every ${repeatEveryDay} until` }}
                </span>
                <calendar-icon
                    class="i-blue mr-1"
                    size="14"
                />
                <v-date-picker
                    v-model="repeatUntil"
                    show-caps
                    popover-direction="top"
                    :tintColor="tintColor"
                    :attributes="attributes"
                    :disabledAttribute="disabledAttribute"
                    :themeStyles="themeStyles"
                >
                    <span class="f-12-blue f-sbold pointer">
                        {{ repeatUntil | date }}
                    </span>
                </v-date-picker>
            </div> -->
            <div class="row-start mt-8">
                <div class="lg-4">
                    <person-icon
                        size="15"
                        class="i-grey mr-3"
                    />
                    <span class="f-12-black f-sbold">Participants</span>
                </div>
                <div class="lg-8 column-start">
                    <div
                        v-for="(customer, index) in attendees"
                        :key="index"
                        class="row-start row-baseline mb-1"
                    >
                        <div class="lg-2">
                            <avatar
                                :name="getName(customer)"
                                :border="false"
                                size="23"
                                :url="customer.thumbnail"
                            />
                        </div>
                        <div class="lg-9 column-start">
                            <span
                                v-if="customer.first_name || customer.last_name"
                                class="f-12-black hidden ellipsis"
                            >
                                {{ `${customer.first_name} ${customer.last_name}` }}
                            </span>
                            <span class="f-10-grey hidden ellipsis">{{ customer.email }}</span>
                        </div>
                        <div class="lg-1 s-align-5">
                            <button
                                class="transparent-input p-1"
                                @click="removeParticipanrs(index)"
                            >
                                <close-icon
                                    size="7"
                                    class="i-grey"
                                />
                            </button>
                        </div>
                    </div>
                    <div class="add-new-customer row-between row-baseline relative">
                        <button
                            class="transparent-button add-new-customer-btn"
                            @click="addCustomer(newCustomer)"
                        >
                            <add-icon
                                size="7"
                                class="i-blue"
                            />
                        </button>
                        <input
                            :class="[
                                'add-new-customer-input f-12-grey',
                                { 'transparent-input-danger' : errors.has('new-customer-input') }
                            ]"
                            name="new-customer-input"
                            type="text"
                            placeholder="Placeholder to invite"
                            v-model="newCustomer"
                            v-validate="'email'"
                            @focus="getUsers"
                            @keydown.enter="addCustomer(newCustomer)"
                            ref="customerInput"
                        >
                        <div
                            v-if="matchingCustomers.length"
                            class="matching-customers"
                        >
                            <perfect-scrollbar class="ps-grey">
                                <button
                                    v-for="(customer, index) in matchingCustomers"
                                    :key="index"
                                    class="matching-customer transparent-button"
                                    @click="addCustomer(customer)"
                                >
                                    <user-box
                                        :firstName="customer.first_name"
                                        :lastName="customer.last_name ? customer.last_name : ''"
                                        :avatarUrl="customer.thumbnail"
                                        :info="customer.email"
                                        :border="false"
                                    />
                                </button>
                            </perfect-scrollbar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="activeTab === TABS.WORK_DESCRIPTION"
            class="p-20"
        >
            <div class="row-start row-baseline mb-3">
                <attach-icon class="i-grey mr-2"/>
                <span class="f-12-black f-sbold">Attachment</span>
            </div>
            <div
                v-for="(file, index) in attachments"
                :key="index"
                class="row-start row-baseline pv-1"
            >
                <div class="lg-10 s-align-4">
                    <document-icon
                        width="14"
                        height="19"
                        class="flex-shrink-0"
                    />
                    <div class="event-attachment-title column-start hidden ml-6">
                        <span class="f-13-black ellipsis no-wrap">
                            {{ file.files ? file.files[0].original_name : file.name }}
                        </span>
                    </div>
                </div>
                <div class="lg-2 s-align-6">
                    <button
                        class="transparent-button ml-6"
                        @click="deleteAttachment(file,index)"
                    >
                        <close-icon
                            class="i-grey"
                            size="7"
                        />
                    </button>
                </div>
            </div>
            <button 
                class="transparent-button mt-3 mb-8"
                @click="startUploadAttachment()"
            >
                <plus-icon class="mr-2 i-blue"/>
                <span class="f-13-blue">Add attachment</span>
            </button>
            <input 
                type="file" 
                name="file_upload"
                ref="uploadEventAttachment"
                @change="fileInputHandler"
                hidden
            />
            <template v-if="calendarType === CALENDAR_TYPES.USER">
                <p class="f-12-black f-sbold mb-1">Notes</p>
                <text-editor 
                    v-model="description"
                    class="mb-4"
                    placeholder="Enter notes..."
                    toolbarName="notesToolbar"
                    theme="bubble"
                    maxLength="2500"
                />
            </template>
            <template v-if="calendarType === CALENDAR_TYPES.TECHNICIAN">
                <p class="f-12-black f-sbold mb-1">Scope of work</p>
                <text-editor 
                    v-model="scopeOfWork"
                    class="mb-25"
                    placeholder="Enter notes..."
                    toolbarName="notesToolbar"
                    theme="bubble"
                    maxLength="2500"
                />
            </template>
            <template v-if="calendarType === CALENDAR_TYPES.TECHNICIAN">
                <p class="f-12-black f-sbold mb-1">Materials</p>
                <text-editor 
                    v-model="materials"
                    class="mb-4"
                    placeholder="Enter notes..."
                    toolbarName="notesToolbar"
                    theme="bubble"
                    maxLength="2500"
                />
            </template>
        </div>
        <div    
            v-if="event"
            class="row-between ph-20 pv-8 bt-grey"
        >
            <tooltip 
                direction="right"
                color="white"
                :fixedDirection="true"
                :isShow="isDeleteTooltipShow"
            >
                <button 
                    class="transparent-button s-align-4"
                    @click="isDeleteTooltipShow = !isDeleteTooltipShow"
                >
                    <trash-icon class="i-red mr-1"/>
                    <span class="f-12-red f-sbold">Delete</span>
                </button>
                <template v-slot:content>
                    <p class="f-14-black f-bold t-center mb-6">
                        Are you sure to want to delete this event?
                    </p>
                    <div class="row-between">
                        <button 
                            class="add-button"
                            @click="isDeleteTooltipShow = false"
                        >
                            No
                        </button>
                        <button 
                            class="red-button"
                            @click="$emit('deleteEvent')"
                            :disabled="requestIsGoing"
                        >
                            Yes
                        </button>
                    </div>
                </template>
            </tooltip>
            <div class="s-align-6">
                <button 
                    class="transparent-button f-12-grey f-sbold"
                    @click="$emit('cancelEdit')"
                >
                    Cancel
                </button>
                <button 
                    :class="['transparent-button f-sbold ml-8', requestIsGoing ? 'f-12-grey' : 'f-12-blue']"
                    @click="updateEvent"
                    :disabled="requestIsGoing"
                >
                    Save
                </button>
            </div>
        </div>
        <!-- footer for new event -->
        <div 
            v-else
            class="event-popup-footer p-8 bt-grey"
        >
            <div class="row-end">
                <button 
                    class="icon-light"
                    @click="$emit('closeNewEventPopup')"
                >
                    <span class="f-13-grey">
                        Cancel
                    </span>
                </button>
                <button 
                    class="icon-light ml-8"
                    @click="createEvent()"
                    :disabled="requestIsGoing"
                >
                    <span class="f-13-blue">
                        Save
                    </span>
                </button>
            </div>
        </div> 
    </div>
</template>

<script>
    import { 
        BriefcaseIcon, MapIcon, PickListIcon, CalendarIcon, ArrowIcon, PersonIcon, CloseIcon, AttachIcon, 
        DocumentIcon, PlusIcon, CrmLogo, OutlookLogo, PhoneIcon, EnvelopeIcon, AddIcon
    } from '@/icons';
    import { FormInput, FormDateTimeInput, CheckboxButton, Loader, FormInputTextarea, FormDatepicker } from '@/ui';
    import UserBox from '@/components/User/UserBox.vue';
    import { BASE_ENTITY_TYPES, ENTITY_TYPES, CALENDAR_TYPES, CALENDAR_PROVIDERS } from '@/constants.js';
    import { getContentWithoutBottomAndTopBrTag } from '@/helpers';
    import calendarConfig from '@/mixins/calendarConfig';
    import { SearchIcon, TrashIcon } from '@/icons';
    import { Autocomplete } from '@/GoogleApi';
    import Multiselect from 'vue-multiselect';
    import { clearArray } from '@/helpers';
    import { Avatar } from '@/components';
    import TextEditor from '@/components/TextEditor.vue';
    import moment from 'moment/src/moment';
    import api from '@/headers.js';
    import IMAPapi from '@/IMAPheaders';
    import Tooltip from '@/ui/Tooltip';
    import { mask } from 'vue-the-mask';
    import debounce from 'lodash/debounce';

    const SINGLE_INSTANCE = 'singleInstance';
    
    export default {
        name: 'EventEdit',
        components: {
            BriefcaseIcon,
            MapIcon,
            PickListIcon,
            CalendarIcon,
            ArrowIcon,
            PersonIcon,
            CloseIcon,
            AttachIcon, 
            DocumentIcon,
            PlusIcon,
            FormInput,
            FormDateTimeInput,
            CheckboxButton,
            Tooltip,
            Loader,
            FormInputTextarea,
            UserBox,
            SearchIcon,
            TrashIcon,
            Autocomplete,
            Multiselect,
            Avatar,
            TextEditor,
            CrmLogo,
            OutlookLogo,
            PhoneIcon,
            EnvelopeIcon,
            AddIcon,
            FormDatepicker
        },
        props: {
            // props for existing event
            event: {
                type: Object,
                required: false
            },
            // props for new event
            newEventStart: {
                type: String,
                required: false
            },
            newEventEnd: {
                type: String,
                required: false
            },
            calendarList: {
                type: Array,
                required: false
            },
            calendarId: {
                type: String,
                required: false
            },
            calendarType: {
                type: String,
                required: true
            },
            userId: {
                type: [String, Number],
                required: false
            },
            reAssignMode: {
                type: Boolean,
                required: false
            },
            eventAssignTechnick: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                BASE_ENTITY_TYPES: BASE_ENTITY_TYPES,
                summary: '',
                description: '',
                scopeOfWork: '',
                materials: '',
                isJobFieldShow: false,
                searchResultsShown: false,
                searchResultsLoading: false,
                searchResults: [],
                job: '',
                entityId: null,
                entityType: '',
                location: {
                    address: '',
                    city: '',
                    lat: '',
                    lng: ''
                },
                selectedCalendar: '',
                isEventAllDay: false,
                dtstart: '',
                dtend: '',
                isDeleteTooltipShow: false,
                requestIsGoing: false,
                TABS: {
                    INFORMATION: 'Information',
                    WORK_DESCRIPTION: 'Work description'
                },
                activeTab: '',
                CALENDAR_TYPES: CALENDAR_TYPES,
                // test data
                repeatOptions: ['None', 'Custom'],
                repeat: 'None',
                repeatEveryOptions: ['Week', 'Day'],
                repeatEvery: 'Week',
                repeatEveryCount: 1,
                repeatDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                repeatEveryDay: '',
                repeatUntil: new Date(),
                customers: [
                    { first_name: 'John', last_name: 'Doe', email: 'johndoe@gmail.com' },
                    { first_name: 'Michael', last_name: 'Jackson', email: 'michaeljackson@gmail.com' }
                ],
                attachments: [],
                sendAttachments: [],
                attachmentsForDelete: [],
                CRM: CALENDAR_PROVIDERS.CRM,
                first_name: '',
                phone: '',
                email: '',
                attendees: [],
                newCustomer: '',
                users: [],
                usersLoaded: false,
                calendarListData: [],
                eventAssignedTo: null,
                assignedCalendarId: null,
                allProvidersCalendars: [],
                isOutlookSynced: true,
                needHardRefresh: false
            }
        },
        mixins: [ calendarConfig ],
        directives: { mask },
        watch: {
            dtend: function() {
                // validate Start date when changed End date
                if (this.isEdit === undefined || this.isEdit) {
                    this.$validator.validate('event_start_date');
                }

                if (moment(this.dtend).isSameOrBefore(moment(this.dtstart)) && !this.isEventAllDay) {
                    this.notifyError('End date has to be after the Start date');
                }
            },
            // validate End date when changed Start date
            dtstart: function() {
                if (this.isEdit === undefined || this.isEdit) {
                    this.$validator.validate('event_end_date');
                }
            },
            isEventAllDay(val,oldVal) {
                if (val != oldVal && !val && !!this.dtstart && !!this.dtend) {
                    this.$validator.pause();
                    this.dtstart = moment(this.dtstart).set({ hour:'07', minutes: '00' })
                            .subtract(this.utcOffset, 'm')
                            .format('YYYY-MM-DD HH:mm:ss');
                    this.dtend = moment(this.dtend).set({ hour:'09', minutes: '00' })
                            .subtract(this.utcOffset, 'm')
                            .format('YYYY-MM-DD HH:mm:ss');
                    this.$nextTick(() => {
                        this.$validator.resume();
                    })
                }
            },
            userId() {
                if (this.userId) {
                    // load calendar list for users calendar
                    this.getCalendarList();

                    this.dtstart = this.newEventStart;
                    this.dtend = this.newEventEnd;
                }
            },
            selectedCalendar(val) {
                this.isOutlookSynced = true;
                if (val && this.selectedCalendar.provider === CALENDAR_PROVIDERS.OUTLOOK) {
                    this.checkOutlookSync(this.selectedCalendar);
                }
            }
        },
        computed: {
            loggedInUserId() {
                return this.$store.getters['user/getUserId'];
            },
            allFieldsEmpty() {
                return this.summary === '' && this.location.address === '' && this.job === '';
            },
            // on edit event change entity type doesn't available
            filteredSearchResults() {
                return this.searchResults.filter(i => {
                    if (this.event) {
                        switch (this.entityType) {
                            case ENTITY_TYPES.RESIDENTIAL_LEAD:
                                return i.type === BASE_ENTITY_TYPES.RESIDENTIAL;
                                break;
                            case ENTITY_TYPES.COMMERCIAL_JOB:
                                return i.type === BASE_ENTITY_TYPES.COMMERCIAL_JOB;
                                break;
                            case ENTITY_TYPES.COMMERCIAL_LEAD:
                                return i.type === BASE_ENTITY_TYPES.COMMERCIAL_LEAD;
                                break;
                        }
                        
                    }
                    return true;
                });
            },
            matchingCustomers() {
                if (!this.newCustomer.trim().length) { return [] }

                // customer can be added once and on the 'user & appointment calendar' user cannot add himself
                return this.users.filter(user => {
                    return !this.attendees.some(u => u.email === user.email)
                        && (this.calendarType === CALENDAR_TYPES.USER
                            && user.id !== this.loggedInUserId
                            || this.calendarType === CALENDAR_TYPES.TECHNICIAN)
                        && (user.fullname.toLowerCase().includes(this.newCustomer.toLowerCase())
                        || (user.email && user.email.toLowerCase().includes(this.newCustomer.toLowerCase())));
                });
            },
            utcOffset() {
                return moment().utcOffset();
            },
            calendarsListSelect() {
                if (this.reAssignMode) {
                    return this.allProvidersCalendars;
                } else {
                    return this.calendarListData;
                }
            },
            isMyCalendar() {
                return this.$route.name === 'CalendarWeek' 
                    || this.$route.name === 'CalendarDay' 
                    || this.$route.name === 'CalendarMonth' 
                    || this.$route.name === 'CalendarAgenda';
            },
            userCalendars() {
                return this.$store.getters['calendars/getUserCalendars'];
            }
        },
        methods: {
            getName(customer) {
                return customer.first_name && customer.last_name
                    ? customer.first_name + ' ' + customer.last_name
                    : customer.email;
            },
            startUploadAttachment() {
                this.$refs.uploadEventAttachment.click();
            },
            deleteAttachment(file,index) {
                this.attachments.splice(index, 1);

                // if event allready have attachments
                if (file.id) {
                    this.attachmentsForDelete.push(file.id);
                }

                // delete new uploading attachment
                if (!file.original_name) {
                    let uploadIndex = this.sendAttachments.findIndex(el => el.name == file.name);
                    this.sendAttachments.splice(uploadIndex, 1);
                }
            },
            fileInputHandler(event) {
                if (!this.$refs.uploadEventAttachment.files[0]) return false;
                let file = event.target.files[0];
                this.attachments.push(file);
                this.sendAttachments.push(file);
            },
            searchJobs() {
                if (this.job.length < 3) {
                    this.searchResultsShown = false;
                    return;
                }

                this.searchResultsShown = true;
                this.searchResultsLoading = true;

                api.get('/search', { params: { q: this.job } })
                    .then(response => {
                        clearArray(this.searchResults);
                        this.searchResults.push(...response.data.data);            
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.searchResultsLoading = false;
                    });
            },
            setJob(job) {
                this.entityId = job.id;
                this.onLocationManualInput(job.address);
                this.location.city = job.city;
                this.searchResultsShown = false;

                this.phone = job.phone;
                this.email = job.email;

                switch (job.type) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL:
                        this.job = job.customer;
                        this.entityType = ENTITY_TYPES.RESIDENTIAL_LEAD;
                        this.first_name = `${job.first_name} ${job.last_name}`;
                        break;
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                        this.job = job.property;
                        this.entityType = ENTITY_TYPES.COMMERCIAL_LEAD;
                        this.first_name = `${job.first_name} ${job.last_name} ${job.title ? ', ' + job.title : ''}`;
                        break;
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        this.job = `${job.property}, ${job.job_number}`;
                        this.entityType = ENTITY_TYPES.COMMERCIAL_JOB;
                        this.first_name = `${job.first_name} ${job.last_name} ${job.title ? ', ' + job.title : ''}`;
                        break;
                }
            },
            setAddress(data, coords, id) {   
                let address = '';       
                data.street_number ? address += `${data.street_number} ` : '';
                data.route ? address += `${data.route}, ` : '';
                data.locality ? address += `${data.locality}, ` : '';
                data.administrative_area_level_1 ? address += `${data.administrative_area_level_1}, ` : '';
                data.postal_code  ? address += `${data.postal_code}, ` : '';
                address += 'USA';

                this.location.city = data.locality;
                this.location.address = address;
                this.location.lat = coords.lat;
                this.location.lng = coords.lng;
            },
            onLocationManualInput(value) {
                this.location.address = value;
                this.location.city = '';
                this.location.lat = null;
                this.location.lng = null;
            },
            getUsers() {
                if (this.usersLoaded) { return }

                if (this.calendarType === CALENDAR_TYPES.USER) {
                    api.get(`calendars/owners`)
                        .then(response => response.data.data)
                        .then(data => {
                            this.users = data;
                            this.usersLoaded = true;
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                }

                if (this.calendarType === CALENDAR_TYPES.TECHNICIAN) {
                    const params = {
                        perPage: 9999,
                        is_active: 1,
                    };
                    api.get('/technicians', { params })
                        .then(response => response.data.data)
                        .then(data => {
                            this.users = data.data;
                            this.usersLoaded = true;
                        })
                        .catch(error => {
                            this.notifyRequestErrors(error);
                        });
                }
            },
            addCustomer(data) {
                // add new customer not from CRM
                if (!data.id) {
                    this.$validator.validate('new-customer-input')
                        .then(result => {
                            if (result
                                && this.newCustomer.trim().length
                                && !this.attendees.some(u => u.email === this.newCustomer)
                            ) {
                                this.attendees.push({
                                    email: data
                                });
                                this.newCustomer = '';
                                this.setRefreshAfterEdit();
                            } else if (!this.newCustomer.trim().length) {
                                this.$refs.customerInput.focus();
                            } else {
                                this.notifyError('Please enter valid email address');
                            }
                        })
                } else {
                    // customer added from existing CRM user
                    this.attendees.push({
                        email: data.email,
                        id: data.id,
                        thumbnail: data.thumbnail,
                        first_name: data.first_name,
                        last_name: data.last_name,
                        type: data.type
                    });
                    this.newCustomer = '';
                    this.setRefreshAfterEdit();
                }
            },
            removeParticipanrs(index) {
                this.attendees.splice(index, 1);
                this.setRefreshAfterEdit();
            },
            setRefreshAfterEdit() {
                if (this.reAssignMode) {
                    this.needHardRefresh = true;
                }
            },
            createEvent() {
                if (this.allFieldsEmpty) {
                    this.notifyError('Please fill at least one field!');
                    return;
                }

                if (this.newCustomer.trim().length) {
                    this.notifyError('Please press enter in participants field to add  email address');
                    return;
                }

                this.$validator.validateAll().then(result => {
                    if (result && this.isOutlookSynced) {
                        this.requestIsGoing = true;
                        let params = new FormData();

                        // required fields
                        if (this.summary) {
                            params.append('summary', this.summary);
                        } else if (!this.summary && !this.isJobFieldShow) {
                            params.append('summary', this.location.city);
                        }

                        params.append('dtstart', this.isEventAllDay ? moment(this.dtstart).format('YYYY-MM-DD') : this.dtstart);
                        params.append('dtend', this.isEventAllDay ? moment(this.dtend).clone().add(1, 'd').format('YYYY-MM-DD') : this.dtend);
                        params.append('owner_type', this.calendarType);
                        params.append('owner_id', this.userId ? this.userId : this.loggedInUserId);
                        switch (this.calendarType) {
                            case CALENDAR_TYPES.TECHNICIAN: {
                                params.id = this.selectedCalendar.calendar_id;
                                params.append('work_description[materials]', this.materials);
                                params.append('work_description[scope_of_work]', this.scopeOfWork);
                            } break;
                            default: {
                                if (this.userId) {
                                    params.id = this.selectedCalendar.calendar_id;
                                } else {
                                    params.id =  this.selectedCalendar.id ? this.selectedCalendar.id : 'primary';
                                }
                                params.append(
                                    'description',
                                    getContentWithoutBottomAndTopBrTag(this.description)
                                );
                            } break;
                        }

                        params.append('type', SINGLE_INSTANCE);

                        // add lead to event
                        if (this.isJobFieldShow && this.entityId !== null) {
                            params.append('entity_id', this.entityId);
                            params.append('entityType', this.entityType);
                        }

                        // add address to event
                        if (this.location.address !== '') {
                            params.append('location', this.location.address);
                            params.append('lat', this.location.lat);
                            params.append('lng', this.location.lng);
                        }

                        // is event all day
                        if (this.isEventAllDay) {
                            params.append('all_day', 1);
                        }

                        if (this.sendAttachments.length) {
                            this.sendAttachments.forEach(el => {
                                params.append('attachments[]', el);
                            })              
                        }

                        if (this.first_name) {
                            params.append('responsible_person[first_name]', this.first_name);
                        }

                        if (this.phone) {
                            params.append('responsible_person[phone]', this.phone.replace(/\D+/g, ''));
                        }

                        if (this.email) {
                            params.append('responsible_person[email]', this.email);
                        }

                        if (this.attendees.length) {
                            this.attendees.forEach((customer, index) => {
                                params.append(`attendees[${index}][attendee_id]`, customer.id ? customer.id : null)
                                params.append(`attendees[${index}][attendee_type]`, customer.type ? customer.type : this.calendarType)
                                params.append(`attendees[${index}][email]`, customer.email)
                                params.append(
                                    `attendees[${index}][cn]`,
                                    customer.first_name 
                                        ? `${customer.first_name} ${customer.last_name}`
                                        : customer.email
                                    )
                            });
                        }

                        api.post(`calendars/${params.id}/events`, params) 
                            .then(response => response.data.data)
                            .then(data => {
                                this.notifySuccess('Event created!');
                                data.is_organizer = true;
                                let emitedParams = {
                                    data: data,
                                    attendees: this.attendees
                                };
                                let main_assignee_fullname;

                                if (this.calendarType === CALENDAR_TYPES.USER && !this.isMyCalendar) {
                                    main_assignee_fullname = this.calendarListData[0].summary;
                                    emitedParams.main_assignee_fullname = main_assignee_fullname;
                                }

                                if (this.calendarType === CALENDAR_TYPES.TECHNICIAN) {
                                    main_assignee_fullname = this.eventAssignTechnick;
                                    emitedParams.main_assignee_fullname = main_assignee_fullname;
                                }

                                this.$emit('closeNewEventPopup', emitedParams);
                            })
                            .catch(error => this.notifyRequestErrors(error))
                            .finally(() => this.requestIsGoing = false);
                    } else if (this.$validator.errors.has('event_end_date')) {
                        this.notifyError('End date has to be after the Start date');
                    } else if (!this.isOutlookSynced) {
                        this.notifyError('This Outlook calendar is not synced!');
                    }
                });
            },
            updateEvent() {
                if (this.allFieldsEmpty) {
                    this.notifyError('Please fill at least one field!');
                    return;
                }

                if (this.newCustomer.trim().length) {
                    this.notifyError('Please press enter in participants field to add  email address');
                    return;
                }
                
                this.$validator.validateAll().then(result => {
                    if (result && this.isOutlookSynced) {
                        let calendarId;
                        let params = new FormData();

                        switch (this.calendarType) {
                            case CALENDAR_TYPES.TECHNICIAN: {
                                calendarId = this.event.calendar_id;
                                if (this.assignedCalendarId) {
                                    params.append('calendar_id', this.assignedCalendarId);
                                }
                                params.append('work_description[materials]', this.materials);
                                params.append('work_description[scope_of_work]', this.scopeOfWork);
                            } break;
                            default: {
                                calendarId = this.selectedCalendar ? this.selectedCalendar.id : 'primary';
                                if (this.reAssignMode) {
                                    calendarId = this.selectedCalendar ? this.selectedCalendar.calendar_id : 'primary';
                                    params.append('calendar_id', this.selectedCalendar.calendar_id);
                                }
                                let description = getContentWithoutBottomAndTopBrTag(this.description);
                                params.append('description', description);
                            } break;
                        }
                        const eventId = this.event.id;
                        

                        // required fields
                        params.append('dtstart', this.isEventAllDay ? moment(this.dtstart).format('YYYY-MM-DD') : this.dtstart);
                        params.append('dtend', this.isEventAllDay ? moment(this.dtend).clone().add(1, 'd').format('YYYY-MM-DD') : this.dtend);
                        // params.dtend = this.dtend;
                        if (this.summary) {
                            params.append('summary', this.summary);
                        } else if (!this.summary && !this.isJobFieldShow) {
                            params.append('summary', this.location.city);
                        }

                        if (this.isJobFieldShow && this.entityId !== null) {
                            params.append('entity_id', this.entityId);
                            params.append('entityType', this.entityType);
                        }              

                        // add address to event
                        if (this.location.address !== '') {
                            params.append('location', this.location.address);
                            params.append('lat', this.location.lat);
                            params.append('lng', this.location.lng);
                        };

                        params.append('all_day', this.isEventAllDay ? 1 : 0);

                        if (this.sendAttachments.length) {
                            this.sendAttachments.forEach(el => {
                                params.append('attachments[]', el);
                            });
                        }

                        if (this.attachmentsForDelete.length) {
                            this.attachmentsForDelete.forEach(el => {
                                params.append('attachments_need_delete[]', el);
                            });
                        }

                        params.append('_method','PUT');

                        if (this.first_name) {
                            params.append('responsible_person[first_name]', this.first_name);
                        }

                        if (this.phone) {
                            params.append('responsible_person[phone]', this.phone.replace(/\D+/g, ''));
                        }

                        if (this.email) {
                            params.append('responsible_person[email]', this.email);
                        }

                        if (this.attendees.length) {
                            this.attendees.forEach((customer, index) => {
                                params.append(`attendees[${index}][attendee_id]`, customer.id ? customer.id : null)
                                params.append(`attendees[${index}][attendee_type]`, customer.type ? customer.type : this.calendarType)
                                params.append(`attendees[${index}][email]`, customer.email)
                                params.append(
                                    `attendees[${index}][cn]`,
                                    customer.first_name 
                                        ? `${customer.first_name} ${customer.last_name}`
                                        : customer.email
                                    )
                            });
                        }

                        this.requestIsGoing = true;
                        api.post(`/calendars/${calendarId}/events/${eventId}`, params)
                            .then(response => response.data.data)
                            .then(data => {
                                this.$emit('onUpdate', data, this.needHardRefresh);
                                this.notifySuccess('Event updated!');
                            })
                            .catch(error => this.notifyRequestErrors(error))
                            .finally(() => this.requestIsGoing = false);
                    } else if (this.$validator.errors.has('event_end_date')) {
                        this.notifyError('End date has to be after the Start date');
                    } else if (!this.isOutlookSynced) {
                        this.notifyError('This Outlook calendar is not synced!');
                    }
                });
            },
            getCalendarList() {
                const params = {
                    owner_id: this.userId,
                    owner_type: this.calendarType
                }
                
                let key = `${params.owner_type}_${params.owner_id}`;
                let calendars = this.userCalendars[key];

                if (!calendars) {
                    this.$store.dispatch('calendars/GET_USER_CALEDARS', params)
                        .then(data => {
                            this.calendarListData.splice(0, this.calendarListData.length, ...data);
                            this.selectedCalendar = this.calendarListData[0];
                        })
                        .catch(error => {
                            this.notifyRequestErrors(error);
                        });
                } else {
                    this.calendarListData.splice(0, this.calendarListData.length, ...calendars);
                    this.selectedCalendar = this.calendarListData[0];
                }
            },
            getCalendarIdByOwner(data, reassignEvent = false) {
                let params;
                this.requestIsGoing = true;

                if (this.calendarType === CALENDAR_TYPES.TECHNICIAN) {
                    params = {
                        owner_id: data.id,
                        owner_type: CALENDAR_TYPES.TECHNICIAN
                    }
                } else {
                    params = {
                        owner_id: data.id,
                        owner_type: CALENDAR_TYPES.USER
                    }
                }

                let key = `${params.owner_type}_${params.owner_id}`;
                let calendars = this.userCalendars[key];
                
                if (calendars && calendars.length) {
                    this.allProvidersCalendars = calendars;
                    this.setReassignSelectedCalendar(reassignEvent);
                    this.requestIsGoing = false;
                }

                if (!calendars) {
                    this.$store.dispatch('calendars/GET_USER_CALEDARS', params)
                        .then(data => {
                            this.allProvidersCalendars = data;
                            this.setReassignSelectedCalendar(reassignEvent);
                        })
                        .catch(error => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => this.requestIsGoing = false);
                }
            },
            setReassignSelectedCalendar(reassignEvent) {
                if (reassignEvent && this.allProvidersCalendars.length) {
                    this.$nextTick(() => {
                        this.selectedCalendar = this.allProvidersCalendars.find(el => {
                            return el.calendar_id === this.event.calendar_id;
                        }) ? this.allProvidersCalendars.find(el => {
                            return el.calendar_id === this.event.calendar_id;
                        }) : this.allProvidersCalendars[0];
                        this.assignedCalendarId = this.selectedCalendar.calendar_id;
                    });
                } else {
                    this.selectedCalendar = this.allProvidersCalendars.find(el => {
                        return el.calendar_id === this.event.calendar_id
                    });
                    this.assignedCalendarId = this.selectedCalendar.calendar_id;
                }
            },
            async checkOutlookSync(calendar) {
                this.isOutlookSynced = true;

                if (calendar.provider === CALENDAR_PROVIDERS.OUTLOOK) {
                    let imapConfig = [];
                    let params = {
                        user_id: this.selectedCalendar.owner_id 
                            ? this.selectedCalendar.owner_id 
                            : this.selectedCalendar.user.id,
                        user_type: this.selectedCalendar.owner_type
                    };

                    await IMAPapi.get(`accounts`, { params })
                        .then(response => {
                            if (response.data.data.length) {
                                imapConfig = response.data.data;
                            } else {
                                this.isOutlookSynced = false;
                                this.notifyError('This Outlook calendar is not synced!');
                                return;
                            }
                        })
                        .catch(error => {
                            this.notifyError(error);
                        })

                    if (imapConfig.length) {
                        await IMAPapi.get(`/calendars/get-sync?account_id=${imapConfig[0]._id}`)
                            .then((response) => {
                                if (!response.data.data.length) {
                                    this.notifyError('This Outlook calendar is not synced!');
                                    this.isOutlookSynced = false;
                                }
                            })
                            .catch(error => {
                                this.notifyError(error);
                            })
                    }
                }
            }
        },
        mounted() {
            if (this.event && this.calendarType === CALENDAR_TYPES.USER) {
                this.selectedCalendar = this.calendarList.find(el => el.id == this.event.calendar_id);
                if (!this.isMyCalendar) {
                    this.eventAssignedTo = this.calendarList.find(el => el.id == this.event.user.id);
                }
            }

            if (this.event && this.calendarType === CALENDAR_TYPES.TECHNICIAN) {
                this.assignedCalendarId = this.event.calendar_id;
                this.eventAssignedTo = this.calendarList.find(c => {
                    return c.events.some(el => el.calendar_id == this.event.calendar_id);
                });
            }
        },
        created() {
            let self = this;

            this.activeTab = this.TABS.INFORMATION;

            // create validation rule for Start date
            this.$validator.extend('eventStartDate', {
                getMessage(field, val) {
                    return 'must be earlier than End date'
                },
                validate(value, field) {
                    if (!self.isEventAllDay) {
                        return !moment(self.dtend).isSameOrBefore(moment(self.dtstart));
                    } else {
                        let endDate = moment(self.dtend).clone().add(1, 'd').format('YYYY-MM-DD');
                        let startDate = moment(self.dtstart).format('YYYY-MM-DD');

                        return !moment(endDate).isSameOrBefore(moment(startDate));
                    }
                }
            });

            // create validation rule for End date
            this.$validator.extend('eventEndDate', {
                getMessage(field, val) {
                    return 'must be later than Start date'
                },
                validate(value, field) {
                    if (!self.isEventAllDay) {
                        return !moment(self.dtstart).isSameOrAfter(moment(self.dtend));
                    } else {
                        let endDate = moment(self.dtend).clone().add(1, 'd').format('YYYY-MM-DD');
                        let startDate = moment(self.dtstart).format('YYYY-MM-DD');

                        return !moment(startDate).isSameOrAfter(moment(endDate));
                    }
                }
            });

            this.$nextTick(function() {
                this.$refs.sumamry.focus();
            });

            if (this.event) {
                // init values for exisiting event
                this.summary = this.event.summary;
                this.dtstart = this.event.dtstart;
                if (!+this.event.all_day) {
                    this.dtend = this.event.dtend;
                } else {
                    this.dtend = moment(this.event.dtend).subtract(1,'days').format('YYYY-MM-DD');
                }
                this.attachments = this.event.attachments;
                this.attendees.push(...this.event.attendees);

                switch (this.calendarType) {
                    case CALENDAR_TYPES.TECHNICIAN: {
                        this.scopeOfWork = this.event.work_description
                            ? this.event.work_description.scope_of_work : '';
                        this.materials = this.event.work_description
                            ? this.event.work_description.materials : '';
                    } break;
                    default: {
                        this.description = this.event.description || '';
                    } break;
                }
                
                if (this.event.entity !== null) {
                    this.isJobFieldShow = true;
                    
                    switch (this.event.entity.type) {
                        case ENTITY_TYPES.RESIDENTIAL_LEAD:
                            this.job = this.event.entity.customer;
                            this.entityId = this.event.entity.id;
                            this.entityType = this.event.entity.type;
                            break;
                        case ENTITY_TYPES.COMMERCIAL_LEAD:
                            this.job = this.event.entity.property;
                            this.entityId = this.event.entity.id;
                            this.entityType = this.event.entity.type;
                        case ENTITY_TYPES.COMMERCIAL_JOB:
                            this.job = `${this.event.entity.property}, ${this.event.entity.job_number}`;
                            this.entityId = this.event.entity.id;
                            this.entityType = this.event.entity.type;
                    }
                }

                if (this.event.responsible !== null) {
                    this.first_name = this.event.responsible.first_name;
                    this.email = this.event.responsible.email;
                    this.phone = this.event.responsible.phone;
                }

                if (this.event.location !== null) {
                    this.location.address = this.event.location;
                    this.location.lat = this.event.lat;
                    this.location.lng = this.event.lng;
                }

                this.isEventAllDay = +this.event.all_day;

                if (this.reAssignMode) {
                    this.getCalendarIdByOwner(this.event.user, true);
                }
            } else {
                // init values for new event
                this.dtstart = this.newEventStart;
                this.dtend = this.newEventEnd;

                if (this.userId) {
                    // load calendar list for users calendar
                    this.getCalendarList();
                }
            }
        },
        deactivated() {
            // This hook was called for nested components in keep-alive
            this.needHardRefresh = false;
        }
    }
</script>
