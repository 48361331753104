<template>
    <svg 
        id="master-artboard" 
        viewBox="0 0 448.642333984375 432.06622314453125"
        :width="size"
        :height="size"
        class="cubes-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="448.642333984375" 
            height="432.06622314453125" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(7.839999675750732, 0, 0, 7.839999675750732, 3195.6810607910156, -1884.9403991699219)">
            <path d="M-404,241h19c1.7,0,3,1.3,3,3v18c0,1.7-1.3,3-3,3h-19c-1.7,0-3-1.3-3-3v-18C-407,242.3-405.7,241-404,241z"/>
            <path d="M-404,271h19c1.7,0,3,1.3,3,3v18c0,1.7-1.3,3-3,3h-19c-1.7,0-3-1.3-3-3v-18C-407,272.3-405.7,271-404,271z"/>
            <path d="M-373,241h19c1.7,0,3,1.3,3,3v18c0,1.7-1.3,3-3,3h-19c-1.7,0-3-1.3-3-3v-18C-376,242.3-374.7,241-373,241z"/>
            <path d="M-373,271h19c1.7,0,3,1.3,3,3v18c0,1.7-1.3,3-3,3h-19c-1.7,0-3-1.3-3-3v-18C-376,272.3-374.7,271-373,271z"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'CubesIcon',
    props: {
        size: {
            type: [Number, String],
            default: 16
        }
    }
}
</script>
