<template>
    <div class="w-100">
        <div class="row-between row-baseline mb-2">
            <span
                class="f-10-grey"
                :class="{ 'f-12-darkgrey f-sbold s-align-4 pt-2': isWarrantyLabelStyle }"
            >
                {{ label }}
            </span>
            <button
                v-if="value"
                class="transparent-button"
                @click="clearValue"
            >
                <component
                    :is="`${iconName}-icon`"
                    size="9"
                    class="mr-1"
                    :class="classForIcon"
                />
                <span class="f-10-darkgrey">Clear</span>
            </button>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    import {CloseIcon, TrashIcon} from '@/icons';
    export default {
        name: 'ClearInputValue',
        components: {
            CloseIcon,
            TrashIcon
        },
        props: {
            label: {
                required: false,
                type: String
            },
            value: {
                required: true,
                type: String | Number | Object | Boolean
            },
            iconName: {
                type: String,
                default: 'close'
            },
            classForIcon: {
                type: String,
                default: 'i-red'
            },
            isWarrantyLabelStyle: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            clearValue() {
                this.$emit('clearValue');
            }
        }
    }
</script>