<template>
    <transition name="tabs-full" appear>
        <div class="modal tabs-full-modal">
            <div class="s-align-9 column-end h-100 w-100">
                <div class="tabs-full-content tabs-content-full-height">
                    <div class="tabs-menu-colored">
                        <div class="tabs-full-title row-between">
                            <h2 class="f-24-darkgrey f-sbold">Contact types</h2>
                            <button
                                class="transparent-button"
                                @click="$emit('close')"
                            >
                                <close-icon
                                    size="13"
                                    class="i-grey"
                                />
                            </button>
                        </div>
                        <div class="fixed-tabs-navigation z-index-2">
                            <div class="row-static w-100">
                                <button
                                    v-for="(tab, index) in tabsMenu"
                                    :key="index"
                                    :class="[
                                        'list-items-tab s-align-5 w-50',
                                        { 'materials-tab-active relative' : currentType == index }
                                    ]"
                                    :disabled="loading"
                                    @click="switchTab(index)"
                                >
                                    <span class="list-items-tab-name">{{ tab.name }}</span>
                                </button>

                            </div>
                            <div class="search-wrapper w-100">
                                <div class="search s-align-4 z-index-2">
                                    <button class="transparent-button">
                                        <search-icon class="i-grey"/>
                                    </button>
                                    <input
                                        type="search"
                                        name="search"
                                        placeholder="Search..."
                                        v-model="searchValue"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <loader
                            v-if="loading"
                            size="small"
                        />
                    </div>
                    <div
                        v-if="!loading"
                        class="pv-20 ph-20"
                    >
                        <div
                            v-for="(item, index) in activeOptions"
                            :key="index"
                        >
                            <div
                                class="pick-list-property row-start row-baseline mb-1"
                                @dragover="propertiesDragOver(item, index)"
                                @dragend="propertiesDragEnd(item, index)"
                                @dragstart="propertiesDragStart(item, index, $event)"
                                :class="{
                                    selected: (propertiesDraggingIndex === index),
                                    over: (index === propertiesOverIndex && index !== propertiesDraggingIndex)
                                }"
                                draggable="true"
                            >
                                <line-icon
                                    size="15"
                                    class="i-grey"
                                />
                                <div class="w-100">
                                    <form-input
                                        :class="[
                                            'w-100',
                                            { 'form-input-danger' : errors.has('pick_list_property_' + index) }
                                        ]"
                                    >
                                        <input
                                            class="form-input-field"
                                            type="text"
                                            :id="'pick_list_property_' + index"
                                            :name="'pick_list_property_' + index"
                                            placeholder="Enter value"
                                            v-input-style="activeOptions[index].title"
                                            v-model="activeOptions[index].title"
                                            maxlength="80"
                                            v-validate="'required|max:80'"
                                            @change="updateOption(item)"
                                        >
                                    </form-input>
                                </div>
                                <div class="static-col-3 column-end">
                                    <toggle-switch>
                                        <input
                                            type="checkbox"
                                            :name="`is_active_${index}`"
                                            v-model="activeOptions[index].is_active"
                                            @change="updateOption(item)"
                                        />
                                    </toggle-switch>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!loading">
                        <div>
                            <div
                                v-if="showFormNewOption"
                                class="mb-25 pick-list-property mh-20"
                            >
                                <div class="column-start pv-20">
                                    <div class="row-between row-baseline flex-no-wrap">
                                        <form-input
                                            :class="[
                                                { 'form-input-danger' : errors.has('pick_list_property') }
                                            ]"
                                        >
                                            <input
                                                class="form-input-field"
                                                type="text"
                                                id="new_contact_type_item"
                                                name="new_contact_type_item"
                                                placeholder="Enter value"
                                                v-model="newOption.title"
                                                maxlength="80"
                                                v-validate="'required|max:80'"
                                            >
                                        </form-input>
                                        <div class="static-col-2 column-end">
                                            <toggle-switch>
                                                <input
                                                    type="checkbox"
                                                    v-model="newOption.is_active"
                                                />
                                            </toggle-switch>
                                        </div>
                                    </div>
                                    <div class="row-between mt-20">
                                        <button
                                            @click="showFormNewOption = false"
                                            class="add-button"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            @click="addNewOption"
                                            class="primary-button"
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="new-option-form"
                            class="pv-20 ph-20"
                        >
                            <button
                                v-if="!showFormNewOption"
                                class="custom-fields-new-item s-align-5 w-100"
                                @click="openFormAddOption"
                            >
                                <div class="round s-align-5 mr-5">
                                    <add-icon size="9"/>
                                </div>
                                <span class="f-13-blue f-sbold">New option</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    import { LineIcon, TrashIcon, WarningIcon, AddIcon, ArrowIcon, EditIcon, SearchIcon, CloseIcon } from '@/icons';
    import { FormInput, Loader, ToggleSwitch } from '@/ui';
    import api from '@/headers.js';
    import { scrollToElementInBlock } from '@/helpers';

    const PICK_LIST = 'pick_list';

    export default {
        name: 'ContactTypesProperties',
        components: {
            SearchIcon, CloseIcon, Loader, ToggleSwitch, EditIcon, FormInput,
            ArrowIcon, AddIcon, WarningIcon, TrashIcon, LineIcon
        },
        props: {
            entityType: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                selectData: {
                    type: '',
                    selectOptions: [],
                },
                newOption: {
                    title: '',
                    is_active: true
                },
                showFormNewOption: false,
                propertiesOverIndex: null,
                propertiesDragging: '',
                propertiesDraggingIndex: null,
                loading: false,
                searchValue: '',
                searchAdhered: false,
                tabsMenu: [
                    { name: 'Active' },
                    { name: 'Inactive' }
                ],
                currentType: 0
            }
        },
        created() {
            this.getContactTypes();
        },
        mounted() {
            this.$nextTick(() => {
                const content = document.querySelector('.tabs-full-content');
                const search = document.querySelector('.tabs-full-content .fixed-tabs-navigation');

                content.addEventListener('scroll', (event) => {
                    if (event.target.scrollTop >= 200) {
                        search.classList.add('search-adhered');
                        this.searchAdhered = true;
                    } else {
                        search.classList.remove('search-adhered');
                        this.searchAdhered = false;
                    }
                });
            });
        },
        computed: {
            activeOptions() {
                return this.selectData.selectOptions.filter(post => {
                    return post.title.toLowerCase().includes(this.searchValue.toLowerCase());
                })
            }
        },
        methods: {
            openFormAddOption() {
                this.showFormNewOption = true;
                this.$nextTick(() => {
                    scrollToElementInBlock(document.getElementById('new-option-form'));
                    document.getElementById('new_contact_type_item').focus();
                })
            },
            getContactTypes() {
                this.loading = true;
                this.selectData.selectOptions.length = 0;
                let contactTypesListStatus = this.currentType === 0 ? 1 : 0;

                api.get(`/commercial/contact-types?display=${contactTypesListStatus}`)
                    .then(response => response.data.data)
                    .then(data => {
                        this.selectData.selectOptions.push(...data);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => this.loading = false);
            },
            addNewOption() {
                this.$validator.validate('new_contact_type_item').then(result => {
                    if (!result) {
                        this.notifyError('Please fill out field!');
                        return;
                    };

                    api.post('/commercial/contact-types', this.newOption)
                        .then(response => response.data.data)
                        .then((data) => {
                            this.showFormNewOption = false;
                            this.notifySuccess(`Contact types ${data.title} added!`);
                            if (data.is_active != this.currentType) {
                                this.selectData.selectOptions.push(data);
                            }
                            this.newOption = {
                                title: '',
                                is_active: true,
                            }
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                })
            },
            updateOption(item) {
                const params = {
                    is_active: item.is_active,
                    order: this.propertiesDraggingIndex || item.order,
                    title: item.title
                };

                this.$validator.validateAll().then((result) => {
                    if (result) {
                        api.put(`/commercial/contact-types/${item.id}`, params)
                            .then(() => {
                                this.notifySuccess(`Contact type is updated!`);
                                if (item.is_active == this.currentType) {
                                    let index = this.selectData.selectOptions.findIndex((f) => f.id === item.id);
                                    this.selectData.selectOptions.splice(index, 1);
                                }
                            })
                            .catch(error => {
                                this.notifyRequestErrors(error);
                            })
                            .finally(() => {
                                this.propertiesDraggingIndex = null;
                                this.propertiesOverIndex = null;
                            });
                    }
                })
            },
            propertiesDragStart(item, index, event) {
                this.$store.commit('customFields/SET_DRAG_SOURCE', PICK_LIST);
                this.propertiesDragging = item;
                this.propertiesDraggingIndex = index;
            },
            propertiesDragOver(item, index) {
                this.propertiesOverIndex = index;
                if (this.propertiesOverIndex !== this.propertiesDraggingIndex) {
                    this.selectData.selectOptions.splice(this.propertiesDraggingIndex, 1);
                    this.selectData.selectOptions.splice(this.propertiesOverIndex, 0, this.propertiesDragging);
                    this.propertiesDraggingIndex = index;
                }
            },
            propertiesDragEnd(item, index) {
                this.$store.commit('customFields/SET_DRAG_SOURCE', '');
                this.propertiesDraggingIndex = index;
                if (this.propertiesOverIndex !== this.propertiesDraggingIndex) {
                    this.updateOption(item);
                }
                this.propertiesDragging = '';
            },
            switchTab(index) {
                this.currentType = index;
                this.getContactTypes();
            },
        },
    }
</script>