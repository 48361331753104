<template>
    <div class="preview-warranty-content mb-30">
        <div class="s-align-4 sm-12 pt-8 pb-30 mb-sm-1 pl-20">
            <button
                class="transparent-button"
                @click="backToPage"
            >
                <arrow-icon
                    class="mr-1 i-darkgrey"
                    transform="180"
                />
                <span class="f-12-grey">Back to Warranty</span>
            </button>
        </div>
        <div class="preview-warranty-content__header">
            <div class="lg-8 inline-flex align-center">
                <img
                    v-if="company.thumbnail !== null"
                    :src="`${url}${company.thumbnail}`"
                    class="preview-warranty-content__header-logo"
                    alt=""
                >
                <p class="f-14-black f-sbold preview-warranty-content__header-title">
                    {{ company.company_name }}
                </p>
            </div>
            <div class="lg-4 column-evenly-end s-align-8 pt-20 t-right">
                <p class="f-14-grey f-sbold mb-2">
                    Job #:
                    <span class="f-14-darkgrey2 f-normal pl-1">{{ jobNumber }}</span>
                </p>
            </div>
        </div>
        <div class="preview-warranty-content__card">
            <div v-for="(section, index) in sectionsData" :key="section.id">
                <div
                    class="preview-warranty-content__card-header f-16-white"
                >
                    Type {{ index + 1 }}: {{ section.name }}
                </div>
                <div class="preview-warranty-content__card-body">
                    <div v-for="field in section.fields" :key="field.id">
                        <p class="f-14-grey f-sbold mb-1">
                            {{ field.label }}:
                            <span
                                v-if="field.value"
                                class="f-14-darkgrey2 f-normal"
                                :class="!field.view && 'f-13-grey'"
                            >
                                {{ field.value }}
                            </span>
                            <span
                                class="f-italic f-14-grey"
                                v-else
                            >
                                {{ no_info }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="sm-12"
            v-if="entity && entity.representatives"
        >
            <div v-if="customerSettings" class="preview-warranty-content__card w-100">
                <div class="preview-warranty-content__card-header f-16-white">
                    Customer Details
                </div>
                <div
                    v-for="representative in entity.representatives"
                    :key="representative.id"
                    class="preview-warranty-content__card-body"
                >
                    <p class="f-16-grey f-sbold pb-1">
                        Name:
                        <span class="f-16-darkgrey2 pl-1">
                            {{ representative.first_name }} {{ representative.last_name }}
                        </span>
                    </p>
                    <p class="f-16-grey f-sbold pb-1">
                        Phone:
                        <span
                            v-for="phone in representative.phones"
                            :key="phone.id"
                            class="f-16-darkgrey2 pl-1"
                        >
                            {{ phone.number | phone }}
                        </span>
                    </p>
                    <p class="f-16-grey f-sbold pb-1">
                        Email:
                        <span
                            v-for="email in representative.emails"
                            :key="email.id"
                            class="f-16-darkgrey2 pl-1"
                        >
                            {{ email.email }}
                        </span>
                    </p>
                    <p v-if="entity.full_address" class="f-16-grey f-sbold pb-1">
                        Address:
                        <span class="f-16-darkgrey2 pl-1">
                            {{ entity.full_address }}
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="preview-warranty-content__footer">
            <div class="preview-warranty-content__footer-block">
                <p class="f-16-darkgrey2 f-sbold">{{ company.company_name }}</p>
                <p class="f-16-grey f-sbold">{{ company.full_address }}</p>
                <p class="f-16-grey f-sbold">tel: {{ company.phone }}, fax: {{ company.fax }}</p>
                <a v-if="company.url" :href="company.url" target="_blank">{{ company.url }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import {ArrowIcon} from '@/icons';

export default {
    name: 'WarrantyPdf',
    components: {
        ArrowIcon
    },
    props: {
        company: {
            type: Object
        },
        jobNumber: {
            type: Number | String,
            default: '-'
        },
        sectionsData: {
            type: Array
        },
        entity: {
            type: Object
        },
        customerSettings: {
            type: Boolean
        }
    },
    data() {
        return {
            url: process.env.VUE_APP_AMAZONAWS_URL,
            no_info: 'No info',
        }
    },
    methods: {
        backToPage() {
            this.$emit('backToPage')
        }
    }
}
</script>
