<template>
    <div class="form-input-textarea-wrapper w-100">
        <div 
            :class="[
                'form-input-textarea hidden relative w-100',
                onSubmit === undefined ? 'no-control-btns' : '',
                { 'transparent-bg' : transparentBg }
            ]"
            @click="focusOnInput"
            ref="wrapper"
        >
            <slot name="default"></slot>
            <span 
                :class="[
                    'form-input-label w-100 t-left',
                    { 'transparent-bg' : transparentBg }
                ]"
                v-if="label"
            >
                {{ label }}
            </span>
        </div>
        <div 
            class="form-input-footer row-between mt-5"
            @click="focusOnInput"
        >
            <div 
                v-if="onSubmit !== undefined"
                class="form-input-control s-align-5"
            >
                <button 
                    class="cancel-changes cancel-button"
                    id="cancelTextAreaChanges"
                    @click="onCancel"
                    :disabled="disabled"
                >
                    Cancel
                </button>
                <button 
                    class="submit-changes cancel-button ml-3"
                    id="submitTextAreaChanges"
                    @click="onSubmit"
                    :disabled="disabled"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FormInputTextarea',
        props: {
            label: {
                type: [String],
                default: '',
                required: false
            },
            onSubmit: {
                type: Function,
                required: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            transparentBg: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                initValue: '',
                input: null
            }
        },
        methods: {
            focusOnInput() {
                this.$refs.wrapper && this.$refs.wrapper.querySelector('textarea').focus();
            },
            onCancel() {
                // resotore init value
                this.input.value = this.initValue;
                // dispatch 'custom' event to prevent issues
                this.input.dispatchEvent(new CustomEvent('input'));
                this.onSubmit();
            }
        },
        mounted() {
            this.input = this.$el.querySelector('.form-input-field');
            // save init value to restore it when canceled
            this.initValue = this.input.value;
        }
    }
</script>
