<template>
    <svg
        viewBox="0 0 13 11"
        :width="size" 
        :height="size"
        class="text-right-icon"
    >
        <path d="M.3 1.4h12.3c.2 0 .4-.2.4-.3V.3c0-.2-.2-.3-.4-.3H.3C.2 0 0 .1 0 .3V1c0 .3.2.4.3.4zM5 4.6h7.6c.2 0 .4-.2.4-.4v-.7c0-.2-.2-.4-.4-.4H5c-.2 0-.4.2-.4.4v.7c.1.3.2.4.4.4zM.3 7.8h12.3c.2 0 .4-.2.4-.4v-.7c0-.2-.2-.3-.4-.3H.3c-.1-.1-.3.1-.3.3v.7c0 .2.2.4.3.4zM5 11h7.6c.2 0 .4-.2.4-.4v-.7c0-.2-.2-.3-.4-.3H5c-.2 0-.4.2-.4.3v.7c.1.2.2.4.4.4z"/>
    </svg>
</template>

<script>
    export default {
        name: 'TextRightIcon',
        props: {
            size: {
                type: [Number, String],
                default: 13
            }
        }
    }
</script>