<template>
    <div class="w-100">
        <div v-if="activeTab === 'information'" class="w-100">
            <div :class="['form-builder-section row-start', dScreen ? 'ph-30' : 'ph-8']">
                <div class="row-start row-baseline mb-8">
                    <div class="lg-5 md-6 sm-12 column-start">
                        <div class="f-15-darkgrey f-sbold">Dates</div>
                        <div class="f-13-grey mb-2">Select date</div>
                    </div>
                    <div class="lg-7 md-6 sm-12">
                        <div :class="dScreen ? 'row-start flex-no-wrap' : 'w-100'">
                            <div :class="dScreen ? 'mr-4 lg-6' : 'w-100 mb-8'">
                                <form-datepicker
                                    v-model="report.dateOfRequest"
                                    :isFullWidth="true"
                                    keyDatePicker="date_of_request_date_picker"
                                    placeholder="Date of Request"
                                />
                            </div>
                            <div :class="dScreen ? 'lg-6' : 'w-100'">
                                <form-datepicker
                                    v-model="report.dateOfResponse"
                                    :isFullWidth="true"
                                    keyDatePicker="date_of_response_date_picker"
                                    placeholder="Date of Response"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row-start row-baseline mb-8">
                    <div class="lg-5 md-6 sm-12 column-start">
                        <div class="f-15-darkgrey f-sbold">Date of Report</div>
                    </div>
                    <div class="lg-7 md-6 sm-12">
                        <form-datepicker
                            v-model="report.dateOfReport"
                            :isFullWidth="true"
                            placeholder="Date of Report"
                            keyDatePicker="date_of_report_date_picker"
                        />
                    </div>
                </div>
                <div class="row-start row-baseline mb-8 s-align-1 bt-grey pt-8">
                    <div class="lg-5 md-6 sm-12 column-start">
                        <div class="f-15-darkgrey f-sbold">Main Information</div>
                    </div>
                    <div class="lg-7 md-6 sm-12">
                        <div class="column-start w-100">
                            <div class="w-100 pb-4">
                                <form-input label="Requested By">
                                    <input
                                        type="text"
                                        name="requested_by"
                                        class="form-input-field"
                                        placeholder="Requested By"
                                        v-input-style="report.requestedBy"
                                        v-model="report.requestedBy"
                                    />
                                </form-input>
                            </div>
                            <div class="row-start">
                                <div :class="['lg-6 md-6 sm-12 pr-2 pr-sm-0', mScreen && 'mb-4']">
                                    <form-input label="Property Name 1">
                                        <input
                                            type="text"
                                            name="name"
                                            class="form-input-field"
                                            placeholder="Property Name 1"
                                            v-input-style="report.name"
                                            v-model="report.name"
                                        />
                                    </form-input>
                                </div>
                                <div class="lg-6 md-6 sm-12 pl-2 pl-sm-0">
                                    <form-input label="Property Name 2">
                                        <input
                                            type="text"
                                            name="name_additional"
                                            class="form-input-field"
                                            placeholder="Property Name 2"
                                            v-input-style="report.nameAdditional"
                                            v-model="report.nameAdditional"
                                        />
                                    </form-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row-start row-baseline mb-4 s-align-1 bt-grey pt-8">
                    <div class="lg-5 md-6 sm-12 column-start">
                        <div class="f-15-darkgrey f-sbold">Address</div>
                    </div>
                    <div class="lg-7 md-6 sm-12">
                        <address-box
                            type="address"
                            :key="'address'"
                            :existing="report.address"
                            :validate="VALIDATE_NOT_REQUIRED"
                            @address="getAddress"
                        />
                    </div>
                </div>
            </div>
            <div
                :class="[
                    'form-builder-content row-start row-baseline s-align-1 pt-8 pb-8',
                    dScreen ? 'ph-30' : 'ph-8'
                ]"
            >
                <div class="lg-5 md-6 sm-12 column-start">
                    <div class="f-15-darkgrey f-sbold">Action Performed</div>
                </div>
                <div class="lg-7 md-6 sm-12">
                    <form-input-textarea v-input-counter="1000">
                        <textarea
                            name="action_performed"
                            v-model="report.actionPerformed"
                            rows="3"
                            class="form-input-field"
                            placeholder="Add action performed description..."
                            maxlength="1000"
                            v-resize-textarea
                        />
                    </form-input-textarea>
                </div>
            </div>
            <div class="form-builder-custom-section bt-grey">
                <form-builder-custom-fields
                    :layoutId="page.id"
                    :updateCustomFields="updateCustomFields"
                    :entityType="entityType"
                    @changeFields="changeCustomFields"
                    :clear="clear"
                />
            </div>
        </div>
        <form-builder-photo-form
            :saved="savedPhotos"
            :crearPhoto="clear"
            :active-tab="activeTab"
            @set="addPhotoToReport"
        />
    </div>
</template>

<script>
    import { FormInput, CheckboxButton, FormDatepicker, FormInputTextarea } from '@/ui';
    import { FORM_BUILDER_REPORTS, ENTITY_TYPES } from '@/constants';
    import { clearArray } from '@/helpers';
    import AddressBox from '@/components/AddressBox.vue';
    import FormBuilderCustomFields from '@/components/LeadModules/FormBuilder/FormBuilderCustomFields.vue';
    import FormBuilderPhotoForm from '@/components/LeadModules/FormBuilder/FormBuilderPhotoForm.vue';

    const REPORT_TYPE = 'service';

    export default {
        name: 'FormBuilderServiceResponseReport',
        props: {
            clear: {
                type: Boolean,
                default: false
            },
            get: {
                type: Boolean,
                default: false
            },
            selected: {
                type: Object,
                required: false
            },
            entityType: {
                type: String,
                required: true
            },
            page: {
                type: Object,
                required: false
            },
            activeTab: {
                type: String,
                required: false,
            }
        },
        components: {
            FormInput,
            CheckboxButton,
            FormDatepicker,
            AddressBox,
            FormBuilderCustomFields,
            FormBuilderPhotoForm,
            FormInputTextarea
        },
        data() {
            return {
                report: {},
                updateCustomFields: {},
                savedPhotos: [],
                addActionPerformed: false
            }
        },
        watch: {
            clear: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal) this.createNewReport();
                }
            },
            get: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal) this.$emit('receive', this.report);
                }
            },
            selected: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal != null && newVal != oldVal) {
                        this.report = this.selected
                        this.updateCustomFields = this.selected.customFields;
                        this.savedPhotos = this.selected.photos;
                    }
                }
            },
            entityAddress(val, oldVal) {
                if (val.address) {
                    this.report.address = val;
                }
            }
        },
        computed: {
            entityAddress() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: {
                        return this.$store.getters['lead/getClippedLeadAddress'];
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_JOB: {
                        return this.$store.getters['commercial/getCommercialJobAddress'];
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return this.$store.getters['commercial/getCommercialLeadAddress'];
                    } break;
                }
            }
        },
        methods: {
            createNewReport() {
                this.report = {
                    title: FORM_BUILDER_REPORTS.SERVICE,
                    reportType: REPORT_TYPE,
                    reportNumber: this.$route.params.lead_id,
                    reportName: '',
                    dateOfRequest: null,
                    dateOfResponse: null,
                    dateOfReport: new Date(),
                    requestedBy: '',
                    name: '',
                    nameAdditional: '',
                    address: {
                        address: '',
                        street: '',
                        city: '',
                        state: '',
                        zip: ''
                    },
                    photos: [],
                    description: '',
                    customFields: {}
                }

                clearArray(this.savedPhotos);
            },
            getAddress(data) {
                this.report.address = data;
            },
            changeCustomFields(data) {
                this.report.customFields = data;
            },
            addPhotoToReport(data) {                
                this.report.photos = data.photos;
            }
        },
        created() {
            this.VALIDATE_NOT_REQUIRED = '';
            this.createNewReport();
        }
    }
</script>