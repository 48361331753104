<template>
    <component 
        :is="mainTag"
        :class="[
            'estimate-info row-start mb-2 relative', 
            { 
                'active' : active, 
                'pointer': $can($permissions.EDIT_ESTIMATE) 
            }
        ]"
        @click="moveToEstimate"
    >
        <slot name="input"></slot>
        <div class="info-name lg-7 md-11 sm-11">
            <div class="row-start">
                <div class="pr-20">
                    <span 
                        class="edit-icon-wrapper s-align-5"
                        v-if="isManual"
                    >
                        <edit-icon
                            size="14"
                            class="i-violet"
                        />
                    </span>
                    <span
                        class="manual-icon-wrapper s-align-5"
                        v-else
                    >
                        <template-icon 
                            size="18"
                            class="inverted-template-icon"
                        />
                    </span>
                </div>
                <div class="lg-11 nb-10 md-10 sm-9 column-start">
                    <h2 :class="['column-start f-15-darkgrey f-sbold', !filesLength ? 'pb-4' : 'pb-1']">
                        {{ estimate.name }}
                        <span 
                            v-if="estimate.parent_id"
                            class="f-10-grey"
                        >
                            ({{ estimate.comment }})
                        </span>
                    </h2>
                    <p class="row-start">
                        <span class="f-12-grey pr-4 pb-1">
                            Generated by:
                            <span class="f-12-darkgrey pl-1">{{ estimate.user.fullname }}</span>
                        </span>
                        <span class="f-12-grey pr-4">
                            on
                            <span class="f-12-darkgrey pl-1">{{ estimate.created_at | monthDayYear }}</span>
                        </span>
                        <span 
                            class="f-12-grey pr-4 pb-1"
                            v-if="estimate.valid_until"
                        >
                            Valid until:
                            <span class="f-12-darkgrey pl-1">{{ estimate.valid_until | date }}</span>
                        </span>
                        <span
                            v-if="!isManual"
                            class="f-12-grey pb-1"
                        >
                            Status:
                            <span :class="[
                                'capitalize relative', `preview-box-${defineEstimateStatus(estimate.status)}`
                            ]">
                                {{ defineEstimateStatus(estimate.status) }}
                            </span>
                        </span>
                    </p>
                    <p 
                        class="w-100 s-align-4"
                        v-if="filesLength"
                    >
                        <attach-icon 
                            class="i-blue mr-2"
                            size="12"
                        />
                        <span class="f-12-black">{{ `${filesLength} Attachments` }}</span>
                        <!-- <span class="f-12-grey ph-2">&#149;</span>
                        <button 
                            class="transparent-button f-12-blue f-sbold"
                        >
                            Download all
                        </button> -->
                    </p>
                </div>
            </div>
        </div>
        <div :class="['info-description md-12 sm-12 column-start s-align-5', showBaseBidButton ? 'lg-2' : 'lg-4']">
            <p class="f-14-black f-sbold">{{ estimate.amount | currency }}</p>
            <p class="f-11-grey">Total price</p>
            <p 
                class="f-12-black pt-3"
                v-if="estimate.from_library_updated_date"
            >
                Updated:
                <span class="f-12-grey underline">{{ estimate.from_library_updated_date | timeAgo }}</span>
            </p>
            <p 
                class="pt-2 s-align-4"
                v-if="estimate.status == estimate.all_statuses.outdated"
            >
                <round-warning-icon class="i-orange"/>
                <span class="f-12-orange opacity-8 pl-2">Material substitute needed</span>
            </p>
        </div>
        <div 
            class="info-base-bid lg-2 md-12 sm-12 s-align-5 column-end"
            v-if="showBaseBidButton"
        >
            <button
                v-if="!estimate.is_base_bid"
                :class="[
                    'estimate-base-bid-button f-11-white f-sbold',
                    { 'active' : estimate.is_base_bid }
                ]"
                @click.stop.prevent="$emit('setBaseBid', estimate)"
            >
                <mark-icon
                    size="9"
                    class="i-white mr-2"
                    v-if="estimate.is_base_bid"
                />
                {{ estimate.is_base_bid ? 'Base bid' : 'Check as Base bid' }}
            </button>
            <span
                v-else
                @click.stop
                :class="[
                    'estimate-base-bid-button s-align-5 f-11-white f-sbold cursor-default', 
                    { 'active' : estimate.is_base_bid }
                ]"
            >
                <mark-icon
                    size="9"
                    class="i-white mr-2"
                    v-if="estimate.is_base_bid"
                />
                Base bid
            </span>
            <p
                class="f-12-grey mt-1"
                v-if="estimate.is_base_bid"
            >
                Probability 
                <span 
                    class="ph-1 br-5 f-11-white f-sbold"
                    :style="{
                        backgroundColor: getProbabilityColor(estimate.probability)
                    }"
                >
                    {{ !estimate.probability ? 0 : estimate.probability }} %
                </span>
            </p>
        </div>
        <div class="lg-1 info-menu">
            <slot name="context-menu"></slot>
        </div>
    </component>
</template>

<script>
    import { ESTIMATE_TYPES, ENTITY_TYPES } from '@/constants.js';
    import { RoundWarningIcon, EditIcon, TemplateIcon, AttachIcon, MarkIcon } from '@/icons';

    export default {
        name: 'EstimateInfo',
        props: {
            estimate: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            entityType: {
                type: String,
                default: ENTITY_TYPES.RESIDENTIAL_LEAD
            }
        },
        components: {
            RoundWarningIcon, 
            EditIcon, 
            TemplateIcon, 
            AttachIcon,
            MarkIcon
        },
        data() {
            return {
                ESTIMATE_TYPES: ESTIMATE_TYPES
            }
        },
        computed: {
            filesLength() {
                return this.estimate.files.length;
            },
            isManual() {
                return this.estimate.type === ESTIMATE_TYPES.MANUAL;
            },
            showBaseBidButton() {
                return this.estimate.status === this.estimate.all_statuses.final || this.isManual;
            },
            editEstimateRoute() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: {
                        return 'ResidentialEstimateEdit';
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_JOB: {
                        return 'CommercialJobEstimateEdit';
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return 'CommercialLeadEstimateEdit';
                    } break;
                }
            },
            editManualEstimateRoute() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: {
                        return 'ResidentialManualEstimateCreate';
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_JOB: {
                        return 'CommercialJobManualEstimateCreate';
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return 'CommercialLeadManualEstimateCreate';
                    } break;
                }
            },
            mainTag() {
                return this.disabled ? 'label' : 'div';
            }
        },
        methods: {
            defineEstimateStatus(status) {
                for (let field in this.estimate.all_statuses) {
                    if (this.estimate.all_statuses[field] == status) {
                        return field;
                    }
                }

                return '';
            },
            moveToEstimate(event) {
                if (this.disabled || !this.$can(this.$permissions.EDIT_ESTIMATE)) return;

                event.stopPropagation();
                event.preventDefault();

                if (this.estimate.type === ESTIMATE_TYPES.MANUAL) {
                    this.$router.push({ 
                        name: this.editManualEstimateRoute, 
                        params: { estimate_id: this.estimate.id }
                    });
                } else {
                    this.$router.push({ 
                        name: this.editEstimateRoute, 
                        params: { estimate_id: this.estimate.id }
                    });
                }
            },
            getProbabilityColor(value) {
                if (value < 33.3) {
                    return 'red';
                } else if (value < 66.6) {
                    return 'blue'; 
                }
                return 'green'; 
            }
        }
    }
</script>