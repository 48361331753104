<template>
    <div 
        :class="[
            'form-input',
            label === '' ? 'no-label' : '',
            !!requiredMark ? 'required-field-mark' : ''
        ]"
    >
        <slot name="default"></slot>
        <span 
            class="form-input-label inline-block ellipsis"
            v-if="label"
        >
            {{ label }}
        </span>
        <button    
            v-if="showClearButton"
            class="clear-form-input"
        >
            <close-icon/>
        </button>
        <div 
            v-if="checkIfMaskVisible"
            class="form-input-mask"
            @click="focusOnInput"
        >
            <span 
                v-if="maskBefore !== ''"
                class="mask-before"
            >
                {{ maskBefore }}
            </span>
            <span class="mask-value">{{ maskValueWithFilter }}</span>
            <span 
                v-if="maskAfter !== ''"
                class="mask-after"
            >
                {{ maskAfter }}
            </span>
        </div>
    </div>
</template>

<script>
    import { CloseIcon } from '@/icons';
    import dynamicFilter from '@/mixins/dynamicFilter';

    export default {
        name: 'FormInput',
        props: {
            label: {
                type: [String],
                default: ''
            },
            maskBefore: {
                type: String,
                default: ''
            },
            maskAfter: {
                type: String,
                default: ''
            },
            maskFilter: {
                type: String,
                required: false
            },
            maskValue: {
                type: [String, Number],
                required: false
            },
            requiredMark: Boolean,
            showClearButton: {
                type: Boolean,
                default: false
            }
        },
        mixins: [dynamicFilter],
        components: {
            CloseIcon
        },
        computed: {
            checkIfMaskVisible() {
                return (this.maskBefore !== '' || this.maskAfter !== '' || this.maskFilter !== '') 
                    && !(this.maskValue === undefined || this.maskValue === null || this.maskValue === '')
            },
            maskValueWithFilter() {
                if (this.maskFilter !== undefined) {
                    return this.dynamicFilter(this.maskFilter, parseFloat(this.maskValue))
                } else {
                    return this.maskValue;
                }
            }
        },
        methods: {
            focusOnInput() {
                this.$el.querySelector('input').focus();
            }
        }
    }
</script>
