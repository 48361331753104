<template>
    <svg 
	    viewBox="-429 343 13 13"
        :width="size" 
        :height="size"
        class="progress-icon"
    >
        <path d="M-426.4,353c-2-2.2-1.8-5.5,0.4-7.5s5.5-1.8,7.5,0.4h-1.6c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h2.9c0.3,0,0.6-0.3,0.6-0.6v-2.9c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6v1.5c-1.2-1.3-3-2.1-4.8-2c-3.6,0-6.5,2.9-6.5,6.5c0,3.6,2.9,6.5,6.5,6.5c3.6,0,6.5-2.9,6.5-6.5c0-0.3-0.3-0.6-0.6-0.6s-0.6,0.3-0.6,0.6c0,2.9-2.4,5.3-5.3,5.3C-424,354.8-425.4,354.1-426.4,353z"/>
    </svg>
</template>

<script>
    export default {
        name: 'ProgressIcon',
        props: {
            size: {
                type: [Number, String],
                default: 13
            }
        }
    }
</script>