import {ADD_CONTACT_IN_STORE} from "../actions/pointOfContacts";

export default {
    state: {
        contact: null,
    },
    getters: {
      getContact: state => state.contact
    },
    mutations: {
        [ADD_CONTACT_IN_STORE] (state, payload) {
            state.contact = payload;
        }
    }
}