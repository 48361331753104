<template>
<svg 
    viewBox="0 0 12 13"
    :width="size" 
    :height="size"
    class="flag-icon"
>
	<path 
        d="M11.7,0.8c-0.2-0.1-0.4-0.1-0.6,0c-0.3,0.2-2.2,0.6-3,0.6C7.2,1.3,6.5,1,5.9,0.7C5.1,0.4,4.3,0,3.1,0C1.7,0,0.4,0.7,0.3,0.7C0.1,0.8,0,1.1,0,1.3v2.1v3.6v5.3C0,12.7,0.3,13,0.7,13s0.7-0.3,0.7-0.6V7.5c0.4-0.2,1.1-0.4,1.8-0.4c1,0,1.6,0.3,2.3,0.6C6.1,8,6.9,8.4,8,8.4c1.3,0,3.6-0.7,3.6-0.7C11.9,7.6,12,7.3,12,7.1V1.3C12,1.1,11.9,0.9,11.7,0.8z"
    />
</svg>
</template>

<script>
    export default {
        name: 'FlagIcon',
        props: {
            size: {
                type: [Number, String],
                default: 15
            }
        }
    }
</script>
