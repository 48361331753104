<template>
    <svg 
        viewBox="0 0 19 19"
        :width="size"
        :height="size"
        class="close-ratio-report-icon"
    >
        <path d="M18.7,7L18.7,7c-0.7-2.5-2.2-4.5-4.4-5.8C12.8,0.4,11.2,0,9.5,0C6.1,0,3,1.8,1.3,4.8C0,6.9-0.3,9.5,0.3,12
            c0.7,2.5,2.2,4.5,4.4,5.8C6.2,18.6,7.8,19,9.5,19c3.4,0,6.5-1.8,8.2-4.8C19,12.1,19.3,9.5,18.7,7z M10.1,2.1V1.3
            c0.2,0,0.5,0,0.7,0.1L10.1,2.1z M10.1,5.1V3.9l2.1-2.1c0.3,0.1,0.6,0.2,0.9,0.4L10.1,5.1z M14.4,11.6l3.2-3.2
            c0.1,0.4,0.1,0.8,0.1,1.1l-2.5,2.5L14.4,11.6z M12.5,10.5L17,6.1c0.1,0.3,0.2,0.6,0.3,0.9l-4,4L12.5,10.5z M10.6,9.4l5.2-5.2
            c0.1,0.1,0.2,0.2,0.3,0.3s0.2,0.2,0.3,0.4l-4.9,5L10.6,9.4z M10.1,8.1V6.9l4.1-4.1c0.2,0.2,0.5,0.3,0.7,0.5L10.1,8.1z M5.4,2.3
            c1-0.6,2.2-1,3.4-1l0,8.2C8.9,9.7,9,9.9,9.2,10l7.1,4.1c-0.3,0.4-0.6,0.9-1,1.2c-0.5,0.5-1.1,0.9-1.7,1.3c-1.9,1.1-4.1,1.3-6.2,0.8
            c-0.7-0.2-1.4-0.5-2-0.8c-1-0.6-1.8-1.3-2.5-2.2c-0.6-0.8-1.1-1.8-1.4-2.8c-0.3-1-0.4-2.1-0.2-3.1c0.1-1.1,0.5-2.1,1-3.1
            c0.4-0.6,0.8-1.2,1.4-1.8C4.2,3.1,4.8,2.7,5.4,2.3z M17.5,11.6c-0.1,0.5-0.3,1-0.5,1.5l-0.6-0.3L17.5,11.6z"/>
    </svg>
</template>

<script>
export default {
    name: 'CopyIcon',
    props: {
        size: {
            type: [Number, String],
            default: 19
        }
    }
}
</script>