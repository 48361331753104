<template>
    <svg 
        viewBox="0 0 105.83853912353516 121.87399291992188"
        :width="width" 
        :height="height"
        class="password-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="105.83853912353516" 
            height="121.87399291992188" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(4.514847755432129, 0, 0, 4.514847755432129, 1568.14794921875, -1313.0107421875)">
            <g transform="translate(0,-952.36216)">
                <path 
                    class="st0" 
                    d="M-335.7,1243.6c-0.2,0-0.4,0.1-0.5,0.3c-1.1,1.6-4.7,3-8.8,3c0,0,0,0,0,0c0,0-0.1,0-0.1,0
                    c-0.3,0-0.5,0.2-0.6,0.5c0,0-0.6,2.7-0.9,6.1c-0.3,3.3-0.4,7.2,1,9.7c1,1.9,3.4,3.4,5.6,4.5c2,1.1,3.6,1.7,3.9,1.8
                    c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1c0,0,0,0,0,0c0.3-0.1,1.9-0.7,3.9-1.8c2.1-1.1,4.5-2.7,5.6-4.5c1.4-2.5,1.3-6.4,1-9.7
                    c-0.3-3.3-0.9-6.1-0.9-6.1c-0.1-0.3-0.3-0.5-0.6-0.5c0,0,0,0-0.1,0c-3.9,0-7.4-1.3-8.6-2.9
                    C-335.2,1243.8-335.5,1243.6-335.7,1243.6L-335.7,1243.6z M-335.7,1245.2c1.7,1.8,5,2.8,8.7,2.9c0.1,0.6,0.6,2.6,0.8,5.5
                    c0.3,3.2,0.2,7-0.8,9c-0.7,1.4-3,2.9-5.1,4c-1.9,1-3.4,1.6-3.7,1.7c-0.3-0.1-1.8-0.7-3.7-1.7c-2.1-1.1-4.3-2.7-5.1-4
                    c-1.1-2-1.1-5.8-0.8-9c0.3-2.8,0.7-4.9,0.8-5.5C-340.8,1248-337.5,1247-335.7,1245.2z"
                />
                <path 
                    class="st0" 
                    d="M-339.8,1254.1c-1.4,0-2.6,1.1-2.6,2.5s1.2,2.5,2.6,2.5c1.2,0,2.2-0.8,2.5-1.9h7.5c0.3,0,0.6-0.3,0.6-0.6
                    c0-0.3-0.3-0.6-0.6-0.6c0,0,0,0,0,0h-7.5C-337.6,1254.9-338.6,1254.1-339.8,1254.1z M-339.8,1255.3c0.7,0,1.3,0.6,1.3,1.3
                    c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3C-341.2,1255.9-340.6,1255.3-339.8,1255.3z M-332.6,1258c-0.3,0-0.6,0.3-0.6,0.6
                    s0.3,0.6,0.6,0.6c0,0,0,0,0,0h2.8c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c0,0,0,0,0,0H-332.6z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'PasswordIcon',
        props: {
            width: {
                type: [Number, String],
                default: 25
            },
            height: {
                type: [Number, String],
                default: 30
            }
        }
    }
</script>
