<template>
    <full-modal @close="$emit('close')">
        <div slot="title">Customer portal settings</div>
        <div class="pt-4" slot="body">
            <p class="f-14-black mb-25">
                Invite customers to their personal account to have all updates on their order progress. 
            </p>
            <radio-button
                class="mb-8"
                label="When job reaches milestone"
                color="blue"
            >
                <input 
                    type="radio" 
                    name="invite_mode" 
                    :value="INVITE_MODES.MILESTONE"
                    v-model="customerPortalSettingsData.inviteMode"
                />
            </radio-button>
            <div 
                v-if="customerPortalSettingsData.inviteMode === INVITE_MODES.MILESTONE"
                class="w-100 mb-8 pl-25"
            >
                <multiselect
                    :options="customerPortalSettingsData.availableMilestones"
                    name="milestone"
                    v-model="customerPortalSettingsData.inviteMilestone"
                    :showLabels="false"                        
                    :max-height="200"
                    placeholder="Select milestone"
                    track-by="id" 
                    label="title"
                    v-validate="'required'"
                    :class="[{ 'multiselect-danger' : errors.has('milestone') }]"
                >
                    <template 
                        slot="singleLabel" 
                        slot-scope="props"
                    >
                        <div 
                            v-if="props.option.title"
                            class="row-start row-baseline"
                        >
                            <span class="milestone-status-marker capitalize mr-4">
                                {{ getAbbreviation(props.option.title) }}
                            </span>
                            <span class="f-14-black">
                                {{ props.option.title }}
                            </span>
                        </div>
                    </template>
                    <template 
                        slot="option" 
                        slot-scope="props"
                    >   
                       <div 
                            v-if="props.option.title"
                            class="row-start row-baseline"
                        >
                            <span class="milestone-status-marker capitalize mr-4">
                                {{ getAbbreviation(props.option.title) }}
                            </span>
                            <span class="f-14-black">
                                {{ props.option.title }}
                            </span>
                        </div>
                    </template>  
                </multiselect>
            </div>
            <radio-button
                class="mb-8"
                label="Never send an invitation"
                color="blue"
            >
                <input 
                    type="radio" 
                    name="invite_mode" 
                    :value="INVITE_MODES.NONE"
                    v-model="customerPortalSettingsData.inviteMode"
                />
            </radio-button>
            <radio-button
                class="mb-30"
                label="Send invitations manually"
                color="blue"
            >
                <input 
                    type="radio" 
                    name="invite_mode" 
                    :value="INVITE_MODES.MANUAL"
                    v-model="customerPortalSettingsData.inviteMode"
                />
            </radio-button>
            <template v-if="isEmailInputShow">
                <p class="f-14-black">Send e-mails from a contact form to</p>
                <p class="f-12-grey mb-5">Enter e-mail where you'd like to receive customer's messages</p>
                <form-input 
                    label="Enter email here"
                    :class="[{ 'form-input-danger' : errors.has('recipient_email') }]"
                >
                    <input 
                        class="form-input-field"
                        name="recipient_email"
                        v-input-style="customerPortalSettingsData.recipientEmail"
                        v-model="customerPortalSettingsData.recipientEmail"
                        type="text"
                        placeholder="Enter email here"
                        v-validate="'required|email'"
                    />
                </form-input>
            </template>
        </div>
        <div slot="footer">
            <button 
                class="add-button mr-4"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                @click="updateCustomerPortalSettings"
                :disabled="requestIsGoing"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import api from '@/headers.js';
    import { FullModal, RadioButton, FormInput } from '@/ui';
    import cloneDeep from 'lodash/cloneDeep';
    import Multiselect from 'vue-multiselect';
    import { CUSTOMER_INVITE_MODES } from '@/constants.js';
    import { getAbbreviation } from '@/helpers';

    export default {
        name: 'CustomerPortalSettingsModal',
        components: {
            FullModal,
            RadioButton,
            Multiselect,
            FormInput
        },
        props: {
            customerPortalSettings: {
                required: true
            }
        },
        data() {
            return {
                customerPortalSettingsData: null,
                requestIsGoing: false,
                INVITE_MODES: CUSTOMER_INVITE_MODES,
                getAbbreviation
            }
        },
        computed: {
            isEmailInputShow() {
                return this.customerPortalSettingsData.inviteMode === this.INVITE_MODES.MILESTONE 
                    || this.customerPortalSettingsData.inviteMode === this.INVITE_MODES.MANUAL;
            }
        },
        methods: {
            updateCustomerPortalSettings() {
                this.$validator.validateAll().then(result => {
                    let params = {
                        inviteMode: this.customerPortalSettingsData.inviteMode
                    };

                    switch (this.customerPortalSettingsData.inviteMode) {
                        case this.INVITE_MODES.MILESTONE: 
                            params.inviteMilestoneId = this.customerPortalSettingsData.inviteMilestone.id;
                            params.recipientEmail = this.customerPortalSettingsData.recipientEmail;
                            params.inviteMilestone = this.customerPortalSettingsData.inviteMilestone.title;
                            break;
                        case this.INVITE_MODES.MANUAL:
                            params.recipientEmail = this.customerPortalSettingsData.recipientEmail;  
                            break; 
                    }

                    this.requestIsGoing = true;
                    api.put('settings/customer-portal', params)
                        .then(response => response.data.data)
                        .then(data => {
                            if (data) {
                                this.$emit('updateSettingsData', params)
                                this.notifySuccess('Customer portal settings changed!');
                            }
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.requestIsGoing = false;
                        });
                });
            }
        },
        created() {
            this.customerPortalSettingsData = cloneDeep(this.customerPortalSettings);

            if (this.customerPortalSettingsData.inviteMilestone) {
                this.customerPortalSettingsData.inviteMilestone = this.customerPortalSettingsData.availableMilestones
                .find(item => item.id === this.customerPortalSettingsData.inviteMilestoneId);
            }
        }
    }
</script>

