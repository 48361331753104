<template>
    <div>
        <div class="row-between">
            <div class="s-align-4">
                <span class="f-18-black f-sbold">
                    {{ reportTitle }}
                </span>
            </div>
            <button
                :class="[
                    'filter-button',
                    { 'selected-filter' : isFiltersShow }
                ]"
                @click="showFilters"
            >
                <div 
                    v-if="filterCount"
                    class="count f-11-black f-sbold s-align-5"
                >
                    {{ filterCount }}
                </div>
                <settings-icon
                    size="14"
                    v-else
                    class="i-black"
                />
                <span class="f-12-asphalt f-sbold ml-4">Filters</span>
            </button>
        </div>
        <div
            v-if="isFiltersShow"
            class="row-start pb-4 bb-grey"
        >
            <div
                v-if="hasAccessToAllLeadsAndJobs"
                class="lg-6 sm-12 column-start pr-4 pr-sm-0"
            >
                <div class="row-between">
                    <span class="f-12-black f-sbold s-align-4 pv-2">
                        Salesman
                    </span>
                    <button
                        v-if="salesman"
                        class="transparent-button s-align-4 f-12-grey pv-2"
                        @click="clearSalesmanFilter()"
                    >
                        <close-icon
                            class="i-red mr-4"
                            size="8"
                        />
                        Clear
                    </button>
                </div>
                <multiselect
                    v-model="salesman"
                    class="mb-4 multiselect-small"
                    label="Salesman"
                    placeholder="Select salesman"
                    track-by="first_name"
                    name="salesman"
                    data-name="salesman"
                    :options="sellers"
                    :option-height="104"
                    :show-labels="false"
                    :allow-empty="false"
                    :custom-label="customLabel"
                    @input="setSalesman"
                    @select="setSalesmanFilter"
                >
                    <template
                        slot="singleLabel"
                        slot-scope="props"
                    >
                        <template v-if="props.option.first_name !== undefined">
                            <user-box
                                :firstName="props.option.first_name"
                                :lastName="props.option.last_name"
                                :avatarUrl="props.option.avatar"
                                :border="false"
                                avatarSize="25"
                            />
                        </template>
                        <template v-else>
                            <p class="f-14-grey">Assign to...</p>
                        </template>
                    </template>
                    <template
                        slot="option"
                        slot-scope="props"
                    >
                        <user-box
                            :firstName="props.option.first_name"
                            :lastName="props.option.last_name"
                            :avatarUrl="props.option.avatar"
                            :border="false"
                        />
                    </template>
                </multiselect>
            </div>
            <div
                :class="[
                    'lg-6 sm-12 column-start',
                    { 'pl-4 pl-sm-0' : hasAccessToAllLeadsAndJobs }
                ]"
            >
                <div class="row-between">
                    <span class="f-12-black f-sbold s-align-4 pv-2">
                        Date period
                    </span>
                </div>
                <form-date-range-input-with-date-menu
                    :isFullWidth="true"
                    :name="reportTitle"
                    @input="setCustomDate"
                    @defaultDate="setDefaultDate"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { SettingsIcon, CloseIcon } from '@/icons';
    import { REPORT_COLUMNS } from '@/constants.js';
    import UserBox from '@/components/User/UserBox.vue';
    import Multiselect from 'vue-multiselect';
    import { FormDateRangeInputWithDateMenu } from '@/ui';
    import api from '@/headers.js';

    export default {
        name: 'ReportFilters',
        components: {
            SettingsIcon,
            CloseIcon,
            UserBox,
            Multiselect,
            FormDateRangeInputWithDateMenu
        },
        props: {
            reportTitle: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                isFiltersShow: false,
                sellersLoaded: false,
                sellers: [],
                salesman: null,
                filters: {
                    salesman: {
                        column_id: REPORT_COLUMNS.SALESMAN.id,
                        condition: "is",
                        match: "and",
                        value: null
                    },
                    customDate: {
                        start: null,
                        end: null
                    },
                    defaultDate: "current year"
                }
            }
        },
        computed: {
            hasAccessToAllLeadsAndJobs() {
                return this.$can(this.$permissions.VIEW_ALL_LEADS_AND_JOBS);
            },
            filterCount() {
                if (this.filters.salesman.value 
                    && !this.filters.customDate.start
                    && !this.filters.customDate.end
                    || !this.filters.defaultDate) {
                        return '1';
                }

                if (!this.filters.salesman.value 
                    && this.filters.customDate.start
                    && this.filters.customDate.end 
                    || this.filters.defaultDate) {
                    return '1';
                }

                if (this.filters.salesman.value 
                    && this.filters.customDate.start
                    && this.filters.customDate.end 
                    || this.filters.defaultDate) {
                        return '2';
                }
            }
        },
        methods: {
            showFilters() {
                this.isFiltersShow = !this.isFiltersShow;
                this.getSellers();
            },
            getSellers() {
                if (this.sellersLoaded || !this.hasAccessToAllLeadsAndJobs) { return }

                const params = {
                    needed: ['sellers'],
                    unassigned: 1
                };

                api.get('/handbooks?filter[is_active]=1', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        this.sellersLoaded = true;
                        this.sellers.push(...data.sellers);
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            customLabel({ first_name, last_name }) {
                return `${first_name} – ${last_name}`;
            },
            setCustomDate(range) {
                this.filters.customDate.start = range.start;
                this.filters.customDate.end = range.end;
                this.$emit('filterChanged', this.filters);
            },
            setDefaultDate(data) {
                this.filters.defaultDate = data;
                this.page = 1;
                this.$emit('filterChanged', this.filters);
            },
            clearSalesmanFilter() {
                this.filters.salesman.value = null;
                this.salesman = null;
                this.$emit('filterChanged', this.filters);
            },
            setSalesman() {
                this.$emit('filterChanged', this.filters);
            },
            setSalesmanFilter(val) {
                this.filters.salesman.value = val.fullname ? val.fullname : `${val.first_name} ${val.last_name}`;
            }
        }
    }
</script>