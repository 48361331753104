<template>
    <div class="form-builder-custom-fields">
        <loader 
            v-if="loading"
            size="small"
        />
        <div
            v-else 
            class="w-100"
            v-for="(section, sectionIndex) in customFields.sections"
            :key="sectionIndex"
        >
            <template v-if="section.fields.length">
                <div class="s-align-4 pb-4 ph-2">
                    <p class="f-16-darkgrey f-sbold">{{ section.name }}</p>
                </div>
                <div class="row-start">
                    <custom-field-address-section
                        v-if="section.type === CUSTOM_FIELDS_SECTION_TYPES.ADDRESS"
                        v-model="customFields.sections[sectionIndex].fields"
                    />
                    <form-builder-custom-fields-picture-section
                        v-else-if="section.type === CUSTOM_FIELDS_SECTION_TYPES.PICTURE"
                        :field='section.fields[0]'
                        :entityType="entityType"
                        @change="p => onPictureSectionChange(p, sectionIndex)"
                    />
                    <div
                        v-else
                        v-for="(field, fieldIndex) in section.fields"
                        :key="fieldIndex"
                        :class="[
                            section.type === CUSTOM_FIELDS_SECTION_TYPES.COMMON
                                ? 'lg-3 md-6 sm-12 mb-4 ph-2' : 'lg-12'
                        ]"
                    >
                        <tooltip
                            v-if="field.parameters.show_tooltip"
                            class="w-100"
                            direction="top"
                            mobileDescription="Field info"
                        >
                            <custom-field-input
                                :field='field'
                                v-model="customFields.sections[sectionIndex].fields[fieldIndex].values[0].value"
                            />
                            <form-input
                                v-if="showExtensionField(sectionIndex, fieldIndex)"
                                label="Extension"
                                :class="[{ 'form-input-danger' : errors.has(`extension-${fieldIndex}`) }]"
                            >
                                <input 
                                    type="text"
                                    :name="`extension-${fieldIndex}`" 
                                    class="form-input-field mt-2"
                                    placeholder="Extension"
                                    v-model="customFields.sections[sectionIndex].fields[fieldIndex].values[0].object_value.extension"
                                    v-validate="'integer'"
                                />
                            </form-input>
                            <template v-slot:content>
                                <p class="f-16-white">
                                    {{ field.parameters.tooltip_text }}
                                </p>
                            </template>
                        </tooltip>
                        <div
                            v-else
                            class="column-start w-100"
                        >
                            <custom-field-input
                                :field='field'
                                v-model="customFields.sections[sectionIndex].fields[fieldIndex].values[0].value"
                            />
                            <form-input
                                v-if="showExtensionField(sectionIndex, fieldIndex)"
                                label="Extension"
                                :class="[{ 'form-input-danger' : errors.has(`extension-${fieldIndex}`) }]"
                            >
                                <input 
                                    type="text"
                                    :name="`extension-${fieldIndex}`" 
                                    class="form-input-field mt-2"
                                    placeholder="Extension"
                                    v-model="customFields.sections[sectionIndex].fields[fieldIndex].values[0].object_value.extension"
                                    v-validate="'integer'"
                                />
                            </form-input>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { CustomFieldInput } from '@/components';
    import CustomFieldAddressSection from '@/components/LeadModules/CustomFieldAddressSection.vue';
    import FormBuilderCustomFieldsPictureSection from '@/components/LeadModules/FormBuilder/FormBuilderCustomFieldsPictureSection.vue';
    import { Tooltip, Loader, FormInput } from '@/ui';
    import api from '@/headers.js';
    import cloneDeep from 'lodash/cloneDeep';
    import { CUSTOM_FIELDS_SECTION_TYPES, ENTITY_TYPES, CUSTOM_FIELDS_TYPES } from '@/constants';

    export default {
        components: {
            CustomFieldInput,
            FormBuilderCustomFieldsPictureSection,
            Tooltip,
            FormInput,
            Loader,
            CustomFieldAddressSection
        },
        name: 'FormBuilderCustomFields',
        data() {
            return {
                loading: false,
                customFields: {},
                defaultCustomFields: {},
                CUSTOM_FIELDS_SECTION_TYPES: CUSTOM_FIELDS_SECTION_TYPES
            }
        },
        props: {
            layoutId: {
                type: Number
            },
            updateCustomFields: {
                type: Object
            },
            clear: {
                type: Boolean,
                default: false
            },
            entityType: {
                type: String,
                required: true
            }
        },
        inject: ['$validator'],
        watch: {
            customFields: {
                handler: function () {
                    this.$emit('changeFields', this.customFields);
                },
                deep: true
            },
            updateCustomFields(data) {
                this.customFields = data;
            },
            clear: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal) {
                        this.customFields = cloneDeep(this.defaultCustomFields);
                    }
                }
            }
        },
        computed: {
            checkCustomSectionPhoneExtension() {
                let sections = this.customFields.sections;
                return sections.map(el => el.fields.reduce((acc, el, i) => {
                    return el.parameters && el.parameters.extension ? [...acc, i] : acc;
                }, []));
            }
        },
        methods: {
            showExtensionField(sectionIndex, fieldIndex) {
                return this.checkCustomSectionPhoneExtension[sectionIndex].includes(fieldIndex);
            },
            getCustomFields() {
                const params = {
                    id: this.layoutId // layout id
                }

                this.loading = true;
                api.get('/layouts/detail', { params })
                    .then(response => {
                        let customFields = response.data.data;
                        let sections = customFields.sections;
                        let fields;

                        // convert JSON parameters to object && init value 
                        sections.forEach(function(section, sectionIndex) {
                            fields = section.fields;

                            fields.forEach(function(field, fieldIndex) {
                                // convert parameters from JSON to Object
                                this[fieldIndex].parameters = JSON.parse(field.parameters);

                                // add custom field empty value
                                switch (field.type.name) {
                                    case CUSTOM_FIELDS_TYPES.ADDRESS:
                                        this[fieldIndex].values = [{ 'object_value': {} }];
                                        break;
                                    case CUSTOM_FIELDS_TYPES.PHONE:
                                        this[fieldIndex].values = [{ 'object_value': {}, 'value': ''}];
                                        break;
                                    default:
                                        this[fieldIndex].values = [{ 'value': '' }];
                                        break;
                                }
                            }, fields)

                            this[sectionIndex].fields = fields;
                        }, sections);

                        customFields.sections = sections;
                        this.customFields = cloneDeep(customFields);
                        this.defaultCustomFields = customFields;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onPictureSectionChange(payload, index) {
                this.$set(this.customFields.sections[index].fields[0], 'object_value', payload);
            }
        },
        created() {
            this.layoutId && this.getCustomFields();
        }
    }
</script>