<template>
    <label class="priority-checkbox pointer relative">
        <slot name="default"></slot>
        <span 
            :style="`background-color: ${ !activeValue ? 
                hexToRgba('#a1a7af',0.3) : hexToRgba(priorityColor,0.1) }`
            " 
            class="row-start row-baseline checkbox-wrapper flex-no-wrap"
        >
            <span 
                class="mr-4 checkbox-mark" 
                :style="`background-color: ${ !activeValue ? '#a1a7af' : priorityColor }`"
            >
            </span>
            <span class="f-13-black f-sbold capitalize">
                {{ priority }}
            </span>
        </span>
    </label>
</template>

<script>

    export default {
        name: 'PriorityCheckbox',
        props: {
            priority: {
                type: String,
                default: ''
            },
            priorityColor: {
                type: String,
                default: ''
            },
            id: {
                type: [String, Number],
                default:''
            },
            filters: {
                type: Array,
                default: []
            }
        },
        methods: {
            hexToRgba(hex, alpha) {
                hex = hex.replace('#', '');
                let r = parseInt(hex.length == 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
                let g = parseInt(hex.length == 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
                let b = parseInt(hex.length == 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);

                return `rgba(${r},${g},${b},${alpha})`;
            }
        },
        computed: {
            activeValue() {
                let activeValue = this.filters

                return activeValue.some(el => el == this.id);
            }
        }
    }
</script>
