<template>
    <div class="letters-list w-100 pb-8">
        <div 
            :class="[
                'letter w-100 row-between',
                { 'messages-mock-loading' : loading}
            ]"
            v-for="(letter, index) in letters"
            :key="index"
        >
            <div 
                v-if="!loading" 
                class="lg-3 md-12 sm-12 s-align-1"
            >
                <div class="column-center h-100">
                    <sent-email-icon v-if="letter.is_outgoing"/>
                    <manual-email-icon v-else-if="letter.is_manual" />
                    <inbox-email-icon v-else />
                    <!-- <draft-email-icon v-if="letter.type == 'draft'"/> -->
                    <div 
                        class="letter-count s-align-5 mt-4" 
                        v-if="letter.conversationCountMessages > 1"
                    >
                        <span class="f-11-white">
                            {{ letter.conversationCountMessages }}
                        </span>
                    </div>
                </div>
                <div class="column-start pl-8">
                    <p
                        v-if="letter.from && letter.from.emailAddress"
                        class="f-14-darkgrey f-sbold break-all pb-2"
                    >
                        {{ letter.from.emailAddress.name }}
                    </p>
                    <p class="f-12-grey mb-2">
                        Status:
                        <span 
                            :class="[
                                'pv-1 pr-5 pl-25 f-bold letter-status',
                                letter.isDraft ? 'letter-status-draft f-11-grey' : 'letter-status-success f-11-green',
                                letter.is_manual ? 'letter-status-manual' : ''
                            ]"
                        >
                            <template v-if="!letter.is_manual">
                                {{ letter.isDraft ? 'Draft' : 'Received' }}
                            </template>
                            <template v-else>
                                Manual
                            </template>
                        </span>
                    </p>
                    <p
                        v-if="!letter.is_manual"
                        class="f-12-grey"
                    >
                        Source:
                        <span class="f-12-black">IMAP</span>
                    </p>
                    <p
                        class="f-12-grey"
                        v-if="letter.toRecipients"
                    >
                        To:
                        <span class="f-12-black">
                            {{ toRecipients(letter.toRecipients) }}
                        </span>
                    </p>
                </div>
            </div>
            <div 
                v-if="!loading" 
                class="column-start lg-7 md-12 sm-12 mt-md-1 mt-sm-1 ml-4"
            >
                <button 
                    @click="replyToLetter(letter)"
                    class="transparent-button break-all t-left f-15-blue pb-2"
                >
                    <template v-if="!letter.is_manual">
                        {{ letter.subject }}
                        <span v-if="letter.hasAttachments">
                            <attach-icon 
                                class="i-blue" 
                                size="13"
                            />
                        </span>
                    </template>
                    <template v-else>
                        {{ messagePreview(letter.bodyPreview) }}
                    </template>
                </button>
                <p class="f-12-grey preserve-whitespaces break-all">
                    {{letter.bodyPreview}}
                </p>
                <p
                    class="f-12-grey"
                    v-if="letter.from && letter.from.emailAddress"
                >
                    Sent by:
                    <span class="f-12-black" >
                        {{ letter.from.emailAddress.name }}
                    </span>
                </p>
            </div>
            <div 
                v-if="!loading" 
                class="column-between-end lg-1 md-12 sm-12 mt-sm-1"
            >
                <span class="f-12-grey pb-3">
                    {{ letter.receivedDateTime | date }}
                </span>
                <button 
                    v-if="!letter.is_manual && account_id"
                    class="transparent-button"
                    @click="replyToLetter(letter)"
                >
                    <reply-icon class="i-blue"/>
                    <span class="f-12-blue pl-2">Reply</span>
                </button>
                <context-menu
                    v-if="letter.is_manual && showContextMenu" 
                    unicId="manual-letter"
                    pointsWidth="10"
                    pointsHeight="15"
                    btnStyle="icon-dark"
                    :key="'manual-' + letter.manual_id"
                >
                    <button
                        class="s-align-4"
                        @click="editMessage(letter)"
                    >
                        <span class="icon-container mr-6">
                            <edit-icon/>
                        </span>
                        <span>Edit Message</span>
                    </button>
                    <button
                        class="s-align-4"
                        @click="deleteMessage(letter.manual_id)"
                    >
                        <span class="icon-container mr-6">
                            <trash-icon size="12"/>
                        </span>
                        <span>Delete Message</span>
                    </button>
                </context-menu>
            </div>
        </div>
    </div>
</template>

<script>
    import { 
        SentEmailIcon, InboxEmailIcon, DraftEmailIcon, 
        AttachIcon, ReplyIcon, PointsIcon, TrashIcon,
        EditIcon, ManualEmailIcon
    } from '@/icons';
    import ContextMenu from '@/components/ContextMenu.vue';
    import IMAPapi from '@/IMAPheaders';

    export default {
        name: 'LettersList',
        components: {
            SentEmailIcon,
            InboxEmailIcon,
            DraftEmailIcon,
            AttachIcon,
            ReplyIcon,
            PointsIcon,
            TrashIcon,
            EditIcon,
            ContextMenu,
            ManualEmailIcon
        },
        props: {
            letters: {
                type: Array,
                default: function() {
                    return []
                }
            },
            loading: {
                type: Boolean,
                default: false
            },
            account_id: {
                default: null
            }
        },
        computed: {
            showContextMenu() {
                return this.$route.name !== 'Overview';
            }
        },
        methods: {
            replyToLetter(letter) {
                this.$emit('reply', letter);
            },
            toRecipients(emails) {
                return emails.map(email => email.emailAddress.name).join(', ');
            },
            editMessage(letter) {
                let params = {
                    show: true,
                    modalType: 'message',
                    title: 'Edit email/message',
                    editedMessage: letter,
                    editMode: true
                }
                this.$store.commit('emailMessage/openModal', params);
            },
            deleteMessage(id) {
                let params = {
                    "message_id": id
                }

                IMAPapi.delete(`/messages/manual`, {params})
                    .then(response => {
                        this.notifySuccess('Message is delete');
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        let msgId = this.letters.findIndex(el => el.manual_id === id);
                        this.letters.splice(msgId, 1);
                    });
            },
            messagePreview(message) {
                return message.substring(0, 30);
            }
        }
    }
</script>