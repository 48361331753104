<template>
    <button 
        class="load-more-button s-align-5"
        :disabled="status"
    >
        <span 
            v-if="!status" 
            class="f-12-blue f-sbold"
        >
            Load More
        </span>
        <loading v-if="status"/>
    </button>
</template>

<script>
    import { Loading } from '@/icons';

    export default {
        name: 'LoadMore',
        props: {
            status: {
                type: Boolean,
                default: false
            }
        },
        components: {
            Loading
        }
    }
</script>