<template>
    <div class="w-100">
        <div class="subtitle">
            <h2>Milestone Updates</h2>
        </div>
        <div class="milestone-statistics">
            <div 
                class="milestone-statistics-info row-between"
                v-for="(item, index) in statistics"
                :key="index"
            >
                <div class="lg-6 md-12 sm-12 pb-2">
                    <span class="f-13-blue">Milestone
                        <span class="f-13-darkgrey">
                            {{ item.updates }}
                        </span>
                    </span>
                </div>
                <div class="lg-4 md-8 sm-12 pb-2">
                    <span class="f-13-grey">by
                        <span class="f-13-darkgrey">{{ item.author.fullname }}</span>
                    </span>
                </div>
                <div class="lg-2 md-4 sm-12 s-align-6">
                    <span class="f-13-grey">
                        {{ item.updated_at | createdAt }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MilestoneStatistics',
        props: {
            statistics: {
                type: Array,
                required: true
            }
        }
    }
</script>