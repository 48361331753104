var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.mainTag,{tag:"component",class:[
        'estimate-info row-start mb-2 relative', 
        { 
            'active' : _vm.active, 
            'pointer': _vm.$can(_vm.$permissions.EDIT_ESTIMATE) 
        }
    ],on:{"click":_vm.moveToEstimate}},[_vm._t("input"),_c('div',{staticClass:"info-name lg-7 md-11 sm-11"},[_c('div',{staticClass:"row-start"},[_c('div',{staticClass:"pr-20"},[(_vm.isManual)?_c('span',{staticClass:"edit-icon-wrapper s-align-5"},[_c('edit-icon',{staticClass:"i-violet",attrs:{"size":"14"}})],1):_c('span',{staticClass:"manual-icon-wrapper s-align-5"},[_c('template-icon',{staticClass:"inverted-template-icon",attrs:{"size":"18"}})],1)]),_c('div',{staticClass:"lg-11 nb-10 md-10 sm-9 column-start"},[_c('h2',{class:['column-start f-15-darkgrey f-sbold', !_vm.filesLength ? 'pb-4' : 'pb-1']},[_vm._v("\n                    "+_vm._s(_vm.estimate.name)+"\n                    "),(_vm.estimate.parent_id)?_c('span',{staticClass:"f-10-grey"},[_vm._v("\n                        ("+_vm._s(_vm.estimate.comment)+")\n                    ")]):_vm._e()]),_c('p',{staticClass:"row-start"},[_c('span',{staticClass:"f-12-grey pr-4 pb-1"},[_vm._v("\n                        Generated by:\n                        "),_c('span',{staticClass:"f-12-darkgrey pl-1"},[_vm._v(_vm._s(_vm.estimate.user.fullname))])]),_c('span',{staticClass:"f-12-grey pr-4"},[_vm._v("\n                        on\n                        "),_c('span',{staticClass:"f-12-darkgrey pl-1"},[_vm._v(_vm._s(_vm._f("monthDayYear")(_vm.estimate.created_at)))])]),(_vm.estimate.valid_until)?_c('span',{staticClass:"f-12-grey pr-4 pb-1"},[_vm._v("\n                        Valid until:\n                        "),_c('span',{staticClass:"f-12-darkgrey pl-1"},[_vm._v(_vm._s(_vm._f("date")(_vm.estimate.valid_until)))])]):_vm._e(),(!_vm.isManual)?_c('span',{staticClass:"f-12-grey pb-1"},[_vm._v("\n                        Status:\n                        "),_c('span',{class:[
                            'capitalize relative', ("preview-box-" + (_vm.defineEstimateStatus(_vm.estimate.status)))
                        ]},[_vm._v("\n                            "+_vm._s(_vm.defineEstimateStatus(_vm.estimate.status))+"\n                        ")])]):_vm._e()]),(_vm.filesLength)?_c('p',{staticClass:"w-100 s-align-4"},[_c('attach-icon',{staticClass:"i-blue mr-2",attrs:{"size":"12"}}),_c('span',{staticClass:"f-12-black"},[_vm._v(_vm._s((_vm.filesLength + " Attachments")))])],1):_vm._e()])])]),_c('div',{class:['info-description md-12 sm-12 column-start s-align-5', _vm.showBaseBidButton ? 'lg-2' : 'lg-4']},[_c('p',{staticClass:"f-14-black f-sbold"},[_vm._v(_vm._s(_vm._f("currency")(_vm.estimate.amount)))]),_c('p',{staticClass:"f-11-grey"},[_vm._v("Total price")]),(_vm.estimate.from_library_updated_date)?_c('p',{staticClass:"f-12-black pt-3"},[_vm._v("\n            Updated:\n            "),_c('span',{staticClass:"f-12-grey underline"},[_vm._v(_vm._s(_vm._f("timeAgo")(_vm.estimate.from_library_updated_date)))])]):_vm._e(),(_vm.estimate.status == _vm.estimate.all_statuses.outdated)?_c('p',{staticClass:"pt-2 s-align-4"},[_c('round-warning-icon',{staticClass:"i-orange"}),_c('span',{staticClass:"f-12-orange opacity-8 pl-2"},[_vm._v("Material substitute needed")])],1):_vm._e()]),(_vm.showBaseBidButton)?_c('div',{staticClass:"info-base-bid lg-2 md-12 sm-12 s-align-5 column-end"},[(!_vm.estimate.is_base_bid)?_c('button',{class:[
                'estimate-base-bid-button f-11-white f-sbold',
                { 'active' : _vm.estimate.is_base_bid }
            ],on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('setBaseBid', _vm.estimate)}}},[(_vm.estimate.is_base_bid)?_c('mark-icon',{staticClass:"i-white mr-2",attrs:{"size":"9"}}):_vm._e(),_vm._v("\n            "+_vm._s(_vm.estimate.is_base_bid ? 'Base bid' : 'Check as Base bid')+"\n        ")],1):_c('span',{class:[
                'estimate-base-bid-button s-align-5 f-11-white f-sbold cursor-default', 
                { 'active' : _vm.estimate.is_base_bid }
            ],on:{"click":function($event){$event.stopPropagation();}}},[(_vm.estimate.is_base_bid)?_c('mark-icon',{staticClass:"i-white mr-2",attrs:{"size":"9"}}):_vm._e(),_vm._v("\n            Base bid\n        ")],1),(_vm.estimate.is_base_bid)?_c('p',{staticClass:"f-12-grey mt-1"},[_vm._v("\n            Probability \n            "),_c('span',{staticClass:"ph-1 br-5 f-11-white f-sbold",style:({
                    backgroundColor: _vm.getProbabilityColor(_vm.estimate.probability)
                })},[_vm._v("\n                "+_vm._s(!_vm.estimate.probability ? 0 : _vm.estimate.probability)+" %\n            ")])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"lg-1 info-menu"},[_vm._t("context-menu")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }