var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-box column-center mt-20"},[_c('div',{staticClass:"row-between row-baseline mb-60"},[_c('p',{class:[
                'f-sbold',
                _vm.mScreen ? 'f-14-black' : 'f-24-black'
            ]},[_vm._v("\n            Choose components and trade for the order\n        ")]),_c('router-link',{staticClass:"add-button",attrs:{"to":{ name: _vm.allOrdersRoute }}},[_vm._v("\n            Cancel\n        ")])],1),_c('p',{staticClass:"f-16-black t-center mb-20"},[_vm._v("Select components for the order")]),_c('div',{staticClass:"row-center mb-60"},[_c('button',{class:[
                'add-medium-button mr-4 m-sm-0 mb-sm-1',
                { 'add-medium-button-active' : _vm.has_material && _vm.has_labor }
            ],on:{"click":function($event){_vm.has_material = 1; _vm.has_labor = 1}}},[_vm._v("\n            Materials & Labor\n        ")]),_c('button',{class:[
                'add-medium-button mr-4 m-sm-0 mb-sm-1',
                { 'add-medium-button-active' : _vm.has_material && !_vm.has_labor }
            ],on:{"click":function($event){_vm.has_material = 1; _vm.has_labor = 0}}},[_vm._v("\n            Materials only\n        ")]),_c('button',{class:[
                'add-medium-button mr-4 m-sm-0',
                { 'add-medium-button-active' : !_vm.has_material && _vm.has_labor }
            ],on:{"click":function($event){_vm.has_material = 0; _vm.has_labor = 1}}},[_vm._v("\n            Labor only\n        ")])]),_c('p',{staticClass:"16-black t-center mb-20"},[_vm._v("Select trade for the order")]),_c('div',{class:[ _vm.mScreen || _vm.tScreen ? 'w-100' : 'w-55' ]},[_c('multiselect',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:['mb-8',{ 'multiselect-danger' : _vm.errors.has('order_trade') }],attrs:{"options":_vm.trades,"track-by":"id","label":"name","showLabels":false,"max-height":160,"name":"order_trade","data-name":"order_trade","placeholder":"Trade"},on:{"select":_vm.setOrderName},model:{value:(_vm.selectedTrade),callback:function ($$v) {_vm.selectedTrade=$$v},expression:"selectedTrade"}}),_c('form-input',{class:['mb-8', { 'form-input-danger' : _vm.errors.has('order_name') }],attrs:{"label":"Section Name"}},[_c('input',{directives:[{name:"input-style",rawName:"v-input-style",value:(_vm.name),expression:"name"},{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"},{name:"validate",rawName:"v-validate",value:('required|min:3|max:255'),expression:"'required|min:3|max:255'"}],staticClass:"form-input-field",attrs:{"type":"text","name":"order_name","placeholder":"Order Name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }