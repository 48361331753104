<template>
    <svg 
	    viewBox="0 0 348.7772216796875 354.90740966796875"
        :width="size" 
        :height="size"
        class="print-icon"
    >
        <rect 
            id="ee-background" 
            x="0"
            y="0" 
            width="348.7772216796875" 
            height="354.90740966796875" 
            style="fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(7.840000629425049, 0, 0, 7.840000629425049, 6441.979088068008, -6784.12890625)">
            <g>
                <path d="M-782.3,879.5h-4.5v-12.4c0-0.8-0.7-1.5-1.5-1.5h-22.4c-0.8,0-1.5,0.7-1.5,1.5v12.4h-4.5c-2.6,0-4.8,2.1-4.8,4.8v19.1c0,0.8,0.7,1.5,1.5,1.5h7.8v4c0,0.8,0.7,1.5,1.5,1.5h22.4c0.8,0,1.5-0.7,1.5-1.5v-4h7.8c0.8,0,1.5-0.7,1.5-1.5v-19.1C-777.5,881.6-779.7,879.5-782.3,879.5z M-809.2,868.6h19.4v10.9h-19.4V868.6z M-789.8,907.4h-19.4v-9.1h19.4V907.4z M-780.5,901.8h-6.3v-5c0-0.8-0.7-1.5-1.5-1.5h-22.4c-0.8,0-1.5,0.7-1.5,1.5v5h-6.3v-17.6c0-1,0.8-1.8,1.8-1.8h34.4c1,0,1.8,0.8,1.8,1.8V901.8z"/>
                <path d="M-809.8,886.9h-3.7c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h3.7c0.8,0,1.5-0.7,1.5-1.5S-808.9,886.9-809.8,886.9z"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'PrintIcon',
        props: {
            size: {
                type: [Number, String],
                default: 15
            }
        }
    }
</script>