<template>
    <div class="close-ratio-report w-100 p-20">
        <report-filters
            reportTitle="Close ratio report"
            @filterChanged="getFilteringReport"
        />
        <loader
            v-if="loading"
            size="small"
        />
        <div
            v-else
            class="row-start row-baseline mt-40"
        >
            <div class="lg-5 sm-12 s-align-5">
                <div class="pie-chart relative">
                    <button
                        class="closing-ratio-chart transparent-button"
                        :style="{ 
                            'background': 
                            `conic-gradient(transparent 0%,
                                #88f1b5 0%,
                                #88f1b5 ${reportData.closingRatio}%,
                                transparent ${reportData.closingRatio}%
                            )`
                        }"
                        @click="showBreakdownTable"
                    >
                    </button>
                </div>
            </div>
            <div class="lg-7 sm-12 mt-sm-1 column-start">
                <div :class="['row-baseline', mScreen ? 'row-center' : 'row-start row-baseline']">
                    <span
                        v-if="!mScreen"
                        class="f-14-grey f-sbold mr-1"
                    >
                        Total Bid:
                    </span>
                    <span class="f-20-black f-sbold mr-1">
                        ${{ reportData.totalBids | numberWithCommas }}
                    </span>
                    <span class="f-13-grey f-normal">
                        ({{ reportData.countBids }} bids)
                    </span>
                </div>
                <div :class="['row-baseline', mScreen ? 'row-center' : 'row-start row-baseline']">
                    <span class="f-14-grey f-sbold mr-1">Total Contract:</span>
                    <span class="f-16-black f-sbold mr-1">
                        ${{ reportData.totalContract | numberWithCommas }}
                    </span>
                    <span class="f-13-grey">
                        ({{ reportData.countContract }} bids)
                    </span>
                </div>
                <div :class="['row-baseline', mScreen ? 'row-center' : 'row-start row-baseline']">
                    <span class="f-14-grey f-sbold">Closing Ratio:&nbsp;</span>
                    <span class="f-16-black f-sbold mr-1">{{ reportData.closingRatio }}%</span>
                    <span class="f-13-black">({{reportData.bidContract}}% Bid - Contract)</span>
                </div>
            </div>
            <div class="lg-12 s-align-5 mt-40">
                <div class="total-label total-label-contract mr-3">
                    <span class="f-11-black">
                        Contract
                    </span>
                    <span class="f-11-grey">
                        {{ reportData.countContract }}
                        Bids
                    </span>
                </div>
                <div class="total-label total-label-dead">
                    <span class="f-11-black">
                        Dead
                    </span>
                    <span class="f-11-grey">
                        {{ reportData.countDead }}
                        Bids
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { REPORT_COLUMNS, REPORT_TYPES, ENTITY_LEAD_TYPES } from '@/constants.js';
    import { Loader } from '@/ui';
    import ReportFilters from './ReportFilters';
    import moment from 'moment/src/moment';
    import api from '@/headers.js';
    import { manipulationIndexedDB } from './dashboard-mixins/saveToIndexedDB.js';

    const INDEXED_DB_NAME = 'DashboardCloseRatioReport';
    const INDEXED_DB_STORE_NAME = 'CloseRatioReport';

    export default {
        name: 'CloseRatioReport',
        components: {
            Loader,
            ReportFilters
        },
        data() {
            return {
                reportData: {},
                loading: false,
                filters: {
                    salesman: {
                        column_id: REPORT_COLUMNS.SALESMAN.id,
                        condition: "is",
                        match: "and",
                        value: null
                    },
                    customDate: {
                        start: null,
                        end: null
                    },
                    defaultDate: "current year"
                },
            }
        },
        mixins: [manipulationIndexedDB],
        methods: {
            getReport() {
                let params = {
                    "title": "Close Ratio Report",    
                    "description": "",    
                    "type_id": REPORT_TYPES.SUMMARY_REPORT,   
                    "group1": null,    
                    "sort1": "asc",    
                    "group2": null,    
                    "sort2": "asc",    
                    "group3": null,    
                    "sort3": "asc",    
                    "stdDateFilterField": "created_at",     
                    "startDate": null,    
                    "endDate": null, 
                    "titleOverride": {},    
                    "selectedColumns": [REPORT_COLUMNS.BASE_BID.id],
                    "totals": [],    
                    "modules": [
                        ENTITY_LEAD_TYPES.RESIDENTIAL,
                        ENTITY_LEAD_TYPES.COMMERCIAL
                    ],    
                    "patternVal": "1",    
                    "filters": [],    
                    "showTotalPages": true,    
                    "showLabels": false,    
                    "sorts": [], 
                    "callback": "closeRatio"
                }

                if (this.filters.salesman.value) {
                    params.filters.push(this.filters.salesman);

                    // if selected 'Unassigned" option
                    // if passing null, in response we have 500 error
                    // Need change logic for 'Unassigned" option
                    // if (!this.salesman.id) {
                    //     params.filters[0].value = null;
                    // }
                }

                if (this.filters.defaultDate != 'custom') {
                    params.stdDateFilter = this.filters.defaultDate
                } else {
                    params.stdDateFilter = this.filters.defaultDate;
                    params.startDate = moment(this.filters.customDate.start).format('YYYY-MM-DD');
                    params.endDate = moment(this.filters.customDate.end).format('YYYY-MM-DD');
                }

                this.loading = true;
                api.post(`/reports/build`, params)
                    .then(response => response.data.data)
                    .then(data => {
                        this.reportData = data;
                        this.addReportToIndexedDB(INDEXED_DB_NAME, INDEXED_DB_STORE_NAME);
                    })
                    .catch(error => this.notifyRequestErrors(error))
                    .finally(() => this.loading = false);
            },
            showBreakdownTable() {
                let reportData = {
                    title: 'Closing ratio',
                    description: '',
                    type_id: REPORT_TYPES.SUMMARY_REPORT,
                    modules: [
                        ENTITY_LEAD_TYPES.RESIDENTIAL,
                        ENTITY_LEAD_TYPES.COMMERCIAL
                    ], 
                    group1: null,
                    sort1: 'asc',
                    group2: null,
                    sort2: 'asc',
                    group3: null,
                    sort3: 'asc',
                    stdDateFilterField: 'created_at', 
                    stdDateFilter: this.filters.defaultDate,
                    selectedColumns: [
                        REPORT_COLUMNS.JOB_ID.id,
                        REPORT_COLUMNS.JOB_NAME.id,
                        REPORT_COLUMNS.RESPONSIBLE_BILLING_PARTY.id,
                        REPORT_COLUMNS.PRIMARY_CONTACT.id,
                        REPORT_COLUMNS.BID_DATE.id,
                        REPORT_COLUMNS.BID_DUE_DATE.id,
                        REPORT_COLUMNS.MILESTONE.id,
                        REPORT_COLUMNS.BASE_BID.id,
                        REPORT_COLUMNS.SALESMAN.id,
                        REPORT_COLUMNS.CREATED_TIME.id,
                        REPORT_COLUMNS.ADDRESS.id
                    ],
                    titleOverride: {
                        [REPORT_COLUMNS.JOB_NAME.id]: 'Property name',
                        [REPORT_COLUMNS.MILESTONE.id]: 'Status',
                        [REPORT_COLUMNS.CREATED_TIME.id]: 'Lead Created Date',
                        [REPORT_COLUMNS.BID_DATE.id]: 'Bid Sent',
                    },
                    totals: [],
                    startDate: this.filters.customDate.start,
                    endDate: this.filters.customDate.end,
                    is_custom: 1,
                    notEditable: true,
                    showSystemColumns: true
                }

                if (this.filters.salesman.value) {
                    reportData.filters = [this.filters.salesman];
                    reportData.patternVal = '1';
                }
                
                this.$store.commit('reports/STORE_REPORT_DATA', reportData);
                this.$router.push({ name: 'ReportOverview' });
            },
            getFilteringReport(val) {
                this.filters = val;
                this.getReportsFromIndexedDB(
                    INDEXED_DB_NAME,
                    INDEXED_DB_STORE_NAME
                );
            }
        },
        created() {
            this.createIndexedDBSchema(
                INDEXED_DB_NAME,
                INDEXED_DB_STORE_NAME
            );
            this.getReport()
        }
    }
</script>