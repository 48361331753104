var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'users-select column-start',
        ("users-select-" + _vm.direction)
    ]},[_c('div',{staticClass:"row-between mb-6"},[_c('span',{staticClass:"f-16-black"},[_vm._v("Assign to")]),_c('button',{staticClass:"icon-light",on:{"click":function($event){return _vm.$emit('close')}}},[_c('close-icon',{attrs:{"size":"15"}})],1)]),_c('div',{staticClass:"users-search row-start row-baseline mb-8 p-7"},[_c('search-icon',{staticClass:"i-grey mr-3"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.needToFind),expression:"needToFind"}],attrs:{"type":"text","name":"search_members","id":"search_members","placeholder":"Search members"},domProps:{"value":(_vm.needToFind)},on:{"input":function($event){if($event.target.composing){ return; }_vm.needToFind=$event.target.value}}})],1),_vm._t("default"),_c('p',{staticClass:"f-13-black f-sbold mb-1"},[_vm._v("Members")]),(_vm.isLoading)?_c('Loader',{attrs:{"size":"small"}}):_c('perfect-scrollbar',{staticClass:"ps-grey column-start user-list w-100 pr-2"},_vm._l((_vm.users || _vm.allUsers),function(user,index){return _c('button',{key:index,class:[
                'user row-between row-baseline p-2 bb-grey',
                _vm.checkIfUserSelected(user.id)  ? 'selected' : '',
                _vm.checkUserVisibility(user) ? 'hide' : ''
            ],attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('update', user.id)}}},[_c('user-box',{key:index,attrs:{"firstName":user.first_name,"lastName":user.last_name,"avatarUrl":user.thumbnail}}),(_vm.checkIfUserSelected(user.id) && _vm.icon === 'mark-icon')?[_c('mark-icon',{staticClass:"i-green",attrs:{"size":"10"}}),_c('close-icon',{staticClass:"i-red",attrs:{"size":"8"}})]:_vm._e(),(_vm.icon === 'bell-icon')?_c('bell-icon',{attrs:{"size":"15"}}):_vm._e()],2)}),0)],2)}
var staticRenderFns = []

export { render, staticRenderFns }