import api from '@/api';

export default {
    namespaced: true,
    state: {
        zip: '',
        outOfArea: false,
        requestIsGoing: false
    },
    getters: {
        outOfArea: state => state.outOfArea,
        requestIsGoing: state => state.requestIsGoing,
    },
    mutations: {
        SET_ZIP_CODE(state, zip) {
            state.zip = zip;
        },
        SET_OUT_OF_AREA(state, status) {
            state.outOfArea = status;
        },
        SET_REQUEST_GOING(state, status) {
            state.requestIsGoing = status;
        }
    },
    actions: {
        checkZipCodeArea({ commit, state }, zipCode) {
            if (state.requestIsGoing === true) {
                return false;
            }
            commit('SET_REQUEST_GOING', true)
            commit('SET_ZIP_CODE', zipCode)

            return api.checkZipCodeArea({ zip: zipCode })
                .then(() => {
                    commit('SET_OUT_OF_AREA', false);
                    commit('SET_REQUEST_GOING', false)
                    return true;
                })
                .catch(e => {
                    commit('SET_OUT_OF_AREA', true);
                    commit('SET_REQUEST_GOING', false)
                    return false;
                });
        }
    }
}
