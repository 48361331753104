<template>
    <svg 
        viewBox="-247 483.9 17 18"
        :width="width" 
        :height="height"
        class="add-person-icon"
    >
        <path d="M-230,498.4c0,0.4-0.4,0.8-0.8,0.8h-2v1.9c0,0.4-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8v-1.9h-2c-0.5,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8h2v-1.9c0-0.4,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v1.9h2C-230.4,497.6-230,498-230,498.4z"/>
        <!-- <path d="M-239.3,491.4c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1c-1.7,0-3.1,1.4-3.1,3.1C-242.4,490-241,491.4-239.3,491.4z"/> -->
        <path d="M-243.7,488.3c0,2.4,2,4.4,4.5,4.4c2.5,0,4.5-2,4.5-4.4c0-2.4-2-4.4-4.5-4.4C-241.7,483.9-243.7,485.9-243.7,488.3zM-239.3,485.2c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1c-1.7,0-3.1-1.4-3.1-3.1C-242.4,486.6-241,485.2-239.3,485.2z"/>
        <path d="M-241.2,495.6h3.8c0.1,0,0.2,0,0.3,0c0.4-0.4,1-0.8,1.5-1c-0.6-0.2-1.2-0.3-1.8-0.3h-3.8c-3.2,0-5.8,2.6-5.8,5.7h0.7h0.7C-245.7,497.6-243.7,495.6-241.2,495.6z"/>
        </svg>
</template>

<script>
export default {
    name: 'AddPersonIcon',
    props: {
        width: {
            type: [Number, String],
            default: 16
        },
        height: {
            type: [Number, String],
            default: 18
        }
    }
}
</script>
