import {GET_AUDIT_LISTS, SET_AUDIT_LISTS, SET_LOADING, SET_LOADING_PAGINATION} from "../actions/audit";
import api from "../../api";

export default {
    namespaced: true,
    state: {
        auditList: null,
        isLoading: false,
        isLoadingPagination: false,
        paginationData: null,
    },
    getters: {
        getIsLoading(state){
            return state.isLoading;
        },
        getIsLoadingPagination(state){
            return state.isLoadingPagination;
        },
        getAuditList(state) {
            return state.auditList;
        },
        getPaginationData(state) {
            return state.paginationData;
        }
    },
    mutations: {
        [SET_AUDIT_LISTS](state, {data, isLoadingPagination}){
            const items = data.items || [];
            if (isLoadingPagination){
                state.auditList = {...state.auditList, ...items};
            }else{
                state.auditList = items;
            }
            state.paginationData = {
                current_page: data.current_page,
                last_page: data.last_page,
            }
        },
        [SET_LOADING](state, payload) {
            state.isLoading = payload;
        },
        [SET_LOADING_PAGINATION](state, payload) {
            state.isLoadingPagination = payload;
        }
    },
    actions: {
        async [GET_AUDIT_LISTS]({commit}, {query, isLoadingPagination = false}) {
            const loadingCommit = isLoadingPagination ? SET_LOADING_PAGINATION : SET_LOADING;
            try {
                commit(loadingCommit, true);
                if (!query.page) query.page = 1;
                const res = await api.Audit.getData({params: query});
                const data = res.data.data;
                commit(SET_AUDIT_LISTS, {data, isLoadingPagination});
            } catch (error) {
                throw error;
            } finally {
                commit(loadingCommit, false)
            }
        }
    }
}
