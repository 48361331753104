<template>
    <div class="tab-panel">
        <div class="tab-panel__container">
            <nav
                v-if="showTabs"
                class="tab-panel__item tab-panel__item-header tab-panel__item-header--tab-menu tab-menu"
            >
                <ul class="tab-menu__list">
                    <li
                        class="tab-menu__tab tab-link"
                        v-for="(tab, index) in tabs"
                        :key="index"
                    >
                        <router-link
                            :to="{name: tab.name}"
                            class="tab-link__link"
                            active-class="tab-link__link--active"
                            :key="tab.name"
                        >
                            {{ tab.label }}
                        </router-link>
                    </li>
                </ul>
            </nav>
            <slot/>
        </div>
    </div>
</template>

<script>
import { ENTITY_TYPES } from '@/constants';

export default {
    name: "TabPanel",
    props: {
        entityType: {
            type: String,
            required: true
        },
        showTabs: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        tabs() {
            switch (this.entityType) {
                case ENTITY_TYPES.RESIDENTIAL_LEAD:
                    return [
                        {label: 'Messages', name: 'ResidentialMessages'},
                        {label: 'Calls',    name: 'ResidentialCalls'},
                    ];
                case ENTITY_TYPES.COMMERCIAL_LEAD:
                    return [
                        {label: 'Messages', name: 'CommercialLeadMessages'},
                        {label: 'Calls',    name: 'CommercialLeadCalls'},
                    ];
                case ENTITY_TYPES.COMMERCIAL_JOB:
                    return [
                        {label: 'Messages', name: 'CommercialJobMessages'},
                        {label: 'Calls',    name: 'CommercialJobCalls'},
                    ];
                case ENTITY_TYPES.OPPORTUNITY:
                    return [
                        {label: 'Messages', name: 'OpportunityMessages'},
                        {label: 'Calls',    name: 'OpportunityCalls'},
                    ];
                case ENTITY_TYPES.WARRANTY_JOB:
                    return [
                        {label: 'Messages', name: 'WarrantyJobMessages'}
                    ];
            }
        },
    },
}
</script>
