<template>
    <svg 
        viewBox="-247 483.9 17 17"
        :height="size" 
        :width="size"
        class="folder-add-icon"
    >
        <path d="M-245.6,498.8h7.1c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7h-3.5v-7.1h10.6v5c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-5c0-0.8-0.6-1.4-1.4-1.4h-0.7v-1.4c0-0.8-0.6-1.4-1.4-1.4h-4.6l-1.2-1.8c-0.1-0.2-0.4-0.3-0.6-0.3h-5.7c-0.8,0-1.4,0.6-1.4,1.4v12C-247,498.1-246.4,498.8-245.6,498.8z M-245.6,485.3h5.3l1.2,1.8c0.1,0.2,0.4,0.3,0.6,0.3h5v1.4h-8.5c-0.8,0-1.4,0.6-1.4,1.4v7.1h-2.1V485.3z"/>
        <path d="M-233.5,495.2c-0.4,0-0.7,0.3-0.7,0.7v1.4h-1.4c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h1.4v1.4c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-1.4h1.4c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7h-1.4v-1.4C-232.8,495.5-233.2,495.2-233.5,495.2z"/>
    </svg>
</template>

<script>
    export default {
        name: 'FolderAddIcon',
        props: {
            size: {
                type: [Number, String],
                default: 17
            }
        }
    }
</script>