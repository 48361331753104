<template>
  <div>
    <full-modal @close="$emit('close')">
      <div slot="title">Property settings</div>
      <div slot="body">
        <div v-if="loading">
          <loader size="normal"/>
        </div>
        <template v-else>
          <p class="f-14-asphalt f-sbold pb-4">Information</p>
          <div class="w-100 pb-30">
            <form-input
                label="Property name"
                :class="[{ 'form-input-danger' : errors.has('property_name') }]"
            >
              <input
                  type="text"
                  name="property_name"
                  class="form-input-field"
                  placeholder="Property name"
                  v-input-style="propertyData.property_name"
                  v-model="propertyData.property_name"
                  v-validate="{ required: true, regex: titleRegex }"
                  id="property_name"
              />
            </form-input>
            <div
                class="w-100"
                v-if="!editLeadProperty"
            >
              <p class="f-13-asphalt pb-4 pt-8">Designation:</p>
              <div class="s-align-4 w-100">
                <radio-switch
                    v-for="(item, index) in designationOptions"
                    :key="`designation-${index}`"
                    :label="item"
                    color="blue"
                    :class="[{ 'radio-switch-error' : errors.has('designation') }]"
                >
                  <input
                      type="radio"
                      name="designation"
                      v-model="propertyData.designation"
                      :value="item"
                      v-validate="'required'"
                  />
                </radio-switch>
              </div>
              <p class="f-13-asphalt pv-4">Division:</p>
              <div class="s-align-4 w-100">
                <radio-switch
                    v-for="(item, index) in divisionOptions"
                    :key="`division-${index}`"
                    :label="item"
                    color="blue"
                    :class="[{ 'radio-switch-error' : errors.has('division') }]"
                >
                  <input
                      type="radio"
                      name="division"
                      v-model="propertyData.division"
                      :value="item"
                      v-validate="'required'"
                  />
                </radio-switch>
              </div>
              <p class="f-13-asphalt pv-4">Allow to be selected as a location?</p>
              <div class="s-align-4 w-100">
                <radio-switch
                    v-for="(item, index) in isSelectedLocationOptions"
                    :key="`is-selected-${index}`"
                    :label="item.title"
                    color="blue"
                    :class="[{ 'radio-switch-error' : errors.has('is-selected-location') }]"
                >
                  <input
                      type="radio"
                      name="is-selected-location"
                      v-model="propertyData.is_selected_location"
                      :value="item.value"
                      v-validate="'required'"
                  />
                </radio-switch>
              </div>
            </div>
          </div>
          <p class="f-14-asphalt f-sbold pb-4">Address</p>
          <div class="w-100 pb-30">
            <address-box
                type="address"
                :key="'main-address'"
                unicId="address"
                :existing="propertyData.addresses.main[0]"
                :apartment="true"
                @address="getMainAddress"
            >
              <div
                  class="w-100 mb-4"
                  slot="apartment"
              >
                <form-input
                    label="Unit"
                    :class="[{ 'form-input-danger' : errors.has('apartment') }]"
                >
                  <input
                      type="text"
                      name="apartment"
                      id="apartment"
                      class="form-input-field"
                      placeholder="Unit"
                      v-input-style="propertyData.addresses.main[0].apartment"
                      v-model="propertyData.addresses.main[0].apartment"
                      v-validate="{ max: 50, regex: titleRegex }"
                  />
                </form-input>
              </div>
            </address-box>
          </div>
          <div>
            <div class="row-between pb-4">
              <p class="f-14-asphalt f-sbold">Billing address</p>
              <checkbox-button
                  color="dark-blue"
                  label="Use the same address"
                  class="f-11-darkgrey f-sbold no-wrap"
              >
                <input
                    type="checkbox"
                    name="add_billing_address"
                    v-model="useMainPropertyAddress"
                    @change="setPropertyBillingAddress($event)"
                />
              </checkbox-button>
            </div>
            <div class="w-100 pb-30">
              <address-box
                  type="address"
                  unicId="billing"
                  :key="'billing-address'"
                  :existing="propertyData.addresses.billing[0]"
                  :apartment="false"
                  :validate="'required'"
                  @address="getBillingAddress"
              />
            </div>
          </div>
          <div
              class="w-100 pb-30"
              v-if="!mailingAddressIsAdded && !editLeadProperty"
          >
            <button
                class="transparent-button"
                @click="mailingAddressIsAdded = true"
            >
              <span class="f-12-blue">+ Add Mailing Address</span>
            </button>
          </div>
          <div
              class="w-100"
              v-if="mailingAddressIsAdded"
          >
            <div class="row-between">
              <p class="f-14-asphalt f-sbold pb-4">Mailing Address</p>
              <button
                  class="icon-light"
                  @click="removeMailingAddress"
              >
                <trash-icon/>
              </button>
            </div>
            <div class="w-100 pb-30">
              <address-box
                  type="address"
                  :key="'mailing-address'"
                  unicId="mailing"
                  :existing="propertyData.addresses.mailing[0]"
                  @address="getMailingAddress"
              />
            </div>
          </div>
          <p class="f-14-asphalt f-sbold pb-4">Change Customer</p>
          <multiselect
              :options="customers"
              track-by="name"
              :value="customer"
              label="name"
              placeholder="Change Customer"
              name="customer_id"
              data-name="customer_id"
              :multiple="false"
              :searchable="true"
              :showLabels="false"
              class="pb-30 f-normal"
              @select="selectCustomer($event)"
          />
          <p class="f-14-black f-sbold">Want to delete this property?</p>
          <div
              v-if="propertyData.jobs_count || propertyData.leads_count || propertyData.unit_jobs_count"
              class="f-13-grey f-sbold mt-4"
          >
            <warning-icon
                size="13"
                class="i-red mr-1"
            />
            You cannot delete Property that has active
            {{ propertyData.jobs_count || propertyData.unit_jobs_count ? 'Jobs' : '' }}
            {{ (propertyData.jobs_count || propertyData.unit_jobs_count) && propertyData.leads_count ? 'and' : '' }}
            {{ propertyData.leads_count ? 'Leads' : '' }}.
            Move active
            {{ propertyData.jobs_count || propertyData.unit_jobs_count ? 'Jobs' : '' }}
            {{ (propertyData.jobs_count || propertyData.unit_jobs_count) && propertyData.leads_count ? 'and' : '' }}
            {{ propertyData.leads_count ? 'Leads' : '' }}
            to another property or delete them.
          </div>
          <template v-else>
            <p class="f-13-grey">
              By clicking Delete property you completely erase information on the property.
            </p>
            <template v-if="isDeleteConfirmShow">
              <form-input-textarea
                  label="Reason"
                  v-input-counter="500"
              >
                        <textarea
                            :class="[
                                'form-input-field',
                                { 'form-input-textarea-danger' : errors.has('deleting_reason') }
                            ]"
                            rows="1"
                            name="deleting_reason"
                            id="deleting_reason"
                            placeholder="Reason"
                            v-model="deletingReason"
                            v-input-style="deletingReason"
                            maxlength="500"
                            v-validate="'required|min:3|max:500'"
                            v-resize-textarea
                        />
              </form-input-textarea>
              <div class="row-end">
                <button
                    class="add-button mr-4"
                    @click="isDeleteConfirmShow = false"
                >
                  Cancel
                </button>
                <button
                    class="red-button"
                    @click="deleteProperty"
                    :disabled="sending"
                >
                  Yes, delete
                </button>
              </div>
            </template>
            <button
                v-else
                class="transparent-button s-align-4 mt-4"
                @click="showDeleteConfirm"
            >
              <trash-icon class="i-red mr-1"/>
              <span class="f-13-red f-sbold">Delete property</span>
            </button>
          </template>
        </template>
      </div>
      <div slot="footer">
        <button
            class="add-button"
            @click="$emit('close')"
        >
          Cancel
        </button>
        <button
            class="primary-button"
            @click="save"
            :disabled="sending"
        >
          Save
        </button>
      </div>
    </full-modal>
    <zip-code-modal
        v-if="outOfArea"
        @continue="updateProperty"
        @cancel="cancel"
        @close="cancel"
    />
  </div>
</template>

<script>
    import { FullModal, FormInput, RadioSwitch, FormInputTextarea, CheckboxButton } from '@/ui';
    import { validationErrorNotification } from '@/helpers';
    import {
        DESIGNATION_OPTIONS, DIVISION_OPTIONS, IS_SELECTED_LOCATION, COMMERCIAL_PHONE_TYPES, ADDRESS_TYPES,
        NOT_ALLOWED_CHARACTERS
    } from '@/constants';
    import { TrashIcon, ArrowIcon, WarningIcon } from '@/icons';
    import cloneDeep from 'lodash/cloneDeep';
    import AddressBox from '@/components/AddressBox.vue';
    import api from '@/headers.js';
    import Multiselect from "vue-multiselect";
    import { Loader } from '@/ui';
    import {mapActions, mapGetters} from "vuex";
    import ZipCodeModal from "./ZipCodeModal";

    export default {
        name: 'EditCommercialPropertySectionModal',
        components: {
            Loader,
            FullModal,
            FormInput,
            RadioSwitch,
            FormInputTextarea,
            CheckboxButton,
            AddressBox,
            TrashIcon,
            ArrowIcon,
            WarningIcon,
            Multiselect,
            ZipCodeModal,
        },
        props: {
            property: {
                type: Object,
                required: true
            },
            // for editing property inside a commercial lead (not a job)
            editLeadProperty: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                propertyData: {},
                designationOptions: DESIGNATION_OPTIONS,
                divisionOptions: DIVISION_OPTIONS,
                isSelectedLocationOptions: IS_SELECTED_LOCATION,
                mailingAddressIsAdded: false,
                phoneTypes: COMMERCIAL_PHONE_TYPES,
                sending: false,
                isDeleteConfirmShow: false,
                deletingReason: '',
                titleRegex: NOT_ALLOWED_CHARACTERS,
                customers: [],
                loading: false,
                isChangedCustomer: false,
                useMainPropertyAddress: false
            }
        },
        computed: {
            ...mapGetters('zipCodes', [
              'outOfArea'
            ]),
            customer() {
                return this.customers.find(item => this.propertyData.customer_id === item.id);
            }
        },
        methods: {
            ...mapActions('zipCodes', [
              'checkZipCodeArea'
            ]),
            selectCustomer(item) {
                this.propertyData.customer_id = item.id;
                this.isChangedCustomer = true;
            },
            getCustomers() {
                this.loading = true;
                api.get('/commercial/customers?perPage=-1&onlyNames=1')
                    .then(response => response.data)
                    .then(data => {
                        this.customers = data.data;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => this.loading = false);
            },
            getMainAddress(data) {
                this.propertyData.addresses.main[0] = data;
            },
            getMailingAddress(data) {
                this.propertyData.addresses.mailing[0] = data;
            },
            getBillingAddress(data) {
                this.propertyData.addresses.billing[0] = data;
            },
            setPropertyBillingAddress(event) {
                if (event.target.checked) {
                    this.propertyData.addresses.billing[0] = cloneDeep(this.propertyData.addresses.main[0]);
                } else {
                    this.propertyData.addresses.billing[0] = {
                        address: '',
                        street: '',
                        city: '',
                        state: '',
                        zip: '',
                        lat: null,
                        lng: null,
                    };
                }
            },
            removeMailingAddress() {
                this.mailingAddressIsAdded = false;

                [this.property, this.mailingAddress].map((obj) => {
                    for (let field in obj) {
                        if (field.includes('_mailing')) {
                            obj[field] = null;
                        }
                    };
                });
            },
            cancel() {
              this.$store.commit('zipCodes/SET_OUT_OF_AREA', false);
            },
            updateProperty() {
              this.cancel();
              this.sending = true;

              let params = {
                property_name: this.propertyData.property_name,
                customer_id: this.propertyData.customer_id
              };

              params.addresses = [{
                ...this.propertyData.addresses.main[0],
                type: ADDRESS_TYPES.MAIN
              }];
              delete params.addresses[0].id;

              if (this.mailingAddressIsAdded) {
                params.addresses.push({
                  ...this.propertyData.addresses.mailing[0],
                  type: ADDRESS_TYPES.MAILING
                });
                delete params.addresses[1].id;
              }

              if (Object.keys(this.propertyData.addresses.billing[0]).length) {
                params.addresses.push({
                  ...this.propertyData.addresses.billing[0],
                  type: ADDRESS_TYPES.BILLING
                });
                delete params.addresses[params.addresses.length - 1].id;
              }

              if (!this.editLeadProperty) {
                const fullParams = {
                  designation: this.propertyData.designation,
                  division: this.propertyData.division,
                  is_selected_location: this.propertyData.is_selected_location
                }

                Object.assign(params, fullParams);
              }

              api.put(`/commercial/properties/${this.propertyData.id}`, params)
                  .then((response) => {
                    this.notifySuccess('Property is updated!');
                    this.$emit('update', {
                      ...response.data.data,
                      jobs: this.property.jobs,
                      leads: this.property.leads,
                      units: this.property.units,
                      jobs_count: this.property.jobs_count,
                      leads_count: this.property.leads_count,
                      unit_jobs_count: this.property.unit_jobs_count,
                      units_count: this.property.units_count
                    });

                    if(this.isChangedCustomer) {
                      this.$router.push({
                        name: 'CommercialDetails',
                        params: {
                          customer_id: this.propertyData.customer_id,
                          property_id: this.propertyData.id
                        }
                      });
                    }
                  })
                  .catch(error => {
                    this.notifyRequestErrors(error);
                  })
                  .finally(() => {
                    this.sending = false;
                  });
            },
            save() {
                this.$children.forEach(vm => {
                    vm.$validator.validateAll();
                });

                this.$validator.validateAll().then(result => {
                    if (this.$validator.errors.items.length) {
                        const context = this;
                        validationErrorNotification(this.$validator, context);

                        let domRect = document.getElementById(this.$validator.errors.items[0].field);
                        if (domRect != null || domRect != undefined) {
                            this.$el.querySelector('.full-modal-content').scrollTo({
                                top: domRect.offsetTop - 100,
                                behavior: "smooth"
                            })
                        }

                        return;
                    }
                  this.checkZipCodeArea(this.propertyData.addresses.main[0].zip).then(res => {
                    if (res) {
                      this.updateProperty();
                    }
                  })
                });
            },
            showDeleteConfirm() {
                this.isDeleteConfirmShow = true;
                this.$nextTick(() => {
                    const input = document.getElementById('deleting_reason');
                    // scroll to textarea
                    input.scrollIntoView({ behavior: 'smooth' });
                    // focus on textarea
                    input.focus({ preventScroll: true });
                });
            },
            deleteProperty() {
                this.$validator.validate('deleting_reason')
                    .then(result => {
                        if (result) {
                            this.sending = true;
                            api.delete(`/commercial/properties/${this.propertyData.id}?comment=${this.deletingReason}`)
                                .then(() => {
                                    this.notifySuccess('Property deleted.');
                                    this.$emit('delete', this.propertyData.id);
                                })
                                .catch((error) => { this.notifyRequestErrors(error); })
                                .finally(() => { this.sending = false });
                        }
                    });
            }
        },
        created() {
            this.propertyData = cloneDeep(this.property);
            this.getCustomers();

            if (this.propertyData.addresses.mailing.length) {
                this.mailingAddressIsAdded = true;
            } else {
                // init empty mailing address
                this.propertyData.addresses.mailing.push({
                    address: '',
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                    lat: null,
                    lng: null,
                    apartment: ''
                });
            }
        }
    }
</script>
