<template>
    <span>{{ `${representative.first_name} ${representative.last_name}` }}</span>
</template>

<script>
    import { sortRepresentatives } from '@/helpers';

    export default {
        name: 'PrimaryRepresentative',
        props: {
            representatives: {
                type: Array,
                required: true
            },
            entityId: {
                type: [String, Number],
                required: true
            }
        },
        computed: {
            representative() {
                return sortRepresentatives(this.representatives, this.entityId)[0];
            }
        }
    }
</script>