<template>
    <div
        class="lead-item"
        :style="`border-left: 4px solid #fff4d4;`"
    >
        <div :class="['row-between', !mScreen && 'flex-no-wrap']">
            <div class="s-align-4">
                <status-marker
                    :marker="lead.property"
                    :color="'#fff4d4'"
                    size="35"
                />
            </div>
            <div class="ml-15 flex-grow-1 column-start">
                <p class="f-14-black f-sbold mb-2">
                    <router-link
                        :to="{ name: 'CommercialLeadOverview', params: { lead_id: lead.id }}"
                        class="black-link-underlined relative"
                        target="_blank"
                    >
                        {{ lead.property }}
                    </router-link>
                </p>
                <p class="f-12-grey">
                    Requested by:
                    <span class="f-12-black">
                        {{ fullName || no_info }}
                    </span>
                </p>
                <p class="f-12-grey">
                    Address:
                    <span class="f-12-blue">
                        {{ getAddress(lead.address) }}
                    </span>
                </p>
                <div>
                    <span class="f-12-grey">
                        Trades:
                    </span>
                    <span class="f-12-black ml-1 mr-4">
                        {{ lead.trades || no_info }}
                    </span>
                    <span class="f-12-grey">
                        Source:
                    </span>
                    <span class="f-12-black ml-1">
                        {{ lead.source || no_info }}
                    </span>
                </div>
            </div>
            <div :class="mScreen || tScreen
                ? 'w-100 row-between ml-50 mt-2'
                : 'column-between-end'
            ">
                <div class="s-align-6">
                    <clock-icon
                        class="i-grey mr-2"
                        size="12"
                    />
                    <span class="f-11-grey">
                        {{ lead.created_at | timeAgo }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {ClockIcon} from '@/icons';
    import {showFullAddress} from '@/helpers';
    import StatusMarker from "../StatusMarker";
    
    export default {
        name: 'ComLeadCard',
        components: {
            ClockIcon,
            StatusMarker
        },
        data() {
            return {
                no_info: 'No information'
            }
        },
        props: {
            lead: {
                type: Object,
                required: true
            }
        },
        computed: {
            fullName() {
                const {first_name, last_name} = this.lead.created_by;
                if (first_name || last_name) {
                    return `${first_name || ''} ${last_name}`
                }
                return '';
            }
        },
        methods: {
            getAddress(lead) {
                return showFullAddress(lead);
            },
        }
    }
</script>
