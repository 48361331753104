<template>
    <div class="br-5 column-start expected-revenue-overview p-20">
        <div class="lg-12 sm-12 expected-revenue">
            <div class="column-start w-100 pb-6">
                <div class="row-baseline w-100 ">
                    <div class="expected-revenue-icon s-align-5 br-5">
                        <broken-lightning-icon/>
                    </div>
                    <p class="expected-revenue-title ml-4">Expected Revenue</p>
                </div>
                <animate-number 
                    class="f-30-white f-sbold break-all mt-2"
                    data-name="expected_revenue"
                    :number="expected_revenue"
                    :commasFilter="true"
                    filter="currency"
                />
            </div>
        </div>
        <div class="lg-12 sm-12 mb-20">
            <div class="br-5 column-start w-100 pt-4">
                <div class="row-baseline w-100 ">
                    <div class="base-bid-icon s-align-5 br-5">
                        <dollar-icon size="12"/>
                    </div>
                    <p class="expected-revenue-title ml-4">Base bid</p>
                </div>
                <animate-number 
                    class="f-30-white f-sbold break-all mt-2"
                    data-name="base_bid"
                    :number="base_bid"
                    :commasFilter="true"
                    filter="currency"
                />
            </div>
        </div>
        <div class="lg-12 sm-12 row-baseline flex-no-wrap">
            <div class="probability-percent w-45 relative">
                <span
                    class="probability-percent-range"
                    :style="{
                        width: `${probability}%`,
                        backgroundColor: getProbabilityColor(probability)
                    }"
                ></span>
            </div>
            <p class="f-12-white ml-20">
                <span class="f-15-white f-sbold">{{ probability }}%</span> of probability
            </p>
        </div>
    </div>
</template>
<script>
    import { BrokenLightningIcon, DollarIcon } from '@/icons';
    import { AnimateNumber } from '@/ui';
    import api from '@/headers.js';

    export default {
        name: 'ExpectedRevenue',
        components: {
            BrokenLightningIcon,
            DollarIcon,
            AnimateNumber
        },
        props: {
            entityType: {
                type: String,
                required: true
            },
            entityId: {
                type: [ String, Number ],
                required: true
            }
        },
        data() {
            return {
                base_bid: 0,
                probability: 0,
                expected_revenue: 0
            }
        },
        methods: {
            getProbabilityColor(value) {
                if (value < 33.3) {
                    return 'red';
                } else if (value < 66.6) {
                    return 'blue'; 
                }
                return 'green'; 
            },
            getExpectedRevenue() {
                let params = {
                    entity_id: this.entityId,
                    entity_type: this.entityType
                }

                api.get('/estimates/revenue', { params })
                    .then(response => response.data)
                    .then(data => {
                        if(!data.data) {
                            return;
                        }

                        this.base_bid = data.data.base_bid;
                        this.probability = data.data.probability;
                        this.expected_revenue = data.data.expected_revenue;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            }
        },
        created() {
            this.getExpectedRevenue();
        }
    }
</script>