import Echo from "laravel-echo";

export default {
    connect() {
        window.Echo = new Echo({
            broadcaster: 'socket.io',
            host: process.env.VUE_APP_ECHO_SERVER_URL,
            transports: ['websocket', 'polling', 'flashsocket'],
            auth:
                {
                    headers:
                        {
                            'Authorization': 'Bearer ' + localStorage.getItem('user-token') || ''
                        }
                }
        });
    },

    disconnect() {
        window.Echo.disconnect();
    }
}
