<template>
    <svg 
        viewBox="0 0 108.99159240722656 122.35298919677734"
        :width="size" 
        :height="size"
        class="paper-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="108.99159240722656" 
            height="122.35298919677734" 
            style="fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(5.256667137145996, 0, 0, 5.256667137145996, 4653.3857421875, -4659.397859096527)">
            <g>
                <path d="M-864.9,906.2v-11.4c0-0.5-0.2-1-0.6-1.4l-6.8-6.2c-0.3-0.3-0.8-0.5-1.3-0.5h-8.3c-1.7,0-3.1,1.4-3.1,3.1v16.4c0,1.7,1.4,3.1,3.1,3.1h13.8C-866.3,909.3-864.9,907.9-864.9,906.2z M-867.2,893.6h-3.4c-1,0-1.9-0.8-1.9-1.9v-3L-867.2,893.6zM-866.2,906.2c0,1-0.8,1.9-1.9,1.9h-13.8c-1,0-1.9-0.8-1.9-1.9v-16.4c0-1,0.8-1.9,1.9-1.9h8.2v3.8c0,1.7,1.4,3.1,3.1,3.1h4.4V906.2z"/>
                <path d="M -881.299988 903.700012 H -871.199987 V 905.000012 H -881.299988 V 903.700012 Z"/>
                <path d="M -881.299988 890.5 H -876.299988 V 891.8 H -881.299988 V 890.5 Z"/>
                <path d="M -881.299988 894.900024 H -873.799988 V 896.200024 H -881.299988 V 894.900024 Z"/>
                <path d="M -881.299988 899.299988 H -868.699987 V 900.599988 H -881.299988 V 899.299988 Z"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'PaperIcon',
        props: {
            size: {
                type: [Number, String],
                default: 19
            }
        }
    }
</script>