<template>
<!-- ITS ALL IN DEV -->
    <div class="import-step-two full-container pb-100">
        <div class="mb-20 mt-50 ph-30">
            <div class="row-between bb-grey pb-8">
                <div>
                    <p class="f-16-black f-sbold">
                        {{ selectColumnsQuantity }} Columns Matched
                        <span class="f-13-grey f-sbold">
                            (of {{ csvColumnsQuantity }})
                        </span>
                    </p>
                    <div class="f-12-grey mt-2 s-align-4">
                        What is Lorem Ipsum? Settings lorem title to
                        <button
                            @click="settingsModalIsOpen = true" 
                            class="ml-1 transparent-button f-12-blue"
                        >
                            Settings title.
                        </button> 
                    </div>
                </div>
                <div class="s-align-6">
                    <dropdown
                        label="value"
                        :options="dropdownOptions"
                        :bydefault="dropdownOptions[0].id"
                        return="object"
                        @select="onDropDownSelect"
                    />
                </div>
            </div>
            <div class="conflict-columns mt-8 s-align-4">
                <div class="assign-info-icon s-align-5 mr-2">
                    <info-icon 
                        class="i-white"
                        size="8"
                    />
                </div>
                <div class="f-12-black mr-8">
                    <span class="f-bold">
                        {{ conflictTags.length }}
                    </span>
                    columns conflicts
                </div>
                <div
                    v-for="(tag,index) in conflictTags"
                    :key="tag+index"
                    class="conflict-tag s-align-5 f-12-black f-sbold ph-4 mr-2"
                >
                    {{ tag.label }}
                </div>
            </div>
        </div>
        <div class="import-table-wrapper">
            <div class="s-align-1 column-title-wrapper">
                <div class="checkbox-title bb-grey">
                    <!-- empty title for checkbox -->
                </div>
                <div
                    class="column-title column-start s-align-4"
                    v-for="(item,index) in csvData.meta.fields"
                    :key="index+'a'"
                >
                    <div class="row-between">
                        <div class="s-align-4 w-80">
                            <checkbox-button
                                color="blue"
                                class="mr-5"
                            >
                                <input 
                                    type="checkbox" 
                                    name="is_job_field_show" 
                                    v-model="activeColumn[index]"
                                    :true-value="item"
                                    :false-value="''"
                                />
                            </checkbox-button>
                            <tooltip
                                v-if="item.length > 21"
                                direction="bottom"
                            >
                                <span class="f-13-asphalt w-90 mt-1 f-sbold ellipsis inline-block">
                                    {{ item }}
                                </span>
                                <template v-slot:content>
                                    <p class="f-16-white break-all t-center">
                                        {{ item }}
                                    </p>
                                </template>
                            </tooltip>
                            <span
                                v-else
                                class="f-13-asphalt f-sbold"
                            >
                                {{ item }}
                            </span>
                        </div>
                        <div class="s-align-6">
                            <div class="error-counter s-align-5 f-11-red f-sbold">
                                {{ columnErrorCounter(item) }}
                            </div>
                            <context-menu
                                :unicId="`conflict-menu-${index}`"
                                width="200"
                            >
                                <button class="s-align-4 bb-grey pb-4">
                                    <span class="icon-container s-align-5 mr-6">
                                        <round-question-icon class="i-grey"/>
                                    </span>
                                    <span class="f-13-grey">
                                        Show conflict rows
                                    </span>
                                </button>
                                <button
                                    class="s-align-4"
                                    @click="openSettingsColumnPopup(index)"
                                >
                                    <span class="icon-container s-align-5 mr-6">
                                        <gear-icon
                                            class="i-grey"
                                            size="16"
                                        />
                                    </span>
                                    <span class="f-13-grey">
                                        Conflict settings
                                    </span>
                                </button>
                            </context-menu>
                        </div>
                    </div>
                    <multiselect 
                        class="mt-5"
                        v-model="selectColumnsValues[index]" 
                        :options="needOptions(index)"
                        track-by="label"
                        label="label"
                        :searchable="false" 
                        :show-labels="false" 
                        placeholder="Pick a value"
                        :max-height="200"
                    />
                </div>
            </div>
            <div class="column-start">
                <div
                    class="mr-8 column-item"
                    v-for="(item, index) in columnData"
                    :key="index+'b'"
                >
                    <div class="checkbox-wrapper s-align-4 pl-6 bb-grey">
                        <checkbox-button color="blue">
                            <input 
                                type="checkbox" 
                                name="row-item"
                                :id="`row-check-${index}`"
                                :checked="true"
                            />
                        </checkbox-button>
                    </div>
                    <div
                        v-for="(el,ind) in item"
                        :key="ind+'c'+item[ind]"
                        :class="[
                            'table-values hidden s-align-4',
                            item[ind] != null ? 'border-grey' : 'border-red'
                        ]" 
                    >
                        <textarea
                            class="transparent-input pointer f-12-black s-align-4 w-100"
                            v-model="item[ind]"
                            rows="2"
                        />
                        <!-- <template v-if="defaultValues[ind] && el == null">
                            <tooltip
                                v-if="defaultValues[ind].length > 57"
                                direction="top"
                            >
                                <span class="column-value f-12-black mt-1 ellipsis inline-block">
                                    {{ defaultValues[ind] }}
                                </span>
                                <template v-slot:content>
                                    <p class="f-16-white break-all t-center">
                                        {{ defaultValues[ind] }}
                                    </p>
                                </template>
                            </tooltip>
                            <span
                                v-else
                                class="f-12-black column-value"
                            >
                                {{ defaultValues[ind] }}
                            </span>
                        </template>
                        <template v-else>
                            <tooltip
                                v-if="el && el.length > 57"
                                direction="top"
                            >
                                <span class="column-value f-12-black mt-1 ellipsis inline-block">
                                    {{ el }}
                                </span>
                                <template v-slot:content>
                                    <p class="f-16-white break-all t-center">
                                        {{ el }}
                                    </p>
                                </template>
                            </tooltip>
                            <span
                                v-else
                                class="f-12-black column-value"
                            >
                                {{ el }}
                            </span>
                        </template> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="page-settings row-between">
            <button
                class="add-button"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                @click="validateData()"
            >
                Next
            </button>
        </div>
        <import-conflict-settings-modal
            v-if="settingsModalIsOpen"
            :columnsOption="columnOptions"
            @setDefaultValues="setDefaultValues"
            @close="settingsModalIsOpen = false"
        />
        <import-conflict-settings-popup
            v-if="settingsPopupIsOpen"
            :defaultValue="defaultValues[columnInSetDefault]"
            @setDefaultValue="setDefaultValue"
            @close="settingsPopupIsOpen = false"
        />
    </div>
</template>

<script>
    import { InfoIcon, RoundQuestionIcon, GearIcon } from '@/icons';
    import { CheckboxButton, Tooltip, Dropdown } from '@/ui';
    import api from '@/headers.js';
    import Multiselect from 'vue-multiselect';
    import ContextMenu from '@/components/ContextMenu.vue';
    import cloneDeep from 'lodash/cloneDeep';

    export default {
        name: "ImportStepTwo",
        components: {
            InfoIcon,
            CheckboxButton,
            Multiselect,
            ContextMenu,
            RoundQuestionIcon,
            GearIcon,
            Tooltip,
            Dropdown,
            ImportConflictSettingsModal: () => import('@/modals/ImportConflictSettingsModal.vue'),
            ImportConflictSettingsPopup: () => import('./ImportConflictSettingsPopup.vue')
        },
        props: {
            csvData: {
                type: Object,
                required: true
            },
            selectedType: {
                type: String,
                required: true
            },
            propertyId: {
                type: Number,
                required: true
            },
            layoutId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                requiredFields: [],
                fieldsData: [],
                selectColumnsValues: [],
                settingsModalIsOpen: false,
                settingsPopupIsOpen: false,
                activeColumn: [],
                disableRows: [],
                columnInSetDefault: null,
                defaultValues: [],
                errorColumns: [],
                dropdownOptions: [
                    {
                        id: 0,
                        value: 'All columns'
                    },
                    {
                        id: 1,
                        value: 'Error columns'
                    }
                ],
                columnOptions: [
                    {
                        name: 'job_number',
                        label: 'Job Number',
                        required: true,
                        default: ''
                    },
                    {
                        name: 'representatives_first_name',
                        label: 'Representatives first name',
                        required: true,
                        default: ''
                    },
                    {
                        name: 'representatives_last_name',
                        label: 'Representatives last name',
                        required: true,
                        default: ''
                    },
                    {
                        name: 'representatives_title',
                        label: 'Representatives title',
                        required: true,
                        default: ''
                    },  
                    {
                        name: 'apartment',
                        label: 'Apartment',
                        required: false,
                        default: ''
                    },  
                    {
                        name: 'address',
                        label: 'Address',
                        required: true,
                        default: ''
                    },
                    {
                        name: 'street',
                        label: 'Street',
                        required: true,
                        default: ''
                    },
                    {
                        name: 'city',
                        label: 'City',
                        required: true,
                        default: ''
                    },
                    {
                        name: 'state',
                        label: 'State',
                        required: true,
                        default: ''
                    },
                    {
                        name: 'zip',
                        label: 'Zip',
                        required: true,
                        default: ''
                    },
                    {
                        name: 'notes',
                        label: 'Notes',
                        required: false,
                        default: ''
                    }
                ]
            }
        },
        computed: {
            columnData() {
               return this.csvData.data.map(item => this.csvData.meta.fields.map(f => item[f]))
            },
            conflictTags() {
                return this.columnOptions.filter(el => el.required &&
                    !this.selectColumnsValues.some(item => item ? item.label == el.label : null));
            },
            selectColumnsQuantity() {
                return this.selectColumnsValues.filter(Boolean).length;
            },
            csvColumnsQuantity() {
                return this.csvData.meta.fields.length;
            }
        },
        methods: {
            needOptions(index) {
                let selectVal = '';

                if (this.selectColumnsValues[index]) {
                    selectVal = this.selectColumnsValues[index].label;
                }

                return this.columnOptions.filter(el => 
                    !this.selectColumnsValues.some(item => item ? item.label == el.label : null) ||
                    el.label == selectVal);
            },
            setDefaultValue(data) {
                this.defaultValues[this.columnInSetDefault] = data;
                this.columnData.forEach((fields,ind) => {
                    if (fields[this.columnInSetDefault] == null || !fields[this.columnInSetDefault].length || 
                        fields[this.columnInSetDefault] == this.columnOptions[this.columnInSetDefault].default) {
                        fields[this.columnInSetDefault] = data;
                    }
                });
                this.columnOptions[this.columnInSetDefault].default = data;
                this.settingsPopupIsOpen = false;
            },
            openSettingsColumnPopup(index) {
                this.settingsPopupIsOpen = true;
                this.columnInSetDefault = index;
            },
            setDefaultValues(data) {
                if (data.some((item,index) => item.default != this.columnOptions[index].default)) {
                    data.forEach((elem, ind) => {
                        let index = this.selectColumnsValues.findIndex(el => el ? el.label == elem.label : null);

                        if (index > -1) {
                            this.columnData.forEach(fields => {
                                if (fields[index] == null || !fields[index].length || 
                                    fields[index] == this.columnOptions[ind].default) {
                                    fields[index] = elem.default;
                                }
                            });
                        }
                    });
                    this.columnOptions = data;
                }
            },
            onDropDownSelect(data) {
                // Need BackEnd
            },
            columnErrorCounter(item) {
                let column = this.csvData.data.map(el => el[item]).filter(value => value == null);

                return column.length;
            },
            validateData() {
                let sendData = [];
                this.columnData.forEach((fields,ind) => {
                    let jobTemplate = {
                        job_number: "",
                        representatives:[
                            {
                                first_name:"",
                                last_name:"",
                                title:"",  
                            }
                        ],
                        apartment:"",
                        property_id: this.propertyId,
                        address: "",
                        street: "",
                        city: "",
                        state: "",
                        zip: "",
                        lat: null,
                        lng: null,
                        layout_id: this.layoutId,
                        notes:[]
                    }

                    fields.forEach((item,index) => {
                        if (this.selectColumnsValues[index]) {
                            if (this.selectColumnsValues[index].name == 'representatives_first_name') {
                                jobTemplate.representatives[0].first_name = item;
                            } else if (this.selectColumnsValues[index].name == 'representatives_last_name') {
                                jobTemplate.representatives[0].last_name = item;
                            } else if (this.selectColumnsValues[index].name == 'representatives_title') {
                                jobTemplate.representatives[0].title = item;
                            } else {
                                jobTemplate[this.selectColumnsValues[index].name] = item;
                            }  
                        }
                        if (index == fields.length - 1) {
                            sendData.push(jobTemplate);
                        }
                    });
                });

                let formData =  {
                    jobs: sendData, 
                    mode: 'validate'
                }

                api.post('/commercial/imports', formData)
                    .then(res => {
                        // in dev
                    })
                    .catch(error => {
                        // in dev
                    })
            }
        },
        created() {
            this.activeColumn = cloneDeep(this.csvData.meta.fields);
        }
    }
</script>