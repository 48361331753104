<template>
    <svg 
        viewBox="0 0 538.2366333007812 563.3482055664062"
        :width="size"
        :height="size"
        class="calendar-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="538.2366333007812" 
            height="563.3482055664062" 
            style="fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(7.840000152587891, 0, 0, 7.840000152587891, 3241.0475158691406, -1819.5440673828125)">
            <path d="M-391.5,244.9h25c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2h-25c-1.1,0-2,0.9-2,2C-393.5,244-392.6,244.9-391.5,244.9z M-350.8,240.9h-4.5c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h4.5c0.8,0,1.5,0.7,1.5,1.5v11.7h-59.5v-11.7c0-0.8,0.7-1.5,1.5-1.5h4.5c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2h-4.5c-3,0-5.5,2.5-5.5,5.5V298c0,3,2.5,5.5,5.5,5.5h56.5c3,0,5.5-2.5,5.5-5.5v-51.6C-345.3,243.4-347.7,240.9-350.8,240.9z M-349.3,298c0,0.8-0.7,1.5-1.5,1.5h-56.5c-0.8,0-1.5-0.7-1.5-1.5v-35.9h59.5L-349.3,298L-349.3,298z M-397.5,252.1c1.1,0,2-0.9,2-2v-15.6c0-1.1-0.9-2-2-2s-2,0.9-2,2v15.6C-399.5,251.2-398.6,252.1-397.5,252.1z M-361.1,252.1c1.1,0,2-0.9,2-2v-15.6c0-1.1-0.9-2-2-2s-2,0.9-2,2v15.6C-363.1,251.2-362.2,252.1-361.1,252.1z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'CalendarIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>
