<template>
    <div class="lead-emails-menu z-index-2">
        <perfect-scrollbar class="ps-grey small-height">
            <div
                :class="[
                    'lead-emails-item pointer s-align-4',
                    { 'bb-grey' : index != leadEmails.length -1 }
                ]"
                v-for="(email,index) in leadEmails"
                :key="'email_' + index"
                @click="$emit('addEmail', email.email)"
            >
                <avatar
                    :name="email.email"
                    :border="false"
                    size="35"
                    class="mr-4 ml-2"
                />
                {{email.email}}
                <span
                    v-if="email.label"
                    class="blue-label f-11-black f-sbold dark-blue-label ellipsis mh-8"
                >
                    {{ email.label }}
                </span>
            </div>
        </perfect-scrollbar>
    </div>
</template>

<script>
import Avatar from './User/Avatar.vue';

export default {
    name: 'LeadEmailsMenu',
    components: {
        Avatar
    },
    props: {
        leadEmails: {
            type: Array,
            required: true
        }
    }
}
</script>