<template>
    <div
        v-body-scroll-lock:reserveScrollBarGap="true"
        class="file-preview-modal s-align-5"
    >
        <div class="modal-container relative">
            <file-preview-container
                v-if="documentFileTyle"
                :url="fileUrl"
                :type="file.type"
            />
            <img
                v-if="imgFileType"
                v-show="!loading"
                :src="fileUrl" 
                :alt="file.original_name"
                @load="loading = false"
            >
            <button
                class="transparent-button close-button"
                @click="$emit('close')"
            >
                <close-icon 
                    size="17"
                    class="i-grey"
                />
            </button>
            <loader 
                v-if="loading"
                class="s-align-5"
            />
        </div>
    </div>
</template>

<script>
    import FilePreviewContainer from '@/components/FilePreviewContainer.vue';
    import { FILE_EXTENSIONS } from '@/constants.js';
    import { CloseIcon } from '@/icons';
    import { Loader } from '@/ui';

    export default {
        name: 'PacketFilePreviewModal',
        components: {
            CloseIcon,
            FilePreviewContainer,
            Loader
        },
        props: {
            file: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                loading: false
            }
        },
        computed: {
            documentFileTyle() {
                return FILE_EXTENSIONS.DOCUMENTS.some(type => type.toLowerCase() === this.file.type.toLowerCase());
            },
            imgFileType() {
                return FILE_EXTENSIONS.IMAGES.some(type => type.toLowerCase() === this.file.type.toLowerCase());
            },
            fileUrl() {
                return process.env.VUE_APP_AMAZONAWS_URL + this.file.id;
            }
        },
        created() {
            if (this.imgFileType) {
                this.loading = true;
            }
        }
    }
</script>