<template>
    <svg 
        viewBox="0 0 20 20"
        :width="size"
        :height="size"
        class="phone-icon"
    >
        <g transform="matrix(0.07684346389778826, 0, 0, 0.07684346389778826, 21.00005776048031, -8.572318732442897)">
            <g>
                <path d="M-170,152.8c13,12,13,33,0,46l-13,13c18,22,42,46,64,64l14-13c12-13,33-13,46,0l21,21c11,12,12,30,2,43c-5,8-14,14-20,21c-20,20-60,5-99-24c-29-22-54-46-75-75c-30-40-45-79-25-99c7-7,14-15,22-21c12-10,31-9,42,3L-170,152.8z M-201,210.8l22-22c7-7,7-19,0-27l-21-21c-7-7-18-7-26-1c-6,5-13,13-19,19c-14,14,0,47,25,82c21,27,45,51,73,72c34,26,67,40,81,26c6-6,14-13,20-20c6-8,5-19-2-26l-21-21c-7-7-20-7-27,0l-22,22C-147,270.8-178,240.8-201,210.8z"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'PhoneIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>

