import {
    SET_DRAG_SOURCE,
    SET_DRAGGING_FIELD,
    SET_SECTION_OVER_ID,
    SET_DRAG_AVAILABILITY,
    SET_NEW_SECTION_TYPE,
    SET_SECTION_COUNT
} from '../actions/customFields';

export default {
    namespaced: true,
    state: {
        dragSource: '',
        draggingField: {},
        sectionOverId: null,
        isDragAvailable: true,
        newSectionType: '',
        sectionCount: ''
    },
    getters: {
        getDragSource: state => state.dragSource,
        getDraggingField: state => state.draggingField,
        getSectionOverId: state => state.sectionOverId,
        getDragAvailability: state => state.isDragAvailable,
        getNewSectionType: state => state.newSectionType,
        getSectionCount: state => state.sectionCount
    },
    mutations: {
        [SET_DRAG_SOURCE](state, payload) {
            state.dragSource = payload;
        },
        [SET_DRAGGING_FIELD](state, payload) {
            state.draggingField = payload;
        },
        [SET_SECTION_OVER_ID](state, payload) {
            state.sectionOverId = payload;
        },
        [SET_DRAG_AVAILABILITY](state, payload) {
            state.isDragAvailable = payload;
        },
        [SET_NEW_SECTION_TYPE](state, payload) {
            state.newSectionType = payload;
        },
        [SET_SECTION_COUNT](state, payload) {
            state.sectionCount = payload;
        }
    }
}