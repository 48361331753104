<template>
    <svg
        :width="size"
        :height="height || size"
        viewBox="0 0 125 44"
    >
        <g>
            <g>
                <path fill="#E5E1F9" d="M120.8,6.8h-10.3c-0.3,0-0.5-0.5-0.5-1l0,0c0-0.5,0.2-0.9,0.5-0.9h10.3c0.3,0,0.5,0.4,0.5,0.9l0,0
                    C121.4,6.2,121.1,6.8,120.8,6.8z"/>
            </g>
            <g>
                <path fill="#BCD6F9" d="M123.7,15h-2.6c-0.7,0-1.3-0.6-1.3-1.3v-2.4c0-0.7,0.6-1.3,1.3-1.3h2.6c0.7,0,1.3,0.6,1.3,1.3v2.4
                    C125.1,14.4,124.5,15,123.7,15z"/>
            </g>
            <g>
                <path fill="#E5E1F9" d="M15.7,33.9h-15c-0.4,0-0.7-0.5-0.7-1l0,0c0-0.5,0.3-0.9,0.7-0.9h15c0.4,0,0.7,0.4,0.7,0.9l0,0
                    C16.5,33.3,16.2,33.9,15.7,33.9z"/>
            </g>
            <g>
                <path fill="#FFFFFF" d="M103.5,44H25.4c-1.4,0-2.6-0.9-2.6-2V2c0-1.1,1.2-2,2.6-2h78.1c1.4,0,2.6,0.9,2.6,2v40
                    C106,43.2,105,44,103.5,44z"/>
            </g>
            <path fill="#BCD6F9" d="M81.7,14.5H31.4c-0.9,0-1.6-0.7-1.6-1.6v-1.2c0-0.9,0.7-1.6,1.6-1.6h50.3c0.9,0,1.6,0.7,1.6,1.6v1.2
                C83.2,13.8,82.5,14.5,81.7,14.5z"/>
            <path fill="#BCD6F9" d="M96.7,14.5h-3.9c-0.9,0-1.6-0.7-1.6-1.6v-1.2c0-0.9,0.7-1.6,1.6-1.6h3.9c0.9,0,1.6,0.7,1.6,1.6v1.2
                C98.2,13.8,97.5,14.5,96.7,14.5z"/>
            <path fill="#E8E9EA" d="M81.7,24.3H31.4c-0.9,0-1.6-0.7-1.6-1.6v-1.2c0-0.9,0.7-1.6,1.6-1.6h50.3c0.9,0,1.6,0.7,1.6,1.6v1.2
                C83.2,23.6,82.5,24.3,81.7,24.3z"/>
            <path fill="#E8E9EA" d="M96.7,24.3h-3.9c-0.9,0-1.6-0.7-1.6-1.6v-1.2c0-0.9,0.7-1.6,1.6-1.6h3.9c0.9,0,1.6,0.7,1.6,1.6v1.2
                C98.2,23.6,97.5,24.3,96.7,24.3z"/>
            <path fill="#E8E9EA" d="M81.7,34H31.4c-0.9,0-1.6-0.7-1.6-1.6v-1.2c0-0.9,0.7-1.6,1.6-1.6h50.3c0.9,0,1.6,0.7,1.6,1.6v1.2
                C83.2,33.3,82.5,34,81.7,34z"/>
            <path fill="#E8E9EA" d="M96.7,34h-3.9c-0.9,0-1.6-0.7-1.6-1.6v-1.2c0-0.9,0.7-1.6,1.6-1.6h3.9c0.9,0,1.6,0.7,1.6,1.6v1.2
                C98.2,33.3,97.5,34,96.7,34z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'ListEmpty',
        props: {
            size: {
                type: [Number, String],
                default: 125
            },
            height: {
                type: [Number, String],
                default: 44
            }
        }
    }
</script>