<template>
    <div>
        <div class="jobs-report ph-20 pv-6 mb-40 br-5">
            <div
                v-if="milestonesSetLoaded"
                class="jobs-report-header bb-grey pt-6 mb-20"
            >
                <div 
                    :class="[
                        'row-start flex-no-wrap ',
                        mScreen ? 'pb-1' : ''
                    ]">
                    <button
                        v-if="mScreen"
                        class="transparent-button pb-8 ph-4 pb-sm-0"
                        @click="swipeNav('left')"
                    >
                        <arrow-icon
                            transform="180"
                            size="13"
                            class="i-grey"
                        />
                    </button>
                    <div
                        ref="content"
                        :class="[
                            'row-start flex-no-wrap nav', 
                            mScreen && 'mobile-swipe-block'
                        ]"
                    >
                        <button
                            v-for="(elem, index) in dashboardReportParamsForBuilder"
                            :key="index"
                            :class="[
                                'transparent-button pb-4 milestone-set-filter relative no-wrap',
                                { 'milestone-set-filter-active' : selectedReport == elem },
                                mScreen && 'p-4',
                                !mScreen && 'mr-20'
                            ]"
                            @click="setActiveReport(elem)"
                        >
                        <span :class="[
                            'f-sbold mr-2',
                            selectedReport == elem ? 'f-13-black' : 'f-13-grey'
                        ]">
                            {{ elem.title }}
                        </span>
                            <span class="milestone-entity-counter f-11-black f-sbold s-align-5">
                                {{elem.milestones.count}}
                            </span>
                        </button>
                    </div>
                    <button
                        v-if="mScreen"
                        class="transparent-button pb-8 ph-4"
                        @click="swipeNav('right')"
                    >
                        <arrow-icon
                            transform="0"
                            size="13"
                            class="i-grey"
                        />
                    </button>
                </div>
            </div>
            <div 
                v-if="selectedReport && milestoneInReport || groupingMilestoneSet"
                :class="[
                    'filters-section pb-30',
                    mScreen ? 'row-between' : 'row-start'
                ]"
            >
                <template v-if="!isModuleGrouping">
                    <div
                        v-for="(milestone, key, id) in milestoneInReport"
                        :key="id"
                        v-show="milestone"
                        @click="setMilestone(key, null)"
                        :class="[
                            'lg-3 md-4 sm-12',
                            { 'none-events' : loading }
                        ]"
                    >
                        <div
                            :class="[
                                'ml-4 w-100 milestone-filter pv-5 ph-8 mb-4 pointer lg-3 md-4 sm-6 br-5',
                                { 'milestone-filter-active' : selectedMilestone == key },
                                mScreen ? 'column-reverse' : 'row-between'
                            ]"
                        >
                            <div :class="['column-start', mScreen && 'mt-8']">
                                <span class="f-14-black f-sbold break-all">
                                    {{ key }}
                                </span>
                                <span class="f-12-grey">
                                    {{ milestone.count }} leads
                                </span>
                            </div>
                            <span
                                :style="`background-color: ${milestone.color}`"
                                :class="[
                                    'milestone-status-marker capitalize',
                                    { 'status-marker-lightblue':  milestone.isNew }
                                ]"
                            >
                                {{ getStatusMarker(key) }}
                            </span>
                        </div>
                    </div>
                </template>
                <!-- Template for displaying milestones with separate on Commercial and Residential -->
                <template v-if="isModuleGrouping && !allReportLoading">
                    <div class="row-start">
                        <div class="column-start lg-6 pr-8 br-5 md-12 sm-12 ">
                            <p class="f-16-black f-sbold mb-6 ml-4">Commercial</p>
                            <div class="row-start">
                                <div
                                    v-for="(milestone, key, id) in groupingMilestoneSet"
                                    :key="id"
                                    v-show="getCommercialLeadsCounts(milestone)"
                                    @click="setMilestone(key, 'Commercial')"
                                    :class="[
                                        'lg-6 md-4 sm-12',
                                        { 'none-events' : loading }
                                    ]"
                                >
                                    <div
                                        :class="[
                                            'ml-4 w-100 milestone-filter br-5 pv-5 ph-8 mb-4 pointer lg-3 md-4 sm-6',
                                            { 'milestone-filter-active' : selectedMilestone == key && commercialType },
                                            mScreen ? 'column-reverse' : 'row-between'
                                        ]"
                                    >
                                        <div :class="['column-start', mScreen && 'mt-8']">
                                            <span class="f-14-black f-sbold break-all">
                                                {{ key }}
                                            </span>
                                            <span class="f-12-grey">
                                                {{ getCommercialLeadsCounts(milestone) }} leads
                                                <span class="f-9-orange f-bold uppercase orange-label ml-1">
                                                    Commercial
                                                </span>
                                            </span>
                                        </div>
                                        <span
                                            :style="{'background-color': getColor(milestone)}"
                                            :class="[
                                                'milestone-status-marker capitalize',
                                                { 'status-marker-lightblue':  milestone.isNew }
                                            ]"
                                        >
                                            {{ getStatusMarker(key) }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column-start lg-6 br-5 md-12 sm-12">
                            <p class="f-16-black f-sbold mb-6 ml-4">Residential</p>
                            <div class="row-start">
                                <div
                                    v-for="(milestone, key, id) in groupingMilestoneSet"
                                    :key="id"
                                    v-show="getResidentialLeadsCounts(milestone)"
                                    @click="setMilestone(key, 'Residential')"
                                    :class="[
                                        'lg-6 md-4 sm-12',
                                        { 'none-events' : loading }
                                    ]"
                                >
                                    <div
                                        :class="[
                                            'ml-4 w-100 milestone-filter pv-5 ph-8 mb-4 pointer lg-6 md-4 sm-6 br-5',
                                            { 'milestone-filter-active' : selectedMilestone == key && !commercialType },
                                            mScreen ? 'column-reverse' : 'row-between'
                                        ]"
                                    >
                                        <div :class="['column-start', mScreen && 'mt-8']">
                                            <span class="f-14-black f-sbold break-all">
                                                {{ key }}
                                            </span>
                                            <span class="f-12-grey">
                                                {{ getResidentialLeadsCounts(milestone) }} leads
                                                <span class="f-bold uppercase trade-label ml-1">
                                                    Residential
                                                </span>
                                            </span>
                                        </div>
                                        <span
                                            :style="{'background-color': getColor(milestone)}"
                                            :class="[
                                                'milestone-status-marker capitalize',
                                                { 'status-marker-lightblue':  milestone.isNew }
                                            ]"
                                        >
                                            {{ getStatusMarker(key) }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="jobs-report-table">
                <div class="w-100">
                    <div 
                        :class="[
                            'ph-20',
                            { 'dashboard-table-wrapper' : dScreen || nScreen }
                        ]"
                        :style="{ minHeight: reportTableHeight }"
                    >
                        <div
                            class="row-start f-12-black f-sbold pb-15"
                            v-if="dScreen || nScreen && tableHeader.length"
                        >
                            <div
                                v-for="(title, index) in tableHeader"
                                :key="index"
                                :style="{
                                    width: tableCellWidth(title),
                                    padding: tableHeader.length > 10 && dScreen ? '20px 3px' : ''
                                }"
                            >
                                <button 
                                    class="transparent-button"
                                    @click="changeSort(relationTableHeaderAndBody[index])"
                                >
                                    <move-icon 
                                        transform="90" 
                                        :class="[
                                            'order-by-icon', 
                                            relationTableHeaderAndBody[index] === sortColumn ? `order-${orderBy}` : ''
                                        ]"
                                    />
                                    <span class="pl-1 f-sbold f-9-grey uppercase">
                                        {{ title }}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <loader
                            v-if="loading || sortLoading || !milestonesSetLoaded || reportRecordsLoaded"
                            class="s-align-5"
                            :size="loading ? 'small' : 'full'"
                        />
                        <div
                            v-show="!loading"
                            v-for="(report, index) in reportRecords"
                            :key="`report-${index}`"
                            :class="[
                                'table-row row-start row-baseline flex-no-wrap f-14-black pv-3',
                                { 'bb-grey' : index != reportRecords.length -1 }
                            ]"
                        >
                            <div
                                v-for="(cell, index) in tableHeader"
                                :key="index"
                                :style="{
                                    width: tableCellWidth(cell),
                                    padding: tableHeader.length > 10 && dScreen ? '20px 3px' : ''
                                }"
                                class="md-12 sm-12"
                            >
                                <div class="row-between row-baseline">
                                    <div 
                                        :class="[
                                            mScreen ? 'column-reverse sm-12 mb-sm-1' : ''
                                        ]">
                                        <span 
                                            :class="[
                                                'table-cell md-12',
                                                cell === SPECIAL_HEADER_NAME.JOB_NAME ? 'column-start s-align-1' : '',
                                                cell === SPECIAL_HEADER_NAME.PRIORITY 
                                                ? 'priority-tag f-11-black f-sbold ph-2 s-align-5' : '',
                                                mScreen ? 'sm-12 s-align-5' : 'pl-2'
                                            ]"
                                            :style="{
                                                backgroundColor: cell === SPECIAL_HEADER_NAME.PRIORITY 
                                                ? `${hexToRgba(report.color_code, 0.1)}` : '',
                                                color: cell === SPECIAL_HEADER_NAME.PRIORITY 
                                                ? `${report.color_code}` : ''
                                            }"
                                        >
                                            <span v-if="cell === SPECIAL_HEADER_NAME.AGE">
                                                <age-icon
                                                    size="14" 
                                                    class="i-blue mr-3"
                                                />
                                            </span>
                                            <template
                                                v-if="cell === SPECIAL_HEADER_NAME.BASE_BID
                                                || cell === SPECIAL_HEADER_NAME.ESTIMATES_PRICE_TOTAL
                                                || cell === SPECIAL_HEADER_NAME.EXPECTED_REVENUE"
                                            >
                                                ${{ getReportField(report, index) | numberWithCommas }}
                                            </template>
                                            <template v-else>
                                                {{ getReportField(report, index) }}
                                            </template>
                                            <template v-if="cell === SPECIAL_HEADER_NAME.AGE">
                                                d
                                            </template>
                                            <span
                                                v-if="cell === SPECIAL_HEADER_NAME.JOB_NAME && report.bid_due_time"
                                                class="f-12-grey s-align-1"
                                            >
                                                {{ checkDueTime(report.bid_due_time) }}
                                            </span>
                                        </span>
                                        <span 
                                            v-if="mScreen"
                                            class="table-hint f-10-grey uppercase mb-4 sm-12 s-align-5"
                                        >
                                            {{ cell }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div :class="[
                                'table-cell lg-2 md-12 sm-12 pl-2',
                                dScreen ? 's-align-6' : 's-align-4 sm-mt-4',
                                mScreen ? 's-align-5 pl-sm-0' : ''
                            ]">
                                <button
                                    class="transparent-button p-1"
                                    @click="openOverviewPage(report)"
                                >
                                    <span class="f-12-blue">
                                        View
                                    </span>
                                    <arrow-icon 
                                        class="ml-1 i-blue"
                                        size="7"
                                        :transform="0"
                                    />
                                </button>
                            </div>
                        </div>
                        <not-found-message
                            v-if="!loading && reportRecords && !reportRecords.length"
                            v-show="!loading"
                            custom="Nothing to display"
                            tableEmpty
                        />
                    </div>
                </div>
            </div>
            <div class="pagination-wrapper row-between pt-8 bt-grey">
                <div 
                    :class="[
                        's-align-4 lg-6 nb-7 md-12 sm-12',
                        { 'row-start' : tScreen },
                        { 'column-start' : mScreen }    
                    ]"
                >
                    <div 
                        :class="[
                            'row-start f-13-grey',
                            mScreen ? 's-align-5 md-12' : 's-align-4 md-5'
                        ]">
                        <span class="f-sbold mr-2">
                            {{ totalItems }} 
                        </span>
                        results | Display
                        <div class="ml-3">
                            <multiselect
                                :options="perPageOptions"
                                :showLabels="false"
                                v-model="perPage"
                                @input="loadPage(1)"
                                @select="clearIndexedDB"
                                class="pagination-wrapper-select s-align-4"
                            />
                        </div>
                        <span class="ml-30">
                            per page
                        </span>
                    </div>
                    <pagination
                        v-if="totalPages > 1"
                        :class="[
                            'job-report-pagination md-7 mt-1 mt-md-2 mt-sm-2',
                            dScreen || mScreen ? 'w-50' : 'w-100',
                            { 's-align-9 row-baseline' : tScreen }
                        ]"
                        :activePage="Number(page)"
                        :quantity="totalPages"
                        :showDesc="false"
                        @click="loadPage"
                        theme="minimal"
                    />
                </div>
                <div
                    :class="[
                        'mt-md-2',
                        mScreen ? 's-align-5 md-12 mt-8 sm-12' : 's-align-6'
                    ]"
                    v-if="totalPages > 1"
                >
                    <div class="s-align-4 mr-5">
                        <span class="f-13-grey">
                            Go to page
                        </span>
                        <form-input class="ml-8">
                            <input 
                                type="text" 
                                name="go_to_page" 
                                class="form-input-field"
                                v-model="goToPageCount"
                                @keydown.enter="goToPage()"
                            />
                        </form-input>
                    </div>
                    <button
                        @click="goToPage()" 
                        class="primary-button"
                        :disabled="loading"
                    >
                        Go
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { MoveIcon, AgeIcon, ArrowIcon } from '@/icons';
    import { Loader, FormInput, Pagination } from '@/ui';
    import Multiselect from 'vue-multiselect';
    import NotFoundMessage from '@/components/NotFoundMessage.vue';
    import { REPORT_COLUMNS, BASE_ENTITY_TYPES, MODEL_CLASSES } from '@/constants';
    import moment from 'moment/src/moment';
    import api from '@/headers.js';
    import { getAbbreviation } from '@/helpers';
    import cloneDeep from 'lodash/cloneDeep';

    const SPECIAL_HEADER_NAME = {
        AGE: 'Age',
        PRIORITY: 'Priority',
        JOB_NAME: 'Job name',
        BASE_BID: 'Base bid',
        ESTIMATES_PRICE_TOTAL: 'Estimates Price total',
        EXPECTED_REVENUE: 'Expected revenue'
    };

    export default {
        name: 'JobsReport',
        components: {
            ArrowIcon,
            MoveIcon,
            AgeIcon,
            Loader,
            FormInput,
            Pagination,
            Multiselect,
            NotFoundMessage
        },
        data() {
            return {
                REPORT_COLUMNS,
                MODEL_CLASSES,
                SPECIAL_HEADER_NAME,
                selectedMilestoneSet: null,
                selectedMilestone: null,
                goToPageCount: '',
                page: 1,
                perPage: 5,
                totalPages: 1,
                totalItems: null,
                totals: null,
                milestonesSetLoaded: false,
                milestoneSets: [],
                sortColumn: 'AGE',
                ORDER_BY: {
                    ASC: 'asc',
                    DESC: 'desc'
                },
                orderBy: 'asc',
                sortBy: REPORT_COLUMNS.AGE,
                filters: {
                    milestone: {
                        column_id: REPORT_COLUMNS.MILESTONE.id,
                        condition: "is",
                        match: "and",
                        value: null
                    },
                    defaultDate: "all years"
                },
                dashboardTableHeader: [],
                dashboardData: [],
                loading: false,
                sortLoading: false,
                savedDashbordState: {},
                perPageOptions: [5, 10, 20],
                dashboardReportParamsForBuilder: [],
                selectedReport: null,
                selectedEntityType: null,
                savedEntityType: null,
                groupingMilestoneSet: [],
                allReportLoading: false,
                reportFromDB: null
            }
        },
        computed: {
            milestoneInReport() {
                return this.selectedReport ? this.selectedReport.milestones.data : [];
            },
            // Checks the selected two modules ('commercial' and 'residential') in dashboard report.
            isModuleGrouping() {
                if (this.selectedReport && this.selectedReport.is_dashboard) {
                    return this.selectedReport.modules.length === 2 
                        && this.selectedReport.group2 === this.REPORT_COLUMNS.CLASS_NAMESPACE.id;
                }
            },
            tableHeader() {
                const milestoneHeader = 'Milestone';
                const moduleHeader = 'Entity Type';
                const bidDate = 'Bid Due Date';
                const leadJobType = 'Lead/Job type';
                return this.dashboardTableHeader.filter(name => {
                    return name !== milestoneHeader 
                        && name !== moduleHeader
                        && name !== bidDate
                        && name !== leadJobType;
                });
            },
            /* Uppercase and paste underscore if header name more than one word.
             * For select report column based on REPORT_COLUMNS constants. 
             */
            relationTableHeaderAndBody() {
                let header = [...this.tableHeader];
                let result = [];

                result = header.map(el => {
                    return el.trim().split(' ').join('_').toUpperCase();
                });

                return result;
            },
            selectedReportId() {
                return this.selectedReport.id;
            },
            reportRecords() {
                let result = [];

                if (!this.isModuleGrouping) {
                    result = this.dashboardData;
                } else {
                    let residentialLead = this.MODEL_CLASSES.RESIDENTIAL_LEAD;
                    let commercialLead = this.MODEL_CLASSES.COMMERCIAL_LEAD;
                    let commercialJob = this.MODEL_CLASSES.COMMERCIAL_JOB.JOB;

                    if (this.selectedEntityType === 'Residential' 
                        && this.groupingMilestoneSet.length) {
                        result = this.dashboardData[0][residentialLead].data;
                    } else if (this.selectedEntityType === 'Commercial' 
                                && this.groupingMilestoneSet.length) {
                        let commonCommercial = [];

                        if (this.dashboardData[0][commercialLead] !== undefined) {
                            commonCommercial = [...this.dashboardData[0][commercialLead].data];
                        }

                        if (this.dashboardData[0][commercialJob] !== undefined) {
                            commonCommercial = [...this.dashboardData[0][commercialJob].data];
                        }
                        result = commonCommercial;
                    } else {
                        let allGroupingReport = [];
                        this.dashboardData.forEach((el, index) => {
                            if (el[commercialLead] !== undefined) {
                                allGroupingReport = allGroupingReport.concat(this.dashboardData[index][commercialLead].data);
                            }

                            if (el[residentialLead] !== undefined) {
                                allGroupingReport = allGroupingReport.concat(this.dashboardData[index][residentialLead].data);
                            }

                            if (el[commercialJob] !== undefined) {
                                allGroupingReport = allGroupingReport.concat(this.dashboardData[index][commercialJob].data);
                            }
                        });
                        result = allGroupingReport;
                    }
                }

                return this.sortBy.id === REPORT_COLUMNS.AGE.id ? result.sort((first, second) => {
                    if (this.orderBy === this.ORDER_BY.ASC) {
                        return first[this.sortBy.excerpt] - second[this.sortBy.excerpt];
                    }

                    return second[this.sortBy.excerpt] - first[this.sortBy.excerpt];
                }) : result;
            },
            reportRecordsLoaded() {
                return this.loading && this.reportRecords && !this.reportRecords.length;
            },
            // Selects all milestones in report for filtering when report changed.
            filtersParamsForRequest() {
                if (this.selectedReportId 
                    && !this.loading 
                    && this.selectedReport.milestones.data) {
                    let milestones = Object.keys(this.selectedReport.milestones.data);

                    if (milestones.length) {
                        return milestones.join(', ');
                    } else {
                        return '';
                    }
                }
            },
            commercialType() {
                return this.selectedEntityType === 'Commercial' || this.savedEntityType === 'Commercial';
            },
            reportTableHeight() {
                if (this.reportRecords.length !== this.perPage && !this.loading) {
                    return;
                } 
                
                switch(this.perPage) {
                    case 5:
                        return '310px';
                    case 10:
                        return '588px';
                    case 20:
                        return '1148px';
                    default:
                        return '310px';
                }
            }
        },
        watch: {
            isModuleGrouping(val) {
                if (val) {
                    this.getReports();
                }
            },
            "selectedReport.id": function(val) {
                if (val && this.isModuleGrouping) {
                    this.getReports();
                }
            }
        },
        methods: {
            scrollTo(element, scrollPixels, duration) {
                const scrollPos = element.scrollLeft;
                if ( !((scrollPos === 0 || scrollPixels > 0) 
                        && (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0)))
                {
                    const startTime =
                        'now' in window.performance
                            ? performance.now()
                            : new Date().getTime();

                    function scroll(timestamp) {
                        const timeElapsed = timestamp - startTime;
                        const progress = Math.min(timeElapsed / duration, 1);
                        element.scrollLeft = scrollPos + scrollPixels * progress;
                        if (timeElapsed < duration) {
                            window.requestAnimationFrame(scroll);
                        } else {
                            return;
                        }
                    }

                    window.requestAnimationFrame(scroll);
                }
            },
            swipeNav(rotation) {
                const content = this.$refs.content;
                this.scrollTo(content, rotation == 'left' ? -100 : 100, 800);
            },
            checkDueTime(time) {
                let getTime = moment.utc(time, 'DD MMM, YYYY hh:mm [at] a').local().format('DD MMM, YYYY [at] hh:mm A');
                let todayDiff = moment().diff(moment(time));
                
                if (getTime === 'Invalid date') {
                    return `${todayDiff < 0 ? 'Past Due' : 'Due'}: ${time}`;
                } else {
                    return `${todayDiff < 0 ? 'Past Due' : 'Due'}: ${getTime}`;
                }
            },
            openOverviewPage(report) {
                let routeData;

                if (report.entity_type == BASE_ENTITY_TYPES.RESIDENTIAL) {
                    routeData = this.$router.resolve({
                        name: 'Overview', 
                        params: { lead_id: report.id }
                    });
                }

                if (report.entity_type == BASE_ENTITY_TYPES.COMMERCIAL_LEAD) {
                    routeData = this.$router.resolve({ 
                        name: 'CommercialLeadOverview', 
                        params: { lead_id: report.id }
                    });
                }

                if (report.entity_type == BASE_ENTITY_TYPES.COMMERCIAL_JOB) {
                    routeData = this.$router.resolve({ 
                        name: 'CommercialJobOverview',
                        params: { 
                            job_id: report.id,
                            property_id: report.property_id
                        }
                    });
                }

                window.open(routeData.href, '_blank');
            },
            changeSort(sort_by) {
                let sortParamsId = this.REPORT_COLUMNS[sort_by].id;
                this.sortColumn = sort_by;

                if (this.sortBy.id === sortParamsId) {
                    this.orderBy = this.orderBy === this.ORDER_BY.ASC ? this.ORDER_BY.DESC : this.ORDER_BY.ASC;
                } else {
                    this.sortBy.id = sortParamsId;
                    this.orderBy = this.ORDER_BY.ASC;
                }
                
                this.clearIndexedDB();
                this.sortLoading = true;
                this.getReportRecords(this.sortBy.id);
            },
            setActiveReport(report) {
                this.filters.milestone.value = null;
                this.selectedMilestoneSet = null;
                this.selectedMilestone = null;
                this.selectedEntityType = '';
                this.dashboardTableHeader = [];
                this.dashboardData = [];
                this.selectedReport = report;
                this.filters.milestone.value = this.filtersParamsForRequest;
                this.page = 1;
                this.getReportsFromIndexedDB();
                this.saveDashbordSate();
            },
            setMilestone(key, moduleType) {
                this.selectedEntityType = moduleType;
                this.selectedMilestone = null;
                this.filters.milestone.value = key;
                this.selectedMilestone = key;
                this.page = 1;
                this.getReportsFromIndexedDB();
                this.saveDashbordSate(moduleType);
                this.savedEntityType = '';
            },
            loadPage(page) {
                this.page = page;
                this.savePerPageToLocalStorage();
                this.saveDashbordSate(this.savedEntityType);
                this.getReportsFromIndexedDB();
            },
            goToPage() {
                if (!this.goToPageCount) {
                    return;
                }
                if (this.goToPageCount > this.totalPages || this.goToPageCount < 0) {
                    this.notifyError('This page number does not exist');
                    return;
                }
                this.page = this.goToPageCount;
                this.getReportsFromIndexedDB();
                this.saveDashbordSate();

                setTimeout(() => { this.goToPageCount = '' }, 1000);
            },
            /*
             * Getting reports with all records without pagination for
             * correct rendering milestones in reports when applied
             * module grouping (Commercial and Residential).
             */
            getReports() {
                this.allReportLoading = true;
                let params = cloneDeep(this.selectedReport);
                this.groupingMilestoneSet = [];

                api.post(`/reports/build`, params)
                    .then(response => response.data.data)
                    .then(data => {
                        this.groupingMilestoneSet = data.body.data;
                        this.dashboardTableHeader = data.head;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error)
                    })
                    .finally(() => {
                        this.sortLoading = false;
                        this.allReportLoading = false;
                    })
            },
            // Getting report records with pagination.
            getReportRecords(sortBy) {
                if (!sortBy) {
                    this.loading = true;
                }

                let params = cloneDeep(this.selectedReport);

                if (this.filters.milestone.value) {
                    params.filters.push(this.filters.milestone);
                }

                if (this.selectedEntityType === 'Residential') {
                    params.filters.push({
                        column_id: REPORT_COLUMNS.CLASS_NAMESPACE.id,
                        condition: "is",
                        match: "and",
                        value: this.MODEL_CLASSES.RESIDENTIAL_LEAD
                    });
                }

                if (this.selectedEntityType === 'Commercial') {
                    params.filters.push({
                        column_id: REPORT_COLUMNS.CLASS_NAMESPACE.id,
                        condition: "is",
                        match: "and",
                        value: this.MODEL_CLASSES.COMMERCIAL_JOB.JOB + ', ' + this.MODEL_CLASSES.COMMERCIAL_LEAD
                    });
                }

                if (params.filters.length) {
                    params.filters.forEach((el, index) => {
                        if (index == 0) {
                            params.patternVal = '1';
                        } else {
                            params.patternVal += ` and ${index +1}`;
                        }
                    });
                }

                params.showTotalPages = true;
                params.sorts = [];
                params.sorts.push({
                    "column_id": this.sortBy.id,
                    "direction": this.orderBy
                });
                params.showColors = true;

                api.post(`/reports/build?perPage=${this.perPage}&page=${this.page}`, params)
                    .then(response => response.data.data)
                    .then(data => {
                        this.allReportForReportTab(data.body.data);
                        this.dashboardTableHeader = data.head;
                        this.totalPages = data.body.totalPages;
                        this.totalItems = data.body.totalCount;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.sortLoading = false;
                        this.loading = false;
                        this.addReportParts();
                    })
            },
            /* It is first request. Getting all parameters for dashboard reports
             * which be passing in second request for report building.
             */
            getDashboardReportsParams() {
                this.loading = true;
                if (this.milestonesSetLoaded) { 
                    return;
                }

                api.get('/reports/dashboard')
                    .then((response) => {
                        this.milestonesSetLoaded = true;
                        this.dashboardReportParamsForBuilder = response.data.data;
                        this.getSavedDashbordState();
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => this.createIndexedDBSchema());
            },
            customLabel({ first_name, last_name }) {
                return `${first_name} – ${last_name}`;
            },
            getStatusMarker(title) {
                if (title.length < 2) {
                    return 'MI';
                } 
                return getAbbreviation(title);
            },
            hexToRgba(hex, alpha) {
                if (!hex) { return }

                hex = hex.replace('#', '');
                let r = parseInt(hex.length == 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
                let g = parseInt(hex.length == 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
                let b = parseInt(hex.length == 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);

                return `rgba(${r},${g},${b},${alpha})`;
            },
            saveDashbordSate(moduleType = '') {
                let milestoneName = '';
                milestoneName = moduleType ? `${moduleType}-${this.selectedMilestone}` : this.selectedMilestone;

                const params = {
                    "report_id" : this.selectedReport ? this.selectedReport.id : 0,
                    "milestone": this.selectedMilestone ? milestoneName : '',
                    "page": this.page ? this.page : 1
                }

                api.post('/reports/pin', params)
                    .then(response => response.data.data)
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            getSavedDashbordState() {
                api.get('/reports/pin')
                    .then(response => {
                        this.savedDashbordState = response.data.data;

                        let savedReport = this.dashboardReportParamsForBuilder.find(el => {
                            return el.id === this.savedDashbordState.report_id;
                        });

                        if (!this.savedDashbordState.report_id || savedReport === undefined) {
                            this.selectedReport = this.dashboardReportParamsForBuilder[0];
                        } else {
                            this.selectedReport = savedReport;

                            if (this.isModuleGrouping) {
                                const milestoneNameLength = this.savedDashbordState.milestone.length;
                                let separatorIndex = this.savedDashbordState.milestone.indexOf('-');
                                let milestone = this.savedDashbordState.milestone.substring(separatorIndex + 1, milestoneNameLength);
                                this.savedEntityType = this.savedDashbordState.milestone.substring(0, separatorIndex);
                                this.selectedMilestone = milestone;
                                this.filters.milestone.value = milestone;
                            } else {
                                this.selectedMilestone = this.savedDashbordState.milestone;
                                this.filters.milestone.value = this.savedDashbordState.milestone;
                            }
                            
                            this.page = this.savedDashbordState.page;
                        }
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error)
                    })
                    .finally(() => {
                        this.loading = false;
                        this.getReportRecords();
                    })
            },
            savePerPageToLocalStorage() {
                localStorage.setItem('perPageOptionForDashboard', this.perPage);
            },
            getPerPageFromLocalStorage() {
                this.perPage = Number(localStorage.getItem('perPageOptionForDashboard')) || this.perPage;
            },
            getResidentialLeadsCounts(milestone) {
                if (milestone.data[this.MODEL_CLASSES.RESIDENTIAL_LEAD]) {
                    return milestone.data[this.MODEL_CLASSES.RESIDENTIAL_LEAD].count;
                } else {
                    return false;
                }
            },
            getCommercialLeadsCounts(milestone) {
                let commonCounter = 0;

                if (milestone.data && milestone.data[this.MODEL_CLASSES.COMMERCIAL_LEAD]) {
                    commonCounter = milestone.data[this.MODEL_CLASSES.COMMERCIAL_LEAD].count;
                }

                if (milestone.data && milestone.data[this.MODEL_CLASSES.COMMERCIAL_JOB.JOB]) {
                    commonCounter += milestone.data[this.MODEL_CLASSES.COMMERCIAL_JOB.JOB].count;
                }

                return commonCounter;
            },
            getColor(milestone) {
                let color = '';

                if (milestone.data && milestone.data[this.MODEL_CLASSES.COMMERCIAL_LEAD]) {
                    color = milestone.data[this.MODEL_CLASSES.COMMERCIAL_LEAD].data[0].milestone_color;
                }

                if (milestone.data && milestone.data[this.MODEL_CLASSES.COMMERCIAL_JOB.JOB]) {
                    color = milestone.data[this.MODEL_CLASSES.COMMERCIAL_JOB.JOB].data[0].milestone_color;
                }

                if (milestone.data && milestone.data[this.MODEL_CLASSES.RESIDENTIAL_LEAD]) {
                    color = milestone.data[this.MODEL_CLASSES.RESIDENTIAL_LEAD].data[0].milestone_color;
                }

                return color;
            },
            /* Gets value for table cell based on header name cell
             * and field excerpt in REPORT_COLUMNS constants.
             */
            getReportField(report, id) {
                let header = this.relationTableHeaderAndBody[id];
                let field;

                switch (header) {
                    case 'ZIP_CODE': 
                        field = this.REPORT_COLUMNS.ZIP.excerpt;
                        break;
                    case 'ESTIMATES_PRICE_TOTAL':
                        field = this.REPORT_COLUMNS.ESTIMATES_SIGNED_PRICE_TOTAL.excerpt;
                        break;
                    case 'LEAD/JOB_TYPE':
                        field = this.REPORT_COLUMNS.LEAD_TYPE.excerpt;
                        break;
                    case 'ENTITY_TYPE':
                        field = this.REPORT_COLUMNS.CLASS_NAMESPACE.excerpt;
                        break;
                    default:
                        field = this.REPORT_COLUMNS[header].excerpt;
                }

                return report[field] ? report[field] : '-';
            },
            allReportForReportTab(data) {
                this.dashboardData = [];

                for (const key in data) {
                    if (Object.hasOwnProperty.call(data, key)) {
                        this.dashboardData = this.dashboardData.concat(data[key].data);
                    }
                }
            },
            tableCellWidth(cell) {
                if (cell === this.SPECIAL_HEADER_NAME.JOB_NAME) {
                    return '30%';
                }

                if (this.dScreen || this.nScreen) {
                    return `calc(100%/${this.tableHeader.length + 2})`;
                }
            },
            createIndexedDBSchema() {
                let db;
                let dbReq = indexedDB.open('DashboardJobsReport', 1);

                dbReq.onupgradeneeded = (event) => {
                    // Save link on DB
                    db = event.target.result;
                    // Create an object store or get it if it already exists.
                    this.dashboardReportParamsForBuilder.forEach(r => {
                        let reportTab;
                        if (!db.objectStoreNames.contains(r.title)) {
                            reportTab = db.createObjectStore(r.title);
                        } else {
                            reportTab = dbReq.transaction.objectStore(r.title);
                        }
                        // Create timestamp index
                        if (!reportTab.indexNames.contains('timestamp')) {
                            reportTab.createIndex('timestamp', 'timestamp');
                        }
                    });
                }

                dbReq.onsuccess = (event) => {}

                dbReq.onerror = (event) => {
                    this.notifyError(`Initialization Indexed DB: ${event.target.error.message}`);
                }
            },
            makeReportKey() {
                let entityTypeKey = this.selectedEntityType ? `${this.selectedEntityType} - ` : '';
                let milestoneKey = this.selectedMilestone 
                    ? this.selectedMilestone 
                    : this.selectedReport.title;
                return `${entityTypeKey}${milestoneKey}`;
            },
            addReportParts() {
                let db;
                let dbReq = indexedDB.open('DashboardJobsReport', 1);

                dbReq.onsuccess = (event) => {
                    db = event.target.result;
                    // Let's start a Indexed DB transaction and get an object store.
                    let tx = db.transaction([this.selectedReport.title], 'readwrite');
                    let store = tx.objectStore(this.selectedReport.title);
                    // Get all records from the storage by key. The key is the chosen milestone.
                    let reportKey = this.makeReportKey();
                    let allRecords = store.get(reportKey);

                    allRecords.onsuccess = (event) => {
                        let allReports = event.target.result;
                        let reportParts = {
                            [this.page]: this.dashboardData,
                            timestamp: Date.now()
                        };
                        reportParts.totalPages = this.totalPages;
                        reportParts.totalItems = this.totalItems;

                        if (!this.selectedMilestone) {
                            reportParts.tableHeader = this.dashboardTableHeader;
                        }

                        if (allReports) {
                            let timeMark = allRecords.result.timestamp;

                            // Delete reports if timestamp has been expired.
                            if (Date.now() - timeMark > 300000) {
                                const req = store.getKey(reportKey);

                                req.onsuccess = (event) => {  
                                    const key = req.result;
                                    // Delete all records from the storage by key.
                                    let deleteRequest = store.delete(key);

                                    deleteRequest.onsuccess = (event) => {
                                        // Save curent page.
                                        store.add(reportParts, reportKey);
                                    };
                                };
                            }

                            // Save report to Indexed DB.
                            let data = allRecords.result;

                            if (!([this.page] in data)) {
                                data[this.page] = this.dashboardData;
                            }
                            store.put(data, reportKey);
                        } else {
                            store.add(reportParts, reportKey);
                        }
                    }
                    // Handle error
                    allRecords.onerror = (event) => {
                        this.notifyError(`Indexed DB Error: ${event.target.error.message}`);
                    }
                    
                    tx.oncomplete = () => {}

                    tx.onerror = (event) => {
                        this.notifyError(`Indexed DB Error: ${event.target.error.message}`);
                    }
                }
            },
            getReportsFromIndexedDB() {
                let db;
                let dbReq = indexedDB.open('DashboardJobsReport', 1);

                dbReq.onsuccess = (event) => {
                    db = event.target.result;
                    // Let's start a Indexed DB transaction and get an object store.
                    let tx = db.transaction([this.selectedReport.title], 'readwrite');
                    let store = tx.objectStore(this.selectedReport.title);
                    // Get all records from the storage by key. The key is the chosen milestone.
                    let reportKey = this.makeReportKey();
                    let allRecords = store.get(reportKey);

                    allRecords.onsuccess = (event) => {
                        let allReports = event.target.result;

                        if (allReports) {
                            let timeMark = allRecords.result.timestamp;

                            // If timestamp has been expired or reports not found, get reports from API.
                            // Else get report from Indexed Db.
                            if (Date.now() - timeMark > 300000 || !allReports[this.page]) {
                                this.reportFromDB = [];
                                this.getReportRecords();
                            } else {
                                this.reportFromDB = allReports;
                                this.dashboardData = this.reportFromDB[this.page];

                                if (!this.selectedMilestone) {
                                    this.dashboardTableHeader = this.reportFromDB.tableHeader;
                                }

                                this.totalPages = this.reportFromDB.totalPages;
                                this.totalItems = this.reportFromDB.totalItems;
                            }
                        } else {
                            // If reports by key does not exist, get reports from API.
                            this.reportFromDB = [];
                            this.getReportRecords();
                        }
                    }
                    // Handle error
                    allRecords.onerror = (event) => {
                        this.notifyError(`Indexed DB Error: ${event.target.error.message}`);
                    }
                    
                    tx.oncomplete = (event) => {}

                    tx.onerror = (event) => {
                        this.notifyError(`Indexed DB Error: ${event.target.error.message}`);
                    }
                }
            },
            clearStoredReport() {
                let db;
                let dbReq = indexedDB.open('DashboardJobsReport', 1);

                dbReq.onsuccess = (event) => {
                    db = event.target.result;
                    // Let's start a Indexed DB transaction and get an object store.
                    let tx = db.transaction([this.selectedReport.title], 'readwrite');
                    let store = tx.objectStore(this.selectedReport.title);
                    // Get all records from the storage by key. The key is the chosen milestone.
                    let reportKey = this.makeReportKey();
                    let allRecords = store.get(reportKey);

                    allRecords.onsuccess = (event) => {
                        const req = store.getKey(reportKey);
                        req.onsuccess = (event) => {  
                            const key = req.result;
                            // Delete all records from the storage by key.
                            let deleteRequest = store.delete(key);
                        };
                    }
                    // Handle error
                    allRecords.onerror = (event) => {
                        this.notifyError(`Indexed DB Error: ${event.target.error.message}`);
                    }

                    tx.oncomplete = () => {}

                    tx.onerror = (event) => {
                        this.notifyError(`Indexed DB Error: ${event.target.error.message}`);
                    }
                }
            },
            clearIndexedDB() {
                this.reportFromDB = [];
                this.clearStoredReport();
            }
        },
        created() {
            this.getPerPageFromLocalStorage();
            this.getDashboardReportsParams();
        },
        beforeDestroy() {
            indexedDB.deleteDatabase('DashboardJobsReport');
        }
    }
</script>