<template>
    <div :class="dScreen ? 'z-index-2 form-builder-nav' : 'w-100 form-builder-nav'">
        <aside :class="dScreen ? 'h-100 w-100' : 'w-100'">
            <div class="sidebar-group column-static mb-5">
                <div class="row-between mb-8">
                    <div>
                        <span class="f-16-darkgrey f-sbold p-4">Reports</span>
                        <span
                            v-if="countReports"
                            class="badge-content f-11-black f-sbold"
                        >
                            {{ countReports }}
                        </span>
                    </div>
                    <div class="form-builder-button s-align-6 ph-4">
                        <router-link
                            class="t-right w-100 f-13-blue"
                            :to="{ name: reportListRoute }"
                        >
                            View All
                        </router-link>
                    </div>
                </div>
                <template v-for="(page, index) in pages">
                    <div
                        @click="changePage(page)"
                        :key="index"
                        :class="[
                            'sidebar-item f-13-grey f-sbold p-4 pointer',
                            { 'active br-5': page.name == currentPage.name }
                        ]"
                    >
                        {{ page.name }}
                    </div>
                </template>
            </div>
        </aside>
    </div>
</template>

<script>
    import api from '@/headers.js';
    import { ENTITY_TYPES, MODEL_NAMES } from '@/constants.js';

    export default {
        name: 'FormBuilderNavPanel',
        props: {
            entityType: {
                type: String,
                required: true
            },
            entityId: {
                type: [String, Number],
                required: true
            },
            countReports: {
                type: Number
            }
        },
        data() {
            return {
                pages: [],
                currentPage: '',
                active: null,
                scrollToStep: 0
            }
        },
        computed: {
            reportListRoute() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: {
                        return 'ResidentialFormBuilderReportList';
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_JOB: {
                        return 'CommercialJobFormBuilderReportList';
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return 'CommercialLeadFormBuilderReportList';
                    } break;
                }
            }
        },
        methods: {
            getPages() {
                api.get(`/layouts/model-name?model_name=${MODEL_NAMES.REPORT}`)
                    .then(response => {
                        this.pages = response.data.data; 

                        if (this.pages.length) {
                            this.checkCurrentPage();
                        } else {
                            this.$emit('changeReport', null);
                        }
                    });
            },
            changePage(page) {
                this.$store.commit('formBuilder/GET_COUNT_ATTACHMENT', 0);
                if (page.name === this.currentPage.name) return false;

                this.currentPage = page;
                this.$router.push({ query: { report: page.name }});
                this.$emit('changeReport', page);
            },
            checkCurrentPage() {
                this.currentPage = this.pages.find(page => page.name === this.$route.query.report);
                if (!this.currentPage) {
                    this.$router.push({query: {report: this.pages[0].name}});
                    this.currentPage = this.pages[0];
                }
                this.$emit('changeReport', this.currentPage);

                this.scrollToStep = this.pages.findIndex(c => c.id === this.currentPage.id);
            },
        },
        created() {
            this.getPages();
            this.$store.dispatch('formBuilder/GET_REPORT_PHOTO', { 
                entityType: this.entityType, 
                entityId: this.entityId 
            });
            this.$store.dispatch('formBuilder/GET_TECHNICIAN_INFO', { 
                entityType: this.entityType, 
                entityId: this.entityId 
            });
        }
    }
</script>