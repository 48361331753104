<template>
    <svg
        :width="size"
        :height="size"
        viewBox="0 0 36 15"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="0.348999" y="0.24781" width="34.8621" height="14.2618" rx="1.58464" fill="#2ECC71"/>
        <path d="M18.6745 4.67787C18.2232 4.67787 17.8737 4.84809 17.6261 5.18854C17.3785 5.52641 17.2547 5.9984 17.2547 6.60451C17.2547 7.86572 17.728 8.49633 18.6745 8.49633C19.0717 8.49633 19.5527 8.39703 20.1176 8.19843V9.20431C19.6533 9.39775 19.1349 9.49447 18.5623 9.49447C17.7396 9.49447 17.1103 9.24558 16.6744 8.7478C16.2385 8.24744 16.0206 7.53043 16.0206 6.59677C16.0206 6.00872 16.1276 5.49417 16.3417 5.05314C16.5557 4.60952 16.8627 4.27036 17.2624 4.03565C17.6648 3.79837 18.1355 3.67973 18.6745 3.67973C19.2239 3.67973 19.7758 3.81255 20.3304 4.07821L19.9435 5.05314C19.732 4.95255 19.5192 4.86486 19.3051 4.79006C19.0911 4.71526 18.8809 4.67787 18.6745 4.67787ZM23.9786 9.41709L23.7503 8.82904H23.7194C23.5208 9.07922 23.3157 9.25331 23.1043 9.35132C22.8953 9.44675 22.6219 9.49447 22.2841 9.49447C21.8688 9.49447 21.5413 9.37582 21.3014 9.13854C21.0641 8.90126 20.9455 8.56339 20.9455 8.12493C20.9455 7.66583 21.1054 7.32796 21.4252 7.11131C21.7476 6.89208 22.2325 6.77086 22.8799 6.74765L23.6304 6.72444V6.53487C23.6304 6.09641 23.406 5.87718 22.9572 5.87718C22.6116 5.87718 22.2054 5.98164 21.7386 6.19055L21.3478 5.39359C21.8456 5.13309 22.3976 5.00284 23.0037 5.00284C23.584 5.00284 24.0289 5.12922 24.3384 5.38198C24.6479 5.63474 24.8026 6.01903 24.8026 6.53487V9.41709H23.9786ZM23.6304 7.41308L23.1739 7.42855C22.8309 7.43887 22.5755 7.50077 22.4079 7.61425C22.2402 7.72773 22.1564 7.90054 22.1564 8.13266C22.1564 8.46538 22.3473 8.63173 22.729 8.63173C23.0024 8.63173 23.2203 8.55307 23.3828 8.39574C23.5479 8.23841 23.6304 8.0295 23.6304 7.769V7.41308ZM27.2013 9.41709H26.0213V3.39731H27.2013V9.41709ZM29.6231 9.41709H28.4431V3.39731H29.6231V9.41709Z" fill="white"/>
        <path d="M12.916 10.0055L11.7989 11.1136C11.634 11.2828 11.4155 11.34 11.2026 11.3403C10.2613 11.3121 9.37147 10.8497 8.6409 10.3749C7.44171 9.50248 6.3414 8.42073 5.65082 7.11348C5.38596 6.56531 5.07519 5.86588 5.10488 5.25401C5.10753 5.02385 5.16955 4.79795 5.33166 4.64959L6.44874 3.53307C6.6807 3.33576 6.90503 3.40398 7.05347 3.6338L7.95217 5.33797C8.04676 5.53986 7.9925 5.75617 7.85137 5.90044L7.43981 6.31178C7.4144 6.34659 7.39823 6.3862 7.39782 6.42932C7.55564 7.04021 8.03374 7.60327 8.4561 7.99076C8.87846 8.37826 9.33244 8.90323 9.92174 9.02752C9.99459 9.04783 10.0838 9.05497 10.1359 9.00653L10.6147 8.51963C10.7797 8.39454 11.018 8.3334 11.1942 8.43568H11.2026L12.8236 9.39269C13.0616 9.54186 13.0864 9.83013 12.916 10.0055Z" fill="white"/>
    </svg>

</template>

<script>
    export default {
        name: 'CallBtn',
        props: {
            size: {
                type: [Number, String],
                default: 30
            }
        }
    }
</script>
