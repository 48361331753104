<template>
    <button 
        @click="scrollToTop" 
        class="to-top-button"
        ref="scrollButton"
    >
        <arrow-up-icon class="i-white" />
    </button>
</template>

<script>
import { ArrowUpIcon } from '@/icons';

export default {
    name: 'ScrollToTopButton',
    components: { ArrowUpIcon },
    methods: {
        scrollToTop() {
            document.querySelector('#app').scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        },
        showBtn() {
            const btn = this.$refs.scrollButton;
            if(window.scrollY > 700) {
                btn.classList.add('show-button');
            } else {
                btn.classList.remove('show-button');
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.showBtn);
    },
    destroyed() {
        window.removeEventListener('scroll', this.showBtn);
    }
}
</script>