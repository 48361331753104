var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'calendar-event-popup',
        _vm.popupOffset.offsetLeft > _vm.popupOffset.offsetRight 
            ? 'calendar-event-popup-triangle-right' 
            : 'calendar-event-popup-triangle-left',
        _vm.popupOffset.offsetTop < _vm.popupOffset.offsetBottom 
            ? 'calendar-event-popup-triangle-top' 
            : 'calendar-event-popup-triangle-bottom' ],style:(_vm.popupPosition)},[(_vm.calendarType !== _vm.CALENDAR_TYPES.JOB)?_c('event-edit',{attrs:{"newEventStart":_vm.newEventStart,"newEventEnd":_vm.newEventEnd,"calendarList":_vm.calendarList,"reAssignMode":false,"calendarId":_vm.calendarId,"calendarType":_vm.calendarType,"userId":_vm.userId,"eventAssignTechnick":_vm.eventAssignTechnick},on:{"closeNewEventPopup":_vm.closeNewEventPopup}}):_c('order-create-popup',{attrs:{"job":_vm.job},on:{"closeNewEventPopup":_vm.closeNewEventPopup}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }