<template>
    <div class="row-start mb-20">
        <div class="lg-5 md-12 sm-12 order-preview-details column-start">
            <p class="f-24-black f-sbold mb-30">Order Details</p>
            <div class="row-start pb-30 bb-grey">
                <div class="lg-9 column-start">
                    <p class="f-14-darkgrey f-sbold mb-4">Materials Delivery</p>
                    <p class="f-14-grey mb-4">
                        Date:
                        <span v-if="order.delivery_date" class="f-14-black">
                            {{ order.delivery_date | date(false) }}
                        </span>
                        <span v-else class="f-italic f-14-grey">
                            {{ no_info }}
                        </span>
                    </p>
                    <p class="f-14-grey mb-4">
                        Time:
                        <span class="f-14-black">
                            {{ order.delivery_time }}
                        </span>
                    </p>
                    <p class="f-14-grey mb-4">
                        Method:
                        <span class="f-14-black">
                            {{ order.receiving }}
                        </span>
                    </p>
                    <p class="f-14-grey mb-4">
                        Confirm:
                        <span class="f-14-black">
                            {{ order.assign.fullname }}
                        </span>
                    </p>
                    <div v-if="order.vendor">
                        <p class="f-14-grey mb-4">
                            Vendor:
                            <span class="f-14-black">
                                {{ order.vendor_name }}
                            </span>
                        </p>
                        <p
                           class="f-14-grey mb-4"
                           v-if="order.vendor.email"
                        >
                            Vendor email:
                            <span class="f-14-black">
                                {{ order.vendor.email }}
                            </span>
                        </p>
                        <p
                            class="f-14-grey mb-4"
                            v-if="order.vendor.address"
                        >
                            Vendor address:
                            <a
                                :href="getGoogleMapLink(order.vendor.address.lat, order.vendor.address.lng)"
                                :class="[
                                    'f-14-blue',
                                    {'none-events': !getGoogleMapLink(order.vendor.address.lat, order.vendor.address.lng)}
                                ]"
                                target="_blank"
                            >
                                {{ getAddress(order.vendor.address) }}
                                <link-icon 
                                    v-if="getGoogleMapLink(order.vendor.address.lat, order.vendor.address.lng)"
                                    class="i-blue"
                                />
                            </a>
                        </p>
                    </div>
                    <div v-else>
                        <p class="f-14-grey mb-4">
                            Vendor:
                            <span class="f-italic f-14-grey">
                                {{ no_info }}
                            </span>
                        </p>
                    </div>
                </div>
                <div class="lg-3 s-align-3">
                    <button
                        class="edit-button"
                        @click="$emit('goToStep', 5)"
                    >
                        <edit-icon class="i-blue mr-4"/>
                        <span class="f-12-blue f-sbold">Edit</span>
                    </button>
                </div>
            </div>
            <div class="row-start pv-30 bb-grey">
                <div class="lg-9 column-start">
                    <p class="f-14-darkgrey f-sbold mb-4">Scheduled labor</p>
                    <p class="f-14-grey mb-4">
                        Crew:
                        <span v-if="order.crew_name" class="f-14-black">
                            {{ order.crew_name }}
                        </span>
                        <span v-else class="f-italic f-14-grey">
                            {{ no_info }}
                        </span>
                    </p>
                    <p class="f-14-grey mb-4">
                        Date:
                        <span
                            class="f-14-black"
                            v-if="order.labor_delivery_date_from && order.labor_delivery_date_to"
                        >
                            from
                            {{ order.labor_delivery_date_from | monthDayYear }}
                            to
                            {{ order.labor_delivery_date_to | monthDayYear }}
                        </span>
                        <span v-else class="f-italic f-14-grey">
                            {{ no_info }}
                        </span>
                    </p>
                    <p class="f-14-grey">
                        Time:
                        <span
                            class="f-14-black"
                            v-if="order.labor_delivery_time_from && order.labor_delivery_time_to"
                        >
                            from
                            {{ order.labor_delivery_time_from | onlyTime }}
                            to
                            {{ order.labor_delivery_time_to | onlyTime }}
                        </span>
                        <span
                            class="f-14-black"
                            v-else-if="order.labor_all_day_working"
                        >
                            All Day
                        </span>
                        <span v-else class="f-italic f-14-grey">
                            {{ no_info }}
                        </span>
                    </p>
                </div>
                <div class="lg-3 s-align-3">
                    <button
                        class="edit-button"
                        @click="$emit('goToStep', 6)"
                    >
                        <edit-icon class="i-blue mr-4"/>
                        <span class="f-12-blue f-sbold">Edit</span>
                    </button>
                </div>
            </div>
            <div class="row-start pt-30" v-if="leadPreview">
                <div class="lg-12 column-start">
                    <p class="f-14-darkgrey f-sbold mb-4">Customer</p>
                    <p class="f-14-grey mb-4">
                        Name:
                        <span class="f-14-black">
                            {{ leadPreview.fullname || leadPreview.name }}
                        </span>
                    </p>
                    <p class="f-14-grey mb-4">
                        Trade:
                        <span class="f-14-black">
                            {{ order.trade.name }}
                        </span>
                    </p>
                    <p class="f-14-grey">
                        Address:
                        <a
                            :href="getGoogleMapLink(order.address.lat, order.address.lng)"
                            :class="[
                                'f-14-blue',
                                {'none-events': !getGoogleMapLink(order.address.lat, order.address.lng)}
                            ]"
                            target="_blank"
                        >
                            {{ getAddress(order.address) }}
                            <link-icon 
                                v-if="getGoogleMapLink(order.address.lat, order.address.lng)"
                                class="i-blue"
                            />
                        </a>
                    </p>
                </div>
            </div>
        </div>
        <div class="lg-7 md-12 sm-12 order-total-cost column-start pl-1">
            <div class="total-cost-header w-100 mb-30">
                <div class="row-start mb-2">
                    <div class="lg-7 column-start">
                        <span class="f-24-black f-sbold">
                            {{ totalOrderCost | currency }}
                        </span>
                        <span class="f-13-grey">Total order cost</span>
                    </div>
                    <div class="lg-5 s-align-3">
                        <button
                            class="edit-button"
                            @click="$emit('goToStep', 4)"
                        >
                            <edit-icon class="i-blue mr-4"/>
                            <span class="f-12-blue f-sbold">Edit order</span>
                        </button>
                    </div>
                </div>
                <div class="tabs-menu">
                    <div class="tabs-menu-panel row-start">
                        <button
                            v-if="order.has_material"
                            :class="[
                                'tabs-menu-item h-100 transparent-button',
                                { 'tabs-menu-item-active' : activeTab === TABS.MATERIAL }
                            ]"
                            @click="activeTab = TABS.MATERIAL"
                        >
                            Material
                        </button>
                        <button
                            v-if="order.has_labor"
                            :class="[
                                'tabs-menu-item h-100 transparent-button',
                                { 'tabs-menu-item-active' : activeTab === TABS.LABOR }
                            ]"
                            @click="activeTab = TABS.LABOR"
                        >
                            Labor
                        </button>
                    </div>
                </div>
            </div>
            <div class="w-100 ph-20">
                <div
                    v-if="activeTab === TABS.MATERIAL"
                    class="row-between"
                >
                    <div class="lg-9">
                        <span class="f-16-black f-sbold">Material order</span>
                    </div>
                    <div class="lg-3 s-align-3">
                        <span class="f-18-black f-sbold">
                            {{ materialsTotalOrder | currency }}
                        </span>
                    </div>
                </div>
                <div
                    v-if="activeTab === TABS.LABOR"
                    class="row-between"
                >
                    <div class="lg-9">
                        <span class="f-16-black f-sbold">Labor order</span>
                    </div>
                    <div class="lg-3 s-align-3">
                        <span class="f-18-black f-sbold">
                            {{ laborsTotalOrder | currency }}
                        </span>
                    </div>
                </div>
                <div
                    v-for="(item, index) in visibleItems"
                    :key="index"
                    :class="[
                        'row-start pv-4',
                        { 'bb-grey' : index !== visibleItems.length - 1 }
                    ]"
                >
                    <div class="w-5 s-align-2">
                        <button
                            v-if="activeTab === TABS.MATERIAL && item.description"
                            class="transparent-button p-3"
                            @click="openedItemIndex = openedItemIndex === index ? null : index"
                        >
                            <arrow-icon
                                class="i-grey"
                                :transform="openedItemIndex === index ? 270 : 90"
                            />
                        </button>
                    </div>
                    <div class="row-start w-95 pl-2">
                        <div class="lg-7">
                            <span class="f-14-black">{{ item.name }}</span>
                        </div>
                        <div class="lg-2 s-align-6">
                            <span class="f-14-black">{{ item.quantity }}/</span>
                            <span class="f-14-grey">{{ item.per_unit }}</span>
                        </div>
                        <div class="lg-3 column-end">
                            <span class="f-16-black f-sbold">
                                {{ (Number(item.cost) * item.quantity).toFixed(2) | currency }}
                            </span>
                            <span class="f-14-black">
                                {{ item.cost }}
                                <span class="f-14-grey">
                                    /{{ item.per_unit }}
                                </span>
                            </span>
                        </div>
                        <div
                            v-if="openedItemIndex === index && item.description"
                            class="lg-12 mt-1"
                        >
                            <p class="f-12-grey">
                                {{ item.description }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <loader
            v-if="requestIsGoing"
            size="full"
        />
    </div>
</template>

<script>
    import {EditIcon, ArrowIcon, LinkIcon} from '@/icons';
    import {MATERIAL, LABOR} from '@/constants';
    import {showFullAddress, getGoogleMapLink} from '@/helpers';
    import {Loader} from '@/ui';
    import {ENTITY_TYPES} from '@/constants.js';

    export default {
        name: 'OrderCreateStep7',
        components: {
            EditIcon,
            ArrowIcon,
            LinkIcon,
            Loader
        },
        data() {
            return {
                ITEM_TYPES: {
                    MATERIAL: MATERIAL,
                    LABOR: LABOR
                },
                TABS: {
                    MATERIAL: 'material',
                    LABOR: 'labor'
                },
                activeTab: '',
                openedItemIndex: null,
                requestIsGoing: false,
                no_info: 'No information',
            }
        },
        props: {
            entityType: {
                type: String,
                default: null
            }
        },
        computed: {
            order() {
                return this.$store.getters['orders/getOrder'];
            },
            orderId() {
                return this.order.id;
            },
            leadPreview() {
                switch (this.entityType) {
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.$store.getters['commercial/getCommercialLeadPreview'];
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.$store.getters['commercial/getCommercialJobPreview'];
                    case ENTITY_TYPES.WARRANTY_JOB:
                        return this.$store.getters['warranty/getWarrantyJobPreview'];
                    default:
                        return this.$store.getters['lead/getLeadPreview'];
                }
            },
            totalOrderCost() {
                return this.order.items.reduce((sum, current) => {
                    return sum + Number(current.cost) * current.quantity;
                }, 0).toFixed(2);
            },
            materialsTotalOrder() {
                return this.order.items.reduce((sum, current) => {
                    if (current.type_id !== this.ITEM_TYPES.MATERIAL) {
                        return sum;
                    }

                    return sum + Number(current.cost) * current.quantity;
                }, 0).toFixed(2);
            },
            laborsTotalOrder() {
                return this.order.items.reduce((sum, current) => {
                    if (current.type_id !== this.ITEM_TYPES.LABOR) {
                        return sum;
                    }

                    return sum + Number(current.cost) * current.quantity;
                }, 0).toFixed(2);
            },
            visibleItems() {
                return this.order.items.filter(item => {
                    switch (this.activeTab) {
                        case this.TABS.MATERIAL:
                            return item.type_id === this.ITEM_TYPES.MATERIAL;
                            break;
                        case this.TABS.LABOR:
                            return item.type_id === this.ITEM_TYPES.LABOR;
                            break;
                    }
                })
            }
        },
        methods: {
            // Method imported from helpers.js
            getGoogleMapLink,
            // validation is calling in parent component, need to return promise with result
            validateFields() {
                return Promise.resolve();
            },
            getAddress(address) {
                return showFullAddress(address);
            }
        },
        created() {
            if (this.order.has_material) {
                this.activeTab = this.TABS.MATERIAL;
            } else {
                this.activeTab = this.TABS.LABOR;
            }
        }
    }
</script>
