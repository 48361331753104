<template>
    <full-modal
        class="order-create-modal"
        :withSearch="!showTemplateInfo"
        :searchRequestLength="!!needToFind.length"
        @cancelSearch="needToFind = ''"
        :full="true"
        @close="$emit('close')" 
    >
        <div slot="title">
            <button
                v-if="showTemplateInfo"
                class="transparent-button s-align-4 pv-3"
                @click="showTemplateInfo = false"
            >
                <arrow-icon
                    transform="180"
                    class="i-grey mr-2"
                />
                <span class="f-12-grey">Back to Template list</span>
            </button>
            <template v-else>Templates</template>
        </div>
        <div slot="search">
            <input
                class="w-100 ph-2"
                type="text"
                placeholder="Search template..."
                v-model="needToFind"
            />
        </div>
        <div slot="body">
            <loader v-if="loading"/>
            <template v-else-if="!showTemplateInfo">
                <template v-if="visibleTemplatesLength">
                    <div
                        v-for="(template, index) in visibleTemplates"
                        :key="index"
                        class="order-item row-between row-baseline w-100 mb-1"
                    >
                        <div class="order-item-description row-baseline">
                            <div class="lg-1">
                                <button 
                                    class="transparent-button p-3"
                                    @click="showTemplateDetails(index)"
                                >
                                    <arrow-icon 
                                        class="i-blue"
                                        transform="0"
                                    />
                                </button>
                            </div>
                            <div class="lg-11 column-start">
                                <div
                                    v-if="checkIfTemplateAdded(template.id)" 
                                    class="row-baseline mb-4"
                                >
                                    <div class="order-item-added-icon s-align-5 mr-3">
                                        <mark-icon
                                            class="i-white"
                                            size="8"
                                        />
                                    </div>
                                    <span class="f-12-green">Added</span>
                                </div>
                                <p class="f-14-black f-sbold">
                                    {{ template.name }}
                                </p>
                                <!-- TO DO -->
                                <!-- <p class="f-12-grey">
                                    Total:
                                    <span class="f-12-black f-sbold">0</span>
                                </p> -->
                            </div>
                        </div>
                        <button
                            v-if="checkIfTemplateAdded(template.id)"
                            class="add-button"
                            :disabled="requestIsGoing"
                            @click="deleteTemplateFromOrder(template.id)"
                        >
                            <span class="f-12-darkgrey">Remove</span>
                        </button>
                        <button
                            v-else
                            class="add-button"
                            :disabled="requestIsGoing"
                            @click="addTemplateToOrder(template.id)"
                        >
                            <span class="f-12-blue">Add all</span>
                        </button>
                    </div>
                </template>
                <not-found-message
                    v-else
                    custom="Nothing to display"
                />
            </template>
            <template v-else>
                <div class="row-start pb-20 bb-grey">
                    <div class="lg-8 column-start">
                        <p class="f-18-black f-sbold">
                            {{ templates[templateInfoIndex].name }}
                        </p>
                        <!-- TO DO -->
                        <!-- <p class="f-12-grey">
                            Total:
                            <span class="f-12-black f-sbold"></span>
                        </p> -->
                    </div>
                    <div class="lg-4 s-align-3">
                        <p class="f-12-grey">
                            Added templates:
                            <span class="f-12-black f-sbold">1</span>
                        </p>
                    </div>
                </div>
                <template v-if="visibleTemplateItemsLength">
                    <div
                        v-for="(item, index) in visibleTemplateItems"
                        :key="index" 
                        class="order-sub-item row-between row-baseline w-100 bb-grey"
                    >
                        <div class="order-item-description">
                            <div
                                v-if="checkIfTemplateItemAdded(item.id)" 
                                class="row-baseline mb-1"
                            >
                                <div class="order-item-added-icon s-align-5 mr-3">
                                    <mark-icon
                                        class="i-white"
                                        size="8"
                                    />
                                </div>
                                <span class="f-12-green">Added</span>
                            </div>
                            <p class="f-13-black">
                                {{ item.name }}
                            </p>
                        </div>
                        <button
                            v-if="checkIfTemplateItemAdded(item.id)"
                            class="add-button"
                            @click="deleteItemFromOrder(item.id)"
                            :disabled="requestIsGoing"
                        >
                            <span class="f-12-darkgrey">Remove</span>
                        </button>
                        <button
                            v-else 
                            class="add-button"
                            @click="addItemToOrder(item.id)"
                            :disabled="requestIsGoing"
                        >
                            <span class="f-12-blue">Add</span>
                        </button>
                    </div>
                </template>
                <not-found-message
                    v-else
                    custom="Nothing to display"
                />
            </template>
        </div>
    </full-modal>
</template>

<script>
    import { ArrowIcon, MarkIcon } from '@/icons';
    import { Loader } from '@/ui';
    import { clearArray } from '@/helpers';
    import api from '@/headers.js';

    export default {
        name: 'OrderAddFromTemplateModal',
        components: {
            FullModal: () => import('@/ui/Modal/FullModal.vue'),
            NotFoundMessage: () => import('@/components/NotFoundMessage.vue'),
            Loader,
            ArrowIcon,
            MarkIcon,
        },
        props: {
            typeId: {
                type: [String, Number],
                required: true
            },
            items: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                needToFind: '',
                showTemplateInfo: false,
                templateInfoIndex: null,
                loading: false,
                requestIsGoing: false,
                templates: [],
                templateItems: []
            }
        },
        computed: {
            orderId() {
                return this.$route.params.order_id;
            },
            visibleTemplates() {
                return this.templates.filter(t => {
                    return t.name.toLowerCase().includes(this.needToFind.toLowerCase())
                });
            },
            visibleTemplatesLength() {
                return this.visibleTemplates.length;
            },
            visibleTemplateItems() {
                return this.templateItems.filter(i => i.type_id == this.typeId);
            },
            visibleTemplateItemsLength() {
                return this.visibleTemplateItems.length;
            }
        },
        methods: {
            getTemplates() {
                this.loading = true;
                api.post(`/get-templates?existence=1&item_type=${this.typeId}`)
                    .then(response => {
                        this.templates.push(...response.data.data.data);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            getTemplateDetails() {
                clearArray(this.templateItems);

                this.loading = true;
                api.get(`/templates/${this.templates[this.templateInfoIndex].id}`)
                    .then(response => response.data.data)
                    .then(data => {
                        this.templateItems.push(...data.items);
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            showTemplateDetails(index) {
                this.templateInfoIndex = index;
                this.showTemplateInfo = true;

                this.getTemplateDetails()
            },
            checkIfTemplateAdded(id) {
                return this.items.some(i => {
                    return i.entity_type == 'template'
                        && i.entity_group == id
                        && i.type_id == this.typeId
                });
            },
            checkIfTemplateItemAdded(id) {
                return this.items.some(i => {
                    return i.entity_type == 'template'
                        && i.entity_group == this.templates[this.templateInfoIndex].id
                        && i.entity_id == id
                });
            },
            addTemplateToOrder(id) {
                const params = {
                    template_id: id,
                    type_id: this.typeId
                };

                this.requestIsGoing = true;
                api.post(`/orders/${this.orderId}/add-template`, params)
                    .then(response => response.data.data)
                    .then(data => {
                        this.$emit('onAddItems', data)
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            },
            addItemToOrder(id) {
                const params = {
                    template_item_id: id,
                };

                this.requestIsGoing = true;
                api.post(`/orders/${this.orderId}/add-template-item`, params)
                    .then(response => response.data.data)
                    .then(data => {
                        this.$emit('onAddItems', [data]);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            },
            deleteTemplateFromOrder(templateId) {
                const params = {
                    order_id: this.orderId,
                    entity_group: templateId,
                    entity_type: 'template',
                    type_id: this.typeId
                };

                this.requestIsGoing = true;
                api.delete('/orders/items/delete-by-entity', { params })
                    .then(() => {
                        this.notifySuccess('Template deleted from order');
                        this.$emit('onDeleteTemplate');
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            },
            deleteItemFromOrder(id) {
                let itemOrderId;
                const index = this.items.findIndex(i => {
                    return i.entity_type === 'template' 
                    && i.entity_id === id
                });

                if (index !== -1) {
                    itemOrderId = this.items[index].id;
                    this.requestIsGoing = true;
                    api.delete(`orders/items/${itemOrderId}`)
                        .then(response => response.data.data)
                        .then(data => {
                            this.$emit('onDeleteItem', itemOrderId)
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.requestIsGoing = false;
                        });
                }
            }
        },
        created() {
            this.getTemplates();
        }
    }
</script>