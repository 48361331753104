<template>
    <full-modal @close="$emit('close')" >
        <div slot="title">Form builder</div>
        <div slot="body">
            <div class="tabs-menu bb-grey w-100">
                <div class="tabs-menu-panel row-start">
                    <button
                        :class="[
                            'tabs-menu-item h-100 transparent-button',
                            { 'tabs-menu-item-active': activeTab === tabs.default.name }
                        ]"
                        @click="activeTab = tabs.default.name"
                    >
                        Default Reports
                    </button>
                    <button
                        :class="[
                            'tabs-menu-item h-100 transparent-button',
                            { 'tabs-menu-item-active': activeTab === tabs.custom.name }
                        ]"
                        @click="activeTab = tabs.custom.name"
                    >
                        Custom Created Reports
                    </button>
                </div>
            </div>
            <loader 
                v-if="loading" 
                size="small"
            />
            <div 
                v-else 
                class="form-builder-template-list w-100 pt-8"
            >
                <p class="f-13-grey mb-8">
                    {{ activeTab === tabs.default.name ? tabs.default.title : tabs.custom.title }}
                </p>
                <div 
                    class="form-builder-template-item row-start mb-4 grabbable"
                    v-for="(item, index) in reportsItems"
                    :key="`item-${index}`"
                    :draggable="true"
                    @dragstart="itemDragStart(index)"
                    @dragenter="itemDragEnter(index)" 
                    @dragend="itemDragEnd(index)"
                >
                    <div 
                        class="static-col-1 s-align-4"
                        v-if="isDragEvent"
                    >
                        <drag-icon class="i-grey-6"/>
                    </div>
                    <div :class="['column-start', isDragEvent ? 'static-col-10' : 'static-col-11']">
                        <div
                            v-if="item.name == furtherReport" 
                            class="f-12-grey s-align-1 mb-4"
                        >
                            <span class="further-icon s-align-5 mr-1">
                                <info-icon 
                                    size="6" 
                                    class="i-white"
                                />
                            </span>
                            This report will be used to link job that requires further actions to a new job.   
                        </div>
                        <form-input 
                            :class="['mb-1', { 'form-input-danger' : errors.has(`report-name-${index}`) }]"
                            label="Job type name"
                        >
                            <input 
                                type="text" 
                                :name="`report-name-${index}`" 
                                :id="`report-name-${index}`"
                                class="form-input-field"
                                placeholder="Report name"
                                v-model="item.name"
                                v-input-style="item.name"
                                v-validate="'required|max:250'"
                                :disabled="!item.is_custom"
                            />
                        </form-input>
                        <form-input 
                            :class="[{ 'form-input-danger' : errors.has(`abbreviation-${index}`) }]"
                            label="Abbreviation"
                        >
                            <input 
                                type="text" 
                                :name="`abbreviation-${index}`" 
                                :id="`abbreviation-${index}`"
                                class="form-input-field"
                                placeholder="Abbreviation"
                                v-model="item.abbreviation"
                                v-input-style="item.abbreviation"
                                v-validate="'required|max:250'"
                                :disabled="!item.is_custom"
                            />
                        </form-input>
                    </div>
                    <div
                        v-if="item.is_custom" 
                        class="static-col-1 s-align-6"
                    >
                        <button 
                            class="transparent-button"
                            @click="removeItem(index)"
                            :disabled="type === 'commercialJob'"
                        >
                            <trash-icon class="i-grey"/>
                        </button>
                    </div>
                </div>
                <not-found-message
                    v-if="!reportsItems.length && !loading"
                    custom="Nothing to display"
                />
            </div>
        </div>
        <div slot="footer">
            <button
                class="add-button mr-4"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                @click="save"
                :disabled="requestIsGoing"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, FormInput, Loader } from '@/ui';
    import { DragIcon, TrashIcon, InfoIcon } from '@/icons';
    import cloneDeep from 'lodash/cloneDeep';
    import api from '@/headers.js';
    import Multiselect from 'vue-multiselect';
    import { FORM_BUILDER_REPORTS } from '@/constants';
    import NotFoundMessage from '@/components/NotFoundMessage.vue';

    const DEFAULT_REPORTS = 'default';
    const CUSTOM_REPORTS = 'custom';

    export default {
        name: 'CustomFieldsEditLayoutsModal',
        components: {
            FullModal,
            DragIcon,
            TrashIcon,
            InfoIcon,
            FormInput,
            Multiselect,
            Loader,
            NotFoundMessage
        },
        props: {
            items: {
                type: Array,
                required: true
            },
            type: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                requestIsGoing: false,
                loading: false,
                dragIndex: null,
                dragItem: null,
                editableItems: [],
                itemForRemove: [],
                furtherReport: FORM_BUILDER_REPORTS.FURTHER,
                activeTab: DEFAULT_REPORTS,
                tabs: {
                    default: {
                        name: DEFAULT_REPORTS,
                        title: 'Default reports cannot be edited or deleted. You can only change the sorting order of the reports.'
                    },
                    custom: {
                        name: CUSTOM_REPORTS,
                        title: 'Custom reports can be edited, sorted, and deleted.'
                    } 
                }
            }
        },
        computed: {
            isDragEvent() {
                return this.nScreen || this.dScreen;
            },
            reportsItems() {
                if (this.activeTab === this.tabs.default.name) {
                    return this.editableItems.filter(el => !el.is_custom);
                } else {
                    return this.editableItems.filter(el => el.is_custom);
                }
            }
        },
        methods: {
            itemDragStart(index) {
                this.dragIndex = index;
                this.dragItem = this.reportsItems[index];
            },
            itemDragEnter(index) {
                if (this.dragIndex == index) return false;
                let itemStart = this.reportsItems.find((el,ind) => ind == this.dragIndex);
                let itemEnter = this.reportsItems.find((el,ind) => ind == index);
                let editStartIndex = this.editableItems.findIndex(el => el.id == itemStart.id);
                let editEnterIndex = this.editableItems.findIndex(el => el.id == itemEnter.id);

                this.editableItems.splice(editStartIndex, 1);
                this.editableItems.splice(editEnterIndex, 0, this.dragItem);

                for (let i = 0; i < this.editableItems.length; i++) {
                    this.editableItems[i].main_section_order = i;
                }
                this.dragIndex = index;
            },
            itemDragEnd(index) {
                this.dragIndex = null;
                this.dragItem = null;
            },
            removeItem(index) {
                if(!this.reportsItems[index].is_custom) {
                    this.notifyError('Sorry, you cannot delete default reports.');

                    return;
                }
                this.itemForRemove.push(this.reportsItems[index]);
                let editableItemsIndex = this.editableItems.findIndex(el => el.id == this.reportsItems[index].id);
                this.editableItems.splice(editableItemsIndex, 1);
            },
            save() {
                let params = {
                    layouts: this.editableItems,
                    layouts_for_remove: this.itemForRemove
                };

                this.requestIsGoing = true;
                api.put(`/layouts/layouts`, params)
                    .then((response) => {
                        this.notifySuccess('Successfully edited settings templates!');
                        this.$emit('close', response.data.data);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            }
        },
        created() {
            this.editableItems = cloneDeep(this.items);           
        }
    }
</script>