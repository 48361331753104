<template>
    <div class="checkbox-multiselect w-100">
        <div class="row-between mb-5">
            <span class="f-12-black f-sbold">
                {{ title }}
            </span>
            <button 
                v-if="!!selectedItemsCount"
                class="transparent-button f-12-grey"
                @click="selectOrDeselectAll(false)"
            >
                <close-icon
                    class="i-red mr-4"
                    size="8"
                />
                Clear
            </button>
        </div>
        <div class="row-start relative">
            <div 
                class="selected-items row-between ph-8 pv-20 pointer"
                @click="showCheckboxMenu = true"
            >
                <span class="w-90 f-14-black">{{ selectedValue }}</span>
                <span class="selected-count f-12-blue f-bold s-align-5">
                    {{ selectedItemsCount}}
                </span>
            </div>
            <div
                v-if="showCheckboxMenu" 
                class="select-menu w-100"
            >
                <div class="row-between bb-grey p-7">
                    <div class="s-align-4 w-80">
                        <search-icon
                            class="i-grey"
                            size="14"
                        />
                        <input
                            class="checkbox-multiselect-input ml-2 w-100" 
                            type="text"
                            v-model="searchValue"
                            placeholder="Search calendar"
                        >
                    </div>
                    <button
                        class="transparent-button f-12-blue"
                        @click="selectOrDeselectAll(true)"
                    >
                        Select all
                    </button>
                </div>
                <div class="column-start mt-20">
                    <checkbox-button 
                        v-for="(item, index) in searchSelectItems"
                        :key="index"
                        color="blue"
                        :label="item"
                        class="no-wrap ml-8 mb-7"
                    >
                        <input 
                            type="checkbox"
                            :name="item + index"
                            @change="selectItem(item,$event)"
                            :checked="checkChecked(item)"
                        />
                    </checkbox-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CloseIcon, SearchIcon } from '@/icons';
import { CheckboxButton } from '@/ui';
import cloneDeep from 'lodash/cloneDeep';

export default {
    name: 'CheckboxMultiselect',
    components: {
        CloseIcon,
        SearchIcon,
        CheckboxButton
    },
    props: {
        title: {
            type: String,
            required: true
        },
        itemsToSelect: {
            type: Array,
            required: true
        },
        selectedItems: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            searchValue: '',
            selected: [],
            showCheckboxMenu: false
        }
    },
    computed: {
        selectedValue() {
            return this.selected.join(', ');
        },
        selectedItemsCount() {
            return this.selected.length;
        },
        searchSelectItems() {
            return this.itemsToSelect.filter(item => item.toLowerCase().includes(this.searchValue.toLowerCase()));
        }
    },
    methods: {
        selectOrDeselectAll(bool) {
            let event = {
                target: {
                    checked: bool
                }
            }
            this.itemsToSelect.forEach(item => this.selectItem(item, event));
        },
        selectItem(item,event) {
            if (event.target.checked) {
                if (!this.selected.some(el => el == item)) {
                    this.selected.push(item);
                }
            } else {
                let index = this.selected.findIndex(el => el == item);
                this.selected.splice(index,1);
            }

            this.$emit('select', item, event.target.checked);
        },
        checkChecked(item) {
            return this.selectedItems.some(el => el == item);
        },
        outside(event) {
            if (!this.$el.contains(event.target) && this.showCheckboxMenu)
                this.showCheckboxMenu = false;
        }
    },
    mounted() {
        this.selected = cloneDeep(this.selectedItems);
        document.body.addEventListener('click', this.outside, false);
    }
}
</script>