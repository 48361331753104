<template>
    <svg 
        viewBox="0 0 261.7565002441406 227.541015625"   
        :height="size" 
        :width="size"
        :class="['folder-icon', palette]"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="261.7565002441406" 
            height="227.541015625" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(7.840000152587891, 0, 0, 7.840000152587891, 3101.6411743164062, -2081.766357421875)">
            <path 
                class="st0" 
                d="M-365.5,270h-14.2c-0.2,0-0.3-0.1-0.4-0.2l-1.8-2.7c-0.5-0.7-1.2-1.1-2.1-1.1h-8.5c-1.4,0-2.5,1.1-2.5,2.5v23c0,1.4,1.1,2.5,2.5,2.5h27c1.4,0,2.5-1.1,2.5-2.5v-19C-363,271.1-364.1,270-365.5,270z"
            />
            <path 
                class="st1" 
                d="M-381,289h-8c-0.6,0-1-0.4-1-1s0.4-1,1-1h8c0.6,0,1,0.4,1,1S-380.4,289-381,289z"
            />
            <path 
                class="st2" 
                d="M-364,276h-30c-0.6,0-1-0.4-1-1s0.4-1,1-1h30c0.6,0,1,0.4,1,1S-363.4,276-364,276z"
            />
            <path 
                class="st3" 
                d="M-381,289h-8c-0.6,0-1-0.4-1-1s0.4-1,1-1h8c0.6,0,1,0.4,1,1S-380.4,289-381,289z"
            />
            <path 
                class="st4" 
                d="M-364,276h-30c-0.6,0-1-0.4-1-1s0.4-1,1-1h30c0.6,0,1,0.4,1,1S-363.4,276-364,276z"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'FolderIcon',
        props: {
            size: {
                type: [Number, String],
                default: 30
            },
            palette: {
                type: String,
                default: 'transparent'
            }
        }
    }
</script>
