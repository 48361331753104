<template>
    <div class="project-management-card-modal h-100 w-100">
        <div class="card-modal-container relative">
            <div 
                v-if="card.is_archive"
                class="card-modal-archive row-between row-baseline"
            >
                <div>
                    <p class="f-18-darkgrey f-sbold">This card is archived</p>
                    <p class="f-14-grey">It is a long established fact that a reader will be distracted.</p>
                    <button 
                        class="transparent-button s-align-4 mt-4"
                        @click="unarchiveCard"
                    >
                        <next-icon 
                            transform="180"
                            class="i-blue mr-2"
                        />
                        <span class="f-14-blue">Unarchive this card</span>
                    </button>
                </div>
                <img 
                    class="mt-sm-2"
                    src="@/assets/images/archived-card-icon.svg" 
                    width="114" 
                    height="82" 
                    alt="Card is archived"
                />
            </div>
            <div 
                v-if="isLoading"
                class="card-modal-main"
            >
                <Loader class="row-baseline" size="small"/>
            </div>
            <div 
                v-else
                class="card-modal-main"
            >
                <div class="card-modal-detail">
                    <div class="row-between row-baseline-start flex-no-wrap mb-1">
                        <div class="card-tags s-align-4">
                            <div class="add-tag relative">
                                <button
                                    v-can="[$permissions.EDIT_CARDS]"
                                    class="add-tag-btn transparent-button"
                                    @click="toggleTagsModal"
                                >
                                    <add-icon width="8"/>
                                </button>
                                <project-management-tags
                                    v-if="isTagsModalShow"
                                    :tags="card.tags"
                                    :allTagsProps="allTags"
                                    :cardId="card.id"
                                    :isLoading="isTagsLoading"
                                    @updateTags="updateTags"
                                    @closeModal="() => { isTagsModalShow = false }"
                                />
                            </div>
                            <span 
                                v-for="(tag, index) in card.tags"
                                :key="index"
                                :class="[
                                    'card-tag p-2',
                                    `card-tag-${tag.color}`
                                ]"
                            >
                                {{ tag.name }}
                            </span>
                        </div>
                        <div 
                            v-if="card.designated_user"
                            class="card-designated-user t-right s-align-6"
                        >
                            <div class="mr-4">
                                <p class="f-13-darkgrey">
                                    {{ card.designated_user.fullname }}
                                </p>
                                <p
                                    v-if="card.lead_data"
                                    class="f-12-grey"
                                >
                                    Salesman
                                </p>
                            </div>
                            <avatar
                                :url="card.designated_user.thumbnail"
                                :name="`${card.designated_user.first_name} ${card.designated_user.last_name}`"
                                :border="false"
                                size="30"
                            />
                        </div>
                    </div>
                    <textarea
                        :class="[
                            'card-modal-title transparent-input w-100',
                            errors.has('card_title') ? 'transparent-input-danger' : '' 
                        ]" 
                        name="card_title"
                        rows="1"
                        v-model="card.title"
                        @input="updateCard('title')"
                        placeholder="Enter title here..."
                        maxlength="100"
                        v-validate="'required|min:3|max:99'"
                        v-resize-textarea
                    />
                    <div
                        v-if="card.lead_data"
                        class="w-100"
                    >
                        <p class="f-12-blue">
                            {{ card.lead_data.fulladdress }}
                        </p>
                        <div class="row-start">
                            <div class="s-align-4 mr-4">
                                <phone-icon
                                    size="12"
                                    class="i-darkgrey mr-1"
                                />
                                <span class="f-12-black">
                                    {{ card.lead_data.primary_phone }}
                                </span>
                            </div>
                            <div class="s-align-4">
                                <envelope-icon
                                    size="12"
                                    class="i-darkgrey mr-1"
                                />
                                <span class="f-12-black">
                                    {{ card.lead_data.primary_email }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-modal-dates row-start mv-5">
                        <div 
                            v-if="card.date_of_completion"
                            class="lg-6 sm-12 mb-sm-1"
                        >
                            <span class="f-11-grey mr-1">Due date:</span>
                            <span class="f-11-black">{{ card.date_of_completion | createdAt }}</span>
                        </div>
                        <div class="lg-6 sm-12">
                            <span class="f-11-grey mr-1">Created:</span>
                            <span class="f-11-black">{{ card.created_at | createdAt }}</span>
                        </div>
                    </div>
                    <p class="f-16-darkgrey f-sbold mb-4">Description</p>
                    <textarea
                        :class="[
                            'card-modal-description transparent-input w-100 bb-grey',
                            errors.has('card_description') ? 'transparent-input-danger' : '' 
                        ]" 
                        name="card_description"
                        rows="1"
                        v-model="card.description"
                        @input="updateCard('description')"
                        placeholder="Enter description here..."
                        maxlength="2500"
                        v-validate="'min:3|max:2499'"
                        v-resize-textarea
                    />
                </div>
                <div v-if="filesLength || fileUploading" class="card-modal-attachments bb-grey">
                    <div class="row-between mb-8">
                        <p class="f-16-darkgrey f-sbold">Attachments</p>
                    </div>
                    <div class="column-static">
                        <div
                            v-for="(file, index) in card.files"
                            :key="index"
                            class="row-start row-baseline mb-4"
                        >
                            <img
                                v-if="FILE_EXTENSIONS.IMAGES.some(t => t === file.file_type)"
                                class="attachment-img-preview"
                                :src="getPreviewUrl(file.file_type, file.file)" 
                                width="55" 
                                height="55" 
                            >
                            <div
                                v-else
                                class="attachment-preview s-align-5"
                            >
                                <span class="f-13-grey f-sbold">
                                    .{{ file.file_type }}
                                </span>
                            </div>
                            <div class="attachment-desc ellipsis column-start ml-4">
                                <a 
                                    :href="`${url}${file.file}`"
                                    target="_blank"
                                    class="attachment-img f-14-black f-sbold ellipsis"
                                >
                                    {{ file.original_name }}
                                </a> 
                                <div class="row-start">
                                    <span class="f-12-grey">Uploaded</span>
                                    <span class="f-12-black ml-1">
                                        {{ file.updated_at | createdAt }}
                                    </span>
                                    <button 
                                        class="transparent-button f-12-blue ml-1"
                                        @click="removeFile(file.id)"
                                        :disabled="requestIsGoing"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <loader 
                        v-if="fileUploading"
                        class="pv-20 s-align-5" 
                        size="mini"
                    />
                </div>
                <div 
                    v-if="todoList !== null && todoListTotalCount !== 0"
                    class="card-modal-checklist bb-grey"
                >
                    <div class="row-between row-baseline mb-8">
                        <p class="f-16-darkgrey f-sbold">Checklist</p>
                        <button 
                            class="add-button"
                            @click="todoList.splice(0, todoListTotalCount)"
                        >
                            Delete
                        </button>
                    </div>
                    <form
                        class="w-100"
                        data-vv-scope="checklist_items"
                        @submit.prevent
                    >
                        <div 
                            v-for="(todo, index) in todoList"
                            :key="index"
                            class="row-start row-baseline-top mb-7"
                        >
                            <checkbox-button
                                color="violet"
                            >
                                <input 
                                    type="checkbox" 
                                    :name="`checklist_checkbox_${index}`" 
                                    :id="`checklist_checkbox_${index}`" 
                                    v-model="todo.completed"
                                />
                            </checkbox-button>
                            <textarea
                                :name="`checklist_item_${index}`" 
                                :id="`checklist_item_${index}`" 
                                :class="[
                                    'checklist-item-title',
                                    errors.has(`checklist_items.checklist_item_${index}`) 
                                        ? 'checklist-item-title-danger' : '' 
                                ]" 
                                placeholder="Enter item title..."
                                v-model="todo.title"
                                @input="onTodoInput(todo.title, index)"
                                v-resize-textarea
                                v-validate="'required|min:3|max:256'"
                                maxlength="255"
                                rows="1"
                                :ref='`checklist_item_${index}`'
                                @keydown.enter.exact.prevent
                                @keyup.enter.exact="addTodoListItem"
                            />
                        </div>
                    </form>
                    <div class="row-start">
                        <button
                            ref="addItem"
                            class="add-button"
                            @click="addTodoListItem"
                        >
                            + Add item
                        </button>
                    </div>
                </div>
                <div class="card-modal-comments" v-can="[$permissions.EDIT_CARDS]">
                    <div class="row-start mb-8">
                        <p class="f-16-darkgrey f-sbold">Comments</p>
                    </div>
                    <div
                        :class="[
                            'comments-write-message column-start',
                            errors.has('card_new_comment')
                                ? 'comments-write-message-error' : ''
                            ]"
                    >
                        <textarea
                            v-model="newComment"
                            name="card_new_comment"
                            id="card_new_comment"
                            placeholder="Type your comment here..."
                            @keypress.13.prevent="saveComment"
                            v-validate="'min:3|max:255'"
                            maxlength="256"
                        />
                        <div class="comments-panel row-end">
                            <!-- <button class="icon-dark">
                                <attach-icon/>
                            </button> -->
                            <button
                                class="icon-dark"
                                @click="saveComment"
                            >
                                <send-icon/>
                            </button>
                        </div>
                    </div>
                    <div
                        class="comments-list"
                        v-if="card.comments.length"
                    >
                        <div
                            class="comments-item"
                            v-for="(comment, index) in card.comments"
                            :key="index"
                        >
                            <div class="row-between row-baseline">
                                <div class="s-align-4">
                                    <avatar
                                        :url="comment.user.thumbnail"
                                        :name="`${comment.user.first_name} ${comment.user.last_name}`"
                                        :border="false"
                                        size="35"
                                    />
                                    <div class="column-start pl-4">
                                        <span class="f-13-black">
                                            {{ comment.user.fullname }}
                                        </span>
                                        <span class="f-11-grey">{{ comment.created_at | timeAgo }}</span>
                                    </div>
                                </div>
                                <button
                                    v-if="comment.user.id === loggedUserId"
                                    class="add-button"
                                    @click="removeComment(comment.id)"
                                >
                                    Delete
                                </button>
                                <!-- <button class="transparent-button s-align-6">
                                    <reply-icon/>
                                    <span class="f-12-blue ml-2">Reply</span>
                                </button> -->
                            </div>
                            <div class="comments-item-body row-start mt-1">
                                <span class="f-12-grey break-word w-100">{{ comment.body }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-modal-sidebar">
                <div class="card-modal-actions" >
                    <div class="row-between row-baseline mb-20">
                        <span class="f-16-white f-sbold opacity-8">Settings</span>
                        <button
                            class="card-modal-close transparent-button"
                            @click="$emit('close', cardPreview)"
                        >
                            <close-icon 
                                size="15"
                                class="i-darkgrey"
                            />
                        </button>
                    </div>
                    <div v-can="[$permissions.EDIT_CARDS]">
                        <button
                            class="card-action w-100"
                            @click="toggleTagsModal"
                        >
                            <tag-icon size="14"/>
                            <span class="f-14-grey">Labels</span>
                        </button>
                        <button
                            class="card-action w-100"
                            @click="addTodoListItem"
                        >
                            <checkbox-icon size="15"/>
                            <span class="f-14-grey">Checklist</span>
                        </button>
                        <div class="w-100 relative">
                            <button
                                class="card-action w-100"
                                @click="toggleCardModals('dueDateModal')"
                            >
                                <calendar-icon size="15"/>
                                <span class="f-14-grey">Due Date</span>
                            </button>
                            <div
                                v-show="isDueDateModalShow"
                                class="date-modal"
                            >
                                <div class="row-between mb-6">
                                    <span class="f-16-black">Change Due Date</span>
                                    <button
                                        class="icon-light"
                                        @click="isDueDateModalShow = false"
                                    >
                                        <close-icon size="15"/>
                                    </button>
                                </div>
                                <form-date-time-input
                                    :class="[
                                        'mb-8',
                                        errors.has('card_due_date') ? 'form-date-time-input-error' : ''
                                    ]"
                                        :isFullWidth="true"
                                        v-model="card.date_of_completion"
                                        name="card_due_date"
                                        v-validate="'required'"
                                />
                                <div class="row-between pt-7 bt-grey">
                                    <div class="lg-6">
                                        <button
                                            v-if="card.date_of_completion"
                                            class="remove-due-date transparent-button"
                                            @click="removeDueDate"
                                        >
                                            <trash-icon class="mr-1" width="10"/>
                                            Remove
                                        </button>
                                    </div>
                                    <div class="lg-6 s-align-6">
                                        <button
                                            class="transparent-button f-12-grey f-sbold"
                                            @click="isDueDateModalShow = false"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            class="save-due-date transparent-button ml-8"
                                            @click="updateDueDate"
                                            :disabled="requestIsGoing"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-100 relative">
                            <button
                                class="card-action w-100"
                                @click="toggleCardModals('assignModal')"
                            >
                                <person-icon size="15"/>
                                <span class="f-14-grey">Assign to</span>
                            </button>
                            <users-select
                                v-if="isAssignModalShow"
                                @close="isAssignModalShow = false"
                                @update="(id) => updateCard('assignment', id)"
                                :selectedUsers="card.designated_user ? card.designated_user : []"
                                :disabled="requestIsGoing"
                                direction="left"
                            />
                        </div>
                        <label class="card-action w-100 pointer">
                            <input
                                type="file"
                                multiple
                                @change="uploadFiles($event)"
                            />
                            <attach-icon width="12" height="13"/>
                            <span class="f-14-grey">Attachment</span>
                        </label>
                        <div class="w-100 relative">
                            <button
                                class="card-action w-100"
                                @click="toggleCardModals('appointmentDateModal')"
                            >
                                <clock-icon size="15"/>
                                <span class="f-14-grey">Appointment Date</span>
                            </button>
                            <div
                                v-show="isAppointmentDateModalShow"
                                class="date-modal"
                            >
                                <div class="row-between mb-6">
                                    <span class="f-16-black">Appointment Date</span>
                                    <button
                                        class="icon-light"
                                        @click="isAppointmentDateModalShow = false"
                                    >
                                        <close-icon size="15"/>
                                    </button>
                                </div>
                                <form-date-time-input
                                    :class="[
                                        'mb-8',
                                        errors.has('card_appointment_date') ? 'form-date-time-input-error' : ''
                                    ]"
                                    :isFullWidth="true"
                                    v-model="card.appoint_date"
                                    name="card_appointment_date"
                                    v-validate="'required'"
                                />
                                <div class="row-between pt-7 bt-grey">
                                    <div class="lg-6">
                                        <button
                                            v-if="card.appoint_date"
                                            class="remove-due-date transparent-button"
                                            @click="removeAppointmentDate"
                                        >
                                            <trash-icon class="mr-1" width="10"/>
                                            Remove
                                        </button>
                                    </div>
                                    <div class="lg-6 s-align-6">
                                        <button
                                            class="transparent-button f-12-grey f-sbold"
                                            @click="isAppointmentDateModalShow = false"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            class="save-due-date transparent-button ml-8"
                                            @click="updateAppointmentDate"
                                            :disabled="requestIsGoing"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-100 relative mt-7">
                            <button
                                class="card-action card-action-subscribe w-100"
                                @click="toggleCardModals('subscribeModal')"
                            >
                                <div class="lg-8">
                                    <bell-icon
                                        size="15"
                                        class="i-blue"
                                    />
                                    <span class="f-12-grey">SUBSCRIBE</span>
                                </div>
                                <div
                                    v-if="card.subscribers"
                                    class="lg-4 row-baseline subscribers-count"
                                >
                                    <avatar
                                        v-for="user in card.subscribers.slice(0,3)"
                                        :key="user.user_id"
                                        :url="user.user_thumbnail"
                                        :name="`${user.user_first_name} ${user.user_last_name}`"
                                        :border="true"
                                        size="25"
                                    />
                                    <div
                                        v-if="card.subscribers.length > 3"
                                        class="subscribers-overflow-count f-11-white f-bold s-align-5"
                                    >
                                        {{ `+${card.subscribers.length - 3}` }}
                                    </div>
                                </div>
                            </button>
                            <users-select
                                v-if="isSubscribeModalShow"
                                @close="isSubscribeModalShow = false"
                                @update="toggleUserSubscription"
                                :selectedUsers="card.subscribers"
                                :hideLoggedUser="true"
                                :disabled="requestIsGoing"
                                icon="bell-icon"
                                direction="left"
                            >
                                <div class="w-100 mb-8">
                                    <p class="f-13-black f-sbold mb-1">Get news</p>
                                    <div class="subscribe-card-checkbox row-between row-baseline b-grey p-5">
                                        <div class="lg-10">
                                            <bell-icon
                                                class="i-grey mr-4"
                                                size="15"
                                            />
                                            <span class="f-13-black">Subscribe to a card </span>
                                        </div>
                                        <checkbox-button
                                            color="blue"
                                            class="checkbox-small"
                                        >
                                            <input
                                                type="checkbox"
                                                name="is_logged_user_subscribed"
                                                :checked="isLoggedUserSubscribed"
                                                @change="toggleUserSubscription(loggedUserId)"
                                            />
                                        </checkbox-button>
                                    </div>
                                </div>
                            </users-select>
                        </div>
                    </div>
                </div>
                <div class="card-audit column-start">
                    <p class="f-16-white mb-1">Audit Log</p>
                    <div 
                        v-for="(item, index) in auditLog.data"
                        :key="item.id"
                        :class="[
                            'audit-item column-start pv-5 opacity-8',
                            { 'bb-grey' : index !== auditLog.data.length - 1 }
                        ]"
                    >
                        <p class="f-11-grey mb-1">
                            {{ item.updated_at | createdAt }}
                        </p>
                        <p class="f-13-white">
                            {{ item.changes }}
                        </p>
                    </div>
                    <pagination 
                        class="mt-1"
                        v-if="auditLog.last_page !== 1 && auditLog.last_page !== undefined"
                        :quantity="auditLog.last_page"
                        :showDesc="false"
                        @click="getLog"
                    />
                </div>
                <div 
                    v-if="card.is_archive"
                    class="w-100 mt-40"
                >
                    <p class="f-13-grey mb-4">Delete card to lorem Ipsum is simply text</p>
                    <button 
                        class="delete-card-button s-align-4"
                        @click="deleteCard"
                        :disabled="requestIsGoing"
                    >
                        <trash-icon 
                            width="10" 
                            height="10"
                            class="i-grey mr-1"
                        />
                        <span class="f-11-grey">Delete</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { 
        TagIcon, CheckboxIcon, CalendarIcon, AttachIcon, CloseIcon, SendIcon, ReplyIcon,
        ClockIcon, PersonIcon, SearchIcon, AddIcon, TrashIcon, BellIcon, NextIcon,
        PhoneIcon, EnvelopeIcon
    } from '@/icons';
    import { Loader, CheckboxButton, FormDateTimeInput, Pagination } from '@/ui';
    import api from '@/headers.js';
    import debounce from 'lodash/debounce';
    import cloneDeep from 'lodash/cloneDeep';
    import { Avatar, ProjectManagementTags, UsersSelect } from '@/components';
    import { FILE_EXTENSIONS } from '@/constants.js';

    export default {
        name: 'ProjectManegementCard',
        components: {
            TagIcon,
            CheckboxIcon,
            CalendarIcon,
            AttachIcon,
            CloseIcon,
            SendIcon,
            ReplyIcon,
            ClockIcon,
            PersonIcon,
            Avatar,
            Loader,
            CheckboxButton,
            FormDateTimeInput,
            ProjectManagementTags,
            SearchIcon,
            AddIcon,
            TrashIcon,
            Pagination,
            UsersSelect,
            BellIcon,
            NextIcon,
            PhoneIcon,
            EnvelopeIcon
        },
        props: {
            cardSlug: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                isLoading: true,
                fileUploading: false,
                requestIsGoing: false,
                card: {},
                files: [],
                todoList: [],
                newComment: '',
                url: process.env.VUE_APP_AMAZONAWS_URL,
                isDueDateModalShow: false,
                isAppointmentDateModalShow: false,
                isAssignModalShow: false,
                isTagsModalShow: false,
                auditLog: {},
                isTagsLoading: false,
                allTags: [],
                isSubscribeModalShow: false,
                FILE_EXTENSIONS: FILE_EXTENSIONS
            }
        },
        computed: {
            loggedUserId() {
                return this.$store.getters['user/getUserId'];
            },
            filesLength() {
                return this.card.files.length;
            },
            todoListTotalCount() {
                return this.todoList.length;
            },
            todoListCompletedCount() {
                return this.todoList.filter(i => i.completed).length;
            },
            isLoggedUserSubscribed() {
                return this.card.subscribers.some(user => user.user_id === this.loggedUserId);
            },
            // card preview, emited up to keep the board up to date
            cardPreview() {
                return {
                    id: this.card.id,
                    title: this.card.title,
                    slug: this.card.slug,
                    order_card: this.card.order_card,
                    date_of_completion: this.card.date_of_completion,
                    column_id: this.card.column_id,
                    designated_user: this.card.designated_user,
                    tags: this.card.tags,
                    count_files: this.filesLength,
                    count_comments: this.card.comments.length,
                    count_todo_completed: this.todoListCompletedCount,
                    count_todo_total: this.todoListTotalCount
                }
            }
        },
        methods: {
            getCard() {
                api.get(`/kanban/cards/${this.cardSlug}`)
                    .then(response => response.data.data)
                    .then(data => {
                        this.card = data;
                        this.isLoading = false;

                        if (this.$route.query.card !== this.card.slug) {
                            this.$router.push({ query: { 
                                ...this.$route.query,
                                card: this.card.slug, 
                            } });
                        }
                        
                        // parse todoList json
                        if (this.card.todo_list !== null) {
                            this.todoList = JSON.parse(this.card.todo_list);
                        }
                        
                        // added 'watch' here to prevent request when data loading
                        this.$nextTick(function() {
                            this.$watch('todoList', function () {
                                this.updateCard('todoList');
                            }, { deep: true });
                        });

                        this.getLog();
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 404) {
                            this.notifyError('Card not found');
                        }

                        this.$emit('close', this.cardPreview);
                    });
            },
            getLog(selectedPage) {
                const params = {
                    perPage: 5,
                    page: selectedPage ? selectedPage : 1
                };

                api.get(`/kanban/cards/${this.card.id}/logs`, { params })
                    .then(response => response.data.data)
                    .then(data => {
                        this.auditLog = data;
                    });
            },
            getTags() {
                this.isTagsLoading = true;
                api.get('/kanban/tags')
                    .then(response => response.data.data)
                    .then(data => {
                        this.allTags = data;
                    })
                    .finally(() => {
                        this.isTagsLoading = false;
                    });
            },
            updateCard: debounce(function(field, userId) {

                if (! this.$can([this.$permissions.EDIT_CARDS])) {
                    return false;
                }

                let validateField;

                switch(field) {
                    case 'title': 
                        validateField = 'card_title';
                        break;
                    case 'description': 
                        validateField = 'card_description';
                        break;
                    case 'dueDate': 
                        validateField = 'card_due_date';
                        break;
                    case 'appointmentDate':
                        validateField = 'card_appointment_date';
                        break;
                    case 'assignment':
                        validateField = 'checklist_items';
                        break;
                    case 'todoList':
                        validateField = 'checklist_items' // scope
                        break;
                }

                this.$validator.validateAll(validateField)
                    .then(result => {
                        if (result) {
                            let params = {
                                id: this.card.id,
                                redirect_url: window.location.origin +
                                    this.$router.resolve({name: 'ProjectManagement'}).href + '?card=' + this.cardSlug
                            };

                            switch(field) {
                                case 'title': 
                                    Object.assign(params, { title: this.card.title });
                                    break;
                                case 'description': 
                                    Object.assign(params, { description: this.card.description });
                                    break;
                                case 'todoList': 
                                    Object.assign(params, { todo_list: JSON.stringify(this.todoList) });
                                    break;
                                case 'dueDate': 
                                    Object.assign(params, { date_of_completion: this.card.date_of_completion });
                                    break;
                                case 'appointmentDate': 
                                    Object.assign(params, { appoint_date: this.card.appoint_date });
                                    break;
                                case 'assignment':
                                    if (this.card.designated_user && this.card.designated_user.id === userId) {
                                        Object.assign(params, { designated_user_id: null });
                                    } else {
                                        Object.assign(params, { designated_user_id: userId });
                                    }
                                    break;
                            }

                            this.requestIsGoing = true;
                            api.put(`/kanban/cards/${params.id}`, params)
                                .then(response => response.data.data)
                                .then(data => {
                                    this.card.slug = data.slug;
                                    // update todo_list json to actual state, needed to correct display checklist count in kanban board
                                    this.card.todo_list = data.todo_list;

                                    if (this.$route.query.card && this.$route.query.card !== this.card.slug) {
                                        this.$router.replace({ query: { 
                                            ...this.$route.query,
                                            card: data.slug 
                                        } });
                                    }

                                    switch(field) {
                                        case 'dueDate':
                                            this.isDueDateModalShow = false; 
                                            this.errors.remove('card_due_date');
                                            this.notifySuccess(`Due Date ${
                                                    data.date_of_completion ? 'changed' : 'removed'
                                                }!`
                                            );
                                            setTimeout(function() {
                                                this.getLog();
                                            }.bind(this), 1000);
                                            break;
                                        case 'appointmentDate':
                                            this.isAppointmentDateModalShow = false; 
                                            this.errors.remove('card_appointment_date');
                                            this.notifySuccess(`Appointment Date ${
                                                    data.appoint_date ? 'changed' : 'removed'
                                                }!`
                                            );
                                            setTimeout(function() {
                                                this.getLog();
                                            }.bind(this), 1000);
                                            break;
                                        case 'assignment': 
                                            if (data.designated_user) {
                                                this.card.designated_user = Object.assign({}, data.designated_user);
                                                this.notifySuccess(`Card assigned to ${this.card.designated_user.fullname}`);
                                            } else {
                                                this.card.designated_user = null;
                                                this.notifySuccess('Card unassigned');
                                            }

                                            this.isAssignModalShow = false;
                                            setTimeout(function() {
                                                this.getLog();
                                            }.bind(this), 1000);
                                            break;
                                    }
                                })
                                .catch((error) => {
                                    this.notifyRequestErrors(error);
                                })
                                .finally(() => {
                                    this.requestIsGoing = false;
                                });
                        }
                    });
            }, 500), 
            updateDueDate() {
                this.$validator.validate('card_due_date')
                    .then(result => {
                        if (result) {
                            this.requestIsGoing = true;
                            this.updateCard('dueDate');
                        }
                    });
            },
            updateAppointmentDate() {
                this.$validator.validate('card_appointment_date')
                    .then(result => {
                        if (result) {
                            this.requestIsGoing = true;
                            this.updateCard('appointmentDate');
                        }
                    });
            },
            uploadFiles(event) {
                const files = event.target.files;
                const maxFileSize = 9961472; //9961472 - 9.5 MB in bytes

                if (!files.length) {
                    return
                }

                this.fileUploading = true;
                Promise.all(Object.entries(files).map(item => {
                    if (item[1].size > maxFileSize) {
                        this.notifyError(`File '${item[1].name}' have size more then 10MB! Need less.`);
                        return Promise.resolve();
                    }

                    let formData = new FormData();
                    formData.append('file', item[1]);
                    formData.append('type', 'kanban_file');
                    formData.append('kanban_card_id', this.card.id);
                    formData.append('redirect_url', window.location.href);

                    return api.post('/kanban/files', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                }))
                .then(response => { 
                    response.forEach(item => {
                        this.card.files.push(item.data.data);
                    });
                    this.notifySuccess(`${response.length === 1 ? 'File' : 'Files'} uploaded!`);
                    setTimeout(function() {
                        this.getLog();
                    }.bind(this), 2000);
                })
                .catch(error => this.notifyRequestErrors(error))
                .finally(() => this.fileUploading = false);
            },
            removeFile(id) {
                this.requestIsGoing = true;
                api.delete(`/kanban/files/${id}`)
                    .then(response => response.data.data)
                    .then(data => {
                        if (data) {
                            const index = this.card.files.findIndex(item => item.id === id);
                            this.card.files.splice(index, 1);
                            this.notifySuccess('File deleted!');
                            setTimeout(function() {
                                this.getLog();
                            }.bind(this), 2000);
                        }
                    })
                    .catch(error => this.notifyRequestErrors(error))
                    .finally(() => this.requestIsGoing = false);
            },
            addTodoListItem() {               
                this.$validator.validateAll('checklist_items')
                    .then(result => {
                        if (result) {
                            this.todoList.push({ completed: false, title: '' });

                            // focus on new input
                            this.$nextTick(function() {
                                let refName = `checklist_item_${this.todoListTotalCount - 1}`;
                                this.$refs[refName][0].focus();
                            });  
                        }
                    });
            },
            onTodoInput(title, index) {
                // remove todo if deleted all text
                if (title === '') {
                    this.todoList.splice(index, 1)
                }
            },
            saveComment() {
                this.$validator.validate('card_new_comment').then(result => {
                    if (result && this.newComment !== '') {
                        const id = this.card.id;
                        const params = {
                            id,
                            body: this.newComment
                        };

                        api.post(`/kanban/cards/${id}/comments`, params)
                            .then(response => response.data.data)
                            .then(data => {
                                this.card.comments.unshift(data);
                                this.newComment = '';
                            })
                            .catch((error) => {
                                this.notifyRequestErrors(error);
                            });
                    } else {
                        // generate error
                        this.errors.add({
                            field: 'card_new_comment',
                            msg: 'field is required'
                        });

                        this.notifyError('This field must be at least 3 characters.');
                    }
                })
            },
            removeComment(id) {
                const cardId = this.card.id;
                const params = {
                    id: cardId,
                    comments: [id]
                };
                let index;

                api.delete(`/kanban/cards/${cardId}/comments`, { params })
                    .then(response => response.data.data)
                    .then(data => {
                        if (data) {
                            index = this.card.comments.findIndex(el => el.id === id);
                            if (index > -1) {
                                this.card.comments.splice(index, 1);
                            }
                            
                            this.notifySuccess('Comment deleted!');
                        }
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    });
            },
            removeDueDate() {
                this.card.date_of_completion = null;
                this.requestIsGoing = true;
                this.updateCard('dueDate');
                this.isDueDateModalShow = false;
            },
            removeAppointmentDate() {
                this.card.appoint_date = null;
                this.requestIsGoing = true;
                this.updateCard('appointmentDate');
                this.isAppointmentDateModalShow = false;
            },
            toggleUserSubscription(id) {
                this.requestIsGoing = true;

                if (this.card.subscribers.some(user => user.user_id === id)) {
                    const params = { 
                        user_id: id,
                        card_id: this.card.id
                    }

                    api.delete('/kanban/subscribers', { params })
                        .then(response => response.data.data)
                        .then(data => {
                            if (data) {
                                const index = this.card.subscribers.findIndex(item => item.user_id === id);
                                this.card.subscribers.splice(index, 1);
                                this.notifySuccess('User unsubscribed!');
                            }
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => { 
                            this.requestIsGoing = false 
                        });
                } else {
                    const params = { 
                        user_id: id,
                        card_id: this.card.id
                    }

                    api.post('/kanban/subscribers', params)
                        .then(response => response.data.data)
                        .then(data => {
                            this.card.subscribers.push(data);
                            this.notifySuccess('User subscribed!');
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => { 
                            this.requestIsGoing = false 
                        });
                }
            },
            updateTags(payload) {
                this.card.tags = cloneDeep(payload.selectedTags);
                this.allTags = cloneDeep(payload.allTags);
            },
            getPreviewUrl(fileType, filePath) {
                if (!FILE_EXTENSIONS.IMAGES.some(type => type == fileType)) {
                    return;
                }
                return `${this.url}${filePath.replace("original", "small")}`;
            },
            toggleTagsModal() {
                this.isTagsModalShow = !this.isTagsModalShow;

                if (!this.allTags.length && this.isTagsModalShow) {
                    this.getTags();
                }
            },
            toggleCardModals(type) {
                switch (type) {
                    case 'dueDateModal':
                        this.isDueDateModalShow = !this.isDueDateModalShow;
                        this.isAppointmentDateModalShow = false;
                        this.isAssignModalShow = false;
                        this.isSubscribeModalShow = false;
                        break;
                    case 'appointmentDateModal':
                        this.isAppointmentDateModalShow = !this.isAppointmentDateModalShow;
                        this.isDueDateModalShow = false;
                        this.isAssignModalShow = false;
                        this.isSubscribeModalShow = false;
                        break;
                    case 'assignModal':
                        this.isAssignModalShow = !this.isAssignModalShow;
                        this.isDueDateModalShow = false;
                        this.isAppointmentDateModalShow = false;
                        this.isSubscribeModalShow = false;
                        break;
                    case 'subscribeModal':
                        this.isSubscribeModalShow = !this.isSubscribeModalShow;
                        this.isAssignModalShow = false;
                        this.isDueDateModalShow = false;
                        this.isAppointmentDateModalShow = false;
                }
            },
            unarchiveCard() {
                api.post(`/kanban/cards/${this.card.id}`)
                    .then(response => response.data.data)
                    .then(data => {
                        if (data) {
                            this.card.is_archive = false;
                            this.notifySuccess('Card unarchived');
                        }
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    });
            },
            deleteCard() {
                this.requestIsGoing = true;

                api.delete(`/kanban/cards/delete/${this.card.id}`)
                    .then(response => response.data.data)
                    .then(data => {
                        if (data) {
                            this.notifySuccess('Card deleted!');
                            this.$emit('close', {});
                        }
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            }
        },
        mounted() {
            this.getCard();
        }
    }
</script>