import { 
    GET_MILESTONES,
    UPDATE_MILESTONES,
    CANCEL_ENTITY,
    GET_MILESTONES_STATISTICS,
    UPDATE_MILESTONE_DATE,
    SET_BOUNDARY_INDEX,
    GET_BOUNDARY
} from '../actions/milestones';
import { MILESTONE_STATUSES } from '@/constants';
import moment from 'moment/src/moment';
import api from '@/headers.js';

export default {
    namespaced: true,
    state: {
        boundary: null,
        milestones: [],
        canceled: false,
        statistics: [],
        boundaryIndex: null
    },
    getters: {
        getBoundary: state => state.boundary,
        getMilestones: state => state.milestones,
        getCanceledStatus: state => state.canceled,
        getMilestoneInprogressIndex: state => state.milestones.findIndex(m => m.status === MILESTONE_STATUSES.INPROGRESS),
        getMilestoneLastDoneIndex: state => {
            const filterArr = state.milestones.filter(m => m.status === MILESTONE_STATUSES.DONE);
            return state.milestones.lastIndexOf(el => {
                if (filterArr.length) {
                    return el.milestone.id == filterArr[filterArr.length - 1].milestone.id;
                }
            });
        },
        getMilestoneCurrent: state => { 
            let current = state.milestones.find(m => m.status === MILESTONE_STATUSES.INPROGRESS);
            
            return current
                ? current 
                : state.milestones.find(m => m.status === MILESTONE_STATUSES.COMPLETED);
        },
        getMilestonesStatistics: state => state.statistics,
        getMilestoneDead: state => state.milestones.find(m => m.status === MILESTONE_STATUSES.DEAD),
        getMilestoneLast: state => state.milestones[state.milestones.length - 1],
        getMilestoneCompleted: state => {
            return !state.milestones.find(m => m.status === MILESTONE_STATUSES.INPROGRESS)
            && !state.milestones.find(m => m.status === MILESTONE_STATUSES.AWAIT)
        },
        getBoundaryIndex: state => state.boundaryIndex
    },
    mutations: {
        [GET_BOUNDARY] (state, payload) {
            state.boundary = state.milestones.findIndex((el, i) => {
                if (el.milestone.is_boundary) {
                    return i;
                }
            });
        },
        [SET_BOUNDARY_INDEX] (state, payload) {
            state.boundaryIndex = payload;
        },
        [GET_MILESTONES] (state, payload) {
            state.canceled = false;

            // 'dead' milestone check
            if (payload.find(m => m.status === MILESTONE_STATUSES.DEAD) != undefined) {
                state.canceled = true;
            }

            // transform date
            payload.forEach((m, index) => {
                m.completed_at = m.completed_at ? moment.utc(m.completed_at, 'X') : null;
                m.created_at = m.created_at ? moment.utc(m.created_at, 'X') : null;
                m.started_at = m.started_at ? moment.utc(m.started_at, 'X') : null;
            });

            state.milestones = payload;
        },
        [UPDATE_MILESTONES] (state, payload) {
            const index = state.milestones.findIndex(i => {
                return i.milestone.id === payload.current.milestone.id
            });

            if (index !== -1) {
                // update status on current milestone
                state.milestones[index].status = MILESTONE_STATUSES.DONE;
                state.milestones[index].completed_at = payload.current.completed_at;
                
                // update date on next milestone
                if (state.milestones[index + 1]) {
                    state.milestones[index + 1].started_at = payload.current.completed_at;
                }
                
                // when converting to the last milestone set status 'done'
                if (index === state.milestones.length - 2) {
                    state.milestones[index + 1].status = MILESTONE_STATUSES.DONE;
                    state.milestones[index + 1].completed_at = payload.current.completed_at;
                } else if (state.milestones[index + 1].status) {
                    state.milestones[index + 1].status = MILESTONE_STATUSES.INPROGRESS;
                }
            }
        },
        [CANCEL_ENTITY] (state, payload) {
            state.canceled = payload;
        },
        [GET_MILESTONES_STATISTICS] (state, payload) {
            state.statistics = payload;
        },
        [UPDATE_MILESTONE_DATE] (state, payload) {
            let index = state.milestones.findIndex(m => m.milestone.id === payload.milestone_id);
            if (index !== -1) {
                state.milestones[index].started_at = moment.utc(payload.started_at);
                state.milestones[index].last_updated = moment.utc(payload.updated_at);
            }
        }
    },
    actions: {
        [GET_MILESTONES]({ commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                api.get(`/entities/${payload.entityId}/milestones`, { 
                    params: { entityType: payload.entityType } 
                }).then(response => response.data.data)
                    .then(data => {
                        commit(GET_MILESTONES, data);
                        commit(GET_BOUNDARY, data);
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        [UPDATE_MILESTONES]({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                let toSave = [];
                
                data.milestones.map((m) => {
                    toSave.push({
                        id: m.milestone.id,
                        started_at: m.started_at == null ? null : m.started_at,
                        completed_at: m.completed_at == null ? null : m.completed_at,
                        extra_reason: m.extra_reason,
                        milestone_from: data.updated.current.milestone.id === m.milestone.id,
                        milestone_to: data.updated.next ? data.updated.next.milestone.id === m.milestone.id : false
                    });
                });

                api.put(`/entities/${data.id}/milestones`, { milestones: toSave, entityType: data.entityType })
                    .then(response => {
                        commit(UPDATE_MILESTONES, data.updated);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        [CANCEL_ENTITY]({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                let params = {
                    reason: data.reason,
                    extra_reason: data.extra_reason,
                    entityType: data.entityType
                };

                api.put(`/entities/${data.entity_id}/markAsDead`, params)
                    .then(response => {
                        commit(CANCEL_ENTITY, true);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        [GET_MILESTONES_STATISTICS]({ commit, dispatch }, params) {
            return new Promise((resolve, reject) => {
                api.get(`/entities/${params.id}/history-milestone`, { params: { entityType: params.entityType }})
                    .then(response => {
                        commit(GET_MILESTONES_STATISTICS, response.data.data);
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        [UPDATE_MILESTONE_DATE]({ commit, dispatch }, params) {
            return new Promise((resolve, reject) => {
                api.put(`/entities/${params.entityId}/updateMilestoneDate`, params)
                    .then(response => {
                        commit(UPDATE_MILESTONE_DATE, response.data.data);
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
    }
}
