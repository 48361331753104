var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{class:[
        'estimate-preview-box w-100 relative pv-25 ph-20',
        { 'estimate-preview-box-active' : _vm.active }
    ],attrs:{"id":_vm.id}},[_vm._t("radio"),_c('div',{class:[
            'preview-box-headline row-between pointer',
            { 'flex-no-wrap' : _vm.dScreen },
            { 'flex-column' : _vm.mScreen || this.nScreen }
        ]},[_c('div',{staticClass:"preview-box-captions column-start lg-9 sm-12 ls-sm-9 mr-20",on:{"click":_vm.moveToItemPage}},[_c('ul',{staticClass:"row-start align-center"},[_c('li',{staticClass:"sm-12 f-12-grey pr-6 pb-1 estimate-list-item"},[_vm._v("\n                    "+_vm._s(_vm._f("date")(_vm.item.created_at))+"\n                ")]),(_vm.item.user)?_c('li',{staticClass:"sm-12 f-12-grey pr-6 pb-1 estimate-list-item"},[_vm._v("\n                    by "+_vm._s(_vm.item.user.fullname)+"\n                ")]):_vm._e()]),_c('div',{staticClass:"row-start row-baseline mb-2 flex-no-wrap"},[_c('div',{staticClass:"title-icon-container s-align-5 mr-3"},[(_vm.item.type === _vm.ESTIMATE_TYPES.MANUAL)?_c('edit-icon',{staticClass:"i-green",attrs:{"size":"11"}}):_c('template-icon',{staticClass:"inverted-template-icon",attrs:{"size":"15"}})],1),_c('p',{staticClass:"f-16-darkgrey f-sbold"},[_vm._v("\n                    "+_vm._s(_vm.item.name)+"\n                ")])]),(_vm.item.status == _vm.item.all_statuses.outdated)?_c('div',{staticClass:"lg-12 sm-12 mt-sm-1 s-align-4 pb-2"},[_c('round-warning-icon',{staticClass:"i-orange"}),_c('div',{staticClass:"f-12-orange opacity-8 pl-2"},[_vm._v("Material or Labor substitute needed")])],1):_vm._e(),_c('div',{staticClass:"row-start"},[(_vm.item.type !== _vm.ESTIMATE_TYPES.MANUAL)?_c('p',{staticClass:"sm-12 f-12-grey pr-6 pb-1"},[_vm._v("\n                    Status:\n                    "),_c('span',{class:[
                        'capitalize relative', ("preview-box-" + (_vm.defineEstimateStatus(_vm.item.status)))
                    ]},[_vm._v("\n                        "+_vm._s(_vm.defineEstimateStatus(_vm.item.status))+"\n                    ")])]):_vm._e(),(_vm.item.valid_until)?_c('p',{staticClass:"sm-12 f-12-grey pb-1"},[_vm._v("\n                    Valid until:\n                    "),_c('span',{staticClass:"f-12-black"},[_vm._v(_vm._s(_vm._f("date")(_vm.item.valid_until)))])]):_vm._e(),(_vm.item.trades && _vm.item.trades.length)?_c('p',{staticClass:"sm-12 f-12-grey pr-6 pb-1"},[_vm._v("\n                Trades:\n                "),_c('span',{staticClass:"capitalize relative"},[_vm._v(_vm._s(_vm.item.trades.map(function (el) { return el.name; }).join(', ')))])]):_vm._e()]),(_vm.item.from_library_updated_date)?_c('div',{staticClass:"row-start s-align-4 f-12-grey"},[_c('calendar-icon',{staticClass:"i-blue mr-1",attrs:{"size":"12"}}),_vm._v("\n                Price updated:\n                "),_c('span',{staticClass:"f-12-black ph-1"},[_vm._v(_vm._s(_vm._f("date")(_vm.item.from_library_updated_date))+" ")]),_c('span',{staticClass:"f-12-grey"},[_vm._v("("+_vm._s(_vm._f("timeAgo")(_vm.item.from_library_updated_date))+")")])],1):_vm._e()]),_c('div',{staticClass:"preview-box-buttons sm-12 ls-sm-3"},[_vm._t("buttons")],2)]),(_vm.page == _vm.ESTIMATE && _vm.item.versions && _vm.versionsLength)?_c('div',{class:[
            'column-start w-100 ph-8', 
            _vm.disabled ? 'pointer' : 'cursor-default',
            { 'pb-4' : !_vm.item.description }
        ]},[_c('div',{staticClass:"s-align-4 pb-3"},[_c('button',{staticClass:"transparent-button",attrs:{"disabled":!_vm.versionsActively},on:{"click":function($event){_vm.versionListIsOpen = !_vm.versionListIsOpen}}},[(_vm.versionsActively)?_c('arrow-icon',{staticClass:"i-asphalt mr-1",attrs:{"transform":_vm.versionListIsOpen ? 270 : 90}}):_vm._e(),_c('span',{staticClass:"f-12-black f-sbold"},[_vm._v("\n                    Versions\n                    "),(_vm.versionsActively)?_c('span',{staticClass:"f-12-grey"},[_vm._v("\n                        ("+_vm._s(_vm.versionsLength)+")\n                    ")]):_vm._e()])],1)]),_c('ul',{staticClass:"pl-8 w-100"},_vm._l((_vm.versions),function(version,index){return _c('li',{key:("version-" + index),staticClass:"estimate-version p-6"},[_c(_vm.versionTag,{tag:"component",staticClass:"row-start row-baseline",attrs:{"to":{ name: _vm.editEstimateRoute, params: { estimate_id: version.id }}}},[_c('div',{class:['column-start sm-12', _vm.showBaseBidLabel ? 'lg-10' : 'lg-12']},[_c('p',{staticClass:"f-14-blue relative pb-1"},[_vm._v("\n                            "+_vm._s(version.comment ? version.comment : version.name)+"\n                        ")]),_c('div',{staticClass:"row-start"},[(version.valid_until)?_c('span',{staticClass:"sm-12 f-12-grey pr-8"},[_vm._v("\n                                Valid until:\n                                "),_c('span',{staticClass:"f-12-black"},[_vm._v(_vm._s(_vm._f("date")(version.valid_until)))])]):_vm._e(),_c('span',{staticClass:"sm-12 f-12-grey"},[_vm._v("\n                                Status:\n                                "),_c('span',{class:[
                                        'capitalize relative',
                                        ("preview-box-" + (_vm.defineEstimateStatus(version.status)))
                                    ]},[_vm._v("\n                                    "+_vm._s(_vm.defineEstimateStatus(version.status))+"\n                                ")])])])]),(_vm.showBaseBidLabel)?_c('div',{staticClass:"lg-2 sm-12 s-align-3"},[(version.status === _vm.item.all_statuses.final
                                || version.type === _vm.ESTIMATE_TYPES.MANUAL)?_c('button',{class:[
                                'base-bid-version-label transparent-button f-10-white',
                                { 'active' : version.is_base_bid }
                            ],attrs:{"disabled":_vm.baseBidLabelDisabled},on:{"click":function($event){$event.preventDefault();return _vm.$emit('setBaseBid', version.id)}}},[_vm._v("\n                            BASE BID\n                        ")]):_vm._e()]):_vm._e()])],1)}),0)]):_vm._e(),(_vm.page === _vm.ESTIMATE && _vm.item.type === _vm.ESTIMATE_TYPES.MANUAL)?_c('div',{staticClass:"row-end pl-8 pr-8 pb-8",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[(_vm.filesLength)?_c('div',{staticClass:"lg-9 sm-12 row-start row-baseline"},[_c('attach-icon',{staticClass:"i-grey mr-2"}),_c('span',{staticClass:"f-12-grey mr-3"},[_vm._v("Files:")]),_vm._l((_vm.item.files),function(file,index){return _c('button',{key:index,staticClass:"transparent-button break-all mb-sm-1",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.previewFileIndex = index, _vm.previewModalOpened = true}}},[_c('span',{staticClass:"f-12-black mr-1"},[_vm._v("\n                    "+_vm._s(file.original_name)+"\n                ")]),_c('span',{staticClass:"f-12-grey mr-3"},[_vm._v("\n                    "+_vm._s(((Math.round(file.size/1024*100)/100) + "Kb" + (index !== _vm.filesLength -1 ? ',' : '')))+"\n                ")])])})],2):_vm._e(),_c('div',{staticClass:"lg-3 sm-12 s-align-9"},[_c('span',{staticClass:"f-12-grey mr-1"},[_vm._v("Total:")]),_c('span',{staticClass:"f-15-black f-sbold"},[_vm._v("\n                "+_vm._s(_vm._f("currency")(_vm.item.amount))+"\n            ")])])]):_vm._e(),(_vm.item.description)?_c('div',{staticClass:"w-100"},[_c('div',{class:[
                'column-start w-100 f-12-grey pointer',
                { 'pb-8' : !_vm.buttonsVisible }
            ],on:{"click":_vm.moveToItemPage}},[_c('span',{class:[
                'preview-box-description preserve-whitespaces hidden break-word w-100',
                { 'line-clamp-5' : _vm.toggle }
            ]},[_vm._v("\n                "+_vm._s(_vm.item.description)+"\n            ")])]),(_vm.buttonsVisible)?_c('div',{staticClass:"s-align-5 mt-3 w-100 pointer"},[_c('button',{class:[
                    'transparent-button pb-8 row-start',
                    _vm.toggle ? 'f-12-blue' : 'f-12-grey'
                ],style:(_vm.readMoreLessButtonWidth),attrs:{"disabled":_vm.disabled},on:{"click":_vm.toggleDescription}},[_c('div',{staticClass:"s-align-4 w-100"},[_vm._v("\n                    "+_vm._s(_vm.toggle ? 'Read more' : 'Show less')+"\n                    "),_c('arrow-icon',{class:[
                            'ml-1',
                            _vm.toggle ? 'i-blue' : 'i-grey'
                        ],attrs:{"transform":_vm.toggle ? 90 : 270}})],1)])]):_vm._e()]):_vm._e(),(_vm.previewModalOpened)?_c('file-preview-modal',{attrs:{"file":_vm.item.files[_vm.previewFileIndex]},on:{"close":function($event){_vm.previewModalOpened = false, _vm.previewFileIndex = null}}}):_vm._e(),(_vm.showBaseBidLabel && (_vm.item.status === _vm.item.all_statuses.final || _vm.item.type === _vm.ESTIMATE_TYPES.MANUAL))?_c('button',{class:[
            'base-bid-label transparent-button f-10-white',
            { 'active' : _vm.item.is_base_bid }
        ],attrs:{"disabled":_vm.baseBidLabelDisabled},on:{"click":function($event){$event.preventDefault();return _vm.$emit('setBaseBid', _vm.item.id)}}},[_vm._v("\n        BASE BID\n    ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }