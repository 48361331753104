<template>
    <svg 
        viewBox="0 0 54.2 54.2"
        :width="size" 
        :height="size"
        class="tag-icon" 
    >
        <path d="M38.4,8.4c-4.1,0-7.5,3.3-7.5,7.5c0,4,3.4,7.4,7.5,7.4s7.5-3.4,7.5-7.4C45.8,11.7,42.5,8.4,38.4,8.4z M42.3,15.9c0,2.2-1.8,4-4,4s-4-1.8-4-4s1.8-3.9,4-3.9S42.3,13.7,42.3,15.9z"/>
        <path d="M52.2,2c-1.4-1.2-3.2-2-5.1-2L31.6,0.5c-2.5,0.2-5,1.3-6.8,3.1L2.4,26C0.9,27.5,0,29.6,0,31.8s0.9,4.2,2.4,5.8l14.2,14.2c1.5,1.5,3.6,2.4,5.8,2.4c2.1,0,4.1-0.8,5.8-2.4l22.3-22.3c1.9-1.9,3-4.3,3.1-6.9l0.6-15.5C54.3,5.2,53.5,3.4,52.2,2z M50.8,7.1l-0.6,15.3c-0.1,1.7-0.9,3.4-2.1,4.5L25.8,49.2c-0.9,0.9-2,1.4-3.3,1.4c-1.2,0-2.4-0.5-3.3-1.4L5,35c-0.9-0.9-1.4-2-1.4-3.3c0-1.2,0.5-2.4,1.4-3.3L27.3,6.1C28.5,4.9,30,4.2,31.8,4l15.4-0.5c0.7,0,1.8,0.2,2.5,1C50.4,5.2,50.8,6.2,50.8,7.1z"/>
    </svg>
</template>

<script>
    export default {
        name: 'TagIcon',
        props: {
            size: {
                type: [Number, String],
                default: 14
            }
        }
    }
</script>
