<template>
    <p v-if="filter">
        <template v-if="!commasFilter">
            {{ dynamicFilter(filter, displayNumber) }}
        </template>
        <template v-else>
            {{ dynamicFilter(filter, displayNumber) | numberWithCommas }}
        </template>
    </p>
    <p v-else>
        {{ displayNumber }}
    </p>
</template>

<script>
    import dynamicFilter from '@/mixins/dynamicFilter';

    export default {
        name: 'AnimateNumber',
        data () {
            return {
                displayNumber: 0,
                interval: null
            }
        },
        props: {
            number: {
                type: [String, Number],
                default: 0
            },
            filter: {
                type: String,
                required: false
            },
            commasFilter: {
                type: Boolean,
                default: false
            }
        },
        mixins: [dynamicFilter],
        methods: {
            updateNumber() {
                this.interval = window.setInterval(function() {
                    if(this.displayNumber != this.number){
                        let change = (this.number - this.displayNumber) / 10;
                        this.displayNumber = this.displayNumber + change;
                    }

                    if (Math.floor(this.displayNumber) === Math.floor(this.number)) {
                        this.displayNumber = this.number;
                        clearInterval(this.interval);
                    }
                }.bind(this), 20);
            }
        },
        watch: {
            number: function() {            
                if (this.number == this.displayNumber) {
                    return;
                }

                this.updateNumber();
            }
        },
        mounted() {
            this.updateNumber();
        }
    }
</script>
