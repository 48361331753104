<template>
    <div class="w-100">
        <div v-if="activeTab === 'information'">
            <div :class="dScreen ? 'ph-30' : 'ph-8'">
                <div class="row-start row-baseline mb-8">
                    <div class="lg-5 md-6 sm-12 column-start">
                        <div class="f-15-darkgrey f-sbold">Dates</div>
                        <div class="f-13-grey mb-2">
                            Select date
                        </div>
                    </div>
                    <div
                        :class="[
                            'lg-7 md-6 sm-12 row-start',
                            dScreen && 'flex-no-wrap'
                        ]"
                    >
                        <form-datepicker
                            v-model="report.dateOfRequest"
                            :isFullWidth="true"
                            keyDatePicker="date_of_request_date_picker"
                            placeholder="Date of Request"
                            :class="dScreen ? 'mr-4' : 'mb-8'"
                        />
                        <form-datepicker
                            v-model="report.dateOfResponse"
                            :isFullWidth="true"
                            keyDatePicker="date_of_response_date_picker"
                            placeholder="Date of Response"
                        />
                    </div>
                </div>
                <div class="pv-8 bb-grey bt-grey">
                    <div class="row-start row-baseline mb-2 s-align-1">
                        <div class="lg-5 md-6 sm-12 column-start pv-8">
                            <div class="f-15-darkgrey f-sbold">Main Information</div>
                        </div>
                        <div class="lg-7 md-6 sm-12 row-start">
                            <div class="w-100 pb-4">
                                <form-input label="Requested By">
                                    <input
                                        v-if="report"
                                        type="text"
                                        name="requested_by"
                                        class="form-input-field"
                                        placeholder="Requested By"
                                        v-input-style="report.requestedBy"
                                        v-model="report.requestedBy"
                                    />
                                </form-input>
                            </div>
                            <div class="row-start">
                                <div class="lg-12 md-12 sm-12 pr-2 pr-sm-0 pb-4">
                                    <form-input label="Property Name">
                                        <input
                                            v-if="report"
                                            type="text"
                                            name="name"
                                            class="form-input-field"
                                            placeholder="Property Name"
                                            v-input-style="report.name"
                                            v-model="report.name"
                                        />
                                    </form-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row-start row-baseline">
                        <div class="lg-5 md-6 sm-12 column-start pv-8">
                            <div class="f-15-darkgrey f-sbold">Date of Report</div>
                        </div>
                        <div class="lg-7 md-6 sm-12 row-start">
                            <form-datepicker
                                v-model="report.dateOfReport"
                                keyDatePicker="date_of_reports_date_picker"
                                :isFullWidth="true"
                            />
                        </div>
                    </div>
                </div>
                <div class="row-start row-baseline mb-2 s-align-1 mt-8">
                    <div class="lg-5 md-6 sm-12 column-start pv-8">
                        <div class="f-15-darkgrey f-sbold">Address</div>
                    </div>
                    <div class="lg-7 md-6 sm-12 row-start">
                        <address-box
                            type="address"
                            :key="'address'"
                            :existing="report.address"
                            :validate="VALIDATE_NOT_REQUIRED"
                            @address="getAddress"
                        />
                    </div>
                </div>
            </div>
            <div class="form-builder-content row-start">
                <div class="form-builder-section w-100 pv-8 p-30">
                    <div class="row-between">
                        <div class="f-16-darkgrey f-sbold s-align-4">Description</div>
                        <div class="relative">
                            <button
                                class="view-details-primary ph-8"
                                :disabled="!technicianData.length"
                                @click="getTechniciansIntakeDetails(technicianData[0])"
                            >
                                + Get technicians intake details
                            </button>
                        </div>
                    </div>
                    <div class="mt-8 column-start">
                        <tabs-menu
                            class="mb-4"
                            identifier="form-builder-leak-response"
                        >
                            <li slot="links">
                                <button
                                    :class="[
                                        'tabs-menu-item s-align-5 h-100 uppercase',
                                        activeColumnsTabLeakResponse === 'before' ? 'tabs-menu-item-active' : ''
                                    ]"
                                    @click="activeColumnsTabLeakResponse = 'before'"
                                >
                                    Before starting
                                </button>
                            </li>
                            <li slot="links">
                                <button
                                    :class="[
                                        'tabs-menu-item s-align-5 h-100 uppercase',
                                        activeColumnsTabLeakResponse === 'during' ? 'tabs-menu-item-active' : ''
                                    ]"
                                    @click="activeColumnsTabLeakResponse = 'during'"
                                >
                                    During
                                </button>
                            </li>
                            <li slot="links">
                                <button
                                    :class="[
                                        'tabs-menu-item s-align-5 h-100 uppercase',
                                        activeColumnsTabLeakResponse === 'after' ? 'tabs-menu-item-active' : ''
                                    ]"
                                    @click="activeColumnsTabLeakResponse = 'after'"
                                >
                                    After the job is done
                                </button>
                            </li>
                            <li slot="links">
                                <button
                                    :class="[
                                        'tabs-menu-item s-align-5 h-100 uppercase',
                                        activeColumnsTabLeakResponse === 'farr' ? 'tabs-menu-item-active' : ''
                                    ]"
                                    @click="activeColumnsTabLeakResponse = 'farr'"
                                >
                                    FARR
                                </button>
                            </li>
                        </tabs-menu>
                        <div
                            v-if="activeColumnsTabLeakResponse === 'before'"
                            class="w-100 row-start row-baseline mb-2 s-align-1 pt-30"
                        >
                            <div class="column-start">
                                <p class="f-16-darkgrey f-sbold pb-4">
                                    Describe the current state of the job
                                </p>
                            </div>
                            <text-editor
                                v-model="report.desc_before"
                                placeholder="Describe the current state of the job"
                                toolbarName="notesToolbar"
                                theme="bubble"
                                maxLength="2500"
                                class="w-100"
                            />
                        </div>
                        <div
                            v-if="activeColumnsTabLeakResponse === 'after'"
                            class="w-100"
                        >
                            <div class="w-100 pt-30">
                                <div class="column-start">
                                    <p class="f-16-darkgrey f-sbold pb-4">
                                        Describe repairs that you made
                                    </p>
                                </div>
                                <text-editor
                                    key="desc_after"
                                    v-model="report.desc_after"
                                    placeholder="List repairs that were completed"
                                    toolbarName="notesToolbar"
                                    theme="bubble"
                                    maxLength="2500"
                                />
                            </div>
                            <div class="w-100 pt-30">
                                <div class="column-start">
                                    <p class="f-16-darkgrey f-sbold pb-4">
                                        Materials that were used
                                    </p>
                                </div>
                                <text-editor
                                    v-model="report.material_list"
                                    placeholder="Materials list"
                                    toolbarName="notesToolbar"
                                    theme="bubble"
                                    maxLength="2500"
                                />
                            </div>
                        </div>
                        <div
                            class="w-100"
                            v-if="activeColumnsTabLeakResponse === 'during'"
                        >
                            <div class="w-100 pt-30">
                                <text-editor
                                    v-model="report.description"
                                    key="during"
                                    placeholder="During"
                                    toolbarName="notesToolbar"
                                    theme="bubble"
                                    maxLength="2500"
                                />
                            </div>
                        </div>
                        <div
                            v-if="activeColumnsTabLeakResponse === 'farr'"
                            class="w-100"
                        >
                            <div class="w-100 pt-30">
                                <div class="column-start">
                                    <p class="f-16-darkgrey f-sbold pb-4">
                                        Describe what needs to be done
                                    </p>
                                </div>
                                <text-editor
                                    v-model="report.far_desc"
                                    placeholder="Describe what needs to be done"
                                    toolbarName="notesToolbar"
                                    theme="bubble"
                                    maxLength="2500"
                                />
                            </div>
                            <div class="w-100 pt-30">
                                <div class="row-start s-align-4">
                                    <p class="f-16-darkgrey f-sbold mr-4">
                                        How many hours it will take to complete the job
                                    </p>
                                    <div :class="['lg-2 md-4 sm-6', !dScreen && 'mt-4']">
                                        <form-input>
                                            <input
                                                class="form-input-field"
                                                type="number"
                                                v-model.number="report.far_hours"
                                                placeholder="0 hours"
                                                min="0"
                                            />
                                            <button
                                                class="decrease-button transparent-button"
                                                @click="report.far_hours--"
                                                :disabled="report.far_hours <= 0"
                                            >
                                                <arrow-icon class="i-grey"/>
                                            </button>
                                            <button
                                                class="increase-button transparent-button"
                                                @click="report.far_hours++"
                                            >
                                                <arrow-icon
                                                    class="i-grey"
                                                    transform="270"
                                                />
                                            </button>
                                        </form-input>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100 pt-30">
                                <div class="column-start">
                                    <p class="f-16-darkgrey f-sbold pb-4">
                                        What materials are required
                                    </p>
                                </div>
                                <text-editor
                                    v-model="report.far_material"
                                    placeholder="Material list"
                                    toolbarName="notesToolbar"
                                    theme="bubble"
                                    maxLength="2500"
                                />
                            </div>
                            <div class="w-100 pt-30">
                                <div class="row-start s-align-4">
                                    <p class="f-16-darkgrey f-sbold mr-4">
                                        Hours logged
                                    </p>
                                    <div :class="['lg-2 md-4 sm-6', !dScreen && 'mt-4']">
                                        <form-input>
                                            <input
                                                class="form-input-field"
                                                type="number"
                                                v-model.number="report.timer_hours"
                                                placeholder="0 hours"
                                                min="0"
                                            />
                                            <button
                                                class="decrease-button transparent-button"
                                                @click="report.timer_hours--"
                                                :disabled="report.timer_hours <= 0"
                                            >
                                                <arrow-icon class="i-grey"/>
                                            </button>
                                            <button
                                                class="increase-button transparent-button"
                                                @click="report.timer_hours++"
                                            >
                                                <arrow-icon
                                                    class="i-grey"
                                                    transform="270"
                                                />
                                            </button>
                                        </form-input>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100 pt-30">
                                <div class="row-start s-align-4">
                                    <p class="f-16-darkgrey f-sbold mr-4">
                                        Hours submitted
                                    </p>
                                    <div :class="['lg-2 md-4 sm-6', !dScreen && 'mt-4']">
                                        <form-input>
                                            <input
                                                class="form-input-field"
                                                type="number"
                                                v-model.number="report.time_spend"
                                                placeholder="0 hours"
                                                min="0"
                                            />
                                            <button
                                                class="decrease-button transparent-button"
                                                @click="report.time_spend--"
                                                :disabled="report.time_spend <= 0"
                                            >
                                                <arrow-icon class="i-grey"/>
                                            </button>
                                            <button
                                                class="increase-button transparent-button"
                                                @click="report.time_spend++"
                                            >
                                                <arrow-icon
                                                    class="i-grey"
                                                    transform="270"
                                                />
                                            </button>
                                        </form-input>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100 pt-30">
                                <div class="column-start">
                                    <p class="f-16-darkgrey f-sbold pb-4">
                                        Comments for the hours submitted
                                    </p>
                                </div>
                                <text-editor
                                    v-model="report.desc_time_spend"
                                    placeholder="Comments"
                                    toolbarName="notesToolbar"
                                    theme="bubble"
                                    maxLength="2500"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-builder-custom-section bt-grey">
                <form-builder-custom-fields
                    :layoutId="page.id"
                    :updateCustomFields="updateCustomFields"
                    :entityType="entityType"
                    @changeFields="changeCustomFields"
                    :clear="clear"
                />
            </div>
        </div>
        <form-builder-leak-photo-form
            :saved="savedPhotos" 
            :crearPhoto="clear"
            @set="addPhotoToReport"
            :entityType="entityType"
            :entityId="entityId"
            :active-tab="activeTab"
        />
    </div>
</template>

<script>
    import TabsMenu from '@/components/TabsMenu.vue';
    import { FormInput, CheckboxButton, FormDatepicker, FormInputTextarea } from '@/ui';
    import { FORM_BUILDER_REPORTS, ENTITY_TYPES } from '@/constants';
    import { clearArray, secondsToHours } from '@/helpers';
    import AddressBox from '@/components/AddressBox.vue';
    import FormBuilderCustomFields from '@/components/LeadModules/FormBuilder/FormBuilderCustomFields.vue';
    import FormBuilderLeakPhotoForm from '@/components/LeadModules/FormBuilder/FormBuilderLeakPhotoForm.vue';
    import TextEditor from '@/components/TextEditor.vue';
    import { ArrowIcon } from '@/icons'

    const REPORT_TYPE = 'leak';
    const FORM_ACTION_INIT = 'init';
    const FORM_ACTION_CLEAR = 'clear';

    export default {
        name: 'FormBuilderLeakResponseReport',
        components: {
            TabsMenu,
            FormInput,
            CheckboxButton,
            FormDatepicker,
            FormInputTextarea,
            AddressBox,
            FormBuilderCustomFields,
            FormBuilderLeakPhotoForm,
            TextEditor,
            ArrowIcon
        },
        props: {
            clear: {
                type: Boolean,
                default: false
            },
            get: {
                type: Boolean,
                default: false
            },
            selected: {
                type: Object,
                required: false
            },
            entityType: {
                type: String,
                required: true
            },
            entityId: {
                type: String,
                required: true
            },
            page: {
                type: Object,
                required: false
            },
            activeTab: {
                type: String,
                required: false,
            }
        },
        data() {
            return {
                report: {},
                updateCustomFields: {},
                savedPhotos: {},
                activeColumnsTabLeakResponse: 'before'
            }
        },
        watch: {
            entityAddress(val, oldVal) {
                if (val.address) {
                    this.report.address = val;
                }
            },
            clear: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal) this.createNewReport(FORM_ACTION_CLEAR);
                }
            },
            get: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal) this.$emit('receive', this.report);
                }
            },
            selected: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal != null && newVal != oldVal) 
                        this.setReport(newVal);
                }
            }
        },
        computed: {
            entity() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD:
                        return this.$store.getters['lead/getLeadPreview'];
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.$store.getters['commercial/getCommercialJob'];
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.$store.getters['commercial/getCommercialLeadPreview'];
                }
            },
            entityAddress() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: {
                        return this.$store.getters['lead/getClippedLeadAddress'];
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_JOB: {
                        return this.$store.getters['commercial/getCommercialJobAddress'];
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return this.$store.getters['commercial/getCommercialLeadAddress'];
                    } break;
                }
            },
            technicianData() {
                return this.$store.getters['formBuilder/getTechnicianData'];
            },
            reportName() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD:
                        return null;
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.entity.property ? this.entity.property.property_name : null;
                }
            },
            reportRequestedBy() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD:
                        return null;
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.entity.lead && this.entity.lead.requested_by
                            ? this.entity.lead.requested_by
                            : null;
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.entity.requestedBy;
                }
            }
        },
        methods: {
            getTechniciansIntakeDetails(el) {
                this.setTechnicianData(el);
            },
            setTechnicianData(el) {
                this.report.desc_before = el.desc_before;
                this.report.desc_after = el.desc_after;
                this.report.desc_time_spend = el.desc_time_spend;
                this.report.far_desc = el.far_desc;
                this.report.far_hours = el.far_hours;
                this.report.far_material = el.far_material;
                this.report.material_list = el.material_list;
                this.report.description = el.description;
                this.report.time_spend = secondsToHours(el.time_spend);
                this.report.timer_hours = secondsToHours(el.timer_hours);
            },
            createNewReport(flag) {
                this.report = {
                    title: FORM_BUILDER_REPORTS.LEAK,
                    reportType: REPORT_TYPE,
                    reportNumber: this.$route.params.lead_id,
                    reportName: '',
                    dateOfRequest: null,
                    dateOfResponse: null,
                    dateOfReport: new Date(),
                    requestedBy: '',
                    name: '',
                    address: {
                        address: '',
                        street: '',
                        city: '',
                        state: '',
                        zip: ''
                    },
                    furtherAction: '',
                    photos: [],
                    mainPhoto: null,
                    beforePhotos: [],
                    afterPhotos: [],
                    description: '',
                    customFields: {},
                    desc_before: '',
                    desc_after: '',
                    desc_time_spend: '',
                    far_desc: '',
                    far_hours: null,
                    far_material: '',
                    material_list: '',
                    time_spend: null,
                    timer_hours: null
                }
                this.savedPhotos = {
                    mainPhoto: null,
                    beforePhotos: [],
                    duringPhotos: [],
                    afterPhotos: [],
                };

                if (flag == FORM_ACTION_CLEAR && this.furtherActionRequired) 
                    document.getElementById('further-action-textarea').style.height = 'auto';
            },
            getAddress(data) {
                this.report.address = data;
            },
            setReport(report) {
                this.report = this.selected;
                this.updateCustomFields = this.selected.customFields;
                this.furtherActionRequired = this.selected.furtherAction ? this.selected.furtherAction.length : 0;
                this.savedPhotos = {
                    mainPhoto: report.mainPhoto,
                    beforePhotos: report.beforePhotos,
                    duringPhotos: report.duringPhotos,
                    afterPhotos: report.afterPhotos
                }
                this.actionPerformed = false;

                this.$nextTick(() => {
                    this.actionPerformed = true;
                });
            },
            changeCustomFields(data) {
                this.report.customFields = data;
            },
            addPhotoToReport(data) {
                this.report.mainPhoto = data.mainPhoto;
                this.report.beforePhotos = data.beforePhotos;
                this.report.duringPhotos = data.duringPhotos;
                this.report.afterPhotos = data.afterPhotos;
            },
            getFullName(option) {
                return option.first_name && option.last_name
                    ? option.first_name + ' ' + option.last_name
                    : null;
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.report.name = this.reportName;
                if (this.reportRequestedBy) {
                    this.report.requestedBy = this.getFullName(this.reportRequestedBy);
                }
                this.report.address = this.entityAddress;
            });
        },
        created() {
            this.VALIDATE_NOT_REQUIRED = '';
            this.createNewReport(FORM_ACTION_INIT);
        }
    }
</script>
