<template>
    <svg 
        x="0px" 
        y="0px"
        viewBox="0 0 28 28"
        class="round-pointer-icon"
        :width="size"
        :height="size"
    >
        <g>
            <circle 
                class="st0" 
                cx="14" 
                cy="14" 
                r="14"
            />
            <g>
                <path 
                    class="st1" 
                    d="M10.1,15.6c0,0.1,0,0.2,0.1,0.2l3.4,5.1c0.1,0.2,0.4,0.3,0.6,0.1c0,0,0.1-0.1,0.1-0.1l3.5-5.1c0.1-0.2,0.1-0.5-0.1-0.6c-0.1-0.1-0.2-0.1-0.3-0.1h-1.8V7.3c0-0.2-0.2-0.4-0.4-0.4h-2.5c-0.2,0-0.4,0.2-0.4,0.4v7.8h-1.8C10.3,15.1,10.1,15.3,10.1,15.6C10.1,15.6,10.1,15.6,10.1,15.6z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'RoundPointerIcon',
        props: {
            size: {
                type: [String, Number],
                default: 30
            }
        }
    }
</script>