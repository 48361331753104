<template>
    <svg
        viewBox="-868 861.3 29 29"
        :width="size" 
        :height="size"
        class="manual-email-icon"
    >
        <g>
            <g>
                <path fill="#E0E4E9" d="M-853.8,877.8L-853.8,877.8l-2.2-1.8c-0.4-0.4-1.1-0.4-1.6,0l-9.7,8.5c0.1,0,0.2,0,0.4,0h11.8
                    c-0.1-0.6-0.2-1.3-0.2-2C-855.3,880.8-854.8,879.1-853.8,877.8z"/>
                <path fill="#E0E4E9" d="M-868,870.5v13.1c0,0.1,0,0.1,0,0.2l7.7-6.7L-868,870.5z"/>
                <path fill="#E0E4E9" d="M-859.6,876.5l1.5-1.3c0.8-0.7,2-0.7,2.8,0l1.9,1.6l8.5-7.3l-10.5-8c-0.5-0.4-1.4-0.4-1.9,0l-10.4,8
                    L-859.6,876.5z"/>
                <path fill="#E0E4E9" d="M-849.1,874.3c0.7-0.2,1.5-0.3,2.4-0.3c0.7,0,1.4,0.1,2,0.2v-3.7L-849.1,874.3z"/>
            </g>
            <ellipse fill="#8876E2" cx="-846.7" cy="882.6" rx="7.7" ry="7.7"/>
            <path fill="#FFFFFF" d="M-842.5,881c-0.1-0.1-0.3-0.1-0.4,0l-2.9,2.9c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.2
                c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.3l2.5-2.5c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3-0.1-0.4,0l-2.5,2.5
                c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.3l2.9-2.9c0.1-0.1,0.1-0.3,0-0.4
                c-0.1-0.1-0.3-0.1-0.4,0l-3.1,3.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0l-1,2.5c0,0,0,0,0,0l-0.6,1.6
                c0,0.1,0,0.2,0,0.3l0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0l4.1-1.6c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1l3.1-3.1
                C-842.4,881.3-842.4,881.1-842.5,881z M-847.7,883.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.3,0.3,0.5
                c0.1,0.1,0.2,0.2,0.3,0.2l-1.7,0.7l-0.8-0.8l0.7-1.7c0,0.1,0.1,0.2,0.2,0.3C-848,883-847.8,883.1-847.7,883.1z M-849.3,884.8
                l0.4,0.4l-0.7,0.3L-849.3,884.8z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'SentEmailIcon',
        props: {
            size: {
                type: [Number, String],
                default: 30
            }
        }
    }
</script>