<template>
    <div
        class="not-support-stub column-center s-align-5 ph-30"
        v-if="showStub"
    >
        <button 
            class="transparent-button close-stub-button"
            @click="closeStub"
        >
            <close-icon
                class='i-white'
                size="13"
            />
        </button>
        <div class="column-center">
            <rotate-screen
                size="45"
                class='i-white mb-40'
            />
            <p class="f-16-white t-center">Try rotating screen to a <br/> landscape view.</p>
            <p class="f-14-white t-center mt-5 grey-text">Current screen size is not supported.</p>
        </div>
    </div>
</template>

<script>
import { RotateScreen, CloseIcon } from '@/icons';

export default {
    name: 'NotSupportedScreen',
    components: {
        RotateScreen,
        CloseIcon
    },
    data() {
        return {
            showStub: true
        }
    },
    methods: {
        closeStub() {
            let app = document.getElementById('app');
            app.classList.remove('hidden');
            document.body.classList.remove('hidden');
            this.showStub = false;
        }
    },
    mounted() {
        let app = document.getElementById('app');
        app.classList.add('hidden');
        document.body.classList.add('hidden');
    }
}
</script>