import {GET_OPPORTUNITY, SET_OPPORTUNITY} from '../actions/opportunity';
import { showFullAddress, getDataFromQuery } from '@/helpers.js';
import api from '@/api';

export default {
    namespaced: true,
    state: {
        opportunityForEvents: {},
        requestIsGoing: false
    },
    getters: {
        getOpportunityForEvents: (state) => state.opportunityForEvents,
        requestIsGoing: state => state.requestIsGoing,
    },
    mutations: {
        [SET_OPPORTUNITY](state, payload) {
            state.opportunityForEvents = {};

            const data = getDataFromQuery(payload);

            state.opportunityForEvents = {
                zip: data.address.zip || '',
                lat: data.address.lat || '',
                lng: data.address.lng || '',
                full_address: showFullAddress(data.address),
                fullname: `${payload.first_name} ${payload.last_name}`,
                poc: payload.created_by ? payload.created_by.fullname : '',
                phone: data.phone.number || '',
                email: data.email.email || '',
            }
        },
        SET_REQUEST_GOING(state, status = false) {
            state.requestIsGoing = status;
        }
    },
    actions: {
        async [GET_OPPORTUNITY]({ commit }, Id) {
            const opportunityData = await api.Opportunity.findById(Id);
            commit(SET_OPPORTUNITY, opportunityData.data.data);
            return opportunityData;
        },
        createOpportunity({ commit }, formData) {
            return new Promise((resolve, reject) => {
                commit('SET_REQUEST_GOING', true);
                api.Opportunity.create(formData)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch(e => {
                        this.notifyError(e);
                        reject(e);
                    })
                    .finally(() => {
                        commit('SET_REQUEST_GOING', false);
                    })
            });
        }
    }
}