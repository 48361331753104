<template>
    <span class="row-baseline">
        <span
            v-if="showPhoneType"
            class="f-12-grey mr-2"
        >
            {{ defineTelType(phone.type) }}:
        </span>
        <span
            :class="[
                { 'f-12-black' : !isShowWidget, 'f-12-blue pointer' : isShowWidget }
            ]"
            @click.stop="makeCall"
        >
            {{ phoneNumber | phone }}
        </span>
        <button
            class="ml-2"
            :class="iconBtn !== 'call-btn' ? 'phone-button-round' : 'phone-button'"
            v-if="isShowWidget"
            @click.stop="makeCall"
        >
            <component
                :is="iconBtn"
                class="i-white"
                :size="iconBtn !== 'call-btn' ? 10 : 40"
            />
        </button>
    </span>
</template>

<script>
import { FillPhoneIcon, CallBtn } from '@/icons';
import { LEAD_TEL_TYPES } from '@/constants';

export default {
    name: 'MakeCallFromNumberButton',
    components: { 
        FillPhoneIcon,
        CallBtn
    },
    props: {
        phone: {
            type: [Object, String],
            required: true
        },
        showPhoneType: {
            type: Boolean,
            default: true
        },
        entityId: {
            type: [Number, String],
            required: true
        },
        entityType: {
            type: String
        },
        callToCustomerFromLeadJob: {
            type: Object,
            required: false
        },
        iconBtn: {
            type: String,
            default: 'fill-phone-icon'
        }
    },
    computed: {
        phoneNumber() {
            if (typeof this.phone === 'object') {
                return (this.phone && this.phone.num) ? this.phone.num :
                    (this.phone && this.phone.number) ? this.phone.number : null;
            }

            if (typeof this.phone === 'string') {
                return this.phone ? this.phone : null;
            }
        },
        jobOrLeadInfoForCustomerCall() {
            return !!(this.callToCustomerFromLeadJob && Object.keys(this.callToCustomerFromLeadJob).length);
        },
        isShowWidget() {
            return this.$store.getters['telephony/isWidgetWork'];
        },
        authUser() {
            return this.$store.getters['user/getUserData'];
        }
    },
    methods: {
        defineTelType(telType) {

            telType = typeof telType === 'string' ? telType.toLowerCase() : telType;

            switch (telType) {
                case 1:
                case LEAD_TEL_TYPES.HOME.toLowerCase():
                    return LEAD_TEL_TYPES.HOME;
                case 2:
                case LEAD_TEL_TYPES.MOBILE.toLowerCase():
                    return LEAD_TEL_TYPES.MOBILE;
                case 3:
                case LEAD_TEL_TYPES.WORK.toLowerCase():
                    return LEAD_TEL_TYPES.WORK;
            }
        },
        makeCall() {
            if (!this.phoneNumber) {
                return false;
            }

            let callInfo = {
                phone_number: this.phoneNumber,
                entity_id: Number(this.entityId),
                user_id: this.authUser.id
            };
            if (this.entityType){
                callInfo.entity_type = this.entityType;
            }

            if (this.jobOrLeadInfoForCustomerCall) {
                callInfo.jobOrLeadEntityInfo = this.callToCustomerFromLeadJob;
            }

            this.$store.dispatch('telephony/MAKE_OUTCOME_CALL', callInfo);
        }
    }
}
</script>
