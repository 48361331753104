import moment from 'moment/src/moment';

export const userTechnicsDayView = {
    directives: {
        expandColumn: {
            inserted(el, binding) {
                let events = el;
                let allUserEvents = binding.value;
                let timeEvent = {};
                let eventsArray = [];
                let countEventsInHour = 0;

                function getMaxOfArray(numArray) {
                    return Math.max.apply(null, numArray);
                }

                if (allUserEvents.length) {
                    /* Write all events to the object. 
                     * Where the key is the time of the event, 
                     * and the value is an array with all events at that time.
                     */
                    allUserEvents.forEach((e) => {
                        if (e.all_day) {
                            for (let i = 0; i < 23; i++) {
                                if (timeEvent[i] === undefined) {
                                    timeEvent[i] = [e];
                                } else {
                                    timeEvent[i].push(e);
                                }
                            }
                        } else {
                            let startHour = moment(e.dtstart).hour();
                            let endHour = moment(e.dtend).hour();

                            if (endHour === startHour) {
                                if (timeEvent[startHour] === undefined) {
                                    timeEvent[startHour] = [e];
                                } else {
                                    timeEvent[startHour].push(e);
                                }
                            }

                            for (let i = startHour; i < endHour; i++) {
                                if (timeEvent[i] === undefined) {
                                    timeEvent[i] = [e];
                                } else {
                                    timeEvent[i].push(e);
                                }
                            }
                        }
                    });
                }

                for (let key in timeEvent) {
                    if (timeEvent[key].length) {
                        eventsArray.push(timeEvent[key].length);
                    }
                }

                if (eventsArray.length) {
                    countEventsInHour = getMaxOfArray(eventsArray);
                }

                if (countEventsInHour) {
                    el.style.minWidth = countEventsInHour * 210 + 'px';
                } else {
                    el.style.minWidth = '210 px';
                }
                

                for (let key in timeEvent) {
                    let position = 0;
                    if (timeEvent[key].length ) {
                        timeEvent[key].forEach((event) => {
                            if (el.childNodes.length) {
                                el.childNodes.forEach((el, index) => {                                        
                                    if (event.id === el.id.substring(6, el.id.length)) {
                                        el.style.left = position + 'px';
                                        position += 210;
                                    }
                                });
                            }
                        });
                    }
                }
            },
            componentUpdated(el, binding) {
                let events = el;
                let allUserEvents = binding.value;
                let timeEvent = {};
                let eventsArray = [];
                let countEventsInHour = 0;

                function getMaxOfArray(numArray) {
                    return Math.max.apply(null, numArray);
                }

                if (allUserEvents.length) {
                    allUserEvents.forEach((e) => {
                        if (e.all_day) {
                            for (let i = 0; i < 23; i++) {
                                if (timeEvent[i] === undefined) {
                                    timeEvent[i] = [e];
                                } else {
                                    timeEvent[i].push(e);
                                }
                            }
                        } else {
                            let startHour = moment(e.dtstart).hour();
                            let endHour = moment(e.dtend).hour();

                            if (endHour === startHour) {
                                if (timeEvent[startHour] === undefined) {
                                    timeEvent[startHour] = [e];
                                } else {
                                    timeEvent[startHour].push(e);
                                }
                            }

                            for (let i = startHour; i < endHour; i++) {
                                if (timeEvent[i] === undefined) {
                                    timeEvent[i] = [e];
                                } else {
                                    timeEvent[i].push(e);
                                }
                            }
                        }
                    });
                }

                for (let key in timeEvent) {
                    if (timeEvent[key].length) {
                        eventsArray.push(timeEvent[key].length);
                    }
                }

                if (eventsArray.length) {
                    countEventsInHour = getMaxOfArray(eventsArray);
                }

                if (countEventsInHour) {
                    el.style.minWidth = countEventsInHour * 210 + 'px';
                } else {
                    el.style.minWidth = '210 px';
                }

                for (let key in timeEvent) {
                    let position = 0;
                    if (timeEvent[key].length) {
                        timeEvent[key].forEach((event) => {
                            if (el.childNodes.length) {
                                el.childNodes.forEach((el, index) => {                                        
                                    if (event.id === el.id.substring(6, el.id.length)) {
                                        el.style.left = position + 'px';
                                        position += 210;
                                    }

                                    if (event.is_event_placeholder && el.classList.contains('event-placeholder')) {
                                        el.style.left = (timeEvent[key].length - 1) * 210 + 'px';
                                    }
                                });
                            }
                        });
                    }
                }
            }
        }
    }
}