// form
import RadioButton from './RadioButton.vue';
import CheckboxButton from './CheckboxButton.vue';
import FormInput from './FormInput.vue';
import Dropdown from './Dropdown.vue';
import DropdownMenu from './DropdownMenu.vue';
import PriorityCheckbox from './PriorityCheckbox.vue';
import Trade from './Trade.vue';
import SelectInput from './SelectInput.vue';
import CurrencyInput from './CurrencyInput';
import AnimateNumber from './AnimateNumber.vue';
import FormColorpicker from './FormColorpicker.vue';
import FormDatepicker from './FormDatepicker.vue';
import FormTimeInput from './FormTimeInput.vue';
import FormInputTextarea from './FormInputTextarea.vue';
import FormDateTimeInput from './FormDateTimeInput.vue';
import SubmitButton from './SubmitButton.vue';
import RadioSwitch from './RadioSwitch.vue';
import ToggleSwitch from './ToggleSwitch.vue';
import FormDateRangeInput from './FormDateRangeInput.vue';
import FormDateRangeInputWithDateMenu from './FormDateRangeInputWithDateMenu.vue';
import AlertButton from './AlertButton.vue';
import CheckboxMultiselect from './CheckboxMultiselect.vue';
import PercentArrowInput from './PercentArrowInput';
import ClearInputValue from "./ClearInputValue";
import FormWebsiteInput from "./FormWebsiteInput";

// load
import LoadMore from './LoadMore.vue'; // new
import Loader from './Loader.vue';
import LoaderSolid from './LoaderSolid.vue';

// modal (components)
import Modal from './Modal/Modal.vue';
import FullModal from './Modal/FullModal.vue';
import FullScreenModal from './Modal/FullScreenModal';

// notification
import CustomNotification from './CustomNotification';

// components
import SimpleDiagram from './SimpleDiagram.vue';
import PercentCount from './PercentCount.vue';
import Breadcrumbs from './Breadcrumbs';
import CrewsCalendar from './Cards/CrewsCalendar';
import StatisticCards from './Cards/StatisticCards';
// tooltip
import Tooltip from './Tooltip';

// search bar
import SearchBar from './SearchBar';

// pagination
import Pagination from './Pagination';

// Move to Top component (button)
import ScrollToTopButton from './ScrollToTopButton.vue';

// Telephony
import PhoneCallRoundedButton from './PhoneCallRoundedButton.vue';

import TabPanel from './TabPanel';

//Call log
import AudioPlayer from './AudioPlayer.vue';

export {
    // form
    RadioButton,
    CheckboxButton,
    FormInput,
    Dropdown,
    DropdownMenu,
    PriorityCheckbox,
    Trade,
    SelectInput,
    CurrencyInput,
    AnimateNumber,
    FormDatepicker,
    FormTimeInput,
    FormInputTextarea,
    FormDateTimeInput,
    SubmitButton,
    RadioSwitch,
    ToggleSwitch,
    FormDateRangeInput,
    FormDateRangeInputWithDateMenu,
    FormColorpicker,
    AlertButton,
    CheckboxMultiselect,
    PercentArrowInput,
    ClearInputValue,
    FormWebsiteInput,
    // load
    Loader,
    LoaderSolid,
    LoadMore, // new

    // modal
    Modal,
    FullModal,
    FullScreenModal,

    // notification
    CustomNotification,
    
    // components
    SimpleDiagram,
    PercentCount,
    Breadcrumbs,
    CrewsCalendar,
    StatisticCards,

    // tooltip
    Tooltip,
    
    // search bar
    SearchBar,

    // pagination
    Pagination,

    // Move to Top button
    ScrollToTopButton,

    // Telephony
    PhoneCallRoundedButton,

    TabPanel,
    
    //Call log
    AudioPlayer
}
