<template>
    <perfect-scrollbar class="commercial-list-details ps-grey w-100">
        <div 
            class="w-100" 
            v-if="loading"
        >
            <loader/>
        </div>
        <div 
            class="w-100" 
            v-else
        >
            <div class="s-align-4 w-100 pb-4">
                <clock-icon 
                    class="i-grey mr-2"
                    size="12"
                />
                <span class="f-11-grey">{{ unit.created_at | timeAgo }}</span>
            </div>
            <div class="column-start w-100">
                <h2 class="f-20-darkgrey f-sbold pb-4">
                    <router-link
                        v-if="unit.property.customer"
                        :to="{ 
                            name: 'CommercialDetails', 
                            params: { 
                                customer_id: unit.property.customer_id, 
                                property_id: unit.property.id,
                                unit_id: unit.id
                            }
                        }"
                    >
                        {{ unit.name }}
                    </router-link>
                    <router-link
                        v-else
                        :to="{ 
                            name: 'CommercialPropertyWithoutCustomer', 
                            params: { property_id: unit.property.id }
                        }"
                        class="black-link-underlined relative"
                    >
                        {{ unit.name }}
                    </router-link>
                </h2>
                <div class="row-start pb-3">
                    <div :class="['md-12', addressIsCorrect ? 'lg-8 nb-7' : 'lg-12 nb-12']">
                        <span class="f-12-grey">
                            Address:
                            <a
                                :href="getGoogleMapLink(unit.lat, unit.lng)"
                                :class="[
                                    unit.full_address ? 'f-12-blue' : 'f-italic',
                                    {'none-events': !getGoogleMapLink(unit.lat, unit.lng)}
                                ]"
                                target="_blank"
                            >
                                {{
                                    unit.full_address 
                                    ? unit.full_address 
                                    : 'No information'
                                }}
                                <link-icon 
                                    v-if="getGoogleMapLink(unit.lat, unit.lng)"
                                    class="i-blue"
                                />
                            </a>
                        </span>
                    </div>
                    <div 
                        class="s-align-3 lg-4 nb-5 md-12 mt-md-1"
                        v-if="addressIsCorrect"
                    >
                        <button 
                            class="show-on-map"
                            @click="$emit('showMap', { lat: +unit.lat, lng: +unit.lng })"
                        >
                            <map-icon/>
                            Show on Map
                        </button>
                    </div>
                </div>
            </div>
            <div class="column-start pv-30 w-100 bb-grey">
                <h2 class="f-16-darkgrey f-sbold mb-8">Information</h2>
                <p
                    v-if="unit.property.customer"
                    class="f-12-grey pb-3"
                >
                    Responsible billing party:
                    <span class="f-12-black pl-1">
                        <router-link
                            :to="{ name: 'CommercialDetails', 
                            params: { customer_id: unit.property.customer_id }}"
                            class="black-link-underlined relative"
                        >
                            {{ unit.property.customer.name }}
                        </router-link>
                    </span>
                </p>
                <p 
                    class="f-12-grey"
                    v-if="unit.state"
                >
                    State: 
                    <span class="f-12-black pl-1">{{ unit.state }}</span>
                </p>
            </div>
            <representatives-details-list 
                title="Unit"
                :representatives="unit.representatives"
                :entityId="unit.id"
            />
            <div class="column-start pv-30 w-100 bb-grey">
                <h2 class="f-16-darkgrey f-sbold mb-8">Property</h2>
                <p class="f-12-grey pb-3">
                    Property: 
                    <span class="f-12-black pl-1">
                        <router-link
                            :to="{ 
                                name: 'CommercialDetails', 
                                params: { 
                                    customer_id: unit.property.customer_id, 
                                    property_id: unit.property.id 
                                }
                            }"
                            class="black-link-underlined relative"
                            v-if="unit.property.customer"
                        >
                            {{ unit.property.property_name }}
                        </router-link>
                        <router-link
                            v-else
                            :to="{ 
                                name: 'CommercialPropertyWithoutCustomer', 
                                params: { property_id: unit.property.id }
                            }"
                            class="black-link-underlined relative"
                        >
                            {{ unit.property.property_name }}
                        </router-link>
                    </span>
                </p>
                <p class="f-12-grey">
                    Address:
                    <a
                        :href="getGoogleMapLink(unit.property.addresses.main[0].lat, unit.property.addresses.main[0].lng)"
                        :class="[
                            'pl-1',
                            unit.property.addresses.main.length ? 'f-12-blue' : 'f-italic',
                            {'none-events': !getGoogleMapLink(unit.property.addresses.main[0].lat, unit.property.addresses.main[0].lng)}
                        ]"
                        target="_blank"
                    >
                        {{
                            unit.property.addresses.main.length 
                            ? getAddress(unit.property.addresses.main[0])
                            : 'No information'
                        }}
                        <link-icon 
                            v-if="getGoogleMapLink(unit.property.addresses.main[0].lat, unit.property.addresses.main[0].lng)"
                            class="i-blue"
                        />
                    </a>
                </p>
            </div>
            <div 
                class="column-start pv-30 w-100"
                v-if="unit.jobs && jobsLength"
            >
                <button 
                    class="transparent-button mb-8"
                    @click="jobsIsOpen = !jobsIsOpen"
                    :disabled="!jobsActively"
                >
                    <arrow-icon 
                        class="i-blue mr-1"
                        :transform="jobsIsOpen ? 270 : 90"
                        size="10"
                        v-if="jobsActively"
                    />
                    <span class="f-16-darkgrey f-sbold pr-4">Jobs</span>
                    <span 
                        class="count-blue f-11-blue f-bold s-align-5"
                        v-if="jobsActively"
                    >
                        {{ jobsLength }}
                    </span>
                </button>
                <div 
                    class="lead-details-bordered row-between pv-3"
                    v-for="(job, index) in jobs"
                    :key="`job-${index}`"
                >
                    <div class="lg-4 md-12 pv-1">
                        <p class="f-13-grey">
                            ID:
                            <span class="f-13-black pl-1">
                                <router-link 
                                    class="black-link-underlined relative"
                                    :to="{ 
                                        name: 'CommercialJobOverview', 
                                        params: { property_id: job.property_id, job_id: job.id }
                                    }"
                                >
                                    {{ job.job_number }}
                                </router-link>
                            </span>
                        </p>
                    </div>
                    <div class="lg-4 md-12 pv-1">
                        <p class="f-13-grey">
                            Date:
                            <span class="f-13-black pl-1">{{ job.created_at | date}}</span>
                        </p>
                    </div>
                    <div :class="['lg-4 md-12 pv-1', dScreen ? 's-align-6' : 's-align-4']">
                    <span 
                        v-if="job.type_name"
                        class="major-green-label f-11-white f-sbold s-aling-5"
                    >
                        {{ job.type_name }}
                    </span>
                    </div>
                </div>
            </div>
        </div>
    </perfect-scrollbar>
</template>

<script>
    import { Loader } from '@/ui';
    import { ClockIcon, MapIcon, ArrowIcon, LinkIcon } from '@/icons';
    import RepresentativesDetailsList from '@/components/RepresentativesDetailsList.vue';
    import api from '@/headers.js';
    import { showFullAddress, getGoogleMapLink } from '@/helpers';

    export default {
        name: 'CommercialUnitListDetails',
        components: {
            Loader,
            ClockIcon,
            MapIcon,
            ArrowIcon,
            LinkIcon,
            RepresentativesDetailsList
        },
        props: {
            unitId: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                loading: false,
                unit: {},
                jobsIsOpen: false
            }
        },
        computed: {
            jobsLength() {
                return this.unit.jobs.length;
            },
            jobsActively() {
                return this.jobsLength > 3;
            },
            jobs() {
                return this.jobsIsOpen 
                    ? this.unit.jobs
                    : this.unit.jobs.slice(0, 3);
            },
            addressIsCorrect() {
                return this.unit.lat != null && this.unit.lng != null;
            }
        },
        methods: {
            // Method imported from helpers.js
            getGoogleMapLink,
            getUnit() {
                this.loading = true;

                api.get(`/commercial/units/${this.unitId}`)
                    .then(response => {
                        this.unit = response.data.data;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);          
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            getAddress(customer) {
                return showFullAddress(customer);
            }
        },
        created() {
            this.getUnit();
        }
    }
</script>