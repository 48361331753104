<template>
    <transition 
        name="full-modal" 
        appear
    >
        <div :class="['full-modal', { 'full-modal-without-footer': full }]">
            <div class="s-align-9 column-end h-100 w-100">
                <div 
                    class="full-modal-content"
                    v-body-scroll-lock:reserveScrollBarGap="isFullModalCondition"
                >
                    <div
                        v-if="inDev"
                        class="mh-8 in-dev mb-8"
                    />
                    <div class="full-modal-title row-between">
                        <h2>
                            <slot name="title"></slot>
                        </h2>
                        <button 
                            class="transparent-button"
                            @click="$emit('close')"
                        >
                            <close-icon 
                                size="13" 
                                class="i-grey"
                            />
                        </button>
                    </div>
                    <div 
                        class="full-modal-search row-start row-baseline bb-grey"
                        v-if="withSearch"
                    >
                        <search-icon class="i-darkgrey"/>
                        <div class="full-modal-search-container">
                            <slot name="search"></slot>
                        </div>
                        <button 
                            class="icon-dark"
                            v-if="searchRequestLength"
                            @click="$emit('cancelSearch')"
                        >
                            <close-icon size="8"/>
                        </button>
                    </div>
                    <div class="full-modal-body">
                        <slot name="body"></slot>
                    </div>
                </div>
                <div 
                    class="full-modal-footer"
                    v-show="!full"
                >
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import { CloseIcon, SearchIcon } from '@/icons';

    /*
    * full: modal without footer
    */

    export default {
        name: 'FullModal',
        components: {
            CloseIcon,
            SearchIcon
        },
        props: {
            full: {
                type: Boolean,
                default: false
            },
            withSearch: {
                type: Boolean,
                default: false
            },
            searchRequestLength: {
                type: Boolean,
                default: false
            },
            inDev: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            isFullModalCondition() {
                return this.$store.getters['views/getFullModalCondition'];
            }
        },
        created() {
            this.$store.commit('views/FULL_MODAL_OPEN', true);
        },
        destroyed() {
            this.$store.commit('views/FULL_MODAL_OPEN', false);
        }
    }
</script>
