<template>
    <div 
        :class="[
            'read-more-container column-start', `read-more-${unicId}`, 
            { 'pointer': !normalHeight }
        ]"
        @click="openContentBox"
    >
        <span :class="['break-all', characteristic]">
            {{ text }}
            <span 
                :class="[
                    'f-13-blue relative pr-6 no-wrap', 
                    open ? 'read-more-open' : 'read-more-close'
                ]"
                v-if="!normalHeight"
            >
                {{ open ? 'Show less' : 'Read more' }}
            </span>
        </span>
    </div>
</template>

<script>
    /*
    * content: text to be displayed
    *
    * characteristic: markup class
    * 
    * unicId: tis component is often displayed in a loop, 
    * so everyone needs to set a unique id
    * 
    * stringLength: string length + '...'
    * 
    * heightLimit: how many lines should be shown
    * 
    * TODO: implement the function of choosing this functionality 
    * on different screens (if it's really need)
    */

    export default {
        name: 'ReadMore',
        props: {
            content: {
                type: String,
                required: true
            },
            characteristic: {
                type: String,
                default: 'f-13-black'
            },
            unicId: {
                type: [String, Number],
                required: true
            },
            stringLength: {
                type: Number,
                default: 0
            },
            heightLimit: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                normalHeight: true,
                open: false
            }
        },
        computed: {
            text() {
                if (!this.normalHeight) {
                    return !this.open 
                        ? this.content.slice(0, this.stringLength) + '...'
                        : this.content;
                }

                return this.content;
            }
        },
        watch: {
            content: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal != oldVal)
                        this.defineHeight();   
                }
            }
        },
        methods: {
            defineHeight() {
                let description = document.querySelector(`.read-more-${this.unicId}`);

                this.$nextTick(function() {
                    if (description) {
                        this.normalHeight = description.scrollHeight < this.heightLimit;
                    }
                });
            },
            openContentBox() {
                if (this.normalHeight) return;
                this.open = !this.open;
            }
        },
        mounted() {
            if (this.content.length)
                this.defineHeight();
        }
    }
</script>