var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('full-modal',{on:{"close":function($event){return _vm.$emit('close')}}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v("\n        Custom "+_vm._s(_vm.typeName)+"\n    ")]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('form-input',{class:[
                'mb-7',
                {'form-input-danger' : _vm.errors.first('custom_item_name')}
            ],attrs:{"label":("Custom " + _vm.typeName + " title")}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"},{name:"input-style",rawName:"v-input-style",value:(_vm.name),expression:"name"},{name:"validate",rawName:"v-validate",value:('required|min:3|max:255'),expression:"'required|min:3|max:255'"}],staticClass:"form-input-field",attrs:{"type":"text","name":"custom_item_name","placeholder":("Custom " + _vm.typeName + " title"),"maxlength":"256"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}})]),(!_vm.showNotesInput)?_c('button',{staticClass:"transparent-button f-14-blue mb-30",on:{"click":function($event){_vm.showNotesInput = true}}},[_vm._v("\n            + Add notes\n        ")]):_c('form-input-textarea',{directives:[{name:"input-counter",rawName:"v-input-counter",value:(255),expression:"255"}],staticClass:"mb-20",attrs:{"label":"Notes"}},[_c('textarea',{directives:[{name:"input-style",rawName:"v-input-style",value:(_vm.description),expression:"description"},{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"},{name:"validate",rawName:"v-validate",value:('required|min:3|max:254'),expression:"'required|min:3|max:254'"}],class:['form-input-field',
                    { 'form-input-textarea-danger' : _vm.errors.has('item_description') }],attrs:{"name":"item_description","rows":"5","placeholder":"Notes","maxlength":"255"},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.description=$event.target.value}}})]),_c('form-input',{class:[
                'form-input-with-select',
                {'form-input-danger' : _vm.errors.first('custom_item_quantity')}
            ],attrs:{"label":"Quantity"}},[_c('input',{directives:[{name:"input-style",rawName:"v-input-style",value:(_vm.quantity),expression:"quantity"},{name:"model",rawName:"v-model.number",value:(_vm.quantity),expression:"quantity",modifiers:{"number":true}},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"limiter",rawName:"v-limiter"}],staticClass:"form-input-field",attrs:{"name":"custom_item_quantity","type":"text","placeholder":"Quantity","autocomplete":"off"},domProps:{"value":(_vm.quantity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.quantity=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}}),_c('multiselect',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                    {'multiselect-danger' : _vm.errors.has('custom_item_per_unit')}
                ],attrs:{"options":_vm.CUSTOM_ITEM_UNITS_TYPES,"name":"custom_item_per_unit","data-name":"custom_item_per_unit","max-height":200,"placeholder":"Unit","show-labels":false,"allow-empty":false,"searchable":false},model:{value:(_vm.perUnit),callback:function ($$v) {_vm.perUnit=$$v},expression:"perUnit"}})],1),_c('form-input',{class:[
                'mt-7',
                {'form-input-danger' : _vm.errors.first('price')}
            ],attrs:{"label":"Price / Unit","maskBefore":"$","maskValue":_vm.cost,"maskFilter":"numberWithCommas"}},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.cost),expression:"cost",modifiers:{"number":true}},{name:"input-style",rawName:"v-input-style",value:(_vm.cost),expression:"cost"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"limiter",rawName:"v-limiter"}],staticClass:"form-input-field",attrs:{"type":"text","name":"price","placeholder":"Price / Unit"},domProps:{"value":(_vm.cost)},on:{"input":function($event){if($event.target.composing){ return; }_vm.cost=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}})])],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"row-between"},[_c('button',{staticClass:"add-button",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("\n                Cancel\n            ")]),_c('button',{staticClass:"primary-button",attrs:{"disabled":_vm.requestIsGoing},on:{"click":_vm.createCustomItem}},[_vm._v("\n                Create\n            ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }