<template>
    <div class="w-box column-center mt-20">
        <div class="row-between row-baseline mb-60">
            <p 
                :class="[
                    'f-sbold',
                    mScreen ? 'f-14-black' : 'f-24-black'
                ]"
            >
                {{ addNewMeasurementSectionShown ? 'Create' : 'Choose' }} a measurement
            </p>
            <router-link
                :to="{ name: allOrdersRoute }"
                class="add-button"
            >
                Cancel
            </router-link>
        </div>
        <div class="row-center mb-8">
            <button
                :class="[
                    'add-medium-button column-center mr-4 m-sm-0 mb-sm-1',
                    { 'add-medium-button-active' : type === MEASUREMENT.USE }
                ]"
                @click="type = MEASUREMENT.USE"
            >
                Use measurements
            </button>
            <button
                :class="[
                    'add-medium-button mr-4 m-sm-0 mb-sm-1',
                    { 'add-medium-button-active' : type === MEASUREMENT.DONOTUSE }
                ]"
                @click="type = MEASUREMENT.DONOTUSE"
            >
                Don’t use measurements
            </button>
        </div>
        <template v-if="type">
            <loader
                v-if="loading"
                size="small"
            />
            <template v-else-if="!addNewMeasurementSectionShown">
                <list-empty
                    viewType="map"
                    v-if="masurementsListIsEmpty && !loading"
                >
                    <p class="f-24-darkgrey f-sbold pb-4">Job Measurements</p>
                    <div class="column-start pb-20">
                        <span class="f-14-darkgrey">
                            This job does not have any measurements.
                        </span>
                    </div>
                    <div class="list-empty-buttons row-start">
                        <button 
                            class="primary-button mr-8"
                            @click="showAddNewMeasurementSection"
                        >
                            + New Measurement
                        </button>
                        <button 
                            class="secondary-button mt-md-1"
                            @click="startUploadXml()"
                        >
                            Upload XML
                        </button>
                    </div>
                </list-empty>
                <div
                    v-else
                    :class="[ mScreen || tScreen ? 'w-100' : 'w-80' ]"
                >
                    <div class="order-create-search row-between row-baseline mb-30 pv-30 bb-grey">
                        <input
                            class="f-16-black"
                            type="text"
                            placeholder="Search measurements..."
                            v-model="needToFind"
                        >
                        <search-icon class="i-grey"/>
                    </div>
                    <template>
                        <measurement-view 
                            v-for="(measurement, index) in visibleMeasurements"
                            :class="[
                                'relative column-start pointer b-grey',
                                { 'measurement-view-active' : measurement.id === selectedMeasurementId }
                            ]"
                            :key="`measurement-${index}`"
                            :measurement="measurement"
                            :previewMode="true"
                        >
                            <div slot="radio">
                                <div class="angle-input">
                                    <input 
                                        type="radio"
                                        name="measurement"
                                        v-model="selectedMeasurementId"
                                        :value="measurement.id"
                                    />
                                    <span class="angle-input-icon">
                                        <mark-icon size="12"/>
                                    </span>
                                </div>
                            </div>
                        </measurement-view>
                        <measurements-eagle-view 
                            v-for="(measurement, index) in visibleEagleViewMeasurements"
                            :class="[
                                'relative column-start pointer b-grey',
                                { 'measurement-view-active' : measurement.id === selectedMeasurementId }
                            ]"
                            :key="`measurement-eagle-view-${index}`"
                            :measurement="measurement"
                            :previewMode="true"
                        >
                            <div slot="radio">
                                <div class="angle-input">
                                    <input 
                                        type="radio"
                                        name="measurement"
                                        v-model="selectedMeasurementId"
                                        :value="measurement.id"
                                    />
                                    <span class="angle-input-icon">
                                        <mark-icon size="12"/>
                                    </span>
                                </div>
                            </div>
                        </measurements-eagle-view>
                    </template>
                    <div class="create-new-measurement row-start row-baseline">
                        <div class="lg-6 sm-12 column-start mb-sm-2">
                            <p class="f-16-black">Want to create new one?</p>
                            <p class="f-13-grey">Here you can create additional measurement.</p>
                        </div>
                        <div :class="['lg-6 sm-12', mScreen ? 's-align-5' : 's-align-6']">
                            <button 
                                class="primary-button mr-8"
                                @click="showAddNewMeasurementSection"
                            >
                                + New Measurement
                            </button>
                            <button 
                                class="secondary-button mt-md-1"
                                @click="startUploadXml()"
                            >
                                Upload XML
                            </button>
                        </div>
                    </div>
                </div>
            </template>
            <div
                v-else
                class="column-start w-100"
            >
                <div class="row-start pb-30 bb-grey">
                    <div class="lg-6 sm-12 pr-8 pr-sm-0 mb-sm-1">
                        <multiselect
                            :options="measurementTypes"
                            track-by="name"
                            label="name"
                            placeholder="Measurement Type"
                            :value="newMeasurementSelectedType"
                            @select="selectMeasurement"
                            :showLabels="false"
                            :max-height="200"
                            name="measurement_type"
                            data-name="measurement_type"
                            v-validate="'required'"
                            :class="[{ 'multiselect-danger' : errors.has('measurement_type') }]"
                        />
                    </div>
                    <div class="lg-6 sm-12">
                        <form-input
                            label="Measurement Name"
                            :class="[{ 'form-input-danger' : errors.has('measurement_name') }]"
                        >
                            <input
                                type="text"
                                name="measurement_name"
                                class="form-input-field"
                                placeholder="Measurement Name"
                                v-model="newMeasurementName"
                                v-input-style="newMeasurementName"
                                v-validate="'required|min:3|max:250'"
                            />
                        </form-input>
                    </div>
                </div>
                <div class="pv-30 w-100">
                    <div class="row-between pb-20 ph-4">
                        <div class="s-align-4 sm-12 ls-sm-8 mb-sm-1">
                            <h2 class="f-20-darkgrey f-sbold">Measurement Data</h2>
                        </div>
                        <div class="s-align-6 sm-12 ls-sm-4">
                            <button
                                class="icon-dark"
                                @click="clearAllMeasurement"
                            >
                                <close-icon class="mr-2"/>
                                Clear all
                            </button>
                        </div>
                    </div>
                    <div class="row-start">
                        <div
                            v-for="(field, index) in newMeasurementSelectedType.attributes"
                            :key="index"
                            class="column-start lg-3 md-4 sm-12 ph-4 pb-8"
                        >
                            <span class="f-15-black pb-1 ellipsis w-100">{{ field.name }}</span>
                            <form-input
                                :label="field.name"
                                :class="[{ 'form-input-danger' : errors.has(`measurement-input-${index}`) }]"
                                :maskAfter="`/ ${field.attribute_type}`"
                                :maskValue="field.value"
                            >
                                <input
                                    type="text"
                                    :name="`measurement-input-${index}`"
                                    class="form-input-field"
                                    :placeholder="`0 / ${field.attribute_type}`"
                                    v-model="field.value"
                                    v-input-style="field.value"
                                    v-validate="'required'"
                                    v-limiter
                                />
                            </form-input>
                        </div>
                    </div>
                </div>
                <div class="w-100 mb-8">
                    <form-input-textarea
                        label="Notes"
                        v-input-counter="2500"
                    >
                        <textarea
                            rows="1"
                            name="measurement_notes"
                            :class="
                                ['form-input-field',
                                { 'form-input-textarea-danger' : errors.has('measurement_notes') }]
                            "
                            placeholder="Enter note..."
                            v-model="newMeasurementSelectedType.notes"
                            v-input-style="newMeasurementSelectedType.notes"
                            maxlength="2500"
                            v-validate="'required|min:3|max:2499'"
                            v-resize-textarea
                        />
                    </form-input-textarea>
                </div>
                <div class="row-between">
                    <button 
                        class="add-button"
                        @click="addNewMeasurementSectionShown = false"
                    >
                        Cancel
                    </button>
                    <button 
                        class="primary-button"
                        @click="createMeasurement"
                    >
                        Create
                    </button>
                </div>
            </div>
            <modal
                v-if="showUploadXmlModal"
                @close="showUploadXmlModal = false"
            >
                <div slot="modal-body">
                    <div class="w-100 t-center pb-4">
                        <span class="f-14-black f-sbold">
                            Enter measurement name and click Upload
                        </span>
                        <form-input 
                            label="Name"
                            :class="['mt-4',{ 'form-input-danger' : errors.has('xml_name') }]"
                        >
                            <input 
                                class="form-input-field"
                                name="xml_name"
                                v-input-style="xmlName"
                                v-model="xmlName"
                                type="text"
                                placeholder="Name"
                                v-validate="'required'"
                            />
                        </form-input>
                    </div>
                </div>
                <div slot="modal-footer">
                    <div class="row-between">
                        <button 
                            class="add-button"
                            @click="confirmCancelModalIsOpen = false"
                        >
                            Cancel
                        </button>
                        <button 
                            class="primary-button"
                            @click="uploadXml()"
                            :disabled="requestIsGoing"
                        >
                            Upload
                        </button>
                    </div>
                </div>
            </modal>
            <input 
                type="file" 
                name="xml_upload"
                ref="uploadXmlInput"
                accept=".xml"
                @change="fileInputHandler"
                hidden
            />
        </template>
    </div>
</template>

<script>
    import { MeasurementView, MeasurementsEagleView, ListEmpty } from '@/components';
    import { Modal, Loader, FormInput, FormInputTextarea } from '@/ui';
    import { SearchIcon, MarkIcon, CloseIcon } from '@/icons';
    import { ENTITY_TYPES } from '@/constants';
    import Multiselect from 'vue-multiselect';
    import { clearArray } from '@/helpers';
    import api from '@/headers.js';

    export default {
        name: 'OrderCreateStep3',
        components: {
            MeasurementView,
            MeasurementsEagleView,
            ListEmpty,
            SearchIcon,
            MarkIcon,
            CloseIcon,
            Loader,
            FormInput,
            FormInputTextarea,
            Multiselect,
            Modal
        },
        props: {
            entityType: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                measurementsLoaded: false,
                needToFind: '',
                measurements: [],
                measurementsEagleView: [],
                selectedMeasurementId: null,
                addNewMeasurementSectionShown: false,
                measurementTypesLoaded: false,
                measurementTypes: [],
                newMeasurementName: '',
                newMeasurementSelectedType: {
                    name: '',
                    notes: '',
                    entity_id: '',
                    type_id: '',
                    attributes: [],
                    entity_type: ''
                },
                xmlFile: null,
                showUploadXmlModal: false,
                xmlName:'',
                requestIsGoing: false,
                MEASUREMENT: {
                    USE: true,
                    DONOTUSE: false
                },
                type: null
            }
        },
        computed: {
            allOrdersRoute() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: 
                        return 'ResidentialOrders';
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return 'CommercialJobOrders';
                    case ENTITY_TYPES.WARRANTY_JOB:
                        return 'WarrantyJobOrders';
                }
            },
            formData() {
                return this.$store.getters['orders/getOrderFormData'];
            },
            entityId() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: 
                        return this.$route.params.lead_id;
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.$route.params.job_id;
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.$route.params.lead_id;
                    case ENTITY_TYPES.WARRANTY_JOB:
                        return this.$route.params.job_id;
                }
            },
            visibleMeasurements() {
                return this.measurements.filter(measurement => {
                    return measurement.name.toLowerCase().includes(this.needToFind.toLowerCase());
                });
            },
            visibleEagleViewMeasurements() {
                return this.measurementsEagleView.filter(measurement => {
                    return measurement.name.toLowerCase().includes(this.needToFind.toLowerCase());
                });
            },
            masurementsListIsEmpty() {
                return !this.measurements.length && !this.measurementsEagleView.length;
            }
        },
        watch: {
            type(newVal) {
                if (newVal) {
                    this.getMeasurements();
                }
            }
        },
        methods: {
            startUploadXml() {
                this.$refs.uploadXmlInput.click();
            },
            fileInputHandler(event) {
                if (!this.$refs.uploadXmlInput.files[0]) return false;
                this.xmlFile = event.target.files[0];
                this.showUploadXmlModal = true;
            },
            uploadXml() {
                this.$validator.validate('xml_name').then(result => {
                    if (!result) {
                        this.notifyError('Please fill required field');
                        return;
                    }

                    this.requestIsGoing = true;

                    let formData = new FormData();                
                    formData.append('file', this.xmlFile);
                    formData.append('name', this.xmlName);
                    formData.append('entity_id', this.entityId);
                    formData.append('entity_type', this.entityType);

                    api.post('/measurements/create-by-xml', formData) 
                        .then(response => {
                            this.notifySuccess('Xml was uploaded');
                            this.getMeasurements();
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.$validator.pause();
                            this.showUploadXmlModal = false;
                            this.requestIsGoing = false;
                            this.xmlName = '';
                            this.xmlFile = null;
                            this.$nextTick(() => {
                                this.$validator.reset();
                                this.$validator.resume();
                            }); 
                        })
                });
            },
            getMeasurements() {
                if (this.measurementsLoaded) {
                    return;
                }

                this.loading = true;
                api.get(`/${this.entityId}/measurements/?entity_type=${this.entityType}`)
                    .then(response => response.data.data)
                    .then(data => {
                        this.measurements.push(...data.measurements);
                        this.measurementsLoaded = true;
                        this.measurementsEagleView.push(...data.orders);
                        this.measurementsEagleView.push(...data.not_completed_orders);
                        
                        if (this.measurements.length === 1 && !this.measurementsEagleView.length) {
                            this.selectedMeasurementId = this.measurements[0].id;
                        } else if (!this.measurements.length && this.measurementsEagleView.length === 1) {
                            this.selectedMeasurementId = this.measurementsEagleView[0].id;
                        }
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            // validation is calling in parent component, need to return promise with result
            validateFields() {
                if (this.type) {
                    if (!!this.selectedMeasurementId) {
                        this.$store.commit('orders/STORE_ORDER_FORM_DATA', {
                            measurement_id: this.selectedMeasurementId
                        });
                    }

                    return Promise.resolve(!!this.selectedMeasurementId);
                } else {
                    return Promise.resolve(!this.type);
                }
            },
            getMeasurementTypes() {
                this.loading = true;
                api.get('/measurements/types/')
                    .then(response => {
                        this.measurementTypes = response.data;
                        this.measurementTypesLoaded = true;
                        // by default select first
                        this.selectMeasurement(response.data[0]);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            clearAllMeasurement() {
                this.newMeasurementSelectedType.attributes.map((measurement) => {
                    measurement.value = '';
                });
            },
            selectMeasurement(data) {
                this.newMeasurementSelectedType.entity_id = this.entityId
                this.newMeasurementSelectedType.type_id = data.id;
                this.newMeasurementSelectedType.name = data.name;
                this.newMeasurementSelectedType.entity_type = this.entityType;

                clearArray(this.newMeasurementSelectedType.attributes);
                this.newMeasurementSelectedType.attributes.push(...data.attributes.map(function(el) {
                    return {
                        id: el.id,
                        name: el.name,
                        value: '',
                        attribute_type: el.attribute_type
                    }
                }));
            },
            showAddNewMeasurementSection() {
                if (!this.measurementTypesLoaded) {
                    this.getMeasurementTypes();
                }

                this.addNewMeasurementSectionShown = true;
            },
            createMeasurement() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('All fields are required.');
                        return;
                    }

                    this.loading = true;
                    api.post('/measurements', {
                        ...this.newMeasurementSelectedType,
                        name: this.newMeasurementName
                    }).then(response => response.data.data)
                        .then(data => {
                            this.measurements.push(data);
                            this.addNewMeasurementSectionShown = false;
                            this.selectedMeasurementId = data.id;

                            // clear 'new measurement' fields after create
                            this.newMeasurementName = '';
                            this.newMeasurementSelectedType.notes = '';
                            this.clearAllMeasurement();

                            this.notifySuccess('Measurement created!');
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                });
            }
        }
    }
</script>