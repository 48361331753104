<template>
    <div>
        <Loader
            v-if="loading"
            size="small"
        />
        <div
            v-else
            class="w-100"
        >
            <div class="event-edit-title p-8">
                <input
                    class="transparent-input"
                    type="text"
                    placeholder="Order Name"
                    name="new_order_name"
                    v-model="name"
                    v-validate="'required|min:3|max:99'"
                    maxlength="100"
                    :class="[{ 'transparent-input-danger' : errors.has('new_order_name') }]"
                >
            </div>
            <div class="column-start ph-8">
                <div class="f-13-black mb-4">
                    Select components for the order
                </div>
                <div class="row-start mb-8 flex-no-wrap">
                    <radio-switch
                        v-for="(type, index) in componentType"
                        :key="`calendar-type-${index}`"
                        :label="type.name"
                        color="blue"
                        height="35"
                        :class="[
                            'capitalize',
                            { 'radio-switch-error' : errors.has(`calendar-type-${index}`) }
                        ]"
                    >
                        <input
                            type="radio"
                            :name="`calendar-type-${index}`"
                            :value="type"
                            v-model="selectedType"
                            v-validate="'required'"
                            @input="has_labor = type.has_labor, has_material = type.has_material"
                        />
                    </radio-switch>
                </div>
                <div class="row-between flex-no-wrap s-align-4 mb-8">
                    <div class="f-13-black no-wrap mr-4">
                        Select trade for the order
                    </div>
                    <multiselect
                        :options="trades"
                        track-by="id"
                        label="name"
                        v-model="selectedTrade"
                        :showLabels="false"
                        :max-height="160"
                        v-validate="'required'"
                        name="order_trade"
                        data-name="order_trade"
                        placeholder="Trade"
                        :class="{ 'multiselect-danger' : errors.has('order_trade') }"
                    />
                </div>
            </div>
            <div class="event-popup-footer p-8 bt-grey">
                <div class="row-end">
                    <button
                        class="icon-light"
                        @click="$emit('closeNewEventPopup')"
                    >
                        <span class="f-13-grey">
                            Cancel
                        </span>
                    </button>
                    <button
                        class="primary-button ml-8"
                        @click="createOrder()"
                        :disabled="requestIsGoing"
                    >
                        Create Order
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { RadioSwitch, Loader } from '@/ui';
import Multiselect from 'vue-multiselect';
import api from '@/headers.js';
import { ENTITY_TYPES } from '@/constants';

export default {
    name: 'OrderCreatePopup',
    components: {
        RadioSwitch,
        Multiselect,
        Loader
    },
    props: {
        job: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            selectedTrade: null,
            name: '',
            typeComponents: null,
            trades: [],
            requestIsGoing: false,
            has_labor: true,
            has_material: true,
            selectedType:'',
            loading: false,
            componentType: [
                {
                    name: 'Materials & Labor',
                    has_material: true,
                    has_labor: true
                },
                {
                    name: 'Materials only',
                    has_material: true,
                    has_labor: false
                },
                {
                    name: 'Labor only',
                    has_material: false,
                    has_labor: true
                }
            ]
        }
    },
    computed: {
        allFieldsEmpty() {
            return this.name === '' && this.typeComponents === '' && trade === '';
        }
    },
    methods: {
        getTrades() {
            this.loading = true;
            const params = { needed: ['trades'] };

            api.get('/handbooks?filter[is_active]=1', { params })
                .then(response => response.data.data)
                .then(data => {
                    this.trades.push(...data.trades);

                    if (this.formData.trade_id) {
                        this.selectedTrade = this.trades.find(trade => {
                            return trade.id === this.formData.trade_id
                        });
                    }
                })
                .catch(error => {
                    this.notifyRequestErrors(error);
                })
                .finally(() => this.loading = false);
        },
        createOrder() {
            if (this.allFieldsEmpty) {
                this.notifyError('Please fill at least one field!');
                return;
            }

            this.$validator.validateAll().then(result => {
                if (result) {
                    this.requestIsGoing = true;
                    this.$store.commit('orders/SET_IS_CREATE_FROM_CALENDAR', true);
                    this.$store.commit('orders/STORE_ORDER_FORM_DATA', {
                        has_material: this.has_material,
                        has_labor: this.has_labor,
                        name: this.name,
                        trade_id: this.selectedTrade.id
                    });
                    switch (this.job.entity_type) {
                        case ENTITY_TYPES.RESIDENTIAL_LEAD:
                            return this.$router.push({
                                path: `/residential-job-details/${this.job.id}/order-create`
                            });
                        case ENTITY_TYPES.COMMERCIAL_JOB:
                            return this.$router.push({
                                path: `/commercial-job-details/${this.job.property_id}/${this.job.id}/order-create`
                            });
                    }
                }
            });
        }
    },
    created() {
        this.getTrades();
    }
}
</script>