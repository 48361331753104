<template>
    <svg
        viewBox="0 0 30 30"
        :width="size"
        :height="size"
        class="fill-phone-icon"
    >
        <path
            d="M29.5832 24.946L25.3535 29.1418C24.7291 29.7821 23.9016 29.999 23.0956 30C19.5313 29.8932 16.1623 28.1424 13.3961 26.3446C8.8555 23.0414 4.68935 18.9455 2.0746 13.9958C1.07175 11.9202 -0.104945 9.27195 0.0074795 6.95522C0.0175045 6.08372 0.252354 5.2284 0.866129 4.66665L5.09578 0.439121C5.97408 -0.307953 6.82345 -0.0496535 7.3855 0.820546L10.7883 7.2731C11.1464 8.03752 10.941 8.85657 10.4066 9.4028L8.84833 10.9603C8.75213 11.0921 8.69088 11.2421 8.68933 11.4053C9.28688 13.7184 11.0972 15.8503 12.6964 17.3175C14.2955 18.7847 16.0145 20.7724 18.2458 21.243C18.5216 21.3199 18.8594 21.3469 19.0567 21.1635L20.8695 19.3199C21.4944 18.8463 22.3966 18.6148 23.0638 19.0021H23.0956L29.2333 22.6257C30.1343 23.1905 30.2283 24.282 29.5832 24.946Z"/>
    </svg>
</template>

<script>
    export default {
        name: 'FillPhoneIcon',
        props: {
            size: {
                type: [Number, String],
                default: 30
            }
        }
    }
</script>
