export const SET_COMMERCIAL_JOB = 'SET_COMMERCIAL_JOB';
export const GET_COMMERCIAL_JOB_PREVIEW = 'GET_COMMERCIAL_JOB_PREVIEW';
export const SET_COMMERCIAL_JOB_PREVIEW = 'SET_COMMERCIAL_JOB_PREVIEW';
export const SET_COMMERCIAL_JOB_ADDRESS = 'SET_COMMERCIAL_JOB_ADDRESS';
export const GET_COMMERCIAL_LEAD = 'GET_COMMERCIAL_LEAD';
export const SET_COMMERCIAL_LEAD = 'SET_COMMERCIAL_LEAD';
export const SET_CUSTOMER_IN_COMMERCIAL_LEAD = 'SET_CUSTOMER_IN_COMMERCIAL_LEAD';
export const SET_PROPERTY_IN_COMMERCIAL_LEAD = 'SET_PROPERTY_IN_COMMERCIAL_LEAD';
export const SET_CUSTOMER_IN_COMMERCIAL_JOB = 'SET_CUSTOMER_IN_COMMERCIAL_JOB';
export const SET_PROPERTY_IN_COMMERCIAL_JOB = 'SET_PROPERTY_IN_COMMERCIAL_JOB';
export const SET_COMMERCIAL_LEAD_ASSIGNMENT = 'SET_COMMERCIAL_LEAD_ASSIGNMENT';
export const SET_COMMERCIAL_JOB_ASSIGNMENT = 'SET_COMMERCIAL_JOB_ASSIGNMENT';
export const SET_COMMERCIAL_LEAD_REPRESENTATIVES = 'SET_COMMERCIAL_LEAD_REPRESENTATIVES';
export const SET_COMMERCIAL_JOB_REPRESENTATIVES = 'SET_COMMERCIAL_JOB_REPRESENTATIVES';
export const UPDATE_QUALITY_CONTROL = 'UPDATE_QUALITY_CONTROL';
export const SET_COMMERCIAL_JOB_PREVIEW_FOR_PROPERTY = 'SET_COMMERCIAL_JOB_PREVIEW_FOR_PROPERTY';
export const SET_COMMERCIAL_LEAD_FOR_PROPERTY = 'SET_COMMERCIAL_LEAD_FOR_PROPERTY';
export const SET_TOTAL_JOBS = 'SET_TOTAL_JOBS';