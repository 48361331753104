import moment from 'moment/src/moment';
import { CALENDAR_TYPES, PROJECT_COLORS, ENTITY_TYPES, DELIVERY_TIME, EVENTS_TYPE_SCHEDULER } from '@/constants.js';
import { collectJobNumber, showFullAddress } from '@/helpers';

export const schedulerCalendar = {
    data() {
        return {
            isLoading: false,
            currentPeriod: [],
            startOfPeriod: '',
            endOfPeriod: '',
            newEventCalendarId: null,
            colors: PROJECT_COLORS,
            isEventPopupShow: false,
            ENTITY_TYPES,
            selectOrder: null,
            selectCrew: null,
            colorOrder: '',
            CALENDAR_TYPES,
            showOnlyActiveItems: false,
            selectedOrder: ''
        }
    },
    computed: {
        showLabor() {
            return this.eventsTypeFilter.find(el => el.label == EVENTS_TYPE_SCHEDULER.LABOR && el).value;
        },
        showMaterial() {
            return this.eventsTypeFilter.find(el => el.label == EVENTS_TYPE_SCHEDULER.MATERIAL && el).value;
        },
        schedulerJobList() {
            return this.$store.getters['scheduler/getSchedulerJobList'];
        },
        isWeekStartOnMonday() {
            return this.$store.getters['user/isWeekStartOnMonday'];
        },
        currentDay() {
            return this.$route.params.date
                ? this.$route.params.date
                : moment().format();
        },
        currentDate: function() {
            return `<span class="month">${moment(this.currentDay).format('MMMM')}</span>
                ${moment(this.currentDay).format('DD')},
                ${moment(this.currentDay).format('YYYY')}`;
        }
    },
    mounted() {
        this.getWeek();
    },
    methods: {
        isCommertialJob(order) {
            return order.entity_type == ENTITY_TYPES.COMMERCIAL_JOB;
        },
        isResidentialLead(order) {
            return order.entity_type == ENTITY_TYPES.RESIDENTIAL_LEAD;
        },
        filters(orders) {
            let newOrders = orders.filter(el => {
                return ((this.showMaterial && el.is_material)
                    || (this.showLabor && el.is_labor))
                    && this.tradesFilter.indexOf(el.trade_id) > -1;
            });

            return this.sortByEventRow(newOrders);
        },
        getAddress(address) {
            return showFullAddress(address);
        },
        getOrderType(order) {
            if (order.is_labor) {
                return order.labor_delivery_date_to + ' ' + order.labor_delivery_time_to;
            }

            if (order.is_material) {
                let timeEnd;
                if (order.delivery_time == DELIVERY_TIME.ANYTIME || order.delivery_time == DELIVERY_TIME.PM) {
                    timeEnd = '23:59';
                } else {
                    timeEnd = '12:00';
                }
                return order.delivery_date + ' ' + timeEnd;
            }
        },
        showOrder(order) {
            return order.is_active && order.classes.indexOf('span-0') == -1
                && (order.delivery_date || order.labor_delivery_date_from);
        },
        showOrderPopup(selectedOrder, parentClass, id) {
            const event = document.getElementById(id);

            const container = event.closest(parentClass);
            this.selectedOrder = selectedOrder;

            this.popupOffset.offsetTop = event.getBoundingClientRect().top
                - container.getBoundingClientRect().top - 20;
            this.popupOffset.offsetBottom = container.getBoundingClientRect().height
                - this.popupOffset.offsetTop
                - event.getBoundingClientRect().height - 40;

            this.popupOffset.offsetLeft = event.getBoundingClientRect().left + 30;
            this.popupOffset.offsetRight = container.getBoundingClientRect().width
                - event.getBoundingClientRect().right + 30;
            this.isEventPopupShow = true;
        },
        collectJobNumber,
        color(index) {
            if (index > this.colors.length - 1) {
                return this.colors[+(index % 10)];
            }

            return this.colors[index];
        },
        getIdOrder(order, index) {
            return `${index}-order-${order.id}-${order.is_labor ? 'labor' : 'material'}`;
        },
        //needed for correct margin-top at different heights order
        getMaxHeight(order, rowId) {
            let div = document.getElementById(rowId);
            let elems = div.getElementsByClassName(order.row);
            const maxHeight = Object.values(elems).reduce((prev, curr) => {
                return prev.clientHeight > curr.clientHeight ? prev : curr;
            });

            return maxHeight.clientHeight;
        },
        getOrderTop(order, index, row, orders, orderIndex) {
            setTimeout(() => {
                let domEvent = document.getElementById(this.getIdOrder(order, index));
                let maxHeight = this.getMaxHeight(order, row.id);
                let height = domEvent.clientHeight < maxHeight ? maxHeight : domEvent.clientHeight;

                if (order.eventRow > 0) {
                    domEvent.style.top = `${this.iterationEvents + 5}px`;

                    if (orders[orderIndex + 1] && orders[orderIndex + 1].eventRow > order.eventRow) {
                        this.iterationEvents += height + 5;
                    }
                } else {
                    this.iterationEvents = height + 5;
                    domEvent.style.top = `5px`;
                }
            },0);
        },
        isBetweenDate(date) {
            return moment(date).isBetween(this.startOfPeriod, this.endOfPeriod, null, '[]');
        },
        getUserOrders(params) {
            const events = params;
            const results = [];
            const eventRows = [[], [], [], [], [], [], []];
            let ep, continued, startOffset, span;
            this.iterationEvents = 0;

            for (let i = 0; i < events.length; i++) {
                if (events[i].has_labor && events[i].labor_delivery_date_from) {
                    ep = Object.assign({}, events[i], {
                        classes: [],
                        eventRow: 0
                    });

                    continued = moment(ep.labor_delivery_date_from).isSameOrBefore(moment(this.startOfPeriod), 'd');
                    startOffset = continued
                        ? 0
                        : moment(ep.labor_delivery_date_from).diff(this.startOfPeriod, 'days');
                    let col = startOffset > 7 ? 7 : startOffset;
                    span = Math.min(
                        7 - col,
                        Math.abs(moment(ep.labor_delivery_date_to).diff(moment(this.startOfPeriod).add(col, 'days'), 'days')) + 1
                    );

                    if (span > 0) {
                        for (let d = 0; d < 7; d++) {
                            if (d === startOffset) {
                                let s = 0;
                                while (eventRows[d][s]) { s++; }
                                ep.eventRow = s;
                                eventRows[d][s] = true;
                            } else if (d < startOffset + span) {
                                eventRows[d][ep.eventRow] = true;
                            };
                        };

                        ep.classes.push(`offset-${startOffset}`);
                        ep.classes.push(`span-${span}`);
                        ep.classes.push(`row-${ep.eventRow}`);
                        ep.row = `row-${ep.eventRow}`;
                        ep.is_labor = 1;
                        ep.is_material = 0;
                    }

                    if (this.showOnlyActiveItems) {
                        !this.checkIfEventPast(this.getOrderType(ep))
                        && this.showOrder(ep)
                            ? results.push(ep)
                            : null;
                    } else {
                        this.showOrder(ep)
                            ? results.push(ep)
                            : null;
                    }
                }
                if (events[i].has_material && events[i].delivery_date) {
                    ep = Object.assign({}, events[i], {
                        classes: [],
                        eventRow: 0
                    });

                    continued = moment(ep.delivery_date).isSameOrBefore(moment(this.startOfPeriod), 'd');
                    startOffset = continued
                        ? 0
                        : moment(ep.delivery_date).diff(this.startOfPeriod, 'days');
                    span = 1;
                    if (this.isBetweenDate(ep.delivery_date)) {
                        for (let d = 0; d < 7; d++) {
                            if (d === startOffset) {
                                let s = 0;
                                while (eventRows[d][s]) { s++; }
                                ep.eventRow = s;
                                eventRows[d][s] = true;
                            } else if (d < startOffset + span) {
                                eventRows[d][ep.eventRow] = true;
                            };
                        };
                        ep.classes.push(`offset-${startOffset}`);
                        ep.classes.push(`span-${span}`);
                        ep.classes.push(`row-${ep.eventRow}`);
                        ep.row = `row-${ep.eventRow}`;
                        ep.is_labor = 0;
                        ep.is_material = 1;
                    }

                    if (this.showOnlyActiveItems) {
                        !this.checkIfEventPast(this.getOrderType(ep))
                        && this.showOrder(ep)
                            ? results.push(ep)
                            : null;
                    } else {
                        this.showOrder(ep)
                            ? results.push(ep)
                            : null;
                    }
                }
            }

            return this.filters(results);
        },
        sortByEventRow(arr) {
            return arr.sort((a, b) => a.eventRow > b.eventRow ? 1 : -1);
        },
        getWeek() {
            this.currentPeriod.splice(0, this.currentPeriod.length);

            // isoWeek = start on Monday
            // week - start on Sunday
            const weekType = this.isWeekStartOnMonday ? 'isoWeek' : 'week';
            const startOfPeriod = moment(this.currentDay).clone().startOf(weekType);
            const endOfPeriod = moment(this.currentDay).clone().endOf(weekType);
            let day = startOfPeriod.clone();

            this.startOfPeriod = startOfPeriod;
            this.endOfPeriod = endOfPeriod;

            while (day <= endOfPeriod) {
                this.currentPeriod.push(day.toDate());
                day = day.clone().add(1, 'd');
            }
        }
    },
}