var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('p',{staticClass:"f-24-grey mb-1"},[_vm._v(_vm._s(_vm.name))]),(_vm.filled && _vm.requestStatus)?_c('div',{directives:[{name:"touch",rawName:"v-touch:swipe.left",value:(_vm.swipeRight),expression:"swipeRight",arg:"swipe",modifiers:{"left":true}},{name:"touch",rawName:"v-touch:swipe.right",value:(_vm.swipeLeft),expression:"swipeLeft",arg:"swipe",modifiers:{"right":true}}],class:[
            'scroll-table', 
            ("scroll-table-" + (_vm.head.length)),
            _vm.isShowDivider ? 'scroll-table-divider' : ''
        ]},[_c('div',{class:[
                'table-head',
                { 'table-head-collapse' : _vm.isShowHeadMobileOnly}
            ]},[(_vm.isShowHead)?_c('div',{staticClass:"scroll-container"},_vm._l((_vm.head),function(item,index){return _c('span',{key:index,class:[
                        'table-td',
                        [_vm.isShowHeadMobileOnly && index === (_vm.head.length - 1) ? 't-center' : ''],
                        { 'pointer': _vm.showSort }
                    ],on:{"click":function($event){return _vm.$emit('sort', item)}}},[(_vm.showSort)?_c('move-icon',{class:[
                            'order-by-icon',
                            item === _vm.columnSort ? ("order-" + _vm.orderBy) : ''
                        ],attrs:{"transform":"90"}}):_vm._e(),_vm._v("\n                    "+_vm._s(item)+"\n                ")],1)}),0):_vm._e(),(_vm.head.length > _vm.elementsInView)?_c('div',{staticClass:"table-navigation"},[_c('button',{class:[
                        'swipe-left',
                        this.lastElementInView === this.elementsInView ? 'disabled' : ''
                    ],attrs:{"disabled":_vm.swiping},on:{"click":_vm.swipeLeft}},[_c('arrow-icon',{attrs:{"size":"15","transform":"180"}})],1),_c('button',{class:[
                        'swipe-right',
                        this.head.length === this.lastElementInView ? 'disabled' : ''
                    ],attrs:{"disabled":_vm.swiping},on:{"click":_vm.swipeRight}},[_c('arrow-icon',{attrs:{"size":"15","transform":"0"}})],1)]):_vm._e()]),_vm._t("default")],2):_vm._e(),(!_vm.requestStatus)?_c('Loader',{attrs:{"size":"small"}}):_vm._e(),(!_vm.filled && _vm.requestStatus && _vm.showNotFoundMessage)?_c('not-found-message',{attrs:{"location":_vm.name}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }