var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-milestones"},[_c('div',{staticClass:"order-milestones-panel row-start relative"},[_c('div',{class:[
                'order s-align-5 h-100', 
                { 'order-without-order': !_vm.hasMaterial || !_vm.existMaterial }
            ]},[_c('span',{staticClass:"f-12-darkgrey"},[_vm._v("Order")])]),_c('div',{class:[
                'material s-align-5 h-100 relative', 
                { 'order-without-material': !_vm.hasMaterial || !_vm.existMaterial }
            ]},[_c('p',{staticClass:"column-center w-100"},[_c('span',{staticClass:"f-12-darkgrey"},[_vm._v("Material")]),(_vm.vendor)?_c('span',{staticClass:"f-10-blue w-100 ellipsis ph-2 t-center"},[_vm._v("\n                    "+_vm._s(_vm.vendor.name)+"\n                ")]):_vm._e()]),(!_vm.hasMaterial || !_vm.existMaterial)?_c('div',{staticClass:"material-message s-align-5 column-center"},[_c('p',{staticClass:"f-12-darkgrey"},[_vm._v("No Material")]),(!_vm.existMaterial && _vm.hasMaterial)?_c('router-link',{staticClass:"f-11-blue",attrs:{"to":{ name: _vm.orderCreateRoute, 
                        params: { order_id: _vm.orderId },
                        query: { tab: 'material' }
                    }}},[_vm._v("\n                    + Add Material\n                ")]):_vm._e()],1):_vm._e()]),_c('div',{class:[
                'labor s-align-5 h-100 relative', 
                { 'order-without-labor': !_vm.hasLabor || !_vm.existLabor }
            ]},[_c('p',{staticClass:"column-center w-100"},[_c('span',{staticClass:"f-12-darkgrey"},[_vm._v("Labor")]),(_vm.crew)?_c('span',{staticClass:"f-10-blue w-100 ellipsis ph-2 t-center"},[_vm._v("\n                    "+_vm._s(_vm.crew.name)+" \n                ")]):_vm._e()]),(!_vm.hasLabor || !_vm.existLabor)?_c('div',{staticClass:"labor-message s-align-5 column-center"},[_c('p',{staticClass:"f-12-darkgrey"},[_vm._v("No Labor")]),(!_vm.existLabor && _vm.hasLabor)?_c('router-link',{staticClass:"f-11-blue",attrs:{"to":{ name: _vm.orderCreateRoute, 
                        params: { order_id: _vm.orderId },
                        query: { tab: 'labor' }
                    }}},[_vm._v("\n                    + Add Labor\n                ")]):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"closed h-100"})]),_c('div',{staticClass:"order-milestones-items row-start"},_vm._l((_vm.milestones),function(milestone,index){return _c('div',{key:("milestone-" + index),staticClass:"order-milestones-item"},[_c('div',{class:['milestone-box hidden s-align-8', { 'first-border': index == 0 }]},[_c('div',{class:[
                        'w-100 relative',
                        { 
                            'milestone-status-done': milestone.status == _vm.DIAGRAM_STATUSES.DONE,
                            'milestone-status-await': milestone.status == _vm.DIAGRAM_STATUSES.AWAIT,
                            'milestone-status-in-progress': milestone.status == _vm.DIAGRAM_STATUSES.IN_PROGRESS 
                        }
                    ],style:({ height: Math.round(60 + 19 * index) + 'px' })},[_c('div',{staticClass:"s-align-5 relative pt-25"},[_c('div',{staticClass:"milestone-status-icon s-align-5"},[(milestone.status == _vm.DIAGRAM_STATUSES.DONE)?_c('mark-icon',{staticClass:"i-white",attrs:{"size":"9"}}):_vm._e()],1),_c('span',{class:[
                                'f-sbold capitalize', 
                                milestone.status == _vm.DIAGRAM_STATUSES.DONE ? 'f-11-green' : 'f-11-grey'
                            ]},[_vm._v("\n                            "+_vm._s(milestone.name)+"\n                        ")])])])]),_c('div',{staticClass:"s-align-5 pt-4 f-11-grey"},[_c('span',{class:['ph-4 t-center', { 'f-italic opacity-5': !milestone.date }]},[_vm._v("\n                    "+_vm._s(_vm._f("date")(!milestone.date ? 'no info' : milestone.date))+"\n                ")])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }