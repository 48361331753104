<template>
    <div class="audit-list-table">
        <scroll-table 
            :head="tableHeadOverflow"
            :filled="tableBody.length"
            name="Payment"
            :requestStatus="true"
        >
            <div 
                class="table-body"
                v-for="(row, index) in tableBody"
                :key="index"
            >
                <div class="table-row">
                    <div class="scroll-container">
                        <div class="table-td">
                            <span class="cell">{{ row.updatedAt }}</span>
                        </div>
                        <div class="table-td">
                            <span class="cell">{{ row.category }}</span>
                        </div>
                        <div class="table-td">
                            <span class="cell">{{ row.event }}</span>
                        </div>
                        <div class="table-td">
                            <span class="cell">{{ row.jobNumber }}</span>
                        </div>
                        <div class="table-td">
                            <span class="cell">{{ row.paymentNumber }}</span>
                        </div>
                         <div class="table-td">
                            <span class="cell">{{ row.createdBy }}</span>
                        </div>
                         <div class="table-td">
                            <span class="cell">{{ row.paymentType }}</span>
                        </div>
                         <div class="table-td">
                            <span class="cell">{{ row.trade }}</span>
                        </div>
                    </div>
                    <button class="content-open-button">
                        <arrow-icon/> 
                    </button>
                </div>
                <div class="table-content">
                    <div class="table-content-container">
                        <div class="row-start">
                            <div class="lg-3 md-4 sm-12 mb-5">
                                <div class="column-start">
                                    <div>
                                        <div class="table-content-title table-content-title-green f-14-darkgrey mb-4">Title lorem</div>
                                        <span class="f-14-grey">Mailing:</span>
                                        <span class="ml-1 f-14-darkgrey">Same as location</span>
                                    </div>
                                    <div>
                                        <span class="f-14-grey">Billing:</span>
                                        <span class="ml-1 f-14-darkgrey">Same as location</span>
                                    </div>
                                    <div>
                                        <span class="f-14-grey">Category:</span>
                                        <span class="ml-1 f-14-darkgrey">Residential</span>
                                    </div>
                                </div>
                            </div>
                            <div class="lg-3 md-4 sm-12 mb-5">
                                <div class="column-start">
                                    <div>
                                        <div class="table-content-title table-content-title-orange f-14-darkgrey mb-4">Title lorem</div>
                                        <span class="f-14-grey">Mailing:</span>
                                        <span class="ml-1 f-14-darkgrey">Same as location</span>
                                    </div>
                                    <div>
                                        <span class="f-14-grey">Billing:</span>
                                        <span class="ml-1 f-14-darkgrey">Same as location</span>
                                    </div>
                                    <div>
                                        <span class="f-14-grey">Category:</span>
                                        <span class="ml-1 f-14-darkgrey">Residential</span>
                                    </div>
                                </div>
                            </div>
                            <div class="lg-3 md-4 sm-12 mb-5">
                                <div class="column-start">
                                    <div>
                                        <div class="table-content-title table-content-title-violet f-14-darkgrey mb-4">Title lorem</div>
                                        <span class="f-14-grey">Mailing:</span>
                                        <span class="ml-1 f-14-darkgrey">Same as location</span>
                                    </div>
                                    <div>
                                        <span class="f-14-grey">Billing:</span>
                                        <span class="ml-1 f-14-darkgrey">Same as location</span>
                                    </div>
                                    <div>
                                        <span class="f-14-grey">Category:</span>
                                        <span class="ml-1 f-14-darkgrey">Residential</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </scroll-table>
    </div>
</template>

<script>
    import ScrollTable from '@/components/ScrollTable.vue';
    import { ArrowIcon } from '@/icons';

    export default {
        name: 'PaymentTable',
        components: {
            ScrollTable,
            ArrowIcon
        },
        data() {
            return {
                tableHeadOverflow: ['Updated at', 'Category', 'Event', 'Job #', 'Payment #', 'Created by', 'Payment type', 'Trade'],
                tableBody: [
                    {   
                        updatedAt: '2019-06-18 09:21:21',
                        category: 'Materials', 
                        event: 'Deleted', 
                        jobNumber: 1,
                        paymentNumber: 2,
                        createdBy: 'Admin',
                        paymentType: 'Visa',
                        trade: 'Repairs'
                    },
                    {   
                        updatedAt: '2019-06-18 09:21:21',
                        category: 'Materials', 
                        event: 'Deleted', 
                        jobNumber: 1,
                        paymentNumber: 2,
                        createdBy: 'Admin',
                        paymentType: 'Visa',
                        trade: 'Repairs'
                    }
                ]
            }
        }
    }
</script>

