<template>
    <svg 
        viewBox="0 0 459 459"
        :width="size"
        :height="size"
        class="line-icon"
    >
        <g>
            <g id="menu">
                <path d="M0,382.5h459v-51H0V382.5z M0,255h459v-51H0V255z M0,76.5v51h459v-51H0z"/>
            </g>
        </g>
    </svg>
</template>
<script>
    export default {
        name: 'LineIcon',
        props: {
            size: {
                type: [Number, String],
                default: 16
            }
        }
    }
</script>