<template>
    <full-modal
        @close="$emit('close',editingFields,fieldIndex)"
        class="pick-list-modal"
    >
        <div slot="title">
            Dropdown properties
        </div>
        <div slot="body">
            <div class="row-between mb-3">
            <span class="f-14-darkgrey f-sbold">
                Add and prioritize options
            </span>
            <span class="f-14-darkgrey f-sbold">
                Choose default value
            </span>
            </div>
            <div 
                v-for="(item, index) in selectData"   
                :key="index"
            >
                <div
                    class="pick-list-property row-start row-baseline mb-1"
                    @dragover="propertiesDragOver(item, index)" 
                    @dragend="propertiesDragEnd(item, index)"
                    @dragstart="propertiesDragStart(item, index, $event)"
                    :class="{
                        selected: (propertiesDraggingIndex === index),
                        over: (index === propertiesOverIndex && index !== propertiesDraggingIndex)
                    }"
                    draggable="true"  
                >
                    <line-icon 
                        size="15"
                        class="i-grey"
                    />
                    <div :class="[
                        'w-100',
                        mScreen ? 'pick-list-input-mobile' : 'pick-list-input-desktop row-start'
                    ]">
                        <form-input 
                            :class="[{ 
                                'form-input-danger' : 
                                errors.has('pick_list_property_' + index) 
                            }]"
                        >
                            <input 
                                class="form-input-field"
                                type="text" 
                                :id="'pick_list_property_' + index"
                                :name="'pick_list_property_' + index"
                                placeholder="Enter value"
                                v-input-style="selectData[index].value"
                                v-model="selectData[index].value"
                                maxlength="30"
                                v-validate="'required|max:30'"
                            >
                        </form-input>
                    </div>
                    <radio-button 
                        color="blue"
                        :class="[
                            selectData.length > 1 ? 'mr-3' : 'mr-25',
                            { 'none-events' : item.default}
                        ]"
                    >
                        <input 
                            type="checkbox" 
                            name="default_item"
                            :checked="item.default"
                            @change="selectDefault(item.id)"
                        />
                    </radio-button>
                    <button 
                        v-if="selectData.length > 1"
                        class="pick-list-delete transparent-button"
                        @click="deleteOption(index)"
                    >
                        <trash-icon 
                            :width="17"
                            :height="17"
                            class="i-grey"
                        />
                    </button>
                </div>      
            </div>
            <button 
                class="custom-fields-new-item s-align-5 w-100"
                @click="addNewField()"
            >
                <div class="round s-align-5 mr-5">
                    <add-icon size="9"/>
                </div>
                <span class="f-13-blue f-sbold">New option</span>
            </button>
        </div>
        <div slot="footer">
            <button 
                class="add-button mb-2"
                @click="$emit('close',editingFields,fieldIndex)"
            >
                Cancel
            </button>
            <button 
                class="primary-button mb-2"
                @click="updateField"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { LineIcon, TrashIcon, AddIcon, ArrowIcon } from '@/icons';
    import { FullModal, RadioButton, FormInput } from '@/ui';
    import cloneDeep from 'lodash/cloneDeep';

    export default {
        name: 'SmartDropdownItemsModal',
        components: {
            LineIcon, 
            TrashIcon,
            ArrowIcon,
            FullModal,
            RadioButton,
            FormInput,
            AddIcon
        },
        props: {
            editingFields: {
                type: Array,
                required: true
            },
            fieldIndex: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                propertiesOverIndex: null,
                propertiesDragging: '',
                propertiesDraggingIndex: null,
                selectData: []
            }
        },
        created() {
            this.selectData = cloneDeep(this.editingFields);
        },
        methods: {
            addNewField() {
                let ids = this.selectData.map(el => el.id);
                let newId = Math.max.apply(null, ids) + 1;

                let newField = {
                    'id': newId,
                    'value' : '',
                    'order': this.selectData.length,
                    'default': false
                };
                this.selectData.push(newField);
            },
            selectDefault(id) {
                this.selectData.forEach(el => el.default = el.id == id)
            },
            deleteOption(index) {
                let defaultField = this.selectData[index].default;
                
                this.selectData.splice(index, 1);

                if (defaultField) {
                    this.selectData[0].default = true;
                }
            },
            propertiesDragStart(item, index, event) {
                this.propertiesDragging = item;
                this.propertiesDraggingIndex = index;
            },
            propertiesDragOver(item, index) {
                this.propertiesOverIndex = index;

                if (this.propertiesOverIndex !== this.propertiesDraggingIndex) {
                    this.selectData.splice(this.propertiesDraggingIndex, 1);
                    this.selectData.splice(this.propertiesOverIndex, 0, this.propertiesDragging);

                    this.propertiesDraggingIndex = index;
                }     
            },
            propertiesDragEnd(item, index) {
                this.propertiesDragging = '';
                this.propertiesDraggingIndex = null;
                this.propertiesOverIndex = null;
                this.$validator.reset();
            },
            updateField() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('Please fill out all fields!');

                        return;
                    };
                    this.notifySuccess('All fields has been saved.');
                    this.$emit('onUpdate', this.selectData, this.fieldIndex);
                    this.$emit('close', this.selectData, this.fieldIndex);
                });
            }
        }
    }
</script>