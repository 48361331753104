<template>
    <div 
        :class="['dropdown', { 
            'dropdown-disabled' : disabled, 
            'dropdown-danger': danger 
        }]"
        :style="{ width: width + unit }"
    >
        <button 
            class="dropdown-head row-between"
            @click="open = !open"
            :style="{ height: `${height}px` }"
        >
            <span class="s-align-4 ellipsis h-100">
                {{ current.length ? current : placeholder }}
            </span>
            <span 
                v-if="arrowIcon"
                class="s-align-6 h-100"
            >
                <arrow-icon :transform="open && !disabled ? 270 : 90"/>
            </span>
        </button>
        <perfect-scrollbar
            class="ps-grey dropdown-list"
            v-if="open && !disabled"
        >
            <ul v-if="!$slots.default">
                <li
                    v-for="option in options"
                    :key="option.id"
                    @click="selectItem(option)"
                >
                    {{ option[label] }}
                </li>
            </ul>
            <ul v-else>
                <slot></slot>
            </ul>
        </perfect-scrollbar>
    </div>
</template>

<script>
    // get id - default
    // get name - add props return="name"
    // get object - add props return="object"
    // disabled - :disabled="true"
    // width - width="100"
    // height - height="45" (pixel)
    // bydefault - ID of the default value
    // arrowIcon - show/hide arrow icon, default true

    // options - { name: 'some name', id: 25 }

    // to pass custom elements inside dropdown use <slot>, if <slot> empty uses values from 'options'

    import { ArrowIcon } from '@/icons';

    export default {
        name: 'Dropdown',
        props: {
            options: {
                type: [Array, Object],
                default: null
            },
            placeholder: {
                type: String,
                default: 'Select options'
            },
            return: {
                type: String,
                default: 'id'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            width: {
                type: String,
                default: '130'
            },
            height: {
                type: [String, Number],
                default: 35
            },
            bydefault: {
                type: Number,
                default: -1
            },
            arrowIcon: {
                type: Boolean,
                default: true
            },
            label: {
                type: String,
                default: 'name'
            },
            danger: {
                type: Boolean,
                default: false
            }
        },
        components: {
            ArrowIcon
        },
        data() {
            return {
                open: false,
                current: ''
            }
        },
        watch: {
            open() {
                this.$emit('open', this.open);
            }
        },
        computed: {
            unit() {
                return this.width.slice(-1) == '%' ? '' : 'px';
            }
        },
        methods: {
            selectItem(option) {
                this.current = option[this.label];
                
                let value = null;

                switch(this.return) {
                    case this.label : {
                        value = option[this.label];
                    }; break;
                    case 'object' : {
                        value = option;
                    }; break;
                    default : {
                        value = option.id;
                    }; break;
                };

                this.$emit('select', value);
                this.open = false;
            },
            outside(event) {
                if (!this.$el.contains(event.target) && this.open)
                    this.open = false;
            }
        },
        mounted() {
            document.body.addEventListener('click', this.outside, false);
            this.current = this.bydefault != -1 ? this.options.find(o => o.id === this.bydefault)[this.label] : '';
        },
        destroyed() {
            document.body.removeEventListener('click', this.outside, false);
        }
    }
</script>
