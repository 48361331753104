<template>
    <svg
        viewBox="0 0 20 15"
        :width="size"
        :height="size"
        class="long-integer-icon"
    >
        <g transform="matrix(0.19378390908241272, 0, 0, 0.19378390908241272, -19.519407272338867, -35.46250279744466)">
            <g>
                <path d="M162.5,224h-20.4c-2.8,0-5-2.2-5-5s2.2-5,5-5h20.4c2.8,0,5,2.2,5,5S165.3,224,162.5,224z"/>
            </g>
            <g>
                <g>
                    <path d="M 124.000003 216 A 7.2 7.2 0 0 1 116.800003 223.2 A 7.2 7.2 0 0 1 109.600003 216 A 7.2 7.2 0 0 1 116.800003 208.8 A 7.2 7.2 0 0 1 124.000003 216 Z"/>
                    <path d="M116.8,225.9c-5.5,0-9.9-4.5-9.9-9.9c0-5.5,4.5-9.9,9.9-9.9c5.5,0,9.9,4.5,9.9,9.9C126.8,221.5,122.3,225.9,116.8,225.9z M116.8,211.6c-2.4,0-4.4,2-4.4,4.4c0,2.4,2,4.4,4.4,4.4s4.4-2,4.4-4.4C121.2,213.6,119.3,211.6,116.8,211.6z"/>
                </g>
                <g>
                    <path d="M108,235.9c-1.3,0-2.4-0.9-2.7-2.2c-0.3-1.5,0.6-3,2.1-3.3c14.9-3.3,13.9-13.6,13.9-14.1c-0.2-1.5,0.9-2.9,2.4-3s2.9,0.9,3,2.4c0.7,5.5-2.3,16.7-18.2,20.2C108.4,235.9,108.2,235.9,108,235.9z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M 197.000003 216 A 7.2 7.2 0 0 1 189.800003 223.2 A 7.2 7.2 0 0 1 182.600003 216 A 7.2 7.2 0 0 1 189.800003 208.8 A 7.2 7.2 0 0 1 197.000003 216 Z"/>
                    <path d="M189.8,225.9c-5.5,0-9.9-4.5-9.9-9.9c0-5.5,4.5-9.9,9.9-9.9c5.5,0,9.9,4.5,9.9,9.9C199.8,221.5,195.3,225.9,189.8,225.9z M189.8,211.6c-2.4,0-4.4,2-4.4,4.4c0,2.4,2,4.4,4.4,4.4c2.4,0,4.4-2,4.4-4.4C194.2,213.6,192.3,211.6,189.8,211.6z"/>
                </g>
                <g>
                    <path d="M181,235.9c-1.3,0-2.4-0.9-2.7-2.2c-0.3-1.5,0.6-3,2.1-3.3c14.9-3.3,13.9-13.6,13.9-14.1c-0.2-1.5,0.9-2.9,2.4-3c1.5-0.2,2.9,0.9,3,2.4c0.7,5.5-2.3,16.7-18.2,20.2C181.4,235.9,181.2,235.9,181,235.9z"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'LongIntegerIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>
