<template>
    <div
        :style="popupPosition"
        :class="[
            'calendar-event-popup',
            popupOffset.offsetLeft > popupOffset.offsetRight 
                ? 'calendar-event-popup-triangle-right' 
                : 'calendar-event-popup-triangle-left',
            popupOffset.offsetTop < popupOffset.offsetBottom 
                ? 'calendar-event-popup-triangle-top' 
                : 'calendar-event-popup-triangle-bottom',
        ]"
    >
        <event-edit
            v-if="calendarType !== CALENDAR_TYPES.JOB"
            :newEventStart="newEventStart"
            :newEventEnd="newEventEnd"
            :calendarList="calendarList"
            :reAssignMode='false'
            :calendarId="calendarId"
            :calendarType="calendarType"
            :userId="userId"
            :eventAssignTechnick="eventAssignTechnick"
            @closeNewEventPopup="closeNewEventPopup"
        />
        <order-create-popup
            v-else
            :job="job"
            @closeNewEventPopup="closeNewEventPopup"
        />
    </div>
</template>

<script>
    import { CALENDAR_TYPES } from '@/constants.js';
    import EventEdit from '@/components/Calendar/EventEdit.vue';
    import OrderCreatePopup from '@/components/Calendar/OrderCreatePopup.vue';
    import moment from 'moment/src/moment';
    
    export default {
        name: 'EventCreatePopup',
        components: {
            OrderCreatePopup,
            EventEdit
        },
        props: {
            job: {
                type: Object,
                required: false
            },
            popupOffset: {
                type: Object,
                required: true
            },
            eventStart: {
                type: String,
                default: ''
            },
            eventEnd: {
                type: String,
                default: ''
            },
            calendarList: {
                type: Array,
                required: false
            },
            calendarType: {
                type: String,
                default: CALENDAR_TYPES.USER
            },
            calendarId: {
                type: String,
                required: false
            },
            userId: {
                type: [String, Number],
                required: false
            },
            eventAssignTechnick: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                CALENDAR_TYPES: CALENDAR_TYPES
            }
        },
        computed: {
            popupPosition: function() {
                let css = '';

                if (this.popupOffset.offsetLeft > this.popupOffset.offsetRight) {
                    css = `right: ${this.popupOffset.offsetRight}px;`
                } else {
                    css =  `left: ${this.popupOffset.offsetLeft}px;`
                }

                if (this.popupOffset.offsetTop < this.popupOffset.offsetBottom) {
                    css += `top: ${this.popupOffset.offsetTop}px;`
                } else {
                    css += `bottom: ${this.popupOffset.offsetBottom}px;`
                }

                return css;
            },
            utcOffset() {
                return moment(this.eventStart).utcOffset();
            },
            newEventStart() {
                return this.eventStart
                    ? moment(this.eventStart).subtract(this.utcOffset, 'm').format('YYYY-MM-DD HH:mm:ss') : '';
            },
            newEventEnd() {
                return this.eventEnd
                    ? moment(this.eventEnd).subtract(this.utcOffset, 'm').format('YYYY-MM-DD HH:mm:ss') : '';
            }
        },
        methods: {
            closeNewEventPopup(data) {
                this.$emit('closeNewEventPopup', data);
            }
        }
    }
</script>