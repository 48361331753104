<template>
    <svg 
        viewBox="0 0 18 18"
        :width="size"
        :height="size"
        class="crop-icon"
    >
        <path d="M17.3 13h-3V3.7H5v-3c0-.4-.2-.7-.6-.7-.4 0-.7.3-.7.7v3h-3c-.4 0-.7.3-.7.6 0 .4.3.7.7.7h3v9.3H13v3c0 .4.3.7.7.7.4 0 .7-.3.7-.7v-3h3c.4 0 .7-.3.7-.7-.1-.3-.4-.6-.8-.6zM13 5v8H5V5h8z"/>
    </svg>
</template>

<script>
    export default {
        name: 'CropIcon',
        props: {
            size: {
                type: [Number, String],
                default: 18
            }
        }
    }
</script>