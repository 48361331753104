<template>
    <div 
        :class="[
            'reply-to-letter w-100',
            !letter.is_manual ? 'pt-30' : 'pt-5'
        ]"
    >
        <div class="row-between">
            <div class="lg-8 md-12 sm-12">
                <h2
                    v-if="!letter.is_manual"
                    class="f-20-darkgrey break-all f-sbold"
                >
                    {{ letter.subject }}
                </h2>
            </div>
        </div>
        <loader
            v-if="loading"
            size="small"
        />
        <div 
            v-for="(message, index) in conversations"
            :key="message.id"
            v-else
            :class="[
                !letter.is_manual ? 'pt-30' : 'pt-5',
                { 'bb-grey pb-30' : index != conversations.length -1 }
            ]"
        >
            <div :class="[
                'row-between hidden',
                { 'hidden-content' : !isVisible(message.id) 
                                    && isNeedToggleButton(index) 
                                    && !letter.is_manual }
            ]">
                <div
                    v-if="message.from.emailAddress" 
                    class="lg-8 sm-12 s-align-4"
                >
                    <avatar
                        :name="getNameForAvatar(message)"
                        :border="false"
                        size="35"
                    />
                    <div class="column-start pl-8">
                        <p class="f-16-black f-sbold">
                            {{ message.from.emailAddress.name }}
                        </p>
                        <p class="f-13-grey">
                            {{ message.from.emailAddress.address }}
                        </p>
                        <p
                            v-if="!letter.is_manual"
                            class="f-13-grey"
                        >
                            Sent to:
                            <span>
                                {{ toRecipients(message.toRecipients) }}
                            </span>
                        </p>
                    </div>
                </div>
                <div
                    v-if="!letter.is_manual"
                    class="lg-4 sm-12 s-align-6 mt-sm-1"
                >
                    <span 
                        class="f-12-blue f-sbold s-align-6 pr-8 br-grey" 
                        v-if="message.attachments"
                    >
                        <attach-icon 
                            class="i-blue mr-1" 
                            size="13"
                        />
                        {{ message.attachments.length }}
                    </span>
                    <span class="f-12-grey f-sbold pl-8">
                        {{ message.created_at | timeAgo }}
                    </span>
                </div>
                <div class="w-100 pt-4 pl-50 mt-sm-3">
                    <div 
                        class="f-14-grey message mb-8 " 
                        v-html="message.body.content"
                    >
                    </div>
                </div>
                <div
                    v-if="!letter.is_manual"
                    class="row-start pl-50 mb-5"
                >
                    <div 
                        v-for="attachment in message.attachments"
                        :key="attachment.id"
                        class="s-align-5 mb-4 mr-8"
                    >       
                        <div class="attachment-preview s-align-5">
                            <span class="f-13-grey f-sbold">
                                .{{ getFileType(attachment.name) }}
                            </span>
                        </div>
                        <div class="attachment-name ellipsis column-start ml-4">
                            <button 
                                class="transparent-button f-14-black f-sbold ellipsis"
                                @click="downloadAttachment(message, attachment)"
                            >
                                {{attachment.name}}
                            </button> 
                            <div class="row-start">
                                <span class="f-12-grey">{{ attachment.size / 1000 }} kb</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button 
                v-if="isNeedToggleButton(index) && !letter.is_manual" 
                @click="showHideEmail(message.id, index)"
                class="transparent-button f-13-blue ml-50"
            >
                {{ isVisible(message.id) ? 'Hide email' : 'Show full email' }}
                <arrow-icon 
                    :transform="!isVisible(message.id) ? 90 : 270" 
                    size="8"
                    class="i-blue ml-2"
                />
            </button>
        </div>
        <div
            v-if="isAccountIdInConversation"
            class="w-100 mt-30 pt-4 send-reply-wrapper"
        >
            <div class="pl-20">
                <div :class="[
                    's-align-4 f-13-black f-sbold mb-4 no-wrap',
                    { 'bb-grey': showCC || showBCC }
                ]">
                    Send to:
                    <div class="w-90 ml-4">
                        <lead-emails-chips
                            :account-id="account_id"
                            :leadChips="sendChips"
                            :entityType="entityType"
                            :recipients="recipients"
                            :fromEmailAddress="fromEmailAddress"
                            name="send-to-email"
                        />
                    </div>
                    <div class="s-align-4">
                        <button
                            v-if="!showCC"
                            @click="showCC = true" 
                            class="transparent-button mr-1 f-13-blue"
                        >
                            + CC
                        </button>
                        <button 
                            v-if="!showBCC"
                            @click="showBCC = true"
                            class="transparent-button f-13-blue mr-2"
                        >
                            + BCC
                        </button>
                    </div>
                </div>
                <div 
                    v-if="showCC"
                    :class="[
                        's-align-4 f-13-black f-sbold mb-4 no-wrap',
                        { 'bb-grey': showBCC }
                    ]"
                >
                    Carbon copy:
                    <div class="w-90 ml-4">
                        <lead-emails-chips 
                            :leadChips="copyChips"
                            :account-id="account_id"
                            :entityType="entityType"
                            :recipients="recipients"
                            :fromEmailAddress="fromEmailAddress"
                            name="send-to-copy-email"
                        />
                    </div>
                </div>
                <div 
                    v-if="showBCC"
                    class="s-align-4 f-13-black f-sbold no-wrap"
                >
                    Blind copy:
                    <div class="w-90 ml-4">
                        <lead-emails-chips 
                            :leadChips="blindChips"
                            :entityType="entityType"
                            :account-id="account_id"
                            :recipients="recipients"
                            :fromEmailAddress="fromEmailAddress"
                            name="send-to-bcopy-email"
                        />
                    </div>
                </div>
            </div>
            <div class="relative">
            <text-editor 
                v-model="message"
                placeholder="Type your message here..."
                toolbarName="notesToolbar"
            />
                <button 
                    class="primary-button send-reply-button"
                    @click="sendReply()"
                    :disabled="requestIsGoing"
                >
                    <send-icon 
                        class="mr-1" 
                        size="15"
                    />
                    Send
                </button>
                <button 
                    class="transparent-button send-attachment-button"
                    @click="addAttachment()"
                >
                    <attach-icon 
                        class="i-grey" 
                        size="16"
                    />
                </button>
            </div>
            <input 
                type="file" 
                name="file_upload"
                ref="uploadInput"
                @change="fileInputHandler"
                hidden
            />
            <div class="row-start mh-8">
                <div 
                    v-for="(attachment, index) in attachmentsList"
                    :key="index"
                    class="s-align-5 mb-4 mr-8"
                >
                    <div class="row-between">
                        <div class="s-align-4">
                            <div class="attachment-preview s-align-5">
                                <span class="f-13-grey f-sbold">
                                    .{{ getFileType(attachment.name) }}
                                </span>
                            </div>
                            <div class="attachment-name ellipsis column-start ml-4">
                                <span class="f-14-black f-sbold ellipsis">
                                    {{attachment.name}}
                                </span> 
                                <div class="row-start">
                                    <span class="f-12-grey">{{ attachment.size / 1000 }} kb</span>
                                </div>
                            </div>
                        </div>
                        <div class="ml-2">
                            <button
                                class="transparent-button p-1"
                                @click="removeAttachment(index)"
                            >
                                <close-icon
                                    class="i-red"
                                    size="5"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    
    import { WriteIcon, TrashIcon, AttachIcon, SendIcon, ArrowIcon, CloseIcon } from '@/icons';
    import { Loader, FormInput } from '@/ui';
    import { Avatar, LeadEmailsChips } from '@/components';
    import TextEditor from '@/components/TextEditor.vue';
    import IMAPapi from '@/IMAPheaders';
    import { BASE_ENTITY_TYPES, MAX_SIZE_FILES } from '@/constants';

    export default {
        name: 'ReplyToLetter',
        components: {
            WriteIcon,
            ArrowIcon,
            TrashIcon,
            Loader,
            FormInput,
            Avatar,
            AttachIcon,
            TextEditor,
            SendIcon,
            CloseIcon,
            LeadEmailsChips
        },
        props: {
            letter: {
                type: Object,
                required: true
            },
            account_id: {
                default: null
            },
            entityType: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                message: '',
                conversations: [],
                loading: false,
                requestIsGoing: false,
                sendChips:[],
                copyChips: [],
                blindChips: [],
                attachmentsList: [],
                sendingAttachments: [],
                visibleMessages: [],
                messagesDomList: [],
                showCC: false,
                showBCC: false,
                fromEmailAddress: []
            }
        },
        computed: {
            leadId() {
                return this.$route.params.lead_id;
            },
            entityId() {
                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL:
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return this.$route.params.lead_id;
                    }
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB: {
                        return this.$route.params.job_id;
                    }
                }
            },
            propertyId() {
                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB: {
                        return +this.$route.params.property_id;
                    }
                    default: return null;
                }
            },
            isAccountIdInConversation() {
                return this.conversations.some(el => el.account_id && el.account_id == this.account_id);
            },
            userSignature() {
                return this.$store.getters['user/getUserSignature'];
            },
            recipients() {
                let recipients = this.conversations.flatMap(el => el.toRecipients.map(el => el.emailAddress.address));
                let uniqRecipients = [...new Set(recipients)];
                return uniqRecipients;
            }
        },
        methods: {
            addAttachment() {
                this.$refs.uploadInput.click();
            },
            removeAttachment(index) {
                this.attachmentsList.splice(index, 1);
                this.sendingAttachments.splice(index, 1);
            },
            fileInputHandler(event) {
                if (!this.$refs.uploadInput.files[0]) return false;
                let file = event.target.files[0];

                if (MAX_SIZE_FILES.FILE_EMAIL_MESSSAGE < file.size) {
                    this.notifyError('Maximum size of the file can be 30.0MB');
                    return;
                }

                this.attachmentsList.push(file)
                var reader = new FileReader();
                reader.readAsBinaryString(file);

                reader.onload = () => {
                    let attachment = {
                        Name: file.name,
                        ContentBytes: btoa(reader.result),
                        contentType: file.type,
                        size: file.size
                    }
                    this.sendingAttachments.push(attachment);
                };
            },
            isNeedToggleButton(index) {
                return this.messagesDomList && 
                    this.messagesDomList[index] && 
                    this.messagesDomList[index].clientHeight > 20;
            },
            showHideEmail(id) {
                if (this.isVisible(id)) {
                    let index = this.visibleMessages.findIndex(el => el == id);
                    this.visibleMessages.splice(index,1);
                } else {
                    this.visibleMessages.push(id);
                }
            },
            isVisible(id) {
                return this.visibleMessages.some(item => item == id);
            },
            sendReply() {
                this.requestIsGoing = true;
                let lastConversation = this.conversations[this.conversations.length -1];
                let entityIds = [];
                let toRecipients = [];
                let ccRecipients = [];
                let bccRecipients = [];
                const sizeAllFiles = this.attachmentsList.reduce(function(sum, current) {
                    return sum + current.size;
                }, 0);

                if (MAX_SIZE_FILES.FILE_EMAIL_MESSSAGE < sizeAllFiles) {
                    this.notifyAttachmentsFileLimit();
                    return;
                }

                entityIds.push(Number(this.entityId));
                if (this.sendChips.length) {
                    this.sendChips.forEach(mail => {
                        let emailAddress = {
                            "emailAddress": {
                                "address": mail
                            }
                        };
                        toRecipients.push(emailAddress);
                    });
                } else {
                    this.notifyError('You need fill "Send to" field for send message');
                    this.requestIsGoing = false;
                    return
                }

                if (this.copyChips.length) {
                    this.copyChips.forEach(mail => {
                        let emailAddress = {
                            "emailAddress": {
                                "address": mail
                            }
                        };
                        ccRecipients.push(emailAddress);
                    });
                }

                if (this.blindChips.length) {
                    this.blindChips.forEach(mail => {
                        let emailAddress = {
                            "emailAddress": {
                                "address": mail
                            }
                        };
                        bccRecipients.push(emailAddress);
                    });
                }

                let params = {
                    "account_id": this.account_id,
	                "message_id": lastConversation.id,
	                "comment": this.message,
                    "entity_ids": entityIds,
                    "entity_type": this.entityType,
                    "message": {
                        "toRecipients": toRecipients,
                        "ccRecipients": ccRecipients,
                        "bccRecipients": bccRecipients,
                        "attachments": this.sendingAttachments
                    }
                }

                if (this.propertyId) {
                    params.property_id = this.propertyId;
                }

                IMAPapi.post(`microsoft/message/reply`, params)
                    .then(response => {
                        this.getConversation();
                        this.notifySuccess('Message is send');
                        this.message = '';
                        this.attachmentsList = [];
                        this.sendingAttachments = [];
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            },
            getConversation() {
                this.loading = true;

                let params = {
                    conversationId: this.letter.conversationId
                }

                IMAPapi.get(`conversation`, { params })
                    .then(response => {
                        response.data.data.forEach(el => {
                            let body = el.body.content.replace(/<style>/gi,'');
                            el.body.content = body;
                        });
                        this.conversations = response.data.data;
                        this.conversations.forEach(el => {
                            this.fromEmailAddress.push(el.from.emailAddress.address);
                        });
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$nextTick(() => {
                            this.messagesDomList = this.$el.querySelectorAll('.message');   
                        });
                    });
            },
            downloadAttachment(message, attachment) {
                let params = {
                    "account_id": this.letter.account_id,
                    "message_id": message.id,
                    "attachment_id": attachment.id
                }
    
                IMAPapi.get(`microsoft/message/attachment`, { params })
                    .then(response => {
                        let data = response.data.data;
                        const linkSource = `data:${data.contentType};base64,${data.contentBytes}`;
                        const fileName = data.name;
                        const downloadLink = document.createElement('a');
                        document.body.appendChild(downloadLink);
                        downloadLink.href = linkSource;
                        downloadLink.target = '_self';
                        downloadLink.download = fileName;
                        downloadLink.click(); 
                        document.body.removeChild(downloadLink);
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            getFileType(name) {
                let fileType = name.split('.');
                return fileType[fileType.length -1];
            },
            getNameForAvatar(message) {
                let name = message.from.emailAddress.name;

                return name;
            },
            toRecipients(emails) {
                return emails.map(email => email.emailAddress.name).join(', ');
            }
        },
        created() {
            this.getConversation();

            if (this.userSignature) {
                // if signature exist insert with 3 line break
                this.message = `<p><br></p><p><br></p><p><br></p>${this.userSignature}`;
            }
        }
    }
</script>
