<template>
    <div class="modal s-align-5">
        <div class="add-new-section-modal crew-modal bg-white w-95 column-static mb-20">
            <div class="modal-crew-content w-100 relative">
                <div
                    class="w-100"
                    v-if="loading"
                >
                    <loader/>
                </div>
                <div
                    :class="[
                        'row-between pt-30 flex-no-wrap mb-20',
                        mScreen ? 'ph-20' : 'ph-30'
                    ]"
                    v-if="!loading"
                >
                    <div class="column-start">
                        <div :class="['f-24-asphalt f-sbold', { 'w-100': mScreen }]">
                            Labor Ticket
                        </div>
                        <div class="f-13-darkgrey">
                            <span class="f-13-grey mr-2">Client Job details:</span>
                            <span
                                v-if="calendarType == CALENDAR_TYPES.CREW && order.entity"
                                class="f-13-blue"
                            >
                                <template v-if="order.entity.job_number">
                                    {{ order.entity.job_number }}
                                </template>
                                 <template v-else>
                                     {{ order.entity.first_name }}
                                     {{ order.entity.last_name }}
                                 </template>
                            </span>
                            <span
                                v-if="calendarType == CALENDAR_TYPES.JOB"
                                class="f-13-blue"
                            >
                                {{ crew.job_number }}
                            </span>
                        </div>
                    </div>
                    <button
                        class="transparent-button small-button mt-sm-1"
                        @click="$emit('close')"
                    >
                        <close-icon
                            class="i-grey"
                            size="15"
                        />
                    </button>
                </div>
            </div>
            <div
                v-if="!loading"
                class="column-start ph-30"
            >
                <div class="row-between">
                    <div class="s-align-1 w-100 mb-20">
                        <div class="s-align-4 flex-grow-1 w-100 in-dev">
                            <div class="f-18-black f-sbold mr-8">{{ order.name }}</div>
                            <button class="primary-button small-button">
                                <envelope-icon
                                    class="i-white mr-4"
                                    size="20"
                                />
                                Email to
                            </button>
                        </div>
                    </div>
                </div>
                <div class="bt-grey w-100 pv-8">
                    <div class="row-between pointer">
                        <div
                            class="s-align-4"
                            @click="deliveryIsOpen = !deliveryIsOpen"
                        >
                            <arrow-icon
                                class="i-grey mr-4"
                                :transform="deliveryIsOpen ? 270 : 90"
                            />
                            <div class="f-14-darkgrey f-sbold">Delivery</div>
                        </div>
                    </div>
                    <div
                        v-if="deliveryIsOpen"
                        class="mt-8 column-start s-align-1 w-100"
                    >
                        <p class="f-14-grey mb-4">
                            Date:
                            <span class="f-14-black">
                                {{ order.delivery_date | date }}
                            </span>
                        </p>
                        <p class="f-14-grey mb-4">
                            Time:
                            <span class="f-14-black">
                                {{ order.delivery_time }}
                            </span>
                        </p>
                        <p
                            v-if="order.entity"
                            class="f-14-grey"
                        >
                            Address:
                            <span class="f-14-blue">
                                {{ order.entity.full_address || getAddress(order.entity) }}
                            </span>
                        </p>
                    </div>
                </div>
                <div class="bt-grey w-100 pv-8">
                    <div
                        v-if="labor"
                        class="row-between pointer"
                    >
                        <div
                            class="s-align-4"
                            @click="laborContactsOpen = !laborContactsOpen"
                        >
                            <arrow-icon
                                class="i-grey mr-4"
                                :transform="laborContactsOpen ? 270 : 90"
                            />
                            <div class="f-14-darkgrey f-sbold">Labor Contacts</div>
                        </div>
                    </div>
                    <div
                        v-if="laborContactsOpen && labor"
                        class="mt-8 column-start s-align-1 w-100 break-all"
                    >
                        <p class="f-14-grey mb-4">
                            Name:
                            <span class="f-14-black">
                                {{ laborContact }}
                            </span>
                        </p>
                    </div>
                </div>
                <div class="bt-grey w-100 pv-8">
                    <div class="row-between pointer">
                        <div
                            class="s-align-4"
                            @click="datesIsOpen = !datesIsOpen"
                        >
                            <arrow-icon
                                class="i-grey mr-4"
                                :transform="datesIsOpen ? 270 : 90"
                            />
                            <div class="f-14-darkgrey f-sbold">Dates</div>
                        </div>
                    </div>
                    <div
                        v-if="datesIsOpen"
                        class="row-start s-align-4 pv-8"
                    >
                        <calendar-icon
                            size="14"
                            class="i-blue mr-4"
                        />
                        <span class="f-13-grey">
                            <span class="f-14-black">
                                {{ order.labor_delivery_date_from | date }}
                                <span class="f-14-grey">
                                    as {{ order.labor_delivery_time_from | time }}
                                </span>
                            </span>
                            -
                            <span class="f-14-black">
                                {{ order.labor_delivery_date_to | date }}
                                <span class="f-14-grey">
                                    as {{ order.labor_delivery_time_to | time }}
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
                <div
                    v-if="calendarType == CALENDAR_TYPES.CREW"
                    class="bt-grey w-100 pv-8"
                >
                    <div class="row-between pointer">
                        <div
                            class="s-align-4"
                            @click="crewAssignmentOpen = !crewAssignmentOpen"
                        >
                            <arrow-icon
                                class="i-grey mr-4"
                                :transform="crewAssignmentOpen ? 270 : 90"
                            />
                            <div class="f-14-darkgrey f-sbold">Crew Assignment</div>
                        </div>
                    </div>
                    <div
                        v-if="crewAssignmentOpen"
                        class="row-start s-align-4 pv-8"
                    >
                        <div
                            class="crew-icon mr-4"
                            :style="`background-color: ${ colorOrder }`"
                        />
                        <div class="column-start">
                            <div class="f-14-black">{{ crew.name }}</div>
                            <div class="f-12-grey">
                                Crew lead:
                                <span class="f-12-black">{{ crew.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="bt-grey w-100 pv-8"
                    v-if="order.quality_controls && order.quality_controls.length"
                >
                    <div class="row-between pointer">
                        <div
                            class="s-align-4"
                            @click="qcIsOpen = !qcIsOpen"
                        >
                            <arrow-icon
                                class="i-grey mr-4"
                                :transform="qcIsOpen ? 270 : 90"
                            />
                            <div class="f-14-darkgrey f-sbold">Qality Controls</div>
                        </div>
                    </div>
                    <div
                        v-if="qcIsOpen"
                        class="row-start s-align-4 pv-8"
                    >
                        <div class="column-start lg-4">
                            <template v-for="qc in order.quality_controls">
                                <p class="mb-1 w-100">
                                    <span class="f-14-darkgrey mr-4">
                                        {{ qc.fullname }}
                                    </span>
                                </p>
                            </template>
                        </div>
                        <div
                            class="lg-8 s-align-4"
                            v-if="order.appointment_at"
                        >
                            <calendar-icon
                                size="14"
                                class="i-blue mr-4"
                            />
                            <span class="f-14-black">
                                {{ order.appointment_at | date }}
                                {{ order.appointment_at | time }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="bt-grey w-100 pv-8">
                    <div class="row-between pointer">
                        <div
                            class="s-align-4"
                            @click="laborOrderOpen = !laborOrderOpen"
                        >
                            <arrow-icon
                                v-if="order.has_material"
                                class="i-grey mr-4"
                                :transform="laborOrderOpen ? 270 : 90"
                            />
                            <div class="f-14-darkgrey f-sbold">Labor Order</div>
                        </div>
                        <button
                            :class="disabledRedirectButton ? 'filter-button' : 'edit-button small-edit-button'"
                            :disabled="disabledRedirectButton"
                            @click="redirectToEditPage"
                        >
                            <edit-icon
                                size="11"
                                class="i-blue mr-2"
                            />
                            Edit order
                        </button>
                    </div>
                    <div
                        v-if="laborOrderOpen && order.has_material"
                        class="row-start s-align-4"
                    >
                        <div class="mt-8 column-start s-align-1 w-100">
                            <p class="f-14-grey mb-4">
                                Material order:
                                <span
                                    class="pointer f-14-blue"
                                    @click="$emit('openMaterial')"
                                >
                                    View materials
                                    <arrow-icon
                                        class="i-blue"
                                        :transform="360"
                                    />
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="!loading && labor.length"
                :class="[
                    'w-100 pt-8 bt-grey bg-grey-2 pb-20',
                    mScreen ? 'ph-20' : 'ph-30'
                ]"
            >
                <div
                    v-for="(el, index) in labor"
                    :key="index"
                    :class="[
                        'row-between f-14-black pv-4',
                        index < labor.length - 1 && 'bb-grey'
                    ]"
                >
                    <div class="lg-4 s-align-4">{{ el.name }}</div>
                    <div class="lg-4 s-align-5">
                        {{ el.quantity }}/
                        <span class="f-14-grey">CY</span>
                    </div>
                    <div class="lg-4 column-end">
                        <div class="f-16-black f-sbold">
                            {{ (Number(el.cost) * el.quantity) | currency }}
                        </div>
                        <div class="f-14-black">
                            ${{ el.cost }} /
                            <span class="f-14-grey">CY</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Loader } from '@/ui';
    import { CloseIcon, EnvelopeIcon, ArrowIcon, EditIcon, CalendarIcon } from '@/icons';
    import api from '@/headers.js';
    import { showFullAddress } from '@/helpers';
    import { ORDER_ITEMS_TYPE_ID, ENTITY_TYPES, CALENDAR_TYPES } from '@/constants.js';

    export default {
        name: 'SchedulerLaborModal',
        components: {
            CalendarIcon,
            EditIcon,
            ArrowIcon,
            EnvelopeIcon,
            CloseIcon,
            Loader
        },
        props: {
            order: {
                type: Object,
                required: true
            },
            colorOrder: {
                type: String
            },
            crew: {
                type: Object,
                required: true
            },
            calendarType: {
                type: String,
                default: CALENDAR_TYPES.CREW
            }
        },
        data() {
            return {
                loading: false,
                datesIsOpen: true,
                qcIsOpen: true,
                deliveryIsOpen: true,
                laborContactsOpen: true,
                crewAssignmentOpen: true,
                laborOrderOpen: true,
                labor: [],
                ENTITY_TYPES,
                laborContact: null,
                disabledRedirectButton: false,
                CALENDAR_TYPES
            }
        },
        created() {
            if (this.order) {
                this.getLabor(this.order.items);
            }

            this.getOrder();
        },
        methods: {
            getAddress(address) {
                return showFullAddress(address);
            },
            getOrder() {
                this.loading = true;

                api.get(`/orders/${this.order.id}`)
                    .then(response => response.data.data)
                    .then(data => {
                        this.laborContact = data.assign.fullname;
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            getLabor(items) {
                items.map(el => {
                    return el.type_id == ORDER_ITEMS_TYPE_ID.LABORS ? this.labor.push(el) : null;
                });
            },
            redirectToEditPage() {
                if (this.calendarType == CALENDAR_TYPES.CREW) {
                    switch (this.order.entity_type) {
                        case ENTITY_TYPES.RESIDENTIAL_LEAD:
                            return this.$router.push({
                                name: 'ResidentialOrderCreate',
                                params: {
                                    lead_id: this.order.entity_id,
                                    order_id: this.order.id
                                }
                            });
                        case ENTITY_TYPES.COMMERCIAL_JOB:
                            return this.$router.push({
                                name: 'CommercialJobOrderCreate',
                                params: {
                                    job_id: this.order.entity_id,
                                    property_id: this.order.crew_id,
                                    order_id: this.order.id
                                }
                            });
                    }
                }

                if (this.calendarType == CALENDAR_TYPES.JOB) {
                    switch (this.order.entity_type) {
                        case ENTITY_TYPES.RESIDENTIAL_LEAD:
                            return this.$router.push({
                                name: 'ResidentialOrderCreate',
                                params: {
                                    lead_id: this.crew.id,
                                    order_id: this.order.id
                                }
                            });
                        case ENTITY_TYPES.COMMERCIAL_JOB:
                            return this.$router.push({
                                name: 'CommercialJobOrderCreate',
                                params: {
                                    job_id: this.crew.id,
                                    property_id: this.crew.property_id,
                                    order_id: this.order.id
                                }
                            });
                    }
                }
            }
        }
    }
</script>