<template>
  <div class="modal s-align-5">
    <div class="create-new-template-modal cm-fond cm-scroll">
      <h2 class="f-24-black f-sbold t-center">
        Create new Template
      </h2>
      <div class="w-100 pb-8">
        <multiselect
            :options="measurementTypes"
            placeholder="Measurement type"
            :showLabels="false"
            :max-height="160"
            :searchable="false"
            label="name"
            track-by="id"
            v-model="newTemplateType"
            name="new_template_type"
            data-name="new_template_type"
            :class="[{ 'multiselect-danger' : errors.has('new_template_type') }]"
            @select="setNewTemplateName"
            v-validate="'required'"
        />
      </div>
      <div class="w-100 pb-8">
        <multiselect
            :options="tradesOptions"
            placeholder="Trade"
            :showLabels="false"
            :max-height="160"
            :searchable="false"
            label="name"
            track-by="id"
            v-model="trade"
            name="trade_type"
            data-name="trade_type"
            :class="[{ 'multiselect-danger' : errors.has('trade_type') }]"
            v-validate="'required'"
        />
      </div>
      <div class="w-100">
        <form-input
            label="Template Name"
            :class="[{ 'form-input-danger' : errors.has('new_template_name') }]"
        >
          <input
              type="text"
              name="new_template_name"
              class="form-input-field"
              placeholder="Template Name"
              v-input-style="newTemplateName"
              v-model="newTemplateName"
              v-validate="'required|min:3|max:250'"
          />
        </form-input>
      </div>
      <div class="custom-modal-footer row-between">
        <button
            class="add-button"
            @click="$emit('close')"
        >
          Cancel
        </button>
        <button
            class="primary-button"
            @click="createTemplate"
            :disabled="requestIsGoing"
        >
          Create
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {FormInput} from '@/ui';
import Multiselect from 'vue-multiselect';
import api from '@/headers.js';

export default {
  name: 'CreateNewTemplateModal',
  components: {
    FormInput,
    Multiselect
  },
  data() {
    return {
      measurementTypes: [],
      newTemplateType: null,
      newTemplateName: '',
      requestIsGoing: false,
      trade: null
    }
  },
  props: {
    tradesOptions: {
      type: Array,
      required: true
    }
  },
  computed: {
    userId() {
      return this.$store.getters['user/getUserId'];
    }
  },
  methods: {
    getMeasurementTypes() {
      api.get('/measurements/types/')
          .then(response => {
            this.measurementTypes = response.data;
          })
          .catch((error) => {
            this.notifyRequestErrors(error);
          });
    },
    setNewTemplateName(data) {
      this.newTemplateName = data.name + ' Template';
    },
    createTemplate() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.requestIsGoing = true;

          let newTemplate = {
            name: this.newTemplateName,
            type_id: this.newTemplateType.id,
            description: '',
            profit: 0,
            use_in: 1,
            trades: [this.trade.id],
            user_id: this.userId
          };

          api.post('/templates', newTemplate)
              .then(response => response.data.data)
              .then(data => {
                this.notifySuccess(`"${data.name}" has been created!`);

                this.$router.push({
                  name: 'TemplateEdit',
                  params: {template_id: data.id}
                });
              })
              .catch(error => {
                const errorMessage = Object.keys(error.response.data.meta.error_description).length
                    ? error.response.data.meta.error_description.name[0]
                    : error.response.data.meta.error_message;

                this.notifyError(errorMessage);
              })
              .finally(() => {
                this.requestIsGoing = false;
              });
        } else {
          this.notifyError('All fields are required.');
        }
      });
    }
  },
  created() {
    this.getMeasurementTypes();
  }
}
</script>