<template>
    <full-modal
        @close="$emit('close')"
        class="send-new-email-modal"
        :full="true"
    >
        <div slot="title">New Template</div>
        <div slot="body">
            <div class="column-start mb-8 relative">
                <div class="s-align-1 w-100 f-13-black f-sbold no-wrap subject-wrapper bb-grey">
                    <span :class="['mt-8', {'f-13-red' : errors.has('name') }]">
                        Name:
                    </span>
                    <div class="w-90 ml-4">
                        <input
                            type="text"
                            name="name"
                            class="form-input-transparent w-100"
                            v-model="name"
                            v-validate="'required'"
                        />
                    </div>
                </div>
                <div class="s-align-1 w-100 f-13-black f-sbold no-wrap subject-wrapper bb-grey">
                    <span :class="['mt-8', {'f-13-red' : errors.has('subject') }]">
                        Subject:
                    </span>
                    <div class="w-90 ml-4">
                        <input
                            type="text"
                            name="subject"
                            class="form-input-transparent w-100"
                            v-model="subject"
                            v-validate="'required'"
                        />
                    </div>
                </div>
            </div>
            <div class="relative">
                <text-editor
                    placeholder="Description"
                    v-model="description"
                    toolbarName="notesToolbar"
                />
                <button
                    class="primary-button send-new-email-button"
                    @click="sendTemplate"
                    :disabled="loading"
                >
                    <send-icon
                        class="mr-1"
                        size="15"
                    />
                    Save
                </button>
                <button
                    class="transparent-button send-attachment-button"
                    @click="addAttachment()"
                >
                    <attach-icon
                        class="i-grey"
                        size="16"
                    />
                </button>
                <input
                    type="file"
                    name="file_upload"
                    ref="uploadInput"
                    @change="fileInputHandler"
                    hidden
                />
            </div>
            <div class="row-start mt-8">
                <div
                    v-if="attachmentsList.length"
                    v-for="(attachment, index) in attachmentsList"
                    :key="index"
                    class="s-align-1 mb-4 mr-8"
                >
                    <div class="s-align-4">
                        <div class="attachment-preview s-align-5">

                            <span class="f-13-grey f-sbold">
                                .{{ getFileType(attachment.Name) }}
                            </span>
                        </div>
                        <div class="attachment-name max-w-80 column-start ml-4">
                            <span class="f-14-black break-all f-sbold">
                                {{ attachment.Name }}
                            </span>
                        </div>
                    </div>
                    <div class="ml-1">
                        <button
                            class="transparent-button p-1"
                            @click="removeAttachment(index)"
                        >
                            <close-icon
                                class="i-red"
                                size="5"
                            />
                        </button>
                    </div>
                </div>
            </div>
            <div
                v-if="loading"
                class="column-center s-align-5 w-100 mb-8"
            >
                <loader size="mini"/>
            </div>
        </div>
    </full-modal>
</template>

<script>
    import api from '@/headers.js';
    import { FullModal, FormInput } from '@/ui';
    import { SendIcon, AttachIcon, CloseIcon } from '@/icons';
    import TextEditor from '@/components/TextEditor.vue';
    import { Loader } from '@/ui';
    import { KEY_TEMPLATE, MAX_SIZE_FILES } from '@/constants';

    export default {
        name: 'FormEmailTemplateModal',
        components: {
            Loader,
            FullModal,
            FormInput,
            TextEditor,
            SendIcon,
            AttachIcon,
            CloseIcon
        },
        props: {
            existing: {
                type: Object,
                default: null
            },
            defaultSubject: {
                type: String,
                required: false
            },
            defaultSendTo: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                sendChips: [],
                copyChips: [],
                blindChips: [],
                subject: '',
                description: '',
                loading: false,
                attachmentsList: [],
                sendingAttachments: [],
                name: ''
            }
        },
        computed: {
            userSignature() {
                return this.$store.getters['user/getUserSignature'];
            }
        },
        methods: {
            addAttachment() {
                this.$refs.uploadInput.click();
            },
            removeAttachment(index) {
                this.attachmentsList.splice(index, 1);
                this.sendingAttachments.splice(index, 1);
            },
            getFileType(name) {
                if (name) {
                    let fileType = name.split('.');
                    return fileType[fileType.length - 1];
                }
            },
            generateRandAlphaNumStr(len) {
                let rdmString = "";
                while (rdmString.length < len) {
                    rdmString  += Math.random().toString(36).substr(2);
                }
                return  rdmString.substr(0, len);
            },
            fileInputHandler(event) {
                if (!this.$refs.uploadInput.files[0]) return false;
                let file = event.target.files[0];

                if (MAX_SIZE_FILES.FILE_EMAIL_MESSSAGE < file.size) {
                    this.notifyError('Maximum size of the file can be 30.0MB');
                    return;
                }

                var reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = () => {
                    let attachment = {
                        Name: file.name,
                        ContentBytes: btoa(reader.result),
                        contentType: file.type
                    }
                    this.attachmentsList.push(attachment);
                };
            },
            updateTemplate() {
                this.loading = true;
                this.$validator.validateAll().then(() => {
                    if (this.$validator.errors.items.length || !this.description.length) {
                        this.notifyError('Fill all required fields!');
                        this.loading = false;
                        return;
                    }

                    const redirectUrl = window.location.protocol + "//" + window.location.host;
                    let params = {
                        subject: this.subject,
                        title: this.name,
                        description: this.description,
                        attachments: this.attachmentsList,
                        redirect_url: redirectUrl
                    };

                    api.put(`/message-template/${this.existing.id}`, params)
                        .then(() => {
                            this.notifySuccess('Template is save');
                            this.$emit('onSend')
                            this.$emit('close');
                        })
                        .catch(error => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                });
            },
            sendTemplate() {
                if (this.existing) {
                    this.updateTemplate();
                    return;
                }
                this.loading = true;

                this.$validator.validateAll().then(() => {
                    if (this.$validator.errors.items.length || !this.description.length) {
                        this.notifyError('Fill all required fields!');
                        this.loading = false;
                        return;
                    }

                    const redirectUrl = window.location.protocol + "//" + window.location.host;
                    let params = {
                        key: KEY_TEMPLATE.EMAIL_TEMPLATE,
                        subject: this.subject,
                        title: this.name,
                        description: this.description,
                        attachments: this.attachmentsList,
                        redirect_url: redirectUrl,
                        created_at: new Date()
                    };

                    api.post('/message-template', params)
                        .then(() => {
                            this.notifySuccess('Template is save');
                            this.$emit('onSend')
                            this.$emit('close');
                        })
                        .catch(error => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                });
            }
        },
        created() {
            if (this.existing) {
                this.subject = this.existing.subject;
                this.name = this.existing.title;
                this.description = this.existing.description;
                this.attachmentsList = this.existing.attachments;
            }

            if (this.defaultSendTo) {
                this.sendChips.push(this.defaultSendTo);
            }

            if (this.defaultSubject) {
                this.subject = this.defaultSubject;
            }
        }
    }
</script>