<template>
    <svg
        viewBox="-446 239.5 25 32"
        :width="width" 
        :height="height"
        class="smart-doc-preview-icon"
    >
        <path class="prefix__st0" d="M-436.7 267.4c.1-.4-.1-.8-.4-1-.5-.3-1-.9-1.1-1.6v-.1c-.1-.7-.1-1.5 0-2.3.1-.8.5-1.4 1.1-1.8.3-.2.5-.6.4-.9 0-.1-.1-.3-.1-.5 0-.6.2-1.3.6-1.7.3-.4.6-.7.9-1l.4-.4c.5-.5 1.2-.7 1.9-.6.1 0 .2 0 .3.1.4.1.8 0 1-.4.4-.6 1-1 1.7-1.1.7-.1 1.4-.1 2.1 0 .7.1 1.4.5 1.8 1.1.2.3.6.5 1 .4.5-.1 1-.1 1.5.2v-7c0-.8-.3-1.6-.9-2.1l-6.7-6.4c-.5-.5-1.2-.8-2-.8h-10.1c-1.4 0-2.6 1.2-2.6 2.6v24.3c0 1.4 1.2 2.6 2.6 2.6h6.8c-.3-.5-.4-1.1-.2-1.6z"/>
        <path class="prefix__st0" d="M-432.7 248.7h9c0-.8-.3-1.5-.9-2l-6.7-6.4c-.5-.5-1.2-.8-1.9-.8v8.7c0 .3.2.5.5.5z"/>
        <path d="M-432.7 248.7h9c0-.8-.3-1.5-.9-2l-6.7-6.4c-.5-.5-1.2-.8-1.9-.8v8.7c0 .3.2.5.5.5z" opacity=".3" fill="#fff"/>
        <path class="prefix__st0" d="M-421.1 262.7c0-.6-.4-.9-1.1-.9-.3 0-.6-.1-.8-.4-.2-.2-.3-.5-.3-.8 0-.2.1-.5.4-.7.4-.4.4-1 .1-1.4-.3-.4-.8-.9-1.3-1.3-.4-.4-1-.3-1.4.1-.2.3-.7.5-1.2.3-.3-.2-.6-.6-.5-1.1 0-.5-.4-1-.9-1-.6-.1-1.3-.1-1.8 0-.5 0-.9.5-.9 1 0 .4-.2.8-.6 1-.5.1-.9 0-1.2-.3-.2-.2-.4-.3-.7-.3-.3 0-.5 0-.7.2l-.4.4c-.3.3-.6.6-.8.9-.2.2-.3.5-.3.7 0 .3.2.5.4.7.3.4.4.8.2 1.2-.2.4-.5.6-1 .6s-1 .4-1 .9c-.1.7-.1 1.3 0 1.9.1.5.5.9 1.1.9.5 0 1 .4 1.1 1 0 .3-.1.6-.4.8-.4.4-.4 1-.1 1.4l.4.4c.3.3.5.6.9.9.2.2.4.3.7.2.2 0 .5-.2.7-.3.2-.3.7-.5 1.2-.3.4.2.6.6.6 1 0 .5.4 1 .9 1h2c.1 0 .1 0 .2-.1.2-.1.4-.5.4-.9s.2-.8.6-1c.3-.2.9 0 1.2.3.4.4 1 .4 1.4.1l1.3-1.3c.4-.4.3-1-.1-1.4-.2-.2-.3-.4-.3-.7 0-.3.1-.6.3-.7.2-.2.5-.4.7-.4h.1c.5.1 1-.4 1.1-.9-.1-.5-.1-1.2-.2-1.7zm-3.4 3.7c0 .5.2 1.1.6 1.5-.2.3-.5.6-.8.8-.7-.6-1.5-.8-2.4-.5-.8.3-1.4 1.1-1.4 2h-1.2c-.1-.9-.6-1.7-1.4-2-.8-.3-1.7-.1-2.4.5-.3-.3-.6-.5-.8-.8.6-.7.8-1.6.5-2.4-.3-.8-1.1-1.3-1.9-1.4-.1-.4-.1-.8 0-1.3.9-.1 1.7-.6 2-1.4.3-.9.1-1.7-.5-2.4l.6-.6.2-.2c.7.6 1.5.8 2.4.5.9-.3 1.4-1.2 1.4-2 .4-.1.8-.1 1.2 0 .1.9.6 1.7 1.4 2 .8.3 1.7.1 2.4-.5.3.3.6.5.8.8-.5.5-.7 1-.7 1.6 0 1.2.9 2.2 2.1 2.3.1.4.1.8.1 1.2-1.2 0-2.2 1.1-2.2 2.3z"/>
        <path class="prefix__st0" d="M-429 260.4c-1.7 0-3.1 1.5-3.1 3.2 0 1.7 1.4 3.2 3.1 3.2 1.7 0 3.1-1.4 3.1-3.2 0-1.7-1.5-3.2-3.1-3.2zm-.5 1.3c.1 0 .3-.1.4-.1.1 0 .3 0 .4.1.5.1 1 .4 1.2.8.3.4.4.9.3 1.4-.2.8-.8 1.4-1.6 1.6-1 .2-2-.5-2.3-1.6-.1-.5-.1-.9.2-1.4.4-.4.9-.7 1.4-.8z"/>
    </svg>
</template>

<script>
    export default {
        name: 'SmartDocPreviewIcon',
        props: {
            width: {
                type: [Number, String],
                default: 23
            },
            height: {
                type: [Number, String],
                default: 32
            }
        }
    }
</script>