<template>
    <div class="row-start">
        <div class="labor-delivery-details lg-4 sm-12 column-start mb-20">
            <p class="f-20-darkgrey f-sbold mb-20">Labor Delivery Details</p>
            <div class="w-100 s-align-5 pb-20 bb-grey">
                <v-calendar
                    :key="isWeekStartOnMonday"
                    ref="calendarPlugin"
                    :tintColor="tintColor"
                    :attributes="attributes"
                    :disabledAttribute="disabledAttribute"
                    :themeStyles="themeStyles"
                />
            </div>
            <div class="column-center w-100 pt-30 ">
                <div class="material-delivery-date row-between row-baseline">
                    <div class="date s-align-5 column-center">
                        <span class="f-10-white">
                            {{ order.delivery_date | monthAbbreviation }}
                        </span>
                        <span class="f-14-white f-bold">
                            {{ order.delivery_date | day(false) }}
                        </span>
                    </div>
                    <span class="description column-start">
                        Material delivery date
                        <span class="f-12-black f-normal">
                            Time: {{ order.delivery_time }}
                        </span>
                        <span class="f-12-black f-normal">
                            Method:
                            {{
                                order.receiving === 'Pickup'
                                ? 'Pickup'
                                : order.delivery_method
                            }}
                        </span>
                    </span>
                    <button
                        class="transparent-button p-1"
                        @click="$emit('goToStep', 5)"
                    >
                        <edit-icon/>
                    </button>
                </div>
            </div>
        </div>
        <div class="labor-delivery-availability lg-8 sm-12 column-start">
            <div
                v-if="(differentLaborsPriceLength || laborsNotInPriceListLength) && !loading && selectedCrew"
                class="review-labor-box row-start row-baseline p-4 mb-25"
            >
                <div class="lg-9 sm-7 s-align-1">
                    <round-warning-icon
                        size="20"
                        class="i-yellow mr-4"
                    />
                    <p class="f-12-darkgrey preserve-whitespaces">{{
                        differentLaborsPriceLength ? laborsNotInPriceListLength
                            ? `1. Labor price differs from master price list
                               2. Some of the labor is not on the crew labor price list`
                            : 'Labor price differs from master price list'
                            : 'Some of the labor is not on the crew labor price list'
                    }}</p>
                    <!-- ↑↑↑ don't format code, needed to correct work css property!  -->
                </div>
                <div class="lg-3 sm-5 s-align-6">
                    <button
                        class="primary-button"
                        @click="reviewOrderLaborModalShown = true"
                    >
                        <eye-icon class="mr-2"/>
                        Review labor
                    </button>
                </div>
            </div>
            <p class="f-16-darkgrey f-sbold mb-3">Select a crew to check availability</p>
            <div
                v-if="loading"
                class="w-100 s-align-5 pv-25 mb-1"
            >
                <loading class="i-blue"/>
            </div>
            <multiselect
                v-show="!loading"
                class="mb-30"
                :options="crews"
                name="crew"
                data-name="crew"
                label="name"
                track-by="id"
                v-model="selectedCrew"
                :max-height="200"
                placeholder="Crew"
                :show-labels="false"
                :searchable="true"
                @select="checkCrewPrice"
                @remove="deselectCrew"
            />
            <div class="row-start row-baseline-end mb-25">
                <div class="lg-6 sm-12 column-start pr-4 p-sm-0 mb-sm-1">
                    <p class="f-16-darkgrey f-sbold mb-3">Select a delivery date and time</p>
                    <form-date-range-input
                        :isFullWidth="true"
                        :ignore-t-z="true"
                        v-model="deliveryDate"
                        :class="{ 'form-date-range-input-error' : errors.has('labor_delivery_date')}"
                        name="labor_delivery_date"
                        v-validate.continues="'requiredArrayIfCrewSelected'"
                    />
                </div>
                <div class="lg-6 sm-12 column-start pl-4 p-sm-0">
                    <div class="row-between mb-5">
                        <checkbox-button
                            color="violet"
                            label="All day working"
                            class="w-50"
                        >
                            <input
                                @click="deleteTimeWorking"
                                type="checkbox"
                                name="all_day_working"
                                v-model="order.labor_all_day_working"
                            />
                        </checkbox-button>
                        <button
                            v-if="isTimeOrDateSelected"
                            class="transparent-button"
                            @click="clearTimeAndDate"
                        >
                            <close-icon
                                size="9"
                                class="i-red mr-1"
                            />
                            <span class="f-12-darkgrey">Clear</span>
                        </button>
                    </div>
                    <form-time-input
                        :isFullWidth="true"
                        v-model="deliveryTime"
                        :class="{ 'form-time-input-error' : errors.has('labor_delivery_time')}"
                        name="labor_delivery_time"
                        v-validate.continues="order.labor_all_day_working ? null : 'requiredObjectIfCrewSelected'"
                        :disabled="order.labor_all_day_working"
                    />
                </div>
            </div>
            <div class="w-100">
                <p class="f-16-darkgrey f-sbold mb-3">Select a QC and date</p>
                <div class="row-start">
                    <div class="lg-6 sm-12 pr-4 p-sm-0 mb-sm-1">
                        <multiselect
                            v-model="assigned"
                            placeholder="Assign to"
                            track-by="fullname"
                            name="assign_to"
                            data-name="assign_to"
                            :options="users"
                            multiple
                            :option-height="104"
                            :show-labels="false"
                            :allow-empty="true"
                            label="fullname"
                            @select="getQcIds"
                            @remove="getQcIds"
                        >
                            <template
                                slot="singleLabel"
                                slot-scope="props"
                            >
                                <template v-if="props.option.first_name">
                                    <user-box
                                        :firstName="props.option.first_name"
                                        :lastName="props.option.last_name"
                                        :avatarUrl="props.option.avatar"
                                        :border="false"
                                    />
                                </template>
                                <template v-else>
                                    <p class="f-14-grey">Assign to...</p>
                                </template>
                            </template>
                            <template
                                slot="option"
                                slot-scope="props"
                            >
                                <user-box
                                    :firstName="props.option.first_name"
                                    :lastName="props.option.last_name"
                                    :avatarUrl="props.option.avatar"
                                    :border="false"
                                />
                            </template>
                        </multiselect>
                    </div>
                    <div class="lg-6 sm-12 pl-4 p-sm-0">
                        <form-date-range-input
                            :isFullWidth="true"
                            :ignore-t-z="true"
                            v-model="qcDate"
                            :class="{ 'form-date-range-input-error' : errors.has('labor_qc_date')}"
                            name="labor_qc_date"
                        />
                    </div>
                </div>
            </div>
        </div>
        <review-order-labor-modal
            v-if="reviewOrderLaborModalShown"
            :laborsNotInPriceList="laborsNotInPriceList"
            :differentLaborsPrice="differentLaborsPrice"
            :crewId="selectedCrew.id"
            @onSuccess="onSuccessReview"
            @close="reviewOrderLaborModalShown = false"
        />
    </div>
</template>

<script>
    import { FormDateRangeInput, FormTimeInput, FormDateTimeInput, CheckboxButton } from '@/ui';
    import { EditIcon, Loading, RoundWarningIcon, EyeIcon, CloseIcon } from '@/icons';
    import UserBox from '@/components/User/UserBox.vue';
    import calendarConfig from '@/mixins/calendarConfig';
    import { ReviewOrderLaborModal } from '@/modals';
    import Multiselect from 'vue-multiselect';
    import { clearArray } from '@/helpers';
    import api from '@/headers.js';
    import { ENTITY_TYPES } from '@/constants.js';

    export default {
        name: 'OrderCreateStep6',
        components: {
            Multiselect,
            UserBox,
            FormDateRangeInput,
            FormTimeInput,
            FormDateTimeInput,
            CheckboxButton,
            EditIcon,
            Loading,
            RoundWarningIcon,
            EyeIcon,
            CloseIcon,
            ReviewOrderLaborModal
        },
        props: {
            entityType: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                loading: false,
                order: {
                    crew_id: null,
                    crew_name: '',
                    labor_delivery_date_from: null,
                    labor_delivery_date_to: null,
                    labor_delivery_time_from: null,
                    labor_delivery_time_to: null,
                    labor_all_day_working: false,
                    appointment_at: null,
                    appointment_until: null,
                    quality_control_ids: []
                },
                crews: [],
                selectedCrew: null,
                differentLaborsPrice: [],
                laborsNotInPriceList: [],
                reviewOrderLaborModalShown: false,
                requestIsGoing: false,
                assigned: [],
                users: [],
                qCAppointmentAt: null,
                assignedQCIds: []
            }
        },
        mixins: [calendarConfig],
        computed: {
            crewId() {
              switch (this.entityType) {
                  case ENTITY_TYPES.RESIDENTIAL_LEAD:
                      return this.lead.crew_id;
                  case ENTITY_TYPES.COMMERCIAL_JOB:
                      return this.job.crew_id;
              }
            },
            isWeekStartOnMonday() {
                return this.$store.getters['user/isWeekStartOnMonday']
            },
            differentLaborsPriceLength() {
                return this.differentLaborsPrice.length;
            },
            laborsNotInPriceListLength() {
                return this.laborsNotInPriceList.length;
            },
            deliveryDate: {
                get: function() {
                    return [this.order.labor_delivery_date_from, this.order.labor_delivery_date_to]
                },
                set: function(value) {
                    if (value[0]) { this.order.labor_delivery_date_from = value[0] }
                    if (value[1]) { this.order.labor_delivery_date_to = value[1] }
                }
            },
            qcDate: {
                get: function() {
                    return [this.order.appointment_at, this.order.appointment_until]
                },
                set: function(value) {
                    if (value[0]) { this.order.appointment_at = value[0] }
                    if (value[1]) { this.order.appointment_until = value[1] }
                }
            },
            deliveryTime: {
                get: function() {
                    return {
                        startTime: this.order.labor_delivery_time_from,
                        endTime: this.order.labor_delivery_time_to
                    }
                },
                set: function(value) {
                    if (value.startTime) { this.order.labor_delivery_time_from = value.startTime }
                    if (value.endTime) { this.order.labor_delivery_time_to = value.endTime }
                }
            },
            isTimeOrDateSelected() {
                return this.timeOrDateSelected(this.deliveryTime, this.deliveryDate);
            },
            lead() {
                return this.$store.getters['lead/getLeadPrimaryInformation'];
            },
            job() {
                return this.$store.getters['commercial/getCommercialJob'];
            },
        },
        watch: {
            order: {
                deep: true,
                handler: function() {
                    this.$store.commit('orders/STORE_ORDER', this.order);
                }
            }
        },
        methods: {
            deleteTimeWorking() {
                this.order.labor_delivery_time_from = null;
                this.order.labor_delivery_time_to = null;
            },
            // validation is calling in parent component, need to return promise with result
            validateFields() {
                return this.$validator.validateAll()
                    .then(result => {
                        if (result) {
                            return Promise.resolve(
                                !this.differentLaborsPriceLength
                                && !this.laborsNotInPriceListLength
                                && !this.loading
                            );
                        }
                    });
            },
            clearTimeAndDate() {
                this.order.labor_delivery_date_from = null;
                this.order.labor_delivery_date_to   = null;
                this.order.labor_delivery_time_from = null;
                this.order.labor_delivery_time_to   = null;
            },
            getCrews() {
                this.loading = true;
                api.get('/crews?perPage=999')
                    .then(response => {
                        this.crews.push(...response.data.data.data);
                        if (this.crewId) {
                            this.selectedCrew = this.crews.find(el => el.id == this.crewId);
                            this.checkCrewPrice(this.selectedCrew);
                        } else if (this.order.crew_id) {
                            this.selectedCrew = this.crews.find(el => el.id == this.order.crew_id);
                            this.checkCrewPrice(this.selectedCrew);
                        }
                    })
                    .catch(error => {
                        this.notifyRequestError(error);
                    })
                    .finally(() => this.loading = false);
            },
            deselectCrew() {
                this.order.crew_id = null;
                this.order.crew_name = '';
            },
            checkCrewPrice(crew) {
                this.order.crew_id = crew.id;
                this.order.crew_name = crew.name;
                this.loading = true;

                api.get(`/orders/${this.order.id}/check-crew/${crew.id}`)
                    .then(response => response.data.data)
                    .then(data => {
                        if (data.error) {
                            this.$emit('crewError', data.error)
                        }
                        this.differentLaborsPrice.splice(0, this.differentLaborsPriceLength, ...data.crew);
                        this.laborsNotInPriceList.splice(0, this.laborsNotInPriceListLength, ...data.order);
                    })
                    .catch(error => {
                        this.notifyRequestError(error);
                    })
                    .finally(() => { this.loading = false });
            },
            onSuccessReview() {
                this.$emit('crewError', false)
                clearArray(this.differentLaborsPrice);
                clearArray(this.laborsNotInPriceList);
                this.reviewOrderLaborModalShown = false;

                api.get(`/orders/${this.$route.params.order_id}`)
                    .then(response => response.data.data)
                    .then(data => {
                        let items = data.items;
                        this.$store.commit('orders/STORE_ORDER', items);
                    });
            },
            timeOrDateSelected(time, date) {
                const someTimeSelected = Object.keys(time).some(key => time[key]);
                const someDateSelected = date.some(v => v);
                return someTimeSelected || someDateSelected;
            },
            registerCustomValidationRules() {
                // create validation rule for date & time input

                // check if all items in date array != null
                this.$validator.extend('requiredArrayIfCrewSelected', (value, field) => {
                    const someSelected = this.timeOrDateSelected(this.deliveryTime, value);
                    const everyDateSelected = value.every(v => v);

                    return !!(!this.selectedCrew && (!someSelected || someSelected && everyDateSelected)
                        || this.selectedCrew && everyDateSelected);
                });

                // check if all items in time object != null
                this.$validator.extend('requiredObjectIfCrewSelected', (value, field) => {
                    const someSelected = this.timeOrDateSelected(value, this.deliveryDate);
                    const everyTimeSelected = Object.keys(value).every(key => value[key]);

                    return !!(!this.selectedCrew && (!someSelected || someSelected && everyTimeSelected)
                        || this.selectedCrew && everyTimeSelected);
                });
            },
            getUsers() {
                this.requestIsGoing = true;
                const params = {
                    perPage: 999,
                    is_active: 1
                };

                this.$store.dispatch('users/USERS_GET', params)
                    .then(data => {
                        this.$store.commit('users/USERS_RESET', data.data);
                        this.users = data.data;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                        
                        if (this.order.quality_controls && this.order.quality_controls.length) {
                            this.assigned = this.order.quality_controls.map(qc => {
                                return {
                                    ...qc,
                                    fullname: `${qc.first_name} ${qc.last_name}`
                                }
                            });

                            this.order.quality_control_ids = this.assigned && this.assigned.length
                                ? this.assigned.map(el => el.id) : [];
                        }
                    });
            },
            // saveQcForOrder(params) {
            //     if (this.assignedQCIds && this.assignedQCIds.length && this.qCAppointmentAt) {
            //         api.put(`/orders/${this.order.id}`, params)
            //             .then(() => {
            //                 this.$store.commit('orders/STORE_ORDER', {
            //                     quality_controls: this.assigned,
            //                     ...params
            //                 });
            //             })
            //             .catch((error) => {
            //                 this.notifyRequestErrors(error);
            //             });
            //     }
            // },
            async getQcIds() {
                await this.$nextTick();
                this.order.quality_control_ids = this.assigned && this.assigned.length
                    ? this.assigned.map(el => el.id) : [];
            },
        },
        created() {
            this.registerCustomValidationRules();
            this.getCrews();

            // load order from store
            Object.assign(this.order, this.$store.getters['orders/getOrder']);

            this.qCAppointmentAt = this.order.appointment_at;
            this.getUsers();
        }
    }
</script>
