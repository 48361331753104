import { 
    ADD_DOCUMENT, 
    REMOVE_DOCUMENT, 
    REMOVE_ALL_DOCUMENTS,
    SET_DRAG_SOURCE,
    SET_FOLDER_LIST,
    UPDATE_FOLDER_LIST,
    SET_DOCUMENT_OVER_ID,
    SET_FOLRED_OVER_ID
} from '../actions/companyDocuments';

export default {
    namespaced: true,
    state: {
        selectedDocuments: [],
        dragSource: '',
        folderList: [],
        documentOverId: '',
        folderOverId: ''
    },
    getters: {
        getSelectedDocuments: state => state.selectedDocuments,
        getDragSource: state => state.dragSource,
        getFolderList: state => state.folderList,
        getDocumentOverId: state => state.documentOverId,
        getFolderOverId: state => state.folderOverId
    },
    mutations: {
        [ADD_DOCUMENT] (state, payload) {
            state.selectedDocuments.push(payload);
        },
        [REMOVE_DOCUMENT] (state, payload) {
            let index = state.selectedDocuments.findIndex(d => d.id === payload);                
            state.selectedDocuments.splice(index, 1);
        },
        [REMOVE_ALL_DOCUMENTS] (state, payload) {
            state.selectedDocuments.splice(0, state.selectedDocuments.length);
        },
        [SET_DRAG_SOURCE] (state, payload) {
            state.dragSource = payload;
        },
        [SET_FOLDER_LIST] (state, payload) {
            state.folderList = payload;
        },
        [UPDATE_FOLDER_LIST] (state, payload) {
            const current = state.folderList.find(f => f.name === payload.oldName);
            current.name = payload.folder.original_name;
            current.path = payload.folder.id;
        },
        [SET_DOCUMENT_OVER_ID] (state, payload) {
            state.documentOverId = payload;
        },
        [SET_FOLRED_OVER_ID] (state, payload) {
            state.folderOverId = payload;
        }
    }
}