<template>
    <full-modal 
        @close="$emit('close')"
        class="view-log-modal"
        :full="true"
    >
        <div slot="title">Log View</div>
        <div 
            slot="body"
            v-if="loading"
        >
            <Loader size="small"/>
        </div>
        <div 
            slot="body"
            class="mb-30"
            v-else
        >
            <div class="row-between mb-4">
                <div class="f-12-grey s-align-5">
                    Changes:
                    <span class="f-12-black ml-1">
                        {{ logs.length }}
                    </span>
                </div>
                <div class="s-align-6">
                    <dropdown
                        name="select_status"
                        :options="filter"
                        :bydefault="selectedFilter.id"
                        width="160"
                        return="object"
                        @select="onStatusSelect"
                    />
                    <button 
                        class="service-secondary-button ml-2"
                        @click="changeSortOrder"
                    >
                        <filter-icon :transform="order == 'asc' ? 180 : 0"/>
                    </button>
                </div>
            </div>
            <div 
                v-for="log in logs" 
                :key="log.id" 
                class="column-start bb-grey pv-4"
            >
                <p class="f-14-black">
                   {{ log.log }}
                </p>
                <p class="f-12-grey">
                    by <span class="f-12-black ph-1">{{ log.user.fullname }}</span>,
                    {{ log.created_at | createdAt}}
                </p>
            </div>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, Dropdown, Loader } from '@/ui';
    import { FilterIcon } from '@/icons';
    import api from '@/headers.js';

    export default {
        name: 'ViewLogModal',
        components: {
            FullModal,
            Dropdown,
            Loader,
            FilterIcon
        },
        props: {
            type:{
                type: String,
                required: true
            },
            id: {
                type: [Number, String],
                required: true
            }
        },
        data() {
            return {
                logs: null,
                loading: true,
                filter: [
                    { id: 0, name: 'Action date', value: 'date' },
                    { id: 1, name: 'Changed by', value: 'changedBy' }
                ],
                selectedFilter: {},
                order: 'asc'
            }
        },
        methods: {
            getWorksheetLogs() {
                api.get(`worksheet/logs/${this.id}`)
                    .then(response => {
                        this.logs = response.data.data;
                        this.loading = false;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
            },
            onStatusSelect(data) {
                this.selectedFilter = data;
                if (data.value == 'changedBy') {
                    this.logs.sort((a,b) => a.user.fullname !== b.user.fullname ? a.user.fullname < b.user.fullname ? -1 : 1 : 0);
                } else {
                    this.logs.sort((a,b) => a.created_at !== b.created_at ? a.created_at < b.created_at ? -1 : 1 : 0);
                }
            },
            changeSortOrder() {
                this.order = this.order == 'asc' ? 'desc' : 'asc';
                this.logs.reverse();
            }
        },
        created() {
            if (this.type == 'worksheet') {
                this.getWorksheetLogs();
            }
            this.selectedFilter = this.filter[0];
        }
    }
</script>