<template>
    <svg
        viewBox="0 0 20 20"
        :width="size"
        :height="size"
        class="url-icon"
    >
        <g transform="matrix(0.27967092394828796, 0, 0, 0.27967092394828796, 116.17389678955078, -65.1109848022461)">
            <g>
                <g>
                    <g>
                        <path d="M-364.7,301.5c-4.9,0-9.8-1.9-13.6-5.6l-10-10l4.2-4.2l10,10c5.1,5.1,13.5,5.1,18.6,0c5.1-5.1,5.1-13.5,0-18.6l-10-10l4.2-4.2l10,10c7.5,7.5,7.5,19.7,0,27.1C-354.9,299.6-359.8,301.5-364.7,301.5z"/>
                    </g>
                    <g>
                        <path d="M-396.9,277.2l-10-10c-7.5-7.5-7.5-19.7,0-27.1c7.5-7.4,19.7-7.5,27.1,0l10,10l-4.2,4.3l-10-10c-2.5-2.5-5.8-3.9-9.3-3.9s-6.8,1.4-9.3,3.9c-5.1,5.1-5.1,13.5,0,18.6l10,10L-396.9,277.2z"/>
                    </g>
                </g>
                <g>
                    <path 
                        d="M -391.100006 265 H -366.900005 V 271 H -391.100006 V 265 Z" 
                        transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 -836.4997 189.5097)"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'UrlIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>
