<template>
    <perfect-scrollbar class="commercial-list-details ps-grey w-100">
        <div 
            class="w-100" 
            v-if="loading"
        >
            <loader/>
        </div>
        <div 
            class="w-100" 
            v-else
        >
            <div class="s-align-4 w-100 pb-4">
                <clock-icon 
                    class="i-grey mr-2"
                    size="12"
                />
                <span class="f-11-grey">{{ customer.created_at | timeAgo }}</span>
            </div>
            <div class="column-start w-100">
                <h2 class="f-20-darkgrey f-sbold pb-4">
                    <router-link
                        :to="{ 
                            name: 'CommercialDetails', 
                            params: { customer_id: customer.id }
                        }"
                    >
                        {{ customer.name }}
                    </router-link>
                </h2>
                <div class="row-start pb-1">
                    <div :class="['md-12', addressIsCorrect ? 'lg-8 nb-7' : 'lg-12 nb-12']">
                        <span class="f-12-grey">
                            Address:
                            <a
                                :href="getGoogleMapLink(customer.addresses.main[0].lat, customer.addresses.main[0].lng)"
                                :class="[
                                    customer.addresses.main.length ? 'f-12-blue' : 'f-italic',
                                    {'none-events': !getGoogleMapLink(customer.addresses.main[0].lat, customer.addresses.main[0].lng)}
                                ]"
                                target="_blank"
                            >
                                {{
                                    customer.addresses.main.length 
                                    ? getAddress(customer.addresses.main[0]) 
                                    : 'No information'
                                }}
                                <link-icon 
                                    v-if="getGoogleMapLink(customer.addresses.main[0].lat, customer.addresses.main[0].lng)"
                                    class="i-blue"
                                />
                            </a>
                        </span>
                    </div>
                    <div 
                        class="s-align-3 lg-4 nb-5 md-12 mt-md-1"
                        v-if="addressIsCorrect"
                    >
                        <button 
                            class="show-on-map"
                            @click="$emit(
                                'showMap', 
                                { 
                                    lat: +customer.addresses.main[0].lat, 
                                    lng: +customer.addresses.main[0].lng 
                                }
                            )"
                        >
                            <map-icon/>
                            Show on Map
                        </button>
                    </div>
                </div>
            </div>
            <div class="column-start pv-30 w-100 bb-grey">
                <h2 class="f-16-darkgrey f-sbold mb-8">Information</h2>
                <div 
                    class="mb-3"
                    v-if="customer.management_company"
                >
                    <span class="f-13-grey">Care of:</span>
                    <span class="f-13-black ml-1">{{ customer.management_company }}</span>
                </div>
                <div 
                    class="mb-3 row-start"
                    v-if="customer.phones && customer.phones.length"
                >
                    <span class="f-13-grey mr-1">Phone:</span>
                    <make-call-from-number-button
                        class="w-80"
                        :phone="customer.phones[0].number"
                        :showPhoneType="false"
                        :entityType="ENTITY_TYPES.COMMERCIAL_CUSTOMER"
                        :entityId="customerId"
                    />
                </div>
                <div 
                    class="mb-3"
                    v-if="customer.emails && customer.emails.length"
                >
                    <span class="f-13-grey">Email:</span>
                    <span class="f-13-black ml-1">{{ customer.emails[0].email }}</span>
                </div>
            </div>
            <representatives-details-list 
                title="Customer"
                :representatives="customer.representatives"
                :entityId="customer.id"
            />
            <div 
                class="column-start pv-30 w-100 bb-grey"
                v-if="customer.properties && propertiesLength"
            >
                <button 
                    class="transparent-button mb-8"
                    @click="propertiesIsOpen =! propertiesIsOpen"
                    :disabled="!propertiesActively"
                >
                    <arrow-icon 
                        class="i-blue mr-1"
                        :transform="propertiesIsOpen ? 270 : 90"
                        size="10"
                        v-if="propertiesActively"
                    />
                    <span class="f-16-darkgrey f-sbold pr-4">Property</span>
                    <span 
                        class="count-blue f-11-blue f-bold s-align-5"
                        v-if="propertiesActively"
                    >
                        {{ propertiesLength }}
                    </span>
                </button>
                <div 
                    class="lead-details-bordered w-100 column-start pv-5"
                    v-for="(property, index) in properties"
                    :key="`property-${index}`"
                >
                    <p class="f-13-grey">
                        Name: 
                        <span class="f-13-black pl-1">
                            <router-link
                                :to="{ 
                                    name: 'CommercialDetails', 
                                    params: { 
                                        customer_id: property.customer_id, 
                                        property_id: property.id 
                                    }
                                }"
                                class="black-link-underlined relative"
                            >
                                {{ property.property_name }}
                            </router-link>
                        </span> 
                    </p>
                    <p class="f-13-grey">
                        Address:
                        <a
                            :href="getGoogleMapLink(property.addresses.main[0].lat, property.addresses.main[0].lng)"
                            :class="[
                                property.addresses.main.length ? 'f-12-blue' : 'f-italic',
                                {'none-events': !getGoogleMapLink(property.addresses.main[0].lat, property.addresses.main[0].lng)}
                            ]"
                            target="_blank"
                        >
                            {{
                                property.addresses.main.length 
                                ? getAddress(property.addresses.main[0]) 
                                : 'No information'
                            }}
                            <link-icon 
                                v-if="getGoogleMapLink(property.addresses.main[0].lat, property.addresses.main[0].lng)"
                                class="i-blue"
                            />
                        </a>
                    </p>
                </div>
            </div>
            <div class="column-start pv-30 w-100">
                <h2 class="f-16-darkgrey f-sbold mb-8">Customer notes</h2>
                <p 
                    class="f-13-black break-all w-100"
                    v-if="customer.notes"
                >
                    {{ customer.notes }}
                </p>
                <p 
                    class="f-13-grey f-italic"
                    v-else
                >
                    No comments have been written in this Customer yet.
                </p>
            </div>
        </div>
    </perfect-scrollbar>
</template>

<script>
    import { ClockIcon, MapIcon, ArrowIcon, LinkIcon } from '@/icons';
    import { Loader } from '@/ui';
    import { showFullAddress, getGoogleMapLink } from '@/helpers';
    import { ENTITY_TYPES } from '@/constants';
    import RepresentativesDetailsList from '@/components/RepresentativesDetailsList.vue';
    import MakeCallFromNumberButton from '@/components/Telephony/MakeCallFromNumberButton.vue';
    import api from '@/headers.js';

    export default {
        name: 'CommercialCustomerListDetails',
        props: {
            customerId: {
                type: Number,
                default: 0
            }
        },
        components: {
            ClockIcon,
            Loader,
            MapIcon,
            ArrowIcon,
            LinkIcon,
            RepresentativesDetailsList ,
            MakeCallFromNumberButton   
        },
        data() {
            return {
                ENTITY_TYPES,
                loading: false,
                customer: {},
                propertiesIsOpen: false
            }
        },
        computed: {
            propertiesLength() {
                return this.customer.properties.length;
            },
            propertiesActively() {
                return this.propertiesLength > 3;
            },
            properties() {
                return this.propertiesIsOpen 
                    ? this.customer.properties
                    : this.customer.properties.slice(0, 3);
            },
            addressIsCorrect() {
                return this.customer.addresses.main.length
                    && this.customer.addresses.main[0].lat != null
                    && this.customer.addresses.main[0].lng != null;
            }
        },
        methods: {
            // Method imported from helpers.js
            getGoogleMapLink,
            getCustomer() {
                this.loading = true;

                api.get(`/commercial/customers/${this.customerId}`)
                    .then(response => {
                        this.customer = response.data.data;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);        
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            getAddress(customer) {
                return showFullAddress(customer);
            }
        },
        created() {
            this.getCustomer();
        }
    }
</script>