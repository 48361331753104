<template>
    <svg 
        viewBox="0 0 764.8831787109375 763.130859375"
        :width="size"
        :height="size"
        class="dollar-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="764.8831787109375" 
            height="763.130859375" 
            style="fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(7.840000152587891, 0, 0, 7.840000152587891, 2794.3508917093277, -1793.7227783203125)">
            <path d="M-307.7,326c26.8,0,48.5-21.7,48.5-48.5s-21.7-48.5-48.5-48.5c-26.8,0-48.5,21.7-48.5,48.5C-356.1,304.3-334.4,326-307.7,326z M-307.7,238.5c21.6,0,39,17.5,39,39s-17.5,39-39,39c-21.6,0-39-17.5-39-39C-346.7,256-329.2,238.5-307.7,238.5z"/>
            <path d="M-305.7,290.5c-3.7,0.6-7.9-1.8-10.4-6l-8.1,4.9c2.6,4.6,6.8,8.1,11.8,9.8v6.3h9.5v-5.8c5.7-1.4,11.7-5.9,12.4-12.2c1.2-10.9-7.3-13.7-10.1-14.7c-2.4-0.8-4.4-1.4-6.2-1.9c-4.7-1.3-5.6-1.7-6.6-3.2c-0.6-0.8-0.8-1.9-0.5-2.9c0.3-1.2,1-2.3,2-3c1.7-0.8,3.7-1,5.5-0.4c3,0.7,5.6,2.7,7.1,5.5l8.3-4.3c-2.5-4.7-6.7-8.3-11.8-9.9v-6.4h-9.5v5.7c-1.7,0.3-3.4,1-4.9,1.9c-3.1,2.1-5.3,5.3-6,9c-0.7,3.5,0,7.1,1.9,10c3,4.5,6.9,5.6,11.8,7.1c1.6,0.5,3.5,1,5.8,1.8c3.7,1.2,4,2,3.7,4.7C-300.1,288.1-302.9,290.1-305.7,290.5z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'DollarIcon',
        props: {
            size: {
                type: [Number, String],
                default: 17
            }
        }
    }
</script>