<template>
    <div :class="[
        'p-20 quality-control mb-20 column-start',
        assignedUsers && !assignedUsers.length ? 'bg-grey-1' : 'bg-white'
    ]">
        <div class="f-16-black f-sbold">
            Quality Control
        </div>
        <loader
            v-if="requestIsGoing"
            size="small"
            class="loader-small-padding"
        />
        <template v-if="assignedUsers && assignedUsers.length && !requestIsGoing">
            <div
                v-for="(user, index) in assignedUsers"
                :key="index"
            >
                <div class="row-start mt-8 s-align-4 flex-no-wrap p">
                    <avatar
                        :url="user.thumbnail"
                        :name="user.fullname"
                        :border="false"
                        class="mr-8"
                        size="50"
                    />
                    <div class="column-start">
                        <div class="f-16-darkgrey f-sbold mb-2">
                            {{ user.fullname }}
                        </div>
                        <div class="s-align-4">
                            <div class="flex-grow-1">
                                <phone-icon
                                    class="i-grey"
                                    size="14"
                                />
                            </div>
                            <div class="f-12-grey ml-4 break-all">
                                {{ user.phone }}
                            </div>
                        </div>
                        <div class="s-align-4">
                            <div class="flex-grow-1">
                                <envelope-icon
                                    class="i-grey flex-grow-1"
                                    size="14"
                                />
                            </div>
                            <div class="f-12-grey ml-4 break-all">
                                {{ user.email }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-start flex-no-wrap mt-8 s-align-4">
                <gear-icon
                    size="14"
                    class="i-grey mr-2"
                />
                <div
                    class="f-13-grey"
                    @click="isShowSetQualityControlModal = !isShowSetQualityControlModal"
                >
                    To change quality control click
                    <span class="f-13-blue pointer">here</span>
                </div>
            </div>
        </template>
        <template v-else-if="!requestIsGoing">
            <div class="f-13-black mt-8">
                Choose quality control for this job
            </div>
            <button
                class="primary-button mt-20 full-width-button"
                @click="isShowSetQualityControlModal = !isShowSetQualityControlModal"
            >
                Assign
            </button>
        </template>
        <keep-alive>
            <set-quality-control-modal
                v-if="isShowSetQualityControlModal"
                :entityType="entityType"
                :users="users"
                @close="isShowSetQualityControlModal = false"
            />
        </keep-alive>
    </div>
</template>
<script>
    import { BASE_ENTITY_TYPES } from '@/constants';
    import { EnvelopeIcon, PhoneIcon, GearIcon } from '@/icons';
    import Avatar from '@/components/User/Avatar.vue';
    import { Loader } from '@/ui';

    export default {
        name: 'QualityControl',
        components: {
            GearIcon,
            Loader,
            Avatar,
            PhoneIcon,
            EnvelopeIcon,
            SetQualityControlModal: () => import('@/modals/SetQualityControlModal.vue'),
        },
        props: {
            entityType: {
                type: String,
                default: ''
            }
        },
        computed: {
            lead() {
                return this.$store.getters['lead/getLeadPrimaryInformation'];
            },
            job() {
                return this.$store.getters['commercial/getCommercialJobPreview'];
            },
            warrantyJob() {
                return this.$store.getters['warranty/getWarrantyJobData'];
            },
            assignedUsers() {
                if (this.entityType == BASE_ENTITY_TYPES.RESIDENTIAL && this.lead.quality_controls) {
                    return this.lead.quality_controls;
                }

                if (this.entityType == BASE_ENTITY_TYPES.COMMERCIAL_JOB && this.job.quality_controls) {
                    return this.job.quality_controls;
                }

                if (this.entityType === BASE_ENTITY_TYPES.WARRANTY_JOB && this.warrantyJob.quality_controls) {
                    return this.warrantyJob.quality_controls;
                }
            }
        },
        data() {
            return {
                isShowSetQualityControlModal: false,
                BASE_ENTITY_TYPES,
                users: [],
                requestIsGoing: false
            }
        }
    }
</script>