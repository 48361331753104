<template>
    <svg
	    viewBox="0 0 14 14"
        :width="size" 
        :height="size"
        class="calculator-icon"
    >
        <g>
            <path d="M10.3,4.3H3.8c-0.2,0-0.4-0.2-0.4-0.4v0c0-0.2,0.2-0.4,0.4-0.4h6.5c0.2,0,0.4,0.2,0.4,0.4v0C10.7,4.1,10.5,4.3,10.3,4.3z"/>
            <path d="M4.3,7.3H3.8c-0.2,0-0.4-0.2-0.4-0.4v0c0-0.2,0.2-0.4,0.4-0.4h0.5c0.2,0,0.4,0.2,0.4,0.4v0C4.7,7.1,4.5,7.3,4.3,7.3z"/>
            <path d="M7.3,7.3H6.8c-0.2,0-0.4-0.2-0.4-0.4v0c0-0.2,0.2-0.4,0.4-0.4h0.5c0.2,0,0.4,0.2,0.4,0.4v0C7.7,7.1,7.5,7.3,7.3,7.3z"/>
            <path d="M10.3,7.3H9.8c-0.2,0-0.4-0.2-0.4-0.4v0c0-0.2,0.2-0.4,0.4-0.4h0.5c0.2,0,0.4,0.2,0.4,0.4v0C10.7,7.1,10.5,7.3,10.3,7.3z"/>
            <path d="M4.3,10.3H3.8c-0.2,0-0.4-0.2-0.4-0.4l0,0c0-0.2,0.2-0.4,0.4-0.4h0.5c0.2,0,0.4,0.2,0.4,0.4l0,0C4.7,10.1,4.5,10.3,4.3,10.3z"/>
            <path d="M7.3,10.3H6.8c-0.2,0-0.4-0.2-0.4-0.4l0,0c0-0.2,0.2-0.4,0.4-0.4h0.5c0.2,0,0.4,0.2,0.4,0.4l0,0C7.7,10.1,7.5,10.3,7.3,10.3z"/>
            <path d="M10.3,10.3H9.8c-0.2,0-0.4-0.2-0.4-0.4l0,0c0-0.2,0.2-0.4,0.4-0.4h0.5c0.2,0,0.4,0.2,0.4,0.4l0,0C10.7,10.1,10.5,10.3,10.3,10.3z"/>
            <path d="M11.9,0H2.1C1,0,0,0.9,0,2.1v9.8C0,13,0.9,14,2.1,14h9.8c1.1,0,2.1-0.9,2.1-2.1V2.1C14,0.9,13.1,0,11.9,0z M13.1,11.9c0,0.7-0.5,1.2-1.2,1.2H2.1c-0.7,0-1.2-0.5-1.2-1.2V2.1c0-0.7,0.5-1.2,1.2-1.2h9.8c0.7,0,1.2,0.5,1.2,1.2C13.1,2.1,13.1,11.9,13.1,11.9z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'CalculatorIcon',
        props: {
            size: {
                type: [Number, String],
                default: 14
            }
        }
    }
</script>
