<template>
    <svg 
        viewBox="-446 239.5 50 65"
        :width="width" 
        :height="height"
        class="image-preview-icon" 
    >
        <path class="st0" d="M-401.8,239.5h-15.7h-22.7c-3.2,0-5.8,2.6-5.8,5.8v53.4c0,3.2,2.6,5.8,5.8,5.8h22.7h15.7c3.2,0,5.8-2.6,5.8-5.8V284V260v-14.7C-396,242.1-398.6,239.5-401.8,239.5z"/>
        <g>
            <path class="st1" d="M-405,285.2c-0.4,0.6-1,1-1.8,1h-28.5c-1.1,0-2.1-0.9-2.1-2.1c0-0.4,0.1-0.7,0.3-1l7.5-12.7c0.6-1,1.8-1.3,2.8-0.7c0.2,0.1,0.3,0.2,0.5,0.4l3.3,3.6l5.9-7.6c0.7-0.9,2-1.1,2.9-0.4c0.3,0.2,0.5,0.5,0.6,0.7l8.6,16.7C-404.6,283.8-404.6,284.6-405,285.2z"/>
            <ellipse class="st1" cx="-426.6" cy="261" rx="3.2" ry="3.2"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'ImagePreviewIcon',
        props: {
            width: {
                type: [Number, String],
                default: 28
            },
            height: {
                type: [Number, String],
                default: 36
            }
        }
    }
</script>