<template>
    <div class="alert-button relative">
        <button
            :class="[
                's-align-5 alert-normal', 
                { 'alert-saved' : success, 'alert-error' : error }
            ]"
            :style="{ height: `${height}px`, width: `${width}px` }"
            :disabled="success || error"
        >
            <mark-icon
                size="10"
                class="i-white mr-2"
                v-if="success && !processing"
            />
            <span 
                class="f-13-white f-sbold"
                v-if="!processing"
            >
                {{ success ? 'Saved' : name }}
            </span>
            <loading 
                v-else
                class="i-white"
            />
        </button>
        <div 
            v-if="error"
            :class="[
                'alert-error-message', 
                { 
                    'alert-top-right' : direction == 'top' && side == 'right',
                    'alert-bottom-right' : direction == 'bottom' && side == 'right',
                    'alert-top-left' : direction == 'top' && side == 'left',
                    'alert-bottom-left' : direction == 'bottom' && side == 'left'
                }
            ]"
        >
            <div class="s-align-4 pb-4">
                <span class="alert-error-icon s-align-5 mr-4">
                    <close-icon 
                        class="i-white"
                        size="8"
                    />
                </span>
                <span class="f-13-red f-sbold">Error!</span>
            </div>
            <p class="f-12-black">{{ message }}</p>
        </div>
    </div>
</template>

<script>
    /*
    * name: button name
    *
    * processing: if request is in progress
    * 
    * success, error: statuses
    * (should be updated in the component where this button is used)
    * 
    * message: error message
    * 
    * direction: 'top' or 'bottom'
    * 
    * side: where the button is located ('right' or 'left')
    */

    import { MarkIcon, Loading, CloseIcon } from '@/icons';

    export default {
        name: 'AlertButton',
        props: {
            name: {
                type: String,
                default: 'Save'
            },
            height: {
                type: [String, Number],
                default: 43
            },
            width: {
                type: [String, Number],
                default: 130
            },
            processing: {
                type: Boolean,
                default: false
            },
            success: {
                type: Boolean,
                default: false
            },
            error: {
                type: Boolean,
                default: false
            },
            message: {
                type: String,
                default: ''
            },
            direction: {
                type: String,
                default: 'top'
            },
            side: {
                type: String,
                default: 'right'
            }
        },
        components: {
            MarkIcon,
            Loading,
            CloseIcon
        }
    }
</script>