export const PERMISSIONS = {
    // Lead
    VIEW_ALL_LEADS: 'View all Leads and Jobs',
    VIEW_MY_LEADS: 'View my Leads and Jobs',
    CREATE_LEAD: 'Create Lead/Job',
    EDIT_LEAD: 'Edit Lead/Job',
    DELETE_LEAD: 'Delete Lead/Job',
    MILESTONE_ADVANCED_EDITING: 'Milestone advanced editing',

    // Estimate
    VIEW_ALL_ESTIMATE: 'View All Estimate',
    VIEW_MY_ESTIMATE: 'View My Estimate',
    CREATE_ESTIMATE: 'Create Estimate',
    DELETE_ESTIMATE: 'Delete Estimate',
    EDIT_ESTIMATE: 'Edit Estimate',

    // Estimate Template
    VIEW_ALL_TEMPLATE: 'View All Template',
    VIEW_MY_TEMPLATE: 'View My Template',
    CREATE_TEMPLATE: 'Create Template',
    DELETE_TEMPLATE: 'Delete Template',
    EDIT_TEMPLATE: 'Edit Template',

    // Measurements
    VIEW_ALL_MEASUREMENTS: 'View All Measurements',
    VIEW_MY_MEASUREMENTS: 'View My Measurements',
    CREATE_MEASUREMENTS: 'Create Measurements',
    DELETE_MEASUREMENTS: 'Delete Measurements',
    EDIT_MEASUREMENTS: 'Edit Measurements',

    // Eagleview
    VIEW_ALL_EAGLEVIEW_ORDERS: 'View All Eagleview orders',
    VIEW_MY_EAGLEVIEW_ORDERS: 'View My Eagleview orders',
    ORDER_EAGLEVIEW: 'Order Eagleview',

    // Permissions
    VIEW_PERMISSION_SETTINGS: 'View Permission settings',
    EDIT_PERMISSION_SETTINGS: 'Edit Permission settings',

    // User list
    VIEW_USER_LIST: 'View User list',
    CREATE_USER: 'Create User',
    DELETE_USER: 'Delete User',
    EDIT_USER: 'Edit User',

    // Project management
    VIEW_PROJECT_MANAGEMENT_BOARD: 'View Project management board',
    CREATE_CARDS: 'Create Cards',
    DELETE_CARDS: 'Delete Cards',
    EDIT_CARDS: 'Edit Cards',
    CREATE_COLUMNS: 'Create Columns',
    DELETE_COLUMNS: 'Delete Columns',
    EDIT_COLUMNS: 'Edit Columns',

    // Company Settings
    VIEW_COMPANY_SETTINGS: 'View Company Settings',
    EDIT_COMPANY_SETTINGS: 'Edit Company Settings',
    VIEW_MILESTONES: 'View Milestones',
    EDIT_MILESTONES: 'Edit Milestones',
    VIEW_CUSTOM_FIELDS: 'View Custom fields',
    EDIT_CUSTOM_FIELDS: 'Edit Custom fields',

    // Tasks
    VIEW_ALL_TASKS: 'View All Tasks',
    VIEW_MY_TASKS: 'View My Tasks',
    CREATE_TASK_FOR_OTHERS: 'Create Task for others',
    DELETE_TASK_FOR_OTHERS: 'Delete Task for others',
    EDIT_TASK_OTHERS: 'Edit Task others',

    // Calendar
    VIEW_ALL_CALENDARS: 'View All Calendars',
    VIEW_MY_CALENDAR: 'View My Calendar',
    CREATE_EVENT_FOR_OTHERS: 'Create Event for others',
    DELETE_EVENT_FOR_OTHERS: 'Delete Event for others',
    EDIT_EVENT_OTHERS: 'Edit Event others',

    // Emails
    VIEW_ALL_EMAILS: 'View All E-mails',
    VIEW_MY_EMAILS: 'View My E-mails',
    SEND_EMAILS: 'Send E-mails',

    // Dashboard reports
    VIEW_ALL_LEADS_AND_JOBS: 'View All Leads and Jobs',
    VIEW_MY_LEADS_AND_JOBS: 'View My Leads and Jobs',
    EDIT_DASHBOARD_REPORTS: 'Edit dashboard reports',
    VIEW_DASHBOARD_REPORTS: 'View dashboard reports',

    // Reports
    SEE_ALL_REPORTS: 'See all reports',
    SEE_MY_REPORTS: 'See my reports',
    CREATE_EDIT_REPORTS: 'Create/edit reports',
    DELETE_REPORTS: 'Delete reports',

    // Orders
    VIEW_ALL_ORDERS: 'View all orders',
    VIEW_MY_ORDERS: 'View my orders',
    CREATE_ORDER: 'Create order',
    DELETE_ORDER: 'Delete order',
    EDIT_ORDER: 'Edit order',

    // Documents
    VIEW_ALL_DOCUMENTS: 'View all documents',
    VIEW_MY_DOCUMENTS: 'View my documents',
    ADD_DOCUMENTS: 'Add documents',
    EDIT_DOCUMENTS: 'Edit documents',
    DELETE_DOCUMENTS: 'Delete documents',
    VIEW_AND_EDIT_DEFAULT_DOCUMENTS: 'View and edit default documents',

    //Warranty
    DELETE_WARRANTIES: 'Delete warranties'

};

export const ROLES = {
    ADMIN: 'admin',
    MANAGEMENT: 'management',
    OFFICE_STAFF: 'office staff',
    ACCOUNTANT: 'accountant',
    SALESMAN: 'salesman'
}
