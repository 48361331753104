<template>
    <svg 
        viewBox="0 0 764.7767333984375 769.3973388671875"
        :width="size"
        :height="size"
        class="add-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="764.7767333984375" 
            height="769.3973388671875" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(1.568000078201294, 0, 0, 1.568000078201294, 349.4604187011719, 45.62238311767578)">
            <g>
                <g>
                    <g>
                        <path d="M0.2-3c0,14.7,0,29.3,0,44c0,35.2,0,70.4,0,105.6c0,42.4,0,84.8,0,127.3c0,36.8,0,73.7,0,110.5c0,17.9-0.5,35.9,0,53.7c0,0.3,0,0.5,0,0.8c0,10.9,9.6,21.3,20.8,20.8c11.3-0.5,20.8-9.2,20.8-20.8c0-14.7,0-29.3,0-44c0-35.2,0-70.4,0-105.6c0-42.4,0-84.8,0-127.3c0-36.8,0-73.7,0-110.5c0-17.9,0.5-35.9,0-53.7c0-0.3,0-0.5,0-0.8c0-10.9-9.6-21.3-20.8-20.8C9.7-23.3,0.2-14.6,0.2-3L0.2-3z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M-200,238.8c14.7,0,29.3,0,44,0c35.2,0,70.4,0,105.6,0c42.4,0,84.8,0,127.3,0c36.8,0,73.7,0,110.5,0c17.9,0,35.9,0.5,53.7,0c0.3,0,0.5,0,0.8,0c10.9,0,21.3-9.6,20.8-20.8c-0.5-11.3-9.2-20.8-20.8-20.8c-14.7,0-29.3,0-44,0c-35.2,0-70.4,0-105.6,0c-42.4,0-84.8,0-127.3,0c-36.8,0-73.7,0-110.5,0c-17.9,0-35.9-0.5-53.7,0c-0.3,0-0.5,0-0.8,0c-10.9,0-21.3,9.6-20.8,20.8C-220.3,229.3-211.6,238.8-200,238.8L-200,238.8z"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'AddIcon',
        props: {
            size: {
                type: [Number, String],
                default: 15
            }
        }
    }
</script>

<style scoped>
    /* TODO check it and use classes */
    .add-icon {
        cursor: pointer;
        transition: .1s;
    }
</style>
