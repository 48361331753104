import {
    AUTH_LOGIN,
    AUTH_WITH_MICROSOFT,
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    REFRESH_TOKEN,
    VERIFY_TWO_FACTOR_CODE
} from '../actions/auth';
import api from '@/headers.js';
import IMAPapi from '@/IMAPheaders';
import echoAuth from "../../echoAuth";

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('user-token') || '',
        status: '',
        email: ''
    },
    getters: {
        isAuth: state => {
            try {
                return !!state.token;
            } catch (e) {
                // throws InvalidTokenError exception
                state.status = 'logout';
                state.token = '';
                localStorage.removeItem('user-token');
                localStorage.removeItem('user_id');
                return false;
            }
        },
        userAuthEmail: state => state.email,
    },
    mutations: {
        [AUTH_REQUEST] (state, payload) {
            state.status = payload;
        },
        [AUTH_SUCCESS] (state, payload) {
            state.status = 'success';
            state.token = payload;
        },
        [AUTH_ERROR] (state) {
            state.status = 'error';
            state.token = '';
        },
        [AUTH_LOGOUT] (state) {
            state.status = 'logout';
            state.token = '';
            localStorage.removeItem('user-token');
        },
        [FORGOT_PASSWORD] (state, payload) {
            state.status = 'forgot password';
            state.token = '';
            state.email = payload;
        },
        [RESET_PASSWORD] (state) {
            state.status = 'reset password';
            state.token = '';
        },
        [REFRESH_TOKEN] (state, payload) {
            state.status = 'success';
            state.token = payload;
        }
    },
    actions: {
        [AUTH_LOGIN]({ commit, dispatch }, user) {
            return new Promise((resolve, reject) => {
                commit(AUTH_REQUEST, 'loading');
                api.post('/auth/login', user)
                    .then(response => response.data)
                    .then(resp => {
                        commit(AUTH_REQUEST, '');
                        if (resp.data.two_factor) {
                            resolve(resp.data);
                        } else {
                            localStorage.setItem('user-token', resp.data.token);
                            localStorage.setItem('user_id', resp.data.user.id);

                            commit(AUTH_SUCCESS, resp.data.token);
                            resolve(resp.data);
                        }
                    })
                    .catch(error => {
                        commit(AUTH_LOGOUT);
                        reject(error);
                    });
            })
        },
        [VERIFY_TWO_FACTOR_CODE]({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit(AUTH_REQUEST, 'loading');
                api.post('/two-factor/verify', data)
                    .then(response => response.data)
                    .then(resp => {
                        commit(AUTH_REQUEST, '');
                        localStorage.setItem('user-token', resp.data.token);
                        localStorage.setItem('user_id', resp.data.user.id);

                        commit(AUTH_SUCCESS, resp.data.token);
                        resolve(resp.data);
                    })
                    .catch(error => {
                        commit(AUTH_LOGOUT);
                        reject(error);
                    });
            })
        },
        [AUTH_WITH_MICROSOFT]({ commit }, params) {
            return new Promise((resolve, reject) => {
                commit(AUTH_REQUEST, 'loading');
                IMAPapi.get('/microsoft/get-auth-link-for-login', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        commit(AUTH_LOGOUT);
                        reject(error);
                    });
            })
        },
        [AUTH_LOGOUT]({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                api.post('/auth/logout')
                    .then(() => {
                        commit(AUTH_LOGOUT);
                        echoAuth.disconnect();
                        resolve();
                    })
            })
        },
        [FORGOT_PASSWORD]({ commit, dispatch }, email) {
            return new Promise((resolve, reject) => {
                commit(FORGOT_PASSWORD, email.email);
                api.post('/auth/email', email)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        commit(AUTH_ERROR);
                        reject(error);
                    });
            })
        },
        [RESET_PASSWORD]({ commit, dispatch }, info) {
            return new Promise((resolve, reject) => {
                commit(RESET_PASSWORD);
                api.post(`/auth/reset?token=${info.token}`, info.password)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        commit(AUTH_ERROR);
                        reject(error);
                    });
            })
        },
        [REFRESH_TOKEN]({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                api.post('/auth/refresh')
                    .then(response => response.data)
                    .then(data => {
                        localStorage.setItem('user-token', data.token);
                        commit(REFRESH_TOKEN, data.token);
                        resolve(data);
                    })
                    .catch(error => {
                        commit(AUTH_LOGOUT);
                        reject(error);
                    });
            })
        }
    }
}
