<template>
    <svg
        viewBox="0 0 13 14"
        :width="size" 
        :height="size"
        class="font-big-icon"
    >
        <path d="M0 0v2.2h5.4V14h2.2V2.2H13V0z"/>
    </svg>
</template>

<script>
    export default {
        name: 'FontBigIcon',
        props: {
            size: {
                type: [Number, String],
                default: 13
            }
        }
    }
</script>