<template>
    <full-modal @close="$emit('close')">
        <div slot="title">New Email</div>
        <div slot="body">
            <p class="f-16-darkgrey pb-2 in-dev">Send to:</p>
            <div class="w-100 pb-30">
                <form-input 
                    label="Enter e-mail"
                    :class="[{ 'form-input-danger' : errors.has('email') }]"
                >
                    <input 
                        type="text" 
                        name="email" 
                        class="form-input-field"
                        placeholder="Enter e-mail"
                        v-input-style="email"
                        v-model="email"
                        v-validate="'required|max:250'"
                    />
                </form-input>
            </div>
            <p class="f-16-darkgrey pb-2">Subject:</p>
            <div class="w-100 pb-30">
                <form-input 
                    label="Subject"
                    :class="[{ 'form-input-danger' : errors.has('subject') }]"
                >
                    <input 
                        type="text" 
                        name="subject" 
                        class="form-input-field"
                        placeholder="Subject"
                        v-input-style="subject"
                        v-model="subject"
                        v-validate="'required|max:250'"
                    />
                </form-input>
            </div>
            <p class="f-16-darkgrey pb-2">Message:</p>
            <div class="w-100 pb-30">
                <text-editor
                    v-model="message"
                    placeholder="Type here..."
                />
            </div>
            <div class="w-100 pb-30">
                <div class="s-align-4 pb-4">
                    <AttachIcon 
                        class="i-blue mr-2"
                        size="15"
                    />
                    <span class="f-15-blue f-sbold">File name</span>
                </div>
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button class="primary-button">
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, FormInput } from '@/ui';
    import TextEditor from '@/components/TextEditor.vue';
    import { AttachIcon } from '@/icons';

    export default {
        name: 'FormBuilderSendReportModal',
        components: {
            FullModal,
            FormInput,
            TextEditor,
            AttachIcon
        },
        data() {
            return {
                email: '',
                subject: '',
                message: ''
            }
        }
    }
</script>