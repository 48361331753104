<template>
    <svg
        viewBox="0 0 18 19"
        :width="size" 
        :height="size"
        class="reports-icon"
    >
    <path d="M2.3 14.8h.3c1 0 1.9-.9 1.9-1.9V5.7c0-1.1-.8-1.9-1.9-1.9h-.3c-1 0-1.9.9-1.9 1.9v7.2c0 1.1.9 1.9 1.9 1.9zm-.5-9.1c0-.3.2-.5.5-.5h.3c.3 0 .5.2.5.5v7.2c0 .3-.2.5-.5.5h-.3c-.3 0-.5-.2-.5-.5V5.7zM8.8 14.8h.3c1 0 1.9-.9 1.9-1.9v-11c0-1-.8-1.9-1.9-1.9h-.3c-1 0-1.9.8-1.9 1.9v11c0 1.1.8 1.9 1.9 1.9zM8.3 1.9c0-.3.2-.5.5-.5h.3c.3 0 .5.2.5.5v11c0 .3-.2.5-.5.5h-.3c-.3 0-.5-.2-.5-.5v-11zM15.2 14.8h.3c1 0 1.9-.9 1.9-1.9V5.7c0-1.1-.8-1.9-1.9-1.9h-.3c-1 0-1.9.9-1.9 1.9v7.2c.1 1.1.9 1.9 1.9 1.9zm-.5-9.1c0-.3.2-.5.5-.5h.3c.3 0 .5.2.5.5v7.2c0 .3-.2.5-.5.5h-.3c-.3 0-.5-.2-.5-.5V5.7zM17 19H1c-.6 0-1-.4-1-1s.4-1 1-1h16c.6 0 1 .4 1 1s-.4 1-1 1z"/>
</svg>
</template>

<script>
export default {
    name: 'ReportsIcon',
    props: {
        size: {
            type: [Number, String],
            default: 20
        }
    }
}
</script>