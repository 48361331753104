<template>
    <div class="calendar-header row-start row-baseline">
        <div class="calendar-switch column-start lg-4 nb-3 md-2 sm-12">
            <template v-for="(item, index) in headTitles">
                <router-link
                    v-if="item.visible()"
                    :key="index"
                    :class="[
                        'calendar-switch-link transparent-button',
                        { 'active': item.isActiveClass() }
                    ]"
                    v-can="item.permission"
                    :to="{ name: item.routeName }"
                >
                    {{ item.title }}
                </router-link>
            </template>
        </div>
        <div class="calendar-navigation row-baseline row-center lg-4 md-4 sm-12">
            <button 
                class="switch-prev s-align-5"
                @click="$emit('prevPage')"
                :disabled="isLoading"
            >
                <arrow-icon 
                    size="15" 
                    transform="180"
                />
            </button>
            <div 
                class="calendar-date"
                v-html="displayedDate"
            >
            </div>
            <button 
                class="switch-next s-align-5"
                @click="$emit('nextPage')"
                :disabled="isLoading"
            >
                <arrow-icon 
                    size="15" 
                    transform="0"
                />
            </button>
        </div>
        <template v-for="(calendar, index) in switchCalendars">
            <div
                :key="index"
                v-if="calendar.visible()"
                class="appointment-calendars row-center lg-4 md-5 sm-12"
            >
                <router-link
                    v-for="(link, indexLink) in calendar.links"
                    :key="indexLink"
                    :to="link.route"
                    :class="[
                        'appointment-calendars-item',
                        { 'none-events' : isLoading }
                    ]"
                >
                    {{ link.name }}
                </router-link>
            </div>
        </template>
        <button  
            class="right-sidebar-toggle transparent-button"
            @click="$emit('sidebarToggle')"
        >   
            <line-icon size="25"/>
        </button>
    </div>
</template> 

<script>
    import { LineIcon, ArrowIcon } from '@/icons';

    export default {
        name: 'CalendarHeader',
        components: {
            LineIcon,
            ArrowIcon
        },
        computed: {
            isAppointmentsCalendar() {
                return this.$route.name === 'CalendarWeek' 
                    || this.$route.name === 'CalendarDay' 
                    || this.$route.name === 'CalendarMonth' 
                    || this.$route.name === 'CalendarAgenda';
            },
            isUserCalendar() {
                return this.$route.name === 'UserCalendar';
            },
            isTechnicianCalendar() {
                return this.$route.name === 'TechnicianCalendar';
            },
            isUserDayView() {
                return this.$route.name === 'UserCalendarDayView'
                    || this.$route.name === 'UserCalendar';
            },
            isTechnicianDayView() {
                return this.$route.name === 'TechnicianCalendarDayView'
                    || this.$route.name === 'TechnicianCalendar';
            },
            isCrewScheduler() {
                return this.$route.name === 'CrewScheduler';
            },
            isJobScheduler() {
                return this.$route.name === 'JobScheduler';
            }
        },
        props: {
            displayedDate: {
                type: String,
                required: true
            },
            isLoading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                switchCalendars: [
                    {
                        visible: () => this.isAppointmentsCalendar,
                        links: [
                            {
                                route: { name: 'CalendarAgenda' },
                                name: 'Agenda'
                            },
                            {
                                route: { name: 'CalendarDay' },
                                name: 'Day'
                            },
                            {
                                route: { name: 'CalendarWeek' },
                                name: 'Week'
                            },
                            {
                                route: { name: 'CalendarMonth' },
                                name: 'Month'
                            }
                        ]
                    },
                    {
                        visible: () => this.isUserDayView,
                        links: [
                            {
                                route: { name: 'UserCalendarDayView' },
                                name: 'Day'
                            },
                            {
                                route: { name: 'UserCalendar' },
                                name: 'Week'
                            }
                        ]
                    },
                    {
                        visible: () => this.isTechnicianDayView,
                        links: [
                            {
                                route: { name: 'TechnicianCalendarDayView' },
                                name: 'Day'
                            },
                            {
                                route: { name: 'TechnicianCalendar' },
                                name: 'Week'
                            }
                        ]
                    },
                    {
                        visible: () => this.isCrewScheduler,
                        links: []
                    },
                    {
                        visible: () => this.isJobScheduler,
                        links: []
                    }
                ],
                headTitles: [
                    {
                        visible: () => this.isAppointmentsCalendar || this.isUserCalendar,
                        isActiveClass: () => this.isAppointmentsCalendar,
                        routeName: 'Calendar',
                        title: 'My Calendar',
                        permission: [this.$permissions.VIEW_ALL_CALENDARS]
                    },
                    {
                        visible: () => this.isAppointmentsCalendar || this.isUserCalendar,
                        isActiveClass: () => this.isUserCalendar,
                        routeName: 'UserCalendar',
                        title: 'Salesman',
                        permission: [this.$permissions.VIEW_ALL_CALENDARS]
                    },
                    {
                        visible: () => this.isTechnicianCalendar,
                        isActiveClass: () => this.isTechnicianCalendar,
                        routeName: 'TechnicianCalendar',
                        title: 'Technicians',
                        permission: [this.$permissions.VIEW_ALL_CALENDARS]
                    },
                    {
                        visible: () => this.isCrewScheduler || this.isJobScheduler,
                        isActiveClass: () => this.isCrewScheduler,
                        routeName: 'CrewScheduler',
                        title: 'Crews Scheduler',
                        permission: [this.$permissions.VIEW_ALL_CALENDARS]
                    },
                    {
                        visible: () => this.isCrewScheduler || this.isJobScheduler,
                        isActiveClass: () => this.isJobScheduler,
                        routeName: 'JobScheduler',
                        title: 'Job Scheduler',
                        permission: [this.$permissions.VIEW_ALL_CALENDARS]
                    }
                ]
            }
        }
    }
</script>