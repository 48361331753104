import Vue from 'vue';

export default {
  /**
   * The methods that the mixin used for filer functions.
   */
  methods: {
    /**
     * Method used to check if specific element is found in an filer
     *
     * @param {filer} filtername filer to find created filter
     *
     * @returns {any} filtered output
     */
    dynamicFilter(filterName, value) {
      return Vue.filter(filterName)(value);
    }
  }
};