<template>
    <div class="w-100 pt-4">
        <button 
            class="s-align-4 transparent-button"
            @click="tableIsOpen = !tableIsOpen"
        >
            <arrow-icon 
                class="i-darkgrey mr-4"
                size="6"
                :transform="tableIsOpen ? 270 : 90"
            />
            <span class="f-14-asphalt f-sbold">Jobs</span>
            <span 
                class="f-14-grey pl-1"
                v-if="option.jobs.length >= 1"
            >
                {{ `(${filteredJobs.length})` }}
            </span>
        </button>
        <div 
            class="w-100" 
            v-if="tableIsOpen"
        >
            <div 
                class="row-start f-12-black f-sbold pb-15 mt-5"
                v-if="dScreen || nScreen"
            >
                <div
                    v-if="showMilestone"
                    :class="[
                        selectMode ? 'lg-2 sm-12 s-align-9' : 'lg-1 s-align-4'
                    ]"
                >
                    <button 
                        class="transparent-button"
                        @click="changeSort(SORT_BY.MILESTONE)"
                    >
                        <move-icon 
                            transform="90" 
                            :class="[
                                'order-by-icon', 
                                sortBy === SORT_BY.MILESTONE ? `order-${orderBy}` : ''
                            ]"
                        />
                        <span class="pl-1 f-sbold f-9-grey uppercase">
                            Milestone
                        </span>
                    </button>
                </div>
                <div
                    :class="[
                        selectMode && !showMilestone ? 'lg-3 s-align-5' : 'lg-1 s-align-4',
                        showMilestone ? 'lg-1 pl-7' : 'lg-2'
                    ]"
                >
                    <button 
                        class="transparent-button"
                        @click="changeSort(SORT_BY.JOB_ID)"
                    >
                        <move-icon 
                            transform="90" 
                            :class="[
                                'order-by-icon', 
                                sortBy === SORT_BY.JOB_ID ? `order-${orderBy}` : ''
                            ]"
                        />
                        <span class="pl-1 f-sbold f-9-grey uppercase">
                            Job Id
                        </span>
                    </button>
                </div>
                <div
                    :class="[
                        'sm-12', 
                        selectMode ? 'lg-3 pl-7' : 'lg-4 pl-7'
                    ]"
                >
                    <span class="pl-8 f-sbold f-9-grey uppercase">
                        Address
                    </span>
                </div>
                <div 
                    :class="[
                        'lg-2 sm-12',
                        mScreen ? 's-align-1 w-100' : 's-align-4 pl-40'
                    ]"
                >
                    <button 
                        class="transparent-button"
                        @click="changeSort(SORT_BY.DATE)"
                    >
                        <move-icon 
                            transform="90" 
                            :class="[
                                'order-by-icon', 
                                sortBy === SORT_BY.DATE ? `order-${orderBy}` : ''
                            ]"
                        />
                        <span class="pl-1 f-sbold f-9-grey uppercase">
                            Date
                        </span>
                    </button>
                </div>
                <div class="lg-2 s-align-5">
                    <button 
                        class="transparent-button"
                        @click="changeSort(SORT_BY.JOB_TYPE)"
                    >
                        <move-icon 
                            transform="90" 
                            :class="[
                                'order-by-icon', 
                                sortBy === SORT_BY.JOB_TYPE ? `order-${orderBy}` : ''
                            ]"
                        />
                        <span class="pl-1 f-sbold f-9-grey uppercase">
                            Job Type
                        </span>
                    </button>
                </div>
            </div>
            <div 
                class="row-between row-baseline bb-grey pv-7"
                v-for="(job, index) in filteredJobs"
                :key="`unit-job-${index}`"
            >
                <div
                    v-if="selectMode" 
                    class="lg-1 sm-1 s-align-5"
                >
                    <checkbox-button color="blue">
                        <input
                            type="checkbox"
                            :checked="selectedEntities.some(id => id == job.id)"
                            @change="e => $emit('onSelect', e.srcElement.checked, job.id)"
                        >
                    </checkbox-button>
                </div>
                <div
                    v-if="showMilestone"
                    :class="[
                        'lg-1',
                        mScreen ? 'mb-2' : 's-align-5',
                        selectMode ? 'sm-11' : 'sm-12'
                    ]"
                >
                    <div class="milestone-content column-center t-center">
                        <div class="milestone-icon s-align-5">
                            <status-marker
                                :marker="!job.m_title ? '' : job.m_title"
                                :color="job.m_color"
                            />
                        </div>
                    </div>
                </div>
                <div
                    :class="[
                        'column-start break-all',
                        selectMode ? 'sm-11' : 'sm-12',
                        showMilestone ? 'lg-1' : 'lg-2'
                    ]"
                >
                    <div class="row-start">
                        <span 
                            class="f-13-grey pr-2"
                            v-if="mScreen"
                        >
                            ID:
                        </span>
                        <span class="f-13-black">
                            {{ jobPrefixAndNumber(job) }}
                        </span>
                    </div>
                    <div
                        v-if="job.farr_id && job.farr_number"
                        class="row-start row-baseline mt-1"
                    >
                        <flag-icon
                            class="i-yellow mr-1"
                            size="13"
                        />
                        <span class="f-11-grey mr-1">FARR for</span>
                        <router-link
                            class="f-11-blue"
                            :to="{ 
                                name: 'CommercialJobOverview', 
                                params: { property_id: job.property_id, job_id: job.farr_id }
                            }"
                        >
                            Job # {{ job.farr_number }}
                        </router-link>
                    </div>
                </div>
                <div :class="['sm-12 break-all', selectMode ? 'lg-3' : 'lg-4']">
                    <span 
                        class="f-13-grey pr-2"
                        v-if="mScreen"
                    >
                        Address:
                    </span>
                    <span class="f-13-black">{{ jobAddres(job) }}</span>
                </div>
                <div
                    :class="[
                        'lg-2 sm-12',
                        mScreen ? 's-align-1 w-100' : 's-align-4'
                    ]"
                >
                    <span 
                        class="f-13-grey pr-2"
                        v-if="mScreen"
                    >
                        Date:
                    </span>
                    <span class="f-13-black">{{ job.created_at | date }}</span>
                </div>
                <div class="lg-2 sm-12 mt-sm-1">
                    <span 
                        v-if="job.type_name"
                        class="major-green-label f-13-white f-sbold s-aling-5"
                    >
                        {{ job.type_name }}
                    </span>
                </div>
                <div class="lg-1 sm-12">
                    <div class="s-align-6 w-100">
                        <router-link :to="{ 
                                name: 'CommercialJobOverview', 
                                params: { property_id: propertyId, job_id: job.id } 
                            }"
                        >
                            <span class="f-13-blue f-sbold">To Job</span>
                            <arrow-icon 
                                transform="0" 
                                class="i-blue ml-2"
                            />
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { CheckboxButton } from '@/ui';
    import { ArrowIcon, FlagIcon, MoveIcon } from '@/icons';
    import { StatusMarker } from '@/components';
    import orderBy from 'lodash/orderBy';
    import moment from 'moment/src/moment';


    export default {
        name: 'CommercialJobsTable',
        components: {
            StatusMarker,
            CheckboxButton,
            ArrowIcon,
            FlagIcon,
            MoveIcon
        },
        props: {
            option: {
                type: Object,
                default: function() {
                    return {};
                }
            },
            showMilestone: {
                type: Boolean,
                default: false
            },
            propertyId: {
                type: [String, Number],
                required: true
            },
            selectMode: {
                type: Boolean,
                default: false
            },
            selectedEntities: {
                type: Array,
                default: () => []
            },
            filterParams: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                tableIsOpen: true,
                SORT_BY: {
                    MILESTONE: 'm_title',
                    JOB_ID: 'job_number',
                    DATE: 'created_at',
                    JOB_TYPE: 'type_name'
                },
                ORDER_BY: {
                    ASC: 'asc',
                    DESC: 'desc'
                },
                orderBy: 'asc',
                sortBy: 'job_number'
            }
        },
        computed: {
            filteredJobs() {
                let jobs = this.option.jobs;

                if (this.filterParams && this.filterParams.jobType.length) {
                    let filtering = jobs.filter(({ type_name }) => this.filterParams.jobType.includes(type_name));
                    jobs = [...filtering];
                }
                
                if (this.filterParams && this.filterParams.milestone.length) {
                    let filtering = jobs.filter(({ m_title }) => this.filterParams.milestone.includes(m_title));
                    jobs = [...filtering];
                }

                if (this.filterParams && this.filterParams.date.length 
                    && this.filterParams.date[0] 
                    && this.filterParams.date[1]) {
                    let filtering = jobs.filter(job => {
                        let localTime = moment.utc(job.created_at).local();
                        return localTime.isSameOrAfter(moment(this.filterParams.date[0]), 'day')
                                && localTime.isSameOrBefore(moment(this.filterParams.date[1]), 'day');
                    });
                    jobs = [...filtering];
                }

                if (this.sortBy === this.SORT_BY.MILESTONE) {
                    return orderBy(jobs, this.SORT_BY.MILESTONE, this.orderBy);
                } else if (this.sortBy === this.SORT_BY.JOB_ID) {
                    return orderBy(jobs, this.SORT_BY.JOB_ID, this.orderBy);
                } else if (this.sortBy === this.SORT_BY.JOB_TYPE) {
                    return orderBy(jobs, this.SORT_BY.JOB_TYPE, this.orderBy);
                } else if (this.sortBy === this.SORT_BY.DATE) {
                    return orderBy(jobs, this.SORT_BY.DATE, this.orderBy);
                }

                return jobs;
            }
        },
        methods: {
            changeSort(sort_by) {
                if (this.sortBy === sort_by) {
                    this.orderBy = this.orderBy === this.ORDER_BY.ASC ? this.ORDER_BY.DESC : this.ORDER_BY.ASC;
                } else {
                    this.sortBy = sort_by;
                    this.orderBy = this.ORDER_BY.ASC;
                }
            },
            jobPrefixAndNumber(job) {
                return `${job.prefix ? job.prefix + '-' : ''}${job.job_number ? job.job_number : ''}`;
            },
            jobAddres(job) {
                return job.address ? job.address : job.full_address;
            }
        }
    }
</script>