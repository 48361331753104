<template>
    <div :class="[
        'overview-kanban-link row-between',
        { 'kanban-mock-loading' : loading }
    ]">
        <div 
            v-if="!loading" 
            :class="['s-align-4 sm-12', kanbanLink ? 'lg-9' : 'lg-12 pr-8']">
            <img
                src="@/assets/images/kanban-link-icon.svg"
                alt="kanban"
                class="mr-1"
            />
            <div class="column-start pl-8">
                <p class="f-15-white f-sbold pb-3">Project management board</p>
                <span
                    class="f-12-white opacity-8"
                    v-if="kanbanLink"
                >
                    Click View card to open a created record for this
                    lead/job on the Project management board.
                </span>
                <span
                    class="f-12-white opacity-8"
                    v-else
                >
                    The link to a card will be available as soon as the card
                    is created on the Project management board.
                </span>
            </div>
        </div>
        <div
            class="s-align-6 lg-3 sm-12 mt-sm-1"
            v-if="!loading && kanbanLink"
        >
            <router-link
                :to="{ name: 'ProjectManagement', query: { card: kanbanLink }}"
                class="view-details-secondary"
            >
                View card
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'KanbanLinkBox',
        props: {
            loading: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            kanbanLink() {
                return this.$store.getters['lead/getKanbanLink'];
            }
        }
    }
</script>