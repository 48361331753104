<template>
    <div>
        <div class="commercial-lead-details h-100">
            <div
                class="f-12-grey mb-40 pointer"
                @click="hideCorrespondenceWithClients"
            >
                <arrow-icon
                    class="i-grey mr-2"
                    transform="180"
                />
                <span>Back to Responsible billing party</span>
            </div>
            <div class="row-baseline row-between w-100 p-20 bg-white mb-5">
                <div class="f-20-darkgrey lg-4 sm-12 ls-sm-6 s-align-4 pb-2 pt-2">
                    <span
                        class="mr-20 pointer nav-btn"
                        :class="isMessage ? 'f-20-blue active-massage-nav-bar' : ''"
                        @click="isMessage = true"
                    >
                        Messages
                        <span class="line-active"/>
                    </span>
                    <span
                        class="pointer nav-btn"
                        :class="!isMessage ? 'f-20-blue active-massage-nav-bar' : ''"
                        @click="isMessage = false"
                    >
                        Calls
                        <span class="line-active"/>
                    </span>
                </div>
<!--                <div-->
<!--                    v-if="isMessage"-->
<!--                    class="lg-8 sm-12 ls-sm-6 s-align-6 messages-btn-container"-->
<!--                >-->
<!--                    <div-->
<!--                        class="row-end"-->
<!--                        v-can="[$permissions.SEND_EMAILS]"-->
<!--                    >-->
<!--                        <button-->
<!--                            class="view-details-primary ph-4 mr-20"-->
<!--                            @click="openEmailModal('message')"-->
<!--                        >-->
<!--                            + Add Email/Message-->
<!--                        </button>-->
<!--                        <button-->
<!--                            v-if="outlook_id"-->
<!--                            class="primary-button send-new-email"-->
<!--                            @click="openEmailModal('email')"-->
<!--                        >-->
<!--                            + Send New Email-->
<!--                        </button>-->
<!--                        <tooltip-->
<!--                            v-else-->
<!--                            direction="bottom"-->
<!--                            mobileDescription="Field info"-->
<!--                            class="max-w-100"-->
<!--                        >-->
<!--                            <button-->
<!--                                class="primary-button send-new-email"-->
<!--                                disabled-->
<!--                            >-->
<!--                                + Send New Email-->
<!--                            </button>-->
<!--                            <template v-slot:content>-->
<!--                                <p class="f-16-white t-center">-->
<!--                                    Please link your account to Outlook-->
<!--                                </p>-->
<!--                            </template>-->
<!--                        </tooltip>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
            <loader v-if="loading"/>
            <div
                v-else-if="isMessage && messages && messages.length"
                :class="[
                    'correspondence row-start w-100 bg-white',
                    !mScreen && 'flex-no-wrap'
                ]"
            >
                <div class="correspondence-list">
                    <perfect-scrollbar class="ps ps-grey">
                        <div class="w-100 correspondence-wrapper">
                            <div
                                v-for="(item, index) in messages"
                                :key="index"
                                :class="[
                                    'correspondence-list-item ph-20 pv-15 pointer',
                                    item.conversationId == selectConversationsId && 'correspondence-list-item-active'
                                ]"
                                @click="changeConversationsId(item.conversationId)"
                            >
                                <div class="row-between mb-2 letters-list">
                                    <span
                                        :class="[
                                            'pv-1 pr-5 pl-25 f-bold letter-status',
                                            item.isDraft ? 'letter-status-draft f-11-grey' : 'letter-status-success f-11-green',
                                            item.is_manual ? 'letter-status-manual' : ''
                                        ]"
                                    >
                                        <template v-if="!item.is_manual">
                                            {{ item.isDraft ? 'Draft' : 'Received' }}
                                        </template>
                                        <template v-else>
                                            Manual
                                        </template>
                                    </span>
                                    <div class="f-12-grey">
                                        {{ item.receivedDateTime | date }},
                                        {{ item.receivedDateTime | time }}
                                    </div>
                                </div>
                                <div class="f-14-black f-sbold">
                                    {{ item.subject }}
                                </div>
                                <div class="f-12-grey">
                                    From
                                    <span
                                        v-if="item.from && item.from.emailAddress && item.from.emailAddress.name"
                                        class="f-12-black"
                                    >
                                        {{ item.from.emailAddress.name }}
                                    </span>
                                    <template v-if="item.toRecipients && item.toRecipients.length">to</template>
                                    <span
                                        v-if="item.toRecipients && item.toRecipients.length"
                                        class="f-12-black"
                                    >
                                        <template v-for="(el, i) in item.toRecipients">
                                            {{ el.emailAddress.name }}
                                            <span v-if="i + 1 < item.toRecipients.length">,</span>
                                        </template>
                                    </span>
                                    <template v-if="item.entityLink">
                                        <span>&bull;</span>
                                        <router-link class="f-12-blue" :to="item.entityLink.to">
                                            {{ item.entityLink.text }}
                                        </router-link>
                                    </template>
                                </div>
                                <div class="f-12-grey preserve-whitespaces break-all t-line-height-1">
                                    {{ item.bodyPreview }}
                                </div>
                            </div>
                            <div
                                v-if="isMorePages && !loading"
                                class="s-align-5 p-8"
                            >
                                <load-more
                                    @click.native="loadMore"
                                    :status="isLoadingMore"
                                />
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
                <correspondence-body :selectConversationsId="selectConversationsId"/>
            </div>
            <div v-else-if="!isMessage">
                <calls-with-clients/>
            </div>
            <div
                v-else
                class="w-100 s-align-5 column-center"
            >
                <no-messages size="100"/>
                <div class="f-16-darkgrey f-sbold">This has no messages.</div>
            </div>
        </div>
        <email-fullscreen-modal
            v-if="emailModal"
            :entityType="BASE_ENTITY_TYPES.CONTACT"
            :attachmentReportFile="reportAttachFile"
            @close="closeEmailModal"
            @onSend="updateMessages"
        />
        <confirm-actions-modal
            v-if="needConfirmAction"
            @close="needConfirmAction = !needConfirmAction"
            @confirmAction="confirmCloseEmailModal"
        >
            <p
                slot="body"
                class="f-16-black f-sbold t-center"
            >
                Are you sure you want to close? <br/>
                <span class="f-12-black">
                    Changes you made may not be saved.
                </span>
            </p>
        </confirm-actions-modal>
    </div>
</template>
<script>
    import IMAPapi from '@/IMAPheaders';
    import CorrespondenceBody from "@/components/CreateLead/CorrespondenceBody";
    import CallsWithClients from '@/components/CreateLead/CallsWithClients';
    import {ArrowIcon} from '@/icons';
    import {Loader, LoadMore} from '@/ui';
    import {MESSAGE_TYPE, BASE_ENTITY_TYPES} from '@/constants';
    import {Tooltip} from '@/ui';
    import {emailMessage} from '@/views/LeadModules/messages-mixin/emailMessage';
    import {NoMessages} from '@/icons';
    
    export default {
        name: 'CorrespondenceWithClients',
        components: {
            LoadMore,
            Loader,
            CorrespondenceBody,
            ArrowIcon,
            CallsWithClients,
            Tooltip,
            NoMessages,
            EmailFullscreenModal: () => import('@/modals/EmailFullscreenModal.vue'),
            ConfirmActionsModal: () => import('@/modals/ConfirmActionsModal.vue'),
        },
        computed: {
            authUser() {
                return this.$store.getters['user/getUserData'];
            },
            emailModal() {
                return this.$store.getters['emailMessage/getEmailModalState'];
            },
            outlookId() {
                return this.outlook_id;
            },
            emails() {
                return this.$store.getters['customer/getCustomerContactsEmails'];
            },
            isMorePages() {
                return this.page !== this.lastPage;
            }
        },
        mixins: [emailMessage],
        data() {
            return {
                outlook_id: null,
                isMessage: true,
                messages: [],
                loading: false,
                selectConversationsId: null,
                perPage: 10,
                page: 1,
                lastPage: 0,
                isLoadingMore: false,
                BASE_ENTITY_TYPES,
                reportAttachFile: null,
            }
        },
        methods: {
            attachReport(reportFile) {
                this.reportAttachFile = reportFile;
            },
            openEmailModal(type) {
                if (this.emailModal) {
                    this.openCollapsedModal();
                    return;
                }
                let params = {
                    show: true,
                    modalType: type,
                    title: type === 'email' ? 'New Email' : 'Add email/message',
                    outlook_id: this.outlook_id
                }
                this.$store.commit('emailMessage/openModal', params);
                return this.outlook_id;
            },
            updateMessages() {
                // this.getMessages();
            },
            changeConversationsId(id) {
                this.selectConversationsId = id;
            },
            hideCorrespondenceWithClients() {
                this.$store.commit('customer/SET_CUSTOMER_DETAILS_IS_OPEN', true);
                this.$store.commit('customer/SET_IS_SHOW_CORRESPODENCE', false);
            },
            loadMore() {
                this.page += 1;
                this.getMessagesByEmail();
            },
            getMessagesByEmail(load) {
                this.loading = load ? true : false;
                if (this.emails && !this.emails.length) {
                    this.loading = false;
                    return;
                }
                
                const params = {
                    emails: this.emails,
                    per_page: this.perPage,
                    page: this.page
                };
                
                this.isLoadingMore = true;
                IMAPapi.get('/messages/by-email', {params})
                    .then(response => response.data.data)
                    .then(data => {
                        this.lastPage = data.messages.last_page;
                        let messages = this.setEntityLinks(data.messages.data);
                        this.messages = [...this.messages, ...messages];
                        
                        if (this.messages && this.messages.length && !this.selectConversationsId) {
                            this.selectConversationsId = this.messages[0].conversationId;
                        }
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.isLoadingMore = false;
                    });
            },
            setEntityLinks(messages) {
                return messages.map(message => {
                    if (!message.hasOwnProperty('entity')
                        || !message['entity'].hasOwnProperty('type')
                        || (message['entity'].hasOwnProperty('deleted') && message['entity']['deleted'] === true)
                    ) {
                        return message;
                    }
                    
                    switch (message.entity.type) {
                        case MESSAGE_TYPE.RESIDENTIAL_LEAD: {
                            message.entityLink = {
                                text: 'View lead details',
                                to: {name: 'Overview', params: {lead_id: message.entity.entityId}},
                            }
                        }
                            break;
                        case MESSAGE_TYPE.RESIDENTIAL_JOB: {
                            message.entityLink = {
                                text: 'View job details',
                                to: {name: 'Overview', params: {lead_id: message.entity.entityId}},
                            }
                        }
                            break;
                        case MESSAGE_TYPE.COMMERCIAL_LEAD: {
                            message.entityLink = {
                                text: 'View lead details',
                                to: {name: 'CommercialLeadOverview', params: {lead_id: message.entity.entityId}},
                            }
                        }
                            break;
                        case MESSAGE_TYPE.COMMERCIAL_JOB: {
                            if (!message.entity.hasOwnProperty('propertyId')) {
                                return message;
                            }
                            message.entityLink = {
                                text: 'View job details',
                                to: {
                                    name: 'CommercialJobDetails',
                                    params: {job_id: message.entity.entityId, property_id: message.entity.propertyId}
                                },
                            }
                        }
                            break;
                    }
                    
                    return message;
                });
            },
        },
        created() {
            this.getMessagesByEmail(true);
        }
    }
</script>