<template>
    <div :class="[type == 'modal' ? 'upload-as-modal' : 'upload-as-page', 's-align-5']">
        <div class="upload-modal-content column-center">
            <h2 class="f-24-black upload-title">Add new document</h2>
            <label 
                class="upload-drop-area column-center"
                ref="droparea"
                @dragenter.stop.prevent="dropActive"
                @dragover.stop.prevent
                @dragleave.stop.prevent="$emit('dragClose')"
                @drop.stop.prevent="dropInActive"
            >
                <input 
                    type="file" 
                    name="file_upload"
                    ref="uploadInput"
                    multiple="multiple"
                    :disabled="type == 'page'"
                    @change="fileInputHandler"
                />
                <upload-screen-saver
                    :height="type == 'page' ? 270 : 190"
                    :width="type == 'page' ? 280 : 200"
                    :class="{ 'mb-8' : type == 'page' }"
                    v-if="!toStartUpload && !mScreen"
                />
                <div 
                    v-if="type == 'modal'"
                    class="column-center"
                >
                    <span class="f-24-grey f-sbold pt-8">
                        {{ !mScreen ? 'Drag and Drop files here' : 'Click here' }}
                    </span>
                    <span class="f-14-blue pointer">
                        {{ !mScreen ? 'or Browse your computer' : 'to upload your file' }}
                    </span>
                </div>
                <div 
                    v-if="type == 'page' && !toStartUpload"
                    class="column-center none-events"
                >
                    <span class="f-24-grey f-sbold pt-8">Drop files here</span>
                    <span class="f-14-blue">or use the New Document button</span>
                </div>
                <div 
                    v-if="type == 'page' && toStartUpload"
                    class="upload-as-page-preview column-start"
                >
                    <loader-solid class="pb-8"/>
                    <documents-upload-preview
                        v-for="(file, index) in toUploadFiles"
                        :file="file"
                        :key="index"
                        place="page"
                    />   
                </div>
            </label>
            <div 
                v-if="type == 'modal'"
                class="upload-as-modal-preview"
            >
                <documents-upload-preview
                    v-for="(file, index) in toUploadFiles"
                    :file="file"
                    :key="index"
                    :filesInProgress="filesInProgress"
                    place="modal"
                />
            </div>
            <div 
                v-if="type == 'modal'"
                class="row-end upload-buttons"
            >
                <button 
                    :class="[!toStartUpload ? 'add-button' : 'primary-button']"
                    @click="$emit('close')"
                    :disabled="filesInProgressLength"
                >
                    {{ !toStartUpload ? 'Cancel' : 'Done!' }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import UploadScreenSaver from '@/components/UploadScreenSaver.vue';
    import DocumentsUploadPreview from '@/components/LeadModules/DocumentsUploadPreview.vue';
    // ↑↑↑ don't change import, nedded to correct build project
    import { scrollToElement } from '@/helpers';
    import { LoaderSolid } from '@/ui';
    import api from '@/headers.js';

    export default {
        name: 'DocumentsUploadFileModal',
        props: {
            type: {
                type: String,
                default: ''
            },
            id: {
                type: [Number, String],
                default: 0
            },
            path: {
                type: String,
                default: ''
            },
            modelClass: {
                type: String,
                required: true
            }
        },
        components: {
            UploadScreenSaver,
            DocumentsUploadPreview,
            LoaderSolid
        },
        data() {
            return {
                toStartUpload: false,
                toUploadFiles: [],
                filesCount: 0,
                filesInProgress: []
            }
        },
        computed: {
            uploadFilesLength() {
                return this.toUploadFiles.length;
            },
            filesInProgressLength() {
                return !!this.filesInProgress.length;
            }
        },
        methods: {
            fileInputHandler(event) {
                if (!this.$refs.uploadInput.files.length) {
                    return false;
                }

                for (let i = 0; i < this.$refs.uploadInput.files.length; i++) {
                    const file = this.$refs.uploadInput.files[i];
                    this.filesInProgress.push(file.name);
                    this.toUploadFiles.push(file);
                    this.uploadFiles(file);
                }
            },
            dropActive() {
                if (this.type == 'modal') {
                    this.$refs.droparea.style.backgroundColor = '#f8f8f8';
                }
                
                if (!this.toStartUpload) {
                    document.querySelector('.upload-screen-saver').classList.add('upload-screen-saver-animate');
                }
            },
            dropInActive() {
                if (this.type == 'modal')
                    this.$refs.droparea.style.backgroundColor = '#ffffff';
            },
            dropHandler(event) {
                let files = event.dataTransfer.files;
                ([...files]).forEach(file => { 
                    this.filesInProgress.push(file.name);
                    this.toUploadFiles.push(file);
                });
                
                Object.values(files).forEach((file, index) => {
                    setTimeout(() => {
                        this.uploadFiles(file);
                    }, 500 * index);
                });
            },
            uploadFiles(file) {                     
                this.toStartUpload = true;

                let formData = new FormData();
                formData.append('file', file);
                formData.append('model_id', this.id);
                formData.append('path', this.path);
                formData.append('model_class', this.modelClass);

                api.post('/upload', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                    .then(response => {
                        this.filesCount++;

                        if (this.filesCount == this.uploadFilesLength) {
                            this.$emit('update');
                            this.notifySuccess(`${this.filesCount} file(s) were uploaded.`);

                            if (this.type == 'page')
                                this.$emit('close');
                        };
                    })
                    .catch(error => {
                        this.toUploadFiles.map((f) => {
                            if (f.name == file.name) {
                                this.toUploadFiles.splice(f, 1);
                            };
                        });

                        this.notifyError(`File "${file.name}" already exists in this directory or this type is not supported.`);

                        if (this.type == 'page' && this.uploadFilesLength == 0)
                            this.$emit('close');
                    })
                    .finally(() => {
                        let index = this.filesInProgress.findIndex(f => f === file.name);
                        this.filesInProgress.splice(index, 1);
                    });
            }
        },
        mounted() {
            this.$refs.droparea.addEventListener('drop', this.dropHandler, false);

            if (this.type == 'page')
                scrollToElement(document.querySelector('.upload-as-page'));
        }
    }
</script>