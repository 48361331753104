<template>
    <div class="modal modal-map">
        <div class="row-end pv-4 pr-8">
            <button 
                class="transparent-button"
                @click="$emit('closeMap')"
            >
                <close-icon class="i-white"/>
            </button>
        </div>
        <div class="modal-content relative">
            <google-map 
                :coords="mapSettings.coords"
                :zoom="mapSettings.zoom"
                :satellite="mapSettings.satellite"
            />
        </div>
    </div>
</template>

<script>
    import { CloseIcon } from '@/icons';
    import { GoogleMap } from '@/GoogleApi';

    export default {
        name: 'MapModal',
        props: {
            mapSettings: {
                type: Object,
                default: null
            }
        },
        components: {
            CloseIcon,
            GoogleMap
        }
    }
</script>
