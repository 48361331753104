<template>
    <modal
        class="ImportConflictSettingsPopup p-8"
        @close="$emit('close')"
    >
        <div slot="modal-header">
            <span class="f-20-black f-sbold">
                Conflicts to Lorem Title
            </span>
        </div>
        <div slot="modal-body">
            <div class="w-100 pb-4 mt-20">
                <span class="f-13-black f-sbold">
                    It is a long established fact
                </span>
                <div class="mt-5 row-end">
                    <div class="w-100">
                        <radio-button
                            label="Match default value to column items"
                            color="blue"
                        >
                            <input 
                                type="radio" 
                                name="conflict_option" 
                                value="default"
                                v-model="selected"
                            />
                        </radio-button>
                    </div>
                    <div class="w-90 mt-8">
                        <form-input>
                            <input
                                class="form-input-field"
                                v-if="selected == 'default'" 
                                type="text" 
                                v-model="value"
                            >
                        </form-input>
                    </div>
                </div>
            </div>
        </div>
        <div slot="modal-footer">
            <div class="row-end">
                <button 
                    class="transparent-button f-12-asphalt f-sbold mr-30"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button 
                    class="primary-button"
                    @click="agreeSettings()"
                >
                    Continue
                    <arrow-icon
                        size="11"
                        class="ml-5"
                        transform="0"
                    />
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
    import { RadioButton, Modal, FormInput } from '@/ui';
    import { ArrowIcon } from '@/icons';

    export default {
        name: "ImportConflictSettingsPopup",
        components: {
            Modal,
            RadioButton,
            FormInput,
            ArrowIcon
        },
        props: {
            defaultValue: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                selected: 'default',
                value: ''
            }
        },
        methods: {
            agreeSettings() {
                this.$emit('setDefaultValue', this.value);
            }
        },
        created() {
            this.value = this.defaultValue;
        }
    }
</script>

<style>

</style>