<template>
    <div class="form-builder-photo-form w-100">
        <div class="row-between">
            <div class="lg-8 sm-10 column-start">
                <p class="row-start row-baseline mb-1">
                    <attach-icon 
                        class="i-darkgrey mr-2" 
                        size="14"
                    />
                    <tooltip
                        v-if="field.parameters.show_tooltip"
                        direction="right"
                        :fixedDirection="true"
                    >
                        <span class="f-16-asphalt f-sbold">Attachments</span>
                        <template v-slot:content>
                            <p class="f-16-white">
                                {{ field.parameters.tooltip_text }}
                            </p>
                        </template>
                    </tooltip>
                    <span
                        v-else 
                        class="f-16-asphalt f-sbold"
                    >
                        Attachments
                    </span>
                </p>
                <p class="f-14-grey">
                    {{ field.label }}
                </p>
            </div>
            <div class="lg-4 sm-2 s-align-3">
                <label 
                    class="view-details-primary ph-4"
                >
                    Add Pictures
                    <input 
                        class="hide"
                        type="file"
                        name="image"
                        accept="image/*"
                        @change="addImage"
                        :disabled="requestIsGoing"
                    >
                </label>
            </div>
        </div>
        <div
            v-if="imagesLength || requestIsGoing" 
            class="form-builder-bar row-baseline w-100 mt-30 relative"
        >
            <div class="form-builder-bar-photos">
                <label 
                    class="form-builder-bar-photo s-align-5 relative"
                    v-for="(image, index) in images"
                    :key="`photo-bar-${index}`"  
                    :style="{ backgroundImage: getImageUrl(image) }"     
                >
                    <button 
                        class="delete-photo transparent-button"
                        @click="deleteImage(image, index)"
                    >
                        <close-icon class="i-red"/>
                    </button>
                </label>
                <loader
                    v-if="requestIsGoing"
                    class="s-align-5"
                    size="small"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { AttachIcon, CloseIcon } from '@/icons';
    import { Tooltip, Loader } from '@/ui';
    import api from '@/headers.js';
    import { ENTITY_TYPES } from '@/constants';

    export default {
        name: 'FormBuilderCustomFieldsPictureSection',
        components: {
            AttachIcon,
            CloseIcon,
            Tooltip,
            Loader
        },
        props: {
            field: {
                type: Object,
                required: true
            },
            entityType: {
                type: String,
                required: true
            }
        },
        data() {
            return  {
                requestIsGoing: false
            }
        },
        computed: {
            images() {
                return this.field.object_value 
                    ? JSON.parse(this.field.object_value).pictures : [];
            },
            imagesLength() {
                return this.images.length;
            },
            entityId() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: {
                        return this.$route.params.lead_id;
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_JOB: {
                        return this.$route.params.job_id;
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return this.$route.params.lead_id;
                    } break;
                }
            }
        },
        methods: {
            getImageUrl(image) {
                return `url(${image.url}original${image.path})`;
            },
            addImage(e) {
                const maxPhotoSize = 9961472;
                if (e.target.files[0].size > maxPhotoSize) {
                    this.notifyError('File have size more then 10MB! Need less.');
                    return;
                }

                let formData = new FormData();
                formData.append('image', e.target.files[0]);
                formData.append('entity_type', this.entityType);
                formData.append('entity_id', this.entityId);
                formData.append('custom_field_id', this.field.id);

                this.requestIsGoing = true;
                api.post('/layouts/picture-upload', formData, { 
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }).then(response => response.data.data)
                    .then(data => {
                        this.$emit('change', JSON.stringify({
                            pictures: [
                                ...this.images,
                                {
                                    image: data.image,
                                    path: data.path,
                                    url: data.url  
                                }
                            ]
                        }));
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            },
            deleteImage(image, index) {
                const params = {
                    entity_type: this.entityType,
                    entity_id: this.entityId,
                    custom_field_id: this.field.id,
                    pictures: [image.path]
                };

                this.requestIsGoing = true;
                api.delete('/layouts/picture-upload', { params })
                    .then(() => {
                        this.$emit('change', JSON.stringify({
                            pictures: this.images.slice(0, index).concat(this.images.slice(index + 1))
                        }))
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            }
        }
    }
</script>