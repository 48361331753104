<template>
    <div class="w-100">
        <tabs-menu identifier="custom-fields-commercial-job-tabs">
            <li
                v-for="link in pages"
                :key="link.id"
                slot="links"
            >
                <router-link 
                    :to="{ name: link.nameRoute }"
                    class="tabs-menu-item s-align-5 h-100"
                    @click.native="isJobPage ? getLayouts() : $emit('getLayout')"
                >
                    {{ link.name }}
                </router-link>
            </li>
        </tabs-menu>
        <div 
            v-if="isJobPage"
            class="row-center row-baseline pv-25 bb-grey"
        >
            <Loading 
                v-if="isLayoutsLoading"
                class="i-blue"
            />
            <template v-else>
                <div class="lg-6 sm-12 column-start">
                    <p class="f-16-darkgrey f-sbold">Choose Job type</p>
                    <div class="f-14-grey row-start">
                        <span class="mr-1">Click</span>
                        <button 
                            class="f-14-blue transparent-button mr-1"
                            @click="showAddTemplateModal = true"
                        >
                            Create new
                        </button> 
                        <span class="mr-1">to add a new job type.</span>
                        <span class="mr-1">To assign a milestone set, rename or delete one the existing job types</span> 
                        <span class="mr-1">click</span>
                        <button 
                            class="f-14-blue transparent-button mr-1"
                            @click="showEditTemplatesModal = true"
                        >
                            Edit
                        </button> 
                    </div>
                </div>
                <div class="lg-6 sm-12 mt-sm-1">
                    <multiselect
                        :options="layouts"
                        name="description"
                        data-name="description"
                        placeholder="Description"
                        track-by="id"
                        label="name"
                        :multiple="false"
                        :allow-empty="false"
                        :searchable="true"
                        :show-labels="false"
                        :value="currentLayout"
                        @select="changeLayout"
                    />
                </div>
            </template>
        </div>
        <custom-fields-create-layout-modal
            v-if="showAddTemplateModal"
            type="commercialJob"
            :order="lastOrder"
            @close="showAddTemplateModal = false"
            @created="addTemplate"
        />
        <custom-fields-edit-layouts-modal
            v-if="showEditTemplatesModal"
            type="CommercialJob"
            :items="layouts"
            @close="closeModal"
        />
    </div>
</template>

<script>
    import { TabsMenu } from '@/components';
    import Multiselect from 'vue-multiselect';
    import { Loading } from '@/icons';
    import { CUSTOM_FIELDS_PAGES, MODEL_NAMES } from '@/constants';
    import api from '@/headers.js';

    export default {
        name: 'CommercialJobNav',
        components: {
            CustomFieldsCreateLayoutModal: () => import('@/modals/CustomFieldsCreateLayoutModal.vue'),
            CustomFieldsEditLayoutsModal: () => import('@/modals/CustomFieldsEditLayoutsModal.vue'),
            TabsMenu,
            Multiselect,
            Loading
        },
        data() {
            return {
                pages: [
                    { name: 'Customer', nameRoute: 'CustomFieldsCommercialJobCustomer' },
                    { name: 'Property', nameRoute: 'CustomFieldsCommercialJobProperty' },
                    { name: 'Unit', nameRoute: 'CustomFieldsCommercialJobUnit' },
                    { name: 'Job', nameRoute: 'CustomFieldsCommercialJob' }
                ],
                isLayoutsLoading: false,
                layouts: [],
                lastOrder: 0,
                currentLayout: null,
                showAddTemplateModal: false,
                showEditTemplatesModal: false
            }
        },
        computed: {
            isJobPage() {
                return this.$route.name === CUSTOM_FIELDS_PAGES.COMMERCIAL_JOB.JOB;
            }
        },
        methods: {
            getLayouts() {
                this.isLayoutsLoading = true;

                // show loader for parent component
                this.$emit('showLayoutLoader');
                
                api.get(`/layouts/model-name?model_name=${MODEL_NAMES.COMMERCIAL_JOB}&with=jobType`)
                    .then(response => {
                        this.layouts = response.data.data;

                        if (this.layouts.length) {
                            this.lastOrder = this.layouts.reduce(function(prev,cur) {
                                return cur.main_section_order > prev.main_section_order ? cur : prev;
                            }).main_section_order;
                            this.checkCurrentLayout();
                        } else {
                            this.$emit('getLayoutSections', null);
                        }
                    })
                    .finally(() => {
                        this.isLayoutsLoading = false;
                    });
            },
            checkCurrentLayout(){
                this.currentLayout = this.layouts.find(layout => layout.name === this.$route.query.type);
                if (!this.currentLayout) {
                    this.$router.push({ query: { type: this.layouts[0].name }});
                    this.currentLayout = this.layouts[0];
                }
                this.$emit('getLayoutSections', this.currentLayout.id);
            },
            changeLayout(layout) {
                if (layout.name === this.currentLayout.name) {
                    return;
                }
                this.currentLayout = layout;
                this.$router.push({ query: { type: layout.name }});
                this.$emit('getLayoutSections', this.currentLayout.id);
            },
            addTemplate(data) {
                this.layouts.push(data);
                this.lastOrder = data.main_section_order;
                this.showAddTemplateModal = false;
            },
            closeModal(data) {
                this.showEditTemplatesModal = false;
                if (data != null) {
                    this.layouts = data;
                    this.checkCurrentLayout();
                }
            }
        },
        created() {
            if (this.isJobPage) {
                this.getLayouts();
            } else {
                this.$emit('getLayout');
            }
        }
    }
</script>