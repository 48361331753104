<template>
    <full-modal 
        @close="$emit('close', updatedData);"
        :full="!itemForEdit" 
    >
        <div 
            v-if="!itemForEdit" 
            slot="title"
        >
            Job type list
        </div>
        <div v-else slot="title">
            <button
                @click="backToJobList()" 
                class="transparent-button f-13-grey f-sbold"
            >
                <arrow-icon 
                    transform="180" 
                    size="8"
                    class="mr-4"
                />
                Back to job type list
            </button>
        </div>
        <div slot="body">
            <div 
                v-if="itemForEdit"
                class="row-between mb-4"
            >
                <span class="f-sbold">Job type settings</span>
                <checkbox-button 
                    color="blue"
                    label="Job type"
                    class="no-wrap"
                    labelDirection="left"
                >
                    <input 
                        type="checkbox"
                        name="lead_type"
                        v-model="itemForEdit.has_lead_type"
                        disabled
                    />
                </checkbox-button>
            </div>
            <loader 
                v-if="loading" 
                size="small"
            />
            <div 
                v-else 
                class="form-builder-template-list w-100"
            >
                <div v-if="!itemForEdit">
                    <div 
                        :class="[
                            `form-builder-template-item row-start mb-1 grabbable form-builder-template-item${index}`,
                            { 'template-item-placeholder' : draggableOverIndex == index}
                        ]"
                        v-for="(item, index) in editableItems"
                        :key="`item-${index}`"
                        :draggable="true"
                        @dragstart="itemDragStart(index)"
                        @dragenter="itemDragEnter(index)" 
                        @dragend="itemDragEnd(index)"
                    >
                        <div 
                            class="static-col-1 s-align-4"
                            v-if="isDragEvent"
                        >
                            <drag-icon class="i-grey"/>
                        </div>
                        <div :class="['column-start f-14-black s-align-2', isDragEvent ? 'static-col-8' : 'static-col-9']">
                            {{ item.name }}
                        </div>
                        <div class="static-col-3 s-align-6">
                            <toggle-switch>
                                <input 
                                    type="checkbox"
                                    :name="`is_active_${index}`"
                                    v-model="item.is_active"
                                    @change="save(item)"
                                    disabled
                                />
                            </toggle-switch>
                            <button 
                                class="transparent-button ml-30 p-2"
                                @click="itemForEdit = item"
                            >
                                <edit-icon 
                                    size="14"
                                    class="i-grey"
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="column-start static-col-12">
                        <form-input 
                            :class="['mb-1 w-100', { 'form-input-danger' : errors.has(`report-name-${itemForEdit.id}`) }]"
                            label="Job type name"
                        >
                            <input 
                                type="text" 
                                :name="`report-name-${itemForEdit.id}`" 
                                :id="`report-name-${itemForEdit.id}`"
                                class="form-input-field"
                                placeholder="Job type name"
                                v-model="itemForEdit.name"
                                v-input-style="itemForEdit.name"
                                v-validate="'required|max:250'"
                                disabled
                            />
                        </form-input>
                        <div class="row-between">
                            <div class="form-builder-template-list-row-item">
                                <form-input 
                                    :class="[{ 'form-input-danger' : errors.has(`abbreviation-${itemForEdit.id}`) }]"
                                    label="Abbreviation"
                                >
                                    <input 
                                        type="text" 
                                        :name="`abbreviation-${itemForEdit.id}`" 
                                        :id="`abbreviation-${itemForEdit.id}`"
                                        class="form-input-field"
                                        placeholder="Abbreviation"
                                        v-model="itemForEdit.abbreviation"
                                        v-input-style="itemForEdit.abbreviation"
                                        v-validate="'required|max:250'"
                                        disabled
                                    />
                                </form-input>
                            </div>
                            <div class="form-builder-template-list-row-item">
                                <form-input 
                                    label="Milestone Set"
                                    v-if="itemForEdit.job_type && milestoneSet"
                                >
                                    <multiselect
                                        :options="milestoneSet.map(item => item.id)"
                                        :custom-label="opt => milestoneSet.find(x => x.id == opt).name"
                                        :max-height="200"
                                        v-input-style="itemForEdit"
                                        data-name="`milestone_set`"
                                        v-model="itemForEdit.job_type.group_id"
                                        :show-labels="false"
                                        disabled
                                    />
                                </form-input>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 bt-grey mt-30">
                        <p class="f-14-black f-sbold mb-1 mt-30">
                            Would you like to delete this job type?
                        </p>
                        <p 
                            v-if="!itemForEdit.has_usage"
                            class="f-13-grey"
                        >
                            Once the job type is deleted, it is cannot be restored
                        </p>
                        <p  
                            class="f-13-grey" 
                            v-else
                        >
                            This job type is already being used in jobs or leads, it cannot be deleted. 
                            To remove it from the available job and job type list, change its settings to inactive.
                        </p>
                        <button 
                            v-if="!itemForEdit.has_usage"
                            class="transparent-button f-13-red f-sbold mt-5"
                            @click="showDeleteConfirm = true"
                        >
                            <trash-icon 
                                class="i-red mr-5"
                                size="15"
                            />
                            Delete job type
                        </button>
                        <modal
                            v-if="showDeleteConfirm"
                            @close="showDeleteConfirm = !showDeleteConfirm"
                            class="section-delete-confirm"
                        >
                            <div slot="modal-body">
                                <div class="w-100 column-center pb-4">
                                    <span class="f-14-black pb-2">
                                        Are you sure want to delete '{{ itemForEdit.name }}' job type?
                                    </span>
                                </div>
                            </div>
                            <div slot="modal-footer">
                                <div class="row-between">
                                    <button 
                                        class="add-button"
                                        @click="showFieldDeleteConfirm = false"
                                        :disabled="disableBtns"
                                    >
                                        Cancel
                                    </button>
                                    <button 
                                        class="delete-button mr-4"
                                        @click="removeItem(itemForEdit)"
                                        :disabled="disableBtns"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </modal>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="itemForEdit" 
            slot="footer"
        >
            <button
                class="add-button mr-4"
                @click="backToJobList()"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                @click="save()"
                :disabled="requestIsGoing"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, FormInput, Loader, ToggleSwitch, CheckboxButton, Modal } from '@/ui';
    import { DragIcon, TrashIcon, AddIcon, EditIcon, ArrowIcon } from '@/icons';
    import cloneDeep from 'lodash/cloneDeep';
    import api from '@/headers.js';
    import Multiselect from 'vue-multiselect';
    import { MODEL_NAMES } from '@/constants';
    import { createGhost } from '@/helpers';

    export default {
        name: 'CustomFieldsEditLayoutsModal',
        components: {
            FullModal,
            DragIcon,
            TrashIcon,
            FormInput,
            Multiselect,
            Loader,
            AddIcon,
            EditIcon,
            ToggleSwitch,
            ArrowIcon,
            CheckboxButton,
            Modal
        },
        props: {
            items: {
                type: Array,
                required: true
            },
            type: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                requestIsGoing: false,
                loading: false,
                dragIndex: null,
                dragItem: null,
                editableItems: [],
                itemForEdit: null,
                itemForRemove: [],
                milestoneSet: null,
                commercialJobType: MODEL_NAMES.COMMERCIAL_JOB,
                updatedData: null,
                showDeleteConfirm: false,
                disableBtns: false,
                draggableOverIndex: null
            }
        },
        computed: {
            isDragEvent() {
                return this.nScreen || this.dScreen;
            }
        },
        methods: {
            getMilestoneSet() {
                this.loading = true;

                api.get('/milestone-sets?with=milestones')
                    .then((response) => {
                        this.milestoneSet = response.data.data;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            itemDragStart(index) {
                this.dragIndex = index;
                this.dragItem = this.editableItems[index];
                createGhost(document.querySelector(`.form-builder-template-item${index}`), 'template-item-draggable');
            },
            itemDragEnter(index) {
                this.draggableOverIndex = index;
                if (this.dragIndex == index) return false;

                this.editableItems.splice(this.dragIndex, 1);
                this.editableItems.splice(index, 0, this.dragItem);

                for (let i = 0; i < this.editableItems.length; i++) {
                    this.editableItems[i].main_section_order = i;
                }
                this.dragIndex = index;
            },
            itemDragEnd(index) {
                this.draggableOverIndex = null;
                this.dragIndex = null;
                this.dragItem = null;
                this.save();
            },
            removeItem(item) {
                let index = this.editableItems.findIndex(el => el.id == item.id);
                if(!this.editableItems[index].is_custom) {
                    this.notifyError('Sorry, you cannot delete default reports.');
                    this.disableBtns = false;
                    return;
                }
                this.disableBtns = true;
                this.itemForRemove.push(this.editableItems[index]);
                this.editableItems.splice(index, 1);
                this.save();
            },
            save(active) {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('Fill in required fields!'); 

                        return;
                    }

                    let params = {
                        layouts: this.editableItems,
                        layouts_for_remove: this.itemForRemove
                    };

                    if (this.type == this.commercialJobType) {
                        params.entityType = this.type;
                    }

                    this.requestIsGoing = true;
                    api.put(`/layouts/layouts`, params)
                        .then((response) => {
                            this.updatedData = response.data.data;
                            this.notifySuccess('Successfully edited settings templates!');
                            if (!active) {
                                this.backToJobList();
                            }
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.requestIsGoing = false;
                            this.showDeleteConfirm = false;
                            this.disableBtns = false;
                        });
                });
            },
            backToJobList() {
                this.$validator.pause();
                this.itemForEdit = null;
                this.$nextTick(() => {
                    this.$validator.reset();
                    this.$validator.resume();
                }); 
            }
        },
        created() {
            this.editableItems = cloneDeep(this.items);

            if (this.type == this.commercialJobType) {
                this.getMilestoneSet();
            }   
        }
    }
</script>