<template>
    <svg 
        viewBox="0 0 82.83458709716797 56.37790298461914" 
        :width="size" 
        :height="size"
        class="full-upload-icon"
    >
        <g transform="matrix(2.7068233489990234, 0, 0, 2.7068233489990234, 1166.6902704238892, -759.5757741928101)">
            <path d="M-405.3,289.1c0-0.1,0-0.2,0-0.3c0-4.3-3.5-7.7-7.7-7.7c-3.1,0-5.7,1.8-7,4.4c-0.7-0.5-1.5-0.8-2.4-0.8c-2.3,0-4.1,1.8-4.1,4c0,0.3,0,0.6,0.1,0.9c-2.6,0.6-4.6,2.9-4.6,5.7c0,3.2,2.6,5.7,5.7,5.7h8v-6.3h-2.7c-0.3,0-0.4-0.3-0.2-0.5l4-4.3c0.1-0.1,0.3-0.1,0.4,0l4,4.3c0.2,0.2,0,0.5-0.2,0.5h-2.7v6.3h8.3c3,0,5.5-2.6,5.5-6C-401,292.1-402.8,289.7-405.3,289.1z"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'FullUploadIcon',
    props: {
        size: {
            type: [Number, String],
            default: 28
        }
    }
}
</script>