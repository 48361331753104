<template>
    <div class="tabs-menu w-100 relative bb-grey">
        <div 
            class="tubs-menu-loading w-100 h-100 s-align-5" 
            v-if="!measured"
        >
            <loading class="i-blue"/>
        </div>
        <div 
            :class="[
                'tabs-menu-wrapper w-100 row-between relative hidden', 
                { 'opacity-0' : !measured }
            ]"
        >
            <button 
                :class="['tabs-menu-to h-100', { 'tabs-menu-to-tinted': !atTheBeginning && backlight }]"
                v-if="buttonsVisible"
                @click="scrollLinks(SCROLL_TO_LEFT)"
                :disabled="atTheBeginning"
            >
                <arrow-icon 
                    transform="180" 
                    size="13"
                />
            </button>
            <nav class="tabs-menu-panel relative hidden" :class="{ 'tabs-menu-panel-padding': isTabsMenuPanelPadding }">
                <ul
                    class="tabs-menu-links w-100"
                    :id="identifier"
                    ref="links"
                >
                    <slot name="links"></slot>
                </ul>
            </nav>
            <button 
                :class="['tabs-menu-back h-100', { 'tabs-menu-back-tinted' : !atTheEnd && backlight }]"
                v-if="buttonsVisible"
                @click="scrollLinks(SCROLL_TO_RIGHT)"
                :disabled="atTheEnd"
            >
                <arrow-icon 
                    transform="0" 
                    size="13"
                />
            </button>
        </div>
    </div>
</template>

<script>
    /*
    * backlight - for buttons
    *
    * scrollToStep - scroll to the selected menu item
    * 
    * identifier - unique identifier for the tabs-menu
    * needs for listeners to work correctly
    */

    import { ArrowIcon, Loading } from '@/icons';
    import { getWidth } from '@/helpers';
    import cloneDeep from 'lodash/cloneDeep';

    export default {
        name: 'TabsMenu',
        components: {
            ArrowIcon,
            Loading
        },
        props: {
            backlight: {
                type: Boolean,
                default: false
            },
            scrollToStep: {
                type: Number,
                required: false
            },
            identifier: {
                type: String,
                required: true
            },
            isTabsMenuPanelPadding: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                wrapperWidth: 0,
                linksWidth: 0,
                step: 0,
                steps: [],
                measured: false,
                current: 0,
                SCROLL_TO_RIGHT: 'to right',
                SCROLL_TO_LEFT: 'to left'
            }
        },
        computed: {
            buttonsVisible() {
                return this.wrapperWidth < this.linksWidth;
            },
            limit() {
                return this.linksWidth - this.wrapperWidth;
            },
            atTheBeginning() {
                return this.current == 0;
            },
            atTheEnd() {
                return this.step >= this.limit;
            }
        },
        methods: {
            getLinksWidth() {
                if (this.measured) return false;

                this.measured = true;
                this.wrapperWidth = getWidth(this.$refs.links);

                let menuItems = this.$el.querySelectorAll('.tabs-menu-item');

                // links can be of different lengths, need to measure each of them
                [].slice.call(menuItems).map((link) => {
                    this.steps.push(getWidth(link));
                    this.linksWidth = this.linksWidth + getWidth(link);
                });

                // scroll to the pre-selected item
                if (this.scrollToStep != undefined) {
                    if (this.scrollToStep >= 2 && !this.mScreen) {
                        this.current = this.scrollToStep - 2;
                    } else if (this.mScreen) {
                        this.current = this.scrollToStep == 0 ? 0 : this.scrollToStep - 1;
                    }
    
                    // counting steps before the pre-selected item
                    if (this.current > 0) {
                        const takenSteps = this.steps.slice(0, this.current);
        
                        this.step = takenSteps.reduce(function(prev, next) {
                            return prev + next;
                        });

                        this.$nextTick(function() {
                            this.scrollLinks(this.SCROLL_TO_RIGHT);
                            
                            // if the user has the ability to use the buttons 
                            // and tabs-menu is set at the end, 
                            // it needs to reset the steps to the first hidden
                            if (this.atTheEnd && this.dScreen) {
                                let stepsBack = 0;
                                let stepsBackCount = 0;
                                const allSteps = cloneDeep(this.steps).reverse();
    
                                for (let i = 0; i < allSteps.length; i++) {
                                    stepsBack = stepsBack + allSteps[i];
                                    stepsBackCount++;
    
                                    if (stepsBack >= this.wrapperWidth)
                                        break;
                                }
    
                                this.current = this.steps.length - stepsBackCount;
                                this.step = this.linksWidth - stepsBack;
                            }
                        });
                    }
                }
            },
            scrollLinks(direction) {
                switch(direction) {
                    case this.SCROLL_TO_RIGHT :
                        this.step = this.step + this.steps[this.current];
                        this.current++;                       
                    break;
                    case this.SCROLL_TO_LEFT :
                        this.current--;
                        this.step = this.step - this.steps[this.current];
                    break;
                };

                this.$refs.links.scrollTo({
                    left: this.step,
                    behavior: "smooth"
                });
            }
        },
        mounted() {
            // the width of all children must be measured for scrolling. 
            // since they are not props, it is necessary to wait 
            // for the dom-elements to load and check them
            const intervalID = setInterval(() => {
                if (document.getElementById(this.identifier).children[0]) {
                    this.getLinksWidth();
                    clearInterval(intervalID);
                }
            }, 300);
        }
    }
</script>