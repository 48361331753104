import store from '@/store/store';

export const emailMessage = {
    data() {
        return {
            needConfirmAction: false
        }
    },
    methods: {
        openCollapsedModal() {
            const modal = document.querySelector('.modal');
            const modalAlign =  document.querySelector('.modal > .h-100');

            if (modal.classList.contains('email-fullscreen-modal-collapse')) {
                modal.classList.remove('email-fullscreen-modal-collapse');
                this.$store.commit('emailMessage/collapseModal', false);
            } else {
                this.$store.commit('emailMessage/collapseModal', true);
            }

            if (modal.classList.contains('resize-fullscreen-modal')) {
                modalAlign.classList.remove('s-align-5');
                modalAlign.classList.add('s-align-9');
            } else {
                modalAlign.classList.remove('s-align-9');
                modalAlign.classList.add('s-align-5');
            }
        },
        closeEmailModal(val, signature) {
            const createSignature = `<p><br></p><p><br></p><p><br></p><div><span class="ql-font-arial" style="font-size: 16px; color: rgb(16, 31, 50);">${signature}</span></div>`;

            if (createSignature === val || !val) {
                store.commit('emailMessage/openModal', false);
                return;
            }

            this.needConfirmAction = true;
        },
        confirmCloseEmailModal() {
            this.needConfirmAction = false;
            store.commit('emailMessage/openModal', false);
        }
    }
}
