<template>
    <div class="form-website-group">
        <span
            :class="['protocol-text', { 'danger' : dangerField }]"
        >
            {{ protocol }}
        </span>

        <slot name="default" />
    </div>
</template>

<script>
    export default {
        props: {
            protocol: {
                type: String,
                default: ''
            },
            dangerField: {
                type: Boolean
            }
        }
    };
</script>
