<template>
    <transition name="tabs-full" appear>
        <div class="modal tabs-full-modal">
            <div class="s-align-9 column-end h-100 w-100">
                <div class="tabs-full-content">
                    <div class="tabs-full-title row-between">
                        <h2 class="f-24-darkgrey f-sbold">Company Library</h2>
                        <button 
                            class="transparent-button"
                            @click="$emit('close')"
                        >
                            <close-icon 
                                size="13" 
                                class="i-grey"
                            />
                        </button>
                    </div>
                    <div class="row-static w-100">
                        <button 
                            :class="[
                                'list-items-tab s-align-5 w-50', 
                                { 'materials-tab-active relative' : currentType == MATERIAL }
                            ]"
                            :disabled="loading"
                            @click="switchTab(MATERIAL)"
                        >
                            <span class="list-items-tab-name">Materials</span>
                        </button>
                        <button 
                            :class="[
                                'list-items-tab s-align-5 w-50', 
                                { 'labors-tab-active relative' : currentType == LABOR }
                            ]"
                            :disabled="loading"
                            @click="switchTab(LABOR)"
                        >
                            <span class="list-items-tab-name">Labors</span>
                        </button>
                    </div>
                    <div class="search-wrapper w-100">
                        <div 
                            :class="[
                                'search s-align-4', 
                                { 'search-adhered-materils' : currentType == MATERIAL && searchAdhered },
                                { 'search-adhered-labors' : currentType == LABOR && searchAdhered }
                            ]"
                        >
                            <button class="transparent-button">
                                <search-icon class="i-grey"/>
                            </button>
                            <input 
                                type="search" 
                                name="search" 
                                placeholder="Search..."
                                v-model="needToFind"
                                @input="searchItems"
                            />
                        </div>
                    </div>
                    <loader 
                        v-if="loading" 
                        size="small"
                    />
                    <div 
                        class="tabs-full-items w-100"
                        v-if="currentType == MATERIAL && !loading"
                    >
                        <div 
                            class="row-center t-center pv-8"
                            v-if="!materials.length"
                        >
                            <span class="f-14-grey">There is no material</span>
                        </div>
                        <div 
                            class="tabs-full-item mb-4"
                            v-for="(material, materialIndex) in materials" 
                            :key="materialIndex"
                            v-else
                        >
                            <div class="row-between">
                                <div class="column-start lg-8 md-8 sm-12 f-sbold mb-mob-1">
                                    <div class="w-100 s-align-4">
                                        <div class="s-align-5 template-material-label mr-8">
                                            <span class="f-10-white f-sbold l-spacing-02">
                                                Materials
                                            </span>
                                        </div>
                                        <span 
                                            class="added-label s-align-4 mr-8"
                                            v-if="setAddedStatus(material.id, MATERIAL)"
                                        >
                                            <span class="added-mark s-align-5">
                                                <mark-icon 
                                                    size="7" 
                                                    class="i-white"
                                                />
                                            </span>
                                            <span class="f-10-green pl-1">Added</span>
                                        </span>
                                    </div>
                                    <p class="f-14-black pt-4 w-100">{{ material.name }}</p>
                                    <p class="f-14-grey w-100">
                                        Cost:
                                        <span class="f-14-black mr-2">
                                            {{ +material.cost == 0 ? '-' : +material.cost | currency }}
                                        </span>
                                        Unit:
                                        <span class="f-14-black">
                                            {{ material.order_unit }}
                                        </span>
                                    </p>
                                </div>
                                <div class="lg-4 md-4 sm-12 s-align-6">
                                    <button 
                                        class="service-middle-button"
                                        @click="$emit('addMaterial', MATERIAL, materials[materialIndex])"
                                    >
                                        <span class="f-12-blue f-sbold">Add</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div 
                        class="tabs-full-items w-100"
                        v-if="currentType == LABOR && !loading"
                    >
                        <div 
                            class="row-center t-center pv-8"
                            v-if="!labors.length"
                        >
                            <span class="f-14-grey">There is no labor</span>
                        </div>
                        <div 
                            class="tabs-full-item mb-4"
                            v-for="(labor, laborIndex) in labors" 
                            :key="laborIndex"
                            v-else
                        >
                            <div class="row-between">
                                <div class="column-start lg-8 md-8 sm-12 f-sbold mb-mob-1">
                                    <div class="w-100 s-align-4">
                                        <div class="s-align-5 template-labor-label mr-8">
                                            <span class="f-10-white f-sbold l-spacing-02">
                                                Labors
                                            </span>
                                        </div>
                                        <span 
                                            class="added-label s-align-4 mr-8"
                                            v-if="setAddedStatus(labor.id, LABOR)"
                                        >
                                            <span class="added-mark s-align-5">
                                                <mark-icon 
                                                    size="7" 
                                                    class="i-white"
                                                />
                                            </span>
                                            <span class="f-10-green pl-1">Added</span>
                                        </span>
                                    </div>
                                    <p class="f-14-black pt-4 w-100">{{ labor.name }}</p>
                                    <p class="f-14-grey w-100">
                                        Cost:
                                        <span class="f-14-black mr-2">
                                            {{ +labor.cost == 0 ? '-' : +labor.cost | currency }}
                                        </span>
                                        Unit:
                                        <span class="f-14-black">
                                            {{ labor.order_unit }}
                                        </span>
                                    </p>
                                </div>
                                <div class="lg-4 md-4 sm-12 s-align-6">
                                    <button 
                                        class="service-middle-button"
                                        @click="$emit('addLabor', LABOR, labors[laborIndex])"
                                    >
                                        <span class="f-12-blue f-sbold">Add</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div 
                        v-if="loadMoreButtonShown"
                        class="row-center pb-25"
                    >
                        <load-more
                            @click.native="getItems"
                            :status="loadingMore"
                        />
                    </div>
                </div>
                <div class="tabs-full-modal-footer row-end">
                    <button 
                        class="primary-button"
                        @click="$emit('close')"
                    >
                        Done!
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import { CloseIcon, SearchIcon, MarkIcon } from '@/icons';
    import { MATERIAL, LABOR, COMPANY_LIBRARY_TYPES } from '@/constants';
    import { Loader, LoadMore } from '@/ui';
    import { clearArray } from '@/helpers';
    import api from '@/headers.js';
    import debounce from 'lodash/debounce';

    export default {
        name: 'AddLibraryItemsModal',
        components: {
            CloseIcon,
            SearchIcon,
            MarkIcon,
            Loader,
            LoadMore
        },
        props: {
            // items already added to the template or estimate
            added: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                currentType: Number,
                needToFind: '',
                searchAdhered: false,
                materials: [],
                labors: [],
                isMoreMaterials: false,
                isMoreLabors: false,
                materialsCurrentPage: 1,
                laborsCurrentPage: 1,
                materialsSearchResult: false,
                laborsSearchResult: false,
                loading: false,
                loadingMore: false
            }
        },
        computed: {
            loadMoreButtonShown() {
                switch (this.currentType) {
                    case MATERIAL:
                        return !this.loading && this.isMoreMaterials;
                        break;
                    case LABOR:
                        return !this.loading && this.isMoreLabors;
                        break;
                }
            }
        },
        methods: {
            setAddedStatus(id, type) {
                // id - material or labor id
                // type - MATERIAL or LABOR
                
                if (!this.added.length) return false;

                let res = 0;
                this.added.forEach((i, index) => {
                    if (i.type_id == type && (id == i.material_id || id == i.labor_id)) {
                        res++;
                    }
                });

                return res;
            },
            getItems() {
                let params = {
                    per_page: 50
                };

                switch (this.currentType) {
                    case MATERIAL:
                        params.type = COMPANY_LIBRARY_TYPES.ALL_MATERIALS;
                        params.page = this.materialsCurrentPage;

                        if (this.materialsCurrentPage === 1) {
                            this.loading = true;
                            clearArray(this.materials);
                        } else {
                            this.loadingMore = true;
                        }
                        break;
                    case LABOR:
                        params.type = COMPANY_LIBRARY_TYPES.LABORS;
                        params.page = this.laborsCurrentPage;

                        if (this.laborsCurrentPage === 1) {
                            this.loading = true;
                            clearArray(this.labors);
                        } else {
                            this.loadingMore = true;
                        }
                        break;
                }                

                if (this.needToFind) {
                    params.search_value = this.needToFind;
                }
                
                api.get('/company-library', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        switch (this.currentType) {
                            case MATERIAL:
                                this.materials.push(...data.materials.data);
                                this.isMoreMaterials = data.materials.current_page !== data.materials.last_page;
                                this.materialsCurrentPage++;
                                this.materialsSearchResult = !!this.needToFind;
                                break;
                            case LABOR:
                                this.labors.push(...data.labors.data);
                                this.isMoreLabors = data.labors.current_page !== data.labors.last_page;
                                this.laborsCurrentPage++;
                                this.laborsSearchResult = !!this.needToFind;
                                break;
                        }
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.loadingMore = false;
                    });
            },
            switchTab(type) {
                this.currentType = type;
                this.needToFind = '';

                switch (type) {
                    case MATERIAL:
                        if (this.materialsSearchResult) {
                            this.materialsCurrentPage = 1;
                            this.getItems();
                        } else if (!this.materials.length) {
                            this.getItems();
                        }
                        break;
                    case LABOR:
                        if (this.laborsSearchResult) {
                            this.laborsCurrentPage = 1;
                            this.getItems();
                        } else if (!this.labors.length) {
                            this.getItems();
                        }
                        break;
                }
            },
            searchItems: debounce(function() {
                switch (this.currentType) {
                    case MATERIAL:
                        this.materialsCurrentPage = 1;
                        break;
                    case LABOR:
                        this.laborsCurrentPage = 1;
                        break;
                }
                this.getItems();
            }, 500)
        },
        created() {
            this.MATERIAL = MATERIAL;
            this.LABOR = LABOR;
            this.currentType = this.MATERIAL;

            this.getItems();
        },
        mounted() {
            const content = this.$el.querySelector('.tabs-full-content');
            const search = this.$el.querySelector('.tabs-full-content .search');

            content.addEventListener('scroll', (event) => {
                if (event.target.scrollTop >= 180) {
                    search.classList.add('search-adhered');
                    this.searchAdhered = true;
                } else {
                    search.classList.remove('search-adhered');
                    this.searchAdhered = false;
                }
            });
        }
    }
</script>