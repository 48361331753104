var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{class:[
    's-align-5 ph-5 pv-3 hidden', 
    {
        'tag-active': _vm.active,
        'tag-default': !_vm.active,
        'tag-disabled': _vm.disabled,
        'pointer': !_vm.disabled
    }
]},[_c('span',{staticClass:"f-sbold"},[_vm._v(_vm._s(_vm.tag.name))]),(!_vm.active && !_vm.disabled)?_c('button',{staticClass:"transparent-button ml-3",on:{"click":function($event){return _vm.$emit('addTag', _vm.tag)}}},[_c('span',{staticClass:"f-16-blue f-sbold"},[_vm._v("+")])]):_vm._e(),(_vm.active && !_vm.disabled)?_c('button',{staticClass:"transparent-button ml-3",on:{"click":function($event){return _vm.$emit('removeTag', _vm.tag)}}},[_c('close-icon',{staticClass:"i-white",attrs:{"size":"7"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }