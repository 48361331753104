<template>
    <div class="w-100">
        <div
            class="preview-page-table w-100 pt-20"
            v-for="(section, index) in sections"
            :key="index"
        >
            <perfect-scrollbar
                class="ps ps-grey pb-8"
                :key="`scroll-${index}`"
            >
                <table>
                    <tbody>
                        <tr>
                            <td
                                colspan="2"
                                align="left"
                                class="pl-20 pb-2 w-50"
                            >
                                <p class="f-14-black f-sbold">{{ section.name }}</p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <p
                                    class="preserve-whitespaces break-all w-100 f-12-black ph-20"
                                    v-if="showSectionDescription && section.description"
                                >
                                    {{ section.description }}
                                </p>
                            </td>
                        </tr>
                        <tr v-if="showMaterials || showLabors">
                            <td colspan="2">
                                <table class="tp-4">
                                    <thead>
                                        <tr>
                                            <th
                                                align="left"
                                                class="w-60 pl-20"
                                            >
                                                <span class="f-9-black f-sbold">Name</span>
                                            </th>
                                            <th align="left">
                                                <span
                                                    class="f-9-black f-sbold"
                                                    v-if="showQuantitiesUnits"
                                                >
                                                    QTY
                                                </span>
                                            </th>
                                            <th align="left">
                                                <span
                                                    class="f-9-black f-sbold"
                                                    v-if="showQuantitiesUnits"
                                                >
                                                    Unit
                                                </span>
                                            </th>
                                            <th align="left">
                                                <span
                                                    class="f-9-black f-sbold"
                                                    v-if="showPerUnitCharge"
                                                >
                                                    Per Unit
                                                </span>
                                            </th>
                                            <th align="right"></th>
                                        </tr>
                                    </thead>
                                    <tbody
                                        v-for="(item, itemIndex) in sectionItems(section)"
                                        :key="`section-item-${itemIndex}`"
                                    >
                                        <tr>
                                            <td :class="['f-12-black pt-1 pb-2 pl-20', { 'bb-grey': !item.description || !showItemsDescriptions }]">
                                                {{ !item.estimate_name ? item.name : item.estimate_name }}
                                            </td>
                                            <td :class="['f-12-black pt-1 pb-2', { 'bb-grey': !item.description || !showItemsDescriptions }]">
                                                <span v-if="showQuantitiesUnits">{{ item.quantity }}</span>
                                            </td>
                                            <td :class="['f-12-black pt-1 pb-2', { 'bb-grey': !item.description || !showItemsDescriptions }]">
                                                <span v-if="showQuantitiesUnits">{{ item.unit }}</span>
                                            </td>
                                            <td :class="['f-12-black pt-1 pb-2', { 'bb-grey': !item.description || !showItemsDescriptions }]">
                                                <span v-if="showPerUnitCharge">{{ showTax ? item.per_unit_with_tax : item.per_unit_charge | currency }}</span>
                                            </td>
                                            <td
                                                :class="['f-12-black f-sbold pt-1 pb-2 pr-20', { 'bb-grey': !item.description || !showItemsDescriptions }]"
                                                align="right"
                                            >
                                                <span v-if="showLineItemPrice">{{ showTax ? item.price_with_tax : item.price | currency }}</span>
                                            </td>
                                        </tr>
                                        <tr v-if="item.description != null && showItemsDescriptions">
                                            <td colspan="5">
                                                <p class="w-100 f-10-grey ph-20 bb-grey pb-2">
                                                    <span class="preserve-whitespaces break-all w-100">{{ item.description }}</span>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <template v-if="showSectionCostOut && (showLabors || showMaterials)">
                            <tr class="w-100 ml-4 mr-4">
                                <td></td>
                                <td
                                    align="right"
                                    valign="top"
                                    class="w-15 pv-4 pr-4 no-padding-right"
                                >
                                    <p
                                        class="f-14-black f-sbold p-4 bt-grey bb-grey"
                                        v-if="showLabors"
                                    >
                                      Labor total {{ showTax ? section.price_total_with_tax_labor : section.total_price_labor | currency }}
                                    </p>
                                    <p
                                        class="f-14-black f-sbold p-4 bb-grey"
                                        v-if="showMaterials"
                                    >
                                        Material total {{ showTax ? section.price_total_with_tax_material : section.total_price_material  | currency }}
                                    </p>
                                </td>
                            </tr>
                        </template>
                        <tr class="w-100 ml-4 mr-4">
                            <td
                                align="left"
                                valign="top"
                                class="pv-4 pl-8 no-padding-right"
                            >
                                <div class="f-14-black f-sbold p-4 bg-grey">Section total</div>
                            </td>
                            <td
                                align="right"
                                valign="top"
                                class="pv-4 pr-4"
                            >
                                <p class="f-14-black f-sbold p-4 bg-grey">
                                    {{ priceTotalWithTax(section) | currency }}
                                </p>
                                <p
                                    class="s-align-6 w-100 mt-4"
                                    v-if="showTax && section.tax_percent"
                                >
                                    <currency-icon
                                        class="i-darkgrey"
                                        width="9"
                                        height="12"
                                    />
                                    <span class="f-10-black pl-1">{{ taxTotal(section) }}</span>
                                    <span class="f-10-darkgrey pl-1">({{ section.tax_percent }}%)</span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script>
    import { CurrencyIcon } from '@/icons';

    export default {
        name: 'EstimatePreviewTable',
        data() {
            return {
                TYPE_MATERIAL: 'Material',
                TYPE_LABOR: 'Labor'
            }
        },
        props: {
            sections: {
                type: Array,
                required: true
            },
            showTax: {
                type: Boolean,
                default: true
            },
            showSectionDescription: {
                type: Boolean,
                default: true
            },
            showQuantitiesUnits: {
                type: Boolean,
                default: true
            },
            showPerUnitCharge: {
                type: Boolean,
                default: true
            },
            showLineItemPrice: {
                type: Boolean,
                default: true
            },
            showItemsDescriptions: {
                type: Boolean,
                default: true
            },
            showSectionCostOut: {
                type: Boolean,
                default: true
            },
            showLabors: {
                type: Boolean,
                default: true
            },
            showMaterials: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            sectionItems(section) {
                if (!this.showLabors && this.showMaterials) {
                    return section.items.filter(item => {
                        return item.type === this.TYPE_MATERIAL;
                    });
                } else if (this.showLabors && !this.showMaterials) {
                    return section.items.filter(item => {
                        return item.type === this.TYPE_LABOR;
                    });
                }

                return section.items;
            },
            priceTotalWithTax(section) {
                if (!this.showLabors && this.showMaterials) {
                    return this.showTax
                        ? section.price_total_with_tax_material
                        : section.total_price_material;
                } else if (this.showLabors && !this.showMaterials) {
                    return this.showTas
                        ? section.price_total_with_tax_labor
                        : section.total_price_labor;
                }

                return this.showTax ? section.price_total_with_tax : section.total_price;
            },
            taxTotal(section) {
                if (!this.showLabors && this.showMaterials) {
                    return section.tax_total_material;
                } else if (this.showLabors && !this.showMaterials) {
                    return section.tax_total_labor;
                }

                return section.tax_total;
            }

        },
        components: {
            CurrencyIcon
        }
    }
</script>
