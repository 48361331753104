<template>
    <full-modal 
        @close="$emit('close')"
        class="assign-salesman-modal"
    >
        <div slot="title">Assign salesman</div>
        <div slot="body">
            <div class="modal-body-sticky">
                    <p class="f-14-black f-sbold mb-4">
                        Select a salesman {{ existingSalesmanId ? '' : 'and an appointment date' }}
                    </p>
                    <multiselect
                        v-model="assignedUser"
                        :class="['mb-4', { 'multiselect-danger' : errors.has('assign_to') }]"
                        placeholder="Assign to"
                        label="Assign to"
                        track-by="first_name"
                        name="assign_to"
                        data-name="assign_to"
                        :options="showUnassignedUser"
                        :option-height="104"
                        :show-labels="false"
                        :allow-empty="false"
                        :custom-label="customLabel"
                        :disabled="sellersLoading"
                        v-validate="'required'"
                        @select="getSalesmanCalendars"
                    >
                        <template
                            slot="singleLabel"
                            slot-scope="props"
                        >
                            <template v-if="props.option.first_name">
                                <user-box
                                    :firstName="props.option.first_name"
                                    :lastName="props.option.last_name"
                                    :avatarUrl="props.option.avatar"
                                    :border="false"
                                />
                            </template>
                            <template v-else>
                                <p class="f-14-grey">Assign to...</p>
                            </template>
                        </template>
                        <template
                            slot="option"
                            slot-scope="props"
                        >
                            <user-box
                                :firstName="props.option.first_name"
                                :lastName="props.option.last_name"
                                :avatarUrl="props.option.avatar"
                                :border="false"
                            />
                        </template>
                    </multiselect>
                    <template v-if="!existingSalesmanId">
                        <multiselect
                            :options="selectedSalesmanCalendars"
                            track-by="id"
                            class="mb-4"
                            label="summary"
                            name="user_calendars_list"
                            placeholder="Choose calendar"
                            v-model="selectedCalendar"
                            data-name="user_calendars_list"
                            :show-labels="false"
                            :allow-empty="false"
                            :disabled="!assignedUser || !assignedUser.id || salesmanCalendarsLoading"
                        >
                            <template
                                slot="singleLabel"
                                slot-scope="props"
                            >
                                <template v-if="props.option">
                                    <div class="s-align-4">
                                        <crm-logo
                                            v-if="props.option.provider == CRM"
                                            class="mr-2"
                                        />
                                        <outlook-logo
                                            v-else
                                            class="mr-2"
                                        />
                                        <span class="f-13-black ellipsis">{{ props.option.summary }}</span>
                                        <span
                                            v-if="props.option.primary"
                                            class="light-blue-label f-12-white ml-1"
                                        >
                                        Primary
                                    </span>
                                    </div>
                                </template>
                            </template>
                            <template
                                slot="option"
                                slot-scope="props"
                            >
                                <div class="s-align-4">
                                    <crm-logo
                                        v-if="props.option.provider == CRM"
                                        class="mr-2"
                                    />
                                    <outlook-logo
                                        v-else
                                        class="mr-2"
                                    />
                                    <span class="f-13-black ellipsis">{{ props.option.summary }}</span>
                                    <span
                                        v-if="props.option.primary"
                                        class="light-blue-label f-12-white ml-1"
                                    >
                                    Primary
                                </span>
                                </div>
                            </template>
                        </multiselect>
                        <div class="row-between mb-25">
                            <div class="lg-6 sm-12 pr-2 pr-sm-0 mb-4">
                                <form-datepicker
                                    :isFullWidth="true"
                                    v-model="assignedDate"
                                    keyDatePicker="assigne_date_picker"
                                    popoverDirection="top"
                                />
                            </div>
                            <div class="lg-6 sm-12 pl-2 pl-sm-0">
                                <form-time-input
                                    v-model="assignedTime"
                                    :isFullWidth="true"
                                    popoverDirection="top"
                                />
                            </div>
                        </div>
                    </template>
            </div>
            <div
                v-if="hasAccessToCalendar && !existingSalesmanId"
                class="calendar"
            >
                <div class="modal-calendar-sticky">
                    <div class="row-center mb-8">
                        <button
                            class="icon-dark"
                            @click="prevWeek"
                        >
                            <arrow-icon
                                transform="180"
                                size="13"
                            />
                        </button>
                        <div
                            class="calendar-date mh-8"
                            v-html="currentPeriodDates"
                        />
                        <button
                            class="icon-dark"
                            @click="nextWeek"
                        >
                            <arrow-icon
                                transform="0"
                                size="13"
                            />
                        </button>
                    </div>
                </div>
                <div class="user-calendar">
                    <Loader
                        v-if="isLoading || sellersLoading"
                        class="s-align-5"
                        size="small"
                    />
                    <template v-else>
                        <div class="modal-user-sticky">
                            <div class="user-calendar-days row-start bb-grey">
                            <span class="user-calendar-days-title f-13-grey">
                                Users
                            </span>
                                <template v-for="(day, index) in currentPeriod">
                                <span
                                    class="user-calendar-day f-13-grey t-center"
                                    :key="`${day}_${index}`"
                                >
                                    {{ day | monthAndDay }}
                                </span>
                                </template>
                            </div>
                        </div>
                        <div
                            v-for="(userRow, index) in filteredEvents"
                            v-show="userRow.fullname !== IS_UNASSIGNED"
                            :key="index"
                            class="user-calendar-row row-start w-100 bb-grey"
                        >
                            <span class="user-name f-13-black">
                                {{ userRow.fullname }}
                            </span>
                            <div
                                class="user-calendar-grid row-start relative expanded"
                                v-expand-buttons
                            >
                                <div
                                    :class="[
                                        'day-item bl-grey pointer',
                                        selectedUserRowIndex === userRow.id && selectedNIndex === n
                                        ? 'is-active-border' : ''
                                    ]"
                                    v-for="n in 3"
                                    :key="n"
                                    @click="setAssignment(userRow.id, n)"
                                >
                                </div>
                                <template v-for="event in getUserEvents(userRow.events)">
                                    <button
                                        :key="event.id"
                                        :style="`top:${ getEventTop(event, true)}`"
                                        :class="[
                                            'event is-event-details-show',
                                            event.classes,
                                            getEventClasses(event),
                                            event.eventRow > 2 ? 'event-overflow' : ''
                                        ]"
                                        :id="`event-${event.id}`"
                                    >
                                        <p
                                            v-if="+event.all_day"
                                            class="event-description"
                                        >
                                            All day
                                            {{ eventDetails(event) }}
                                        </p>
                                        <p
                                            v-else
                                            class="event-description"
                                        >
                                            {{ event.dtstart | time }}
                                            -
                                            {{ event.dtend | time }}
                                            {{ eventDetails(event) }}
                                        </p>
                                    </button>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div slot="footer">
            <button
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                @click="createAssignment"
                :disabled="requestIsGoing"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { Loader, FormInputTextarea, FullModal, FormDatepicker, FormTimeInput } from '@/ui';
    import { userCalendar, eventPopup } from '@/components/Calendar/calendar-mixins';
    import UserBox from '@/components/User/UserBox.vue'; 
    import { BASE_ENTITY_TYPES, CALENDAR_PROVIDERS, CALENDAR_TYPES } from '@/constants.js';
    import Multiselect from 'vue-multiselect';
    import { ArrowIcon, CrmLogo, OutlookLogo } from '@/icons';
    import api from '@/headers.js';
    import moment from 'moment/src/moment';

    const IS_ORGANIZER = 1;
    const IS_UNASSIGNED = 'Unassigned';

    export default {
        name: 'AssignSalesmanModal',
        components: {
            Loader,
            FormInputTextarea,
            FullModal,
            FormDatepicker,
            FormTimeInput,
            Multiselect,
            UserBox,
            ArrowIcon,
            CrmLogo, 
            OutlookLogo
        },
        mixins: [userCalendar, eventPopup],
        props: {
            entityType: {
                type: String,
                required: true
            },
            existingSalesmanId: {
                type: [String, Number],
                required: false
            },
            customerContact: {
                type: Array,
                required: false
            }
        },
        data() {
            return {
                date: null,
                sellersLoading: false,
                sellers: [],
                salesmanCalendarsLoading: false,
                selectedSalesmanCalendars: [],
                assignedUser: null,
                assignedDate: null,
                assignedTime: {
                    startTime: '',
                    endTime: ''
                },
                requestIsGoing: false,
                selectedCalendar: null,
                CRM: CALENDAR_PROVIDERS.CRM,
                selectedUserRowIndex: null,
                selectedNIndex: null,
                IS_UNASSIGNED
            }
        },
        computed: {
            showUnassignedUser() {
                if (this.assignedUser) {
                    return this.sellers;
                }

                if (this.assignedUser === null) {
                     return this.sellers.filter(assigned => {
                        return assigned.fullname !== IS_UNASSIGNED;
                    });
                }
            },
            entityId() {
                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL:
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.$route.params.lead_id;
                        break;
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.$route.params.job_id;
                        break;
                }
            },
            filteredEvents() {
                return this.sellers.map(seller => {
                    const user = this.events.find(e => e.id === seller.id);
                    let dayFilterEvent = user ? user.events.filter(event => 
                        moment(event.dtend).format('YYYY-MM-DD') <= 
                        moment(this.currentPeriod[this.currentPeriod.length -1]).format('YYYY-MM-DD')) 
                        : [];

                    return {
                        ...seller,
                        events: dayFilterEvent 
                    }
                });
            },
            hasAccessToCalendar() {
                return this.$can([this.$permissions.VIEW_ALL_CALENDARS]);
            },
        },
        methods: {
            eventDetails(event) {
                let resp = event.responsible;
                if (resp) {
                    let name = `
                        ${resp.first_name ? resp.first_name : ''} 
                        ${resp.last_name ? resp.last_name : ''}`;
                    let phone = `${resp.phone ? resp.phone : ''}`;
                    let address = `${event.location ? event.location : ''}`;

                    return `${name} - ${this.$options.filters.phone(phone)} - ${address}`;
                }

                return event.summary;
            },
            getSellers() {
                this.sellersLoading = true;

                const params = {
                    needed: [
                        'sellers'
                    ]
                };

                api.get('/handbooks?filter[is_active]=1&unassigned=1', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        this.sellers.push(...data.sellers);

                        if (this.existingSalesmanId) {
                            const index = this.sellers.findIndex(s => s.id == this.existingSalesmanId);
                            if (index !== -1) {
                                this.assignedUser = this.sellers[index];
                            }
                        }
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.sellersLoading = false;
                    });
            },
            getSalesmanCalendars(data) {
                // create event available only on first salesman assign
                if (this.existingSalesmanId) { return }

                // if selected 'unasigned' option
                if (!data.id) { 
                    this.selectedSalesmanCalendars.splice(0, this.selectedSalesmanCalendars.length);
                    return;
                }

                const params = {
                    owner_id: data.id,
                    owner_type: CALENDAR_TYPES.USER
                }

                this.salesmanCalendarsLoading = true;
                api.get('/calendars/calendar-list', { params })
                    .then(response => {
                        this.selectedSalesmanCalendars.splice(0,
                            this.selectedSalesmanCalendars.length,
                            ...response.data.data
                        )

                        // select primary calendar by default
                        this.selectedCalendar = this.selectedSalesmanCalendars[0];
                    })
                    .catch(error => this.notifyRequestErrors(error))
                    .finally(() => this.salesmanCalendarsLoading = false);
            },
            customLabel({ first_name, last_name }) {
                return `${first_name} – ${last_name}`
            },
            setAssignment(userId, index) {
                this.assignedUser = this.sellers.find(seller => seller.id === userId);
                this.assignedDate = moment(this.currentPeriod[index - 1]).format('YYYY-MM-DD');
                this.selectedUserRowIndex = userId;
                this.selectedNIndex = index;

                this.getSalesmanCalendars({id: userId});
            },
            getGrid(date) { 
                this.currentPeriod.splice(0, this.currentPeriod.length);
                this.events.splice(0, this.events.length);

                const startOfPeriod = date.clone().startOf('day');
                const endOfPeriod = date.clone().add('2', 'days');
                let day = startOfPeriod.clone();

                this.startOfPeriod = startOfPeriod;
                this.endOfPeriod = endOfPeriod;

                while (day <= endOfPeriod) {
                    this.currentPeriod.push(day.toDate());
                    day = day.clone().add(1, 'd');
                }

                this.getEvents();
            },
            getEvents() {
                this.isLoading = true;
                this.events.splice(0, this.events.length);

                const params = {
                    dtstart: moment().format('YYYY-MM-DD'),
                    view: 'agenda'
                };

                api.get('/calendars/user-calendar', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        this.events.push(...data);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            nextWeek() {
                this.getGrid(moment(this.endOfPeriod).clone().add(1, 'd'));
            },
            prevWeek() {
                this.getGrid(moment(this.startOfPeriod).clone().subtract(3, 'd'));
            },
            createAssignment() {
                this.$validator.validateAll()
                    .then(result => {
                        if (result) {
                            let params = {
                                id: this.entityId,
                                assignment_id: this.assignedUser.id,
                                entityType: this.entityType
                            };

                            if (this.assignedUser !== null
                                && Object.keys(this.assignedUser).length !== 0 
                                && this.assignedDate !== null 
                                && this.assignedTime.startTime !== '' 
                                && this.assignedTime.endTime !== '') {
                                
                                const utcOffset = moment().utcOffset();
                                const dtstart = moment(
                                    `${moment(this.assignedDate).format('YYYY-MM-DD')} 
                                    ${this.assignedTime.startTime}`,
                                    'YYYY-MM-DD HH:mm:ss'
                                ).subtract(utcOffset, 'm');
                                const dtend = moment(
                                    `${moment(this.assignedDate).format('YYYY-MM-DD')} 
                                    ${this.assignedTime.endTime}`,
                                    'YYYY-MM-DD HH:mm:ss'
                                ).subtract(utcOffset, 'm');

                                params.events = [{
                                    dtstart: dtstart.format('YYYY-MM-DD HH:mm:ss'),
                                    dtend: dtend.format('YYYY-MM-DD HH:mm:ss'),
                                    calendar_id: this.selectedCalendar.calendar_id,
                                    attendees: [
                                        {
                                            attendee_id: this.assignedUser.id,
                                            email: this.assignedUser.email,
                                            cn: this.assignedUser.fullname,
                                            is_salesman: true,
                                            organizer: IS_ORGANIZER,
                                            link_to_job: `${window.location.origin}${this.$route.path}`
                                        }
                                    ]
                                }];

                                // TODO: This functionality for adding participants to the event is disabled,
                                // in hotfix 1.27.2 and will most likely be needed in the future.
                                // if(this.customerContact) {
                                //     params.events[0].attendees.push(...this.customerContact);
                                // }
                            }

                            this.requestIsGoing = true;
                            api.post(`/entities/${this.entityId}/assignment`, params)
                                .then(() => {
                                    switch (this.entityType) {
                                        case BASE_ENTITY_TYPES.RESIDENTIAL:
                                        this.$store.commit(
                                                'lead/SET_RESIDENTIAL_ASSIGNMENT',
                                                this.assignedUser
                                            );
                                            break;
                                        case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                                            this.$store.commit(
                                                'commercial/SET_COMMERCIAL_LEAD_ASSIGNMENT',
                                                this.assignedUser
                                            );
                                            break;
                                        case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                                            this.$store.commit(
                                                'commercial/SET_COMMERCIAL_JOB_ASSIGNMENT',
                                                this.assignedUser
                                            );
                                            break;
                                    }

                                    this.notifySuccess(`${this.assignedUser.fullname} successfully assigned`);
                                    this.$emit('close');
                                })
                                .catch((error) => {
                                    this.notifyRequestErrors(error);
                                })
                                .finally(() => {
                                    this.requestIsGoing = false;
                                });
                        }
                    });                
            }
        },
        created() {
            this.date = moment();

            this.getSellers();

            if (this.hasAccessToCalendar && !this.existingSalesmanId) {
                this.getGrid(moment());
            }
        }
    }
</script>
