<template>
    <svg 
        viewBox="0 0 79 85.5"
        :width="width"
        :height="height"
        class="card-icon"
    >
        <rect 
            x="15" 
            y="36" 
            width="48" 
            height="5"
        />
        <rect 
            x="15" 
            y="12" 
            width="23" 
            height="5"
        />
        <rect 
            x="15" 
            y="24" 
            width="48" 
            height="5"
        />
        <rect 
            x="15" 
            y="48" 
            width="48" 
            height="5"
        />
        <rect 
            x="15" 
            y="60" 
            width="23" 
            height="5"
        />
        <path d="M0,0v83.9L2.4,84l1.7,0.1l6.3-3.6l8,5l9.1-5l7.8,4.3l1.2,0.7l9-5l7.8,4.3l1.3,0.7l1.2-0.7l12.7-4.3l6.4,3.7l4.1-0.1V0.2H0V0z M74,5v72.7l-5.5-3.2l-1.2,0.8l-12.7,4.3l-7.8-4.3l-1.2-0.7l-9,5l-7.8-4.3l-1.2-0.7l-8.9,5L12,75.4l-1.3-0.8l-5.5,3.2V5.1L74,5L74,5z"/>
    </svg>
</template>

<script>
    export default {
        name: 'CardIcon',
        props: {
            width: {
                type: [Number, String],
                default: 12
            },
            height: {
                type: [Number, String],
                default: 14
            }
        }
    }
</script>

