<template>
   <div>
       <full-modal
           @close="$emit('close')"
           class="commercial-edit-modal"
       >
           <div slot="title">Opportunity settings</div>
           <div slot="body">
               <div class="w-100 pb-30">
                   <p class="f-14-asphalt f-sbold pb-4">Main information</p>
                   <div class="row-between">
                       <div class="lg-6 sm-12 pb-4 pr-2 pr-sm-0">
                           <form-input
                               label="First name"
                               :class="[{ 'form-input-danger' : errors.has('first_name') }]"
                           >
                               <input
                                   type="text"
                                   name="first_name"
                                   id="first_name"
                                   class="form-input-field"
                                   placeholder="First name"
                                   v-input-style="edited.first_name"
                                   v-model="edited.first_name"
                                   maxlength="31"
                                   v-validate="{ max: 30, regex: titleRegex }"
                               />
                           </form-input>
                       </div>
                       <div class="lg-6 sm-12 pb-4 pl-2 pl-sm-0">
                           <form-input
                               label="Last name"
                               :class="[{ 'form-input-danger' : errors.has('last_name') }]"
                           >
                               <input
                                   type="text"
                                   name="last_name"
                                   id="last_name"
                                   class="form-input-field"
                                   placeholder="Last name"
                                   v-input-style="edited.last_name"
                                   v-model="edited.last_name"
                                   maxlength="31"
                                   v-validate="{ max: 30, regex: titleRegex }"
                               />
                           </form-input>
                       </div>
                   </div>
               </div>
               <div class="w-100 pb-30">
                   <p class="f-14-asphalt f-sbold pb-4">Address</p>
                   <address-box
                       type="address"
                       :key="'address'"
                       :existing="opportunityAddress"
                       :apartment="true"
                       validate=""
                       @address="getJobAddress"
                   />
               </div>
               <div>
                   <p class="f-14-asphalt f-sbold pb-4">Contacts</p>
                   <div
                       class="w-100 pb-30"
                   >
                       <div class="w-100 column-start">
                           <p class="f-13-asphalt pb-4">Phones:</p>
                           <div
                               class="s-align-4 w-100 pb-4"
                               v-for="(phone, index) in phones"
                               :key="`phone-number-${index}`"
                               :id="`phone-number-${index}`"
                           >
                               <div class="column-start static-col-8">
                                   <form-input
                                       label="Phone"
                                       class="form-input-with-select"
                                       :class="[{ 'form-input-danger' : errors.has(`phone-number-${index + 1}`) }]"
                                   >
                                       <input
                                           type="tel"
                                           :name="`phone-number-${index + 1}`"
                                           class="form-input-field"
                                           placeholder="Phone"
                                           v-input-style="phone.number"
                                           v-model="phone.number"
                                           v-mask="'(###) ###-####'"
                                           v-validate="index === 0 ? 'min:14' : 'required|min:14'"
                                       />
                                       <multiselect
                                           :options="phoneTypes"
                                           :showLabels="false"
                                           :max-height="200"
                                           placeholder="Types"
                                           v-model="phone.type"
                                           :name="'phone_type' + index"
                                           :data-name="'phone_type' + index"
                                           :class="['capitalize', { 'multiselect-danger' : errors.has('phone_type' + index) }]"
                                           :searchable="false"
                                       />
                                   </form-input>
                                   <form-input
                                       label="Phone extension"
                                       :class="[{ 'form-input-danger' : errors.has(`extension-${index}`) }]"
                                   >
                                       <input
                                           type="text"
                                           :name="`extension-${index}`"
                                           class="form-input-field mt-2"
                                           placeholder="Phone extension"
                                           v-model="phone.extension"
                                           v-validate="'integer'"
                                       />
                                   </form-input>
                               </div>
                               <button
                                   class="service-add-button ml-2"
                                   v-if="index > 0"
                                   @click="removeContactsFromRequestedBy(index, phones)"
                                   tabindex="-1"
                               >
                                   <trash-icon/>
                               </button>
                               <button
                                   class="service-secondary-button ml-2"
                                   v-if="phones.length == index + 1 && phones.length < 3"
                                   @click="addPhoneNumber"
                                   tabindex="-1"
                               >
                                   <add-icon size="10"/>
                               </button>
                           </div>
                       </div>
                       <div class="w-100 column-start pt-5">
                           <p class="f-13-asphalt pb-4 mt-sm-1">Emails:</p>
                           <div
                               class="s-align-4 w-100 pb-4"
                               v-for="(email, index) in emails"
                               :key="email.id"
                               :id="`email-${index}`"
                           >
                               <div class="static-col-8">
                                   <form-input
                                       label="Email"
                                       :class="[{ 'form-input-danger' : errors.has(`email-${index + 1}`) }]"
                                   >
                                       <input
                                           type="email"
                                           :name="`email-${index + 1}`"
                                           class="form-input-field"
                                           placeholder="Email"
                                           v-input-style="email.email"
                                           v-model="email.email"
                                           v-validate="index === 0 ? 'email' : 'required|email'"
                                       />
                                   </form-input>
                               </div>
                               <button
                                   class="service-add-button ml-2"
                                   v-if="index > 0"
                                   @click="removeContactsFromRequestedBy(index, emails)"
                                   tabindex="-1"
                               >
                                   <trash-icon/>
                               </button>
                               <button
                                   class="service-secondary-button ml-2"
                                   v-if="emails.length === index + 1 && emails.length < 3"
                                   @click="addEmail"
                                   tabindex="-1"
                               >
                                   <add-icon size="10"/>
                               </button>
                           </div>
                       </div>
                   </div>
               </div>
               <div>
                   <Loader
                       v-if="loadingTrade"
                       size="small"
                   />
                   <template v-else>
                       <div class="row-end pb-4">
                           <select-or-deselect
                               :data="edited.trades"
                               :valuesFromAdd="trades"
                           />
                       </div>
                       <div class="row-start ph-2">
                           <trade
                               v-for="(trade, index) in trades"
                               :key="trade.id"
                               :label="trade.name"
                               :class="[{ 'trade-danger' : errors.has(`trade-${index}`) }]"
                               :id="`trade-${index}`"
                               :icon="trade.icon_code"
                           >
                               <input
                                   type="checkbox"
                                   :name="`trade-${index}`"
                                   v-model="edited.trades"
                                   :value="trade.id"
                               />
                           </trade>
                       </div>
                   </template>
               </div>
               <Loader
                   size="full"
                   v-if="loading"
               />
           </div>
           <div slot="footer">
               <button
                   class="add-button"
                   @click="$emit('close')"
               >
                   Cancel
               </button>
               <button
                   class="primary-button"
                   @click="save"
                   :disabled="loading || !isActiveBtn"
               >
                   Save
               </button>
           </div>
       </full-modal>
       <zip-code-modal
           v-if="outOfArea"
           @continue="updateOpportunity"
           @cancel="cancel"
           @close="cancel"
       />
   </div>
</template>

<script>
    import {FullModal, Loader, FormInput, Trade} from '@/ui';
    import AddressBox from '@/components/AddressBox.vue';
    import SelectOrDeselect from '@/components/SelectOrDeselect.vue'
    import {copyFieldsValueFromObjectToObject} from '@/helpers';
    import Multiselect from 'vue-multiselect';
    import {TrashIcon, AddIcon} from '@/icons';
    import api from '@/api';
    import { mapActions, mapGetters } from 'vuex';
    
    import {
        NOT_ALLOWED_CHARACTERS,
        COMMERCIAL_PHONE_TYPES,
        ADDRESS_TYPES
    } from '@/constants';
    import {mask} from "vue-the-mask";
    import cloneDeep from "lodash/cloneDeep";
    
    export default {
        name: 'EditOpportunityModal',
        components: {
            FullModal,
            FormInput,
            AddressBox,
            TrashIcon,
            AddIcon,
            Multiselect,
            SelectOrDeselect,
            Loader,
            Trade,
            zipCodeModal: () => import('@/modals/ZipCodeModal.vue')
        },
        props: {
            opportunityData: {
                type: Object,
                required: true
            }
        },
        directives: {
            mask
        },
        data() {
            return {
                loading: false,
                titleRegex: NOT_ALLOWED_CHARACTERS,
                edited: {
                    first_name: '',
                    last_name: '',
                    trades: []
                },
                opportunityAddress: {
                    address: '',
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                    lat: null,
                    lng: null,
                    type: ADDRESS_TYPES.MAIN
                },
                loadingTrade: false,
                phones: [],
                phoneTypes: COMMERCIAL_PHONE_TYPES,
                emails: [],
                trades: []
            }
        },
        computed: {
            ...mapGetters('zipCodes', [
                'outOfArea'
            ]),
            isActiveBtn(){
                return !!(this.edited.first_name || this.edited.last_name ||
                    this.opportunityAddress.address || this.emails[0].email
                    || this.edited.trades.length || this.phones[0].number);
            }
        },
        methods: {
            ...mapActions('zipCodes', [
                'checkZipCodeArea'
            ]),
            save() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        this.checkZipCodeArea(this.opportunityAddress.zip)
                            .then(response => {
                                if (response) {
                                    this.updateOpportunity();
                                }
                            });
                    } else {
                        this.$validator.errors.items.forEach(err => {
                            this.notifyError(err.msg);
                        })
                    }
                })
            },
            updateOpportunity() {
                this.cancel();
                this.loading = true;
                const data = {
                    ...this.edited
                };
                data.addresses = this.opportunityAddress.address ? [this.opportunityAddress] : [];
                data.emails = this.emails[0].email ? this.emails : [];
                data.phones = this.phones[0].number ? this.phones : [];
                api.Opportunity.update(this.opportunityData.id, data)
                    .then(res => {
                        this.$emit('close');
                        this.$emit('updateData');
                    })
                    .catch(err => {
                        this.notifyRequestErrors(err);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            addPhoneNumber() {
                this.phones.push({
                    number: '',
                    type: this.phoneTypes[1],
                    order: 0
                });
            },
            addEmail() {
                this.emails.push({email: ''});
            },
            getJobAddress(data) {
                this.opportunityAddress = data;
            },
            removeContactsFromRequestedBy(index, arr) {
                arr.splice(index, 1);
            },
            getTrades() {
                this.loadingTrade = true;
                
                const params = {
                    needed: [
                        'trades'
                    ],
                    "unassigned": 1
                };
                api.handbooks(params, {'filter[is_active]': 1})
                    .then(response => response.data.data)
                    .then(data => {
                        this.trades = data.trades;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loadingTrade = false;
                    });
            },
            cancel() {
                this.$store.commit('zipCodes/SET_OUT_OF_AREA', false);
            }
        },
        created() {
            this.getTrades();
            const opportunity = cloneDeep(this.opportunityData);
            if (this.opportunityData.addresses.length) {
                copyFieldsValueFromObjectToObject(this.opportunityData.addresses[0], this.opportunityAddress);
            }
            if (!opportunity.phones.length){
                this.addPhoneNumber();
            }else{
                this.phones = opportunity.phones;
            }
            if (!opportunity.emails.length){
                this.addEmail();
            }else{
                this.emails = opportunity.emails;
            }
            this.requested_by = opportunity.requested_by;
            this.edited.first_name = opportunity.first_name;
            this.edited.last_name = opportunity.last_name;
            if (opportunity.trades.length) {
                this.edited.trades = opportunity.trades.map(el => el.id);
            }
        }
    }
</script>
