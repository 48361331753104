var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('perfect-scrollbar',{staticClass:"commercial-list-details ps-grey w-100"},[(_vm.loading)?_c('div',{staticClass:"w-100"},[_c('loader')],1):_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"s-align-4 w-100 pb-4"},[_c('clock-icon',{staticClass:"i-grey mr-2",attrs:{"size":"12"}}),_c('span',{staticClass:"f-11-grey"},[_vm._v(_vm._s(_vm._f("timeAgo")(_vm.job.created_at)))])],1),_c('div',{staticClass:"column-start w-100"},[_c('h2',{staticClass:"f-20-darkgrey f-sbold pb-4"},[_c('router-link',{staticClass:"to-commercial-lead",attrs:{"to":{ 
                        name: 'CommercialJobOverview', 
                        params: { property_id: _vm.job.property.id, job_id: _vm.job.id }
                    }}},[_vm._v("\n                    "+_vm._s(_vm.jobPrefixAndNumber())+"\n                ")])],1),_c('div',{staticClass:"row-start pb-1"},[_c('div',{class:['md-12', _vm.addressIsCorrect ? 'lg-8 nb-7' : 'lg-12 nb-12']},[_c('span',{staticClass:"f-12-grey"},[_vm._v("\n                        Address:\n                        "),_c('a',{class:[
                                _vm.job.full_address ? 'f-12-blue' : 'f-italic',
                                {'none-events': !_vm.getGoogleMapLink(_vm.job.lat, _vm.job.lng)}
                            ],attrs:{"href":_vm.getGoogleMapLink(_vm.job.lat, _vm.job.lng),"target":"_blank"}},[_vm._v("\n                            "+_vm._s(_vm.job.full_address 
                                ? _vm.job.full_address 
                                : 'No information')+"\n                            "),(_vm.getGoogleMapLink(_vm.job.lat, _vm.job.lng))?_c('link-icon',{staticClass:"i-blue"}):_vm._e()],1)])]),(_vm.addressIsCorrect)?_c('div',{staticClass:"s-align-3 lg-4 nb-5 md-12 mt-md-1"},[_c('button',{staticClass:"show-on-map",on:{"click":function($event){return _vm.$emit('showMap', { lat: +_vm.job.lat, lng: +_vm.job.lng })}}},[_c('map-icon'),_vm._v("\n                        Show on Map\n                    ")],1)]):_vm._e()])]),_c('div',{staticClass:"column-start pv-30 w-100 bb-grey"},[_c('h2',{staticClass:"f-16-darkgrey f-sbold mb-8"},[_vm._v("Property")]),_c('div',{staticClass:"mb-3"},[_c('span',{staticClass:"f-13-grey"},[_vm._v("Property:")]),_c('span',{staticClass:"f-13-black pl-1"},[(_vm.job.property.customer)?_c('router-link',{staticClass:"black-link-underlined relative",attrs:{"to":{ 
                            name: 'CommercialDetails', 
                            params: { 
                                customer_id: _vm.job.property.customer_id, 
                                property_id: _vm.job.property.id 
                            }
                        }}},[_vm._v("\n                        "+_vm._s(_vm.job.property.property_name)+"\n                    ")]):_c('router-link',{staticClass:"black-link-underlined relative",attrs:{"to":{ 
                            name: 'CommercialPropertyWithoutCustomer', 
                            params: { property_id: _vm.job.property.id }
                        }}},[_vm._v("\n                        "+_vm._s(_vm.job.property.property_name)+"\n                    ")])],1)]),_c('div',{staticClass:"mb-3"},[_c('span',{staticClass:"f-12-grey"},[_vm._v("\n                    Address:\n                    "),_c('a',{class:[
                            'pl-1',
                            _vm.job.property.addresses.main.length ? 'f-12-blue' : 'f-italic',
                            {'none-events': !_vm.getGoogleMapLink(_vm.job.property.addresses.main[0].lat, _vm.job.property.addresses.main[0].lng)}
                        ],attrs:{"href":_vm.getGoogleMapLink(_vm.job.property.addresses.main[0].lat, _vm.job.property.addresses.main[0].lng),"target":"_blank"}},[_vm._v("\n                        "+_vm._s(_vm.job.property.addresses.main.length 
                            ? _vm.getAddress(_vm.job.property.addresses.main[0])
                            : 'No information')+"\n                        "),(_vm.getGoogleMapLink(_vm.job.property.addresses.main[0].lat, _vm.job.property.addresses.main[0].lng))?_c('link-icon',{staticClass:"i-blue"}):_vm._e()],1)])]),(_vm.job.property.addresses.mailing.length)?_c('p',{staticClass:"f-12-grey pb-3"},[_vm._v("\n                Mailing Address:\n                "),_c('a',{class:[
                        'pl-1',
                        _vm.job.property.addresses.mailing.length ? 'f-12-blue' : 'f-italic',
                        {'none-events': !_vm.getGoogleMapLink(_vm.job.property.addresses.mailing[0].lat, _vm.job.property.addresses.mailing[0].lng)}
                    ],attrs:{"href":_vm.getGoogleMapLink(_vm.job.property.addresses.mailing[0].lat, _vm.job.property.addresses.mailing[0].lng),"target":"_blank"}},[_vm._v("\n                    "+_vm._s(_vm.job.property.addresses.mailing.length 
                        ? _vm.getAddress(_vm.job.property.addresses.mailing[0])
                        : 'No information')+"\n                    "),(_vm.getGoogleMapLink(_vm.job.property.addresses.mailing[0].lat, _vm.job.property.addresses.mailing[0].lng))?_c('link-icon',{staticClass:"i-blue"}):_vm._e()],1)]):_vm._e(),(_vm.job.property.division)?_c('p',{staticClass:"s-align-4 f-12-grey pb-3"},[_vm._v("\n                Division: \n                "),_c('span',{staticClass:"orange-label s-align-5 f-11-white f-sbold ml-2"},[_vm._v(_vm._s(_vm.job.property.division))])]):_vm._e(),(_vm.job.property.designation)?_c('p',{staticClass:"s-align-4 f-12-grey pb-3"},[_vm._v("\n                Designation:\n                "),_c('span',{staticClass:"blue-label s-align-5 f-11-white f-sbold ml-2"},[_vm._v(_vm._s(_vm.job.property.designation))])]):_vm._e(),_c('p',{staticClass:"f-12-grey"},[_vm._v("\n                Is selected location: \n                "),_c('span',{staticClass:"f-12-black pl-1"},[_vm._v(_vm._s(_vm.job.property.is_selected_location ? 'Yes' : 'No'))])])]),_c('representatives-details-list',{attrs:{"title":"Job","representatives":_vm.job.representatives,"entityId":_vm.job.id}}),(_vm.job.unit)?_c('div',{staticClass:"column-start pv-30 w-100"},[_c('h2',{staticClass:"f-16-darkgrey f-sbold mb-8"},[_vm._v("Unit")]),_c('div',{staticClass:"mb-3"},[_c('span',{staticClass:"f-12-grey"},[_vm._v("\n                    Unit: \n                    "),_c('span',{staticClass:"f-12-black pl-1"},[_c('router-link',{staticClass:"black-link-underlined relative",attrs:{"to":{ 
                                name: 'CommercialDetails', 
                                params: { 
                                    customer_id: _vm.job.property.customer_id, 
                                    property_id: _vm.job.property.id,
                                    unit_id: _vm.job.unit.id
                                }
                            }}},[_vm._v("\n                            "+_vm._s(_vm.job.unit.name)+"\n                        ")])],1)])]),(_vm.job.unit.store)?_c('div',{staticClass:"mb-3"},[_c('span',{staticClass:"f-12-grey"},[_vm._v("\n                    Store: \n                    "),_c('span',{staticClass:"f-12-black pl-1"},[_vm._v(_vm._s(_vm.job.unit.store))])])]):_vm._e(),_c('p',{staticClass:"f-12-grey"},[_vm._v("\n                Address:\n                "),_c('a',{class:[
                        _vm.job.unit.full_address ? 'f-12-blue' : 'f-italic',
                        {'none-events': !_vm.getGoogleMapLink(_vm.job.unit.lat, _vm.job.unit.lng)}
                    ],attrs:{"href":_vm.getGoogleMapLink(_vm.job.unit.lat, _vm.job.unit.lng),"target":"_blank"}},[_vm._v("\n                    "+_vm._s(_vm.job.unit.full_address 
                        ? _vm.job.unit.full_address 
                        : 'No information')+"\n                    "),(_vm.getGoogleMapLink(_vm.job.unit.lat, _vm.job.unit.lng))?_c('link-icon',{staticClass:"i-blue"}):_vm._e()],1)])]):_vm._e(),(_vm.tags.length)?_c('div',{staticClass:"column-start pv-30 w-100"},[_c('h2',{staticClass:"f-16-darkgrey f-sbold pb-8"},[_vm._v("Labels")]),_c('div',{staticClass:"row-start"},_vm._l((_vm.tags),function(tag,index){return _c('tag',{key:("tag-" + index),attrs:{"tag":tag,"disabled":true}})}),1)]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }