<template>
  <div>
    <full-modal
        @close="$emit('close')"
        class="commercial-edit-modal"
    >
        <div slot="title">Customer settings</div>
        <div slot="body">
            <p class="f-14-asphalt f-sbold pb-4">Responsible billing party</p>
            <div class="w-100 pb-30">
                <div class="w-100 pb-4">
                    <form-input
                        label="Company name"
                        :class="[{ 'form-input-danger' : errors.has('company_name') }]"
                    >
                        <input
                            type="text"
                            name="company_name"
                            id="company_name"
                            class="form-input-field"
                            placeholder="Company name"
                            v-input-style="customer.name"
                            v-model="customer.name"
                            v-validate="{ required: true, max: 250, regex: titleRegex }"
                        />
                    </form-input>
                </div>
                <div class="w-100 pb-4">
                    <form-input
                        label="Care of"
                        :class="[{ 'form-input-danger' : errors.has('management_company') }]"
                    >
                        <input
                            type="text"
                            name="management_company"
                            id="management_company"
                            class="form-input-field"
                            placeholder=" Care of"
                            v-input-style="customer.management_company"
                            v-model="customer.management_company"
                            v-validate="{ max: 250, regex: titleRegex }"
                        />
                    </form-input>
                </div>
            </div>
            <p class="f-14-asphalt f-sbold pb-4">Contacts</p>
            <p class="f-13-asphalt pb-4">Phones:</p>
            <div class="w-100 pb-30">
                <div
                    class="s-align-4 w-100 pb-4"
                    v-for="(phone, index) in customer.phones"
                    :key="`phone-${index}`"
                >
                    <div class="column-start static-col-8">
                        <form-input
                            v-if="!showTooltipInPrmaryFields"
                            label="Phone"
                            class="form-input-with-select"
                            :class="[{ 'form-input-danger' : errors.has(`phone_number_${index}`) }]"
                        >
                            <input
                                type="tel"
                                :name="`phone_number_${index}`"
                                class="form-input-field"
                                placeholder="Phone"
                                v-input-style="phone.number"
                                v-model="phone.number"
                                v-mask="'(###) ###-####'"
                                v-validate="'required|min:14'"
                            />
                            <multiselect
                                :options="phoneTypes"
                                :showLabels="false"
                                :max-height="200"
                                placeholder="Type"
                                v-model="phone.type"
                                :name="`phone_type_${index}`"
                                :data-name="`phone_type_${index}`"
                                v-validate="'required'"
                                :class="['capitalize', { 'multiselect-danger' : errors.has(`phone_type_${index}`) }]"
                                :searchable="false"
                            />
                        </form-input>
                        <tooltip
                            class="w-100"
                            v-if="showTooltipInPrmaryFields"
                            direction="top"
                            mobileDescription="Field info"
                        >
                            <form-input
                                label="Phone"
                                class="form-input-with-select"
                                :class="[{ 'form-input-danger' : errors.has(`phone_number_${index}`) }]"
                            >
                                <input
                                    type="tel"
                                    :name="`phone_number_${index}`"
                                    class="form-input-field"
                                    placeholder="Phone"
                                    v-input-style="phone.number"
                                    v-model="phone.number"
                                    v-mask="'(###) ###-####'"
                                    v-validate="'required|min:14'"
                                />
                                <multiselect
                                    :options="phoneTypes"
                                    :showLabels="false"
                                    :max-height="200"
                                    placeholder="Type"
                                    v-model="phone.type"
                                    :name="`phone_type_${index}`"
                                    :data-name="`phone_type_${index}`"
                                    v-validate="'required'"
                                    :class="['capitalize', { 'multiselect-danger' : errors.has(`phone_type_${index}`) }]"
                                    :searchable="false"
                                />
                            </form-input>
                            <template v-slot:content>
                                <p class="f-16-white">
                                    {{ showTooltipInPrmaryFields }}
                                </p>
                            </template>
                        </tooltip>
                        <form-input
                            v-if="showPhoneExtInPrmaryFields || phone.extension"
                            label="Phone extension"
                            :class="[{ 'form-input-danger' : errors.has(`extension-${index}`) }]"
                        >
                            <input
                                type="text"
                                :name="`extension-${index}`"
                                class="form-input-field mt-2"
                                placeholder="Phone extension"
                                v-model="phone.extension"
                                v-validate="'integer'"
                            />
                        </form-input>
                    </div>
                    <button
                        class="service-add-button ml-2"
                        @click="removeItem(index, customer.phones)"
                        v-if="index > 0"
                    >
                        <trash-icon/>
                    </button>
                    <button
                        class="service-secondary-button ml-2"
                        @click="addPhoneNumber"
                        v-if="phonesLength == index + 1 && phonesLength < 3"
                    >
                        <add-icon size="10"/>
                    </button>
                </div>
                <p class="f-13-asphalt pv-4">Emails:</p>
                <div
                    class="s-align-4 w-100 pb-4"
                    v-for="(email, index) in customer.emails"
                    :key="`email-${index}`"
                >
                    <div class="static-col-8">
                        <form-input
                            label="E-mail"
                            :class="[{ 'form-input-danger' : errors.has(`email-${index}`) }]"
                        >
                            <input
                                type="text"
                                :name="`email-${index}`"
                                :id="`email-${index}`"
                                class="form-input-field"
                                placeholder="E-mail"
                                v-input-style="email.email"
                                v-model="email.email"
                                v-validate="'required|email'"
                            />
                        </form-input>
                    </div>
                    <button
                        class="service-add-button ml-2"
                        @click="removeItem(index, customer.emails)"
                        v-if="index > 0"
                    >
                        <trash-icon/>
                    </button>
                    <button
                        class="service-secondary-button ml-2"
                        @click="addEmail"
                        v-if="emailsLength == index + 1 && emailsLength < 3"
                    >
                        <add-icon size="10"/>
                    </button>
                </div>
                <p class="f-13-asphalt pv-4">Billing Email:</p>
                <div class="lg-8 sm-12">
                    <form-input
                        label="Billing email"
                        :class="[{ 'form-input-danger' : errors.has('billing_email') }]"
                    >
                        <input
                            type="text"
                            :name="'billing_email'"
                            :id="'billing_email'"
                            class="form-input-field"
                            placeholder="Billing email"
                            v-input-style="customer.billing_emails[0].email"
                            v-model="customer.billing_emails[0].email"
                            v-validate="'email'"
                        />
                    </form-input>
                </div>
            </div>
            <p class="f-14-asphalt f-sbold pb-4">Mailing Address</p>
            <div class="w-100 pb-30">
                <address-box
                    type="address"
                    :key="'main'"
                    unicId="1"
                    :existing="customer.addresses.main[0]"
                    :apartment="true"
                    @address="getMailingAddress"
                >
                    <div
                        class="w-100 mb-4"
                        slot="apartment"
                    >
                        <form-input
                            label="Unit"
                            :class="[{ 'form-input-danger' : errors.has('apartment') }]"
                        >
                            <input
                                type="text"
                                name="apartment"
                                id="apartment"
                                class="form-input-field"
                                placeholder="Unit"
                                v-input-style="customer.addresses.main[0].apartment"
                                v-model="customer.addresses.main[0].apartment"
                                v-validate="'max:50'"
                            />
                        </form-input>
                    </div>
                </address-box>
            </div>
            <div
                class="w-100 pb-30"
                v-if="!billingIsAdded"
            >
                <button
                    class="transparent-button"
                    @click="billingIsAdded = true"
                >
                    <span class="f-12-blue">+ Add Billing Address</span>
                </button>
            </div>
            <div
                class="w-100 pb-30 column-start"
                v-else
            >
                <div class="row-between">
                    <p class="f-14-asphalt f-sbold pb-4">Billing Address</p>
                    <button
                        class="icon-light"
                        @click="removeBillingAddress"
                    >
                        <trash-icon/>
                    </button>
                </div>
                <address-box
                    type="address"
                    :key="'billing'"
                    unicId="2"
                    :existing="customer.addresses.billing[0]"
                    :apartment="true"
                    @address="getBillingAddress"
                >
                    <div
                        class="w-100 mb-4"
                        slot="apartment"
                    >
                        <form-input
                            label="Unit"
                            :class="[{ 'form-input-danger' : errors.has('apartment_billing') }]"
                        >
                            <input
                                type="text"
                                name="apartment_billing"
                                id="apartment_billing"
                                class="form-input-field"
                                placeholder="Unit"
                                v-input-style="customer.addresses.billing[0].apartment"
                                v-model="customer.addresses.billing[0].apartment"
                                v-validate="'max:50'"
                            />
                        </form-input>
                    </div>
                </address-box>
            </div>
            <p class="f-14-asphalt f-sbold pb-4">Customer notes</p>
            <div class="w-100 pb-30">
                <form-input-textarea
                    label="Notes"
                    v-input-counter="1000"
                >
                    <textarea
                        rows="4"
                        class="form-input-field"
                        name="notes"
                        placeholder="Notes"
                        v-model="customer.notes"
                        v-input-style="customer.notes"
                        maxlength="1000"
                        v-resize-textarea
                    />
                </form-input-textarea>
            </div>
            <p class="f-14-black f-sbold">Want to delete this customer?</p>
            <p class="f-13-grey">
                Deleting the Customer will erase all customer information from CRM.
            </p>
            <template v-if="isDeleteConfirmShow">
                <form-input-textarea
                    label="Reason"
                    v-input-counter="500"
                >
                    <textarea
                        :class="[
                            'form-input-field',
                            { 'form-input-textarea-danger' : errors.has('deleting_reason') }
                        ]"
                        rows="1"
                        name="deleting_reason"
                        id="deleting_reason"
                        placeholder="Reason"
                        v-model="deletingReason"
                        v-input-style="deletingReason"
                        maxlength="500"
                        v-validate="'required|min:3|max:500'"
                        v-resize-textarea
                    />
                </form-input-textarea>
                <div class="row-end">
                    <button
                        class="add-button mr-4"
                        @click="isDeleteConfirmShow = false"
                    >
                        Cancel
                    </button>
                    <button
                        class="red-button"
                        @click="deleteCustomer"
                        :disabled="sending"
                    >
                        Yes, delete
                    </button>
                </div>
            </template>
            <button
                v-else
                class="transparent-button s-align-4 mt-4 pb-20"
                @click="showDeleteConfirm"
            >
                <trash-icon class="i-red mr-1"/>
                <span class="f-13-red f-sbold">Delete customer</span>
            </button>
        </div>
        <div slot="footer">
            <button
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                @click="save"
                :disabled="sending"
            >
                Save
            </button>
        </div>
    </full-modal>
    <zip-code-modal
        v-if="outOfArea"
        @continue="updateCustomer"
        @cancel="cancel"
        @close="cancel"
    />
  </div>
</template>

<script>
    import { FullModal, FormInput, FormInputTextarea, Tooltip } from '@/ui';
    import { TrashIcon, AddIcon, EditIcon, ArrowIcon, PlusIcon, WarningIcon } from '@/icons';
    import { mask } from 'vue-the-mask';
    import { Avatar } from '@/components';
    import cloneDeep from 'lodash/cloneDeep';
    import { COMMERCIAL_PHONE_TYPES, ADDRESS_TYPES, NOT_ALLOWED_CHARACTERS } from '@/constants';
    import AddressBox from '@/components/AddressBox.vue';
    import Multiselect from 'vue-multiselect';
    import api from '@/headers.js';
    import { removePhoneMask, validationErrorNotification } from '@/helpers';
    import { customFieldInPrimarySection } from '@/components/CreateLead/mixins/customFieldInPrimarySection.js';
    import {mapActions, mapGetters} from "vuex";
    import ZipCodeModal from "./ZipCodeModal";

    export default {
        name: 'EditCommercialCustomerSectionModal',
        props: {
            customer: {
                type: Object,
                required: true
            },
            phonePrimarySectionParameters: {
                type: Object,
                required: false
            }
        },
        components: {
            FullModal,
            FormInput,
            AddressBox,
            TrashIcon,
            AddIcon,
            FormInputTextarea,
            Avatar,
            EditIcon,
            Multiselect,
            ArrowIcon,
            PlusIcon,
            WarningIcon,
            Tooltip,
            ZipCodeModal,
        },
        mixins: [customFieldInPrimarySection],
        data() {
            return {
                billingIsAdded: false,
                phoneTypes: COMMERCIAL_PHONE_TYPES,
                sending: false,
                isDeleteConfirmShow: false,
                deletingReason: '',
                titleRegex: NOT_ALLOWED_CHARACTERS
            }
        },
        computed: {
            ...mapGetters('zipCodes', [
              'outOfArea'
            ]),
            phonesLength() {
                return this.customer.phones.length;
            },
            emailsLength() {
                return this.customer.emails.length;
            }
        },
        methods: {
            ...mapActions('zipCodes', [
              'checkZipCodeArea'
            ]),
            addPhoneNumber() {
                this.customer.phones.push({
                    number: '',
                    extension: '',
                    order: this.phonesLength,
                    type: this.phoneTypes[1]
                });
            },
            addEmail() {
                this.customer.emails.push({
                    email: '',
                    order: this.emailsLength
                });
            },
            removeItem(index, arr) {
                arr.splice(index, 1);
            },
            getMailingAddress(data) {
                this.customer.addresses.main[0] = data;
            },
            getBillingAddress(data) {
                this.customer.addresses.billing[0] = data;
            },
            removeBillingAddress() {
                this.billingIsAdded = false;

                [this.customer, this.customerBilling].map((obj) => {
                    for (let field in obj) {
                        if (field.includes('_billing')) {
                            obj[field] = null;
                        }
                    };
                });
            },
            save() {
                this.$children.forEach(vm => {
                    vm.$validator.validateAll();
                });

                this.$validator.validateAll().then(result => {
                    if (!result) {
                        const context = this;
                        validationErrorNotification(this.$validator, context);
                        return;
                    }

                  this.checkZipCodeArea(this.customer.addresses.main[0].zip).then(res => {
                    if (res) {
                      this.updateCustomer();
                    }
                  })
                });
            },
            cancel() {
              this.$store.commit('zipCodes/SET_OUT_OF_AREA', false);
            },
            updateCustomer() {
              this.sending = true;
              this.cancel();
              let customer = cloneDeep(this.customer);

              customer.addresses = [{
                ...this.customer.addresses.main[0],
                type: ADDRESS_TYPES.MAIN
              }];
              delete customer.addresses[0].id;

              if (this.billingIsAdded) {
                customer.addresses.push({
                  ...this.customer.addresses.billing[0],
                  type: ADDRESS_TYPES.BILLING
                });
                delete customer.addresses[1].id;
              }

              if (this.customer.billing_emails[0].email == '') {
                customer.billing_emails = [];
              }

              if (this.customer.phones.length) {
                removePhoneMask(customer.phones);
              }
              api.put(`/commercial/customers/${customer.id}`, customer)
                  .then(response => response.data.data)
                  .then(data => {
                    this.notifySuccess('Customer is updated!');
                    this.$emit('update', {
                      ...data,
                      properties: customer.properties
                    });
                  })
                  .catch(error => {
                    this.notifyRequestErrors(error);
                  })
                  .finally(() => {
                    this.sending = false;
                  });
            },
            showDeleteConfirm() {
                this.isDeleteConfirmShow = true;
                this.$nextTick(() => {
                    const input = document.getElementById('deleting_reason');
                    // scroll to textarea
                    input.scrollIntoView({ behavior: 'smooth' });
                    // focus on textarea
                    input.focus({ preventScroll: true });
                });
            },
            deleteCustomer() {
                this.$validator.validate('deleting_reason')
                    .then(result => {
                        if (result) {
                            this.sending = true;
                            api.delete(`/commercial/customers/${this.customer.id}?reason=${this.deletingReason}`)
                                .then(() => {
                                    this.notifySuccess('Customer deleted.');
                                    this.$router.push({ name: 'CommercialCustomerList' });
                                })
                                .catch((error) => { this.notifyRequestErrors(error) })
                                .finally(() => { this.sending = false });
                        }
                    });
            }
        },
        directives: {
            mask
        },
        created() {
            if (this.customer.addresses.billing.length) {
                this.billingIsAdded = true;
            } else {
                // init empty billing address
                this.customer.addresses.billing.push({
                    address: '',
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                    lat: null,
                    lng: null,
                    apartment: ''
                });
            }

            if (!this.customer.phones.length) {
                this.addPhoneNumber();
            }

            if (!this.customer.emails.length) {
                this.addEmail();
            }

            if (!this.customer.billing_emails.length) {
                this.customer.billing_emails = [{ email: '', order: 0 }];
            }
        }
    }
</script>
