<template>
    <full-modal @close="closeModal">
        <span slot="title">
            {{ options.isEdit ? 'Edit User' : 'New user' }}
        </span>
        <div slot="body">
            <p class="f-16-darkgrey w-50 pb-2">Personal information</p>
            <div class="w-100 pb-20">
                <div class="row-start">
                    <div class="lg-6 sm-12 pr-2 pr-sm-0 mb-4">
                        <form-input
                            label="First Name"
                            :class="{'form-input-danger': errors.first('first_name')}"
                        >
                            <input
                                class="form-input-field"
                                name="first_name"
                                v-input-style="formData.first_name"
                                v-model="formData.first_name"
                                v-validate="'required|max:250'"
                                type="text"
                                placeholder="First Name"
                            />
                        </form-input>
                    </div>
                    <div class="lg-6 sm-12 pl-2 pl-sm-0">
                        <form-input
                            label="Last Name"
                            :class="{'form-input-danger': errors.first('last_name')}"
                        >
                            <input
                                name="last_name"
                                class="form-input-field"
                                v-input-style="formData.last_name"
                                v-model="formData.last_name"
                                v-validate="'required|max:250'"
                                type="text"
                                placeholder="Last Name"
                            />
                        </form-input>
                    </div>
                </div>
            </div>
            <p class="f-16-darkgrey pb-2">Contact form information</p>
            <div class="pb-20 row-start">
                <div class="lg-6 sm-12 pr-2 pr-sm-0 mb-4">
                    <form-input
                        label="Phone"
                        :class="{'form-input-danger': errors.first('phone')}"
                    >
                        <input
                            name="phone"
                            class="form-input-field"
                            v-mask="'(###) ###-####'"
                            v-input-style="formData.phone"
                            v-model="formData.phone"
                            v-validate="'required|min:14'"
                            type="text"
                            placeholder="Phone"
                        />
                    </form-input>
                </div>
                <div class="lg-6 sm-12 pl-2 pl-sm-0 mb-4">
                    <form-input
                        label="Email"
                        :class="{'form-input-danger': errors.first('email')}"
                    >
                        <input
                            name="email"
                            class="form-input-field"
                            v-input-style="formData.email"
                            v-model="formData.email"
                            v-validate="'required|email'"
                            type="text"
                            placeholder="Email"
                        />
                    </form-input>
                </div>
            </div>
            <div class="row-start pb-20">
                <div class="s-align-4 pl-8 lg-6 sm-12">
                    <checkbox-button
                        label="Active"
                        class="w-100"
                    >
                        <input
                            name="is_active"
                            v-model="formData.is_active"
                            v-validate="'required'"
                            type="checkbox"
                            true-value="1"
                            false-value="0"
                        />
                    </checkbox-button>
                </div>
                <div class="s-align-4 pl-8 lg-6 sm-12">
                    <checkbox-button
                        label="Account confirmed"
                        class="w-100"
                    >
                        <input
                            name="is_confirmed"
                            type="checkbox"
                            v-model="formData.is_confirmed"
                            v-validate="'required'"
                            disabled
                        />
                    </checkbox-button>
                </div>
            </div>
            <p class="f-16-darkgrey pb-2">Access to systems:</p>
            <div class="w-100 pb-20">
                <div class="row-start">
                    <button
                        :class="[
                            'add-button system-button mr-5',
                            { 'active' : useSystems.crm }
                        ]"
                        @click="useSystems.crm = !useSystems.crm"
                    >
                        <mark-icon
                            v-if="useSystems.crm"
                            class="i-blue mr-3"
                            size="14"
                        />
                        {{ SYSTEMS.CRM.name }}
                    </button>
                    <button
                        :class="[
                            'add-button system-button',
                            { 'active' : useSystems.rg }
                        ]"
                        @click="useSystems.rg = !useSystems.rg"
                    >
                        <mark-icon
                            v-if="useSystems.rg"
                            class="i-blue mr-3"
                            size="14"
                        />
                        {{ SYSTEMS.RG.name }}
                    </button>
                </div>
            </div>
            <p class="f-16-darkgrey pb-2">Roles</p>
            <div class="w-100 pb-20">
                <multiselect
                    :options="roles"
                    track-by="name"
                    label="name"
                    name="roles"
                    v-model="formData.roles"
                    :showLabels="false"
                    :multiple="true"
                    :max-height="170"
                    v-validate="useSystems.crm ? 'required' : ''"
                    :allow-empty="false"
                    :searchable="false"
                    :class="{ 'multiselect-danger' : errors.has('roles')}"
                />
            </div>
        </div>
        <div slot="footer">
            <button
                class="add-button"
                @click="closeModal"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                @click="saveUser"
                :disabled="requestIsGoing"
            >
                {{ options.isEdit ? 'Save' : 'Create' }}
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { mask } from 'vue-the-mask';
    import { FormInput, CheckboxButton, FullModal } from '@/ui';
    import { ROLES, SYSTEMS } from '@/constants';
    import Multiselect from 'vue-multiselect';
    import { MarkIcon } from '@/icons';

    export default {
        name: 'UserListModal',
        components: {
            FullModal,
            FormInput,
            CheckboxButton,
            Multiselect,
            MarkIcon,
        },
        props: {
            options: {
                type: Object,
                required: true
            }
        },
        directives: {
            mask
        },
        data() {
            return {
                formData: {
                    first_name: '',
                    last_name: '',
                    is_active: 1,
                    is_confirmed: false,
                    phone: '',
                    email: '',
                    // default role salesman
                    roles: [ROLES[4]],
                    provider: ''
                },
                roles: ROLES,
                SYSTEMS: SYSTEMS,
                requestIsGoing: false,
                useSystems: {
                    crm: true,
                    rg: true
                }
            }
        },
        created() {
            if (this.options.isEdit) {
                this.formData = Object.assign({}, this.options.user)
                this.useSystems.crm = this.formData.provider
                    ? this.formData.provider.indexOf(SYSTEMS.CRM.code) !== -1
                    : false;
                this.useSystems.rg = this.formData.provider
                    ? this.formData.provider.indexOf(SYSTEMS.RG.code) !== -1
                    : false;
            }
        },
        methods: {
            // ...mapActions('users', ['USER_UPDATE', 'USER_SAVE']),
            closeModal() {
                this.options.user = null;
                this.options.isEdit = false;
                this.options.isShow = false;
            },
            saveUser() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        this.requestIsGoing = true;

                        // convert roles to [1, 2, etc]
                        let formData = Object.assign({}, this.formData);
                        formData.roles = this.formData.roles.map(role => role.id);
                        formData.phone = this.formData.phone.replace(/\D+/g, '');
                        formData.redirect_uri = window.location.origin
                            + this.$router.resolve({name: 'Login'}).href;
                        formData.error_redirect_uri = window.location.origin;
                        formData.provider = this.providerToStr();

                        if (this.options.isEdit) {
                            delete formData.signature;
                            this.$store.dispatch('users/USER_UPDATE', formData)
                                .then(data => {
                                    this.notifySuccess(`A user account has been successfully edited`);
                                    this.closeModal();
                                })
                                .catch(error => {
                                    this.notifyRequestErrors(error);
                                })
                                .finally(() => {
                                    this.requestIsGoing = false;
                                });
                        } else {
                            this.$store.dispatch('users/USER_SAVE', formData)
                                .then(() => {
                                    this.notifySuccess( `User has been successfully created. An email has been sent to ${this.formData.email} to complete the registration.`);
                                    this.$emit('created');
                                    this.closeModal();
                                })
                                .catch(error => {
                                    let meta = error.response.data.meta;
                                    if (meta.code === 422) {
                                        this.displayUnprocEntityErrors(meta, this.formData);
                                    }
                                })
                                .finally(() => {
                                    this.requestIsGoing = false;
                                });
                        }
                    } else {
                        this.notifyError('Please fill out all required fields!');
                    }
                });
            },
            displayUnprocEntityErrors(meta, formData) {
                let errorsFields = Object.keys(meta.error_description);
                errorsFields.map(err => {
                    meta.error_description[err].forEach(errText => {
                        this.notifyError(errText);
                    });

                    if (err in formData) {
                        this.errors.add({field: err, msg: 'Error'})
                    }
                })

            },
            providerToStr() {
                if (this.useSystems.crm && this.useSystems.rg) {
                    return SYSTEMS.CRM.code + ',' + SYSTEMS.RG.code;
                } else {
                    if (this.useSystems.crm) {
                        return SYSTEMS.CRM.code;
                    } else if (this.useSystems.rg) {
                        return SYSTEMS.RG.code;
                    }
                }
                return '';
            }
        }
    }
</script>

