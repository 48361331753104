import { PERMISSIONS } from '@/plugins/ACL/constants';

export default {
    namespaced: true,
    state: {
        menu: [
            { 
                name: 'Dashboard', 
                link: 'Dashboard',
                icon: 'dashboard-icon'
            },
            {
                name: 'Contacts',
                link: 'Contacts',
                icon: 'contact-icon'
            },
            {
                name: 'Opportunities',
                link: 'Opportunities',
                icon: 'opportunity-icon'
            },
            { 
                name: 'Leads & Jobs',
                link: null,
                icon: 'briefcase-icon',
                permissions: [PERMISSIONS.VIEW_ALL_LEADS, PERMISSIONS.VIEW_MY_LEADS],
                submenu: [
                    {
                        links: [
                            { name: 'Residential', link: 'ResidentialLeads' },
                            { name: 'Commercial Jobs', link: 'CommercialJobList' },
                            { name: 'Commercial Leads', link: 'CommercialLeads' },
                            { name: 'Warranty Jobs', link: 'WarrantyJobs' }
                        ]
                    }
                ]
            },
            { 
                name: 'Tools', 
                link: null,
                icon: 'gear-icon',
                submenu: [
                    {
                        title: 'Calendars',
                        permissions: [
                            PERMISSIONS.VIEW_ALL_CALENDARS,
                            PERMISSIONS.VIEW_MY_CALENDAR
                        ],
                        links: [
                            { 
                                name: 'My Calendar',
                                link: 'CalendarWeek',
                                permissions: [
                                    PERMISSIONS.VIEW_MY_CALENDAR,
                                    PERMISSIONS.VIEW_ALL_CALENDARS
                                ]
                            },
                            {
                                name: 'Salesman',
                                link: 'UserCalendar',
                                permissions: [
                                    PERMISSIONS.VIEW_ALL_CALENDARS
                                ]
                            },
                            {
                                name: 'Technicians',
                                link: 'TechnicianCalendar',
                                permissions: [
                                    PERMISSIONS.VIEW_ALL_CALENDARS
                                ]
                            },
                            {
                                name: 'Crew Scheduler',
                                link: 'CrewScheduler',
                                permissions: [
                                    PERMISSIONS.VIEW_ALL_CALENDARS
                                ]
                            },
                            {
                                name: 'Job Scheduler',
                                link: 'JobScheduler',
                                permissions: [
                                    PERMISSIONS.VIEW_ALL_CALENDARS
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Templates',
                        permissions: [PERMISSIONS.VIEW_ALL_TEMPLATE, PERMISSIONS.VIEW_MY_TEMPLATE],
                        links: [
                            { name: 'Template Manager', link: 'TemplateManager' }
                        ]
                    },
                    {
                        title: 'Reports',
                        permissions: [PERMISSIONS.SEE_ALL_REPORTS, PERMISSIONS.SEE_MY_REPORTS],
                        links: [
                            { name: 'Reports', link: 'Reports' }
                        ]
                    },
                    // { NEED LEAD/JOBS INTEGRATION
                    //     title: 'Others',
                    //     permissions: [PERMISSIONS.CREATE_LEAD],
                    //     links: [
                    //         { name: 'Import Leads / Jobs', link: 'ImportLeadsJobs' }
                    //     ]
                    // }
                ]
            },
            { 
                name: 'Admin', 
                link: null,
                icon: 'admin-icon',
                submenu: [
                    {
                        links: [
                            { 
                                name: 'Users List', 
                                link: 'UsersList', 
                                permissions: [PERMISSIONS.VIEW_USER_LIST]
                            },
                            { 
                                name: 'Permission Settings',
                                link: 'PermissionSettings',
                                permissions: [PERMISSIONS.VIEW_PERMISSION_SETTINGS]
                            },
                            { 
                                name: 'Audit List', 
                                link: 'AuditList'
                            },
                            {
                                name: 'Call log',
                                link: 'CallLog'
                            },
                            {
                                name: 'Warranty Cases',
                                link: 'WarrantyCases'
                            }
                        ]
                    }
                ]
            },
            {
                name: 'Management',
                link: null,
                icon: 'person-icon',
                submenu: [
                    {
                        links: [
                            { 
                                name: 'Company Library Manager', 
                                link: 'CompanyLibrary'
                            },
                            { 
                                name: 'Project Management',
                                link: 'ProjectManagement',
                                permissions: [PERMISSIONS.VIEW_PROJECT_MANAGEMENT_BOARD]
                            }
                        ]
                    }
                ]
            },
            { 
                name: 'Settings', 
                link: 'Settings',
                icon: 'settings-icon',
                permissions: [PERMISSIONS.VIEW_COMPANY_SETTINGS]
            }
        ]
    },
    getters: {
        getMenu: state => state.menu
    }
}
