<template>
    <div class="l-filter-item ph-20">
        <div class="w-100 bb-grey">
            <div
                :id="`l-filter-item-head-${keyFilter}`"
                :class="[
                    'l-filter-item-head row-between pointer',
                    sectionOpen ? 'pt-20 pb-15' : 'pv-20'
                ]"
                @click="sectionOpen = !sectionOpen"
            >
                <span
                    slot="filter-head"
                    class="f-11-darkgrey f-sbold s-align-4 uppercase"
                >
                    <slot name="filter-head"></slot>
                </span>
                <span class="s-align-6">
                    <arrow-icon :transform="sectionOpen ? 270 : 90"/>
                </span>
            </div>
            <transition name="filter-section" v-if="sectionOpen">
                <div class="column-start">
                    <div
                        :style="`height: ${!showMore && countElements > 5 ? '160px' : 'auto'}`"
                        class="l-filter-item-list mv-1 hidden"
                    >
                        <slot name="filter-list"></slot>
                    </div>
                    <div
                        v-if="countElements > 5"
                        class="f-12-grey pointer mb-20"
                        @click="toggle"
                    >
                        {{ !showMore ? 'Show' : 'Hide'}}
                        {{ countElements - 5 }} more
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import { ArrowIcon } from '@/icons';

    export default {
        name: 'LeadFilterSection',
        components: {
            ArrowIcon
        },
        props: {
            countElements: {
                type: Number,
                default: null
            },
            keyFilter: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                sectionOpen: true,
                showMore: false
            }
        },
        computed: {
            expandAll() {
                return this.$store.getters['filters/getExpandAll'];
            }
        },
        watch: {
            expandAll: {
                immediate: true,
                handler (val, oldVal) {
                    if (val != oldVal) {
                        this.sectionOpen = val;
                    }
                }
            }
        },
        methods: {
            toggle() {
                this.showMore = !this.showMore;
                let scrollElement = document.getElementById(`l-filter-item-head-${this.keyFilter}`);
                let scrollTop = scrollElement.offsetTop;
                document.getElementById('container-filters').scrollTo({
                    top: scrollTop,
                    behavior: 'smooth'
                });
            }
        }
    }
</script>

<style scoped>
    /* TODO move to css file */
    .arrow-icon {
        transition: .2s;
    }

    .filter-section-enter-active,
    .filter-section-leave-active {
        transition: .2s;
    }
    .filter-section-enter,
    .filter-section-leave-to {
        opacity: 0;
    }
</style>

