<template>
    <svg  
        viewBox="0 0 490.672 490.672"
        :width="size" 
        :height="size" 
        :style="{ transform: 'rotate('+ transform + 'deg)' }"
        class="double-arrow-icon"
    >
        <g>
            <g>
                <path d="M466.221,237.787L231.555,3.12c-4.16-4.16-10.923-4.16-15.083,0c-4.16,4.16-4.16,10.923,0,15.083l227.115,227.136 L216.451,472.453c-4.16,4.16-4.16,10.923,0,15.083c2.091,2.091,4.821,3.136,7.552,3.136c2.731,0,5.461-1.045,7.552-3.115 l234.667-234.667C470.381,248.709,470.381,241.968,466.221,237.787z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M274.221,237.787L39.555,3.12c-4.16-4.16-10.923-4.16-15.083,0c-4.16,4.16-4.16,10.923,0,15.083l227.115,227.136 L24.451,472.453c-4.16,4.16-4.16,10.923,0,15.083c2.091,2.091,4.821,3.136,7.552,3.136c2.731,0,5.461-1.045,7.552-3.115 l234.667-234.667C278.381,248.709,278.381,241.968,274.221,237.787z"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'DoubleArrowIcon',
        props: {
            size: {
                type: [Number, String],
                default: 8
            },
            transform: {
                type: [Number, String],
                default: 0
            }
        }
    }
</script>