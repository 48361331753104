<template>
    <svg
        viewBox="0 0 104.6 50.1"
        :width="size"
        :height="size"
        class="table-empty"
    >
        <g>
            <g>
                <path
                    fill="#E5E1F9"
                    d="M10.8,35.2H0.5c-0.3,0-0.5-0.5-0.5-1l0,0c0-0.5,0.2-0.9,0.5-0.9h10.3c0.3,0,0.5,0.4,0.5,0.9l0,0C11.4,34.7,11.1,35.2,10.8,35.2z"
                />
            </g>
            <g>
                <path
                    fill="#BCD6F9"
                    d="M14.3,50.1h-2.6c-0.7,0-1.3-0.6-1.3-1.3v-2.4c0-0.7,0.6-1.3,1.3-1.3h2.6c0.7,0,1.3,0.6,1.3,1.3v2.4C15.7,49.5,15.1,50.1,14.3,50.1z"
                />
            </g>
            <g>
                <path
                    fill="#E5E1F9"
                    d="M103.8,1.9H88.9c-0.4,0-0.7-0.5-0.7-1l0,0c0-0.5,0.3-0.9,0.7-0.9h14.9c0.4,0,0.7,0.4,0.7,0.9l0,0C104.6,1.4,104.3,1.9,103.8,1.9z"
                />
            </g>
            <g>
                <path
                    fill="#EFF1F4"
                    d="M96.5,40.5H17.2c-1.4,0-2.6-1.2-2.6-2.6V8.4c0-1.4,1.2-2.6,2.6-2.6h79.3c1.4,0,2.6,1.2,2.6,2.6v29.5C99.1,39.4,98,40.5,96.5,40.5z"
                />
            </g>
            <g>
                <path
                    fill="#006BFD"
                    d="M34.4,16.2c0,2.5-2,4.5-4.5,4.5c-2.5,0-4.5-2-4.5-4.5c0-2.5,2-4.5,4.5-4.5C32.4,11.7,34.4,13.7,34.4,16.2z"
                />
            </g>
            <g>
                <path
                    fill="#BCD6F9"
                    d="M68.5,14.9H41.4c-0.5,0-0.8-0.4-0.8-0.8v0c0-0.5,0.4-0.8,0.8-0.8h27.1c0.5,0,0.8,0.4,0.8,0.8v0C69.3,14.6,68.9,14.9,68.5,14.9z"
                />
            </g>
            <g>
                <path
                    fill="#FFFFFF"
                    d="M88.8,18.7H41.4c-0.5,0-0.8-0.4-0.8-0.8l0,0c0-0.5,0.4-0.8,0.8-0.8h47.4c0.5,0,0.8,0.4,0.8,0.8l0,0C89.6,18.3,89.2,18.7,88.8,18.7z"
                />
            </g>
            <g>
                <path
                    fill="#006BFD"
                    d="M34.4,30.2c0,2.5-2,4.5-4.5,4.5c-2.5,0-4.5-2-4.5-4.5c0-2.5,2-4.5,4.5-4.5C32.4,25.7,34.4,27.8,34.4,30.2z"
                />
            </g>
            <g>
                <path
                    fill="#BCD6F9"
                    d="M54.4,29h-13c-0.5,0-0.8-0.4-0.8-0.8l0,0c0-0.5,0.4-0.8,0.8-0.8h13c0.5,0,0.8,0.4,0.8,0.8l0,0C55.2,28.6,54.9,29,54.4,29z"
                />
            </g>
            <g>
                <path
                    fill="#FFFFFF"
                    d="M88.8,32.7H41.4c-0.5,0-0.8-0.4-0.8-0.8v0c0-0.5,0.4-0.8,0.8-0.8h47.4c0.5,0,0.8,0.4,0.8,0.8v0C89.6,32.3,89.2,32.7,88.8,32.7z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'TableEmpty',
        props: {
            size: {
                type: [Number, String],
                default: 14
            }
        }
    }
</script>
