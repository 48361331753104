<template>
    <div 
        :class="[
            'tooltip',
            mobileDescription === '' ? 'tooltip-without-mobile-description' : '',
            fixedDirection ? 'tooltip-fixed-direction' : ''
        ]"
    >
        <slot name="default"/> 
        <div 
            class="tooltip-mobile-description"
            v-if="mobileDescription !== ''"
        >
            {{ mobileDescription }}
        </div>
        <div 
            :class="[
                'tooltip-content',
                `tooltip-content-${direction}`,
                `tooltip-content-${color}`,
                isStatic ? 'tooltip-content-static' : '',
                isShow ? 'tooltip-content-static-show' : ''
            ]"
        >
            <div class="tooltip-content-inner">
                <slot name="content"/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Tooltip',
        props: {
            isShow: {
                tyle: Boolean,
                required: false
            },
            direction: {
                type: String,
                default: 'top'
            },
            mobileDescription: {
                type: String,
                default: ''
            },
            color: {
                type: String,
                default: 'blue'
            },
            fixedDirection: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isStatic: this.isShow !== undefined ? true : false
            }
        },
        inject: ['$validator'],
    }
</script>