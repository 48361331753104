import {somePermission as checkPermissions, notifyIfNoAccessToPage} from "../utils/helpers";
import store from '@/store/store';

export default function somePermission({ next, to }, redirect) {

    // check if meta permissions value exists
    if (to.matched.some(record => record.meta.permissions)) {

        const userPermissions = store.getters['user/getUserPermissions'];

        // redirect if user does not have some permissions
        if (!checkPermissions(to.meta.permissions, userPermissions)) {
            notifyIfNoAccessToPage();
            return next(redirect);
        }
    }

    return next();
}
