<template>
    <svg 
        viewBox="0 0 47.85712814331055 43.928531646728516"
        :width="size"
        :height="size"
        class="add-new-icon"
    >
        <path fill="none" pointer-events="none" d="M0 0h47.857v43.929H0z"/>
        <path d="M33.053 19.269c-6.497 0-11.874 5.377-11.874 11.65s5.377 11.65 11.874 11.65c6.498 0 11.875-5.152 11.875-11.65 0-6.497-5.377-11.65-11.875-11.65zm5.826 13.219h-4.257v4.257c0 .896-.672 1.568-1.569 1.568-.896 0-1.568-.672-1.568-1.568v-4.257h-4.257c-.896 0-1.568-.673-1.568-1.569 0-.896.672-1.568 1.568-1.568h4.257v-4.257c0-.896.672-1.568 1.568-1.568.897 0 1.569.672 1.569 1.568v4.257h4.257c.896 0 1.568.672 1.568 1.568s-.672 1.569-1.568 1.569zM27.9 16.804H8.856c-.672 0-1.12.448-1.12 1.12 0 .673.672 1.12 1.12 1.12h19.269c.672 0 1.12-.447 1.12-1.12-.224-.672-.672-1.12-1.345-1.12z"/>
        <path d="M20.507 21.285H8.632c-.672 0-1.12.448-1.12 1.12 0 .673.672 1.12 1.12 1.12h13.891c.672-.895 1.345-1.568 2.017-2.24H20.507zM15.802 25.99h-7.17c-.672 0-1.12.448-1.12 1.12 0 .673.672 1.12 1.12 1.12h11.875c.224-.895.448-1.568.672-2.24h-5.377z"/>
        <path d="M7.288 37.417c-1.344 0-2.689-1.12-2.689-2.689V10.98h4.257c2.689 0 4.93-2.24 4.93-4.929V2.241h15.459c1.344 0 2.688 1.12 2.688 2.689V18.373h2.465V4.93c0-2.689-2.24-4.93-4.93-4.93H13.114c-.224 0-.672 0-.896.225-.224 0-.448.224-.672.448L3.03 8.739c-.224.224-.448.448-.448.672 0 .224-.224.448-.224.896v24.421c0 2.689 2.24 4.93 4.929 4.93h16.355c-.672-.673-1.344-1.57-1.792-2.241H7.288z"/>
        <path d="M27.9 12.1H17.37c-.672 0-1.12.447-1.12 1.12 0 .672.448 1.12 1.12 1.12H28.124c.673 0 1.12-.448 1.12-1.12-.223-.673-.671-1.12-1.344-1.12z"/>
    </svg>
</template>

<script>
    export default {
        name: 'AddNewIcon',
        props: {
            size: {
                type: [Number, String],
                default: 18
            }
        }
    }
</script>

