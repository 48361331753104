import axios from 'axios';

const IMAPbase = axios.create({
    baseURL: process.env.VUE_APP_EMAIL_SERVICE_HOST,
    // responseType` indicates the type of data that the server will respond with
    // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
    // browser only: 'blob'
    responseType: 'json', // default

    // responseEncoding` indicates encoding to use for decoding responses
    // Note: Ignored for `responseType` of 'stream' or client-side requests
    responseEncoding: 'utf8', // default
});

IMAPbase.defaults.withCredentials = true;

export default IMAPbase;
