<template>
    <svg 
        viewBox="0 0 64.66510772705078 65.87057495117188"
        :width="size" 
        :height="size"
        class="bell-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="64.66510772705078"
            height="65.87057495117188" 
            style="fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(4.661607265472412, 0, 0, 4.661607265472412, 1766.6376953125, -1230.2482604980469)">
            <path d="M-366.9,272.6c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4h-10.2c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.1,0,0.1-0.1,0.1-0.1v-3.1c0-2.7,2.2-5,5-5s5,2.2,5,5v3.1C-367,272.5-367,272.6-366.9,272.6z M-366.9,273.4c-0.5,0-0.9-0.4-0.9-0.9v-3.1c0-2.3-1.9-4.2-4.2-4.2c-2.3,0-4.2,1.9-4.2,4.2v3.1c0,0.5-0.4,0.9-0.9,0.9c-0.4,0-0.6,0.3-0.6,0.6c0,0.4,0.3,0.6,0.6,0.6h10.2c0.4,0,0.6-0.3,0.6-0.6C-366.3,273.6-366.6,273.4-366.9,273.4z M-374.2,275.4h0.8c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4h0.8c0,1.2-1,2.2-2.2,2.2S-374.2,276.6-374.2,275.4z"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'BellIcon',
    props: {
        size: {
            type: [Number, String],
            default: 10
        }
    }
}
</script>
