<template>
    <svg 
       viewBox="0 0 719.759521484375 780.0218505859375"
        :width="width" 
        :height="height"
        class="broken-lightning-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="719.759521484375" 
            height="780.0218505859375" 
            style="fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(7.840000629425049, 0, 0, 7.840000629425049, 2475.42724609375, -1776.8292636871338)">
            <path d="M-224.5,252.8l-7.9-22.3c-1-2.7-4.2-4.3-7-3.3l-22.3,7.9l0,0c-1.4,0.5-2.5,1.5-3.2,2.9c-0.6,1.4-0.7,2.9-0.2,4.3c0.5,1.3,1.6,2.4,3,2.9c1.3,0.6,2.8,0.6,4.1,0.1l9.3-3.3l-23.2,44.8l-3-1.1c-4.9-1.8-9.5-3.5-13.7-5.1c-1.4-0.7-3.1-0.7-4.6-0.1c-1.1,0.5-2.1,1.4-2.6,2.4l-19,35c-0.7,1.2-0.8,2.8-0.4,4.2c0.4,1.4,1.4,2.6,2.6,3.2c0.8,0.4,1.7,0.6,2.5,0.6c2,0,3.9-1,4.9-2.8l16.7-30.8l16.9,6.3c2.6,1.1,5.8,0,7.1-2.5l25.9-50.1l3.6,10.3c0.5,1.3,1.5,2.5,2.8,3.1c1.3,0.6,2.9,0.8,4.2,0.3c1.3-0.5,2.5-1.5,3.1-2.8C-224.1,255.7-224.1,254.1-224.5,252.8z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'BrokenLightningIcon',
        props: {
            width: {
                type: [Number, String],
                default: 15
            },
            height: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>