<template>
    <modal @close="$emit('close')">
        <div slot="modal-body">
            <p class="f-24-black f-sbold t-center mb-30">
                {{ `Move ${activeTab} to` }}
            </p>
            <div class="row-between row-baseline mb-30 flex-no-wrap">
                <div class="column-start hidden">
                    <p class="f-14-darkgrey f-sbold">Responsible billing party</p>
                    <p class="f-10-grey ellipsis">Currently '{{ currentCustomer && currentCustomer.name }}'</p>
                </div>
                <div class="s-align-6">
                    <radio-switch 
                        v-for="(item, index) in CUSTOMER_OPTIONS"
                        :key="`customer-option-${index}`"
                        :label="item"
                        color="blue"
                        :class="{ 'radio-switch-error' : errors.has('customer-option') }"
                        width="80"
                    >
                        <input 
                            type="radio" 
                            name="customer-option"
                            :value="item"
                            v-model="customerOption"
                            v-validate="'required'"
                            @click="onCustomerOptionChange"
                        />
                    </radio-switch>
                </div>
            </div>
            <p class="f-14-darkgrey f-sbold mb-4">
                {{
                    customerOption === 'Other'
                    ? 'Choose Responsible billing party and Property'
                    : 'Choose Property'
                }}
            </p>
            <multiselect
                v-if="customerOption === 'Other'"
                :class="['mb-2', { 'multiselect-danger' : errors.has('customer') }]"
                placeholder="Select customer"
                label="name"
                track-by="name"
                name="customer"
                data-name="customer"
                :options="customers"
                v-model="customer"
                :option-height="104" 
                :show-labels="false"
                :allow-empty="false"
                v-validate="'required'"
                @select="onCustomerSelect"
            />
            <multiselect
                v-if="properties.length || propertiesLoading"
                :class="[{ 'multiselect-danger' : errors.has('property') }]"
                placeholder="Select property"
                label="property_name"
                track-by="property_name"
                name="property"
                data-name="property"
                :options="properties"
                v-model="property"
                :option-height="104" 
                :show-labels="false"
                :allow-empty="false"
                v-validate="'required'"
                :disabled="propertiesLoading"
            />
            <div
                v-else
                class="f-13-darkgrey f-sbold mt-4"
            >
                <warning-icon
                    size="13"
                    class="i-red mr-1"
                />
                Selected Responsible billing party does not have any properties. To create a property for this Responsible billing party, click
                <router-link
                    :to="{ 
                        name: 'CommercialProperty',
                        params: { customer_id: customer && customer.id }
                    }"
                    class="f-14-blue"
                >
                    here.
                </router-link>
            </div>
            <checkbox-button
                v-if="property && property.units.length"
                color="blue"
                label="Select Property Unit"
                class="w-100 mv-7"
            >
                <input
                    type="checkbox"
                    name="unit_select"
                    v-model="unitSelect"
                />
            </checkbox-button>
            <p
                v-else-if="property && !property.units.length"
                class="f-13-darkgrey f-sbold f-sbold mt-4"
            >
                <warning-icon
                    size="15"
                    class="i-red mr-2"
                />
                This property has no units
            </p>
            <multiselect
                v-if="unitSelect"
                :class="[{ 'multiselect-danger' : errors.has('unit') }]"
                placeholder="Select unit"
                label="name"
                track-by="name"
                name="unit"
                data-name="unit"
                :options="property.units"
                v-model="unit"
                :option-height="104" 
                :show-labels="false"
                :allow-empty="false"
                v-validate="'required'"
            />
        </div>
        <div slot="modal-footer">
            <div class="row-between">
                <button 
                    class="add-button"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button 
                    class="primary-button"
                    @click="moveEntities"
                    :disabled="requestIsGoing"
                >
                    Move
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
    import { COMMERCIAL_PROPERTY_TABS, BASE_ENTITY_TYPES } from '@/constants.js';
    import { Modal, RadioSwitch, CheckboxButton } from '@/ui';
    import Multiselect from 'vue-multiselect';
    import { clearArray } from '@/helpers';
    import { UserBox } from '@/components';
    import { WarningIcon } from '@/icons';
    import api from '@/headers.js';

    export default {
        name: 'MoveCommercialEntitiesModal',
        components: {
            Multiselect,
            Modal,
            RadioSwitch,
            CheckboxButton,
            UserBox,
            WarningIcon
        },
        props: {
            currentCustomerId: {
                type: [String, Number],
                required: true
            },
            currentPropertyId: {
                type: [String, Number],
                required: true
            },
            entitiesToMove: {
                type: Array,
                required: true
            },
            activeTab: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                CUSTOMER_OPTIONS: ['Current', 'Other'],
                customerOption: null,
                customers: [],
                customer: null,
                currentCustomer: null,
                propertiesLoading: false,
                properties: [],
                property: null,
                unitSelect: false,
                unit: null,
                requestIsGoing: false,
            }
        },
        computed: {
            entityType() {
                switch (this.activeTab) {
                    case COMMERCIAL_PROPERTY_TABS.JOBS: {
                        return BASE_ENTITY_TYPES.COMMERCIAL_JOB;
                    } break;
                    case COMMERCIAL_PROPERTY_TABS.LEADS: {
                        return BASE_ENTITY_TYPES.COMMERCIAL_LEAD;
                    } break;
                }
            }
        },
        methods: {
            getCustomers() {
                api.get('/commercial/customers?perPage=-1')
                    .then(response => {
                        this.customers = response.data.data.filter(i => i.id !== this.currentCustomerId);
                        this.currentCustomer = response.data.data.find(i => i.id === this.currentCustomerId);
                        this.customer = this.currentCustomer;
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    });
            },
            getProperties(id) {
                this.property = null;
                this.propertiesLoading = true;
                this.unitSelect = false;
                this.$validator.reset();

                api.get(`/commercial/customers/${id}/properties`)
                    .then(response => {
                        this.properties.splice(0, this.properties.length, ...response.data.data);
                    })
                    .catch((error) => { this.notifyRequestErrors(error) })
                    .finally(() => { this.propertiesLoading = false; })
            },
            onCustomerOptionChange(e) {
                if (e.target.value === this.CUSTOMER_OPTIONS[0] 
                    && this.customer
                    && this.currentCustomerId !== this.customer.id
                ) {
                    this.unitSelect = false;
                    this.customer = this.currentCustomer;
                    this.getProperties(this.customer.id);
                } else if (e.target.value === this.CUSTOMER_OPTIONS[1]) {
                    this.unitSelect = false;
                    this.property = null;
                    this.customer = null;
                    this.$validator.reset();
                }
            },
            onCustomerSelect(customer) {
                this.getProperties(customer.id);
            },
            moveEntities() {
                this.$validator.validateAll()
                    .then(result => {
                        if (this.currentPropertyId == this.property.id && !this.unitSelect) {
                            this.notifyError(`You can't move to the same property`);
                            return;
                        } else if (this.unitSelect && this.currentUnitId == this.unit.id) {
                            this.notifyError(`You can't move to the same unit`);
                            return;
                        }

                        if (result) {
                            let params = {
                                customerFrom: this.currentCustomerId,
                                customerTo: this.customer.id,
                                propertyFrom: this.currentPropertyId,
                                propertyTo: this.property.id,
                                entities: this.entitiesToMove,
                                entityType: this.entityType,
                            };

                            if (this.unitSelect) { params.unitTo = this.unit.id; }

                            this.requestIsGoing = true;
                            api.post('/commercial/move', params)
                                .then(() => {
                                    this.$emit(
                                        'removeMovedEntities',
                                        this.customerOption === this.CUSTOMER_OPTIONS[0],
                                        this.property.id,
                                        this.unitSelect ? this.unit.id : null
                                    );
                                    this.notifySuccess(
                                        `${this.activeTab === COMMERCIAL_PROPERTY_TABS.JOBS ? 'Job(s)' : 'Lead(s)'}
                                        moved` 
                                    );
                                })
                                .catch((error) => { this.notifyRequestErrors(error); })
                                .finally(() => { this.requestIsGoing = false; });                          
                        }
                    });
            }
        },
        created() {
            this.getCustomers();
            this.getProperties(this.currentCustomerId);
            this.customerOption = this.CUSTOMER_OPTIONS[0];
        }
    }
</script>