<template>
    <full-modal @close="$emit('close')">
        <div slot="title">
            {{ item.type_id == MATERIAL ? 'Material Details' : 'Labor Details' }}
        </div>
        <div 
            slot="body"
            v-if="Object.keys(item).length"
        >
            <div class="w-100 pb-30">
                <div class="s-align-4">
                    <span class="f-16-darkgrey mr-2">
                        Name:
                        <span class="f-16-blue f-sbold">
                            {{ item.name }}
                        </span>
                    </span>
                </div>
            </div>
            <div class="w-100 pb-30">
                <form-input 
                    label="Estimate Item Name"
                    :class="[{ 'form-input-danger' : errors.has('estimate_item_name') }]"
                >
                    <input 
                        type="text" 
                        name="estimate_item_name" 
                        class="form-input-field"
                        placeholder="Estimate Item Name"
                        v-input-style="item.estimate_name"
                        v-model="item.estimate_name"
                        v-validate="'min:3|max:250'"
                    />
                </form-input>
                <div class="pt-8">
                    <button 
                        class="transparent-button"
                        v-if="!editingDescription"
                        @click="editingDescription = true"
                    >
                        <edit-icon 
                            class="mr-1 i-blue" 
                            v-if="item.description != null"
                        />
                        <span class="f-14-blue f-sbold">
                            {{ item.description == null ? '+ Add' : 'Edit' }}
                            {{ item.type_id == MATERIAL ? 'Material' : 'Labor' }} 
                            Description
                        </span>
                    </button>
                    <div 
                        class="w-100 relative"
                        v-if="editingDescription"
                    >
                        <form-input-textarea 
                            label="Item Description"
                            :onSubmit="() => { editingDescription = false }"
                            :disabled="requestIsGoing"
                            v-input-counter="1000"
                        >
                            <textarea 
                                class="form-input-field"
                                v-resize-textarea
                                placeholder="Item Description"
                                maxlength="1000"
                                name="description"
                                v-input-style="item.description"
                                v-model="item.description"
                            />
                        </form-input-textarea>
                    </div>
                </div>
            </div>            
            <div class="w-100 pb-30">
                <form-input 
                    label="Waste"
                    maskAfter="%"
                    :maskValue="item.waste"
                >
                    <input 
                        type="text" 
                        name="item_waste"
                        class="form-input-field"
                        placeholder="Waste"
                        v-input-style="item.waste"
                        v-model="item.waste"
                        v-limiter
                    />
                </form-input>
            </div>            
            <div class="pb-30 row-start">
                <div class="lg-6 sm-12 pr-2 pr-sm-0 column-start mb-sm-1">
                    <radio-button 
                        color="green"
                        label="Use Profit Margin"
                        class="f-12-darkgrey f-sbold mb-8"
                    >
                        <input 
                            type="radio" 
                            name="fixed_price" 
                            v-model="item.fixed_price"
                            :value="0"
                        />
                    </radio-button>
                    <form-input
                        label="Estimate"
                        :class="[
                            'form-input-with-select',
                            {'form-input-danger' : errors.first('estimate')}
                        ]"
                    >
                        <input
                            type="text"
                            name="item_cost"
                            class="form-input-field"
                            placeholder="Cost"
                            v-input-style="item.cost"
                            :value="item.cost"
                            @input="(e) => { item.cost = e.target.value }"
                            :disabled="item.fixed_price == 1"
                            v-limiter
                        />
                        <multiselect
                            :options="[item.estimate_unit, item.order_unit]"
                            name="per_unit"
                            data-name="per_unit"
                            v-model="item.per_unit"
                            :max-height="200"
                            placeholder="Unit"
                            :disabled="item.fixed_price == 1"
                            :show-labels="false"
                            :allow-empty="false"
                            :searchable="false"
                            @select="onChangePerUnit"
                            v-validate="'required'"
                            :class="[{'multiselect-danger' : errors.has('estimate_type')}, 'estimate-type']"
                        />
                    </form-input>
                </div>
                <div class="lg-6 sm-12 pl-2 pl-sm-0 column-start">
                    <radio-button 
                        color="dark-blue"
                        label="Fixed Price"
                        class="f-12-darkgrey f-sbold mb-8"
                    >
                        <input 
                            type="radio" 
                            name="fixed_price" 
                            v-model="item.fixed_price"
                            :value="1"
                        />
                    </radio-button>
                    <form-input
                        label="Estimate"
                        :class="[
                            'form-input-with-select',
                            {'form-input-danger' : errors.first('estimate')}
                        ]"
                    >
                        <input
                            type="text"
                            name="fixed_price_value"
                            class="form-input-field"
                            placeholder="Fixed Price"
                            v-input-style="item.fixed_price_value"
                            v-model="item.fixed_price_value"
                            :disabled="item.fixed_price == 0"
                            v-limiter
                        />
                        <multiselect
                            :options="[item.estimate_unit, item.order_unit]"
                            name="per_unit"
                            data-name="per_unit"
                            v-model="item.per_unit"
                            :max-height="200"
                            placeholder="Unit"
                            :show-labels="false"
                            :allow-empty="false"
                            :searchable="false"
                            :disabled="item.fixed_price == 0"
                            @select="onChangePerUnit"
                            v-validate="'required'"
                            :class="[{'multiselect-danger' : errors.has('estimate_type')}, 'estimate-type']"
                        />
                    </form-input>
                </div>
            </div>
            <div class="w-100 pb-30">
                <div 
                    class="blue-box pv-30 row-center"
                    v-if="item.fixed_price == 0"
                >
                    <div class="column-center lg-3 sm-12 ls-sm-3 ph-8 mb-sm-1">
                        <span class="f-13-grey">Cost</span>
                        <span class="f-20-black f-sbold">
                            {{ +item.cost | currency }}
                        </span>
                    </div>
                    <div class="s-align-5 lg-1 sm-12 ls-sm-1 ph-8 mb-sm-1">
                        <span class="f-16-darkgrey">@</span>
                    </div>
                    <div class="column-center lg-4 sm-12 ls-sm-3 ph-8 mb-sm-1">
                        <span class="f-13-grey">Profit Margin</span>
                        <span class="f-20-black f-sbold">
                            {{ margin }}%
                        </span>
                    </div>
                    <div class="s-align-5 lg-1 sm-12 ls-sm-1 ph-8 mb-sm-1">
                        <span class="f-16-darkgrey">=</span>
                    </div>
                    <div class="column-center lg-3 sm-12 ls-sm-3 ph-8 mb-sm-1">
                        <span class="f-13-grey">Price</span>
                        <span class="f-20-black f-sbold">
                            {{ price | currency }}
                        </span>
                    </div>
                </div>
                <div 
                    class="blue-box pv-30"
                    v-else
                >
                    <div class="column-center ph-8">
                        <span class="f-14-grey">Price</span>
                        <span class="f-20-black f-sbold">
                            {{ +item.fixed_price_value | currency }}
                        </span>
                    </div>
                </div>
            </div>
            <p class="f-16-darkgrey pb-8">
                Measurement used to calculate quantity
            </p>
            <div class="w-100 pb-30">
                <div
                    v-for="(measurement, index) in accessory"
                    :key="index"
                    class="w-100 pb-8"
                >
                    <checkbox-button 
                        :label="measurement.name"
                        color="dark-blue"
                    >
                        <input 
                            type="checkbox"
                            v-model="measurement.active" 
                            :name="`measurement_active_${index}`"
                        />
                    </checkbox-button>
                    <div 
                        class="row-between pt-8"
                        v-if="measurement.active"
                    >
                        <div class="static-col-6 pr-2">
                            <form-input 
                                label="Measure"
                                :maskAfter="'/ ' + measurement.attribute_type"
                                :maskValue="measurement.measure_value"
                            >
                                <input 
                                    type="text" 
                                    :name="`measure_${index}`" 
                                    :id="`measure_${index}`"
                                    class="form-input-field"
                                    placeholder="Measure"
                                    v-input-style="measurement.measure_value"
                                    v-model="measurement.measure_value"       
                                    v-limiter     
                                />
                            </form-input>
                        </div>
                        <div class="static-col-6 pl-2">
                            <form-input 
                                label="Need"
                                :maskAfter="'/ ' + item.per_unit"
                                :maskValue="measurement.need_value"
                            >
                                <input 
                                    type="text" 
                                    :name="`need_${index}`" 
                                    :id="`need_${index}`"
                                    class="form-input-field"
                                    placeholder="Need"
                                    v-input-style="measurement.need_value"
                                    v-model="measurement.need_value"    
                                    v-limiter        
                                />
                            </form-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                @click="formItemMeasurementsObject"
                :disabled="requestIsGoing"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { 
        FormInput, 
        RadioButton, 
        CheckboxButton, 
        FormInputTextarea, 
        FullModal
    } from '@/ui';
    import { clearArray } from '@/helpers';
    import { MATERIAL } from '@/constants';
    import { EditIcon } from '@/icons';
    import Multiselect from 'vue-multiselect';
    import api from '@/headers.js';

    export default {
        name: 'TemplateEditLibraryItemModal',
        components: {
            FullModal,
            FormInput,
            Multiselect,
            RadioButton,
            CheckboxButton,
            FormInputTextarea,
            EditIcon
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            margin: {
                type: [String, Number],
                required: true
            },
            measurements: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                accessory: [],
                editingDescription: false,
                requestIsGoing: false
            }
        },
        computed: {
            price() {
                return this.item.cost * 100 / (100 - this.margin);
            }
        },
        methods: {
            onChangePerUnit(value) {
                if ([this.item.cost, this.item.estimate, this.item.order].some(i => i === '')) {
                    // no need to recompute cost
                    return;
                } else {
                    if (value === this.item.order_unit) {
                      if (this.item.cost == this.item.estimate_cost) {
                        this.item.cost = this.item.order_cost;
                      } else {
                        this.item.cost = (this.item.cost * this.item.order / this.item.estimate).toFixed(2);
                      }
                        this.item.fixed_price_value = (this.item.fixed_price_value * this.item.estimate / this.item.order).toFixed(2);
                    }
                    if (value === this.item.estimate_unit) {
                      if (this.item.cost == this.item.order_cost) {
                        this.item.cost = this.item.estimate_cost;
                      } else {
                        this.item.cost = (this.item.cost * this.item.estimate / this.item.order).toFixed(2);
                      }

                      this.item.fixed_price_value = (this.item.fixed_price_value * this.item.order / this.item.estimate).toFixed(2);
                    }
                }
            },
            checkMeasurements() {
                // if there are no saved measurements, 
                // the form is displayed with a value of 0
                if (!this.item.measurements.length) {
                    this.measurements.map((measure) => {
                        this.accessory.push({
                            measure_value: 0,
                            need_value: 0,
                            attribute_id: measure.id,
                            active: false,
                            name: measure.name,
                            attribute_type: measure.attribute_type
                        });
                    });
                // if there is, the obtained values are added to the form, 
                // and the remaining fields of the form are displayed with a value of 0
                } else {
                    this.measurements.map((measure) => {
                        let data = {
                            measure_value: 0,
                            need_value: 0,
                            attribute_id: measure.id,
                            active: false,
                            name: measure.name,
                            attribute_type: measure.attribute_type
                        };
                        
                        for (let i = 0; i < this.item.measurements.length; i++) {
                            if (measure.id == this.item.measurements[i].id) {
                                data.measure_value = this.item.measurements[i].measure_value;
                                data.need_value = this.item.measurements[i].need_value;
                                data.active = true;
                            }
                        }

                        this.accessory.push(data);
                    });
                };
            },
            formItemMeasurementsObject() {
                // the edited measurements are saved by the following three fields, 
                // so the measurements array is cleared and filled again
                clearArray(this.item.measurements);
                this.accessory.map((measure) => {
                    if (measure.active) {
                        this.item.measurements.push({
                            measure_value: measure.measure_value,
                            need_value: measure.need_value,
                            attribute_id: measure.attribute_id
                        });
                    };
                });

                this.save();
            },
            save() {
                this.requestIsGoing = true;

                // if the user deleted the value from optional fields, 
                // it is necessary to keep 0, not an empty string
                this.item.waste = this.item.waste == '' ? 0 : this.item.waste;
                this.item.cost = this.item.cost == '' ? 0 : this.item.cost;
                this.item.fixed_price_value = this.item.fixed_price_value == '' ? 0 : this.item.fixed_price_value;

                api.put(`/templates/items?id=${this.item.id}`, this.item)
                    .then(response => {
                        this.$emit('update');
                        this.notifySuccess(`"${this.item.name}" has been updated!`);
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.$emit('close');
                        this.requestIsGoing = false;
                    });
            }
        },
        created() {
            this.MATERIAL = MATERIAL;
            this.checkMeasurements();
        }
    }
</script>