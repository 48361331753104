<template>
    <div 
        :class="[
            'avatar',
            border && isActive ? 'avatar-bordered' : 'avatar-not-bordered',
            !isActive && 'inactive-avatar'
        ]"
        :style="{ width: size + 'px', height: size + 'px', backgroundColor: isActive ? color() : '' }"
    >
        <div 
            v-if="!!url"
            v-bind:style="{ 'background-image': `url('${url}')` }"
            :class="['avatar-img', !isActive && 'in-active']"
        >
            <div
                v-if="!isActive"
                class="inactive-badge s-align-5"
            >
                &times;
            </div>
        </div>
        <div v-else 
            class="avatar-text"
            :style="{ fontSize: size/2.5 + 'px' }"
        >
            <span>{{ getInitials() }}</span>
            <div
                v-if="!isActive"
                class="inactive-badge s-align-5"
            >
                &times;
            </div>
        </div>
        <div 
            class="avatar-status"
            v-if="!!status"
        >
            <span :class="[`avatar-status-${status}`, 's-align-5']">
                <close-icon v-if="status == 'error' || status == 'cancel'"/>
                <clock-icon v-if="status == 'warn'"/>
                <mark-icon 
                    v-if="status == 'success'" 
                    size="12"
                />
            </span>
        </div>
        <div 
            class="avatar-marked"
            v-if="marked"
        >
            <star-icon class="i-yellow"/>
        </div>
    </div>
</template>

<script>
    /*
    * url: avatar image
    *
    * name: [first_name, last_name]
    * 
    * border: boolean
    * 
    * size: multipurpose props for height and width
    *
    * status: warn, error, success, cancel
    * type of status is defined in the parent component
    * an example is Packets.vue
    * 
    * marked: to show star-icon
    */

    import { CloseIcon, ClockIcon, MarkIcon, StarIcon } from '@/icons';
    import { PROJECT_COLORS } from '@/constants';
    import { getAbbreviation } from '@/helpers';

    export default {
        name: 'Avatar',
        components: {
            CloseIcon,
            ClockIcon,
            MarkIcon,
            StarIcon
        },
        props: {
            isActive: {
                type: Number,
                default: 1
            },
            url: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                required: true
            },
            border: {
                type: Boolean,
                default: true
            },
            size: {
                type: [Number, String],
                default: 40
            },
            status: {
                type: String,
                default: ''
            },
            marked: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                colors: PROJECT_COLORS
            }
        },
        methods: {
            getInitials() {
                return getAbbreviation(this.name);
            },
            color() {

                if (! this.name) {
                    return this.colors[0];
                }

                const firstLetter = this.name[0].charCodeAt() - 64;
                return this.colors[firstLetter];
            }
        }
    }
</script>

