<template>
    <div
        class="column-start dropdown-menu"
        :style="maxWidth && `max-width: ${maxWidth}px;`"
    >
        <slot name="header"/>
        <ul class="w-100">
            <perfect-scrollbar
                class="ps-grey"
                :style="`max-height: ${maxHeight}px;`"
            >
                <li
                    v-for="(item, index) in items"
                    :key="`menu-${index}`"
                >
                    <button
                        class="menu-item f-14-darkgrey"
                        @click="$emit('select', item)"
                    >
                        {{ getTitle(item) }}
                    </button>
                </li>
            </perfect-scrollbar>
        </ul>
        <slot name="content"/>
    </div>
</template>

<script>
    export default {
        name: 'DropdownMenu',
        props: {
            items: {
                type: Array,
                required: true
            },
            trackBy: {
                type: String,
                required: true
            },
            maxHeight: {
                type: [String, Number],
                default: 'none'
            },
            maxWidth: {
                type: [String, Number],
                default: 'none'
            }
        },
        methods: {
            getTitle(item) {
                return item[this.trackBy];
            }
        }
    }
</script>