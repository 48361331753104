<template>
    <full-modal 
        class="milestone-re-dispatch"
        :full="viewReDispatchLog"
        @close="$emit('close')"
    >
        <div slot="title">
            {{ viewReDispatchLog
                ? 'Re-Dispatch Details' 
                : 'Re Dispatch Options' }}
        </div>
        <div slot="body">
            <template v-if="!viewReDispatchLog">
                <div v-if="loading">
                    <loader size="normal"/>
                </div>
                <template v-else>
                    <div
                        v-if="dispatchedFrom && dispatchedTo"
                        class="row-baseline pb-6 f-14-asphalt f-sbold"
                    >
                        <status-marker
                            class="mr-5 flex-shrink-0"
                            size="30"
                            :marker="getMilestoneTitle(dispatchedFrom.milestone)"
                            :color="dispatchedFrom.milestone.color"
                        />
                        {{ dispatchedFrom.milestone.title }}
                        <pointerIcon
                            size="12"
                            class="s-align-5 mh-3"
                        />
                        <status-marker
                            class="mr-5 flex-shrink-0"
                            size="30"
                            :marker="getMilestoneTitle(dispatchedTo.milestone)"
                            :color="dispatchedTo.milestone.color"
                        />
                        {{ dispatchedTo.milestone.title }}
                    </div>
                    <div
                        id="reDispatch_comment"
                        class="mb-40"
                    >
                        <p class="f-12-black f-sbold">Add comment</p>
                        <form-input-textarea
                            v-input-counter="2500"
                        >
                            <textarea
                                name="reDispatch_comment"
                                v-model="reDispatch.comment"
                                rows="1"
                                v-resize-textarea
                                :class="[
                                    'form-input-field',
                                    { 'form-input-textarea-danger' : errors.has('reDispatch_comment') }
                                ]"
                                placeholder="Add comment here"
                                maxlength="2500"
                                v-validate="'required|max:2499'"
                            />
                        </form-input-textarea>
                    </div>
                    <div class="row-start row-baseline">
                        <p class="f-12-black f-sbold mb-20">Reschedule Options</p>
                        <radio-button
                            v-for="(type, index) in rescheduleType"
                            :key="index"
                            color="blue"
                            class="w-100 mb-6"
                            :label="type.name"
                        >
                            <input 
                                type="radio" 
                                :name="`reschedule_${type.value}`" 
                                :id="`reschedule_${type.value}`"
                                :value="type.value"
                                v-model="reDispatch.reschedule_type"
                                v-validate="'required'"
                            />
                        </radio-button>
                    </div>
                    <create-new-event-modal
                        v-if="eventCreateModalShown"
                        :entityType="entityType"
                        :calendarList="calendarList"
                        :editableEvent="editableEvent"
                        :mode="eventModalMode"
                        @close="eventCreateModalShown = false"
                        @update="setEventsParamsToReDispatch"
                    />
                    <loader
                        v-if="eventModalLoad"
                        size="normal"
                    />
                </template>
            </template>
            <template v-else>
                <div
                    v-for="item in reDispatchLog"
                    :key="item.id"
                    class="w-100 p-20 pt-7 mb-2 column-start bg-grey-1 br-5"
                >
                    <div class="row-between">
                        <div class="column-start lg-6">
                            <div class="row-baseline">
                                <status-marker
                                    class="mr-5 flex-shrink-0" 
                                    size="30"
                                    :marker="getMilestoneTitle(item.milestone_to)"
                                    :color="item.milestone_to ? item.milestone_to.color : ''"
                                />
                                <div class="column-start">
                                    <span
                                        v-if="item.milestone_to && item.milestone_to.title"
                                        class="f-14-black f-sbold"
                                    >
                                        {{ item.milestone_to.title }}
                                    </span>
                                    <div
                                        v-if="item.user && item.user.fullname"
                                        class="f-12-black"
                                    >
                                        <span class="f-12-grey">by</span>
                                        {{ item.user.fullname }}
                                    </div>
                                </div>
                            </div>
                            <pointerIcon
                                size="12"
                                class="s-align-5 mv-3 ml-3"
                                transform="270"
                            />
                            <div class="row-baseline">
                                <status-marker
                                    class="mr-5 flex-shrink-0" 
                                    size="30"
                                    :marker="getMilestoneTitle(item.milestone_from)"
                                    :color="item.milestone_from ? item.milestone_from.color : ''"
                                />
                                <div
                                    v-if="item.milestone_from && item.milestone_from.title"
                                    class="column-start"
                                >
                                    <span class="f-14-black f-sbold">
                                        {{ item.milestone_from.title }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="column-end">
                            <span class="f-12-grey">
                                {{ item.created_at | createdAt}}
                            </span>
                            <button
                                class="transparent-button f-12-blue f-sbold"
                                @click="showComment(item.id)"
                            >
                                View comment
                                <arrow-icon
                                    class="ml-1 i-blue"
                                    :transform="commentShow && activeComment === item.id ? 270 : 90"
                                />
                            </button>
                        </div>
                    </div>
                    <div
                        v-if="commentShow && activeComment === item.id"
                        class="pt-20 f-13-grey row-start row-no-wrap row-baseline-end preserve-whitespaces"
                    >
                        {{ item.comment }}
                    </div>
                </div>
            </template>
        </div>
        <div slot="footer">
            <button 
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                @click="save"
                :disabled="disabledSaveButton"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, Loader, FormInputTextarea, RadioButton } from '@/ui';
    import { PointerIcon, ArrowIcon } from '@/icons';
    import { ENTITY_TYPES, BASE_ENTITY_TYPES, MODEL_CLASSES, RE_DISPATCH_STATUSES } from '@/constants';
    import StatusMarker from '@/components/StatusMarker.vue';
    import api from '@/headers.js';

    const RESCHEDULE_TYPE = [
        { name: 'Reschedule later', value: 'later' },
        { name: 'Reschedule now', value: 'now' }
    ];
    const CREATE_REDISPATCH_EVENT = 'create_redispatch_event';
    const UPDATE_REDISPATCH_EVENT = 'update_redispatch_event';

    export default {
        name: 'MilestoneReDispatch',
        components: {
            FullModal,
            Loader,
            FormInputTextarea,
            RadioButton,
            StatusMarker,
            PointerIcon,
            ArrowIcon,
            CreateNewEventModal: () => import('@/modals/CreateNewEventModal.vue')
        },
        data() {
            return {
                loading: false,
                eventModalLoad: false,
                reDispatch: {
                    comment: '',
                    reschedule_type: RESCHEDULE_TYPE[0].value
                },
                rescheduleType: RESCHEDULE_TYPE,
                commentShow: false,
                activeComment: null,
                eventCreateModalShown: false,
                calendarList: null,
                eventParams: {},
                eventParamsLoaded: false,
                editableEvent: {},
                eventModalMode: CREATE_REDISPATCH_EVENT
            }
        },
        props: {
            baseEntityType: {
                type: [String],
                required: true
            },
            reDispatchLog: {
                type: Array,
                required: false
            },
            reDispatchFromToMilestones: {
                type: Array,
                required: false
            },
            viewReDispatchLog: {
                type: Boolean,
                required: false
            },
            allCalendars: {
                type: Array,
                required: false
            },
            lastEvent: {
                type: Object,
                required: false
            }
        },
        computed: {
            entityType() {
                switch (this.baseEntityType) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL: {
                        return ENTITY_TYPES.RESIDENTIAL_LEAD;
                    };
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return ENTITY_TYPES.COMMERCIAL_LEAD;
                    };
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB: {
                        return ENTITY_TYPES.COMMERCIAL_JOB;
                    };
                }
            },
            entityId() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD:
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.$route.params.lead_id;
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.$route.params.job_id;
                }
            },
            modelClass() {
                switch (this.baseEntityType) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL: {
                        return MODEL_CLASSES.RESIDENTIAL_LEAD;
                    };
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return MODEL_CLASSES.COMMERCIAL_LEAD;
                    };
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB: {
                        return MODEL_CLASSES.COMMERCIAL_JOB.JOB;
                    };
                }
            },
            dispatchedFrom() {
                if (this.reDispatchFromToMilestones && this.reDispatchFromToMilestones.length) {
                    return this.reDispatchFromToMilestones.find(m => {
                        return m.redispatch_status === RE_DISPATCH_STATUSES.DISPATCHED_FROM;
                    });
                }
            },
            dispatchedTo() {
                if (this.reDispatchFromToMilestones && this.reDispatchFromToMilestones.length) {
                    return this.reDispatchFromToMilestones.find(m => {
                        return m.redispatch_status === RE_DISPATCH_STATUSES.DISPATCHED_TO;
                    });
                }
            },
            lastReDispatchWithEvent() {
                if (this.reDispatchLog && this.reDispatchLog.length) {
                    return this.reDispatchLog.find(m => m.event_id);
                }
                return null;
            },
            disabledSaveButton() {
                return this.loading
                    || this.reDispatch.reschedule_type === this.rescheduleType[1].value
                    && !this.eventParamsLoaded;
            }
        },
        watch: {
            "reDispatch.reschedule_type": async function(newVal) {
                this.eventModalLoad = true;
                if (newVal === RESCHEDULE_TYPE[1].value) {
                    if (!this.allCalendars) {
                        await this.getCalendarList();
                    }
                    
                    this.calendarList = this.allCalendars;
                    this.eventModalMode = CREATE_REDISPATCH_EVENT;

                    if (this.lastReDispatchWithEvent) {
                        await this.getSingleEvent(
                            this.lastReDispatchWithEvent.calendar_id, 
                            this.lastReDispatchWithEvent.event_id
                        );
                        this.eventModalMode = UPDATE_REDISPATCH_EVENT;
                    }

                    this.eventCreateModalShown = true;
                } else {
                    this.eventCreateModalShown = false;
                    this.eventParams = {};
                    this.eventParamsLoaded = false;
                }
                this.eventModalLoad = false;
            }
        },
        methods: {
            save() {
                let params = {
                    ...this.reDispatch,
                    entity_type: this.modelClass,
	                entity_id: this.entityId,
                    entityType: this.entityType
                };

                if (this.eventParams) {
                    params = Object.assign(params, this.eventParams);
                }

                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('Please fill required field');

                        let domRect;
                        domRect = document.getElementById(this.$validator.errors.items[0].field);

                        // scroll to the first mistake
                        domRect != null || domRect != undefined 
                            ? domRect.scrollIntoView({ behavior: 'smooth', block: 'start'}) 
                            : '';
                        return;
                    }

                    this.loading = true;
                    api.post(`/milestones/redispatch`, params)
                        .then(response => response.data.data)
                        .then(data => {
                            this.$emit('updateReDispatchLog', data);
                        })
                        .catch(error => this.notifyRequestErrors(error))
                        .finally(() => this.loading = false);
                });
            },
            showComment(index) {
                if (this.activeComment !== index) {
                    this.commentShow = false;
                }

                this.activeComment = index;
                this.commentShow = !this.commentShow;
            },
            getMilestoneTitle(result) {
                if (!result) {
                    return '';
                }

                return !result.title ? '' : result.title;
            },
            getCalendarList() {
                let params = {
                    entity_id: this.entityId,
                    entity_type: this.entityType
                };

                return api.get(`/calendars/events/entity`, { params })
                    .then(response => response.data.data)
                    .then(data => {                 
                        this.calendarList = data.calendar_list;
                        this.$emit('saveCalendarList', this.calendarList);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    });
            },
            getSingleEvent(calendar_id, event_id) {
                if (calendar_id 
                    && !this.lastEvent || event_id !== this.lastEvent.id) {
                    return api.get(`/calendars/${calendar_id}/events/${event_id}`)
                        .then(response => response.data.data)
                        .then(data => {
                            this.editableEvent = data;
                            this.editableEvent.summary = '';
                            this.editableEvent.dtstart = null;
                            this.editableEvent.dtend = null;
                            this.$emit('saveLastEvent', data);
                        })
                        .catch(error => this.notifyRequestErrors(error));
                } else {
                    this.editableEvent = this.lastEvent;
                }
            },
            setEventsParamsToReDispatch(mode, event) {
                for(let pair of event.entries()) {
                    let key = pair[0];

                    if (key === 'lat' || key === 'lng') {
                        this.eventParams[key] = null;
                    } else {
                        this.eventParams[key] = pair[1];
                    }
                }
                this.eventParamsLoaded = true;
            }
        }
    }
</script>