<template>
    <svg
        viewBox="0 0 100 30"
        :width="size"
        :height="size"
        class="no-messages"
    >
        <g>
            <path
                fill="#E5E1F9"
                d="M97.8,11.5h-6.4c-0.7,0-1.3-0.6-1.3-1.3v0c0-0.7,0.6-1.3,1.3-1.3h6.4c0.7,0,1.3,0.6,1.3,1.3v0C99.1,10.9,98.5,11.5,97.8,11.5z"
            />
            <path
                fill="#E5E1F9"
                d="M11.8,46.8H1.3c-0.7,0-1.3-0.6-1.3-1.3v0c0-0.7,0.6-1.3,1.3-1.3h10.4c0.7,0,1.3,0.6,1.3,1.3v0C13,46.2,12.5,46.8,11.8,46.8z"
            />
            <g>
                <path
                    fill="#EFF1F4"
                    d="M20.8,14.2c-1.7,0.3-3.1,1.6-3.5,3.2l-0.1,0.4v11.5c0,9.5,0,11.5,0.1,11.7c0.2,0.8,0.6,1.6,1.1,2.2c0.5,0.5,1.3,1,2.2,1.2c0.5,0.1,1,0.2,1.6,0.1h1.9h0.6v3.8c0,4.3,0,3.9,0.3,4.3s0.9,0.4,1.3,0.2c0.1-0.1,1.1-1.1,2.3-2.3l4.1-4.1l1.9-1.9h12.8c8.4,0,12.8,0,13,0c1.9-0.3,3.4-1.8,3.7-3.7c0-0.2,0-3.4,0-11.6V18L64,17.6c-0.2-0.7-0.5-1.4-1-1.9c-0.6-0.6-1.4-1.1-2.3-1.3c-0.3-0.1-1.7-0.1-19.8-0.1C24.1,14.2,21,14.2,20.8,14.2z"
                />
                <path
                    fill="#BCD6F9"
                    d="M63.5,0C45.4,0,44,0,43.7,0.1c-0.9,0.2-1.6,0.7-2.2,1.3c-0.5,0.5-0.9,1.2-1.1,1.9l-0.1,0.3v7.8h22.6c1.8,0,3.9,2.3,3.9,4.8v14.1h3l1.9,1.9l4.1,4.1c1.2,1.2,2.2,2.3,2.3,2.3c0.3,0.3,0.9,0.2,1.2-0.2c0.3-0.3,0.3,0,0.3-4.3v-3.8h0.6H82c0.5,0,1,0,1.6-0.1c1.6-0.4,2.9-1.7,3.4-3.4c0-0.2,0.1-2.3,0.1-11.7V3.6L87,3.3c-0.4-1.6-1.8-3-3.5-3.3C83.1,0,80,0,63.5,0z"
                />
                <path
                    fill="#BCD6F9"
                    d="M27.2,30.5h18.3c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8l0,0H27.2c-0.4-0.1-0.8,0.3-0.9,0.7s0.3,0.8,0.7,0.9C27.1,30.5,27.1,30.5,27.2,30.5L27.2,30.5z"
                />
                <path
                    fill="#BCD6F9"
                    d="M27.1,24.7h11.5c0.4,0.1,0.8-0.3,0.9-0.7s-0.3-0.8-0.7-0.9c-0.1,0-0.1,0-0.2,0H27.1c-0.4-0.1-0.8,0.3-0.9,0.7s0.3,0.8,0.7,0.9C27,24.7,27,24.7,27.1,24.7L27.1,24.7z"
                />
                <path
                    fill="#BCD6F9"
                    d="M27.2,35.9h18.3c0.4,0.1,0.8-0.3,0.9-0.7c0.1-0.4-0.3-0.8-0.7-0.9c-0.1,0-0.1,0-0.2,0H27.2c-0.4-0.1-0.8,0.3-0.9,0.7c-0.1,0.4,0.3,0.8,0.7,0.9C27.1,35.9,27.1,35.9,27.2,35.9L27.2,35.9z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'NoMessages',
        props: {
            size: {
                type: [Number, String],
                default: 17
            }
        }
    }
</script>