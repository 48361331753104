<template>
    <svg
        x="0px"
        y="0px"
        viewBox="0 0 28 25"
        :width="size"
        :height="size"
        class="no-template-icon"
    >
        <g>
            <path
                fill="#006BFD"
                d="M24.6,24.1h-5c-0.6,0-1-0.4-1-1s0.4-1,1-1h5c0.3,0,0.6-0.2,0.8-0.4c0.3-0.5,0.9-0.6,1.4-0.3c0.5,0.3,0.6,0.9,0.3,1.4
                C26.5,23.6,25.6,24.1,24.6,24.1z M15.6,24.1h-5c-0.6,0-1-0.4-1-1s0.4-1,1-1h5c0.6,0,1,0.4,1,1S16.2,24.1,15.6,24.1z M6.6,24.1H2.9
                c-0.7,0-1.3-0.2-1.8-0.6c-0.4-0.3-0.5-1-0.2-1.4c0.3-0.4,1-0.5,1.4-0.2c0.1,0.1,0.3,0.2,0.6,0.2h3.7c0.6,0,1,0.4,1,1
                S7.2,24.1,6.6,24.1z M1,19.9c-0.6,0-1-0.4-1-1v-5c0-0.6,0.4-1,1-1s1,0.4,1,1v5C2,19.4,1.6,19.9,1,19.9z M26.5,19.2
                c-0.6,0-1-0.4-1-1v-5c0-0.6,0.4-1,1-1s1,0.4,1,1v5C27.5,18.8,27.1,19.2,26.5,19.2z M1,10.9c-0.6,0-1-0.4-1-1v-5c0-0.6,0.4-1,1-1
                s1,0.4,1,1v5C2,10.4,1.6,10.9,1,10.9z M26.5,10.2c-0.6,0-1-0.4-1-1v-5c0-0.6,0.4-1,1-1s1,0.4,1,1v5C27.5,9.8,27.1,10.2,26.5,10.2z
                 M2,2.2C1.7,2.2,1.3,2,1.1,1.7C0.9,1.2,1,0.6,1.5,0.4C2,0.1,2.4,0,2.9,0c0,0,0,0,0,0h4c0.6,0,1,0.4,1,1S7.5,2,7,2h-4c0,0,0,0,0,0
                C2.8,2,2.6,2,2.5,2.1C2.3,2.2,2.2,2.2,2,2.2z M25,2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0H20c-0.6,0-1-0.4-1-1s0.4-1,1-1h4.7
                c0.2,0,0.3,0,0.5,0c0.5,0.1,0.9,0.6,0.8,1.2C25.9,1.7,25.4,2,25,2z M16,2h-5c-0.6,0-1-0.4-1-1s0.4-1,1-1h5c0.6,0,1,0.4,1,1
                S16.5,2,16,2z"
            />
        </g>
    </svg>
</template>
<script>
    export default {
        name: 'NoTemplateIcon',
        props: {
            size: {
                type: [Number, String],
                default: 15
            }
        }
    }
</script>