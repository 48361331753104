<template>
    <modal
        class="select-entity-modal"
        @close="$emit('close')"
    >
        <div slot="modal-header">
            <div class="w-100 t-left pb-4">
                <span class="f-24-black f-sbold">
                    Convert to Smart Document
                </span>
                <br/>
                <span class="f-14-grey">
                    Choose type of leads and/or jobs document will be available for
                </span>
            </div>
        </div>
        <div slot="modal-body">
            <div class="row-around mv-35">
                <div
                    v-for="(item,index) in leadTypes" 
                    :key="index"
                    :class="[
                        'choose-lead-type relative s-align-5 pointer',
                        { 'selected-lead-type' : selectedType == item.name }
                    ]"
                    @click="selectedType = item.name"
                >
                    <div class="angle-input">
                        <input 
                            type="checkbox" 
                            :name="`selectType-${index}`"
                            v-model="selectedType"
                            :value="item.name"
                        />
                        <span
                            v-if="selectedType == item.name" 
                            class="angle-input-icon"
                        >
                            <mark-icon size="12"/>
                        </span>
                    </div>
                    <span :class="[
                        'w-50 t-center f-sbold',
                        selectedType == item.name ? 'f-15-blue' : 'f-15-black'
                    ]">
                        {{ item.title }}
                    </span>
                </div>
            </div>
        </div>
        <div slot="modal-footer">
            <div class="row-end">
                <button 
                    class="add-button"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button 
                    class="primary-button ml-5"
                    @click="$emit('continue', detectEntityType)"
                    :disabled="!selectedType"
                >
                    Continue
                    <arrow-icon
                        class="i-white ml-6"
                        size="11"
                        transform="0"
                    />
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
    import { CheckboxButton, Modal } from '@/ui';
    import { ArrowIcon, MarkIcon } from "@/icons";
    import { ENTITY_TYPES } from '@/constants';

    export default {
        name: 'SelectEntityModal',
        components: {
            CheckboxButton,
            ArrowIcon,
            Modal,
            MarkIcon
        },
        data() {
            return {
                leadTypes: [
                    {
                        name: 'Residential Lead&Job',
                        title: 'Residential Lead&Job'
                    },
                    {
                        name: 'CommercialLead',
                        title: 'Commercial Lead'
                    },
                    {
                        name: 'CommercialJob',
                        title: 'Commercial Job'
                    }
                ],
                selectedType: ''
            }
        },
        computed: {
            detectEntityType() {
                if (this.selectedType) {
                    switch(this.selectedType) {
                        case this.leadTypes[0].name:
                            return ENTITY_TYPES.RESIDENTIAL;
                        case this.leadTypes[1].name:
                            return ENTITY_TYPES.COMMERCIAL_LEAD;
                        case this.leadTypes[2].name:
                            return ENTITY_TYPES.COMMERCIAL_JOB;
                    }
                }
            }
        }
    }
</script>