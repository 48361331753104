<template>
    <full-modal 
        @close="$emit('close')"
        class="create-new-tax-modal"
    >
        <div slot="title">Add Tax Rate</div>
        <div slot="body">
            <div class="column-static w-100">
                <p class="f-14-black f-sbold pb-4">Tax Settings</p>
                <div class="w-100 pb-30">
                    <form-input 
                        :class="[{ 'form-input-danger' : errors.has('tax-name') }]"
                        label="Tax name"
                    >
                        <input 
                            type="text" 
                            name="tax-name" 
                            id="tax-name"
                            class="form-input-field"
                            placeholder="Tax Name"
                            v-model="tax.name"
                            v-input-style="tax.name"
                            v-validate="'required'"
                        />
                    </form-input>
                </div>
                <div class="pb-30 row-between bb-grey">
                    <div class="s-align-4">
                        <p class="f-14-black f-sbold">Tax percentage:</p>
                    </div>
                    <div class="lg-4">
                        <percent-count 
                            :profitMargin="tax.percent"
                            :sectionIndex="0"
                            @set="setPercentage"
                        />
                    </div>
                </div>
                <div class="w-100 pt-20">
                    <checkbox-button 
                        color="dark-blue"
                        :label="tax.is_on ? 'Active' : 'Activate tax'"
                        class="f-14-black f-sbold"
                    >
                        <input 
                            type="checkbox" 
                            name="tax-active" 
                            v-model="tax.is_on"
                        />
                    </checkbox-button>
                </div>
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                @click="saveTax"
                ref="create_button"
            >
                {{ existing ? 'Save' : 'Create' }}
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, FormInput, CheckboxButton, PercentCount } from '@/ui';

    export default {
        name: 'CreateNewTaxModal',
        components: {
            FullModal,
            FormInput, 
            CheckboxButton, 
            PercentCount
        },
        props: {
            existing: {
                type: Object,
                default: function() {
                    return {};
                }
            }
        },
        data() {
            return {
                tax: {
                    name: '',
                    percent: 0,
                    is_on: true,
                    material_only: '',
                    apply_pm: ''
                }
            }
        },
        methods: {
            setPercentage(data) {
                this.tax.percent = data;
            },
            saveTax() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('Field "Tax Name" is required.');

                        return;
                    }

                    this.existing
                        ? this.$emit('update', this.tax)
                        : this.$emit('create', this.tax);
                });
            }
        },
        created() {
            if (this.existing) {
                this.tax = this.existing;
            }
        }
    }
</script>
