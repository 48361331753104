<template>
    <div class="google-map"></div>
</template>

<script>
    /* 
    * coords: { lat: lat, lng: lng } or { lat: lat_billing, lng: lng_billing }
    * zoom - 21 max
    * satellite - show satellite map
    */

    export default {
        name: 'GoogleMap',
        props: {
            coords: {
                type: Object,
                default: null
            },
            zoom: {
                type: Number,
                default: 0
            },
            satellite: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                map: '',
                marker: '',
                animateMarker: true
            }
        },
        watch: {
            coords: {
                handler(newValue, oldValue) {
                    if (newValue && newValue.lat != oldValue.lat || newValue.lng != oldValue.lng)
                        this.runMap();
                },
                deep: true
            }
        },
        methods: {
            runMap() {
                this.map = new google.maps.Map(this.$el, {
                    zoom: this.zoom,
                    center: this.coords,    
                    mapTypeId: this.satellite ? google.maps.MapTypeId.SATELLITE : google.maps.MapTypeId.ROADMAP
                });

                this.marker = new google.maps.Marker({ 
                    position: this.coords,
                    map: this.map,
                    animation: this.animateMarker ? google.maps.Animation.DROP : ''
                });

                this.animateMarker = false;
            }
        },
        mounted() {
            this.runMap();
        }
    }
</script>
