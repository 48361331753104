<template>
    <full-modal @close="$emit('close')">
        <div slot="title">{{ title }}</div>
        <div slot="body">
            <div class="pb-20">
                <p class="f-14-darkgrey f-sbold pb-4">Enter a {{ fieldName }} name</p>
                <form-input
                    :label="`${fieldName} name`"
                    :class="[{'form-input-danger': errors.first('template_name')}]"
                >
                    <input
                        class="form-input-field"
                        name="template_name"
                        :placeholder="`${fieldName} name`"
                        v-model="name"
                        v-validate="'required|min:2|max:255'"
                    >
                </form-input>
            </div>
            <p class="f-14-darkgrey f-sbold pb-4">
                Enter abbreviation for the {{ fieldName }} name
            </p>
            <form-input 
                label="Abbreviation"
                :class="[{'form-input-danger': errors.first('abbreviation')}]"
            >
                <input
                    class="form-input-field"
                    name="abbreviation"
                    v-model="abbreviation"
                    placeholder="Abbreviation"
                    v-validate="'required|min:2|max:255'"
                >
            </form-input>
        </div>
        <div slot="footer">
            <button
                class="add-button mr-4"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                @click="onSave"
                :disabled="requestIsGoing"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FormInput, CheckboxButton, FullModal } from '@/ui';
    import api from '@/headers.js';
    import { MODEL_CLASSES, MODEL_NAMES, BASE_ENTITY_TYPES } from '@/constants';
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'CustomFieldsCreateLayoutModal',
        components: {
            FormInput, 
            CheckboxButton, 
            FullModal,
            Multiselect
        },
        props: {
            order: {
                type: Number,
                required: true
            },
            type: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                name: '',
                abbreviation: '',
                requestIsGoing: false
            }
        },
        computed: {
            title: function() {
                switch (this.type) {
                    case 'formBuilder':
                        return 'Form Builder Template';
                        break;
                    case 'commercialJob':
                        return 'Create a job type';
                        break;
                }
            },
            fieldName: function() {
                switch (this.type) {
                    case 'formBuilder':
                        return 'template';
                        break;
                    case 'commercialJob':
                        return 'job type';
                        break;
                }
            },
            modelClass: function() {
                switch (this.type) {
                    case 'formBuilder':
                        return MODEL_CLASSES.CUSTOM_REPORT;
                        break;
                    case 'commercialJob':
                        return MODEL_CLASSES.COMMERCIAL_JOB.JOB;
                        break;
                }
            },
            modelName: function() {
                switch (this.type) {
                    case 'formBuilder':
                        return MODEL_NAMES.REPORT;
                        break;
                    case 'commercialJob':
                        return MODEL_NAMES.COMMERCIAL_JOB;
                        break;
                }
            }
        },
        methods: {
            onSave() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('All fields are required!');

                        return;
                    }

                    this.createNewTemplate();
                });
            },
            createNewTemplate() {
                let params = {
                    'name' : this.name,
                    'abbreviation' : this.abbreviation,
                    'model_name' : this.modelName,
                    'model_class' : this.modelClass,
                    'is_custom' : true,
                    'main_section_order' : this.order + 1
                };

                this.requestIsGoing = true;
                api.post('/layouts', params)
                    .then(response => {
                        this.notifySuccess('Template created.');
                        this.$emit('created', response.data.data);
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            }
        }
    }
</script>