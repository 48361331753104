<template>
    <full-modal 
        @close="$emit('close')"
        :in-dev="inDev"
    >
        <div slot="title">{{ title }}</div>
        <div slot="body">
            <div 
                class="w-100 pb-20" 
                :style="{ height: `${height}px` }"
            >
                <form-input-textarea v-input-counter="length">
                    <textarea
                        :class="
                            ['form-input-field', 'add-note-textarea',
                            { 'form-input-textarea-danger' : errors.has('note') }]
                        "
                        rows="1"
                        placeholder="Type text here..."
                        :maxlength="length"
                        v-model="content"
                        v-validate="validate"
                        v-resize-textarea
                        name="note"
                        @input="getTextareaHeight"
                    />
                </form-input-textarea>
            </div>
        </div>
        <div slot="footer">
            <button
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                @click="save"
                :disabled="disabled"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    /*
    * title: headline of modal
    *
    * text: received or edited text
    *
    * length: maximum number of characters
    *
    * disabled: if it needs disable save button when request is going
    *
    * validate: validate note rule
    */

    import { FormInputTextarea, FullModal } from '@/ui';
    import { getHeight } from '@/helpers';

    export default {
        name: 'AddNoteModal',
        props: {
            title: {
                type: String,
                default: ''
            },
            text: {
                type: String,
                default: ''
            },
            length: {
                type: Number,
                default: 250
            },
            disabled: {
                type: Boolean,
                default: false
            },
            validate: {
                type: String,
                default: ''
            },
            inDev: {
                type: Boolean,
                default: false
            }
        },
        components: {
            FormInputTextarea,
            FullModal
        },
        data() {
            return {
                content: '',
                height: 'auto'
            }
        },
        methods: {
            save() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        this.$emit('save', this.content);
                    } else {
                        this.notifyError(this.$validator.errors.items[0].msg);
                    };
                });
            },
            getTextareaHeight() {
                this.height = getHeight(this.$el.querySelector('.add-note-textarea')) + 80;
            }
        },
        created() {
            this.content = this.text;
        }
    }
</script>
