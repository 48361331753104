<template>
    <form-input 
        :class="[
            'form-input-percent', 
            { 'form-input-danger' : errors.has('percent_field') }
        ]"
        :label="label"
        :maskValue="value"
        maskAfter="%"
    >
        <input
            class="form-input-field"
            type="text"
            v-input-style="value"
            name="percent_field"
            :placeholder="placeholder"
            v-model.number="value"
            @input="$emit('setValue', value)"
            v-limiter
            v-validate="'required|min_value:1'"
        />
        <button
            class="decrease-button transparent-button"
            @click="value--"
            :disabled="value <= 0"
        >
            <arrow-icon class="i-grey"/>
        </button>
        <button
            class="increase-button transparent-button"
            @click="value++"
        >
            <arrow-icon
                class="i-grey"
                transform="270"
            />
        </button>
    </form-input>
</template>

<script>
    import { ArrowIcon } from '@/icons';
    import { FormInput } from '@/ui';

    export default {
        name: 'PercentArrowInput',
        components: {
            ArrowIcon,
            FormInput
        },
        props: {
            label: {
                type: [String],
                default: ''
            },
            placeholder: {
                type: [String],
                default: ''
            },
            validate: {
                type: Boolean,
                required: false
            },
            editedValue: {
                type: [String, Number],
                required: false
            }
        },
        data() {
            return {                
                value: ''
            }
        },
        watch: {
            value(newValue, oldValue) {
                if (newValue < 0) {
                    this.value = 1;
                    this.sendResult();
                } else if (newValue > 100) {
                    this.value = 100;
                    this.sendResult();
                } else {
                    this.sendResult();
                }
                this.$emit('validationResult', true);
            },
            validate(newVal) {
                if (newVal) {
                    this.$validator.validateAll().then(result => {
                        if (result) {
                            this.$emit('validationResult', true);
                        } else {
                            this.$emit('validationResult', false);
                        }
                    });
                }
            }
        },
        methods: {
            focusOnInput() {
                this.$el.querySelector('input').focus();
            },
            sendResult() {
                this.$nextTick(function() {
                    this.$emit('setValue', this.value);
                });
            }
        },
        created() {
            if (this.editedValue) {
                this.value = this.editedValue;
            }
        }
    }
</script>
