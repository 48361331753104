<template>
    <button
        class="submit-button"
        :disabled="disabled || status"
    >
        <loading v-if="status"/>
        <span v-else>{{ title }}</span>
    </button>
</template>

<script>
    /*
    * status: is loading (if true button is disabled)
    * 
    * title: title for button
    * 
    * disabled: if the button should be disabled
    */

    import { Loading } from '@/icons';

    export default {
        name: 'SubmitButton',
        components: {
            Loading
        },
        props: {
            status: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: 'Save'
            },
            disabled: {
                type: Boolean,
                default: false
            }
        }
    }
</script>