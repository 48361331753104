<template>
    <div class="project-management-archive column-start h-100">
        <div class="archive-title row-between">
            <p class="f-24-darkgrey f-sbold">
                Archive
            </p>
            <button 
                class="transparent-button"
                @click="$emit('close')"
            >
                <close-icon 
                    class="i-grey"
                    size="15"
                />
            </button>
        </div>
        <div class="archive-tabs w-100 bb-grey">
            <button 
                :class="[
                    'archive-tab w-50',
                    { 'active' : activeTab === 'columns' }
                ]"
                @click="activeTab = 'columns'"
            >
                <span class="tab-name">Columns</span>
            </button>
            <button 
                :class="[
                    'archive-tab w-50',
                    { 'active' : activeTab === 'cards' }
                ]"
                @click="showCards"
            >
                <span class="tab-name">Cards</span>
            </button>
        </div>
        <div class="archive-search row-start row-baseline bb-grey">
            <search-icon 
                class="i-grey"
                size="14"
            />
            <input 
                class="ml-4 f-14-darkgrey"
                type="text"
                name="archive_search"
                placeholder="Search by title"
                v-model="needToFind"
            >
            <button
                v-if="needToFind.length"
                class="transparent-button"
                @click="needToFind = ''"
            >
                <close-icon 
                    class="i-red"
                />
            </button>
        </div>
        <loader
            v-if="loading"
        />
        <perfect-scrollbar
            v-else-if="activeTab === 'columns'"
            class="ps-grey w-100"
        >
            <div
                v-for="(column, index) in columnsArchive"
                :key="column.id"
                class="archive-column row-between row-baseline mb-1"
            >
                <div class="w-45">
                    <p class="f-14-black ellipsis">
                        {{ column.title }}
                    </p>
                    <p class="f-12-grey">
                        {{ column.count_archive_cards }} tasks
                    </p>
                </div>
                <div class="w-50 row-end">
                    <button 
                        class="unarchive-btn transparent-button s-align-5"
                        @click="restoreColumn(column.id, index)"
                        :disabled="requestIsGoing"
                    >
                        <next-icon 
                            transform="180"
                            width="9"
                            height="7"
                            class="i-blue mr-2"
                        />
                        <span class="f-11-blue">Unarchive</span>
                    </button>
                    <button 
                        class="delete-btn transparent-button ml-1 s-align-5"
                        @click="showDeleteColumnConfirm(column, index)"
                        :disabled="requestIsGoing"
                    >
                        <trash-icon
                            width="16"
                            height="12"
                            class="i-grey"
                        />
                    </button>
                </div>
            </div>
            <not-found-message
                v-if="!columnsArchive.length"
                custom="Sorry, nothing matches your request"
            />
        </perfect-scrollbar>
        <perfect-scrollbar
            v-else-if="activeTab === 'cards'"
            class="ps-grey w-100"
        >
            <project-management-card-preview
                v-for="(card, index) in cardsArchive"
                :key="index"
                class="pointer"
                :card="card"
                :cardIndex="index"
                :archiveCard="true"
                :requestIsGoing="requestIsGoing"
                @showDeleteCardConfirm="showDeleteCardConfirm(card, index)"
                @restoreCard="restoreCard(card.id, index)"
                @click.native="showCardModal(card, index)"
            />
            <not-found-message
                v-if="!cardsArchive.length"
                custom="Sorry, nothing matches your request"
            />
        </perfect-scrollbar>
        <project-management-delete-column-modal
            v-if="isColumnDeleteConfirmModalShow"
            :id="deletingColumnId"
            :title="deletingColumnTitle"
            @onDeleteColumn="removeColumnFromBoard"
            @close="isColumnDeleteConfirmModalShow = false"
        />
        <project-management-delete-card-modal
            v-if="isCardDeleteConfirmModalShow"
            :id="deletingCardId"
            :title="deletingCardTitle"
            @onDeleteCard="removeCardFromBoard"
            @close="isCardDeleteConfirmModalShow = false"
        />
        <project-management-card 
            v-if="isCardModalShow"
            @close="closeCardModal"
            :cardSlug="selectedCardSlug"
        />
    </div>
</template>

<script>
    import { ProjectManagementDeleteColumnModal, ProjectManagementDeleteCardModal } from '@/modals';
    import { ProjectManagementCard, ProjectManagementCardPreview, NotFoundMessage } from '@/components';
    import { CloseIcon, SearchIcon, NextIcon, TrashIcon } from '@/icons';
    import { Loader } from '@/ui';
    import api from '@/headers.js';
    import { clearArray } from '@/helpers';
    import debounce from 'lodash/debounce';

    export default {
        name: 'ProjectManagementArchive',
        components: {
            ProjectManagementDeleteColumnModal,
            ProjectManagementDeleteCardModal,
            ProjectManagementCard,
            ProjectManagementCardPreview,
            NotFoundMessage,
            CloseIcon,
            SearchIcon,
            NextIcon,
            TrashIcon,
            Loader
        },
        props: {
            boardId: {
                type: [Number, String],
                required: true
            }
        },
        data() {
            return {
                loading: true,
                requestIsGoing: false,
                activeTab: 'columns',
                needToFind: '',
                columnsArchiveLoaded: false,
                columnsArchive: [],
                cardsArchiveLoaded: false,
                cardsArchive: [],
                isColumnDeleteConfirmModalShow: false,
                deletingColumnId: null,
                deletingColumnTitle: '',
                deletingColumnIndex: null,
                isCardDeleteConfirmModalShow: false,
                deletingCardId: null,
                deletingCardTitle: '',
                deletingCardIndex: null,
                isCardModalShow: false,
                selectedCardSlug: '',
                selectedCardIndex: null
            }
        },
        watch: {
            needToFind: function() {
                this.search();
            }
        },
        methods: {
            getColumnsArchive(showLoader = true) {
                const params = {
                    board_id: this.boardId,
                    q: this.needToFind
                };

                if (showLoader) {
                    this.loading = true;
                }
                api.get('/kanban/columns/archive/', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        clearArray(this.columnsArchive);
                        this.columnsArchive.push(...data);
                        this.columnsArchiveLoaded = true;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        if (showLoader) {
                            this.loading = false;
                        }
                    });
            },
            restoreColumn(id, index) {
                this.requestIsGoing = true;
                api.post(`kanban/columns/${id}`)
                    .then(response => response.data.data)
                    .then(data => {
                        if (data) {
                            this.notifySuccess('Column restored!');
                            this.columnsArchive.splice(index, 1);
                            this.$emit('reloadBoard');
                        }
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            },
            restoreCard(id, index) {
                this.requestIsGoing = true;
                api.post(`kanban/cards/${id}`)
                    .then(response => response.data.data)
                    .then(data => {
                        if (data) {
                            this.notifySuccess('Card restored!');
                            this.cardsArchive.splice(index, 1);
                            this.$emit('reloadBoard');
                        }
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            },
            showCards() {
                this.activeTab = 'cards';

                if (!this.cardsArchiveLoaded) {
                    this.getCardsArchive();
                }
            },
            getCardsArchive(showLoader = true) {
                const params = {
                    board_id: this.boardId,
                    q: this.needToFind
                };

                if (showLoader) {
                    this.loading = true;
                }
                api.get('/kanban/cards/archive/', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        clearArray(this.cardsArchive);
                        this.cardsArchive.push(...data);
                        this.cardsArchiveLoaded = true;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        if (showLoader) {
                            this.loading = false;
                        }
                    });
            },
            showDeleteColumnConfirm(column, columnIndex) {
                this.deletingColumnId = column.id;
                this.deletingColumnTitle = column.title;
                this.deletingColumnIndex = columnIndex;

                this.isColumnDeleteConfirmModalShow = true;
            },
            // remove column from board when delete/archive request finished
            removeColumnFromBoard() {
                this.columnsArchive.splice(this.deletingColumnIndex, 1);
            },
            showDeleteCardConfirm(column, columnIndex) {
                this.deletingCardId = column.id;
                this.deletingCardTitle = column.title;
                this.deletingCardIndex = columnIndex;

                this.isCardDeleteConfirmModalShow = true;
            },
            // remove card from board when delete/archive request finished
            removeCardFromBoard() {
                this.cardsArchive.splice(this.deletingCardIndex, 1);
            },
            search: debounce(function() {
                switch (this.activeTab) {
                    case 'columns':
                        this.getColumnsArchive();
                        break;
                    case 'cards':
                        this.getCardsArchive();
                        break;
                }
            }, 300),
            showCardModal(card, cardIndex) {
                this.selectedCardSlug = card.slug;
                this.selectedCardIndex = cardIndex;
                this.isCardModalShow = true;
            },
            closeCardModal() {
                let query = Object.assign({}, this.$route.query);
                delete query.card;
                this.$router.push({ query });
                this.getCardsArchive(false);

                this.selectedCardSlug = null;
                this.selectedCardIndex = null;
                this.isCardModalShow = false;
            },
        },
        created() {
            this.getColumnsArchive();
        }
    }
</script>