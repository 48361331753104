var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'tooltip',
        _vm.mobileDescription === '' ? 'tooltip-without-mobile-description' : '',
        _vm.fixedDirection ? 'tooltip-fixed-direction' : ''
    ]},[_vm._t("default"),(_vm.mobileDescription !== '')?_c('div',{staticClass:"tooltip-mobile-description"},[_vm._v("\n        "+_vm._s(_vm.mobileDescription)+"\n    ")]):_vm._e(),_c('div',{class:[
            'tooltip-content',
            ("tooltip-content-" + _vm.direction),
            ("tooltip-content-" + _vm.color),
            _vm.isStatic ? 'tooltip-content-static' : '',
            _vm.isShow ? 'tooltip-content-static-show' : ''
        ]},[_c('div',{staticClass:"tooltip-content-inner"},[_vm._t("content")],2)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }