<template>
    <svg
        viewBox="0 0 16 16"
        :width="size"
        :height="size"
        :style="{ transform: 'rotate('+ transform + 'deg)' }"
    >
        <path d="M7.99 0L15.97 7.99L14.12 9.85L9.28 5V15.74H6.66V5.04L1.85 9.85L0 7.99L7.99 0Z"/>
    </svg>
</template>

<script>
    export default {
        name: 'ArrowUpIcon',
        props: {
            size: {
                type: [Number, String],
                default: 16
            },
            transform: {
                type: [Number, String],
                default: 0
            }
        }
    }
</script>