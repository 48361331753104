<template>
    <svg
        x="0px"
        y="0px"
        viewBox="0 0 19 19"
        :width="size"
        :height="size"
        class="app-icon"
    >
        <path d="M17.5,0h-16C0.7,0,0,0.7,0,1.5v16.1C0,18.3,0.7,19,1.5,19h16.1c0.8,0,1.5-0.7,1.5-1.5v-16C19,0.7,18.3,0,17.5,0 z M7.5,17.7H2.1c-0.5,0-0.9-0.4-0.9-0.9V2.2c0-0.5,0.4-0.9,0.9-0.9h5.4V17.7z M17.8,16.8c0,0.5-0.4,0.9-0.9,0.9H8.8v-9h9V16.8z M17.8,7.4h-9V1.3h8.1c0.5,0,0.9,0.4,0.9,0.9V7.4z"/>
    </svg>
</template>
<script>
    export default {
        name: 'AppIcon',
        props: {
            size: {
                type: [Number, String],
                default: 15
            }
        }
    }
</script>