<template>
    <div
        :id="`context-menu-container${unicId}`"
        class="relative"
    >
        <button
            @click.stop.prevent="toggleOpenContextMenu(unicId)"
            :class="btnStyle"
            :disabled="disabled"
        >
            <points-icon
                v-if="iconType == 'points'"
                :width="pointsWidth"
                :height="pointsHeight"
            />
            <gear-icon
                :size="gearSize"
                v-else
            />
        </button>
        <div
            :class="[
                'context-menu',
                !isShowPopup && 'visiblity-hidden'
            ]"
            :id="unicId"
            :style="`${stylePopup};${widthContextMenu}`"
            v-if="open && unicId == openContextMenu"
            @click.stop
        >
            <slot name="default"></slot>
        </div>
    </div>
</template>

<script>
    import { PointsIcon, GearIcon } from '@/icons';
    import { getCoords } from '@/helpers.js'
    /*
    * coordinates: any styles for this component
    * usually: top, left, right or bottom
    *
    * unicId: this component is often displayed in a loop,
    * so everyone needs to set a unique id
    *
    * iconType: can be 'points' (default) or 'gear'
    *
    * pointsWidth, pointsHeight, gearSize - size of menu icons
    *
    * btnStyle: class for menu icon
    */

    export default {
        name: 'ContextMenu',
        props: {
            width: {
                type: [String, Number],
                default: ''
            },
            unicId: {
                type: String,
                required: true
            },
            iconType: {
                type: String,
                default: 'points'
            },
            pointsWidth: {
                type: [String, Number],
                default: 15
            },
            pointsHeight: {
                type: [String, Number],
                default: 20
            },
            gearSize: {
                type: [String, Number],
                default: 17
            },
            btnStyle: {
                type: String,
                default: 'icon-light'
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        components: {
            PointsIcon,
            GearIcon
        },
        data() {
            return {
                open: false,
                scrollHeight: 0,
                stylePopup: '',
                isShowPopup: false
            }
        },
        computed: {
            widthContextMenu() {
                return this.width ? `width: ${this.width}px` : null;
            },
            openContextMenu() {
                return this.$store.getters['views/getOpenContextMenu'];
            }
        },
        methods: {
            showDateMenu() {
                this.stylePopup = '';
                const popup = document.getElementById(this.unicId);
                const container = document.getElementById(`context-menu-container${this.unicId}`);
                const freeHeightBottom = this.scrollHeight - getCoords(container).top - container.clientHeight;
                if (
                    freeHeightBottom < popup.clientHeight
                    || container.getBoundingClientRect().top > window.outerHeight - container.getBoundingClientRect().top
                ) {
                    const marginTop = Math.round(popup.clientHeight);
                    this.stylePopup = `top: -${marginTop}px;`;
                }
            },
            toggleOpenContextMenu(id) {
                this.open = !this.open;
                let openMenu = this.open ? id : null;
                this.$store.commit('views/TOGGLE_CONTEXT_MENU', openMenu);
                setTimeout(() => {
                    if (this.open) {
                        this.showDateMenu();
                        this.isShowPopup = true;
                    } else {
                        this.isShowPopup = false;
                    }
                }, 0);
            },
            outside(event) {
                if (!this.$el.contains(event.target)
                    || (document.getElementById(this.unicId)
                        && document.getElementById(this.unicId).contains(event.target)
                    )) {
                    this.open = false;
                }
            }
        },
        mounted() {
            document.body.addEventListener('click', this.outside);
            this.scrollHeight = document.body.offsetHeight;
        },
        destroyed() {
            document.body.removeEventListener('click', this.outside);
        }
    }
</script>