var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overview-statistic w-100"},[_c('div',[_c('div',{class:[
            'row-between',
            { 'statistic-mock-loading' : _vm.loading }
        ]},_vm._l((_vm.statistic),function(item,key,index){return _c('router-link',{key:key,class:[
                    'overview-statistic-column flex-grow-1 ph-20 pv-15',
                    index !== 0 && _vm.dScreen && 'ml-20',
                    !_vm.dScreen && 'm-4',
                    'column-start',
                    { 'none-events' : item.dev },
                    { 'expired-notification': _vm.showExpired && item.name === 'Tasks / Expired'}
                ],attrs:{"to":{ name: item.routerName }}},[_c('div',{staticClass:"row-between align-bottom"},[_c('div',{staticClass:"column-start"},[_c('div',{class:item.dev && 'w-100 in-dev'}),_c('span',{staticClass:"f-24-black f-sbold t-line-height-1"},[_vm._v("\n                             "+_vm._s(_vm.showCounters(item))+"\n                        ")]),_c('span',{staticClass:"f-12-grey mt-2"},[_vm._v("\n                            "+_vm._s(item.name)+"\n                        ")])]),_c('div',{class:[
                        'overview-statistic-column-icon s-align-5',
                        item.class
                    ]},[_c(item.icon,{tag:"component",class:item.iconClass,attrs:{"size":"8"}})],1)])])}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }