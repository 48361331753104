<template>
    <svg 
        viewBox="0 0 20 20"
        :width="size" 
        :height="size"
        class="signature-doc-icon"
    >
        <path d="M14.2 16.2c-.4 0-.8.4-.8.8v.2c0 .6-.5 1.1-1.1 1.1H2.7c-.6 0-1.1-.5-1.1-1.1V2.8c0-.6.5-1.1 1.1-1.1h5.7c.3 0 .6.1.8.3L13 6.2c.2.2.3.5.3.8v1.5c0 .4.4.8.8.8s.8-.4.8-.8V7c0-.7-.3-1.4-.7-1.9L10.4.9C9.9.3 9.1 0 8.4 0H2.7C1.2 0 0 1.3 0 2.8v14.4C0 18.8 1.2 20 2.7 20h9.6c1.5 0 2.7-1.3 2.7-2.8V17c0-.4-.4-.8-.8-.8z"/>
        <path d="M19.9 13.4c-.1-.2-.4-.4-.6-.5-.3-.1-.6 0-.8.2-.4.3-.7.2-.9.2v-.2h-.3c-.7-.5-1.2-.7-1.6-.8-.6-.1-1.1.2-1.4.4-.1.1-.2.1-.3.2.1-.3.3-.6.4-.9.2-.3.2-.6.1-.9-.1-.3-.4-.5-.7-.6-.4-.1-.9 0-1.1.4-.1.1-.5.4-.9.7.1-.1.1-.3.2-.4.3-.4.2-1-.2-1.4-.4-.4-1.1-.3-1.4.1-2.1 2.4-3.4 2.8-4 2.8-.3 0-.5-.1-.6-.2-.4-.2-.8-.2-1.2 0-.5.2-.6.6-.6.9 0 .3.2.6.5.8.5.3 1 .5 1.6.5.8 0 1.7-.2 2.6-.8l.3.6.1.1-.1.5.3-.3c.7.4 1.6-.3 1.9-.4.1 0 .2-.1.4-.2 0 .2.1.4.2.5.2.3.7.8 1.8.5.9-.2 1.4-.6 1.6-.8h.1c.2.1.5.3.8.5.2.1.8.5 1.7.5.6 0 1.2-.2 1.7-.6.5-.3.6-1 .4-1.4zm-15.2-.5z"/>
    </svg>
</template>

<script>
    export default {
        name: 'SignatureDocIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>