<template>
    <div class="settings-item column-start w-100">
        <div class="item-title row-between row-baseline mb-6">
            <div class="s-align-4">
                <menu-icon 
                    class="i-lightblue mr-4" 
                    size="20"
                />
                <p class="f-18-darkgrey f-sbold">Job numbering</p>
            </div>
            <button 
                class="icon-light"
                v-if="mScreen || tScreen"
                @click="startEditJobNumbering"
                disabled="true"
            >
                <edit-icon size="15"/>
            </button>
        </div>
        <div class="w-box">
            <div class="row-start pb-4">
                <div class="lg-8 s-align-4">
                    <p class="f-14-black f-sbold">
                        Choose how to assign job numbers
                    </p>
                </div>
                <div class="lg-4 s-align-6">
                    <button
                        v-can="[$permissions.EDIT_COMPANY_SETTINGS]"
                        class="edit-button"
                        @click="startEditJobNumbering"
                        v-if="nScreen || dScreen"
                        disabled="true"
                    >
                        Edit
                    </button>
                </div>
            </div>
            <loader
                size="small"
                v-if="loading"
            />
            <div v-else>
                <div class="row-start mb-7">
                    <div class="lg-6">
                        <p class="f-14-grey">
                            Full Replacement #: 
                            <span 
                                v-if="jobNumbers.full_replacement"
                                class="f-14-black"
                            >
                                {{ jobNumbers.full_replacement }}
                            </span>
                        </p>
                    </div>
                    <div class="lg-6">
                        <p class="f-14-grey">
                            Prefix:
                            <span 
                                v-if="jobNumbers.residential_prefix"
                                class="f-14-black"
                            >
                                {{ jobNumbers.residential_prefix }}
                            </span>
                            <span class="bg-blue br-5 ml-3 ph-1 pv-1 f-9-white f-bold uppercase">
                                Residential
                            </span>
                        </p>
                    </div>
                </div>
                <div class="row-start mb-7">
                    <div class="lg-6">
                        <p class="f-14-grey">
                            Repairs #: 
                            <span 
                                v-if="jobNumbers.repairs"
                                class="f-14-black"
                            >
                                {{ jobNumbers.repairs }}
                            </span>
                        </p>
                    </div>
                    <div class="lg-6">
                        <p class="f-14-grey">
                            Prefix:
                            <span 
                                v-if="jobNumbers.commercial_prefix"
                                class="f-14-black"
                            >
                                {{ jobNumbers.commercial_prefix }}
                            </span>
                            <span class="bg-asphalt br-5 ml-3 ph-1 pv-1 f-9-white f-bold uppercase">
                                Commercial
                            </span>
                        </p>
                    </div>
                </div>
                <div class="row-start mb-7">
                    <div class="lg-12">
                        <p class="f-14-grey">
                            Inspection #:
                            <span 
                                v-if="jobNumbers.inspection"
                                class="f-14-black"
                            >
                                {{ jobNumbers.inspection }}
                            </span>
                        </p>
                    </div>
                </div>
                <div class="row-start">
                    <div class="lg-12">
                        <p class="f-14-grey">
                            Service #:
                            <span 
                                v-if="jobNumbers.service"
                                class="f-14-black"
                            >
                                {{ jobNumbers.service }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <configure-id-numbering-modal
            v-if="idNumberingModalIsOpen"
            :jobNumbers="jobNumbers"
            @close="updateJobNumbers"
        />
    </div>
</template>

<script>
    import { MenuIcon, EditIcon } from '@/icons';
    import { RadioButton, Loader } from '@/ui';
    import api from '@/headers.js';

    export default {
        name: 'NextJobsSetup',
        components: {
            MenuIcon,
            RadioButton,
            Loader,
            EditIcon,
            ConfigureJobNumberingModal: () => import('@/modals/ConfigureJobNumberingModal.vue'),
            ConfigureIdNumberingModal: () => import('@/modals/ConfigureIdNumberingModal.vue')
        },
        props: {
            startingNum: {
                type: [String, Number],
                required: true
            },
            alternately: {
                type: [String, Number],
                required: true
            }
        },
        data() {
            return {
                selected: 1,
                jobNumberingModalIsOpen: false,
                idNumberingModalIsOpen: false,
                loading: false,
                ALTERNATELY_UPDATE: 'update alternately',
                jobNumbers: {
                    full_replacement: null,
                    repairs: null,
                    service: null,
                    residential_prefix: "",
                    commercial_prefix: ""
                }

            }
        },
        methods: {
            startEditJobNumbering() {
                this.selected
                    ? this.jobNumberingModalIsOpen = true
                    : this.idNumberingModalIsOpen = true;
            },
            getJobNumbering() {
                this.loading = true;
                api.get('/job-number')
                    .then(response => response.data.data)
                    .then(data => {
                        this.jobNumbers = data;
                    })
                    .catch(error => {                       
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            updateJobNumbers(data) {
                if (data) {
                    this.jobNumbers = data;
                }
                this.idNumberingModalIsOpen = false;
            }
        },
        created() {
            this.getJobNumbering();
            this.selected = this.alternately;
        }
    }
</script>
