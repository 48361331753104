<template>
    <svg 
        class="pie-chart"
        width="21px"
        height="21px"
        style="border-radius: 50%"
    >
        <circle
            v-for="(item,index) in dataObjects"
            :key="index"
            :style="{strokeDasharray: `${item.relativeSize} ${circleLength}`, strokeDashoffset: item.offset, stroke: color}"
            r="21px"
            cx="50%"
            cy="50%"
        />
    </svg>
</template>

<script>
    export default {
        name: 'PieChartIcon',
        props: {
            color: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                data: [5,5,5,5,5,5,5,5],
                circleLength: 371.9451599121094,
            }
        },
        computed: {
            dataTotal() {
                return this.data.reduce((previous, current) => previous + current);
            },
            dataObjects() {
                let startingPoint = 0;
                return this.data.map(item => {
                    let relativeSize = item + 11.5;
                    let dataObject =  { relativeSize: relativeSize, offset: -startingPoint };
                    startingPoint += relativeSize;
                    return dataObject;
                })
            }
        }
    }
</script>

