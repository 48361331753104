<template>
    <div class='w-100'>
        <loader 
            v-if="!loaded"
            class="s-align-5"
        />
        <template v-if="isPdfFile || isTxtFile">
            <iframe
                v-show="loaded"
                class="w-100"
                :src="`https://docs.google.com/viewerng/viewer?url=${url}&embedded=true`" 
                frameborder="0"
                @load="clearInterval"
                ref="iframe"
            />
        </template>
        <iframe
            v-else
            v-show="loaded"
            class="w-100"
            :src="`https://view.officeapps.live.com/op/embed.aspx?src=${url}`"
            @load="loaded = true"
            frameborder="0"
        />
    </div>
</template>

<script>
    import { Loader } from '@/ui';
    import { FILE_EXTENSIONS } from '@/constants.js';

    // Google docs viewer sometimes return 204 response and content not whowing.
    // Component watch iframe, and if content not loaded reloading iframe

    export default {
        name: 'FilePreviewContainer',
        components: {
            Loader
        },
        props: {
            url: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loaded: false,
                timerId: null
            }
        },
        computed: {
            isPdfFile() {
                return this.type === 'pdf';
            },
            isTxtFile() {
                return this.type === 'txt';
            }
        },
        methods: {
            clearInterval() {
                clearInterval(this.timerId);
                this.loaded = true;
            },
            reloadIframe() {
                this.$refs.iframe.src = this.$refs.iframe.src;
            }
        },
        created() {
            if (this.isPdfFile || this.isTxtFile) {
                this.timerId = setInterval(this.reloadIframe, 5000);
            }
        },
        destroyed() {
            clearInterval(this.timerId);
        }
    }
</script>