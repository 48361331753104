<template>
    <full-modal
        @close="$emit('close')"
        class="workflow-task-modal"
    >
        <div slot="title">Assign Task</div>
        <div slot="body">
            <div class="w-100 pb-4">
                <form-input 
                    :class="[{ 'form-input-danger' : errors.has('subject') }]"
                    label="Subject"
                >
                    <input 
                        class="form-input-field"
                        type="text"                         
                        name="subject"
                        placeholder="Subject"
                        v-input-style="task.subject"
                        v-model="task.subject"
                        v-validate="'required'"
                    />
                </form-input>
            </div>
            <div class="w-100 pb-30">
                <form-input-textarea v-input-counter="2500">
                    <textarea
                        rows="1"
                        placeholder="Description"
                        :maxlength="2500"
                        v-model="task.description"
                        v-resize-textarea
                        name="description"
                        v-validate="'required'"
                        :class="[
                            'form-input-field', 
                            { 'form-input-textarea-danger' : errors.has('description') }
                        ]"
                    />
                </form-input-textarea>
            </div>
            <div class="w-100 pb-30 bb-grey">
                <p class="f-13-black f-sbold pb-6">Due Day</p>
                <div 
                    class="row-start row-baseline pb-1"
                    v-for="(option, dueDayIndex) in dueDayOptions"
                    :key="`dueDay-${dueDayIndex}`"
                >
                    <radio-button
                        :label="option.name"
                        color="blue"
                    >
                        <input 
                            type="radio" 
                            :name="option.name" 
                            :value="option.value"
                            v-model="selectedDueDayOption"
                            @change="makeDueDayInstantly(option.value)"
                        />
                    </radio-button>
                    <div 
                        class="lg-4 sm-6 pl-8"
                        v-if="option.value == 1"
                    >
                        <div 
                            class="asymmetric-input relative" 
                            data-placeholder="day(s)"
                        >
                            <input 
                                class="input input-ass"
                                type="number"                         
                                name="due-day"
                                v-input-style="task.dueDay"
                                v-model="dueDay"
                                :disabled="selectedDueDayOption == 0"
                                v-limiter
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-between row-baseline pv-20 bb-grey">
                <p class="f-13-black f-sbold">Set the priority for this task</p>
                <div :class="['s-align-6 mt-sm-1', { 'w-100': mScreen }]">
                    <label 
                        class="task-priority-button s-align-5 ml-2 pointer"
                        v-for="(priority, priorityIndex) in priorityOptions"
                        :key="`priority-${priorityIndex}`"
                    >
                        <input 
                            type="radio" 
                            :name="`priority-option-${priorityIndex}`" 
                            :value="priority"
                            v-model="task.priority"
                        />
                        <span :class="['s-align-5 capitalize', priority]">
                            <pointer-icon 
                                class="mr-1"
                                :transform="task.priority == priority ? 270 : 90"
                                size="10"
                            />
                            {{ priority }}
                        </span>
                    </label>
                </div>
            </div>
            <div class="w-100 pv-20 bb-grey">
                <p class="f-13-black f-sbold pr-8 mb-sm-1 mb-4">Assign to:</p>
                <div
                    :class="[
                        'row-between row-baseline lg-12',
                        assignIndex - 1 ? 'mb-5' : 'mb-1',
                        { 'mb-5': mScreen }
                    ]"
                    v-for="(option, assignIndex) in typeAssign"
                    :key="`reminder-${assignIndex}`"
                >
                    <radio-button
                        :label="option.name"
                        color="blue"
                    >
                        <input 
                            type="radio" 
                            :name="option.name" 
                            :value="option.value"
                            v-model="task.typeAssign"
                            @change="showAssignTo(option.value)"
                        />
                    </radio-button>
                    <div
                        class="lg-6 sm-12 mt-sm-1"
                        v-if="option.name === 'Specific user'"
                    >
                        <multiselect
                            :options="sellers"  
                            label="fullname"                                      
                            name="sellers"
                            :showLabels="false"
                            placeholder="Salesman"
                            v-model="seller"
                            @select="selectSeller"
                            @remove="removeSeller"
                            :disabled="task.typeAssign !== 'assign to'"
                            v-validate="task.typeAssign === 'assign to' ? 'required' : ''"
                            :class="[
                                'multiselect-small', 
                                { 'multiselect-danger' : errors.has('sellers') }
                            ]"
                        />
                    </div>
                </div>
                <p class="s-align-1 f-12-grey w-100">
                    <span class="assign-info-icon s-align-5 f-10-white mr-2">
                        <info-icon 
                            class="i-white"
                            size="7"
                        />
                    </span>
                    <span class="w-90">
                        If you do not assign the task to a user, 
                        it will be automatically assigned to the Salesman.
                    </span>
                </p>
            </div>
            <div class="w-100 pv-20">
                <p class="f-13-black f-sbold mb-5">Choose reminder type</p>
                <div class="row-start mb-4">
                    <checkbox-button
                        color="blue"
                        class="mr-4"
                        label=""
                    >
                        <input 
                            type="checkbox" 
                            name="notification_alert" 
                            v-model="task.isNotifyRemind"
                        />
                    </checkbox-button>
                    <span class="f-13-black mr-1">Alert</span>
                    <span class="f-13-grey">(calendar notifications)</span>
                </div>
                <div class="row-start">
                    <checkbox-button
                        color="blue"
                        class="mr-4"
                    >
                        <input 
                            type="checkbox" 
                            name="email"
                            v-model="task.isMailRemind"
                        />
                    </checkbox-button>
                    <span class="f-13-black mr-1">Email</span>
                    <span class="f-13-grey">
                        {{ 
                            seller == null
                            ? '(to mailexample@mail.com)'
                            : `(to ${seller.email})`
                        }}
                    </span>
                </div>
            </div>
            <div
                v-if="task.isNotifyRemind || task.isMailRemind"
                class="w-100 pb-30 bb-grey"
            >
                <p class="f-13-black f-sbold pb-6">Choose when to send a reminder</p>
                <div
                    :class="[
                        'row-start row-baseline pb-1',
                        { 'in-dev': option.inDev }
                    ]"
                    v-for="(option, reminderIndex) in reminderOptions"
                    :key="`reminder-${reminderIndex}`"
                >
                    <radio-button
                        :label="option.name"
                        color="blue"
                    >
                        <input 
                            type="radio" 
                            :name="option.name" 
                            :value="option.value"
                            v-model="task.once"
                            @change="makeDueDayInstantly(option.value)"
                            :disabled="option.inDev"
                        />
                    </radio-button>
                    <div class="lg-3 sm-6 pl-8">
                        <div 
                            class="asymmetric-input asymmetric-input-hour relative" 
                            data-placeholder="hour(s)"
                        >
                            <input
                                v-if="option.name === 'Once'" 
                                class="input input-ass"
                                type="number"                         
                                name="due-day"
                                v-input-style="task.addHours"
                                v-model.number="onceRemindHours"
                                :disabled="!task.once"
                                v-limiter
                            />
                            <input
                                v-else 
                                class="input input-ass"
                                type="number"                         
                                name="due-day"
                                v-input-style="task.addHours"
                                v-model.number="everyRemindHours"
                                :disabled="task.once"
                                v-limiter
                            />
                        </div>
                    </div>
                    <div class="ml-2 f-13-black">
                        <p v-if="option.name === 'Once'">before a due date.</p>
                        <p v-else>until task is marked as completed.</p>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button mb-2"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button mb-2"
                @click="saveTask"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, FormInput, RadioButton, CheckboxButton, FormInputTextarea } from '@/ui';
    import { PointerIcon, InfoIcon } from '@/icons';
    import Multiselect from 'vue-multiselect';

    const INSTANTLY_DUE_DAY_VALUE = 0;

    export default {
        name: 'WorkflowTaskModal',
        components: {
            FullModal,
            FormInput,
            RadioButton,
            PointerIcon,
            InfoIcon,
            CheckboxButton,
            FormInputTextarea,
            Multiselect
        },
        props: {
            sellers: {
                type: Array,
                required: true
            },
            existingTask: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                task: {
                    subject: '',
                    dueDate: 'trigger date',
                    dueDay: 0,
                    priority: 'normal',
                    typeAssign: '', // salesman | created by | assign to
                    assignTo: '',
                    remind: 0,
                    description: '',
                    isNotifyRemind: false,
                    isMailRemind: false,
                    addHours: 0,
                    once: true
                },
                dueDayOptions: [
                    { name: 'Instantly', value: 0 },
                    { name: 'When date plus', value: 1 }
                ],
                selectedDueDayOption: 0,
                priorityOptions: ['normal', 'high'],
                editDescriptionMode: false,
                seller: null,
                reminderOptions: [
                    { name: 'Once', value: true },
                    { name: 'Every', value: false }
                ],
                onceRemindHours: 0,
                everyRemindHours: 0,
                typeAssign: [
                    { name: 'Salesman', value: 'salesman' },
                    { name: 'Created by', value: 'created by' },
                    { name: 'Specific user', value: 'assign to' }
                ],
                dueDay: null
            }
        },
        computed: {
            isTaskDescription() {
                return !!this.task.description;
            },
            enableRemind() {
                if (this.task.isNotifyRemind || this.task.isMailRemind) {
                    this.task.remind = true;
                }
            }
        },
        methods: {
            makeDueDayInstantly(value) {
                if (value == 0)
                    this.task.dueDay = 0;
            },
            selectSeller(data) {
                this.task.assignTo = data.id;
            },
            removeSeller() {
                this.task.assignTo = '';
            },
            saveTask() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('Fill in required fields!');
                        return;
                    };
                    
                    if (this.onceRemindHours) {
                        this.task.addHours = this.onceRemindHours;
                    }

                    if (this.everyRemindHours) {
                        this.task.addHours = this.everyRemindHours;
                    }

                    if (!this.task.isNotifyRemind && !this.task.isMailRemind) {
                        this.task.dueDay = null;
                    }

                    this.task.dueDay = this.selectedDueDayOption === INSTANTLY_DUE_DAY_VALUE
                        ? INSTANTLY_DUE_DAY_VALUE
                        : this.dueDay;

                    this.$emit('setTask', this.task);
                });
            },
            showAssignTo(val) {
                if (val !== this.typeAssign[2].value) {
                    this.task.assignTo = null;
                    this.seller = null;
                }
            }
        },
        created() {
            if (Object.keys(this.existingTask).length) {
                this.task = this.existingTask;
                this.dueDay = this.existingTask.dueDay;
                const savedSeller = this.sellers.find(s => s.id == this.existingTask.assignTo);
                this.seller = savedSeller ? savedSeller : null;

                if (this.existingTask.once) {
                    this.onceRemindHours = this.existingTask.addHours;
                } else {
                    this.everyRemindHours = this.existingTask.addHours;
                }

                if (+this.existingTask.dueDay > 0) 
                    this.selectedDueDayOption = 1;
            }
        }
    }
</script>