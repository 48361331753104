<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 10 14"
    >
        <path d="M2.27262 8.08881C3.48273 8.53148 4.3886 9.28888 5.00366 10.385C5.2861 9.86186 5.67021 9.39161 6.13667 8.99792C6.60786 8.6027 7.15492 8.29323 7.74934 8.08564C7.70494 8.05439 7.67615 8.03311 7.64541 8.01228C6.29404 7.06648 5.61774 5.80528 5.648 4.23794C5.68606 2.24197 7.2309 0.484209 9.3198 0.0188986C9.70601 -0.0671443 10 0.147736 10 0.518626C10 3.47306 10 6.4275 10 9.38194C9.99707 11.6607 8.16337 13.6186 5.73046 13.9501C3.03406 14.319 0.509908 12.6022 0.0746594 10.0999C0.0308626 9.84678 0.00867332 9.5908 0.00829846 9.33439C0.00366296 6.40645 0.00976239 3.47827 3.44111e-06 0.550326C-0.00121643 0.171059 0.322049 -0.0716727 0.724849 0.024333C2.63199 0.479228 4.09949 1.99268 4.31663 3.80116C4.5262 5.54692 3.85308 6.95486 2.34825 8.0202C2.32898 8.03379 2.30897 8.04692 2.29018 8.06118C2.28319 8.06973 2.27729 8.079 2.27262 8.08881ZM1.04567 1.14651V7.60199C2.09135 7.23178 3.29511 6.10235 3.33073 4.45056C3.36684 2.81416 2.23749 1.58941 1.04567 1.14651ZM8.96165 1.14402C7.72812 1.6116 6.55143 2.89205 6.68342 4.66883C6.77589 5.91419 7.89621 7.37397 8.96165 7.59814V1.14402ZM5.52991 12.9987C7.571 12.8737 9.31541 10.7328 8.9553 8.79006C7.9938 8.92048 7.18503 9.31515 6.53606 9.9847C5.70387 10.8426 5.43354 11.8656 5.52991 12.9987ZM4.47741 13.0075C4.55377 11.9114 4.3725 10.8191 3.30634 9.82597C2.68298 9.24541 1.92569 8.90576 1.04933 8.79096C0.954429 9.89707 1.20401 10.8934 1.98497 11.7395C2.64077 12.4496 3.47175 12.8683 4.47741 13.0075Z"/>
    </svg>
</template>

<script>
    export default {
        name: 'StormIcon',
        props: {
            width: {
                type: [Number, String],
                default: 10
            },
            height: {
                type: [Number, String],
                default: 14
            }
        }
    }
</script>
