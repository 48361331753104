<template>
    <perfect-scrollbar class="ps-grey lead-details">
        <div 
            class="w-100" 
            v-if="loading"
        >
            <loader/>
        </div>
        <div 
            class="w-100" 
            v-if="!loading && Object.keys(lead).length"
        >
            <span class="f-11-grey mb-2">
                <clock-icon
                    class="i-grey mr-1"
                    size="10"
                />
                <span>
                    {{ lead.created_at | timeAgo }}
                </span>
            </span>
            <div class="row-between">
                <h2 class="f-20-darkgrey f-sbold">{{ lead.fullname }}</h2>
                <div class="s-align-6">
                    <priority
                        v-if="lead.priority" 
                        :priority="lead.priority.name"
                        :priorityColor="lead.priority.color_code"
                    />
                </div>
            </div>
            <div class="w-100 pt-20">
                <router-link
                    :to="{ name: 'CreateNewResidential', query: { from_existing: lead.id } }"
                    class="primary-button lead-details-button"
                    v-can="[$permissions.CREATE_LEAD]"
                >
                    Create a new lead for {{ lead.first_name }}
                </router-link>
            </div>
            <div class="column-start pv-25 w-100 bb-grey">
                <h2 class="f-16-darkgrey f-sbold mb-8">Demographics</h2>
                <div class="mb-1">
                    <content-item                                
                        title="Name"
                        :content="lead.fullname"
                        :titleBold="false"
                        titleStyle="f-13-grey"
                    />
                </div>
                <div 
                    class="mb-1"
                    v-if="lead.lead_source"
                >
                    <content-item                                
                        title="Source"
                        :content="lead.lead_source.name"
                        :titleBold="false"
                        titleStyle="f-13-grey"
                    />
                </div>
                <div class="row-between mb-1">
                    <div :class="['md-12 s-align-1 mb-md-1', addressIsCorrect ? 'lg-8' : 'lg-12']">
                        <span class="f-13-grey pb-1">Address:</span>
                        <a
                            :href="getGoogleMapLink(lead.lat, lead.lng)"
                            :class="[
                                'f-13-blue pl-1',
                                {'none-events': !getGoogleMapLink(lead.lat, lead.lng)}
                            ]"
                            target="_blank"
                        >
                            {{ lead.full_address }}
                            <link-icon 
                                v-if="getGoogleMapLink(lead.lat, lead.lng)"
                                class="i-blue"
                            />
                        </a>
                    </div>
                    <div 
                        class="s-align-3 lg-4 md-12"
                        v-if="addressIsCorrect"
                    >
                        <button 
                            class="show-on-map"
                            @click="$emit('map', { lat: lat, lng: lng })"
                        >
                            <map-icon/>
                            Show on Map
                        </button>
                    </div>
                </div>
                <div class="mb-1">
                    <content-item                              
                        title="Phone"
                        :content="lead.tel_1"
                        :titleBold="false"
                        titleStyle="f-13-grey row-baseline"
                        :isPhone="true"
                        :isPhoneMakeCall="true"
                        :entityId="leadId"
                    />
                </div>
                <content-item                              
                    title="Email"
                    :content="lead.email_1"
                    :titleBold="false"
                    titleStyle="f-13-grey"
                />
            </div>
            <div class="column-start pv-25 w-100 bb-grey">
                <h2 class="f-16-darkgrey f-sbold mb-8">Work</h2>
                <div 
                    class="mb-1" 
                    v-if="lead.salesman"
                >
                    <content-item                              
                        title="Salesman"
                        :content="lead.salesman.fullname"
                        :titleBold="false"
                        titleStyle="f-13-grey"
                    />
                </div>
                <div 
                    class="mb-1"
                    v-if="lead.lead_type"
                >
                    <content-item                              
                        title="Job type"
                        :content="lead.lead_type.name"
                        :titleBold="false"
                        titleStyle="f-13-grey"
                    />
                </div>
                <div 
                    class="mb-1"
                    v-if="lead.home_type"
                >
                    <content-item                              
                        title="Home type"
                        :content="lead.home_type.name"
                        :titleBold="false"
                        titleStyle="f-13-grey"
                    />
                </div>
                <content-item                              
                    title="Trade"
                    :content="lead.trades"
                    :titleBold="false"
                    titleStyle="f-13-grey"
                />
            </div>
            <div class="column-start pv-25 w-100">
                <h2 class="f-16-darkgrey f-sbold pb-8">Comments</h2>
                <div 
                    v-if="lead.notes"
                    class="lead-comment w-100 pt-5"
                >
                    <div v-if="lead.notes.length">
                        <div
                            v-for="note in lead.notes"
                            :key="note.id"
                            class="pb-8 pl-4"
                        >
                            <div class="s-align-4 w-100">
                                <avatar
                                    :url="note.user.thumbnail"
                                    :name="`${note.user.first_name} ${note.user.last_name}`"
                                    :border="false"
                                    size="28"
                                />
                                <div class="column-evenly-start pl-4">
                                    <span class="f-13-darkgrey f-sbold">{{ note.user.fullname }}</span>
                                    <span class="f-11-grey">{{ note.created_at | timeAgo }}</span>
                                </div>
                            </div>
                            <div class="comment-body">
                                <span 
                                    class="f-12-darkgrey" 
                                    v-html="note.body"
                                >
                                    {{ note.body }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div 
                        class="column-start" 
                        v-else
                    >
                        <span class="f-13-darkgrey">Nothing to display in Comments</span>
                        <span class="f-13-grey f-italic">
                            No comments have been written in this lead yet.
                        </span>
                    </div>
                </div>
            </div>
            <div 
                class="column-start pv-30 w-100 bt-grey"
                v-if="tags.length"
            >
                <h2 class="f-16-darkgrey f-sbold pb-8">Labels</h2>
                <div class="row-start">
                    <tag
                        v-for="(tag, index) in tags"
                        :key="`tag-${index}`"
                        :tag="tag"
                        :disabled="true"
                    />
                </div>
            </div>
        </div>
    </perfect-scrollbar>
</template>

<script>
    import { MapIcon, ClockIcon, LinkIcon } from '@/icons';
    import { Loader } from '@/ui';
    import Priority from '@/components/Priority.vue';
    import Avatar from '@/components/User/Avatar.vue';
    import Tag from '@/components/Tag.vue';
    import ContentItem from '@/components/ContentItem.vue';
    import api from '@/headers.js';
    import { getGoogleMapLink } from '@/helpers';

    export default {
        name: 'ResidentialLeadListDetails',
        components: {
            ClockIcon,
            MapIcon,
            LinkIcon,
            Avatar,
            Priority,
            Tag,
            Loader,
            ContentItem
        },
        props: {
            leadId: {
                type: [Number],
                default: 0
            },
            tags: {
                type: Array,
                default: function() {
                    return [];
                }
            }
        },
        data() {
            return {
                lead: [],
                loading: false,
            }
        },
        computed: {
            lat() {
                return this.lead.lat != '' ? +this.lead.lat : null;
            },
            lng() {
                return this.lead.lng != '' ? +this.lead.lng : null;
            },
            addressIsCorrect() {
                return this.lead.lat != null && this.lead.lng != null;
            }
        },
        watch: {
            leadId() {
                this.getLead();
            }
        },
        methods: {
            // Methods imported from helpers.js
            getGoogleMapLink,
            getLead() {
                this.loading = true;

                api.get(`/leads/${this.leadId}`)
                    .then(response => response.data)
                    .then(data => {
                        this.lead = data.data;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        mounted() {
            this.getLead();
        }
    }
</script>
