<template>
    <div class="pv-30 correspondence-container w-100 hidden">
        <perfect-scrollbar class="correspondence ps ps-grey w-100 pb-30">
            <div class="ph-30 w-100">
                <template v-if="selectConversationsId && !loading">
                    <div
                        v-for="(item, index) in conversations"
                        :key="index"
                        :class="[
                            'pt-30',
                            conversations.length - 1 !== index && 'bb-grey'
                        ]"
                    >
                        <div class="mb-20 row-between s-align-4 flex-no-wrap">
                            <div
                                v-if="item.subject"
                                class="f-20-black flex-grow-1 w-100"
                            >
                                {{ item.subject }}
                            </div>
                        </div>
                        <div class="mb-30 column-start">
                            <div class="correspondence-email w-100">
                                <div class="row-between flex-no-wrap s-align-1">
                                    <div class="column-start flex-grow-1 reply-to-letter max-w-100 break-all">
                                        <div
                                            v-if="item.from"
                                            class="row-start s-align-1 flex-no-wrap"
                                        >
                                            <div
                                                v-if="item.from.emailAddress"
                                                class="column-start w-100"
                                            >
                                                <div
                                                    v-if="item.from.emailAddress"
                                                    class="s-align-4"
                                                >
                                                    <avatar
                                                        :name="getNameForAvatar(item)"
                                                        :border="false"
                                                        size="35"
                                                    />
                                                    <div class="column-start pl-8">
                                                        <p class="f-16-black f-sbold">
                                                            {{ item.from.emailAddress.name }}
                                                        </p>
                                                        <p class="f-13-grey">
                                                            {{ item.from.emailAddress.address }}
                                                        </p>
                                                        <p
                                                            v-if="!item.is_manual"
                                                            class="f-13-grey"
                                                        >
                                                            Sent to:
                                                            <span>
                                                                {{ toRecipients(item.toRecipients) }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row-end flex-no-wrap">
                                                <div
                                                    v-if="item.attachments && item.attachments.length"
                                                    class="row-end mr-4"
                                                >
                                                    <attach-icon
                                                        size="12"
                                                        class="i-blue"
                                                    />
                                                    <span class="f-12-blue ml-2">
                                                        {{ item.attachments.length }}
                                                    </span>
                                                </div>
                                                <div
                                                    :class="[
                                                        'f-12-grey no-wrap pl-4',
                                                        item.attachments
                                                            && item.attachments.length
                                                            && 'correspondence-email-date'
                                                    ]"
                                                >
                                                    {{ item.sentDateTime | timeAgo }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            :class="[
                                                'column-start hidden w-100 message',
                                                { 'hidden-content' : getIsHideContent(item, index) }
                                            ]"
                                        >
                                            <div
                                                :class="[
                                                    'f-14-grey w-100 message,',
                                                    item.from && item.from.emailAddress &&  'mt-20 pl-50'
                                                ]"
                                                v-html="item.body.content"
                                            />
                                            <div
                                                v-if="!item.is_manual && item.attachments && item.attachments.length"
                                                class="row-start mb-5 reply-to-letter mt-20 pl-50"
                                            >
                                                <div
                                                    v-for="attachment in item.attachments"
                                                    :key="attachment.id"
                                                    class="s-align-5 mb-4 mr-8"
                                                >
                                                    <div class="attachment-preview s-align-5 ellipsis ph-1">
                                                        <span class="f-13-grey f-sbold ellipsis">
                                                            .{{ getFileType(attachment.name) }}
                                                        </span>
                                                    </div>
                                                    <div class="attachment-name ellipsis column-start ml-4">
                                                        <button
                                                            class="transparent-button f-14-black f-sbold ellipsis"
                                                            @click="downloadAttachment(item, attachment)"
                                                        >
                                                            {{attachment.name}}
                                                        </button>
                                                        <div class="row-start">
                                                            <span class="f-12-grey">{{ attachment.size / 1000 }} kb</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            v-if="isNeedToggleButton(index) && !item.is_manual"
                                            @click="showHideEmail(item.id, index)"
                                            class="transparent-button f-13-blue ml-50"
                                        >
                                            {{ isVisible(item.id) ? 'Hide email' : 'Show full email' }}
                                            <arrow-icon
                                                :transform="!isVisible(item.id) ? 90 : 270"
                                                size="8"
                                                class="i-blue ml-2"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <loader v-else />
            </div>
        </perfect-scrollbar>
    </div>
</template>
<script>
    import { WriteIcon, TrashIcon, AttachIcon, ArrowIcon } from '@/icons';
    import Avatar from '@/components/User/Avatar.vue';
    import { Loader } from '@/ui';
    import IMAPapi from '@/IMAPheaders';
    export default {
        name: 'CorrespondenceBody',
        components: {
            ArrowIcon,
            Loader,
            AttachIcon,
            Avatar,
            TrashIcon,
            WriteIcon
        },
        props: {
            selectConversationsId: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                conversations: [],
                loading: false,
                accountId: null,
                visibleMessages: []
            }
        },
        computed: {
            imapConfig() {
                return this.$store.getters['imap/getImapConfig'];
            },
            userId() {
                return this.$store.getters['user/getUserId'];
            },
            isImapConfigLoaded() {
                return this.$store.getters['imap/isImapConfigLoaded'];
            }
        },
        watch: {
            selectConversationsId: {
                handler(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        this.getConversation();
                    }
                },
                deep: true
            }
        },
        methods: {
            showHideEmail(id) {
                if (this.isVisible(id)) {
                    let index = this.visibleMessages.findIndex(el => el == id);
                    this.visibleMessages.splice(index, 1);
                    return;
                }

                this.visibleMessages.push(id);
            },
            getIsHideContent(item, index) {
                return !this.isVisible(item.id)
                    && this.isNeedToggleButton(index);
            },
            isVisible(id) {
                return this.visibleMessages.some(item => item == id);
            },
            isNeedToggleButton(index) {
                return this.messagesDomList &&
                    this.messagesDomList[index] &&
                    this.messagesDomList[index].clientHeight > 20;
            },
            getFileType(name) {
                let fileType = name.split('.');
                return fileType[fileType.length -1];
            },
            getImapAccounts() {
                if (!this.isImapConfigLoaded) {
                    this.$store.dispatch('imap/GET_IMAP_ACCOUNTS', this.userId);
                    this.$watch('isImapConfigLoaded', this.getImapAccounts);
                    return;
                }

                if (this.imapConfig[0]) {
                    this.accountId = this.imapConfig[0]._id;
                }
            },
            downloadAttachment(message, attachment) {
                let params = {
                    "account_id": message.account_id,
                    "message_id": message.id,
                    "attachment_id": attachment.id
                };

                IMAPapi.get(`microsoft/message/attachment`, { params })
                    .then(response => {
                        let data = response.data.data;
                        const linkSource = `data:${data.contentType};base64,${data.contentBytes}`;
                        const fileName = data.name;
                        const downloadLink = document.createElement('a');
                        document.body.appendChild(downloadLink);
                        downloadLink.href = linkSource;
                        downloadLink.target = '_self';
                        downloadLink.download = fileName;
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            getNameForAvatar(message) {
                let name = message.from.emailAddress.name;

                return name;
            },
            toRecipients(emails) {
                return emails.map(email => email.emailAddress.name).join(', ');
            },
            getConversation() {
                this.loading = true;
                let params = {
                    conversationId: this.selectConversationsId
                };

                IMAPapi.get(`conversation`, { params })
                    .then(response => {
                        response.data.data.forEach(el => {
                            let body = el.body.content.replace(/<style>/gi,'');
                            el.body.content = body;
                        });
                        this.conversations = response.data.data;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$nextTick(() => {
                            this.messagesDomList = this.$el.querySelectorAll('.message');
                        });
                    });
            }
        },
        created() {
            this.getConversation();
            this.getImapAccounts();
        }
    }
</script>