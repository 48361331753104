import {hasPermissions, somePermission, notifyIfNoAccessToPage} from '../utils/helpers';
import store from '@/store/store';

export default function everySomePermissions({ next, to }, redirect) {
    // check if the route has permissions
    if (to.matched.some(record => record.meta.permissions)) {
        const userPermissions = store.getters['user/getUserPermissions'];

        const isAccessGranted = to.meta.permissions.every(permission => {
            if (Array.isArray(permission)) {
                return somePermission(permission, userPermissions)
            }

            return hasPermissions(permission, userPermissions);
        });

        if (!isAccessGranted) {
            notifyIfNoAccessToPage();
            return next(redirect);
        }
    }

    return next();
}
