<template>
    <div
        :id="`form-date-range-input-${name}`"
        class="form-date-range-input row-baseline row-between relative pointer"
        :style="{ width: isFullWidth ? '100%' : 'auto' }"
        @click="dateMenuShow = !dateMenuShow"
    >
        <div class="form-date-block">
            <calendar-icon size="16"/>

            <div class="date-picker-from column-start">
                <span
                    v-if="range.end"
                    class="date ml-2"
                >
                    {{ range.start | date }}
                </span>
                <span class="placeholder ellipsis ml-2">From</span>
            </div>

            <div class="separator"></div>

            <div class="date-picker-to column-start">
                <span
                    v-if="range.end"
                    class="date ml-2"
                >
                    {{ range.end | date }}
                </span>
                <span class="placeholder ellipsis ml-2">To</span>
            </div>
        </div>
        <div 
            v-if="dateMenuShow"
            :id="`date-menu-${name}`"
            :class="[
                'date-menu',
                mScreen ? 'column-start' : 'row-between pl-4'
            ]"
            :style="stylePopup"
            @click.stop
        >
            <v-date-picker
                mode='range'
                v-model='range'
                is-inline
                :tintColor="tintColor"
                :attributes="attributes"
                :disabledAttribute="disabledAttribute"
                :themeStyles="themeStyles"
            />
            <div :class="[
                'date-menu-buttons w-100 column-center p-4', 
                { 'ml-4' : !mScreen }
            ]">
                <button 
                    v-for="button in menuButtons"
                    :key="button.title"
                    :class="[
                        'add-button mb-1', 
                        { 'active-button' : defaultDate.toUpperCase() == button.title.toUpperCase() }
                    ]"
                    @click.stop="button.method(), dateMenuShow = false"
                >
                    {{ button.title }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment/src/moment';
    import { getCoords } from '@/helpers.js'
    import { CalendarIcon, ArrowIcon } from '@/icons';
    import calendarConfig from '@/mixins/calendarConfig';

    const CURRENT_YEAR = 'current year';
    const CURRENT_WEEK = 'current week';
    const CURRENT_MONTH = 'current month';
    const CURRENT_QUARTER = 'current quarter';
    const LAST_YEAR = 'last year';
    const LAST_MONTH = 'last month';
    const LAST_WEEK = 'last week';

    export default {
        name: 'FormDateRangeInputWithDateMenu',
        components: {
            CalendarIcon, 
            ArrowIcon
        },
        props: {
            isFullWidth: {
                type: Boolean,
                default: false
            },
            name: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                stylePopup: '',
                range: {
                    start: moment().startOf('year'),
                    end: moment()
                },
                scrollHeight: 0,
                defaultDate: CURRENT_YEAR,
                dateMenuShow: false,
                menuButtons: [
                    {
                        title: 'Current year',
                        method: () => {
                            this.setCurrentYear();
                        }
                    },
                    {
                        title: 'Current month',
                        method: () => {
                            this.setCurrentMonth();
                        }
                    },
                    {
                        title: 'Current week',
                        method: () => {
                            this.setCurrentWeek();
                        }
                    },
                    {
                        title: 'Current quarter',
                        method: () => {
                            this.setCurrentQuarter();
                        }
                    },
                    {
                        title: 'Last year',
                        method: () => {
                            this.setLastYear();
                        }
                    },
                    {
                        title: 'Last month',
                        method: () => {
                            this.setLastMonth();
                        }
                    },
                    {
                        title: 'Last week',
                        method: () => {
                            this.setLastWeek();
                        }
                    }
                ]
            }
        },
        mixins: [ calendarConfig ],
        watch: {
            range(val,oldVal) {
                if(val.start != oldVal.start && val.end != oldVal.end) {
                    this.setCustomDate();
                }
            },
            dateMenuShow(val, oldVal) {
                if (!!val) {
                    this.$nextTick(() => {
                        this.showDateMenu();
                    });
                }
            }
        },
        methods: {
            showDateMenu() {
                this.stylePopup = '';
                const popup = document.getElementById(`date-menu-${this.name}`);
                const container = document.getElementById(`form-date-range-input-${this.name}`);
                const freeHeightBottom = this.scrollHeight - getCoords(container).top - container.clientHeight;
                if (
                    freeHeightBottom < popup.clientHeight
                    || container.getBoundingClientRect().top > window.outerHeight - container.getBoundingClientRect().top
                ) {
                    const marginTop = Math.round(popup.clientHeight + container.clientHeight);
                    this.stylePopup = `top: -${marginTop}px;`;
                }
            },
            setCustomDate() {
                this.$emit('input', this.range);
                this.defaultDate = 'custom';
                this.$emit('defaultDate', this.defaultDate);
            },
            setCurrentYear() {
                this.range.start = moment().startOf('year');
                this.range.end = moment();
                this.defaultDate = CURRENT_YEAR;
                this.$emit('defaultDate', this.defaultDate);
            },
            setCurrentWeek() {
                this.range.start = moment().startOf('week');
                this.range.end = moment();
                this.defaultDate = CURRENT_WEEK;
                this.$emit('defaultDate', this.defaultDate);
            },
            setCurrentMonth() {
                this.range.start = moment().startOf('month');
                this.range.end = moment();
                this.defaultDate = CURRENT_MONTH;
                this.$emit('defaultDate', this.defaultDate);
            },
            setCurrentQuarter() {
                this.range.start = moment().quarter(moment().quarter()).startOf('quarter');
                this.range.end = moment();
                this.defaultDate = CURRENT_QUARTER;
                this.$emit('defaultDate', this.defaultDate);
            },
            setLastYear() {
                let lastYear = moment().get('year') - 1;

                this.range.start = new Date(lastYear, 0, 1);
                this.range.end = new Date(lastYear, 11, 31);
                this.defaultDate = LAST_YEAR;
                this.$emit('defaultDate', this.defaultDate);
            },
            setLastMonth() {
                this.range.start = moment().subtract(1,'months').startOf('month');
                this.range.end = moment().subtract(1,'months').endOf('month');
                this.defaultDate = LAST_MONTH;
                this.$emit('defaultDate', this.defaultDate);
            },
            setLastWeek() {
                this.range.start = moment().subtract(1,'week').startOf('week');
                this.range.end = moment().subtract(1,'week').endOf('week');
                this.defaultDate = LAST_WEEK;
                this.$emit('defaultDate', this.defaultDate);
            },
            outside(event) {
                if (!this.$el.contains(event.target) && this.dateMenuShow)
                    this.dateMenuShow = false;
            }
        },
        mounted() {
            document.body.addEventListener('click', this.outside, false);
            this.scrollHeight = document.body.offsetHeight;
        },
        destroyed() {
            document.body.removeEventListener('click', this.outside, false);
        }
    }
</script>

