<template>
    <div class="smart-doc-sidebar s-align-1 mr-8">
        <div class="smart-doc-sidebar-tabs column-start">
            <div
                v-for="(tab,index) in tabs"
                :key="tab.name + index"
                @click="activeTab = tab.name"
                :class="[
                    'smart-doc-sidebar-tab p-8 pointer',
                    { 'smart-doc-sidebar-tab-active' : activeTab == tab.name }
                ]"
            >
                <component
                    :is="tab.icon"
                    :class="activeTab == tab.name ? 'i-blue' : 'i-grey'"
                />
            </div>
        </div>
        <div class="smart-doc-sidebar-items pv-8 ph-5 column-start">
            <div :class="[
                'smart-doc-sidebar-header f-sbold s-align-4 row-between ph-8 mb-8',
                getClassSidebarHeader(activeTab)
            ]">
                {{ getTitleSidebarHeader(activeTab) }}
            </div>
            <div
                v-for="(item,index) in smartObjects"
                :key="item.name"
                @dragstart="dragStart($event,item,index)"
                :draggable="true"
                :class="[
                    'grabbable smart-doc-sidebar-item s-align-4 mb-8 ph-1 pv-3', 
                    smartDrag == index
                        ?  'drag-item'
                        :  'smart-doc-sidebar-item'
                ]"
            >
                <div class="s-align-4">
                    <button class="transparent-button ml-8">
                        <drag-icon class="i-grey"/>
                    </button>
                    <span class="f-13-black ml-8">
                        {{ item.title }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { LightbulbIcon, SignatureDocIcon, ChainIcon, DragIcon, AddIcon, CalendarIcon } from '@/icons'
    import api from '@/headers.js';
    import { CheckboxButton } from '@/ui';

    const LEAD_FIELDS = 'LeadFields';
    const NOT_LINKED = 'NotLinked';
    const SIGNATURE_FIELDS = 'Signature';

    export default {
        name: 'SmartDocSidebar',
        components: {
            LightbulbIcon,
            SignatureDocIcon,
            ChainIcon,
            DragIcon,
            AddIcon,
            CalendarIcon,
            CheckboxButton
        },
        data() {
            return {
                items: [],
                tabs: [
                    {
                        name: LEAD_FIELDS,
                        icon: 'lightbulb-icon'
                    },
                    {
                        name: NOT_LINKED,
                        icon: 'chain-icon'
                    },
                    {
                        name: SIGNATURE_FIELDS,
                        icon: 'signature-doc-icon'
                    }
                ],
                activeTab: LEAD_FIELDS,
                LEAD_FIELDS: LEAD_FIELDS,
                NOT_LINKED: NOT_LINKED,
                SIGNATURE_FIELDS: SIGNATURE_FIELDS,
                smartDrag: null,
                draggable: false
            }
        },
        computed: {
            entityType() {
                return this.$route.query.entity;
            },
            smartObjects() {
                return this.items.filter(el => this.activeTab == NOT_LINKED ? el.entity == NOT_LINKED : 
                    this.activeTab == SIGNATURE_FIELDS ? el.entity == SIGNATURE_FIELDS :
                    el.entity != NOT_LINKED && el.entity != SIGNATURE_FIELDS);
            }
        },
        methods: {
            getTitleSidebarHeader(activeTab) {
                switch (activeTab) {
                    case LEAD_FIELDS:
                        return 'Smart Objects';
                    case NOT_LINKED:
                        return 'Not Linked';
                    default:
                        return 'Signature / Initial';
                }
            },
            getClassSidebarHeader(activeTab) {
                switch (activeTab) {
                    case LEAD_FIELDS:
                        return 'smart-doc-sidebar-header-smart';
                    case NOT_LINKED:
                        return 'smart-doc-sidebar-header-not-linked';
                    case SIGNATURE_FIELDS:
                        return 'smart-doc-sidebar-header-signature';
                }
            },
            getCoords(elem) {
                let box = elem.getBoundingClientRect();

                return {
                    top: box.top + pageYOffset,
                    left: box.left + pageXOffset
                }
            },
            dragStart(event,item,index) {
                let coords = this.getCoords(event.target);
                let shiftX = event.pageX - coords.left;
                let shiftY = event.pageY - coords.top;
                this.smartDrag = index;
                item.shiftX = shiftX;
                item.shiftY = shiftY;
                event.dataTransfer.setData('obj', JSON.stringify(item));
                setTimeout(() => {
                    this.smartDrag = null;
                },0);
            },
            getFields() {
                api.get(`/smart-documents/fields?entity=${this.entityType }`)
                    .then(response => {
                        this.items = response.data.data;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            scrollListener() {
                let smartWrapper = document.querySelector('.smart-wrapper');
                let smartSideBar = document.querySelector('.smart-doc-sidebar');

                if (window.scrollY > 200) {
                    smartSideBar.classList.add('smart-fixed-bar');
                    smartSideBar.style.paddingTop = 70 + 'px';
                    smartWrapper.style.paddingLeft = 365 + 'px';
                } else {
                    smartSideBar.classList.remove('smart-fixed-bar');
                    smartSideBar.style.paddingTop = '';
                    smartWrapper.style.paddingLeft = '';
                }
            }
        },
        created() {
            this.getFields();
            window.addEventListener('scroll', this.scrollListener);
        },
        destroyed() {
            window.removeEventListener('scroll', this.scrollListener);
        }
    }
</script>