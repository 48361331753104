<template>
    <svg
        viewBox="0 0 10 9"
        :width="size"
        :height="size"
        class="messages-icon"
    >
        <path d="M2.20712 4.21901H4.7074C4.88778 4.2169 5.03525 4.36227 5.03525 4.54279C5.03525 4.72225 4.88778 4.86762 4.7074 4.86577H2.20712C1.78051 4.86143 1.78051 4.22322 2.20712 4.21901ZM2.20712 2.35668H7.79347C7.97372 2.35457 8.12132 2.49993 8.12132 2.68019C8.12132 2.86071 7.97372 3.00607 7.79347 3.00397H2.20712C2.02661 3.00607 1.87914 2.86071 1.87914 2.68019C1.87914 2.49993 2.02661 2.35457 2.20712 2.35668ZM1.6474 0.333313C1.072 0.333313 0.600098 0.80469 0.600098 1.37995V8.23704C0.600098 8.46825 0.888849 8.57451 1.03829 8.39833L2.34959 6.85662H8.35359C8.92912 6.85662 9.4001 6.38629 9.4001 5.81076V1.38009C9.4001 0.804821 8.92912 0.333445 8.35359 0.333445H1.6474V0.333313Z"/>
    </svg>
</template>

<script>
    export default {
        name: 'MessagesIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>
