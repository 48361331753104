<template>
    <svg 
        viewBox="0 0 18.666668 20"
        :width="size" 
        :height="size"
        class="person-icon"
    >
        <clipPath clipPathUnits="userSpaceOnUse">
            <path d="M 0,15 H 14 V 0 H 0 Z" />
        </clipPath>
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,20)">
            <g transform="translate(6.9997,13.791)">
                <path d="m 0,0 c 1.566,0 2.839,-1.292 2.839,-2.879 0,-1.588 -1.273,-2.879 -2.839,-2.879 -1.566,0 -2.84,1.291 -2.84,2.879 C -2.84,-1.292 -1.566,0 0,0 m 0,-6.967 c 2.227,0 4.032,1.83 4.032,4.088 0,2.257 -1.805,4.088 -4.032,4.088 -2.227,0 -4.032,-1.831 -4.032,-4.088 0,-2.258 1.805,-4.088 4.032,-4.088"/>
            </g>
            <g transform="translate(1.1921)">
                <path d="M 0,0 C 0,2.275 1.826,4.127 4.071,4.127 H 7.545 C 9.79,4.127 11.616,2.275 11.616,0 h 0.595 0.597 c 0,2.942 -2.361,5.336 -5.263,5.336 H 4.071 C 1.169,5.336 -1.192,2.942 -1.192,0 h 0.596 z"/>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'PersonIcon',
    props: {
        size: {
            type: [Number, String],
            default: 15
        }
    }
}
</script>
