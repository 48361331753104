import moment from 'moment/src/moment';
import Vue from 'vue';

// check what events overlapping at the same time (needed to calculate width & left css properties)
export const checkCellOverlappingEvents = {
    data() {
        return {
            cellOverlaps: {}
        }
    },
    methods: {
        checkCellOverlappingEvents() {
            // sort event to single/multi days
            this.sortEvents();

            let comparisonArray = this.events.slice(0);
            let cellOverlaps = {};

            this.events.forEach(e => {
                // Never compare the current event in the next loops. the array is refined as we loop.
                comparisonArray.shift();

                if (!cellOverlaps[e.id]) {
                    Vue.set(cellOverlaps, e.id, { overlaps: [], start: e.dtstart, position: 0 });
                }
                cellOverlaps[e.id].position = 0;

                comparisonArray.forEach(e2 => {
                    if (!cellOverlaps[e2.id]) {
                        Vue.set(cellOverlaps, e2.id, { overlaps: [], start: e2.dtstart, position: 0 })
                    }

                    // Add to the overlaps array if overlapping.
                    if (this.eventInRange(e, e2)) {
                        cellOverlaps[e.id].overlaps.push(e2.id)
                        cellOverlaps[e.id].overlaps = [...new Set(cellOverlaps[e.id].overlaps)] // Remove duplicate values

                        cellOverlaps[e2.id].overlaps.push(e.id)
                        cellOverlaps[e2.id].overlaps = [...new Set(cellOverlaps[e2.id].overlaps)] // Remove duplicate values
                        cellOverlaps[e2.id].position++;
                        return;
                    }
                    // Remove from the overlaps array if not overlapping
                    let pos1, pos2;
                    if ((pos1 = (cellOverlaps[e.id] || { overlaps: [] }).overlaps.indexOf(e2.id)) > -1) {
                        cellOverlaps[e.id].overlaps.splice(pos1, 1);
                    }

                    if ((pos2 = (cellOverlaps[e2.id] || { overlaps: [] }).overlaps.indexOf(e.id)) > -1) {
                        cellOverlaps[e2.id].overlaps.splice(pos2, 1);
                    }

                    cellOverlaps[e2.id].position--;
                })
            })

            // Determining the width of events in a row.
            // e.g. 3 overlapping events [1, 2, 3]; 1 overlaps 2 & 3; 2 & 3 don't overlap;
            //      => streak = 2; each width = 50% not 33%.
            for (const id in cellOverlaps) {
                const item = cellOverlaps[id];

                // Calculate the position of each event in current streak (determines the CSS left property).
                const overlapsRow = item.overlaps.map(id2 => ({ id: id2, start: cellOverlaps[id2].start }));
                overlapsRow.push({ id, start: item.start });
                overlapsRow.sort((a, b) => a.start < b.start
                    ? -1
                    : (a.start > b.start
                        ? 1
                        : (a.id > b.id ? -1 : 1)
                    )
                );
                item.position = overlapsRow.findIndex(e => e.id === id)
            }

            this.cellOverlaps = cellOverlaps;
        },
        // Check if events overlaps
        eventInRange(event1, event2) {
            if (moment(event1.dtstart).isSame(moment(event2.dtstart), 'day')) {
                return moment(event2.dtstart).isBefore(moment(event1.dtend)) 
                    && moment(event2.dtend).isAfter(moment(event1.dtstart));
            }
            return false;
        },
        // calculate event popup position
        getEventStyles(event, day) {
            const utcOffset = moment(day).utcOffset();
            let dtstart = moment(event.dtstart).add(utcOffset, 'm').format('YYYY-MM-DD HH:mm:ss');
            let dtend = moment(event.dtend).add(utcOffset, 'm').format('YYYY-MM-DD HH:mm:ss');

            let dayStart = moment(day).set('hour', this.timeLineStart);
            let dayEnd = moment(day).set('hour', this.timeLineEnd);
            
            if (moment(dtstart).isBefore(dayStart, 'hour')) {
                dtstart = dayStart.clone();
            }

            if (moment(dtend).isAfter(dayEnd, 'hour')) {
                dtend = dayEnd.clone();
            }

            // calculate top position of event according to timeLineStart variable
            const top = moment(dtstart).diff(moment(dtstart).startOf('day'), 'minutes') 
                - (this.timeLineStart) * 60;

            // 1 minutes of event == 1px in markup
            const height = moment(dtend).diff(moment(dtstart), 'minutes'); 
            
            const width = this.cellOverlaps[event.id]
                ? 100 / (this.cellOverlaps[event.id].overlaps.length + 1)
                : 100;
            const left = this.cellOverlaps[event.id]
                ? width * this.cellOverlaps[event.id].position
                : 0;

            return {
                // calulate text eclipsis, height - height of event, 17 - top/bottom padding + border of event element, 12 - height of one line of text
                lineClamp: Math.floor((height - 17)/12),
                top: `${top + 2.5}px`,
                height: `${height - 5}px`,
                width: `calc(${width}% - 5px)`,
                left: `calc(${left}% + 2.5px)`,
                // backgroundColor: event.color !== null ? event.color : '#f7f8fb'
            }
        }
    }
}