<template>
    <p :class="['pb-1', titleStyle, { 'f-sbold': titleBold }]">
        {{ title.length? `${title}:` : '' }}
        <span 
            :class="['f-normal pl-1', contentStyle]"
            v-if="isDate"
        >
            {{ content | date }}
        </span>
        <span 
            :class="['f-normal pl-1', contentStyle]"
            v-else-if="isPhone"
        >
            <template v-if="isPhoneMakeCall">
                <make-call-from-number-button
                    :phone="content"
                    :showPhoneType="false"
                    :entityType="entityType"
                    :entityId="entityId"
                />
            </template>
            <template v-else>
                {{ content | phone }}
            </template>
        </span>
        <span 
            class="f-normal f-13-grey f-italic pl-1"
            v-else-if="contentEmpty"
        >
            {{ placeholder }}
        </span>
        <span 
            :class="['f-normal pl-1', contentStyle]"
            v-else
        >
            {{ content }}
        </span>
    </p>
</template>

<script>
    import MakeCallFromNumberButton from './Telephony/MakeCallFromNumberButton.vue';
    import { ENTITY_TYPES } from '@/constants';

    export default {
        name: 'ContentItem',
        components: {
            MakeCallFromNumberButton
        },
        props: {
            title: {
                type: String,
                default: ''
            },
            titleStyle: {
                type: String,
                default: 'f-13-grey'
            },
            titleBold: {
                type: Boolean,
                default: true
            },
            content: {
                type: [String, Number],
                default: ''
            },
            contentStyle: {
                type: String,
                default: 'f-13-black'
            },
            isDate: {
                type: Boolean,
                default: false
            },
            isPhone: {
                type: Boolean,
                default: false
            },
            isPhoneMakeCall: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: 'No information'
            },
            entityId: {
                type: [String, Number],
                required: false
            }
        },
        computed: {
            contentEmpty() {
                return this.content == '' || this.content == null;
            },
            entityType() {
                switch(this.$route.name) {
                    case "ResidentialLeads": {
                        return ENTITY_TYPES.RESIDENTIAL_LEAD;
                    }
                }
            }
        }
    }
</script>