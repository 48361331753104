<template>
    <div 
        class="add-tag-modal"
    >
        <div class="row-between mb-6 p-8">
            <span class="f-16-black">Labels</span>
            <button 
                class="icon-light"
               @click="$emit('closeModal')"
            >
                <close-icon size="15"/>
            </button>
        </div>
        <div 
            v-if="isLoading"
            class="w-100"
        >
            <loader size="small"/>
        </div>
        <div 
            v-else
            class="available-tags column-start"
        >
            <template v-for="(tag, index) in allTags">
                <div 
                    v-if="editingTagId !== tag.id"
                    :key="`label_${index}`"
                    class="row-baseline ph-8 mb-1"
                > 
                    <button 
                        :class="[
                            'row-between row-baseline tag lg-10',
                            `tag-${tag.color}`,
                            isTagSelected(tag.id) ? 'tag-selected' : ''
                        ]"
                        @click="toggleTag(tag.id)"
                        :disabled="requestIsGoing"
                    >
                        <span class="ellipsis t-left">{{ tag.name }}</span>
                        <mark-icon 
                            v-if="isTagSelected(tag.id)"
                            size="10"
                        />
                    </button>
                    <button 
                        class="icon-light lg-2"
                        @click="showEditField(tag)"
                    >
                        <edit-icon/>
                    </button>
                </div>
                <div 
                    v-else
                    :key="`input_${index}`"
                    class="tag-edit w-100 bt-grey bb-grey mv-1"
                >
                    <div class="column-start p-4">
                        <div class="row-between mb-4">
                            <span class="f-11-black f-sbold">Edit label</span>
                            <button 
                                class="icon-light"
                                @click="hideEditField(tag.id)"
                            >
                                <close-icon size="9"/>
                            </button>
                        </div>
                        <form-input 
                            :class="[
                                'mb-6',
                                errors.has(`tag_${tag.id}_name`) ? 'form-input-danger' : ''
                            ]"
                        >
                            <input 
                                class="form-input-field"
                                type="text"
                                :name="`tag_${tag.id}_name`"
                                placeholder="Tag name"
                                v-model="editingTagName"
                                v-validate.persist="'required|min:3|max:99'"
                                maxlength="100"
                            >
                        </form-input>
                        <p class="f-11-black f-sbold mb-3">Color</p>
                        <div class="row-between">
                            <label
                                v-for="(color, index) in colors"
                                :key="index"
                                :class="[
                                    'tag-color',
                                    `tag-color-${color}`
                                ]"
                            >
                                <input 
                                    type="radio" 
                                    name="tag_color" 
                                    :value="color"
                                    v-model="editingTagColor"
                                />
                                <div class="color-label pointer"></div>
                            </label>
                        </div>
                    </div>
                    <div class="row-between bt-grey p-5">
                        <button 
                            class="transparent-button f-12-grey"
                            @click="deleteTag(tag.id)"
                            :disabled="requestIsGoing"
                        >
                            <trash-icon width="18" height="12"/>
                            Delete
                        </button>
                        <button 
                            class="transparent-button f-12-blue f-sbold"
                            @click="updateTag()"
                            :disabled="requestIsGoing"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </template>
            <button 
                v-if="!isCreateNewShow"
                class="w-100 transparent-button p-8 f-13-blue f-sbold t-center"
                @click="hideEditField(), isCreateNewShow = true"
            >
                + Create a new label
            </button>
            <div 
                v-else
                class="tag-edit w-100 bt-grey mt-1"
            >
                <div class="column-start p-4">
                    <div class="row-between mb-4">
                        <span class="f-11-black f-sbold">Create new label</span>
                        <button 
                            class="icon-light"
                            @click="isCreateNewShow = false"
                        >
                            <close-icon size="9"/>
                        </button>
                    </div>
                    <form-input 
                        :class="[
                            'mb-6',
                            errors.has('new_tag_name') ? 'form-input-danger' : ''
                        ]"
                    >
                        <input 
                            class="form-input-field"
                            type="text"
                            name="new_tag_name"
                            placeholder="Tag name"
                            v-model="newTagName"
                            v-validate.persist="'required|min:3|max:99'"
                            maxlength="100"
                        >
                    </form-input>
                    <p class="f-11-black f-sbold mb-3">Color</p>
                    <div class="row-between">
                        <label
                            v-for="(color, index) in colors"
                            :key="index"
                            :class="[
                                'tag-color',
                                `tag-color-${color}`
                            ]"
                        >
                            <input 
                                type="radio" 
                                name="new_tag_color" 
                                :value="color"
                                v-model="newTagColor"
                            />
                            <div class="color-label pointer"></div>
                        </label>
                    </div>
                </div>
                <div class="row-end bt-grey p-5">
                    <button 
                        class="transparent-button f-12-grey f-sbold"
                        @click="isCreateNewShow = false"
                    >
                        Cancel
                    </button>
                    <button 
                        class="transparent-button f-12-blue f-sbold ml-8"
                        @click="createTag"
                        :disabled="requestIsGoing"
                    >
                        Create
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { 
        CloseIcon,
        EditIcon,
        MarkIcon,
        TrashIcon
    } from '@/icons';
    import { FormInput, RadioButton, Loader } from '@/ui';
    import api from '@/headers.js';
    import cloneDeep from 'lodash/cloneDeep';

    export default {
        name: 'ProjectManagementTags',
        components: {
            CloseIcon,
            EditIcon,
            MarkIcon,
            TrashIcon,
            FormInput,
            RadioButton,
            Loader
        },
        props: {
            tags: {
                type: Array,
                required: true
            },
            cardId: {
                type: [String, Number],
                required: true
            },
            allTagsProps: {
                type: Array,
                required: true
            },
            isLoading: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                requestIsGoing: false,
                allTags: [],
                selectedTags: [],
                colors: [
                    'dark',
                    'darkblue',
                    'blue',
                    'green',
                    'yellow',
                    'orange',
                    'red',
                    'violet'
                ],
                isCreateNewShow: false,
                newTagName: '',
                newTagColor: 'orange', // default color
                editingTagId: null,
                editingTagName: '',
                editingTagColor: ''
            }
        },
        watch: {
            allTagsProps: function() {
                this.allTags = cloneDeep(this.allTagsProps);
            }
        },
        methods: {
            getTags() {
                this.isLoading = true;
                api.get('/kanban/tags')
                    .then(response => response.data.data)
                    .then(data => {
                        this.allTags = data;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            isTagSelected(id) {
                return this.selectedTags.some(item => item.id === id);
            },
            showEditField(tag) {
                this.isCreateNewShow = false;
                this.editingTagId = tag.id;
                this.editingTagName = tag.name;
                this.editingTagColor = tag.color;
            },
            hideEditField() {
                this.editingTagId = null;
                this.editingTagName = '';
                this.editingTagColor = '';
            },
            toggleTag(id) {
                this.requestIsGoing = true;
                if (this.isTagSelected(id)) {
                    api.delete(`/kanban/tags/${this.cardId}/remove-tag/${id}`)
                        .then(response => response.data.data)
                        .then(data => {
                            if (data) {
                                const index = this.selectedTags.findIndex(item => item.id === id);
                                this.selectedTags.splice(index, 1);
                                this.$emit('updateTags', {
                                    selectedTags: cloneDeep(this.selectedTags),
                                    allTags: cloneDeep(this.allTags)
                                });
                                
                                this.notifySuccess('Tag deleted from card!');
                            }
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.requestIsGoing = false;
                        });
                    return;
                }

                const params = {
                    id,
                    card_id: this.cardId
                };
                api.post(`/kanban/tags/${id}`, params)
                    .then(() => {
                        const index = this.allTags.findIndex(item => item.id === id);
                        this.selectedTags.push(this.allTags[index]);
                        this.$emit('updateTags', {
                            selectedTags: cloneDeep(this.selectedTags),
                            allTags: cloneDeep(this.allTags)
                        });

                        this.notifySuccess('Tag added to card!');   
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            },
            createTag() {
                this.$validator.validate('new_tag_name')
                    .then(result => {
                        if (result) {
                            if (this.allTagsProps.some(tag => { 
                                return tag.name.toLowerCase() === this.newTagName.toLowerCase();
                            })) {
                                this.notifyWarning('Label with this name already exists. Use a different label name.');
                                
                                return;
                            }

                            const params = {
                                name: this.newTagName,
                                color: this.newTagColor
                            };

                            this.requestIsGoing = true;
                            api.post('/kanban/tags', params)
                                .then(response => response.data.data)
                                .then(data => {
                                    this.allTags.push(data);

                                    this.$emit('updateTags', {
                                        selectedTags: cloneDeep(this.selectedTags),
                                        allTags: cloneDeep(this.allTags)
                                    });

                                    this.notifySuccess(`Tag '${data.name}' created!`);
                                })
                                .catch((error) => {
                                    this.notifyRequestErrors(error);
                                })
                                .finally(() => {
                                    this.requestIsGoing = false;
                                    this.isCreateNewShow = false;
                                    this.newTagName = '';
                                    this.newTagColor = 'orange'; // reset to default
                                });
                        }
                    });
            },
            updateTag() {
                this.$validator.validate(`tag_${this.editingTagId}_name`)
                    .then(result => {
                        if (result) {
                            const params = {
                                id: this.editingTagId,
                                name: this.editingTagName,
                                color: this.editingTagColor
                            }

                            this.requestIsGoing = true;
                            api.put(`/kanban/tags/${this.editingTagId}`, params)
                                .then(response => response.data.data)
                                .then(data => {
                                    if (data) {
                                        const allTagIndex = this.allTags.findIndex(item => item.id === this.editingTagId)
                                        this.allTags[allTagIndex].name = this.editingTagName;
                                        this.allTags[allTagIndex].color = this.editingTagColor;

                                        const selectedTagIndex = this.selectedTags.findIndex(
                                            item => item.id === this.editingTagId);
                                        if (selectedTagIndex !== -1) {
                                            this.selectedTags[selectedTagIndex].color = this.editingTagColor;
                                            this.selectedTags[selectedTagIndex].name = this.editingTagName;
                                            this.$emit('updateTags', {
                                                selectedTags: cloneDeep(this.selectedTags),
                                                allTags: cloneDeep(this.allTags)
                                            });
                                        }

                                        this.notifySuccess(`Tag '${this.editingTagName}' updated!`);
                                    }
                                })
                                .catch((error) => {
                                    this.notifyRequestErrors(error);
                                })
                                .finally(() => {
                                    this.requestIsGoing = false;
                                    this.hideEditField();
                                });
                        }
                    });
            },
            deleteTag(id) {
                this.requestIsGoing = true;
                api.delete(`/kanban/tags/${id}`)
                    .then(response => response.data.data)
                    .then(data => {
                        if (data) {
                            const allTagIndex = this.allTags.findIndex(item => item.id === id);
                            const selectedTagIndex = this.selectedTags.findIndex(item => item.id === id);
                            this.allTags.splice(allTagIndex, 1);
                            
                            if (selectedTagIndex !== -1) {
                                this.selectedTags.splice(selectedTagIndex, 1);
                            }

                            this.$emit('updateTags', {
                                selectedTags: cloneDeep(this.selectedTags),
                                allTags: cloneDeep(this.allTags)
                            });

                            this.notifySuccess('Tag deleted!');
                        }
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                        this.editingTagId = null;
                    });
            }
        },
        created() {
            this.allTags = cloneDeep(this.allTagsProps);
            this.selectedTags = cloneDeep(this.tags);
        }
    }
</script>