<template>
    <div class="w-box column-center mt-20">
        <div class="row-between row-baseline mb-60">
            <p 
                :class="[
                    'f-sbold',
                    mScreen ? 'f-14-black' : 'f-24-black'
                ]"
            >
                Choose components and trade for the order
            </p>
            <router-link
                :to="{ name: allOrdersRoute }"
                class="add-button"
            >
                Cancel
            </router-link>
        </div>
        <p class="f-16-black t-center mb-20">Select components for the order</p>
        <div class="row-center mb-60">
            <button 
                :class="[
                    'add-medium-button mr-4 m-sm-0 mb-sm-1',
                    { 'add-medium-button-active' : has_material && has_labor }
                ]"
                @click="has_material = 1; has_labor = 1"
            >
                Materials & Labor
            </button>
            <button 
                :class="[
                    'add-medium-button mr-4 m-sm-0 mb-sm-1',
                    { 'add-medium-button-active' : has_material && !has_labor }
                ]"
                @click="has_material = 1; has_labor = 0"
            >
                Materials only
            </button>
            <button 
                :class="[
                    'add-medium-button mr-4 m-sm-0',
                    { 'add-medium-button-active' : !has_material && has_labor }
                ]"
                @click="has_material = 0; has_labor = 1"
            >
                Labor only
            </button>
        </div>
        <p class="16-black t-center mb-20">Select trade for the order</p>
        <div :class="[ mScreen || tScreen ? 'w-100' : 'w-55' ]">
            <multiselect
                :options="trades"
                track-by="id" 
                label="name"
                v-model="selectedTrade"
                :showLabels="false"
                :max-height="160"
                v-validate="'required'"
                name="order_trade"
                data-name="order_trade"
                placeholder="Trade"
                :class="['mb-8',{ 'multiselect-danger' : errors.has('order_trade') }]"
                @select="setOrderName"
            />
            <form-input 
                label="Section Name"
                :class="['mb-8', { 'form-input-danger' : errors.has('order_name') }]"
            >
                <input 
                    type="text" 
                    name="order_name" 
                    class="form-input-field"
                    placeholder="Order Name"
                    v-input-style="name"
                    v-model="name"
                    v-validate="'required|min:3|max:255'"
                />
            </form-input>
        </div>
    </div>
</template>

<script>
    import { ENTITY_TYPES } from '@/constants';
    import Multiselect from 'vue-multiselect';
    import { FormInput} from '@/ui';
    import api from '@/headers.js';

    export default {
        name: 'OrderCreateStep1',
        components: {
            FormInput,
            Multiselect
        },
        props: {
            entityType: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                has_material: true,
                has_labor: true,
                trades: [],
                selectedTrade: null,
                name: ''
            }
        },
        computed: {
            formData() {
                return this.$store.getters['orders/getOrderFormData'];
            },
            allOrdersRoute() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: 
                        return 'ResidentialOrders';
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return 'CommercialJobOrders';
                    case ENTITY_TYPES.WARRANTY_JOB:
                        return 'WarrantyJobOrders';
                }
            }
        },
        methods: {
            getTrades() {
                const params = { needed: ['trades'] };

                api.get('/handbooks?filter[is_active]=1', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        this.trades.push(...data.trades);

                        if (this.formData.trade_id) {
                            this.selectedTrade = this.trades.find(trade => {
                                return trade.id === this.formData.trade_id
                            });
                        }
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            setOrderName(data) {
                this.name = `${data.name} Order`;
            },
            // validation is calling in parent component, need to return promise with result
            validateFields() {
                return this.$validator.validateAll()
                    .then(result => {
                        if (result) {
                            this.$store.commit('orders/STORE_ORDER_FORM_DATA', {
                                has_material: this.has_material,
                                has_labor: this.has_labor,
                                name: this.name,
                                trade_id: this.selectedTrade.id
                            })
                        }

                        return result;
                    });
            }
        },
        created() {
            this.getTrades();
            
            if (this.formData.name) {
                this.has_material = this.formData.has_material;
                this.has_labor = this.formData.has_labor;
                this.name = this.formData.name;
            }
        }
    }
</script>