var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.quantity >= 2)?_c('div',{staticClass:"pagination w-100 row-between"},[(_vm.showDesc)?_c('div',{staticClass:"lg-2 sm-3 s-align-4"},[_c('span',{staticClass:"f-12-grey"},[_vm._v("\n            Page "+_vm._s(_vm.currentPage.number)+" of "+_vm._s(_vm.quantity)+"\n        ")])]):_vm._e(),_c('div',{class:[
            'pagination-nav',
            _vm.showDesc ? 'lg-10 sm-9 s-align-6' : 'lg-12 s-align-5'
        ]},[_c('button',{staticClass:"transparent-button pagination-button mr-1",attrs:{"disabled":_vm.prevPage == null},on:{"click":function($event){return _vm.switchPage(_vm.prevPage)}}},[_c('arrow-icon',{attrs:{"transform":"180"}})],1),_vm._l((_vm.pagination),function(page,index){return _c('button',{key:index,class:[
                'transparent-button pagination-button mr-1 ph-1',
                { 'pagination-active-button' : page.status && _vm.theme == 'default' },
                { 'pagination-active-minimal-button' : page.status && _vm.theme == _vm.MINIMAL }
            ],attrs:{"disabled":page.number == _vm.DOTS || page.number == _vm.LINE},on:{"click":function($event){return _vm.switchPage(page)}}},[(page.number != _vm.LINE)?_c('div',[_vm._v("\n                "+_vm._s(page.number)+"\n            ")]):_c('div',{staticClass:"s-align-5",domProps:{"innerHTML":_vm._s(page.number)}})])}),_c('button',{staticClass:"transparent-button pagination-button",attrs:{"disabled":_vm.nextPage == null},on:{"click":function($event){return _vm.switchPage(_vm.nextPage)}}},[_c('arrow-icon',{attrs:{"transform":"0"}})],1)],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }