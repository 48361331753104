<template>
    <svg
        viewBox="0 0 15 20"
        :width="width"
        :height="height"
        class="currency-icon"
    >
        <g transform="matrix(0.8628545403480531, 0, 0, 0.8628545403480531, 365.2251281738282, -225.5899200439453)">
            <path d="M-412.7,265.8c1.7,0,3,1.4,3,3.1c0,0.6,0.4,1,1,1s1-0.4,1-1c0-2.8-2.2-5.1-5-5.1h-1.3v-0.9c0-0.6-0.4-1-1-1s-1,0.4-1,1v0.9h-0.7c-2.8,0-5,2.3-5,5.1v0.7c0,2.4,1.9,4.4,4.3,4.5h1.4v6.2h-1.3c-1.7,0-3-1.4-3-3.1c0-0.6-0.4-1-1-1s-1,0.4-1,1c0,2.8,2.2,5.1,5,5.1h1.3v0.9c0,0.6,0.4,1,1,1s1-0.4,1-1v-0.9h1.2c2.9,0,5.2-2.4,5.2-5.2v0c0-2.7-2.2-5-4.9-5h-1.4v-6.2H-412.7z M-416,272h-1.4c-1.3,0-2.4-1.1-2.4-2.5v-0.7c0-1.7,1.3-3.1,3-3.1h0.8V272z M-412.6,274c1.6,0,2.9,1.4,2.9,3c0,1.8-1.4,3.2-3.2,3.2c0,0,0,0,0,0h-1.2V274H-412.6z"/>
        </g>
    </svg>
    
</template>

<script>
    export default {
        name: 'CurrencyIcon',
        props: {
            width: {
                type: [Number, String],
                default: 15
            },
            height: {
                type: [Number, String],
                default: 18
            }
        }
    }
</script>
