<template>
    <div :class="[
        'p-20 quality-control mb-20 column-start w-100',
        assigneCrew ? 'bg-white' : 'bg-grey-1'
    ]">
        <div class="f-16-black f-sbold">
            Pre-Scheduled Crew
        </div>
        <loader
            v-if="loading"
            size="small"
            class="loader-small-padding"
        />
        <div
            v-if="assigneCrew && assigneCrew.length"
            class="column-start w-100"
        >
            <div
                :class="[
                    'row-start mt-4 s-align-4 flex-no-wrap w-100 pb-4',
                    { 'bb-grey' : assigneCrew.length - 1 !== index}
                ]"
                v-for="(crew, index) in assigneCrew"
                :key="crew.id"
            >
                <avatar
                    :name="crew.name"
                    :border="false"
                    class="mr-8"
                    size="50"
                />
                <div class="column-start">
                    <div class="f-16-darkgrey f-sbold mb-2">
                        {{ crew.name }}
                    </div>
                    <div
                        v-if="crew.phone"
                        class="s-align-4"
                    >
                        <div class="flex-grow-1">
                            <phone-icon
                                class="i-grey"
                                size="14"
                            />
                        </div>
                        <div class="f-12-grey ml-4 break-all">
                            {{ crew.phone }}
                        </div>
                    </div>
                    <div
                        v-if="crew.email"
                        class="s-align-4"
                    >
                        <div class="flex-grow-1">
                            <envelope-icon
                                class="i-grey flex-grow-1"
                                size="14"
                            />
                        </div>
                        <div class="f-12-grey ml-4 break-all">
                            {{ crew.email }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-end mt-4">
                <div
                    class="f-13-blue pointer"
                    @click="isShowPreScheduledCrew = !isShowPreScheduledCrew"
                >
                    Edit
                </div>
            </div>
        </div>
        <div
            v-else-if="!loading"
            class="mt-8"
        >
            <div class="f-13-black">
                Choose Pre-Scheduled Crew for this job
            </div>
            <button
                class="primary-button mt-20 full-width-button"
                @click="isShowPreScheduledCrew = !isShowPreScheduledCrew"
            >
                Assign
            </button>
        </div>
        <keep-alive>
            <pre-scheduled-crew-edit-modal
                v-if="isShowPreScheduledCrew"
                :key="entityType"
                :entityType="entityType"
                :assigneCrew="assigneCrew"
                :enyityId="enyityId"
                @updateAssignedCrew="updateAssignedCrew"
                @close="isShowPreScheduledCrew = false"
            />
        </keep-alive> 
    </div>
</template>
<script>
    import Avatar from '@/components/User/Avatar.vue';
    import { EnvelopeIcon, PhoneIcon } from '@/icons';
    import { BASE_ENTITY_TYPES } from '@/constants';
    import { Loader } from '@/ui';
    import api from '@/headers.js';

    export default {
        name: 'PreScheduledCrew',
        components: {
            Loader,
            PreScheduledCrewEditModal: () => import('@/modals/PreScheduledCrewEditModal.vue'),
            EnvelopeIcon,
            PhoneIcon,
            Avatar
        },
        props: {
            entityType: {
                type: String,
                default: null
            },
            enyityId: {
                type: [String, Number],
                required: true
            }
        },
        data() {
            return {
                isShowPreScheduledCrew: false,
                loading: false,
                assigneCrew: null
            }
        },
        computed: {
            getEntityType() {
                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        return BASE_ENTITY_TYPES.COMMERCIAL_JOB;
                    case BASE_ENTITY_TYPES.RESIDENTIAL:
                        return BASE_ENTITY_TYPES.RESIDENTIAL_JOB;
                    case BASE_ENTITY_TYPES.WARRANTY_JOB:
                        return BASE_ENTITY_TYPES.WARRANTY_JOB;
                    default:
                        return '';
                }
            }
        },
        methods: {
            getAssigneCrew() {
                const params = {
                    entityId: this.enyityId,
                    entityType: this.getEntityType
                };

                this.loading = true;
                api.get(`/crews/entities`, { params })
                    .then((response) => {
                        this.assigneCrew = [...response.data.data];
                    })
                    .catch((error) => this.notifyRequestErrors(error))
                    .finally(() => {
                        this.loading = false;
                    });
            },
            updateAssignedCrew(crews) {
                this.assigneCrew = crews;
                this.isShowPreScheduledCrew = false;

                setTimeout(() => {
                    this.notifySuccess('Crew is added!');
                }, 200);
            }
        },
        created() {
            this.getAssigneCrew();
        }
    }
</script>