<template>
    <svg
        :width="size"
        :height="size"
        viewBox="0 0 16 9"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M15.2 1.58824H0.8C0.587827 1.58824 0.384344 1.50457 0.234315 1.35564C0.0842855 1.20672 0 1.00473 0 0.794118C0 0.583505 0.0842855 0.381518 0.234315 0.232592C0.384344 0.0836656 0.587827 0 0.8 0H15.2C15.4122 0 15.6157 0.0836656 15.7657 0.232592C15.9157 0.381518 16 0.583505 16 0.794118C16 1.00473 15.9157 1.20672 15.7657 1.35564C15.6157 1.50457 15.4122 1.58824 15.2 1.58824Z" />
        <path d="M12.5332 5.29411H3.4665C3.25433 5.29411 3.05085 5.21044 2.90082 5.06151C2.75079 4.91259 2.6665 4.7106 2.6665 4.49999C2.6665 4.28938 2.75079 4.08739 2.90082 3.93846C3.05085 3.78954 3.25433 3.70587 3.4665 3.70587H12.5332C12.7453 3.70587 12.9488 3.78954 13.0989 3.93846C13.2489 4.08739 13.3332 4.28938 13.3332 4.49999C13.3332 4.7106 13.2489 4.91259 13.0989 5.06151C12.9488 5.21044 12.7453 5.29411 12.5332 5.29411Z" />
        <path d="M9.33337 8.99998H6.6667C6.45453 8.99998 6.25104 8.91631 6.10101 8.76739C5.95098 8.61846 5.8667 8.41647 5.8667 8.20586C5.8667 7.99525 5.95098 7.79326 6.10101 7.64434C6.25104 7.49541 6.45453 7.41174 6.6667 7.41174H9.33337C9.54554 7.41174 9.74902 7.49541 9.89905 7.64434C10.0491 7.79326 10.1334 7.99525 10.1334 8.20586C10.1334 8.41647 10.0491 8.61846 9.89905 8.76739C9.74902 8.91631 9.54554 8.99998 9.33337 8.99998Z" />
    </svg>
</template>

<script>
    export default {
        name: 'FilterIconCenter',
        props: {
            size: {
                type: [Number, String],
                default: 16
            }
        }
    }
</script>
