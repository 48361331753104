<template>
    <div class="modal s-align-5">
        <div class="create-new-folder-modal cm-fond">
            <h2 class="f-24-black f-sbold t-center">Create new default folder</h2>
            <div class="w-100">
                <multiselect
                    :options="folders"
                    placeholder="Сhoose a parent"
                    track-by="id"
                    label="name"
                    :showLabels="false"
                    :value="folderParentId"
                    v-model="parentId"
                />
            </div>
            <div class="w-100 mt-4">
                <form-input
                    label="New folder name"
                    :class="[{ 'form-input-danger' : errors.has('folder_name') }]"
                >
                    <input
                        type="text"
                        name="folder_name"
                        class="form-input-field"
                        placeholder="New folder name"
                        v-input-style="folderName"
                        v-model="folderName"
                        v-validate="'required|min:3|max:250'"
                    />
                </form-input>
            </div>
            <div class="w-100 mt-4">
                <div class="blue-box pv-8 ph-7 row-between mb-4">
                    <div class="s-align-4 mb-sm-1">
                        <span class="f-14-black f-sbold">Job</span>
                    </div>
                    <div class="sm-12 ls-sm-3 s-align-6">
                        <toggle-switch :class="isDisabledIsJob && 'disabled'">
                            <input
                                type="checkbox"
                                name="is_active_job"
                                v-model="isJob"
                                :disabled="isDisabledIsJob"
                            />
                        </toggle-switch>
                    </div>
                </div>
            </div>
            <div class="w-100 mt-4">
                <div class="blue-box pv-8 ph-7 row-between mb-4">
                    <div class="s-align-4 mb-sm-1">
                        <span class="f-14-black f-sbold">Lead</span>
                    </div>
                    <div class="sm-12 ls-sm-3 s-align-6">
                        <toggle-switch :class="isDisabledIsLead && 'disabled'">
                            <input
                                type="checkbox"
                                name="is_active_job"
                                v-model="isLead"
                                :disabled="isDisabledIsLead"
                            />
                        </toggle-switch>
                    </div>
                </div>
            </div>
            <div class="custom-modal-footer row-between">
                <button
                    class="add-button"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button
                    class="primary-button"
                    @click="save"
                    :disabled="requestIsGoing"
                >
                    Create
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import { FormInput, ToggleSwitch } from '@/ui';
    import { ToolsIcon } from '@/icons';

    export default {
        name: 'CreateNewDefaultFolderModal',
        components: {
            ToolsIcon,
            ToggleSwitch,
            FormInput,
            Multiselect
        },
        props: {
            folderParentId: {
                type: Number,
            }
        },
        data() {
            return {
                folderName: '',
                requestIsGoing: false,
                isLead: 1,
                isJob: 1,
                parentId: null,
                isDisabledIsLead: false,
                isDisabledIsJob: false
            }
        },
        computed: {
            folders() {
                return this.$store.getters['defaultFolder/getFolders'];
            }
        },
        methods: {
            save() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        const params = {
                            name: this.folderName,
                            is_lead: Number(this.isLead),
                            is_job: Number(this.isJob)
                        };

                        this.parentId ? params.parent_id = this.parentId.id : null;

                        this.requestIsGoing = true;
                        this.$store.dispatch('defaultFolder/SEND_NEW_FOLDER', params)
                            .then((data) => {
                                this.$emit('close');
                                this.notifySuccess('Folder has been created!');
                            })
                            .catch(error => {
                                this.notifyRequestErrors(error);
                            })
                            .finally(() => {
                                this.requestIsGoing = false;
                            });
                    } else {
                        this.notifyError('Fields are required.');
                    };
                });
            }
        },
        watch: {
            folderName(newValue, oldValue) {
                if (newValue.length) {
                    this.folderName = newValue.replace('/', '');
                };
            }
        },
        created() {
            if(this.folders && this.folderParentId !== null) {
                this.parentId = this.folders[this.folderParentId];
                this.isDisabledIsLead = Boolean(!this.folders[this.folderParentId].is_lead);
                this.isDisabledIsJob = Boolean(!this.folders[this.folderParentId].is_job);
                this.isLead = this.isDisabledIsLead ? 0 : 1;
                this.isJob = this.isDisabledIsJob ? 0 : 1;
            }
        }
    }
</script>
