<template>
    <div class="preview-order-content mb-30">
        <div class="s-align-4 sm-12 pt-8 pb-30 mb-sm-1 pl-20">
            <button
                class="transparent-button"
                @click="toOrdersPage"
            >
                <arrow-icon
                    class="mr-1 i-darkgrey"
                    transform="180"
                />
                <span class="f-12-grey">Back to Order Module</span>
            </button>
        </div>
        <div class="preview-order-content__header">
            <div class="lg-8 inline-flex align-center">
                <img
                    v-if="company.thumbnail !== null"
                    :src="`${url}${company.thumbnail}`"
                    class="preview-order-content__header-logo"
                    alt=""
                >
                <p class="f-14-black f-sbold preview-order-content__header-title">
                    {{ company.company_name }}
                </p>
            </div>
            <div class="lg-4 column-evenly-end s-align-8 pt-20 t-right">
                <p class="f-14-grey f-sbold mb-2">
                    Job #:
                    <span class="f-14-darkgrey2 f-normal pl-1">{{ jobNumber }}</span>
                </p>
                <p class="f-14-grey f-sbold mb-2">
                    Order #:
                    <span class="f-14-darkgrey2 f-normal pl-1">{{ order.full_order_number }}</span>
                </p>
                <p class="f-14-grey f-sbold">
                    Type:
                    <span class="f-14-darkgrey2 f-normal pl-1 capitalize">{{ order.type }}</span>
                </p>
            </div>
        </div>
        <div class="preview-order-content__card">
            <div
                v-if="titleSettings[0].active"
                class="preview-order-content__card-header f-16-white"
            >
                {{ order.name }}
            </div>
            <div class="preview-order-content__card-body">
                <p
                    v-if="submittedName"
                    class="f-14-grey f-sbold mb-1"
                >
                    Submitted:
                    <span
                        class="f-14-darkgrey2 f-normal"
                        :class="!submittedName.view && 'f-13-grey'"
                    >
                        {{ submittedName.value }}
                    </span>
                </p>
                <p class="f-14-grey f-sbold mb-1">
                    Requested delivery:
                    <span
                        v-if="requested"
                        class="f-14-darkgrey2 f-normal"
                        :class="requested.style"
                    >
                        {{ requested.value }},
                        {{ requested.method }}
                    </span>
                    <span
                        v-else
                        class="f-14-darkgrey2 f-normal"
                    >
                        Pickup
                    </span>
                </p>
                <p class="f-14-grey f-sbold mb-1">
                    Delivery time:
                    <span
                        class="f-14-darkgrey2 f-normal"
                        v-if="order.delivery_date"
                    >
                        {{ order.delivery_date | date(false) }},
                    </span>
                    <span class="f-14-darkgrey2 f-normal">
                        {{ order.delivery_time }}
                    </span>
                </p>
                <template v-if="orderType === LABOR">
                    <p class="f-14-grey f-sbold mt-1">
                        Scheduled labor address:
                        <span class="f-14-darkgrey2 f-normal">
                            {{ order.addresses.length ? getFullAddress(order.addresses[0]) : no_info }}
                        </span>
                    </p>
                    <p
                        class="f-14-black mt-1"
                    >
                        <span class="f-14-grey f-sbold">Scheduled labor time:</span>
                        <template v-if="isLaborDeliveryDatetime">
                            {{ order.labor_delivery_date_from | date }}
                            -
                            {{ order.labor_delivery_date_to | date }},
                            <span v-if="isLaborDeliveryTime">
                                from
                                {{ order.labor_delivery_time_from | onlyTime }}
                                to
                                {{ order.labor_delivery_time_to | onlyTime }}
                            </span>
                            <span v-else>All Day</span>

                        </template>
                        <span
                            class="f-italic f-14-grey"
                            v-else
                        >
                            {{ no_info }}
                        </span>
                    </p>
                </template>
                <template v-if="orderType === MATERIAL && order.vendor">
                    <p class="f-14-grey f-sbold mb-1">
                        Vendor:
                        <span class="f-14-darkgrey2 f-normal">
                            {{ order.vendor.name }}
                        </span>
                    </p>
                    <p
                        class="f-14-grey f-sbold mb-1"
                        v-if="order.vendor.email"
                    >
                        Vendor email:
                        <span class="f-14-darkgrey2 f-normal">
                            {{ order.vendor.email }}
                        </span>
                    </p>
                    <p
                        class="f-14-grey f-sbold mb-1"
                        v-if="order.vendor.address"
                    >
                        Vendor address:
                        <span class="f-14-darkgrey2 f-normal">
                            {{ getFullAddress(order.vendor.address) }}
                        </span>
                    </p>
                </template>
            </div>
        </div>
        <div class="row-between">
            <div
                class="sm-12"
                :class="`lg-${widthCard}`"
                v-if="customerDetails"
            >
                <div class="preview-order-content__card w-100">
                    <div class="preview-order-content__card-header f-16-white">
                        Customer Details
                    </div>
                    <div class="preview-order-content__card-body">
                        <p class="f-16-grey f-sbold pb-1" v-for="(key, value) in customerDetails" :key="value">
                            {{ key.label }}:
                            <span
                                v-if="key.label.toLowerCase() === CUSTOM_FIELDS_TYPES.PHONE"
                                class="f-16-darkgrey2 pl-1"
                                :class="key.value !== no_info ? 'f-16-darkgrey2' : 'f-italic f-16-grey'"
                            >
                                {{ key.value | phone }}
                            </span>
                            <span
                                v-else
                                class="f-16-darkgrey2 pl-1"
                                :class="key.value !== no_info ? 'f-16-darkgrey2' : 'f-italic f-16-grey'"
                            >
                                {{ key.value }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div
                class="sm-12"
                :class="`lg-${widthCard}`"
                v-if="production"
            >
                <div class="preview-order-content__card w-100">
                    <div
                        class="preview-order-content__card-header f-16-white production-header"
                        :class="widthCard != 12 ? 'bl-grey' : ''"
                    >
                        Production
                    </div>
                    <div class="preview-order-content__card-body pl-40">
                        <p class="f-16-grey f-sbold pb-1" v-for="(key, value) in production" :key="value">
                            {{ key.label }}:
                            <span
                                v-if="key.label.toLowerCase()  === CUSTOM_FIELDS_TYPES.PHONE"
                                class="f-16-darkgrey2 pl-1"
                                :class="key.value !== no_info ? 'f-16-darkgrey2' : 'f-italic f-16-grey'"
                            >
                                {{ key.value | phone }}
                            </span>
                            <span
                                v-else
                                class="f-16-darkgrey2 pl-1"
                                :class="key.value !== no_info ? 'f-16-darkgrey2' : 'f-italic f-16-grey'"
                            >
                                {{ key.value }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <table class="preview-order-content__table">
            <thead class="preview-order-content__table-header">
            <tr>
                <td>Name</td>
                <td class="bl-grey">QTY</td>
                <td class="bl-grey">Unit</td>
                <td class="bl-grey" v-if="pageSettings[3].active">Price</td>
            </tr>
            </thead>
            <tbody class="preview-order-content__table-body">
            <tr v-if="!order.items.length">
                <td colspan="4"/>
            </tr>
            <tr
                v-else
                v-for="(line, index) in order.items"
                :key="`line-${index}`"
                v-show="orderType == line.type_id"
            >
                <td
                    valign="top"
                >
                    <p>{{ line.name }}</p>
                    <p
                        class="w-100 f-10-grey pt-1"
                        v-if="line.description"
                    >
                        {{ line.description }}
                    </p>
                </td>
                <td
                    valign="top"
                >
                    <p>{{ line.quantity }}</p>
                </td>
                <td
                    valign="top"
                >
                    <p>{{ line.per_unit }}</p>
                </td>
                <td
                    valign="top"
                    v-if="pageSettings[3].active"
                >
                    <p class="column-start w-100">
                        {{ (line.cost * line.quantity) | currency }}
                        <span
                            class="f-12-grey"
                            v-if="pageSettings[3].active && pageSettings[4].active"
                        >
                            {{ line.cost | currency }}/{{ line.per_unit }}
                        </span>
                    </p>
                </td>
            </tr>
            </tbody>
        </table>
        <div
            class="lg-11 column-evenly-end s-align-8 pt-20 t-right"
            v-if="pageSettings[5].active"
        >
            <p class="preview-order-content__table-total">
                <span class="mr-6">{{total.label }}</span>
                {{ total.value | currency }}
            </p>
        </div>
        <div class="preview-order-content__footer">
            <div class="preview-order-content__footer-block">
                <p class="f-16-darkgrey2 f-sbold">{{ company.company_name }}</p>
                <p class="f-16-grey f-sbold">{{ company.full_address }}</p>
                <p class="f-16-grey f-sbold">tel: {{ company.phone }}, fax: {{ company.fax }}</p>
                <a v-if="company.url" :href="company.url" target="_blank">{{ company.url }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import {ArrowIcon} from '@/icons';
    import {showFullAddress} from '@/helpers';
    import {ORDER_RECEIVING_STATUS, LABOR, MATERIAL, CUSTOM_FIELDS_TYPES} from "@/constants";

    export default {
        name: 'OrderPdf',
        components: {
            ArrowIcon
        },
        data() {
            return {
                no_info: 'No info',
                url: process.env.VUE_APP_AMAZONAWS_URL,
                LABOR: LABOR,
                MATERIAL: MATERIAL,
                CUSTOM_FIELDS_TYPES
            }
        },
        props: {
            company: {
                type: Object,
                default: {}
            },
            order: {
                type: Object,
                default: {}
            },
            jobNumber: {
                type: Number | String,
                default: '-'
            },
            orderType: {
                type: Number,
                required: false
            },
            labor: {
                type: Number,
                required: false
            },
            material: {
                type: Number,
                required: false
            },
            pageSettings: {
                type: Array,
                required: true
                // Example props
                // { name: 'Production Contact', active: true, isOnPage: 'both' },
                // { name: 'Company Rep', active: true, isOnPage: 'residential' },
                // { name: 'Show Price', active: true, isOnPage: 'both' },
                // { name: 'Show Unit Price', active: true, isOnPage: 'both' },
                // { name: 'Submitted', active: true, isOnPage: 'both' }
            },
            isCommercial: {
                type: Boolean,
                default: false
            },
            customerSettings: {
                type: Array,
                default: []
                //Example props
                //{ name: 'Customer', active: true }
                //...
            },
            titleSettings: {
                type: Array,
                default: []
            },
            selectedResp: {
                type: Object,
                default: {}
            },
            isLaborDeliveryDatetime: {
                type: String,
                default: ''
            },
            isLaborDeliveryTime: {
                type: String,
                default: ''
            },
            isWarrantyJobWithResidential: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            submittedName() {
                const objSub = {
                    value: '',
                    view: false
                };
                const order = this.order;
                const active = this.pageSettings[0].active;
                if (this.orderType == this.material && active) {
                    objSub.value = order.vendor && order.vendor.name ? order.vendor.name : this.no_info;
                    objSub.view = order.vendor && order.vendor.name;
                    return objSub;
                }
                if (this.orderType == this.labor && active) {
                    objSub.value = order.crew && order.crew.name ? order.crew.name : this.no_info;
                    objSub.view = order.crew && order.crew.name;
                    return objSub;
                }
                return null;
            },
            requested() {
                const order = this.order;
                let value = null;
                if (order.receiving === ORDER_RECEIVING_STATUS.DELIVERY) {
                    value = {
                        style: !order.addresses.length ? 'f-italic f-13-grey' : '',
                        value: order.addresses.length ? showFullAddress(order.addresses[0]) : this.no_info,
                        method: order.delivery_method
                    }
                }
                return value;
            },
            customerDetails() {
                const objCustomerDet = {
                    name: {
                        label: 'Name',
                        value: '',
                    },
                    phone: {
                        label: 'Phone',
                        value: ''
                    },
                    email: {
                        label: 'Email',
                        value: ''
                    },
                    address: {
                        label: 'Address',
                        value: ''
                    }
                };
                const selectResp = this.selectedResp;
                const order = this.order;
                const active = this.customerSettings[0].active;
                if (this.isCommercial) {
                    if (selectResp) {
                        objCustomerDet.name.value = `${selectResp.first_name} ${selectResp.last_name}`;
                        objCustomerDet.phone.value = selectResp.phones.length ? selectResp.phones[0].number : this.no_info;
                        objCustomerDet.email.value = selectResp.emails.length ? selectResp.emails[0].email : this.no_info;
                        objCustomerDet.address.value = order.entity.full_address ? order.entity.full_address : this.no_info;
                    }
                } else if (!this.isCommercial) {
                    const data = this.isWarrantyJobWithResidential ? order.entity.customer : order.entity;
                    objCustomerDet.name.value = order.entity ?
                        `${data.first_name} ${data.last_name}` : this.no_info;
                    objCustomerDet.phone.value = data.tel_1 ? data.tel_1 : this.no_info;
                    objCustomerDet.email.value = data.email_1 ? data.email_1 : this.no_info;
                    if (this.isWarrantyJobWithResidential){
                        objCustomerDet.address.value = data.full_address || this.no_info;
                    }else{
                        objCustomerDet.address.value = data.address ? showFullAddress(data) : this.no_info;
                    }
                }
                return active ? objCustomerDet : null;
            },
            production() {
                const order = this.order;
                const active = this.pageSettings[1].active;
                const objProd = {
                    name: {
                        label: 'Name',
                        value: '',
                    },
                    phone: {
                        label: 'Phone',
                        value: ''
                    },
                    email: {
                        label: 'Email',
                        value: ''
                    }
                };
                if (active) {
                    objProd.name.value = order.assign ? order.assign.fullname : this.no_info;
                    objProd.phone.value = order.assign ? order.assign.phone : this.no_info;
                    objProd.email.value = order.assign ? order.assign.email : this.no_info;
                }
                return active ? objProd : null;
            },
            total() {
                const objTotal = {
                    label: 'Total:',
                    value: 0
                };
                if (this.orderType == this.material) {
                    objTotal.value = this.order.material_total;
                } else {
                    objTotal.value = this.order.labor_total;
                }
                return objTotal;
            },
            widthCard() {
                let widthCard = '12';
                if (this.production && this.customerDetails) {
                    widthCard = '6'
                }
                return widthCard;
            }
        },
        methods: {
            toOrdersPage() {
                this.$emit('toOrdersPage');
            },
            getFullAddress(address) {
                return showFullAddress(address);
            },
        }
    }
</script>
