<template>
    <div class="preview-page-signature">
        <div class="s-align-4 pb-8">
            <span class="f-20-grey f-sbold">
                {{ signatures.length > 1 ? 'Signatures' : 'Signature'}}
            </span>
        </div>
        <div 
            class="w-100" 
            v-for="(option, index) in signatures"
            :key="`option-${index}`"
        >
            <div class="w-100">
                <div
                    :class="[
                        'w-100 pt-4 pb-5 bb-grey row-baseline',
                        option.hasInitialSignature ? 'row-between' : 'row-end'
                    ]"
                >
                    <span
                        class="f-13-grey"
                        v-if="option.hasInitialSignature && option.label === E_SIGNATURE"
                    >
                        {{ option.initialName }}
                    </span>
                    <button
                        class="primary-button sign-here"
                        v-if="option.label === E_SIGNATURE"
                        @click="$emit('sign')"
                        disabled
                    >
                        <sign-icon class="mr-1"/>
                        <span>Sign Here</span>
                    </button>
                    <div
                        v-if="option.label === PAPER_SIGNATURE"
                        class="lg-3 md-6 sm-7"
                        :key="`option-signature-${index}`"
                    >
                        <form-datepicker
                            v-model="option.dateValue"
                            :isFullWidth="true"
                            keyDatePicker="date_of_request_date_picker"
                            placeholder="Select date"
                            :name="`estimate_date_${index}`"
                        />
                        <button
                            v-if="option.dateValue"
                            class="icon-light ml-2"
                            @click="removeDateValue(option.signature)"
                        >
                            <trash-icon/>
                        </button>
                    </div>
                </div>
                <div class="row-between pb-8 pt-3">
                    <span class="f-13-grey">
                        {{ option.signature ? option.signature : 'Signature ' }}
                        {{ option.signature ? '' : index + 1 }}
                    </span>
                    <span
                        v-if="option.date"
                        class="f-13-grey"
                    >
                        Date
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { SignIcon, TrashIcon } from '@/icons';
    import { FormDatepicker } from '@/ui';

    const E_SIGNATURE = 'E-signature';
    const PAPER_SIGNATURE = 'Paper signature';

    export default {
        name:'EstimatePreviewSignaturePanel',
        components: {
            SignIcon,
            TrashIcon,
            FormDatepicker
        },
        props: {
            signatures: {
                type: Array
            }
        },
        data() {
            return {
                E_SIGNATURE: E_SIGNATURE,
                PAPER_SIGNATURE: PAPER_SIGNATURE
            }
        },
        methods: {
            removeDateValue(signatureName) {
                this.$emit('remove', signatureName)
            }
        }
    }
</script>