<template>
    <svg
        viewBox="-387.6 272.5 17.02 17"
        :width="size" 
        :height="size"
        class="settings-icon"
    >
        <g>
            <path 
                class="st0" 
                d="M-385.67,275.71h0.31c0.35,0.95,1.3,1.6,2.35,1.6s2-0.65,2.35-1.6h8.32c0.46,0,0.83-0.36,0.83-0.8s-0.37-0.8-0.83-0.8h-8.32c-0.35-0.95-1.3-1.6-2.35-1.6c-1.05,0-2,0.65-2.35,1.6h-0.31c-0.46,0-0.83,0.36-0.83,0.8S-386.13,275.71-385.67,275.71z"
            />
            <path 
                class="st0" 
                d="M-372.33,286.29h-5.32c-0.35-0.95-1.3-1.6-2.35-1.6s-2,0.65-2.35,1.6h-3.31c-0.46,0-0.83,0.36-0.83,0.81c0,0.44,0.37,0.8,0.83,0.8h3.31c0.35,0.95,1.3,1.6,2.35,1.6c1.05,0,2-0.65,2.35-1.6h5.32c0.46,0,0.83-0.36,0.83-0.8C-371.5,286.65-371.87,286.29-372.33,286.29z"
            />
            <path 
                class="st0" 
                d="M-372.33,280.2h-0.31c-0.35-0.95-1.3-1.6-2.35-1.6c-1.05,0-2,0.65-2.35,1.6h-8.32c-0.46,0-0.83,0.36-0.83,0.8s0.37,0.8,0.83,0.8h8.32c0.35,0.95,1.3,1.6,2.35,1.6c1.05,0,2-0.65,2.35-1.6h0.31c0.46,0,0.83-0.36,0.83-0.8S-371.87,280.2-372.33,280.2z"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'SettingsIcon',
        props: {
            size: {
                type: [Number, String],
                default: 17
            }
        }
    }
</script>