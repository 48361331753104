<template>
    <svg
        viewBox="0 0 8 10"
        :width="size"
        :height="size"
        class="estimate-icon"
    >
        <path d="M6.81279 0H5.49772H5.47945H1.18721C0.52968 0 0 0.549242 0 1.23106V8.76894C0 9.45076 0.52968 10 1.18721 10H6.81279C7.47032 10 8 9.45076 8 8.76894V7.53788C8 7.53788 8 7.27272 8 6.59091V1.23106C8 0.549242 7.47032 0 6.81279 0ZM2.79452 8.29545H1.55251V7.34848H2.79452V8.29545ZM2.79452 6.40151H1.55251V5.45454H2.79452V6.40151ZM2.79452 4.50757H1.55251V3.56061H2.79452V4.50757ZM4.621 8.29545H3.379V7.34848H4.621V8.29545ZM4.621 6.40151H3.379V5.45454H4.621V6.40151ZM4.621 4.50757H3.379V3.56061H4.621V4.50757ZM6.44749 8.29545H5.20548V7.34848H6.44749V8.29545ZM6.44749 6.40151H5.20548V5.45454H6.44749V6.40151ZM6.44749 4.50757H5.20548V3.56061H6.44749V4.50757ZM6.44749 2.61364H1.55251V1.66667H6.44749V2.61364Z"/>
    </svg>
</template>

<script>
    export default {
        name: 'EstimateIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>
