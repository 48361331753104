<template>
    <div class="form-builder-custom-section bt-grey">
        <form-builder-custom-fields
            :layoutId="page.id"
            :updateCustomFields="updateCustomFields"
            :entityType="entityType"
            @changeFields="changeCustomFields"
            :clear="clear"
        />
    </div>
</template>

<script>
    import FormBuilderCustomFields from '@/components/LeadModules/FormBuilder/FormBuilderCustomFields.vue';
    
    const REPORT_TYPE = 'custom';
    const FORM_ACTION_INIT = 'init';
    const FORM_ACTION_CLEAR = 'clear';
    
    export default {
        name: 'FormBuilderCustomReport',
        data() {
            return {
                report: {},
                descriptionOptions: [],
                updateCustomFields: {}
            }
        },
        props: {
            clear: {
                type: Boolean,
                default: false
            },
            get: {
                type: Boolean,
                default: false
            },
            selected: {
                type: Object,
                required: false
            },
            page: {
                type: Object,
                required: false
            },
            entityType: {
                type: String,
                required: true
            }
        },
        watch: {
            clear: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal) this.createNewReport(FORM_ACTION_CLEAR);
                }
            },
            get: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal) this.$emit('receive', this.report);
                }
            },
            selected: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal != null && newVal != oldVal)
                        this.setReport();
                }
            }
        },
        methods: {
            createNewReport(flag) {
                this.report = {
                    title: this.page.name,
                    reportType: REPORT_TYPE,
                    reportNumber: this.$route.params.lead_id,
                    customFields: {}
                }

                if (flag == FORM_ACTION_CLEAR && this.furtherActionRequired)
                    document.getElementById('further-action-textarea').style.height = 'auto';
            },
            getAddress(data) {
                this.report.address = data;
            },
            setReport() {
                this.report = this.selected;
                this.updateCustomFields = this.selected.customFields;
            },
            changeCustomFields(data) {
                this.report.customFields = data;
            }
        },
        created() {
            this.createNewReport(FORM_ACTION_INIT);
        },
        components: {
            FormBuilderCustomFields
        },
    }
</script>