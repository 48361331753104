<template>
    <div :class="[
        'w-box w-100 stretch column-start in-dev',
        { 'in-dev' : !loading },
        { 'tags-mock-loading' : loading }
    ]">
        <div 
            v-if="!loading" 
            class="row-between pb-8"
        >
            <p class="f-15-darkgrey f-sbold">Tags</p>
            <button class="icon-light">
                <edit-icon/>
            </button>
        </div>
        <div class="row-center">
            <tag
                v-show="!loading"
                v-for="(tag, index) in tags"
                :key="`tag-${index}`"
                :title="tag"
                class="mr-2 mb-2"
            />
        </div>
    </div>
</template>

<script>
    import { Tag } from '@/components';
    import { EditIcon } from '@/icons';

    export default {
        name: 'TagBox',
        props: {
            tags: {
                type: Array,
                default: function() {
                    return [];
                }
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        components: {
            Tag,
            EditIcon
        }
    }
</script>