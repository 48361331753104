import {COMPLETE_HANDLER} from "../actions/chooseNextStepWarranty";
import api from "@/headers";
import {TYPE_COMPLETE} from "@/constants";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        [COMPLETE_HANDLER]({ commit }, data) {
            const {caseId, activeStepId} = data;
            return new Promise((resolve, reject) => {
                const data = {
                    complete_type: activeStepId
                }

                if (activeStepId !== TYPE_COMPLETE.new_job) {
                    api.post(`/warranty-cases/${caseId}/complete`, data)
                        .then(res => resolve(res.data.data))
                        .catch(error => {
                            reject(error);
                        });
                }

                resolve();
            });
        }
    }
}