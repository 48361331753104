<template>
    <div class="milestone-history-list w-100">
        <loader
            v-if="loading"
            size="small"
        />
        <div
            v-else 
            class="history-item w-100"
            v-for="(item, index) in milestonesHistory"
            :key="`milestone-history-item-${index}`"
        >
            <div class="row-start row-baseline">
                <status-marker
                    :marker="item.milestoneToTitle"
                    :color="item.milestoneToColor"
                />
                <div class="milestone-history-content pl-8">
                    <div class="row-between row-baseline-start">
                        <div class="lg-8 sm-12 column-start">
                            <p class="f-14-asphalt f-sbold">
                                {{ item.updates }}, selected date {{ item.started_at | monthDayYear }}
                            </p>
                            <p class="f-12-grey">
                                Changed by:
                                <span class="f-12-black pl-1">
                                    {{ item.author }}
                                </span>
                            </p>
                        </div>
                        <div :class="['lg-4 sm-12', mScreen ? 's-align-4' : 's-align-6']">
                            <div
                                class="f-12-grey f-sbold"
                                v-if="item.started_at"
                            >
                                <p>Change date: </p>
                                {{ item.created_at | createdAt }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="item.reason"
                class="s-align-6 pl-8 pt-1"
            >
                <div class="milestone-history-content column-start">
                    <p class="f-12-grey">
                        Reason: 
                        <span class="pl-1">{{ item.reason }}</span>
                    </p>
                    <p 
                        class="f-12-grey"
                        v-if="item.extra_reason"
                    >
                        Comment:
                        <span class="pl-1 break-word preserve-whitespaces">{{ item.extra_reason }}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { BASE_ENTITY_TYPES, ENTITY_TYPES } from '@/constants';
    import StatusMarker from '@/components/StatusMarker';
    import { Loader } from '@/ui';

    export default {
        name: 'MilestonesLog',
        components: {
            StatusMarker,
            Loader
        },
        props: {
            entityType: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                loading: false
            }
        },
        computed: {
            milestonesHistory() {
                return this.$store.getters['milestones/getMilestonesStatistics'].reverse();
            },
            milestones() {
                return this.$store.getters['milestones/getMilestones'];
            },
            entityId() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD:
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.$route.params.lead_id;
                    case ENTITY_TYPES.WARRANTY_JOB:
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.$route.params.job_id;
                }
            },
            baseEntityType() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD:
                        return BASE_ENTITY_TYPES.RESIDENTIAL;
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return BASE_ENTITY_TYPES.COMMERCIAL_LEAD;
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return BASE_ENTITY_TYPES.COMMERCIAL_JOB;
                    case ENTITY_TYPES.WARRANTY_JOB:
                        return BASE_ENTITY_TYPES.WARRANTY_JOB
                }
            }
        },
        methods: {
            getLog() {
                this.loading = true;
                this.$store.dispatch('milestones/GET_MILESTONES_STATISTICS', {
                    id: this.entityId,
                    entityType: this.baseEntityType
                })
                .catch(error => this.notifyRequestErrors(error))
                .finally(() => this.loading = false );
            }
        },
        created() {
            this.getLog();
        }
    }
</script>