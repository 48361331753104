<template>
    <div class="w-100 pt-4">
        <button 
            class="s-align-4 transparent-button"
            @click="tableIsOpen = !tableIsOpen"
        >
            <arrow-icon 
                class="i-darkgrey mr-4"
                size="6"
                :transform="tableIsOpen ? 270 : 90"
            />
            <span class="f-14-asphalt f-sbold">Leads</span>
            <span 
                class="f-14-grey pl-1"
                v-if="option.length >= 1"
            >
                {{ `(${filteredLeads.length})` }}
            </span>
        </button>
        <div 
            class="w-100" 
            v-if="tableIsOpen"
        >
            <div 
                class="row-start f-12-black f-sbold pb-15 mt-5"
                v-if="dScreen || nScreen"
            >
                <div
                    v-if="showMilestone"
                    :class="[
                        selectMode ? 'lg-2 sm-12 s-align-9' : 'lg-1 s-align-4'
                    ]"
                >
                    <button 
                        class="transparent-button"
                        @click="changeSort(SORT_BY.MILESTONE)"
                    >
                        <move-icon 
                            transform="90" 
                            :class="[
                                'order-by-icon', 
                                sortBy === SORT_BY.MILESTONE ? `order-${orderBy}` : ''
                            ]"
                        />
                        <span class="pl-1 f-sbold f-9-grey uppercase">
                            Milestone
                        </span>
                    </button>
                </div>
                <div 
                    :class="[
                        selectMode && !showMilestone ? 'lg-3 s-align-5' : '',
                        selectMode && showMilestone ? 'lg-2 pl-7' : 'lg-3 pl-7'
                    ]">
                    <button 
                        class="transparent-button"
                        @click="changeSort(SORT_BY.REQUESTED_BY)"
                    >
                        <move-icon 
                            transform="90" 
                            :class="[
                                'order-by-icon', 
                                sortBy === SORT_BY.REQUESTED_BY ? `order-${orderBy}` : ''
                            ]"
                        />
                        <span class="pl-1 f-sbold f-9-grey uppercase">
                            Requested By
                        </span>
                    </button>
                </div>
                <div class="lg-2">
                    <button 
                        class="transparent-button"
                        @click="changeSort(SORT_BY.DATE)"
                    >
                        <move-icon 
                            transform="90" 
                            :class="[
                                'order-by-icon', 
                                sortBy === SORT_BY.DATE ? `order-${orderBy}` : ''
                            ]"
                        />
                        <span class="pl-1 f-sbold f-9-grey uppercase">
                            Date
                        </span>
                    </button>
                </div>
                <div class="sm-12 s-align-5 lg-5">
                    <span class="pl-1 f-sbold f-9-grey uppercase">
                        Lead address
                    </span>
                </div>
            </div>
            <div 
                class="row-start row-baseline bb-grey pv-7"
                v-for="(lead, index) in filteredLeads"
                :key="`lead-${index}`"
            >
                <div
                    v-if="selectMode" 
                    class="lg-1 sm-1 s-align-5"
                >
                    <checkbox-button color="blue">
                        <input
                            type="checkbox"
                            :checked="selectedEntities.some(id => id == lead.id)"
                            @change="e => $emit('onSelect', e.srcElement.checked, lead.id)"
                        >
                    </checkbox-button>
                </div>
                <div
                    v-if="showMilestone"
                    :class="[
                        'lg-1',
                        mScreen ? 'mb-2' : 's-align-5',
                        selectMode ? 'sm-11' : 'sm-12'
                    ]"
                >
                    <div class="milestone-content column-center t-center">
                        <div class="milestone-icon s-align-5">
                            <status-marker
                                :marker="!lead.m_title ? '' : lead.m_title"
                                :color="lead.m_color"
                            />
                        </div>
                    </div>
                </div>
                <div
                    :class="[
                        'pl-7',
                        selectMode ? 'sm-11' : 'sm-12',
                        selectMode && showMilestone ? 'lg-2 ' : 'lg-3'
                    ]"
                >
                    <span
                        v-if="mScreen"
                        class="f-13-grey pr-2"
                    >
                        Requested by:
                    </span>
                    <span class="f-13-black">
                        {{ `${lead.requested_by.first_name} ${lead.requested_by.last_name}` }}
                    </span>
                </div>
                <div class="lg-2 sm-12">
                    <span
                        v-if="mScreen"
                        class="f-13-grey pr-2"
                    >
                        Lead date:
                    </span>
                    <span class="f-13-black">{{ lead.created_at | date }}</span>
                </div>
                <div 
                    :class="[
                        'sm-12 lg-5',
                        !mScreen ? 's-align-5' : ''
                    ]"
                >
                    <span
                        v-if="mScreen"
                        class="f-13-grey pr-2"
                    >
                        Lead address:
                    </span>
                    <span class="f-13-black">{{ getLeadFullAddress(lead) }}</span>
                </div>
                <div class="lg-1 sm-12">
                    <div class="s-align-6 w-100">
                        <router-link :to="{ name: 'CommercialLeadOverview', params: { lead_id: lead.id }}">
                            <span class="f-13-blue f-sbold">To Lead</span>
                            <arrow-icon 
                                transform="0" 
                                class="i-blue ml-2"
                            />
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { CheckboxButton } from '@/ui';
    import { ArrowIcon, MoveIcon } from '@/icons';
    import { StatusMarker } from '@/components';
    import orderBy from 'lodash/orderBy';
    import moment from 'moment/src/moment';

    export default {
        name: 'CommercialLeadsTable',
        props: {
            option: {
                type: Array,
                default: () => []
            },
            selectMode: {
                type: Boolean,
                default: false
            },
            selectedEntities: {
                type: Array,
                default: () => []
            },
            filterParams: {
                type: Object,
                default: () => {}
            },
            showMilestone: {
                type: Boolean,
                default: false
            }
        },
        components: {
            ArrowIcon,
            MoveIcon,
            CheckboxButton,
            StatusMarker
        },
        data() {
            return {
                tableIsOpen: true,
                SORT_BY: {
                    REQUESTED_BY: 'requested_by.first_name',
                    DATE: 'created_at'
                },
                ORDER_BY: {
                    ASC: 'asc',
                    DESC: 'desc'
                },
                orderBy: 'asc',
                sortBy: 'job_number'
            }
        },
        computed: {
            filteredLeads() {
                let leads = this.option;

                if (this.filterParams.date.length 
                    && this.filterParams.date[0] 
                    && this.filterParams.date[1]) {
                    let filtering = leads.filter(lead => {
                        let localTime = moment.utc(lead.created_at).local();
                        return localTime.isSameOrAfter(moment(this.filterParams.date[0]), 'day')
                                && localTime.isSameOrBefore(moment(this.filterParams.date[1]), 'day');
                    });
                    leads = [...filtering];
                }

                if (this.sortBy === this.SORT_BY.REQUESTED_BY) {
                    return orderBy(leads, this.SORT_BY.REQUESTED_BY, this.orderBy);
                } else if (this.sortBy === this.SORT_BY.DATE) {
                    return orderBy(leads, this.SORT_BY.DATE, this.orderBy);
                }

                return leads;
            }
        },
        methods: {
            getLeadFullAddress(lead) {
                return `${lead.address} ${lead.street}, ${lead.city}, ${lead.state} ${lead.zip}`;
            },
            changeSort(sort_by) {
                if (this.sortBy === sort_by) {
                    this.orderBy = this.orderBy === this.ORDER_BY.ASC ? this.ORDER_BY.DESC : this.ORDER_BY.ASC;
                } else {
                    this.sortBy = sort_by;
                    this.orderBy = this.ORDER_BY.ASC;
                }
            }
        }
    }
</script>