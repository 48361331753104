<template>
    <div
        class="l-filter column-between-start"
        v-scroll="scrollListener"
    >
        <div class="l-filter-search row-start s-align-4">
            <filter-icon-center/>
            <span class="f-14-black ml-3 f-normal">Filter</span>
        </div>
        <perfect-scrollbar
            id="container-filters"
            class="ps-grey flex-grow-1"
        >
            <lead-filter-section
                :count-elements="customers && customers.length"
                :class="{ hide: checkFilterVisibility('customers') }"
                keyFilter="customers"
            >
                <span slot="filter-head">Customer name</span>
                <div
                    slot="filter-list"
                    class="row-between"
                    v-for="(item, index) in customers"
                    :key="index"
                >
                    <div class="s-align-4 w-85">
                        <checkbox-button
                            color="yellow"
                            :label="item.title"
                            class="f-13-black f-sbold w-100"
                        >
                            <input
                                type="checkbox"
                                :name="'customers' + index"
                                :id="'customers' + index"
                                v-model="filters.customers"
                                :value="item.title"
                            />
                        </checkbox-button>
                    </div>
                    <div class="s-align-6">
                        <span class="f-12-grey">
                            {{ item.count }}
                        </span>
                    </div>
                </div>
            </lead-filter-section>
            <lead-filter-section
                :count-elements="salesman && salesman.length"
                :class="{ hide: checkFilterVisibility('Salesman') }"
                keyFilter="salesman"
            >
                <span slot="filter-head">Salesman</span>
                <div
                    slot="filter-list"
                    class="row-between"
                    v-for="(item, index) in salesman"
                    :key="index"
                >
                    <div class="s-align-4 w-85">
                        <checkbox-button
                            color="violet"
                            :label="item.fullname"
                            class="f-13-black f-sbold w-100"
                        >
                            <input
                                type="checkbox"
                                name="salesman"
                                :id="'salesman' + index"
                                v-model="filters.salesman"
                                :value="item.fullname"
                            />
                        </checkbox-button>
                    </div>
                    <div class="s-align-6">
                        <span class="f-12-grey">
                            {{ item.count }}
                        </span>
                    </div>
                </div>
            </lead-filter-section>
            <lead-filter-section
                :count-elements="customerNumbers && customerNumbers.length"
                :class="[
                    'l-filter-priority',
                    { hide: checkFilterVisibility('Customer number') }
                ]"
                keyFilter="customer-number"
            >
                <span slot="filter-head">Customer number</span>
                <div
                    slot="filter-list"
                    class="row-between"
                    v-for="(item, index) in customerNumbers"
                    :key="index"
                >
                    <priority-checkbox
                        :priorityColor="item.color_code"
                        :priority="item.name"
                        :id="item.name"
                        :filters="filters.customerNumbers"
                        class="pointer"
                    >
                        <input
                            type="checkbox"
                            name="customer-number"
                            :id="'customer-number' + index"
                            v-model="filters.customerNumbers"
                            :value="item.name"
                        />
                    </priority-checkbox>
                    <div class="s-align-6">
                        <span class="f-12-grey">
                            {{ item.count }}
                        </span>
                    </div>
                </div>
            </lead-filter-section>
            <lead-filter-section
                :count-elements="dates && dates.length"
                :class="{ hide: checkFilterVisibility('Date and time') }"
                keyFilter="date-and-time"
            >
                <span slot="filter-head">Date and time</span>
                <div
                    slot="filter-list"
                    class="row-between"
                    v-for="(item, index) in dates"
                    :key="index"
                >
                    <div class="s-align-4 w-85">
                        <checkbox-button
                            color="green"
                            :label="item.name"
                            class="f-13-black f-sbold w-100"
                        >
                            <input
                                type="checkbox"
                                name="date-and-time"
                                :id="'date-and-time' + index"
                                v-model="filters.dates"
                                :value="item.name"
                            />
                        </checkbox-button>
                    </div>
                    <div class="s-align-6">
                        <span class="f-12-grey">
                            {{ item.count }}
                        </span>
                    </div>
                </div>
            </lead-filter-section>
            <lead-filter-section
                :count-elements="callDurations && callDurations.length"
                :class="{ hide: checkFilterVisibility('Call duration') }"
                keyFilter="call-durations"
            >
                <span slot="filter-head">Call duration</span>
                <div
                    slot="filter-list"
                    class="row-between"
                    v-for="(item, index) in callDurations"
                    :key="index"
                >
                    <div class="s-align-4 w-85">
                        <checkbox-button
                            color="green"
                            :label="item.property_name"
                            class="f-13-black f-sbold w-100"
                        >
                            <input
                                type="checkbox"
                                name="call-durations"
                                :id="'call-durations' + index"
                                v-model="filters.callDurations"
                                :value="item.property_name"
                            />
                        </checkbox-button>
                    </div>
                    <div class="s-align-6">
                        <span class="f-12-grey">
                            {{ item.count }}
                        </span>
                    </div>
                </div>
            </lead-filter-section>
        </perfect-scrollbar>
        <div class="w-100 l-filter-expand-all">
            <checkbox-button
                class="ph-20 pv-15 s-align-1 w-100 f-12-black"
                color="grey"
                label="Expand all items"
            >
                <input
                    type="checkbox"
                    name="expand_all"
                    id="expand_all"
                    v-model="isExpandAll"
                    @change="checkExpandAll"
                />
            </checkbox-button>
        </div>
    </div>
</template>

<script>
    import { SearchIcon, ArrowIcon, FilterIconCenter } from '@/icons';
    import { CheckboxButton, PriorityCheckbox } from '@/ui';
    import { LeadFilterSection } from '@/components';
    
    export default {
        name: 'CallLogFilter',
        props: [],
        components: {
            SearchIcon,
            ArrowIcon,
            CheckboxButton,
            LeadFilterSection,
            PriorityCheckbox,
            FilterIconCenter
        },
        data() {
            return {
                salesman: [],
                customers: [],
                customerNumbers: [],
                dates: [],
                callDurations: [],
                filters: {
                    salesman: [],
                    customerNumbers: [],
                    customers: [],
                    dates: [],
                    callDurations: []
                },
                filtersInput: '',
                isExpandAll: true
            }
        },
        methods: {
            checkExpandAll() {
                if (!this.isExpandAll) {
                    document.getElementById('container-filters').scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }
            },
            checkFilterVisibility(filterName) {
                return !filterName.toLowerCase().includes(this.filtersInput.toLowerCase());
            },
            scrollListener: function (event, el) {
                if (window.innerWidth > 1165) {
                    window.scrollY > 50
                        ? el.style.paddingTop = 70 + 'px'
                        : el.style.paddingTop = 150 + 'px';
                } else {
                    return false;
                }
            },
        }
    }
</script>
