<template>
    <svg 
        viewBox="0 0 370.89288330078125 295.825927734375"
        :width="width" 
        :height="height"
        class="next-icon"
        :style="{ transform: 'rotate('+ transform + 'deg)' }"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="370.89288330078125" 
            height="295.825927734375" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(8.166666984558105, 0, 0, 8.166666984558105, 3509.0832867622375, -2204.408935546875)">
            <g>
	            <path d="M-406.4,294.2h-22.4c-0.4,0-0.7-0.3-0.7-0.7v-10.9c0-0.4,0.3-0.7,0.7-0.7h22.4v-11c0-0.1,0-0.3,0.1-0.4c0.2-0.3,0.7-0.3,1-0.1l20.5,17.1c0,0,0,0,0.1,0.1c0.2,0.3,0.2,0.7-0.1,1l-20.5,17.1c-0.1,0.1-0.3,0.2-0.5,0.2c-0.4,0-0.7-0.3-0.7-0.7V294.2z"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'NextIcon',
        props: {
            width: {
                type: [Number, String],
                default: 14
            },
            height: {
                type: [Number, String],
                default: 11
            },
            transform: {
                type: [Number, String],
                default: 0
            }
        }
    }
</script>