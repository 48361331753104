<template>
    <full-modal @close="$emit('close')">
        <div slot="title">
            {{ `Edit ${customFieldsSection.name}` }}
        </div>
        <div slot="body">
            <div 
                v-if="customFields.length"
                class="w-100 pb-30"
            >
                <custom-field-address-section 
                    v-if="customFieldsSection.type === CUSTOM_FIELDS_SECTION_TYPES.ADDRESS"
                    v-model="customFields"
                    :fullWidth="true"
                />
                <div 
                    v-else
                    class="row-start"
                >
                    <div 
                        v-for="(field, fieldIndex) in customFields"
                        :key="fieldIndex"
                        :class="[ 
                            customFieldsSection.type === CUSTOM_FIELDS_SECTION_TYPES.COMMON
                                ? 'lg-6 md-6 sm-12 mb-4 p-sm-0' : 'lg-12',
                            (field.type.name === CUSTOM_FIELDS_TYPES.PHONE || fieldIndex % 2 === 0) 
                            ? 'pr-2' : 'pl-2',
                            { 'order-negative lg-12 pl-0' : field.type.name === CUSTOM_FIELDS_TYPES.PHONE }
                        ]"
                    >
                        <tooltip
                            v-if="field.parameters.show_tooltip"
                            class="w-100"
                            :direction="fieldIndex > 5 ? 'top' : 'bottom'"
                            mobileDescription="Field info"
                        >
                            <div class="column-start">
                                <custom-field-input 
                                    :field='field'
                                    :index="fieldIndex"
                                    v-model="field.values[0].value"
                                />
                                <form-input
                                    v-if="field.type.name === CUSTOM_FIELDS_TYPES.PHONE"
                                    label="Extension"
                                    :class="[{ 'form-input-danger' : errors.has(`extension-${fieldIndex}`) }]"
                                >
                                    <input 
                                        type="text"
                                        :name="`extension-${fieldIndex}`" 
                                        class="form-input-field mt-2"
                                        placeholder="Extension"
                                        v-input-style="field.values[0].object_value.extension"
                                        v-model="field.values[0].object_value.extension"
                                        v-validate="'integer'"
                                    />
                                </form-input>
                            </div>
                            <template v-slot:content>
                                <p class="f-16-white">
                                    {{ field.parameters.tooltip_text }}
                                </p>
                            </template>
                        </tooltip>
                        <div
                            v-else
                            class="column-start w-100"
                        >
                            <custom-field-input 
                                :field='field'
                                :index="fieldIndex"
                                v-model="field.values[0].value"
                            />
                            <form-input
                                v-if="field.type.name === CUSTOM_FIELDS_TYPES.PHONE"
                                label="Extension"
                                :class="[{ 'form-input-danger' : errors.has(`extension-${fieldIndex}`) }]"
                            >
                                <input 
                                    type="text"
                                    :name="`extension-${fieldIndex}`" 
                                    class="form-input-field mt-2"
                                    placeholder="Extension"
                                    v-input-style="field.values[0].object_value.extension"
                                    v-model="field.values[0].object_value.extension"
                                    v-validate="'integer'"
                                />
                            </form-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                @click="save"
                :disabled="requestIsGoing"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, Tooltip, FormInput } from '@/ui';
    import { mask } from 'vue-the-mask';
    import CustomFieldInput from '@/components/LeadModules/CustomFieldInput.vue';
    import CustomFieldAddressSection from '@/components/LeadModules/CustomFieldAddressSection.vue';
    import api from '@/headers.js';
    import { CUSTOM_FIELDS_SECTION_TYPES, CUSTOM_FIELDS_TYPES } from '@/constants';

    export default {
        name: 'EditJobCustomFieldsModal',
        components: {
            FormInput,
            FullModal,
            CustomFieldInput,
            CustomFieldAddressSection,
            Tooltip
        },
        props: {
            id: {
                type: [String, Number],
                default: 0
            },
            customFieldsSection: {
                type: [Object],
                required: true
            }
        },
        directives: {
            mask
        },
        data() {
            return {
                customFields: [],
                customFieldsNewValues: { custom_fields: [] },
                customFieldsExistingValues: { custom_fields: [] },
                requestIsGoing: false,
                CUSTOM_FIELDS_SECTION_TYPES: CUSTOM_FIELDS_SECTION_TYPES,
                CUSTOM_FIELDS_TYPES
            }
        },
        methods: {
            save() {
                // validate nested components
                this.$children.forEach(vm => {
                    vm.$validator.validateAll().then(result => {
                        if (!result) {
                            this.notifyError('Fill in required fields!');
                            return;
                        }
                    });
                });
                
                this.$validator.validateAll().then(result => {
                    if (this.$validator.errors.items.length === 0) {
                        this.requestIsGoing = true;
                        
                        let params;
                        let queries = [];

                        // separation values on new/existing
                        this.customFields.forEach((field, fieldIndex) => {
                            params = {
                                custom_field_id: field.id, 
                                model_id: this.id, // job id
                            };

                            switch (this.customFieldsSection.type) {
                                case CUSTOM_FIELDS_SECTION_TYPES.ADDRESS:
                                    params.object_value = JSON.stringify(field.values[0].object_value);
                                    break;
                                default: 
                                    params.value = field.values[0].value.toString();
                                    break;
                            }

                            switch (field.type.name) {
                                case CUSTOM_FIELDS_TYPES.PHONE:
                                    params.object_value = JSON.stringify(field.values[0].object_value);
                                    params.value = field.values[0].value.toString();
                                    break;
                                default: 
                                    params.value = field.values[0].value.toString();
                                    break;
                            }

                            if (field.isNewValue) {
                                this.customFieldsNewValues.custom_fields.push(params)
                            } else if (field.values[0].value !== '') {
                                this.customFieldsExistingValues.custom_fields.push(params)
                            }
                        });
                        
                        // save new values
                        if (this.customFieldsNewValues.custom_fields.length !== 0) {
                            queries.push(
                                api.post('/layouts/custom-field-values', this.customFieldsNewValues)
                            );
                        }

                        // update existing values
                        if (this.customFieldsExistingValues.custom_fields.length !== 0) {
                            queries.push(
                                api.put('/layouts/custom-field-values', this.customFieldsExistingValues)
                            );
                        }

                        Promise.all(queries)
                            .then(() => {
                                this.notifySuccess('Changes have been saved!');

                                this.$emit('refresh', this.id);
                            })
                            .then(() => {
                                this.$emit('close');
                            })
                            .catch((error) => {
                                this.notifyRequestErrors(error);
                            })
                            .finally(() => {
                                this.requestIsGoing = false;
                            });
                    } else {
                        let domRect = document.getElementById(this.$validator.errors.items[0].field);

                        if (domRect != null || domRect != undefined) {
                            this.$el.querySelector('.full-modal-content').scrollTo({
                                top: domRect.offsetTop - 100,
                                behavior: "smooth"
                            })
                        };
                    }
                });
            },
            initCustomFields() {
                let values;
                let convertedParameters;
                let fields = this.customFieldsSection.fields;

                fields.forEach(function(field, fieldIndex) {
                    // convert parameters from JSON to Object
                    convertedParameters = JSON.parse(field.parameters);
                    fields[fieldIndex].parameters = convertedParameters;
                    let values;
                    
                    // if value not exist add empty initial value
                    if (field.values.length === 0) {
                        switch (this.customFieldsSection.type) {
                            case CUSTOM_FIELDS_SECTION_TYPES.ADDRESS:
                                values = [{ 'object_value': null }];
                                break;
                            default:
                                values = [{ 'value': '' }];
                                break;
                        }

                        fields[fieldIndex].isNewValue = true;
                    } else {
                        switch (this.customFieldsSection.type) {
                            case CUSTOM_FIELDS_SECTION_TYPES.ADDRESS:
                                values = field.values;
                                break;
                            default:
                                values = field.values;
                                break;
                        }

                        // if value present but has empty value or object_value parameters.
                        if (field.values.length
                            && field.values[0].value === '' 
                            || field.values[0].object_value === ''
                        ) {
                            fields[fieldIndex].isNewValue = true;
                        } else {
                            fields[fieldIndex].isNewValue = false;
                        }
                    }

                    fields[fieldIndex].values = values;
                }.bind(this))

                this.customFields.push(...fields);            
            }
        },
        mounted() {
            this.initCustomFields();
        }
    }
</script>