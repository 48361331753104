<template>
    <div class="modal s-align-5">
        <div class="add-new-tag-modal cm-fond cm-scroll">
            <h2 class="f-24-black f-sbold t-center">Add new label</h2>
            <div class="w-100">
                <form-input 
                    label="Label name"
                    :class="[{ 'form-input-danger' : errors.has('tag_name') }]"
                >
                    <input 
                        type="text" 
                        name="tag_name" 
                        class="form-input-field"
                        placeholder="Label name"
                        v-input-style="tag.name"
                        v-model="tag.name"
                        v-validate="'required|min:3|max:100'"
                    />
                </form-input>
            </div>
            <div class="custom-modal-footer row-between">
                <button 
                    class="add-button"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button 
                    class="primary-button"
                    @click="addTag"
                    :disabled="disabled"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { FormInput } from '@/ui';

    export default {
        name: 'AddNewTagModal',
        components: {
            FormInput
        },
        props: {
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                tag: {
                    name: ''
                }
            }
        },
        methods: {
            addTag() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('The "Label name" field is required.');
                        return;
                    }

                    this.$emit('addNewTag', this.tag);
                });
            }
        }
    }
</script>