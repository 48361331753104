<template>
    <div class="w-100 property-section">
        <div class="row-between">
            <div class="s-align-7">
                <h2 class="f-24-asphalt f-sbold">{{ property.property_name }}</h2>
                <button 
                    class="icon-light ml-8"
                    @click="$emit('edit')"
                >
                    <edit-icon size="15"/>
                </button>
            </div>
            <div class="s-align-6">
                <button 
                    class="transparent-button"
                    @click="propertyIsOpen = !propertyIsOpen"
                >
                    <span class="f-12-blue pr-1">{{ propertyIsOpen ? 'Hide info' : 'Advanced Info' }}</span>
                    <arrow-icon 
                        class="i-blue"
                        size="6"
                        :transform="propertyIsOpen ? 90 : 270"
                    />
                </button>
            </div>
        </div>
        <div class="lead-pages-container w-100 overview">
            <div class="commercial-lead-overview">
                <div class="overview-statistic w-100 mb-20">
                    <div class="row-between">
                        <div
                            :class="[
                                'column-start w-100',
                                { 'statistic-mock-loading' : loading }
                            ]"
                        >
                            <div class="w-100 align-s row-stretch">
                                <div
                                    v-for="(item, index) in statistic"
                                    :key="index"
                                    v-show="!loading"
                                    :class="[
                                        'overview-statistic-column flex-grow-1 ph-20 pv-15',
                                        index && dScreen && 'ml-20',
                                        !dScreen && 'm-4',
                                        'column-start',
                                        item.inDev ? 'cursor-not-allowed' : 'pointer'
                                    ]"
                                    @click="selectTab(item)"
                                >
                                    <div :class="item.inDev && 'w-100 in-dev no-wrap'"/>
                                    <div class="row-between align-bottom h-100">
                                        <div class="column-start s-align-6">
                                            <span class="f-24-black f-sbold t-line-height-1">
                                                {{ typeof(item.count) ? item.count : null}}
                                            </span>
                                            <span class="f-12-grey mt-2 s-align-6">
                                                {{ item.name }}
                                            </span>
                                        </div>
                                        <div :class="[
                                            'overview-statistic-column-icon s-align-5',
                                            item.class
                                        ]">
                                            <component
                                                :is="item.icon"
                                                size="8"
                                                :class="item.iconClass"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="isShowPropertyInfo">
            <div
                class="w-100 pb-8"
                v-if="propertyIsOpen"
            >
                <div class="column-static w-100 mb-8">
                    <p class="f-16-asphalt f-sbold mb-8">Information</p>
                    <div class="row-start">
                        <div class="lg-4 md-12 sm-12 pr-8 pb-2">
                            <span class="f-13-grey pr-2">Property Name:</span>
                            <span class="f-13-black">{{ property.property_name }}</span>
                        </div>
                        <div class="lg-4 md-12 sm-12 pr-8 pb-2">
                            <span class="f-13-grey pr-2">Designation:</span>
                            <span
                                class="f-13-black"
                                v-if="property.designation"
                            >
                                {{ property.designation }}
                            </span>
                            <span
                                class="f-13-black"
                                v-else
                            >
                                -
                            </span>
                        </div>
                        <div class="lg-4 md-12 sm-12 pr-8 pb-2">
                            <span class="f-13-grey pr-2">Division:</span>
                            <span
                                class="f-13-black"
                                v-if="property.division"
                            >
                                {{ property.division }}
                            </span>
                            <span
                                class="f-13-black"
                                v-else
                            >
                                -
                            </span>
                        </div>
                        <div class="lg-4 md-12 sm-12 pr-8 pb-2">
                            <span class="f-13-grey pr-2">Allow to be selected as a location?:</span>
                            <span
                                class="f-13-black"
                                v-if="property.is_selected_location"
                            >
                                Yes
                            </span>
                            <span
                                class="f-13-black"
                                v-else
                            >
                                No
                            </span>
                        </div>
                        <div class="lg-4 md-12 sm-12 pr-8 pb-2">
                            <a
                                :href="getGoogleMapLink(property.addresses.main[0].lat, property.addresses.main[0].lng)"
                                :class="[
                                    'f-13-blue',
                                    {'none-events': !getGoogleMapLink(property.addresses.main[0].lat, property.addresses.main[0].lng)}
                                ]"
                                target="_blank"
                            >
                                {{ getAddress(property.addresses.main[0]) }}
                                <link-icon 
                                    v-if="getGoogleMapLink(property.addresses.main[0].lat, property.addresses.main[0].lng)"
                                    class="i-blue"
                                />
                            </a>
                        </div>
                        <div
                            v-if="property.addresses.billing.length"
                            class="lg-4 md-12 sm-12 pr-8 pb-2"
                        >
                            <a
                                :href="getGoogleMapLink(property.addresses.billing[0].lat, property.addresses.billing[0].lng)"
                                :class="[
                                    'f-13-blue',
                                    {'none-events': !getGoogleMapLink(property.addresses.billing[0].lat, property.addresses.billing[0].lng)}
                                ]"
                                target="_blank"
                            >
                                {{ getAddress(property.addresses.billing[0]) }}
                                <link-icon 
                                    v-if="getGoogleMapLink(property.addresses.billing[0].lat, property.addresses.billing[0].lng)"
                                    class="i-blue"
                                />
                            </a>
                        </div>
                        <div
                            class="lg-4 md-12 sm-12 pr-8 pb-2"
                            v-if="property.creator"
                        >
                            <span class="f-13-grey pr-2">Created by:</span>
                            <span class="f-13-black">{{ property.creator.fullname }}</span>
                        </div>
                    </div>
                </div>
                <template v-for="(section, index) in propertyCustomFields.sections">
                    <div
                        v-if="section.fields.length"
                        :key="index"
                        class="w-100 mb-8"
                    >
                        <div class="row-between row-baseline mb-2">
                            <p class="sm-7 f-16-asphalt f-sbold">{{ section.name }}</p>
                            <button
                                class="icon-light ml-8"
                                @click="showPropertyCustomFieldsModal(index)"
                            >
                                <edit-icon size="15"/>
                            </button>
                        </div>
                        <div class="row-start">
                            <template
                                v-if="section.type === CUSTOM_FIELDS_SECTION_TYPES.ADDRESS"
                            >
                                <div class="lg-6 sm-12">
                                    <p class="f-12-grey mr-2 break-all">
                                        {{ `${section.fields[0].label}: ` }}
                                        <span class="f-12-darkgrey 11">
                                        {{ getCustomFieldsAddress(section.fields[0].values) }}
                                    </span>
                                    </p>
                                </div>
                                <div
                                    v-if="checkIfSecondAddressShow(section.fields[0].parameters)"
                                    class="lg-6 sm-12 mt-sm-1"
                                >
                                    <p class="f-12-grey mr-2 break-all">
                                        {{ `${section.fields[1].label}: ` }}
                                        <span class="f-12-darkgrey 11">
                                        {{ getCustomFieldsAddress(section.fields[1].values) }}
                                    </span>
                                    </p>
                                </div>
                            </template>
                            <div
                                v-else
                                v-for="(field, index) in section.fields"
                                :key="index"
                                :class="[
                                'pv-3',
                                field.type.name === CUSTOM_FIELDS_TYPES.MULTILINE
                                ? 'lg-12' : 'lg-4 sm-12'
                            ]"
                            >
                                <p class="f-12-grey mr-2 break-all">
                                    {{ `${field.label}: ` }}
                                    <span
                                        v-if="field.type.name === CUSTOM_FIELDS_TYPES.DATE"
                                        class="f-12-darkgrey "
                                    >
                                        {{ field.values.length && field.values[0].value
                                            ? field.values[0].value
                                            : '-' | date
                                        }}
                                    </span>
                                    <span
                                        v-else-if="field.type.name === CUSTOM_FIELDS_TYPES.DATE_TIME"
                                        class="f-12-darkgrey "
                                    >
                                        {{ field.values.length ? field.values[0].value : '-' | createdAt }}
                                    </span>
                                    <span
                                        v-else-if="field.type.name === CUSTOM_FIELDS_TYPES.MULTILINE"
                                        class="f-12-darkgrey preserve-whitespaces"
                                    >
                                        <br>{{ field.values.length && field.values[0].value
                                            ? field.values[0].value
                                            : '-'
                                        }}
                                        <!-- ↑↑↑ don't format code, needed to correct work css property!  -->
                                    </span>
                                    <span
                                        v-else-if="field.type.name === CUSTOM_FIELDS_TYPES.MULTISELECT
                                        || field.type.name === CUSTOM_FIELDS_TYPES.PICK_LIST"
                                        class="f-12-darkgrey"
                                    >
                                        {{ getMultiselectValue(field) }}
                                    </span>
                                    <span
                                        v-else
                                        class="f-12-darkgrey"
                                    >
                                        {{ field.values.length && field.values[0].value
                                            ? field.values[0].value
                                            : '-'
                                        }}
                                        <p v-if="field.type.name === CUSTOM_FIELDS_TYPES.PHONE">
                                            Ext - {{ field.values.length && field.values[0].object_value 
                                                && field.values[0].object_value.extension 
                                                ? field.values[0].object_value.extension
                                                : '' }}
                                        </p>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="customer-info w-box column-start">
                    <div class="column-static w-100 pt-20 bt-grey">
                        <div
                            class="row-between pointer"
                            @click="customerDetailsIsOpen = !customerDetailsIsOpen"
                        >
                            <div class="w-75">
                                <p class="f-16-asphalt f-sbold">Customer History</p>
                            </div>
                            <div class="w-25 s-align-6">
                                <arrow-icon
                                    size="11"
                                    :transform="customerDetailsIsOpen ? 270 : 90"
                                    class="i-darkgrey"
                                />
                            </div>
                        </div>
                        <div
                            class="column-static w-100 pt-8"
                            v-if="customerDetailsIsOpen"
                        >
                            <div
                                class="row-start mb-30"
                                v-if="customerHistory.length < 1"
                            >
                                <p>
                                    The history of customers is empty!
                                </p>
                            </div>
                            <div
                                class="row-start mb-30"
                                v-else
                            >
                                <div
                                    class="column-start lg-6 md-4 sm-12 mb-sm-1"
                                    v-if="customerHistory.length"
                                >
                                    <span class="f-12-grey f-sbold">
                                        {{ customerHistory.length > 1 ? 'Customers' : 'Customer' }}
                                    </span>
                                    <span class="f-14-black pt-2"
                                          v-for="(customer, index) in customerHistory"
                                          :key="`customer-${index}`"
                                    >
                                        {{ customer.name }}
                                    </span>
                                </div>
                                <div
                                    class="column-start lg-5 md-4 sm-12 pr-4 mb-sm-1"
                                    v-if="customerHistory.length"
                                >
                                    <span class="f-12-grey f-sbold">
                                        {{ customerHistory.length > 1 ? 'Dates' : 'Date' }}
                                    </span>
                                    <span class="f-14-black pt-2"
                                          v-for="(date, index) in customerHistory"
                                          :key="`date-${index}`"
                                    >
                                    from {{ date.data_start | monthDayYear }}
                                    until {{ date.data_end === null ? 'now' : date.data_end | monthDayYear }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="column-static w-100"
                    v-if="property.representatives.length"
                >
                    <representatives
                        id="property_representatives"
                        :options="customerRepresentatives"
                        :existing="property.representatives"
                        :forRemove="propertyRepresentativesForRemove"
                        :moduleMode="'update'"
                        :saveImmediately="true"
                        :entityId="property.id"
                        :loadRepresentatives="loadRepresentatives"
                        :representativesForEntity="ENTITY_TYPES.COMMERCIAL_PROPERTY"
                        @clean="cleanCancelRepresentative"
                        @save="savePropertyRepresentatives"
                        sectionName="Point of contacts"
                    />
                </div>
            </div>
            <ul class="property-tabs-buttons row-start pv-6 bt-grey">
                <li class="pr-6">
                    <button
                        :class="[
                            'property-tab-button s-align-5 f-sbold',
                            { 'tab-button-active' : activeTab == unitsTabSelected }
                        ]"
                        @click="activeTab = unitsTabSelected"
                        :disabled="selectToMoveMode"
                    >
                        <span>Units</span>
                        <span
                            class="section-tab-count s-align-5 ml-4"
                            v-if="property.units_count > 0"
                        >
                            {{ property.units_count }}
                        </span>
                    </button>
                </li>
                <li class="pr-6">
                    <button
                        :class="[
                            'property-tab-button s-align-5 f-sbold',
                            { 'tab-button-active' : activeTab == jobsTabSelected }
                        ]"
                        @click="activeTab = jobsTabSelected"
                        :disabled="selectToMoveMode"
                    >
                        <span>Only jobs</span>
                        <span
                            class="section-tab-count s-align-5 ml-4"
                            v-if="property.jobs_count > 0"
                        >
                            {{ property.jobs_count }}
                        </span>
                    </button>
                </li>
                <li>
                    <button
                        :class="[
                        'property-tab-button s-align-5 f-sbold',
                            { 'tab-button-active' : activeTab == leadsTabSelected }
                        ]"
                        @click="activeTab = leadsTabSelected"
                        :disabled="selectToMoveMode"
                    >
                        <span>Leads</span>
                        <span
                            class="section-tab-count s-align-5 ml-4"
                            v-if="propertyLeadsLength > 0"
                        >
                            {{ property.leads_count }}
                        </span>
                    </button>
                </li>
            </ul>
            <div class="property-tabs w-100">
                <div
                    class="property-tab"
                    v-if="activeTab == unitsTabSelected"
                >
                    <div
                        v-if="propertyUnitsLength"
                        class="row-between row-baseline pb-4"
                    >
                        <p class="f-16-asphalt f-sbold">Property Units</p>
                        <router-link
                            :class="['view-details-primary', mScreen ? 'ph-3' : 'ph-6']"
                            :to="{
                            name: 'CommercialProperty',
                            params: { customer_id: property.customer_id },
                            query: {
                                property: property.id,
                                unit_mode: true
                            }
                        }"
                        >
                            + Create new Unit
                        </router-link>
                    </div>
                    <div
                        class="w-box column-start w-100"
                        v-for="(unit, index) in property.units"
                        :key="`unit-${index}`"
                    >
                        <div class="row-between">
                            <p class="f-16-black f-sbold">{{ unit.name }}</p>
                            <button
                                class="icon-light"
                                @click="$emit('editUnit', unit.id)"
                            >
                                <edit-icon/>
                            </button>
                        </div>
                        <div class="column-start w-100">
                            <a
                                :href="getGoogleMapLink(unit.lat, unit.lng)"
                                :class="[
                                    'f-13-blue',
                                    {'none-events': !getGoogleMapLink(unit.lat, unit.lng)}
                                ]"
                                target="_blank"
                            >
                                <span class="f-13-grey pr-2">Address:</span>
                                {{ unit.full_address }}
                                <link-icon 
                                    v-if="getGoogleMapLink(unit.lat, unit.lng)"
                                    class="i-blue"
                                />
                            </a>
                            <div
                                class="w-100"
                                v-if="unit.creator"
                            >
                                <span class="f-13-grey pr-2">Created by:</span>
                                <span class="f-13-black">{{ unit.creator.fullname }}</span>
                            </div>
                        </div>
                        <template v-for="(section, index) in unitsCustomFields[unit.id] && unitsCustomFields[unit.id].sections">
                            <div
                                v-if="section.fields.length"
                                :key="index"
                                class="w-100 mb-8"
                            >
                                <div class="row-between row-baseline mb-2">
                                    <p class="sm-7 f-15-asphalt f-sbold">{{ section.name }}</p>
                                    <button
                                        class="icon-light ml-8"
                                        @click="showUnitCustomFieldsModal(unit.id, index)"
                                    >
                                        <edit-icon size="15"/>
                                    </button>
                                </div>
                                <div class="row-start">
                                    <template
                                        v-if="section.type === CUSTOM_FIELDS_SECTION_TYPES.ADDRESS"
                                    >
                                        <div class="lg-6 sm-12">
                                            <p class="f-12-grey mr-2 break-all">
                                                {{ `${section.fields[0].label}: ` }}
                                                <span class="f-12-darkgrey 11">
                                                    {{ getCustomFieldsAddress(section.fields[0].values) }}
                                                </span>
                                            </p>
                                        </div>
                                        <div
                                            v-if="checkIfSecondAddressShow(section.fields[0].parameters)"
                                            class="lg-6 sm-12 mt-sm-1"
                                        >
                                            <p class="f-12-grey mr-2 break-all">
                                                {{ `${section.fields[1].label}: ` }}
                                                <span class="f-12-darkgrey 11">
                                                    {{ getCustomFieldsAddress(section.fields[1].values) }}
                                                </span>
                                            </p>
                                        </div>
                                    </template>
                                    <div
                                        v-else
                                        v-for="(field, index) in section.fields"
                                        :key="index"
                                        :class="[
                                            'pv-3',
                                            field.type.name === CUSTOM_FIELDS_TYPES.MULTILINE
                                            ? 'lg-12' : 'lg-4 sm-12'
                                        ]"
                                    >
                                        <p class="f-12-grey mr-2 break-all">
                                            {{ `${field.label}: ` }}
                                            <span
                                                v-if="field.type.name === CUSTOM_FIELDS_TYPES.DATE"
                                                class="f-12-darkgrey "
                                            >
                                                {{ field.values.length ? field.values[0].value : '-' | date }}
                                            </span>
                                            <span
                                                v-else-if="field.type.name === CUSTOM_FIELDS_TYPES.DATE_TIME"
                                                class="f-12-darkgrey "
                                            >
                                                {{ field.values.length ? field.values[0].value : '-' | createdAt }}
                                            </span>
                                            <span
                                                v-else-if="field.type.name === CUSTOM_FIELDS_TYPES.MULTILINE"
                                                class="f-12-darkgrey preserve-whitespaces"
                                            >
                                                <br>{{ field.values.length ? field.values[0].value : '-' }}
                                                <!-- ↑↑↑ don't format code, needed to correct work css property!  -->
                                            </span>
                                            <span
                                                v-else-if="field.type.name === CUSTOM_FIELDS_TYPES.MULTISELECT
                                                || field.type.name === CUSTOM_FIELDS_TYPES.PICK_LIST"
                                                class="f-12-darkgrey"
                                            >
                                                {{ getMultiselectValue(field) }}
                                            </span>
                                            <span
                                                v-else
                                                class="f-12-darkgrey"
                                            >
                                                {{ field.values.length ? field.values[0].value : '-' }}
                                                <p v-if="field.type.name === CUSTOM_FIELDS_TYPES.PHONE">
                                                    Ext - {{ field.values.length && field.values[0].object_value 
                                                        && field.values[0].object_value.extension 
                                                        ? field.values[0].object_value.extension
                                                        : '' }}
                                                </p>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <unit-representatives
                            :representatives="unit.representatives"
                            :propertyRepresentatives="propertyRepresentatives"
                            :unitId="unit.id"
                        />
                        <commercial-jobs-table
                            v-if="unit.jobs.length"
                            :option="unit"
                            :propertyId="property.id"
                            :selectMode="selectToMoveMode"
                            :selectedEntities="entitiesToMove"
                            :show-milestone="true"
                            @onSelect="onSelect"
                        />
                        <div class="w-100 mt-5">
                            <router-link
                                class="f-13-blue f-sbold"
                                :to="{
                                name: 'CommercialJob',
                                    params: {
                                        customer_id: property.customer_id,
                                        property_id: property.id,
                                        unit_id: unit.id
                                    }
                                }"
                            >
                                + Add New Job
                            </router-link>
                        </div>
                    </div>
                    <div
                        class="w-100"
                        v-if="!propertyUnitsLength"
                    >
                        <p class="f-16-asphalt f-sbold pb-4">Property Units</p>
                        <span class="f-14-black">
                        There are no units added to this property.
                        You can
                        <router-link
                            class="f-14-blue"
                            :to="{
                                name: 'CommercialProperty',
                                params: { customer_id: property.customer_id },
                                query: {
                                    property: property.id,
                                    unit_mode: true
                                }
                            }"
                        >
                            create new units for
                            <span class="f-sbold">"{{ property.property_name }}"</span>
                            here
                        </router-link>
                    </span>
                    </div>
                </div>
                <div
                    class="property-tab"
                    v-else-if="activeTab == jobsTabSelected"
                >
                    <div class="row-between row-baseline pb-4">
                        <p class="f-16-asphalt f-sbold">Property Jobs</p>
                        <div
                            v-if="propertyJobsLength"
                            class="s-align-6 mt-sm-1"
                        >
                            <template v-if="selectToMoveMode">
                                <button
                                    class="secondary-button"
                                    @click="selectAll"
                                >
                                    Select all
                                </button>
                                <button
                                    class="add-button ph-3 ml-4"
                                    @click="exitSelectMode"
                                >
                                    Cancel
                                </button>
                            </template>
                            <template v-else>
                                <router-link
                                    :class="['view-details-primary', mScreen ? 'ph-3' : 'ph-6']"
                                    :to="{
                                        name: 'CommercialJob',
                                        params: {
                                            customer_id: property.customer_id,
                                            property_id: property.id
                                        }
                                    }"
                                >
                                    + Create new Job
                                </router-link>
                                <button
                                    v-if="property.jobs_count > 1"
                                    :class="[
                                        'view-details-primary ml-4',
                                        { 'active-filters' : isJobFiltersShow }
                                    ]"
                                    @click="isJobFiltersShow = !isJobFiltersShow"
                                >
                                    <div
                                        v-if="filterCount"
                                        class="count f-11-blue f-bold s-align-5"
                                    >
                                        {{ filterCount }}
                                    </div>
                                    <settings-icon
                                        v-else
                                        class="i-blue"
                                    />
                                    <span class="f-13-blue f-sbold ml-4">Filters</span>
                                </button>
                                <button
                                    class="secondary-button ml-4"
                                    @click="selectToMoveMode = true"
                                >
                                    Select...
                                </button>
                            </template>
                        </div>
                    </div>
                    <div
                        v-if="isJobFiltersShow"
                        class="row-between mb-8"
                    >
                        <div class="lg-12 sm-12 column-start mb-5">
                        <span class="f-12-black f-sbold s-align-4 pv-2">
                            Milestone
                        </span>
                            <multiselect
                                :options="propertyMilestones"
                                placeholder="Milestone"
                                :showLabels="false"
                                :max-height="160"
                                :searchable="false"
                                :multiple="true"
                                v-model="filters.milestone"
                                name="milestone_name"
                                data-name="milestone_name"
                            />
                        </div>
                        <div class="lg-6 sm-12 column-start">
                        <span class="f-12-black f-sbold s-align-4 pv-2">
                            Job Type
                        </span>
                            <multiselect
                                :options="propertyJobsTypes"
                                placeholder="Job type"
                                :showLabels="false"
                                :max-height="160"
                                :searchable="false"
                                :multiple="true"
                                v-model="filters.jobType"
                                name="job_type"
                                data-name="job_type"
                            />
                        </div>
                        <div class="lg-5 sm-12 column-start">
                            <div class="row-between row-baseline">
                                <span class="f-12-black f-sbold pv-2">Date</span>
                                <button
                                    v-if="filters.date.some(i => i !== null)"
                                    class="transparent-button"
                                    @click="filters.date = [null,null]"
                                >
                                    <close-icon
                                        size="9"
                                        class="i-red mr-1"
                                    />
                                    <span class="f-12-darkgrey">Clear</span>
                                </button>
                            </div>
                            <form-date-range-input
                                :isFullWidth="true"
                                v-model="filters.date"
                            />
                        </div>
                    </div>
                    <div
                        class="w-box"
                        v-if="propertyJobsLength"
                    >
                        <commercial-jobs-table
                            :option="property"
                            :propertyId="property.id"
                            :selectMode="selectToMoveMode"
                            :selectedEntities="entitiesToMove"
                            @onSelect="onSelect"
                            :show-milestone="true"
                            :filterParams="filters"
                        />
                    </div>
                    <div
                        class="w-100"
                        v-else
                    >
                    <span class="f-14-black">
                        There are no jobs added to this property.
                        You can
                        <router-link
                            class="f-14-blue"
                            :to="{
                                name: 'CommercialJob',
                                params: {
                                    customer_id: property.customer_id,
                                    property_id: property.id
                                }
                            }"
                        >
                            create new jobs for
                            <span class="f-sbold">"{{ property.property_name }}"</span>
                            here
                        </router-link>
                    </span>
                    </div>
                </div>
                <div
                    class="property-tab"
                    v-else-if="activeTab == leadsTabSelected"
                >
                    <div class="row-between row-baseline pb-4">
                        <p class="f-16-asphalt f-sbold">Property Leads</p>
                        <div
                            v-if="propertyLeadsLength"
                            class="s-align-6 mt-sm-1"
                        >
                            <template v-if="selectToMoveMode">
                                <button
                                    class="secondary-button"
                                    @click="selectAll"
                                >
                                    Select all
                                </button>
                                <button
                                    class="add-button ph-3 ml-4"
                                    @click="exitSelectMode"
                                >
                                    Cancel
                                </button>
                            </template>
                            <template v-else>
                                <router-link
                                    :class="['view-details-primary', mScreen ? 'ph-3' : 'ph-6']"
                                    :to="{ name: 'CreateNewCommercialLead', params: { property_id: property.id }}"
                                >
                                    + Create new Lead
                                </router-link>
                                <button
                                    v-if="property.leads_count > 0"
                                    :class="[
                                    'view-details-primary ml-4',
                                    { 'active-filters' : isLeadFiltersShow }
                                ]"
                                    @click="isLeadFiltersShow = !isLeadFiltersShow"
                                >
                                    <settings-icon class="i-blue"/>
                                    <span class="f-13-blue f-sbold ml-4">Filters</span>
                                </button>
                                <button
                                    class="secondary-button ml-4"
                                    @click="selectToMoveMode = true"
                                >
                                    Select...
                                </button>
                            </template>
                        </div>
                    </div>
                    <div
                        v-if="isLeadFiltersShow"
                        class="row-start mb-8"
                    >
                        <div class="lg-6 sm-12 column-start">
                            <div class="row-between row-baseline">
                                <span class="f-12-black f-sbold pv-2">Date</span>
                                <button
                                    v-if="leadFilters.date.some(i => i !== null)"
                                    class="transparent-button"
                                    @click="leadFilters.date = [null,null]"
                                >
                                    <close-icon
                                        size="9"
                                        class="i-red mr-1"
                                    />
                                    <span class="f-12-darkgrey">Clear</span>
                                </button>
                            </div>
                            <form-date-range-input
                                :isFullWidth="true"
                                v-model="leadFilters.date"
                            />
                        </div>
                    </div>
                    <div
                        class="w-box"
                        v-if="propertyLeadsLength"
                    >
                        <commercial-leads-table
                            :option="property.leads"
                            :selectMode="selectToMoveMode"
                            :selectedEntities="entitiesToMove"
                            :show-milestone="true"
                            @onSelect="onSelect"
                            :filterParams="leadFilters"
                        />
                    </div>
                    <div
                        class="w-100"
                        v-else
                    >
                    <span class="f-14-black">
                        There are no leads added to this property.
                        You can
                        <router-link
                            class="f-14-blue"
                            :to="{ name: 'CreateNewCommercialLead', params: { property_id: property.id }}"
                        >
                            create new leads for
                            <span class="f-sbold">"{{ property.property_name }}"</span>
                            here
                        </router-link>
                    </span>
                    </div>
                </div>
            </div>
            <div
                v-if="selectToMoveMode"
                class="page-settings"
            >
                <div class="page-settings-buttons row-between row-baseline">
                <span class="f-13-grey">
                    <span class="f-13-black f-sbold">
                        {{ entitiesToMove.length }}
                    </span>
                    {{
                        `${activeTab == jobsTabSelected ? 'job': 'lead'}
                        ${entitiesToMove.length === 1 ? '' : 's'}`
                    }}
                    Selected
                </span>
                    <button
                        class="primary-button"
                        @click="moveToModalShown = true"
                        :disabled="!entitiesToMove.length"
                    >
                        Move To...
                    </button>
                </div>
            </div>
        </template>
        <edit-job-custom-fields-modal
            v-if="editCustomFieldsModalShow"
            :id="editedCustomFieldId"
            :customFieldsSection="selectedCustomFieldsSection"            
            @close="closeCustomFieldsModal"
            @refresh="updateCustomFields"
        />
        <move-commercial-entities-modal
            v-if="moveToModalShown"
            :currentCustomerId="property.customer_id"
            :currentPropertyId="property.id"
            :entitiesToMove="entitiesToMove"
            :activeTab="activeTab"
            @removeMovedEntities="removeMovedEntities"
            @close="moveToModalShown = false"
        />
    </div>
</template>

<script>
    import {
        EditIcon, ArrowIcon, LinkIcon, SettingsIcon, CloseIcon,
        PackatsIcon, MessagesIcon, DocumentsIcon, OrdersIcon, StarIcon
    } from '@/icons';
    import { Avatar, Representatives } from '@/components';
    import { FormDateRangeInput } from '@/ui';
    import ContactList from '@/components/CommercialJobs/ContactList.vue';
    import CommercialJobsTable from '@/components/CommercialJobs/CommercialJobsTable.vue';
    import CommercialLeadsTable from '@/components/CommercialJobs/CommercialLeadsTable.vue';
    import UnitRepresentatives from '@/components/CommercialJobs/UnitRepresentatives.vue';
    import { 
        CUSTOM_FIELDS_SECTION_TYPES, CUSTOM_FIELDS_TYPES, COMMERCIAL_PROPERTY_TABS, ENTITY_TYPES,
        MODEL_CLASSES
    } from '@/constants';
    import { showFullAddress, clearArray, scrollToElement, getGoogleMapLink } from '@/helpers';
    import cloneDeep from 'lodash/cloneDeep';
    import { MoveCommercialEntitiesModal } from '@/modals';
    import api from '@/headers.js';
    import Multiselect from 'vue-multiselect';

    const ROUTE_NAME_CURRENT_PROPERTY_MODULE = 'curentPropertyModule';

    export default {
        name: 'PropertySection',
        props: {
            property: {
                type: Object,
                required: true
            },
            propertyCustomFields: {
                type: Object,
                default: () => {}
            },
            unitsCustomFields: {
                type: Object,
                default: () => {}
            },            
            defaultSelectedTab: {
                type: String,
                default: COMMERCIAL_PROPERTY_TABS.UNITS
            },
            customerRepresentatives: {
                type: Array,
                required: true
            },
            propertyRepresentatives: {
                type: Array,
                required: true
            },
            isShowPropertyInfo: {
                type: Boolean,
                default: true
            }
        },
        components: {
            EditIcon,
            ArrowIcon,
            LinkIcon,
            SettingsIcon,
            CloseIcon,
            Avatar,
            ContactList,
            CommercialJobsTable,
            EditJobCustomFieldsModal: () => import('@/modals/EditJobCustomFieldsModal.vue'),
            CommercialLeadsTable,
            MoveCommercialEntitiesModal,
            Representatives,
            UnitRepresentatives,
            FormDateRangeInput,
            Multiselect,
            PackatsIcon,
            MessagesIcon,
            DocumentsIcon,
            OrdersIcon,
            StarIcon
        },
        data() {
            return {
                statistic: [
                    {
                        name: 'Overview',
                        dev: false,
                        routerName: 'CommercialDetails',
                        icon: 'star-icon',
                        class: 'bg-darkblue-10',
                        iconClass: 'i-blue'
                    },
                    {
                        name: 'Documents',
                        count: 0,
                        dev: false,
                        routerName: 'PropertyDocuments',
                        icon: 'documents-icon',
                        class: 'bg-el-yellow-20',
                        iconClass: 'i-yellow'
                    },
                    {
                        name: 'Events',
                        count: 0,
                        dev: false,
                        routerName: 'PropertyEvents',
                        icon: 'packats-icon',
                        class: 'bg-green-10',
                        iconClass: 'i-green'
                    },
                    {
                        name: 'Messages',
                        count: 0,
                        dev: false,
                        icon: 'messages-icon',
                        class: 'bg-violet-20',
                        iconClass: 'i-violet',
                        inDev: true
                    }
                ],
                customerDetailsIsOpen: false,
                propertyIsOpen: true,
                activeTab: '',
                unitsTabSelected: COMMERCIAL_PROPERTY_TABS.UNITS,
                jobsTabSelected: COMMERCIAL_PROPERTY_TABS.JOBS,
                leadsTabSelected: COMMERCIAL_PROPERTY_TABS.LEADS,
                CUSTOM_FIELDS_SECTION_TYPES: CUSTOM_FIELDS_SECTION_TYPES,
                CUSTOM_FIELDS_TYPES: CUSTOM_FIELDS_TYPES,
                editCustomFieldsModalShow: false,
                selectedCustomFieldsSection: {},
                editedCustomFieldType: '',
                selectToMoveMode: false,
                entitiesToMove: [],
                moveToModalShown: false,
                propertyRepresentativesForRemove: [],
                isJobFiltersShow: false,
                isLeadFiltersShow: false,
                filters: {
                    jobType: [],
                    milestone: [],
                    date: [null, null]
                },
                leadFilters: {
                    date: [null, null]
                },
                loading: false,
                ENTITY_TYPES,
                loadRepresentatives: false
            }
        },
        computed: {
            propertyId() {
                return this.property.id;
            },
            editedCustomFieldId: function() {
                switch (this.editedCustomFieldType) {
                    case 'property':
                        return this.property.id
                        break;
                    case 'unit':
                        return this.editedCustomFieldUnitId;
                        break;
                    default:
                        return null;
                }
            },
            propertyJobsLength () {
                return this.property.jobs.length;
            },
            propertyLeadsLength() {
                return this.property.leads.length;
            },
            propertyUnitsLength() {
                return this.property.units.length;
            },
            customerHistory() {
                return this.property.customer_history;
            },
            filterCount() {
                return this.filters.jobType.length 
                    + this.filters.milestone.length
                    + (this.filters.date.some(item => !item) ? 0 : 1)
            },
            propertyJobsTypes() {
                if (this.propertyJobsLength) {
                    let types = [];
                    this.property.jobs.forEach(t => {
                        types.push(t.type_name);
                    })
                    let uniqueTypes = new Set(types);
                    types = [...uniqueTypes]
                    return types;
                } else {
                    return [];
                }
            },
            propertyMilestones() {
                if (this.propertyJobsLength) {
                    let milestones = [];
                    this.property.jobs.forEach(j => {
                        if (j.m_title) {
                            milestones.push(j.m_title);
                        }
                    })
                    let uniqueMilestones = new Set(milestones);
                    milestones = [...uniqueMilestones]
                    return milestones;
                } else {
                    return [];
                }
            }
        },
        watch: {
            propertyId: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal !== oldVal) {
                        this.getOverviewStatistics();
                    }
                }
            }
        },
        methods: {
            // Method imported from helpers.js
            getGoogleMapLink,
            selectTab(item) {
                if (this.$route.name == item.routerName || !item.routerName) {
                    this.scrollAfterSelectModule();
                    return;
                }

                switch (item.name) {
                    case 'Overview':
                        this.$emit('showComponentInRouterView', false);
                        this.scrollAfterSelectModule();
                        break;
                    case 'Documents':
                        this.$emit('showComponentInRouterView', true);
                        break;
                    case 'Events':
                        this.$emit('showComponentInRouterView', true);
                        break;
                }

                const propertyId = this.$route.params.property_id;

                this.$router.push({
                    name: item.routerName,
                    params: {
                        property_id: propertyId
                    }
                });
            },
            scrollAfterSelectModule() {
                let propertyComponent = document.getElementById('commercial-lead-search');

                if (propertyComponent) { 
                    scrollToElement(propertyComponent);
                }
            },
            showUnitCustomFieldsModal(id, index) {
                this.editedCustomFieldType = 'unit';
                this.editedCustomFieldUnitId = id;
                this.selectedCustomFieldsSection = cloneDeep(this.unitsCustomFields[id].sections[index]);
                this.editCustomFieldsModalShow = true;
            },
            showPropertyCustomFieldsModal(index) {
                this.editedCustomFieldType = 'property';
                this.selectedCustomFieldsSection = cloneDeep(this.propertyCustomFields.sections[index]);
                this.editCustomFieldsModalShow = true;
            },
            closeCustomFieldsModal() {
                this.editedCustomFieldType = '';
                this.editCustomFieldsModalShow = false;

                // clear object
                for (let i in this.selectedCustomFieldsSection) {
                    delete this.selectedCustomFieldsSection[i];
                };
            },
            getCustomFieldsAddress(values) {
                if (values.length) {
                    const addressObject = values[0].object_value 
                        ? JSON.parse(values[0].object_value)
                        : '';
                    return showFullAddress(addressObject) ? showFullAddress(addressObject) : '-';
                }

                return '-';
            },
            getAddress(address) {
                return showFullAddress(address);
            },
            checkIfSecondAddressShow(params) {
                return JSON.parse(params).add_other_address;
                this.editablePrimaryInformation = cloneDeep(this.lead);
            },
            getMultiselectValue(field) {
                if (!field.values.length) {
                    return '-';
                }
                const properties = JSON.parse(field.parameters).properties;
                const values = field.values[0].value.split(', ');
                const textValue = properties
                    .filter(i => values.some(v => v === i.id))
                    .map(i => i.value)
                    .join(', ');

                return textValue;
            },
            updateCustomFields(id) {
                switch (this.editedCustomFieldType) {
                    case 'property':
                        this.$emit('getPropertyCustomFields');
                        break;
                    case 'unit':
                        this.$emit('getUnitsCustomFields', id);
                        break;
                }
            },
            onSelect(isChecked, id) {
                if (isChecked) {
                    this.entitiesToMove.push(id);
                } else {
                    this.entitiesToMove.indexOf(id) !== -1
                        && this.entitiesToMove.splice(this.entitiesToMove.indexOf(id), 1);
                }
            },
            selectAll() {
                switch (this.activeTab) {
                    case COMMERCIAL_PROPERTY_TABS.JOBS: {
                        this.property.jobs.forEach(i => {
                            if (this.entitiesToMove.indexOf(i.id) == -1) {
                                this.entitiesToMove.push(i.id);
                            }
                        });
                    } break;
                    case COMMERCIAL_PROPERTY_TABS.LEADS: {
                        this.property.leads.forEach(i => {
                            if (this.entitiesToMove.indexOf(i.id) == -1) {
                                this.entitiesToMove.push(i.id);
                            }
                        });
                    } break;
                }
            },
            removeMovedEntities(isMovedToCurrentCustomer, propertyId, unitId) {
                this.$emit(
                    'removeMovedEntities',
                    {
                        activeTab: this.activeTab,
                        entitiesToMove: this.entitiesToMove,
                        isMovedToCurrentCustomer,
                        moveToPropertyId: propertyId,
                        moveToUnitId: unitId
                    }
                );

                this.selectToMoveMode = false;
                this.moveToModalShown = false;
                clearArray(this.entitiesToMove);
            },
            exitSelectMode() {
                this.selectToMoveMode = false;
                clearArray(this.entitiesToMove);
            },
            savePropertyRepresentatives(data) {
                this.loadRepresentatives = true;
                const params = { representatives: data };

                if (this.propertyRepresentativesForRemove.length) {
                    Object.assign(params, { representatives_for_remove: this.propertyRepresentativesForRemove });
                }

                api.put(`/commercial/properties/${this.property.id}`, params)
                    .then((response) => {
                        this.notifySuccess('Point of contacts is updated!');
                        this.$emit('updatePropertyRepresentatives', response.data.data.representatives);
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => this.loadRepresentatives = false);
            },
            getOverviewStatistics(updateCounter) {
                if (!updateCounter) {
                    if (this.property.counter && this.property.counter.fields_name) {
                        let newData = this.property.counter.fields_name;
                        this.statistic[1].count = newData.files_count;
                        this.statistic[2].count = newData.events_count;
                        return;
                    }
                }

                const params = {
                    entity_type: MODEL_CLASSES.COMMERCIAL_JOB.PROPERTY,
                    entity_id: this.property.id
                };

                return api.get('/counter', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        if (data.fields_name) {
                            let newData = data.fields_name;
                            this.statistic[1].count = newData.files_count;
                            this.statistic[2].count = newData.events_count;
                        }
                        this.scrollAfterSelectModule();
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            removePropertyModuleFromLocaleStorage() {
                localStorage.removeItem(ROUTE_NAME_CURRENT_PROPERTY_MODULE);
            },
            cleanCancelRepresentative() {
                this.propertyRepresentativesForRemove = [];
            }
        },
        created() {
            this.activeTab = this.defaultSelectedTab;
            this.getOverviewStatistics(false);
        },
        updated() {
            if (localStorage.getItem(ROUTE_NAME_CURRENT_PROPERTY_MODULE)) {
                this.removePropertyModuleFromLocaleStorage();
            }
        }
    }
</script>