<template>
    <label :class="['toggle-switch', smallSize && 'toggle-switch-small']">
        <slot name="default"></slot>
        <span
            :class="[
                'toggle-switch-field relative w-100 h-100',
                `toggle-switch-${color}`,
                smallSize && 'toggle-switch-field-small'
            ]"
        />
    </label>
</template>

<script>
    export default {
        name: 'ToggleSwitch',
        props: {
            color: {
                type: String,
                default: 'green'
            },
            smallSize: {
                type: Boolean,
                default: false
            }
        }
    }
</script>