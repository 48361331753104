<template>
    <svg 
        viewBox="-465 271.38 19.36 19.38" 
        :height="size" 
        :width="size"
        :style="{ transform: 'rotate('+ transform + 'deg)' }"
        class="send-icon"
    >
        <path d="M-449,281l-15.71-1.18c-0.17-0.01-0.29-0.15-0.29-0.32v-7.02c0-0.23,0.24-0.38,0.45-0.29l18.91,8.51c0.25,0.11,0.25,0.47,0,0.58l-18.91,8.51c-0.21,0.09-0.45-0.06-0.45-0.29v-7.02c0-0.17,0.13-0.3,0.29-0.32L-449,281"/>
    </svg>
</template>

<script>
    export default {
        name: 'SendIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            },
            transform: {
                type: [Number, String],
                default: -45
            }
        }
    }
</script>