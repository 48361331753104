var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"jobs-report ph-20 pv-6 mb-40 br-5"},[(_vm.milestonesSetLoaded)?_c('div',{staticClass:"jobs-report-header bb-grey pt-6 mb-20"},[_c('div',{class:[
                    'row-start flex-no-wrap ',
                    _vm.mScreen ? 'pb-1' : ''
                ]},[(_vm.mScreen)?_c('button',{staticClass:"transparent-button pb-8 ph-4 pb-sm-0",on:{"click":function($event){return _vm.swipeNav('left')}}},[_c('arrow-icon',{staticClass:"i-grey",attrs:{"transform":"180","size":"13"}})],1):_vm._e(),_c('div',{ref:"content",class:[
                        'row-start flex-no-wrap nav', 
                        _vm.mScreen && 'mobile-swipe-block'
                    ]},_vm._l((_vm.dashboardReportParamsForBuilder),function(elem,index){return _c('button',{key:index,class:[
                            'transparent-button pb-4 milestone-set-filter relative no-wrap',
                            { 'milestone-set-filter-active' : _vm.selectedReport == elem },
                            _vm.mScreen && 'p-4',
                            !_vm.mScreen && 'mr-20'
                        ],on:{"click":function($event){return _vm.setActiveReport(elem)}}},[_c('span',{class:[
                        'f-sbold mr-2',
                        _vm.selectedReport == elem ? 'f-13-black' : 'f-13-grey'
                    ]},[_vm._v("\n                        "+_vm._s(elem.title)+"\n                    ")]),_c('span',{staticClass:"milestone-entity-counter f-11-black f-sbold s-align-5"},[_vm._v("\n                            "+_vm._s(elem.milestones.count)+"\n                        ")])])}),0),(_vm.mScreen)?_c('button',{staticClass:"transparent-button pb-8 ph-4",on:{"click":function($event){return _vm.swipeNav('right')}}},[_c('arrow-icon',{staticClass:"i-grey",attrs:{"transform":"0","size":"13"}})],1):_vm._e()])]):_vm._e(),(_vm.selectedReport && _vm.milestoneInReport || _vm.groupingMilestoneSet)?_c('div',{class:[
                'filters-section pb-30',
                _vm.mScreen ? 'row-between' : 'row-start'
            ]},[(!_vm.isModuleGrouping)?_vm._l((_vm.milestoneInReport),function(milestone,key,id){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(milestone),expression:"milestone"}],key:id,class:[
                        'lg-3 md-4 sm-12',
                        { 'none-events' : _vm.loading }
                    ],on:{"click":function($event){return _vm.setMilestone(key, null)}}},[_c('div',{class:[
                            'ml-4 w-100 milestone-filter pv-5 ph-8 mb-4 pointer lg-3 md-4 sm-6 br-5',
                            { 'milestone-filter-active' : _vm.selectedMilestone == key },
                            _vm.mScreen ? 'column-reverse' : 'row-between'
                        ]},[_c('div',{class:['column-start', _vm.mScreen && 'mt-8']},[_c('span',{staticClass:"f-14-black f-sbold break-all"},[_vm._v("\n                                "+_vm._s(key)+"\n                            ")]),_c('span',{staticClass:"f-12-grey"},[_vm._v("\n                                "+_vm._s(milestone.count)+" leads\n                            ")])]),_c('span',{class:[
                                'milestone-status-marker capitalize',
                                { 'status-marker-lightblue':  milestone.isNew }
                            ],style:(("background-color: " + (milestone.color)))},[_vm._v("\n                            "+_vm._s(_vm.getStatusMarker(key))+"\n                        ")])])])}):_vm._e(),(_vm.isModuleGrouping && !_vm.allReportLoading)?[_c('div',{staticClass:"row-start"},[_c('div',{staticClass:"column-start lg-6 pr-8 br-5 md-12 sm-12 "},[_c('p',{staticClass:"f-16-black f-sbold mb-6 ml-4"},[_vm._v("Commercial")]),_c('div',{staticClass:"row-start"},_vm._l((_vm.groupingMilestoneSet),function(milestone,key,id){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getCommercialLeadsCounts(milestone)),expression:"getCommercialLeadsCounts(milestone)"}],key:id,class:[
                                    'lg-6 md-4 sm-12',
                                    { 'none-events' : _vm.loading }
                                ],on:{"click":function($event){return _vm.setMilestone(key, 'Commercial')}}},[_c('div',{class:[
                                        'ml-4 w-100 milestone-filter br-5 pv-5 ph-8 mb-4 pointer lg-3 md-4 sm-6',
                                        { 'milestone-filter-active' : _vm.selectedMilestone == key && _vm.commercialType },
                                        _vm.mScreen ? 'column-reverse' : 'row-between'
                                    ]},[_c('div',{class:['column-start', _vm.mScreen && 'mt-8']},[_c('span',{staticClass:"f-14-black f-sbold break-all"},[_vm._v("\n                                            "+_vm._s(key)+"\n                                        ")]),_c('span',{staticClass:"f-12-grey"},[_vm._v("\n                                            "+_vm._s(_vm.getCommercialLeadsCounts(milestone))+" leads\n                                            "),_c('span',{staticClass:"f-9-orange f-bold uppercase orange-label ml-1"},[_vm._v("\n                                                Commercial\n                                            ")])])]),_c('span',{class:[
                                            'milestone-status-marker capitalize',
                                            { 'status-marker-lightblue':  milestone.isNew }
                                        ],style:({'background-color': _vm.getColor(milestone)})},[_vm._v("\n                                        "+_vm._s(_vm.getStatusMarker(key))+"\n                                    ")])])])}),0)]),_c('div',{staticClass:"column-start lg-6 br-5 md-12 sm-12"},[_c('p',{staticClass:"f-16-black f-sbold mb-6 ml-4"},[_vm._v("Residential")]),_c('div',{staticClass:"row-start"},_vm._l((_vm.groupingMilestoneSet),function(milestone,key,id){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getResidentialLeadsCounts(milestone)),expression:"getResidentialLeadsCounts(milestone)"}],key:id,class:[
                                    'lg-6 md-4 sm-12',
                                    { 'none-events' : _vm.loading }
                                ],on:{"click":function($event){return _vm.setMilestone(key, 'Residential')}}},[_c('div',{class:[
                                        'ml-4 w-100 milestone-filter pv-5 ph-8 mb-4 pointer lg-6 md-4 sm-6 br-5',
                                        { 'milestone-filter-active' : _vm.selectedMilestone == key && !_vm.commercialType },
                                        _vm.mScreen ? 'column-reverse' : 'row-between'
                                    ]},[_c('div',{class:['column-start', _vm.mScreen && 'mt-8']},[_c('span',{staticClass:"f-14-black f-sbold break-all"},[_vm._v("\n                                            "+_vm._s(key)+"\n                                        ")]),_c('span',{staticClass:"f-12-grey"},[_vm._v("\n                                            "+_vm._s(_vm.getResidentialLeadsCounts(milestone))+" leads\n                                            "),_c('span',{staticClass:"f-bold uppercase trade-label ml-1"},[_vm._v("\n                                                Residential\n                                            ")])])]),_c('span',{class:[
                                            'milestone-status-marker capitalize',
                                            { 'status-marker-lightblue':  milestone.isNew }
                                        ],style:({'background-color': _vm.getColor(milestone)})},[_vm._v("\n                                        "+_vm._s(_vm.getStatusMarker(key))+"\n                                    ")])])])}),0)])])]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"jobs-report-table"},[_c('div',{staticClass:"w-100"},[_c('div',{class:[
                        'ph-20',
                        { 'dashboard-table-wrapper' : _vm.dScreen || _vm.nScreen }
                    ],style:({ minHeight: _vm.reportTableHeight })},[(_vm.dScreen || _vm.nScreen && _vm.tableHeader.length)?_c('div',{staticClass:"row-start f-12-black f-sbold pb-15"},_vm._l((_vm.tableHeader),function(title,index){return _c('div',{key:index,style:({
                                width: _vm.tableCellWidth(title),
                                padding: _vm.tableHeader.length > 10 && _vm.dScreen ? '20px 3px' : ''
                            })},[_c('button',{staticClass:"transparent-button",on:{"click":function($event){return _vm.changeSort(_vm.relationTableHeaderAndBody[index])}}},[_c('move-icon',{class:[
                                        'order-by-icon', 
                                        _vm.relationTableHeaderAndBody[index] === _vm.sortColumn ? ("order-" + _vm.orderBy) : ''
                                    ],attrs:{"transform":"90"}}),_c('span',{staticClass:"pl-1 f-sbold f-9-grey uppercase"},[_vm._v("\n                                    "+_vm._s(title)+"\n                                ")])],1)])}),0):_vm._e(),(_vm.loading || _vm.sortLoading || !_vm.milestonesSetLoaded || _vm.reportRecordsLoaded)?_c('loader',{staticClass:"s-align-5",attrs:{"size":_vm.loading ? 'small' : 'full'}}):_vm._e(),_vm._l((_vm.reportRecords),function(report,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],key:("report-" + index),class:[
                            'table-row row-start row-baseline flex-no-wrap f-14-black pv-3',
                            { 'bb-grey' : index != _vm.reportRecords.length -1 }
                        ]},[_vm._l((_vm.tableHeader),function(cell,index){return _c('div',{key:index,staticClass:"md-12 sm-12",style:({
                                width: _vm.tableCellWidth(cell),
                                padding: _vm.tableHeader.length > 10 && _vm.dScreen ? '20px 3px' : ''
                            })},[_c('div',{staticClass:"row-between row-baseline"},[_c('div',{class:[
                                        _vm.mScreen ? 'column-reverse sm-12 mb-sm-1' : ''
                                    ]},[_c('span',{class:[
                                            'table-cell md-12',
                                            cell === _vm.SPECIAL_HEADER_NAME.JOB_NAME ? 'column-start s-align-1' : '',
                                            cell === _vm.SPECIAL_HEADER_NAME.PRIORITY 
                                            ? 'priority-tag f-11-black f-sbold ph-2 s-align-5' : '',
                                            _vm.mScreen ? 'sm-12 s-align-5' : 'pl-2'
                                        ],style:({
                                            backgroundColor: cell === _vm.SPECIAL_HEADER_NAME.PRIORITY 
                                            ? ("" + (_vm.hexToRgba(report.color_code, 0.1))) : '',
                                            color: cell === _vm.SPECIAL_HEADER_NAME.PRIORITY 
                                            ? ("" + (report.color_code)) : ''
                                        })},[(cell === _vm.SPECIAL_HEADER_NAME.AGE)?_c('span',[_c('age-icon',{staticClass:"i-blue mr-3",attrs:{"size":"14"}})],1):_vm._e(),(cell === _vm.SPECIAL_HEADER_NAME.BASE_BID
                                            || cell === _vm.SPECIAL_HEADER_NAME.ESTIMATES_PRICE_TOTAL
                                            || cell === _vm.SPECIAL_HEADER_NAME.EXPECTED_REVENUE)?[_vm._v("\n                                            $"+_vm._s(_vm._f("numberWithCommas")(_vm.getReportField(report, index)))+"\n                                        ")]:[_vm._v("\n                                            "+_vm._s(_vm.getReportField(report, index))+"\n                                        ")],(cell === _vm.SPECIAL_HEADER_NAME.AGE)?[_vm._v("\n                                            d\n                                        ")]:_vm._e(),(cell === _vm.SPECIAL_HEADER_NAME.JOB_NAME && report.bid_due_time)?_c('span',{staticClass:"f-12-grey s-align-1"},[_vm._v("\n                                            "+_vm._s(_vm.checkDueTime(report.bid_due_time))+"\n                                        ")]):_vm._e()],2),(_vm.mScreen)?_c('span',{staticClass:"table-hint f-10-grey uppercase mb-4 sm-12 s-align-5"},[_vm._v("\n                                        "+_vm._s(cell)+"\n                                    ")]):_vm._e()])])])}),_c('div',{class:[
                            'table-cell lg-2 md-12 sm-12 pl-2',
                            _vm.dScreen ? 's-align-6' : 's-align-4 sm-mt-4',
                            _vm.mScreen ? 's-align-5 pl-sm-0' : ''
                        ]},[_c('button',{staticClass:"transparent-button p-1",on:{"click":function($event){return _vm.openOverviewPage(report)}}},[_c('span',{staticClass:"f-12-blue"},[_vm._v("\n                                    View\n                                ")]),_c('arrow-icon',{staticClass:"ml-1 i-blue",attrs:{"size":"7","transform":0}})],1)])],2)}),(!_vm.loading && _vm.reportRecords && !_vm.reportRecords.length)?_c('not-found-message',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"custom":"Nothing to display","tableEmpty":""}}):_vm._e()],2)])]),_c('div',{staticClass:"pagination-wrapper row-between pt-8 bt-grey"},[_c('div',{class:[
                    's-align-4 lg-6 nb-7 md-12 sm-12',
                    { 'row-start' : _vm.tScreen },
                    { 'column-start' : _vm.mScreen }    
                ]},[_c('div',{class:[
                        'row-start f-13-grey',
                        _vm.mScreen ? 's-align-5 md-12' : 's-align-4 md-5'
                    ]},[_c('span',{staticClass:"f-sbold mr-2"},[_vm._v("\n                        "+_vm._s(_vm.totalItems)+" \n                    ")]),_vm._v("\n                    results | Display\n                    "),_c('div',{staticClass:"ml-3"},[_c('multiselect',{staticClass:"pagination-wrapper-select s-align-4",attrs:{"options":_vm.perPageOptions,"showLabels":false},on:{"input":function($event){return _vm.loadPage(1)},"select":_vm.clearIndexedDB},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('span',{staticClass:"ml-30"},[_vm._v("\n                        per page\n                    ")])]),(_vm.totalPages > 1)?_c('pagination',{class:[
                        'job-report-pagination md-7 mt-1 mt-md-2 mt-sm-2',
                        _vm.dScreen || _vm.mScreen ? 'w-50' : 'w-100',
                        { 's-align-9 row-baseline' : _vm.tScreen }
                    ],attrs:{"activePage":Number(_vm.page),"quantity":_vm.totalPages,"showDesc":false,"theme":"minimal"},on:{"click":_vm.loadPage}}):_vm._e()],1),(_vm.totalPages > 1)?_c('div',{class:[
                    'mt-md-2',
                    _vm.mScreen ? 's-align-5 md-12 mt-8 sm-12' : 's-align-6'
                ]},[_c('div',{staticClass:"s-align-4 mr-5"},[_c('span',{staticClass:"f-13-grey"},[_vm._v("\n                        Go to page\n                    ")]),_c('form-input',{staticClass:"ml-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.goToPageCount),expression:"goToPageCount"}],staticClass:"form-input-field",attrs:{"type":"text","name":"go_to_page"},domProps:{"value":(_vm.goToPageCount)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.goToPage()},"input":function($event){if($event.target.composing){ return; }_vm.goToPageCount=$event.target.value}}})])],1),_c('button',{staticClass:"primary-button",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.goToPage()}}},[_vm._v("\n                    Go\n                ")])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }