<template>
    <div class="material-colors-popup s-align-5">
        <div class="material-colors-box hidden">
            <div class="pt-20 pb-2 pr-6 pl-8">
                <div class="row-between row-baseline pb-4">
                    <p class="f-14-asphalt f-sbold">Available colors</p>
                    <button 
                        class="icon-light"
                        @click="$emit('close')"
                    >
                        <close-icon size="14"/>
                    </button>
                </div>
                <div class="material-colors-search s-align-4 p-4">
                    <search-icon 
                        size="12" 
                        class="i-grey"
                    />
                    <input 
                        type="text" 
                        name="material-colors-search"
                        placeholder="Search for a color"
                        class="pl-2"
                        v-model="needToFind"
                        @input="checkHideColors"
                    />
                </div>
            </div>
            <div>
                <p class="f-12-asphalt f-sbold ph-8 pv-1">Results:</p>
                <perfect-scrollbar class="material-colors-scroll ps-blue">
                    <ul>
                        <li 
                            v-for="(color, index) in colors"
                            :key="`material-color-${index}`"
                            :class="['material-color w-100', { 'hide' : !searchColor(color.name) }]"
                            v-show="color.id != currentColorId"
                        >
                            <button 
                                class="s-align-4 t-left ph-8 pv-2 w-100"
                                @click="update(color)"
                            >
                                {{ color.name }}
                            </button>
                        </li>
                    </ul>
                    <div
                        class="ph-8 pv-2 w-100"
                        v-if="nothingWasFound"
                    >
                        <span class="f-12-grey">
                            Nothing was found. Try searching for a different color.
                        </span>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
    import { CloseIcon, SearchIcon } from '@/icons';
    import api from '@/headers.js';

    export default {
        name: 'MaterialColorsPopup',
        components: {
            CloseIcon,
            SearchIcon
        },
        props: {
            materialId: {
                type: [String, Number],
                required: true
            },
            open: {
                type: Boolean,
                default: false
            },
            colors: {
                type: Array,
                required: true
            },
            sectionIndex: {
                type: [String, Number],
                required: true
            },
            itemIndex: {
                type: [String, Number],
                required: true
            },
            currentColorId: {
                type: [String, Number],
                required: true
            }
        },
        data() {
            return {
                needToFind: '',
                nothingWasFound: false
            }
        },
        computed: {
            colorsLength() {
                return this.colors.length;
            }
        },
        methods: {
            searchColor(name) {                
                return name.toLowerCase().includes(this.needToFind.toLowerCase());
            },
            checkHideColors() {
                this.$nextTick(function() {
                    let result = 0;
                    [].slice.call(this.$el.querySelectorAll('.material-color')).map((item) => {
                        if (item.classList.contains('hide'))
                            result++
                    });
                    
                    this.nothingWasFound = result == this.colorsLength;
                });           
            },
            update(color) {
                let data = {
                    id: color.id,
                    materialId: this.materialId,
                    sectionIndex: this.sectionIndex,
                    itemIndex: this.itemIndex
                };

                this.$emit('update', data);
            },
            outside(event) {
                if (!this.$el.contains(event.target) && this.open)
                    this.$emit('close');
            }
        },
        mounted() {
            document.body.addEventListener('click', this.outside);
        },
        destroyed() {
            document.body.removeEventListener('click', this.outside);
        }
    }
</script>