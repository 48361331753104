<template>
    <div class="audit-list-table">
        <scroll-table
            :head="tableHeadOverflow"
            :filled="dataItems.length"
            :name="AUDIT_ENTITY_TYPES_LABEL[title]"
            :requestStatus="true"
        >
            <div
                class="table-body"
                v-for="(row, index) in dataItems"
                :key="index"
                :ref='`body-${index}`'
            >
                <div class="table-row">
                    <div class="scroll-container pointer" @click.stop="toggleDropdown(index)">
                        <div class="table-td">
                            <span class="cell">{{ row.updatedAt | updatedAt }}</span>
                        </div>
                        <div class="table-td">
                            <span class="cell">{{ row.eventType }}</span>
                        </div>
                        <div class="table-td">
                            <span class="cell">{{ row.jobNumber }}</span>
                        </div>
                        <div class="table-td">
                              <span class="cell">{{ row.customer }}</span>
                        </div>
                        <div class="table-td">
                            <span class="cell">{{ row.createdBy }}</span>
                        </div>
                    </div>
                    <button class="content-open-button" >
                        <arrow-icon/>
                    </button>
                </div>
                <div class="table-content audit-list-table-content">
                    <div class="table-content-container">
                          <div
                              class="table-content-title table-content-title-green f-14-darkgrey mb-4"
                              v-if="row.new_values.length"
                          >New values:</div>
                          <div
                              class="audit-list-table-content__items mb-8"
                              v-if="row.new_values.length"
                              :class="row.new_values.length <= 5 && 'one-columns'"
                          >
                                <div v-for="item in row.new_values">
                                    <span class="f-14-grey">{{ item.key }}:</span>
                                    <span class="ml-1 f-14-darkgrey">{{ item.value }}</span>
                                </div>
                            </div>
                          <div
                              class="table-content-title table-content-title-orange f-14-darkgrey mb-4"
                              v-if="row.old_values.length"
                          >Old values:</div>
                          <div
                              class="audit-list-table-content__items"
                              v-if="row.old_values.length"
                              :class="row.old_values.length <= 5 && 'one-columns'"
                          >
                                <div v-for="item in row.old_values">
                                  <span class="f-14-grey">{{ item.key }}:</span>
                                  <span class="ml-1 f-14-darkgrey">{{ item.value }}</span>
                                </div>
                          </div>
                    </div>
                </div>
            </div>
        </scroll-table>
    </div>
</template>

<script>
    import ScrollTable from '@/components/ScrollTable.vue';
    import { ArrowIcon } from '@/icons';
    import {
      AUDIT_ENTITY_TYPES,
      AUDIT_ENTITY_TYPES_LABEL,
      AUDIT_EVENT_TYPE,
      AUDIT_KEYS_LABEL,
      MILESTONE_STATUSES_LABEL
    } from "../../constants";

    export default {
        name: 'JobMaterialTable',
        components: {
            ScrollTable,
            ArrowIcon
        },
        props: {
            data: {
              type: [Array],
              required: true
            },
          title: {
              type: String,
              required: true,
          }
        },
        data() {
            return {
              AUDIT_ENTITY_TYPES_LABEL,
            }
        },
        computed: {
          tableHeadOverflow() {
            switch (this.title) {
              case AUDIT_ENTITY_TYPES["Modules\\Commercial\\Entities\\CommercialLead"]:
              case AUDIT_ENTITY_TYPES["App\\Models\\Lead"]:
                return ['Updated at', 'Event type', 'Lead #','Customer', 'Who Change'];
              case AUDIT_ENTITY_TYPES["Modules\\Milestone\\Entities\\EntityMilestone"]:
                return ['Updated at', 'Event type', 'Job #','Customer', 'Who Change'];
              default:
                return ['Updated at', 'Event type', 'Job #','Customer', 'Who Change'];
            }
          },
          dataItems() {
            const res = this.data.map(el => {
              const createdBy = `${el.user.fullname} (${el.ip_address})`;
              const new_values = this.filterData(el.new_values);
              const old_values = this.filterData(el.old_values);
              const new_join_values = el.new_values.join_values;
              const old_join_values = el.old_values.join_values;
              const jobNumber = el.event === AUDIT_EVENT_TYPE.deleted
                  ? (old_join_values && old_join_values.job_number) || '-'
                  : (new_join_values && new_join_values.job_number) || '-';
              const customer = el.event === AUDIT_EVENT_TYPE.deleted
                  ? (old_join_values && old_join_values.customer) || '-'
                  : (new_join_values && new_join_values.customer) || '-'
              return {
                updatedAt: el.updated_at,
                eventType: el.event,
                jobNumber,
                customer,
                createdBy,
                new_values,
                old_values,
              }
            });
            return res;
          }
        },
        methods: {
          filterData(el) {
            const {currency, phone, updatedAt} = this.$options.filters;
            return Object.keys(el)
                .filter(key => key !== 'join_values')
                .map(key => {
                  let value = el[key];
                  switch (key) {
                    case AUDIT_KEYS_LABEL.amount:
                      value = currency(value);
                      break;
                    case AUDIT_KEYS_LABEL.probability:
                      value = `${value}%`;
                      break;
                    case AUDIT_KEYS_LABEL.tel1:
                    case AUDIT_KEYS_LABEL.tel2:
                      value = phone(value);
                      break;
                    case AUDIT_KEYS_LABEL.status:
                      value = MILESTONE_STATUSES_LABEL[value];
                      break;
                    case AUDIT_KEYS_LABEL.bid_due_date:
                      value = updatedAt(value);
                      break;
                  }
                  return {key, value}
                });
          },
          toggleDropdown(idx) {
            const bodyEl = this.$refs[`body-${idx}`];
            if (bodyEl && bodyEl.length) {
              if (bodyEl[0].classList.contains("opened")) {
                bodyEl[0].classList.remove('opened');
              }else{
                bodyEl[0].classList.add('opened');
              }
            }
          }
        }
    }
</script>

