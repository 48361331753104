<template>
    <div 
        class="milestone-update column-start w-100"
        v-if="milestones.length"
    >
        <div class="milestone-update-top row-between">
            <div class="milestone-update-title lg-6 sm-12 ls-sm-6">
                <div class="milestone-update-icon s-align-5">
                    <pointer-icon class="i-white"/>
                </div>
                <div class="column-start pl-8">
                    <span class="f-14-black f-sbold">
                        {{ `Next step to ${nextMilestoneTitle}` }}
                    </span>
                    <div
                        v-if="reDispathCounter"
                        class="f-12-black mt-1 row-baseline"
                    >
                        <span class="f-12-grey mr-1">Last changes:</span>
                        {{ lastDateReDispatch | date }} &#8226;
                        <button
                            v-if="reDispathCounter"
                            class="transparent-button f-12-blue ml-1"
                            @click="showReDispatchLog"
                        >
                            {{ reDispathCounter }}
                        </button>
                    </div>
                </div>
                <button
                    v-if="showReDispatchButton"
                    v-can="[$permissions.EDIT_MILESTONES]"
                    class="primary-button ml-20"
                    @click="milestoneReDispatchModalShown = true"                                     
                >
                    Re-Dispatch
                </button>
            </div>
            <div class="milestone-update-buttons lg-6 sm-12 ls-sm-6">
                <button 
                    class="orange-button"
                    @click="milestoneConvertModalShown = true"                                     
                >
                    Convert to {{ nextMilestoneTitle }}
                </button>
                <button
                    v-if="isDead"
                    class="transparent-button p-8"
                    :disabled="inprogressIndex == -1"
                    @click="milestoneCancelModalShown = true"
                >
                    <span class="f-12-black f-sbold">Mark as Dead</span>
                </button>
            </div>
        </div>
        <milestone-convert-modal
            v-if="milestoneConvertModalShown"
            :nextMilestoneTitle="nextMilestoneTitle"
            :entityType="entityType"
            :inprogressIndex="inprogressIndex"
            :checkingQueryToRedirect="checkingQueryToRedirect"
            @redirectToConvertLeadPage="(data) => $emit('redirectToConvertLeadPage', data)"
            @close="milestoneConvertModalShown = false"
        />
        <milestone-cancel-modal
            v-if="milestoneCancelModalShown"
            :entityType="entityType"
            :groupId="groupId"
            :job-number="jobNumber"
            @close="milestoneCancelModalShown = false"
        />
        <milestone-re-dispatch
            v-if="milestoneReDispatchModalShown"
            :baseEntityType="entityType"
            :reDispatchLog="reDispatchLog"
            :reDispatchFromToMilestones="reDispatchFromToMilestones"
            :viewReDispatchLog="viewReDispatchLog"
            :allCalendars="calendarList"
            :lastEvent="lastRedispatchEvent"
            @close="closeReDispathcModal"
            @updateReDispatchLog="updateReDispatchLog"
            @saveCalendarList="saveCalendarList"
            @saveLastEvent="saveLastEvent"
        />
    </div>
</template>

<script>
    import { MilestoneConvertModal, MilestoneCancelModal, MilestoneReDispatch } from '@/modals';
    import { PointerIcon } from '@/icons';
    import { ENTITY_TYPES, BASE_ENTITY_TYPES, MODEL_CLASSES, RE_DISPATCH_STATUSES } from '@/constants';
    import api from '@/headers.js';

    export default {
        name: 'MilestonesUpdate',
        components: {
            MilestoneConvertModal,
            MilestoneCancelModal,
            MilestoneReDispatch,
            PointerIcon
        },
        props: {
            entityType: {
                type: [String],
                required: true
            },
            customerId: {
                type: [String, Number],
                required: false
            },
            groupId: {
                type: [String, Number],
                default: ''
            },
            checkingQueryToRedirect: {
                type: Boolean,
                required: false
            },
            jobNumber: {
                type: [String, Number],
                required: false
            },
            isDead: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                milestoneConvertModalShown: false,
                milestoneCancelModalShown: false,
                milestoneReDispatchModalShown: false,
                reDispatchLog: null,
                viewReDispatchLog: false,
                reDispatchFromToMilestones: [],
                calendarList: null,
                lastRedispatchEvent: null
            }
        },
        computed: {
            milestones() {
                return this.$store.getters['milestones/getMilestones'];
            },
            inprogressIndex() {
                return this.$store.getters['milestones/getMilestoneInprogressIndex'];
            },
            lastDoneIndex() {
                return this.$store.getters['milestones/getMilestoneLastDoneIndex'];
            },
            nextMilestoneTitle() {
                if (this.inprogressIndex !== -1 && this.milestones[this.inprogressIndex + 1]) {
                    return this.milestones[this.inprogressIndex + 1].milestone.title;
                }

                if (this.lastDoneIndex !== -1 && this.milestones[this.lastDoneIndex + 1]) {
                    return this.milestones[this.lastDoneIndex + 1].milestone.title;
                }

                return this.lastMilestone.milestone.title;
            },
            lastMilestone() {
                return this.$store.getters['milestones/getMilestoneLast'];
            },
            entityId() {
                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL:
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.$route.params.lead_id;
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.$route.params.job_id;
                    case BASE_ENTITY_TYPES.OPPORTUNITY:
                        return this.$route.params.opportunity_id;
                    case BASE_ENTITY_TYPES.WARRANTY_JOB:
                        return this.$route.params.job_id;
                }
            },
            modelClass() {
                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL:
                        return MODEL_CLASSES.RESIDENTIAL_LEAD;
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                        return MODEL_CLASSES.COMMERCIAL_LEAD;
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        return MODEL_CLASSES.COMMERCIAL_JOB.JOB;
                    case BASE_ENTITY_TYPES.OPPORTUNITY:
                        return MODEL_CLASSES.OPPORTUNITY;
                    case BASE_ENTITY_TYPES.WARRANTY_JOB:
                        return MODEL_CLASSES.WARRANTY_JOB;
                }
            },
            showReDispatchButton() {
                return this.milestones[this.inprogressIndex].redispatch_status === RE_DISPATCH_STATUSES.DISPATCHED_FROM;
            },
            reDispathCounter() {
                if (this.reDispatchLog && this.reDispatchLog.length) {
                    return this.reDispatchLog.length > 1 
                        ? `${this.reDispatchLog.length} times` 
                        : `1 times`;
                }
            },
            lastDateReDispatch() {
                if (this.reDispatchLog && this.reDispatchLog.length) {
                    return this.reDispatchLog[0].updated_at;
                }
            },
            entityTypeForEvent() {
                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL:
                        return ENTITY_TYPES.RESIDENTIAL_LEAD;
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                        return ENTITY_TYPES.COMMERCIAL_LEAD;
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        return ENTITY_TYPES.COMMERCIAL_JOB.JOB;
                    case BASE_ENTITY_TYPES.OPPORTUNITY:
                        return ENTITY_TYPES.OPPORTUNITY;
                    case BASE_ENTITY_TYPES.WARRANTY_JOB:
                        return ENTITY_TYPES.WARRANTY_JOB;
                }
            }
        },
        methods: {
            getReDispatchLog() {
                let params = {
                    entity_id: this.entityId,
                    entity_type: this.modelClass
                };

                api.get('/milestones/redispatch', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        this.reDispatchLog = data;
                    })
                    .catch(error => this.notifyRequestErrors(error));
            },
            showReDispatchLog() {
                this.viewReDispatchLog = true;
                this.milestoneReDispatchModalShown = true;
            },
            closeReDispathcModal() {
                this.milestoneReDispatchModalShown = false;
                this.viewReDispatchLog = false;
            },
            updateReDispatchLog(data) {
                this.reDispatchLog.unshift(data.redispatch);
                this.milestoneReDispatchModalShown = false;
                this.$store.dispatch('milestones/GET_MILESTONES', {
                    entityId: this.entityId,
                    entityType: this.entityType
                })
                .catch(error => {
                    this.notifyRequestErrors(error);
                });
                this.$store.dispatch('calendars/GET_EVENT', {
                    entity_id: this.entityId,
                    entity_type: this.entityTypeForEvent
                })
                .catch(error => {
                    this.notifyRequestErrors(error);
                });
            },
            saveCalendarList(data) {
                this.calendarList = data;
            },
            saveLastEvent(event) {
                this.lastRedispatchEvent = event;
            }
        },
        created() {
            this.getReDispatchLog();
            this.reDispatchFromToMilestones = this.milestones.filter(m => {
                return m.redispatch_status === RE_DISPATCH_STATUSES.DISPATCHED_FROM
                    || m.redispatch_status === RE_DISPATCH_STATUSES.DISPATCHED_TO;
            });
        }
    }
</script>
