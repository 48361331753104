<template>
    <full-modal
        @close="$emit('close')"
        class="workflow-kanban-modal"
    >
        <div slot="title">Project management card settings</div>
        <div slot="body">
            <loader
                size="small"
                v-if="loading"
            />
            <div 
                class="w-100"
                v-else
            >
                <div class="content-box column-start w-100 p-4 mb-4">
                    <p class="f-13-asphalt f-sbold pb-2">Lead/Job type:</p>
                    <multiselect
                        :options="filteredLeadTypes"                                        
                        name="lead_type"
                        :showLabels="false"
                        placeholder="Lead/Job type"
                        v-validate="'required'"
                        v-model="leadType"
                        label="name"
                        track-by="id"
                        :class="['multiselect-small', { 'multiselect-danger' : errors.has('lead_type') }]"
                        @select="selectLeadType"
                    />
                </div>
                <div class="content-box column-start w-100 p-4 mb-4">
                    <p class="f-13-asphalt f-sbold pb-2">Project management board:</p>
                    <multiselect
                        :options="boards"                                        
                        name="project_management_board"
                        :showLabels="false"
                        placeholder="Project management board"
                        v-validate="'required'"
                        v-model="projectManagementBoard"
                        label="name"
                        track-by="id"
                        :class="['multiselect-small', { 'multiselect-danger' : errors.has('project_management_board') }]"
                        @select="selectProjectManagementBoard"
                        @remove="deselectProjectManagementBoard"
                        :disabled="!leadType"
                    />
                </div>
                <div class="content-box column-start w-100 p-4 mb-4 relative">
                    <p class="f-13-asphalt f-sbold pb-2">Choose column:</p>
                    <multiselect
                        :options="colunms"                                        
                        name="choose_column"
                        :showLabels="false"
                        placeholder="Choose column"
                        v-validate="action.isArchive ? '' : colunmValidateRule"
                        v-model="selectedColumn"
                        label="title"
                        track-by="id"
                        :class="['multiselect-small', { 'multiselect-danger' : errors.has('choose_column') }]"
                        @select="selectColumn"
                        :disabled="!projectManagementBoard || columnLoading || action.isArchive"
                    />
                    <loader
                        size="mini"
                        class="kanban-column-loader"
                        v-if="columnLoading"
                    />
                </div>
                <checkbox-button
                    v-if="actionType === 'move card'"
                    label="Move to archive"
                    class="ml-4 f-12-black"
                >
                    <input 
                        type="checkbox" 
                        name="is_archive"
                        v-model="action.isArchive"
                    />
                </checkbox-button>
            </div>
        </div>
        <div slot="footer">
        <button 
            class="add-button mb-2"
            @click="$emit('close')"
        >
            Cancel
        </button>
        <button 
            class="primary-button mb-2"
            @click="validateBeforeSave"
        >
            Save
        </button>
    </div>
    </full-modal>
</template>

<script>
    import { FullModal, Loader, CheckboxButton } from '@/ui';
    import { ENTITY_TYPES, ENTITY_LEAD_TYPES } from '@/constants';
    import Multiselect from 'vue-multiselect';
    import api from '@/headers.js';

    const VALIDATION_REQUIRED = 'required';

    export default {
        name: 'WorkflowKanbanModal',
        components: {
            FullModal,
            Multiselect,
            Loader,
            CheckboxButton
        },
        props: {
            leadTypes: {
                type: Array,
                required: true
            },
            entityName: {
                type: String,
                required: true
            },
            existingData: {
                type: Object,
                required: true
            },
            actionType: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                action: {
                    leadTypeId: '',
                    boardId: '',
                    columnId: '',
                    isArchive: false
                },
                leadType: null,
                projectManagementBoard: null,
                selectedColumn: null,
                loading: false,
                columnLoading: false,
                boards: [],
                colunms: [],
                colunmValidateRule: ''
            }
        },
        computed: {
            filteredLeadTypes() {
                if (this.entityName == ENTITY_TYPES.RESIDENTIAL_LEAD 
                    || this.entityName == ENTITY_TYPES.RESIDENTIAL_JOB) {
                    return this.leadTypes.filter(t => t.entity_type == ENTITY_LEAD_TYPES.RESIDENTIAL);
                } else if (this.entityName == ENTITY_TYPES.COMMERCIAL_LEAD 
                    || this.entityName == ENTITY_TYPES.COMMERCIAL_JOB) {
                    return this.leadTypes.filter(t => t.entity_type == ENTITY_LEAD_TYPES.COMMERCIAL);
                }

                return [];
            },
            editMode() {
                return Object.keys(this.existingData).length;
            }
        },
        methods: {
            getBoards() {
                this.loading = true;
                api.get('/kanban/boards')
                    .then(response => {
                        this.boards = response.data.data;
                    })
                    .finally(() => {
                        this.loading = false;

                        if (this.editMode) {
                            this.action = this.existingData;
                            
                            this.leadType = this.leadTypes.find(t => t.id == this.action.leadTypeId);
                            this.projectManagementBoard = this.boards.find(b => b.id == this.action.boardId);

                            this.getColumn(this.action.boardId);
                        }
                    });
            },
            getColumn(id) {
                this.columnLoading = true;
                api.get(`kanban/columns?board_id=${id}`)
                    .then(response => {
                        this.colunms = response.data.data;
                    })
                    .finally(() => {
                        this.columnLoading = false;

                        if (this.editMode) {
                            this.selectedColumn = this.colunms.find(c => c.id == this.action.columnId);
                        }
                    });
            },
            selectLeadType(data) {
                this.action.leadTypeId = data.id;
            },
            selectProjectManagementBoard(data) {
                this.action.boardId = data.id;
                this.action.columnId = '';
                this.selectedColumn = null;
                this.getColumn(data.id);
            },
            deselectProjectManagementBoard() {
                this.action.columnId = '';
                this.selectedColumn = null;
            },
            selectColumn(data) {
                this.action.columnId = data.id;
            },
            validateBeforeSave() {
                this.colunmValidateRule = VALIDATION_REQUIRED;

                this.$nextTick(function() {
                    this.$validator.validateAll().then(result => {
                        if (!result) {
                            this.notifyError('All fields are required.');
                            return;
                        }

                        if (this.action.isArchive) { delete this.action.columnId }
                        if (this.actionType !== 'move card') { delete this.action.isArchive }
                        
                        this.$emit('setKanbanAction', this.action);
                    });
                });
            }
        },
        created() {
            this.getBoards();
        }
    }
</script>