<template>
    <div>
        <div 
            :class="[
                'folder w-100 mb-3', `document-folder-${index}`,
                { 'folder-placeholder': folderOverId == folder.id }
            ]"
            :draggable="dScreen && !selectedMode"
            @dragstart="folderDragStart"
            @dragenter="$emit('folderDragEnter')"
            @dragend="$emit('folderDragEnd')"
        >
            <div :class="['w-box d-flex', { 'grabbable': dScreen && !selectedMode }]">
                <div
                    class="s-align-4 lg-5 sm-8 pr-4"
                    @click="startToEditFolderName"
                >
                    <div
                        class="mr-20 s-align-5 h-100"
                        v-if="dScreen"
                    >
                        <drag-icon :class="['i-grey', { 'opacity-5': selectedMode }]"/>
                    </div>
                    <div class="folder-name-wrapper">
                        <input
                            type="text"
                            name="folder_name"
                            class="transparent-input pointer"
                            v-model="folder.original_name"
                            :id="`folder-name-${index}`"
                            :disabled="dragSource == FOLDER || !editFolderTitlePermission"
                            @blur="saveNewFolderName"
                        />
                    </div>
                </div>
                <div class="folder-right-block">
                    <span class="f-13-grey sm-1 lg-4">{{ documentsCount }}</span>
                    <span class="f-13-grey">{{ typeDocumentsTitle }}</span>
                    <div class="d-flex">
                        <button
                            class="icon-dark"
                            @click="open = !open"
                        >
                            <arrow-icon :transform="open ? 270 : 90"/>
                        </button>
                        <div class="ml-30">
                            <context-menu
                                :unicId="`folder-menu-${index}`"
                                :btnStyle="selectedMode ? 'icon-light' : 'icon-dark'"
                                :disabled="selectedMode"
                            >
                                <button
                                    class="s-align-4"
                                    @click="$emit('removeFolder', folder)"
                                >
                                <span class="icon-container mr-6">
                                    <trash-icon/>
                                </span>
                                    <span>Delete folder</span>
                                </button>
                            </context-menu>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="w-100 folder-items-wrapper"
                :id="`folder-items-wrapper-${index}`"
                v-if="open"
            >
                <div
                    v-for="(item, itemIndex) in folder.files"
                    :key="`item-${itemIndex}`"
                    :draggable="dScreen && !selectedMode"
                    :class="[
                        'folder-items', `folder-item-${index}-${itemIndex}`,
                        {
                            'folder-item-empty-place relative': item.marker == EMPTY_SPACE,
                            'grabbable': dScreen && !selectedMode,
                            'folder-item-placeholder': documentOverId == item.id
                        }
                    ]"
                    @dragstart.stop="documentDragStart(itemIndex, item, index)"
                    @dragenter.stop="$emit('documentDragEnter', itemIndex, item, index)"
                    @dragend.stop="$emit('documentDragEnd', itemIndex, item, index)"
                >
                    <div
                        v-if="!item.marker"
                        class="d-flex"
                    >
                        <div class="s-align-4 lg-5 sm-11 pr-4">
                            <div class="row-start row-baseline">
                                <div
                                    class="mr-20 s-align-5"
                                    v-if="dScreen"
                                >
                                    <drag-icon :class="['i-grey', { 'opacity-5': selectedMode }]"/>
                                </div>
                                <checkbox-button 
                                    color="blue" 
                                    class="mr-8"
                                    v-if="selectedMode"
                                >
                                    <input 
                                        type="checkbox" 
                                        :name="`document-selected-${itemIndex}`" 
                                        v-model="selectedDocuments"
                                        :value="item"
                                        @input="selectDeselectDocument($event, item)"
                                    />
                                </checkbox-button>
                                <component
                                    :is="icon(item)"
                                    v-if="item.type"
                                    height="19"
                                    width="14"
                                    class="mr-7"
                                />
                                <div 
                                    class="s-align-4" 
                                    :style="nameFieldWidth"
                                    @click="startToEditDocumentName(`document-${index}-${itemIndex}`, item)"
                                >
                                    <div class="column-start pr-4 w-100">
                                        <input 
                                            type="text" 
                                            :name="`document-${index}-${itemIndex}`" 
                                            :id="`document-${index}-${itemIndex}`"
                                            class="transparent-input pointer w-100"
                                            v-model="item.original_name"
                                            :disabled="dragSource == DOCUMENT || !editDcumentTitlePermission"
                                            @blur="saveNewDocumentName"
                                        />
                                        <span
                                            class="f-13-grey"
                                            v-if="mScreen"
                                        >
                                            Last change: {{ item.updated_at | date }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="folder-right-block">
                            <div
                                class="lg-5"
                                v-if="!mScreen"
                            >
                                <span class="f-13-grey">Last change: {{ item.updated_at | date }}</span>
                            </div>
                            <div v-if="item.is_smart && item.smarts.length" class="smartdocs-type">
                                <div
                                    class="smartdocs-label"
                                    :style="`background-color: ${ typeDocuments[item.smarts[0].entity_type].bgColor }`"
                                    v-if="!mScreen"
                                >
                                    <span>{{ typeDocuments[item.smarts[0].entity_type].title }}</span>
                                </div>
                                <div v-else class="smartdocs-label-mobile"
                                     :style="`background-color: ${ typeDocuments[item.smarts[0].entity_type].bgColor }`"
                                ></div>
                            </div>
                            <div class="lg-1 sm-1 s-align-6">
                                <button
                                    v-if="checkAvailableType(item)"
                                    class="transparent-button p-1"
                                    @click="showPreviewFile(item)"
                                >
                                    <eye-icon
                                        size="18"
                                        class="i-blue"
                                    />
                                </button>
                                <context-menu
                                    :unicId="`folder-item-menu-${itemIndex}`"
                                    :btnStyle="selectedMode ? 'icon-light' : 'icon-dark'"
                                    :disabled="selectedMode"
                                >
                                    <button
                                        class="s-align-4"
                                        @click="openSmartDocModal(item)"
                                        v-if="item.type == 'pdf' && !item.is_smart"
                                    >
                                    <span class="icon-container mr-6">
                                        <smart-doc-icon/>
                                    </span>
                                        <span>Make Smart</span>
                                    </button>
                                    <button
                                        class="s-align-4"
                                        @click="editSmartDoc(item)"
                                        v-if="item.type == 'pdf' && item.is_smart"
                                    >
                                    <span class="icon-container mr-6">
                                        <smart-doc-icon/>
                                    </span>
                                        <span>Edit Smart</span>
                                    </button>
                                    <button
                                        class="s-align-4"
                                        @click="$emit('moveDocument', item)"
                                    >
                                    <span class="icon-container mr-6">
                                        <pointer-icon/>
                                    </span>
                                        <span>Move to...</span>
                                    </button>
                                    <button
                                        class="s-align-4"
                                        @click="$emit('downloadDocument', item)"
                                    >
                                    <span class="icon-container mr-6">
                                        <download-icon/>
                                    </span>
                                        <span>Download</span>
                                    </button>
                                    <button
                                        class="s-align-4"
                                        @click="$emit('deleteDocument', item)"
                                    >
                                    <span class="icon-container mr-6">
                                        <trash-icon width="15"/>
                                    </span>
                                        <span>Delete document</span>
                                    </button>
                                </context-menu>
                            </div>
                        </div>
                    </div>
                </div>
                <div 
                    class="row-start" 
                    :style="addItemsStyle"
                >
                    <button 
                        class="transparent-button f-13-blue"
                        @click="$emit('addDocument', folder.id)"
                    >
                        <add-document-icon class="i-blue mr-8"/>
                        Add document
                    </button>
                </div>
            </div>
        </div>
        <file-preview-modal
            v-if="previewModalOpened"
            :file="fileForPreview"
            @close="previewModalOpened = false"
        />
        <select-entity-modal
            v-if="openMakeSmartModal"
            @close="openMakeSmartModal = false"
            @continue="makeSmartDoc"
        />
    </div>
</template>

<script>
    import { 
        DragIcon, ArrowIcon, DownloadIcon, DocumentIcon, 
        PlusIcon, ImagePreviewIcon, EditIcon, TrashIcon, PointerIcon,
        AddDocumentIcon, EyeIcon, SmartDocIcon, SmartDocPreviewIcon
    } from '@/icons';
    import { CheckboxButton } from '@/ui';
    import { clearArray, createGhost } from '@/helpers';
    import ContextMenu from '@/components/ContextMenu.vue';
    import FilePreviewModal from '@/modals/FilePreviewModal';
    import SelectEntityModal from '@/modals/SelectEntityModal';
    import { FILE_EXTENSIONS } from '@/constants.js';
    import api from '@/headers.js';

    export default {
        name: 'CompanyDocumentsFolder',
        components: {
            DragIcon,
            ArrowIcon,
            DownloadIcon,
            DocumentIcon,
            PlusIcon,
            ImagePreviewIcon,
            ContextMenu,
            EditIcon,
            TrashIcon,
            PointerIcon,
            CheckboxButton,
            AddDocumentIcon,
            EyeIcon,
            FilePreviewModal,
            SelectEntityModal,
            SmartDocIcon,
            SmartDocPreviewIcon
        },
        props: {
            folder: {
                type: Object,
                required: true
            },
            index: {
                type: [String, Number],
                required: true
            },
            openFolder: {
                type: Boolean,
                default: false
            },
            selectedMode: {
                type: Boolean,
                default: false
            },
            modelClass: {
                type: String,
                required: true
            },
            hostFolderId: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                open: false,
                editFolderTitlePermission: false,
                editDcumentTitlePermission: false,
                extension: '',
                savedName: '',
                editableDocument: {},
                editableType: '',
                FOLDER: 'folder',
                DOCUMENT: 'document',
                EMPTY_SPACE: 'empty',
                previewModalOpened: false,
                fileForPreview: {},
                openMakeSmartModal: false,
                fileForSmartDoc: null,
                typeDocuments:  {
                    Residential: {title: 'Residential Lead & Job', bgColor: '#FA9BA3' },
                    CommercialLead: {title: 'Commercial Lead', bgColor: '#97E5B8'},
                    CommercialJob: {title: 'Commercial Job', bgColor: '#80B5FE'},
                }
            }
        },
        computed: {
            documentsCount() {
                return this.mScreen
                    ? `(${this.folder.all_files_count})`
                    : `${this.folder.all_files_count} ${this.folder.all_files_count == 1 ? 'document' : 'documents'}`;
            },
            typeDocumentsTitle() {
                return this.mScreen ? 'Type' : 'Type of  smart Documents';
            },
            nameFieldWidth() {
                return this.dScreen
                    ? { width: `calc(100% - ${this.selectedMode ? 115 : 80}px)` }
                    : { width: `calc(100% - ${this.selectedMode ? 65 : 30}px)` };
            },
            addItemsStyle() {
                if (this.dScreen) {
                    return { padding: `15px 20px 15px ${this.selectedMode ? 86 : 51}px` }
                } else if (this.nScreen || this.tScreen) {
                    return { padding: `15px 20px 15px ${this.selectedMode ? 55 : 20}px` }
                } else {
                    return { padding: `15px 15px 15px ${this.selectedMode ? 53 : 18}px` }
                }
            },
            selectedDocuments() {
                return this.$store.getters['companyDocuments/getSelectedDocuments'];
            },
            dragSource() {
                return this.$store.getters['companyDocuments/getDragSource'];
            },
            documentOverId() {
                return this.$store.getters['companyDocuments/getDocumentOverId'];
            },
            folderOverId() {
                return this.$store.getters['companyDocuments/getFolderOverId'];
            }
        },
        watch: {
            openFolder: {
                immediate: true,
                handler(newVal, oldVal) {
                    this.open = newVal;
                }
            },
            hostFolderId: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (this.dragSource != this.DOCUMENT) return;

                    let added = false;
                    if (newVal === this.folder.id && !added) {
                        this.folder.files.push({ order: this.folder.files.length, marker: this.EMPTY_SPACE });
                        added = true;
                    } else {
                        let index = this.folder.files.findIndex(d => d.marker === this.EMPTY_SPACE);                        
                        if (index != -1)
                            this.folder.files.splice(index, 1);
                    }
                }
            }
        },
        methods: {
            icon(item) {
                if (FILE_EXTENSIONS.IMAGES.some(t => t == item.type)) {
                    return 'image-preview-icon';
                }

                if (item.type == 'pdf' && item.is_smart) {
                    return 'smart-doc-preview-icon';
                }

                return 'document-icon';
            },
            startToEditFolderName() {
                this.editFolderTitlePermission = true;
                this.$nextTick(function() {
                    document.getElementById(`folder-name-${this.index}`).focus();
                    this.savedName = this.folder.original_name;
                });
            },
            saveNewFolderName() {
                this.editFolderTitlePermission = false;

                if (this.folder.original_name == this.savedName) {
                    return;
                }

                if (this.folder.original_name.length <= 2) {
                    this.notifyError('The Name field must be at least 3 characters');
                    this.folder.original_name = this.savedName;
                    return;
                }

                this.editableType = this.FOLDER;

                let params = {
                    file: this.folder.id,
                    new_name: this.folder.original_name,
                    model_class: this.modelClass
                };

                this.saveNewName(params);
            },
            startToEditDocumentName(id, item) {
                this.editDcumentTitlePermission = true;
                this.editableDocument = item;

                this.$nextTick(function() {
                    document.getElementById(id).focus();

                    let regexp = /\.[0-9a-z]+$/i;

                    this.extension = this.editableDocument.id.match(regexp)[0];
                    this.editableDocument.original_name = this.editableDocument.original_name.replace(regexp, '');

                    this.savedName = this.editableDocument.original_name;
                });
            },
            saveNewDocumentName() {
                this.editDcumentTitlePermission = false;

                if (this.editableDocument.original_name == this.savedName) {
                    this.editableDocument.original_name = `${this.savedName}${this.extension}`;
                    return;
                }

                if (this.editableDocument.original_name.length <= 2) {
                    this.notifyError('The Name field must be at least 3 characters');
                    this.editableDocument.original_name = `${this.savedName}${this.extension}`;
                    return;
                }

                this.editableType = this.DOCUMENT;
                this.editableDocument.original_name = `${this.editableDocument.original_name}${this.extension}`;
                
                let params = {
                    file: this.editableDocument.id,
                    new_name: this.editableDocument.original_name,
                    model_class: this.modelClass
                };

                this.saveNewName(params);
            },
            saveNewName(params) {                
                api.post(`/document/rename`, params)
                    .then(response => {
                        this.notifySuccess('Name has been saved!');

                        if (this.editableType == this.FOLDER) {
                            const staticPart = this.folder.id.slice(0, this.folder.id.lastIndexOf('/') + 1);                            
                            this.folder.id = `${staticPart}${this.folder.original_name}`;
                            this.$store.commit('companyDocuments/UPDATE_FOLDER_LIST', { oldName: this.savedName, folder: this.folder });

                            if (this.folder.files.length) {
                                this.folder.files.map((file) => {
                                    file.folder_id = this.folder.id;
                                    file.id = `${this.folder.id}${file.id.slice(file.id.lastIndexOf('/'))}`;
                                });
                            }
                        }

                        this.editableType = '';
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            selectDeselectDocument($event, item) {
                $event.target.checked
                    ? this.$store.commit('companyDocuments/ADD_DOCUMENT', item)
                    : this.$store.commit('companyDocuments/REMOVE_DOCUMENT', item.id);
            },
            folderDragStart() {
                this.$emit('folderDragStart');
                createGhost(document.querySelector(`.document-folder-${this.index}`), 'folder-draggable');
            },
            documentDragStart(index, item, folderIndex) {
                this.$emit('documentDragStart', index, item, folderIndex);
                createGhost(this.$el.querySelector(`.folder-item-${this.index}-${index}`), 'folder-item-draggable');
            },
            checkAvailableType(file) {
                return FILE_EXTENSIONS.DOCUMENTS.some(e => e.toLowerCase() === file.type.toLowerCase())
                    || FILE_EXTENSIONS.IMAGES.some(e => e.toLowerCase() === file.type.toLowerCase());
            },
            showPreviewFile(file) {
                this.fileForPreview = file;
                this.previewModalOpened = true;
            },
            openSmartDocModal(item) {
                this.openMakeSmartModal = true;
                this.fileForSmartDoc = item;
            },
            makeSmartDoc(entity) {
                this.$router.push({
                    name: 'SmartDocBuilder',
                    params: { id: this.fileForSmartDoc.id },
                    query: { mode: 'create', entity: entity }
                })
            },
            editSmartDoc(item) {
                api.get(`/smart-documents/by-file?file_id=${item.id}`)
                    .then(response => {
                        let smartDoc = response.data.data;
                        this.$router.push({
                            name: 'SmartDocBuilder',
                            params: { id: item.id },
                            query: { 
                                mode: 'edit', 
                                entity: smartDoc.entity_type,
                                smart_id: smartDoc.id
                            }
                        })
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            }
        }
    }
</script>