<template>
    <div
        v-if="activeTab === 'attachments'"
        class="form-builder-photo-form"
    >
        <div :class="['row-between pb-8 mt-30', dScreen ? 'ph-30' : 'ph-8']">
            <div class="lg-8 sm-10 column-start">
                <p class="f-16-asphalt f-sbold pb-1">
                    <attach-icon 
                        class="i-grey-17 mr-2" 
                        size="14"
                    />
                    Attachments
                </p>
                <p 
                    class="f-14-grey"
                    v-if="isPhotos"
                >
                    Selected files are included in the report. 
                    You can also add comments to every attachment.
                </p>
                <p class="f-14-grey" v-else>
                    There are no photos uploaded to this job yet.
                </p>
            </div>
            <div class="lg-4 sm-2 s-align-3 h-100">
                <button 
                    class="view-details-primary ph-4"
                    @click="addPictures"
                    v-if="!sectionAdded && !mScreen && isPhotos"
                >
                    + Add Pictures to Report
                </button>
                <button 
                    class="add-button remove-section-button"
                    @click="checkSectionBeforeRemove"
                    v-if="sectionAdded"
                >
                    <trash-icon class="i-grey-17"/>
                    <span 
                        class="f-12-asphalt f-sbold pl-2"
                        v-if="!mScreen"
                    >
                        Remove section
                    </span>
                </button>
            </div>
            <div 
                class="w-100 mt-sm-2" 
                v-if="!sectionAdded && mScreen && isPhotos"
            >
                <button 
                    class="view-details-primary ph-4 add-pictures-on-mobile"
                    @click="addPictures"
                >
                    + Add Pictures to Report
                </button>
            </div>
        </div>
        <div 
            class="w-100" 
            v-if="sectionAdded || added.length"
            id="photo-form"
        >
            <div :class="['w-100', dScreen ? 'ph-30' : 'ph-8']">
                <div class="form-builder-photos row-start w-100 p-20">
                    <div
                        class="form-builder-photo relative"
                        v-for="(photo, index) in added"
                        :key="`photo-${index}`"
                        @mouseover="setFocus(index)"
                    >
                        <button
                            class="rotate-button"
                            @click="rotatePhoto(index)"
                        >
                            <rotate-icon class="i-white"/>
                        </button>
                        <div
                            class="photo-wrapper s-align-5"
                            :style="{
                                backgroundImage: `url(${photo.url})`,
                                transform: `rotate(${photo.options.rotate}deg)`
                            }"
                        />
                        <div class="photo-description row-between">
                            <textarea
                                name="caption"
                                :rows="tScreen ? 3 : 2"
                                :id="`comment-${index}`"
                                v-model="photo.caption"
                                maxlength="50"
                                placeholder="Add comment to picture here..."
                            />
                            <button
                                class="service-add-button"
                                @click="removePhoto(index)"
                            >
                                <trash-icon/>
                            </button>
                        </div>
                    </div>
                    <div
                        :class="[
                            's-align-5 t-center',
                            dropAreaFullView ? 'drop-area-full' : 'drop-area',
                            { 'drop-area-active' : dragActive }
                        ]"
                        @drop.prevent="dropHandler"
                        @dragenter.prevent="dragActive = true"
                        @dragover.prevent="dragActive = true"
                        @dragleave.prevent="dragActive = false"
                        v-if="dScreen"
                    >
                        <span class="f-13-grey f-sbold">
                            Drag items from the bar <br v-if="!dropAreaFullView"/> bottom and drop them here
                        </span>
                    </div>
                </div>
            </div>
            <div :class="['form-builder-bar w-100 mt-30', { 'expand-to-full-screen' : expandToFullScreen }]">
                <div class="form-builder-bar-photos column-start">
                    <div class="row-between w-100 mb-20 row-baseline-start">
                        <span class="f-16-white f-sbold">
                            Choose pictures for the report
                        </span>
                        <span class="badge-content f-11-black f-sbold ml-2">
                            {{ selected.length }}
                        </span>
                    </div>
                    <div :class="['row-start', mScreen ? 's-align-5' : 's-align-4']">
                        <label
                            class="form-builder-bar-photo relative mr-4"
                            v-for="(photo, dragIndex) in photos"
                            :key="`photo-bar-${dragIndex}`"
                            :style="{ backgroundImage: `url(${photo.url})` }"
                            draggable="true"
                            @dragstart="startToDragPhoto($event, dragIndex)"
                        >
                            <div class="angle-input">
                                <input
                                    type="checkbox"
                                    :name="`added-${dragIndex}`"
                                    v-model="selected"
                                    :value="photo"
                                    @input="removeWithInputEvent($event, photo.id)"
                                />
                                <span class="angle-input-icon">
                                    <mark-icon size="12"/>
                                </span>
                            </div>
                            <div
                                class="form-builder-bar-caption w-100"
                                v-if="photo.caption"
                            >
                                <span class="f-10-white f-sbold break-word wrap w-100 max-w-100">
                                    {{ photo.caption }}
                                </span>
                            </div>
                        </label>
                    </div>
                </div>
                <div 
                    class="row-between p-5 mt-6"
                    v-if="!dScreen"
                >
                    <button 
                        class="transparent-button pt-2"
                        @click="moveImages"
                    >
                        <rotate-icon class="i-white" />
                        <span class="f-13-white f-sbold mt-1 ml-4">Move images</span>
                    </button>
                    <div>
                        <button
                            class="transparent-button p-2"
                            @click="expandToFullScreen = !expandToFullScreen"
                        >
                            <component
                                :is="!expandToFullScreen ? 'resize-icon' : 'decrease-icon'"
                                class="i-white"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <modal
            v-if="showConfirmModal"
            @close="showConfirmModal = false"
        >
            <div slot="modal-body">
                <div class="t-center column-center pb-4">
                    <span class="f-14-black pb-2">
                        Are you sure you want to remove these changes?
                    </span>
                    <span class="f-14-black f-sbold">They will not be saved</span>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="row-between">
                    <button 
                        class="add-button"
                        @click="showConfirmModal = false"
                    >
                        Cancel
                    </button>
                    <button 
                        class="delete-button"
                        @click="removeSection"
                    >
                        Delete
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    // dispatch (get photos request) for this component is in the 'FormBuilderNavPanel'
    import { AttachIcon, TrashIcon, RotateIcon, MarkIcon, PointerIcon, ResizeIcon, DecreaseIcon } from '@/icons';
    import { clearArray, scrollToElement } from '@/helpers';
    import { Modal } from '@/ui';

    export default {
        name: 'FormBuilderPhotoForm',
        components: {
            DecreaseIcon,
            ResizeIcon,
            AttachIcon,
            TrashIcon,
            RotateIcon,
            MarkIcon,
            Modal,
            PointerIcon
        },
        props: {
            saved: {
                type: Array,
                required: false
            },
            crearPhoto: {
                type: Boolean,
                default: false
            },
            activeTab: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                sectionAdded: true,
                selected: [],
                added: [],
                dragActive: false,
                showConfirmModal: false,
                expandToFullScreen: false
            }
        },
        computed: {
            photos() {
                return this.$store.getters['formBuilder/getFormBuilderPhoto'];
            },
            dropAreaFullView() {
                return this.added.length == 0 || this.added.length % 3 == 0;
            },
            isPhotos() {
                return this.photos.length;
            },
            getCountAttachment() {
                let sum = 0;
                if (this.added && this.added.length) {
                    sum += this.added.length;
                }
                return sum;
            }
        },
        watch: {
            getCountAttachment: {
                deep: true,
                handler (val, oldVal) {
                    if (val != oldVal) {
                        this.$store.commit('formBuilder/GET_COUNT_ATTACHMENT', this.getCountAttachment);
                    }
                }
            },
            added(val) {
                this.$emit('set', { photos: this.added });                
            },
            saved: {
                immediate: true, 
                handler (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        this.added = newValue;
                    }
                }
            },
            crearPhoto: {
                immediate: true,
                handler (newVal, oldVal) {
                    this.removeSection();
                }
            }
        },
        methods: {
            rotatePhoto(index) {
                this.added[index].options.rotate == 360
                    ? this.added[index].options.rotate = 90
                    : this.added[index].options.rotate = this.added[index].options.rotate + 90;
            },
            setFocus(index) {
                document.getElementById(`comment-${index}`).focus();
            },
            startToDragPhoto(event, index) {
                // check in case the photo was not selected but grabbed using drag event
                let check = this.selected.find(s => s.id === this.photos[index].id);
                if (check === undefined) {
                    this.selected.push(this.photos[index]);
                }

                event.dataTransfer.setData('photos', JSON.stringify(this.selected));
            },
            dropHandler(event) {
                // user cannot upload new photos not from a saved report
                if (!event.dataTransfer.getData('photos')) {
                    this.notifyWarning('You are trying to add a new photo. Use uploaded photos for your report.');
                    this.dragActive = false;
                    return false;
                }

                let newPhotos = JSON.parse(event.dataTransfer.getData('photos'));

                newPhotos.map((ph) => {
                    ph.options = JSON.parse(ph.options);
                });

                this.added = newPhotos;
                this.dragActive = false;
            },
            moveImages() {
                this.added = this.selected;
                this.expandToFullScreen = false;
            },
            removePhoto(index) {
                this.added.splice(index, 1);
                this.selected.splice(index, 1);
            },
            removeWithInputEvent($event, id) {
                // if pfoto was deleted by unchecking
                if (!$event.target.checked)
                    this.added.splice(this.added.findIndex(ph => ph.id === id), 1);               
            },
            addPictures() {
                this.sectionAdded = true;

                this.$nextTick(function () {
                    scrollToElement(document.getElementById('photo-form'));
                });
            },
            checkSectionBeforeRemove() {
                if (this.added.length) {
                    this.showConfirmModal = true;
                    return;
                }

                this.removeSection();
            },
            removeSection() {
                this.sectionAdded = false;
                clearArray(this.added);
                clearArray(this.selected);

                this.showConfirmModal = false;
            }
        },
        mounted() {
            window.addEventListener('resize', () => {
                this.expandToFullScreen = false;
            });
        }
    }
</script>