var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{class:[
    'checkbox',
    { 'checkbox-reverse' : _vm.labelDirection == 'left' }
]},[_vm._t("default"),_c('span',{class:['checkbox-mark', _vm.color ]}),_vm._t("image"),(_vm.label)?_c('span',{class:[
            'checkbox-label',
            _vm.labelNoWrap && 'no-wrap',
            { 'mr-4' : _vm.labelDirection == 'left' },
            _vm.labelClass
        ]},[_vm._v("\n        "+_vm._s(_vm.label)+"\n    ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }