<template>
    <div class="priority">
        <span 
            :style="`background-color: ${ hexToRgba(priorityColor,0.1) }`" 
            class="row-baseline priority-wrapper flex-no-wrap"
        >
            <span 
                class="mr-4 priority-mark" 
                :style="`background-color: ${ priorityColor }`"
            >
            </span>
            <span
                class="f-13-black f-sbold capitalize"
                :style="textBgColor && `color: ${ priorityColor }`"
            >
                {{ priority }}
            </span>
        </span>
    </div>
</template>

<script>

    export default {
        name: 'Priority',
        props: {
            priority: {
                type: String,
                default: ''
            },
            priorityColor: {
                type: String,
                default: ''
            },
            textBgColor: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            hexToRgba(hex, alpha) {
                hex = hex.replace('#', '');
                let r = parseInt(hex.length == 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
                let g = parseInt(hex.length == 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
                let b = parseInt(hex.length == 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);

                return `rgba(${r},${g},${b},${alpha})`;
            }
        }
    }
</script>
