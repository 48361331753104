<template>
    <full-modal 
        @close="$emit('close')"
        :full="true"
    >
        <div slot="title">Notes</div>
        <div slot="body">
            <p
                class="hidden f-13-black break-word list-styled"
                v-html="note"
                ref="notes"
            />
        </div>
    </full-modal>
</template>

<script>
    import { FullModal } from '@/ui';

    export default {
        name: 'ViewFullNoteModal',
        components: { FullModal },
        props: {
            note: {
                type: String,
                required: true
            }
        },
    }
</script>