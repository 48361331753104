<template>
    <div class="w-100">
        <p class="f-24-grey mb-1">{{ name }}</p>
        <div 
            :class="[
                'scroll-table', 
                `scroll-table-${head.length}`,
                isShowDivider ? 'scroll-table-divider' : ''
            ]"
            v-if="filled && requestStatus"
            v-touch:swipe.left="swipeRight"
            v-touch:swipe.right="swipeLeft"
        >
            <div
                :class="[
                    'table-head',
                    { 'table-head-collapse' : isShowHeadMobileOnly}
                ]"
            >
                <div 
                    class="scroll-container"
                    v-if="isShowHead"
                >
                    <span 
                        :class="[
                            'table-td',
                            [isShowHeadMobileOnly && index === (head.length - 1) ? 't-center' : ''],
                            { 'pointer': showSort }
                        ]"
                        v-for="(item, index) in head"
                        :key="index"
                        @click="$emit('sort', item)"
                    >
                        <move-icon
                            v-if="showSort"
                            transform="90" 
                            :class="[
                                'order-by-icon',
                                item === columnSort ? `order-${orderBy}` : ''
                            ]"
                        />
                        {{ item }}
                    </span>
                </div>
                <div 
                    class="table-navigation"
                    v-if="head.length > elementsInView"
                >
                    <button 
                        :class="[
                            'swipe-left',
                            this.lastElementInView === this.elementsInView ? 'disabled' : ''
                        ]"
                        :disabled="swiping"
                        @click="swipeLeft"
                    >
                        <arrow-icon 
                            size="15" 
                            transform="180"
                        />
                    </button>
                    <button 
                        :class="[
                            'swipe-right',
                            this.head.length === this.lastElementInView ? 'disabled' : ''
                        ]"
                        :disabled="swiping"
                        @click="swipeRight"
                    >
                        <arrow-icon 
                            size="15" 
                            transform="0"
                        />
                    </button>
                </div>
            </div>
            <slot name="default"></slot>
        </div>
        <Loader size="small" v-if="!requestStatus"/>
        <not-found-message 
            :location="name" 
            v-if="!filled && requestStatus && showNotFoundMessage"
        />
    </div>
</template>

<script>

    /* 
     * Head: ['Lorem', 'Officiis'...]
     * filled="head.length"
     * name:"Some Table Name"
     * requestStatus="true/false" - is request done?
     * overflow colors: dark, blue, green, red, orange, violet 
     * isShowHead="true/false" - show/hide table head, default - true
     * isShowDivider="true/false" - show/hide divider between rows, default = false
     */

    import NotFoundMessage from '@/components/NotFoundMessage.vue';
    import { Loader } from '@/ui';
    import { ArrowIcon, MoveIcon } from '@/icons';

    export default {
        name: 'ScrollTable',
        data() {
            return {
                elementsInView: null,
                lastElementInView: null,
                isHandlerSet: false,
                swiping: false
            }
        },
        props: {
            head: {
                type: Array,
                default: () => []
            },
            isShowHead: {
                type: Boolean,
                default: true
            },
            isShowHeadMobileOnly: {
                type: Boolean,
                default: false
            },
            isShowDivider: {
                type: Boolean,
                default: false
            },
            filled: {
                type: Number,
                default: 0
            },
            name: {
                type: String,
                default: ''
            },
            requestStatus: {
                type: Boolean,
                default: false
            },
            showNotFoundMessage: {
                type: Boolean,
                default: true
            },
            showSort: {
                type: Boolean,
                default: false
            },
            orderBy: {
                type: String,
                default: 'asc'
            },
            columnSort: {
                type: String,
                default: ''
            }
        },
        components: {
            NotFoundMessage,
            Loader,
            ArrowIcon,
            MoveIcon
        },
        methods: {
            scrollTo(element, scrollPixels, duration) {
                const scrollPos = element.scrollLeft;

                // Condition to check if scrolling is required
                if ( !( (scrollPos === 0 || scrollPixels > 0) && (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0))) 
                {
                    // Get the start timestamp
                    const startTime =
                    "now" in window.performance
                        ? performance.now()
                        : new Date().getTime();
                    
                    function scroll(timestamp) {
                        //Calculate the timeelapsed
                        const timeElapsed = timestamp - startTime;
                        //Calculate progress 
                        const progress = Math.min(timeElapsed / duration, 1);
                        //Set the scrolleft
                        element.scrollLeft = scrollPos + scrollPixels * progress;
                        //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
                        if (timeElapsed < duration) {
                            //Request for animation
                            window.requestAnimationFrame(scroll);
                        } else {
                            return;
                        }
                    }

                    //Call requestAnimationFrame on scroll function first time
                    window.requestAnimationFrame(scroll);
                }
            },
            swipeRight() {
                if (this.head.length === this.lastElementInView ) {
                    return;
                }

                // disable buttom when animation works
                this.swiping = true;

                const content = this.$el.querySelectorAll('.scroll-container');
                const nextScrollValue = content[0].childNodes[this.lastElementInView].clientWidth;
                this.lastElementInView++;

                for (let i = 0; i < content.length; i++) {
                    this.scrollTo(content[i], nextScrollValue, 300);
                }
                
                // enable button
                setTimeout(function() {
                    this.swiping = false;
                }.bind(this), 350);
            },
            swipeLeft() {
                if (this.lastElementInView === this.elementsInView) {
                    return;
                }

                // disable buttom when animation works
                this.swiping = true;

                const content = this.$el.querySelectorAll('.scroll-container');
                const nextScrollValue = content[0].childNodes[this.lastElementInView - 1].clientWidth;
                this.lastElementInView--;

                for (let i = 0; i < content.length; i++) { 
                    this.scrollTo(content[i], (-1 * nextScrollValue), 300);
                }

                // enable button
                setTimeout(function() {
                    this.swiping = false;
                }.bind(this), 350);
            },
            setHandlers() {            
                if (!this.isHandlerSet && this.requestStatus) {
                    this.isHandlerSet = true;
                    let openButtons = [].slice.call(this.$el.querySelectorAll('.content-open-button'));

                    openButtons.forEach(function(button) {
                        button.addEventListener('click', (e) => {
                            let tableBody = button.closest('.table-body');
    
                            if (tableBody.classList.contains('opened')) {
                                tableBody.classList.remove('opened');
                            } else {
                                tableBody.classList.add('opened');
                            };
                        });
                    })
                };
            },
            calculateVisibleColumns() {
                let element = this.$el;
                this.swipeToStart();

                if (window.innerWidth > 1164) {
                    if (this.head.length < 6) {
                        this.elementsInView = this.head.length;
                        this.lastElementInView = this.head.length;
                    } else {
                        this.elementsInView = 6;
                        this.lastElementInView = 6;
                    }
                } else if (window.innerWidth > 889) {
                    if (this.head.length < 5) {
                        this.elementsInView = this.head.length;
                        this.lastElementInView = this.head.length;
                    } else {
                        this.elementsInView = 5;
                        this.lastElementInView = 5;
                    }
                } else if (window.innerWidth > 767) {
                    if (this.head.length < 4) {
                        this.elementsInView = this.head.length;
                        this.lastElementInView = this.head.length;
                    } else {
                        this.elementsInView = 4;
                        this.lastElementInView = 4;
                    }
                } else if (window.innerWidth > 600) {
                    if (this.head.length < 3) {
                        this.elementsInView = this.head.length;
                        this.lastElementInView = this.head.length;
                    } else {
                        this.elementsInView = 3;
                        this.lastElementInView = 3;
                    }
                } else if (window.innerWidth > 400) {
                    if (this.head.length < 2) {
                        this.elementsInView = this.head.length;
                        this.lastElementInView = this.head.length;
                    } else {
                        this.elementsInView = 2;
                        this.lastElementInView = 2;
                    }
                } 
                else {
                    this.elementsInView = 1;
                    this.lastElementInView = 1;
                }
            },
            swipeToStart() {
                if (this.lastElementInView === this.elementsInView) {
                    return;
                }

                const content = this.$el.querySelectorAll('.scroll-container');
                this.lastElementInView = this.elementsInView;

                for (let i = 0; i < content.length; i++) { 
                    this.scrollTo(content[i], -1000, 0);
                }
            }
        },
        mounted() {
            this.setHandlers();
            this.calculateVisibleColumns();
            window.addEventListener('resize', this.calculateVisibleColumns);
        },
        updated() {
            this.setHandlers();
        },
        watch: {
            head: function() {
                this.calculateVisibleColumns();
            }
        }
    }
</script>
