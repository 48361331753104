var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"steps-panel row-center"},_vm._l((_vm.captions),function(caption,index){return _c('div',{key:index,class:[
            's-align-4', 'step', 'pv-1',
            { 'step-inprogress' : index != 0 }, 
            { 'step-done' : _vm.current >= index + 1 && index != 0 }
        ]},[_c('div',{class:[
                'step-icon', 's-align-5', 
                { 'step-icon-inprogress' : _vm.current >= index + 1 },
                { 'step-icon-done' : _vm.current > index + 1 }
            ]},[(_vm.current > index + 1)?_c('mark-icon',{attrs:{"size":"12"}}):_c('span',[_vm._v(_vm._s(index + 1))])],1),_c('span',{staticClass:"f-13-black"},[_vm._v(_vm._s(caption))])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }