import {GET_WARRANTY, SET_EXISTING_WARRANTY, SET_WARRANTY} from '../actions/warranty'
import api from '@/headers.js';
import {UPDATE_QUALITY_CONTROL} from "@/store/actions/commercial";

export default {
    namespaced: true,
    state: {
        warrantyJob: null,
        warrantyForEvents: {},
        existingWarranty: {}
    },
    getters: {
        getWarrantyForEvents: (state) => state.warrantyForEvents,
        getWarrantyJobNumber: state => state.warrantyJob ? state.warrantyJob.job_number: null,
        getWarrantyJobData: (state) => state.warrantyJob,
        getWarrantyJobPreview: state => {
            if (state.warrantyJob && state.warrantyJob.entity){
                const customer = state.warrantyJob.entity.customer;
                const customerData = {
                    fullname: ''
                }
                customerData.fullname = customer.name
                    ? customer.name
                    : `${customer.first_name || ''} ${customer.last_name || ''}`;

                return customerData
            }
        },
        getPropertyId: state => {
            if (state.warrantyJob && state.warrantyJob.entity && state.warrantyJob.entity.property) {
                return state.warrantyJob.entity.property.id;
            }
        },
        getWarrantyJobAddress: state => {
            if (state.warrantyJob && state.warrantyJob.addresses.length){
                const address = state.warrantyJob.addresses[0];
                return {
                    address: address.address,
                    street: address.street,
                    city: address.city,
                    state: address.state,
                    zip: address.zip,
                    lat: address.lat,
                    lng: address.lng,
                };
            }
            return state.warrantyJob;
        },
        getExistingWarranty: state => state.existingWarranty
    },
    mutations: {
        [SET_WARRANTY](state, payload) {
           state.warrantyJob = payload;
        },
        [UPDATE_QUALITY_CONTROL] (state, payload) {
            state.warrantyJob.quality_controls = payload;
        },
        [SET_EXISTING_WARRANTY] (state, payload) {
            state.existingWarranty = payload;
        }
    },
    actions: {
        async [GET_WARRANTY]({ commit }, id) {
            const warrantiesData = await api.get(`/warranty-jobs/${id}`);
            commit(SET_WARRANTY, warrantiesData.data.data);
            return warrantiesData;
        }
    }
}