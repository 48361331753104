<template>
    <svg 
        viewBox="0 0 14 13"
        :width="size" 
        :height="size"
        class="age-icon"
    >
        <path d="M7.2 2.9c-.2 0-.4.2-.4.4V7c0 .2.1.3.2.4.9.5 1.9 1 2.8 1.6.2.1.5 0 .6-.2.1-.2.1-.5-.2-.6L7.7 6.7V3.3c0-.2-.2-.4-.5-.4z"/>
        <path d="M7 .5c0 .2.2.5.5.5 2.8 0 5.2 2.1 5.5 4.9.4 3-1.8 5.8-4.9 6.2-2.7.3-5.1-1.3-6-3.7l.6.4c.2.1.5.1.7-.1.1-.1.1-.2.1-.3 0-.1-.1-.3-.2-.3l-1.6-1c-.1-.4-.3-.4-.4-.3-.1 0-.2.1-.3.2L.1 8.6c-.2.2-.1.5.1.6.3.1.6.1.7-.2l.3-.5c1 2.9 3.9 4.8 7 4.4 3.6-.4 6.2-3.7 5.7-7.2C13.6 2.5 10.8 0 7.5 0c-.3 0-.5.2-.5.5z"/>
    </svg>
</template>

<script>
export default {
    name: 'AgeIcon',
    props: {
        size: {
            type: [Number, String],
            default: 20
        }
    }
}
</script>