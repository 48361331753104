<template>
    <full-modal
        @close="$emit('close')"
        class="commercial-edit-modal"
    >
        <div slot="title">Edit primary information</div>
        <div slot="body">
            <p class="f-16-darkgrey pb-2">Primary Contact</p>
            <div class="pb-30 row-start">
                <div class="lg-6 md-6 sm-12 mb-4 pr-2 pr-sm-0">
                    <form-input
                        label="First Name"
                        :class="[{ 'form-input-danger' : errors.has('first_name') }]"
                    >
                        <input
                            type="text"
                            name="first_name"
                            id="first_name"
                            class="form-input-field"
                            placeholder="First Name"
                            v-input-style="formData.first_name"
                            v-model="formData.first_name"
                            :disabled="hideOrDisabledElement"
                            maxlength="31"
                            v-validate="{ required: true, max: 30, regex: titleRegex }"
                        />
                    </form-input>
                </div>
                <div class="lg-6 md-6 sm-12 mb-4 pl-2 pl-sm-0">
                    <form-input
                        label="Last Name"
                        :class="[{ 'form-input-danger' : errors.has('last_name') }]"
                    >
                        <input
                            type="text"
                            name="last_name"
                            id="last_name"
                            class="form-input-field"
                            placeholder="Last Name"
                            v-input-style="formData.last_name"
                            v-model="formData.last_name"
                            :disabled="hideOrDisabledElement"
                            maxlength="31"
                            v-validate="{ required: true, max: 30, regex: titleRegex }"
                        />
                    </form-input>
                </div>
            </div>
            <p class="f-16-darkgrey pb-2">Work Items</p>
            <div class="pb-30 row-start">
                <div
                    class="column-start lg-6 md-6 sm-12 mb-4 pr-2 pr-sm-0"
                    id="priority"
                >
                    <p class="f-13-black pb-2">Priority:</p>
                    <multiselect
                        :options="priorities"
                        track-by="id"
                        label="name"
                        placeholder="Priorities"
                        :showLabels="false"
                        :max-height="200"
                        :searchable="false"
                        name="priority"
                        data-name="priority"
                        v-model="formData.priority"
                        :class="['capitalize', { 'multiselect-danger' : errors.has('priority') }]"
                        v-validate="'required'"
                    />
                </div>
                <div class="column-start lg-6 md-6 sm-12 mb-4 pl-2 pl-sm-0">
                    <p class="f-13-black pb-2">Home type:</p>
                    <multiselect
                        :options="homeTypes"
                        placeholder="Home Types"
                        :showLabels="false"
                        :max-height="200"
                        :searchable="false"
                        label="name"
                        track-by="id"
                        name="home_types"
                        data-name="home_types"
                        v-model="formData.homeType"
                        :class="[{ 'multiselect-danger' : errors.has('home_types') }]"
                        v-validate="'required'"
                        id="home_types"
                    />
                </div>
                <div
                    class="column-start lg-6 md-6 sm-12 mb-4 pr-2 pr-sm-0"
                    id="lead_type"
                >
                    <p class="f-13-black pb-2">Job type:</p>
                    <multiselect
                        :options="leadTypes"
                        placeholder="Job Type"
                        :showLabels="false"
                        :max-height="200"
                        :searchable="false"
                        label="name"
                        track-by="id"
                        name="lead_type"
                        data-name="lead_type"
                        v-model="formData.leadType"
                        :class="[{ 'multiselect-danger' : errors.has('lead_type') }]"
                        v-validate="'required'"
                    />
                </div>
                <div
                    class="column-start lg-6 md-6 sm-12 mb-4 pl-2 pl-sm-0"
                    id="materials"
                >
                    <p class="f-13-black pb-2">Materials:</p>
                    <multiselect
                        :options="materials"
                        placeholder="Materials"
                        :showLabels="false"
                        :max-height="200"
                        :searchable="false"
                        label="name"
                        track-by="id"
                        name="materials"
                        data-name="materials"
                        v-model="formData.material"
                        :class="[{ 'multiselect-danger' : errors.has('materials') }]"
                        v-validate="'required'"
                    />
                </div>
                <div
                    class="column-start lg-6 md-6 sm-12 mb-4 pr-2 pr-sm-0"
                    id="lead_source"
                >
                    <p class="f-13-black pb-2">Lead source:</p>
                    <multiselect
                        :options="sources"
                        placeholder="Lead Source"
                        :showLabels="false"
                        :max-height="200"
                        :searchable="false"
                        label="name"
                        track-by="id"
                        name="lead_source"
                        data-name="lead_source"
                        v-model="formData.source"
                        :class="[{ 'multiselect-danger' : errors.has('lead_source') }]"
                        v-validate="'required'"
                    />
<!--                    <form-input-->
<!--                        v-if="!selectedPartner && isReferral"-->
<!--                        label="Referral Name"-->
<!--                        :class="[{ 'form-input-danger' : errors.has('referral_name') }, 'mt-4']"-->
<!--                    >-->
<!--                        <input-->
<!--                            type="text"-->
<!--                            name="referral_name"-->
<!--                            id="referral_name"-->
<!--                            class="form-input-field"-->
<!--                            placeholder="Referral Name"-->
<!--                            v-model="referralName"-->
<!--                            maxlength="255"-->
<!--                            v-validate="{regex: titleRegex }"-->
<!--                            ref="ReferralName"-->
<!--                        />-->
<!--                    </form-input>-->
<!--                    <multiselect-->
<!--                        v-if="isReferral && !referralName"-->
<!--                        :options="contactPartners"-->
<!--                        placeholder="Select from Contacts"-->
<!--                        :show-labels="false"-->
<!--                        :max-height="200"-->
<!--                        :searchable="false"-->
<!--                        :custom-label="partnerCustomLabel"-->
<!--                        track-by="id"-->
<!--                        name="lead_partner"-->
<!--                        data-name="lead_partner"-->
<!--                        v-model="selectedPartner"-->
<!--                        @input="addFieldToSave('partner_id', 'lead_partner')"-->
<!--                        :class="[-->
<!--                            {'selected-referral' : selectedPartner},-->
<!--                            { 'multiselect-danger' : errors.has('lead_partner') },-->
<!--                            'mt-5 multiselect-small'-->
<!--                        ]"-->
<!--                        v-validate="{required: Boolean(!referralName)}"-->
<!--                    />-->
                </div>
            </div>
            <p class="f-16-darkgrey pb-2">Trades</p>
            <div class="pb-30 row-start">
                <trade
                    v-for="(trade, index) in trades"
                    :key="trade.id"
                    :label="trade.name"
                    :class="[{ 'trade-danger' : errors.has(`trade-${index}`) }]"
                    :id="`trade-${index}`"
                    :icon="trade.icon_code"
                >
                    <input
                        type="checkbox"
                        :name="`trade-${index}`"
                        v-model="selectedTrades"
                        :value="trade.id"
                        v-validate="'required'"
                    />
                    <div slot="isSalesRabbit">
                        <div
                            v-if="trade.is_sales_rabbit"
                            class="row-start s-align-4 ml-4"
                        >
                            <storm-icon
                                width="10"
                                height="12"
                                class="ml-2"
                            />
                            <toggle-switch
                                class="ml-1 pointer"
                                smallSize
                            >
                                <input
                                    :checked="isSalesRabbit(trade.id)"
                                    @change="toggleSalesRabbit(trade.id, index)"
                                    type="checkbox"
                                />
                            </toggle-switch>
                        </div>
                    </div>
                </trade>
            </div>
            <p class="f-16-darkgrey pb-2">Location Info</p>
            <div class="w-100 pb-30">
                <address-box
                    type="address"
                    :key="'address'"
                    :existing="address"
                    @address="getMainAddress"
                />
            </div>
            <div v-if="!hideOrDisabledElement">
                <div
                    class="w-100 pb-30"
                    v-if="!isBillingAddress"
                >
                    <button
                        class="transparent-button mt-mob-1"
                        @click="isBillingAddress = true"

                    >
                        <span class="f-13-blue">+ Add Billing Address</span>
                    </button>
                </div>
                <div
                    class="w-100 pb-30"
                    v-else
                >
                    <div class="row-between">
                        <p class="f-16-darkgrey pb-2">Billing Address</p>
                        <button
                            class="icon-light mt-mob-1"
                            @click="removeBillingAddress"
                        >
                            <trash-icon/>
                        </button>
                    </div>
                    <div class="w-100">
                        <address-box
                            type="billing"
                            :key="'billing'"
                            :existing="billing"
                            @address="getBillingAddress"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <button
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                @click="save"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import {
        FullModal, FormInput, FormDatepicker, FormTimeInput,
        RadioSwitch, FormInputTextarea, CheckboxButton, Loader, Trade, ToggleSwitch,
    } from '@/ui';
    import {NOT_ALLOWED_CHARACTERS, EMAIL_TYPES, ENTITY_TYPES} from '@/constants';
    import {copyFieldsValueFromObjectToObject} from '@/helpers';
    import Multiselect from 'vue-multiselect';
    import api from '@/headers';
    import {clearObject} from '@/helpers';
    import AddressBox from "../components/AddressBox";
    import {TrashIcon, StormIcon} from '@/icons';
    
    export default {
        name: 'EditWarrantyJobModal',
        components: {
            FullModal,
            FormInput,
            FormDatepicker,
            FormTimeInput,
            RadioSwitch,
            FormInputTextarea,
            CheckboxButton,
            Loader,
            Trade,
            ToggleSwitch,
            AddressBox,
            Multiselect,
            StormIcon,
            TrashIcon
        },
        data() {
            return {
                titleRegex: NOT_ALLOWED_CHARACTERS,
                ENTITY_TYPES,
                hideOrDisabledElement: false,
                formData: {
                    first_name: '',
                    last_name: '',
                    source: null,
                    material: null,
                    leadType: null,
                    homeType: null,
                    priority: null
                },
                address: {
                    address: '',
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                    lat: null,
                    lng: null
                },
                billing: {
                    address_billing: '',
                    street_billing: '',
                    city_billing: '',
                    state_billing: '',
                    zip_billing: '',
                    lat_billing: null,
                    lng_billing: null
                },
                isBillingAddress: false,
                priorities: [],
                sources: [],
                materials: [],
                leadTypes: [],
                homeTypes: [],
                trades: [],
                selectedTrades: [],
                tradesSalesRabbit: []
            }
        },
        props: {
            job: {
                type: Object,
                required: true
            },
            entityType: {
                type: String,
                required: false
            }
        },
        methods: {
            removeBillingAddress() {
                this.isBillingAddress = false;
                clearObject(this.billing);
            },
            save() {
                this.$children.forEach(vm => {
                    vm.$validator.validateAll();
                });

                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('Please fill out all required fields!');

                        return
                    }

                    const params = this.processingParamsForEdit();

                    api.put(`warranty-jobs/${this.job.id}`, params)
                        .then((res) => {
                            this.$emit('refreshData');
                            this.$emit('close');
                        })
                })
            },
            getMainAddress(mailingData) {
                this.address = mailingData;
            },
            getBillingAddress(billingData) {
                this.billing = billingData;
            },
            isSalesRabbit(id) {
                return this.tradeList.find(el => el.id === id && el.is_sales_rabbit);
            },
            toggleSalesRabbit(id, index) {
                const findElIndex = this.salesRabbit.findIndex(el => el === id);
                if (findElIndex !== -1) {
                    this.salesRabbit.splice(findElIndex, 1);
                    return;
                }
        
                this.salesRabbit.push(id);
                this.addFieldToSave(this.salesRabbit, 'salesRabbit', index);
            },
            getJobAddress(data) {
                this.jobAddress = data;
                copyFieldsValueFromObjectToObject(data, this.job);
            },
            processingParamsForEdit() {
                let params = {};
                params.addresses = [];

                params.priority_id = this.formData.priority.id;
                params.home_type_id = this.formData.homeType.id;
                params.lead_type = this.formData.leadType.id;
                params.material_id = this.formData.material.id;
                params.source_id = this.formData.source.id;
                params.trades = this.selectedTrades;
                params.addresses.push(this.address);

                return params;
            },
            replaceData(){
                const data = this.job;
                const address = data.addresses.find(el => el.type === EMAIL_TYPES.MAIN);

                if (address){
                    this.jobAddress = address;
                    this.address = address;
                }

                this.formData.homeType = data.home_type;
                this.formData.priority = data.priority;
                this.formData.source = data.source;
                this.formData.leadType = data.lead_type;
                this.formData.material = data.material;
                this.selectedTrades = data.trades.map(el => el.id);
                this.formData.job_number = data.job_number;
            },
            getHandbooks() {
                const params = {
                    needed: [
                        'home_types',
                        'lead_types',
                        'lead_sources',
                        'trades',
                        'priorities',
                        'materials'
                    ]
                };
        
                api.get('/handbooks?filter[is_active]=1', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        this.leadTypes = data.lead_types;
                        this.homeTypes = data.home_types;
                        this.sources = data.lead_sources;
                        this.trades = data.trades;
                        this.tradesSalesRabbit = data.trades;
                        this.priorities = data.priorities;
                        this.materials = data.materials;
                    });
            },
        },
        created() {
            this.getHandbooks();
            this.replaceData();
        },
        mounted() {
            switch (this.entityType) {
                case ENTITY_TYPES.WARRANTY_JOB:
                    this.hideOrDisabledElement = true;
                    break;
                default:
                    this.hideOrDisabledElement = false;
            }
        }
    }
</script>

