<template>
    <div :class="['w-100', `${name.toLowerCase()}-statistics`]">
        <div 
            :class="['mb-5 w-100', `${name.toLowerCase()}-statistics-head`, { 'pointer' : childrenLength }]" 
            @click="openDetails"
        >
            <div class="row-between">
                <div class="s-align-4 lg-8 sm-12 mb-sm-1">
                    <p class="f-36-white f-sbold">{{ count }}</p>
                    <div class="column-start pl-8">
                        <span class="f-18-white f-sbold">{{ name }}</span>
                        <span class="f-14-white opacity-8">
                            Check the most recent 5 {{ name }} or click View all to open the {{ name }} page.
                        </span>
                    </div>
                </div>
                <div class="s-align-6 lg-4 sm-12">
                    <router-link 
                        class="view-details-secondary"
                        :to="{ name: link }"
                    >
                        View All
                    </router-link>
                    <div class="statistics-arrow">
                        <arrow-icon
                            size="12"
                            :transform="open ? 270 : 90"
                            class="i-white"
                            v-if="childrenLength"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div 
            class="w-100"
            v-if="open && name == 'Packets'"
        >
            <div 
                class="packets-statistics-details mb-4"
                v-for="(item, index) in children"
                :key="index"
            >
                <div class="row-between">
                    <div class="lg-6 md-12 sm-12 s-align-4">
                        <span class="f-18-darkgrey f-sbold">{{ item.title }}</span>
                    </div>
                    <div class="lg-4 md-6 sm-12 s-align-4 mb-sm-1">
                        <span class="f-13-grey pr-1">Status:</span>
                        <span :class="['f-13-darkgrey relative pl-8 capitalize', `packets-statistics-${item.status}`]">
                            {{ item.status }}
                        </span>
                    </div>
                    <div class="lg-2 md-6 sm-12 s-align-6">
                        <span class="f-13-grey pr-1">Created:</span>
                        <span class="f-13-darkgrey">{{ item.created_at | date }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div 
            class="w-100"
            v-if="open && name == 'Estimates'"
        >
            <div 
                class="estimates-statistics-details mb-4"
                v-for="(item, index) in children"
                :key="index"
            >
                <div class="row-between">
                    <div class="lg-6 md-12 sm-12 s-align-4">
                        <span class="f-18-darkgrey f-sbold">{{ item.name }}</span>
                    </div>
                    <div class="lg-4 md-6 sm-12 column-start mb-sm-1">
                        <span class="f-20-black">{{ item.total_price_with_discount | currency }}</span>
                        <span class="f-13-grey">Total Price</span>
                    </div>
                    <div class="lg-2 md-6 sm-12 s-align-6">
                        <span class="f-13-grey pr-1">Created:</span>
                        <span class="f-13-darkgrey">{{ item.created | date }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div 
            class="w-100"
            v-if="open && name == 'Measurements'"
        >
            <p 
                class="f-14-grey f-sbold pb-1"
                v-if="children.length"
            >
                Custom
            </p>
            <div 
                class="measurements-statistics-details mb-4"
                v-for="(item, index) in children"
                :key="`children-${index}`"
            >
                <div class="row-between">
                    <div class="lg-6 sm-12 s-align-4 mb-sm-1">
                        <span class="f-18-darkgrey f-sbold">{{ item.name }}</span>
                    </div>
                    <div class="lg-6 sm-12 s-align-6">
                        <span class="f-13-grey pr-1">Created:</span>
                        <span class="f-13-darkgrey">{{ item.created_at | date }}</span>
                    </div>
                </div>
            </div>
            <p 
                class="f-14-grey f-sbold pb-1 pt-4"
                v-if="additional.length"
            >
                Eagleview
            </p>
            <div 
                class="measurements-statistics-details mb-4"
                v-for="(item, index) in additional"
                :key="`additional-${index}`"
            >
                <div class="row-between">
                    <div class="lg-6 md-12 sm-12 s-align-4">
                        <span class="f-18-darkgrey f-sbold">{{ item.name }}</span>
                    </div>
                    <div class="lg-4 md-6 sm-12 s-align-4 mb-sm-1">
                        <span class="f-13-grey pr-1">Status:</span>
                        <span :class="['f-13-darkgrey relative pl-8', measurementStatus(item.order.status)]">
                            {{ item.order.status }}
                        </span>
                    </div>
                    <div class="lg-2 md-6 sm-12 s-align-6">
                        <div class="sm-12 s-align-6 pl-8">
                            <span class="f-13-grey pr-1">Created:</span>
                            <span class="f-13-darkgrey">{{ item.created_at | date }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ArrowIcon } from '@/icons';
    import { EAGLE_VIEW_STATUSES } from '@/constants';

    export default {
        name: 'GeneralStatistics',
        props: {
            count: {
                type: [String, Number],
                required: true
            },
            name: {
                type: String,
                required: true
            },
            link: {
                type: String,
                required: true
            },
            children: {
                type: Array,
                required: true
            },
            additional: {
                type: Array,
                default: function() {
                    return [];
                }
            }
        },
        components: {
            ArrowIcon
        },
        data() {
            return {
                open: false
            }
        },
        computed: {
            childrenLength() {
                return this.children.length || this.additional.length;
            }
        },
        methods: {
            openDetails(event) {
                if (event.target == this.$el.querySelector('.view-details-secondary')) {
                    return false;
                }

                this.open = !this.open;
            },
            measurementStatus(status) {                
                if (status == EAGLE_VIEW_STATUSES.CLOSED) {
                    return 'measurement-statistics-closed';
                }

                if (status == EAGLE_VIEW_STATUSES.COMPLETED) {
                    return 'measurement-statistics-completed';
                }

                return 'measurement-statistics-ordered';
            }
        }
    }
</script>