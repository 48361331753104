<template>
    <svg
        viewBox="0 0 20 22"
        :width="width"
        :height="height"
        class="xls-icon"
    >
        <g clip-path="url(#clip0)">
            <path d="M18.6787 18.9463C18.6787 19.9546 17.8858 20.7796 16.8285 20.7796H3.084C2.11484 20.7796 1.23378 20.0463 1.23378 18.9463V2.9963C1.23378 1.98796 2.02673 1.16296 3.084 1.16296H11.1897V4.82963C11.1897 6.47963 12.5994 7.85463 14.2734 7.85463H18.6787V18.9463ZM12.4232 1.98796L17.7096 6.75463H14.2734C13.3043 6.75463 12.4232 6.0213 12.4232 4.9213V1.98796ZM19.3836 6.47963L12.5994 0.429632C12.3351 0.154632 11.8065 -0.0287018 11.3659 -0.0287018H3.084C1.40999 -0.0287018 0.000301361 1.3463 0.000301361 2.9963V18.9463C0.000301361 20.5963 1.40999 21.9713 3.084 21.9713H16.8285C18.5906 21.9713 20.0003 20.5963 20.0003 18.9463V7.85463C20.0003 7.3963 19.736 6.93796 19.3836 6.47963Z"/>
            <path d="M8.54625 9.53319H7.40088L7.04846 10.3582C6.96035 10.6332 6.87225 10.8165 6.78414 11.0915C6.69603 10.8165 6.60793 10.6332 6.43172 10.3582L6.0793 9.53319H4.84582L6.0793 11.8249L4.75771 14.1165H5.90308L6.25551 13.2915C6.43172 13.0165 6.51982 12.7415 6.60793 12.4665C6.69604 12.7415 6.87225 13.0165 6.96035 13.2915L7.40088 14.1165H8.54625L7.22467 11.7332L8.54625 9.53319Z"/>
            <path d="M10.0441 9.53319H9.07489V14.1165H11.8062V13.1999H10.0441V9.53319Z"/>
            <path d="M14.0969 11.3666C13.4802 11.1832 13.2159 10.9999 13.2159 10.7249C13.2159 10.4499 13.3921 10.2666 13.8326 10.2666C14.2731 10.2666 14.5374 10.3582 14.7137 10.4499L14.978 9.6249C14.7137 9.53323 14.3612 9.3499 13.8326 9.3499C12.7753 9.3499 12.1586 9.99157 12.1586 10.7249C12.1586 11.3666 12.5991 11.8249 13.3921 12.0999C13.9207 12.2832 14.0969 12.4666 14.0969 12.7416C14.0969 13.0166 13.8326 13.1999 13.3921 13.1999C12.9515 13.1999 12.511 13.0166 12.2467 12.9249L12.0705 13.7499C12.3348 13.9332 12.7753 14.0249 13.304 14.0249C14.5374 14.0249 15.0661 13.3832 15.0661 12.5582C15.2423 12.0999 14.8018 11.6416 14.0969 11.3666Z"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect 
                    :width="width" 
                    :height="height"
                    fill="white"
                />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {
        name: 'XlsIcon',
        props: {
            width: {
                type: [Number, String],
                default: 20
            },
            height: {
                type: [Number, String],
                default: 22
            }
        }
    }
</script>