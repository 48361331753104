<template>
    <div class="row-baseline">
        <div :class="isDownload ? 'lg-9' : ''" >
            <div class="row-baseline audio-player-container" id="audio-player-container">
                <div class="mr-5">
                    <button @click.stop="playAudio" class="transparent-button">
                        <PauseIcon v-if="statusPlay === 'pause'"/>
                        <PlayIcon v-else/>
                    </button>
                </div>
                <div class="column-start info-player">
                    <div class="row-between w-100 mb-1">
                        <span class="f-8-grey">{{ playDuration }}</span>
                        <span class="f-8-grey">{{ duration || playDuration }}</span>
                    </div>
                    <input
                        type="range"
                        :id="`input-player-${keyAudio}`"
                        class="w-100 spinner-audio"
                        :value="spinner.value"
                        @input.stop="changeHandler"
                        min="0"
                        :max="spinner.max"
                    >
                </div>
                <audio
                    class="audio-player"
                    :id="`audio-player-${keyAudio}`"
                    :src="value"
                    controls
                >
                </audio>
            </div>
        </div>
        <div v-if="isDownload" class="lg-3 row-center">
            <button
                @click.stop="downloadAudio"
                class="transparent-button"
            >
                <DownloadCloudIcon/>
            </button>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import {PauseIcon, PlayIcon, DownloadCloudIcon} from '@/icons';
    
    const defaultTime = '00:00:00';
    export default {
        name: 'AudioPlayer',
        components: {
            PauseIcon,
            PlayIcon,
            DownloadCloudIcon
        },
        data() {
            return {
                duration: '',
                playDuration: defaultTime,
                el: null,
                elInput: null,
                statusPlay: 'play',
                spinner: {
                    value: 0,
                    max: 0
                }
            }
        },
        props: {
            value: {
                type: String,
                required: true
            },
            keyAudio: {
                type: String,
                required: true
            },
            isDownload: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            spinner: {
                deep: true,
                handler(newVal) {
                    const progress = (newVal.value / this.spinner.max) * 100;
                    this.elInput.style.background = 'linear-gradient(to right, #006bfd 0%, #006bfd ' + progress + '%, #e0e4e9 ' + progress + '%, #e0e4e9 100%)';
                }
            }
        },
        methods: {
            changeHandler(e) {
                const value = +e.target.value;
                this.el.currentTime = value;
            },
            playAudio() {
                const audio = this.el;
                if (this.statusPlay === 'play') {
                    audio.play();
                    this.statusPlay = 'pause';
                } else {
                    audio.pause();
                    this.statusPlay = 'play';
                }
            },
            downloadAudio() {
                const filename = this.value.substring(this.value.lastIndexOf("/") + 1).split("?")[0];
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = function() {
                    const a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhr.response);
                    a.download = filename;
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                };
                xhr.open('GET', this.value);
                xhr.send();
            },
            calculateTime(secs, play) {
                if (play) {
                    if (secs >= this.spinner.max) {
                        this.playDuration = defaultTime;
                        this.spinner.value = 0;
                        this.statusPlay = 'play';
                    } else {
                        this.playDuration = moment.utc(+secs * 1000).format('HH:mm:ss');
                        this.spinner.value = secs;
                    }
                } else {
                    this.duration = moment.utc(+secs * 1000).format('HH:mm:ss');
                    this.spinner.max = secs;
                }
            }
        },
        mounted() {
            const id = `#audio-player-${this.keyAudio}`;
            const idInput = `#input-player-${this.keyAudio}`;
            const audio = document.querySelector(id);
            const input = document.querySelector(idInput);
            this.el = audio;
            this.elInput = input;
            if (audio.readyState > 0) {
                this.calculateTime(audio.duration);
            } else {
                audio.addEventListener('loadedmetadata', () => {
                    this.calculateTime(audio.duration);
                });
            }
            audio.addEventListener('timeupdate', () => {
                this.calculateTime(audio.currentTime, true);
            });
        }
    }
</script>
