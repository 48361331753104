<template>
    <div class="modal s-align-5">
        <div class="copy-estimate-modal cm-fond cm-scroll">
            <span class="f-24-black f-sbold">Create a copy of an estimate</span>
            <p class="f-14-grey pb-2">Enter a name for the new version of an estimate:</p>
            <div class="w-100 pb-4 pt-25">
                <form-input 
                    label="Estimate name"
                    :class="[{ 'form-input-danger' : errors.has('estimate_name') }]"
                >
                    <input 
                        type="text" 
                        name="estimate_name" 
                        class="form-input-field"
                        placeholder="Estimate name"
                        v-input-style="estimate.name"
                        v-model="estimate.name"
                        v-validate="'required|min:3|max:250'"
                    />
                </form-input>
            </div>
            <div class="w-100">
                <form-input 
                    label="Version name"
                    :class="[{ 'form-input-danger' : errors.has('comment') }]"
                >
                    <input 
                        type="text" 
                        name="comment"
                        class="form-input-field"
                        placeholder="Version name"
                        v-input-style="comment"
                        v-model="comment"
                        v-validate="'required|min:3|max:250'"
                    />
                </form-input>
            </div>
            <div class="custom-modal-footer row-end">
                <button 
                    class="add-button mr-4"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button 
                    class="primary-button"
                    @click="save"
                    ref="save"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { ENTITY_TYPES } from '@/constants.js';
    import { FormInput } from '@/ui';
    import api from '@/headers.js';

    export default {
        name: 'CopyEstimateModal',
        components: {
            FormInput
        },
        props: {
            estimate: {
                type: Object,
                required: true
            },
            entityType: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                comment: ''
            }
        },
        computed: {
            editEstimateRoute() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: {
                        return 'ResidentialEstimateEdit';
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_JOB: {
                        return 'CommercialJobEstimateEdit';
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return 'CommercialLeadEstimateEdit';
                    } break;
                }
            }
        },
        methods: {
            save() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('All fields are required.');

                        return;
                    }

                    this.$refs.save.disabled = true;

                    let params = {
                        estimate_id: this.estimate.id,
                        name: this.estimate.name,
                        comment: this.comment
                    };

                    api.post('/estimates/make-new-version', params) 
                        .then(response => {
                            this.$router.push({ 
                                name: this.editEstimateRoute, 
                                params: { estimate_id: response.data.data.id }
                            });

                            this.$emit('close');
                            this.notifySuccess('Estimate is copied!');
                        })
                        .catch((error) => {
                            this.$refs.save.disabled = false;
                            this.notifyRequestErrors(error);
                        });
                });
            }
        }
    }
</script>