<template>
    <div class="smart-document pb-20">
        <div
            @dragenter.prevent
            @dragover.prevent
            @drop="drop"
            draggable="false"
            @mouseup="onMouseUp()"
            id="smartDoc"
            class="hidden relative flex-shrink-0"
        >
            <img
                v-if="pdfImage && !loading"
                :src="pdfImage"
                alt="pdf"
                draggable="false"
                id="pdfImage"
                class="pdf-image"
            >
            <template v-if="pdfIsLoaded && !loading">
                <div
                    v-for="(field,index) in pageSmartFields"
                    :key="'smart_' + index"
                    :id="'smart_' + index"
                    @dragstart="false"
                    draggable="false"
                    :style="`position:absolute;top: ${field.y}%;left: ${field.x}%; width: ${field.width}%;`"
                    :class="['smart-field grabbable', { 'field-z-index': index === elementIndex }]"
                    @mousedown="dragStart(field, $event, index)"
                >
                    <div
                        v-if="field.type == 'label' || field.type == 'text'"
                        :class="[
                            'smart-object relative s-align-4 ph-2 pv-1',
                            field.type == 'label' ? 'smart-object-system' :
                            field.entity == 'Signature' ? 'smart-object-signature' : 'smart-object-not-linked'
                        ]"
                    >
                        <div class="w-100 s-align-4">
                            <textarea
                                v-if="field.entity != 'Signature'"
                                :type="field.type"
                                :key="'smart_' + index + field.width + field.font_size"
                                class="transparent-input pointer s-align-4 w-100"
                                :style="`font-size: ${Number(field.font_size) + FONT_SCALE}pt; text-align: ${ textAlign(field.alight) };`"
                                :disabled="mode != CREATE_LOCAL"
                                :placeholder="field.title"
                                :value="field.value"
                                @input="field.value = $event.target.value"
                                :draggable="false"
                                rows="1"
                                v-resize-textarea
                            />
                            <span
                                class="f-17-grey"
                                v-else
                            >
                                {{field.title}}
                            </span>
                        </div>
                        <button
                            v-if="field.entity != 'Signature'"
                            class="transparent-button cursor-resize smart-resize-button"
                            @mousedown.stop="widthStart(field, $event)"
                        >
                        </button>
                        <div
                            v-if="openFieldControl == index"
                            class="field-control p-3 s-align-4"
                            @click.stop
                        >
                            <template v-if="field.entity != 'Signature'">
                                <button
                                    v-for="(btn, alignBtnIndex) in alignButtons"
                                    :key="`align-btn-${alignBtnIndex}`"
                                    :class="['transparent-button mr-20', !alignBtnIndex && 'ml-3']"
                                    @click="field.alight = btn.value"
                                >
                                    <component
                                        :is="btn.icon"
                                        :class="field.alight == btn.value ? 'i-white' : 'i-grey'"
                                    />
                                </button>
                                <div class="field-control-separator mr-20"></div>
                                <button
                                    v-for="(fontSizeBtn, fontSizeIndex) in fontSizeButtons"
                                    :key="`font-size-${fontSizeIndex}`"
                                    class="transparent-button mr-20"
                                    @click="field.font_size = fontSizeBtn.value"
                                >
                                    <component
                                        :is="fontSizeBtn.icon"
                                        :class="field.font_size == fontSizeBtn.value ? 'i-white' : 'i-grey'"
                                    />
                                </button>
                                <div class="field-control-separator mr-4"></div>
                            </template>
                            <button
                                class="delete-smart-button s-align-5"
                                @click="deleteSmartField(index)"
                            >
                                <trash-icon
                                    width="10"
                                    height="11"
                                    class="i-white"
                                />
                            </button>
                        </div>
                    </div>
                    <div
                        v-else-if="field.type == 'date'"
                        :class="`smart-date relative row-between p-2`"
                    >
                        <v-date-picker
                            :class="[
                                's-align-4 h-100 pointer',
                                { 'none-events' : mode != CREATE_LOCAL }
                            ]"
                            :value="field.value"
                            @dayclick="onDateClick(field, $event)"
                            show-caps
                            popover-direction="bottom"
                            :tintColor="tintColor"
                            :attributes="attributes"
                            :disabledAttribute="disabledAttribute"
                            :themeStyles="themeStyles"
                        >
                            <span :class="[
                                'date',
                                mode == CREATE_LOCAL ? 'f-17-black' : 'f-17-grey'
                            ]">
                                {{ field.value ? field.value : new Date() | date }}
                            </span>
                        </v-date-picker>
                        <div class="s-align-6">
                            <calendar-icon
                                class="i-violet"
                                size="16"
                            />
                        </div>
                        <div
                            v-if="openFieldControl == index"
                            class="field-control p-3"
                        >
                            <button
                                class="delete-smart-button s-align-5"
                                @click="deleteSmartField(index)"
                            >
                                <trash-icon
                                    width="10"
                                    height="11"
                                    class="i-white"
                                />
                            </button>
                        </div>
                    </div>
                    <div v-else-if="field.type == 'drop down'">
                        <div
                            class="smart-object relative s-align-4 smart-object-not-linked"
                            v-if="field.values"
                        >
                            <dropdown
                                :key="field.values.find(el => !!el.default).value"
                                label="value"
                                :options="field.values"
                                :bydefault="field.values.find(el => !!el.default).id"
                                :width="`${field.width / 100 * wrapper.width - 3}`"
                                height="35"
                                return="object"
                                @select="onDropDownSelect($event,index)"
                                :disabled="mode != CREATE_LOCAL"
                                :placeholder="field.entity === ENTITY_TYPE_SMARTDOC.NOT_LINKED ? 'Select options' : field.title"
                                @open="dropDownZIndex($event, index)"
                            />
                            <button
                                class="transparent-button cursor-resize smart-resize-button"
                                @mousedown.stop="widthStart(field, $event)"
                            >
                            </button>
                            <div
                                v-if="openFieldControl == index"
                                class="field-control dropdown-control s-align-4 p-3"
                            >
                                <template v-if="mode != CREATE_LOCAL && field.entity === ENTITY_TYPE_SMARTDOC.NOT_LINKED">
                                    <button
                                        class="transparent-button edit-dropdown-button mr-5"
                                        @click="editDropdownItems(field.values,index)"
                                    >
                                        <edit-icon class="i-white"/>
                                    </button>
                                    <div class="field-control-separator mr-5" />
                                </template>
                                <button
                                    class="delete-smart-button s-align-5"
                                    @click="deleteSmartField(index)"
                                >
                                    <trash-icon
                                        width="10"
                                        height="11"
                                        class="i-white"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        v-else
                        class="checkbox-button relative s-align-4"
                    >
                        <checkbox-button :class="[{ 'none-events' : mode != CREATE_LOCAL }]">
                            <input
                                type="checkbox"
                                v-model="field.value"
                            />
                        </checkbox-button>
                        <div
                            v-if="openFieldControl == index"
                            class="field-control p-3"
                        >
                            <button
                                class="delete-smart-button s-align-5"
                                @click="deleteSmartField(index)"
                            >
                                <trash-icon
                                    width="10"
                                    height="11"
                                    class="i-white"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="s-align-5">
            <pagination
                v-if="!loading"
                class="mt-20"
                :activePage="Number(page)"
                :quantity="totalPages"
                :showDesc="false"
                @click="loadPage"
            />
        </div>
        <loader
            v-if="loading"
            class="s-align-5"
        />
        <smart-dropdown-items-modal
            v-if="!!showSmartItemsEdit"
            @close="closeDropdown"
            @onUpdate="updateSmartItems"
            :editingFields="editingField"
            :fieldIndex="fieldIndex"
        />
    </div>
</template>

<script>
    import { CheckboxButton, Loader, Pagination, Dropdown } from '@/ui';
    import {
        AddIcon, CalendarIcon, TrashIcon, TextLeftIcon, TextCenterIcon,
        TextRightIcon, FontBigIcon, FontSmallIcon, EditIcon
    } from '@/icons';
    import calendarConfig from '@/mixins/calendarConfig';
    import api from '@/headers.js';
    import moment from 'moment/src/moment';
    import cloneDeep from 'lodash/cloneDeep';
    import { SmartDropdownItemsModal }  from '@/modals';
    import { ENTITY_TYPE_SMARTDOC } from '@/constants';


    const CREATE_LOCAL = 'create-local';
    const EDIT = 'edit';
    const SMALL = '7';
    const BIG = '9';
    const FONT_SCALE = 4;
    const LEFT = 'L';
    const CENTER = 'C';
    const RIGHT = 'R';


    export default {
        name: 'SmartDocument',
        components: {
            CheckboxButton,
            Loader,
            Pagination,
            AddIcon,
            CalendarIcon,
            TrashIcon,
            TextLeftIcon,
            TextCenterIcon,
            TextRightIcon,
            FontBigIcon,
            FontSmallIcon,
            EditIcon,
            Dropdown,
            SmartDropdownItemsModal
        },
        data() {
            return {
                smartFields: [],
                initialSmartFields: [],
                movingField: null,
                shiftX: null,
                shiftY: null,
                pdfImage: '',
                loading: false,
                page: 1,
                totalPages: null,
                wrapper: null,
                openFieldControl: null,
                CREATE_LOCAL: CREATE_LOCAL,
                isSaved: false,
                SMALL: SMALL,
                BIG: BIG,
                FONT_SCALE: FONT_SCALE,
                LEFT: LEFT,
                CENTER: CENTER,
                RIGHT: RIGHT,
                pdfIsLoaded: false,
                showSmartItemsEdit: false,
                editingField: [],
                fieldIndex: null,
                alignButtons: [
                    {
                        value: LEFT,
                        icon: 'text-left-icon'
                    },
                    {
                        value: CENTER,
                        icon: 'text-center-icon'
                    },
                    {
                        value: RIGHT,
                        icon: 'text-right-icon'
                    }
                ],
                fontSizeButtons: [
                    {
                        value: SMALL,
                        icon: 'font-small-icon'
                    },
                    {
                        value: BIG,
                        icon: 'font-big-icon'
                    }
                ],
                ENTITY_TYPE_SMARTDOC: ENTITY_TYPE_SMARTDOC,
                elementIndex: null
            }
        },
        mixins: [ calendarConfig ],
        computed: {
            pdfId() {
                return this.$route.params.id;
            },
            pageSmartFields() {
                return this.smartFields.filter(el => el.page == this.page);
            },
            entityType() {
                return this.$route.query.entity;
            },
            smartId() {
                return this.$route.query.smart_id;
            },
            mode() {
                return this.$route.query.mode;
            },
            entityId() {
                return this.$route.query.entity_id;
            },
            packetId() {
                return this.$route.query.packet_id;
            }
        },
        methods: {
            dropDownZIndex(open, index) {
                open ? this.elementIndex = index : this.elementIndex = null;
            },
            updateSmartItems(data,index) {
                this.smartFields[index].values = data;
            },
            editDropdownItems(values,index) {
                this.editingField = values;
                this.fieldIndex = index;
                this.showSmartItemsEdit = true;
            },
            closeDropdown(data,index) {
                if (!data[0].value) {
                    this.smartFields.splice(index,1);
                }

                this.showSmartItemsEdit = false;
            },
            textAlign(align) {
                switch (align) {
                    case LEFT:
                        return 'left';
                    case CENTER:
                        return 'center';
                    case RIGHT:
                        return 'right';
                }
            },
            getWrapperRect() {
                this.wrapper = document.getElementById('smartDoc').getBoundingClientRect();
            },
            onDropDownSelect(event,index) {
                this.smartFields[index].value = event.value;
            },
            onDateClick(field, event) {
                field.value = moment(event.date).toDate();
            },
            dragStart(field, event, index) {
                this.getWrapperRect();
                this.movingField = field;
                this.openFieldControl = index;
                let elem = document.getElementById('smart_' + index);
                let coords = this.getCoords(elem);
                this.shiftX = event.screenX - coords.left;
                this.shiftY = event.screenY - coords.top;

                document.addEventListener('mousemove', this.onMouseMove);
                document.body.style.userSelect = 'none';
            },
            onMouseUp() {
                this.movingField = null;
                this.shiftX = null;
                this.shiftY = null;
                document.removeEventListener('mousemove', this.onMouseMove);
                document.removeEventListener('mousemove', this.onMouseWidth);
                document.documentElement.style.cursor = '';
                document.body.style.userSelect = '';
            },
            getCoords(elem) {
                let box = elem.getBoundingClientRect();

                return {
                    top: box.top,
                    left: box.left
                }
            },
            onMouseMove(event) {
                let x_px = event.screenX - this.shiftX - this.wrapper.x;
                let y_px = event.screenY  - this.shiftY - this.wrapper.y;
                this.movingField.x = x_px * 100 / this.wrapper.width;
                this.movingField.y = y_px * 100 / this.wrapper.height;
                this.openFieldControl = null;
            },
            widthStart(field, event) {
                this.getWrapperRect();
                this.movingField = field;
                document.addEventListener('mousemove', this.onMouseWidth);
                document.documentElement.style.cursor = 'col-resize';
                window.onselectstart = e => false;
            },
            onMouseWidth(event) {
                let pxWidth = Math.floor(event.screenX - this.wrapper.x - (this.movingField.x / 100 * this.wrapper.width));
                this.movingField.width = pxWidth * 100 / this.wrapper.width;
                this.$forceUpdate();
            },
            drop(event) {
                if (!event.dataTransfer.getData('obj')) {
                    return;
                }

                let objData = JSON.parse(event.dataTransfer.getData('obj'));

                if (objData.entity == 'Signature') {
                    if (this.smartFields.some(el => objData.name == 'Signature customer' ?
                        el.name == 'Signature customer' : el.name == 'Signature company rep')) {
                        let signNumb = objData.name == 'Signature customer' ? '1' : '2';
                        this.notifyError(`Smart document already has a Signature ${signNumb} field`);
                        return;
                    }
                }

                this.getWrapperRect();
                objData.x = (event.layerX - objData.shiftX) * 100 / this.wrapper.width;
                objData.y = (event.layerY - objData.shiftY) * 100 / this.wrapper.height;
                objData.page = this.page;
                objData.alight = LEFT;
                objData.font_size = BIG;

                if (objData.type == 'checkbox') {
                    objData.width = 20 * 100 / this.wrapper.width;
                } else if (objData.type == 'date') {
                    objData.width = 135 * 100 / this.wrapper.width;
                    objData.value = new Date();
                } else if (objData.type == 'drop down') {
                    objData.width = 225 * 100 / this.wrapper.width;
                    objData.values = [{
                        'id': 1,
                        'value' : '',
                        'order' : 0,
                        'default' : true
                    }];
                    this.editingField = objData.values;
                    this.fieldIndex = this.smartFields.length;
                    if (objData.entity === ENTITY_TYPE_SMARTDOC.NOT_LINKED) {
                        this.showSmartItemsEdit = true;
                    }
                } else if (objData.entity == 'Signature') {
                    objData.width = 380 * 100 / this.wrapper.width;
                } else {
                    objData.width = 225 * 100 / this.wrapper.width;
                }

                this.smartFields.push(objData);
            },
            getPdf(page = 1) {
                this.loading = true;
                let params = {
                    file_id: this.pdfId,
                    page: page
                }
                api.get('/document/pdf-to-image', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        this.totalPages = data.total_pages;
                        this.pdfImage = `data:image/png;base64, ${data.image}`;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                        setTimeout(() => {
                            this.pdfIsLoaded = !!this.pdfImage;
                            this.getWrapperRect();
                        }, 0);
                    });
            },
            loadPage(page) {
                this.page = page;
                this.getPdf(page)
            },
            updateSmartDocument(sendData) {
                api.put(`/smart-documents/${this.smartId}`, sendData)
                    .then(response => {
                        this.notifySuccess('Smart Document is saved');
                        this.isSaved = true;
                        this.$router.push({ name: 'CompanyDocuments'});
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.$emit('saveRequestIsGoing', false);
                    });
            },
            createLocalSmartDocument(sendData) {
                api.post('/smart-documents/add-smart', sendData)
                    .then(response => {
                        this.isSaved = true;
                        let selectedFiles = JSON.parse(localStorage.getItem('selectedPacketFiles') || "[]");
                        let selectedParentIndex = selectedFiles.findIndex(el => el.id == this.pdfId);
                        let packetRedirectUrl = localStorage.getItem('packetRedirectUrl');
                        if (selectedParentIndex) {
                            selectedFiles.splice(selectedParentIndex, 1);
                        }
                        selectedFiles.push(response.data.data);
                        localStorage.setItem('selectedPacketFiles', JSON.stringify(selectedFiles));
                        this.notifySuccess('Document is created');
                        this.$router.push(packetRedirectUrl);
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.$emit('saveRequestIsGoing', false);
                    });
            },
            createSmartDocument(sendData) {
                api.post('/smart-documents', sendData)
                    .then(response => {
                        this.isSaved = true;
                        this.notifySuccess('Smart Document is created');
                        this.$router.push({ name: 'CompanyDocuments'});
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.$emit('saveRequestIsGoing', false);
                    });
            },
            saveSmartDoc() {
                this.$emit('saveRequestIsGoing', true);
                let sendData = {};
                let fieldsData = [];
                let pdfSize = document.getElementById('pdfImage').getBoundingClientRect();
                const MARGIN_COEF_HEIGHT = 0.5;

                this.smartFields.forEach((el,index) => {
                    let top = el.y < 0 ? 0.1 : el.y > 96.4 ? 96.4 : el.y;

                    let smartObj = {
                        field_id: el.field_id ? el.field_id : el.id,
                        width: el.width,
                        x: el.x,
                        y: top,
                        alight: el.alight,
                        font_size: el.font_size,
                        page: el.page,
                        margin_y: ((top / 100 * pdfSize.height) / pdfSize.height) * MARGIN_COEF_HEIGHT,
                        entity: el.entity
                    }
                    let regexp = /([A-z]+)([0-9]+)/i;
                    let matchesArray = el.name.match(regexp);
                    let name, number;
                    if (matchesArray != null && matchesArray.length) {
                        name = matchesArray[1];
                        number = matchesArray[2];
                    }
                    if (this.mode == CREATE_LOCAL) {
                        let minFieldHeight = 35;
                        smartObj.height = minFieldHeight * 100 / pdfSize.height;

                        if (el.type == 'date') {
                            smartObj.value = moment(el.value ? el.value : new Date()).format('D MMM YYYY');
                        } else if (el.type == 'drop down') {
                            if (el.values) {
                                smartObj.value = el.value ? el.value : el.values.find(el => !!el.default).value;
                            }
                        } else if (el.type == 'text') {
                            if (name === undefined && number === undefined) {
                                smartObj.value = el.value;
                            } else {
                                smartObj.font_size = SMALL;
                                if (name === 'signer') {
                                    smartObj.value = '{signature,w194,h25:signer' + number + ':Please+sign+here}';
                                } else if (name === 'initials_signer') {
                                    smartObj.value = '{text,w194,h25:signer' + number + ':Initials}';
                                } else if(name === 'text_signer') {
                                    smartObj.value = '{text,w194,h25:signer' + number + ':Text+input+customer}';
                                }
                            }
                        }
                    } else {
                        if (el.type == 'drop down') {
                            smartObj.values = el.values;
                        }
                    }
                    fieldsData.push(smartObj);
                });

                sendData.params = JSON.stringify({'params': fieldsData});

                switch (this.mode) {
                    case EDIT:
                        this.updateSmartDocument(sendData);
                        break;
                    case CREATE_LOCAL:
                        sendData.file_id = this.pdfId;
                        sendData.entity_id = this.entityId;
                        this.createLocalSmartDocument(sendData);
                        break;
                    default:
                        sendData.file_id = this.pdfId;
                        sendData.entity_type = this.entityType;
                        this.createSmartDocument(sendData);
                        break;
                }
            },
            getSmartFields() {
                api.get(`/smart-documents/by-file?file_id=${this.pdfId}`)
                    .then(response => response.data.data)
                    .then(data => {
                        this.smartFields = JSON.parse(data.params).params;
                        this.initialSmartFields = JSON.parse(data.params).params;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            getSmartValues() {
                let params = {
                    file_id: this.pdfId,
                    entity_id: this.entityId
                }
                api.get('/smart-documents/values', {params})
                    .then(response => response.data.data)
                    .then(data => {
                        let fields = JSON.parse(data.params);
                        fields.forEach(el => {
                            el.title = el.type.title;
                            el.type = el.type.type;
                        });
                        this.smartFields = fields;
                        this.initialSmartFields = cloneDeep(fields);
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            deleteSmartField(index) {
                this.smartFields.splice(index,1);
                this.openFieldControl = null;
            },
            outside(event) {
                if (!this.openFieldControl) {
                    return;
                }

                let elem = document.getElementById(`smart_${this.openFieldControl}`);

                if (!elem.contains(event.target)) {
                    this.openFieldControl = null;
                }
            }
        },
        mounted() {
            document.body.addEventListener('click', this.outside, false);
        },
        destroyed() {
            document.body.removeEventListener('click', this.outside, false);
        },
        created() {
            this.getPdf();

            if (this.mode == EDIT) {
                this.getSmartFields();
            }

            if (this.mode == CREATE_LOCAL) {
                this.getSmartValues();
            }
        }
    }
</script>
