<template>
    <svg 
        viewBox="0 0 456.2276306152344 457.2953186035156"
        :width="size"
        :height="size"
        class="color-wheel-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="456.2276306152344" 
            height="457.2953186035156" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(32.31382751464844, 0, 0, 32.31382751464844, -1048.4259033203125, -873.2573815584183)">
            <switch>
                <g>
                    <path 
                        id="XMLID_2_" 
                        class="st0" 
                        d="M39.5,34.3l-6.2-6.2c0.5-0.6,1.3-1,2.2-1h4V34.3z" 
                    />
                    <path 
                        id="XMLID_4_" 
                        class="st1" 
                        d="M46.5,34.3v3.8c0,0.9-0.4,1.7-1,2.2l-6-6H46.5z" 
                    />
                    <path 
                        id="XMLID_3_" 
                        class="st2" 
                        d="M39.5,34.3v-7.2h4c0.9,0,1.7,0.4,2.2,1L39.5,34.3z" 
                    />
                    <path 
                        id="XMLID_6_" 
                        class="st3" 
                        d="M39.5,34.3l6.2-6.2c0.5,0.5,0.8,1.2,0.8,2v4.2H39.5z" 
                    />
                    <path 
                        id="XMLID_7_" 
                        class="st4" 
                        d="M39.5,34.3v6.8h-4c-0.8,0-1.5-0.3-2-0.8L39.5,34.3z" 
                    />
                    <path 
                        id="XMLID_9_" 
                        class="st5" 
                        d="M39.5,34.3h-7v-4.2c0-0.8,0.3-1.5,0.8-2L39.5,34.3z"                         
                    />
                    <path 
                        id="XMLID_8_" 
                        class="st6" 
                        d="M39.5,34.3l6,6c-0.5,0.5-1.2,0.8-2,0.8h-4V34.3z" 
                    />
                    <path 
                        id="XMLID_10_" 
                        class="st7" 
                        d="M39.5,34.3l-6,6c-0.6-0.5-1-1.3-1-2.2v-3.8H39.5z" 
                    />
                </g>
            </switch>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'ColorWheelIcon',
        props: {
            size: {
                type: [Number, String],
                default: 14
            }
        }
    }
</script>