import { 
    ADD_NOTE,
    CLEAR_NOTE,
    SEND_NEW_NOTE,
    SET_NOTES
} from '../actions/notes';
import api from '@/headers.js';

export default {
    namespaced: true,
    state: {
        notes: []
    },
    getters: {
        getNotes: state => state.notes
    },
    mutations: {
        [ADD_NOTE] (state, payload) {
            state.notes.unshift(payload);
        },
        [CLEAR_NOTE] (state) {
            state.notes.splice(0, state.notes.length);
        },
        [SET_NOTES] (state, payload) {
            state.notes = payload;
        }
    },
    actions: {
        [SEND_NEW_NOTE]({ commit, dispatch }, note) {
            return new Promise((resolve, reject) => {
                api.post('/notes', note)
                    .then(response => {
                        commit(ADD_NOTE, response.data.data);
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        }
    }
}