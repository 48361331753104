<template>
    <div
        v-if="activeTab === 'attachments'"
        :class="[
            'form-builder-photo-form',
            { 'form-builder-photo-form-expand' : expandToFullScreen }
        ]"
    >
        <div :class="['row-between pb-8 mt-30', dScreen ? 'ph-30' : 'ph-8']">
            <div class="lg-8 sm-10 column-start">
                <p class="f-16-asphalt f-sbold pb-1">
                    <attach-icon
                        class="i-darkgrey mr-2"
                        size="14"
                    />
                    Attachments
                </p>
                <p
                    class="f-14-grey"
                    v-if="isPhotos"
                >
                    Selected files are included in the report.
                    You can also add comments to every attachment.
                </p>
                <p class="f-14-grey" v-else>
                    There are no photos uploaded to this job yet.
                </p>
            </div>
            <div class="lg-4 sm-2 s-align-3 h-100">
                <button
                    class="view-details-primary ph-4"
                    @click="addPictures"
                    v-if="!sectionAdded && !mScreen"
                >
                    + Add Pictures to Report
                </button>
                <button
                    class="add-button remove-section-button"
                    @click="checkSectionBeforeRemove"
                    v-if="sectionAdded"
                >
                    <trash-icon class="i-darkgrey"/>
                    <span
                        class="f-12-asphalt f-sbold pl-2"
                        v-if="!mScreen"
                    >
                        Remove section
                    </span>
                </button>
            </div>
            <div
                class="w-100 mt-sm-2"
                v-if="!sectionAdded && mScreen"
            >
                <button
                    class="view-details-primary ph-4 add-pictures-on-mobile"
                    @click="addPictures"
                >
                    + Add Pictures to Report
                </button>
            </div>
        </div>
        <div 
            :class="['w-100', expandToFullScreen && 'mb-120']"
            v-if="ifNeedSection"
            id="photo-form"
        >
            <div :class="['w-100', dScreen ? 'ph-30' : 'ph-8']">
                <div class="form-builder-photos w-100">
                    <div class="f-16-asphalt f-sbold row-between bb-grey pv-25 ph-20">
                        <div class="s-align-4">
                            Main
                        </div>
                        <button
                            class="transparent-button"
                            @click="showMainSection = !showMainSection"
                        >
                            <arrow-icon
                                class="i-gray"
                                :transform="showMainSection ? 270 : 90"
                            />
                        </button>
                    </div>
                    <div
                        v-show="showMainSection"
                        class="main-form-builder-photo p-20"
                    >
                        <div
                            class="form-builder-photo main-photo relative"
                            v-if="mainPhoto"
                            @mouseover="setMainFocus(mainPhoto)"
                        >
                            <button
                                class="rotate-button"
                                @click="rotateMainPhoto(mainPhoto)"
                            >
                                <rotate-icon class="i-white"/>
                            </button>
                            <button
                                class="crop-button s-align-5"
                                @click="cropPhoto(0,mainPhoto)"
                            >
                                <crop-icon class="i-white"/>
                            </button>
                            <div class="photo-wrapper hidden s-align-5">
                                <div
                                    class="photo-wrapper"
                                    :style="{
                                        backgroundImage: `url(${mainPhoto.url})`,
                                        transform: `rotate(${mainPhoto.options.rotate}deg)`
                                    }"
                                />
                            </div>
                            <div class="photo-description row-between">
                                <textarea
                                    name="caption"
                                    :rows="tScreen ? 3 : 2"
                                    :id="`comment-mainPhoto`"
                                    v-model="mainPhoto.caption"
                                    maxlength="50"
                                    placeholder="Add comment to picture here..."
                                />
                                <button
                                    class="service-add-button"
                                    @click="removeMainPhoto(mainPhoto)"
                                >
                                    <trash-icon/>
                                </button>
                            </div>
                        </div>
                        <div
                            v-show="showMainSection"
                            :class="[
                                'area-for-drop s-align-5 t-center drop-area column-center',
                                { 'drop-area-active' : dragMainActive }
                            ]"
                            @drop.prevent="dropMainPhoto"
                            @dragenter.prevent="dragMainActive = true"
                            @dragover.prevent="dragMainActive = true"
                            @dragleave.prevent="dragMainActive = false"
                            v-if="dScreen && !mainPhoto"
                        >
                            <full-upload-icon class="i-blue mr-2"/>
                            <span class="f-13-grey f-sbold">
                                Place for the main picture. Drag a picture from the bar <br/> below and drop it here
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="['w-100 mt-8 column-start', dScreen ? 'ph-30' : 'ph-8']">
                <div class="form-builder-photos row-start s-align-4">
                    <div 
                        :class="[
                            'f-16-asphalt f-sbold row-between bb-grey pv-25 ph-20',
                            { 'mb-20' : showBeforeSection }
                        ]"
                    >
                        <div class="s-align-4">
                            Before
                            <div 
                                v-if="beforeAdded.length"
                                class="ml-5 photos-count f-12-blue f-bold s-align-5"
                            >
                                {{ beforeAdded.length }}
                            </div>
                        </div>
                        <button
                            class="transparent-button"
                            @click="showBeforeSection = !showBeforeSection"
                        >
                            <arrow-icon
                                class="i-gray"
                                :transform="showBeforeSection ? 270 : 90" 
                            />
                        </button>
                    </div>
                    <div 
                        v-show="showBeforeSection"
                        class="form-builder-photo hidden relative ml-20"
                        v-for="(photo, index) in beforeAdded"
                        :key="`photo-${index}`"
                        @mouseover="setBeforeFocus(index)"
                    >
                        <button 
                            class="rotate-button"
                            @click="rotateBeforePhoto(index)"
                        >
                            <rotate-icon class="i-white"/>
                        </button>
                        <button 
                            class="crop-button s-align-5"
                            @click="cropPhoto(index,beforeAdded)"
                        >
                            <crop-icon class="i-white"/>
                        </button>
                        <div class="photo-wrapper hidden s-align-5">
                            <div 
                                class="photo-wrapper" 
                                :style="{ 
                                    backgroundImage: `url(${photo.url})`,
                                    transform: `rotate(${photo.options.rotate}deg)`
                                }"
                            >
                            </div>
                        </div>
                        <div class="photo-description row-between">
                            <textarea 
                                name="caption" 
                                :rows="tScreen ? 3 : 2"
                                :id="`comment-before-${index}`"
                                v-model="photo.caption"
                                maxlength="50"
                                placeholder="Add comment to picture here..."
                            />
                            <button 
                                class="service-add-button"
                                @click="removeBeforePhoto(index, photo.id)"
                            >
                                <trash-icon/>
                            </button>
                        </div>
                    </div>
                    <div 
                        v-show="showBeforeSection"
                        :class="[
                            'area-for-drop s-align-5 t-center mb-20', 
                            dropAreaBeforeFullView ? 'drop-area-full row-center mh-20' : 'drop-area column-start ml-20', 
                            { 'drop-area-active' : dragBeforeActive }
                        ]"
                        @drop.prevent="dropBeforePhoto"
                        @dragenter.prevent="dragBeforeActive = true"
                        @dragover.prevent="dragBeforeActive = true"
                        @dragleave.prevent="dragBeforeActive = false"
                        v-if="dScreen"
                    >
                        <full-upload-icon class="i-blue mr-2"/>
                        <span class="f-13-grey f-sbold">
                            Drag items from the bar <br v-if="!dropAreaBeforeFullView"/> below and drop them here
                        </span>
                    </div>
                </div>
                <div class="form-builder-photos row-start s-align-4 mt-8">
                    <div 
                        :class="[
                            'f-16-asphalt f-sbold row-between bb-grey pv-25 ph-20',
                            { 'mb-20' : showDuringSection }
                        ]"
                    >
                        <div class="s-align-4">
                            During
                            <div
                                v-if="duringAdded.length"
                                class="ml-5 photos-count f-12-blue f-bold s-align-5"
                            >
                                {{ duringAdded.length }}
                            </div>
                        </div>
                        <button
                            class="transparent-button"
                            @click="showDuringSection = !showDuringSection"
                        >
                            <arrow-icon
                                class="i-gray"
                                :transform="showDuringSection ? 270 : 90" 
                            />
                        </button>
                    </div>
                    <div 
                        v-show="showDuringSection"
                        class="form-builder-photo hidden relative ml-20"
                        v-for="(photo, index) in duringAdded"
                        :key="`photo-${index}`"
                        @mouseover="setDuringFocus(index)"
                    >
                        <button 
                            class="rotate-button"
                            @click="rotateDuringPhoto(index)"
                        >
                            <rotate-icon class="i-white"/>
                        </button>
                        <button 
                            class="crop-button s-align-5"
                            @click="cropPhoto(index,duringAdded)"
                        >
                            <crop-icon class="i-white"/>
                        </button>
                        <div class="photo-wrapper hidden s-align-5">
                            <div 
                                class="photo-wrapper" 
                                :style="{ 
                                    backgroundImage: `url(${photo.url})`,
                                    transform: `rotate(${photo.options.rotate}deg)`
                                }"
                            />
                        </div>
                        <div class="photo-description row-between">
                            <textarea 
                                name="caption" 
                                :rows="tScreen ? 3 : 2"
                                :id="`comment-during-${index}`"
                                v-model="photo.caption"
                                maxlength="50"
                                placeholder="Add comment to picture here..."
                            />
                            <button 
                                class="service-add-button"
                                @click="removeDuringPhoto(index, photo.id)"
                            >
                                <trash-icon/>
                            </button>
                        </div>
                    </div>
                    <div 
                        v-show="showDuringSection"
                        :class="[
                            'area-for-drop s-align-5 t-center mb-20', 
                            dropAreaDuringFullView ? 'drop-area-full row-center mh-20' : 'drop-area column-start ml-20', 
                            { 'drop-area-active' : dragDuringActive }
                        ]"
                        @drop.prevent="dropDuringPhoto"
                        @dragenter.prevent="dragDuringActive = true"
                        @dragover.prevent="dragDuringActive = true"
                        @dragleave.prevent="dragDuringActive = false"
                        v-if="dScreen"
                    >
                        <full-upload-icon class="i-blue mr-2"/>
                        <span class="f-13-grey f-sbold">
                            Drag items from the bar <br v-if="!dropAreaDuringFullView"/> below and drop them here
                        </span>
                    </div>
                </div>
                <div class="form-builder-photos row-start s-align-4 mt-8">
                    <div 
                        :class="[
                            'f-16-asphalt f-sbold row-between bb-grey pv-25 ph-20',
                            { 'mb-20' : showAfterSection }
                        ]"
                    >
                        <div class="s-align-4">
                            After
                            <div
                                v-if="afterAdded.length" 
                                class="ml-5 photos-count f-12-blue f-bold s-align-5"
                            >
                                {{ afterAdded.length }}
                            </div>
                        </div>
                        <button
                            class="transparent-button"
                            @click="showAfterSection = !showAfterSection"
                        >
                            <arrow-icon
                                class="i-gray"
                                :transform="showAfterSection ? 270 : 90" 
                            />
                        </button>
                    </div>
                    <div 
                        v-show="showAfterSection"
                        class="form-builder-photo hidden relative ml-20"
                        v-for="(photo, index) in afterAdded"
                        :key="`photo-${index}`"
                        @mouseover="setAfterFocus(index)"
                    >
                        <button 
                            class="rotate-button"
                            @click="rotateAfterPhoto(index)"
                        >
                            <rotate-icon class="i-white"/>
                        </button>
                        <button 
                            class="crop-button s-align-5"
                            @click="cropPhoto(index,afterAdded)"
                        >
                            <crop-icon class="i-white"/>
                        </button>
                        <div class="photo-wrapper hidden s-align-5">
                            <div 
                                class="photo-wrapper" 
                                :style="{ 
                                    backgroundImage: `url(${photo.url})`,
                                    transform: `rotate(${photo.options.rotate}deg)`
                                }"
                            />
                        </div>
                        <div class="photo-description row-between">
                            <textarea 
                                name="caption" 
                                :rows="tScreen ? 3 : 2"
                                :id="`comment-after-${index}`"
                                v-model="photo.caption"
                                maxlength="50"
                                placeholder="Add comment to picture here..."
                            />
                            <button 
                                class="service-add-button"
                                @click="removeAfterPhoto(index, photo.id)"
                            >
                                <trash-icon/>
                            </button>
                        </div>
                    </div>
                    <div 
                        v-show="showAfterSection"
                        :class="[
                            'area-for-drop s-align-5 t-center mb-20', 
                            dropAreaAfterFullView ? 'drop-area-full row-center mh-20' : 'drop-area column-start ml-20', 
                            { 'drop-area-active' : dragAfterActive }
                        ]"
                        @drop.prevent="dropAfterPhoto"
                        @dragenter.prevent="dragAfterActive = true"
                        @dragover.prevent="dragAfterActive = true"
                        @dragleave.prevent="dragAfterActive = false"
                        v-if="dScreen"
                    >
                        <full-upload-icon class="i-blue mr-2"/>
                        <span class="f-13-grey f-sbold">
                            Drag items from the bar <br v-if="!dropAreaAfterFullView"/> below and drop them here
                        </span>
                    </div>
                </div>
            </div>
            <div 
                :class="[
                    'form-builder-bar w-100 mt-30',
                    { 'expand-to-full-screen' : expandToFullScreen },
                ]"
            >
                <div
                    :class="[
                        'row-start ph-20 pt-20 mb-5 bb-darkgrey s-align-2',
                        mScreen && 'row-between'
                    ]"
                >
                    <div class="f-16-white f-sbold row-between lg-8 sm-10 flex-grow-1">
                        <span
                            v-if="!dScreen"
                            class="f-16-white f-sbold mb-20"
                        >
                            Choose pictures for the report
                        </span>
                        <div
                            :class="[
                                'bar-photos-tabs s-align-5',
                                { 'mt-2' : mScreen }
                            ]"
                        >
                            <button
                                :class="[
                                    'transparent-button mr-8 uppercase pb-20',
                                    activeBarTab == TECHNICIAN ? 'f-12-white bb-blue' : 'f-12-grey'
                                ]"
                                @click="activeBarTab = TECHNICIAN"
                            >
                                {{ dScreen ? 'Uploaded by technician' : 'By technician'}}
                            </button>
                            <button
                                :class="[
                                    'transparent-button uppercase pb-20',
                                    activeBarTab == USER ? 'f-12-white bb-blue' : 'f-12-grey'
                                ]"
                                @click="activeBarTab = USER"
                            >
                                Your pictures
                            </button>
                        </div>
                        <button
                            v-if="dScreen"
                            class="transparent-button p-2"
                            @click="expandToFullScreen = !expandToFullScreen"
                        >
                            <component
                                :is="!expandToFullScreen ? 'resize-icon' : 'decrease-icon'"
                                class="i-white"
                            />
                        </button>
                    </div>
                    <span
                        v-if="mScreen"
                        class="badge-content f-11-black f-sbold mt-1 ml-2"
                    >
                        {{ selected.length }}
                    </span>
                </div>
                <div 
                    :class="[
                        'mb-4 ph-20', 
                        mScreen ? 'column-start' : 'row-between'
                    ]"
                >
                    <button
                        class="primary-button f-12-white f-sbold"
                        v-if="activeBarTab == USER && !showEmptyPictureList"
                        @click="startUploadImage()"
                    >
                        <full-upload-icon 
                            class="i-white mr-2"
                            size="18"
                        />
                        Browse...
                    </button>
                    <div
                        v-if="activeBarTab == TECHNICIAN"
                        :class="['s-align-4 bar-photos-tabs', mScreen && 'bar-photos-tabs-dark p-4 w-100']"
                    >
                        <button
                            :class="[
                                'transparent-button mr-8',
                                mScreen && 'flex-grow-1',
                                activeTechnicianTab == 'before' ? 'f-12-white active-technician-tab' : 'f-12-grey ml-8'
                            ]"
                            @click="activeTechnicianTab = 'before'"
                        >
                            Before
                        </button>
                        <button
                            :class="[
                                'transparent-button',
                                mScreen && 'flex-grow-1',
                                activeTechnicianTab == 'after' ? 'f-12-white active-technician-tab' : 'f-12-grey'
                            ]"
                            @click="activeTechnicianTab = 'after'"
                        >
                            After
                        </button>
                    </div>
                    <div
                        v-if="showEmptyPictureList"
                        :class="['s-align-5 w-100', mScreen && 'mt-8']"
                    >
                        <div
                            :class="[
                                'align-center mt-8',
                                mScreen ? 'column-center' : 'row-center'
                            ]"
                        >
                            <pictures-list-empty
                                size="90"
                            />
                            <div
                                :class="[
                                    's-align-2',
                                    mScreen ? 'column-center' : 'column-start ml-30'
                                ]"
                            >
                                <div class="f-16-white f-sbold mb-1">The list is empty</div>
                                <div
                                    v-if="activeBarTab == USER"
                                    class="f-13-white"
                                >
                                    To download images, <br />select
                                    <span
                                        class="f-13-blue pointer"
                                        @click="startUploadImage()"
                                    >
                                        Browse
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="s-align-6">
                        <div 
                            v-if="selected.length && !mScreen"
                            :class="[
                                's-align-6',
                                { 'mt-4' : mScreen }
                            ]"
                        >
                            <span class="f-13-grey mr-20">
                                <span class="f-13-white">
                                    {{ selected.length }}
                                </span>
                                Files Selected
                            </span>
                            <button
                                class="primary-button mr-5 f-12-white f-sbold"
                                @click="downloadPicture()"
                            >
                                <download-icon 
                                    class="i-white mr-4"
                                    size="14"
                                />
                                Download
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    class="form-builder-bar-photos" 
                    id="leakResponsePhotos"
                >
                    <label 
                        class="form-builder-bar-photo relative flex-shrink-0"
                        v-for="(photo, dragIndex) in filterPhotos"
                        :key="`photo-bar-${dragIndex}`"
                        :style="{ backgroundImage: `url(${photo.url})` }"
                        draggable="true"
                        @drag="dragPhoto($event)"
                        @dragstart="startToDragPhoto($event, dragIndex)"
                    >
                        <div class="angle-input">
                            <input 
                                type="checkbox" 
                                :name="`added-${dragIndex}`"
                                v-model="selected"
                                :value="photo"
                            />
                            <span class="angle-input-icon">
                                <mark-icon size="12"/>
                            </span>
                        </div>
                        <div 
                            class="form-builder-bar-caption w-100"
                            v-if="photo.caption"
                        >
                            <span class="f-10-white f-sbold break-word wrap w-100 max-w-100">
                                {{ photo.caption }}
                            </span>
                        </div>
                    </label>
                    <div
                        v-for="(item,index) in mockPhoto"
                        :key="`mockPhoto-${index}`"
                        class="mock-loading-image-block s-align-5 flex-shrink-0 mr-5"
                    >
                        <loader size="mini"/>
                    </div>
                </div>
                <div
                    class="row-between p-5 flex-no-wrap ph-20"
                >
                    <div
                        v-if="!dScreen"
                        @click="moveImageToWindowVisible = true, moveActiveButton = []"
                        class="row-start s-align-4 flex-grow-1 pointer"
                    >
                        <rotate-icon class="i-white" />
                        <span class="f-13-white ml-2 mt-1">Move images to...</span>
                    </div>
                    <div v-if="!dScreen">
                        <button
                            class="transparent-button p-2"
                            @click="expandToFullScreen = !expandToFullScreen"
                        >
                            <component
                                :is="!expandToFullScreen ? 'resize-icon' : 'decrease-icon'"
                                class="i-white"
                            />
                        </button>
                    </div>
                </div>
                <div 
                    v-if="!dScreen && moveImageToWindowVisible"
                    class="row-between p-5 mt-6 move-image-to z-index-2 h-100 w-100"
                >
                    <div class="mobile-buttons w-100 h-100 column-start">
                        <div
                            @click="moveImageToWindowVisible = false"
                            class="row-between s-align-4 pv-8 mb-8 ph-4 pointer"
                        >
                            <pointer-icon
                                class="i-grey mr-4"
                                transform="180"
                            />
                            <span class="f-16-black f-sbold flex-grow-1">Move images to...</span>
                        </div>
                        <div class="w-100">
                            <div
                                :class="[
                                    'row-start p-8 b-grey w-100 s-align-4 mb-4 move-image-to-button pointer',
                                    activeButton('main') && 'move-image-to-button-active'
                                ]"
                                @click="moveImagesToSelect('main')"
                            >
                                <div class="move-image-icon s-align-5 mr-2 bg-yellow">
                                    <dashboard-icon
                                        size="14"
                                        class="i-white"
                                    />
                                </div>
                                <div class="column-start">
                                    <span class="f-13-black f-sbold">Main section</span>
                                </div>
                            </div>
                            <div
                                :class="[
                                    'row-start p-8 b-grey w-100 s-align-4 mb-4 move-image-to-button pointer',
                                    activeButton('before') && 'move-image-to-button-active'
                                ]"
                                @click="moveImagesToSelect('before')"
                            >
                                <div class="move-image-icon s-align-5 mr-2 bg-green">
                                    <back-icon class="i-white" />
                                </div>
                                <div class="column-start">
                                    <span class="f-13-black f-sbold">Before section</span>
                                </div>
                            </div>
                            <div
                                :class="[
                                    'row-start p-8 b-grey w-100 s-align-4 mb-4 move-image-to-button pointer',
                                    activeButton('during') && 'move-image-to-button-active'
                                ]"
                                @click="moveImagesToSelect('during')"
                            >
                                <div class="move-image-icon s-align-5 mr-2 bg-darkblue-20">
                                    <in-process-icon
                                        class="i-white"
                                        size="12"
                                    />
                                </div>
                                <div class="column-start">
                                    <span class="f-13-black f-sbold">During section</span>
                                </div>
                            </div>
                            <div
                                :class="[
                                    'row-start p-8 b-grey w-100 s-align-4 mb-4 move-image-to-button pointer',
                                    activeButton('after') > 0 && 'move-image-to-button-active'
                                ]"
                                @click="moveImagesToSelect('after')"
                            >
                                <div class="move-image-icon s-align-5 mr-2 bg-violet-80">
                                    <forward-icon class="i-white" />
                                </div>
                                <div class="column-start">
                                    <span class="f-13-black f-sbold">After section</span>
                                </div>
                            </div>
                        </div>
                        <div class="w-100 flex-grow-1 s-align-6">
                            <button
                                class="primary-button move-to-fullwith-button mt-8 self-end"
                                @click="moveImagesTo"
                            >
                                Move images
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal
            v-if="showConfirmModal"
            @close="showConfirmModal = false"
        >
            <div slot="modal-body">
                <div class="t-center column-center pb-4">
                    <span class="f-14-black pb-2">
                        Are you sure you want to remove these changes?
                    </span>
                    <span class="f-14-black f-sbold">They will not be saved</span>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="row-between">
                    <button 
                        class="add-button"
                        @click="showConfirmModal = false"
                    >
                        Cancel
                    </button>
                    <button 
                        class="delete-button"
                        @click="removeSection"
                    >
                        Delete
                    </button>
                </div>
            </div>
        </modal>
        <input 
            type="file" 
            name="file_upload"
            ref="uploadInput"
            accept=".jpeg, .jpg, .bmp, .png, .gif"
            @change="fileInputHandler"
            multiple
            hidden
        />
        <avatar-cropper
            trigger="#start-crop"
            ref="avatarCrop"
            :uploadHandler="cropUpload"
            :labels="cropButtons"
            data-name="file-upload"
        />
    </div>
</template>

<script>
    // dispatch (get photos request) for this component is in the 'FormBuilderNavPanel'
    import { AttachIcon, TrashIcon, RotateIcon, MarkIcon, PointerIcon, PicturesListEmpty,
        FullUploadIcon, ArrowIcon, DownloadIcon, CropIcon, ResizeIcon, DecreaseIcon, BackIcon,
        DashboardIcon, InProcessIcon, ForwardIcon
    } from '@/icons';
    import { clearArray, scrollToElement, horizontalScrollToLast } from '@/helpers';
    import { Modal, Loader } from '@/ui';
    import api from '@/headers.js';
    import AvatarCropper from "vue-avatar-cropper";

    const TECHNICIAN = 'technician';
    const USER = 'user';

    export default {
        name: 'FormBuilderLeakPhotoForm',
        components: {
            ForwardIcon,
            InProcessIcon,
            BackIcon,
            DashboardIcon,
            PicturesListEmpty,
            AttachIcon,
            TrashIcon,
            RotateIcon,
            MarkIcon,
            Modal,
            PointerIcon,
            FullUploadIcon,
            ArrowIcon,
            DownloadIcon,
            CropIcon,
            AvatarCropper,
            ResizeIcon,
            DecreaseIcon,
            Loader
        },
        props: {
            saved: {
                type: Object,
                required: false
            },
            crearPhoto: {
                type: Boolean,
                default: false
            },
            entityType: {
                type: String,
                required: true
            },
            entityId: {
                type: String,
                required: true
            },
            activeTab: {
                type: String,
                required: false,
            }
        },
        data() {
            return {
                moveImageToWindowVisible: false,
                sectionAdded: true,
                selected: [],
                added: [],
                mainPhoto: null,
                beforeAdded: [],
                duringAdded: [],
                afterAdded: [],
                dragBeforeActive: false,
                dragDuringActive: false,
                dragAfterActive: false,
                dragMainActive: false,
                showConfirmModal: false,
                expandToFullScreen: false,
                showMainSection: true,
                showAfterSection: true,
                showDuringSection: true,
                showBeforeSection: true,
                activeBarTab: TECHNICIAN,
                moveActiveButton: [],
                cropButtons: {
                    submit: 'Save',
                    cancel: 'Cancel'
                },
                photosForCropData: null,
                photosForCropIndex: null,
                TECHNICIAN: TECHNICIAN,
                USER: USER,
                activeTechnicianTab: 'before',
                mockPhoto: []
            }
        },
        computed: {
            showEmptyPictureList() {
                return (!this.selected.length && this.activeBarTab == TECHNICIAN && !this.filterPhotos.length)
                    || (this.activeBarTab == USER && !this.photos.length);
            },
            filterPhotos() {
                return this.photos
                    .filter(el => el.owner_type == this.activeBarTab)
                    .filter(el => this.activeBarTab != USER ? el.order_type == this.activeTechnicianTab : true);
            },
            photos() {
                return this.$store.getters['formBuilder/getFormBuilderPhoto'];
            },
            userId() {
                return this.$store.getters['user/getUserId'];
            },
            dropAreaBeforeFullView() {
                return !this.beforeAdded.length;
            },
            dropAreaDuringFullView() {
                return !this.duringAdded.length;
            },
            dropAreaAfterFullView() {
                return !this.afterAdded.length;
            },
            isPhotos() {
                return this.photos.length;
            },
            getCountAttachment() {
                let sum = 0;
                if (this.mainPhoto && this.mainPhoto.length) {
                    sum += this.mainPhoto.length;
                }
                if (this.beforeAdded && this.beforeAdded.length) {
                    sum += this.beforeAdded.length;
                }
                if (this.duringAdded && this.duringAdded.length) {
                    sum += this.duringAdded.length;
                }
                if (this.afterAdded && this.afterAdded.length) {
                    sum += this.afterAdded.length;
                }
                return sum;
            },
            ifNeedSection() {
                return this.sectionAdded  || this.mainPhoto || this.beforeAdded.length || this.duringAdded.length || this.afterAdded.length;
            },
        },
        watch: {
            getCountAttachment: {
                deep: true,
                handler (val, oldVal) {
                    if (val != oldVal) {
                        this.$store.commit('formBuilder/GET_COUNT_ATTACHMENT', this.getCountAttachment);
                    }
                }
            },
            mainPhoto(val) {
                this.$emit('set', { 
                    mainPhoto: this.mainPhoto,
                    beforePhotos: this.beforeAdded,
                    duringPhotos: this.duringAdded,
                    afterPhotos: this.afterAdded
                });                
            },
            beforeAdded(val) {
                this.$emit('set', { 
                    mainPhoto: this.mainPhoto,
                    beforePhotos: this.beforeAdded,
                    duringPhotos: this.duringAdded,
                    afterPhotos: this.afterAdded
                });              
            },
            duringAdded(val) {
                this.$emit('set', { 
                    mainPhoto: this.mainPhoto,
                    beforePhotos: this.beforeAdded,
                    duringPhotos: this.duringAdded,
                    afterPhotos: this.afterAdded
                });              
            },
            afterAdded(val) {
                this.$emit('set', { 
                    mainPhoto: this.mainPhoto,
                    beforePhotos: this.beforeAdded,
                    duringPhotos: this.duringAdded,
                    afterPhotos: this.afterAdded
                });              
            },
            saved: {
                immediate: true, 
                handler (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        this.mainPhoto = newValue.mainPhoto;
                        this.beforeAdded = newValue.beforePhotos;
                        this.duringAdded = newValue.duringPhotos;
                        this.afterAdded = newValue.afterPhotos;
                        
                        if (!newValue.mainPhoto || !newValue.beforePhotos.length || 
                            !newValue.duringPhotos.length || !newValue.afterPhotos.length) {
                            this.sectionAdded = false;
                        } else {
                            this.sectionAdded = true;
                        }
                    }
                }
            },
            crearPhoto: {
                immediate: true,
                handler (newVal, oldVal) {
                    this.removeSection();
                }
            }
        },
        methods: {
            dragPhoto(event) {
                const heightNavPanel = document.querySelector('.menu').clientHeight;
                const scrollStep = 20;
                let scrollTop = window.pageYOffset;
                if (event.clientY < heightNavPanel && event.clientY !== 0) {
                    window.scrollTo(0, scrollTop -= scrollStep);
                } else if (window.innerHeight - heightNavPanel <= event.clientY) {
                    window.scrollTo(0, scrollTop += scrollStep);
                }
            },
            activeButton(el) {
                const index = this.moveActiveButton.indexOf(el);
                return index !== -1;
            },
            moveImagesToSelect(el) {
                const index = this.moveActiveButton.indexOf(el);
                if (index < 0) {
                    this.moveActiveButton.push(el);
                } else {
                    this.moveActiveButton.splice(index, 1);
                }
            },
            cropPhoto(index,photos) {
                this.photosForCropData = photos;
                if (Array.isArray(photos)) {
                    this.$refs.avatarCrop.dataUrl = photos[index].url;
                    this.photosForCropIndex = index;
                } else {
                    this.$refs.avatarCrop.dataUrl = photos.url;
                }
            },
            cropUpload(resp) {
                resp.getCroppedCanvas().toBlob(blob => {
                    this.uploadImage(blob, true);
                })
            },
            downloadPicture() {
                this.selected.forEach(el => {
                    fetch(`${el.url}?date=${new Date}`)
                        .then(data => data.blob())
                        .then(blob => {
                            let link = document.createElement('a');
                            link.href = URL.createObjectURL(blob);
                            link.download = el.path;
                            link.click();
                        })
                        .catch(error => this.notifyError(error));
                })
            },
            startUploadImage() {
                this.$refs.uploadInput.click();
            },
            fileInputHandler(event) {
                if (!this.$refs.uploadInput.files[0]) return false;
                let maxPhotoSize = 9961472;
                let notification = '';
                let files = Object.values(event.target.files).filter(item => {
                    //9961472 - 9.5 MB in bytes. formula - (maxPhotoSize/1024)/1024
                    if(item.size > maxPhotoSize) {
                        notification += item.name + ', ';
                        return false;
                    }
                    return true;
                });
                if (notification != '') {
                    notification = notification.slice(0, notification.length -2);
                    this.notifyError(`Files with name ${notification} have size more then 10MB! Need less.`);
                }
                files.forEach(file => {
                    this.uploadImage(file);
                });
            },
            uploadImage(file, fromCrop = false) {
                let formData = new FormData();
                this.mockPhoto.push('MOCK-Loading');

                formData.append('entity_id', this.entityId);
                formData.append('entity_type', this.entityType);
                formData.append('image', file);
                formData.append('order_type', 'user_upload');
                formData.append('owner_type', 'user');
                formData.append('owner_id', this.userId);
                formData.append('report_type', 'Preload');
                formData.append('lastItem', 1);

                this.$nextTick(() => {
                    horizontalScrollToLast(document.getElementById(`leakResponsePhotos`));
                });

                api.post('/technicians/draft-report/image', formData)
                    .then(response => {
                        let img = response.data.data;
                        img.url = `${img.url}original${img.path}`;
                        this.photos.push(img);
                        if (fromCrop) {
                            img.options = JSON.parse(img.options);
                            if (Array.isArray(this.photosForCropData)) {
                                this.photosForCropData[this.photosForCropIndex] = img;
                            } else {
                                this.mainPhoto = img;
                            }
                        }
                        this.$forceUpdate();
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.mockPhoto.splice(0,1);
                    });
            },
            rotateBeforePhoto(index) {
                this.beforeAdded[index].options.rotate == 360
                    ? this.beforeAdded[index].options.rotate = 90
                    : this.beforeAdded[index].options.rotate = this.beforeAdded[index].options.rotate + 90;
                this.$forceUpdate();
            },
            rotateDuringPhoto(index) {
                this.duringAdded[index].options.rotate == 360
                    ? this.duringAdded[index].options.rotate = 90
                    : this.duringAdded[index].options.rotate = this.duringAdded[index].options.rotate + 90;
                this.$forceUpdate();
            },
            rotateAfterPhoto(index) {
                this.afterAdded[index].options.rotate == 360
                    ? this.afterAdded[index].options.rotate = 90
                    : this.afterAdded[index].options.rotate = this.afterAdded[index].options.rotate + 90;
                this.$forceUpdate();
            },
            rotateMainPhoto() {
                this.mainPhoto.options.rotate == 360
                    ? this.mainPhoto.options.rotate = 90
                    : this.mainPhoto.options.rotate = this.mainPhoto.options.rotate + 90;
                this.$forceUpdate();
            },
            setBeforeFocus(index) {
                document.getElementById(`comment-before-${index}`).focus();
            },
            setDuringFocus(index) {
                document.getElementById(`comment-during-${index}`).focus();
            },
            setAfterFocus(index) {
                document.getElementById(`comment-after-${index}`).focus();
            },
            setMainFocus() {
                document.getElementById(`comment-mainPhoto`).focus();
            },
            startToDragPhoto(event, index) {
                // check in case the photo was not selected but grabbed using drag event
                let check = this.selected.find(s => s.id === this.filterPhotos[index].id);
                if (check === undefined) {
                    this.selected.push(this.filterPhotos[index]);
                }

                event.dataTransfer.setData('Photos', JSON.stringify(this.selected));
            },
            dropMainPhoto(event) {
                // user cannot upload new photos not from a saved report
                if (!event.dataTransfer.getData('Photos')) {
                    this.notifyWarning('You are trying to add a new photo. Use uploaded photos for your report.');
                    this.dragMainActive = false;
                    return false;
                }
                
                let newPhotos = JSON.parse(event.dataTransfer.getData('Photos'));

                newPhotos.map((ph) => {
                    ph.options = JSON.parse(ph.options);
                });

                this.mainPhoto = newPhotos[0];
                this.dragMainActive = false;
                this.selected = [];
            },
            dropBeforePhoto(event) {
                // user cannot upload new photos not from a saved report
                if (!event.dataTransfer.getData('Photos')) {
                    this.notifyWarning('You are trying to add a new photo. Use uploaded photos for your report.');
                    this.dragBeforeActive = false;
                    return false;
                }

                let newPhotos = JSON.parse(event.dataTransfer.getData('Photos'));

                newPhotos.map((ph) => {
                    ph.options = JSON.parse(ph.options);
                });

                this.beforeAdded.push(...newPhotos);
                this.dragBeforeActive = false;
                this.selected = [];
            },
            dropDuringPhoto(event) {
                // user cannot upload new photos not from a saved report
                if (!event.dataTransfer.getData('Photos')) {
                    this.notifyWarning('You are trying to add a new photo. Use uploaded photos for your report.');
                    this.dragDuringActive = false;
                    return false;
                }

                let newPhotos = JSON.parse(event.dataTransfer.getData('Photos'));

                newPhotos.map((ph) => {
                    ph.options = JSON.parse(ph.options);
                });

                this.duringAdded.push(...newPhotos);
                this.dragDuringActive = false;
                this.selected = [];
            },
            dropAfterPhoto(event) {
                // user cannot upload new photos not from a saved report
                if (!event.dataTransfer.getData('Photos')) {
                    this.notifyWarning('You are trying to add a new photo. Use uploaded photos for your report.');
                    this.dragAfterActive = false;
                    return false;
                }

                let newPhotos = JSON.parse(event.dataTransfer.getData('Photos'));

                newPhotos.map((ph) => {
                    ph.options = JSON.parse(ph.options);
                });

                this.afterAdded.push(...newPhotos);
                this.dragAfterActive = false;
                this.selected = [];
            },
            moveImagesTo() {
                if (!this.selected.length) {
                    this.notifyError('Selected photo');
                    return this.moveImageToWindowVisible = false;
                    this.moveActiveButton = [];
                }

                if (this.moveActiveButton.indexOf('main') !== -1) {
                    this.mainPhoto = this.selected[0];
                }

                if (this.moveActiveButton.indexOf('before') !== -1) {
                    this.beforeAdded.push(...this.selected);
                }

                if (this.moveActiveButton.indexOf('during') !== -1) {
                    this.duringAdded.push(...this.selected);
                }

                if (this.moveActiveButton.indexOf('after') !== -1) {
                    this.afterAdded.push(...this.selected);
                }

                this.selected = [];

                this.moveImageToWindowVisible = false;

            },
            removeBeforePhoto(index, id) {
                this.beforeAdded.splice(index, 1);
                this.selected.splice(this.selected.findIndex(ph => ph.id === id), 1);
            },
            removeDuringPhoto(index, id) {
                this.duringAdded.splice(index, 1);
                this.selected.splice(this.selected.findIndex(ph => ph.id === id), 1);
            },
            removeAfterPhoto(index, id) {
                this.afterAdded.splice(index, 1);
                this.selected.splice(this.selected.findIndex(ph => ph.id === id), 1);
            },
            removeMainPhoto() {
                this.mainPhoto = null;
            },
            addPictures() {
                this.sectionAdded = true;

                this.$nextTick(function () {
                    scrollToElement(document.getElementById('photo-form'));
                });
            },
            checkSectionBeforeRemove() {
                if (this.beforeAdded.length || this.duringAdded.length || this.afterAdded.length || this.mainPhoto) {
                    this.showConfirmModal = true;
                    return;
                }

                this.removeSection();
            },
            removeSection() {
                this.sectionAdded = false;
                this.mainPhoto = null;
                clearArray(this.beforeAdded);
                clearArray(this.duringAdded);
                clearArray(this.afterAdded);
                clearArray(this.selected);

                this.showConfirmModal = false;
            }
        },
        mounted() {
            window.addEventListener('resize', () => {
                this.expandToFullScreen = false;
            });
        }
    }
</script>
