var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'w-box w-100 events-box',
        { 'events-mock-loading' : _vm.loading && !_vm.requestDone},
        { 's-align-4 events-box-empty' : !_vm.loading && _vm.requestDone && !_vm.event }
    ]},[(_vm.loading || !_vm.requestDone)?_c('div',{staticClass:"s-align-5"},[_c('loader',{attrs:{"size":"small"}})],1):_vm._e(),(!_vm.loading && _vm.requestDone)?_c('div',[(!_vm.event)?_c('div',{staticClass:"events-empty s-align-4"},[_c('img',{staticClass:"ml-30 mr-30",attrs:{"src":require("@/assets/images/events_empty_new.svg"),"alt":"Calendar Icon"}}),_c('div',{staticClass:"column-start"},[_c('p',{staticClass:"f-16-darkgrey f-sbold"},[_vm._v("Events")]),_c('div',{staticClass:"f-13-darkgrey"},[_vm._v("\n                    This "+_vm._s(_vm.isJobLeadName)+" has no events at this moment. To create a new event\n                    "),_c('button',{staticClass:"transparent-button f-12-blue f-sbold redirect-button",on:{"click":_vm.redirectToRoute}},[_vm._v("\n                        click here\n                    ")])])])]):_c('div',{staticClass:"w-100 stretch column-start"},[_c('div',{staticClass:"row-between bb-grey pb-8"},[_c('div',{staticClass:"s-align-4"},[(_vm.event.is_upcoming)?_c('div',{staticClass:"events-box-arrow s-align-5"},[_c('next-icon',{staticClass:"i-blue",attrs:{"size":"12"}})],1):_c('div',{staticClass:"events-box-mark s-align-5"},[_c('mark-icon',{staticClass:"i-white",attrs:{"size":"12"}})],1),_c('div',{staticClass:"column-start ml-7"},[_c('p',{staticClass:"f-16-asphalt f-sbold"},[_vm._v("\n                            "+_vm._s(_vm.event.is_upcoming ? "Upcoming event" : 'Finished last event')+"\n                        ")]),_c('span',{staticClass:"f-13-grey"},[_vm._v("To access list of all events? click View all")])])]),_c('button',{staticClass:"transparent-button f-12-blue f-sbold redirect-button",on:{"click":_vm.redirectToRoute}},[_vm._v("\n                    View all\n                    "),_c('arrow-icon',{staticClass:"i-blue ml-2",attrs:{"size":"8","transform":0}})],1)]),_c('div',{class:[
                's-align-4 mt-8',
                { 'white-spirit-block relative' : !_vm.event.is_upcoming }
            ]},[_c('avatar',{attrs:{"url":_vm.event.owner.avatar,"name":_vm.event.owner.fullname,"size":"50","border":false}}),_c('div',{staticClass:"column-start ml-7"},[_c('p',{staticClass:"f-15-black f-sbold"},[_vm._v(" "+_vm._s(_vm.event.owner.fullname)+" ")]),_c('div',{staticClass:"row-start mt-1"},[_c('div',{staticClass:"f-13-black s-align-4 mr-30"},[_c('calendar-icon',{staticClass:"i-blue mr-6",attrs:{"size":"15"}}),_vm._v("\n                            "+_vm._s(_vm._f("monthDayYear")(_vm.event.dtstart))+"\n                        ")],1),_c('div',{staticClass:"s-align-4 f-14-black"},[_c('clock-icon',{staticClass:"i-blue mr-6",attrs:{"size":"15"}}),(!+_vm.event.all_day)?_c('div',{staticClass:"s-align-4"},[_c('span',{staticClass:"f-14-grey mr-1"},[_vm._v("From")]),_vm._v("\n                                    "+_vm._s(_vm._f("time")(_vm.event.dtstart))+"\n                                "),(_vm.checkOneDay)?_c('div',{staticClass:"s-align-4 ml-1"},[_c('span',{staticClass:"f-14-grey mr-1"},[_vm._v("to")]),_vm._v("\n                                    "+_vm._s(_vm._f("time")(_vm.event.dtend))+"\n                                ")]):_vm._e()]):_c('span',[_vm._v("\n                                All day\n                            ")])],1)])])],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }