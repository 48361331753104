<template>
    <div class="w-box">
        <p class="f-20-darkgrey f-sbold mb-30">Materials Delivery Details</p>
        <div class="row-between pb-30 bb-grey">
            <div class="lg-6 nb-12 md-12 sm-12 column-start">
                <p class="f-16-darkgrey f-sbold mb-3">Select vendor</p>
                <div
                    v-if="vendordsLoading"
                    class="w-100 s-align-5 pv-7 mb-20"
                >
                    <loading class="i-blue"/>
                </div>
                <multiselect
                    v-else
                    class="mb-25"
                    :options="vendors"
                    track-by="id"
                    label="name"
                    name="vendor"
                    data-name="vendor"
                    v-model="selectedVendor"
                    :max-height="200"
                    placeholder="Select vendor"
                    :show-labels="false"
                    :searchable="true"
                    @select="onVendorSelect"
                    @remove="onVendorRemove"
                >
                    <template
                        slot="singleLabel"
                        slot-scope="props"
                    >
                        <template v-if="props.option.name !== undefined">
                            <div class="row-start row-baseline">
                                <span
                                    v-if="!props.option.is_warehouse"
                                    :class="[
                                        'mr-3 f-11-white f-bold',
                                        props.option.is_custom ? 'dark-label' : 'orange-label'
                                    ]"
                                >
                                    {{ props.option.is_custom ? 'Custom' : 'Main list' }}
                                </span>
                                <span class="f-14-black">
                                    {{ props.option.name }}
                                </span>
                            </div>
                        </template>
                        <template v-else>
                            <p class="f-14-grey">Select vendor</p>
                        </template>
                    </template>
                    <template
                        slot="option"
                        slot-scope="props"
                    >
                        <div class="row-start row-baseline">
                            <span
                                v-if="!props.option.is_warehouse"
                                :class="[
                                    'mr-3 f-11-white f-bold',
                                    props.option.is_custom ? 'dark-label' : 'orange-label'
                                ]"
                            >
                                {{ props.option.is_custom ? 'Custom' : 'Main list' }}
                            </span>
                            <span class="f-14-black">
                                {{ props.option.name }}
                            </span>
                        </div>
                    </template>
                </multiselect>
                <div class="row-between row-baseline mb-20">
                    <span class="lg-6 sm-12 mb-sm-1 f-16-darkgrey f-sbold">
                        How are you receiving the materials?
                    </span>
                    <div :class="[ mScreen ? 'w-100 s-align-5' : 's-align-6']">
                        <radio-switch
                            v-for="item in RECEIVING_TYPES"
                            :key="`receiving-${item}`"
                            :label="item"
                            color="blue"
                        >
                            <input
                                type="radio"
                                :name="`receiving-${item}`"
                                v-model="order.receiving"
                                :value="item"
                                v-validate="'required'"
                            />
                        </radio-switch>
                    </div>
                </div>
                <div
                    v-if="order.receiving === RECEIVING_TYPES.DELIVERY"
                    class="row-between row-baseline mb-4"
                >
                    <span class="lg-6 sm-12  mb-sm-1 f-16-darkgrey f-sbold">
                        Select delivery method
                    </span>
                    <div :class="[ mScreen ? 'w-100 s-align-5' : 's-align-6']">
                        <radio-switch
                            v-for="item in DELIVERY_METHODS"
                            :key="`delivery-method-${item}`"
                            :label="item"
                            color="blue"
                        >
                            <input
                                type="radio"
                                :name="`delivery-method-${item}`"
                                v-model="order.delivery_method"
                                :value="item"
                                v-validate="'required'"
                            />
                        </radio-switch>
                    </div>
                </div>
                <div
                    v-if="selectedVendor"
                    class="w-100"
                >
                    <div v-if="selectedVendor.email">
                        <p class="f-16-darkgrey f-sbold mb-4">
                            Vendor Email
                        </p>
                        <form-input
                            label="Email"
                            class="mb-4"
                        >
                            <input
                                type="text"
                                name="vendor_email"
                                id="vendor_email"
                                class="form-input-field"
                                placeholder="Email"
                                v-model="selectedVendor.email"
                                disabled
                            />
                        </form-input>
                    </div>
                    <div v-if="selectedVendor.address">
                        <p class="f-16-darkgrey f-sbold mb-4">
                            Vendor Address
                        </p>
                        <address-box
                            type="vendor"
                            :existing="selectedVendor.address"
                            :validate="''"
                            :disabled="true"
                        />
                    </div>
                </div>
            </div>
            <div
                v-if="order.receiving === RECEIVING_TYPES.DELIVERY"
                :class="[
                    'lg-6 nb-12 md-12 sm-12 column-start',
                    dScreen ? 'pl-50' : 'mt-8'
                ]">
                <p class="f-16-darkgrey f-sbold mb-4">
                    Job Address
                </p>
                <address-box
                    type="address"
                    :existing="order.address"
                    :apartment="true"
                    @address="adrs => Object.assign(order.address, adrs)"
                >
                    <div
                        class="w-100 mb-4"
                        slot="apartment"
                    >
                        <form-input
                            label="Unit"
                            :class="[{ 'form-input-danger' : errors.has('property_unit') }]"
                        >
                            <input
                                type="text"
                                name="property_unit"
                                id="property_unit"
                                class="form-input-field"
                                placeholder="Unit"
                                v-input-style="order.address.apartment"
                                v-model="order.address.apartment"
                                v-validate="'max:50'"
                            />
                        </form-input>
                    </div>
                </address-box>
            </div>
        </div>
        <div class="row-start pv-30 bb-grey">
            <div class="lg-6 md-12 sm-12 column-start">
                <div class="row-between mb-3">
                    <p class="f-16-darkgrey f-sbold">Select a delivery date and time</p>
                    <button
                        class="transparent-button"
                        v-if="order.delivery_date"
                        @click="order.delivery_date = null"
                    >
                        <close-icon
                            size="9"
                            class="i-red mr-1"
                        />
                        <span class="f-12-darkgrey">Clear</span>
                    </button>
                </div>
                <div class="row-start row-baseline">
                    <div class="lg-6 sm-12 pr-4 pr-sm-0 mb-sm-1">
                        <form-datepicker
                            :class="{ 'form-datepicker-error' : errors.has('delivery_date')}"
                            :isFullWidth="true"
                            v-model="order.delivery_date"
                            name="delivery_date"
                            keyDatePicker="delivery_date_picker"
                            v-validate.continues="'required_if_vendor_selected'"
                        />
                    </div>
                    <div class="lg-6 sm-12 s-align-5">
                        <clock-icon
                            class="i-blue mr-8"
                            size="15"
                        />
                        <radio-switch
                            v-for="(item, index) in DELIVERY_TIME"
                            :key="`delivery-time-${index}`"
                            :label="item"
                            color="blue"
                            :class="{ 'radio-switch-error' : errors.has('delivery-time') }"
                        >
                            <input
                                type="radio"
                                name="delivery-time"
                                :value="item"
                                v-model="order.delivery_time"
                                v-validate="'required'"
                            />
                        </radio-switch>
                    </div>
                </div>
            </div>
        </div>
        <div class="row-start pv-30 bb-grey">
            <div class="lg-6 md-12 sm-12 column-start">
                <p class="f-16-darkgrey f-sbold mb-3">Assign to</p>
                <div
                    v-if="usersLoading"
                    class="w-100 s-align-5 pv-6"
                >
                    <loading class="i-blue"/>
                </div>
                <multiselect
                    v-else
                    :options="users"
                    track-by="id"
                    label="fullname"
                    name="assign_to"
                    data-name="assign_to"
                    v-model="order.assign"
                    :max-height="200"
                    placeholder="Assign to"
                    :show-labels="false"
                    :allow-empty="false"
                    :searchable="false"
                    v-validate="'required'"
                    :class="[
                        {'multiselect-danger' : errors.has('assign_to')}
                    ]"
                    @select="user => order.assign_to = user.id"
                />
            </div>
        </div>
        <div
            class="column-start pt-30"
            ref="deliveryComment"
        >
            <p class="f-16-darkgrey f-sbold">Enter location info</p>
            <button
                v-show="!order.delivery_comment"
                class="add-notes-button transparent-button f-12-blue"
                @click="showDeliveryCommentInput"
            >
                + Add comments to delivery
            </button>
            <div
                v-show="order.delivery_comment"
                class="input-wrapper w-100"
            >
                <textarea
                    type="text"
                    name="delivery"
                    class="notes-input w-100"
                    placeholder="Material notes"
                    v-resize-textarea
                    v-model="order.delivery_comment"
                    rows="1"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { FormInput, RadioSwitch, FormDatepicker } from '@/ui';
    import { ENTITY_TYPES } from '@/constants.js';
    import { scrollToElement } from '@/helpers';
    import { AddressBox } from '@/components';
    import Multiselect from 'vue-multiselect';
    import { ClockIcon, Loading, CloseIcon } from '@/icons';
    import api from '@/headers.js';

    export default {
        name: 'OrderCreateStep5',
        components: {
            FormInput,
            RadioSwitch,
            FormDatepicker,
            AddressBox,
            Multiselect,
            ClockIcon,
            CloseIcon,
            Loading
        },
        props: {
            entityType: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                RECEIVING_TYPES: {
                    DELIVERY: 'Delivery',
                    PICKUP: 'Pickup'
                },
                DELIVERY_METHODS: {
                    GROUND: 'Ground',
                    ROOF: 'Roof'
                },
                DELIVERY_TIME: ['AM', 'PM', 'Anytime'],
                order: {
                    assign: null,
                    address: {
                        address: '',
                        street: '',
                        city: '',
                        state: '',
                        zip: '',
                        lat: null,
                        lng: null,
                        apartment: ''
                    },
                    assign_to: null,
                    vendor_id: null,
                    vendor_name: '',
                    is_warehouse: false,
                    receiving: null,
                    delivery_method: null,
                    delivery_date: null,
                    delivery_time: null
                },
                users: [],
                vendors: [{
                    id: null,
                    name: 'Warehouse',
                    is_warehouse: true
                }],
                selectedVendor: null,
                vendordsLoading: false,
                usersLoading: false
            }
        },
        computed: {
            deliveryTime: {
                get: function() {
                    return {
                        startTime: this.order.delivery_time_from,
                        endTime: this.order.delivery_time_to
                    }
                },
                set: function(value) {
                    this.order.delivery_time_from = value.startTime;
                    this.order.delivery_time_to = value.endTime;
                }
            },
            entityId() {
                switch (this.entityType) {
                    case ENTITY_TYPES.WARRANTY_JOB:
                        return this.$route.params.job_id;
                        break;
                }
            },
        },
        watch: {
            order: {
                deep: true,
                handler: function() {
                    this.$store.commit('orders/STORE_ORDER', this.order);
                }
            }
        },
        methods: {
            getWarrantyJob() {
                api.get(`/warranty-jobs/${this.entityId}`)
                    .then(response => response.data.data)
                    .then((data) => {
                        if (data.addresses.length > 0) {
                            this.order.address = data.addresses[0];
                        }
                    })
            },
            getUsers() {
                const params = {
                    perPage: 100,
                    is_active: 1
                };

                this.usersLoading = true;
                this.$store.dispatch('users/USERS_GET', params)
                    .then(data => {
                        this.users.push(...data.data);

                        if (!this.order.assign) {
                            this.order.assign = this.$store.getters['user/getUserData'];
                            this.order.assign_to = this.order.assign.id;
                        }
                    })
                    .catch(error => {
                        this.notifyRequestError(error);
                    })
                    .finally(() => { this.usersLoading = false });
            },
            getVendors() {
                const params = {
                    display: 'active',
                    per_page: 999
                };

                this.vendordsLoading = true;
                api.get('/vendors', { params })
                    .then(response => {
                        this.vendors.push(...response.data.data.data);

                        if (this.order.vendor_id) {
                            this.selectedVendor = this.vendors.find(i => i.id == this.order.vendor_id);
                            this.order.vendor_name = this.selectedVendor.name;
                            this.order.vendor = this.selectedVendor;
                        } else if (this.order.is_warehouse) {
                            this.selectedVendor = this.vendors[0];
                        }
                    })
                    .catch(error => {
                        this.notifyRequestError(error);
                    })
                    .finally(() => { this.vendordsLoading = false });
            },
            showDeliveryCommentInput() {
                const parent = this.$refs.deliveryComment;
                // hide '+ add comments to delivery' button
                parent.querySelector('.add-notes-button').style.display = 'none';
                // show notes input
                parent.querySelector('.input-wrapper').style.display = 'block';
                // focus on input
                parent.querySelector('.notes-input').focus();
            },
            // validation is calling in parent component, need to return promise with result
            validateFields() {
                return this.$validator.validateAll()
                    .then(result => {
                        if (!result) {
                            scrollToElement(document.querySelector(`[name="${this.errors.items[0].field}"]`))
                        }

                        return result;
                    });
            },
            onVendorRemove() {
                this.order.is_warehouse = false;
                this.order.vendor_id = null;
                this.order.vendor_name = '';
                this.order.vendor = null;
            },
            onVendorSelect(data) {
                if (data.is_warehouse) {
                    this.order.is_warehouse = true;
                    this.order.vendor_id = null;
                    this.order.vendor_name = '';
                    this.order.vendor = null;
                } else {
                    this.order.is_warehouse = false;
                    this.order.vendor_id = data.id;
                    this.order.vendor_name = data.name;
                    this.order.vendor = data;
                }
            },
            registerCustomValidationRules() {
                this.$validator.extend('required_if_vendor_selected', (value, field) => {
                    return !this.selectedVendor || this.selectedVendor && !!value;
                });
            },
        },
        created() {
            this.registerCustomValidationRules();
            this.getUsers();
            this.getVendors();

            switch (this.entityType) {
                case ENTITY_TYPES.WARRANTY_JOB:
                    this.getWarrantyJob();
                    break;
            }

            // load order from store
            Object.assign(this.order, this.$store.getters['orders/getOrder']);

            if (!this.order.receiving) {
                // init default values
                this.order.receiving = this.RECEIVING_TYPES.DELIVERY;
                this.order.delivery_method = this.DELIVERY_METHODS.GROUND;
            }

            // init property address
            if (!this.order.addresses.length) {
                let leadAddress;
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD:
                        leadAddress = this.$store.getters['lead/getLeadPreview'];
                        break;
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        leadAddress = this.$store.getters['commercial/getCommercialJobAddress'];
                        break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        leadAddress = this.$store.getters['commercial/getCommercialLeadAddress'];
                        break;
                    case ENTITY_TYPES.WARRANTY_JOB:
                        leadAddress = this.$store.getters['warranty/getWarrantyJobAddress'];
                }

                Object.assign(this.order.address, {
                    address: leadAddress.address,
                    street: leadAddress.street,
                    city: leadAddress.city,
                    state: leadAddress.state,
                    zip: leadAddress.zip,
                    lat: leadAddress.lat,
                    lng: leadAddress.lng,
                    apartment: '',
                    type: 'main'
                });
            } else {
                Object.assign(this.order.address, this.order.addresses[0]);
            }
        }
    }
</script>
