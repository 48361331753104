<template>
    <div
        :class="[
            'row-start row-baseline flex-no-wrap',
            mScreen ? 'p-4' : 'p-8',
            { 'mb-1' : index === 0 },
            { 'bb-grey' : index !== filesLength - 1 }
        ]"
    >
        <checkbox-button 
            v-if="!file.is_smart"
            color="blue"
            :class="[mScreen? 'mr-5' : 'mr-30']"
        >
            <input 
                type="checkbox"
                @change="e => toggleFileSelection(e, file)"
                :disabled="checkIfDisabled(file.type)"
                :checked="selectedFiles.some(i => i.id === file.id)"
            >
        </checkbox-button>
        <div class="file-description row-start row-baseline">
            <p class="lg-5 sm-12 f-14-black">
                <component
                    :is="getIcon(file)"
                    height="19"
                    width="14"
                    class="mr-7"
                />
                <span class="f-13-black ellipsis">
                    {{ file.original_name }}
                </span>
            </p>
            <p 
                :class="[ 
                    'f-13-grey sm-12',
                    type === FOLDER_TYPES.JOB_DOCUMENTS ? 'lg-3' : 'lg-7'
                ]"
            >
                <span class="ml-2">
                    Date:
                </span>
                <span class="f-13-black ml-1">
                    {{ file.created_at | date }}
                </span>
            </p>
            <p
                v-if="type === FOLDER_TYPES.JOB_DOCUMENTS" 
                class="lg-4 sm-12 f-13-grey"
            >
                Uploaded by:
                <span class="f-13-black ml-1">
                    {{ file.user.fullname }}
                </span>
            </p>
        </div>
        <button
            v-if="!file.is_smart"
            class="transparent-button s-align-6 f-13-blue mr-8"
            @click="previewModalOpened = true"
        >
            <eye-icon
                size="15"
                class="mr-2 i-blue"
            />
            <template v-if="!mScreen">Preview</template>
        </button>
        <button
            v-if="file.is_smart || file.parent_id"
            class="transparent-button s-align-6 f-13-blue no-wrap column-start"
            @click="makeLocalSmart(file)"
        >
            <div class="row-start s-align-5">
                <edit-icon
                    size="15"
                    class="mr-2 i-blue"
                />
                {{ file.parent_id ? 'Edit' : 'Make Smart' }}
            </div>    
            (current record)
        </button>
        <file-preview-modal
            v-if="previewModalOpened"
            :file="file"
            @close="previewModalOpened = false"
        />
    </div>
</template>

<script>
    import { DocumentIcon, EyeIcon, ImagePreviewIcon, SmartDocPreviewIcon, EditIcon } from '@/icons';
    import { FILE_EXTENSIONS } from '@/constants';
    import { FilePreviewModal } from '@/modals';
    import { CheckboxButton } from '@/ui';
    import api from '@/headers.js';

    const FOLDER_TYPES = {
        JOB_DOCUMENTS: 'jobDocuments',
        COMPANY_DOCUMENTS: 'companyDocuments',
        ESTIMATES: 'estimates'
    };

    export default {
        name: 'PacketFile',
        components: {
            CheckboxButton,
            DocumentIcon,
            EyeIcon,
            ImagePreviewIcon,
            FilePreviewModal,
            SmartDocPreviewIcon,
            EditIcon
        },
        props: {
            file: {
                type: Object,
                required: true
            },
            index: {
                type: [String, Number],
                required: true
            },
            filesLength: {
                type: Number,
                required: true
            },
            selectedFiles: {
                type: Array,
                required: true
            },
            type: {
                type: String,
                default: FOLDER_TYPES.JOB_DOCUMENTS
            },
            entityId: {
                type: [String, Number],
                default: ''
            }
        },
        data() {
            return {
                previewModalOpened: false,
                FOLDER_TYPES: FOLDER_TYPES
            }
        },
        computed: {
            packetId() {
                return this.$route.params.packet_id;
            }
        },
        methods: {
            checkIfDisabled(type) {
                return !FILE_EXTENSIONS.DOCUMENTS.some(t => t === type);
            },
            getIcon(file) {
                if (FILE_EXTENSIONS.IMAGES.some(t => t == file.type)) {
                    return 'image-preview-icon';
                }

                if (file.type == 'pdf' && file.is_smart || 
                    file.type == 'pdf' && file.parent_id) {
                    return 'smart-doc-preview-icon';
                }

                return 'document-icon';
            },
            toggleFileSelection(e, file) {
                this.$emit('change', e.srcElement.checked, file);
            },
            makeLocalSmart(file) {
                api.get(`/smart-documents/by-file?file_id=${file.parent_id ? file.parent_id : file.id}`)
                    .then(response => {
                        let smartDoc = response.data.data;
                        localStorage.setItem("packetRedirectUrl", this.$route.fullPath);
                        this.$router.push({
                            name: 'SmartDocBuilder',
                            params: { id: file.parent_id ? file.parent_id : file.id },
                            query: { 
                                mode: 'create-local', 
                                entity: smartDoc.entity_type,
                                entity_id: this.entityId,
                                packet_id: this.packetId
                            }
                        })
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            }
        }
    }
</script>