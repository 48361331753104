<template>
    <full-modal @close="$emit('close')">
        <div slot="title">Taxes</div>
        <div slot="body">
            <div class="blue-box pv-8 ph-7 row-between mb-4">
                <div class="s-align-4 mb-sm-1">
                    <tools-icon class="i-darkgrey mr-4"/>
                    <span class="f-14-black f-sbold">Apply to materials only</span>
                </div>
                <div class="sm-12 ls-sm-3 s-align-6">
                    <toggle-switch>
                        <input 
                            type="checkbox"
                            name="apply_to_materials"
                            v-model="applyToMaterialsOnly"
                        />
                    </toggle-switch>
                </div>
            </div>
            <div class="blue-box pv-8 ph-7 row-between">
                <div class="s-align-4 mb-sm-1">
                    <dollar-icon 
                        class="i-darkgrey mr-4" 
                        size="25"
                    />
                    <span class="f-14-black f-sbold">Apply profit margin to tax</span>
                </div>
                <div class="sm-12 ls-sm-3 s-align-6">
                    <toggle-switch>
                        <input 
                            type="checkbox"
                            name="apply_profit_margin_to_tax"
                            v-model="applyProfitMarginToTax"
                        />
                    </toggle-switch>
                </div>
            </div>
            <div class="s-align-6 w-100 pt-50 pb-8">
                <span class="f-12-darkgrey f-sbold pr-4">Apply to all sections</span>
                <checkbox-button color="dark-blue">
                    <input 
                        type="checkbox" 
                        name="apply_to_all_sections" 
                        v-model="applyToAllSections"
                    />
                </checkbox-button>
            </div>
            <p class="f-14-black">Select one of your company presets:</p>
            <div 
                class="w-100"
                v-if="applyToAllSections"
            >
                <div class="row-between pv-20">
                    <div class="lg-3 sm-12 s-align-4">
                        <span class="f-14-black f-sbold">Taxes:</span>
                    </div>
                    <div class="lg-7 sm-10">
                        <multiselect
                            :options="taxes"
                            placeholder="No Tax"
                            v-model="selectedToAll"
                            name="taxes-list"
                            data-name="taxes-list"
                            :searchable="false"
                            :showLabels="false"
                            :custom-label="customLabel"
                            @select="addTaxToEstimate"
                            @remove="removeTaxFromEstimate"
                        />  
                    </div>
                    <div class="lg-1 sm-2 s-align-6">
                        <button 
                            class="icon-light"
                            @click="removeTaxFromEstimate"
                        >
                            <trash-icon/>
                        </button>
                    </div>
                </div>
            </div>
            <div 
                class="w-100"
                v-else
            >
                <div
                    :class="['row-between pv-20', { 'bb-grey' : index != sections.length - 1 }]"
                    v-for="(section, index) in sections"
                    :key="index"
                >
                    <div class="lg-3 sm-12 s-align-4 mb-sm-1">
                        <span class="f-14-black f-sbold">{{ section.name }}</span>
                    </div>
                    <div class="lg-7 sm-10">
                        <multiselect
                            :options="taxes"
                            placeholder="No Tax"
                            v-model="sectionsWithTax[index].tax"
                            :name="`taxes-list-${index}`"
                            :data-name="`taxes-list-${index}`"
                            :searchable="false"
                            :showLabels="false"
                            :custom-label="customLabel"
                            track-by="id"
                        />  
                    </div>
                    <div class="lg-1 sm-2 s-align-6">
                        <button 
                            class="icon-light"
                            @click="removeTaxFromSection(section)"
                        >
                            <trash-icon/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                @click="save"
                :disabled="disabled"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, ToggleSwitch, CheckboxButton } from '@/ui';
    import { ToolsIcon, DollarIcon, TrashIcon } from '@/icons';
    import Multiselect from 'vue-multiselect';
    import api from '@/headers.js';

    export default {
        name: 'EstimateTaxModal',
        components: {
            FullModal,
            ToolsIcon,
            DollarIcon,
            ToggleSwitch,
            CheckboxButton,
            Multiselect,
            TrashIcon
        },
        props: {
            sections: {
                type: Array,
                required: true
            },
            taxes: {
                type: Array,
                required: true
            },
            estimateId: {
                type: [String, Number],
                required: true
            },
            apply: {
                type: Boolean,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false
            }
        },
        data() {
            return {
                applyToMaterialsOnly: false,
                applyProfitMarginToTax: true,
                applyToAllSections: true,
                selectedToAll: null,
                sectionsWithTax: []
            }
        },
        methods: {
            customLabel({ name, percent }) {
                return `${name} (${percent}%)`
            },
            addTaxToEstimate(data) {
                this.sectionsWithTax.map((section) => {
                    section.tax = data;
                });
            },
            removeTaxFromEstimate() {
                this.selectedToAll = null;
                this.sectionsWithTax.map((section) => {
                    section.tax = null;
                });
            },
            removeTaxFromSection(section) {
                this.sectionsWithTax.find(s => s.id == section.id).tax = null;
            },
            save() {
                const params = {
                    material_only: this.applyToMaterialsOnly,
                    id: this.estimateId,
                    apply_pm: this.applyProfitMarginToTax,
                    same_all: this.applyToAllSections,
                    sections: []
                };

                this.sectionsWithTax.map((section) => {
                    params.sections.push({
                        id: section.id,
                        tax_id: section.tax ? section.tax.id : null
                    });
                });

                this.$emit('save', params);
            }
        },
        created() {
            // it is estimate.same_all
            this.applyToAllSections = this.apply;

            // applies to the whole estimate, so need check only the first object
            this.applyProfitMarginToTax = this.sections.length ? this.sections[0].apply_pm : false;
            this.applyToMaterialsOnly = this.sections.length ? this.sections[0].material_only : false;

            if (this.apply) {
                this.selectedToAll = this.taxes.find(t => t.id == this.sections[0].tax_id);
            }            

            this.sections.forEach((section, index) => {
                this.sectionsWithTax.push({
                    id: section.id,
                    tax: !section.tax_id ? null : this.taxes.find(t => t.id == section.tax_id)
                });
            });
        }
    }
</script>