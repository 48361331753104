<template>
    <div class="calendar-notification-mask s-align-5">
        <div class="calendar-notification relative">
            <div 
                class="row-end pr-5 pb-5"
                v-if="!dScreen"
            >
                <button 
                    class="icon-light"
                    @click="$emit('close')"
                >
                    <close-icon/>
                </button>
            </div>
            <div class="row-between ph-8">
                <span class="f-16-darkgrey f-sbold">Upcoming Events</span>
                <router-link :to="{ name: 'CalendarAgenda', params: { date: currentDate }}">
                    <span class="f-12-blue f-sbold">All Events</span>
                </router-link>
            </div>
            <loader 
                v-if="loading"
                size="mini" 
            />
            <div class="w-100" v-else>
                <div class="w-100 ph-8 pt-4 pb-2">
                    <div class="calendar-notification-dates w-100 row-between">
                        <button 
                            :class="[
                                'transparent-button column-center relative pb-3', 
                                { 'calendar-selected-day' : selectedDate == index }
                            ]"
                            v-for="(date, index) in dates"
                            :key="index"
                            @click="selectDate(index)"
                        >
                            <span :class="['f-sbold l-height-16', selectedDate == index ? 'f-16-blue' : 'f-16-darkgrey']">
                                {{ date | day }}
                            </span>
                            <span :class="[selectedDate == index ? 'f-10-darkgrey' : 'f-10-grey']">
                                {{ date | dayName }}
                            </span>
                        </button>
                    </div>
                </div>
                <perfect-scrollbar 
                    :class="[
                        'calendar-notification-events ps-grey w-100', 
                        { 'calendar-notification-events-open' : showMoreEvents }
                    ]"
                >
                    <div 
                        v-for="(item, itemIndex) in croppedEvents"
                        :key="itemIndex"
                        :class="[
                            'row-between pl-8 pr-6 pt-4 pb-4', 
                            { 'bb-grey' : itemIndex != events[selectedDate].length - 1 }
                        ]"
                    >
                        <div class="calendar-notification-sheet column-center pt-2">
                            <span class="f-11-white">{{ item.dtstart | monthShortened }}</span>
                            <span class="f-16-white f-sbold">
                                {{ getEventDay(item) }}
                            </span>
                        </div>
                        <div class="calendar-notification-details">
                            <p class="f-13-darkgrey f-sbold w-100 ellipsis">{{ item.summary }}</p>
                            <p class="f-11-grey">{{ getEventTime(item.dtstart, item.dtend) }}</p>
                            <p
                                v-if="item.location"
                                class="f-11-blue w-100 ellipsis"
                            >
                                {{ item.location }}
                            </p>
                        </div>
                        <div class="s-align-3">
                            <router-link
                                class="icon-light"
                                :to="{ name: 'CalendarDay', params: { date: currentDate }}"
                            >
                                <edit-icon size="10"/>
                            </router-link>
                        </div>
                    </div>
                    <div 
                        class="calendar-notification-empty column-center w-100 s-align-5"
                        v-if="events.length && !events[selectedDate].length"
                    >
                        <img 
                            class="mb-20"
                            src="@/assets/images/calendar-notifications-empty.svg" 
                            width="99"
                            height="85"
                            alt="Calendar notifications not found"
                        />
                        <p class="f-13-darkgrey t-center">
                            Seems like there are no new events<br> 
                            in your calendar
                        </p>
                    </div>
                </perfect-scrollbar>
                <div class="w-100">                
                    <button 
                        class="view-all-notification-button"
                        @click.stop="showMoreEvents = true"
                        v-if="events[selectedDate].length > 3 && !showMoreEvents"
                    >
                        {{ moreCount }}
                    </button>
                    <router-link
                        :to="{ name: 'Calendar' }"
                        class="view-all-notification-button s-align-5"
                        v-if="events[selectedDate].length <= 3"
                    >
                        To Calendar
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { CloseIcon, EditIcon } from '@/icons';
    import { Loader } from '@/ui';
    import moment from 'moment/src/moment';
    import api from '@/headers.js';

    export default {
        name: 'CalendarNotification',
        components: {
            CloseIcon,
            EditIcon,
            Loader
        },
        data() {
            return {
                selectedDate: 0,
                showMoreEvents: false,
                dates: [],
                events: [],
                loading: false
            }
        },
        computed: {
            moreCount() {
                return `+ ${this.events[this.selectedDate].length - 3} more`;
            },
            currentDate() {
                return moment(this.dates[this.selectedDate]).format('YYYY-MM-DD');
            },
            croppedEvents() {
                if (this.showMoreEvents) {
                    return this.events[this.selectedDate];
                } 

                return this.events[this.selectedDate].slice(0, 3);
            }
        },
        watch: {
            '$route' (to, from) {
                this.$emit('close');
            }
        },
        methods: {
            selectDate(index) {
                this.showMoreEvents = false;
                this.selectedDate = index;
            },
            getInfo() {   
                this.loading = true;

                api.get('/calendars/notification')
                    .then(response => response.data.data)
                    .then(data => {
                        for (let date in data) {
                            this.dates.push(date);
                            this.events.push(data[date])
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            getEventTime(start, end) {
                let today = moment(this.dates[this.selectedDate]);
                let dayStart = moment(start);
                let dayEnd = moment(end);

                if (dayStart.isSame(dayEnd, 'day')) {
                    return `${dayStart.format('hh:mm A')} - ${dayEnd.format('hh:mm A')}`
                } else if (dayStart.isBefore(dayEnd) && dayStart.isSame(today, 'day')) {
                    return `${dayStart.format('hh:mm A')} - 11:59 PM`
                } else if (!dayStart.isSame(today, 'day') && !dayEnd.isSame(today, 'day')) {
                    return 'All day'
                } else if (dayStart.isBefore(dayEnd) && dayEnd.isSame(today, 'day')) {
                    return `00:00 AM - ${dayEnd.format('hh:mm a')}`
                }
            },
            getEventDay(event) {
                if (moment(event.dtstart).isSame(event.dtend, 'day')) {
                    return moment(event.dtstart).format('DD');
                } else {
                    return `${moment(event.dtstart).format('DD')}-${moment(event.dtend).format('DD')}`;
                }
            }
        },
        created() {
            this.getInfo();
        }
    }
</script>