<template>
    <svg 
        viewBox="0 0 15 15"
        :width="size"
        :height="size"
        class="decrease-icon"
    >
        <path d="M2 8.9h4.1V13h-1v-2.3L.8 15l-.8-.8 4.3-4.3H2zM14.2 0l.8.8-4.3 4.3H13v1H8.9V2h1v2.3z"/>
    </svg>
</template>

<script>
    export default {
        name: 'DecreaseIcon',
        props: {
            size: {
                type: [Number, String],
                default: 15
            }
        }
    }
</script>