<template>
    <div
        class="pointer relative p-15 bg-white mb-2 call-log-admin-card"
        :class="isActive ? 'active' : ''"
        @click="$emit('openItem')"
    >
        <div class="row-between call-log-admin-card__info">
            <div class="s-align-4 call-log-admin-card__info-marker-desktop">
                <status-marker
                    :marker="callData.contact_name || no_info"
                    size="35"
                />
            </div>
            <div class="flex-grow-1 column-start ml-15">
                <p
                    class="f-14-black f-sbold mb-2 call-log-admin-card__info-marker"
                    :class="!callData.contact_name ? 'f-14-grey f-italic' : '' "
                >
                    <status-marker
                        class="call-log-admin-card__info-marker-mobile"
                        :marker="callData.contact_name || no_info"
                        size="35"
                    />
                    {{ callData.contact_name || no_info }}
                </p>
                <p class="f-12-grey ">
                    Customer number:
                    <span
                        v-if="callData.tracking_number_bare"
                        class="f-12-black"
                    >
                        {{ callData.tracking_number_bare | phone }}
                    </span>
                    <span
                        v-else
                        class="f-12-grey f-italic"
                    >
                        {{ no_info }}
                    </span>
                </p>
                <div class="row-baseline">
                    <p class="f-12-grey mr-1">Call duration:</p>
                    <span :class="callData.duration !== no_info ? 'f-12-black' : 'f-normal f-12-grey f-italic'">
                        {{ callDuration }}
                    </span>
                </div>
                <p class="f-12-grey ">
                    Status:
                    <span :class="callData.direction ? 'f-12-black' : 'f-normal f-12-grey f-italic'">
                        {{callData.direction}}
                    </span>
                </p>
            </div>
            <div class="column-between-end call-log-admin-card__info-date-block">
                <p class="f-12-grey mb-2">
                    <span
                        class="f-12-grey"
                        :class="daysAgo === no_info ? 'f-italic' : ''"
                    >
                        {{ daysAgo }}
                    </span>
                </p>
                <p class="f-12-grey mb-2">
                    <span
                        v-if="unixTime"
                        class="f-12-grey"
                    >
                        {{ unixTime | date }} at {{ unixTime | time }}
                    </span>
                        <span
                            v-else
                            class="f-12-grey f-italic"
                        >
                        {{ no_info }}
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import {StatusMarker} from '@/components';
    import moment from "moment";
    
    export default {
        name: 'CallLogCard',
        components: {
            StatusMarker
        },
        data() {
            return {
                no_info: 'No information'
            }
        },
        props: {
            isActive: {
                type: Boolean,
                default: false
            },
            callData: {
                type: Object,
                required: true
            }
        },
        computed: {
            callDuration() {
                const time = this.callData.duration;
                if (time) {
                    return moment.utc(time * 1000).format('HH:mm:ss')
                }
                return this.no_info;
            },
            unixTime() {
                if (this.callData.unix_time) {
                    return moment.unix(this.callData.unix_time);
                }
                return '';
            },
            daysAgo() {
                if (this.unixTime) {
                    const calledDate = this.unixTime;
                    return calledDate.fromNow();
                }
                return this.no_info;
            }
        }
    }
</script>
