<template>
    <full-modal 
        @close="$emit('close')"
        :withSearch="true"
        :searchRequestLength="!!needToFind.length"
        @cancelSearch="needToFind = ''"
        class="edit-tags-modal"
    >
        <div slot="title">Label settings</div>
        <div slot="search">
            <input 
                type="text" 
                name="tag_search"
                placeholder="Search label..."
                class="w-100 ph-2"
                v-model="needToFind"
            />
        </div>
        <div slot="body">
            <div class="w-100 pb-8 f-12-darkgrey">
                <p>If you edit a label name, it will be edited in all leads and jobs.</p>
                <p>If you delete a label, it will be removed from all leads and jobs.</p>
            </div>
            <div 
                class="blue-box row-between mb-4 pv-7 pl-7 pr-5"
                v-for="(tag, index) in tagList"
                :key="`edit-tag-${index}`"
                v-show="searchTag(tag.name)"
            >
                <div class="static-col-10">
                    <form-input>
                        <input 
                            class="form-input-field"
                            name="tag_name"
                            v-model="tag.name"
                            type="text"
                            placeholder="Label Name"
                            @change="changeTag(tag, index)"
                        />
                    </form-input>
                </div>
                <div class="static-col-2 s-align-6">
                    <button 
                        class="icon-light"
                        @click="removeTag(tag, index)"
                    >
                        <trash-icon/>
                    </button>
                </div>
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                @click="save"
                :disabled="isRequestGoing"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, FormInput } from '@/ui';
    import { SearchIcon, CloseIcon, TrashIcon } from '@/icons';
    import cloneDeep from 'lodash/cloneDeep';
    import api from '@/headers.js';

    export default {
        name: 'EditTagsModal',
        props: {
            tags: {
                type: Array,
                default: () => []
            }
        },
        components: {
            FullModal, 
            FormInput,
            SearchIcon,
            CloseIcon,
            TrashIcon
        },
        data() {
            return {
                needToFind: '',
                tagList: [],
                itemsForUpdate: [],
                itemsForRemove: [],
                isRequestGoing: false
            }
        },
        methods: {
            searchTag(name) {                
                return name.toLowerCase().includes(this.needToFind.toLowerCase());
            },
            changeTag(tag, index) {
                if (this.itemsForUpdate.some(el => el.id == tag.id)) {
                    this.itemsForUpdate[index].name = tag.name
                } else {
                    this.itemsForUpdate.push({
                        id: tag.id,
                        name: tag.name,
                        is_active: tag.is_active
                    });
                }
            },
            removeTag(tag, index) {
                this.tagList.splice(index,1);
                this.itemsForRemove.push({
                    id: tag.id
                });
            },
            save() {
                this.isRequestGoing = true;

                let params = {
                    tags: this.itemsForUpdate,
                    remove_tags: this.itemsForRemove
                }

                api.put(`/tags`, params)
                    .then(res => {
                        this.notifySuccess('Labels was updated');
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.isRequestGoing = false;
                        this.$emit('updateTags');
                        this.$emit('close');
                    })
            }
        },
        mounted() {
            this.tagList = cloneDeep(this.tags);
        }
    }
</script>