<template>
    <full-modal
        class="order-create-modal"
        :full="true"
        :withSearch="!showEstimateSections && !showEstimateSectionDetails"
        :searchRequestLength="!!needToFind.length"
        @cancelSearch="needToFind = ''"
        @close="$emit('close')" 
    >
        <div slot="title">
            <button
                v-if="showEstimateSectionDetails"
                class="transparent-button s-align-4 pv-3"
                @click="showEstimateSectionDetails = false"
            >
                <arrow-icon
                    transform="180"
                    class="i-grey mr-2"
                />
                <span class="f-12-grey">Back to Estimate sections</span>
            </button>
            <button
                v-else-if="showEstimateSections"
                class="transparent-button s-align-4 pv-3"
                @click="showEstimateSections = false"
            >
                <arrow-icon
                    transform="180"
                    class="i-grey mr-2"
                />
                <span class="f-12-grey">Back to Estimate list</span>
            </button>
            <template v-else>Estimates</template>
        </div>
        <div slot="search">
            <input
                class="w-100 ph-2"
                type="text"
                placeholder="Search estimate..."
                v-model="needToFind"
            />
        </div>
        <div slot="body">
            <loader v-if="loading"/>
            <template v-else-if="showEstimateSectionDetails">
                <div class="row-start pb-20 bb-grey">
                    <div class="lg-7 column-start">
                        <p class="f-18-black f-sbold">
                            Gutters Template
                        </p>
                        <!-- TO DO -->
                        <!-- <p class="f-12-grey">
                            Total:
                            <span class="f-12-black f-sbold"></span>
                        </p> -->
                    </div>
                    <div class="lg-5 s-align-3">
                        <p class="f-12-grey">
                            Added templates:
                            <span class="f-12-black f-sbold">1</span>
                        </p>
                    </div>
                </div>
                <template v-if="visibleEstimateSectionDetailsLength">
                    <div
                        v-for="(item, index) in visibleEstimateSectionDetails"
                        :key="index" 
                        class="order-sub-item row-between row-baseline w-100 bb-grey"
                    >
                        <div class="order-item-description">
                            <div
                                v-if="checkIfEstimateItemAdded(item.id)" 
                                class="row-baseline mb-1"
                            >
                                <div class="order-item-added-icon s-align-5 mr-3">
                                    <mark-icon
                                        class="i-white"
                                        size="8"
                                    />
                                </div>
                                <span class="f-12-green">Added</span>
                            </div>
                            <p class="f-13-black">
                                {{ item.name }}
                            </p>
                        </div>
                        <button
                            v-if="checkIfEstimateItemAdded(item.id)" 
                            class="add-button"
                            :disabled="requestIsGoing"
                            @click="deleteItemFromOrder(item.id)"
                        >
                            <span class="f-12-darkgrey">Remove</span>
                        </button>
                        <button
                            v-else
                            class="add-button"
                            :disabled="requestIsGoing"
                            @click="addItemToOrder(item.id)"
                        >
                            <span class="f-12-blue">Add</span>
                        </button>
                    </div>
                </template>
                <not-found-message
                    v-else
                    custom="Nothing to display"
                />
            </template>
            <template v-else-if="showEstimateSections">
                <template v-if="estimateSectionsLength">
                    <div
                        v-for="(section, index) in estimateSections"
                        :key="index"
                        class="order-item row-between row-baseline w-100 mb-1"
                    >
                        <div class="order-item-description row-baseline">
                            <div class="lg-1">
                                <button 
                                    class="transparent-button p-3"
                                    @click="showEstimateSectionDetails = true; selectedEstimateSectionIndex = index"
                                >
                                    <arrow-icon 
                                        class="i-blue"
                                        transform="0"
                                    />
                                </button>
                            </div>
                            <div class="lg-11 column-start">
                                <div
                                    v-if="checkIfEstimateSectionAdded(section.id)" 
                                    class="row-baseline mb-4"
                                >
                                    <div class="order-item-added-icon s-align-5 mr-3">
                                        <mark-icon
                                            class="i-white"
                                            size="8"
                                        />
                                    </div>
                                    <span class="f-12-green">Added</span>
                                </div>
                                <p class="f-14-black f-sbold">
                                    {{ section.name }}
                                </p>
                                <!-- TO DO -->
                                <!-- <p class="f-12-grey">
                                    Total:
                                    <span class="f-12-black f-sbold">0</span>
                                </p> -->
                            </div>
                        </div>
                        <button
                            v-if="checkIfEstimateSectionAdded(section.id)" 
                            class="add-button"
                            :disabled="requestIsGoing"
                            @click="deleteEstimateSectionFromOrder(section.id)"
                        >
                            <span class="f-12-darkgrey">Remove</span>
                        </button>
                        <button
                            v-else
                            class="add-button"
                            :disabled="requestIsGoing"
                            @click="addEstimateSectionToOrder(section.id)"
                        >
                            <span class="f-12-blue">Add all</span>
                        </button>
                    </div>
                </template>
                <not-found-message
                    v-else
                    custom="Nothing to display"
                />
            </template>
            <template v-else>
                <template v-if="visibleEstimatesLength">
                    <div
                        v-for="(estimate, index) in visibleEstimates"
                        :key="index"
                        class="order-item row-between row-baseline w-100 mb-1"
                    >
                        <div class="row-baseline">
                            <div class="lg-1">
                                <button 
                                    class="transparent-button p-3"
                                    @click="getEstimateSections(index)"
                                >
                                    <arrow-icon 
                                        class="i-blue"
                                        transform="0"
                                    />
                                </button>
                            </div>
                            <div class="lg-11 column-start">
                                <p class="f-14-black f-sbold">
                                    {{ estimate.name }}
                                </p>
                                <!-- TO DO -->
                                <!-- <p class="f-12-grey">
                                    Total:
                                    <span class="f-12-black f-sbold"></span>
                                </p> -->
                            </div>
                        </div>
                    </div>
                </template>
                <not-found-message
                    v-else
                    custom="Nothing to display"
                />
            </template>
        </div>
    </full-modal>
</template>

<script>
    import { ArrowIcon, MarkIcon } from '@/icons';
    import { Loader } from '@/ui';
    import { clearArray } from '@/helpers';
    import api from '@/headers.js';

    export default {
        name: 'OrderAddFromEstimateModal',
        components: {
            Loader,
            ArrowIcon,
            MarkIcon,
            NotFoundMessage: () => import('@/components/NotFoundMessage.vue'),
            FullModal: () => import('@/ui/Modal/FullModal.vue'),
        },
        props: {
            typeId: {
                type: [String, Number],
                required: true
            },
            items: {
                type: Array,
                required: true
            },
            entityType: {
                type: String,
                required: true
            },
            entityId: {
                type: [String, Number],
                required: true
            }
        },
        data() {
            return {
                needToFind: '',
                showEstimateSections: false,
                selectedEstimateIndex: null,
                showEstimateSectionDetails: false,
                selectedEstimateSectionIndex: null,
                estimateInfoIndex: null,
                loading: false,
                requestIsGoing: false,
                estimates: [],
                estimateSections: []
            }
        },
        computed: {
            orderId() {
                return this.$route.params.order_id;
            },
            visibleEstimates() {
                return this.estimates.filter(e => {
                    return e.name.toLowerCase().includes(this.needToFind.toLowerCase())
                });
            },
            visibleEstimatesLength() {
                return this.visibleEstimates.length;
            },
            estimateSectionsLength() {
                return this.estimateSections.length;
            },
            visibleEstimateSectionDetails() {
                if (this.estimateSections[this.selectedEstimateSectionIndex]) {
                    return this.estimateSections[this.selectedEstimateSectionIndex].items
                        .filter(i => i.type_id == this.typeId);
                }
                return [];                
            },
            visibleEstimateSectionDetailsLength() {
                return this.visibleEstimateSectionDetails.length;
            }
        },
        methods: {
            getEstimates() {
                const params = {
                    entity_id: this.entityId,
                    entity_type: this.entityType,
                    existence: 1,
                    item_type: this.typeId
                };

                this.loading = true;
                api.get('/estimates', { params })
                    .then(response => {
                        const finalEstimates = response.data.data.filter(estimate => {
                            return estimate.status === estimate.all_statuses.final;
                        });
                        this.estimates.push(...finalEstimates);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            getEstimateSections(index) {
                this.selectedEstimateIndex = index;
                this.showEstimateSections = true;
                this.loading = true;
                clearArray(this.estimateSections);

                api.get(`/estimates/${this.estimates[this.selectedEstimateIndex].id}`)
                    .then(response => response.data.data)
                    .then(data => {
                        this.estimateSections.push(...data.sections);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            checkIfEstimateSectionAdded(id) {
                return this.items.some(i => {
                    return i.entity_type == 'estimate'
                        && i.entity_group == id
                        && i.type_id == this.typeId
                });
            },
            checkIfEstimateItemAdded(id) {
                return this.items.some(i => {
                    return i.entity_type == 'estimate'
                        && i.entity_id == id
                });
            },
            addEstimateSectionToOrder(id) {
                const params = {
                    section_id: id,
                    type_id: this.typeId
                };

                this.requestIsGoing = true;
                api.post(`/orders/${this.orderId}/add-estimate-section`, params)
                    .then(response => response.data.data)
                    .then(data => {
                        this.$emit('onAddItems', data)
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            },
            deleteEstimateSectionFromOrder(estimateSectionId) {
                const params = {
                    order_id: this.orderId,
                    entity_group: estimateSectionId,
                    entity_type: 'estimate',
                    type_id: this.typeId
                };

                this.requestIsGoing = true;
                api.delete('/orders/items/delete-by-entity', { params })
                    .then(() => {
                        this.notifySuccess('Template deleted from order');
                        this.$emit('onDeleteEstimateSection');
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            },
            addItemToOrder(id) {
                const params = {
                    section_item_id: id
                };

                this.requestIsGoing = true;
                api.post(`/orders/${this.orderId}/add-estimate-section-item`, params)
                    .then(response => response.data.data)
                    .then(data => {
                        this.$emit('onAddItems', [data]);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            },
            deleteItemFromOrder(id) {
                let itemOrderId;
                const index = this.items.findIndex(i => {
                    return i.entity_type === 'estimate' 
                    && i.entity_id === id
                });

                if (index !== -1) {
                    itemOrderId = this.items[index].id;
                    this.requestIsGoing = true;
                    api.delete(`orders/items/${itemOrderId}`)
                        .then(response => response.data.data)
                        .then(data => {
                            this.$emit('onDeleteItem', itemOrderId)
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.requestIsGoing = false;
                        });
                }
            }
        },
        created() {
            this.getEstimates();
        }
    }
</script>