<template>
    <svg
        viewBox="0 0 17 16"
        :width="size"
        :height="size"
        class="forward-icon"
    >
        <path d="M6.7,2.4h7.8l-1.2-1.2c-0.3-0.3-0.3-0.7,0-1C13.5,0.1,13.7,0,13.9,0c0,0,0,0,0,0c0.2,0,0.4,0.1,0.5,0.2l2.4,2.4C16.9,2.8,17,3,17,3.2c0,0.2-0.1,0.4-0.2,0.5l-2.4,2.4c-0.3,0.3-0.7,0.3-1,0c-0.3-0.3-0.3-0.8,0-1l1.1-1.1H6.7c-2.9,0-5.2,2.4-5.2,5.3s2.3,5.3,5.2,5.3h9.2c0.4,0,0.7,0.3,0.7,0.7S16.3,16,15.9,16H6.7C3,16,0,13,0,9.2l0,0C0,5.5,3,2.4,6.7,2.4z"/>
    </svg>
</template>

<script>
    export default {
        name: 'ForwardIcon',
        props: {
            size: {
                type: [Number, String],
                default: 10
            }
        }
    }
</script>