<template>
    <div
        id="app"
        :class="[{ 'ios-device': iOsDetect() }]"
    >
        <custom-notification></custom-notification>
        <loader
            v-if="!isUserDataLoaded && isAuth && !isUnderConstructionPage"
            size="full"
        />
        <template v-else>
            <navigation v-if="isPanel && !isUnderConstructionPage"/>
            <router-view></router-view>
        </template>
        <not-supported-screen v-if="!isSupportedScreen" />
        <make-call-widget
            v-if="showMakeCallWidgetInViews"
            :class="{ 'visiblity-hidden' : !callWidgetIsVisible }"
            @callWidgetIsLoaded="callWidgetIsLoaded"
        />
    </div>
</template>

<script>
    import { CustomNotification, Loader } from '@/ui';
    import { MakeCallWidget } from '@/components';
    import { setupCalendar } from 'v-calendar';
    import { Navigation } from '@/layout';
    import NotSupportedScreen from '@/views/NotSupportedScreen'
    import vhCheck from 'vh-check';
    import { SCREEN_SIZES } from '@/constants';

    export default {
        name: 'app',
        components: {
            Navigation,
            CustomNotification,
            Loader,
            NotSupportedScreen,
            MakeCallWidget
        },
        data() {
            return {
                callWidgetIsVisible: false
            }
        },
        computed: {
            isPanel() {
                return this.$store.getters['views/getViewStatus'];
            },
            isWeekStartOnMonday() {
                return this.$store.getters['user/isWeekStartOnMonday'];
            },
            isUserDataLoaded() {
                return this.$store.getters['user/isUserDataLoaded'];
            },
            isAuth() {
                return this.$store.getters['auth/isAuth'];
            },
            screenSize() {
                return this.$store.getters['views/getScreenSize'];
            },
            isSupportedScreen() {
                return this.screenSize !== SCREEN_SIZES.NOT_SUPPORT;
            },
            isShowWidget() {
                return this.$store.getters['telephony/isWidgetWork'];
            },
            showMakeCallWidgetInViews() {
                if (this.isShowWidget) {
                    return this.$route.name === "Overview"
                        || this.$route.name === "ResidentialLeads"
                        || this.$route.name === "CommercialLeads"
                        || this.$route.name === "CommercialLeadOverview"
                        || this.$route.name === "CommercialJobList"
                        || this.$route.name === "CommercialJobOverview"
                        || this.$route.name === "CommercialPropertyList"
                        || this.$route.name === "CommercialDetails"
                        || this.$route.name === "CommercialCustomerList"
                        || this.$route.name === "ContactMessages"
                        || this.$route.name === "ContactDocuments"
                        || this.$route.name === "ContactCalls"
                        || this.$route.name === "PointOfContactDetails"
                        || this.$route.name === "CommercialJobCalls"
                        || this.$route.name === "Opportunities"
                        || this.$route.name === "OpportunityOverview"
                        || this.$route.name === "WarrantyOverview"
                        || this.$route.name === "CallLog";
                }

                return false;
            },
            isUnderConstructionPage() {
                return this.$route.name === "UnderConstructionPage";
            }
        },
        watch: {
            isWeekStartOnMonday: function() {
                // setup calendar plugin depending on company settings
                // en-GB - start week on Monday
                // en-US - start week on Sunday
                setupCalendar({
                    locale: this.$store.getters['user/isWeekStartOnMonday'] ? "en-GB" : "en-US"
                });
            }
        },
        methods: {
            iOsDetect() {
                return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
            },
            checkScreenSize() {
                this.$store.commit('views/SCREEN_SIZE', window.innerWidth);
            },
            checkTaskNotification() {
                let notificationMark =  JSON.parse(localStorage.getItem('notificationMark'));

                if (notificationMark == true) {
                    this.$favicon.badge(' ');
                    this.$store.commit('notifications/SET_NOTIFICATION_MARK', true);
                } else {
                    this.$favicon.badge('');
                    this.$store.commit('notifications/SET_NOTIFICATION_MARK', false);
                }
            },
            callWidgetIsLoaded(value) {
                this.callWidgetIsVisible = value;
            }
        },
        created() {
            document.getElementsByTagName('body')[0].classList.add('light-theme');

            // Viewport vh fix for iOS devices
            vhCheck({
                cssVarName: 'vh-offset',
                updateOnTouch: true,
                force: true
            });

            if (window.Echo) {
                window.Echo.private('user-' +  localStorage.getItem('user_id') || '')
                    .listen('.jobTasks', (e) => {
                        this.$favicon.badge(' ');
                        this.$store.commit('notifications/SET_NOTIFICATION_MARK', true);
                        localStorage.setItem('notificationMark', true);
                    }).listen('.kanban', (e) => {
                        this.$favicon.badge(' ');
                        this.$store.commit('notifications/SET_NOTIFICATION_MARK', true);
                        localStorage.setItem('notificationMark', true);
                    }).listen('.versionCreated', (e) => {
                        localStorage.setItem('versionNotify', true);
                        console.log(e)
                    });
            }
        },
        mounted() {
            window.addEventListener('storage', (e) => {
                if (e.key === 'notificationMark') {
                    this.checkTaskNotification();
                }
            });
            this.checkTaskNotification();
            
            // a full list of screen sizes is in constants
            this.checkScreenSize();
            window.addEventListener('resize', this.checkScreenSize);
        },
        destroyed() {
            window.removeEventListener('resize', this.checkScreenSize);
        }
    }
</script>
