<template>
    <svg 
        viewBox="0 0 587.1874389648438 582.700927734375"
        :width="size" 
        :height="size"
        class="pin-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="587.1874389648438" 
            height="582.700927734375" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(1.53125, 0, 0, 1.53125, 244.20089721679688, -38.92857360839844)">
            <path 
                class="st0" 
                d="M214.8,136.8L112.2,34.2c-3.5-3.5-8.5-5-13.3-3.9c-4.9,1.1-8.8,4.5-10.5,9.1c-5.9,15.8-7.3,33.4-4.1,50.3l-99.9,88c-54.2-19.3-96.8,10.5-98.6,11.8c-3.5,2.5-5.7,6.4-6,10.6c-0.4,4.3,1.2,8.4,4.2,11.5l70.4,70.4l-104.7,104.7c-3.5,3.5-3.5,9.2,0,12.7c1.8,1.8,4.1,2.6,6.4,2.6c2.3,0,4.6-0.9,6.4-2.6L-33,294.8l70.4,70.4c2.7,2.7,6.4,4.3,10.2,4.3c0.4,0,0.8,0,1.2-0.1c4.3-0.4,8.1-2.5,10.6-6c1.5-2.1,9.3-13.6,14.2-31.6c6.1-22.2,5.3-45.3-2.4-67l88-99.9c16.9,3.1,34.5,1.7,50.3-4.2c4.7-1.7,8.1-5.7,9.1-10.5C219.8,145.3,218.3,140.3,214.8,136.8z M-39.7,262.6l-60.7-60.7c10.5-6.2,43.3-21.9,83.1-5.6l9.4-1.6l108-95.1l2.8-8.9c-3.3-13.2-3-27.1,0.6-39.9l94.6,94.6c-12.8,3.7-26.7,3.9-39.9,0.6l-8.9,2.8l-95.1,108l-1.6,9.4c16.3,39.7,0.6,72.5-5.6,83.1l-65.3-65.3" 
                style="stroke: rgb(0, 0, 0); stroke-width: 5; stroke-miterlimit: 10;"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'PinIcon',
        props: {
            size: {
                type: [Number, String],
                default: 13
            }
        }
    }
</script>
