<template>
    <div class="expected-revenue-report w-100 p-20">
        <report-filters
            reportTitle="Expected Revenue"
            @filterChanged="getFilteringReport"
        />
        <loader
            v-if="loading"
            size="small"
        />
        <div
            v-else
            class="row-between pt-20"
        >
            <div class="lg-6 sm-12 pr-1">
                <div class="br-5 expected-revenue column-start w-100">
                    <div class="expected-revenue-icon s-align-5 mb-8">
                        <broken-lightning-icon />
                    </div>
                    <animate-number 
                        class="f-28-black f-sbold break-all"
                        data-name="expected_revenue"
                        :number="total_expected_revenue"
                        :commasFilter="true"
                        filter="currency"
                    />
                    <p class="f-13-grey">Expected Revenue</p>
                </div>
            </div>
            <div class="lg-6 sm-12 pl-1">
                <div class="br-5 base-bid column-start w-100">
                    <div class="base-bid-icon s-align-5 mb-8">
                        <dollar-icon />
                    </div>
                    <animate-number 
                        class="f-28-black f-sbold break-all"
                        data-name="base_bid"
                        :number="total_base_bid"
                        :commasFilter="true"
                        filter="currency"
                    />
                    <p class="f-13-grey">Base bid</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { BrokenLightningIcon, DollarIcon } from '@/icons';
    import { REPORT_COLUMNS, REPORT_TYPES, ENTITY_LEAD_TYPES } from '@/constants.js';
    import { Loader, AnimateNumber } from '@/ui';
    import ReportFilters from './ReportFilters';
    import moment from 'moment/src/moment';
    import api from '@/headers.js';
    import { manipulationIndexedDB } from './dashboard-mixins/saveToIndexedDB.js';

    const INDEXED_DB_NAME = 'DashboardExpectedRevenueReport';
    const INDEXED_DB_STORE_NAME = 'ExpectedRevenueReport';

    export default {
        name: 'ExpectedRevenueReport',
        components: {
            BrokenLightningIcon,
            DollarIcon,
            Loader,
            AnimateNumber,
            ReportFilters
        },
        data() {
            return {
                loading: false,
                filters: {
                    salesman: {
                        column_id: REPORT_COLUMNS.SALESMAN.id,
                        condition: "is",
                        match: "and",
                        value: null
                    },
                    customDate: {
                        start: null,
                        end: null
                    },
                    defaultDate: "current year"
                },
                total_expected_revenue: 0,
                total_base_bid: 0
            }
        },
        mixins: [manipulationIndexedDB],
        methods: {
            getReport() {
                let params = {
                    "title": "expected revenue",
                    "description": "",
                    "type_id": REPORT_TYPES.SUMMARY_REPORT,
                    "group1": null,
                    "sort1": "asc",
                    "group2": null,
                    "sort2": "asc",
                    "group3": null,
                    "sort3": "asc",
                    "stdDateFilterField": "estimate_filter.created_at",
                    "startDate": null,
                    "endDate": null,
                    "titleOverride": {},
                    "status": "on",
                    "selectedColumns": [
                        REPORT_COLUMNS.SALESMAN.id,
                        REPORT_COLUMNS.BASE_BID.id,
                        REPORT_COLUMNS.EXPECTED_REVENUE.id
                    ],
                    "totals": [
                        {
                            "column_id": REPORT_COLUMNS.BASE_BID.id,
                            "title": "Base bid",
                            "sum": false,
                            "avg": false
                        },
                        {
                            "column_id": REPORT_COLUMNS.EXPECTED_REVENUE.id,
                            "title": "Expected revenue",
                            "sum": false,
                            "avg": false
                        }
                    ],
                    "modules": [
                        ENTITY_LEAD_TYPES.RESIDENTIAL,
                        ENTITY_LEAD_TYPES.COMMERCIAL
                    ],
                    "patternVal": "1",
                    "filters": [],
                    "showTotalPages": true,
                    "showLabels": false,    
                    "sorts": [], 
                    "callback": "funnel"
                };

                if (this.filters.salesman.value) {
                    if (this.filters.salesman.value === 'Unassigned') {
                        this.filters.salesman.condition = 'is empty';
                        delete this.filters.salesman.value;
                    }

                    params.filters.push(this.filters.salesman);
                }

                if (this.filters.defaultDate != 'custom') {
                    params.stdDateFilter = this.filters.defaultDate;
                } else {
                    params.stdDateFilter = this.filters.defaultDate;
                    params.startDate = moment(this.filters.customDate.start).format('YYYY-MM-DD');
                    params.endDate = moment(this.filters.customDate.end).format('YYYY-MM-DD');
                }

                this.loading = true;
                api.post(`/reports/build`, params)
                    .then(response => response.data.data)
                    .then(data => {
                        this.total_expected_revenue = data.total_expected_revenue;
                        this.total_base_bid = data.total_base_bid;
                        this.addReportToIndexedDB(INDEXED_DB_NAME, INDEXED_DB_STORE_NAME);
                    })
                    .catch(error => this.notifyRequestErrors(error))
                    .finally(() => this.loading = false);
            },
            getFilteringReport(val) {
                this.filters = val;
                this.getReportsFromIndexedDB(
                    INDEXED_DB_NAME,
                    INDEXED_DB_STORE_NAME
                );
            }
        },
        created() {
            this.createIndexedDBSchema(
                INDEXED_DB_NAME,
                INDEXED_DB_STORE_NAME
            );
            this.getReport();
        }
    }
</script>