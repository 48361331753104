<template>
    <full-modal @close="$emit('close')">
        <div slot="title">Bidders information</div>
        <div slot="body">
            <form-input 
                label="Other bidders" 
                :class="['mb-4', { 'form-input-danger': errors.first('other_bidders')}]"
            >
                <input 
                    class="form-input-field"
                    name="other_bidders"
                    v-input-style="bidder.name"
                    v-model="bidder.name"
                    type="text"
                    placeholder="Other bidders"
                    v-validate="'required|min:3|max:250'"
                />
            </form-input>
            <form-input 
                label="Warranty Manf" 
                :class="['mb-20', {'form-input-danger': errors.first('warranty_manf')}]"
            >
                <input 
                    class="form-input-field"
                    name="warranty_manf"
                    v-input-style="bidder.warranty"
                    v-model="bidder.warranty"
                    type="text"
                    placeholder="Warranty Manf"
                    v-validate="'min:3|max:250'"
                />
            </form-input>
            <p class="f-14-black f-sbold">Unique Offerings</p>
            <form-input-textarea
                v-input-counter="500"
                class="mb-20"
            >
                <textarea
                    :class="[
                        'form-input-field',
                        { 'form-input-textarea-danger' : errors.has('unique_offerings') }
                    ]"
                    name="unique_offerings"
                    v-model="bidder.offerings"
                    rows="1"
                    placeholder="Unique Offerings"
                    maxlength="500"
                    v-validate="'min:3|max:500'"
                    v-resize-textarea
                />
            </form-input-textarea>
            <div class="row-between row-baseline mb-20">
                <span class="f-14-black f-sbold">Roofing Type</span>
                <div class="s-align-6">
                    <radio-switch 
                        v-for="(item, index) in ROOFING_TYPE_OPTIONS"
                        :key="`roofing-type-option-${index}`"
                        :label="item"
                        color="blue"
                        width="90"
                        height="40"
                    >
                        <input 
                            type="radio" 
                            name="roofing-type-option"
                            :value="item"
                            v-model="bidder.roofing_type"
                            v-validate="'required'"
                        />
                    </radio-switch>
                </div>
            </div>
            <form-input
                label="Bid Amount"
                maskBefore="$"
                :maskValue="bidder.amount"
                maskFilter="numberWithCommas"
                :class="['mb-30', {'form-input-danger': errors.first('bid_amount')}]"
            >
                <input 
                    type="text" 
                    name="bid_amount"
                    class="form-input-field"
                    placeholder="Bid Amount"
                    v-input-style="bidder.amount"
                    v-model="bidder.amount"
                    v-validate="'min_value:1|required'"
                    v-limiter
                />
            </form-input>
            <div
                v-if="existingBidder"
                class="w-100 pt-30 bt-grey"
            >
                <p class="f-14-black f-sbold">Want to delete this bidder?</p>
                <p class="f-13-grey mb-4">By clicking Delete bidder you completely erase information on the bidder.</p>
                <template v-if="deleteConfirmShow">
                    <p class="f-11-grey">Do you want to delete this bidder?</p>
                    <div class="row-end">
                        <button
                            class="add-button mr-4"
                            @click="deleteConfirmShow = false"
                        >
                            Cancel
                        </button>
                        <button
                            class="red-button"
                            @click="deleteBidder"
                            :disabled="requestIsGoing"
                        >
                            Yes, delete
                        </button>
                    </div>
                </template>
                <button
                    v-else
                    class="transparent-button s-align-4"
                    @click="deleteConfirmShow = true"
                >
                    <trash-icon class="i-red mr-1"/>
                    <span class="f-13-red f-sbold">Delete bidder</span>
                </button>
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                :disabled="requestIsGoing"
                @click="saveBidder"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, FormInput, FormInputTextarea, RadioSwitch } from '@/ui';
    import cloneDeep from 'lodash/cloneDeep';
    import { TrashIcon } from '@/icons';
    import api from '@/headers.js';

    export default {
        name: 'EditBidderModal',
        components: {
            FullModal,
            FormInput,
            FormInputTextarea,
            RadioSwitch,
            TrashIcon
        },
        props: {
            entityId: {
                type: [String, Number],
                required: true
            },
            entityType: {
                type: String,
                required: true
            },
            existingBidder: {
                type: Object,
                required: false
            }
        },
        data() {
            return {
                ROOFING_TYPE_OPTIONS: ['N/A', 'Flat', 'Pitched'],
                bidder: {
                    amount: 0.00,
                    name: '',
                    offerings: '',
                    roofing_type: '',
                    warranty: ''
                },
                deleteConfirmShow: false,
                requestIsGoing: false
            }
        },
        methods: {
            saveBidder() {
                this.$validator.validateAll()
                    .then(result => {
                        if (result) {
                            const method = this.existingBidder ? 'put' : 'post';

                            let params = cloneDeep(this.bidder);
                            params.entity_id = this.entityId;
                            params.entity_type = this.entityType;

                            if (!this.bidder.warranty) {
                                delete params.warranty;
                            }

                            if (!this.bidder.offerings) {
                                delete params.offerings;
                            }

                            if (this.existingBidder) {
                                params.id = this.existingBidder.id;
                            }

                            this.requestIsGoing = true;
                            api[method](`/bidders/${this.existingBidder ? this.existingBidder.id : ''}`, params)
                                .then(response => {
                                    if (this.existingBidder) {
                                        this.$emit('onUpdate', params);
                                        this.notifySuccess('Bidder information updated');
                                    } else {
                                        this.$emit('onCreate', response.data.data);
                                        this.notifySuccess('Bidder created');
                                    }
                                })
                                .catch((error) => { this.notifyRequestErrors(error) })
                                .finally(() => { this.requestIsGoing = false });
                        }
                    });
            },
            deleteBidder() {
                this.requestIsGoing = true;
                api.delete(`/bidders/${this.existingBidder.id}`)
                    .then(() => {
                        this.$emit('onDelete', this.existingBidder.id);
                        this.notifySuccess('Bidder deleted');
                    })
                    .catch((error) => { this.notifyRequestErrors(error) })
                    .finally(() => { this.requestIsGoing = false });
            }
        },
        created() {
            if (this.existingBidder) {
                this.bidder.name = this.existingBidder.name;
                this.bidder.amount = this.existingBidder.amount;
                this.bidder.offerings = this.existingBidder.offerings ? this.existingBidder.offerings : '';
                this.bidder.roofing_type = this.existingBidder.roofing_type;
                this.bidder.warranty = this.existingBidder.warranty ? this.existingBidder.warranty : ''; 
            } else {
                this.bidder.roofing_type = this.ROOFING_TYPE_OPTIONS[0];
            }
        }
    }
</script>