<template>
    <svg
        viewBox="0 0 15 20"
        :width="size"
        :height="size"
        class="lightbulb-icon"
    >
        <path d="M9 7.7H7.4l.9-.9c.3-.3.3-.7-.1-1-.3-.2-.8-.2-1 .1L5.2 8c-.2.1-.3.2-.3.4 0 .4.3.7.8.7h1.5l-1.1 1.1c-.3.3-.3.7 0 1 .1.1.3.2.5.2v1.5c0 .4.3.7.8.7.4 0 .8-.3.8-.7v-2.4c0-.1 0-.2-.1-.3l1.3-1.3c.1-.1.2-.3.2-.5.1-.4-.2-.7-.6-.7z"/>
        <path d="M7.5 0C3.4 0 0 3.1 0 6.9c0 2 1 3.3 2.2 4.8l.2.3c.9 1.1.9 2 1 3.5v.6C3.6 19.4 5 20 7.3 20h.2c2.5 0 3.9-.6 4.1-3.8v-.6c.1-1.5.1-2.4 1-3.5l.2-.3C13.9 10.3 15 9 15 7c0-3.9-3.4-7-7.5-7zm4.1 10.9l-.2.3c-1.1 1.5-1.2 2.6-1.3 4.3v.6c-.2 2.5-.9 2.5-2.6 2.5s-2.4 0-2.6-2.5v-.6c-.1-1.6-.2-2.8-1.3-4.3l-.2-.3c-1-1.3-1.9-2.5-1.9-4 0-3.1 2.7-5.5 6-5.5h.1c3.3 0 6 2.5 6 5.5-.1 1.5-1 2.7-2 4z"/>
        <path d="M8.9 14.5H6.1c-.4 0-.8.3-.8.7 0 .4.3.7.8.7h2.8c.4 0 .8-.3.8-.7 0-.4-.4-.7-.8-.7zM8.6 16.4H6.4c-.4 0-.8.3-.8.7s.3.7.8.7h2.2c.4 0 .8-.3.8-.7s-.4-.7-.8-.7z"/>
    </svg>
</template>

<script>
    export default {
        name: 'LightbulbIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>