<template>
    <svg 
        x="0px" 
        y="0px"
        viewBox="0 0 30 30"
        class="question-mark"
        :width="size"
        :height="size"
    >
        <path 
            class="st0" 
            d="M29.1,12.9l-12-12c-1.2-1.2-3.1-1.2-4.3,0l-12,12c-1.2,1.2-1.2,3.1,0,4.3l12,12c1.2,1.2,3.1,1.2,4.3,0l12-12C30.3,16,30.3,14,29.1,12.9z"
        />
        <path 
            class="st1" 
            d="M14.9,21.6c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C16.3,21,15.7,21.6,14.9,21.6z"
        />
        <path 
            class="st1" 
            d="M16.7,15.8c-0.7,0.6-0.7,0.8-0.7,1v0.5c0,0.3-0.2,0.5-0.5,0.5h-1.3c-0.3,0-0.5-0.2-0.5-0.5v-0.5c0-1,0.5-1.9,1.5-2.7c0.7-0.6,1.5-1.3,1.5-1.9c0-1-0.5-1.4-1.6-1.4c-1.8,0-1.8,1.3-1.8,1.4v0.2c0,0.3-0.2,0.5-0.5,0.5h-1.3c-0.3,0-0.5-0.2-0.5-0.5V12c0-1.4,1.1-3.6,4.1-3.6c2.9,0,3.9,1.9,3.9,3.7C19,13.9,17.5,15.1,16.7,15.8z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'QuestionMark',
        props: {
            size: {
                type: [String, Number],
                default: 30
            }
        }
    }
</script>