<template>
    <div class="w-100 representative">
        <div class="row-between row-baseline flex-no-wrap">
            <h1 class="f-16-asphalt f-sbold pl-2 lg-8">{{ sectionName }}</h1>
            <div class="row-end flex-no-wrap lg-4">
                <button
                    class="edit-button s-align-9"
                    v-if="sortedRepresentatives.length"
                    @click="openRepresentativesModal('update')"
                >
                    <edit-icon class="mr-4 i-blue"/>
                    Edit
                </button>
                <div
                    v-if="mScreen && showToggle"
                    class="s-align-6 ml-8 mr-2"
                >
                    <button
                        class="transparent-button"
                        @click="openedInformation = !openedInformation"
                    >
                        <arrow-icon
                            class="i-grey mr-5"
                            size="10"
                            :transform="openedInformation ? 270 : 90"
                        />
                    </button>
                </div>
            </div>
        </div>
        <div
            v-if="openedInformation"
            class="column-start w-100 mt-6"
        >
            <scroll-table
                :head="tableHead"
                :isShowHeadMobileOnly="true"
                :filled="isExisting"
                :requestStatus="true"
                v-if="isExisting"
                class="representatives-table"
            >
                <div
                    class="table-body"
                    v-for="(item, index) in sortedRepresentatives"
                    :key="`existing-${index}`"
                >
                    <div class="table-row row-baseline table-row-small-padding">
                        <div :class="['scroll-container', { 'bb-grey' : index !== isExisting - 1 }]">
                            <div class="table-td">
                                <div class="s-align-4">
                                    <avatar
                                        :url="null"
                                        :name="`${item.first_name} ${item.last_name}`"
                                        :border="false"
                                        size="30"
                                        class="mr-4"
                                    />
                                    <span class="cell f-12-asphalt w-75">
                                        <span class="column-start w-100">
                                            <span>{{ `${item.first_name} ${item.last_name}` }}</span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="table-td">
                                <span class="cell s-align-2">
                                    <contact-list
                                        v-if="item.emails.length"
                                        :items="item.emails"
                                        alignClass="column-center"
                                        type="email"
                                    />
                                    <span
                                        class="f-normal f-13-grey f-italic pl-1"
                                        v-else
                                    >
                                        No information
                                    </span>
                                </span>
                            </div>
                            <div class="table-td">
                                <span class="cell s-align-2">
                                    <contact-list
                                        v-if="item.phones.length"
                                        :items="item.phones"
                                        :entityId="entityId"
                                        :representativesEntity="representativesForEntity"
                                        type="phone"
                                        alignClass="column-center"
                                    />
                                    <span
                                        class="f-normal f-13-grey f-italic pl-1"
                                        v-else
                                    >
                                        No information
                                    </span>
                                </span>
                            </div>
                            <div class="table-td">
                                <span class="cell f-12-asphalt s-align-2">
                                    <span
                                        v-if="item.type"
                                        class="blue-label f-11-black f-sbold dark-blue-label ellipsis">
                                        {{ item.type.title }}
                                    </span>
                                    <span
                                        v-else
                                        class="blue-label f-11-black f-sbold dark-blue-label ellipsis">
                                        {{ item.title }}
                                    </span>
                                </span>
                            </div>
                            <div class="table-td">
                                <div class="s-align-6">
                                    <button
                                        v-if="showEmail"
                                        class="transparent-button"
                                        @click="showDetails(item)"
                                    >
                                        <span class="f-12-blue">View details</span>
                                        <arrow-icon
                                            class="i-blue ml-2"
                                            transform="0"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </scroll-table>
            <div :class="['lg-6 sm-12 pr-4 pr-md-0 pr-sm-0 mt-20', mScreen && 'pl-2']">
                <div class="w-100 s-align-4 relative">
                    <button
                        id="representatives-menu-container"
                        @click="toggleOptionList"
                        class="transparent-button pv-2 mr-8 add-responsible"
                    >
                        <add-icon
                            :class="[
                                'i-blue mr-4',
                                { 'i-red' : check && !isExisting }
                            ]"
                            size="10"
                        />
                        <span :class="[
                            'f-13-blue',
                            { 'f-13-red' : check && !isExisting }
                        ]">
                            Choose contact
                        </span>
                    </button>
                    <button
                        class="transparent-button"
                        tabindex="-1"
                        @click="openRepresentativesModal"
                    >
                        <add-icon
                            :class="[
                                'i-blue mr-4',
                                { 'i-red' : check && !isExisting }
                            ]"
                            size="10"
                        />
                        <span :class="[
                            'f-13-blue',
                            { 'f-13-red' : check && !isExisting }
                        ]">
                            Add new contact
                        </span>
                    </button>
                    <div
                        id="representatives-menu"
                        class="responsible-options-wrapper s-align-5"
                        :style="stylePopup"
                        v-if="openOptionList"
                    >
                        <div class="responsible-options">
                            <div class="row-between row-baseline p-20">
                                <span class="f-15-asphalt f-sbold">Choose from the List:</span>
                                <button
                                    class="icon-light"
                                    @click="closeModal"
                                >
                                    <close-icon size="13"/>
                                </button>
                            </div>
                            <div
                                class="w-100 ph-20"
                                v-if="!loading"
                            >
                                <input
                                    type="text"
                                    placeholder="Search contact"
                                    class="opportunity-search pointer"
                                    v-model="searchContact"
                                />
                            </div>
                            <perfect-scrollbar class="responsible-scroll ps-blue">
                                <loader
                                    v-if="loading"
                                    size="small"
                                />
                                <ul
                                    v-else
                                    class="w-100 pb-2"
                                >
                                    <li
                                        class="responsible-options-item row-between pointer"
                                        v-for="(person, index) in searchedContacts"
                                        :key="`person-${index}`"
                                        @click="addRepresentativeFromOptions(person)"
                                        @mouseover="toggleShowTrashRepresentative(person.id)"
                                        @mouseout="clearShowTrashRepresentative"
                                    >
                                        <div class="s-align-4">
                                            <avatar
                                                :url="null"
                                                :name="`${person.first_name} ${person.last_name}`"
                                                :border="false"
                                                size="26"
                                                class="mr-4"
                                            />
                                            <span class="f-12-darkgrey f-sbold">
                                                {{ `${person.first_name} ${person.last_name}` }}
                                            </span>
                                        </div>
                                        <div class="s-align-6 check-representative-action">
                                            <button class="transparent-button pv-2 pl-2 w-100">
                                                <mark-icon
                                                    size="15"
                                                    class="i-green is-check"
                                                    v-if="isShowMarkBtn(person) && !loadRepresentatives"
                                                />
                                            </button>
                                            <button
                                                v-if="checkAddedRepresentative(person) && hideAddedId == person.id && !loadRepresentatives"
                                                :class="[
                                                    'transparent-button delete-representative pv-2 pl-2 w-100',
                                                    loadRepresentatives ? 'cursor-not-allowed' : 'pointer'
                                                ]"
                                                :disabled="loadRepresentatives"
                                            >
                                                <trash-icon
                                                    size="15"
                                                    class="i-grey"
                                                />
                                            </button>
                                        </div>
                                        <template v-if="loadRepresentatives">
                                            <loader size="xs"/>
                                        </template>
                                    </li>
                                </ul>
                            </perfect-scrollbar>
                        </div>
                    </div>
                </div>
            </div>
            <p :class="['f-12-grey pb-2 pt-1', mScreen && 'pl-2']">
                {{
                    isOptions ?
                        'Choose the point of contact from the list or create a new contact.' :
                        'Create a new contact.'
                }}
            </p>
        </div>
        <contacts-modal
            v-if="modalIsOpen"
            :representatives="editableRepresentatives"
            :validation="requiredRepresentativesDataValidation ? 'required' : ''"
            @save="addRepresentativesFromModal"
            @remove="removeRepresentative"
            @close="modalIsOpen = false"
        />
    </div>
</template>

<script>
import {FormInput, Loader} from '@/ui';
import {TrashIcon, EditIcon, CloseIcon, MarkIcon, AddIcon, ArrowIcon} from '@/icons';
import cloneDeep from 'lodash/cloneDeep';
import {ScrollTable} from '@/components';
import {clearArray, sortRepresentatives} from '@/helpers';
import {COMMERCIAL_PHONE_TYPES} from '@/constants';
import Avatar from '@/components/User/Avatar.vue';
import ContactList from '@/components/CommercialJobs/ContactList.vue';
import {getCoords} from '@/helpers.js';
import Tooltip from "../../ui/Tooltip";
import {ContactsModal} from '@/modals'

const CREATE = 'create';

export default {
    name: 'Contacts',
    components: {
        Loader,
        ArrowIcon,
        FormInput,
        TrashIcon,
        EditIcon,
        ScrollTable,
        Avatar,
        CloseIcon,
        MarkIcon,
        ContactList,
        Tooltip,
        AddIcon,
        ContactsModal
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        loadRepresentatives: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            required: true
        },
        showEmail: {
            type: Boolean,
            default: false
        },
        existing: {
            type: Array,
            default: function () {
                return [];
            }
        },
        showToggle: {
            type: Boolean,
            default: false
        },
        forRemove: {
            type: Array,
            default: function () {
                return [];
            }
        },
        check: {
            type: Boolean,
            default: false
        },
        moduleMode: {
            type: String,
            required: true
        },
        saveImmediately: {
            type: Boolean,
            default: false
        },
        requiredRepresentativesDataValidation: {
            type: Boolean,
            default: true
        },
        entityId: {
            type: [Number, String],
            required: false
        },
        sectionName: {
            type: String,
            required: false
        },
        representativesForEntity: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            CREATE: CREATE,
            editableRepresentatives: [],
            tableHead: ['Name', 'E-mail', 'Phone', 'Contact Type'],
            modalIsOpen: false,
            openOptionList: false,
            representativeUnit: {
                first_name: '',
                last_name: '',
                title: '',
                emails: [{email: '', order: 0}],
                phones: [{number: '', order: 0, type: COMMERCIAL_PHONE_TYPES[1]}]
            },
            openedInformation: true,
            stylePopup: '',
            selectPointOfContacts: null,
            showDeleteBtnId: null,
            hideAddedId: null,
            activeRepresentatives: null,
            searchContact: ''
        }
    },
    computed: {
        isExisting() {
            return this.existing.length;
        },
        sortedRepresentatives() {
            return sortRepresentatives(this.existing, this.entityId);
        },
        isOptions() {
            return !!this.options.length;
        },
        userInfo() {
            return this.$store.getters['user/getUserData'];
        },
        searchedContacts() {
          const txtSearchInput = this.searchContact.replaceAll(' ', '');
          const searchRegex = new RegExp(txtSearchInput,'i');

            return this.options.filter(contact => {
                if (contact.first_name && contact.last_name) {
                    const fullName = `${contact.first_name}${contact.last_name}`;

                    return !this.searchContact || searchRegex.test(fullName);
                }
            });
        }
    },
    methods: {
        closeModal() {
            this.openOptionList = false;
            this.searchContact = '';
        },
        isShowMarkBtn(data) {
            return this.checkAddedRepresentative(data)
                && this.hideAddedId !== data.id
                && !(this.loadRepresentatives && this.activeRepresentatives === data.id);
        },
        toggleShowTrashRepresentative(id) {
            this.showDeleteBtnId = id;
            this.hideAddedId = id;
        },
        clearShowTrashRepresentative() {
            this.showDeleteBtnId = null;
            this.hideAddedId = null;
        },
        showDetails(item) {
            this.$store.commit('ADD_CONTACT_IN_STORE', item);
            this.$router.push({
                name: 'ContactInformation',
                params: {contact_id: item.id}
            });
        },
        toggleOptionList() {
            this.openOptionList = !this.openOptionList;

            this.$emit('getContactsList');

            if (this.openOptionList) {
                this.$nextTick(() => {
                    this.showDateMenu();
                });
            }
        },
        showDateMenu() {
            this.stylePopup = '';
            const popup = document.getElementById('representatives-menu');
            const container = document.getElementById('representatives-menu-container');
            const freeHeightBottom = this.scrollHeight - getCoords(container).top - container.clientHeight;
            if (
                freeHeightBottom < popup.clientHeight
                || container.getBoundingClientRect().top > window.outerHeight - container.getBoundingClientRect().top
            ) {
                const marginTop = Math.round(popup.clientHeight);
                this.stylePopup = `top: -${marginTop}px;`;
            }
        },
        openRepresentativesModal(mode = 'create') {
            if (this.isExisting) {
                this.editableRepresentatives = cloneDeep(this.existing);
                if (mode !== 'update') {
                    this.editableRepresentatives.push(this.representativeUnit);
                }
            } else {
                this.editableRepresentatives = [...[this.representativeUnit]];
            }

            this.modalIsOpen = true;
        },
        addRepresentativesFromModal(representatives) {
            clearArray(this.existing);
            let reprArray = representatives;
            if (reprArray.length) {
                reprArray.forEach((item) => {
                    if (item.type || item.type === null) {
                        delete item.type;
                    }
                });
            }
            this.existing.push(...reprArray);
            this.modalIsOpen = false;

            if (this.saveImmediately) {
                this.$emit('save', this.existing);
            }
        },
        removeRepresentative(id) {
            if (this.moduleMode !== CREATE) {
                this.forRemove.push({id: id});
            }
        },
        removeRepresentativeLocal(id) {
            this.$emit('deleteRepresentative', id);
            if (this.moduleMode !== CREATE) {
                if (this.existing.length === 1) {
                    this.notifyError('You cannot delete all representatives');
                    return;
                }

                this.forRemove.push({id: id});
                this.activeRepresentatives = id;
                this.$emit('save', this.existing);
            }
        },
        addRepresentativeFromOptions(data) {
            data = {
                emails: data.emails,
                first_name: data.first_name,
                id: data.id,
                last_name: data.last_name,
                phones: data.phones,
                title: data.position
            }

            if (this.checkAddedRepresentative(data)) {
                return this.removeRepresentativeLocal(data.id, data);
            }

            this.existing.push(data);

            // and remove from forRemove if it is added again
            const index = this.forRemove.findIndex(i => i.id === data.id);

            if (index !== -1) {
                this.forRemove.splice(index, 1);
            }

            if (this.saveImmediately) {
                this.activeRepresentatives = null;
                this.$emit('save', this.existing);
            }
        },
        checkAddedRepresentative(data) {
            return this.existing.find(p => p.id === data.id);
        },
        outside(event) {
            const wrapper = this.$el.querySelector('.responsible-options-wrapper');
            const addbtn = this.$el.querySelector('.add-responsible');
          if (wrapper && !wrapper.contains(event.target) && !addbtn.contains(event.target) && this.openOptionList) {
            this.openOptionList = false;
            this.searchContact = '';
          }
        }
    },
    watch: {
        modalIsOpen(newValue) {
            if (!newValue) {
                this.$emit('clean');
            }
        }
    },
    mounted() {
        document.body.addEventListener('click', this.outside, false);
        if (this.showEmail) {
            this.tableHead.push('Action');
        }
    },
    destroyed() {
        document.body.removeEventListener('click', this.outside, false);
    }
}
</script>
