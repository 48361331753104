<template>
    <div class="modal s-align-5">
        <div class="save-estimate-pdf-modal cm-fond cm-scroll">
            <h2 class="f-24-black f-sbold t-center">
                {{ modalTitle }}
            </h2>
            <div class="w-100">
                <form-input
                    label="PDF Name"
                    :class="[{ 'form-input-danger' : errors.has('pdf_name') }]"
                >
                    <input
                        type="text"
                        name="pdf_name"
                        class="form-input-field"
                        placeholder="PDF Name"
                        v-input-style="name"
                        v-model="name"
                        v-validate="'required|min:3|max:250'"
                    />
                </form-input>
            </div>
            <div class="custom-modal-footer row-between">
                <button
                    class="add-button"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button
                    class="primary-button"
                    @click="save"
                    :disabled="requestIsGoing"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { FormInput, Loader } from '@/ui';
    import api from '@/headers.js';

    export default {
        name: 'SaveEstimatePdfModal',
        components: {
            FormInput,
            Loader
        },
        props: {
            orderName: {
                type: String,
                default: ''
            },
            modalTitle: {
                type: String,
                default: 'Save Estimate as PDF'
            }
        },
        data() {
            return {
                folders: [],
                folder: null,
                name: '',
                loading: false,
                requestIsGoing: false
            }
        },
        methods: {
            save() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        this.requestIsGoing = true;
                        this.$emit('save', { folder: this.folder, name: this.name });

                        setTimeout(() => this.requestIsGoing = false, 8000);
                    } else {
                        this.notifyError('Field "Name" is required.');
                    }
                });
            }
        },
        mounted() {
            if (this.orderName) {
                this.name = this.orderName;
            }
        }
    }
</script>