export const SET_WIDGET_REF = 'SET_WIDGET_REF';
export const OPEN_WIDGET = 'OPEN_WIDGET';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const CLEAR_PHONE_NUMBER = 'CLEAR_PHONE_NUMBER';
export const MAKE_OUTCOME_CALL = 'MAKE_OUTCOME_CALL';
export const TAKE_INCOME_CALL = 'TAKE_INCOME_CALL';
export const STORE_CALL_ID = 'STORE_CALL_ID';
export const STORE_LINKED_CALL = 'STORE_LINKED_CALL';
export const SET_ENTITY_INFO = 'SET_ENTITY_INFO';
export const SET_JOB_OR_LEAD_INFO = 'SET_JOB_OR_LEAD_INFO';
export const CLEAR_ENTITY_INFO = 'CLEAR_ENTITY_INFO';
export const CLEAR_JOB_OR_LEAD_INFO = 'CLEAR_JOB_OR_LEAD_INFO';
export const SWITCH_WIDGET = 'SWITCH_WIDGET';
export const SUCCESS_BIND_ENTITY = 'SUCCESS_BIND_ENTITY';
