<template>
    <div
        :class="[
            'form-datepicker row-baseline row-around',
            { 'cursor-not-allowed disabled-form-datepicker' : disabled }
        ]"
        :style="{ width: isFullWidth ? '100%' : 'auto' }"
    >
        <calendar-icon 
            size="16"
            :class="disabled ? 'i-grey' : 'i-lightblue'"
        />
        <v-date-picker
            :id="keyDatePicker"
            :class="[
                'column-center pl-6 h-100',
                disabled ? 'none-events' : 'pointer'
            ]"
            :value="selectedDate"
            @dayclick="onDateChange"
            show-caps
            :popover-direction="direction"
            :tintColor="tintColor"
            :attributes="attributes"
            :disabledAttribute="disabledAttribute"
            :themeStyles="themeStyles"
            @click.native="changeDirection"
        >
            <span
                v-if="value"
                :class="['form-datepicker-date', { 'form-datepicker-date-warn': markDateAsWarn }]"
            >
                {{ formatedDate }}
            </span>
            <span class="form-datepicker-placeholder ellipsis inline-block max-w-100">
                {{ placeholder }}
            </span>
        </v-date-picker>
        <div class="form-datepicker-control row-around">
            <button 
                class="icon-light pointer"
                @click="prevDate"
                :disabled="!value || disabledControll"
            >
                <arrow-icon 
                    size="12" 
                    transform="180"
                />
            </button> 
            <button 
                class="icon-light pointer"
                @click="nextDate"
                :disabled="!value || disabledControll"
            >
                <arrow-icon 
                    size="12" 
                    transform="0"
                />
            </button> 
        </div>
    </div>
</template>

<script>
    import moment from 'moment/src/moment';
    import { CalendarIcon, ArrowIcon } from '@/icons';
    import calendarConfig from '@/mixins/calendarConfig';
    import { getCoords } from '@/helpers.js'

    export default {
        name: 'FormDatepicker',
        components: {
            CalendarIcon, 
            ArrowIcon
        },
        props: {
            value: {
                required: true
            },
            isFullWidth: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: 'Select date'
            },
            popoverDirection: {
                type: String,
                default: null
            },
            disabledControll: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            markDateAsWarn: {
                type: Boolean,
                default: false
            },
            keyDatePicker: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                selectedDate: null,
                scrollHeight: 0,
                direction: null
            }
        },
        mixins: [ calendarConfig ],
        computed: {
            date() {
                // convert to JS Date object
                return moment(this.value).toDate();
            },
            formatedDate() {
                return moment(this.value).format('DD MMM YYYY');
            }
        },
        methods: {
            changeDirection() {
                setTimeout(() => {
                    this.scrollHeight = document.body.offsetHeight;

                    if (this.popoverDirection) {
                        this.direction = this.popoverDirection;
                    }

                    const container = document.getElementById(this.keyDatePicker);
                    const constainerMarginTop = container.getBoundingClientRect().top;
                    const freeHeightBottom = this.scrollHeight - getCoords(container).top - container.clientHeight;
                    this.direction = freeHeightBottom < 300
                        || constainerMarginTop > window.outerHeight - constainerMarginTop
                            ? 'top'
                            : 'bottom';

                }, 0);
            },
            onDateChange(event) {
                if (event) {
                    this.selectedDate = moment(event.date).toDate();
                    this.$emit('input', moment(event.date).format('YYYY-MM-DD'));
                } else {
                    this.selectedDate = moment(this.selectedDate).toDate();
                    this.$emit('input', moment(this.selectedDate).format('YYYY-MM-DD'));
                }

                // unfocus on date picker after day click
                this.$el.querySelector('.popover-container').blur();
            },
            nextDate: function() {
                const nextDay = moment(this.value).clone().add(1, 'd').format('YYYY-MM-DD');
                this.$emit('input', nextDay)
            },
            prevDate: function() {
                const prevDay = moment(this.value).clone().add(-1, 'd').format('YYYY-MM-DD');
                this.$emit('input', prevDay)
            }
        },
        created() {
            if (this.value) {
                this.selectedDate = moment(this.value).toDate();
            }
        }
    }
</script>

