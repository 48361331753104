<template>
    <div>
        <full-modal @close="$emit('close')">
            <div slot="title">Lead settings</div>
            <div slot="body">
                <p class="f-14-asphalt f-sbold pb-4">Requested by</p>
                <div class="pb-30 row-between">
                    <div class="lg-6 sm-12 pb-4 pr-2 pr-sm-0">
                        <form-input
                            label="First name"
                            :class="[{ 'form-input-danger' : errors.has('first_name') }]"
                        >
                            <input
                                type="text"
                                name="first_name"
                                id="first_name"
                                class="form-input-field"
                                placeholder="First name"
                                v-input-style="lead.requestedBy.first_name"
                                v-model="lead.requestedBy.first_name"
                                maxlength="31"
                                v-validate="{ required: true, max: 30, regex: titleRegex }"
                                @blur="addFieldsToEdited('requestedBy')"
                            />
                        </form-input>
                    </div>
                    <div class="lg-6 sm-12 pb-4 pl-2 pl-sm-0">
                        <form-input
                            label="First name"
                            :class="[{ 'form-input-danger' : errors.has('last_name') }]"
                        >
                            <input
                                type="text"
                                name="last_name"
                                id="last_name"
                                class="form-input-field"
                                placeholder="First name"
                                v-input-style="lead.requestedBy.last_name"
                                v-model="lead.requestedBy.last_name"
                                maxlength="31"
                                v-validate="{ required: true, max: 30, regex: titleRegex }"
                                @blur="addFieldsToEdited('requestedBy')"
                            />
                        </form-input>
                    </div>
                    <div class="w-100">
                        <form-input
                            label="Title"
                            :class="[{ 'form-input-danger' : errors.has('title') }]"
                        >
                            <input
                                type="text"
                                name="title"
                                id="title"
                                class="form-input-field"
                                placeholder="Title"
                                v-input-style="lead.requestedBy.title"
                                v-model="lead.requestedBy.title"
                                maxlength="31"
                                v-validate="{ required: true, max: 30, regex: titleRegex }"
                                @blur="addFieldsToEdited('requestedBy')"
                            />
                        </form-input>
                    </div>
                </div>
                <p class="f-14-asphalt f-sbold pb-4">Contacts</p>
                <div class="w-100 pb-30">
                    <div class="w-100 column-start">
                        <p class="f-13-asphalt pb-4">Phones:</p>
                        <div
                            class="s-align-4 w-100 pb-4"
                            v-for="(phone, index) in lead.requestedBy.phones"
                            :key="`phone-number-${index}`"
                            :id="`phone-number-${index}`"
                        >
                            <div class="column-start static-col-8">
                                <form-input
                                    v-if="!showTooltipInPrmaryFields"
                                    label="Phone"
                                    class="form-input-with-select"
                                    :class="[{ 'form-input-danger' : errors.has(`phone-number-${index}`) }]"
                                >
                                    <input
                                        type="tel"
                                        :name="`phone-number-${index}`"
                                        class="form-input-field"
                                        placeholder="Phone"
                                        v-input-style="phone.number"
                                        v-model="phone.number"
                                        v-mask="'(###) ###-####'"
                                        v-validate="'required|min:14'"
                                        @blur="addFieldsToEdited('requestedBy')"
                                    />
                                    <multiselect
                                        :options="phoneTypes"
                                        :showLabels="false"
                                        :max-height="200"
                                        placeholder="Types"
                                        v-model="phone.type"
                                        :name="'phone_type' + index"
                                        :data-name="'phone_type' + index"
                                        v-validate="'required'"
                                        :class="['capitalize', { 'multiselect-danger' : errors.has('phone_type' + index) }]"
                                        :searchable="false"
                                        @select="addFieldsToEdited('requestedBy')"
                                    />
                                </form-input>
                                <tooltip
                                    class="w-100"
                                    v-if="showTooltipInPrmaryFields"
                                    direction="top"
                                    mobileDescription="Field info"
                                >
                                    <form-input
                                        label="Phone"
                                        class="form-input-with-select"
                                        :class="[{ 'form-input-danger' : errors.has(`phone-number-${index}`) }]"
                                    >
                                        <input
                                            type="tel"
                                            :name="`phone-number-${index}`"
                                            class="form-input-field"
                                            placeholder="Phone"
                                            v-input-style="phone.number"
                                            v-model="phone.number"
                                            v-mask="'(###) ###-####'"
                                            v-validate="'required|min:14'"
                                            @blur="addFieldsToEdited('requestedBy')"
                                        />
                                        <multiselect
                                            :options="phoneTypes"
                                            :showLabels="false"
                                            :max-height="200"
                                            placeholder="Types"
                                            v-model="phone.type"
                                            :name="'phone_type' + index"
                                            :data-name="'phone_type' + index"
                                            v-validate="'required'"
                                            :class="['capitalize', { 'multiselect-danger' : errors.has('phone_type' + index) }]"
                                            :searchable="false"
                                            @select="addFieldsToEdited('requestedBy')"
                                        />
                                    </form-input>
                                    <template v-slot:content>
                                        <p class="f-16-white">
                                            {{ showTooltipInPrmaryFields }}
                                        </p>
                                    </template>
                                </tooltip>
                                <form-input
                                    v-if="showPhoneExtInPrmaryFields || phone.extension"
                                    label="Phone extension"
                                    :class="[{ 'form-input-danger' : errors.has(`extension-${index}`) }]"
                                >
                                    <input
                                        type="text"
                                        :name="`extension-${index}`"
                                        class="form-input-field mt-2"
                                        placeholder="Phone extension"
                                        v-model="phone.extension"
                                        v-validate="'integer'"
                                        @blur="addFieldsToEdited('requestedBy')"
                                    />
                                </form-input>
                            </div>
                            <button
                                class="service-add-button ml-2"
                                v-if="index > 0"
                                @click="removeContactsFromRequestedBy(index, lead.requestedBy.phones)"
                                tabindex="-1"
                            >
                                <trash-icon/>
                            </button>
                            <button
                                class="service-secondary-button ml-2"
                                v-if="phonesLength == index + 1 && phonesLength < 3"
                                @click="addPhoneNumber"
                                tabindex="-1"
                            >
                                <add-icon size="10"/>
                            </button>
                        </div>
                    </div>
                    <div class="w-100 column-start pt-2">
                        <p class="f-13-asphalt pb-4 mt-sm-1">Emails:</p>
                        <div
                            class="s-align-4 w-100 pb-4"
                            v-for="(email, index) in lead.requestedBy.emails"
                            :key="email.id"
                            :id="`email-${index}`"
                        >
                            <div class="static-col-8">
                                <form-input
                                    label="Email"
                                    :class="[{ 'form-input-danger' : errors.has(`email-${index}`) }]"
                                >
                                    <input
                                        type="email"
                                        :name="`email-${index}`"
                                        class="form-input-field"
                                        placeholder="Email"
                                        v-input-style="email.email"
                                        v-model="email.email"
                                        v-validate="'required|email'"
                                        @blur="addFieldsToEdited('requestedBy')"
                                    />
                                </form-input>
                            </div>
                            <button
                                class="service-add-button ml-2"
                                v-if="index > 0"
                                @click="removeContactsFromRequestedBy(index, lead.requestedBy.emails)"
                                tabindex="-1"
                            >
                                <trash-icon/>
                            </button>
                            <button
                                class="service-secondary-button ml-2"
                                v-if="emailsLength == index + 1 && emailsLength < 3"
                                @click="addEmail"
                                tabindex="-1"
                            >
                                <add-icon size="10"/>
                            </button>
                        </div>
                    </div>
                </div>
                <p class="f-14-asphalt f-sbold pb-4">Bid Due Date</p>
                <div class="w-100 pb-30">
                    <div class="w-100 column-start pt-2">
                        <div class="s-align-4 w-100 pb-4">
                            <div class="static-col-8">
                                <form-date-time-input
                                    v-model="lead.bid_due_date"
                                    v-validate="'required'"
                                    name="bid_due_date"
                                    placeholder="Bid Due Date"
                                    size="medium"
                                    @input="addFieldsToEdited('bidDueDate')"
                                    :minDate="new Date()"
                                    :isFullWidth="true"
                                    :class="{ 'form-date-time-input-error' : errors.has('bid_due_date') }"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <p class="f-14-asphalt f-sbold pb-4">Address</p>
                <div class="w-100 pb-30">
                    <address-box
                        type="address"
                        unicId="lead"
                        :key="'address'"
                        :existing="lead.address"
                        :apartment="true"
                        @address="getLeadAddress"
                    >
                        <div
                            class="w-100 mb-4"
                            slot="apartment"
                        >
                            <form-input
                                label="Unit"
                                :class="[{ 'form-input-danger' : errors.has('property_unit') }]"
                            >
                                <input
                                    type="text"
                                    name="property_unit"
                                    id="property_unit"
                                    class="form-input-field"
                                    placeholder="Unit"
                                    v-input-style="lead.address.apartment"
                                    v-model="lead.address.apartment"
                                    v-validate="'max:50'"
                                />
                            </form-input>
                        </div>
                    </address-box>
                </div>
                <p class="f-14-asphalt f-sbold pb-4">Appointment Address</p>
                <div class="w-100 pb-30">
                    <address-box
                        type="address"
                        unicId="appointment"
                        :key="'address'"
                        :validate="appointmentValidateRule"
                        :existing="lead.appointment"
                        @address="getAppointmentAddress"
                    />
                </div>
                <p class="f-14-asphalt f-sbold pb-4">Work Items</p>
                <div class="w-100 pb-30 row-between">
                    <div class="column-start lg-6 sm-12 pb-4 pr-2 pr-sm-0">
                        <p class="f-13-black pb-2">Job type:</p>
                        <multiselect
                            :options="leadTypes"
                            track-by="id"
                            label="name"
                            :showLabels="false"
                            :max-height="200"
                            placeholder="Job type"
                            name="lead_type_id"
                            data-name="lead_type_id"
                            v-model="lead.type"
                            v-validate="'required'"
                            :class="[{ 'multiselect-danger' : errors.has('lead_type_id') }]"
                            @select="addFieldsToEdited('leadTypes')"
                        />
                    </div>
                    <div class="column-start lg-6 sm-12 pb-4 pl-2 pl-sm-0">
                        <p class="f-13-black pb-2">Material:</p>
                        <multiselect
                            :options="materials"
                            track-by="id"
                            label="name"
                            :showLabels="false"
                            :max-height="200"
                            placeholder="Material"
                            name="material_id"
                            data-name="material_id"
                            v-model="lead.material"
                            v-validate="'required'"
                            :class="[{ 'multiselect-danger' : errors.has('material_id') }]"
                            @select="addFieldsToEdited('materials')"
                        />
                    </div>
                    <div class="column-start lg-6 sm-12 pb-4 pr-2 pr-sm-0">
                        <p class="f-13-black pb-2">Home type:</p>
                        <multiselect
                            :options="homeTypes"
                            track-by="id"
                            label="name"
                            :showLabels="false"
                            :max-height="200"
                            placeholder="Home type"
                            name="home_type_id"
                            data-name="home_type_id"
                            v-model="lead.homeType"
                            v-validate="'required'"
                            :class="[{ 'multiselect-danger' : errors.has('home_type_id') }]"
                            @select="addFieldsToEdited('homeTypes')"
                        />
                    </div>
                    <div class="column-start lg-6 sm-12 pb-4 pl-2 pl-sm-0">
                        <p class="f-13-black pb-2">Priorities:</p>
                        <multiselect
                            :options="priorities"
                            track-by="id"
                            label="name"
                            :showLabels="false"
                            :max-height="200"
                            placeholder="Priorities"
                            name="priority"
                            data-name="priority"
                            v-model="lead.priority"
                            v-validate="'required'"
                            :class="[{ 'multiselect-danger' : errors.has('priority') }]"
                            @select="addFieldsToEdited('priorities')"
                        />
                    </div>
                    <div class="column-start lg-6 sm-12 pb-4 pr-2 pr-sm-0">
                        <p class="f-13-black pb-2">Lead source:</p>
                        <multiselect
                            :options="leadSources"
                            track-by="id"
                            label="name"
                            :showLabels="false"
                            :max-height="200"
                            placeholder="Lead source"
                            name="lead_source_id"
                            data-name="lead_source_id"
                            v-model="lead.source"
                            v-validate="'required'"
                            :class="[{ 'multiselect-danger' : errors.has('lead_source_id') }]"
                            @select="addFieldsToEdited('leadSources')"
                        />
                        <form-input
                            v-if="!selectedPartner && isReferral"
                            label="Referral Name"
                            :class="[{ 'form-input-danger' : errors.has('referral_name') }, 'mt-4']"
                        >
                            <input
                                type="text"
                                name="referral_name"
                                id="referral_name"
                                class="form-input-field"
                                placeholder="Referral Name"
                                v-model="referralName"
                                maxlength="255"
                                v-validate="{regex: titleRegex }"
                                ref="ReferralName"
                                @blur="addFieldsToEdited('referral_name')"
                            />
                        </form-input>
                        <multiselect
                            v-if="isReferral && !referralName"
                            :options="partners"
                            placeholder="Select from Contacts"
                            :show-labels="false"
                            :max-height="200"
                            :searchable="false"
                            :custom-label="partnerCustomLabel"
                            track-by="id"
                            name="lead_partner"
                            data-name="lead_partner"
                            v-model="selectedPartner"
                            @select="addFieldsToEdited('lead_partner')"
                            :class="[
                            {'selected-referral' : selectedPartner},
                            { 'multiselect-danger' : errors.has('lead_partner') },
                            'mt-5 multiselect-small'
                        ]"
                            v-validate="{required: Boolean(!referralName)}"
                        />
                    </div>
                </div>
                <div class="row-between pb-8">
                    <p class="f-14-asphalt f-sbold">Trades</p>
                    <select-or-deselect
                        :data="selectedTrades"
                        :valuesFromAdd="trades"
                    />
                </div>
                <div class="w-100 pb-30 row-start">
                    <trade
                        v-for="(trade, index) in trades"
                        :key="trade.id"
                        :label="trade.name"
                        :class="[{ 'trade-danger' : errors.has(`trade-${index}`) }]"
                        :id="`trade-${index}`"
                        :icon="trade.icon_code"
                    >
                        <input
                            type="checkbox"
                            :name="`trade-${index}`"
                            v-model="selectedTrades"
                            :value="trade.id"
                            v-validate="'required'"
                        />
                        <div slot="isSalesRabbit">
                            <div
                                v-if="trade.is_sales_rabbit"
                                class="row-start s-align-4 ml-4"
                            >
                                <storm-icon
                                    width="10"
                                    height="12"
                                    class="ml-2"
                                />
                                <toggle-switch
                                    class="ml-1 pointer"
                                    smallSize
                                >
                                    <input
                                        :checked="isSalesRabbit(trade.id)"
                                        @change="toggleSalesRabbit(trade.id, index)"
                                        type="checkbox"
                                    />
                                </toggle-switch>
                            </div>
                        </div>
                    </trade>
                </div>
            </div>
            <div slot="footer">
                <button
                    class="add-button"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button
                    class="primary-button"
                    @click="save"
                    ref="save"
                >
                    Save
                </button>
            </div>
        </full-modal>
        <zip-code-modal
            v-if="outOfArea"
            @continue="updateLead"
            @cancel="cancel"
            @close="cancel"
        />
    </div>
</template>

<script>
    import { FullModal, FormInput, Trade, RadioButton, FormDateTimeInput, Tooltip, ToggleSwitch } from '@/ui';
    import { TrashIcon, AddIcon, EditIcon, PlusIcon, ArrowIcon, MarkIcon, StormIcon } from '@/icons';
    import { 
        COMMERCIAL_PHONE_TYPES, ADDRESS_TYPES, BASE_ENTITY_TYPES, DUMMY_EMPTY_FIELD,
        NOT_ALLOWED_CHARACTERS
    } from '@/constants';
    import SelectOrDeselect from '@/components/SelectOrDeselect.vue';
    import Avatar from '@/components/User/Avatar.vue';
    import { 
        copyFieldsValueFromObjectToObject, removePhoneMask, clearObject, clearArray, checkContacts,
        validationErrorNotification
    } from '@/helpers';
    import { mask } from 'vue-the-mask';
    import cloneDeep from 'lodash/cloneDeep';
    import AddressBox from '@/components/AddressBox.vue';
    import Multiselect from 'vue-multiselect';
    import api from '@/api';
    import { customFieldInPrimarySection } from '@/components/CreateLead/mixins/customFieldInPrimarySection.js';
    import { mapActions, mapGetters } from 'vuex';

    const VALIDATION_REQUIRED = 'required';
    const VIEW_URL = '/commercial-lead-details';

    export default {
        name: 'EditCommercialLeadModal',
        components: {
            ToggleSwitch,
            StormIcon,
            FullModal,
            FormInput,
            Trade,
            RadioButton,
            Multiselect,
            MarkIcon,
            TrashIcon,
            AddIcon,
            AddressBox,
            Avatar,
            EditIcon,
            PlusIcon,
            ArrowIcon,
            SelectOrDeselect,
            FormDateTimeInput,
            Tooltip,
            zipCodeModal: () => import('@/modals/ZipCodeModal.vue')
        },
        props: {
            lead: {
                type: Object,
                required: true
            },
            phonePrimarySectionParameters: {
                type: Object,
                required: false
            }
        },
        mixins: [customFieldInPrimarySection],
        data() {
            return {
                phoneTypes: COMMERCIAL_PHONE_TYPES,
                contactQuantity: 0,
                leadTypes: [],
                materials: [],
                homeTypes: [],
                leadSources: [],
                trades: [],
                tradesList: [],
                selectedTrades: [],
                priorities: [],
                salesRabbit: [],
                edited: {},
                pageIsLoaded: false,
                appointmentValidateRule: '',
                openConfirmSaveModal: false,
                titleRegex: NOT_ALLOWED_CHARACTERS,
                referralName: null,
                selectedPartner: null
            }
        },
        computed: {
            ...mapGetters('zipCodes', [
                'outOfArea'
            ]),
            ...mapGetters('partners', [
                'partners'
            ]),
            phonesLength() {
                return this.lead.requestedBy.phones.length;
            },
            emailsLength() {
                return this.lead.requestedBy.emails.length;
            },
            isReferral() {
                if (!this.lead.source) {
                    return false;
                }

                let source = this.leadSources.find(item => {
                    return item.id === this.lead.source.id;
                }) || false;

                return source && source.has_children;
            }
        },
        directives: {
            mask
        },
        methods: {
            ...mapActions('zipCodes', [
                'checkZipCodeArea'
            ]),
            ...mapActions('partners', [
                'getPartners'
            ]),
            setLeadInfo() {
                this.salesRabbit = this.lead.trades.filter(el => el.is_sales_rabbit).map(el => el.id);
            },
            isSalesRabbit(id) {
                return this.tradesList.find(el => el.id === id && el.is_sales_rabbit);
            },
            toggleSalesRabbit(id) {
                const findElIndex = this.salesRabbit.findIndex(el => el === id);
                if (findElIndex !== -1) {
                    this.salesRabbit.splice(findElIndex, 1);
                    return;
                }

                this.salesRabbit.push(id);
            },
            getWorkItems() {
                const params = {
                    needed: [
                        'lead_types',
                        'materials',
                        'home_types',
                        'lead_sources',
                        'trades',
                        'priorities'
                    ],
                    entityType: BASE_ENTITY_TYPES.COMMERCIAL_LEAD
                };
                api.handbooks(params, {'filter[is_active]': 1})
                    .then(response => {
                        this.materials = response.data.data.materials;
                        this.homeTypes = response.data.data.home_types;
                        this.leadSources = response.data.data.lead_sources;
                        this.trades = response.data.data.trades;
                        this.priorities = response.data.data.priorities;

                        // show job types with the same group id
                        this.leadTypes = response.data.data.lead_types.filter(i => i.group_id === this.lead.type.group_id);

                        this.trades.map((trade) => {
                            for (let i = 0; i < this.lead.trades.length; i++) {
                                if (this.lead.trades[i].id === trade.id) {
                                    this.selectedTrades.push(this.lead.trades[i].id);
                                    this.tradesList.push(this.lead.trades[i]);
                                }
                            }
                        });
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            addPhoneNumber() {
                this.lead.requestedBy.phones.push({
                    number: '',
                    extension: '',
                    type: this.phoneTypes[1],
                    order: this.phonesLength
                });
            },
            addEmail() {
                this.lead.requestedBy.emails.push({
                    email : '',
                    order: this.emailsLength
                });
            },
            removeContactsFromRequestedBy(index, arr) {
                arr.splice(index, 1);
                this.addFieldsToEdited('requestedBy');
            },
            getLeadAddress(data) {
                copyFieldsValueFromObjectToObject(data, this.lead.address);
                this.lead.address.type = ADDRESS_TYPES.MAIN;

                if (this.pageIsLoaded)
                    this.edited.address = this.lead.address;
            },
            getAppointmentAddress(data) {
                copyFieldsValueFromObjectToObject(data, this.lead.appointment);
                this.lead.address.type = ADDRESS_TYPES.APPOINTMENT;

                if (this.pageIsLoaded)
                    this.edited.appointment = this.lead.appointment;
            },
            partnerCustomLabel ({ first_name, last_name }) {
                return `${first_name} ${last_name}`
            },
            addFieldsToEdited(fields) {
                this.$nextTick(function() {
                    switch(fields) {
                        case 'requestedBy': {
                            this.edited.requestedBy = {
                                first_name: this.lead.requestedBy.first_name,
                                last_name: this.lead.requestedBy.last_name,
                                title: this.lead.requestedBy.title,
                                phones: [],
                                emails: this.lead.requestedBy.emails
                            };

                            const phones = cloneDeep(this.lead.requestedBy.phones);
                            removePhoneMask(phones);
                            this.edited.requestedBy.phones = phones;
                        } break;
                        case 'leadTypes': {
                            if (!this.lead.type) return;
                                this.edited.type_id = this.lead.type.id;
                        } break;
                        case 'materials': {
                            if (!this.lead.material) return;
                                this.edited.material_id = this.lead.material.id;
                        } break;
                        case 'homeTypes': {
                            if (!this.lead.homeType) return;
                                this.edited.home_type_id = this.lead.homeType.id;
                        } break;
                        case 'leadSources': {
                            if (!this.lead.source) return;
                            this.edited.source_id = this.lead.source.id;

                            // Clear Referral if source was changed from referral to not referral
                            if (! this.lead.source.has_children) {

                                if (this.selectedPartner) {
                                    this.selectedPartner = null;
                                    this.edited.partner_id = null;
                                }

                                if (this.referralName) {
                                    this.referralName = null;
                                    this.edited.referral_name = null;
                                }
                            }

                        } break;
                        case 'lead_partner': {
                            let partnerId = this.selectedPartner ? this.selectedPartner.id : null;
                            this.edited.partner_id = partnerId;
                        } break;
                        case 'referral_name':
                            this.edited.referral_name = this.referralName;
                            break;
                        case 'priorities': {
                            if (!this.lead.priority) return;
                                this.edited.priority_id = this.lead.priority.id;
                        } break;
                        case 'bidDueDate': {
                            this.edited.bid_due_date = this.lead.bid_due_date;
                        } break;
                    }
                });
            },
            updateLead() {
                this.cancel();
                this.$refs.save.disabled = true;
                this.edited.redirect_url = window.location.origin + VIEW_URL;
                this.edited['salesRabbit'] = this.salesRabbit;
                this.edited.trades = this.selectedTrades;

                api.Commercial.Lead.update(this.lead.id, this.edited)
                    .then(response => response.data.data)
                    .then((data) => {
                        clearArray(this.lead.trades);
                        this.selectedTrades.map((id) => {
                            const trade = this.trades.find(t => t.id === id);
                            if (trade != undefined) {
                                this.lead.trades.push(trade);
                            }
                        });

                        if (!this.edited.appointment && this.appointmentValidateRule == VALIDATION_REQUIRED) {
                            this.lead.appointment = null;
                        }

                        if (! this.isReferral) {
                            this.lead.partner = null
                        } else if (this.referralName) {
                            this.lead.partner = {referral_name: this.referralName}
                        } else if (this.selectedPartner) {
                            this.lead.partner = this.selectedPartner;
                        }

                        this.$store.dispatch('commercial/GET_COMMERCIAL_LEAD', this.lead.id)

                        this.edited = {};
                        this.notifySuccess('Lead is updated!');
                        this.$emit('close');
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                        this.$refs.save.disabled = false;
                    });
            },
            save(mode = true) {
                this.$children.forEach(vm => {
                    vm.$validator.validateAll();
                });

                this.$validator.validateAll().then(result => {
                    if (!result) {
                        const context = this;
                        validationErrorNotification(this.$validator, context);
                        return;
                    }

                    if (!Object.keys(this.edited).includes('address') ) {
                        this.updateLead();
                        return;
                    }

                    this.checkZipCodeArea(this.edited.address.zip)
                        .then(response => {
                            if (response) {
                                this.updateLead();
                            }
                        });
                });
            },
            cancel() {
                this.$store.commit('zipCodes/SET_OUT_OF_AREA', false);
            }
        },
        created() {
            this.getWorkItems();
            this.getPartners();
            this.setLeadInfo();

            if (!this.lead.appointment) {
                this.lead.appointment = {
                    address: '',
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                    lat: null,
                    lng: null,
                    type: ADDRESS_TYPES.APPOINTMENT
                };
            }
            this.appointmentValidateRule = VALIDATION_REQUIRED;

            if (this.lead.partner && this.lead.partner.referral_name) {
                this.referralName = this.lead.partner.referral_name;
            }

            if (this.lead.partner && this.lead.partner.id) {
                this.selectedPartner = this.lead.partner;
            }
        },
        mounted() {
            this.$nextTick(function() {
                this.pageIsLoaded = true;
            });
        }
    }
</script>
