<template>
    <div
        v-body-scroll-lock:reserveScrollBarGap="open && mScreen"
        :class="open ? 'is-notes-mask' : 'not-notes-mask'"
    >
        <button
            :class="
                ['open-notes s-align-5',
                { 'open-notes-squeeze' : squeeze },
                { 'mobile-close' : mScreen && open },
                { 'open-notes-index' : open }]
            "
            @click="open =! open"
            v-scroll="scrollListener"
        >
            <notes-icon v-if="!open"/>
            <close-icon size="15" v-else/>
            <span
                class="open-notes-count s-align-5"
                v-if="notes.length && !open"
            >
                {{ notes.length }}
            </span>
        </button>
        <div
            class="notes"
            v-if="open && !isFullModalOpen"
        >
            <div class="notes-head s-align-4">
                <button
                    v-if="openEditComment"
                    @click="cancelNote()"
                    class="transparent-button s-align-4"
                >
                    <arrow-icon
                        size="8"
                        class="i-white mt-1"
                        transform="180"
                    />
                    <span class="f-12-white ml-5">
                        Back to comments
                    </span>
                </button>
                <div
                    v-else
                    class="s-align-4"
                >
                    <notes-icon
                        size="17"
                        class="i-white"
                    />
                    <span class="ml-5">
                        Comments
                    </span>
                </div>
            </div>
            <div
                v-if="!openEditComment"
                class="notes-message column-start relative"
            >
                <div class="row-between row-baseline pt-8 pb-2">
                    <span class="f-14-black f-sbold">New Message</span>
                    <span
                        :class="[
                            newNoteLength > 2000 ? 'f-12-red' : 'f-12-grey'
                        ]"
                    >
                        {{ `${newNoteLength}/2000` }}
                    </span>
                </div>
                <text-editor
                    v-model="newNote"
                    placeholder="Type your comment here..."
                    toolbarName="notesToolbar"
                    maxLength="2000"
                />
                <button
                    class="send-note transparent-button"
                    @click="setNote"
                >
                    <send-icon
                        size="14"
                        class="i-white"
                        transform="0"
                    />
                </button>
                <!-- <div class="notes-panel row-end row-baseline">
                    <button class="icon-dark">
                        <attach-icon/>
                    </button>
                </div> -->
            </div>
            <div
                class="notes-list"
                v-if="notes.length && !openEditComment"
            >
                <perfect-scrollbar class="ps-grey w-100">
                    <div
                        class="notes-item"
                        v-for="(item, index) in notes"
                        :key="index"
                    >
                        <div class="row-between">
                            <div class="w-80 s-align-4">
                                <avatar
                                    :url="item.user ? item.user.thumbnail : ''"
                                    :name="item.user ? `${item.user.first_name} ${item.user.last_name}` : item.author"
                                    :border="false"
                                    size="35"
                                />
                                <div class="column-start pl-4 w-80">
                                    <span class="f-13-black f-sbold ellipsis">
                                        {{ item.user ? item.user.fullname : item.author }}
                                    </span>
                                    <span
                                        v-if="item.created_at === item.updated_at"
                                        class="f-11-grey"
                                    >
                                        {{ getCreatedDate(item.created_at) }}
                                    </span>
                                    <span
                                        v-else
                                        class="f-11-grey"
                                    >
                                        Updated {{ getCreatedDate(item.updated_at) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row-start mt-1 pl-45">
                            <!-- <span class="f-12-grey break-word w-100">{{ item.body }}</span> -->
                            <span
                                class="f-12-darkgrey break-word w-100"
                                v-html="item.body"
                            ></span>
                            <div
                                v-if="item.user && (item.user.id == userId || checkUserRoleAdmin)"
                                class="edit-comment-button-wrapper w-100 mt-1"
                            >
                                <button
                                    @click="editComment(item)"
                                    class="edit-comment-button transparent-button s-align-4 f-11-grey "
                                >
                                    <edit-icon
                                        size="11"
                                        class="mr-1 i-grey edit-comment-icon"
                                    />
                                    Edit comment
                                </button>
                            </div>
                        </div>
                    </div>
                 </perfect-scrollbar>
            </div>
            <div
                v-if="!notes.length && !openEditComment"
                class="notes-empty column-center t-center s-align-5"
            >
                <img
                    src="@/assets/images/nothing-in-notes.svg"
                    alt="Nothing to display in Comments"
                    class="mb-4"
                />
                <span class="f-14-darkgrey pt-8 l-spacing-02">
                    Nothing to display in Comments
                </span>
                <span class="f-12-grey l-spacing-02 pt-1">
                    No comments have been written <br/> in this lead yet
                </span>
            </div>
            <div
                v-if="openEditComment"
                class="notes-edit-message"
            >
                <div class="notes-message column-start relative">
                    <div class="row-between row-baseline pt-8 pb-2">
                        <div class="s-align-4">
                            <avatar
                                :url="editNote.user.thumbnail"
                                :name="`${editNote.user.first_name} ${editNote.user.last_name}`"
                                :border="false"
                                size="35"
                            />
                            <div class="column-start pl-4">
                                <span class="f-13-black f-sbold">
                                    {{ editNote.user.fullname }}
                                </span>
                                <span
                                    v-if="editNote.created_at === editNote.updated_at"
                                    class="f-11-grey"
                                >
                                    {{ getCreatedDate(editNote.created_at) }}
                                </span>
                                <span
                                    v-else
                                    class="f-11-grey"
                                >
                                    Updated {{ getCreatedDate(editNote.updated_at) }}
                                </span>
                            </div>
                        </div>
                        <span
                            :class="[
                                editNoteLength > 2000 ? 'f-12-red' : 'f-12-grey'
                            ]"
                        >
                            {{ `${editNoteLength}/2000` }}
                        </span>
                    </div>
                    <text-editor
                        v-model="editNote.body"
                        placeholder="Type your comment here..."
                        toolbarName="notesToolbar"
                        maxLength="2000"
                    />
                    <div class="edite-note-buttons s-align-5">
                        <button
                            class="cancel-edit-changes cancel-button"
                            @click="cancelNote()"
                            :disabled="disabled"
                        >
                            Cancel
                        </button>
                        <button
                            class="submit-edit-changes cancel-button ml-3"
                            @click="saveEditNote()"
                            :disabled="disabled"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Avatar } from '@/components';
    import api from '@/headers.js';
    import cloneDeep from 'lodash/cloneDeep';
    import { BASE_ENTITY_TYPES } from '@/constants';
    import { getContentWithoutBottomAndTopBrTag } from '@/helpers';
    import moment from 'moment/src/moment';

    export default {
        name: 'Notes',
        components: {
            Avatar,
            PrintIcon: () => import('@/icons/PrintIcon.vue'),
            EditIcon: () => import('@/icons/EditIcon.vue'),
            ArrowIcon: () => import('@/icons/ArrowIcon.vue'),
            CloseIcon: () => import('@/icons/CloseIcon.vue'),
            SendIcon: () => import('@/icons/SendIcon.vue'),
            AttachIcon: () => import('@/icons/AttachIcon.vue'),
            NotesIcon: () => import('@/icons/NotesIcon.vue'),
            TextEditor: () => import('@/components/TextEditor.vue'),
        },
        props: {
            entityId: {
                type: [String, Number],
                default: ''
            },
            entityType: {
                type: String,
                default: ''
            },
            entityCreatePage: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                open: false,
                newNote: '',
                squeeze: false,
                openEditComment: false,
                editNote: null,
                initialNote: null,
                disabled: false,
                BASE_ENTITY_TYPES: BASE_ENTITY_TYPES
            }
        },
        watch: {
            newNote(val) {
                let notesMessage = this.$el.querySelector('.notes-message').getBoundingClientRect();
                let notesList = this.$el.querySelector('.notes-list');
                let notesEmpty = this.$el.querySelector('.notes-empty');
                let notes = notesList ? notesList : notesEmpty;

                if (!this.mScreen) {
                    if (!!val) {
                        notes.style.height = `calc(100% - 50px - ${notesMessage.height}px)`;
                    } else {
                        notes.style.height = `calc(100% - 188.39px)`;
                    }
                }
            },
            open(val) {
                if (val && this.mScreen) {
                    let initialHeight = window.innerHeight;
                
                    window.addEventListener('resize', () => {
                        let windowHeight = window.innerHeight;
                        let notesList = this.$el.querySelector('.notes-list');
                        let notesEmpty = this.$el.querySelector('.notes-empty');
                        let notes = notesList ? notesList : notesEmpty;
                            
                        if (initialHeight > windowHeight) {
                            notes.style.height = '30%';
                        } else {
                            notes.style.height = 'calc(90% - 32.5px)';
                        }
                    })
                }
            }
        },
        computed: {
            notes() {
                return this.$store.getters['notes/getNotes'];
            },
            userId() {
                return this.$store.getters['user/getUserId'];
            },
            userRoles() {
                return this.$store.getters['user/getUserRoles'];
            },
            newNoteLength() {
                return this.newNote.replace(/<[^>]+>/g, '').length;
            },
            editNoteLength() {
                return this.editNote.body.replace(/<[^>]+>/g, '').length;
            },
            newNoteWithoutSpaceLength() {
                return this.newNote.replace(/<[^>]+>/g, '').trim().length;
            },
            editNoteWithoutSpaceLength() {
                return this.editNote.body.replace(/<[^>]+>/g, '').trim().length;
            },
            checkUserRoleAdmin() {
                return this.userRoles.some(el => el.name == 'admin');
            },
            isFullModalOpen() {
                return this.$store.getters['views/getFullModalCondition'];
            },
        },
        methods: {
            getCreatedDate(date) {
                if (moment(date).isValid()) {
                    return moment.utc(date).local().format('DD MMM YYYY hh:mm a');
                }

                return date;
            },
            setNote(event) {
                if (this.newNote == '' || !this.newNoteWithoutSpaceLength) {
                    this.notifyError('Note is Empty!');
                    return false;
                };

                if (this.newNoteLength > 2000) {
                    this.notifyError('Note must be no more than 2000 characters!');
                    return;
                }

                const note = {
                    body: getContentWithoutBottomAndTopBrTag(this.newNote),
                    entity_id: this.entityId,
                    entity_type: this.entityType
                };
                
                if (this.entityCreatePage) {
                    this.$emit('notes', note);
                } else {
                    this.$store.dispatch('notes/SEND_NEW_NOTE', note)
                        .catch(error => this.notifyRequestErrors(error));
                };

                this.newNote = '';
            },
            editComment(note) {
                this.openEditComment = true;
                this.initialNote = cloneDeep(note);
                this.editNote = note;
            },
            scrollListener: function (event, el) {  
                if (window.innerWidth < 1165) return false;

                this.squeeze = window.scrollY > 70;
            },
            saveEditNote() {
                this.disabled = true;

                if (this.editNote.body == '' || !this.editNoteWithoutSpaceLength) {
                    this.notifyError('Note is Empty!');
                    this.disabled = false;
                } else {
                    this.editNote.body = getContentWithoutBottomAndTopBrTag(this.editNote.body);
                    api.put(`/notes/${this.editNote.id}`, this.editNote)
                        .then(response => {
                            this.editNote.updated_at = response.data.data.updated_at;
                            this.notifySuccess('Note is Update!');
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.disabled = false;
                            this.openEditComment = false;
                        });
                }
            },
            cancelNote() {
                this.editNote.body = this.initialNote.body;
                this.openEditComment = false;
            }
        },
        destroyed() {
            this.$store.commit('notes/CLEAR_NOTE');
        }
    }
</script>
