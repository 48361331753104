<template>
    <full-modal
        class="representatives-modal"
        @close="$emit('close')"
    >
        <div slot="title">Contacts settings</div>
        <div slot="body">
            <div 
                class="w-100 pv-20"
                v-for="(representative, index) in localRepresentatives"
                :key="`representative-${index}`"
            >
                <div class="row-between row-baseline pb-4">
                    <p class="f-13-grey f-sbold uppercase ellipsis w-60">
                        {{ representative.title }}
                    </p>
                    <span
                        :class="[
                            'inline-flex f-sbold pointer',
                            {'blue-label f-11-white' : representative.entities[0].is_primary},
                            {'f-11-grey b-grey ph-4 pv-2 br-5' : !representative.entities[0].is_primary}
                        ]"
                        @click="changePrimaryStatus(index)"
                    >
                        Save as Primary contact
                    </span>
                </div>
                <div class="blue-box p-20">
                    <div class="w-100 pb-4">
                        <multiselect
                            :id="`representative-contact-type-${index}`"
                            :options="contactTypeTitles"
                            :showLabels="false"
                            placeholder="Contact type"
                            tagPlaceholder="Press enter to create a contact type"
                            v-model="representative.title"
                            @tag="addNewContactType($event, index)"
                            :name="`representative-contact-type-${index}`"
                            :multiple="false"
                            :taggable="true"
                            :allow-empty="true"
                            :searchable="true"
                            :show-labels="false"
                            v-validate="`${validation}`"
                            data-name="contact-type"
                            :class="[
                                errors.has(`representative-contact-type-${index}`) 
                                && !representative.title 
                                ? 'multiselect-danger' : ''
                            ]"
                        />
                    </div>
                    <div class="row-start">
                        <div class="lg-6 sm-12 pr-2 pb-4 pr-sm-0">
                            <form-input 
                                label="First name"
                                :class="[{ 'form-input-danger' : errors.has(`representative-first-name-${index}`) }]"
                            >
                                <input 
                                    type="text"
                                    ref="name"
                                    :name="`representative-first-name-${index}`"
                                    :id="`representative-first-name-${index}`"
                                    class="form-input-field"
                                    placeholder="First name"
                                    v-input-style="representative.first_name"
                                    v-model="representative.first_name"
                                    v-validate="{ 
                                        required: validation ? true : false, 
                                        max: 250, 
                                        regex: titleRegex 
                                    }"
                                />
                            </form-input>
                        </div>
                        <div class="lg-6 sm-12 pl-2 pb-4 pl-sm-0">
                            <form-input 
                                label="Last name"
                                :class="[{ 'form-input-danger' : errors.has(`representative-last-name-${index}`) }]"
                            >
                                <input 
                                    type="text" 
                                    :id="`representative-last-name-${index}`"
                                    :name="`representative-last-name-${index}`" 
                                    class="form-input-field"
                                    placeholder="Last name"
                                    v-input-style="representative.last_name"
                                    v-model="representative.last_name"
                                    v-validate="{ 
                                        required: validation ? true : false, 
                                        max: 250, 
                                        regex: titleRegex 
                                    }"
                                />
                            </form-input>
                        </div>
                    </div>
                    <div 
                        class="s-align-4 w-100 pb-4"
                        v-for="(phone, phoneIndex) in representative.phones"
                        :key="`representative-phone-${phoneIndex}`"
                    >
                        <div class="static-col-8 column-start">
                            <form-input
                                label="Phone"
                                class="form-input-with-select"
                                :class="[{ 'form-input-danger' : errors.has(`representative-phone-number-${index}-${phoneIndex}`) }]"
                            >
                                <input 
                                    type="tel"
                                    :id="`representative-phone-number-${index}-${phoneIndex}`"
                                    :name="`representative-phone-number-${index}-${phoneIndex}`" 
                                    class="form-input-field"
                                    placeholder="Phone"
                                    v-input-style="phone.number"
                                    v-model="phone.number"
                                    v-mask="'(###) ###-####'"
                                    v-validate="`${validation}|min:14`"
                                />
                                <multiselect
                                    :options="phoneTypes"
                                    :showLabels="false"
                                    :max-height="200"
                                    placeholder="Type"
                                    v-model="phone.type"
                                    :name="`representative-phone-type-${index}-${phoneIndex}`"
                                    :data-name="`representative-phone-type-${index}-${phoneIndex}`"
                                    v-validate="validation"
                                    :class="['capitalize', { 'multiselect-danger' : errors.has(`representative-phone-type-${index}-${phoneIndex}`) }]"
                                    :searchable="false"
                                />    
                            </form-input>
                            <form-input
                                label="Extension"
                                class="form-input mt-2"
                                :class="[{ 'form-input-danger' : errors.has(`representative-phone-extension-${index}-${phoneIndex}`) }]"
                            >
                                <input 
                                    type="text"
                                    :id="`representative-phone-extension-${index}-${phoneIndex}`"
                                    :name="`representative-phone-extension-${index}-${phoneIndex}`" 
                                    class="form-input-field"
                                    placeholder="Extension"
                                    v-model="phone.extension"
                                    v-validate="'integer'"
                                />   
                            </form-input>
                        </div>
                        <button 
                            class="service-add-button ml-5"
                            v-if="phoneIndex > 0"
                            @click="removeItem(index, representative.phones, 'phone', phoneIndex)"
                        >
                            <trash-icon/>
                        </button>
                        <button 
                            class="service-secondary-button ml-5"   
                            v-if="phoneIndex < 2 && phoneIndex == representative.phones.length - 1"
                            @click="addPhoneNumber(index)"
                        >
                            <add-icon size="10"/>
                        </button>
                    </div>
                    <div 
                        class="s-align-4 w-100 pb-4"
                        v-for="(email, emailIndex) in representative.emails"
                        :key="`representative-email-${emailIndex}`"
                    >
                        <div class="static-col-8">
                            <form-input 
                                label="E-mail"
                                :class="[{ 'form-input-danger' : errors.has(`representative-email-${index}-${emailIndex}`) }]"
                            >
                                <input 
                                    type="text"
                                    :id="`representative-email-${index}-${emailIndex}`"
                                    :name="`representative-email-${index}-${emailIndex}`"
                                    class="form-input-field"
                                    placeholder="E-mail"
                                    v-input-style="email.email"
                                    v-model="email.email"
                                    v-validate="`${validation}|email`"
                                />
                            </form-input>
                        </div>
                        <button 
                            class="service-add-button ml-5"
                            v-if="emailIndex > 0"
                            @click="removeItem(index, representative.emails, 'email', emailIndex)"
                        >
                            <trash-icon/>
                        </button>
                        <button 
                            class="service-secondary-button ml-5"   
                            v-if="emailIndex < 2 && emailIndex == representative.emails.length - 1"
                            @click="addEmail(index)"
                        >
                            <add-icon size="10"/>
                        </button>
                    </div>
                    <div 
                        class="row-between pt-8 bt-grey mt-5"
                        v-if="!representative.entities[0].is_primary"
                    >
                        <p class="f-12-grey">Remove this contact?</p>
                        <button 
                            class="transparent-button s-align-4"
                            @click="removeItem(index, localRepresentatives, 'representative')"
                        >
                            <trash-icon class="i-grey"/>
                            <span class="f-12-grey f-sbold pl-1">Remove</span>
                        </button>
                    </div>
                </div>
            </div>
            <div 
                class="w-100 pv-20"
                v-if="representativesLength < 10"
            >
                <button
                    class="transparent-button"
                    @click="addNewRepresentative"
                >
                    <add-icon 
                        size="10"
                        class="i-blue"
                    />
                    <span class="f-13-blue pl-1">Add new contact</span>
                </button>
            </div>
        </div>
        <div slot="footer">
            <button
                class="add-button mr-4"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                @click="saveItem"
                ref="save"
            >
                {{ representativesLength ? 'Save' : 'Create' }}  
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, FormInput } from '@/ui';
    import { mask } from 'vue-the-mask';
    import { TrashIcon, AddIcon } from '@/icons';
    import { removePhoneMask, validationErrorNotification } from '@/helpers';
    import { COMMERCIAL_PHONE_TYPES, NOT_ALLOWED_CHARACTERS } from '@/constants';
    import Multiselect from 'vue-multiselect';
    import cloneDeep from 'lodash/cloneDeep';
    import api from '@/headers.js';

    const TYPE_CONTACT_ID = 1;

    export default {
        name: 'RepresentativeModal',
        components: {
            FullModal,
            FormInput,
            Multiselect,
            TrashIcon,
            AddIcon
        },
        props: {
            representatives: {
                type: Array,
                required: true,
                default: () => []
            },
            validation: {
                type: String,
                default: 'required'
            },
            typeContact: {
                type: String,
                required: false,
                default: ''
            }
        },
        data() {
            return {
                phoneTypes: COMMERCIAL_PHONE_TYPES,
                contactType: [],
                localRepresentatives: [],
                exists_on_site_contacts: false,
                passingContactType: '',
                representativeUnit: {
                    title: '',
                    first_name: '',
                    last_name: '',
                    emails: [{ email: '', order: 0 }],
                    phones: [{ number: '', order: 0, type: COMMERCIAL_PHONE_TYPES[1] }],
                    entities: [{ is_primary: 0 }]
                },
                titleRegex: NOT_ALLOWED_CHARACTERS
            }
        },
        directives: {
            mask
        },
        computed: {
            representativesLength() {
                return this.representatives.length;
            },
            contactTypeTitles() {
                let types = [];
                if (this.contactType.length) {
                    this.contactType.forEach((item) => {
                        types.push(item.title);
                    })
                }
                return types;
            }
        },
        methods: {
            addPhoneNumber(index) {
                this.localRepresentatives[index].phones.push({
                    number: '', 
                    order: this.localRepresentatives[index].phones.length,
                    type: this.phoneTypes[1]
                });
            },
            addEmail(index) {
                this.localRepresentatives[index].emails.push({
                    email: '', 
                    order: this.localRepresentatives[index].emails.length
                });
            },
            addNewRepresentative(result) {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('All fields are required.');
                        return;
                    }

                    let contactItem = cloneDeep(this.representativeUnit);
                    this.localRepresentatives.push(...[contactItem]);
                    this.moveToAddedContact();
                });
            },
            removeItem(index, arr, type,  itemIndex = null) {
                if (this.localRepresentatives.length) {
                    switch (type) {
                        case 'email':
                            this.localRepresentatives[index].emails.splice(itemIndex, 1);
                            break;
                        case 'phone':
                            this.localRepresentatives[index].phones.splice(itemIndex, 1);
                            break;
                        case 'representative':
                            const representativeId = this.localRepresentatives[index].id;
                            if (representativeId) {
                                this.$emit('remove', representativeId);
                            }
                            arr.splice(index, 1);
                            break;
                    }
                }
            },
            changePrimaryStatus(index) {
                this.localRepresentatives.forEach((item, i) => {
                    if (index == i) {
                        item.entities[0].is_primary = 1;
                    } else {
                        item.entities[0].is_primary = 0;
                    }
                });
            },
            getContactTypes() {
                api.get(`/commercial/contact-types`, { display: TYPE_CONTACT_ID })
                    .then(response => response.data.data)
                    .then(data => {
                        this.contactType = data;
                    })
                    .catch(error => this.notifyRequestErrors(error));
            },
            addNewContactType(e, id){
                this.localRepresentatives[id].title = e;
            },
            addNewRepresentativeWithContactType() {
                let contactItem = cloneDeep(this.representativeUnit);
                contactItem.title = this.passingContactType;
                this.localRepresentatives.push(contactItem);
                this.passingContactType = '';
                this.setFirstContactAsPrimary();
            },
            setFirstContactAsPrimary() {
                const isPrimary = this.localRepresentatives.findIndex(i => i.entities[0].is_primary);
                
                if (isPrimary == -1) {
                    this.localRepresentatives[0].entities[0].is_primary = 1;
                }
            },
            saveItem(result) {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        const context = this;
                        validationErrorNotification(this.$validator, context);

                        let domRect;
                        domRect = document.getElementById(this.$validator.errors.items[0].field);

                        // scroll to the first mistake
                        domRect != null || domRect != undefined 
                            ? domRect.scrollIntoView({ behavior: 'smooth', block: 'start'}) 
                            : '';
                        return;
                    }

                    const representatives = cloneDeep(this.localRepresentatives);

                    representatives.forEach((item, index) => {                        
                        removePhoneMask(item.phones);
                    });

                    this.$emit('save', representatives);
                });
            },
            moveToAddedContact() {
                let field = this.$refs.name;

                setTimeout(() => {
                    if (field.length) {
                        for (let i = 0; i < field.length; i++) {
                            if (!field[i].value) {
                                field[i].focus();
                                field[i].scrollIntoView({ behavior: 'smooth', block: 'start'});
                                break;
                            }
                        }
                    }
                }, 200);
            }
        },
        created() {
            this.getContactTypes();
            this.passingContactType = this.typeContact;

            if (this.representativesLength) {
                this.localRepresentatives = cloneDeep(this.representatives);
                this.setFirstContactAsPrimary();
                this.localRepresentatives.forEach((representative, index) => {
                    if (!representative.phones.length) {
                        this.addPhoneNumber(index);
                    }

                    if (!representative.emails.length) {
                        this.addEmail(index);
                    }
                });  
            }

            if (this.typeContact) {
                this.addNewRepresentativeWithContactType();
            }
        },
        mounted() {
            this.moveToAddedContact();
        }
    }
</script>
