<template>
    <full-modal @close="$emit('close')">
        <div slot="title">
            {{ this.existing ? 'Edit' : 'Add new' }} vendor
        </div>
        <div slot="body">
            <p class="f-14-asphalt f-sbold pb-4">Information</p>
            <div class="row-between pb-30">
                <div class="static-col-12 pb-4">
                    <form-input 
                        label="Name"
                        :class="[{ 'form-input-danger' : errors.has('vendor_name') }]"
                    >
                        <input 
                            type="text" 
                            name="vendor_name" 
                            id="vendor_name"
                            class="form-input-field"
                            placeholder="Name"
                            v-input-style="vendor.name"
                            v-model="vendor.name"
                            v-validate="`required|max:250`"
                        />
                    </form-input>
                </div>
                <div
                    v-if="!isCustomVendor"
                    class="lg-6 sm-12 pb-4 pr-2 pr-sm-0"
                >
                    <form-input 
                        label="Short Name"
                        :class="[{ 'form-input-danger' : errors.has('short_name') }]"
                    >
                        <input 
                            type="text" 
                            name="short_name" 
                            id="short_name"
                            class="form-input-field"
                            placeholder="Short Name"
                            v-input-style="vendor.short_name"
                            v-model="vendor.short_name"
                            v-validate="`required|max:250`"
                        />
                    </form-input>
                </div>
                <div
                    v-if="!isCustomVendor"
                    class="lg-6 sm-12 pb-4 pl-2 pl-sm-0"
                >
                    <form-input 
                        label="Mailing name"
                        :class="[{ 'form-input-danger' : errors.has('mailing_name') }]"
                    >
                        <input 
                            type="text" 
                            name="mailing_name" 
                            id="mailing_name"
                            class="form-input-field"
                            placeholder="Mailing name"
                            v-input-style="vendor.mailing_name"
                            v-model="vendor.mailing_name"
                            v-validate="`required|max:250`"
                        />
                    </form-input>
                </div>
            </div>
            <p class="f-14-asphalt f-sbold pb-4">Locations info</p>
            <div class="column-start w-100 pb-30">
                <div class="static-col-12 pb-4">
                    <form-input 
                        label="Email"
                        :class="[{ 'form-input-danger' : errors.has('vendor_email') }]"
                    >
                        <input 
                            type="text" 
                            name="vendor_email" 
                            id="vendor_email"
                            class="form-input-field"
                            placeholder="Email"
                            v-input-style="vendor.email"
                            v-model="vendor.email"
                            v-validate="`email`"
                        />
                    </form-input>
                </div>
                <div
                    v-if="!isCustomVendor"
                    class="static-col-12 pb-8"
                >
                    <form-input 
                        label="Account"
                        :class="[{ 'form-input-danger' : errors.has('vendor_account') }]"
                    >
                        <input 
                            type="text" 
                            name="vendor_account" 
                            id="vendor_account"
                            class="form-input-field"
                            placeholder="Account"
                            v-input-style="vendor.account_number"
                            v-model="vendor.account_number"
                            maxlength="26"
                            v-validate="`required|max:25`"
                        />
                    </form-input>
                </div>
                <div class="static-col-12">
                    <checkbox-button
                        color="blue"
                        label="Active vendor"
                        class="f-13-black"
                    >
                        <input
                            type="checkbox"
                            name="active"
                            v-model="vendor.is_active"
                        />
                    </checkbox-button>
                </div>
            </div>
            <template v-if="!isCustomVendor">
                <p class="f-14-asphalt f-sbold pb-4">Address</p>
                <div class="w-100 pb-30">
                    <address-box 
                        type="address"
                        :key="'address'"
                        :existing="vendor.address"
                        @address="getVendorAddress"
                    />
                </div>
            </template>
        </div>
        <div slot="footer">
            <button
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                @click="save"
                :disabled="requestIsGoing"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FormInput, FullModal, CheckboxButton } from '@/ui';
    import AddressBox from '@/components/AddressBox.vue';
    import cloneDeep from 'lodash/cloneDeep';
    import isEqual from 'lodash/isEqual';
    import api from '@/headers.js';
    import { validationErrorNotification } from '@/helpers';

    export default {
        name: 'AddVendorModal',
        components: {
            FormInput, 
            FullModal,
            CheckboxButton,
            AddressBox
        },
        props: {
            currentType: {
                type: Number,
                required: true
            },
            existing: {
                type: Object,
                required: false
            }
        },
        data() {
            return {
                vendor: {
                    name: '',
                    short_name: '',
                    account_number: '',
                    mailing_name: '',
                    email: '',
                    is_active: true,
                    address: {
                        address: '',
                        street: '',
                        city: '',
                        state: '',
                        zip: '',
                        lat: null,
                        lng: null
                    }
                },
                requestIsGoing: false
            }
        },
        computed: {
            isCustomVendor() {
                return this.currentType === 1;
            }
        },
        methods: {
            getVendorAddress(data) {
                this.vendor.address = data;
            },
            save() {
                // validate nested components
                this.$children.forEach(vm => {
                    vm.$validator.validateAll();
                });

                this.$validator.validateAll()
                    .then(result => {
                        if (!result || this.$validator.errors.items.length) {
                            const context = this;
                            validationErrorNotification(this.$validator, context);
                            return;
                        }

                        if (result) {
                            let params = {
                                name: this.vendor.name,
                                is_active: this.vendor.is_active,
                                is_custom: this.currentType
                            };

                            params.email = this.vendor.email || null;

                            if (!this.isCustomVendor) {
                                params.address = this.vendor.address;
                                params.short_name = this.vendor.short_name;
                                params.mailing_name = this.vendor.mailing_name;
                                params.account_number = this.vendor.account_number;
                            }

                            if (this.existing) {
                                params.id = this.existing.id;
                                if (this.existing.account_number === this.vendor.account_number) {
                                    delete params.account_number;
                                }
                            }

                            const method = this.existing ? 'put' : 'post';

                            this.requestIsGoing = true;
                            api[method](`/vendors/${this.existing ? this.existing.id : ''}`, params)
                                .then(response => {
                                    if (this.existing) {
                                        this.$emit('onUpdate', {...this.existing, ...params});
                                    } else {
                                        this.$emit('onCreate', response.data.data);
                                    }
                                    
                                    this.notifySuccess(`Vendor ${this.existing ? 'updated' : 'created'}`);
                                })
                                .catch(error => {
                                    this.notifyRequestErrors(error);
                                })
                                .finally(() => {
                                    this.requestIsGoing = false;
                                });
                        }
                    });
            }
        },
        created() {
            if (this.existing) {
                this.vendor = cloneDeep(this.existing);
            }
        }
    }
</script>