<template>
    <label class="select-input">
        <slot name="default"></slot>
        <span class="select-input-label">
            <mark-icon size="17"/>
        </span>
    </label>
</template>

<script>
    import { MarkIcon } from '@/icons';

    export default {
        name: 'SelectInput',
        components: {
            MarkIcon
        }
    }
</script>
