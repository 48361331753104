<template>
    <svg
        viewBox="0 0 20 20"
        :width="size"
        :height="size"
        class="multiselect-icon"
    >
        <g transform="matrix(0.20074070990085605, 0, 0, 0.20074070990085605, 86.08073425292972, -43.92073694864909)">
            <path d="M-424,301c0-5,4-9,9-9s9,4,9,9s-4,9-9,9S-424,306-424,301z M-415,244c5,0,9-4,9-9s-4-9-9-9s-9,4-9,9S-420,244-415,244z M-415,277c5,0,9-4,9-9s-4-9-9-9s-9,4-9,9S-420,277-415,277z M-385,241h45c3.3,0,6-2.7,6-6s-2.7-6-6-6h-45c-3.3,0-6,2.7-6,6S-388.3,241-385,241z M-385,274h45c3.3,0,6-2.7,6-6s-2.7-6-6-6h-45c-3.3,0-6,2.7-6,6S-388.3,274-385,274z M-385,307h45c3.3,0,6-2.7,6-6s-2.7-6-6-6h-45c-3.3,0-6,2.7-6,6S-388.3,307-385,307z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'MultiselectIcon',
        props: {
            size: {
                type: [Number, String],
                default: 16
            }
        }
    }
</script>
