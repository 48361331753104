import { SHOW_PANEL, FULL_MODAL_OPEN, SCREEN_SIZE, TOGGLE_CONTEXT_MENU, FULL_SCREEN_MODAL_OPEN } from '../actions/views';
import { SCREEN_SIZES } from '@/constants';

export default {
    namespaced: true,
    state: {
        showPanel: false,
        fullModalOpen: false,
        fullScreenModalOpen: false,
        screenSize: '',
        inPixels: 0,
        openContextMenu: null
    },
    getters: {
        getViewStatus: state => state.showPanel,
        getFullModalCondition: state => state.fullModalOpen,
        getFullScreenModalCondition: state => state.fullScreenModalOpen,
        getScreenSize: state => state.screenSize,
        getScreenSizeInPixels: state => state.inPixels,
        getOpenContextMenu: state => state.openContextMenu
    },
    mutations: {
        [TOGGLE_CONTEXT_MENU] (state, payload) {
            state.openContextMenu = payload;
        },
        [SHOW_PANEL] (state, payload) {
            state.showPanel = payload
        },
        [FULL_MODAL_OPEN] (state, payload) {
            state.fullModalOpen = payload
        },
        [FULL_SCREEN_MODAL_OPEN] (state, payload) {
            state.fullScreenModalOpen = payload
        },
        [SCREEN_SIZE] (state, payload) {
            state.inPixels = payload;

            if (payload <= 319) {
                state.screenSize = SCREEN_SIZES.NOT_SUPPORT
            } else if (payload <= 767) {
                state.screenSize = SCREEN_SIZES.MOBILE
            } else if (payload > 767 && payload <= 900) {
                state.screenSize = SCREEN_SIZES.TABLET
            } else if (payload > 900 && payload <= 1165) {
                state.screenSize = SCREEN_SIZES.NETBOOK
            } else {
                state.screenSize = SCREEN_SIZES.DESKTOP
            } 
        }
    }
}