<template>
    <svg
        viewBox="0 0 8 8"
        :width="size"
        :height="size"
        class="packats-icon"
    >
        <path d="M0.266667 8H7.73333C7.88267 8 8 7.88267 8 7.73333V0.266667C8 0.117333 7.88267 0 7.73333 0H5.86667V2.4C5.86667 2.496 5.81333 2.58667 5.728 2.63467C5.69067 2.656 5.648 2.66667 5.6 2.66667C5.54667 2.66667 5.49867 2.65067 5.45067 2.624L4 1.65333L2.54933 2.61867C2.46933 2.672 2.36267 2.67733 2.27733 2.62933C2.18667 2.58667 2.13333 2.496 2.13333 2.4V0H0.266667C0.117333 0 0 0.117333 0 0.266667V7.73333C0 7.88267 0.117333 8 0.266667 8Z"/>
        <path d="M4.14933 1.10933L5.33333 1.89867V0H2.66667V1.904L3.85067 1.11467C3.94133 1.05067 4.05867 1.05067 4.14933 1.10933Z"/>
    </svg>
</template>

<script>
    export default {
        name: 'PackatsIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>
