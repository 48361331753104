var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-input-textarea-wrapper w-100"},[_c('div',{ref:"wrapper",class:[
            'form-input-textarea hidden relative w-100',
            _vm.onSubmit === undefined ? 'no-control-btns' : '',
            { 'transparent-bg' : _vm.transparentBg }
        ],on:{"click":_vm.focusOnInput}},[_vm._t("default"),(_vm.label)?_c('span',{class:[
                'form-input-label w-100 t-left',
                { 'transparent-bg' : _vm.transparentBg }
            ]},[_vm._v("\n            "+_vm._s(_vm.label)+"\n        ")]):_vm._e()],2),_c('div',{staticClass:"form-input-footer row-between mt-5",on:{"click":_vm.focusOnInput}},[(_vm.onSubmit !== undefined)?_c('div',{staticClass:"form-input-control s-align-5"},[_c('button',{staticClass:"cancel-changes cancel-button",attrs:{"id":"cancelTextAreaChanges","disabled":_vm.disabled},on:{"click":_vm.onCancel}},[_vm._v("\n                Cancel\n            ")]),_c('button',{staticClass:"submit-changes cancel-button ml-3",attrs:{"id":"submitTextAreaChanges","disabled":_vm.disabled},on:{"click":_vm.onSubmit}},[_vm._v("\n                Save\n            ")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }