<template>
    <div class="customer-info w-box column-start">
        <div class="row-between pb-8">
            <div class="column-start sm-12 ls-sm-9">
                <h1 class="f-24-asphalt f-sbold">Customer (Responsible billing party)</h1>
                <content-item   
                    v-if="customer.creator"                             
                    title="Created by"
                    :content="customer.creator.fullname"
                    class="pt-2"
                />
            </div>
            <div class="s-align-6 mt-sm-1 sm-12 ls-sm-3">
                <button 
                    class="edit-button"
                    @click="$emit('edit')"
                >
                    Edit
                </button>
            </div>
        </div>
        <div class="row-start">
            <div class="column-start lg-3 md-6 sm-12 pr-8 pb-20">
                <span class="f-12-grey f-sbold">Company Name</span>
                <span class="f-14-black pt-2">{{ customer.name }}</span>
            </div>
            <div 
                class="column-start lg-3 md-6 sm-12 pr-8 pb-20"
                v-if="customer.management_company"
            >
                <span class="f-12-grey f-sbold">C/O</span>
                <span class="f-14-black pt-2">{{ customer.management_company }}</span>
            </div>
            <div class="column-start lg-3 md-6 sm-12 pr-8 pb-20">
                <span class="f-12-grey f-sbold">Mailing address</span>                
                <a 
                    :href="getGoogleMapLink(customer.addresses.main[0].lat, customer.addresses.main[0].lng)"
                    :class="[
                        'f-14-blue pt-2',
                        {'none-events' : !getGoogleMapLink(customer.addresses.main[0].lat, customer.addresses.main[0].lng)}
                    ]"
                    target="_blank"
                >
                    {{ getAddress(customer.addresses.main[0]) }}
                    <link-icon 
                        v-if="getGoogleMapLink(customer.addresses.main[0].lat, customer.addresses.main[0].lng)"
                        class="i-blue"
                    />
                </a>
            </div>
            <div 
                class="column-start lg-3 md-6 sm-12 pr-8 pb-20"
                v-if="customer.addresses.billing.length"
            >
                <span class="f-12-grey f-sbold">Billing address</span>                
                <a 
                    :href="getGoogleMapLink(customer.addresses.billing[0].lat, customer.addresses.billing[0].lng)"
                    :class="[
                        'f-14-blue pt-2',
                        {'none-events' : !getGoogleMapLink(customer.addresses.billing[0].lat, customer.addresses.billing[0].lng)}
                    ]"
                    target="_blank"
                >
                    {{ getAddress(customer.addresses.billing[0]) }}
                    <link-icon 
                        v-if="getGoogleMapLink(customer.addresses.billing[0].lat, customer.addresses.billing[0].lng)"
                        class="i-blue"
                    />
                </a>
            </div>
        </div>
        <div class="column-static w-100 pt-20 bt-grey">
            <div 
                class="row-between pointer" 
                @click="toggleCustomerDetailsIsOpen"
            >
                <div class="w-75">
                    <p class="f-16-asphalt f-sbold">Information</p>
                </div>
                <div class="w-25 s-align-6">
                    <arrow-icon 
                        size="11"
                        :transform="customerDetailsIsOpen ? 270 : 90"
                        class="i-darkgrey"
                    />
                </div>
            </div>
            <div 
                class="column-static w-100 pt-8"
                v-if="customerDetailsIsOpen"
            >
                <div class="row-start mb-30">
                    <div 
                        class="column-start lg-3 md-4 sm-12 mb-sm-1"
                        v-if="customerPhonesLength"
                    >
                        <span class="f-12-grey f-sbold">
                            {{ customerPhonesLength > 1 ? 'Phones' : 'Phone' }}
                        </span>
                        <span class="f-14-black pt-2"
                            v-for="(phone, index) in customer.phones"
                            :key="`phone-${index}`"
                        >
                            <make-call-from-number-button
                                :phone="phone"
                                :showPhoneType="false"
                                :entityType="ENTITY_TYPES.COMMERCIAL_CUSTOMER"
                                :entityId="customerId"
                            />
                            <span v-if="phone.extension">
                                <br />
                                <span class="f-12-grey f-sbold mr-5">Phone extension:</span>
                                <span>{{ phone.extension }}</span>
                            </span>
                        </span>
                    </div>
                    <div 
                        class="column-start lg-3 md-4 sm-12 pr-4 mb-sm-1"
                        v-if="customerEmailsLength"
                    >
                        <span class="f-12-grey f-sbold">
                            {{ customerEmailsLength > 1 ? 'Emails' : 'Email' }}
                        </span>
                        <span class="f-14-black pt-2"
                            v-for="(email, index) in customer.emails"
                            :key="`email-${index}`"
                        >
                            {{ email.email }}
                        </span>
                    </div>
                    <div 
                        class="column-start lg-6 md-4 sm-12 pr-4"
                        v-if="customerBillingEmailsLength"
                    >
                        <span class="f-12-grey f-sbold">Billing email</span>
                        <span class="f-14-black pt-2">{{ customer.billing_emails[0].email }}</span>
                    </div>
                    <div 
                        class="w-100"
                        v-if="!customerPhonesLength && !customerEmailsLength && !customerBillingEmailsLength"
                    >
                        <p class="f-12-grey">
                            No additional information has been added to the customer. 
                            If you’d like to add details, click on 
                            <span class="f-sbold">"Edit"</span> button.
                        </p>
                    </div>
                </div>
                <template v-for="(section, index) in customFields.sections">
                    <div 
                        v-if="section.fields.length"
                        :key="index"
                        class="w-100 mb-8"
                    >
                        <div class="row-between row-baseline mb-2">
                            <p class="sm-7 f-16-asphalt f-sbold">{{ section.name }}</p>
                            <button 
                                @click="showCustomFieldsModal(index)"
                                class="edit-button"
                            >
                                Edit Section
                            </button>
                        </div>
                        <div class="row-start">
                            <template 
                                v-if="section.type === CUSTOM_FIELDS_SECTION_TYPES.ADDRESS"
                            >
                                <div class="lg-6 sm-12">
                                    <p class="f-12-grey mr-2 break-all">
                                        {{ `${section.fields[0].label}: ` }}
                                        <span class="f-12-darkgrey 11">
                                            {{ getCustomFieldsAddress(section.fields[0].values) }}
                                        </span>
                                    </p>
                                </div>
                                <div 
                                    v-if="checkIfSecondAddressShow(section.fields[0].parameters)"
                                    class="lg-6 sm-12 mt-sm-1"
                                >
                                    <p class="f-12-grey mr-2 break-all">
                                        {{ `${section.fields[1].label}: ` }}
                                        <span class="f-12-darkgrey 11">
                                            {{ getCustomFieldsAddress(section.fields[1].values) }}
                                        </span>
                                    </p>
                                </div>
                            </template>
                            <div
                                v-else 
                                v-for="(field, index) in section.fields"
                                :key="index"
                                :class="[
                                    'pv-3',
                                    field.type.name === CUSTOM_FIELDS_TYPES.MULTILINE
                                    ? 'lg-12' : 'lg-3 md-4 sm-12' 
                                ]"
                            >
                                <p class="f-12-grey mr-2 break-all">
                                    {{ `${field.label}: ` }}
                                    <span 
                                        v-if="field.type.name === CUSTOM_FIELDS_TYPES.DATE"
                                        class="f-12-darkgrey "
                                    >
                                        {{ field.values.length ? field.values[0].value : '-' | date }}
                                    </span>
                                    <span 
                                        v-else-if="field.type.name === CUSTOM_FIELDS_TYPES.DATE_TIME"
                                        class="f-12-darkgrey "
                                    >
                                        {{ field.values.length ? field.values[0].value : '-' | createdAt }}
                                    </span>
                                    <span 
                                        v-else-if="field.type.name === CUSTOM_FIELDS_TYPES.MULTILINE"
                                        class="f-12-darkgrey preserve-whitespaces"
                                    ><br>{{ field.values.length ? field.values[0].value : '-' }}
                                    <!-- ↑↑↑ don't format code, needed to correct work css property!  -->
                                    </span>
                                    <span 
                                        v-else-if="field.type.name === CUSTOM_FIELDS_TYPES.MULTISELECT
                                            || field.type.name === CUSTOM_FIELDS_TYPES.PICK_LIST"
                                        class="f-12-darkgrey"
                                    >
                                        {{ getMultiselectValue(field) }}
                                    </span>
                                    <span 
                                        v-else
                                        class="f-12-darkgrey"
                                    >
                                        {{ field.values.length ? field.values[0].value : '-' }}
                                        <p v-if="field.type.name === CUSTOM_FIELDS_TYPES.PHONE">
                                            Ext - {{ field.values.length && field.values[0].object_value 
                                                && field.values[0].object_value.extension 
                                                ? field.values[0].object_value.extension
                                                : '' }}
                                        </p>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
                <representatives 
                    id="representative_customer"
                    :options="[]"
                    :existing="customer.representatives"
                    :forRemove="representativesForRemove"
                    :moduleMode="'update'"
                    :saveImmediately="true"
                    :entityId="customer.id"
                    :showEmail="true"
                    :loadRepresentatives="loadRepresentatives"
                    :representativesForEntity="ENTITY_TYPES.COMMERCIAL_CUSTOMER"
                    @save="saveRepresentatives"
                    sectionName="Point of contacts"
                />
                <div 
                    class="w-100 pt-30"
                    v-if="customer.notes"
                >
                    <p class="f-16-asphalt f-sbold">Customer notes</p>
                    <div class="w-100 pt-2">
                        <p class="f-14-black">{{ customer.notes }}</p>
                    </div>
                </div>
            </div>
        </div>
        <edit-job-custom-fields-modal
            v-if="editCustomFieldsModalShow"
            :id="customerId"
            :customFieldsSection="selectedCustomFieldsSection"            
            @close="closeCustomFieldsModal"
            @refresh="getCustomFields"
        />
    </div>
</template>

<script>
    import { ArrowIcon, LinkIcon } from '@/icons';
    import { Avatar, ContentItem, Representatives } from '@/components';
    import cloneDeep from 'lodash/cloneDeep';
    import ContactList from '@/components/CommercialJobs/ContactList.vue';
    import MakeCallFromNumberButton from '@/components/Telephony/MakeCallFromNumberButton.vue';
    import { MODEL_CLASSES, CUSTOM_FIELDS_TYPES, CUSTOM_FIELDS_SECTION_TYPES, ENTITY_TYPES } from '@/constants';
    import api from '@/headers.js';
    import { showFullAddress, processingCustomFields } from '@/helpers';
    import { customFieldInPrimarySection } from '@/components/CreateLead/mixins/customFieldInPrimarySection.js';
    import { getGoogleMapLink } from '@/helpers';

    export default {
        name: 'CustomerSection',
        components: {
            ArrowIcon,
            Avatar,
            LinkIcon,
            ContactList,
            EditJobCustomFieldsModal: () => import('@/modals/EditJobCustomFieldsModal.vue'),
            ContentItem,
            Representatives,
            MakeCallFromNumberButton
        },
        props: {
            customer: {
                type: Object,
                required: true
            }
        },
        mixins: [customFieldInPrimarySection],
        data() {
            return {
                ENTITY_TYPES,
                customFields: {},
                CUSTOM_FIELDS_SECTION_TYPES: CUSTOM_FIELDS_SECTION_TYPES,
                CUSTOM_FIELDS_TYPES: CUSTOM_FIELDS_TYPES,
                editCustomFieldsModalShow: false,
                selectedCustomFieldsSection: {},
                representativesForRemove: [],
                loadRepresentatives: false
            }
        },
        computed: {
            customerDetailsIsOpen() {
                return this.$store.getters['customer/getCustomerDetailsIsOpen'];
            },
            customerId: function() {
                return this.$route.params.customer_id;
            },
            customerPhonesLength() {
                return this.customer.phones.length;
            },
            customerEmailsLength() {
                return this.customer.emails.length;
            },
            customerBillingEmailsLength() {
                return this.customer.billing_emails.length;
            }
        },
        methods: {
            // Method imported from helpers.js
            getGoogleMapLink,
            toggleCustomerDetailsIsOpen() {
                return this.$store.commit('customer/SET_CUSTOMER_DETAILS_IS_OPEN', !this.customerDetailsIsOpen);
            },
            getCustomFields() {
                this.customFields = cloneDeep(this.customer.layouts[0]);
                // This method called from mixin 'customFieldPrimarySection.js';
                this.getCustomPrimarySection();
                this.$emit('customPrimaryFieldsForCustomer', this.customPrimarySectionParameters);
                this.customFields.sections = processingCustomFields(
                    this.customFields, 
                    CUSTOM_FIELDS_TYPES
                );
            },
            showCustomFieldsModal(index) {
                this.selectedCustomFieldsSection = cloneDeep(this.customFields.sections[index]);
                this.editCustomFieldsModalShow = true;
            },
            closeCustomFieldsModal() {
                this.editCustomFieldsModalShow = false;

                // clear object
                for (let i in this.selectedCustomFieldsSection) {
                    delete this.selectedCustomFieldsSection[i];
                };
            },
            getCustomFieldsAddress(values) {
                if (values.length) {
                    const addressObject = JSON.parse(values[0].object_value);
                    return showFullAddress(addressObject) ? showFullAddress(addressObject) : '-';
                }

                return '-';
            },
            getAddress(address) {
                return showFullAddress(address);
            },
            checkIfSecondAddressShow(params) {
                return JSON.parse(params).add_other_address;
                this.editablePrimaryInformation = cloneDeep(this.lead);
            },
            getMultiselectValue(field) {
                if (!field.values.length) {
                    return '-';
                }
                const properties = JSON.parse(field.parameters).properties;
                const values = field.values[0].value.split(', ');
                const textValue = properties
                    .filter(i => values.some(v => v === i.id))
                    .map(i => i.value)
                    .join(', ');

                return textValue;
            },
            saveRepresentatives(data) {
                this.loadRepresentatives = true;
                const params = { representatives: data };
                
                if (this.representativesForRemove.length) {
                    Object.assign(params, { representatives_for_remove: this.representativesForRemove });
                }

                api.put(`/commercial/customers/${this.customer.id}`, params)
                    .then(response => {
                        this.notifySuccess('Point of contacts is updated!');
                        this.$emit('updateCustomerRepresentatives', response.data.data.representatives);
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => this.loadRepresentatives = false);
            }
        },
        created() {
            this.getCustomFields();
        },
        destroyed() {
            this.$store.commit('customer/SET_CUSTOMER_DETAILS_IS_OPEN', false);
        }
    }
</script>