import api from '@/headers.js';
import {
    SET_CUSTOMER_CONTACTS_EMAILS,
    SET_IS_SHOW_CORRESPODENCE,
    SET_CUSTOMER_DETAILS_IS_OPEN
} from './../actions/customer';
export default {
    namespaced: true,
    state: {
        customerContactsEmails: [],
        isShowCorrespodence: false,
        customerDetailsIsOpen: false
    },
    getters: {
        getCustomerContactsEmails: state => state.customerContactsEmails,
        getIsShowCorrespodence: state => state.isShowCorrespodence,
        getCustomerDetailsIsOpen: state => state.customerDetailsIsOpen
    },
    mutations: {
        [SET_CUSTOMER_CONTACTS_EMAILS](state, payload) {
            state.customerContactsEmails = payload;
        },
        [SET_IS_SHOW_CORRESPODENCE](state, payload) {
            state.isShowCorrespodence = payload;
        },
        [SET_CUSTOMER_DETAILS_IS_OPEN](state, payload) {
            state.customerDetailsIsOpen = payload;
        }
    }
}