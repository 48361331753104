<template>
    <div 
        class="notifications-mask"
        @click="!dScreen ? $emit('close') : ''"
    >
        <div class="notifications column-center hidden">
            <div 
                class="row-end pr-5 pb-5"
                v-if="!dScreen"
            >
                <button 
                    class="icon-light"
                    @click="$emit('close')"
                >
                    <close-icon/>
                </button>
            </div>
            <loader 
                v-if="loading"
                size="mini" 
                class="s-align-5 h-100"
            />
            <div 
                v-if="!newNotification.length && !loading" 
                class="w-100 h-100 column-center s-align-5"
            >
                <img 
                    class="mb-20"
                    src="@/assets/images/notifications-empty.svg" 
                    width="99"
                    height="88"
                    alt="Notifications not found"
                />
                <p class="f-13-darkgrey">You are up to speed</p>
                <p class="f-13-grey">There are no new notifications</p>
            </div>
            <perfect-scrollbar 
                v-else-if="!loading"
                class="ps-grey w-100"
            >
                <div class="notifications-card-wrapper">
                    <div 
                        class="notifications-card w-100 bb-grey"
                        v-for="(item, index) in newNotification" 
                        :key="`${item.id}-${item.entity_id}-${index}`"
                    >
                        <div class="row-start">
                            <template v-if="item.entity && item.entity_type === CLASSES.JOB_TASK">
                                <router-link
                                    v-if="isShowResidentialLink(item)"
                                    class="f-12-black break-all"
                                    :to="{
                                        name: 'ResidentialTasks',
                                        params: { lead_id: item.entity.entity_id },
                                        query: { task: !!item.entity.is_completed ? 'completed' : 'active' }
                                    }"
                                >
                                    {{ item.message }}
                                </router-link>
                                <router-link
                                    v-if="item.entity.entity_type === entity_types.RESIDENTIAL_JOB"
                                    class="f-12-black break-all"
                                    :to="{
                                        name: 'ResidentialTasks',
                                        params: { lead_id: item.entity.entity_id },
                                        query: { task: !!item.entity.is_completed ? 'completed' : 'active' }
                                    }"
                                >
                                    {{ item.message }}
                                </router-link>
                                <router-link
                                    v-if="item.entity.entity_type === entity_types.COMMERCIAL_JOB"
                                    class="f-12-black break-all"
                                    :to="{ 
                                        name: 'CommercialJobTasks',
                                        params: {
                                            property_id: item.property_id || item.id,
                                            job_id: item.entity.entity_id
                                        },
                                        query: { task: !!item.entity.is_completed ? 'completed' : 'active' }
                                    }"
                                >
                                    {{ item.message }}
                                </router-link>
                                <router-link
                                    v-if="item.entity.entity_type === entity_types.COMMERCIAL_LEAD"
                                    class="f-12-black break-all"
                                    :to="{
                                        name: 'CommercialLeadTasks',
                                        params: { lead_id: item.entity.entity_id },
                                        query: { task: !!item.entity.is_completed ? 'completed' : 'active' }
                                    }"
                                >
                                    {{ item.message }}
                                </router-link>
                                <router-link
                                    v-if="item.entity.entity_type === entity_types.OPPORTUNITY"
                                    class="f-12-black break-all"
                                    :to="{
                                        name: 'OpportunityTasks',
                                        params: { opportunity_id: item.entity.entity_id },
                                        query: { task: !!item.entity.is_completed ? 'completed' : 'active' }
                                    }"
                                >
                                    {{ item.message }}
                                </router-link>
                            </template>
                            <router-link 
                                v-else-if="item.entity_type === CLASSES.KANBAN"
                                class="f-12-black break-all"
                                :to="{ name: 'ProjectManagement', query: { card: item.entity.card_slug } }"
                            >
                                {{ item.message }}
                            </router-link>
                        </div>
                        <div class="row-between row-baseline mt-2">
                            <div class="row-start w-60">
                                <template v-if="item.entity_type === CLASSES.JOB_TASK">
                                    <span  
                                        v-if="item.type == TYPE_NOTIFY.DUE_DATE"
                                        class="notifications-icon s-align-5 notifications-due"
                                    >
                                        <stop-icon size="10" class="i-white"/>
                                    </span>
                                    <span  
                                        v-if="item.type == TYPE_NOTIFY.COMPLETED"
                                        class="notifications-icon s-align-5 notifications-complete"
                                    >
                                        <mark-icon size="7" class="i-white"/>
                                    </span>
                                    <span  
                                        v-if="item.type == TYPE_NOTIFY.ASSIGNED"
                                        class="notifications-icon s-align-5 notifications-assigned"
                                    >
                                        <info-icon size="8" class="i-white"/>
                                    </span>
                                    <span  
                                        v-if="item.type == TYPE_NOTIFY.REMIND"
                                        class="notifications-icon s-align-5 notifications-remind"
                                    >
                                        <warning-icon size="8" class="i-white"/>
                                    </span>
                                    <div class="f-11-grey ml-4">
                                        {{ item.created_at | createdAt }}
                                    </div>  
                                </template> 
                                <template v-else-if="item.entity_type === CLASSES.KANBAN">
                                    <span  
                                        class="notifications-icon s-align-5 notifications-assigned"
                                    >
                                        <info-icon size="8" class="i-white"/>
                                    </span>
                                    <div class="f-11-grey ml-4">
                                        {{ item.date_sending || item.created_at | date }}
                                    </div>    
                                </template>                             
                            </div>
                            <button 
                                v-if="!item.dismiss"
                                @click.stop="dismissNotification(item.id)" 
                                :class="['dismiss-button', checkLoading(item.id) ? 'none-events' : '']"
                            > 
                                <loading v-if="checkLoading(item.id)" />
                                <span v-else>Dismiss</span>
                            </button>
                        </div>
                    </div>
                </div>
            </perfect-scrollbar>
            <button 
                v-if="!loading"
                @click.stop="loadAll = !loadAll" 
                class="view-all-notification-button"
            >
                {{ !loadAll ? 'View all notifications' : 'Collapse dismissed notifications '}} 
            </button>
        </div>
    </div>
</template>

<script>
    import { CloseIcon, StopIcon, MarkIcon, InfoIcon, WarningIcon, Loading } from '@/icons';
    import { Loader } from '@/ui';
    import api from '@/headers.js';
    import { mapState } from 'vuex';
    import { ENTITY_TYPES, TYPE_NOTIFY } from '@/constants';

    export default {
        name: 'Notifications',
        components: {
                CloseIcon,
                StopIcon,
                MarkIcon,
                InfoIcon,
                WarningIcon,
                Loader,
                Loading
            },
        data() {
            return {
                loadingItems: [],
                loading: false,
                notification: [],
                loadAll: false,
                CLASSES: {
                    JOB_TASK: `Modules\\JobTask\\Entities\\JobTask`,
                    KANBAN: `Modules\\Kanban\\Entities\\Card`
                },
                entity_types: ENTITY_TYPES,
                TYPE_NOTIFY
            }
        },
        computed: {
            ...mapState("notifications", ["notificationMark"]),
            newNotification() {
                if(!this.loadAll){
                    return this.notification.filter(el => !el.dismiss);
                }

                return this.notification.sort((a, b) => a.dismiss - b.dismiss);
            }
        },
        watch: {
            notificationMark(val,oldVal) {
                if (val) {
                    this.dismissBadge();
                    this.getNotification();
                }
            }
        },
        methods: {
            isShowResidentialLink(item) {
                return item.entity
                    && (item.entity.entity_type === ENTITY_TYPES.RESIDENTIAL_LEAD
                    || item.entity.entity_type === ENTITY_TYPES.RESIDENTIAL_JOB);
            },
            checkLoading(id){
                return this.loadingItems.some(el => el == id);
            },
            getNotification() {
                this.loading = true;
                api.get(`/notifications?perPage=30`)
                    .then(response => response.data.data)
                    .then(data => {
                        this.notification = data.data;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            dismissNotification(id) {
                let params = {
                    notify_id: id,
                    dismiss: 1
                };
                
                this.loadingItems.push(id);
                api.put(`/notifications/dismiss/${id}`, params)
                    .then(() => {
                        let notificationIndex = this.notification.findIndex(item => item.id === id);
                        this.$set(this.notification[notificationIndex], 'dismiss', 1);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        let loadIndex = this.loadingItems.findIndex(item => id === id);
                        this.loadingItems.splice(loadIndex, 1);
                    });
            },
            dismissBadge() {
                this.$favicon.badge('');
                this.$store.commit('notifications/SET_NOTIFICATION_MARK', false);
                localStorage.setItem('notificationMark', false);
            }    
        },
        created() {
            this.getNotification();
        },
        mounted() {
            this.dismissBadge();     
        }
    }
</script>
