<template>
    <full-modal 
        @close="$emit('close')"
        class="order-view-log-modal"
        :full="true"
    >
        <div slot="title">View order log</div>
        <div slot="body">
            <loader v-if="loading"/>
            <div 
                class="v-else"
                v-for="(item, index) in log"
                :key="index"
            >
                <div class="column-start bb-grey pv-4">
                    <p class="f-14-black pb-1">
                        {{ item.text }}
                    </p>
                    <p class="f-12-grey">
                        by <span class="f-12-black">{{ item.created_by.fullname }}</span>
                        {{ item.created_at | createdAt }}
                    </p>
                </div>
            </div>
            <not-found-message
                v-if="logEmpty && !loading"
                custom="Nothing to display"
            />
        </div>
    </full-modal>
</template>

<script>
    import { NotFoundMessage } from '@/components';
    import { FullModal, Loader } from '@/ui';
    import api from '@/headers.js';

    export default {
        name: 'OrderViewLogModal',
        components: {
            NotFoundMessage,
            FullModal,
            Loader
        },
        props: {
            orderId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                log: []
            }
        },
        computed: {
            logEmpty() {
                return !this.log.length;
            }
        },
        methods: {
            getLog() {
                this.loading = true;
                api.get(`/orders/${this.orderId}/logs`)
                    .then(response => response.data.data)
                    .then(data => {
                        this.log.push(...data);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        created() {
            this.getLog();
        }
    }
</script>