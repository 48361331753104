<template>
    <div class="steps-panel row-center">
        <div 
            v-for="(caption, index) in captions"
            :key="index"
            :class="[
                's-align-4', 'step', 'pv-1',
                { 'step-inprogress' : index != 0 }, 
                { 'step-done' : current >= index + 1 && index != 0 }
            ]"
        >
            <div 
                :class="[
                    'step-icon', 's-align-5', 
                    { 'step-icon-inprogress' : current >= index + 1 },
                    { 'step-icon-done' : current > index + 1 }
                ]"
            >
                <mark-icon
                    size="12"
                    v-if="current > index + 1"
                />
                <span v-else>{{ index + 1 }}</span>
            </div>
            <span class="f-13-black">{{ caption }}</span>
        </div>
    </div>
</template>

<script>
    import { MarkIcon } from '@/icons';

    export default {
        name: 'StepsPanel',
        props: {
            captions: {
                type: Array,
                required: true
            },
            current: {
                type: [String, Number],
                required: true
            }
        },
        components: {
            MarkIcon
        }
    }
</script>