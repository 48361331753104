<template>
    <full-modal @close="$emit('close')">
        <div slot="title">
            {{ editedTask === null ? 'Create' : 'Edit' }} Task
        </div>
        <div slot="body">
            <div class="w-100 pb-30">
                <div class="row-start">
                    <div class="lg-5 md-5 sm-12 mb-4 pr-1 pr-sm-0">
                        <form-input 
                            label="Task Title"
                            :class="[{ 'form-input-danger' : errors.has('task_title') }]"
                        >
                            <input 
                                type="text" 
                                name="task_title" 
                                id="task_title"
                                class="form-input-field"
                                placeholder="Task Title"
                                v-input-style="title"
                                v-model="title"
                                v-validate="'required|min:3|max:250'"
                            />
                        </form-input>
                    </div>
                    <div class="lg-7 md-7 sm-12 mb-4 pl-1 pl-sm-0">
                        <form-datepicker
                            v-if="isAllDay && addToCalendar"
                            v-model="dateOfCompletion"
                            :isFullWidth="true"
                            placeholder="Due Date"
                            name="due_date"
                            v-validate="emailAlert || notificationAlert 
                                ? 'required|dateOfCompletion'
                                : 'required'"
                            :class="[{ 'form-datepicker-error' : errors.has('due_date') }]"
                            keyDatePicker="due_date_picker"
                        />
                        <form-date-time-input
                            v-else 
                            v-model="dateOfCompletion"
                            :isFullWidth="true"
                            placeholder="Due Date"
                            name="due_date"
                            v-validate="emailAlert || notificationAlert 
                                ? 'required|dateOfCompletion'
                                : 'required'"
                            :class="[{ 'form-datepicker-error' : errors.has('due_date') }]"
                        />
                    </div>
                </div>
                <multiselect
                    class="mb-6"
                    v-model="selectedUser"
                    placeholder="Assign to"
                    label="Assign to"
                    track-by="first_name"
                    name="assign_to"
                    data-name="assign_to"
                    :options="users"
                    :option-height="104" 
                    :show-labels="false"
                    :allow-empty="false"
                    :custom-label="customLabel"
                    v-validate="'required'"
                    @select="getUserCalendars"
                    :class="[{ 'multiselect-danger' : errors.has('assign_to') }]"
                >  
                    <template 
                        slot="singleLabel" 
                        slot-scope="props"
                    >      
                        <template v-if="props.option.first_name !== undefined">
                            <user-box
                                :firstName="props.option.first_name"
                                :lastName="props.option.last_name"
                                :avatarUrl="props.option.avatar"
                                :border="false" 
                            />
                        </template>
                        <template v-else>
                            <p class="f-14-grey">Assign to...</p>
                        </template>
                    </template>
                    <template 
                        slot="option" 
                        slot-scope="props"
                    >   
                        <user-box
                            :firstName="props.option.first_name"
                            :lastName="props.option.last_name"
                            :avatarUrl="props.option.avatar"
                            :border="false"
                        />
                    </template>  
                </multiselect>
                <checkbox-button
                    v-if='!editedTask'
                    color="blue"
                    class="mb-4"
                    label="Add to calendar"
                >
                    <input 
                        type="checkbox" 
                        name="add_to_calendar" 
                        v-model="addToCalendar"
                    />
                </checkbox-button>
                <checkbox-button
                    v-if='addToCalendar'
                    color="blue"
                    class="mb-4"
                    label="All day"
                >
                    <input 
                        type="checkbox" 
                        name="is_all_day" 
                        v-model="isAllDay"
                    />
                </checkbox-button>
                <multiselect
                    v-if="addToCalendar && selectedUser"
                    :options="selectedUserCalendars"
                    track-by="id"
                    label="summary"
                    name="selected_calendar"
                    placeholder="Choose calendar"
                    v-model="selectedCalendar"
                    data-name="selected_calendar"
                    :show-labels="false"
                    :allow-empty="false"
                    v-validate="'required'"
                    :class="[{ 'multiselect-danger' : errors.has('selected_calendar') }]"
                    :disabled="userCalendarsLoading"
                >
                    <template 
                        slot="singleLabel" 
                        slot-scope="props"
                    >      
                        <template v-if="props.option">
                            <div class="s-align-4">
                                <crm-logo
                                    v-if="props.option.provider == CALENDAR_PROVIDERS.CRM"
                                    size="30"
                                    class="mr-4"
                                />
                                <outlook-logo
                                    v-else
                                    size="30" 
                                    class="mr-4"
                                />
                                <span class="f-13-black ellipsis">{{ props.option.summary }}</span> 
                                <span
                                    v-if="props.option.primary"
                                    class="light-blue-label f-12-white ml-1"
                                >
                                    Primary
                                </span>
                            </div>
                        </template>
                    </template> 
                    <template 
                        slot="option" 
                        slot-scope="props"
                    >   
                        <div class="s-align-4">
                            <crm-logo
                                v-if="props.option.provider == CALENDAR_PROVIDERS.CRM" 
                                class="mr-2"
                            />
                            <outlook-logo
                                v-else 
                                class="mr-2"
                            />
                            <span class="f-13-black ellipsis">{{ props.option.summary }}</span> 
                            <span
                                v-if="props.option.primary"
                                class="light-blue-label f-12-white ml-1"
                            >
                                Primary
                            </span>
                        </div>
                    </template>
                </multiselect>
                <form-input-textarea
                    class="mb-4"
                    label="Task"
                    v-input-counter="1000"
                >
                    <textarea 
                        :class="
                            ['form-input-field',
                            { 'form-input-textarea-danger' : errors.has('task_description') }]
                        "
                        name="task_description"
                        rows="5"
                        placeholder="Task"
                        maxlength="1000"
                        v-input-style="description"
                        v-model="description"
                        v-validate="'required|min:3|max:999'"
                    />
                </form-input-textarea>
            </div>
            <div class="w-100 pb-30">
                <p class="f-13-black f-sbold mb-5">Choose priority</p>
                <div class="row-start row-baseline mb-3">
                    <radio-button 
                        color="blue"
                        class="mr-4"
                    >
                        <input 
                            type="radio" 
                            name="task_priority" 
                            id="task_priority_normal"
                            value="normal"
                            v-model="priority"
                        />
                    </radio-button>
                    <dash-icon class="mr-3 i-green"/>
                    <span class="f-13-black">Normal</span>
                </div>
                <div class="row-start row-baseline">
                    <radio-button 
                        color="blue"
                        class="mr-3"
                    >
                        <input 
                            type="radio" 
                            name="task_priority" 
                            id="task_priority_high"
                            value="high"
                            v-model="priority"
                        />
                    </radio-button>
                    <lightning-icon class="mr-3 i-red"/>
                    <span class="f-13-black">High</span>
                </div>
            </div>
            <div class="w-100 pb-30">
                <p class="f-13-black f-sbold mb-5">Choose reminder type</p>
                <div class="row-start mb-4">
                    <checkbox-button
                        color="blue"
                        class="mr-4"
                        label=""
                    >
                        <input 
                            type="checkbox" 
                            name="notification_alert" 
                            v-model="notificationAlert"
                        />
                    </checkbox-button>
                    <span class="f-13-black mr-1">Alert</span>
                    <span class="f-13-grey">(calendar notifications)</span>
                </div>
                <div class="row-start">
                    <checkbox-button
                        color="blue"
                        class="mr-4"
                        label=""
                    >
                        <input 
                            type="checkbox" 
                            name="email"
                            v-model="emailAlert"
                        />
                    </checkbox-button>
                    <span class="f-13-black mr-1">Email</span>
                    <span class="f-13-grey">
                        {{ 
                            selectedUser == ''
                            ? '(to mailexample@mail.com)'
                            : `(to ${selectedUser.email})`
                        }}
                    </span>
                </div>
            </div>
            <div
                v-if="emailAlert || notificationAlert"
                class="w-100 pb-30 bb-grey"
            >
                <p class="f-13-black f-sbold pb-6">Choose when to send a reminder</p>
                <div
                    :class="[
                        'row-start row-baseline pb-1',
                        { 'mv-2' : !option.isHours },
                        { 'in-dev': option.inDev }
                    ]"
                    v-for="(option, reminderIndex) in reminderOptions"
                    :key="`reminder-${reminderIndex}`"
                >
                    <radio-button
                        :label="option.name"
                        color="blue"
                    >
                        <input
                            type="radio"
                            name="reminder"
                            :value="TRIGGER_VALUE_REMINDER"
                            v-model="option.value"
                            :disabled="option.inDev"
                            @change="changeReminder(reminderIndex)"
                        />
                    </radio-button>
                    <div class="lg-3 sm-6 pl-8">
                        <div
                            class="asymmetric-input asymmetric-input-hour relative"
                            :data-placeholder="option.isHours && 'hour(s)'"
                        >
                            <input
                                v-if="option.name === 'Once'"
                                class="input input-ass"
                                :class="[
                                    'input input-ass',
                                    { 'form-datepicker-error' : errors.has(`due-day-${option.name}`) }
                                ]"
                                type="number"
                                :name="`due-day-${option.name}`"
                                v-model.number="onceRemindHours"
                                :disabled="!option.value"
                                min="1"
                                v-limiter
                                v-validate="option.value && 'required'"
                            />
                            <input
                                v-if="option.name === 'Every'"
                                :class="[
                                    'input input-ass',
                                    { 'form-datepicker-error' : errors.has(`due-day-${option.name}`) }
                                ]"
                                type="number"
                                min="1"
                                :name="`due-day-${option.name}`"
                                v-model.number="everyRemindHours"
                                :disabled="!option.value"
                                v-limiter
                                v-validate="option.value && 'required'"
                            />
                        </div>
                    </div>
                    <div class="ml-2 f-13-black">
                        <p>{{ option.afterText }}</p>
                    </div>
                </div>
                <div
                    v-if="emailAlert || notificationAlert"
                    class="w-100 pb-30"
                >
                    <form-date-time-input
                        v-model="reminderDate"
                        :isFullWidth="true"
                        popoverDirection="top"
                        :minDate="new Date()"
                        :maxDate="new Date(dateOfCompletion)"
                        placeholder="Reminder Date"
                        name="reminder_date"
                        :disabled="!reminderOptions[2].value"
                        :class="[{ 'form-datepicker-error' : errors.has('reminder_date') }]"
                        v-validate="`reminderDate${reminderOptions[2].value ? '|required' : ''}`"
                    />
                </div>
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                :disabled="requestIsGoing"
                @click="createTask"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { 
        FullModal, 
        FormDatepicker,
        FormDateTimeInput, 
        FormInput, 
        FormInputTextarea,
        RadioButton,
        CheckboxButton
    } from '@/ui';
    import api from '@/headers.js';
    import Multiselect from 'vue-multiselect';
    import UserBox from '@/components/User/UserBox.vue'; 
    import { DashIcon, LightningIcon, CrmLogo, OutlookLogo } from '@/icons';
    import moment from 'moment/src/moment';
    import { CALENDAR_TYPES, CALENDAR_PROVIDERS, TASK_FREQUENCY, TASK_TRIGGER_FIELD } from '@/constants.js';

    const TRIGGER_VALUE_IS_COMPLETED = 1;
    const TRIGGER_VALUE_REMINDER = true;

    const REMINDER_INDEX_ONCE = 0;
    const REMINDER_INDEX_EVERY = 1;
    const REMINDER_INDEX_ONCE_DATE = 2;

    export default {
        name: 'EditJobTaskModal',
        components: {
            FullModal,
            Multiselect,
            UserBox,
            FormDatepicker,
            FormDateTimeInput,
            FormInput,
            FormInputTextarea,
            RadioButton,
            DashIcon,
            LightningIcon,
            CrmLogo,
            OutlookLogo,
            CheckboxButton
        },
        props: {
            editedTask: {
                type: Object,
                required: false
            },
            entityType: {
                type: String,
                required: true
            },
            entityId: {
                type: [Number, String],
                required: true
            }
        },
        data() {
            return {
                requestIsGoing: false,
                users: [],
                selectedUser: '',
                dateOfCompletion: null,
                reminderDate: null,
                title: '',
                description: '',
                priority: 'normal',
                emailAlert: true,
                notificationAlert: false,
                addToCalendar: true,
                selectedCalendar: '',
                CALENDAR_PROVIDERS: CALENDAR_PROVIDERS,
                selectedUserCalendars: [],
                userCalendarsLoading: false,
                isAllDay: false,
                reminderOptions: [
                    { name: 'Once', value: true, afterText: 'before a due date.', isHours: true },
                    { name: 'Every', value: false, afterText: 'until task is marked as completed.', isHours: true },
                    { name: 'Once on specific date', value: false, afterText: '', isHours: false }
                ],
                onceRemindHours: 24,
                everyRemindHours: null,
                dueDay: 0,
                plannerId: null,
                reminder: 0,
                TRIGGER_VALUE_REMINDER
            }
        },
        computed: {
            activeReminderIndex() {
                return this.reminderOptions.findIndex(el => el.value);
            },
            dateCompletion() {
                return this.isAllDay
                    ? moment(this.dateOfCompletion).format('YYYY-MM-DD')
                    : moment(this.dateOfCompletion).format('YYYY-MM-DD HH:mm:ss');
            },
            frequency() {
                if (this.activeReminderIndex == REMINDER_INDEX_ONCE) {
                    return TASK_FREQUENCY.ONCE_BEFORE;
                } else if (this.activeReminderIndex == REMINDER_INDEX_ONCE_DATE) {
                    return TASK_FREQUENCY.ONCE_AFTER;
                }

                return TASK_FREQUENCY.LOOP_UNTIL;
            },
            triggerField() {
                return this.activeReminderIndex == REMINDER_INDEX_ONCE
                    || (this.activeReminderIndex == REMINDER_INDEX_ONCE_DATE)
                        ? TASK_TRIGGER_FIELD.DATE_OF_COMPLETION
                        : TASK_TRIGGER_FIELD.IS_COMPLETED;
            },
            triggerValue() {
                if (this.activeReminderIndex == REMINDER_INDEX_ONCE) {
                    return this.dateCompletion;
                }

                if (this.activeReminderIndex == REMINDER_INDEX_ONCE_DATE) {
                    return this.reminderDate;
                }

                return TRIGGER_VALUE_IS_COMPLETED;
            },
            delaySec() {
                if (this.activeReminderIndex == REMINDER_INDEX_ONCE) {
                    return this.getSecReminder(this.onceRemindHours);
                }

                if (this.activeReminderIndex == REMINDER_INDEX_ONCE_DATE) {
                    return 0;
                }

                return this.getSecReminder(this.everyRemindHours);
            }
        },
        methods: {
            changeReminder(index) {
                return this.reminderOptions.forEach((el, i) => el.value = index == i);
            },
            getUsers() {
                const params = {
                    perPage: 999,
                    is_active: 1,
                };

                this.$store.dispatch('users/USERS_GET', params)
                    .then(data => {
                        this.users = data.data;
                    })  
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            customLabel({ first_name, last_name }) {
                return `${first_name} – ${last_name}`
            },
            getUserCalendars(data) {
                // on existing task we can't create event and not necessary to load calendars list
                if (this.editedTask) { return }

                const params = {
                    owner_id: data.id,
                    owner_type: CALENDAR_TYPES.USER
                }

                this.selectedUserCalendars.splice(0, this.selectedUserCalendars.length)
                this.userCalendarsLoading = true;

                api.get('/calendars/calendar-list', { params })
                    .then(response => {
                        this.selectedUserCalendars.push(...response.data.data);

                        // select primary calendar by default
                        this.selectedCalendar = this.selectedUserCalendars[0];
                    })
                    .catch(error => this.notifyRequestErrors(error))
                    .finally(() => this.userCalendarsLoading = false);
            },
            getSecReminder(hours) {
                return hours ? hours * 60 * 60 : null;
            },
            getHoursReminder(sec) {
                return sec ? sec / 3600 : null;
            },
            createTask() {
                this.$validator.validateAll()
                    .then(result => {
                        if (result) {
                            let params = {
                                assigned_to: this.selectedUser.id,
                                title: this.title,
                                description: this.description,
                                date_of_completion: this.dateCompletion,
                                priority: this.priority,
                                is_complete: 0,
                                entity_id: this.entityId,
                                entity_type: this.entityType,
                                is_notify_remind : this.notificationAlert,
                                is_mail_remind : this.emailAlert,
                                redirect_url : `${window.location.href}?task=active`,

                            };

                            if (this.emailAlert || this.notificationAlert) {
                                params.frequency = this.frequency;
                                params.trigger_field = this.triggerField;

                                if (this.activeReminderIndex == REMINDER_INDEX_ONCE || this.activeReminderIndex == REMINDER_INDEX_EVERY) {
                                    params.delay_sec = this.delaySec;
                                    params.trigger_value = this.triggerValue;
                                    this.plannerId ? params.planner_id = this.plannerId : null;
                                    params.remind_date = null;
                                }
                                if (this.activeReminderIndex == REMINDER_INDEX_ONCE_DATE) {
                                    params.remind_date = moment(this.reminderDate).format('YYYY-MM-DD HH:mm:ss');
                                    params.delay_sec = 0;
                                    params.trigger_value = moment(this.reminderDate).format('YYYY-MM-DD HH:mm:ss');
                                }
                            }

                            if (this.addToCalendar) {
                                params.add_to_calendar = true;
                                params.is_all_day = this.isAllDay;
                                params.calendar_id = this.selectedCalendar.calendar_id;
                            }

                            let destination = this.editedTask === null
                                ? '/job-tasks/'
                                : `/job-tasks/${this.editedTask.id}`;

                            let method = this.editedTask === null
                                ? 'post'
                                : 'put';

                            this.requestIsGoing = true;
                             api[method](destination, params)
                                .then(() => {
                                    this.$emit('close', true);
                                    this.notifySuccess(this.editedTask === null ? 'Task created!' : 'Task updated!');
                                })
                                .catch(error => this.notifyRequestErrors(error))
                                .finally(() => this.requestIsGoing = false);
                        }
                    });
            },
            setValues() {
                this.title = this.editedTask.title;
                this.description = this.editedTask.description;
                this.dateOfCompletion = this.editedTask.date_of_completion;
                this.selectedUser = this.editedTask.assigned_to;
                this.priority = this.editedTask.priority;
                this.emailAlert = this.editedTask.is_mail_remind;
                this.notificationAlert = this.editedTask.is_notify_remind;
                this.reminderDate = this.editedTask.remind_date;
                this.addToCalendar = false;
                this.plannerId = this.editedTask.planner_id;

                if (this.editedTask.trigger_field == TASK_TRIGGER_FIELD.DATE_OF_COMPLETION) {
                    this.onceRemindHours = this.getHoursReminder(this.editedTask.delay_sec);
                }

                if (this.editedTask.trigger_field == TASK_TRIGGER_FIELD.IS_COMPLETED) {
                    this.reminderOptions[REMINDER_INDEX_EVERY].value = true;
                    this.everyRemindHours = this.getHoursReminder(this.editedTask.delay_sec);
                } else if (this.editedTask.remind_date) {
                    this.reminderOptions[REMINDER_INDEX_ONCE_DATE].value = true;
                } else  if (this.editedTask.trigger_field == TASK_TRIGGER_FIELD.DATE_OF_COMPLETION){
                    this.reminderOptions[REMINDER_INDEX_ONCE].value = true;
                }
            }
        },
        created() {
            let self = this;
            this.getUsers();
            // create validation rule for due date
            this.$validator.extend('dateOfCompletion', {
                validate(value, field) {
                    return !moment(self.reminderDate).isAfter(moment(self.dateOfCompletion));
                }
            });

            // create validation rule for reminder date
            this.$validator.extend('reminderDate', {
                validate(value, field) {
                    return !moment(self.dateOfCompletion).isBefore(moment(self.reminderDate));
                }
            });

            // init value for existing task
            if (this.editedTask == null) {
                return;
            }

            this.setValues();
        }
    }
</script>
