<template>
    <div class="crew-overview p-20 bg-white w-100 mb-20 column-start h-100">
        <div class="f-16-black f-sbold">
            Crews Calendar
        </div>
        <div class="s-align-5 h-100 w-100">
            <div class="s-align-4 crew-empty-list">
                <calendar-color-icon size="60" />
                <div class="column-start ml-8">
                    <div class="f-16-black f-sbold">
                        Assign crew
                    </div>
                    <div class="f-13-black">
                        Start by creating an order and selecting a crew
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {CalendarColorIcon} from '@/icons';
    
    export default {
        name: 'CrewsCalendar',
        components: {
            CalendarColorIcon
        }
    }
</script>

