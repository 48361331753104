<template>
    <svg 
        viewBox="0 0 384 384"
        :width="size" 
        :height="size"
        :style="{ transform: 'rotate('+ transform + 'deg)' }"
        class="filter-icon"
    >
        <g>
            <g>
                <g>
                    <rect 
                        x="0" 
                        y="277.333" 
                        width="128" 
                        height="42.667"
                    />
                    <rect 
                        x="0" 
                        y="170.667" 
                        width="256" 
                        height="42.667"
                    />
                    <rect 
                        x="0" 
                        y="64" 
                        width="384" 
                        height="42.667"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'FilterIcon',
    props: {
        size: {
            type: [Number, String],
            default: 15
        },
        transform: {
            type: [Number, String],
            default: 0
        }
    }
}
</script>
