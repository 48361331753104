<template>
    <svg
        :width="size"
        :height="size"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_4_4114)">
            <circle cx="20" cy="18" r="12" fill="#006BFD"/>
            <path d="M24.3995 17.6247C24.6768 17.798 24.6768 18.202 24.3995 18.3753L17.6772 22.5767C17.3824 22.761 17 22.549 17 22.2014L17 13.7986C17 13.4509 17.3824 13.239 17.6772 13.4233L24.3995 17.6247Z" fill="white"/>
        </g>
        <defs>
            <filter id="filter0_d_4_4114" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="2"/>
                <feGaussianBlur stdDeviation="4"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_4114"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_4114" result="shape"/>
            </filter>
        </defs>
    </svg>

</template>

<script>
    export default {
        name: 'PlayIcon',
        props: {
            size: {
                type: [Number, String],
                default: 24
            }
        }
    }
</script>
