<template>
    <div class="overview-statistic w-100">
        <div>
            <div :class="[
                'row-between',
                { 'statistic-mock-loading' : loading }
            ]">
                <router-link
                    v-for="(item, key, index) in statistic"
                    :key="key"
                    :to="{ name: item.routerName }"
                    :class="[
                        'overview-statistic-column flex-grow-1 ph-20 pv-15',
                        index !== 0 && dScreen && 'ml-20',
                        !dScreen && 'm-4',
                        'column-start',
                        { 'none-events' : item.dev },
                        { 'expired-notification': showExpired && item.name === 'Tasks / Expired'}
                    ]"
                >
                    <div class="row-between align-bottom">
                        <div class="column-start">
                            <div :class="item.dev && 'w-100 in-dev'"/>
                            <span class="f-24-black f-sbold t-line-height-1">
                                 {{ showCounters(item) }}
                            </span>
                            <span class="f-12-grey mt-2">
                                {{ item.name }}
                            </span>
                        </div>
                        <div :class="[
                            'overview-statistic-column-icon s-align-5',
                            item.class
                        ]">
                            <component
                                :is="item.icon"
                                size="8"
                                :class="item.iconClass"
                            />
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        MapIcon, PersonIcon, CubesIcon, EditIcon, ArrowIcon,
        GearIcon, PdfIcon, DocumentsIcon, OrdersIcon, ExpiredTasksIcon,
        EstimateIcon, MessagesIcon, CalendarColorIcon, StormIcon,
        LinkIcon, CalendarIcon
    } from '@/icons'
    import { ENTITY_TYPES } from '@/constants';
    import IMAPapi from "@/IMAPheaders";
    
    export default {
        name: 'StatisticCards',
        components: {
            DocumentsIcon,
            LinkIcon,
            EditIcon,
            ArrowIcon,
            PdfIcon,
            OrdersIcon,
            MapIcon,
            CalendarColorIcon,
            CalendarIcon,
            MessagesIcon,
            EstimateIcon,
            ExpiredTasksIcon,
            PersonIcon,
            CubesIcon,
            StormIcon,
            GearIcon,
        },
        props: {
            statistic: {
                type: Object,
                default: {}
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                ENTITY_TYPES: ENTITY_TYPES,
            }
        },
        computed: {
            showExpired() {
                //temporarily hide non-existent blocks
                if (!this.statistic.tasks){
                    return false;
                }
                return this.statistic.tasks.count.expired !== 0;
            },
            userId() {
                return this.$store.getters['user/getUserId'];
            },
        },
        methods: {
            showCounters(item) {
                //temporarily hide non-existent blocks
                if (!this.statistic.tasks && !this.statistic.messages){
                    return item.count || 0;
                }
                switch (item.routerName) {
                    case this.statistic.messages.routerName:
                        return `${item.count.messages || 0}`;
                    default:
                        return item.count || 0;
                }
            },
            getMessages() {
                let params = {
                    "entity_id": this.$route.params.job_id,
                    "entity_type": ENTITY_TYPES.WARRANTY_JOB,
                    "per_page": 100,
                    "page": 1,
                    "is_preview": true,
                    "user_id": this.userId
                };

                IMAPapi.get(`messages/entity`, {params})
                    .then(response => {
                        let data = response.data.data;

                        this.$emit('countMessages', data.messages.data.length)
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
            },
        },
        created() {
            this.getMessages();
        }
    }
</script>

