<template>
    <modal
        @close="close"
        class="section-delete-confirm"
    >
        <div slot="modal-body">
            <div class="w-100 column-center pb-4">
                <span class="f-14-black pb-2">
                    Zip Code out of area
                </span>
            </div>
        </div>
        <div slot="modal-footer">
            <div class="row-between">
                <button
                    class="add-button"
                    @click="onCancel"
                >
                    Cancel
                </button>
                <button
                    class="delete-button mr-4"
                    @click="onContinue"
                >
                    Continue
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import { Modal } from '@/ui';

export default {
    name: 'ZipCodeModal',
    components: {
        Modal
    },
    data() {
        return {}
    },
    methods: {
        onContinue() {
            this.$emit('continue');
        },
        onCancel() {
            this.$emit('cancel');
        },
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
    .section-delete-confirm {
        z-index: 13;
    }
</style>