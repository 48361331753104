<template>
    <svg 
        viewBox="0 0 436.9197998046875 464.3080139160156"
        :width="size"
        :height="size"
        class="add-customer-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="436.9197998046875" 
            height="464.3080139160156" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(14.755950927734375, 0, 0, 14.755950927734375, -751.6689453125, -1247.5550003051758)">
            <g id="Layer_1">
                <path d="M65.6,100.3c4.3,0,7.8-3.5,7.8-7.8s-3.5-7.8-7.8-7.8c-4.3,0-7.8,3.5-7.8,7.8C57.8,96.8,61.3,100.3,65.6,100.3z M65.6,86.3c3.4,0,6.2,2.8,6.2,6.2c0,3.4-2.8,6.2-6.2,6.2c-3.4,0-6.2-2.8-6.2-6.2C59.3,89.1,62.1,86.3,65.6,86.3z"/>
                <path d="M73.6,101.7c0.7,0,1.1-0.9,0.5-1.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.9-0.9-2.6-0.9-3.6-0.2c-2.9,1.9-6.8,1.9-9.8,0c-1.2-0.8-2.6-0.6-3.7,0.3c-3,2.8-4.6,5.6-5.5,9.6c-0.5,2,0.3,4,2,5.3c0.5,0.4,1.2,0.6,2,0.6h13c0.5,0,0.8-0.3,0.8-0.8l0,0c0-0.5-0.3-0.8-0.8-0.8h-0.4H55.5c-0.4,0-0.8-0.2-1.1-0.3c-1.2-0.9-1.7-2.3-1.4-3.7c0.9-3.7,2.3-6.2,5.1-8.8c0.2-0.2,0.6-0.4,0.9-0.4c0.2,0,0.5,0.1,0.7,0.2c0.6,0.3,1.2,0.8,1.8,1c3.3,1.3,6.9,0.9,9.7-1c0.5-0.3,1.3-0.2,1.7,0.1c0.1,0.1,0.2,0.2,0.3,0.3L73.6,101.7z"/>
                <g>
                    <path d="M79.5,110.3h-8.8c-0.5,0-0.8-0.3-0.8-0.8c0-0.5,0.3-0.8,0.8-0.8h8.8c0.5,0,0.8,0.3,0.8,0.8C80.2,110,79.9,110.3,79.5,110.3z"/>
                    <path d="M75.1,114.8c-0.5,0-0.8-0.3-0.8-0.8v-8.9c0-0.5,0.3-0.8,0.8-0.8c0.5,0,0.8,0.3,0.8,0.8v8.8C75.9,114.4,75.5,114.8,75.1,114.8z"/>
                </g>
            </g>
            <g 
                id="Layer_2" 
                class="st0" 
                style="display: none;"
            >
                <path 
                    class="st1" 
                    d="M24.3,99.5c2.8,0,5.2,2.3,5.2,5.2c0,2.8-2.3,5.2-5.2,5.2s-5.2-2.3-5.2-5.2C19.1,101.8,21.5,99.5,24.3,99.5 M24.3,112.8c4.5,0,8.1-3.6,8.1-8.1c0-4.5-3.6-8.1-8.1-8.1s-8.1,3.6-8.1,8.1C16.2,109.1,19.8,112.8,24.3,112.8" 
                    style="display: inline;"
                />
                <path 
                    class="st1" 
                    d="M24.3,114.4c-8.3,0-15,6.7-15,15c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5c0-6.6,5.4-12,12-12s12,5.4,12,12c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5C39.3,121.1,32.6,114.4,24.3,114.4" 
                    style="display: inline;"
                />
                <path 
                    class="st1" 
                    d="M41.2,130c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4H39V125c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4v2.2h-2.2c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4h2.2v2.2c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4V130H41.2z" 
                    style="display: inline;"
                />
                <path 
                    d="M 49.399999 128.600006 A 11.8 11.8 0 0 1 37.599998 140.400006 A 11.8 11.8 0 0 1 25.799998 128.600006 A 11.8 11.8 0 0 1 37.599998 116.800006 A 11.8 11.8 0 0 1 49.399999 128.600006 Z" 
                    class="st2" 
                    style="display: inline; fill: none; stroke: rgb(0, 0, 0); stroke-width: 1.75; stroke-miterlimit: 10;"
                />
            </g>
            <g id="Layer_3"></g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'AddCustomerIcon',
        props: {
            size: {
                type: [Number, String],
                default: 27
            }
        }
    }
</script>