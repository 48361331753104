<template>
    <div class="w-100 mb-1">
        <div class="file row-between row-baseline bb-grey">
            <document-icon
                width="17"
                height="22"
                :class="['mr-4', {'opacity-5' : showLoader}]"
            />
            <div :class="['file-description column-start', {'opacity-5' : showLoader}]">
                <span class="f-12-black t-black t-left ellipsis">{{ file.original_name }}</span>
                <span class="f-10-grey">
                    {{ Math.round(file.size/1024 * 100) / 100 }}Kb
                </span>
            </div>
            <loader
                v-if="showLoader"
                size="mini"
            />
            <div
                v-else
                class="file-btns s-align-6"
            >
                <button
                    v-if="previewAvailable && showPreviewBtn"
                    class="transparent-button p-1"
                    @click="previewModalOpened = true"
                >
                    <eye-icon
                        size="18"
                        class="i-blue"
                    />
                </button>
                <button
                    v-if="showDownloadBtn"
                    class="transparent-button p-1"
                    @click="downloadFile"
                >
                    <download-icon
                        size="14"
                        class="i-grey"
                    />
                </button>
                <button
                    v-if="showDeleteBtn"
                    class="transparent-button p-1 ml-1"
                    @click="$emit('deleteFile', file.id)"
                >
                    <trash-icon class="i-grey"/>
                </button>
            </div>
        </div>
        <file-preview-modal
            v-if="previewModalOpened"
            :file="file"
            @close="previewModalOpened = false"
        />
    </div>
</template>

<script>
    import { DocumentIcon, DownloadIcon, EyeIcon, TrashIcon } from '@/icons';
    import { FILE_EXTENSIONS } from '@/constants.js';
    import FilePreviewModal from '@/modals/FilePreviewModal';
    import { Loader } from '@/ui';

    export default {
        name: 'File',
        components: {
            DocumentIcon,
            DownloadIcon,
            EyeIcon,
            TrashIcon,
            FilePreviewModal,
            Loader
        },
        props: {
            file: {
                type: Object,
                required: true
            },
            showDeleteBtn: {
                type: Boolean,
                default: false
            },
            showDownloadBtn: {
                type: Boolean, 
                default: false
            },
            showLoader: {
                type: Boolean,
                default: false
            },
            showPreviewBtn: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                previewModalOpened: false
            }
        },
        computed: {
            previewAvailable() {
                return FILE_EXTENSIONS.DOCUMENTS.some(e => e.toLowerCase() === this.file.type.toLowerCase())
                    || FILE_EXTENSIONS.IMAGES.some(e => e.toLowerCase() === this.file.type.toLowerCase());
            }
        },
        methods: {
            downloadFile() {
                fetch(`${process.env.VUE_APP_AMAZONAWS_URL}${this.file.id}`)
                    .then(data => data.blob())
                    .then(blob => {
                        let link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = this.file.original_name;
                        link.click();
                    })
                    .catch(error => this.notifyRequestErrors(error));
            }
        }
    }
</script>