<template>
    <perfect-scrollbar class="commercial-list-details ps-grey w-100">
        <div 
            class="w-100" 
            v-if="loading"
        >
            <loader/>
        </div>
        <div 
            class="w-100" 
            v-else
        >
            <div class="s-align-4 w-100 pb-4">
                <clock-icon 
                    class="i-grey mr-2"
                    size="12"
                />
                <span class="f-11-grey">{{ job.created_at | timeAgo }}</span>
            </div>
            <div class="column-start w-100">
                <h2 class="f-20-darkgrey f-sbold pb-4">
                    <router-link 
                        class="to-commercial-lead"
                        :to="{ 
                            name: 'CommercialJobOverview', 
                            params: { property_id: job.property.id, job_id: job.id }
                        }"
                    >
                        {{ jobPrefixAndNumber() }}
                    </router-link>
                </h2>
                <div class="row-start pb-1">
                    <div :class="['md-12', addressIsCorrect ? 'lg-8 nb-7' : 'lg-12 nb-12']">
                        <span class="f-12-grey">
                            Address:
                            <a
                                :href="getGoogleMapLink(job.lat, job.lng)"
                                :class="[
                                    job.full_address ? 'f-12-blue' : 'f-italic',
                                    {'none-events': !getGoogleMapLink(job.lat, job.lng)}
                                ]"
                                target="_blank"
                            >
                                {{
                                    job.full_address 
                                    ? job.full_address 
                                    : 'No information'
                                }}
                                <link-icon 
                                    v-if="getGoogleMapLink(job.lat, job.lng)"
                                    class="i-blue"
                                />
                            </a>
                        </span>
                    </div>
                    <div 
                        class="s-align-3 lg-4 nb-5 md-12 mt-md-1"
                        v-if="addressIsCorrect"
                    >
                        <button 
                            class="show-on-map"
                            @click="$emit('showMap', { lat: +job.lat, lng: +job.lng })"
                        >
                            <map-icon/>
                            Show on Map
                        </button>
                    </div>
                </div>
            </div>
            <div class="column-start pv-30 w-100 bb-grey">
                <h2 class="f-16-darkgrey f-sbold mb-8">Property</h2>
                <div class="mb-3">
                    <span class="f-13-grey">Property:</span>
                    <span class="f-13-black pl-1">
                        <router-link
                            :to="{ 
                                name: 'CommercialDetails', 
                                params: { 
                                    customer_id: job.property.customer_id, 
                                    property_id: job.property.id 
                                }
                            }"
                            class="black-link-underlined relative"
                            v-if="job.property.customer"
                        >
                            {{ job.property.property_name }}
                        </router-link>
                        <router-link
                            :to="{ 
                                name: 'CommercialPropertyWithoutCustomer', 
                                params: { property_id: job.property.id }
                            }"
                            class="black-link-underlined relative"
                            v-else
                        >
                            {{ job.property.property_name }}
                        </router-link>
                    </span>
                </div>
                <div class="mb-3">
                    <span class="f-12-grey">
                        Address:
                        <a
                            :href="getGoogleMapLink(job.property.addresses.main[0].lat, job.property.addresses.main[0].lng)"
                            :class="[
                                'pl-1',
                                job.property.addresses.main.length ? 'f-12-blue' : 'f-italic',
                                {'none-events': !getGoogleMapLink(job.property.addresses.main[0].lat, job.property.addresses.main[0].lng)}
                            ]"
                            target="_blank"
                        >
                            {{
                                job.property.addresses.main.length 
                                ? getAddress(job.property.addresses.main[0])
                                : 'No information'
                            }}
                            <link-icon 
                                v-if="getGoogleMapLink(job.property.addresses.main[0].lat, job.property.addresses.main[0].lng)"
                                class="i-blue"
                            />
                        </a>
                    </span>
                </div>
                <p
                    v-if="job.property.addresses.mailing.length"
                    class="f-12-grey pb-3"
                >
                    Mailing Address:
                    <a
                        :href="getGoogleMapLink(job.property.addresses.mailing[0].lat, job.property.addresses.mailing[0].lng)"
                        :class="[
                            'pl-1',
                            job.property.addresses.mailing.length ? 'f-12-blue' : 'f-italic',
                            {'none-events': !getGoogleMapLink(job.property.addresses.mailing[0].lat, job.property.addresses.mailing[0].lng)}
                        ]"
                        target="_blank"
                    >
                        {{
                            job.property.addresses.mailing.length 
                            ? getAddress(job.property.addresses.mailing[0])
                            : 'No information'
                        }}
                        <link-icon 
                            v-if="getGoogleMapLink(job.property.addresses.mailing[0].lat, job.property.addresses.mailing[0].lng)"
                            class="i-blue"
                        />
                    </a>
                </p>
                <p 
                    class="s-align-4 f-12-grey pb-3"
                    v-if="job.property.division"
                >
                    Division: 
                    <span class="orange-label s-align-5 f-11-white f-sbold ml-2">{{ job.property.division }}</span>
                </p>
                <p 
                    class="s-align-4 f-12-grey pb-3"
                    v-if="job.property.designation"
                >
                    Designation:
                    <span class="blue-label s-align-5 f-11-white f-sbold ml-2">{{ job.property.designation }}</span> 
                </p>
                <p class="f-12-grey">
                    Is selected location: 
                    <span class="f-12-black pl-1">{{ job.property.is_selected_location ? 'Yes' : 'No' }}</span>
                </p>                
            </div>
            <representatives-details-list 
                title="Job"
                :representatives="job.representatives"
                :entityId="job.id"
            />
            <div 
                class="column-start pv-30 w-100"
                v-if="job.unit"
            >
                <h2 class="f-16-darkgrey f-sbold mb-8">Unit</h2>
                <div class="mb-3">
                    <span class="f-12-grey">
                        Unit: 
                        <span class="f-12-black pl-1">
                            <router-link
                                :to="{ 
                                    name: 'CommercialDetails', 
                                    params: { 
                                        customer_id: job.property.customer_id, 
                                        property_id: job.property.id,
                                        unit_id: job.unit.id
                                    }
                                }"
                                class="black-link-underlined relative"
                            >
                                {{ job.unit.name }}
                            </router-link>
                        </span>
                    </span>
                </div>
                <div 
                    v-if="job.unit.store"
                    class="mb-3"
                >
                    <span class="f-12-grey">
                        Store: 
                        <span class="f-12-black pl-1">{{ job.unit.store }}</span>
                    </span>
                </div>
                <p class="f-12-grey">
                    Address:
                    <a
                        :href="getGoogleMapLink(job.unit.lat, job.unit.lng)"
                        :class="[
                            job.unit.full_address ? 'f-12-blue' : 'f-italic',
                            {'none-events': !getGoogleMapLink(job.unit.lat, job.unit.lng)}
                        ]"
                        target="_blank"
                    >
                        {{
                            job.unit.full_address 
                            ? job.unit.full_address 
                            : 'No information'
                        }}
                        <link-icon 
                            v-if="getGoogleMapLink(job.unit.lat, job.unit.lng)"
                            class="i-blue"
                        />
                    </a>
                </p>
            </div>
            <div 
                class="column-start pv-30 w-100"
                v-if="tags.length"
            >
                <h2 class="f-16-darkgrey f-sbold pb-8">Labels</h2>
                <div class="row-start">
                    <tag
                        v-for="(tag, index) in tags"
                        :key="`tag-${index}`"
                        :tag="tag"
                        :disabled="true"
                    />
                </div>
            </div>
        </div>
    </perfect-scrollbar>
</template>

<script>
    import { ClockIcon, MapIcon, LinkIcon } from '@/icons';
    import { Loader } from '@/ui';
    import Tag from '@/components/Tag.vue';
    import { showFullAddress, getGoogleMapLink } from '@/helpers';
    import RepresentativesDetailsList from '@/components/RepresentativesDetailsList.vue';
    import api from '@/headers.js';

    export default {
        name: 'CommercialJobListDetails',
        props: {
            jobId: {
                type: Number,
                default: 0
            },
            tags: {
                type: Array,
                default: function() {
                    return [];
                }
            }
        },
        components: {
            ClockIcon,
            Loader,
            MapIcon,
            LinkIcon,            
            RepresentativesDetailsList,
            Tag
        },
        data() {
            return {
                loading: false,
                job: {}
            }
        },
        computed: {
            addressIsCorrect() {
                return this.job.lat != null && this.job.lng != null;
            }
        },
        methods: {
            // Method imported from helpers.js
            getGoogleMapLink,
            getJob() {
                this.loading = true;

                 api.get(`/commercial/jobs/${this.jobId}`)
                    .then(response => {
                        this.job = response.data.data;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);         
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            getAddress(obj, type) {
                return showFullAddress(obj, type);
            },
            jobPrefixAndNumber() {
                return `${this.job.prefix ? this.job.prefix + '-' : ''}${this.job.job_number ? this.job.job_number : ''}`;
            }
        },
        created() {
            this.getJob();
        }
    }
</script>