<template>
    <div class="widget" ref="makeCallWidget"></div>
</template>

<script>
const CALL_TRACKING_METRICS_HOST = "https://app.calltrackingmetrics.com";

export default {
    name: 'CtmPhone',
    data() {
        return {
            refWidget: null,
            iframeNode: null,
            iframeWidgetIsLoaded: false
        }
    },
    methods: {
        createWidgetIframe() {
            const widgetFrame = `
                <iframe
                    allow="geolocation; microphone; camera; midi; encrypted-media;"
                    style="min-width:350px;width:350px;height:100vh;border:none;"
                    id="phone"
                    src="${CALL_TRACKING_METRICS_HOST}/calls/phone?embed=web"
                    crossorigin="anonymus"
                ></iframe>
            `;
            const container = document.querySelector('.widget');
            container.innerHTML = widgetFrame;
        },
        widgetIframeIsLoaded() {
            this.iframeWidgetIsLoaded = true;
            this.$emit('callWidgetIsLoaded', true);
        },
        phoneWidgetNotLoadedHadler() {
            if (!this.iframeWidgetIsLoaded) {
                this.notifyError('Phone widget has not finished loading. Try again.');
                return false;
            }

            return true;
        },
        eventMessageCallback(e) {
            if (CALL_TRACKING_METRICS_HOST == e.origin) {
                let i = e.data;
                const eventEmitsData = {
                    detail: {
                        type: "call",
                        data: i
                    }
                };

                switch (i.event) {
                    case "login":
                        this.$emit("login", { detail: i });
                        break;
                    case "loaded":
                        this.$emit("loaded", eventEmitsData);
                        break;
                    case "answeredCall":
                        this.$emit("answered", eventEmitsData);
                        break;
                    case "incomingCall":
                        this.$emit("incoming", eventEmitsData);
                        break;
                    case "callEnd":
                        this.$emit("end", eventEmitsData);
                }
            }
        },
        muteCall(e) {
            const loadHandle = this.phoneWidgetNotLoadedHadler();

            if (!loadHandle) {
                return;
            }

            let t = { cmd: "mute", options: {} };
            this.iframeNode.contentWindow.postMessage(t, CALL_TRACKING_METRICS_HOST);
        },
        holdCall(e) {
            const loadHandle = this.phoneWidgetNotLoadedHadler();

            if (!loadHandle) {
                return;
            }

            let t = { cmd: "hold", options: {} };
            this.iframeNode.contentWindow.postMessage(t, CALL_TRACKING_METRICS_HOST);
        },
        hangupCall(e) {
            const loadHandle = this.phoneWidgetNotLoadedHadler();

            if (!loadHandle) {
                return;
            }

            let t = { cmd: "hangup", options: {} };
            this.iframeNode.contentWindow.postMessage(t, CALL_TRACKING_METRICS_HOST);
        },
        answerCall(e) {
            const loadHandle = this.phoneWidgetNotLoadedHadler();

            if (!loadHandle) {
                return;
            }

            let t = { cmd: "answer", options: {} };
            this.iframeNode.contentWindow.postMessage(t, CALL_TRACKING_METRICS_HOST);
        },
        outboundCall(e) {
            if (e.detail && e.detail.phoneNumber) {
                const loadHandle = this.phoneWidgetNotLoadedHadler();

                if (!loadHandle) {
                    return;
                }

                let t = {
                    cmd: "call",
                    options: {
                        to_number: e.detail.phoneNumber
                    }
                };
                
                if (e.detail.trackingNumber) {
                    t.options.from_number = e.detail.trackingNumber;
                }

                this.iframeNode.contentWindow.postMessage(t, CALL_TRACKING_METRICS_HOST);
            } else {
                this.notifyError('Missing required phone number for make call');
            }
        },
        dial(e, t) {
            this.outboundCall({ detail: { phoneNumber: e, trackingNumber: t } });
        },
        sendDigit(e) {
            this.keyPress({ detail: { digit: e } });
        },
        keyPress(e) {
            if (e.detail && e.detail.digit) {
                const loadHandle = this.phoneWidgetNotLoadedHadler();

                if (!loadHandle) {
                    return;
                }

                let t = { cmd: "digit", options: { digit: e.detail.digit } };
                this.iframeNode.contentWindow.postMessage(t, CALL_TRACKING_METRICS_HOST);
            }
        },
        clearDialPad() {
            const loadHandle = this.phoneWidgetNotLoadedHadler();

            if (!loadHandle) {
                return;
            }

            let e = { cmd: "cleardialpad", options: {} };
            this.iframeNode.contentWindow.postMessage(e, CALL_TRACKING_METRICS_HOST);
        },
        setNumber(e) {
            const loadHandle = this.phoneWidgetNotLoadedHadler();

            if (!loadHandle) {
                return;
            }

            let t = { cmd: "setdialpad", options: { number: e } };
            this.iframeNode.contentWindow.postMessage(t, CALL_TRACKING_METRICS_HOST);
        }
    },
    mounted() {
        this.createWidgetIframe();

        this.refWidget = this.$refs.makeCallWidget;
        this.$store.commit('telephony/SET_WIDGET_REF', this.refWidget);
        this.iframeNode = this.refWidget.querySelector('iframe');
        this.iframeNode.addEventListener('load', this.widgetIframeIsLoaded);

        window.addEventListener("message", this.eventMessageCallback);
        this.iframeNode.addEventListener("mute", this.muteCall);
        this.iframeNode.addEventListener("hold", this.holdCall);
        this.iframeNode.addEventListener("hangup", this.hangupCall);
        this.iframeNode.addEventListener("answer", this.answerCall);
        this.iframeNode.addEventListener("dial", this.outboundCall);
        this.iframeNode.addEventListener("key", this.keyPress);
    },
    destroyed() {
        window.removeEventListener("message", this.eventMessageCallback, false);
        this.iframeNode.removeEventListener("mute", this.muteCall, false);
        this.iframeNode.removeEventListener("hold", this.holdCall, false);
        this.iframeNode.removeEventListener("hangup", this.hangupCall, false);
        this.iframeNode.removeEventListener("answer", this.answerCall, false);
        this.iframeNode.removeEventListener("dial", this.outboundCall, false);
        this.iframeNode.removeEventListener("key", this.keyPress, false);
    }
}
</script>
