export const customFieldInPrimarySection = {
    data() {
        return {
            customPrimarySection: [],
            customPrimarySectionParameters: {},
        }
    },
    computed: {
        showPhoneExtInPrmaryFields() {
            if (this.phonePrimarySectionParameters 
                    && this.phonePrimarySectionParameters.extension) {
                return this.phonePrimarySectionParameters.extension;
            }
        },
        showTooltipInPrmaryFields() {
            if (this.phonePrimarySectionParameters 
                    && this.phonePrimarySectionParameters.show_tooltip) {
                return this.phonePrimarySectionParameters.show_tooltip 
                    && this.phonePrimarySectionParameters.tooltip_text;
            }
        },
        showPhoneExtInCreateLead() {
            if (this.customPrimarySectionParameters && !this.customFieldsLoading) {
                return this.customPrimarySectionParameters.extension;
            }
        },
        showTooltipInCreateLead() {
            if (this.customPrimarySectionParameters && !this.customFieldsLoading) {
                return this.customPrimarySectionParameters.show_tooltip 
                    && this.customPrimarySectionParameters.tooltip_text;
            }
        }
    },
    methods: {
        getCustomPrimarySection() {
            let sectionIndex = this.customFields && this.customFields.sections
                ? this.customFields.sections.findIndex(el => el.is_primary)
                : -1;

            if (sectionIndex !== -1) {
                this.customPrimarySection.push(...this.customFields.sections.splice(sectionIndex, 1));
                this.parseFieldParameters();
            }
        },
        parseFieldParameters() {
            let fields = [];
            this.customPrimarySection.forEach((section) => {
                fields = section.fields;

                if (fields.length) {
                    fields.forEach(({id, type, parameters}) => {
                        this.customPrimarySectionParameters = typeof parameters === 'string' 
                            ? JSON.parse(parameters) : parameters;
                    })
                }
            });
        },
    }
}