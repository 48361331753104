var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-date-range-input row-baseline row-between relative pointer",style:({ width: _vm.isFullWidth ? '100%' : 'auto' }),attrs:{"id":("form-date-range-input-" + _vm.name)},on:{"click":function($event){_vm.dateMenuShow = !_vm.dateMenuShow}}},[_c('div',{staticClass:"form-date-block"},[_c('calendar-icon',{attrs:{"size":"16"}}),_c('div',{staticClass:"date-picker-from column-start"},[(_vm.range.end)?_c('span',{staticClass:"date ml-2"},[_vm._v("\n                "+_vm._s(_vm._f("date")(_vm.range.start))+"\n            ")]):_vm._e(),_c('span',{staticClass:"placeholder ellipsis ml-2"},[_vm._v("From")])]),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"date-picker-to column-start"},[(_vm.range.end)?_c('span',{staticClass:"date ml-2"},[_vm._v("\n                "+_vm._s(_vm._f("date")(_vm.range.end))+"\n            ")]):_vm._e(),_c('span',{staticClass:"placeholder ellipsis ml-2"},[_vm._v("To")])])],1),(_vm.dateMenuShow)?_c('div',{class:[
            'date-menu',
            _vm.mScreen ? 'column-start' : 'row-between pl-4'
        ],style:(_vm.stylePopup),attrs:{"id":("date-menu-" + _vm.name)},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-date-picker',{attrs:{"mode":"range","is-inline":"","tintColor":_vm.tintColor,"attributes":_vm.attributes,"disabledAttribute":_vm.disabledAttribute,"themeStyles":_vm.themeStyles},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}}),_c('div',{class:[
            'date-menu-buttons w-100 column-center p-4', 
            { 'ml-4' : !_vm.mScreen }
        ]},_vm._l((_vm.menuButtons),function(button){return _c('button',{key:button.title,class:[
                    'add-button mb-1', 
                    { 'active-button' : _vm.defaultDate.toUpperCase() == button.title.toUpperCase() }
                ],on:{"click":function($event){$event.stopPropagation();button.method(), _vm.dateMenuShow = false}}},[_vm._v("\n                "+_vm._s(button.title)+"\n            ")])}),0)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }