<template>
    <div 
        :class="['file-box relative', 'column-start', 
            { 'file-box-selected' : selectMode && selected },
            { 'pointer' : selectMode }
        ]"
        @click="selectFileBox"
    >
        <select-input v-if="selected">
            <input 
                type="checkbox" 
                name="select"
                checked
            />
        </select-input>
        <component
            :is="iconWrapperComponent"
            :class="[
                'file-box-icon s-align-5',
                { 'pointer' : file.type == FOLDER_TYPE },
                disabledFolder && 'disabled'
            ]"
            @click="onWrapperClick"
        >
            <document-icon v-if="documentExtension && !file.parent_id"/>
            <smart-doc-preview-icon v-if="file.parent_id"/>
            <folder-icon
                palette="blue"
                :class="disabledFolder && 'opacity-1'"
                v-if="file.type == FOLDER_TYPE"
            />
            <zip-icon v-if="file.type == 'zip'"/>
            <video-icon v-if="videoExtension"/>
            <img
                :src="`${url}${file.thumbnail}`"
                class="file-box-img"
                alt="img"
                v-if="imgExtension"
                draggable="false"
            />
        </component>
        <div class="file-box-info column-start relative">
            <div class="row-between relative">
                <div class="s-align-4 w-85">
                    <div
                        class="w-75"
                        @click="movingThroughFolders"
                    >
                        <input
                            type="text"
                            name="file_name"
                            v-model="file.original_name"
                            :class="[
                                'file-box-input', 'ellipsis',
                                { 'pointer' : !editName && file.type == FOLDER_TYPE },
                                { 'file-box-input-danger' : errors.has('file_name') }
                            ]"
                            :disabled="!editName"
                            v-validate="file.type === FOLDER_TYPE ?
                                        { required: true, min: 3, max: 255, regex: folderTitleRegex } :
                                        { required: true, min: 3, max: 255, regex: fileTitleRegex }"
                        />
                    </div>
                    <button
                        class="transparent-button ml-2"
                        v-if="editName"
                        @click="cancelEditName"
                    >
                        <close-icon class="i-grey"/>
                    </button>
                    <button
                        class="mark-button ml-2"
                        v-if="editName"
                        @click="saveEditingName"
                    >
                        <mark-icon size="12"/>
                    </button>
                </div>
                <context-menu
                    :unicId="`file-box-menu-${index}`"
                    v-can="[$permissions.EDIT_LEAD]"
                >
                    <button
                        v-if="file.parent_id"
                        class="s-align-4"
                        @click="editLocalSmartDoc(file)"
                    >
                        <span class="icon-container mr-6">
                            <smart-doc-icon/>
                        </span>
                        <span>Edit local Smart</span>
                    </button>
                    <button
                        class="s-align-4"
                        v-can="[$permissions.EDIT_DOCUMENTS]"
                        @click="addFileBoxToSelected('download')"
                    >
                        <span class="icon-container mr-6">
                            <download-icon/>
                        </span>
                        <span>Download</span>
                    </button>
                    <button
                        class="s-align-4"
                        v-can="[$permissions.EDIT_DOCUMENTS]"
                        v-if="file.type != FOLDER_TYPE"
                        @click="addFileBoxToSelected('move')"
                    >
                        <span class="icon-container mr-6">
                            <pointer-icon/>
                        </span>
                        <span>Move to...</span>
                    </button>
                    <button
                        v-if="!disabledCRUDAction"
                        class="s-align-4"
                        v-can="[$permissions.EDIT_DOCUMENTS]"
                        @click="startEditName"
                    >
                        <span class="icon-container mr-6">
                            <edit-icon/>
                        </span>
                        <span>Edit name</span>
                    </button>
                    <button
                        v-if="!disabledCRUDAction"
                        class="s-align-4"
                        v-can="[$permissions.DELETE_DOCUMENTS]"
                        @click="addFileBoxToSelected('remove')"
                    >
                        <span class="icon-container mr-6">
                            <trash-icon width="15"/>
                        </span>
                        <span>Delete</span>
                    </button>
                </context-menu>
            </div>
            <p
                class="f-12-grey pl-1"
                v-if="file.user"
            >
                Added by: <span class="f-12-black">{{ file.user.fullname }}</span>
            </p>
            <div class="row-between row-baseline pl-1">
                <span
                    class="f-10-grey"
                    v-if="file.created_at"
                >
                    {{ file.created_at | createdAt }}
                </span>
                <div
                    v-if="file.all_files_count !== undefined"
                    class="s-align-6"
                >
                    <span class="f-10-grey">Files</span>
                    <span class="file-box-count s-align-5 mh-2 f-11-blue">
                        {{ file.all_files_count }}
                    </span>
                </div>
            </div>
        </div>
        <file-preview-modal
            v-if="previewModalOpened"
            :file="file"
            @close="previewModalOpened = false"
        />
    </div>
</template>

<script>
    import { FilePreviewModal } from '@/modals';
    import { SelectInput } from '@/ui';
    import ContextMenu from '@/components/ContextMenu.vue';
    import api from '@/headers.js';
    import {
        DocumentIcon,
        FolderIcon,
        ZipIcon,
        VideoIcon,
        PointsIcon,
        DownloadIcon,
        PointerIcon,
        EditIcon,
        TrashIcon,
        MarkIcon,
        CloseIcon,
        SmartDocIcon,
        SmartDocPreviewIcon
    } from '@/icons';
    import {
        FILE_EXTENSIONS,
        ENTITY_TYPES,
        ALLOWED_CHARACTERS_FILE,
        ALLOWED_CHARACTERS_FOLDER,
        FOLDER_TYPE
    } from '@/constants.js';

    export default {
        name: 'FileBox',
        props: {
            file: {
                type: Object,
                default: null
            },
            selectMode: {
                type: Boolean,
                default: false
            },
            index: {
                type: [Number, String],
                required: true
            },
            entityType: {
                type: String,
                required: true
            },
            disabledCRUDAction: {
                type: Boolean,
                default: false
            }
        },
        components: {
            FilePreviewModal,
            DocumentIcon,
            FolderIcon,
            ZipIcon,
            VideoIcon,
            PointsIcon,
            DownloadIcon,
            PointerIcon,
            EditIcon,
            TrashIcon,
            SelectInput,
            MarkIcon,
            CloseIcon,
            ContextMenu,
            SmartDocIcon,
            SmartDocPreviewIcon
        },
        data() {
            return {
                isFileBoxMenuOpen: false,
                selected: false,
                editName: false,
                savedName: '',
                url: process.env.VUE_APP_AMAZONAWS_URL,
                extension: '',
                previewModalOpened: false,
                disabledFolder: false,
                fileTitleRegex: ALLOWED_CHARACTERS_FILE,
                folderTitleRegex: ALLOWED_CHARACTERS_FOLDER,
                FOLDER_TYPE: FOLDER_TYPE
            }
        },
        watch: {
            selectMode: function(newVal, oldVal) {
                !newVal
                    ? this.selected = false
                    : '';
            }
        },
        computed: {
            documentExtension() {
                return FILE_EXTENSIONS.DOCUMENTS.some(type => type.toLowerCase() == this.file.type.toLowerCase());
            },
            videoExtension() {
                return FILE_EXTENSIONS.VIDEOS.some(type => type.toLowerCase() == this.file.type.toLowerCase());
            },
            imgExtension() {
                return FILE_EXTENSIONS.IMAGES.some(type => type.toLowerCase() == this.file.type.toLowerCase());
            },
            iconWrapperComponent() {
                return this.documentExtension || this.imgExtension ? 'button' : 'div';
            },
            entityId() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD:
                    case ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return this.$route.params.lead_id;
                    }
                    case ENTITY_TYPES.COMMERCIAL_JOB: {
                        return this.$route.params.job_id;
                    }
                }
            }
        },
        methods: {
            editLocalSmartDoc(file) {
                api.get(`/smart-documents/by-file?file_id=${file.parent_id}`)
                    .then(response => {
                        let smartDoc = response.data.data;
                        localStorage.setItem("packetRedirectUrl", this.$route.fullPath);
                        this.$router.push({
                            name: 'SmartDocBuilder',
                            params: { id: file.parent_id},
                            query: {
                                mode: 'create-local',
                                entity: smartDoc.entity_type,
                                entity_id: this.entityId,
                            }
                        })
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            selectFileBox() {
                if (!this.selectMode) return false;

                this.selected = !this.selected;
                this.$emit('chosen', { status: this.selected, file: this.file });
            },
            onWrapperClick() {
                if (!this.selectMode && !this.editName && (this.documentExtension || this.imgExtension)) {
                    this.previewModalOpened = true;
                    return;
                }

                this.movingThroughFolders();
            },
            movingThroughFolders() {
                if (this.disabledFolder) {
                    return;
                }

                if (!this.selectMode && this.file.type == FOLDER_TYPE && !this.editName) {
                    this.$emit('traverse', this.file.id);
                }
            },
            startEditName() {
                this.editName = true;
                this.isFileBoxMenuOpen = false;

                if (this.file.type != FOLDER_TYPE) {
                    let regexp = /\.[0-9a-z]+$/i;

                    this.extension = this.file.id.match(regexp)[0];
                    this.file.original_name = this.file.original_name.replace(regexp, '');
                }

                // keep old file or folder name, if user cancels changes
                this.savedName = this.file.original_name;
            },
            cancelEditName() {
                this.editName = false;
                this.file.original_name = this.savedName;
            },
            saveEditingName() {
                this.$validator.validateAll().then(result => {
                    if (result) {

                        if (this.file.original_name == this.savedName) {
                            this.editName = false;
                            return false;
                        }

                        this.editName = false;

                        // if the file was not a folder
                        // need to return the extension to the file name
                        this.file.original_name = this.file.type == FOLDER_TYPE
                            ? this.file.original_name
                            : `${this.file.original_name}${this.extension}`;

                        let params = {
                            file: this.file.id,
                            new_name: this.file.original_name
                        };
                        this.disabledFolder = true;

                        api.post(`document/rename`, params)
                            .then(response => {
                                this.notifySuccess('Name has been saved!');

                                if (this.file.type == FOLDER_TYPE) {
                                    const staticPart = this.file.id.slice(0, this.file.id.lastIndexOf('/') + 1);
                                    this.file.id = `${staticPart}${this.file.original_name}`;
                                }
                            })
                            .catch(error => {
                                this.notifyRequestErrors(error);
                            })
                            .finally(() => this.disabledFolder = false);

                    } else if (this.file.original_name === '' || this.file.original_name.length <= 2) {
                        this.notifyError('The Name field must be at least 3 characters');
                    } else if (this.file.type === FOLDER_TYPE) {
                        this.notifyError('Valid characters include A-z, 0-9, and (_-)');
                    } else {
                        this.notifyError('Valid characters include A-z, 0-9, and (._-)');
                    }
                })
            },
            // download, move to and delete from file menu
            addFileBoxToSelected(actionType) {
                if (actionType === 'download' 
                    && this.file.all_files_count !== undefined 
                    && !this.file.all_files_count
                ) {
                    this.notifyError('You can\'t download empty folder!');
                    this.isFileBoxMenuOpen = false;
                    return;
                }

                this.isFileBoxMenuOpen = false;
                this.$emit(actionType, this.file);
            },
            outside(event) {
                if (!this.$el.contains(event.target) && this.isFileBoxMenuOpen)
                    this.isFileBoxMenuOpen = false;
            }
        },
        mounted() {
            document.body.addEventListener('click', this.outside, false);
        },
        destroyed() {
            document.body.removeEventListener('click', this.outside, false);
        }
    }
</script>
