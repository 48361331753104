<template>
    <full-modal
        @close="$emit('close')"
        class="view-log-modal"
    >
        <div slot="title">Edit Pre-Scheduled Crew</div>
        <div
            slot="body"
            v-if="loading"
        >
            <Loader size="small"/>
        </div>
        <div
            slot="body"
            class="mb-30"
            v-else
        >
            <div class="row-end mb-2">
                <div
                    class="underline f-9-grey pointer s-align-6"
                    @click="assignedCrew = null"
                >
                    Clear
                </div>
            </div>
            <multiselect
                v-model="assignedCrew"
                :class="[
                    'mb-4',
                    { 'multiselect-danger' : errors.has('assign_to') || crewIsEmpty }
                ]"
                placeholder="Assign to"
                label="name"
                track-by="name"
                name="assign_to"
                data-name="name"
                :options="crews"
                :option-height="104"
                :show-labels="false"
                :allow-empty="false"
                :multiple="true"
            />
        </div>
        <div slot="footer">
            <button
                class="add-button mr-4"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                @click="updatePreSchedulerCrew"
                :disabled="loading"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>
<script>
    import { FullModal, Loader } from '@/ui';
    import Multiselect from 'vue-multiselect';
    import api from '@/headers.js';
    import { BASE_ENTITY_TYPES } from '@/constants';

    export default {
        name: 'PreScheduledCrewEditModal',
        components: { FullModal, Loader, Multiselect },
        props: {
            crewId: {
                type: [String, Number],
                default: null
            },
            entityType: {
                type: String,
                default: null
            },
            enyityId: {
                type: [String, Number],
                required: true
            },
            assigneCrew: {
                type: Array,
                default: []
            }
        },
        data() {
            return {
                assignedCrew: null,
                loading: false,
                crews: []
            }
        },
        computed: {
            crewIsEmpty() {
                return !(!!this.assignedCrew && !!this.assignedCrew.length);
            },
            getEntityType() {
                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        return BASE_ENTITY_TYPES.COMMERCIAL_JOB;
                    case BASE_ENTITY_TYPES.RESIDENTIAL:
                        return BASE_ENTITY_TYPES.RESIDENTIAL_JOB;
                    case BASE_ENTITY_TYPES.WARRANTY_JOB:
                        return BASE_ENTITY_TYPES.WARRANTY_JOB;
                    default:
                        return '';
                }
            }
        },
        methods: {
            getCrews() {
                this.loading = true;
                api.get('/crews?perPage=999')
                    .then(response => {
                        this.crews.push(...response.data.data.data);
                    })
                    .catch(error => {
                        this.notifyRequestError(error);
                    })
                    .finally(() => this.loading = false);
            },
            setCrew() {
                this.assignedCrew = this.assigneCrew;
            },
            updatePreSchedulerCrew() {
                if (this.crewIsEmpty) {
                    this.notifyError('The Assign to field is required.');
                    return;
                }

                this.loading = true;
                const crewIds = this.assignedCrew && this.assignedCrew.length 
                    ? this.assignedCrew.map(el => el.id)
                    : [];

                const params = {
                    entityId: Number(this.enyityId),
                    entityType: this.getEntityType,
                    crewIds: crewIds
                };

                api.post(`/crews/entities`, params)
                    .then((response) => {
                        this.$emit('updateAssignedCrew', this.assignedCrew);
                    })
                    .catch((error) => this.notifyRequestErrors(error))
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        created() {
            this.getCrews();
            this.setCrew();
        },
        activated() {
            this.$store.commit('views/FULL_MODAL_OPEN', true);
            this.setCrew();
        },
        deactivated() {
            this.$store.commit('views/FULL_MODAL_OPEN', false);
        }
    }
</script>