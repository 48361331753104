<template>
    <svg
        :width="size"
        :height="size"
        viewBox="0 0 26 30"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M12.8786 3.84609C16.2942 3.84609 19.57 5.22384 21.9852 7.67624C24.4005 10.1286 25.7573 13.4548
            25.7573 16.923C25.7573 20.3912 24.4005 23.7174 21.9852 26.1698C19.57 28.6222 16.2942 29.9999 12.8786 29.9999C9.46295 29.9999
            6.1872 28.6222 3.77197 26.1698C1.35674 23.7174 -0.00012207 20.3912 -0.00012207 16.923C-0.00012207 13.4548 1.35674 10.1286 3.77197
            7.67624C6.1872 5.22384 9.46295 3.84609 12.8786 3.84609V3.84609ZM12.8786 8.46148C12.604 8.46149 12.3387 8.56247 12.1317 8.74573C11.9248
            8.929 11.7902 9.18216 11.7528 9.4584L11.7422 9.61532V16.5384L11.7528 16.6953C11.7903 16.9715 11.9249 17.2246 12.1318 17.4078C12.3388
            17.591 12.6041 17.6919 12.8786 17.6919C13.1531 17.6919 13.4184 17.591 13.6254 17.4078C13.8323 17.2246 13.9669 16.9715 14.0044 16.6953L14.015
            16.5384V9.61532L14.0044 9.4584C13.967 9.18216 13.8324 8.929 13.6255 8.74573C13.4185 8.56247 13.1532 8.46149 12.8786 8.46148V8.46148ZM23.7422
            4.03532L23.8664 4.12917L25.6073 5.66763C25.8255 5.85943 25.9636 6.12832 25.9937 6.41974C26.0238 6.71116 25.9435 7.00328 25.7692 7.23682C25.5949
            7.47036 25.3397 7.62783 25.0552 7.67726C24.7708 7.7267 24.4785 7.6644 24.2376 7.50302L24.1149 7.40917L22.374 5.87071C22.1577 5.6785 22.0212 5.41022
            21.992 5.1199C21.9627 4.82957 22.0429 4.53878 22.2164 4.30609C22.3898 4.07339 22.6437 3.91608 22.9268 3.86584C23.2099 3.8156 23.5013 3.87616 23.7422
            4.03532ZM16.2877 -6.10352e-05C16.5756 2.85688e-05 16.8527 0.111084 17.0631 0.310666C17.2735 0.510248 17.4014 0.783475 17.421 1.07514C17.4407 1.3668
            17.3505 1.65516 17.1688 1.88194C16.9871 2.10872 16.7274 2.25701 16.4422 2.29686L16.2877 2.30763H9.46953C9.18161 2.30754 8.90447 2.19649 8.69409
            1.9969C8.48371 1.79732 8.35578 1.5241 8.33616 1.23243C8.31654 0.940767 8.40668 0.652413 8.58837 0.425633C8.77006 0.198853 9.02976 0.0505565
            9.31498 0.0107081L9.46953 -6.10352e-05H16.2877Z" fill="#80B5FE"
        />
    </svg>

</template>

<script>
    export default {
        name: 'WatchProgressIcon',
        props: {
            size: {
                type: Number,
                default: 30
            }
        }
    }
</script>
