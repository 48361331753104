<template>
    <svg 
	    viewBox="0 0 781.0154418945312 781.2987670898438"
        :width="size" 
        :height="size"
        class="inbox-email-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="781.0154418945312" 
            height="781.2987670898438" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(12.43654727935791, 0, 0, 12.43654727935791, 10792.475341796875, -10711.59765625)">
            <g>
                <path 
                    class="st0" 
                    d="M-837.2,897L-837.2,897l-4.6-3.8c-0.9-0.8-2.4-0.8-3.4,0l-20.7,18.2c0.2,0.1,0.5,0.1,0.8,0.1h25.3c-0.3-1.3-0.5-2.7-0.5-4.2C-840.3,903.5-839.2,900-837.2,897z"
                />
                <path 
                    class="st0" 
                    d="M-867.5,881.5v28.1c0,0.2,0,0.3,0.1,0.5l16.4-14.4L-867.5,881.5z"
                />
                <path 
                    class="st0" 
                    d="M-849.5,894.5l3.1-2.7c1.7-1.4,4.3-1.4,5.9,0l4.1,3.4l18.2-15.7l-22.4-17c-1.1-0.9-2.9-0.9-4,0l-22.3,17L-849.5,894.5z"
                />
                <path 
                    class="st0" 
                    d="M-827,889.7c1.6-0.5,3.3-0.7,5.1-0.7c1.5,0,2.9,0.2,4.3,0.5v-7.9L-827,889.7z"
                />
            </g>
            <path 
                class="st1" 
                d="M-821.9,891c-9.1,0-16.4,7.4-16.4,16.4c0,9.1,7.4,16.4,16.4,16.4s16.4-7.4,16.4-16.4C-805.5,898.3-812.9,891-821.9,891z M-814.9,908.3h-11.7l3.5,3.5c0.4,0.4,0.4,1,0,1.4c-0.2,0.2-0.5,0.3-0.7,0.3s-0.5-0.1-0.7-0.3l-5.2-5.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.3-0.1-0.6,0-0.8c0-0.1,0.1-0.2,0.2-0.3l5.2-5.2c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-3.5,3.5h11.7c0.6,0,1,0.4,1,1C-813.9,908-814.3,908.3-814.9,908.3z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'InboxEmailIcon',
    props: {
        size: {
            type: [Number, String],
            default: 30
        }
    }
}
</script>