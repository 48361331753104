import api from '@/headers.js';

import { 
    SET_REQUEST_STATUS,
    USERS_PUSH,
    USERS_RESET,
    USERS_GET,
    USER_ADD,
    USER_UPDATE,
    USER_DELETE,
    USER_SAVE,
} from '../actions/users';

export default {
    namespaced: true,
    state: {
        status: '',
        list: []
    },
    getters: {
        isLoading: state => state.status === 'pending',
        userList: state => state.list,
        getById: state => id => {
            return state.list.find(item => item.id == id);
        }
    },
    mutations: {
        [SET_REQUEST_STATUS] (state, payload) {
            state.status = payload;
        },
        [USERS_PUSH] (state, payload) {
            state.list.push(...payload);
        },
        [USERS_RESET] (state, payload) {
            state.list = payload;
        },
        [USER_ADD] (state, payload) {
            state.list.push(payload);
        },
        [USER_UPDATE] (state, payload) {
            const index = state.list.findIndex(user => {
                return user.id === payload.id;
            });
            if (index > -1) {
                state.list.splice( index, 1, payload);
            }
        },
        [USER_DELETE] (state, payload) {
            state.list = state.list.filter(el => el.id !== payload);
        }
    },
    actions: {
        [USERS_GET]({ commit }, params) {
            return new Promise((resolve, reject) => {
                commit(SET_REQUEST_STATUS, 'pending');
                api.get('/users', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        commit(SET_REQUEST_STATUS, 'success');
                        resolve(data);
                    })
                    .catch(error => {
                        commit(SET_REQUEST_STATUS, 'error');
                        reject(error);
                    })
            })
        },
        [USERS_PUSH]({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit(USERS_PUSH, data);
                resolve(data);
            });
        },
        [USERS_RESET]({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit(USERS_RESET, data);
                resolve(data);
            })
        },
        [USER_UPDATE]({ commit }, data) {
            return new Promise((resolve, reject) => {
                // commit(SET_REQUEST_STATUS, 'pending');
                api.put(`/users/${data.id}`, data)
                    .then(response => response.data.data)
                    .then(data => {
                        // commit(SET_REQUEST_STATUS, 'success');
                        commit('USER_UPDATE', data);
                        resolve(data);
                    })
                    .catch(error => {
                        commit(SET_REQUEST_STATUS, 'error');
                        reject(error);
                    });
            });
        },
        [USER_SAVE]({ commit }, data) {
            return new Promise((resolve, reject) => {
                // commit(SET_REQUEST_STATUS, 'pending');
                api.post(`/users/`, data)
                    .then(response => response.data.data)
                    .then(data => {
                        // commit(SET_REQUEST_STATUS, 'success');

                        // this throws duplicate keys error when the added user reappears on a new page from the backend
                        // commit('USER_ADD', data);

                        resolve(data);
                    })
                    .catch(error => {
                        // commit(SET_REQUEST_STATUS, 'error');
                        reject(error);
                    })
            })
        },
        [USER_DELETE]({ commit }, id) {
            const query = `?users[]=${id}`
            return new Promise((resolve, reject) => {
                // commit(SET_REQUEST_STATUS, 'pending');
                api.delete(`/users${query}`)
                    .then(response => response.data)
                    .then(data => {
                        // commit(SET_REQUEST_STATUS, 'success');
                        commit(USER_DELETE, id);
                        resolve(data);
                    })
                    .catch(error => {
                        commit(SET_REQUEST_STATUS, 'error');
                    })
            })
        },
    }
}
