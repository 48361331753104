<template>
    <svg 
	    viewBox="0 0 512.008 512.008"
        :width="size" 
        :height="size"
        class="reply-icon"
    >
        <g>
            <g>
                <path d="M507.328,228.708l-160-160.032c-4.576-4.576-11.392-5.92-17.44-3.456C323.904,67.684,320,73.54,320,80.004v80h-48
                    c-149.984,0-272,122.016-272,272c0,7.136,4.736,13.408,11.584,15.36c1.472,0.448,2.944,0.64,4.416,0.64
                    c5.408,0,10.592-2.752,13.568-7.52l4.8-7.68c43.424-69.568,121.344-112.8,203.392-112.8H320v80c0,6.464,3.904,12.32,9.888,14.784
                    c6.016,2.432,12.864,1.088,17.44-3.456l160-160C513.568,245.092,513.568,234.948,507.328,228.708z M352,361.38v-57.376
                    c0-8.832-7.168-16-16-16h-98.24c-75.488,0-147.936,32.288-198.816,86.624C64.704,269.924,159.424,192.004,272,192.004h64
                    c8.832,0,16-7.168,16-16v-57.376l121.376,121.376L352,361.38z"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'ReplyIcon',
        props: {
            size: {
                type: [Number, String],
                default: 15
            }
        }
    }
</script>