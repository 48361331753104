<template>
    <svg
        viewBox="0 0 52 59"
        :width="size"
        :height="size"
        class="calendar-color-icon"
    >
        <path
            d="M5.09981 10.1498C2.39981 10.1498 0.299805 12.3566 0.299805 14.9646V54.4862C0.299805 56.9939 2.29981 59.0001 4.69981 59.0001H44.4998C46.8998 59.0001 48.8998 56.9939 48.8998 54.4862V15.0649C48.8998 12.3566 46.6998 10.2501 44.0998 10.2501H5.09981V10.1498Z"
            fill="#006BFD"
        />
        <path
            d="M51.2996 14.6636H2.59961V19.3781H51.2996V14.6636Z"
            fill="#BCD6F9"
        />
        <path
            d="M2.59961 18.8766V52.2793C2.59961 54.7871 4.59961 56.7932 6.99961 56.7932H46.7996C49.1996 56.7932 51.1996 54.7871 51.1996 52.2793V18.8766H2.59961Z"
            fill="white"
        />
        <path
            d="M17.2998 51.7777H12.1998C11.3998 51.7777 10.7998 51.1759 10.7998 50.3734V45.2577C10.7998 44.4552 11.3998 43.8533 12.1998 43.8533H17.2998C18.0998 43.8533 18.6998 44.4552 18.6998 45.2577V50.3734C18.6998 51.1759 17.9998 51.7777 17.2998 51.7777Z"
            fill="#BCD6F9"
        />
        <path
            d="M17.2998 41.8472H12.1998C11.3998 41.8472 10.7998 41.2454 10.7998 40.4429V35.3272C10.7998 34.5247 11.3998 33.9229 12.1998 33.9229H17.2998C18.0998 33.9229 18.6998 34.5247 18.6998 35.3272V40.4429C18.6998 41.2454 17.9998 41.8472 17.2998 41.8472Z"
            fill="#BCD6F9"
        />
        <path
            d="M17.2998 31.9167H12.1998C11.3998 31.9167 10.7998 31.3148 10.7998 30.5124V25.3966C10.7998 24.5942 11.3998 23.9923 12.1998 23.9923H17.2998C18.0998 23.9923 18.6998 24.5942 18.6998 25.3966V30.5124C18.6998 31.3148 17.9998 31.9167 17.2998 31.9167Z"
            fill="#BCD6F9"
        />
        <path
            d="M29.5 51.7777H24.4C23.6 51.7777 23 51.1759 23 50.3734V45.2577C23 44.4552 23.6 43.8533 24.4 43.8533H29.5C30.3 43.8533 30.9 44.4552 30.9 45.2577V50.3734C30.9 51.1759 30.3 51.7777 29.5 51.7777Z"
            fill="#00A0FF"
        />
        <path
            d="M29.5 41.8472H24.4C23.6 41.8472 23 41.2454 23 40.4429V35.3272C23 34.5247 23.6 33.9229 24.4 33.9229H29.5C30.3 33.9229 30.9 34.5247 30.9 35.3272V40.4429C30.9 41.2454 30.3 41.8472 29.5 41.8472Z"
            fill="#00A0FF"
        />
        <path
            d="M29.5 31.9167H24.4C23.6 31.9167 23 31.3148 23 30.5124V25.3966C23 24.5942 23.6 23.9923 24.4 23.9923H29.5C30.3 23.9923 30.9 24.5942 30.9 25.3966V30.5124C30.9 31.3148 30.3 31.9167 29.5 31.9167Z"
            fill="#BCD6F9"
        />
        <path
            d="M41.6997 51.7777H36.5997C35.7997 51.7777 35.1997 51.1759 35.1997 50.3734V45.2577C35.1997 44.4552 35.7997 43.8533 36.5997 43.8533H41.6997C42.4997 43.8533 43.0997 44.4552 43.0997 45.2577V50.3734C43.0997 51.1759 42.4997 51.7777 41.6997 51.7777Z"
            fill="#BCD6F9"
        />
        <path
            d="M41.6997 41.8472H36.5997C35.7997 41.8472 35.1997 41.2454 35.1997 40.4429V35.3272C35.1997 34.5247 35.7997 33.9229 36.5997 33.9229H41.6997C42.4997 33.9229 43.0997 34.5247 43.0997 35.3272V40.4429C43.0997 41.2454 42.4997 41.8472 41.6997 41.8472Z"
            fill="#00A0FF"
        />
        <path
            d="M41.6997 31.9167H36.5997C35.7997 31.9167 35.1997 31.3148 35.1997 30.5124V25.3966C35.1997 24.5942 35.7997 23.9923 36.5997 23.9923H41.6997C42.4997 23.9923 43.0997 24.5942 43.0997 25.3966V30.5124C43.0997 31.3148 42.4997 31.9167 41.6997 31.9167Z"
            fill="#00A0FF"
        />
        <path
            d="M46.8996 7.74173H39.6996V2.7263C39.6996 1.82353 38.9996 1.02106 37.9996 1.02106C37.0996 1.02106 36.2996 1.72322 36.2996 2.7263V7.74173H17.4996V2.7263C17.4996 1.82353 16.7996 1.02106 15.7996 1.02106C14.8996 1.02106 14.0996 1.72322 14.0996 2.7263V7.74173H6.99961C4.59961 7.74173 2.59961 9.7479 2.59961 12.2556V16.0673V16.6692H51.2996V16.0673V12.2556C51.2996 9.7479 49.2996 7.74173 46.8996 7.74173Z"
            fill="#BCD6F9"
        />
        <path
            d="M15.8996 15.5664C14.0996 15.5664 12.5996 14.0618 12.5996 12.2562C12.5996 11.0525 13.2996 9.94914 14.2996 9.34729V12.2562C14.2996 13.159 14.9996 13.9615 15.9996 13.9615C16.8996 13.9615 17.6996 13.2593 17.6996 12.2562V9.34729C18.6996 9.94914 19.3996 10.9522 19.3996 12.2562C19.1996 14.0618 17.6996 15.5664 15.8996 15.5664Z"
            fill="#344051"
        />
        <path
            d="M15.8996 15.9676C13.7996 15.9676 12.0996 14.2624 12.0996 12.1559C12.0996 10.8519 12.7996 9.54785 13.9996 8.84569L14.5996 8.44446V12.0556C14.5996 12.7577 15.1996 13.2593 15.7996 13.2593C16.3996 13.2593 16.9996 12.6574 16.9996 12.0556V8.54477L17.5996 8.946C18.7996 9.64816 19.4996 10.8519 19.4996 12.2562C19.5996 14.2624 17.8996 15.9676 15.8996 15.9676ZM13.7996 10.1497C13.2996 10.6512 12.9996 11.3534 12.9996 12.1559C12.9996 13.7608 14.2996 15.0648 15.8996 15.0648C17.4996 15.0648 18.7996 13.7608 18.7996 12.1559C18.7996 11.4537 18.4996 10.6512 17.9996 10.1497V12.1559C17.9996 13.3596 17.0996 14.2624 15.8996 14.2624C14.6996 14.2624 13.7996 13.3596 13.7996 12.1559V10.1497Z"
            fill="#344051"
        />
        <path
            d="M37.9997 15.5664C36.1997 15.5664 34.6997 14.0618 34.6997 12.2562C34.6997 11.0525 35.3997 9.94914 36.3997 9.34729V12.2562C36.3997 13.159 37.0997 13.9615 38.0997 13.9615C38.9997 13.9615 39.7997 13.2593 39.7997 12.2562V9.34729C40.7997 9.94914 41.4997 10.9522 41.4997 12.2562C41.2997 14.0618 39.7997 15.5664 37.9997 15.5664Z"
            fill="#344051"
        />
        <path
            d="M37.9997 15.9676C35.8997 15.9676 34.1997 14.2624 34.1997 12.1559C34.1997 10.8519 34.8997 9.54785 36.0997 8.84569L36.6997 8.44446V12.0556C36.6997 12.7577 37.2997 13.2593 37.8997 13.2593C38.4997 13.2593 39.0997 12.6574 39.0997 12.0556V8.54477L39.6997 8.946C40.8997 9.64816 41.5997 10.8519 41.5997 12.2562C41.7997 14.2624 40.0997 15.9676 37.9997 15.9676ZM35.8997 10.1497C35.3997 10.6512 35.0997 11.3534 35.0997 12.1559C35.0997 13.7608 36.3997 15.0648 37.9997 15.0648C39.5997 15.0648 40.8997 13.7608 40.8997 12.1559C40.8997 11.4537 40.5997 10.6512 40.0997 10.1497V12.1559C40.0997 13.3596 39.1997 14.2624 37.9997 14.2624C36.7997 14.2624 35.8997 13.3596 35.8997 12.1559V10.1497Z"
            fill="#344051"
        />
        <path
            d="M15.9002 13.8606C15.0002 13.8606 14.2002 13.1584 14.2002 12.1553V2.7263C14.2002 1.82353 14.9002 1.02106 15.9002 1.02106C16.8002 1.02106 17.6002 1.72322 17.6002 2.7263V12.1553C17.5002 13.1584 16.8002 13.8606 15.9002 13.8606Z"
            fill="#006BFD"
        />
        <path
            d="M15.9 14.0618C14.9 14.0618 14 13.2593 14 12.1559V2.72691C14 1.72382 14.8 0.821045 15.9 0.821045C16.9 0.821045 17.8 1.62351 17.8 2.72691V12.1559C17.8 13.2593 16.9 14.0618 15.9 14.0618ZM15.9 1.22228C15.1 1.22228 14.5 1.82413 14.5 2.6266V12.0556C14.5 12.8581 15.1 13.4599 15.9 13.4599C16.7 13.4599 17.3 12.8581 17.3 12.0556V2.72691C17.3 1.92444 16.7 1.22228 15.9 1.22228Z"
            fill="#006BFD"
        />
        <path
            d="M37.9998 13.8606C37.0998 13.8606 36.2998 13.1584 36.2998 12.1553V2.7263C36.2998 1.82353 36.9998 1.02106 37.9998 1.02106C38.8998 1.02106 39.6998 1.72322 39.6998 2.7263V12.1553C39.6998 13.1584 38.8998 13.8606 37.9998 13.8606Z"
            fill="#006BFD"
        />
        <path
            d="M37.9996 14.0618C36.9996 14.0618 36.0996 13.2593 36.0996 12.1559V2.72691C36.0996 1.72382 36.8996 0.821045 37.9996 0.821045C38.9996 0.821045 39.8996 1.62351 39.8996 2.72691V12.1559C39.8996 13.2593 38.9996 14.0618 37.9996 14.0618ZM37.9996 1.22228C37.1996 1.22228 36.5996 1.82413 36.5996 2.6266V12.0556C36.5996 12.8581 37.1996 13.4599 37.9996 13.4599C38.7996 13.4599 39.3996 12.8581 39.3996 12.0556V2.72691C39.4996 1.92444 38.7996 1.22228 37.9996 1.22228Z"
            fill="#006BFD"
        />
    </svg>
</template>

<script>
    export default {
        name: 'CalendarColorIcon',
        props: {
            size: {
                type: [Number, String],
                default: 24
            }
        }
    }
</script>
