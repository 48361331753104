<template>
    <svg 
        viewBox="0 0 741.2750244140625 562.1251831054688"
        :width="size" 
        :height="size"
        class="envelope-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="741.2750244140625" 
            height="562.1251831054688" 
            style="fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(32.666664123535156, 0, 0, 32.666664123535156, 13339.75392627716, -9389.341796875)">
            <path 
                class="st0" 
                d="M-397,297.9c-0.4,0-0.8-0.1-1.1-0.3l-9.6-5.2l0.4-0.8l9.1,4.9c0.7,0.4,1.6,0.4,2.4,0l9.1-4.9l0.4,0.8l-9.6,5.2C-396.2,297.8-396.6,297.9-397,297.9z" style="stroke-width: 0.5; stroke-miterlimit: 10;"
            />
            <path 
                class="st0" 
                d="M-389.3,304.3h-15.4c-1.8,0-3.3-1.5-3.3-3.3v-9.9c0-1.8,1.5-3.3,3.3-3.3h15.4c1.8,0,3.3,1.5,3.3,3.3v9.9C-386,302.8-387.5,304.3-389.3,304.3z M-404.7,288.7c-1.3,0-2.4,1.1-2.4,2.4v9.9c0,1.3,1.1,2.4,2.4,2.4h15.4c1.3,0,2.4-1.1,2.4-2.4v-9.9c0-1.3-1.1-2.4-2.4-2.4H-404.7z" 
                style="stroke-width: 0.5; stroke-miterlimit: 10;"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'EnvelopeIcon',
        props: {
            size: {
                type: [Number, String],
                default: 23
            }
        }
    }
</script>
