<template>
    <div
        class="l-filter column-between-start"
        v-scroll="scrollListener"
    >
        <div class="l-filter-search row-start s-align-4">
            <search-icon/>
            <input
                type="text"
                name="leads_search"
                class="l-filter-input"
                placeholder="Filter Results"
                v-model="filtersInput"
            />
        </div>
    </div>
</template>

<script>
    import { SearchIcon } from '@/icons';
    export default {
        name: 'OpportunitiesFilter',
        components: {
            SearchIcon
        },
        data(){
            return {
                filtersInput: '',
            }
        },
        methods: {
            scrollListener: function (event, el) {
                if (window.innerWidth > 1165) {
                    window.scrollY > 50
                        ? el.style.paddingTop = 70 + 'px'
                        : el.style.paddingTop = 150 + 'px';
                } else {
                    return false;
                }
            },
        }
    }
</script>
