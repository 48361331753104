import moment from 'moment/src/moment';
import api from '@/headers.js';

export const dragEvents = {
    data() {
        return {
            draggingEventId: '',
            draggingEventCalendarId: '',
            draggingEventIndex: null,
            draggingEventDuration: null
        }
    },
    computed: {
        utcOffset() {
            return moment().utcOffset();
        }
    },
    methods: {
        onEventDragStart(event, item, index) {
            this.draggingEventIndex = index;
            this.draggingEventId = item.id;
            this.draggingEventCalendarId = item.calendar_id;

            const startTime = moment(item.dtstart);
            const endTime = moment(item.dtend);

            // get event duration
            this.draggingEventDuration = moment.duration(endTime.diff(startTime)).asSeconds();
        },
        // for week & day view
        onHourDragOver(event, day, timeCountIndex) {
            // cursor position relative to div.hour-item
            const cursorPosition = event.pageY - (event.target.getBoundingClientRect().top + document.documentElement.scrollTop);

            const hour = timeCountIndex + this.timeLineStart - 1;
            let minute;
            
            // calculate minutes depending on the cursor position
            // height of div.hour-item == 60px
            if (cursorPosition < 15) {
                minute = 0;
            } else if (cursorPosition < 30) {
                minute = 15;
            } else if (cursorPosition < 45) {
                 minute = 30;
             } else {
                 minute = 45;
             }

            const dtstart = moment(day).set({ 
                'hour': hour,
                'minute': minute
            });
            const dtend = dtstart.clone().add(this.draggingEventDuration, 'seconds');
            
            // stire new date & time
            this.events[this.draggingEventIndex].dtstart =
                dtstart.subtract(this.utcOffset, 'm').format('YYYY-MM-DD HH:mm:ss');
            this.events[this.draggingEventIndex].dtend =
                dtend.subtract(this.utcOffset, 'm').format('YYYY-MM-DD HH:mm:ss');

            this.checkCellOverlappingEvents();
        },
        // for month view
        onDayDragEnter(day) {
            const index = this.events.findIndex(event => {
                return event.id === this.draggingEventId;
            });

            let dtstart = this.events[index].dtstart;
            let dtend = this.events[index].dtend;
            let duration = moment(dtend).diff(moment(dtstart), 'days');
            
            dtstart = moment(dtstart).clone().set({
                'year': moment(day).get('year'),
                'month': moment(day).get('month'),
                'date': moment(day).get('date')
            });

            dtend = moment(dtend).clone().set({
                'year': moment(day).get('year'),
                'month': moment(day).get('month'),
                'date': moment(day).get('date')
            }).add(duration, 'days');
            
            this.events[index].dtstart = dtstart.format('YYYY-MM-DD HH:mm:ss');
            this.events[index].dtend = dtend.format('YYYY-MM-DD HH:mm:ss');
        },
        onEventDrageEnd() {
            const calendarId = this.draggingEventCalendarId ? this.draggingEventCalendarId : 'primary';
            const eventId = this.draggingEventId;
            const index = this.events.findIndex(event => {
                return event.id === this.draggingEventId;
            });
            const params = new FormData();

            params.append('dtstart',this.events[index].dtstart);
            params.append('dtend',this.events[index].dtend);

            if (this.events[index].attendees.length) {
                this.events[index].attendees.forEach((customer, index) => {
                    params.append(`attendees[${index}][attendee_id]`, customer.id ? customer.id : null)
                    params.append(`attendees[${index}][email]`, customer.email)
                    params.append(
                        `attendees[${index}][cn]`,
                        customer.first_name 
                            ? `${customer.first_name} ${customer.last_name}`
                            : customer.email
                        )
                });
            }

            params.append('_method','PUT');

            api.post(`/calendars/${calendarId}/events/${eventId}`, params)
                .then(() => {
                    this.notifySuccess('Event updated!');
                })
                .catch(error => {
                    this.notifyRequestErrors(error);
                })
                .finally(() => {
                    this.draggingEventDuration = null;
                    this.draggingEventId = '';
                    this.draggingEventCalendarId = '';
                    this.draggingEventIndex = null;

                    if (this.checkCellOverlappingEvents !== undefined) {
                        this.checkCellOverlappingEvents();
                    }
                });
        }
    }
}