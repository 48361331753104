<template>
    <div :class="['mt-8 row-stretch', dScreen && 'flex-no-wrap']">
        <div
            v-for="(reason, index) in reasons"
            :key="index"
            :class="[
                'cancel-job-reasons flex-grow-1',
                index && !mScreen && 'ml-8',
                mScreen && 'mb-8'
            ]"
        >
            <div class="reasons-container p-20">
                <div class="row-start s-align-4">
                    <div
                        v-if="!isOpportunity && !isWarranty"
                        :class="[
                            'badge s-align-5 uppercase f-bold mr-4',
                            reason.type == 'job' ? 'job' : 'lead'
                        ]"
                    >
                        {{ reason.name }}
                    </div>
                    <div class="f-14-black f-sbold">
                        {{ reason.name }}
                    </div>
                </div>
                <p class="f-12-grey mb-8 f-normal mt-1">
                    {{ reason.subtitle }}
                </p>
                <loader v-if="isLoading" size="small"/>
                <div
                    v-else
                    v-for="(el, index) in reason.reasons"
                    :key="index"
                    class="reasons-item row-between row-baseline mt-7"
                >
                    <checkbox-button :color="reason.color">
                        <input
                            type="checkbox"
                            @change="updateReason(index, el, $event)"
                            :disabled="el.isNew"
                            v-model="el.is_active"
                        >
                    </checkbox-button>
                    <textarea
                        :name="`milestone_reason_${index}_${reason.type}`"
                        :id="`milestone_reason_${index}_${reason.type}`"
                        :class="[
                            'reason-title',
                            errors.has(`milestone_reason_${index}_${reason.type}`) ? 'reason-title-danger' : ''
                        ]"
                        placeholder="Enter item title..."
                        v-model="el.title"
                        v-on:blur="updateReasonDelay(el, index, reason.type)"
                        v-resize-textarea
                        v-validate="'required|min:3|max:256'"
                        maxlength="255"
                        rows="1"
                        :ref='`milestone_reason_${index}_${reason.type}`'
                    />
                    <tooltip
                        direction="left"
                        :fixedDirection="true"
                        :isShow="showDeleteModal(index, reason.type)"
                        color="white"
                    >
                        <button
                            class="transparent-button s-align-6"
                            @click="toggleactiveReasonTooltip(index + reason.type)"
                        >
                            <trash-icon class="i-grey"/>
                        </button>
                        <template v-slot:content>
                            <p class="f-14-dark f-sbold t-center mb-8">
                                Are you sure to want to delete this item?
                            </p>
                            <div class="row-between">
                                <button
                                    class="add-button"
                                    @click="activeReasonTooltip = null"
                                >
                                    No
                                </button>
                                <button
                                    class="red-button"
                                    @click="deleteReason(reason.reasons, index)"
                                    :disabled="requestIsGoing"
                                >
                                    Yes
                                </button>
                            </div>
                        </template>
                    </tooltip>
                </div>
            </div>
            <button
                class="add-new-reason transparent-button"
                @click="addNewReason(reason.reasons, reason.type)"
            >
            <span class="icon-container s-align-5 mr-6">
                <add-icon size="9"/>
            </span>
                <span class="f-13-blue">New Reason</span>
            </button>
        </div>
    </div>
</template>

<script>
    import { CheckboxButton, Tooltip, Loader } from '@/ui';
    import api from '@/headers.js';
    import debounce from 'lodash/debounce';
    import { TrashIcon, AddIcon } from '@/icons';
    import { ENTITY_TYPES } from '@/constants';


    export default {
        name: 'MilestoneReasons',
        components: {
            CheckboxButton, 
            Tooltip,
            Loader,
            TrashIcon,
            AddIcon
        },
        props: {
            currentGroupId: {
                type: Number,
                required: true
            },
            isOpportunity: {
                type: Boolean,
                default: false
            },
            isWarranty: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            currentGroupId(val, oldVal) {
                if (val !== oldVal) {
                    this.getMilestoneReasons();
                }
                if (this.isOpportunity || this.isWarranty){
                    this.tabControl();
                }
            }
        },
        data() {
            return {
                isLoading: true,
                requestIsGoing: false,
                milestoneReasons: [],
                activeReasonTooltip: null,
                reasons: [
                    {
                        name: 'Job',
                        subtitle: 'Enter default reasons for marking job as dead',
                        reasons: [],
                        color: 'violet',
                        type: 'job'
                    },
                    {
                        name: 'Lead',
                        subtitle: 'Enter default reasons for marking lead as dead',
                        reasons: [],
                        color: 'orange',
                        type: 'lead'
                    }
                ]
            }
        },
        methods: {
            toggleactiveReasonTooltip(key) {
                return this.activeReasonTooltip = this.activeReasonTooltip == key ? null : key;
            },
            showDeleteModal(index, type) {
                return this.activeReasonTooltip === index + type;
            },
            getMilestoneReasons() {
                this.isLoading = true;
                api.get(`/company/milestone-reasons?filter[group_id]=${this.currentGroupId}`)
                    .then(response => response.data.data)
                    .then(data => {
                        if (this.isOpportunity || this.isWarranty){
                            const type = this.isOpportunity ? ENTITY_TYPES.OPPORTUNITY : ENTITY_TYPES.WARRANTY_JOB;
                            this.reasons[0].reasons = data.filter(el => el.type.toLowerCase() === type.toLowerCase());
                        }else{
                            const milestoneReasonsLead = data.filter(el => el.type == 'lead');
                            const milestoneReasonsJob = data.filter(el => el.type == 'job');
                            this.milestoneReasons = data;
                            this.reasons.forEach(el => el.type == 'job' ? el.reasons = milestoneReasonsJob : null);
                            this.reasons.forEach(el => el.type == 'lead' ? el.reasons = milestoneReasonsLead : null);
                        }
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    })
            },
            addNewReason(reasons, type) {
                this.$validator.validateAll()
                    .then(result => {
                        if (result) {
                            reasons.push({
                                is_active: 0, 
                                title: '',
                                isNew: true,
                                type: type
                            });

                            // focus on new input
                            this.$nextTick(function() {
                                let refName = `milestone_reason_${reasons.length - 1}_${type}`;
                                this.$refs[refName][0].focus();
                            });  
                        }
                    });
            },
            createReason(title, index, type) {
                const params = {
                    title,
                    is_active: 0,
                    group_id: this.currentGroupId,
                    type: type
                };
                if (type.toLowerCase() === ENTITY_TYPES.OPPORTUNITY.toLowerCase()){
                    params.type = ENTITY_TYPES.OPPORTUNITY;
                }else if(type.toLowerCase() === ENTITY_TYPES.WARRANTY_JOB.toLowerCase()){
                    params.type = ENTITY_TYPES.WARRANTY_JOB;
                }
                api.post('/company/milestone-reasons/', params)
                    .then(response => response.data.data)
                    .then(data => {
                      const typeIdx = +(data.type === 'lead');
                      this.reasons[typeIdx].reasons.splice(index, 1, data);
                        this.notifySuccess('Reason has been created!');
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    });
            },
            updateReasonDelay (el, index, type) {
                // create milestone if new
              if (el.title.length >= 3){
                if (el.isNew) {
                  this.createReason(el.title, index, type);
                  // update milestone if already exist
                } else {
                  this.updateReason(index, el, null, true);
                }
              }
            },
            updateReason(index, el, event, updatedTitle) {
              const idxType = +(el.type === 'lead');
              const lastEl = this.reasons[idxType].reasons.some(e => e.is_active);
              if (!lastEl && !updatedTitle) {
                    el.is_active = true;
                    event.target.checked = true;
                    this.notifyError('You cannot inactivate the last active reason.');
              }else{
                const params = {
                  title: el.title,
                  is_active: +el.is_active,
                  type: el.type
                };

                if (el.order) {
                  params.order = el.order;
                }

                api.put(`/company/milestone-reasons/${el.id}?` + new URLSearchParams(params))
                    .then(response => {
                      this.notifySuccess('Reason has been updated!');
                    })
                    .catch((error) => {
                      this.notifyRequestErrors(error);
                    });
              }
            },
            deleteReason(reasons, index) {
                // delete not created reason
                if (reasons[index].isNew) {
                    reasons.splice(index, 1);
                    this.activeReasonTooltip = null;
                    return false;
                }

                this.requestIsGoing = true;
                api.delete(`/company/milestone-reasons/${reasons[index].id}`)
                    .then(response => {
                        reasons.splice(index, 1);

                        this.notifySuccess('Reason has been deleted!');
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                        this.activeReasonTooltip = null;
                    });
            },
            tabControl(){
                if (this.isOpportunity){
                    this.reasons = [
                        {
                            name: 'Opportunity',
                            subtitle: 'Enter default reasons for marking opportunity as dead',
                            reasons: [],
                            color: 'violet',
                            type: 'Opportunity'
                        }
                    ]
                }else if (this.isWarranty){
                    this.reasons = [
                        {
                            name: 'Warranty',
                            subtitle: 'Enter default reasons for marking warranty as dead',
                            reasons: [],
                            color: 'violet',
                            type: 'warranty_job'
                        }
                    ]
                }
               
            },
       
        },
        created() {
            if (this.isOpportunity || this.isWarranty){
               this.tabControl();
            }
            this.getMilestoneReasons();
        }
    }
</script>
