<template>
    <div class="user-box row-baseline">
        <avatar
            :url="avatarUrl"
            :name="`${firstName} ${lastName}`"
            :border="border"
            :marked="marked"
            :is-active="isActive"
            :size="avatarSize"
        />
        <div class="user-box-text ml-4">
            <div class="row-start row-baseline">
                <span class="f-13-darkgrey">
                    {{ `${firstName} ${lastName}` }}
                </span>
                <button
                    v-if="editButton"
                    class="transparent-button ml-1 p-1"
                    @click="$emit('onEditButtonClick')"
                >
                    <edit-icon
                        class="i-grey"
                        size='12'
                    />
                </button>
            </div>
            <div
                v-if="!!providers"
                class="row-start"
            >
                <span
                    class="system-label"
                    style="margin-right: 2px"
                    v-for="(provider) in providers"
                >
                    {{provider}}
                </span>
            </div>
            <p
                v-if="!!info"
                class="f-11-grey"
            >
                {{ info }}
            </p>
        </div>
    </div>
</template>

<script>
    import Avatar from '@/components/User/Avatar.vue';
    import { EditIcon } from '@/icons';

    export default {
        name: 'UserBox',
        components: {
            Avatar,
            EditIcon
        },
        props: {
            isActive: {
                type: Number,
                default: 1
            },
            avatarUrl: {
                type: String,
                default: ''
            },
            firstName: {
                type: String,
                required: true
            },
            lastName: {
                type: String,
                required: true
            },
            providers: {
                type: Array,
                required: false,
            },
            info: {
                type: String,
                default: ''
            },
            border: {
                type: Boolean,
                default: true
            },
            marked: {
                type: Boolean,
                default: false
            },
            editButton: {
                type: Boolean,
                required: false
            },
            avatarSize: {
                type: String,
                default: '30'
            }
        }
    }
</script>
