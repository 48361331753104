<template>
    <svg
        viewBox="0 0 45 45"
        :width="size" 
        :height="size"
        class="rotate-screen"
    >
        <path class="st0" d="M39.9,5c-2.1-2.1-5-3.5-8-3.7c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.6,0.5,1.1,1.1,1.1
            C34,3.7,36,4.6,37.7,6c2.1,1.8,3.5,4.4,3.7,7.1c0.1,0.6,0.5,1.1,1.1,1.1c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.3-0.5,0.3-0.8
            C43.4,10,42.1,7.1,39.9,5z"/>
        <path d="M14.3,36.6c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4C15.7,37.2,15,36.6,14.3,36.6z"/>
        <path d="M27.4,1.5C26.6,0.6,25.4,0,24,0H4.5C2.8,0,1.3,0.9,0.6,2.3C0.2,2.9,0,3.7,0,4.5v36.1C0,43,2,45,4.5,45H24
            c2.5,0,4.5-2,4.5-4.5V4.5C28.5,3.3,28.1,2.3,27.4,1.5z M26.3,40.5c0,1.2-1,2.2-2.2,2.2H4.5c-1.2,0-2.2-1-2.2-2.2V4.5
            c0-1.1,0.9-2.1,2-2.2h20c1.1,0.1,2,1,2,2.2V40.5z"/>
        <path d="M45,22.8v17.3c0,2.7-2.2,4.9-4.8,4.9h-9.9v-2.3h9.9c1.4,0,2.6-1.2,2.6-2.6V22.8c0-0.7-0.3-1.4-0.8-1.8
            c-0.5-0.5-1.1-0.8-1.8-0.8h-9.9V18h9.9C42.8,18,45,20.1,45,22.8z"/>
    </svg>
</template>

<script>
    export default {
        name: 'RotateScreen',
        props: {
            size: {
                type: [Number, String],
                default: 45
            }
        }
    }
</script>