import api from '@/api';

export default {
    namespaced: true,
    state: {
        contacts: [],
        requestIsGoing: false
    },
    getters: {
        contacts: state => state.contacts,
        requestIsGoing: state => state.requestIsGoing,
    },
    mutations: {
        SET_CONTACTS(state, contacts) {
            state.contacts = contacts;
        },
        SET_REQUEST_GOING(state, status = false) {
            state.requestIsGoing = status;
        }
    },
    actions: {
        getContacts({ commit }) {
            commit('SET_REQUEST_GOING', true);
            return api.Contact.list()
                .then(response => response.data)
                .then(response => {
                    commit('SET_CONTACTS', response.data);
                })
                .catch(e => {
                    this.notifyRequestErrors(e);
                })
                .finally(() => {
                    commit('SET_REQUEST_GOING', false);
                })
        }
    }
}
