import { SET_TECHNICIAN_DATA } from '../actions/technicians';

export default {
    namespaced: true,
    state: {
        technicianList:[]
    },
    getters: {
        getTechnicianList: state => state.technicianList
    },
    mutations: {
        [SET_TECHNICIAN_DATA](state, payload) {
            payload.forEach(item => item.is_active = true);
            state.technicianList = payload;
        }
    }
}
