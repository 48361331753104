<template>
<div
    class="lead-item"
    :style="`border-left: 4px solid #bcd6f9;`"
>
    <div :class="['row-between', !mScreen && 'flex-no-wrap']">
        <div class="s-align-4">
            <status-marker
                :marker="fullName || no_info"
                :color="'#bcd6f9'"
                :size="34"
            />
        </div>
        <div class="ml-15 flex-grow-1 column-start">
            <router-link
                class="f-14-black f-sbold black-link-underlined relative mb-1"
                :class="!fullName ? 'f-italic' : ''"
                :to="{ name: 'Overview', params: { lead_id: lead.id }}"
                target="_blank"
            >
                {{ fullName || no_info }}
            </router-link>
            <div class="column-start">
                <span class="f-12-grey">
                    Address:
                    <span class="f-12-blue">
                        {{ getAddress(lead.address) }}
                    </span>
                </span>
            </div>
            <div>
                <span class="f-12-grey">
                    Trades:
                </span>
                <span class="f-12-black ml-1 mr-4">
                    {{ lead.trades || no_info }}
                </span>
                <span class="f-12-grey">
                    Source:
                </span>
                <span class="f-12-black ml-1">
                    {{ lead.source || no_info }}
                </span>
            </div>
        </div>
        <div :class="mScreen || tScreen
            ? 'w-100 row-between ml-50 mt-2'
            : 'column-between-end'
        ">
            <span class="f-11-grey">
                <clock-icon
                    class="i-grey mr-1"
                    size="10"
                />
                <span>
                    {{ lead.created_at | timeAgo }}
                </span>
            </span>
        </div>
    </div>
</div>
</template>

<script>
    import {ArrowIcon, GearIcon, FilterIcon, ClockIcon, SortIcon} from '@/icons';
    import StatusMarker from "../StatusMarker";
    import {showFullAddress} from '@/helpers';
    
    export default {
        name: 'ResLeadCard',
        components: {
            StatusMarker,
            ClockIcon
        },
        data() {
            return {
                no_info: 'No information'
            }
        },
        props: {
            lead: {
                type: Object,
                required: true
            }
        },
        computed: {
            fullName() {
                const {first_name, last_name} = this.lead;
                if (first_name || last_name) {
                    return `${first_name || ''} ${last_name}`
                }
                return '';
            }
        },
        methods: {
            getAddress(lead) {
                return showFullAddress(lead);
            }
        }
    }
</script>
