<template>
    <div
        v-if="hasAccessToCalendar" 
        class="salesman-calendar w-100"
    >
        <div class="row-start pl-20 pr-20 pb-20">
            <button 
                class="transparent-button s-align-4"
                @click="toggleCalendar"
            >
                <arrow-icon 
                    class="i-blue mr-5"
                    :transform="opened ? 270 : 90"
                />
                <span class="f-13-blue">Show salesman calendar details</span>
            </button>
        </div>
        <div
            v-if="opened" 
            class="calendar w-100"
        >
            <div class="row-start row-baseline pl-7 pr-7 pb-7">
                <div class="lg-6 sm-7">
                    <div 
                        class="calendar-date mr-4"
                        v-html="currentPeriodDates"
                    />
                    <button 
                        class="icon-dark"
                        @click="prevWeek"
                    >
                        <arrow-icon 
                            transform="180"
                            size="13"
                        />
                    </button>
                    <button 
                        class="icon-dark"
                        @click="nextWeek"
                    >
                        <arrow-icon 
                            transform="0"
                            size="13"
                        />
                    </button>
                </div>
                <div class="lg-6 sm-5">
                    <checkbox-button
                        class="s-align-6 w-100 f-12-black"
                        color="grey"
                        label="Show all item names"
                    >
                        <input 
                            type="checkbox" 
                            name="show_event_title" 
                            id="show_event_title"
                            v-model="isEventDetailsShow"
                        />
                    </checkbox-button>
                </div>
            </div>
            <div class="user-calendar">
                <Loader
                    v-if="isLoading" 
                    class="s-align-5"
                    size="small"
                />
                <perfect-scrollbar 
                    v-else
                    class="ps-grey w-100"
                >
                    <calendar-days
                        title="Users"
                        :current-period="currentPeriod"
                    />
                    <div 
                        v-for="(userRow, index) in filteredEvents"
                        :key="index"
                        class="user-calendar-row row-start w-100 bb-grey"
                    >
                        <span class="user-name f-13-black">
                            {{ userRow.fullname }}
                        </span>
                        <div 
                            class="user-calendar-grid row-start relative expanded"
                            v-expand-buttons
                        >
                            <div 
                                class="day-item bl-grey"
                                v-for="n in 7"
                                :key="n"
                                @click="setAssignment(userRow.id, n)"
                            >
                            </div>
                            <template v-for="event in getUserEvents(userRow.events)">
                                <button 
                                    :key="`${event.id}${isEventDetailsShow ? 'show' : 'hide'}`"
                                    :style="`top:${ getEventTop(event, isEventDetailsShow)}`"
                                    :class="[
                                        'event',
                                        event.classes,
                                        checkIfEventPast(event.dtend) ? 'event-past' : 'event-violet',
                                        event.eventRow > 2 ? 'event-overflow' : '',
                                        { 'is-event-details-show' : isEventDetailsShow }
                                    ]"
                                    :id="`event-${event.id}`"
                                >
                                    <p 
                                        v-if="+event.all_day"
                                        class="event-description"
                                    >
                                        All day
                                        {{ isEventDetailsShow ? eventDetails(event) : '' }}
                                    </p>
                                    <p 
                                        v-else
                                        class="event-description"
                                    >
                                        {{ event.dtstart | time }}
                                        -
                                        {{ event.dtend | time }}
                                        {{ isEventDetailsShow ? eventDetails(event) : '' }}
                                    </p>
                                </button>   
                            </template>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
    import { userCalendar } from '@/components/Calendar/calendar-mixins/userCalendar.js';
    import { CheckboxButton, Loader } from '@/ui';
    import { clearArray } from '@/helpers';
    import { ArrowIcon } from '@/icons';
    import api from '@/headers.js';
    import moment from 'moment/src/moment';
    import { CalendarDays } from '@/components';

    export default {
        name: 'SalesmanCalendar',
        components: {
            CalendarDays,
            CheckboxButton,
            Loader,
            ArrowIcon
        },
        mixins: [userCalendar],
        props: {
            sellers: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                opened: false,
                isEventDetailsShow: false,
                usersList: []
            }
        },
        computed: {
            filteredEvents() {
                return this.sellers.map(seller => {
                    const user = this.events.find(e => e.id === seller.id);

                    return {
                        ...seller,
                        events: user ? user.events : []
                    }
                });
            },
            hasAccessToCalendar() {
                return this.$can([this.$permissions.VIEW_ALL_CALENDARS]);
            }
        },
        methods: {
            eventDetails(event) {
                let resp = event.responsible;
                if (resp) {
                    let name = `
                        ${resp.first_name ? resp.first_name : ''} 
                        ${resp.last_name ? resp.last_name : ''}`;
                    let phone = `${resp.phone ? resp.phone : ''}`;
                    let address = `${event.location ? event.location : ''}`;

                    return `${name} - ${this.$options.filters.phone(phone)} - ${address}`;
                }

                return event.summary;
            },
            setAssignment(userId, index) {
                this.$emit(
                    'setAssignment', 
                    userId, 
                    moment(this.currentPeriod[index - 1]).format('YYYY-MM-DD')
                );
            },
            nextWeek() {
                this.date = moment(this.date).clone().add(7, 'd').format('YYYY-MM-DD');
                this.getWeek();
            },
            prevWeek() {
                this.date = moment(this.date).clone().subtract(7, 'd').format('YYYY-MM-DD');
                this.getWeek();
            },
            toggleCalendar() {
                this.opened = !this.opened;

                if (!this.events.length) {
                    this.getWeek();
                }
            }
        },
        created() {
            this.date = moment();
        }
    }
</script>