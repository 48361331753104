<template>
    <svg
        viewBox="0 0 17 16"
        :width="size"
        :height="size"
        class="back-icon"
    >
        <path d="M10.3,2.4H2.5l1.2-1.2c0.3-0.3,0.3-0.7,0-1C3.5,0.1,3.3,0,3.1,0c0,0,0,0,0,0C2.9,0,2.7,0.1,2.6,0.2L0.2,2.6C0.1,2.8,0,3,0,3.2c0,0.2,0.1,0.4,0.2,0.5l2.4,2.4c0.3,0.3,0.7,0.3,1,0c0.3-0.3,0.3-0.8,0-1L2.5,3.9h7.8c2.9,0,5.2,2.4,5.2,5.3s-2.3,5.3-5.2,5.3H1.1c-0.4,0-0.7,0.3-0.7,0.7S0.7,16,1.1,16h9.2C14,16,17,13,17,9.2v0C17,5.5,14,2.4,10.3,2.4z"/>
    </svg>
</template>

<script>
    export default {
        name: 'BackIcon',
        props: {
            size: {
                type: [Number, String],
                default: 10
            }
        }
    }
</script>