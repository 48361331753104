<template>
    <svg 
        viewBox="0 0 805.7589111328125 498.4598388671875"
        :width="width"
        :height="height"
        class="job-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="805.7589111328125" 
            height="498.4598388671875" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(7.840000152587891, 0, 0, 7.840000152587891, 6639.718170166016, -6713.357406616211)">
            <path d="M-756.6,856.5h-77.8c-6.7,0-12.1,5.4-12.1,12.1v38.9c0,6.7,5.4,12.1,12.1,12.1h77.8c6.7,0,12.1-5.4,12.1-12.1v-39C-744.5,861.9-749.9,856.5-756.6,856.5z M-749.3,907.5c0,4-3.3,7.3-7.3,7.3h-77.8c-4,0-7.3-3.3-7.3-7.3v-39c0-4,3.3-7.3,7.3-7.3h77.8c4,0,7.3,3.3,7.3,7.3V907.5z"/>
            <path d="M-757.3,869h-37.1c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7h36.9c1.5,0,2.7-1.2,2.7-2.7C-754.6,870.2-755.8,869-757.3,869z"/>
            <path d="M-757.3,885.3h-37.1c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7h36.9c1.5,0,2.7-1.2,2.7-2.7S-755.8,885.3-757.3,885.3z"/>
            <path d="M-757.3,901.6h-37.1c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7h36.9c1.5,0,2.7-1.2,2.7-2.7S-755.8,901.6-757.3,901.6z"/>
            <path d="M-811.9,885.6c2-2,3.2-4.7,3.2-7.7c0-6-4.9-10.9-10.9-10.9c-6,0-10.9,4.9-10.9,10.9c0,3,1.2,5.8,3.2,7.7c-5,3-8.5,8.9-8.5,15.6c0,1.8,0.8,5,5.9,7.1c2.8,1.1,6.5,1.8,10.4,1.8c8.1,0,16.3-2.8,16.3-8.9C-803.4,894.5-806.9,888.6-811.9,885.6z M-825.2,877.9c0-3.1,2.5-5.6,5.6-5.6c3.1,0,5.6,2.5,5.6,5.6c0,3.1-2.5,5.6-5.6,5.6C-822.7,883.5-825.2,880.9-825.2,877.9z M-819.6,904.8c-6.7,0-10.8-2.1-10.8-3.6c0-6.8,4.9-12.4,10.8-12.4c6,0,10.8,5.6,10.8,12.4C-808.8,902.7-812.9,904.8-819.6,904.8z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'JobIcon',
        props: {
            width: {
                type: [Number, String],
                default: 25
            },
            height: {
                type: [Number, String],
                default: 18
            }
        }
    }
</script>