<template>
    <svg
        viewBox="0 0 12 12"
        :width="size"
        :height="size"
        class="expired-tasks-icon"
    >
        <path d="M12 4.8V9.6H10.8V4.8H12ZM10.8 10.8V12H12V10.8H10.8ZM9.6 9.174C8.718 10.17 7.434 10.8 6 10.8C3.354 10.8 1.2 8.646 1.2 6C1.2 3.354 3.354 1.2 6 1.2V6.6L10.53 2.07C9.432 0.804 7.812 0 6 0C2.688 0 0 2.688 0 6C0 9.312 2.688 12 6 12C7.35 12 8.598 11.556 9.6 10.8V9.174Z"/>
    </svg>
</template>

<script>
export default {
    name: "ExpiredTasksIcon",
    props: {
        size: {
            type: [Number, String],
            default: 20
        }
    }
}
</script>