<template>
    <svg
        viewBox="0 0 20 15"
        :width="width"
        :height="height"
        class="decimal-icon"
    >
        <g transform="matrix(0.1560571938753128, 0, 0, 0.1560571938753128, -23.704688292886118, -51.751002532388064)">
            <g>
                <path d="M 173.800006 387.600006 A 7.2 7.2 0 0 1 166.600006 394.800006 A 7.2 7.2 0 0 1 159.400006 387.600006 A 7.2 7.2 0 0 1 166.600006 380.400006 A 7.2 7.2 0 0 1 173.800006 387.600006 Z"/>
                <path d="M166.6,397.6c-5.5,0-9.9-4.5-9.9-9.9c0-5.5,4.5-9.9,9.9-9.9c5.5,0,9.9,4.5,9.9,9.9C176.5,393.1,172.1,397.6,166.6,397.6z M166.6,383.2c-2.4,0-4.4,2-4.4,4.4c0,2.4,2,4.4,4.4,4.4c2.4,0,4.4-2,4.4-4.4C171,385.2,169,383.2,166.6,383.2z"/>
            </g>
            <g>
                <path d="M157.8,407.5c-1.3,0-2.4-0.9-2.7-2.2c-0.3-1.5,0.6-3,2.1-3.3c14.9-3.3,13.9-13.6,13.9-14.1c-0.2-1.5,0.9-2.9,2.4-3c1.5-0.2,2.9,0.9,3,2.4c0.7,5.5-2.3,16.7-18.2,20.2C158.1,407.5,157.9,407.5,157.8,407.5z"/>
            </g>
            <text 
                transform="matrix(1 0 0 1 186.9998 406.2303)" 
                style="font-family: MyriadPro-Semibold; font-size: 90.4633px; white-space: pre;"
            >
                00
            </text>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'DecimalIcon',
        props: {
            width: {
                type: [Number, String],
                default: 20
            },
            height: {
                type: [Number, String],
                default: 15
            }
        }
    }
</script>
