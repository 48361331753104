import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import VeeValidate from 'vee-validate';
import Notifications from 'vue-notification';
import VCalendar from 'v-calendar';
import Vue2TouchEvents from 'vue2-touch-events';
import VBodyScrollLock from 'v-body-scroll-lock';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import VuePapaParse from 'vue-papa-parse';

import sassStyles from './assets/style/app.scss';
import directives from './directives';
import routing from './mixins/routing';
import screens from './mixins/screens';
import notifyAlerts from './mixins/notifyAlerts';
import http from './headers.js';
import filters from './filters';
import io from 'socket.io-client';
import echoAuth from "./echoAuth";
import ACL from './plugins/ACL/ACL';

Vue.use(ACL);
Vue.use(VeeValidate);
Vue.use(Notifications);
Vue.use(Vue2TouchEvents);
Vue.use(VBodyScrollLock);
Vue.use(PerfectScrollbar);
Vue.use(VuePapaParse);

Vue.mixin(routing);
Vue.mixin(screens);
Vue.mixin(notifyAlerts);

Vue.prototype.$http = http;

const Favico = require('favico.js');

Vue.prototype.$favicon = new Favico({
  animation : 'popFade'
});

Vue.config.productionTip = false;

Vue.use(VCalendar, {
    locale: 'en-US',
    popoverVisibility: 'focus',
    popoverKeepVisibleOnInput: false,
    datePickerShowDayPopover: false,
    popoverAlign: 'center',
    formats: {
      title: 'MMMM YYYY',
      weekdays: 'WWW',
      navMonths: 'MMM',
      input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
      dayPopover: 'L',
    }
});

window.io = require('socket.io-client');

if (localStorage.getItem('user-token')) {
    echoAuth.connect();
}
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
