<template>
    <full-modal @close="$emit('close')">
        <div slot="title">
            Next step to {{ nextMilestoneTitle }}
        </div>
        <div slot="body" class="w-100">
            <p class="f-12-black f-sbold mb-2">Pick the date</p>
            <div class="lg-6 md-4 sm-12 mb-2 w-100">
                <form-datepicker 
                    v-model="date"
                    placeholder="Date"
                    keyDatePicker="pick_the_date_picker"
                    :isFullWidth="true"
                />
            </div>
            <form-input-textarea 
                label="Comment"
                v-input-counter="1000"
            >
                <textarea 
                    class="form-input-field"
                    rows="1"
                    maxlength="1000"
                    v-input-style="comment"
                    v-resize-textarea
                    v-model="comment"
                    name="comment"
                    id="comment"
                    placeholder="Comment"
                />
            </form-input-textarea>
        </div>
        <div slot="footer">
            <button
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button
                v-if="showConvertToJobBtn"  
                class="primary-button convert-to-job-button mr-6"
                @click="$emit('redirectToConvertLeadPage')"               
            >
                <loading v-if="checkingQueryToRedirect"/>
                <template v-else>
                    <next-icon class="i-white mr-5"/>
                    Convert to Job
                </template>
            </button>
            <button
                v-else-if="showConvertToLeadBtn"
                class="primary-button convert-to-job-button mr-6"
                @click="$emit('redirectToConvertLeadPage', date)"
            >
                <loading v-if="checkingQueryToRedirect"/>
                <template v-else>
                    <next-icon class="i-white mr-5"/>
                    Convert to Lead
                </template>
            </button>
            <button
                v-else
                class="primary-button"
                :disabled="requestIsGoing"
                @click="convertMilestone"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, FormDatepicker, FormInputTextarea } from '@/ui';
    import { BASE_ENTITY_TYPES, MILESTONE_STATUSES } from '@/constants';
    import cloneDeep from 'lodash/cloneDeep';
    import moment from 'moment/src/moment';
    import { NextIcon, Loading } from '@/icons';

    export default {
        name: 'MilestoneConvertModal',
        components: {
            FullModal,
            FormDatepicker,
            FormInputTextarea,
            NextIcon,
            Loading
        },
        props: {
            nextMilestoneTitle: {
                type: String,
                required: true
            },
            entityType: {
                type: String,
                required: true
            },
            inprogressIndex: {
                type: Number,
                required: true
            },
            checkingQueryToRedirect: {
                type: Boolean,
                required: false
            }
        },
        data() {
            return {
                requestIsGoing: false,
                date: '',
                comment: ''
            }
        },
        computed: {
            entityId() {
                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL:
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.$route.params.lead_id;
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.$route.params.job_id;
                    case BASE_ENTITY_TYPES.OPPORTUNITY:
                        return this.$route.params.opportunity_id;
                    case BASE_ENTITY_TYPES.WARRANTY_JOB:
                        return this.$route.params.job_id;
                }
            },
            milestones() {
                return this.$store.getters['milestones/getMilestones'];
            },
            currentMilestone() {
                if (this.inprogressIndex !== -1) {
                    return this.milestones[this.inprogressIndex];
                }

                if (this.lastDoneIndex !== -1) {
                    return this.milestones[this.lastDoneIndex];
                }

            },
            nextMilestone() {
                if (this.inprogressIndex !== -1 && this.milestones[this.inprogressIndex + 1]) {
                    return this.milestones[this.inprogressIndex + 1];
                }

                if (this.lastDoneIndex !== -1 && this.milestones[this.lastDoneIndex + 1]) {
                    return this.milestones[this.lastDoneIndex + 1];
                }
            },
            isResidentialOverview() {
                return this.$route.name === 'Overview';
            },
            isSettings() {
                return this.$route.name === 'ResidentialSettings'
                || this.$route.name === 'CommercialJobSettings'
                || this.$route.name === 'CommercialLeadSettings';
            },
            overviewKanbanLink() {
                return this.$store.getters['lead/getKanbanLink'];
            },
            showConvertToJobBtn() {
                return this.entityType === BASE_ENTITY_TYPES.COMMERCIAL_LEAD 
                    && this.nextMilestone.milestone.is_boundary;
            },
            showConvertToLeadBtn(){
                const milestones = this.milestones;
                if (this.entityType !== BASE_ENTITY_TYPES.OPPORTUNITY){
                    return false;
                }
                if (milestones.length > 1){
                    if (milestones[milestones.length - 2].status === MILESTONE_STATUSES.INPROGRESS){
                        return true
                    }
                }else{
                    return true;
                }
            },
            lastDoneIndex() {
                return this.$store.getters['milestones/getMilestoneLastDoneIndex'];
            }
        },
        methods: {
            convertMilestone() {
                let milestones = cloneDeep(this.milestones);
                if (this.inprogressIndex != -1) {
                    milestones[this.inprogressIndex].completed_at = this.date;
                    milestones[this.inprogressIndex].extra_reason = this.comment;
                } else if (this.lastDoneIndex != -1) {
                    milestones[this.lastDoneIndex].completed_at = this.date;
                    milestones[this.lastDoneIndex].extra_reason = this.comment;
                }

                if (this.nextMilestone && this.inprogressIndex != -1) {
                    milestones[this.inprogressIndex + 1].started_at = this.date
                } else if (this.nextMilestone && this.lastDoneIndex != -1) {
                    milestones[this.lastDoneIndex + 1].started_at = this.date
                }

                let params = {
                    updated: {
                        next: this.nextMilestone,
                        current: {
                            ...this.currentMilestone,
                            completed_at: this.date
                        }                  
                    }, 
                    milestones: milestones, 
                    id: this.entityId,
                    entityType: this.entityType
                };

                this.requestIsGoing = true;
                this.$store.dispatch('milestones/UPDATE_MILESTONES', params)
                    .finally(() => {
                        // get milestone log if settings page is open
                        if (this.isSettings) {
                            this.$store.dispatch('milestones/GET_MILESTONES_STATISTICS', {
                                id: this.entityId,
                                entityType: this.entityType
                            });
                        }

                        // get Project management card link if next milestone is trigger and card not created yet
                        // delay to wait while back-end create card
                        if (!this.overviewKanbanLink
                            && this.currentMilestone
                            && this.currentMilestone.milestone.is_card_starting
                            && this.entityType === BASE_ENTITY_TYPES.RESIDENTIAL) {
                            setTimeout(() => this.$store.dispatch('lead/GET_KANBAN_LINK', this.entityId), 3000);
                        }

                        // update job number (after updating milestones) on Residential
                        if (this.currentMilestone
                            && this.currentMilestone
                            && this.currentMilestone.milestone.is_boundary
                            && this.entityType === BASE_ENTITY_TYPES.RESIDENTIAL) {
                            this.$store.dispatch('lead/GET_LEAD_PREVIEW', this.entityId);
                        }
                        
                        this.$emit('close');
                        this.requestIsGoing = false;
                    });
            },
        },
        created() {            
            this.date = moment().format('YYYY-MM-DD');
        }
    }
</script>
