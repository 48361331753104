<template>
    <div class="breadcrumbs">
        <router-link 
            v-for="(item, index) in breadcrumbs"
            :key="index"

            :to="{name: item.link}"
        >
            {{ item.name }}
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'Breadcrumbs',
        data() {
            return {
                breadcrumbs: []
            }
        },
        created() {
            this.breadcrumbs = this.$route.meta.breadcrumbs;
        }
    }
</script>

