import {
    GET_EVENTS,
    SET_EVENTS,
    GET_EVENT,
    SET_EVENT,
    SET_EVENT_TECHNICIAN,
    SET_IS_CREATE_TECHNICIAN_CALENDAR,
    SET_USER_CALEDARS,
    GET_USER_CALEDARS
} from "../actions/calendars";
import api from '@/headers.js';

export default {
    namespaced: true,
    state: {
        events: [],
        event: null,
        eventTechnician: null,
        isCreateTechnicianEvent: false,
        usersCalendars: {}
    },
    getters: {
        getEvent: state => state.event,
        getEvents: state => state.events,
        getEventsTechnician: state => state.eventTechnician,
        getIsCreateTechnicianEvent: state => state.isCreateTechnicianEvent,
        getUserCalendars: state => state.usersCalendars
    },
    mutations: {
        [SET_EVENT] (state, payload) {
            state.event = payload;
        },
        [SET_EVENTS] (state, payload) {
            state.events = payload;
        },
        [SET_EVENT_TECHNICIAN] (state, payload) {
            state.eventTechnician = payload;
        },
        [SET_IS_CREATE_TECHNICIAN_CALENDAR] (state, payload) {
            state.isCreateTechnicianEvent = payload;
        },
        [SET_USER_CALEDARS] (state, payload) {
            for (const [key, value] of Object.entries(payload)) {
                state.usersCalendars[key] = value;
            }
        }
    },
    actions: {
        [GET_EVENTS]({ commit }, params) {
            return new Promise((resolve, reject) => {
                api.get('/calendars/events/entity', { params })
                    .then(response => {
                        commit(SET_EVENTS, response.data.data);
                        resolve(response.data.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        [GET_EVENT]({ commit }, params) {
            return new Promise((resolve, reject) => {
                api.get('/calendars/event/entity', { params })
                    .then(response => {
                        if (params.owner_type_filter) {
                            commit(SET_EVENT_TECHNICIAN, response.data.data);
                        } else {
                            commit(SET_EVENT, response.data.data);
                        }

                        resolve(response.data.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });

        },
        [GET_USER_CALEDARS]({ commit }, params) {
            return new Promise((resolve, reject) => {
                api.get('/calendars/calendar-list', { params })
                    .then(response => {
                        let key = `${params.owner_type}_${params.owner_id}`;
                        let calendars = {
                            [key]: response.data.data
                        }
                        commit(SET_USER_CALEDARS, calendars);
                        resolve(response.data.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });

        }
    }
}