<template>
    <svg
        :width="size"
        :height="size"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_4_1947)">
            <circle cx="20" cy="18" r="12" fill="#006BFD"/>
            <rect x="16" y="14" width="8" height="8" rx="1" fill="white"/>
        </g>
        <defs>
            <filter id="filter0_d_4_1947" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="2"/>
                <feGaussianBlur stdDeviation="4"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_1947"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_1947" result="shape"/>
            </filter>
        </defs>
    </svg>

</template>

<script>
    export default {
        name: 'PauseIcon',
        props: {
            size: {
                type: [Number, String],
                default: 24
            }
        }
    }
</script>
