<template>
    <div class="w-100 pt-20">
        <div class="events-table">
            <div 
                class="table-head row-start"
                v-if="dScreen"
            >
                <div 
                    v-for="(item, index) in head"
                    :key="`item-${index}`"
                    :class="`lg-${item.column}`"
                >
                    <span class="f-12-black f-sbold">{{ item.name }}</span>
                </div>
            </div>
            <div class="table-body">
                <div 
                    :class="[
                        'table-row row-start relative mb-1', 
                        {'table-row-open': selectedRowIndex == index }
                    ]"
                    v-for="(item, index) in data"
                    :key="`item_${index}`"
                >
                    <div class="table-cell-title row-baseline relative lg-2 nb-11 md-11 sm-11 pv-2 pr-2">
                        <button
                            v-if="checkIfToggleBtnAvailable(item)" 
                            class="toggle-btn transparent-button p-2"
                            @click="selectedRowIndex = selectedRowIndex == index ? null : index" 
                        >
                            <arrow-icon 
                                :transform="selectedRowIndex == index ? 270 : 90"
                                class="i-grey"
                            />
                        </button>
                        <div class="column-start">
                            <span class="f-13-black f-normal break-all pr-4 s-align-4">
                                {{ item.summary ? item.summary : '-' }}
                            </span>
                            <span
                                v-if="isEventsInProperty"
                                class="f-13-black f-sbold pr-4 s-align-4"
                            >
                                {{ whoOwnsTheEvent(item) }}
                            </span>
                        </div>
                    </div>
                    <div
                        v-if="!dScreen"
                        class="sm-1 nb-1 md-1 s-align-6"
                    >
                        <context-menu :unicId="`events-table-menu-${index}`">
                            <button
                                class="s-align-4"   
                                @click="action('edit', item)"  
                                v-if="showEditEventControls(item.id)"
                            >
                                <span class="icon-container mr-6">
                                    <edit-icon/>
                                </span>
                                <span>Edit Event</span>
                            </button>
                            <button
                                v-if="showDeleteEventControls(item.id)"
                                class="s-align-4"
                                @click="action('remove', item)"
                            >
                                <span class="icon-container mr-6">
                                    <trash-icon width="15"/>
                                </span>
                                <span>Delete Event</span>
                            </button>
                        </context-menu>
                    </div>
                    <div class="table-cell-content column-start s-align-2 lg-2 nb-12 md-12 sm-12 pv-2 pr-2">
                        <span class="f-13-black w-100 break-word pr-8">{{ item.owner.fullname }}</span>
                        <span class="table-hint f-10-grey uppercase pb-6">Responsible</span>
                    </div>
                    <div
                        v-if="!+item.all_day"
                        class="table-cell-content column-start s-align-2 lg-2 nb-12 md-12 sm-12 pv-2 pr-2"
                    >
                        <span class="f-13-black">
                            {{ item.dtstart | date }}
                            <br/>
                            <span class="f-13-grey pr-1">at</span>
                            {{ item.dtstart | time }}
                        </span>
                        <span class="table-hint f-10-grey uppercase pb-6">Start Date</span>
                    </div>
                    <div
                        v-if="!+item.all_day"
                        class="table-cell-content column-start s-align-2 lg-2 nb-12 md-12 sm-12 pv-2 pr-2"
                    >
                        <span class="f-13-black">
                            {{ item.dtend | date }}
                            <br/>
                            <span class="f-13-grey pr-1">at</span>
                            {{ item.dtend | time }}
                        </span>
                        <span class="table-hint f-10-grey uppercase pb-6">End Date</span>
                    </div>
                    <div
                        v-if="+item.all_day"
                        class="table-cell-content column-start s-align-2 lg-4 nb-12 md-12 sm-12 pv-2 pr-2"
                    >
                        <span :class="['f-13-black w-70', dScreen ? 't-center' : 't-left']">
                            All day event <br/>
                            <span v-if="ifOneDay(item.dtstart,item.dtend)">
                                {{ item.dtstart | date_ignore_tz }}
                            </span>
                            <span v-else>
                                {{ item.dtstart | date_ignore_tz }} - {{ allDayEndDate(item.dtend) | date_ignore_tz }}
                            </span>
                        </span>
                        <span class="table-hint f-10-grey uppercase pb-6">Date</span>
                    </div>
                    <div class="table-cell-content column-start lg-2 nb-12 md-12 sm-12 pv-2 pr-2">
                        <span class="f-13-blue">
                            {{ item.location }}
                        </span>
                        <span class="table-hint f-10-grey uppercase pb-2">
                            Location
                        </span>
                    </div>
                    <div
                        class="lg-2 s-align-6"
                        v-if="dScreen"
                    >
                        <attach-icon
                            class="i-blue mr-2"
                            size="12"
                        />
                        <span class="f-13-blue mr-30">
                            {{ item.attachments.length }}
                        </span>
                        <button
                            class="icon-light mr-1"
                            @click="action('edit', item)"
                            v-if="showEditEventControls(item.id) && !disableEditPropertyEvent(item)"
                        >
                            <edit-icon size="14"/>
                        </button>
                        <button
                            v-if="showDeleteEventControls(item.id)"
                            class="icon-light"
                            @click="action('remove', item)"
                        >
                            <trash-icon/>
                        </button>
                    </div>
                    <div
                        :class="[
                            'column-start w-100',
                            {'bt-grey pt-8 mt-8': selectedRowIndex == index }
                        ]"
                    >
                        <template v-if="checkIfDescriptionShowed(item, index)">
                            <p class="f-13-darkgrey f-sbold mb-1">
                                Description
                            </p>
                            <p
                                class="f-12-black mb-6"
                                v-html="item.description"
                            />
                        </template>
                        <div class="row-start">
                            <div
                                :class="[
                                    'event-attachment-wrapper s-align-4 ph-3 pv-1 mr-4',
                                    { 'event-attachment-wrapper-open' : selectedRowIndex == index }
                                ]"
                                v-for="file in item.attachments"
                                :key="file.id"
                                :title="file.files[0] ? file.files[0].original_name : ''"
                                v-show="file.files[0]"
                            >
                                <document-icon
                                    width="17"
                                    height="22"
                                />
                                <div
                                    v-if="file.files[0]"
                                    class="event-attachment-title w-90 ml-6"
                                >
                                    <span
                                        class="attachment-name f-13-black ellipsis pointer"
                                        @click="showPreviewModal(file.files[0])"
                                    >
                                        {{ file.files[0].original_name }}
                                    </span>
                                    <span
                                        class="f-13-grey ellipsis"
                                        v-if="selectedRowIndex == index"
                                    >
                                        <span>
                                            {{ file.files[0].size/1000 }}
                                        </span>
                                        KB
                                    </span>
                                </div>
                                <div
                                    v-if="selectedRowIndex == index"
                                    class="lg-2 s-align-6"
                                >
                                    <div class="event-attachment-delete-wrapper s-align-5">
                                        <button
                                            class="transparent-button p-1"
                                            @click="downloadAttachment(file.files[0])"
                                        >
                                            <download-icon
                                                class="i-blue"
                                                size="12"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="selectedRowIndex === index && item.attendees.length"
                            class="column-start w-100 mt-2"
                        >
                            <p class="f-14-asphalt f-sbold mb-4">Participants</p>
                            <div
                                v-for="(customer, index) in item.attendees"
                                :key="index"
                                class="row-start row-baseline mb-3"
                            >
                                <avatar
                                    :name="getName(customer)"
                                    :border="false"
                                    size="35"
                                    :url="customer.thumbnail"
                                    class="mr-3"
                                />
                                <div class="column-start">
                                    <span
                                        v-if="customer.first_name || customer.last_name"
                                        class="f-12-black hidden ellipsis"
                                    >
                                        {{ `${customer.first_name} ${customer.last_name}` }}
                                    </span>
                                    <span class="f-10-grey hidden ellipsis">{{ customer.email }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <file-preview-modal
                    v-if="previewModalOpened"
                    :file="selectedFileToPreview"
                    @close="previewModalOpened = false"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { EditIcon, TrashIcon, ArrowIcon, AttachIcon, DocumentIcon, DownloadIcon } from '@/icons';
    import ContextMenu from '@/components/ContextMenu.vue';
    import { FilePreviewModal } from '@/modals';
    import Avatar from '@/components/User/Avatar';
    import moment from 'moment/src/moment';
    import { MODEL_CLASSES } from '@/constants';

    const ENTITY_NAMES = {
        LEAD: 'Lead',
        PROPERTY: 'Property'
    }

    export default {
        name: 'EventsTable',
        props: {
            data: {
                type: Array,
                default: () => []
            },
            completedListSelected: {
                type: Boolean,
                default: false
            },
            calendarList: {
                type: Array,
                required: true
            },
            isEventsInProperty: {
                type: Boolean,
                required: false
            }
        },
        components: {
            EditIcon,
            TrashIcon,
            ArrowIcon,
            ContextMenu,
            AttachIcon,
            DocumentIcon,
            FilePreviewModal,
            DownloadIcon,
            Avatar
        },
        data() {
            return {
                selectedRowIndex: null,
                selectedMenuIndex: null,
                head: [
                    { name: 'Title', column: 2 },
                    { name: 'Responsible', column: 2 },
                    { name: 'Start Date', column: 2 },
                    { name: 'End Date', column: 2 },
                    { name: 'Address', column: 4 }
                ],
                previewModalOpened: false,
                selectedFileToPreview: null,
                url: process.env.VUE_APP_AMAZONAWS_URL
            }
        },
        computed: {
            loggedUserId() {
                return this.$store.getters['user/getUserId'];
            },
            hasDeleteSelfEvent() {
                return this.$can([this.$permissions.VIEW_MY_CALENDAR]);
            },
            onlyMyEvents() {
                let myCalendar = null;
                let myEvents = [];

                myCalendar = this.calendarList.find(calendar => {
                    return calendar.owner_id === this.loggedUserId
                        && calendar.owner_type === 'user';
                });

                myEvents = this.data
                    .filter(event => {
                        return event.calendar_id === myCalendar.calendar_id;
                    })
                    .map(event => event.id);

                return myEvents;
            }
        },
        methods: {
            getName(customer) {
                return customer.first_name && customer.last_name
                    ? customer.first_name + ' ' + customer.last_name
                    : customer.email;
            },
            action(action, item) {
                this.selectedMenuIndex = null;

                action == 'edit'
                    ? this.$emit('edit', item)
                    : this.$emit('remove', item);
            },
            showPreviewModal(file) {
                this.selectedFileToPreview = file;
                this.previewModalOpened = true;
            },
            downloadAttachment(file) {
                fetch(`${this.url}${file.id}`).then(data => data.blob())
                    .then(blob => {
                        let link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = file.original_name;
                        link.click();
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            checkIfToggleBtnAvailable(data) {
                if (!this.dScreen) { return true }

                return data.attachments.length || data.description || data.attendees.length;
            },
            checkIfDescriptionShowed(item, index) {
                return this.selectedRowIndex === index
                    && item.description
                    && item.description !== '\n';
            },
            ifOneDay(start,end) {
                let endDate = moment(end).clone().subtract(1, 'd').format('YYYY-MM-DD');
                return moment(start).isSame(moment(endDate));
            },
            allDayEndDate(end) {
                return moment(end).clone().subtract(1, 'd').format('YYYY-MM-DD');
            },
            showDeleteEventControls(id) {
                return this.$can([this.$permissions.DELETE_EVENT_FOR_OTHERS])
                    || this.hasDeleteSelfEvent && this.onlyMyEvents.includes(id);
            },
            showEditEventControls(id) {
                if (!this.completedListSelected && this.$can([this.$permissions.EDIT_EVENT_OTHERS])) {
                    return true;
                }

                if (this.completedListSelected 
                    || this.hasDeleteSelfEvent && !this.onlyMyEvents.includes(id)) {
                    return false;
                }

                if (this.$can([this.$permissions.EDIT_EVENT_OTHERS]) 
                    || this.hasDeleteSelfEvent && this.onlyMyEvents.includes(id)) {
                    return true;
                }
            },
            whoOwnsTheEvent(event) {
                if (this.isEventsInProperty) {
                    switch (event.entity_type) {
                        case MODEL_CLASSES.COMMERCIAL_JOB.PROPERTY: {
                            return ENTITY_NAMES.PROPERTY;
                        }
                        case MODEL_CLASSES.COMMERCIAL_LEAD: {
                            return ENTITY_NAMES.LEAD;
                        }
                        case MODEL_CLASSES.COMMERCIAL_JOB.JOB: {
                            return event.job_number ? event.job_number : '';
                        }
                    }
                }
            },
            disableEditPropertyEvent(event) {
                return event.entity_type === MODEL_CLASSES.COMMERCIAL_JOB.PROPERTY;
            }
        }
    }
</script>