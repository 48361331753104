export default {
    methods: {
        notifyError(message = 'Something went wrong. Try to update this page!') {
            this.$notify({
                group: 'main',
                type: 'error',
                title: 'Error!',
                text: message
            });
        },
        notifySuccess(message) {
            this.$notify({
                group: 'main',
                type: 'success',
                title: 'Success',
                text: message
            });
        },
        notifyWarning(message) {
            this.$notify({
                group: 'main',
                type: 'warn',
                title: 'Warning!',
                text: message
            });
        },
        notifyRequestErrors(error) {
            if (error && error.response && error.response.data) {
                const meta = error.response.data.meta;
                const description = meta.error_description;

                if (meta.code == 422 && Object.keys(description).length) {
                    Object.keys(description).forEach(key => {
                        description[key].forEach((item, index) => {
                            this.notifyError(description[key][index]);
                        });
                    });
                } else if (description) {
                    this.notifyError(description);
                } else {
                    this.notifyError(meta.error_message);
                }
            } else {
                this.notifyError(error);
                console.error(error);
            }
        }
    }
}