<template>
    <div class="not-found-message column-center t-center">
        <img
            v-if="!tableEmpty"
            src="@/assets/images/not-found-icon.svg" 
            width="100" 
            height="95" 
            alt="Not Found"
        />
        <table-empty
            v-if="tableEmpty"
            size="150"
        />
        <span 
            class="f-20-darkgrey f-sbold mb-2"
            v-if="location.length"
        >
            Nothing to display in {{ location }}
        </span>
        <span
            v-else-if="!location.length && !custom.length"
            class="f-20-darkgrey f-sbold mb-2"
        >
            Nothing to display
        </span>
        <span
            class="f-16-darkgrey f-sbold"
            v-else-if="custom.length"
            v-html="custom"
        />
    </div>
</template>

<script>
    import { TableEmpty } from '@/icons';
    export default {
        name: 'NotFoundMessage',
        components: {TableEmpty},
        props: {
            location: {
                type: String,
                default: ''
            },
            custom: {
                type: String,
                default: ''
            },
            tableEmpty: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
