<template>
    <svg 
        viewBox="0 0 36 36"
        :width="size" 
        :height="size"
        class="template-icon" 
    >
        <g id="XMLID_55_">
            <path class="st1" id="XMLID_56_" d="M3.4,1C3.2,0.7,2.9,0.6,2.7,0.8C1.7,1.4,1,2.2,0.5,3.2c-0.1,0.3,0,0.5,0.2,0.7c0.1,0,0.1,0,0.2,0 c0.2,0,0.4-0.1,0.5-0.3c0.4-0.8,1-1.5,1.8-2C3.4,1.5,3.5,1.2,3.4,1z"/>
            <path class="st1" id="XMLID_57_" d="M6.5,35h-1c-0.6,0-1.2-0.1-1.7-0.4c-0.3-0.1-0.5,0-0.7,0.3c-0.1,0.3,0,0.5,0.3,0.7C4,35.9,4.8,36,5.5,36h1C6.8,36,7,35.8,7,35.5S6.8,35,6.5,35z"/>
            <path class="st1" id="XMLID_58_" d="M15.3,0.5c0-0.3-0.2-0.5-0.5-0.5h-3c-0.3,0-0.5,0.2-0.5,0.5S11.5,1,11.8,1h3C15.1,1,15.3,0.8,15.3,0.5z"/>
            <path class="st1" id="XMLID_59_" d="M12.5,35h-3C9.2,35,9,35.2,9,35.5S9.2,36,9.5,36h3c0.3,0,0.5-0.2,0.5-0.5S12.8,35,12.5,35z"/>
            <path class="st1" id="XMLID_60_" d="M9.3,0.5C9.3,0.2,9.1,0,8.8,0h-3C5.5,0,5.3,0.2,5.3,0.5S5.5,1,5.8,1h3C9.1,1,9.3,0.8,9.3,0.5z"/>
            <path class="st1" id="XMLID_61_" d="M29.8,1h0.7c0.7,0,1.4,0.2,2,0.5c0.1,0,0.1,0.1,0.2,0.1c0.2,0,0.4-0.1,0.4-0.3c0.1-0.2,0-0.5-0.2-0.7 C32.2,0.2,31.4,0,30.5,0h-0.7c-0.3,0-0.5,0.2-0.5,0.5S29.5,1,29.8,1z"/>
            <path class="st1" id="XMLID_62_" d="M0.5,9.9C0.8,9.9,1,9.6,1,9.4v-3c0-0.3-0.2-0.5-0.5-0.5S0,6.1,0,6.4v3C0,9.6,0.2,9.9,0.5,9.9z"/>
            <path class="st1" id="XMLID_63_" d="M0.5,15.9c0.3,0,0.5-0.2,0.5-0.5v-3c0-0.3-0.2-0.5-0.5-0.5S0,12.1,0,12.4v3C0,15.6,0.2,15.9,0.5,15.9z"/>
            <path class="st1" id="XMLID_64_" d="M1,30.5v-0.1c0-0.3-0.2-0.5-0.5-0.5S0,30.1,0,30.4v0.1c0,1.1,0.3,2.1,0.9,3c0.1,0.1,0.3,0.2,0.4,0.2 c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.3-0.5,0.1-0.7C1.2,32.2,1,31.4,1,30.5z"/>
            <path class="st1" id="XMLID_65_" d="M0.5,21.9c0.3,0,0.5-0.2,0.5-0.5v-3c0-0.3-0.2-0.5-0.5-0.5S0,18.1,0,18.4v3C0,21.6,0.2,21.9,0.5,21.9z"/>
            <path class="st1" id="XMLID_66_" d="M0.5,27.9c0.3,0,0.5-0.2,0.5-0.5v-3c0-0.3-0.2-0.5-0.5-0.5S0,24.1,0,24.4v3C0,27.6,0.2,27.9,0.5,27.9z"/>
            <path class="st1" id="XMLID_67_" d="M27.3,0.5c0-0.3-0.2-0.5-0.5-0.5h-3c-0.3,0-0.5,0.2-0.5,0.5S23.5,1,23.8,1h3C27.1,1,27.3,0.8,27.3,0.5z"/>
            <path class="st1" id="XMLID_68_" d="M35.5,20.4c-0.3,0-0.5,0.2-0.5,0.5v3c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5v-3 C36,20.7,35.8,20.4,35.5,20.4z"/>
            <path class="st1" id="XMLID_69_" d="M18.5,35h-3c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h3c0.3,0,0.5-0.2,0.5-0.5S18.8,35,18.5,35z"/>
            <path class="st1" id="XMLID_70_" d="M35.5,8.4c-0.3,0-0.5,0.2-0.5,0.5v3c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5v-3C36,8.7,35.8,8.4,35.5,8.4z"/>
            <path class="st1" id="XMLID_71_" d="M35.5,26.4c-0.3,0-0.5,0.2-0.5,0.5v3c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5v-3C36,26.7,35.8,26.4,35.5,26.4z"/>
            <path class="st1" id="XMLID_72_" d="M35.3,2.8c-0.1-0.2-0.4-0.3-0.7-0.2c-0.2,0.1-0.3,0.4-0.2,0.7C34.8,4,35,4.7,35,5.5v0.4c0,0.3,0.2,0.5,0.5,0.5S36,6.2,36,5.9V5.5C36,4.6,35.8,3.6,35.3,2.8z"/>
            <path class="st1" id="XMLID_73_" d="M35.5,14.4c-0.3,0-0.5,0.2-0.5,0.5v3c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5v-3C36,14.7,35.8,14.4,35.5,14.4z"/>
            <path class="st1" id="XMLID_74_" d="M21.3,0.5c0-0.3-0.2-0.5-0.5-0.5h-3c-0.3,0-0.5,0.2-0.5,0.5S17.5,1,17.8,1h3C21.1,1,21.3,0.8,21.3,0.5z"/>
            <path class="st1" id="XMLID_75_" d="M24.5,35h-3c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h3c0.3,0,0.5-0.2,0.5-0.5S24.8,35,24.5,35z"/>
            <path class="st1" id="XMLID_76_" d="M30.5,35h-3c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h3c0.3,0,0.5-0.2,0.5-0.5S30.8,35,30.5,35z"/>
            <path class="st1" id="XMLID_77_" d="M35.1,32.4c-0.2-0.1-0.5,0-0.7,0.2c-0.4,0.8-1.1,1.5-1.9,1.9c-0.2,0.1-0.3,0.4-0.2,0.7c0.1,0.2,0.3,0.3,0.4,0.3c0.1,0,0.2,0,0.2-0.1c1-0.5,1.8-1.3,2.3-2.3C35.5,32.8,35.4,32.5,35.1,32.4z"/>
        </g>
        <g id="XMLID_54_">
            <path id="XMLID_2_" class="st0" d="M29.9,17.2H15.6c-0.5,0-1-0.5-1-1V6c0-0.6,0.5-1,1-1h14.3c0.6,0,1,0.4,1,1v10.1C30.9,16.7,30.5,17.2,29.9,17.2z"/>
            <path id="XMLID_4_" class="st0" d="M11.8,17.2H6c-0.6,0-1-0.5-1-1V6c0-0.6,0.4-1,1-1h5.8c0.6,0,1,0.4,1,1v10.1C12.8,16.7,12.4,17.2,11.8,17.2z"/>
            <path id="XMLID_3_" class="st0" d="M30,31H6c-0.5,0-1-0.5-1-1V19.8c0-0.6,0.5-1,1-1H30c0.5,0,1,0.4,1,1V30C31,30.5,30.5,31,30,31z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'TemplateIcon',
        props: {
            size: {
                type: [Number, String],
                default: 36
            }
        }
    }
</script>