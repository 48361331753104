<template>
    <div class="modal s-align-5">
        <div class="create-new-folder-modal cm-fond">
            <div class="f-24-black f-sbold mb-20 row-between align-center">
                <span>
                    Assign quality  control
                </span>
                <button
                    class="transparent-button"
                    @click="$emit('close')"
                >
                    <close-icon
                        size="13"
                        class="i-grey"
                    />
                </button>
            </div>
            <div class="w-100">
                <div
                    v-if="!requestIsGoing"
                    class="row-between"
                >
                    <div class="f-14-black f-sbold mb-2 flex-no-wrap">
                        Choose user from the list below
                    </div>
                    <div
                        class="underline f-9-grey pointer s-align-6"
                        @click="assigned = null"
                    >
                        Clear
                    </div>
                </div>
                <div class="column-end">
                    <loader
                        v-if="requestIsGoing"
                        size="mini"
                        class="mh-auto pv-6"
                    />
                    <template v-else>
                        <multiselect
                            v-model="assigned"
                            placeholder="Assign to"
                            track-by="fullname"
                            name="assign_to"
                            data-name="assign_to"
                            :options="users"
                            multiple
                            :option-height="104"
                            :show-labels="false"
                            :allow-empty="false"
                            label="fullname"
                        >
                            <template
                                slot="singleLabel"
                                slot-scope="props"
                            >
                                <template v-if="props.option.first_name">
                                    <user-box
                                        :firstName="props.option.first_name"
                                        :lastName="props.option.last_name"
                                        :avatarUrl="props.option.avatar"
                                        :border="false"
                                    />
                                </template>
                                <template v-else>
                                    <p class="f-14-grey">Assign to...</p>
                                </template>
                            </template>
                            <template
                                slot="option"
                                slot-scope="props"
                            >
                                <user-box
                                    :firstName="props.option.first_name"
                                    :lastName="props.option.last_name"
                                    :avatarUrl="props.option.avatar"
                                    :border="false"
                                />
                            </template>
                        </multiselect>
                    </template>
                </div>
            </div>
            <div class="custom-modal-footer row-end">
                <button
                    class="add-button mr-8"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button
                    class="primary-button"
                    @click="save"
                    :disabled="requestIsGoing"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    import { CloseIcon } from '@/icons';
    import Multiselect from 'vue-multiselect';
    import UserBox from '@/components/User/UserBox.vue';
    import api from '@/headers.js';
    import { Loader } from '@/ui';
    import { BASE_ENTITY_TYPES } from '@/constants';

    export default {
        name: 'SetQualityControlModal',
        components: {
            Loader,
            CloseIcon,
            Multiselect,
            UserBox
        },
        props: {
            entityType: {
                type: String,
                default: ''
            }
        },
        computed: {
            lead() {
                return this.$store.getters['lead/getLeadPrimaryInformation'];
            },
            job() {
                return this.$store.getters['commercial/getCommercialJobPreview'];
            },
            warrantyJob() {
                return this.$store.getters['warranty/getWarrantyJobData'];
            },
            jobId() {
                return this.$store.getters['commercial/getCommercialJobId'];
            }
        },
        data() {
            return {
                requestIsGoing: false,
                assigned: null,
                users: []
            }
        },
        methods: {
            getUsers() {
                this.requestIsGoing = true;
                const params = {
                    perPage: 999,
                    is_active: 1
                };

                this.$store.dispatch('users/USERS_GET', params)
                    .then(data => {
                        this.$store.commit('users/USERS_RESET', data.data);
                        this.users = data.data;
                        let assignedUsers;

                        if (this.entityType == BASE_ENTITY_TYPES.RESIDENTIAL && this.lead.quality_controls) {
                            assignedUsers = this.lead.quality_controls.map(el => {
                                return this.users.find(item => item.id == el.id);
                            });
                        }

                        if (this.entityType == BASE_ENTITY_TYPES.COMMERCIAL_JOB && this.job.quality_controls) {
                            assignedUsers = this.job.quality_controls.map(el => {
                                return this.users.find(item => item.id == el.id);
                            });
                        }

                        if (this.entityType === BASE_ENTITY_TYPES.WARRANTY_JOB && this.warrantyJob.quality_controls) {
                            assignedUsers = this.warrantyJob.quality_controls.map(el => {
                                return this.users.find(item => item.id === el.id);
                            });
                        }

                        this.assigned = assignedUsers;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => this.requestIsGoing = false);
            },
            save() {
                this.requestIsGoing = true;
                const assignedQCIds = this.assigned && this.assigned.length ? this.assigned.map(el => el.id) : [];
                const params = {
                    quality_control_ids: assignedQCIds && assignedQCIds.length ? assignedQCIds : null
                };

                if (this.entityType == BASE_ENTITY_TYPES.RESIDENTIAL && this.lead) {
                    api.put(`/leads/${this.lead.id}`, params)
                        .then(() => {
                            this.notifySuccess('Job is updated!');
                            this.$store.commit('lead/UPDATE_QUALITY_CONTROL', this.assigned);
                            this.$emit('close');
                        })
                        .catch((error) => {
                            this.notifyError('Changes have not been saved.');
                        })
                        .finally(() => {
                            this.requestIsGoing = false;
                        });
                }

                if (this.entityType == BASE_ENTITY_TYPES.COMMERCIAL_JOB && this.jobId) {
                    api.put(`/commercial/jobs/${this.jobId}`, params)
                        .then(() => {
                            this.notifySuccess('Job is updated!');
                            this.$store.commit('commercial/UPDATE_QUALITY_CONTROL', this.assigned);
                            this.$emit('close');
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.requestIsGoing = false;
                        });
                }

                if (this.entityType === BASE_ENTITY_TYPES.WARRANTY_JOB && this.warrantyJob.id) {
                    api.put(`/warranty-jobs/${this.warrantyJob.id}`, params)
                        .then(() => {
                            this.notifySuccess('Job is updated!');
                            this.$store.commit('warranty/UPDATE_QUALITY_CONTROL', this.assigned);
                            this.$emit('close');
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.requestIsGoing = false;
                        });
                }
            }
        },
        created() {
            this.getUsers();
        }
    }
</script>