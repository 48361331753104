import axios from 'axios';
import router from './router';
import store from './store/store';
import Cookies from "js-cookie";

const UNDER_CONSTRUCTION_ERROR_TYPE = 'MaintenanceModeException';

const base = axios.create({
    baseURL: process.env.VUE_APP_URL,
    headers: {
        Authorization: `Bearer ${localStorage.getItem('user-token') || ''}`
    },
    // responseType` indicates the type of data that the server will respond with
    // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
    // browser only: 'blob'
    responseType: 'json', // default

    // responseEncoding` indicates encoding to use for decoding responses
    // Note: Ignored for `responseType` of 'stream' or client-side requests
    responseEncoding: 'utf8', // default
});

base.defaults.withCredentials = true;

base.interceptors.response.use((response) => {
    return  response;
}, (error) => {

    if (error.response && error.response.status === 401) {
          /* *
         * TODO Need impliments refresh token.
         * TTL token - never expiring
         **/
        store.commit('auth/AUTH_LOGOUT');

        setTimeout(() => {
            router.push({ name: 'Login'})
        }, 2000);
    }

    if (error.response
        && error.response.status === 403
    ) {
        router.push({ name: 'AccessDeniedPage' });
    }

    if (error.response
        && error.response.status === 503
        && error.response.data.meta.error_type === UNDER_CONSTRUCTION_ERROR_TYPE
    ) {
        router.push({ name: 'UnderConstructionPage' });
    }

    return Promise.reject(error)
});

Cookies.set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);

export default base;
