import {
    GET_USER_DATA,
    UPDATE_USER_PERSONAL,
    CHECK_USER_PASSWORD,
    UPDATE_USER_EMAIL,
    UPDATE_USER_PASSWORD,
    UPDATE_USER_AVATAR,
    REMOVE_USER_AVATAR,
    GET_EMAILS_PEOPLE
} from '../actions/user';
import api from '@/headers.js';

export default {
    namespaced: true,
    state: {
        data: {},
        isDataLoaded: false,
        permissions: [],
        roles: [],
        emailsPeople: []
    },
    getters: {
        getUserData: state => state.data,
        getUserFullName: state => state.data.fullname,
        getUserAvatar: state => state.data.avatar,
        getUserThumbnail: state => state.data.thumbnail,
        getUserId: state => state.data.id,
        getUserFirstName: state => state.data.first_name,
        getUserLastName: state => state.data.last_name,
        isUserDataLoaded: state => state.isDataLoaded,
        getUserRoles: state => state.roles,
        getUserPermissions: state => state.permissions,
        getUserCompanyId: state => state.data.company_id,
        getUserSignature: state => state.data.signature,
        isWeekStartOnMonday: state => state.data.start_week_on_monday,
        getVersion: state => state.data.version,
        getEmailsPeople: state => state.emailsPeople
    },
    mutations: {
        [GET_EMAILS_PEOPLE] (state, payload) {
            state.emailsPeople = payload;
        },
        [GET_USER_DATA](state, payload) {
            state.data = payload;
            state.isDataLoaded = true;
        },
        [UPDATE_USER_PERSONAL](state, payload) {
            state.data = payload;
        },
        [REMOVE_USER_AVATAR](state) {
            state.data.avatar = null;
            state.data.thumbnail = null;
        },
        setPermissions(state, payload) {
            state.permissions = payload;
        },
        setRoles(state, payload) {
            state.roles = payload;
        },
    },
    actions: {
        [GET_USER_DATA]({commit, dispatch}) {
            return new Promise((resolve, reject) => {
                api.get('/me')
                    .then(response => response.data.data)
                    .then(data => {
                        commit('setPermissions', data.permissions);
                        commit('setRoles', data.roles);
                        commit(GET_USER_DATA, data);
                        if (data.telephony) {
                            commit(
                                'telephony/SWITCH_WIDGET',
                                data.telephony.hasOwnProperty('enable') ? +data.telephony.enable : 1,
                                {root: true}
                            );
                        } else {
                            commit('telephony/SWITCH_WIDGET', true, {root: true});
                        }
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        [UPDATE_USER_PERSONAL]({commit, dispatch }, user) {
            return new Promise((resolve, reject) => {
                api.put('/users/', user)
                    .then(response => response.data.data)
                    .then(data => {
                        commit(UPDATE_USER_PERSONAL, data);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        [CHECK_USER_PASSWORD]({commit, dispatch }, password) {
            return new Promise((resolve, reject) => {
                api.post('/edit-profile/check-password', { 'password': password })
                    .then(response => {
                        resolve(response.data.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        [UPDATE_USER_EMAIL]({commit, dispatch }, params) {
            return new Promise((resolve, reject) => {
                api.post('/edit-profile/email', params)
                    .then(() => {
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        [UPDATE_USER_PASSWORD]({commit, dispatch }, params) {
            return new Promise((resolve, reject) => {
                api.post('/edit-profile/password', params)
                    .then(() => {
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        [UPDATE_USER_AVATAR]({commit, dispatch }, formData) {
            return new Promise((resolve, reject) => {
                api.post('/edit-profile/image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(() => {
                        dispatch(GET_USER_DATA);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        [REMOVE_USER_AVATAR]({commit, dispatch }) {
            return new Promise((resolve, reject) => {
                api.delete('/edit-profile/remove-avatar')
                    .then(response => response.data.data)
                    .then(data => {
                        if (data) {
                            commit(REMOVE_USER_AVATAR);
                            resolve();
                        }
                        reject();
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        }
    }
}
