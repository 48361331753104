<template>
    <div class="row-between mt-20 pb-30">
        <div
            v-if="loading"
            class="order-create-main-info"
        >
            <loader/>
        </div>
        <div
            v-else
            class="order-create-main-info"
        >
            <div class="order-header-block">
                <div :class="[mScreen ? 'mb-6' : 'mb-30']">
                    <input
                        type="text"
                        name="order_name"
                        :class="[
                            'transparent-input order-header-block-input w-100 pointer',
                            { 'transparent-input-danger' : errors.has('order_name') },
                        ]"
                        maxlength="251"
                        v-validate="'required|min:3|max:250'"
                        placeholder="Enter order name"
                        v-model="order.name"
                        @input="updateOrder('name')"
                    />
                </div>

                <div class="tabs-menu">
                    <div class="tabs-menu-panel row-start">
                        <button
                            v-if="order.has_material"
                            :class="[
                                'tabs-menu-item h-100 transparent-button',
                                { 'tabs-menu-item-active' : activeTab === TABS.MATERIAL }
                            ]"
                            @click="activeTab = TABS.MATERIAL"
                        >
                            Material
                        </button>
                        <button
                            v-if="order.has_labor"
                            :class="[
                                'tabs-menu-item h-100 transparent-button',
                                { 'tabs-menu-item-active' : activeTab === TABS.LABOR }
                            ]"
                            @click="activeTab = TABS.LABOR"
                        >
                            Labor
                        </button>
                    </div>
                </div>
            </div>
            <div
                v-if="activeTab === TABS.MATERIAL"
                class="main-info-materials grabbable-section"
                @dragover.prevent
            >
                <div class="row-between row-baseline mb-20">
                    <span class="f-16-black f-sbold">Material order</span>
                    <span class="f-22-black f-sbold">
                        {{ materialsTotalOrder | currency }}
                    </span>
                </div>
                <template v-for="(material, index) in order.items">
                    <div
                        v-if="material.type_id === ITEM_TYPES.MATERIAL"
                        :key="index"
                        :class="[
                            'material-item row-start row-baseline mb-1 relative',
                            { 'grabbable-item-selected': (draggingIndex === index) }
                        ]"
                        :ref="`material-item-${index}`"
                        :draggable="false"
                        @dragstart="dragStart(material, index, $event)"
                        @dragover.prevent="dragOver(material, index)"
                        @dragend="dragEnd(material, index, $event)"
                    >
                        <div class="lg-6 sm-12 mb-sm-1 column-start pl-4">
                            <div
                                @mousedown="enableDrag"
                                @mouseup="disableDrag"
                                class="grabbable-drag-place grabbable s-align-5 on-desktop-visible"
                            >
                                <drag-icon size="10"/>
                            </div>
                            <div class="inline-flex">
                                <p class="f-14-black mb-1">
                                    {{ material.name }}
                                </p>
                                <button
                                    v-if="material.material && material.material.group_materials.length > 1"
                                    class="transparent-button ml-3 mb-1"
                                    @click.stop="materialPopupId = materialPopupId === material.id ? null : material.id"
                                >
                                    <color-wheel-icon/>
                                </button>
                                <div
                                    class="relative"
                                    v-if="materialPopupId === material.id"
                                >
                                    <material-colors-popup
                                        :materialId="material.id"
                                        :open="!!materialPopupId"
                                        :itemIndex="index"
                                        :sectionIndex="index"
                                        :colors="material.material.group_materials"
                                        :currentColorId="material.material_id"
                                        @close="materialPopupId = null"
                                        @update="updateColorOfMaterial"
                                    />
                                </div>
                            </div>
                            <button
                                v-show="!material.description"
                                class="add-notes-button transparent-button f-13-blue"
                                @click="showMaterialNotesInput(index)"
                            >
                                + Add notes
                            </button>
                        </div>
                        <div
                            :class="[
                                'lg-3 sm-6',
                                mScreen ? 's-align-4' : 's-align-6'
                            ]"
                        >
                            <form-input
                                label="Quantity"
                                :class="[
                                    'form-input-with-select',
                                    {'form-input-danger' : errors.first(`order_material_${index}_quantity`)}
                                ]"
                            >
                                <input
                                    class="form-input-field"
                                    :id="`order_material_${index}_quantity`"
                                    :name="`order_material_${index}_quantity`"
                                    v-input-style="material.quantity"
                                    v-model.number="material.quantity"
                                    type="text"
                                    placeholder="Quantity"
                                    v-validate="'required|min_value:0'"
                                    @input="updateItem(index)"
                                    autocomplete="off"
                                    v-limiter
                                />
                                <multiselect
                                    :options="[material.estimate_unit, material.order_unit]"
                                    :name="`order_material_${index}_unit`"
                                    :data-name="`order_material_${index}_unit`"
                                    v-model="material.per_unit"
                                    :max-height="200"
                                    placeholder="Unit"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    :searchable="false"
                                    v-validate="'required'"
                                    @select="value => onChangeUnit(material, index, value)"
                                    :class="[
                                        {'multiselect-danger' : errors.has(`order_material_${index}_unit`)}
                                    ]"
                                />
                            </form-input>
                        </div>
                        <div class="lg-3 sm-6 s-align-6">
                            <div class="column-end">
                                <p class="f-16-black f-sbold">
                                    {{ (Number(material.cost) * material.quantity).toFixed(2) | currency }}
                                </p>
                                <p class="f-14-black">
                                    {{ material.cost | numberWithCommas }}
                                    <span class="f-14-grey">
                                        /{{ material.per_unit }}
                                    </span>
                                </p>
                            </div>
                            <button
                                class="transparent-button ml-20"
                                @click="deleteItem(material.id, 'Material')"
                                :disabled="requestIsGoing"
                            >
                                <trash-icon class="i-grey"/>
                            </button>
                        </div>
                        <div
                            v-show="material.description"
                            class="material-notes lg-12 pl-4"
                        >
                            <textarea
                                type="text"
                                :name="`material-notes-${index}`"
                                class="notes-input w-100"
                                placeholder="Material notes"
                                v-resize-textarea
                                v-model="material.description"
                                rows="1"
                                @input="updateItem(index)"
                            />
                        </div>
                    </div>
                </template>
            </div>
            <div
                v-if="activeTab === TABS.LABOR"
                class="main-info-labors grabbable-section"
                @dragover.prevent
            >
                <div class="row-between row-baseline">
                    <span class="f-16-black f-sbold">Labor order</span>
                    <span class="f-22-black f-sbold">
                        {{ laborsTotalOrder | currency }}
                    </span>
                </div>
                <div
                    class="row-start mb-5"
                    ref="crewInstruction"
                >
                    <button
                        v-show="!order.labor_description"
                        class="add-notes-button transparent-button f-13-blue"
                        @click="showCrewInsctuctionInput"
                    >
                        + Add crew instruction
                    </button>
                    <div
                        v-show="order.labor_description"
                        class="input-wrapper w-100"
                    >
                        <textarea
                            type="text"
                            name="labor_description"
                            class="notes-input w-100"
                            placeholder="Material notes"
                            v-resize-textarea
                            v-model="order.labor_description"
                            rows="1"
                            @input="updateOrder('crewInstruction')"
                        />
                    </div>
                </div>
                <template v-for="(labor, index) in order.items">
                    <div
                        v-if="labor.type_id === ITEM_TYPES.LABOR"
                        :key="index"
                        :ref="`labor-item-${index}`"
                        :class="[
                            'labor-item row-between row-baseline mb-1',
                            { 'grabbable-item-selected': (draggingIndex === index) }
                        ]"
                        :draggable="false"
                        @dragstart="dragStart(labor, index, $event)"
                        @dragover.prevent="dragOver(labor, index)" 
                        @dragend="dragEnd(labor, index, $event)"
                    >
                        <div class="labor-fields row-between relative pl-20">
                            <div
                                @mousedown="enableDrag"
                                @mouseup="disableDrag"
                                class="grabbable-drag-place grabbable s-align-5 on-desktop-visible"
                            >
                                <drag-icon size="10"/>
                            </div>
                            <div class="lg-12 mb-4 mb-sm-1 ph-1">
                                <form-input
                                    label="Name"
                                    :class="[
                                        {'form-input-danger' : errors.first(`order_labor_${index}_name`)}
                                    ]"
                                >
                                    <input
                                        class="form-input-field"
                                        :name="`order_labor_${index}_name`"
                                        type="text"
                                        placeholder="Name"
                                        maxlength="256"
                                        v-validate="'required|min:3|max:255'"
                                        v-input-style="labor.name"
                                        v-model="labor.name"
                                        @input="updateItem(index)"
                                    />
                                </form-input>
                            </div>
                            <div class="lg-4 sm-12 mb-sm-1 ph-1">
                                <form-input
                                    label="Quantity"
                                    :class="[
                                        'form-input-with-select',
                                        {'form-input-danger' : errors.first(`order_labor_${index}_quantity`)}
                                    ]"
                                >
                                    <input
                                        class="form-input-field"
                                        :id="`order_labor_${index}_quantity`"
                                        :name="`order_labor_${index}_quantity`"
                                        v-input-style="labor.quantity"
                                        v-model.number="labor.quantity"
                                        type="text"
                                        placeholder="Quantity"
                                        v-validate="'required|min_value:0'"
                                        autocomplete="off"
                                        @input="updateItem(index)"
                                        v-limiter
                                    />
                                    <multiselect
                                        :options="[labor.estimate_unit, labor.order_unit]"
                                        :name="`order_labor_${index}_unit`"
                                        :data-name="`order_labor_${index}_unit`"
                                        v-model="labor.per_unit"
                                        :max-height="200"
                                        placeholder="Unit"
                                        :show-labels="false"
                                        :allow-empty="false"
                                        :searchable="false"
                                        v-validate="'required'"
                                        @select="value => onChangeUnit(labor, index, value)"
                                        :class="[
                                            {'multiselect-danger' : errors.has(`order_labor_${index}_unit`)}
                                        ]"
                                    />
                                </form-input>
                            </div>
                            <div class="lg-4 sm-12 mb-sm-1 ph-1">
                                <form-input
                                    label="Price / Unit"
                                    :class="[
                                        {'form-input-danger' : errors.first(`order_labor_${index}_price_unit`)}
                                    ]"
                                    maskBefore="$"
                                    :maskValue="labor.cost"
                                    maskFilter="numberWithCommas"
                                >
                                    <input
                                        class="form-input-field"
                                        :name="`order_labor_${index}_price_unit`"
                                        v-input-style="labor.cost"
                                        v-model="labor.cost"
                                        type="number"
                                        placeholder="Price / Unit"
                                        autocomplete="off"
                                        v-validate="'required'"
                                        v-limiter
                                        @input="updateItem(index)"
                                    />
                                </form-input>
                            </div>
                            <div class="lg-4 sm-12 ph-1">
                                <form-input
                                    label="Labor price"
                                    :class="[
                                        {'form-input-danger' : errors.first(`order_labor_${index}_price`)}
                                    ]"
                                    maskBefore="$"
                                    :maskValue="(Number(labor.cost) * labor.quantity).toFixed(2)"
                                    maskFilter="numberWithCommas"
                                >
                                    <input
                                        class="form-input-field"
                                        :name="`order_labor_${index}_price`"
                                        v-input-style="(Number(labor.cost) * labor.quantity).toFixed(2)"
                                        :value="(Number(labor.cost) * labor.quantity).toFixed(2)"
                                        type="number"
                                        placeholder="Labor price"
                                        autocomplete="off"
                                        v-validate="'required'"
                                        v-limiter
                                        disabled
                                    />
                                </form-input>
                            </div>
                        </div>
                        <div class="labor-delete-btn s-align-6">
                            <button
                                class="transparent-button ml-20"
                                @click="deleteItem(labor.id, 'Labor')"
                                :disabled="requestIsGoing"
                            >
                                <trash-icon class="i-grey"/>
                            </button>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="order-create-sidebar">
            <div class="sidebar-btns">
                <p class="f-16-white f-sbold mb-8">
                    Sources to import {{ activeTab }}s
                </p>
                <button
                    class="sidebar-btn w-100 mb-1 s-align-4"
                    @click="addFromTemplateModalOpen = true"
                >
                    <template-icon
                        class="mr-5"
                        size="14"
                    />
                    <span>Template</span>
                </button>
                <button
                    class="sidebar-btn w-100 mb-1 s-align-4"
                    @click="addFromCompanyLibraryModalOpen = true"
                >
                    <star-icon
                        class="mr-5"
                        size="14"
                    />
                    <span>Company library</span>
                </button>
                <button
                    class="sidebar-btn w-100 mb-1 s-align-4"
                    @click="addCustomItemModalOpen = true"
                >
                    <gear-icon
                        class="mr-5"
                        size="14"
                    />
                    <span>
                        Add custom {{ activeTab }}
                    </span>
                </button>
                <button
                    class="sidebar-btn w-100 mb-1 s-align-4"
                    @click="addFromEstimateModalOpen = true"
                    v-can:some="[$permissions.VIEW_ALL_ESTIMATE, $permissions.VIEW_MY_ESTIMATE]"
                >
                    <calculator-icon
                        class="mr-5"
                        size="14"
                    />
                    <span>Estimate</span>
                </button>
            </div>
        </div>
        <order-add-from-template-modal
            v-if="addFromTemplateModalOpen"
            :typeId="typeId"
            :items="order.items"
            @onAddItems="addNewItemSpread"
            @onDeleteItem="onDeleteItem"
            @onDeleteTemplate="getOrder(false, false)"
            @close="addFromTemplateModalOpen = false"
        />
        <order-add-from-company-library-modal
            v-if="addFromCompanyLibraryModalOpen"
            :typeId="typeId"
            :items="order.items"
            @onAddItem="addNewItem"
            @onDeleteItem="onDeleteItem"
            @close="addFromCompanyLibraryModalOpen = false"
        />
        <order-add-custom-item-modal
            v-if="addCustomItemModalOpen"
            :typeId="typeId"
            @onAddItem="addCustomItem"
            @close="addCustomItemModalOpen = false"
        />
        <order-add-from-estimate-modal
            v-if="addFromEstimateModalOpen"
            :typeId="typeId"
            :items="order.items"
            :entityType="entityType"
            :entityId="entityId"
            @onAddItems="addNewItemSpread"
            @onDeleteItem="onDeleteItem"
            @onDeleteEstimateSection="getOrder(false, false)"
            @close="addFromEstimateModalOpen = false"
        />
        <loader
            size="full"
            v-if="changeColorLoading || addNewItemLoading"
        />
    </div>
</template>

<script>
    import { ENTITY_TYPES, MATERIAL, LABOR, ORDER_STATUSES } from '@/constants';
    import { MaterialColorsPopup } from '@/components';
    import { scrollToElement, copyFieldsValueFromObjectToObject } from '@/helpers';
    import { FormInput, Loader } from '@/ui';
    import { DragIcon } from '@/icons';
    import Multiselect from 'vue-multiselect';
    import debounce from 'lodash/debounce';
    import api from '@/headers.js';
    import moment from 'moment/src/moment';

    export default {
        name: 'OrderCreateStep4',
        components: {
            TrashIcon: () => import('@/icons/TrashIcon.vue'),
            ColorWheelIcon: () => import('@/icons/ColorWheelIcon.vue'),
            TemplateIcon: () => import('@/icons/TemplateIcon.vue'),
            StarIcon: () => import('@/icons/StarIcon.vue'),
            GearIcon: () => import('@/icons/GearIcon.vue'),
            CalculatorIcon: () => import('@/icons/CalculatorIcon.vue'),
            OrderAddFromTemplateModal: () => import('@/modals/OrderAddFromTemplateModal.vue'),
            OrderAddFromCompanyLibraryModal: () => import('@/modals/OrderAddFromCompanyLibraryModal.vue'),
            OrderAddCustomItemModal: () => import('@/modals/OrderAddCustomItemModal.vue'),
            OrderAddFromEstimateModal: () => import('@/modals/OrderAddFromEstimateModal.vue'),
            FormInput,
            Loader,
            Multiselect,
            MaterialColorsPopup,
            DragIcon
        },
        props: {
            entityType: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                showPreview: false,
                ITEM_TYPES: {
                    MATERIAL: MATERIAL,
                    LABOR: LABOR
                },
                TABS: {
                    MATERIAL: 'material',
                    LABOR: 'labor'
                },
                loading: false,
                requestIsGoing: false,
                activeTab: '',
                addFromTemplateModalOpen: false,
                addFromCompanyLibraryModalOpen: false,
                addCustomItemModalOpen: false,
                addFromEstimateModalOpen: false,
                order: {},
                materials: [],
                labors: [],
                materialPopupId: null,
                changeColorLoading: false,
                addNewItemLoading: false,
                draggingOverIndex: null,
                draggingOverItem: null,
                draggingItem: '',
                draggingIndex: null,
                sortValue: null
            }
        },
        computed: {
            formData() {
                return this.$store.getters['orders/getOrderFormData'];
            },
            orderFromState() {
                return this.$store.getters['orders/getOrder'];
            },
            entityId() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD:
                        return this.$route.params.lead_id;
                        break;
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.$route.params.job_id;
                        break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.$route.params.lead_id;
                        break;
                    case ENTITY_TYPES.WARRANTY_JOB:
                        return this.$route.params.job_id;
                        break;
                }
            },
            materialsTotalOrder() {
                return this.order.items.reduce((sum, current) => {
                    if (current.type_id !== this.ITEM_TYPES.MATERIAL) {
                        return sum;
                    }

                    return sum + Number(current.cost) * current.quantity;
                }, 0).toFixed(2);
            },
            laborsTotalOrder() {
                return this.order.items.reduce((sum, current) => {
                    if (current.type_id !== this.ITEM_TYPES.LABOR) {
                        return sum;
                    }

                    return sum + Number(current.cost) * current.quantity;
                }, 0).toFixed(2);
            },
            // type id for modals, dependent on active tab
            typeId() {
                return this.activeTab === this.TABS.MATERIAL
                    ? this.ITEM_TYPES.MATERIAL
                    : this.ITEM_TYPES.LABOR;
            },
            allOrdersRoute() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD:
                        return 'ResidentialOrders';
                        break;
                    case ENTITY_TYPES.COMMERCIAL_JOB:
                        return 'CommercialJobOrders';
                        break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD:
                        return 'CommercialJobOrders';
                        break;
                }
            }
        },
        methods: {
            addNewItemSpread(item) {
                this.order.items.push(...item);
                this.getOrder(false, false, true);
            },
            addNewItem(item) {
                this.order.items.push(item);
                this.incrementOrderForItems(item, item.type_id);
            },
            addCustomItem(item) {
                this.order.items.push(item);
                this.sortItems();
                this.incrementOrderForItems(item, item.type_id);
                this.addCustomItemModalOpen = false;
            },
            sortItems() {
                this.order.items = this.order.items.sort((a, b) => {
                    if (a.sort < b.sort) {
                        return -1;
                    }

                    if (a.sort > b.sort) {
                        return 1;
                    }

                    return 0;
                });
            },
            createOrder() {
                const params = {
                    entity_id: this.entityId,
                    entity_type: this.entityType,
                    ...this.formData
                }

                if (this.formData.type === 'estimate') {
                    delete params.template_id;
                } else if (this.formData.type === 'template') {
                    delete params.estimate_id;
                } else {
                    delete params.template_id;
                    delete params.estimate_id;
                }

                this.loading = true;
                api.post('/orders', params)
                    .then(response => response.data.data)
                    .then(data => {
                        this.order = data;
                        this.setActiveTab();

                        this.$router.replace({
                            params: {
                                order_id: this.order.id
                            }
                        });
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            updateColorOfMaterial(data) {
                this.changeColorLoading = true;

                let params = {
                    item_id: this.materialPopupId,
                    material_id: data.id
                };

                api.post('/orders/items/change-reference', params)
                    .then(response => {
                        let item = this.order.items[data.itemIndex];
                        copyFieldsValueFromObjectToObject(response.data.data, item);
                        this.materialPopupId = null;
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.changeColorLoading = false;
                    });
            },
            getOrder(showLoader = true, setTab = true, newItemLoader) {
                if (showLoader) {
                    this.loading = true;
                }

                if (newItemLoader) {
                    this.addNewItemLoading = true;
                }

                api.get(`/orders/${this.$route.params.order_id}`)
                    .then(response => response.data.data)
                    .then(data => {
                        if (data.status === ORDER_STATUSES.SAVE) {
                            this.notifyWarning("You cannot edit the sent order!");
                            this.$router.push({ name: this.allOrdersRoute });
                            return;
                        }

                        this.order = data;

                        if (this.order.labor_delivery_date_from && this.order.labor_delivery_date_to) {
                            let datetimeFrom = moment.utc(data.labor_delivery_date_from).local();
                            let datetimeTo = moment.utc(data.labor_delivery_date_to).local();

                            this.order.labor_delivery_date_from = datetimeFrom.format('DD MMM YYYY');
                            this.order.labor_delivery_date_to = datetimeTo.format('DD MMM YYYY');
                            this.order.labor_delivery_time_from = this.order.labor_all_day_working
                                ? null
                                : datetimeFrom.format('HH:mm');
                            this.order.labor_delivery_time_to = this.order.labor_all_day_working
                                ? null
                                : datetimeTo.format('HH:mm');
                        }
                        if (setTab) {
                            this.setActiveTab();
                        }
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        if (showLoader) {
                            this.loading = false;
                        }

                        if (newItemLoader) {
                            this.addNewItemLoading = false;
                        }

                        this.sortItems();
                    });
            },
            setActiveTab() {
                if (this.$route.query.tab) {
                    this.activeTab = this.$route.query.tab;
                    this.$router.replace({ ...this.$route.params });
                } else if (this.order.has_material) {
                    this.activeTab = this.TABS.MATERIAL;
                } else {
                    this.activeTab = this.TABS.LABOR;
                }
            },
            onChangeUnit(item, index, value) {
                if (value === item.order_unit) {
                    this.order.items[index].cost = this.getCost(item.cost, item.order, item.estimate);
                } else if (value === item.estimate_unit) {
                    this.order.items[index].cost = this.getCost(item.cost, item.estimate, item.order);
                }
                this.updateItem(index);
            },
            getCost(cost, firstValue, secondValue) {
                return Math.round((cost * firstValue / secondValue) * 1000) / 1000;
            },
            showMaterialNotesInput(index) {
                const parent = this.$refs[`material-item-${index}`][0];
                // hide '+ add notes' button
                parent.querySelector('.add-notes-button').style.display = 'none';
                // show notes input
                parent.querySelector('.material-notes').style.display = 'flex';
                // focus on input
                parent.querySelector('.notes-input').focus();
            },
            showCrewInsctuctionInput() {
                const parent = this.$refs.crewInstruction;
                // hide '+ add crew instruction' button
                parent.querySelector('.add-notes-button').style.display = 'none';
                // show notes input
                parent.querySelector('.input-wrapper').style.display = 'block';
                // focus on input
                parent.querySelector('.notes-input').focus();
            },
            updateItem: debounce(function(index) {
                const item = this.order.items[index];

                if (!item.name || !item.quantity || (item.type_id === LABOR && !item.cost)) {
                    return;
                }

                const params = {
                    name: item.name,
                    description: item.description,
                    quantity: item.quantity,
                    per_unit: item.per_unit,
                    cost: parseFloat(item.cost)
                };

                api.put(`/orders/items/${item.id}`, params);
            }, 300),
            deleteItem(id, type) {
                this.requestIsGoing = true;
                api.delete(`orders/items/${id}`)
                    .then(response => response.data.data)
                    .then(data => {
                        this.notifySuccess(`${type} deleted from order`);
                        this.onDeleteItem(id);
                        this.$validator.pause();
                        this.$nextTick(() => {
                            this.$validator.reset();
                            this.$validator.resume();
                        });
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            },
            onDeleteItem(id) {
                const index = this.order.items.findIndex(i => i.id === id);

                if (index !== -1) {
                    this.order.items.splice(index, 1);
                }
            },
            updateOrder: debounce(function(type) {
                let params = {};

                switch(type) {
                    case 'name':
                        params.name = this.order.name;
                        break;
                    case 'crewInstruction':
                        params.labor_description = this.order.labor_description;
                        break;
                }

                api.put(`/orders/${this.order.id}`, params)
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    });
            }, 500),
            // validation is calling in parent component, need to return promise with result
            validateFields() {
                return this.$validator.validateAll()
                    .then(result => {
                        const emptyMaterialIndex = this.order.items.findIndex(i => {
                            return i.quantity < 0 && i.type_id === this.ITEM_TYPES.MATERIAL;
                        });
                        const emptyLaborIndex = this.order.items.findIndex(i => {
                            return i.quantity < 0 && i.type_id === this.ITEM_TYPES.LABOR;
                        });

                        if (emptyMaterialIndex !== -1) {
                            this.activeTab = this.TABS.MATERIAL;
                            this.notifyError('Material quantity cannot be less than zero!');

                            this.$nextTick(function() {
                                scrollToElement(this.$refs[`material-item-${emptyMaterialIndex}`][0]);
                                // validate again to highlight fields
                                this.$validator.validateAll();
                            });

                            return false;
                        } else if (emptyLaborIndex !== -1) {
                            this.activeTab = this.TABS.LABOR;
                            this.notifyError('Labor quantity cannot be less than zero!');

                            this.$nextTick(function() {
                                scrollToElement(this.$refs[`labor-item-${emptyLaborIndex}`][0]);
                                // validate again to highlight fields
                                this.$validator.validateAll();
                            });

                            return false;
                        }

                        if (result) {
                            this.$store.commit('orders/STORE_ORDER', this.order);
                        }

                        return result;
                    });
            },
            enableDrag() {
                let draggingItems = this.activeTab === this.TABS.MATERIAL
                    ? document.querySelectorAll('.material-item')
                    : document.querySelectorAll('.labor-item ');

                draggingItems.forEach(function(item) {
                    item.draggable = true;
                })
            },
            disableDrag() {
                let draggingItems = this.activeTab === this.TABS.MATERIAL
                    ? document.querySelectorAll('.material-item')
                    : document.querySelectorAll('.labor-item ');

                draggingItems.forEach(function(item) {
                    item.draggable = false;
                });
            },
            dragOver(item, index) {
                this.draggingOverIndex = index;
                this.draggingOverItem = item;
                // this.order.items.splice(this.draggingIndex, 1);

                if (this.draggingOverIndex !== this.draggingIndex) {
                    this.sortValue = item.sort;
                }

                // this.order.items.splice(this.draggingOverIndex, 0, this.draggingItem);
                this.draggingIndex = index;
            },
            dragStart(item, index, event) {
                this.draggingItem = item;
                this.draggingIndex = index;
            },
            dragEnd(item, index, event) {
                this.saveItemsOrder(this.draggingItem, this.sortValue, this.getOrder);
                
                this.draggingItem = '';
                this.draggingIndex = null;
                this.draggingOverIndex = null;
                this.draggingOverItem = null;
            },
            incrementOrderForItems(itemData, type) {
                let itemOrder = 0;

                if (type === MATERIAL) {
                    let materialItems = this.order.items
                        .filter((item) => {
                            return item.type_id === MATERIAL;
                        })
                        .map(el => el.sort ? el.sort : 0);
                    
                    if (materialItems && materialItems.length) {
                        itemOrder = Math.max(...materialItems) + 1;
                    }
                }

                if (type === LABOR) {
                    let laborItems = this.order.items
                        .filter((item) => {
                            return item.type_id === LABOR;
                        })
                        .map(el => el.sort ? el.sort : 0);
                    
                    if (laborItems && laborItems.length) {
                        itemOrder = Math.max(...laborItems) + 1;
                    }
                }

                this.saveItemsOrder(itemData, itemOrder, this.getOrder);
            },
            saveItemsOrder({ id }, order, callback) {
                api.put(`/orders/items/${id}`, { sort: order })
                    .then(() => {})
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        if (typeof callback === 'function') {
                            callback(false, false, true);
                        }
                    });
            },
        },
        created() {
            if (this.$route.params.order_id) {
                if (this.orderFromState.id) {
                    this.order = this.orderFromState;
                    this.setActiveTab();
                } else {
                    this.getOrder();
                }
            } else {
                this.createOrder();
            }
        }
    }
</script>
