import {
    STORE_ORDER_FORM_DATA,
    CLEAR_ORDER_STATE, STORE_ORDER,
    SET_IS_CREATE_FROM_CALENDAR
} from '../actions/orders';

export default {
    namespaced: true,
    state: {
        formData: {
            name: '',
            has_material: 1,
            has_labor: 1,
            trade_id: null,
            type: 'blank',
            measurement_id: null,
            estimate_id: null,
            template_id: null
        },
        order: {},
        isCreateFromCalendar: false
    },
    getters: {
        getOrderFormData: state => state.formData,
        getOrder: state => state.order,
        getIsCreateFromCalendar: state => state.isCreateFromCalendar
    },
    mutations: {
        [STORE_ORDER_FORM_DATA] (state, payload) {
            Object.assign(state.formData, payload);
        },
        [SET_IS_CREATE_FROM_CALENDAR](state, payload) {
            state.isCreateFromCalendar = payload;
        },
        [CLEAR_ORDER_STATE] (state) {
            Object.assign(state.formData, {
                name: '',
                has_material: 1,
                has_labor: 1,
                trade_id: null,
                type: 'blank',
                measurement_id: null,
                estimate_id: null,
                template_id: null
            });
            
            for (var key in state.order) {
                delete state.order[key];
            }
        },
        [STORE_ORDER] (state, payload) {
            Object.assign(state.order, payload);
        }
    }
}