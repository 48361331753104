import Vue from 'vue';

Vue.directive('input-style', {
    inserted(el, binding) {
        binding.value === undefined || binding.value === null || binding.value.length === 0 
            ? '' : el.parentNode.classList.add('form-input-focus');
            
        const clearInputButton = el.parentNode.querySelector('.clear-form-input');
        if (clearInputButton) {
            clearInputButton.addEventListener('click', () => {
                el.value = '';
            });
        }
    },
    update(el, binding) {
        const checkValue = () => {
            binding.value === undefined || binding.value === null || binding.value.length == 0
                ? el.parentNode.classList.remove('form-input-focus') 
                : el.parentNode.classList.add('form-input-focus');
        };
        checkValue();        
        el.addEventListener('blur', checkValue);
    }
});

Vue.directive('login-style', {
    inserted(el, binding) {
        el.addEventListener('focus', () => {
            el.parentNode.classList.add('login-input-focus');           
        });
        el.addEventListener('blur', () => {
            el.parentNode.classList.remove('login-input-focus');          
        });
    }
});

Vue.directive('scroll', {
    inserted: function (el, binding) {
        let f = function (event) {
            if (binding.value(event, el)) {
                window.removeEventListener('scroll', f);
            }
        };
        window.addEventListener('scroll', f);
    }
});

Vue.directive('resize', {
    inserted: function (el, binding) {
        let f = function (event) {
            if (binding.value(event, el)) {
                window.removeEventListener('resize', f);
            }
        };
        window.addEventListener('resize', f);
    }
});

Vue.directive('input-counter', {
    inserted(el, binding) {
        let formFooter = el.querySelector('.form-input-footer');
        let span = document.createElement('span');
        let textarea = el.querySelector('.form-input-field');
        span.className = 'form-input-count';
        span.innerHTML = `${textarea.value.length}/${binding.value}`;
        formFooter.prepend(span);

        textarea.value.length > (binding.value - (binding.value / 10)) 
            ? span.classList.add('form-input-count-danger')
            : span.classList.remove('form-input-count-danger')
    },
    update(el, binding) {
        let textarea = el.querySelector('.form-input-field');
        let span = el.querySelector('.form-input-count');
        span.classList.add('form-input-count-danger');
        span.innerHTML = `${textarea.value.length}/${binding.value}`;
        
        textarea.value.length > (binding.value - (binding.value / 10)) 
            ? span.classList.add('form-input-count-danger')
            : span.classList.remove('form-input-count-danger')
    }
});

Vue.directive('resize-textarea', {
    inserted(el, binding, vnode) {
        el.style.height = `${el.scrollHeight}px`;
        el.style.overflowY = 'hidden';

        const resizeTextarea = (event) => {
            el.style.height = 'auto';
            el.style.height = (event.target.scrollHeight) + 'px';
        };
        
        el.addEventListener('input', resizeTextarea);
    }
});

// don't remove
// Vue.directive('resize-textarea-maxHeight', {
//     inserted(el, binding, vnode) {
//         let overflow = el.scrollHeight >= binding.value ? 'scroll' : 'hidden';
//         el.setAttribute('style', `height: ${el.scrollHeight}px; max-height: ${binding.value}px; overflow-y: ${overflow};`);

//         const resizeTextarea = (event) => {
//             overflow = el.scrollHeight >= binding.value ? 'scroll' : 'hidden'
//             el.style.overflowY = overflow;
//             el.style.height = 'auto';
//             el.style.height = (event.target.scrollHeight) + 'px';
//         };
        
//         el.addEventListener('input', resizeTextarea);
//     }
// });

Vue.directive('limiter', {
    inserted(el, binding, vnode) {
        el.addEventListener('focus', function() {
            // need for inputCurrency filter
            let value = el.value.replace('$','');

            // if value == 0.00 delete all
            if (parseFloat(value) === 0) {
                el.value = '';
                el.dispatchEvent(new CustomEvent('input'));

            // if value == X.00 delete decimal
            } else if (value.split('.')[1] === '00') {
                el.value = Number(value).toFixed(0);
                el.dispatchEvent(new CustomEvent('input'));

            // if value == X.X0 delete last decimal symbol
            } else if (value.split('.')[1] !== undefined && value.split('.')[1].substr(1) === '0') {
                el.value = Number(value).toFixed(1);
                el.dispatchEvent(new CustomEvent('input'));

            // else select value on focus
            } else if (value.split(".")[1] && value.split(".")[1].length >= 2) {
                el.setAttribute('type', 'text');
                el.setSelectionRange(0, el.value.length);
            };
        });

        el.addEventListener('keypress', function(event) {
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            let value = event.target.value;

            // only allow number, one dot and minus(keyCode - 45)
            if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || value.indexOf('.') != -1) && (keyCode !== 45  || value.indexOf('-') != -1)) { // 46 is dot
                event.preventDefault();
            }

            // remove all text on input when selected all value
            if (el.selectionStart === 0 && el.selectionEnd === el.value.length && el.selectionEnd !== 0) {
                value = event.key;
                el.dispatchEvent(new CustomEvent('input'));
            }

            // restrict to limit decimal places
            if(value !== null && value.indexOf(".") > -1 && (value.split('.')[1].length > 1)) {
                event.preventDefault();
            }
        })
    }
});

// calculate sidebar top padding depending on header height
Vue.directive('sidebar-top-padding', {
    inserted(el, binding, vnode) {
        const checkScroll = () => {
            if (window.innerWidth > 1165 && window.scrollY <= 70) {
                el.style.paddingTop = `${190 - window.scrollY }px`;
            } else if (window.innerWidth > 1165 && window.scrollY > 70) {
                el.style.paddingTop = '110px';
            } else {
                return false;
            }
        }

        window.addEventListener('scroll', checkScroll);
    }
});