<template>
    <div 
        :class="['currency-input', uniqueId]"
        :style="{ width: width + unit }"
    >
        <div 
            :class="[
                'w-100 h-100 s-align-4', 
                { 'bb-blue': edit, 'bb-red': error }
            ]"
            v-show="edit"
        >
            <span class="f-13-grey">
                {{ mask }}
            </span>
            <slot name="default"></slot>
        </div>
        <div 
            class="currency-input-mask h-100 s-align-4 pointer"
            @click="startEditValue"
            v-show="!edit"
        >
            <span :class="[error ? 'f-13-red' : 'f-13-black']">
                {{ value | currency }}
            </span>
        </div>
    </div>
</template>

<script>

    /*
    * mask: symbol
    * 
    * value: value of input in slot
    * 
    * uniqueId: provides work with input
    * 
    * error: to demonstrate the error
    * 
    * width: max width of the element 
    */

    export default {
        name: 'CurrencyInput',
        props: {
            mask: {
                type: String,
                default: '$'
            },
            value: {
                type: Number,
                required: true
            },
            uniqueId: {
                type: String,
                required: true
            },
            error: {
                type: Boolean,
                default: false
            },
            width: {
                type: String,
                default: '100'
            }
        },
        data() {
            return {
                edit: false
            }
        },
        computed: {
            unit() {
                return this.width.slice(-1) == '%' ? '' : 'px';
            }
        },
        methods: {
            startEditValue() {
                this.edit = true;

                this.$nextTick(() => {                    
                    document.querySelector(`.${this.uniqueId} input`).focus();
                });
            },
            outside(event) {
                if (!this.$el.contains(event.target) && this.edit)
                    this.edit = false;
            }
        },
        mounted() {
            document.body.addEventListener('click', this.outside, false);
        },
        destroyed() {
            document.body.removeEventListener('click', this.outside, false);
        }
    }
</script>

