<template>
    <label 
        :class="['s-align-5 relative', `radio-switch-${color}`, { 'none-events': disabled }]"
        :style="{ height: `${height}px`, width: width == 'auto' ? 'auto' : `${width}px` }"
    >
        <slot name="default"></slot>
        <span :class="[
            'radio-switch-mask s-align-5', 
            padding > 0 ? `ph-${padding}` : '',
            { 'pointer' : !disabled }
        ]">
            {{ label }}
        </span>
    </label>
</template>

<script>
    /*
    * colors: white, blue
    * label - name of option
    * height, width - style of one point
    * width: can be 'auto'
    * padding: number defined by the existing grid (`ph-${padding}`)
    */

    export default {
        name: 'RadioSwitch',
        props: {
            label: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            color: {
                type: String,
                default: 'blue'
            },
            height: {
                type: [String, Number],
                default: 50
            },
            width: {
                type: [String, Number],
                default: 124
            },
            padding: {
                type: [String, Number],
                default: 0
            }
        }
    }
</script>