<template>
    <full-modal
        class="order-create-modal"
        :withSearch="true"
        :full="true"
        @close="$emit('close')" 
    >
        <div slot="title">Company library</div>
        <div slot="search">
            <input
                class="w-100 ph-2"
                type="text"
                placeholder="Search catalog..."
                v-model="needToFind"
                @input="searchItems"
            />
        </div>
        <div slot="body">
            <loader v-if="loading"/>
            <div
                v-else-if="visibleItemsLength"
                v-for="(item, index) in visibleItems"
                :key="index"
                class="order-item row-between row-baseline w-100 mb-1"
            >
                <div class="order-item-description row-baseline">
                    <div class="lg-12 column-start">
                        <div class="w-100 s-align-4 mb-4">
                            <div 
                                v-if="type == LABORS"
                                class="s-align-5 trade-label mr-8"
                            >
                                <span
                                    class="mr-1"
                                    v-html="getTradeIcon(item.trade_id)"
                                />
                                    <span class="f-10-blue f-sbold l-spacing-02">
                                        {{ getTradeName(item.trade_id) }}
                                    </span>
                            </div>
                            <div
                                v-if="checkIfItemAdded(item.id)" 
                                class="row-baseline"
                            >
                                <div class="order-item-added-icon s-align-5 mr-3">
                                    <mark-icon
                                        class="i-white"
                                        size="8"
                                    />
                                </div>
                                <span class="f-12-green">Added</span>
                            </div>
                        </div>
                        <p class="f-14-black f-sbold">
                            {{ item.name }}
                        </p>
                        <p 
                            v-if="type == LABORS"
                            class="f-12-grey w-100"
                        >
                            Cost:
                            <span class="f-12-black mr-2">
                                {{ +item.cost == 0 ? '-' : +item.cost | currency }}
                            </span>
                            Unit:
                            <span class="f-12-black">
                                {{ item.order_unit }}
                            </span>
                        </p>
                    </div>
                </div>
                <button
                    v-if="checkIfItemAdded(item.id)" 
                    class="add-button"
                    @click="deleteItem(item.id)"
                    :disabled="requestIsGoing"
                >
                    <span class="f-12-darkgrey">Remove</span>
                </button>
                <button
                    v-else 
                    class="add-button"
                    @click="addItem(item.id)"
                    :disabled="requestIsGoing"
                >
                    <span class="f-12-blue">Add</span>
                </button>
            </div>
            <not-found-message
                v-else
                custom="Nothing to display"
            />
            <div 
                v-if="isMorePages && !loading"
                class="row-center"
            >
                <load-more
                    @click.native="getItems"
                    :status="loadingMore"
                />
            </div>
        </div>
    </full-modal>
</template>

<script>
    import { MATERIAL, LABOR, COMPANY_LIBRARY_TYPES } from '@/constants';
    import { ArrowIcon, MarkIcon } from '@/icons';
    import { Loader, LoadMore } from '@/ui';
    import debounce from 'lodash/debounce';
    import { clearArray } from '@/helpers';
    import api from '@/headers.js';

    export default {
        name: 'OrderAddFromCompanyLibraryModal',
        components: {
            FullModal: () => import('@/ui/Modal/FullModal.vue'),
            NotFoundMessage: () => import('@/components/NotFoundMessage.vue'),
            Loader,
            LoadMore,
            ArrowIcon,
            MarkIcon
        },
        props: {
            typeId: {
                type: [String, Number],
                required: true
            },
            items: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                loadingMore: false,
                requestIsGoing: false,
                needToFind: '',
                materials: [],
                labors: [],
                pageNumber: 1,
                isMorePages: false,
                tradeList: [],
                LABORS: COMPANY_LIBRARY_TYPES.LABORS
            }
        },
        computed: {
            visibleItems() {
                switch (this.typeId) {
                    case MATERIAL:
                        return this.materials;
                        break;
                    case LABOR:
                        return this.labors;
                        break;
                }
            },
            visibleItemsLength() {
                return this.visibleItems.length;
            },
            type() {
                switch (this.typeId) {
                    case MATERIAL:
                        return COMPANY_LIBRARY_TYPES.ALL_MATERIALS;
                        break;
                    case LABOR:
                        return COMPANY_LIBRARY_TYPES.LABORS;
                        break;
                }
            }
        },
        methods: {
            getTradeName(id) {
                return this.tradeList.find(t => t.id === id).name;
            },
            getTradeIcon(id) {
                return this.tradeList.find(t => t.id === id).icon_code;
            },
            getItems() {
                if (this.pageNumber === 1) {
                    this.loading = true;

                    switch (this.type) {
                        case COMPANY_LIBRARY_TYPES.ALL_MATERIALS:
                            clearArray(this.materials);
                            break;
                        case COMPANY_LIBRARY_TYPES.LABORS:
                            clearArray(this.labors);
                            clearArray(this.tradeList);
                            break;
                    }
                } else {
                    this.loadingMore = true;
                }

                let params = {
                    type: this.type,
                    page: this.pageNumber,
                };

                if (this.needToFind) {
                    params.search_value = this.needToFind;
                }

                api.get('/company-library', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        this.pageNumber++;

                        switch (this.type) {
                            case COMPANY_LIBRARY_TYPES.ALL_MATERIALS:
                                this.materials.push(...data.materials.data);
                                this.isMorePages = data.materials.current_page !== data.materials.last_page;
                                break;
                            case COMPANY_LIBRARY_TYPES.LABORS:
                                this.labors.push(...data.labors.data);
                                this.tradeList = data.trades;
                                this.isMorePages = data.labors.current_page !== data.labors.last_page;
                                break;
                        }
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.loadingMore = false;
                    });
            },
            checkIfItemAdded(id) {
                return this.items.some(i => {
                    return i.entity_type == 'library'
                        && (this.typeId === MATERIAL ? i.material_id == id : i.labor_id == id)
                });
            },
            addItem(id) {
                const params = {
                    entity_id: id,
                    type_id: this.typeId
                };

                this.requestIsGoing = true;
                api.post(`/orders/${this.$route.params.order_id}/add-library-item`, params)
                    .then(response => response.data.data)
                    .then(data => {
                        this.$emit('onAddItem', data)
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            },
            deleteItem(id) {
                let itemOrderId;
                const index = this.items.findIndex(i => {
                    return i.entity_type === 'library' 
                    && (this.typeId === MATERIAL ? i.material_id == id : i.labor_id == id)
                });

                if (index !== -1) {
                    itemOrderId = this.items[index].id;
                    this.requestIsGoing = true;
                    api.delete(`orders/items/${itemOrderId}`)
                        .then(response => response.data.data)
                        .then(data => {
                            this.$emit('onDeleteItem', itemOrderId)
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.requestIsGoing = false;
                        });
                }
            },
            searchItems: debounce(function() {
                this.pageNumber = 1;
                this.getItems();
            }, 500)
        },
        created() {
            this.getItems();
        }
    }
</script>