<template>
    <svg 
	    viewBox="0 0 492.7008972167969 528.0580444335938"
        :width="size" 
        :height="size"
        class="attach-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="492.7008972167969" 
            height="528.0580444335938" 
            style="fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(17.04347801208496, 0, 0, 17.04347801208496, 14775.899444580078, -14997.9345703125)">
            <g>
                <g transform="translate(-7.000000, -7.000000)">
                    <g transform="translate(7.000000, 7.000000)">
                        <g transform="translate(9.200000, 7.666667)">
                            <path d="M-848.9,881.1c2,2,2,5.2,0,7.2l-12.4,12.4c-3.3,3.3-8.8,3.3-12.2,0c-3.3-3.3-3.3-8.8,0-12.2l16-16l2.2,2.2l-16,16c-2.1,2.1-2.1,5.7,0,7.8c2.1,2.1,5.7,2.1,7.8,0l12.4-12.4c0.8-0.8,0.8-2.1,0-2.8c-0.8-0.8-2.1-0.8-2.8,0l-14.2,14.2l-2.2-2.2l14.2-14.2C-854.1,879.1-850.9,879.1-848.9,881.1"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'AttachIcon',
        props: {
            size: {
                type: [Number, String],
                default: 10
            }
        }
    }
</script>