import api from '@/headers.js';
import {
    SET_WIDGET_REF,
    OPEN_WIDGET,
    SET_PHONE_NUMBER,
    CLEAR_PHONE_NUMBER,
    MAKE_OUTCOME_CALL,
    TAKE_INCOME_CALL,
    STORE_CALL_ID,
    STORE_LINKED_CALL,
    SET_ENTITY_INFO,
    SET_JOB_OR_LEAD_INFO,
    CLEAR_ENTITY_INFO,
    CLEAR_JOB_OR_LEAD_INFO,
    SWITCH_WIDGET,
    SUCCESS_BIND_ENTITY
} from './../actions/telephony';

export default {
    namespaced: true,
    state: {
        refWidget: null,
        widgetStatus: false,
        phoneNumber: null,
        userId: null,
        entityInfo: {},
        jobOrLeadEntityInfo: {},
        isWidgetWork: Boolean(localStorage.getItem('widgetWork')),
        successBindEntity: false
    },
    getters: {
        needStoreLinkedJobOrLeadInfo: state => {
            return !!(state.jobOrLeadEntityInfo && Object.keys(state.jobOrLeadEntityInfo).length);
        },
        widgetStatus: state => {
            return state.widgetStatus;
        },
        isWidgetWork: state => {
            return state.isWidgetWork;
        },
        successBindEntity: state => state.successBindEntity
    },
    mutations: {
        [SET_PHONE_NUMBER] (state, payload) {
            state.phoneNumber = payload;
        },
        [SET_ENTITY_INFO] (state, payload) {
            state.entityInfo = payload;
        },
        [SET_JOB_OR_LEAD_INFO] (state, payload) {
            state.jobOrLeadEntityInfo = payload.jobOrLeadEntityInfo;
            state.userId = payload.user_id;
        },
        [SET_WIDGET_REF] (state, payload) {
            state.refWidget = payload;
            state.widgetStatus = false;
        },
        [OPEN_WIDGET] (state, payload) {
            state.refWidget.classList.toggle('open');
            state.widgetStatus = !state.widgetStatus;
        },
        [CLEAR_PHONE_NUMBER] (state, payload) {
            state.phoneNumber = payload;
        },
        [CLEAR_ENTITY_INFO] (state, payload) {
            state.entityInfo = payload;
        },
        [CLEAR_JOB_OR_LEAD_INFO] (state, payload) {
            state.jobOrLeadEntityInfo = payload;
        },
        [SWITCH_WIDGET] (state, payload) {
            localStorage.setItem('widgetWork', payload);
            state.isWidgetWork = payload;
        },
        [SUCCESS_BIND_ENTITY] (state, payload) {
            state.successBindEntity = payload;
        }
    },
    actions: {
        [MAKE_OUTCOME_CALL] ({ state, commit }, callInfo) {
            if (state.refWidget) {
                commit(SET_PHONE_NUMBER, callInfo.phone_number);
                commit(SET_ENTITY_INFO, callInfo);
                // This mutation for record lead or job entity info,
                // when the call was made from lead or job to the customer.
                commit(SET_JOB_OR_LEAD_INFO, callInfo);
                delete callInfo.jobOrLeadEntityInfo;

                if (!state.refWidget.classList.contains('open')) {
                    commit(OPEN_WIDGET);
                }

                let iframe = state.refWidget.querySelector('iframe');
                iframe.dispatchEvent(
                    new CustomEvent('dial', {
                        detail: {
                            phoneNumber: `+1${callInfo.phone_number}`
                        }
                    })
                );
            }
        },
        [TAKE_INCOME_CALL] ({ state, commit }) {
            if (state.refWidget) {
                if (!state.refWidget.classList.contains('open')) {
                    commit(OPEN_WIDGET);
                }
            }
        },
        [STORE_CALL_ID] ({ state, commit }, callId) {
            if (state.phoneNumber) {
                return new Promise((resolve, reject) => {
                    let params = {
                        call_id: String(callId),
                        ...state.entityInfo
                    };
                    delete params.phone_number;

                    api.post(`/telephony/calls/bind-entity`, params)
                        .then(response => {
                            resolve(response.data.data);
                            commit(SUCCESS_BIND_ENTITY, true);
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(() => {
                            commit(CLEAR_PHONE_NUMBER, null);
                            commit(CLEAR_ENTITY_INFO, {});
                        });
                });
            }
        },
        [STORE_LINKED_CALL] ({ state, commit, getters }, callId) {
            if (getters.needStoreLinkedJobOrLeadInfo) {
                return new Promise((resolve, reject) => {
                    let params = {
                        call_id: String(callId),
                        user_id: state.userId,
                        ...state.jobOrLeadEntityInfo
                    };
    
                    api.post(`/telephony/calls/bind-entity`, params)
                        .then(response => {
                            resolve(response.data.data);
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(() => {
                            commit(CLEAR_JOB_OR_LEAD_INFO, {});
                        });
                });
            }
        }
    }
}
