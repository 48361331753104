import moment from 'moment/src/moment';

export const timeLine = {
    data() {
        return {
            timeLineStart: 7,
            timeLineEnd: 23,
            currentTime: '',
            currentTimeTop: ''
        }
    },
    computed: {
        // count timeline hours
        timeCount() { 
            return this.timeLineEnd - this.timeLineStart;
        }
    },
    methods: {
        // convert time to am/pm
        timeFilter(val) { 
            let time = val + this.timeLineStart + 1; 
            // +1 nedded beacuse count displayed at the bottom of cells
            if (time < 12) {
                return time + ':00 am';
            }

            if (time === 12) {
                return time + ':00 pm';
            }

            return time - 12 + ':00 pm';
        },
        updateCurrentTime() {
            this.currentTime = moment().format('hh:mm A'); 
            this.currentTimeTop = moment().diff(moment().startOf('day'), 'minutes') 
                - (this.timeLineStart) * 60;
        }
    },
    mounted() {
        this.updateCurrentTime();
    }
}