<template>
    <div class="user-jobs-location row-start relative">
        <div class="assigned-user-calendar">
            <div class="row-start p-20 bb-grey">
                <span class="f-16-darkgrey f-sbold mr-2">{{ date | dayName }}</span>
                <span class="f-16-grey f-sbold">{{ date | monthAndDay }}</span>
            </div>
            <perfect-scrollbar 
                class="calendar ps-grey w-100 pr-5"
                ref="events"
            >
                <loader 
                    v-if="isLoading"
                    class="s-align-5" 
                    size="small"
                />
                <div 
                    v-else-if="events.length || multiDayEvents.length"
                    class="day-view relative"
                >     
                    <div 
                        v-if="multiDayEvents.length"
                        class="multi-day-events row-start bb-grey"
                    >
                        <div class="multi-day-events-title">
                            All Day 
                        </div>
                        <div class="multi-day-events-row">
                            <template v-for="(event, index) in multiDayEvents">
                                <div 
                                    :key="index"
                                    :id="`event-${event.id}`"
                                    :class="[
                                        'event',
                                        checkIfEventPast(event.dtend) ? 'event-past' : 'event-violet'
                                    ]"
                                >
                                    <div class="row-between">
                                        <span class="f-12-darkgrey f-sbold">
                                            {{ event.summary }}
                                        </span>
                                        <span class="f-12-darkgrey">
                                            {{ event.dtstart | time }}
                                            -
                                            {{ event.dtend | time }}
                                        </span>
                                    </div>
                                    <div 
                                        v-if="event.location"
                                        class="row-start row-baseline mt-1"
                                    >
                                        <map-icon size="12" class="i-blue mr-1"/>
                                        <span class="f-12-blue">
                                            {{ event.location }}
                                        </span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="single-day-events row-start relative">
                        <div class="timeline">
                            <template v-for="(index, val) in timeCount">
                                <div class="hour" :key="index"><span>{{ timeFilter(val) }}</span></div>
                            </template>
                        </div>
                        <div class="column-static day-item relative">
                            <div class="day-hours">
                                <template v-for="(index) in timeCount">
                                    <div 
                                        class="hour-item bb-grey" 
                                        :key="index"
                                        
                                    />
                                </template>
                            </div>
                            <div>
                                <template v-for="(event, index) in events">
                                    <div
                                        :key="index" 
                                        :class="[
                                            'event',
                                            checkIfEventPast(event.dtend) ? 'event-past' : 'event-violet'
                                        ]"
                                        :id="`event-${event.id}`"
                                        :style="getEventStyles(event, date)"
                                    >
                                        <div class="row-between">
                                            <span class="f-12-darkgrey f-sbold">
                                                {{ event.summary }}
                                            </span>
                                            <span class="f-12-darkgrey">
                                                {{ event.dtstart | time }}
                                                -
                                                {{ event.dtend | time }}
                                            </span>
                                        </div>
                                        <div 
                                            v-if="event.location"
                                            class="row-start row-baseline mt-1"
                                        >
                                            <map-icon size="12" class="i-blue mr-1"/>
                                            <span class="f-12-blue">
                                                {{ event.location }}
                                            </span>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>        
                    </div>
                </div>
                <div 
                    v-else
                    class="column-center pt-50 pb-50"
                >
                    <img 
                        class="mb-20"
                        src="@/assets/images/calendar-notifications-empty.svg" 
                        width="99"
                        height="85"
                        alt="Calendar notifications not found"
                    />
                    <p class="f-13-darkgrey t-center">
                        Seems like there are no events<br> 
                        for this day
                    </p>
                </div>
            </perfect-scrollbar>
        </div>
        <div 
            :class="[
                'assigned-user-map',
                isMapFullWidth ? 'map-full-width' : ''
            ]"
        >
            <button 
                class="map-toggle s-align-5 b-grey"
                @click="isMapFullWidth = !isMapFullWidth"
            >
                <double-arrow-icon 
                    :transform="isMapFullWidth ? '0' : '180'"
                    class="i-grey"
                />
            </button>
            <google-map 
                :zoom="14"
                ref="map"
            />
        </div>
    </div>
</template>

<script>
    import { Loader } from '@/ui';
    import { DoubleArrowIcon, MapIcon } from '@/icons';
    import { GoogleMap } from '@/GoogleApi';
    import { timeLine } from '@/components/Calendar/calendar-mixins/timeLine.js';
    import { checkCellOverlappingEvents } from '@/components/Calendar/calendar-mixins/checkCellOverlappingEvents.js';
    import api from '@/headers.js';
    import { scrollToElement, clearArray } from '@/helpers.js';
    import moment from 'moment/src/moment';

    export default {
        name: 'UserJobsLocation',
        components: {
            Loader,
            DoubleArrowIcon,
            MapIcon,
            GoogleMap
        },
        props: {
            newJobAddress: {
                type: Object,
                required: true
            },
            user: {
                required: true
            },
            date: {
                required: true
            }
        },
        data() {
            return {
                isLoading: false,
                events: [],
                multiDayEvents: [],
                isMapFullWidth: false,
                markersArray: []
            }
        },
        mixins: [
            timeLine,
            checkCellOverlappingEvents
        ],
        watch: {
            newJobAddress: {
                handler: function() {
                    this.getEvents();
                },
                deep: true
            },
            user(val) {
                this.getEvents();
            },
            date(val) {
                this.getEvents();
            }
        },
        methods: {
            getEvents() {
                clearArray(this.events);
                clearArray(this.multiDayEvents);

                const params = {
                    view: 'day',
                    dtstart: this.date
                };

                this.isLoading = true;
                this.$emit('switchDateInput', true);
                api.get(`/users/${this.user.email}/calendarlist`, { params })
                    .then(response => response.data.data)
                    .then(data => {
                        this.events = data[0].events;
                        this.isLoading = false;
                        this.$emit('switchDateInput', false);

                        this.checkCellOverlappingEvents();
                        this.$nextTick(() => {
                            if (this.multiDayEvents.length || !this.events.length) {
                                this.$refs.events.$el.scrollTop = 0;
                            } else {
                                const firstEventStartTime = this.events.reduce((sum, current) => {
                                    if (!sum || moment(current.dtstart).isBefore(moment(sum))) {
                                        return current.dtstart;
                                    }
                                    return sum;
                                }, 0);
                                const firstEventStartOffset = moment(firstEventStartTime)
                                    .diff(moment(firstEventStartTime)
                                    .clone()
                                    .startOf('day'), 'minutes');
                                const scrollTop = firstEventStartOffset - this.timeLineStart * 60 - 10;

                                this.$refs.events.$el.scrollTop = scrollTop > 0 ? scrollTop : 0;
                            }
                            
                            this.addMarkersToMap();
                        });
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        this.$emit('switchDateInput', false);
                        this.notifyRequestErrors(error);
                    })
            },
            sortEvents() {
                let singleDayEvents = [];
                let multiDayEvents = this.multiDayEvents.length 
                    ? this.multiDayEvents
                    : [];

                this.events.forEach(event => {
                    if (moment(event.dtstart).isSame(moment(event.dtend), 'day')) {
                        singleDayEvents.push(event);
                    } else {
                        multiDayEvents.push(event);
                    }
                });

                // sort by startDate
                multiDayEvents.sort((a, b) =>  {
                    return moment(a.dtstart).format('x') - moment(b.dtstart).format('x');
                });

                this.events = singleDayEvents;
                this.multiDayEvents = multiDayEvents;
            },
            checkIfEventPast(dtend) {
                return moment(dtend).isBefore(moment());
            },
            addMarkersToMap() {   
                // delete all markers on map  
                this.markersArray.forEach((marker) => {
                    marker.setMap(null);
                });
                clearArray(this.markersArray);

                // create icons
                const existingJobIcon = {
                    url: require('@/assets/images/job-icon.svg'),
                    anchor: new google.maps.Point(25,50),
                    scaledSize: new google.maps.Size(35,35)
                };
                const newJobIcon = {
                    url: require('@/assets/images/current-location-icon.svg'),
                    anchor: new google.maps.Point(25,50),
                    scaledSize: new google.maps.Size(100,100)
                };

                // create markers for single-day events which have lat and lng
                let features = [];
                this.events.forEach((item, index) => {
                    if (item.lat && item.lng) {
                        features.push({
                            position: new google.maps.LatLng(item.lat, item.lng)
                        });
                    }
                });

                // create markers for multi-day events which have lat and lng
                this.multiDayEvents.forEach((item, index) => {
                    if (item.lat && item.lng) {
                        features.push({
                            position: new google.maps.LatLng(item.lat, item.lng)
                        });
                    }
                });

                // add markers to map
                features.forEach((feature) => {
                    this.markersArray.push(new google.maps.Marker({
                        position: feature.position,
                        icon: existingJobIcon,
                        map: this.$refs.map.map
                    }));
                });

                // create marker for new job location
                if (this.newJobAddress.lat && this.newJobAddress.lng) {
                    this.markersArray.push(new google.maps.Marker({
                        position: new google.maps.LatLng(this.newJobAddress.lat, this.newJobAddress.lng),
                        icon: newJobIcon,
                        map: this.$refs.map.map
                    }));
                }

                // fit map bounds by markers
                if ((features.length === 1 && !this.newJobAddress.lat && !this.newJobAddress.lng)
                    || (!features.length && this.newJobAddress.lat && this.newJobAddress.lng)) {
                    this.$refs.map.map.setCenter(this.markersArray[0].getPosition());
                    this.$refs.map.map.setZoom(14);
                } else if (features.length) {
                    let bounds = new google.maps.LatLngBounds();
                    this.markersArray.forEach((marker) => {
                        if (marker.getVisible()) {
                            bounds.extend(marker.getPosition());
                        }
                    });
                    this.$refs.map.map.fitBounds(bounds);
                } else if (!features.length && !this.newJobAddress.lat && !this.newJobAddress.lng) {
                     // USA center coordinates
                    this.$refs.map.map.setCenter(new google.maps.LatLng(40.268488,-101.4134227,17));
                    this.$refs.map.map.setZoom(4);
                }
            }
        },
        created() {
            this.getEvents();
            this.$nextTick(function() {
                scrollToElement(document.querySelector('.user-jobs-location'));
            });
        }
    }
</script>