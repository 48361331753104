<template>
    <svg
        viewBox="0 0 40 40"
        style="enable-background:new 0 0 336 235.2;"
        :width="size"
        :height="size"
        class="information-panel"
    >
        <g>
            <path
                fill="#FFCA28"
                d="M0,20c0,11,9,20,20,20s20-8.9,20-20C40,9,31.1,0,20,0C9.1,0,0,8.9,0,20z"
            />
            <path
                fill="#FFFFFF"
                d="M20,14.9c-0.8,0-1.3-0.5-1.3-1.3s0.5-1.3,1.3-1.3s1.3,0.5,1.3,1.3S20.8,14.9,20,14.9z"
            />
            <path
                fill="#FFFFFF"
                d="M20,27.6c-0.7,0-1.3-0.6-1.3-1.4v-7.1c0-0.8,0.6-1.4,1.3-1.4s1.3,0.6,1.3,1.4v7.1C21.3,26.9,20.7,27.6,20,27.6z"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'InformationPanel',
        props: {
            size: {
                type: [Number, String],
                default: 30
            }
        }
    }
</script>
