<template>
    <full-modal 
        @close="$emit('close')"
        class="in-dev"
    >
        <div slot="title">Edit overall progress</div>
        <div slot="body">
            <p class="f-18-grey f-sbold pb-4">Progress:</p>
            <div
                v-for="(status, index) in statuses" 
                :key="index"
                class="blue-box pv-4 ph-7 row-between mb-1"
            >
                <div class="s-align-4 lg-6 sm-12 mb-sm-1">
                    <span class="f-14-black">
                        {{ status.name }}
                    </span>
                </div>
                <div class="s-align-6 lg-6 sm-12">
                    <form-datepicker 
                        v-model="status.pivot.date"
                        keyDatePicker="status_pivot_date_picker"
                        :isFullWidth="true"
                    />
                </div>
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                :disabled="requestIsGoing"
                @click="updateStatuses"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, RadioButton, FormDatepicker } from '@/ui';
    import cloneDeep from 'lodash/cloneDeep';
    import api from '@/headers.js';

    export default {
        name: 'OrderEditOverallProgressModal',
        components: {
            FullModal,
            RadioButton,
            FormDatepicker
        },
        props: {
            statusesProps: {
                type: Array,
                required: true
            },
            orderId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                requestIsGoing: false,
                statuses: []
            }
        },
        computed: {
            visibleStatuses() {
                return this.statuses.filter(i => i.is_change);
            }
        },
        methods: {
            updateStatuses() {
                const params = {
                    statuses: this.statuses.map(i => {
                        return { 
                            id: i.id,
                            date: i.pivot.date
                        }
                    }).filter(i => i.date)
                };

                this.requestIsGoing = true;
                api.put(`/orders/${this.orderId}/update-statuses`, params)
                    .then(response => response.data.data)
                    .then(data => {
                        if (data) {
                            this.$emit('onOverallProgressUpdate', params);
                            this.notifySuccess('Overall progress updated!');
                        }
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                    });
            }
        },
        created() {
            this.statuses.push(...cloneDeep(this.statusesProps.filter(i => i.is_change)));
        }
    }
</script>