<template>
    <div class="row-between">
        <loader
            v-if="loading"
        />
        <ContactCallCard
            v-for="call in calls"
            :call="call"
            :key="call.id"
            :contact-id="customer_id"
        />
        <div
            v-if="!calls.length && !loading"
            class="w-100 s-align-5 column-center pt-20"
        >
            <no-messages size="100"/>
            <div class="f-16-darkgrey f-sbold">This has no calls.</div>
        </div>
<!--        needed after releasing detailed information-->
        <!--        <div class="lg-4 bg-white column-static">-->
<!--            <ClientCallCard-->
<!--                v-for="call in callsData"-->
<!--                :call-data="call"-->
<!--                :key="call.id"-->
<!--                :is-active="call.call_id === callInfoId"-->
<!--                @click="getCallInfo(call.call_id)"-->
<!--            />-->
<!--        </div>-->
<!--        <div class="lg-8">-->
<!--            <ClientCallInfo-->
<!--                v-else-->
<!--                :callInfo="callInfo"-->
<!--            />-->
<!--        </div>-->
    </div>
</template>

<script>
    import {Loader} from '@/ui';
    import ContactCallCard from "../../views/Contacts/ContactCallCard";
    import api from '@/headers.js';
    import {ENTITY_TYPES} from '@/constants';
    import {NoMessages} from '@/icons';
    
    export default {
        name: 'CallsWithClients',
        components: {
            ContactCallCard,
            Loader,
            NoMessages
        },
        data() {
            return {
                loading: false,
                customer_id: null,
                calls: []
            }
        },
        methods: {
            getCalls() {
                const params = {
                    entity_id: this.customer_id,
                    entity_type: ENTITY_TYPES.COMMERCIAL_CUSTOMER
                }
                this.loading = true;
                api.get('/telephony/calls/entity', {params}).then(res => {
                    this.calls = res.data.data;
                }).catch(error => {
                    this.notifyRequestErrors(error);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        created() {
            this.customer_id = this.$route.params.customer_id;
            this.getCalls();
        }
    }
</script>
