<template>
    <div class="pt-25 pb-20 w-100 pr-4">
        <div :class="['s-align-4', { 'in-dev': inDev }]">
            <button 
                class="transparent-button mr-2"
                @click="edit = true"
            >
                <edit-icon class="i-grey"/>
            </button>
            <p class="f-16-grey f-sbold">{{ title }}</p>
        </div>
        <div class="w-100 ph-20">
            <p 
                class="f-13-black preserve-whitespaces"
                v-if="!edit"
            >
                {{ text }}
            </p>
            <div 
                class="w-100 relative"
                v-else
            >
                <form-input-textarea 
                    :onSubmit="save"
                    v-input-counter="1000"
                    :disabled="disabled"
                >
                    <textarea 
                        class="form-input-field"
                        placeholder="Type here..."
                        maxlength="1000"
                        v-model="text"
                        v-resize-textarea
                    />
                </form-input-textarea>
            </div>
        </div>
    </div>
</template>

<script>
    import { EditIcon } from '@/icons';
    import { FormInputTextarea } from '@/ui';

    export default {
        name: 'EstimatePreviewNotesForm',
        components: {
            EditIcon,
            FormInputTextarea
        },
        props: {
            title: {
                type: String,
                default: 'Notes'
            },
            content: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            inDev: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                edit: false,
                text: ''
            }
        },
        methods: {
            save() {
                this.edit = false;
                this.$emit('setNote', this.text);
            }
        },
        created() {
            this.text = this.content;
        }
    }
</script>