<template>
    <div 
        class="simple-diagram"
        :style="{ height: `${size}px`, width: `${size}px` }"
    >
        <svg viewBox="0 0 42 42">
            <circle 
                class="diagram-hole" 
                cx="21" 
                cy="21" 
                r="15.91549430918954" 
            />
            <circle 
                class="diagram-fond" 
                cx="21" 
                cy="21" 
                r="15.91549430918954"
                :stroke="segment >= 0 ? GREY : RED"
            />
            <circle 
                ref="circle"
                class="diagram-segment" 
                cx="21" 
                cy="21" 
                r="15.91549430918954" 
                :stroke="YELLOW"
                v-if="segment >= 0"      
            />
            <circle 
                ref="circle_reverse"
                class="diagram-segment-reverse" 
                cx="21" 
                cy="21" 
                r="15.91549430918954" 
                :stroke="GREY"
                v-else
            />
            <g class="diagram-content">
                <text  
                    x="50%" 
                    y="50%" 
                    class="diagram-data"
                >
                    {{ Math.round(segment * 100) / 100 }}%
                </text>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'SimpleDiagram',
        props: {
            size: {
                type: Number,
                default: 0
            },
            segment: {
                type: Number,
                default: 0
            }
        },
        methods: {
            animate() {
                if (this.$refs.circle) {
                    let roundRadius = this.$refs.circle.getAttribute('r');
                    let roundCircum = 2 * roundRadius * Math.PI;
                    let roundDraw = this.segment * roundCircum / 100;
    
                    this.$refs.circle.style.strokeDasharray = roundDraw  + ' 999';
                } else if (this.$refs.circle_reverse) {
                    let rest = 100 - Math.abs(this.segment)
                    this.$refs.circle_reverse.style.strokeDasharray = `${rest} ${Math.abs(this.segment)}`;
                }
            }
        },
        created() {
            this.GREY = '#dfe4ea';
            this.RED = '#f53847';
            this.YELLOW = '#ffc829';
        },
        mounted() {
            this.animate();
        },
        updated() {
            this.animate();
        }
    }
</script>
