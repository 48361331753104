<template>
    <div 
        :class="[
            'form-date-time-input row-baseline',
            `form-date-time-input-${size}`,
            `popover-direction-${popoverDirection}`
        ]"
        :style="{ width: isFullWidth ? '100%' : 'auto' }"
    >
        <div class="date-input row-around row-baseline h-100">
            <calendar-icon size="16"/>
            <v-date-picker
                class="date-picker-from column-center pl-3 h-100 pointer"
                :value="date.selectedDate"
                @dayclick="changeDate"
                show-caps
                :popover-direction="popoverDirection"
                :min-date="minDate"
                :max-date="maxDate"
                :tintColor="tintColor"
                :attributes="attributes"
                :disabledAttribute="disabledAttribute"
                :themeStyles="themeStyles"
            >
                <span 
                    v-if="date.selectedDate"
                    class="datepicker-value"
                >
                    {{ date.selectedDate | date_ignore_tz }}
                </span>
                <span class="datepicker-placeholder ellipsis inline-block max-w-100">
                    {{ placeholder }}
                </span>
            </v-date-picker>
        </div>
        <div class="time-input row-around row-baseline relative">
            <clock-icon/>
            <div class="time-input-container column-start">
                <div 
                    v-if="date.selectedDate"
                    class="time-input-value"
                >
                    <input 
                        type="text"
                        v-mask="'##:##'"
                        :value="date.selectedTime"
                        @change="changeTime($event)"
                        @focus="showTimeSelect"
                        @blur="hideTimeSelect"
                    >
                    <span 
                        class="f-14-black division pointer"
                        @click="toggleTimePeriod"
                    >
                        {{ timePeriod }}
                    </span>
                </div>
                <span class="time-input-placeholder">
                    {{ timePlaceholder || 'Time' }}
                </span>
            </div>
            <perfect-scrollbar  
                v-if="isTimeSelectShow"
                class="time-input-select ps-grey w-100 pv-1"
            >
                <button
                    v-for="(time, index) in timeList"
                    :key="index"
                    :class="[
                        'w-100 f-13-black t-left',
                        date.selectedTime === time ? 'selected' : ''
                    ]"
                    @click="setTime(time)"
                >
                    {{ time }}
                </button>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script>
    /**
    * Component accept time in UTC time zone and display in local tz
    */

    import moment from 'moment/src/moment';
    import { CalendarIcon, ClockIcon } from '@/icons';
    import { mask } from 'vue-the-mask';
    import timeList from '@/mixins/timeList';
    import calendarConfig from '@/mixins/calendarConfig';

    export default {
        name: 'FormDateTimeInput',
        components: {
            CalendarIcon, 
            ClockIcon
        },
        props: {
            value: {
                required: true
            },
            isFullWidth: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: 'Select date'
            },
            timePlaceholder: {
                type: String,
                required: false
            },
            popoverDirection: {
                type: String,
                default: 'bottom'
            },
            minDate: {
                type: Date,
                default: null
            },
            maxDate: {
                type: Date,
                default: null
            },
            size: {
                type: String,
                default: 'medium'
            }
        },
        mixins: [
            timeList,
            calendarConfig
        ],
        data() {
            return {
                date: {
                    selectedDate: null,
                    selectedTime: null,
                },
                isTimeSelectShow: false,
                localValue: null,
                timePeriod: 'PM'
            }
        },
        directives: {
            mask
        },
        watch: {
            date: {
                deep: true,
                handler(newVal){
                    const date = moment(newVal.selectedDate).format('YYYY-MM-DD');
                    const time = moment(newVal.selectedTime, 'HH:mm A').format('HH:mm:ss');
                    const dateTime = moment(`${date} ${time}`).utc().format('YYYY-MM-DD HH:mm:ss');
                    this.$emit('input', dateTime);
                }
            },
            timePeriod(newVal, oldVal){
                this.date.selectedTime = this.date.selectedTime.replace(oldVal, newVal);
            },
        },
        methods: {
            // changed date but leave time
            changeDate(event) {
                const firstTime = this.timeList[0];
                this.date.selectedDate = event.date;
                if (!this.date.selectedTime){
                    this.date.selectedTime = firstTime;
                    this.timePeriod = firstTime.slice(-2);
                }
                this.$el.querySelector('.popover-container').blur();
            },
            // changed time but leave date
            changeTime(event) {
                const [hour, minute] = event.target.value.split(':').map(el => el);
                this.date.selectedTime =  `${+hour > 12 ? '12' : hour}:${+minute > 59 ? '59' : minute}`;
            },
            showTimeSelect() {
                this.isTimeSelectShow = true;
                const itemIdx = this.timeList.findIndex(item => item === this.date.selectedTime);
                this.$nextTick(function() {
                    // scroll to closest element in select, 38 - height of one element in px, 76 offset to center position 
                    document.querySelector('.time-input-select').scrollTop = itemIdx * 38 - 76;
                });
            },
            hideTimeSelect() {
                setTimeout(function() {
                    this.isTimeSelectShow = false;
                }.bind(this), 250);
            },
            setTime(time) {
                this.date.selectedTime = time;
                this.timePeriod = time.slice(-2);
            },
            getTimeFormat(value) {
                return moment(value, 'HH:mm');
            },
            // AM/PM toggle
            toggleTimePeriod(event) {
                this.timePeriod = event.target.innerText === 'PM' ? 'AM' : 'PM';
            }
        },
        created() {
            if (this.value) {
                const stillUtc = moment.utc(this.value).toDate();
                const local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
                const time = moment(local).format('hh:mm A');
                this.date.selectedDate = new Date(local);
                this.date.selectedTime = time;
                this.timePeriod = time.slice(-2);
            }
        }
    }
</script>

