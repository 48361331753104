<template>
    <svg 
        :height="height" 
        :width="width"
        viewBox="0 0 465.3769226074219 602.5400390625"
        class="document-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="465.3769226074219" 
            height="602.5400390625" 
            style="fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(9.15711784362793, 0, 0, 9.15711784362793, 4010.301315307617, -2191.006103515625)">
            <path 
                class="st0" 
                d="M-389.6,255.4l-15.1-14.2c-1.2-1.2-2.8-1.7-4.4-1.7h-22.7c-3.2,0-5.8,2.6-5.8,5.8v53.5c0,3.2,2.6,5.8,5.8,5.8h38.4c3.2,0,5.8-2.6,5.8-5.8V260C-387.6,258.3-388.3,256.6-389.6,255.4z"
            />
            <path 
                class="st0" 
                d="M-407.9,259.8h20.2c-0.1-1.7-0.7-3.3-2-4.4l-15.1-14.2c-1.1-1.1-2.7-1.7-4.3-1.7v19.2C-409,259.3-408.5,259.8-407.9,259.8z"
            />
            <path 
                class="st1" 
                d="M-407.9,259.8h20.2c-0.1-1.7-0.7-3.3-2-4.4l-15.1-14.2c-1.1-1.1-2.7-1.7-4.3-1.7v19.2C-409,259.3-408.5,259.8-407.9,259.8z" 
                style="opacity: 0.3;"
            />
            <path 
                class="st2" 
                d="M-423,280.3h21.7c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4H-423c-0.8,0-1.4,0.6-1.4,1.4C-424.4,279.7-423.7,280.3-423,280.3z"
            />
            <path 
                class="st2" 
                d="M-423,272.5h10.2c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4H-423c-0.8,0-1.4,0.6-1.4,1.4C-424.4,271.9-423.7,272.5-423,272.5z"
            />
            <path 
                class="st2" 
                d="M-423,288.1h21.7c0.8,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4H-423c-0.8,0-1.4,0.6-1.4,1.4S-423.7,288.1-423,288.1z"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'DocumentIcon',
        props: {
            width: {
                type: [Number, String],
                default: 65
            },
            height: {
                type: [Number, String],
                default: 50
            }
        }
    }
</script>