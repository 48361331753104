<template>
    <full-modal 
        @close="$emit('close')"
        class="import-conflict-settings-modal"
    >
        <div slot="title">Settings</div>
        <div slot="body">
            <div class="column-static w-100">
                <div 
                    v-for="(field,index) in defaultFields"
                    :key="`field-${index}`"
                    :class="[
                        'row-between',
                        { 'pb-20 bb-grey' : index == 0 && defaultFields.length > 1 },
                        { 'pv-20 bb-grey' : index > 0 && index != defaultFields.length -1 },
                        { 'pt-20' : index == defaultFields.length -1}
                    ]"
                >
                    <div class="column-start">
                        <span class="f-13-black f-sbold">
                            {{field.label}}
                        </span>
                        <span class="f-13-grey">
                            Select default values
                        </span>
                    </div>
                    <div class="w-60">
                        <form-input>
                            <input 
                                type="text" 
                                :name="`field-${index}`"
                                class="form-input-field"
                                placeholder="Enter default value"
                                v-model="field.default"
                            />
                        </form-input>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                @click="saveFields"
                ref="create_button"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, FormInput} from '@/ui';
    import cloneDeep from 'lodash/cloneDeep';

    export default {
        name: 'ImportConflictSettingsModal',
        components: {
            FullModal,
            FormInput
        },
        props: {
            columnsOption: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                defaultFields: []
            }
        },
        methods: {
            saveFields() {
                this.$emit('setDefaultValues',this.defaultFields);
                this.$emit('close');
            }
        },
        created() {
            this.defaultFields = cloneDeep(this.columnsOption);
        }
    }
</script>
