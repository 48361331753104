<template>
    <full-modal
        @close="$emit('close')"
        class="pick-list-modal"
    >
        <div slot="title">
            {{ `${editingField.label} properties` }}
        </div>
        <div slot="body">
            <p class="f-14-darkgrey f-sbold mb-3">Add and prioritize options</p>
            <div 
                v-for="(item, index) in activeOptions"   
                :key="index"
            >
                <div
                    class="pick-list-property row-start row-baseline mb-1"
                    @dragover="propertiesDragOver(item, index)" 
                    @dragend="propertiesDragEnd(item, index)"
                    @dragstart="propertiesDragStart(item, index, $event)"
                    :class="[
                        {
                        selected: (propertiesDraggingIndex === index),
                        over: (index === propertiesOverIndex && index !== propertiesDraggingIndex)
                        },
                        { 'pick-list-referral' : isHasChildren(item) }
                    ]"
                    :draggable="!isHasChildren(item)"
                >
                    <line-icon
                        size="13"
                        class="i-grey"
                    />
                    <div :class="[
                        'w-100',
                        mScreen ? 'pick-list-input-mobile' :
                        selectData.type == leadType ? 'mr-25' :'pick-list-input-desktop row-start',
                        { 'form-input-referral' : isHasChildren(item) }
                    ]">
                        <form-input
                            :class="[{
                                'form-input-danger' :
                                errors.has('pick_list_property_' + index)
                            }]"
                        >
                            <input
                                class="form-input-field"
                                type="text"
                                :id="'pick_list_property_' + index"
                                :name="'pick_list_property_' + index"
                                placeholder="Enter value"
                                v-input-style="activeOptions[index].name"
                                v-model="activeOptions[index].name"
                                maxlength="31"
                                v-validate="'required|max:30'"
                                :disabled="isHasChildren(item)"
                            >
                        </form-input>
                        <form-colorpicker
                            v-if="selectData.type == prioritiesType"
                            :class="[{
                                'form-input-textarea-danger' :
                                errors.has('pick_list_property_color' + index)
                            }]"
                            :color="activeOptions[index].color_code"
                            v-model="activeOptions[index].color_code"
                            :name="'pick_list_property_color' + index"
                            v-validate="'required|min:4'"
                        />
                        <div v-if="selectData.type == leadType">
                            <button
                                @click="toggleMilestoneSet(activeOptions[index].id)"
                                class="transparent-button f-13-blue mt-2"
                            >
                                <arrow-icon
                                    :transform="!isVisibleMilestoneSelect(activeOptions[index].id) ? 90 : 270"
                                    size="8"
                                    class="i-blue mr-2"
                                />
                                Select a milestone set
                            </button>
                            <multiselect
                                v-if="
                                    milestoneSet.length &&
                                    isVisibleMilestoneSelect(activeOptions[index].id)
                                "
                                :options="milestoneSet.map(item => item.id)"
                                :custom-label="opt => milestoneSet.find(x => x.id == opt).name"
                                :max-height="200"
                                data-name="milestone_set"
                                v-model="activeOptions[index].group_id"
                                class="mt-1"
                            />
                        </div>
                    </div>
                    <toggle-switch
                        v-if="isHasChildren(item)"
                        class="pointer"
                    >
                        <input
                            type="checkbox"
                            v-model="item.is_active"
                        />
                    </toggle-switch>
                    <button
                        v-if="activeOptions.length > 1 && !isHasChildren(item) && !activeOptions[index].provider_id"
                        class="pick-list-delete transparent-button"
                        @click="deleteOption(item,index)"
                    >
                        <trash-icon
                            :width="17"
                            :height="17"
                            class="i-grey"
                        />
                    </button>
                </div>
                <div 
                    class="del-priority-property column-center s-align-5 mb-1" 
                    v-if="selectData.type == prioritiesType && delPriorityPrompt === item.id"
                >
                    <div class="del-priority-header row-center">
                        <div class="warning-icon-wrapper s-align-5">
                            <warning-icon class="i-white"/>
                        </div>
                        <div class="del-priority-title ml-5 column-start">
                            <span class="f-14-black f-sbold">
                                Priority 
                                <span class="f-14-darkgrey f-sbold f-italic">
                                    {{activeOptions[index].name}}
                                </span>
                                is already being used in leads or jobs
                            </span>
                            <span class="f-12-grey f-sbold">
                                Change priority of leads or jobs to:
                            </span>
                        </div>
                    </div>
                    <multiselect
                        :options="delPriorityOptions(activeOptions[index].id)"
                        track-by="id"
                        label="name"
                        :showLabels="false"
                        :max-height="200"
                        placeholder="Priorities"
                        :name="'priority_item'+index"
                        data-name="priority"
                        v-model="delPriorityItem"
                        v-validate="'required'"
                        class="mt-8"
                    />
                    <div class="del-priority-buttons row-end mt-8">
                        <button 
                            class="edit-button mr-4" 
                            @click="delPriorityPrompt = null"
                        >
                            Cancel
                        </button>
                        <button 
                            class="red-button"
                            @click="deletePriority(activeOptions[index].id, delPriorityItem.id, index)"
                        >
                            Save and delete
                        </button>
                    </div>
                </div>       
            </div>
            <button 
                class="custom-fields-new-item s-align-5 w-100"
                @click="addNewOption"
            >
                <div class="round s-align-5 mr-5">
                    <add-icon size="9"/>
                </div>
                <span class="f-13-blue f-sbold">New option</span>
            </button>
        </div>
        <div slot="footer">
            <button 
                class="add-button mb-2"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button mb-2"
                @click="updateField"
                :disabled="requestIsGoing"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import api from '@/headers.js';
    import Multiselect from 'vue-multiselect';
    import { LineIcon, TrashIcon, WarningIcon, AddIcon, ArrowIcon, PlusIcon, EditIcon } from '@/icons';
    import { FullModal, FormInput, FormColorpicker, ToggleSwitch } from '@/ui';
    import { NOT_ALLOWED_CHARACTERS } from '@/constants';
    import { validationErrorNotification } from '@/helpers';
    import { cloneDeep } from "lodash/lang";

    const PICK_LIST = 'pick_list';
    const PRIORITIES = 'lead_priorities';
    const LEAD_TYPE = 'lead_types';
    const DISABLED_LEAD_TYPE = {
        FULL_REPLACEMENT:  'Full Replacement',
        REPAIRS: 'Repairs',
        INSPECTION: 'Inspection'
    };
    const LEAD_SOURCES = 'lead_sources';

    export default {
        name: 'WorkItemsProperties',
        components: {
            EditIcon,
            LineIcon, 
            TrashIcon,
            ArrowIcon,
            WarningIcon, 
            FullModal,
            FormInput,
            FormColorpicker,
            Multiselect,
            AddIcon,
            ToggleSwitch,
            PlusIcon,
        },
        props: {
            editingField: {
                type: Object,
                required: true
            },
            entityType: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                propertiesOverIndex: null,
                propertiesDragging: '',
                propertiesDraggingIndex: null,
                prioritiesType: PRIORITIES,
                leadType: LEAD_TYPE,
                selectData: {
                    // label: '',
                    type: '',
                    selectOptions: [],
                },
                requestIsGoing: false,
                delPriorityPrompt: null,
                delPriorityItem: null,
                milestoneSet: [],
                visibleMilestoneSelect: [],
                titleRegex: NOT_ALLOWED_CHARACTERS
            }
        },
        created() {
            if (this.editingField) {
                // this.selectData.label = this.editingField.label;
                this.selectData.type = this.editingField.type,
                this.selectData.selectOptions = cloneDeep(this.editingField.data);
            }
            if (this.selectData.type == this.leadType) {
                this.getMilestoneSet();
            }
        },
        computed:{
            activeOptions() {
                return this.selectData.selectOptions
                    .filter((el) => el.has_children === true || el.is_active != 0);
            }
        },
        methods: {
            toggleMilestoneSet(id) {
                if (this.isVisibleMilestoneSelect(id)) {
                    let index = this.visibleMilestoneSelect.findIndex(el => el == id);
                    this.visibleMilestoneSelect.splice(index,1);
                } else {
                    this.visibleMilestoneSelect.push(id);
                }
            },
            isVisibleMilestoneSelect(id) {
                return this.visibleMilestoneSelect.some(item => item == id);
            },
            getMilestoneSet() {
                api.get('/milestone-sets?with=milestones')
                    .then((response) => {
                        this.milestoneSet = response.data.data;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            propertiesDragStart(item, index, event) {                
                this.$store.commit('customFields/SET_DRAG_SOURCE', PICK_LIST);
                this.propertiesDragging = item;
                this.propertiesDraggingIndex = index;
            },
            propertiesDragOver(item, index) {
                this.propertiesOverIndex = index;

                if (this.propertiesOverIndex !== this.propertiesDraggingIndex) {
                    this.selectData.selectOptions.splice(this.propertiesDraggingIndex, 1)
                    this.selectData.selectOptions.splice(this.propertiesOverIndex, 0, this.propertiesDragging);

                    this.propertiesDraggingIndex = index;
                }     
            },
            deleteOption(item, index) {
                if (this.selectData.type == this.prioritiesType) {
                    if (item.id) {
                        this.delPriorityPrompt = item.id;
                    } else {
                        this.selectData.selectOptions.splice(index, 1);
                    }
                } else {
                    if (item.id) {
                        item.is_active = 0;
                    } else {
                        this.selectData.selectOptions.splice(index, 1)
                    }
                }
            },
            propertiesDragEnd(item, index) {
                this.$store.commit('customFields/SET_DRAG_SOURCE', '');
                this.propertiesDragging = '';
                this.propertiesDraggingIndex = null;
                this.propertiesOverIndex = null;
            },
            delPriorityOptions(id) {
                return this.selectData.selectOptions.filter((el,k) => el.id != id)
            },
            deletePriority(oldId, newId, index) {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('Please fill out all fields!');

                        return;
                    };

                    const params = {
                        "type": this.selectData.type,
                        "old_id": oldId,
                        "new_id": newId
                    }
                    
                    api.delete('/lead-source-data/delete',{params})
                        .then((response) => {
                            this.notifySuccess(`Priority has been deleted`);
                            this.selectData.selectOptions.splice(index, 1);
                            this.$nextTick(() => {
                                this.updateField();
                            });
                        })
                        .catch(error => {
                            this.notifyError(`Unfortunately could not delete this priority`);
                        })
                })    
            },
            updateField() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        let errorRequired = false;
                        let errorRegex = false;

                        this.errors.items.forEach(error => {
                            if (error.rule === 'required') {
                                errorRequired = true;
                            }
                            if (error.rule === 'regex') {
                                errorRegex = true;
                            }
                        });

                        if (errorRequired) {
                            this.notifyError('All fields are required.');
                        }
                        if (errorRegex) {
                            this.notifyError('Special characters are not accepted.');
                        }

                        return;
                    }

                    this.requestIsGoing = true;

                    if (this.editingField.type === LEAD_SOURCES) {
                        this.selectData.selectOptions.forEach((el,i) => el.order = i)
                    } else {
                        this.editingField.data.forEach((el,i) => el.order = i)
                    }

                    let sendFields = {
                        entityType: this.entityType,
                        type: this.selectData.type
                    }

                    if (this.editingField.type === LEAD_SOURCES) {
                        sendFields.items = this.selectData.selectOptions.map(i => {
                            return  {
                                'id': i.id,
                                'name': i.name,
                                'order': i.order,
                                'children': i.children,
                                'is_active': i.is_active
                            };
                        });
                    } else {
                        sendFields.items = this.selectData.selectOptions;
                    }

                    api.post('/lead-source-data/store', sendFields)
                        .then(response => response.data.data)
                        .then(data => {
                            this.notifySuccess('All fields has been saved.');
                            this.$emit('onUpdate', data, true);
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.requestIsGoing = false;
                        });
                })
            },
            isHasChildren(item) {
                if (this.selectData.type === LEAD_SOURCES) {
                    return item.has_children === true;
                }

                return false;
            },
            addNewOption() {
                this.selectData.selectOptions.push({
                    'name' : '',
                    'order': this.selectData.selectOptions.length
                });
            }
        }
    }
</script>

