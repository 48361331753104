<template>
    <div class="modal s-align-5">
        <div class="add-new-section-modal cm-fond-light-grey w-95 column-static">
            <div class="modal-content w-100 ps-grey relative">
                <div
                    class="w-100"
                    v-if="loading"
                >
                    <loader/>
                </div>
                <div
                    :class="[
                        'row-between pv-30 flex-no-wrap',
                        mScreen ? 'ph-20' : 'ph-30'
                    ]"
                    v-if="!loading"
                >
                    <p :class="['f-24-asphalt f-sbold', { 'w-100': mScreen }]">
                        Create new section
                    </p>
                    <button
                        class="transparent-button mt-sm-1"
                        @click="$emit('close')"
                    >
                        <close-icon
                            class="i-grey"
                            size="15"
                        />
                    </button>
                </div>
                <div :class="mScreen ? 'ph-20' : 'ph-30'">
                    <div
                        v-if="!loading && !addMeasurementToSection"
                        class="card"
                    >
                        <div
                            class="card-head row-between pointer flex-no-wrap"
                            @click="selectTradeIsOpen = !selectTradeIsOpen"
                        >
                            <div class="row-start flex-no-wrap">
                                <span class="w-100 f-14-blue estimate-box-mark mr-20">
                                    <span class="f-sbold row-center column-center mr-20 h-100">
                                        1
                                    </span>
                                </span>
                                <span class="f-18-black f-sbold">
                                    Select trade and section name
                                </span>
                            </div>
                            <div class="s-align-6">
                                <arrow-icon
                                    size="12"
                                    :transform="selectTradeIsOpen ? 270 : 90"
                                    class="i-darkgrey"
                                />
                            </div>
                        </div>
                        <div
                            class="card-body"
                            v-if="selectTradeIsOpen"
                        >
                            <div class="lg-12 sm-12 pt-20 row-start">
                                <div class="lg-6 sm-12 ph-2 ls-sm-6">
                                    <multiselect
                                        :options="trades"
                                        track-by="id"
                                        label="name"
                                        v-model="newSection"
                                        :showLabels="false"
                                        :max-height="160"
                                        v-validate="'required'"
                                        name="new_section"
                                        data-name="new_section"
                                        :class="[
                                            'mb-8',
                                            { 'multiselect-danger' : errors.has('new_section') }
                                        ]"
                                        @select="setNewSectionName"
                                    />
                                </div>
                                <div class="lg-6 sm-12 ph-2 ls-sm-6">
                                    <form-input
                                        label="Section Name"
                                        :class="['mb-8', { 'form-input-danger' : errors.has('section_name') }]"
                                    >
                                        <input
                                            type="text"
                                            name="section_name"
                                            class="form-input-field"
                                            placeholder="Section Name"
                                            v-input-style="newSectionParams.name"
                                            v-model="newSectionParams.name"
                                            v-validate="'required|min:3|max:255'"
                                        />
                                    </form-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="step-2"
                    :class="mScreen ? 'ph-20' : 'ph-30'"
                >
                    <div
                        v-if="!loading && !addMeasurementToSection"
                        :class="[
                            'card',
                            !isSectionInfoFormFilled ? 'box-opacity relative' : 'animation-opacity'
                        ]"
                    >
                        <div
                            class="card-head row-between pointer flex-no-wrap"
                            @click="selectTemplateIsOpen = !selectTemplateIsOpen"
                        >
                            <div class="row-start flex-no-wrap">
                                <span class="w-100 f-14-blue estimate-box-mark mr-20">
                                    <span class="f-sbold row-center column-center mr-20 h-100">
                                        2
                                    </span>
                                </span>
                                <span class="f-18-black f-sbold">
                                    Use template to create an estimate
                                </span>
                            </div>
                            <div class="s-align-6">
                                <arrow-icon
                                    size="12"
                                    :transform="selectTemplateIsOpen ? 270 : 90"
                                    class="i-darkgrey"
                                />
                            </div>
                        </div>
                        <div
                            class="pv-20 h-100"
                            v-if="selectTemplateIsOpen"
                        >
                            <div class="add-section-estimate-template-list">
                                <perfect-scrollbar class="ps-grey h-100">
                                    <span class="f-18-black f-sbold mb-25 pt-50 ph-30">
                                        Select Template
                                    </span>
                                    <div class="ph-50 pt-25 pb-25">
                                        <div class="row-center pt-4">
                                            <div
                                                v-for="(type, index) in templateTypes"
                                                :key="index"
                                                class="lg-6 sm-12 ph-2 ls-sm-6"
                                            >
                                                <label
                                                    :class="[
                                                        'add-large-button mb-sm-1 relative hidden',
                                                        { 'add-large-button-active' : useTemplate == index }
                                                    ]"
                                                >
                                                    <div class="angle-input">
                                                        <input
                                                            type="radio"
                                                            name="use_template"
                                                            v-model="useTemplate"
                                                            :value="type.value"
                                                        />
                                                        <span class="angle-input-icon-medium-border-radius">
                                                            <mark-icon size="12"/>
                                                        </span>
                                                    </div>
                                                    <div class="row-center align-center">
                                                        <no-template-icon
                                                            v-if="type.value == SELECTED_TEMPLATE.NO_TEMPLATE"
                                                            size="25"
                                                        />
                                                        <use-template-icon
                                                            v-else
                                                            size="25"
                                                        />
                                                        <span class="f-15-black ml-20">
                                                            {{ type.title }}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="step-3"
                                        class="w-100 column-start"
                                        v-if="showUseTemplate"
                                        :class="[mScreen ? 'ph-20' : 'ph-30']"
                                    >
                                        <div
                                            v-if="tradeTemplates.length"
                                            class="pb-8 w-100"
                                        >
                                            <div class="row-start t-center pb-8">
                                                <span class="f-16-black">
                                                    {{ tradeName }}
                                                </span>
                                            </div>
                                            <estimate-preview
                                                v-for="(template, index) in tradeTemplates"
                                                :item="template"
                                                :key="`trade-template-${index}`"
                                                :itemIndex="index"
                                                :active="selectedTemplateId == template.id"
                                                page="EstimateCreate"
                                            >
                                                <div
                                                    slot="buttons"
                                                    class="w-100 s-align-6"
                                                >
                                                    <button
                                                        class="dismiss-button mb-sm-1 no-wrap"
                                                        @click="addMeasurements(template.id)"
                                                        v-if="selectedTemplateId == template.id"
                                                    >
                                                        <span class="f-11-blue">+ Add Measurements</span>
                                                    </button>
                                                </div>
                                                <div slot="radio">
                                                    <div class="angle-input">
                                                        <input
                                                            type="radio"
                                                            name="template"
                                                            v-model="selectedTemplateId"
                                                            :value="template.id"
                                                        />
                                                        <span class="angle-input-icon-medium-border-radius">
                                                            <mark-icon size="12"/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </estimate-preview>
                                        </div>
                                        <div
                                            v-if="showAllTemplates"
                                            id="to-all-templates"
                                            class="w-100"
                                        >
                                            <div class="row-start t-center pv-8">
                                                <span class="f-16-black">
                                                    {{
                                                        otherTemplates.length
                                                            ? 'Templates for Other Trades'
                                                            : 'Nothing to display in Templates'
                                                    }}
                                                </span>
                                            </div>
                                            <estimate-preview
                                                v-for="(template, index) in otherTemplates"
                                                :item="template"
                                                :key="`other-template-${index}`"
                                                :active="selectedTemplateId == template.id"
                                                :itemIndex="index"
                                                page="EstimateCreate"
                                            >
                                                <div
                                                    slot="buttons"
                                                    class="w-100 s-align-6"
                                                >
                                                    <button
                                                        class="edit-button mb-sm-1 no-wrap"
                                                        @click="addMeasurements(template.id)"
                                                        v-if="selectedTemplateId == template.id"
                                                    >
                                                        <span class="f-12-blue">+ Add Measurements</span>
                                                    </button>
                                                </div>
                                                <div slot="radio">
                                                    <div class="angle-input">
                                                        <input
                                                            type="radio"
                                                            name="template"
                                                            v-model="selectedTemplateId"
                                                            :value="template.id"
                                                        />
                                                        <span class="angle-input-icon-medium-border-radius">
                                                            <mark-icon size="12"/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </estimate-preview>
                                        </div>
                                        <div
                                            v-if="useTemplate == SELECTED_TEMPLATE.USE_TEMPLATE"
                                            class="w-100 estimate-bottom-panel-sticky pv-15 opacity-8"
                                        >
                                            <div class="row-center t-center">
                                                <span class="f-16-white">
                                                    Not finding what you are looking for?
                                                </span>
                                            </div>
                                            <div class="row-center pt-4">
                                                <div class="lg-6 sm-12 s-align-5">
                                                    <button
                                                        class="transparent-button s-align-4"
                                                        @click="toAllTemplates"
                                                    >
                                                        <span class="f-13-white f-normal underline">
                                                            Show Templates for Other Trades
                                                        </span>
                                                        <arrow-icon
                                                            transform="0"
                                                            size="9"
                                                            class="ml-2 i-white"
                                                        />
                                                    </button>
                                                </div>
                                                <div class="lg-6 sm-12 s-align-5">
                                                    <router-link
                                                        class="f-13-white underline"
                                                        :to="{ name: 'TemplateManager' }"
                                                    >
                                                        Show Template Library
                                                        <arrow-icon
                                                            transform="0"
                                                            class="ml-2 i-white"
                                                            size="9"
                                                        />
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </perfect-scrollbar>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    :class="['h-100', mScreen ? 'ph-20' : 'ph-30']"
                    v-if="addMeasurementToSection"
                >
                    <div class="card pv-30 ph-20">
                        <div class="row-start ph-2">
                            <button
                                @click="addMeasurementToSectionClose"
                                class="transparent-button pb-8 row-start"
                            >
                                <arrow-icon
                                    transform="180"
                                    size="10"
                                    class="i-grey mr-8"
                                />
                                Back to Templates
                            </button>
                        </div>
                        <div class="f-18-black f-sbold ph-25 ph-2">Add Measurements</div>
                        <div
                            class="row-between row-baseline pt-30 ph-2"
                            v-if="selectedTemplateMeasurements.length"
                        >
                            <div class="lg-5 sm-12 pr-4 pv-2">
                                <p class="f-14-black w-100">
                                    Choose one of the existing job measurements or create one manually
                                </p>
                            </div>
                            <div class="lg-7 sm-12 pv-2">
                                <multiselect
                                    :options="selectedTemplateMeasurements"
                                    placeholder="Select an existing measurement"
                                    track-by="id"
                                    label="name"
                                    :showLabels="false"
                                    v-model="selectedTemplateMeasure"
                                    @select="fillMeasurements"
                                />
                            </div>
                        </div>
                        <div class="row-start pt-30 pb-8">
                            <div
                                v-for="(measurement, index) in measurements"
                                :key="index"
                                class="column-start lg-4 sm-12 ls-sm-6 ph-2 pb-4"
                            >
                                <span class="f-13-black ellipsis w-100 pb-1 f-sbold">
                                    {{ measurement.name }}
                                </span>
                                <form-input
                                    label="Actual"
                                    :maskAfter="`/ ${measurement.attribute_type}`"
                                    :maskValue="measurement.value"
                                    :class="[
                                        'mb-8',
                                        { 'form-input-danger' : errors.has(`${measurement.value}-${index}`) }
                                    ]"
                                >
                                    <input
                                        type="number"
                                        :name="`${measurement.value}-${index}`"
                                        class="form-input-field"
                                        placeholder="Actual"
                                        v-input-style="measurement.value"
                                        v-model="measurement.value"
                                        v-validate="'required'"
                                    />
                                </form-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="['modal-footer w-100 pt-8 bt-grey', mScreen ? 'ph-20' : 'ph-30']">
                <div class="row-end">
                    <button
                        class="primary-button"
                        @click="goNext"
                        ref="next_button"
                        v-if="!addMeasurementToSection"
                    >
                        Create
                    </button>
                    <span
                        v-else
                        class="row-end w-100"
                    >
                        <button
                            @click="addMeasurementToSectionClose"
                            class="add-button mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            class="primary-button"
                            @click="saveMeasurement"
                        >
                            Save
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { FormInput, Loader } from '@/ui';
    import { MarkIcon, ArrowIcon, CloseIcon, UseTemplateIcon, NoTemplateIcon } from '@/icons';
    import { scrollToElementInBlock, clearArray } from '@/helpers';
    import Multiselect from 'vue-multiselect';
    import StepsPanel from '@/components/StepsPanel.vue';
    import EstimatePreview from '@/components/LeadModules/EstimatePreview.vue';
    import api from '@/headers.js';
    import { SELECTED_TEMPLATE } from '@/constants';

    export default {
        name: 'AddEstimateSectionModal',
        components: {
            UseTemplateIcon,
            NoTemplateIcon,
            CloseIcon,
            FormInput,
            Multiselect,
            StepsPanel,
            MarkIcon,
            ArrowIcon,
            EstimatePreview,
            Loader
        },
        props: {
            estimateId: {
                type: [String, Number],
                required: true
            },
            entityType: {
                type: String,
                required: true
            },
            jobId: {
                type: [String, Number],
                required: true
            }
        },
        data() {
            return {
                loading: false,
                trades: [],
                newSection: null,
                tradeName: '',
                templateTypes: [
                    { title: 'No template', value: 0 },
                    { title: 'Use a template', value: 1 }
                ],
                useTemplate: null,
                templates: [],
                tradeTemplates: [],
                otherTemplates: [],
                selectedTemplateId: null,
                showAllTemplates: false,
                newSectionParams: {
                    name: '',
                    description: '',
                    estimate_id: '',
                    trade_id: '',
                    profit_margin: 0,
                    items: []
                },
                addMeasurementToSection: false,
                selectTemplateIsOpen: false,
                leadMeasurements: [],
                selectedTemplateMeasurements: [],
                selectedTemplateMeasure: null,
                measurements: [],
                selectTradeIsOpen: false,
                SELECTED_TEMPLATE: SELECTED_TEMPLATE,
            }
        },
        watch: {
            isSectionInfoFormFilled: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (this.isSectionInfoFormFilled && this.useTemplate === null) {
                        scrollToElementInBlock(document.getElementById('step-2'));
                    }
                }
            },
            useTemplate: {
                immediate: true,
                handler (newVal, oldVal) {
                    this.$nextTick(() => {
                        if (this.useTemplate) {
                            scrollToElementInBlock(document.getElementById('step-3'));
                        }
                    });
                }
            }
        },
        computed: {
            showUseTemplate() {
                return !this.loading &&
                    !this.addMeasurementToSection &&
                    this.useTemplate == SELECTED_TEMPLATE.USE_TEMPLATE;
            },
            currentTemplate() {
                return this.templates.find(t => t.id == this.selectedTemplateId);
            },
            isSectionInfoFormFilled() {
                return this.newSection && this.newSectionParams.name !== '';
            }
        },
        methods: {
            getTrades() {
                this.loading = true;
                const params = { needed: ['trades'] };

                Promise.all([
                    api.get('/handbooks?filter[is_active]=1', { params }),
                    api.post('/get-templates'),
                    api.get(`/${this.jobId}/measurements/?entity_type=${this.entityType}`)
                ])
                    .then(([trades, templates, measurements]) => {
                        this.trades.push(...trades.data.data.trades);
                        this.templates.push(...templates.data.data.data);
                        this.leadMeasurements.push(...measurements.data.data.measurements);
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            setNewSectionName(data) {
                this.newSectionParams.name = `${data.name} Section`;
                this.newSectionParams.estimate_id = this.estimateId;
                this.newSectionParams.trade_id = data.id;

                this.tradeName = data.name;
                this.selectedTemplateId = null;

                if (this.templates.length)
                    this.sortTemplates();
            },
            sortTemplates() {
                clearArray(this.tradeTemplates);
                clearArray(this.otherTemplates);

                this.templates.map((template) => {
                    let count = 0;

                    for (let i = 0; i < template.trades.length; i++) {
                        if (template.trades[i].id == this.newSectionParams.trade_id) {
                            count++;
                        };
                    };

                    count > 0
                        ? this.tradeTemplates.push(template)
                        : this.otherTemplates.push(template);
                });
            },
            goNext() {
                this.$refs.next_button.disabled = true;

                if (!this.useTemplate || this.useTemplate == SELECTED_TEMPLATE.NO_TEMPLATE) {
                    return this.saveSection(this.newSectionParams);
                }

                if (!this.selectedTemplateId) {
                    this.$refs.next_button.disabled = false;
                    this.notifyError('Select a template before proceeding further.');
                    return;
                }
                this.newSectionParams.template_id = this.selectedTemplateId;
                if (this.measurements.length) {
                    this.newSectionParams.measurements = [];
                    this.measurements.map((measure) => {
                        this.newSectionParams.measurements.push({
                            measurement_attribute_id: measure.id,
                            value: measure.value
                        });
                    });
                }
                this.saveSection(this.newSectionParams);
            },
            toAllTemplates() {
                this.showAllTemplates = true;
                this.$nextTick(() => {
                    let domRect = document.getElementById('to-all-templates');

                    if (domRect != null || domRect != undefined) {
                        this.$el.querySelector('.modal-content').scrollTo({
                            top: domRect.offsetTop - 220,
                            behavior: "smooth"
                        })
                    };
                });
            },
            addMeasurements(id) {
                clearArray(this.measurements);
                this.sortMeasurements();

                this.currentTemplate.hasOwnProperty('measurements')
                    ? this.measurements.push(...this.currentTemplate.measurements)
                    : this.createNewMeasurementsArray(id);

                this.addMeasurementToSection = true;
            },
            sortMeasurements() {
                if (!this.leadMeasurements.length) return false;

                clearArray(this.selectedTemplateMeasurements);
                this.selectedTemplateMeasure = null;

                this.leadMeasurements.map((measure) => {
                    if (measure.type_id == this.currentTemplate.type_id) {
                        this.selectedTemplateMeasurements.push(measure);
                    }
                });
            },
            createNewMeasurementsArray(id) {
                this.currentTemplate.type.attributes.map((attr) => {
                    this.measurements.push({
                        name: attr.name,
                        attribute_type: attr.attribute_type,
                        id: attr.id,
                        value: ''
                    });
                });
            },
            fillMeasurements(data) {
                this.measurements.forEach((measure, index) => {
                    measure.value = data.attributes[index].pivot.value;
                });
            },
            addMeasurementToSectionClose() {
                this.addMeasurementToSection = false;
            },
            saveMeasurement() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('All fields are required.');
                        return;
                    }
                    this.addMeasurementToSectionClose();
                });
            },
            cancelMeasurements() {
                this.addMeasurementToSection = false;
                clearArray(this.measurements);
            },
            saveSection(params) {
                api.post('/estimate-sections', params)
                    .then(response => {
                        this.notifySuccess(`${params.name} has been added!`);
                        this.$emit('update');
                        this.$emit('close');
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    });
            }
        },
        created() {
            this.getTrades();
        }
    }
</script>