import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import views from './modules/views';
import menu from './modules/menu';
import user from './modules/user';
import users from './modules/users';
import notes from './modules/notes';
import permissions from './modules/permissions';
import customFields from './modules/customFields';
import milestones from './modules/milestones';
import lead from './modules/lead';
import formBuilder from './modules/formBuilder';
import reports from './modules/reports';
import notifications from './modules/notifications';
import commercial from './modules/commercial';
import companyDocuments from './modules/companyDocuments';
import orders from './modules/orders';
import imap from './modules/imap';
import technicians from './modules/technicians';
import defaultFolder from './modules/defaultFolder';
import tasks from './modules/tasks';
import scheduler from './modules/scheduler';
import emailMessage from './modules/emailMessage'
import filters from './modules/filters';
import calendars from './modules/calendars';
import customer from './modules/customer';
import location from './modules/location';
import telephony from './modules/telephony';
import pointOfContacts from './modules/pointOfContacts';
import opportunity from './modules/opportunity';
import warranty from "./modules/warranty";
import chooseNextStepWarranty from "./modules/chooseNextStepWarranty";
import zipCodes from "./modules/zipCodes";
import contacts from "./modules/contacts";
import partners from "./modules/partners";
import audit from "./modules/audit";

Vue.use(Vuex)

const state = {
    referrer: null
};
const mutations = {
    setReferrer(state, payload) {
        state.referrer = payload;
    }
};
const actions = {};
const getters = {};

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules: {
        auth,
        views,
        menu,
        user,
        users,
        notes,
        permissions,
        customFields,
        milestones,
        lead,
        formBuilder,
        reports,
        notifications,
        commercial,
        companyDocuments,
        orders,
        imap,
        technicians,
        defaultFolder,
        tasks,
        scheduler,
        emailMessage,
        filters,
        calendars,
        customer,
        location,
        telephony,
        pointOfContacts,
        opportunity,
        warranty,
        chooseNextStepWarranty,
        zipCodes,
        contacts,
        partners,
        audit,
    }
})
