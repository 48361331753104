<template>
    <svg 
        viewBox="0 0 186.51065063476562 184.54336547851562"
        :width="size"
        :height="size"
        class="briefcase-icon"
    >
        <rect 
            id="ee-background" 
            x="0" 
            y="0" 
            width="186.51065063476562" 
            height="184.54336547851562" 
            style="fill: white; fill-opacity: 0; pointer-events: none;"
        />
        <g transform="matrix(9.800000190734863, 0, 0, 9.800000190734863, 4091.7177734375, -2681.8328247070312)">
            <path 
                class="st0" 
                d="M-401.8,276.5h-2.9v-0.7c0-1-0.8-1.8-1.7-1.8h-3.4c-1,0-1.7,0.8-1.7,1.8v0.7h-2.9c-1.5,0-2.7,1.2-2.7,2.8v5.2c0,0.6,0.4,1,0.9,1.1v3.6c0,1.5,1.2,2.8,2.7,2.8h10.9c1.5,0,2.7-1.2,2.7-2.8v-3.6c0.5-0.1,0.9-0.6,0.9-1.1v-5.2C-399.1,277.7-400.3,276.5-401.8,276.5z M-401.5,289.2c0,0.7-0.5,1.2-1.1,1.2h-10.9c-0.6,0-1.1-0.5-1.1-1.2v-3.5h4.4v1.5h4.4v-1.5h4.4L-401.5,289.2L-401.5,289.2z M-415.5,279.3c0-0.7,0.5-1.2,1.1-1.2h2.9h6.9h2.9c0.6,0,1.1,0.5,1.1,1.2v4.8h-5.2v-1.5h-4.4v1.5h-5.2V279.3z M-410,275.8c0-0.1,0.1-0.2,0.2-0.2h3.4c0.1,0,0.2,0.1,0.2,0.2v0.7h-3.8V275.8z M-407.4,285.6h-1.4v-1.4h1.4V285.6z" 
                style="stroke-width: 0.1018; stroke-miterlimit: 10;"
            />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'BriefcaseIcon',
        props: {
            size: {
                type: [Number, String],
                default: 18
            }
        }
    }
</script>
