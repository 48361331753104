<template>
    <div :class="['s-align-5', view.class]">
        <component 
            :is="view.icon" 
            :class="view.iconStyle" 
            :size="view.iconSize"
        />
    </div>
</template>

<script>
    import { CloseIcon, ClockIcon, MarkIcon } from '@/icons';
    import { PROGRESS_STATUS, PACKETS_PARTIES_STATUSES, PACKETS_STATUSES } from '@/constants';

    export default {
        name: 'ProgressStatus',
        components: {
            MarkIcon, 
            ClockIcon, 
            CloseIcon
        },
        props: {
            status: {
                type: String,
                required: true
            },
            cardStatus: {
                type: String,
                required: false
            }
        },
        computed: {
            view() {
                if (this.cardStatus == PACKETS_STATUSES.CANCELLED) {
                    return {
                        class: 'progress-status-close',
                        icon: 'close-icon',
                        iconStyle: 'i-red',
                        iconSize: 11
                    }
                }

                switch (this.status) {
                    case PACKETS_PARTIES_STATUSES.REJECTED : {
                        return {
                            class: 'progress-status-fault',
                            icon: 'close-icon',
                            iconStyle: 'i-red',
                            iconSize: 11
                        }
                    }; break;
                    case PACKETS_PARTIES_STATUSES.IN_PROGRESS :
                    case PACKETS_PARTIES_STATUSES.PENDING : {
                        return {
                            class: 'progress-status-wait',
                            icon: 'clock-icon',
                            iconStyle: 'i-orange',
                            iconSize: 16
                        }
                    }; break;
                    case PACKETS_PARTIES_STATUSES.SIGNED : {
                        return {
                            class: 'progress-status-done',
                            icon: 'mark-icon',
                            iconStyle: 'i-green',
                            iconSize: 12
                        }
                    }; break;
                }
            }
        }
    }
</script>