<template>
    <modal
        @close="$emit('close')"
        :is-warranty="true"
        :is-close-btn="false"
        :is-header-text-center="true"
    >
        <div slot="modal-header">Choose next steps</div>
        <div slot="modal-body" class="w-100">
            <div class="warranty-next-steps column-center">
                <button
                    v-for="step in steps"
                    :key="step.id"
                    class="warranty-next-steps__btn"
                    :class="{'active': activeStep && activeStep.id === step.id}"
                    @click="activeHandler(step)"
                >
                    {{ step.title }}
                </button>
                <form-input
                    :label="'Job Number'"
                    v-if="activeStep && activeStep.id === TYPE_COMPLETE.new_job && !jobNumber"
                >
                    <input
                        type="text"
                        name="job_number"
                        id="job_number"
                        v-model="stepJobNumber"
                        placeholder="Job Number"
                        class="form-input-field w-50"
                        v-validate="'required'"
                    >
                </form-input>
            </div>
        </div>
        <div
            slot="modal-footer"
            class="row-between"
        >
            <button
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>

            <div class="d-flex">
                <div>
                    <tooltip
                        v-if="!outlook_id"
                        direction="left"
                        mobileDescription="Field info"
                        class="max-w-100"
                    >
                        <button
                            v-if="activeStep && activeStep.id === TYPE_COMPLETE.no_warranty"
                            class="service-primary-button mr-2"
                            @click="sendEmail"
                            disabled
                        >
                            <envelope-icon size="19"/>
                        </button>
                        <template v-slot:content>
                            <p class="f-16-white t-center">
                                Please link your account to Outlook
                            </p>
                        </template>
                    </tooltip>
                    <div
                        v-else
                        class="row-end messages-btn-container"
                    >
                        <button
                            v-if="activeStep && activeStep.id === TYPE_COMPLETE.no_warranty"
                            class="service-primary-button mr-2"
                            @click="sendEmail"
                        >
                            <envelope-icon size="19"/>
                        </button>
                    </div>
                </div>

                <button
                    class="primary-button"
                    @click="completeHandler"
                    :disabled="!activeStep || loading || (activeStep.id === TYPE_COMPLETE.new_job && !stepJobNumber)"
                >
                    Finish
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
    import {TYPE_COMPLETE} from '@/constants';
    import {FormInput, Tooltip} from '@/ui';
    import {EnvelopeIcon} from '@/icons';
  
    export default {
        name: 'ChooseStepWarrantyModal',
        data() {
            return {
                outlook_id: null,
                TYPE_COMPLETE,
                loading: false,
                activeStep: null,
                steps: [
                    {title: 'No Warranty', id: TYPE_COMPLETE.no_warranty, routerName: ''},
                    {title: 'Create new lead', id: TYPE_COMPLETE.new_lead, routerName: 'CreateNewLead'},
                    {title: 'Create Warranty Job', id: TYPE_COMPLETE.new_job, routerName: 'CreateNewWarranty'}
                ],
                stepJobNumber: null
            }
        },
        props: {
            warrantyCaseId: {
                type: Number,
                required: true
            },
            jobNumber: {
                type: String,
                default: null
            },
            overviewRoute: {
                type: Object,
                required: true
            }
        },
        components: {
            Modal: () => import('@/ui/Modal/Modal.vue'),
            FormInput,
            EnvelopeIcon,
            Tooltip
        },
        computed: {
            imapConfig() {
                return this.$store.getters['imap/getImapConfig'];
            },
            loggedInUserId() {
                return this.$store.getters['user/getUserId'];
            },
            isImapConfigLoaded() {
                return this.$store.getters['imap/isImapConfigLoaded'];
            }
        },
        methods: {
            getImapConfig() {
                if (!this.isImapConfigLoaded) {
                    this.$store.dispatch('imap/GET_IMAP_ACCOUNTS', this.loggedInUserId);
                    this.$watch('isImapConfigLoaded', this.getImapConfig);
                    return;
                }

                this.outlook_id = this.imapConfig.length ? this.imapConfig[0]._id : null;
            },
            activeHandler(step) {
                this.activeStep = this.activeStep && this.activeStep.id === step.id ? null : step;
            },
            sendEmail() {
                this.$emit('close');
                this.$emit('showEmailModal', this.outlook_id, this.activeStep);
            },
            completeHandler() {
                this.loading = true;

                const {id,routerName} = this.activeStep;
                const params = {
                    caseId: this.warrantyCaseId,
                    activeStepId: id
                }

                this.$store.dispatch('chooseNextStepWarranty/COMPLETE_HANDLER', params)
                    .then(() => {
                        switch (id) {
                            case TYPE_COMPLETE.no_warranty:
                                this.$router.push(this.overviewRoute.routeLink);
                                break;
                            case TYPE_COMPLETE.new_lead:
                                this.$router.push({name: routerName});
                                break;
                            case TYPE_COMPLETE.new_job:
                                const query = Object.assign(this.$route.query, {job_number: this.stepJobNumber});

                                this.$router.push({name: routerName, query});
                                break;
                        }

                    })
                    .catch(err => {
                        this.notifyRequestErrors(err);
                    })
                    .finally(() =>{
                        this.loading = false;
                    })
            }
        },
        created() {
            this.getImapConfig();
            this.stepJobNumber = this.jobNumber;
        }
    }
</script>
