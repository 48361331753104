<template>
    <div class="row-center">
        <svg 
            class="loader-solid"  
            viewBox="0 0 100 100"
        >
            <circle cx="50" cy="50" fill="none" stroke="#00a0ff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(329.929 50 50)">
                <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
            </circle>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'LoaderSolid'
    }
</script>