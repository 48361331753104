<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 14 15"
        class="crm-logo"
        v-if="type === 'default'"
    >
        <g>
            <path class="crm-logo__st0" d="M2.3 12.2l6.9-3.8-1.9-1.1-4.9 2.9-.1-.1z"/>
            <path class="crm-logo__st1" d="M2.3 5.1v7.3L0 11.3V3.9z"/>
            <path class="crm-logo__st1" d="M7 12.8l-4.9-2.4-1.9.9L7 15z"/>
            <path class="crm-logo__st0" d="M14 3.5L6.8 0 5 1.1l6.9 3.6z"/>
            <path class="crm-logo__st0" d="M7 .1L6.9 0h-.1L4.9 1.2l-.1 7.6 2.3-1.2L7 .1c.1 0 0 0 0 0z"/>
            <path class="crm-logo__st1" d="M7 7.6l7-4.1-2.1-1-4.8 2.9H7z"/>
            <path class="crm-logo__st0" d="M2.3 4.8L8.9 1 6.8 0 2.1 2.8l-.2.1.1 1.8c.1 0 .2.1.3.1z"/>
            <path class="crm-logo__st0" d="M14 11.2V3.5l-2.2 1.3-.1 7.6z"/>
            <path class="crm-logo__st0" d="M7 15l7-3.8-1.9-1.1-5 2.8H7z"/>
            <path class="crm-logo__st0" d="M12.2 12.2l1.8-1-6.6-3.8-2 .9 6.5 4c.1-.1.2-.1.3-.1z"/>
            <path class="crm-logo__st0" d="M9.3 13.8V6.2L7.1 7.5 7 15z"/>
            <path class="crm-logo__st1" d="M7 5.4L2 2.9l-2 1 7 3.8.1-.1z"/>
            <path class="crm-logo__st1" d="M7.1 7.5V15l-2.3-1.2V6.5z"/>
        </g>
    </svg>
    <svg :width="width" :height="height" fill="none" xmlns="http://www.w3.org/2000/svg" class="crm-logo" v-else-if="type === 'color'">
        <g clip-path="url(#a)">
            <path
                d="m23.326 30-2-5.68h-10.76l-2 5.68h-6.96l11.04-28h6.56l11.04 28h-6.92Zm-10.84-11.2h6.92l-3.48-9.92-3.44 9.92Z"
                fill="#5338FC"/>
            <path
                d="M32.078 30V11.52h5.92v2.72h.08c1.12-2.213 3.04-3.32 5.76-3.32 2.827 0 4.76 1.2 5.8 3.6h.08c1.36-2.4 3.427-3.6 6.2-3.6 1.974 0 3.56.627 4.76 1.88 1.227 1.253 1.84 2.973 1.84 5.16V30h-6.04V19.96c0-2.48-1-3.72-3-3.72-2.186 0-3.28 1.533-3.28 4.6V30h-5.8V19.96c0-2.48-1-3.72-3-3.72-2.186 0-3.28 1.533-3.28 4.6V30h-6.04Zm41.666.6c-2.533 0-4.626-.853-6.28-2.56-1.653-1.733-2.48-4.16-2.48-7.28s.827-5.533 2.48-7.24c1.654-1.733 3.747-2.6 6.28-2.6 2.747 0 4.76 1.04 6.04 3.12h.08v-2.52h6.04V30h-6.04v-2.52h-.08c-1.28 2.08-3.293 3.12-6.04 3.12Zm1.68-5.52c1.254 0 2.28-.387 3.08-1.16.8-.8 1.2-1.853 1.2-3.16 0-1.307-.4-2.347-1.2-3.12-.8-.8-1.826-1.2-3.08-1.2-1.226 0-2.24.4-3.04 1.2-.8.773-1.2 1.813-1.2 3.12 0 1.307.4 2.36 1.2 3.16.8.773 1.814 1.16 3.04 1.16Zm20.158-.16h9.44V30h-16.8v-4.56l8.64-8.84h-8v-5.08h15.56v4.44l-8.84 8.96Zm18.167-22.88v6.08h-6.48V2.04h6.48ZM107.509 30V11.52h6.04V30h-6.04Z"
                fill="#000"/>
        </g>
        <defs>
            <clipPath id="a">
                <rect :width="width" :height="height" fill="#fff"/>
            </clipPath>
        </defs>
    </svg>
    <svg :width="width" :height="height" viewBox="0 0 115 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="crm-logo" v-else-if="type === 'white'">
        <g clip-path="url(#clip0_2_2)">
            <path d="M23.3255 30L21.3255 24.32H10.5655L8.56547 30H1.60547L12.6455 2H19.2055L30.2455 30H23.3255ZM12.4855 18.8H19.4055L15.9255 8.88L12.4855 18.8ZM32.0783 30V11.52H37.9983V14.24H38.0783C39.1983 12.0267 41.1183 10.92 43.8383 10.92C46.6649 10.92 48.5983 12.12 49.6383 14.52H49.7183C51.0783 12.12 53.1449 10.92 55.9183 10.92C57.8916 10.92 59.4783 11.5467 60.6783 12.8C61.9049 14.0533 62.5183 15.7733 62.5183 17.96V30H56.4783V19.96C56.4783 17.48 55.4783 16.24 53.4783 16.24C51.2916 16.24 50.1983 17.7733 50.1983 20.84V30H44.3983V19.96C44.3983 17.48 43.3983 16.24 41.3983 16.24C39.2116 16.24 38.1183 17.7733 38.1183 20.84V30H32.0783ZM73.7445 30.6C71.2112 30.6 69.1179 29.7467 67.4645 28.04C65.8112 26.3067 64.9845 23.88 64.9845 20.76C64.9845 17.64 65.8112 15.2267 67.4645 13.52C69.1179 11.7867 71.2112 10.92 73.7445 10.92C76.4912 10.92 78.5045 11.96 79.7845 14.04H79.8645V11.52H85.9045V30H79.8645V27.48H79.7845C78.5045 29.56 76.4912 30.6 73.7445 30.6ZM75.4245 25.08C76.6779 25.08 77.7045 24.6933 78.5045 23.92C79.3045 23.12 79.7045 22.0667 79.7045 20.76C79.7045 19.4533 79.3045 18.4133 78.5045 17.64C77.7045 16.84 76.6779 16.44 75.4245 16.44C74.1979 16.44 73.1845 16.84 72.3845 17.64C71.5845 18.4133 71.1845 19.4533 71.1845 20.76C71.1845 22.0667 71.5845 23.12 72.3845 23.92C73.1845 24.6933 74.1979 25.08 75.4245 25.08ZM95.582 24.92H105.022V30H88.222V25.44L96.862 16.6H88.862V11.52H104.422V15.96L95.582 24.92ZM113.749 2.04V8.12H107.269V2.04H113.749ZM107.509 30V11.52H113.549V30H107.509Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_2_2">
                <rect :width="width" :height="height" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'CrmLogo',
    props: {
        width : {
            type: [Number, String],
            default: 20
        },
        height : {
            type: [Number, String],
            default: 20
        },
        type: {
            type: String,
            default: 'default'
        }
    }
}
</script>