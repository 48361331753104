var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"smart-doc-sidebar s-align-1 mr-8"},[_c('div',{staticClass:"smart-doc-sidebar-tabs column-start"},_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:tab.name + index,class:[
                'smart-doc-sidebar-tab p-8 pointer',
                { 'smart-doc-sidebar-tab-active' : _vm.activeTab == tab.name }
            ],on:{"click":function($event){_vm.activeTab = tab.name}}},[_c(tab.icon,{tag:"component",class:_vm.activeTab == tab.name ? 'i-blue' : 'i-grey'})],1)}),0),_c('div',{staticClass:"smart-doc-sidebar-items pv-8 ph-5 column-start"},[_c('div',{class:[
            'smart-doc-sidebar-header f-sbold s-align-4 row-between ph-8 mb-8',
            _vm.getClassSidebarHeader(_vm.activeTab)
        ]},[_vm._v("\n            "+_vm._s(_vm.getTitleSidebarHeader(_vm.activeTab))+"\n        ")]),_vm._l((_vm.smartObjects),function(item,index){return _c('div',{key:item.name,class:[
                'grabbable smart-doc-sidebar-item s-align-4 mb-8 ph-1 pv-3', 
                _vm.smartDrag == index
                    ?  'drag-item'
                    :  'smart-doc-sidebar-item'
            ],attrs:{"draggable":true},on:{"dragstart":function($event){return _vm.dragStart($event,item,index)}}},[_c('div',{staticClass:"s-align-4"},[_c('button',{staticClass:"transparent-button ml-8"},[_c('drag-icon',{staticClass:"i-grey"})],1),_c('span',{staticClass:"f-13-black ml-8"},[_vm._v("\n                    "+_vm._s(item.title)+"\n                ")])])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }