<template>
    <div class="milestone-dead-info mb-20 p-20">
        <div class="row-between milestone-dead-info-main">
            <div class="s-align-4">
                <div class="stop-icon-wrapper s-align-5 mr-20">
                    <stop-icon
                        class="i-white"
                        size="17"
                    />
                </div>
                <div class="column-start">
                    <span class="f-sbold f-12-darkgrey">
                        {{ milestone.reason }}
                    </span>
                    <button 
                        v-if="milestone.extra_reason"
                        class="transparent-button f-12-blue"
                        @click="detailsOpen = !detailsOpen"
                    >
                        <span>
                            Show details
                        </span>
                        <arrow-icon 
                            class="ml-1 mt-1 i-blue"
                            size="8"
                            :transform="detailsOpen ? 270 : 90"
                        />
                    </button>
                </div>
            </div>
            <div class="mark-dead-info column-end f-sbold f-12-darkgrey">
                <span>
                    {{ getMilestonesDate | createdAt }}
                </span>
                <div>
                    <span class="f-12-grey f-normal">
                        Closed by:
                    </span>
                    {{ milestone.md_user_id.user_first_name }}
                    {{ milestone.md_user_id.user_last_name }}
                </div>
            </div>  
        </div>
        <div
            v-if="detailsOpen" 
            class="milestone-dead-details milestone-break-word f-12-grey mt-8"
        >
            {{ milestone.extra_reason }}
        </div>
    </div>  
</template>

<script>
import moment from 'moment/src/moment';
import { StopIcon, ArrowIcon } from '@/icons';

export default {
    props: ['milestone'],
    components: {
        StopIcon, 
        ArrowIcon
    },
    data() {
        return {
            detailsOpen: false
        }
    },
    computed: {
        getMilestonesDate() {
            return typeof this.milestone.completed_at === 'number'
                ? moment.unix(this.milestone.completed_at) 
                : this.milestone.completed_at;
        }
    }
}
</script>