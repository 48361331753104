<template>
    <div 
        class="section relative pv-15"
        :draggable="isSectionDraggable"
        @dragstart="$emit('dragstart')"
        @dragenter="onSectionDragenter"
        @dragleave="onSectionDragleave"
        @drop="onSectionDrop"
        @dragend="$emit('dragend')"
    >
        <div  
            class="section-title row-between row-baseline mb-4 grabbable relative"
            v-if="section.id || section.isNew"
        >
            <div class="w-90">
                <input 
                    type="text" 
                    name="section_label"
                    :class="[
                        'transparent-input',
                        errors.has('section_label') ? 'transparent-input-danger' : ''
                    ]"
                    v-model="section.name"
                    placeholder="Section label"
                    maxlength="100"
                    v-validate="'required|min:3|max:99'"
                    @input="updateSectionName"
                />
            </div>
            <div class="context-menu-toggle s-align-6">
                <context-menu 
                    :unicId="`section-container-menu-${section.id}`"
                    :iconType="section.type === CUSTOM_FIELDS_SECTION_TYPES.COMMON ? 'gear' : 'points'"
                >
                    <button
                        v-if="section.type !== CUSTOM_FIELDS_SECTION_TYPES.COMMON" 
                        class="delete-field s-align-4 bb-grey pb-4"
                        @click="isSectionEditModalOpen = true"
                    >
                        <span class="icon-container mr-6">
                            <edit-icon/>
                        </span>
                        <span>Edit Section</span>
                    </button>
                    <button 
                        class="delete-field s-align-4"
                        @click="isSectionConfirmDeleteShow = true"
                    >
                        <span class="icon-container mr-6">
                            <trash-icon 
                                width="16"
                                height="16"
                            />
                        </span>
                        <span>Delete Section</span>
                    </button>
                </context-menu>
            </div>
        </div>
        <div 
            v-if="section.type === CUSTOM_FIELDS_SECTION_TYPES.MULTILINE"
            :class="[
                'full-section-fields p-6 none-events',
                { 'required' : isSectionRequired }
            ]"
        >
            <!-- placeholder -->
            <form-input-textarea class="w-100">
                <textarea
                    class="form-input-field"
                    rows="4"
                    :placeholder="section.fields[0].label"
                />
            </form-input-textarea>
        </div>
        <custom-field-address-section
            v-else-if="section.type === CUSTOM_FIELDS_SECTION_TYPES.ADDRESS"
            :class="[
                'full-section-fields p-6 none-events',
                { 'required' : isSectionRequired }
            ]"
            :value="section.fields"
        />
        <div 
            v-else-if="section.type === CUSTOM_FIELDS_SECTION_TYPES.PICTURE"
            :class="[
                'full-section-fields p-6 none-events',
                { 'required' : isSectionRequired }
            ]"
        >
            <div class="row-start row-baseline mb-1">
                <attach-icon
                    class="mr-1" 
                    size="15"
                />
                <span class="f-15-darkgrey f-sbold">Attachments</span>
            </div>
            <p class="f-14-grey mb-7">
                {{ section.fields[0].label }}
            </p>
            <div class="fields-drop-place s-align-5">
                <full-upload-icon 
                    class="i-blue mr-2"
                />
                <span class="f-13-black f-sbold mr-1">Drag and drop files here or</span> 
                <span class="f-13-blue f-sbold">browse your computer</span> 
            </div>
        </div>
        <div 
            class="section-fields row-between"
            v-else-if="section.fields && section.fields.length"
        >
            <div 
                v-for="(field, index) in sortedSection.fields"
                :key="index"
                :class="[
                    'field',
                    {
                        'selected': (draggingField.id === field.id),
                        'grabbable': isDragAvailable
                    }
                ]"
                :draggable="isDragAvailable && field.id"
                @dragstart="fieldDragStart(field, index)"
                @dragenter="fieldDragEnter(field, index)"
                @dragend="fieldDragEnd(field, index)"
            >
                <field 
                    v-if="field.id"
                    :section-id="section.id"
                    :field="field"
                    @edit="editField(field)"
                    @deleted="deleteField"
                    @updated="updateField"
                />
                <div
                    v-else
                    class="field-drop-place s-align-5 f-13-darkgrey w-100 relative"
                    @drop.stop="addNewField"
                >
                    Drop field here
                </div>
            </div>
        </div>
        <div
            v-else
            class="fields-drop-place s-align-5 f-13-darkgrey"
            @drop.prevent="addNewField"
        >
            Drag items from the left bar and drop them here
        </div>

        <!-- modals -->
        <modal
            v-if="isSectionConfirmDeleteShow"
            @close="isSectionConfirmDeleteShow = !isSectionConfirmDeleteShow"
            class="section-delete-confirm"
        >
            <div slot="modal-body">
                <div class="w-100 t-center pb-4">
                    <p class="f-14-black pb-2">
                        Are you sure want to delete '{{ sortedSection.name }}' section?
                    </p>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="row-between">
                    <button 
                        class="add-button"
                        @click="isSectionConfirmDeleteShow = false"
                    >
                        Cancel
                    </button>
                    <button 
                        class="delete-button mr-4"
                        @click="deleteSection"
                        :disabled="requestIsGoing"
                    >
                        Delete
                    </button>
                </div>
            </div>
        </modal>
        <custom-fields-single-line-modal
            v-if="showSingleLineEdit"
            :section-id="section.id"
            :editing-field="editingField"
            :order="nextOrder"
            @close="closePopup"
            @createField="createField"
            @updateField="updateField"
        />
        <custom-fields-db-table-modal
            v-if="showDbTableEdit"
            :section-id="section.id"
            :editing-field="editingField"
            :order="nextOrder"
            @close="closePopup"
            @createField="createField"
            @updateField="updateField"
        />
        <custom-fields-multiselect-modal
            v-if="showPickListEdit"
            :section-id="section.id"
            :editing-field="editingField"
            :order="nextOrder"
            @close="closePopup"
            @createField="createField"
            @updateField="updateField"
        />
        <custom-fields-address-modal
            v-if="isSectionEditModalOpen && section.type === CUSTOM_FIELDS_SECTION_TYPES.ADDRESS"
            @close="closePopup"
            :editing-section="sortedSection.fields"
            @updateSection="updateSection"
        />
        <custom-fields-multiline-modal
            v-if="isMultilineModalShow"
            :editing-field="sortedSection.fields[0]"
            @close="closePopup"
            @updateSection="updateSection"
        />
    </div>
</template>

<script>
    import api from '@/headers.js';
    import { TrashIcon, EditIcon, AttachIcon, FullUploadIcon } from '@/icons';
    import { Modal, FormInputTextarea } from '@/ui';
    import { Field, CustomFieldAddressSection } from '@/components';
    import { 
        CustomFieldsSingleLineModal, 
        CustomFieldsMultiselectModal,
        CustomFieldsAddressModal,
        CustomFieldsMultilineModal,
        CustomFieldsDbTableModal
    }  from '@/modals';
    import debounce from 'lodash/debounce';
    import cloneDeep from 'lodash/cloneDeep';
    import { CUSTOM_FIELDS_TYPES, CUSTOM_FIELDS_SECTION_TYPES } from '@/constants.js';
    import { clearArray } from '@/helpers';
    import ContextMenu from '@/components/ContextMenu.vue';

    const NEW_FIELD = 'new_field';
    const EXISTING_FIELD = 'existing_field';
    const NEW_SECTION = 'new_section';

    export default {
        name: 'SectionContainer',
        components: {            
            TrashIcon, 
            EditIcon,
            AttachIcon,
            FullUploadIcon,
            Modal, 
            Field, 
            FormInputTextarea,
            CustomFieldAddressSection,
            CustomFieldsSingleLineModal,
            CustomFieldsMultiselectModal,
            CustomFieldsAddressModal,
            CustomFieldsMultilineModal,
            ContextMenu,
            CustomFieldsDbTableModal
        },
        props: {
            section: {
                type: Object,
                required: true
            }
        },
        computed: {
            dragSource() {
                return this.$store.getters['customFields/getDragSource'];
            },
            draggingField() {
                return this.$store.getters['customFields/getDraggingField'];
            },
            sectionOverId() {
                return this.$store.getters['customFields/getSectionOverId'];
            },
            nextOrder() {
                return this.lastOrder + 1;
            },
            isSectionDraggable() {
                return !this.showPickListEdit 
                    && !this.showSingleLineEdit 
                    && !this.isSectionConfirmDeleteShow
                    && !this.isSectionEditModalOpen
                    && !this.showDbTableEdit;
            },
            isDragAvailable() {
                return this.$store.getters['customFields/getDragAvailability'];
            },
            isSecondAddressShow() {
                // value stored in first field
                let isChecked = this.sortedSection.fields 
                    && this.sortedSection.fields[0].parameters
                    && JSON.parse(this.sortedSection.fields[0].parameters).add_other_address;
                return this.section.type === this.CUSTOM_FIELDS_SECTION_TYPES.ADDRESS && isChecked;
            },
            isSectionRequired() {
                return this.sortedSection.type !== CUSTOM_FIELDS_SECTION_TYPES.COMMON
                    && this.sortedSection.fields
                    && this.sortedSection.fields.some(f => f.parameters && JSON.parse(f.parameters).required)
            },
            isMultilineModalShow() {
                return this.isSectionEditModalOpen 
                    && (this.section.type === CUSTOM_FIELDS_SECTION_TYPES.MULTILINE 
                    || this.section.type === CUSTOM_FIELDS_SECTION_TYPES.PICTURE)
            }
        },
        mounted() {
            this.sort();
        },
        data() {
            return {
                isSectionConfirmDeleteShow: false,
                isSectionEditModalOpen: false,
                sortedSection: {},
                showSingleLineEdit: false,
                showDbTableEdit: false,
                showPickListEdit: false,
                editingField: {},
                fieldOverOrder: null,
                fieldOverIndex: null,
                fieldOverCount: 0,
                draggingFieldIndex: null,
                lastOrder: null,
                requestIsGoing: false,
                CUSTOM_FIELDS_SECTION_TYPES: CUSTOM_FIELDS_SECTION_TYPES
            }
        },
        watch: {
            section: function() {
                this.sort();
            }
        },
        methods: {
            sort() {
                this.sortedSection = {
                    ...this.section,
                    fields: this.section.fields.sort((a, b) => {
                        // find last order number
                        if (a.order > this.lastOrder) { this.lastOrder = a.order }
                        if (b.order > this.lastOrder) { this.lastOrder = b.order }

                        return a.order - b.order;
                    })
                };
            },
            onSectionDragenter() {
                // allow dragenter only on common section
                if (this.sortedSection.type !== CUSTOM_FIELDS_SECTION_TYPES.COMMON 
                    && this.dragSource === NEW_FIELD) {
                    return;
                }
                this.$emit('dragenter');

                // show drop placeholder for new field 
                if (this.dragSource === NEW_FIELD) {
                    this.sortedSection.fields.push({});
                }

                // show drop placeholder for existing field from another sections
                if (this.dragSource === EXISTING_FIELD 
                    && this.sortedSection.id !== this.draggingField.section_id) {
                    this.sortedSection.fields.push({});
                }
            },
            onSectionDragleave() {
                // allow dragleave only on common section
                if (this.sortedSection.type !== CUSTOM_FIELDS_SECTION_TYPES.COMMON
                    && this.dragSource === NEW_FIELD) {
                    return;
                }
                // hide drop placeholder for new field
                if (this.dragSource === NEW_FIELD) {
                    this.sortedSection.fields.pop({});
                }

                // hide drop placeholder for existing field from another sections
                if (this.dragSource === EXISTING_FIELD 
                    && this.sortedSection.id !== this.draggingField.section_id) {
                    this.sortedSection.fields.pop({});
                }
            },
            onSectionDrop() {
                // delete placeholder when droped not in the drop place
                if (this.dragSource === NEW_FIELD) {
                    this.sortedSection.fields.pop();
                }
                // hide drop placeholder for existing field from another sections
                if (this.dragSource === EXISTING_FIELD 
                    && this.sortedSection.id !== this.draggingField.section_id) {
                    this.sortedSection.fields.pop({});
                }
            },
            updateSectionName: debounce(function() {
                this.$validator.validate('section_label')
                    .then(result => {
                        if (result) {
                            const params = {
                                id: this.section.id,
                                name: this.section.name
                            };

                            api.put('/layouts/sections', params)
                                .then(() => {
                                    this.notifySuccess('Section name has been changed.');
                                });
                        }
                    });
            }, 1000),
            updateSection(fields) {
                this.sortedSection.fields.splice(0, this.sortedSection.fields.length, ...fields);
                this.closePopup();
            },
            deleteSection() {
                this.requestIsGoing = true;
                api.delete(`/layouts/sections?id=${this.section.id}`)
                    .then(() => {
                        this.notifySuccess('Section deleted.');
                        this.$emit('onSectionDelete')
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.isSectionConfirmDeleteShow = false;
                        this.requestIsGoing = false;
                    });
            },
            addNewField() {
                if (this.dragSource === NEW_FIELD) {
                    let field = JSON.parse(event.dataTransfer.getData('obj'));
                    this.editingField = field;

                    switch(field.name) {
                        case CUSTOM_FIELDS_TYPES.PICK_LIST: 
                        case CUSTOM_FIELDS_TYPES.MULTISELECT:
                            this.showPickListEdit = true;
                            break;
                        case CUSTOM_FIELDS_TYPES.DB_TABLE:
                            this.showDbTableEdit = true;
                            break;
                        default:
                            this.showSingleLineEdit = true;
                            break;
                    }
                } else if (this.dragSource === EXISTING_FIELD && this.section.id !== this.dragSource.section_id) {
                    this.$store.commit('customFields/SET_DRAG_AVAILABILITY', false);
                    const params = {
                        id: this.draggingField.id,
                        section_id: this.section.id,
                        order: this.nextOrder
                    };
                    const draggingField = cloneDeep(this.draggingField);
                    
                    api.put('/layouts/custom-fields', params)
                        .then(response => {
                            this.notifySuccess(`Field has been moved to ${this.section.name}.`);
                            this.createField(response.data.data);
                            this.$emit('removeField', {
                                section_id: draggingField.section_id, 
                                field_id: draggingField.id
                            });

                            this.$store.commit('customFields/SET_DRAGGING_FIELD', {});
                            this.$store.commit('customFields/SET_DRAG_SOURCE', '');
                        });
                } 
            },
            editField(field) {
                this.editingField = field;

                switch(this.editingField.type.name) {
                    case CUSTOM_FIELDS_TYPES.PICK_LIST:
                    case CUSTOM_FIELDS_TYPES.MULTISELECT:
                        this.showPickListEdit = true;
                        break;
                    case CUSTOM_FIELDS_TYPES.DB_TABLE:
                        this.showDbTableEdit = true;
                        break;
                    default:
                        this.showSingleLineEdit = true;
                        break;
                }
            },
            closePopup() {
                // delete drop placeholder
                if (this.editingField.parameters === null) {
                    this.sortedSection.fields.pop();
                } 

                this.editingField = {};
                
                this.showSingleLineEdit = false;
                this.showDbTableEdit = false;
                this.showPickListEdit = false;
                this.isSectionEditModalOpen = false;
            },
            createField(field) {
                // delete drop placeholder
                this.sortedSection.fields.pop();
                this.sortedSection.fields.push(field);
                this.lastOrder++;
                // close popup
                this.showSingleLineEdit = false;
                this.showDbTableEdit = false;
                this.showPickListEdit = false;
            },
            updateField(field) {
                let fieldIndex = this.sortedSection.fields.findIndex((f) => f.id === field.id);
                this.sortedSection.fields.splice(fieldIndex, 1, field);
                // close popup
                this.showSingleLineEdit = false;
                this.showDbTableEdit = false;
                this.showPickListEdit = false;
            },
            deleteField(field) {
                let fieldIndex = this.sortedSection.fields.findIndex((f) => f.id === field.id);
                this.sortedSection.fields.splice(fieldIndex, 1);
            },
            fieldDragStart(field, index) {                
                this.$store.commit('customFields/SET_DRAG_SOURCE', EXISTING_FIELD);
                this.$store.commit('customFields/SET_DRAGGING_FIELD',field);
                this.draggingFieldIndex = index;
            },
            fieldDragEnter(field, index) {
                if (this.sectionOverId !== field.section_id) {
                    this.$store.commit('customFields/SET_SECTION_OVER_ID', field.section_id);
                }
                
                if (field.id === this.draggingField.id || this.draggingField.section_id !== this.sortedSection.id) {
                    return
                }

                this.fieldOverCount++;
                this.fieldOverOrder = field.order;
                this.fieldOverIndex = index;
                
                this.sortedSection.fields.splice(this.draggingFieldIndex, 1)
                this.sortedSection.fields.splice(this.fieldOverIndex, 0, this.draggingField);

                this.draggingFieldIndex = index;
            },
            fieldDragEnd(item, index) {
                if (this.sectionOverId === item.section_id && this.fieldOverCount !== 0) {
                    const params = {
                        id: this.draggingField.id,
                        order: this.fieldOverOrder
                    }

                    api.post('/layouts/custom-fields/change-order', params)
                        .then(response => {
                            this.$emit('updateFields', response.data.data);
                            this.notifySuccess('Item order has been changed');
                        })
                }

                this.fieldOverCount = 0;
                this.fieldOverIndex = null;
                this.draggingFieldIndex = null;      
                this.$nextTick(function() {
                    this.$store.commit('customFields/SET_DRAG_SOURCE', '');
                    this.$store.commit('customFields/SET_DRAGGING_FIELD', {});
                }); 
            }
        }
    }
</script>
