<template>
    <svg
        viewBox="0 0 10 10"
        :width="size" 
        :height="size"
        class="font-small-icon"
    >
        <path d="M10 0H0v2.4h3.7V10h2.6V2.4H10z"/>
    </svg>
</template>

<script>
    export default {
        name: 'FontSmallIcon',
        props: {
            size: {
                type: [Number, String],
                default: 10
            }
        }
    }
</script>