<template>
    <div
        v-if="fieldTypeName === CUSTOM_FIELDS_TYPES.PICK_LIST || fieldTypeName === CUSTOM_FIELDS_TYPES.MULTISELECT"
        class="w-100"
        :id="getInputName()"
    >
        <multiselect
            :class="[{ 'multiselect-danger' : errors.has(getInputName()) }]"
            :options="field.parameters.properties"
            label="value"
            :placeholder="field.label"
            :showLabels="false"
            :max-height="150"
            :searchable="false"
            :multiple="fieldTypeName === CUSTOM_FIELDS_TYPES.MULTISELECT"
            :name="getInputName()"
            :data-name="getInputName()"
            :value="transformedValue"
            @select="selectHandler"
            @remove="removeHandler"
            v-validate="field.parameters.required ? 'required' : ''"
        />   
    </div>
    <form-datepicker 
        v-else-if="fieldTypeName === CUSTOM_FIELDS_TYPES.DATE"
        :value="value"
        @input="datePickerHandler"
        :isFullWidth="true"
        :placeholder="field.label"
        :name="getInputName()"
        keyDatePicker="input_name_date_picker"
        :id="getInputName()"
        :popoverDirection="index && index < 6 ? 'bottom' : 'top'"
        v-validate="getInputValidateRule()"
        :class="[{ 'form-datepicker-error' : errors.has(getInputName()) }]"
    />
    <form-date-time-input 
        v-else-if="fieldTypeName === CUSTOM_FIELDS_TYPES.DATE_TIME"
        :value="value"
        @input="datePickerHandler"
        :isFullWidth="true" 
        :placeholder="field.label"
        :popoverDirection="index && index < 6 ? 'bottom' : 'top'"
        :name="getInputName()"
        :id="getInputName()"
        v-validate="getInputValidateRule()"
        :class="[{ 'form-date-time-input-error' : errors.has(getInputName()) }]"
    />
    <div 
        v-else-if="fieldTypeName === CUSTOM_FIELDS_TYPES.CHECKBOX"
        class="s-align-4 p-8 h-100"
    >
        <checkbox-button :label="field.label">
            <input 
                :name="getInputName()"
                :id="getInputName()"
                :checked="(value === '' || value === 'false') ? false : true"
                @change="$emit('input', $event.target.checked)"
                v-validate="field.parameters.required ? 'required' : ''"
                type="checkbox"
            />
        </checkbox-button>
    </div>
    <form-input-textarea 
        v-else-if="fieldTypeName === CUSTOM_FIELDS_TYPES.MULTILINE"
        class="w-100"
        v-input-counter="1000"
    >
        <textarea
            :class="[
                'form-input-field',
                { 'form-input-textarea-danger' : errors.has(getInputName()) }
            ]"
            :name="getInputName()"
            :id="getInputName()"
            rows="1"
            :placeholder="field.label"
            maxlength="1000"
            v-resize-textarea
            v-validate="getInputValidateRule()"
            v-bind:value="value"
            v-on:input="$emit('input', $event.target.value)"
        />
    </form-input-textarea>
    <!-- show input for other fields -->
    <form-input
        v-else
        :label="field.label"
        :class="[{ 
            'form-input-danger' : errors.has(getInputName()) 
        }]"
    >
        <!-- values array always have only 1 value -->
        <input 
            v-if="getInputMask()"
            :type="getInputType()"
            :name="getInputName()"
            :id="getInputName()"
            class="form-input-field"
            :placeholder="field.label"
            :maxlength="field.parameters.max_chars"
            v-validate="getInputValidateRule()"
            v-input-style="value"
            v-bind:value="value"
            v-on:input="$emit('input', $event.target.value)"
            v-mask="getInputMask()"
        >
        <input 
            v-else
            :type="getInputType()"
            :name="getInputName()"
            :id="getInputName()"
            class="form-input-field"
            :placeholder="field.label"
            :maxlength="field.parameters.max_chars"
            v-validate="getInputValidateRule()"
            v-input-style="value"
            v-bind:value="value"
            v-on:input="$emit('input', $event.target.value)"
        >
    </form-input>
</template>

<script>
    import { FormInput, FormDatepicker, CheckboxButton, FormDateTimeInput, FormInputTextarea } from '@/ui';
    import Multiselect from 'vue-multiselect';
    import { mask } from 'vue-the-mask';
    import snakeCase from 'lodash/snakeCase';
    import { CUSTOM_FIELDS_TYPES } from '@/constants.js';

    export default {
        name: 'CustomFieldInput',
        components: {
            FormInput,
            FormDatepicker,
            CheckboxButton,
            FormDateTimeInput,
            Multiselect,
            FormInputTextarea
        },
        props: {
            value: {
                required: true
            },
            field: {
                type: Object,
                required: true
            },
            index: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                CUSTOM_FIELDS_TYPES: CUSTOM_FIELDS_TYPES
            }
        },
        computed: {
            fieldTypeName() {
                return this.field.type.name;
            },
            transformedValue() {
                switch(this.fieldTypeName) {
                    // transform value from string to array to work with multiselect
                    case this.CUSTOM_FIELDS_TYPES.MULTISELECT:
                        if (this.value !== '') {
                            return this.field.parameters.properties.filter(field => {
                                return this.value.split(', ').some(value => value === field.id)
                            });
                        }
                        return [];
                        break;
                    default:
                        return this.field.parameters.properties.filter(field => field.id === this.value);
                        break;
                }
            }
        },
        directives: {
            mask
        },
        inject: ['$validator'],
        methods: {
            // get input name/id in snack_case
            getInputName() {
                return `custom_field_${snakeCase(this.field.label)}_${this.field.id}`;
            },
            // get <input type="" />
            getInputType() {
                switch(this.fieldTypeName) {
                    case this.CUSTOM_FIELDS_TYPES.EMAIL:
                        return 'email';
                        break;
                    case this.CUSTOM_FIELDS_TYPES.PHONE:
                        return 'tel';
                        break;
                    case this.CUSTOM_FIELDS_TYPES.URL:
                        return 'url';
                        break;
                    default:
                        return 'text';
                        break;
                } 
            },
            // mask for directive vue-the-mask
            getInputMask() {
                switch(this.fieldTypeName) {
                    case this.CUSTOM_FIELDS_TYPES.PHONE:
                        return '(###) ###-####';
                        break;
                    default:
                        return false;
                        break;
                }
            },
            // validation rule for vee-validate
            getInputValidateRule() {
                let rule;

                // set max chars
                rule = this.field.parameters.max_chars ? `max:${this.field.parameters.max_chars - 1}` : '';
                // set if field is required
                rule += this.field.parameters.required ? '|required' : '';
                // set url rule
                rule += this.fieldTypeId === this.CUSTOM_FIELDS_TYPES.URL ? '|url' : '';

                return rule;
            },
            selectHandler(selectedOption) {
                switch(this.fieldTypeName) {
                    case this.CUSTOM_FIELDS_TYPES.MULTISELECT:
                        const value = [
                            ...this.transformedValue.map(i => i.id), 
                            selectedOption.id
                        ].join(', ');
                        this.$emit('input', value);
                        break;
                    default:
                        this.$emit('input', selectedOption.id);
                        break;
                }
            },
            removeHandler(removedOption) {
                switch(this.fieldTypeName) {
                    case this.CUSTOM_FIELDS_TYPES.MULTISELECT:
                        const value = this.transformedValue.filter(value => value.id !== removedOption.id)
                            .map(item => item.id)
                            .join(', ');
                        this.$emit('input', value);
                        break;
                    default:
                        return;
                        break;
                }
            },
            datePickerHandler(selectedDate) {
                this.$emit('input', selectedDate)
            }
        }
    }
</script>