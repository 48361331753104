<template>
    <div class="w-100">
        <div :class="['lead-search w-100 mb-20 relative', { 'lead-search-disabled' : disabled }]">
            <div class="row-between h-100 ph-6">
                <button 
                    class="icon-light"
                    @click="getResult"
                    :disabled="disabled"
                >
                    <search-icon size="12"/>
                </button>
                <input 
                    type="text" 
                    name="search_in_leads"
                    v-model="needToFind"
                    placeholder="Search property"
                    @keyup.enter="getResult"
                    ref="search_in_leads"
                    :disabled="disabled"
                />
                <button 
                    class="icon-light"
                    @click="resetSearch(RESET_ALL)"
                    :disabled="disabled"
                >
                    <close-icon size="10"/>
                </button>
            </div>
            <div 
                class="row-center lead-searching"
                v-if="searching"
            >
                <loading class="i-blue"/>
            </div>
            <perfect-scrollbar 
                class="lead-search-results w-100 ps-blue"
                v-if="open"
            >
                <p 
                    class="f-10-grey f-sbold pt-7 pb-2 pl-4 t-left"
                    v-if="representativesLength"
                >
                    {{ representativesLength > 1 ? 'Representatives' : 'Representative' }}
                </p>
                <div 
                    class="s-align-4 w-100 lead-search-item pointer"
                    v-for="(item, index) in representatives"
                    :key="`representative-${index}`"
                    @click="serachRepresentatives(item)"
                >
                    <avatar
                        :url="null"
                        :name="`${item.first_name} ${item.last_name}`"
                        size="25"
                        :border="false"
                    />
                    <span class="f-12-darkgrey f-sbold pl-3">{{ `${item.first_name} ${item.last_name}` }}</span>
                </div>
                <p 
                    class="f-10-grey f-sbold pt-5 pb-2 pl-4 t-left"
                    v-if="resultsLength"
                >
                    {{ resultsLength }} {{ resultsLength > 1 ? 'results' : 'result'}} by 
                    <span class="f-sbold capitalize">"{{ searchName }}"</span>
                </p>
                <ul>
                    <li 
                        v-for="(item, index) in customers"
                        :key="`customer-${index}`"
                        class="lead-search-item s-align-4 pointer"
                        @click="goToCustomerPage(item)"
                    >
                        <span class="mr-1 f-10-white f-sbold search-tag-company">Company</span>
                        <span class="f-12-asphalt f-sbold">{{ item.name }}</span>
                    </li>
                    <li 
                        v-for="(item, index) in properties"
                        :key="`property-${index}`"
                        class="lead-search-item s-align-4 pointer"
                        @click="goToPropertyPage(item)"
                    >
                        <span class="mr-1 f-10-white f-sbold search-tag-property">Property</span>
                        <span class="f-12-asphalt f-sbold">{{ item.property_name }}</span>
                    </li>
                    <li 
                        v-for="(item, index) in units"
                        :key="`unit-${index}`"
                        class="lead-search-item s-align-4 pointer"
                        @click="goToUnitPage(item)"
                    >
                        <span class="mr-1 f-10-white f-sbold search-tag-unit">Unit</span>
                        <span class="f-12-asphalt f-sbold">{{ item.name }}</span>
                    </li>
                </ul>
            </perfect-scrollbar>
        </div>
        <div 
            class="row-start mb-30"
            v-if="nothingWasFound"
        >
            <div class="static-col-1">
                <button 
                    class="cancel-icon"
                    @click="resetSearch(RESET_ALL)"
                >
                    <close-icon size="9"/>
                </button>
            </div>
            <div class="column-start static-col-11 pt-1">
                <p class="f-14-asphalt f-sbold">Seems there is nothing listed under this name.</p>
                <span class="f-10-grey">Try using a different name or create a new job.</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { SearchIcon, CloseIcon, Loading } from '@/icons';
    import Avatar from '@/components/User/Avatar.vue';
    import api from '@/headers.js';

    const RESET_ALL = 'reset';
    const RESET_WITH_NEW_SEARCH = 'search';

    export default {
        name: 'LeadSearch',
        components: {
            SearchIcon,
            CloseIcon,
            Avatar,
            Loading
        },
        props: {
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                needToFind: '',
                searching: false,
                nothingWasFound: false,
                open: false,
                searchName: '',
                RESET_ALL: RESET_ALL,
                representatives: [],
                customers: [],
                properties: [],
                units: []
            }
        },
        computed: {
            resultsLength() {
                return this.customers.length + this.properties.length + this.units.length;
            },
            representativesLength() {
                return this.representatives.length;
            }
        },
        methods: {
            getResult() {
                if (this.needToFind.length < 3) {
                    this.notifyError('Please enter more than 3 symbol.');

                    return false;
                }

                this.searching = true;
                this.resetSearch(RESET_WITH_NEW_SEARCH);
                this.searchName = this.needToFind;

                api.get(`/commercial/search?value=${this.needToFind}`)
                    .then(response => response.data.data)
                    .then(data => {
                        this.nothingWasFound = false;

                        if (!data.representative.length && !data.customer.length && !data.property.length && !data.unit.length) {
                            this.nothingWasFound = true;
                        } else {
                            this.representatives = data.representative;
                            this.customers = data.customer;
                            this.properties = data.property;
                            this.units = data.unit;

                            this.open = true;
                        }
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.searching = false;
                    });
            },
            serachRepresentatives(representative) {
                this.needToFind = `${representative.first_name} ${representative.last_name}`;
                this.getResult();
            },
            goToCustomerPage(item) {
                this.$router.push({
                    name: 'CommercialCustomer',
                    params: { customer_id: item.id }
                });
            },
            goToPropertyPage(item) {
                this.$emit('setPropertyId',item.id);
            },
            goToUnitPage(item) {
                this.$router.push({
                    name: 'CommercialProperty',
                    params: { customer_id: item.customer_id },
                    query: { property: item.property_id, unit: item.id }
                });
            },
            resetSearch(type) {
                if (type == RESET_ALL) {
                    this.needToFind = '';
                }

                this.open = false;
                this.searchName = '';
                this.$refs.search_in_leads.focus();
                this.nothingWasFound = false;
            }
        }
    }
</script>