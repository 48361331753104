import { GET_REPORT_PHOTO, GET_TECHNICIAN_INFO, GET_COUNT_ATTACHMENT } from '../actions/formBuilder';
import api from '@/headers.js';

export default {
    namespaced: true,
    state: {
        photos: [],
        technicianData: {},
        countAttachment: 0
    },
    getters: {
        getFormBuilderPhoto: state => state.photos,
        getTechnicianData: state => state.technicianData,
        getTechnicianIsPush: state => state.technicianData ? state.technicianData.is_push : false,
        getCountAttachment: state => state.countAttachment
    },
    mutations: {
        [GET_COUNT_ATTACHMENT] (state, payload) {
            state.countAttachment = payload;
        },
        [GET_REPORT_PHOTO] (state, payload) {
            if (payload != undefined) {
                payload.forEach((obj) => {
                    obj.url = obj.url + 'normal' + obj.path;
                });

                state.photos = payload;
            }
        },
        [GET_TECHNICIAN_INFO] (state, payload) {
            state.technicianData = payload;
        }
    },
    actions: {
        [GET_REPORT_PHOTO]({ commit, dispatch }, entity) {
            return new Promise((resolve, reject) => {
                const params = {
                    entityId: entity.entityId,
                    entityType: entity.entityType
                };

                api.get('/check-photos', { params })
                    .then(response => {
                        commit(GET_REPORT_PHOTO, response.data.data);
                        resolve(response.data.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        [GET_TECHNICIAN_INFO]({commit}, entity) {
            return new Promise((resolve, reject) => {
                const params = {
                    entity_id: entity.entityId,
                    entity_type: entity.entityType
                };

                api.get('/technicians/draft-report/get-by-entity', { params })
                    .then(response => {
                        commit(GET_TECHNICIAN_INFO, response.data.data);
                        resolve(response.data.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        }
    }
}
