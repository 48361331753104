<template>
    <full-modal @close="$emit('close')">
        <div slot="title">
            {{ 
                `${editingField.type ? editingField.type.label : editingField.label} properties` 
            }}
        </div>
        <div slot="body">
            <p class="f-14-darkgrey f-sbold mb-3">Field label</p>
            <form-input 
                label="Field label"
                :class="[
                    'mb-25',
                    {'form-input-danger': errors.first('single_line_name')}
                ]"
            >
                <input 
                    class="form-input-field"
                    name="single_line_name"
                    type="text"
                    placeholder="Field label"
                    v-model="formData.label"
                    v-input-style="formData.label"
                    :maxlength="labelMaxLength"
                    v-validate="labelValidationRule"
                >
            </form-input>
            <template v-if="isCharactersFieldShow">
                <p class="f-14-darkgrey f-sbold mb-3">Number of characters</p>
                <form-input 
                    label="Number of characters"
                    :class="[
                        'mb-25',
                        {'form-input-danger': errors.first('custom-field-max-chars')}
                    ]"
                >
                    <input 
                        class="form-input-field"
                        name="custom-field-max-chars"
                        type="number"
                        placeholder="Number of characters"
                        v-model="parameters.max_chars"
                        v-input-style="parameters.max_chars"
                        v-validate="'required|between:3,255'"
                    >
                </form-input>
            </template>
            <checkbox-button 
                v-if="notCheckbox(editingField) && !fieldInPrimarySection"
                class="w-100 mb-8"
                label="Required"
                color="dark-blue"
            >
                <input 
                    name="required"
                    v-model="parameters.required"
                    type="checkbox"
                />
            </checkbox-button>
            <checkbox-button
                class="w-100" 
                label="Show Tooltip"
                color="dark-blue"
            >
                <input 
                    name="show_tooltip"
                    v-model="parameters.show_tooltip"
                    type="checkbox"
                />
            </checkbox-button>
            <form-input 
                v-if="parameters.show_tooltip"
                label="Enter help text here"
                :class="[
                    'mt-6',
                    {'form-input-danger': errors.first('custom-field-tooltip-text')}
                ]"
            >
                <input 
                    class="form-input-field"
                    name="custom-field-tooltip-text"
                    type="text"
                    placeholder="Enter help text here"
                    v-model="parameters.tooltip_text"
                    v-input-style="parameters.tooltip_text"
                    maxlength="100"
                    v-validate="'required|min:3|max:99'"
                >
            </form-input>
            <checkbox-button
                v-if="!hidePhoneExtension"
                class="w-100 mt-8" 
                label="Extension"
                color="dark-blue"
            >
                <input 
                    name="show_extension"
                    v-model="parameters.extension"
                    type="checkbox"
                />
            </checkbox-button>
        </div>
        <div slot="footer">
            <button 
                class="add-button mr-4"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                @click="updateField"
                :disabled="requestIsGoing"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import api from '@/headers.js';
    import { FormInput, CheckboxButton } from '@/ui';
    import FullModal from '@/ui/Modal/FullModal.vue';
    import { CUSTOM_FIELDS_TYPES } from '@/constants.js';

    // Define on which types hide 'Number of characters' field
    const hidenCharactersField = [
        CUSTOM_FIELDS_TYPES.DATE,
        CUSTOM_FIELDS_TYPES.DATE_TIME, 
        CUSTOM_FIELDS_TYPES.PHONE,
        CUSTOM_FIELDS_TYPES.CHECKBOX
    ];

    export default {
        name: 'CustomFieldsSingleLineModal',
        components: {
            FormInput, CheckboxButton, FullModal
        },
        props: {
            editingField: {
                type: Object,
                required: true
            },
            sectionId: {
                type: [String, Number],
                required: true
            },
            order: {
                type: [String, Number],
                required: false
            },
            fieldInPrimarySection: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            isCharactersFieldShow() {
                if (this.isEdit) {
                    return !hidenCharactersField.some(id => id === this.editingField.type.name)
                }

                return !hidenCharactersField.some(id => id === this.editingField.name)
            },
            labelMaxLength() {
                if (this.isEdit) {
                    switch (this.editingField.type.name) {
                        case CUSTOM_FIELDS_TYPES.DATE_TIME:
                            return 13;
                            break;
                        default: 
                            return 31;
                            break;
                    }  
                }

                switch (this.editingField.name) {
                    case CUSTOM_FIELDS_TYPES.DATE_TIME:
                        return 13;
                        break;
                    default: 
                        return 31;
                        break;
                }  
            },
            labelValidationRule() {
                return `required|min:3|max:${this.labelMaxLength - 1}`;
            },
            hidePhoneExtension() {
                if (this.isEdit) {
                    return this.editingField.type.name !== CUSTOM_FIELDS_TYPES.PHONE;
                }

                return this.editingField.name !== CUSTOM_FIELDS_TYPES.PHONE;
            }
        },
        data() {
            return {
                isEdit: false,
                formData: {
                    label: '',
                    section_id: this.sectionId,
                    type_id: this.editingField.id,
                    order: null
                },
                parameters: {
                    max_chars: 30,
                    required: false,
                    show_tooltip: false,
                    tooltip_text: '',
                    extension: false
                },
                requestIsGoing: false
            }
        },
        created() {
            if (this.editingField.parameters) {
                this.formData.label = this.editingField.label;
                this.formData.id = this.editingField.id,
                this.formData.section_id = this.editingField.section_id;
                this.formData.type_id = this.editingField.type.id;
                this.formData.order = this.editingField.order;
                this.parameters = JSON.parse(this.editingField.parameters);
                this.isEdit = true;
            }

            this.formData.order = this.order;
        },
        methods: {
            updateField() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('Please fill out all required fields!');

                        return;
                    }

                    this.requestIsGoing = true;
                
                    let method = this.isEdit ? 'put' : 'post';
                    let action = this.isEdit ? 'updated' : 'created'
                    let params = this.formData;
                    params.parameters = JSON.stringify(this.parameters);

                    api[method]('/layouts/custom-fields', params)
                        .then((response) => {
                            this.notifySuccess(`Custom field has been ${action}.`);

                            if (this.isEdit) {
                                this.$emit('updateField', response.data.data)
                            } else {
                                this.$emit('createField', response.data.data);
                            }
                        })
                        .catch(error => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {                                
                            this.requestIsGoing = false;
                        });
                    });
            },
            notCheckbox(field) {
                if (field.type) {
                    return field.type.name != CUSTOM_FIELDS_TYPES.CHECKBOX; 
                } else {
                    return field.name != CUSTOM_FIELDS_TYPES.CHECKBOX; 
                }
            }
        }
    }
</script>

