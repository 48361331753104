<template>
    <svg 
        viewBox="0 0 320.648 320.648"
        :width="size" 
        :height="size"
        class="percent-icon"
    >
        <g>
            <g>
                <g>
                    <path d="M139.219,124.494c12.369-13.416,19.184-31.412,19.184-50.678C158.403,37.134,132.202,0,82.116,0
                        C58.807,0,39.143,8.006,25.247,23.152C12.938,36.57,6.161,54.562,6.161,73.816c0,36.686,26.09,73.818,75.955,73.818
                        C105.512,147.635,125.262,139.635,139.219,124.494z M82.116,34.322c27.443,0,39.941,20.486,39.941,39.52
                        c0,10.354-3.484,19.939-9.816,26.986c-7.064,7.871-17.33,12.033-29.68,12.033c-28.137,0-40.955-20.484-40.955-39.516
                        C41.606,53.924,54.134,34.322,82.116,34.322z"/>
                    <path d="M264.274,28.476c-4.539,0.082-10.736,2.912-13.772,6.287L25.186,285.275c-3.035,3.377-0.885,8.111,3.654,8.111h29.49
                        c4.539,0,10.73-2.768,13.76-6.148L296.013,36.777c3.029-3.383,2.828-8.887-4.672-8.885L264.274,28.476z"/>
                    <path d="M239.647,175.822c-22.859,0-42.15,7.858-55.783,22.715c-12.074,13.158-18.726,30.811-18.726,49.699
                        c0,35.984,25.594,72.412,74.51,72.412c22.957,0,42.326-7.85,56.02-22.701c12.135-13.162,18.818-30.816,18.818-49.711
                        C314.485,212.252,288.78,175.822,239.647,175.822z M240.089,287.418c-27.375,0-39.848-20.557-39.848-39.648
                        c0-10.397,3.482-20.018,9.809-27.092c7.053-7.894,17.287-12.066,29.598-12.066c27.377,0,39.844,20.553,39.844,39.65
                        c0,10.395-3.483,20.018-9.805,27.092C262.632,283.246,252.397,287.418,240.089,287.418z"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'PercentIcon',
        props: {
            size: {
                type: [Number, String],
                default: 12
            }
        }
    }
</script>