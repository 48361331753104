<template>
    <svg 
	    :width="size" 
        :height="size" 
        viewBox="0 0 359.509033203125 358.4375305175781"
        class="notes-icon"
    >
        <g transform="matrix(7.840000152587891, 0, 0, 7.840000152587891, 3149.976119995117, -1921.99951171875)">
            <path d="M-365.4,245.5h-27.2c-4.9,0-8.9,4-8.9,8.9v17.3c0,4.9,4,8.9,8.9,8.9h0.9v8.4c0,0.6,0.3,1.1,0.9,1.4c0.2,0.1,0.4,0.1,0.6,0.1c0.4,0,0.7-0.1,1-0.4l10.8-9.6h13.1c4.9,0,8.9-4,8.9-8.9v-17.2C-356.5,249.5-360.5,245.5-365.4,245.5z M-359.5,271.6c0,3.2-2.6,5.9-5.9,5.9H-379c-0.4,0-0.7,0.1-1,0.4l-8.8,7.8V279c0-0.8-0.7-1.5-1.5-1.5h-2.4c-3.2,0-5.9-2.6-5.9-5.9v-17.2c0-3.2,2.6-5.9,5.9-5.9h27.3c3.2,0,5.9,2.6,5.9,5.9V271.6z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'NotesIcon',
        props: {
            size: {
                type: [Number, String],
                default: 26
            }
        }
    }
</script>