<template>
    <div
        v-show="isVisible"
        class="w-100 pb-8"
    >
        <div 
            v-if="Array.isArray(body.data)"
            class="column-start ph-8"
        >
            <div 
                v-if="dScreen"
                class="report-table-row report-table-head row-start"
            >
                <div
                    v-for="(item, index) in head"
                    :key="index"
                    class="report-table-cell f-10-grey f-sbold uppercase"
                    :style="{
                        width: dScreen ? `calc(100%/${columnsCount})` : '',
                        padding: columnsCount > 10 && dScreen ? '20px 3px' : ''
                    }"
                    v-show="disableEntityTypeColumn(item)"
                >
                    {{ item }}
                </div>
            </div>
            <div 
                v-for="(row, index) in body.data"
                :key="index"
                :class="[
                    'report-table-row row-start',
                    openedTableIndex === index ? 'row-open' : ''
                ]"
            >
                <router-link
                    :to="{ 
                        name: routeNameFromReport(row),
                        params: routeParamsFromReport(row)
                    }"
                    target="_blank"
                    class="row-start"
                >
                    <button 
                        class="report-table-toggle icon-light"
                        @click="openedTableIndex = openedTableIndex == index ? null : index"
                    >
                        <arrow-icon :transform="openedTableIndex === index ? 270 : 90"/>
                    </button>
                    <div 
                        v-for="(cell, key, index) in row"
                        :key="key"
                        class="report-table-cell column-start"
                        :style="{
                            width: dScreen ? `calc(100%/${columnsCount})` : '',
                            padding: columnsCount > 10 && dScreen ? '20px 3px' : ''
                        }"
                        v-show="checkReportSystemColumns(key)"
                    >
                        <span 
                            v-if="key === REPORT_COLUMNS.SOURCE.excerpt"
                            class="report-lead-source f-12-white"
                        >   
                            {{ cell || '-' }}
                        </span>
                        <span
                            v-else-if="key === REPORT_COLUMNS.PROFIT_MARGIN.excerpt"
                            class="cell-value f-14-black"
                        >
                            {{ cell }}%
                        </span>
                        <span
                            v-else-if="key === REPORT_COLUMNS.BASE_BID.excerpt
                                || key === REPORT_COLUMNS.BASE_BID_COST.excerpt
                                || key === REPORT_COLUMNS.ESTIMATES_SIGNED_PRICE_TOTAL.excerpt
                                || key === REPORT_COLUMNS.EXPECTED_REVENUE.excerpt
                                || key === REPORT_COLUMNS.NET_PROFIT.excerpt"
                            class="cell-value f-14-black"
                        >
                            ${{ cell | numberWithCommas }}
                        </span>
                        <span 
                            v-else-if="key === REPORT_COLUMNS.PROPOSAL_DELIVERED.excerpt"
                            class="cell-value f-14-black"
                        >   
                            {{ cell || '-' | date_ignore_tz }}
                        </span>
                        <span 
                            v-else-if="dateTimeReportFields(key)"
                            class="cell-value f-14-black"
                        >   
                            {{ cell || '-' | considerTimeZone }}
                        </span>
                        <span 
                            v-else
                            class="cell-value f-14-black"
                        >
                            {{ cell || '-' }}
                        </span>
                        <span class="cell-desc f-12-grey">{{ head[index] }}</span>
                    </div>
                </router-link>
            </div>
        </div>
        <div
            v-else
            v-for="(item, key) in body.data"
            :key="key"
            class="column-start ph-8 pt-8"
        >
            <div 
                :class="[
                    'table-group f-sbold pointer',
                    `table-group-depth-${depth}`,
                    depth > 1 ? 'row-start row-baseline' : 'row-between'
                ]"
                @click="groupVisibilityStates[key] = !groupVisibilityStates[key]"
            >
                <div 
                    :class="[
                        'lg-6',
                        depth > 1 ? 'row-start row-baseline' : 'row-between',
                    ]"
                >
                    <div class="row-start row-baseline">
                        <button
                            class="transparent-button p-1 mr-2"
                        >
                            <arrow-icon
                                :transform="groupVisibilityStates[key] ? 270 : 90"
                            />
                        </button>
                        <span>{{ key | renameEntityTypeGrouping}}</span>
                        <span class="ml-5" v-if="item.cost">Cost per lead/job = $ {{ item.cost }}</span>
                        <span 
                            v-if="depth > 1"
                            class="table-group-count f-sbold s-align-5 ml-3"
                        >
                            {{ item.count }}
                        </span>
                    </div>
                </div>
                <span 
                    v-if="depth == 1" 
                    :class="[
                        'table-group-count f-sbold s-align-5 ml-3 lg-6',
                        groupVisibilityStates[key] ? 'active' : ''
                    ]"
                >
                    {{ item.count }}
                </span>
                <div
                    v-if="item.hasOwnProperty('totals')"
                    class="lg-6 pt-sm-0"
                >
                    <div
                        v-for="(total, index) in item.totals"
                        :key="index"
                        :class="[ 
                            index > 1 ? 'mb-2' : '',
                            dScreen ? 'row-end' : 'row-start' 
                        ]"
                    >
                        <span class="f-13-grey mr-2">{{ total.column_title }}:</span>
                        <span class="f-13-black f-sbold">{{ total.value | currency }}</span>
                    </div>
                </div>
            </div>
            <report-table 
                :head="head"
                :body="item"
                :depth="depth + 1"
                :isVisible="groupVisibilityStates[key]"
            />
        </div>
    </div>
</template>

<script>
    import { ArrowIcon } from '@/icons';
    import { REPORT_COLUMNS, REPORT_SYSTEM_COLUMNS, 
            BASE_ENTITY_TYPES, MODEL_CLASSES } from '@/constants.js';

    const EXCLUDED_COLUMN = {
        MILESTONE_COLOR: 'milestone_color'
    };

    export default {
        name: 'ReportTable',
        components: {
            ArrowIcon
        },
        props: {
            head: {
                type: Array,
                required: true
            },
            body: {
                required: true
            },
            depth: {
                type: Number,
                default: 1
            },
            isVisible: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                isOpen: false,
                openedTableIndex: null,
                groupVisibilityStates: {},
                REPORT_COLUMNS: REPORT_COLUMNS
            }
        },
        computed: {
            columnsCount() {
                if (this.head.includes('Entity Type')) {
                    return this.head.length - 1;
                } else {
                    return this.head.length;
                }
            },
            showSystemColumns() {
                if (this.$store.getters['reports/getReportData']) {
                    return this.$store.getters['reports/getReportData'].showSystemColumns;
                }
            }
        },
        methods: {
            checkReportSystemColumns(tableRow) {
                return tableRow !== REPORT_SYSTEM_COLUMNS.ID 
                        && tableRow !== REPORT_SYSTEM_COLUMNS.CREATED_AT
                        && tableRow !== REPORT_SYSTEM_COLUMNS.ENTITY_TYPE
                        && tableRow !== REPORT_SYSTEM_COLUMNS.PROPERTY_ID
                        && tableRow !== REPORT_COLUMNS.CLASS_NAMESPACE.excerpt
                        && tableRow !== EXCLUDED_COLUMN.MILESTONE_COLOR
            },
            routeNameFromReport(tableRow) {
                switch (tableRow[REPORT_SYSTEM_COLUMNS.ENTITY_TYPE]) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL:
                        return 'ResidentialJobDetails';
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        return 'CommercialJobOverview';
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                        return 'CommercialLeadOverview';
                    case BASE_ENTITY_TYPES.WARRANTY_JOB:
                        return 'WarrantyJobDetails';
                    case BASE_ENTITY_TYPES.WARRANTY_CASE:
                        return 'WarrantyCases'
                    default:
                        return '';
                }
            },
            routeParamsFromReport(tableRow) {
                switch (tableRow[REPORT_SYSTEM_COLUMNS.ENTITY_TYPE]) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL:
                        return { lead_id: tableRow.id };
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        return {
                            property_id: tableRow.property_id,
                            job_id: tableRow.id
                        };
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                        return {
                            lead_id: tableRow.id
                        };
                    case BASE_ENTITY_TYPES.WARRANTY_JOB:
                        return {
                            job_id: tableRow.id
                        };
                    default:
                        return '';
                }
            },
            dateTimeReportFields(field) {
                switch (field) {
                    case REPORT_COLUMNS.CREATED_TIME.excerpt:
                    case REPORT_COLUMNS.BID_DUE_DATE.excerpt:
                    case REPORT_COLUMNS.BID_DATE.excerpt:
                    case REPORT_COLUMNS.PROPOSAL_DELIVERED.excerpt:
                    case REPORT_COLUMNS.JOB_START_DATE.excerpt:
                    case REPORT_COLUMNS.JOB_COMPLETE_DATE.excerpt:
                    case REPORT_COLUMNS.EVENT_DATE_FOR_TECHNICIAN.excerpt:
                        return true;
                    default:
                        return false;
                }
            },
            disableEntityTypeColumn(field) {
                return field !== 'Entity Type';
            }
        },
        filters: {
            renameEntityTypeGrouping(val) {
                switch (val) {
                    case MODEL_CLASSES.RESIDENTIAL_LEAD:
                        return 'Residential';
                    case MODEL_CLASSES.COMMERCIAL_LEAD:
                        return 'Commercial Lead';
                    case MODEL_CLASSES.COMMERCIAL_JOB.JOB:
                        return 'Commercial Job';
                    default:
                        return val;
                }
            }
        },
        created() {
            if (!Array.isArray(this.body.data)) {
                Object.entries(this.body.data).forEach((item, key) => {
                    // create open/close state for group by key
                    // by default group opened
                    this.$set(this.groupVisibilityStates, item[0], true)
                })
            }
        }
    }
</script>