<template>
    <div 
        class="board-card w-100"
        @dragstart="$emit('dragstart')"
        @dragenter="$emit('dragenter')"
        @dragend="$emit('dragend')"
        @drop="$emit('drop')"
    >
        <template v-if="!archiveCard">
            <button
                v-if="!columnIsArchive"
                v-can:some="[$permissions.DELETE_CARDS, $permissions.EDIT_CARDS]"
                class="card-context-menu-icon icon-light"
                @click.stop="$emit('showCardContextMenu', card.id)"
            >
                <points-icon width="5" height="15"/>
            </button>
            <div 
                class="card-context-menu context-menu"
                v-if="cardContextMenuId === card.id"
            >
                <button
                    v-can="[$permissions.DELETE_CARDS]"
                    v-if="card.is_archive" 
                    class="delete-field s-align-4"
                    @click.stop="$emit('showDeleteCardConfirm', card, columnIndex, cardIndex)"
                >
                    <span class="icon-container mr-6">
                        <trash-icon class="mr-8"/>
                    </span>
                    <span>Delete Card</span>
                </button>
                <button
                    v-else
                    v-can="[$permissions.EDIT_CARDS]"
                    class="delete-field s-align-4"
                    @click.stop="$emit('showArchiveCardConfirm', card, columnIndex, cardIndex)"
                >
                    <span class="icon-container mr-6">
                        <archive-icon class="mr-8"/>
                    </span>
                    <span>Archive Card</span>
                </button>
            </div>
        </template>
        <div 
            v-if="card.date_of_completion || archiveCard"
            :class="[
                'row-baseline mb-2',
                archiveCard ? 'row-end' : 'row-start'
            ]"
        >
            <div
                v-if="card.date_of_completion" 
                class="w-50"
            >
                <clock-icon 
                    class="i-grey mr-1"
                    size="11"
                />
                <span class="f-11-grey mr-1">Due</span>
                <span class="f-11-grey">{{ card.date_of_completion | date }}</span>
            </div>
            <div
                v-if="archiveCard"
                class="w-50 row-end"
            >
                <button 
                    class="unarchive-btn transparent-button s-align-4"
                    @click.stop="$emit('restoreCard')"
                    :disabled="requestIsGoing"
                >
                    <next-icon 
                        transform="180"
                        width="9"
                        height="7"
                        class="i-blue mr-2"
                    />
                    <span class="f-11-blue">Unarchive</span>
                </button>
                <button 
                    class="delete-btn transparent-button ml-1 s-align-5"
                    @click.stop="$emit('showDeleteCardConfirm')"
                    :disabled="requestIsGoing"
                >
                    <trash-icon
                        width="16"
                        height="12"
                        class="i-grey"
                    />
                </button>
            </div>
        </div>
        <p class="card-title f-14-black f-sbold mb-3">
            {{ card.title }}
        </p>
        <div 
            v-if="card.tags.length"
            class="card-tags row-start mb-4"
        >
            <span 
                v-for="(tag, index) in card.tags"
                :key="index"
                :class="['card-tag', `card-tag-${tag.color}`]"
            >
                <p 
                    v-if="isTagsNameShow"
                    class="p-2"
                >
                    {{ tag.name }}
                </p>
            </span>
            <!-- also available card-tag-blue, card-tag-green, card-tag-violet, card-tag-red classes -->
        </div>
        <div class="card-footer row-between row-baseline">
            <div class="card-badges">
                <div :class="['badge', {'badge-highlight' : card.count_comments}]">
                    <notes-icon size="14"/>
                    <span>{{ card.count_comments}}</span>
                </div>
                <div :class="['badge', {'badge-highlight' : card.count_files}]">
                    <attach-icon size="13"/>
                    <span>{{ card.count_files }}</span>
                </div>
                <div
                    :class="[
                        'badge',
                        { 'badge-completed' : 
                            card.count_todo_completed === card.count_todo_total
                            && card.count_todo_total !== 0
                        }
                    ]"
                >
                    <checkbox-icon size="14"/>
                    <span>{{ `${card.count_todo_completed}/${card.count_todo_total}` }}</span>
                </div>
            </div>
            <avatar
                v-if="card.designated_user"
                class="card-avatar"
                :url="card.designated_user.thumbnail"
                :name="`${card.designated_user.first_name} ${card.designated_user.last_name}`"
                :border="false"
                size="30"
            />
        </div>
    </div>
</template>

<script>
    import { 
        PointsIcon, NotesIcon, AttachIcon, CheckboxIcon, ArchiveIcon, ClockIcon, NextIcon,
        TrashIcon
    } from '@/icons';
    import { Avatar } from '@/components';

    export default {
        name: 'ProjectManagementCardPreview',
        components: {
            PointsIcon,
            NotesIcon,
            AttachIcon,
            CheckboxIcon,
            ArchiveIcon,
            ClockIcon,
            NextIcon,
            TrashIcon,
            Avatar
        },
        props: {
            card: {
                type: Object,
                required: true
            },
            cardIndex: {
                type: Number,
                required: true
            },
            columnIndex: {
                type: Number,
                required: false
            },
            isTagsNameShow: {
                type: Boolean,
                default: false
            },
            cardContextMenuId: {
                type: Number, 
                required: false
            },
            archiveCard: {
                type: Boolean,
                default: false
            },
            requestIsGoing: {
                type: Boolean,
                default: false
            },
            columnIsArchive: {
                type: Boolean,
                default: false
            }
        }
    }
</script>