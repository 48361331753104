<template>
    <modal
        @close="$emit('close')"
        class="section-delete-confirm"
    >
        <div slot="modal-body">
            <div class="w-100 column-center pb-4">
                <span class="f-14-black pb-2">
                    Are you sure want to archive '{{ title }}' column?
                </span>
            </div>
        </div>
        <div slot="modal-footer">
            <div class="row-between">
                <button 
                    class="add-button"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button 
                    class="primary-button mr-4"
                    @click="archiveColumn"
                    :disabled="requestIsGoing"
                >
                    Archive
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
    import api from '@/headers.js';
    import { Modal } from '@/ui';

    export default {
        name: 'ProjectManagementArchiveColumnModal',
        components: {
            Modal
        },
        props: {
            id: {
                type: [Number, String],
                required: true
            },
            title: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                requestIsGoing: false
            }
        },
        methods: {
            archiveColumn() {
                this.requestIsGoing = true;
                api.delete(`/kanban/columns/${this.id}`)
                    .then(response => response.data.data)
                    .then(data => {
                        if (data) {
                            this.$emit('onArchiveColumn');
                            this.notifySuccess('Column archived!');
                        }
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.requestIsGoing = false;
                        // close modal after delete
                        this.$emit('close');
                    });
            },
        }
    }
</script>