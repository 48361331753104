import api from '@/headers.js';
import moment from 'moment/src/moment';
import { CALENDAR_TYPES } from '@/constants.js';
import store from '@/store/store';
import { result } from 'lodash';

export const userCalendar = {
    data() {
        return {
            isLoading: false,
            startOfPeriod: '',
            endOfPeriod: '',
            currentPeriod: [],
            events: [], // for user calendar
            calendars: [], // for technician calendar
            date: {},
            iterationEvents: 0
        }
    },
    computed: {
        isWeekStartOnMonday() {
            return this.$store.getters['user/isWeekStartOnMonday'];
        },
        currentPeriodDates: function() {
            return `<span class="month">${moment(this.startOfPeriod).format('MMM')}</span> 
                ${moment(this.startOfPeriod).format('DD')} - ${moment(this.endOfPeriod).format('DD')},
                ${moment(this.startOfPeriod).format('YYYY')}`;
        }
    },
    methods: {
        nextWeek(name, date) {
            this.$router.push({
                name: name,
                query: this.$route.query,
                params: {
                    date: moment(date).clone().add(7, 'd').format('YYYY-MM-DD')
                }
            });
        },
        prevWeek(name, date) {
            this.$router.push({
                name: name,
                query: this.$route.query,
                params: {
                    date: moment(date).clone().subtract(7, 'd').format('YYYY-MM-DD')
                }
            });
        },
        // get week day's
        getWeek(calendarType = CALENDAR_TYPES.USER) {
            this.currentPeriod.splice(0, this.currentPeriod.length);

            // isoWeek = start on Monday
            // week - start on Sunday
            const weekType = this.isWeekStartOnMonday ? 'isoWeek' : 'week';
            const startOfPeriod = moment(this.date).clone().startOf(weekType);
            const endOfPeriod = moment(this.date).clone().endOf(weekType);
            let day = startOfPeriod.clone();

            this.startOfPeriod = startOfPeriod;
            this.endOfPeriod = endOfPeriod;

            while (day <= endOfPeriod) {
                this.currentPeriod.push(day.toDate());
                day = day.clone().add(1, 'd');
            }

            this.getEvents(calendarType);
        },
        checkIfEventPast(dtend) {
            return moment(dtend).isBefore(moment());
        },
        sortEvents(params) {
            // Sorted so the events that start earlier are always shown first.
            return [...params]
                .sort((a, b) => {
                    if (moment(a.dtstart).isBefore(moment(b.dtstart))) { return -1; }
                    if (moment(b.dtstart).isBefore(moment(a.dtstart))) { return 1; }
                    if (moment(a.dtend).isAfter(moment(b.dtend))) { return -1; }
                    if (moment(b.dtend).isAfter(moment(a.dtend))) { return 1; }
                });
        },
        getUserEvents(params) {
            const events = this.sortEvents(params);
            const results = [];
            const eventRows = [[], [], [], [], [], [], []];
            let ep, continued, startOffset, span;

            for (let i = 0; i < events.length; i++) {
                ep = Object.assign({}, events[i], {
                    classes: [],
                    eventRow: 0
                });

                continued = moment(ep.dtstart).isSameOrBefore(moment(this.startOfPeriod), 'd');
                startOffset = continued
                    ? 0
                    : moment(ep.dtstart).diff(this.startOfPeriod, 'days');
                span = Math.min(
                    7 - startOffset,
                    Math.abs(moment(ep.dtend).diff(moment(this.startOfPeriod).add(startOffset, 'days'), 'days')) + (+ep.all_day ? 0 : 1)
                );
                for (let d = 0; d < 7; d++) {
                    if (d === startOffset) {
                        let s = 0;
                        while (eventRows[d][s]) { s++; }
                        ep.eventRow = s;
                        eventRows[d][s] = true;
                    } else if (d < startOffset + span) {
                        eventRows[d][ep.eventRow] = true;
                    };
                };
                ep.classes.push(`offset-${startOffset}`);
                ep.classes.push(`span-${span}`);
                ep.classes.push(`row-${ep.eventRow}`);
                results.push(ep);
            }

            return results;
        },
        getEventTop(event, show) {
            // Compute the top position of the event based on its assigned row within the given week.
            // 31 - height of event element defined in CSS, 2.5 - margin
            if (!show) {
                return `${(event.eventRow * 31) + 2.5}px`;
            }
            setTimeout(() => {
                let domEvent = document.getElementById(`event-${event.id}`);
                let height = domEvent.clientHeight;

                if (!event.eventRow) {
                    this.iterationEvents = height + 2.5;
                    domEvent.style.top = `2.5px`;
                    return;
                }

                domEvent.style.top = `${this.iterationEvents + 2.5}px`;
                this.iterationEvents += height + 2.5;
            },0);
        },
        getEvents(calendarType) {
            if (this.closeEventPopup) {
                this.closeEventPopup();
            }

            const params = {
                dtstart: moment(this.startOfPeriod).format('YYYY-MM-DD'),
                view: 'week'
            };

            let query;
            switch (calendarType) {
                case CALENDAR_TYPES.USER: {
                    this.events.splice(0, this.events.length);
                    query = '/calendars/user-calendar';
                } break;
                case CALENDAR_TYPES.TECHNICIAN: {
                    query = '/technicians/calendars';
                } break;
            }

            this.isLoading = true;
            api.get(query, { params })
                .then(response => response.data.data)
                .then(data => {
                    switch (calendarType) {
                        case CALENDAR_TYPES.USER: {
                            this.events.push(...data);
                        } break;
                        case CALENDAR_TYPES.TECHNICIAN: {
                            if (!this.calendars.length) {
                                store.commit('technicians/SET_TECHNICIAN_DATA', data)
                            }
                            this.calendars = data;
                        } break;
                    }
                })
                .catch(error => this.notifyRequestErrors(error))
                .finally(() => this.isLoading = false);
        },
        isShowMainEvent(event) {
            if (this.showMainEventOnly) {
                return !event.parent_id && !event.parent_event_id;
            }

            return true;
        }
    },
    directives: {
        expandButtons: {
            inserted: function(el) {
                const expandButtons = el.querySelectorAll('.show-more, .show-less');
                const events = el.querySelectorAll('.event');
                const rowNumbersArr = [...events].map(el =>
                [...el.classList]
                    .filter(el => el.includes('row')))
                    .flat()
                    .map(el => el.replace('row-',''));
                const maxRowNumber = Math.max.apply(null, rowNumbersArr);
                const maxRowIndex = rowNumbersArr.findIndex(el => el == maxRowNumber);
                const rowsBeforeCollapseHeight = [];
                let maxCollapseRowHeight;
                const rowsHeight = [];
                let maxRowHeight;

                if (events.length) {
                    setTimeout(() => {
                        rowNumbersArr.forEach((el,index) => {
                            let eventTop = document.getElementById(events[index].id).offsetTop;
                            let eventHeight = document.getElementById(events[index].id).offsetHeight;
                            if (el == 2) {
                                rowsBeforeCollapseHeight.push(eventTop + eventHeight);
                            }
                            rowsHeight.push(eventTop + eventHeight);
                        });
                        maxCollapseRowHeight = Math.max.apply(null, rowsBeforeCollapseHeight);
                        maxRowHeight = Math.max.apply(null, rowsHeight);
                        el.style.height = maxRowHeight + 2.5 + 'px';
                    },0);
                }

                if (maxRowNumber > 2) {
                    el.classList.add('overflow');
                } else {
                    el.classList.remove('overflow');
                }

                events.forEach((e,index) => {
                    setTimeout(() => {
                        if (index == maxRowIndex && maxRowNumber < 3) {
                            el.style.height = maxCollapseRowHeight + 2.5 + 'px';
                            return;
                        }

                        if (index == 2 && maxRowNumber > 2 && !expandButtons.length) {
                            el.style.height = maxRowHeight + 32.5 + 'px';
                            // create 'Show more' button
                            let showMore = document.createElement('button');
                            let showMoreText = document.createTextNode('+ Show more');
                            showMore.appendChild(showMoreText);
                            showMore.classList.add('show-more', 'transparent-button', 'f-11-grey');

                            showMore.addEventListener('click', function() {
                                let rowsHeight = [];
                                rowNumbersArr.forEach((el,index) => {
                                    let eventTop = document.getElementById(events[index].id).offsetTop;
                                    let eventHeight = document.getElementById(events[index].id).offsetHeight;
                                    rowsHeight.push(eventTop + eventHeight);
                                });
                                let maxRowHeight = Math.max.apply(null, rowsHeight);
                                el.style.height = maxRowHeight + 32.5 + 'px';
                                el.classList.add('expanded');
                            });

                            // create 'Show less' button
                            let showLess = document.createElement('button');
                            let showLessText = document.createTextNode('- Show less');
                            showLess.appendChild(showLessText);
                            showLess.classList.add('show-less', 'transparent-button', 'f-11-grey');

                            showLess.addEventListener('click', () => {
                                let rowsBeforeCollapseHeight = [];
                                //in case there is no row-2, needed when filtering
                                const counts = rowNumbersArr, maxRow = 2;
                                const closest = counts.reduce(function(prev, curr) {
                                    return (Math.abs(curr - maxRow) < Math.abs(prev - maxRow) ? curr : prev);
                                });

                                rowNumbersArr.forEach((el,index) => {
                                    if (el == maxRow || el == closest) {
                                        let eventTop = document.getElementById(events[index].id).offsetTop;
                                        let eventHeight = document.getElementById(events[index].id).offsetHeight;
                                        rowsBeforeCollapseHeight.push(eventTop + eventHeight);
                                    }
                                });
                                maxCollapseRowHeight = Math.max.apply(null, rowsBeforeCollapseHeight);
                                el.style.height = maxCollapseRowHeight + 32.5 + 'px';
                                el.classList.remove('expanded');
                            });

                            el.append(showMore, showLess);
                            return;
                        }

                        if (maxRowNumber == 2 && expandButtons.length) {
                            el.style.height = maxRowHeight + (32.5 * events.length) + 'px';
                            el.classList.remove('expanded');
                            expandButtons.forEach(function(button) {
                                button.remove();
                            });
                            return;
                        }
                    },0);
                });
            },
            componentUpdated: (el) => {
                const events = el.querySelectorAll('.event');
                const expandButtons = el.querySelectorAll('.show-more, .show-less');

                if (events.length) {
                    let rowNumbersArr = [...events].map(el =>
                    [...el.classList]
                        .filter(el => el.includes('row')))
                        .flat()
                        .map(el => el.replace('row-',''));
                    let rowsBeforeCollapseHeight = [];
                    let maxCollapseRowHeight;
                    let rowsHeight = [];
                    let maxRowHeight;

                    setTimeout(() => {
                        rowNumbersArr.forEach((el,index) => {
                            let eventTop = document.getElementById(events[index].id).offsetTop;
                            let eventHeight = document.getElementById(events[index].id).offsetHeight;
                            if (el == 2) {
                                rowsBeforeCollapseHeight.push(eventTop + eventHeight);
                            }
                            rowsHeight.push(eventTop + eventHeight);
                        });
                        maxCollapseRowHeight = Math.max.apply(null, rowsBeforeCollapseHeight);
                        maxRowHeight = Math.max.apply(null, rowsHeight);
                        if (!expandButtons.length) {
                            el.style.height = maxRowHeight + 2.5 + 'px';
                            return;
                        }
                        if (el.classList.contains('expanded')) {
                            el.style.height = maxRowHeight + 32.5 + 'px';
                            return;
                        }
                        el.style.height = maxCollapseRowHeight + 32.5 + 'px';
                    },0);
                }
            }
        }
    },
}