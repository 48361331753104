<template>
    <svg 
        viewBox="0 0 21 21"
        :width="size"
        :height="size"
        class="microsft-logo-icon"
    >
        <rect x="1" y="1" width="9" height="9" fill="#f25022"/>
        <rect x="1" y="11" width="9" height="9" fill="#00a4ef"/>
        <rect x="11" y="1" width="9" height="9" fill="#7fba00"/>
        <rect x="11" y="11" width="9" height="9" fill="#ffb900"/>
    </svg>
</template>

<script>
export default {
    name: 'MicrosoftLogoIcon',
    props: {
        size: {
            type: [Number, String],
            default: 21
        }
    }
}
</script>