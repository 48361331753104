<template>
    <svg
	    viewBox="0 0 20 20"
        :width="size" 
        :height="size"
        class="clock-icon"
    >
        <g transform="matrix(0.07471881806850435, 0, 0, 0.07471881806850435, 27.40893457727647, -8.840864197442766)">
            <g>
                <path d="M-230,125.8c68,0,124,56,124,125c0,68-56,124-124,124c-69,0-125-56-125-124C-355,181.8-299,125.8-230,125.8z M-230,142.8c-60,0-108,48-108,108c0,59,48,107,108,107c59,0,107-48,107-107C-123,190.8-171,142.8-230,142.8z"/>
                <path d="M -222 172.8000030517578 L -222 246.8000030517578 L -186 282.79998779296875 L -198 294.79998779296875 L -239 253.8000030517578 L -239 172.8000030517578 Z"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'ClockIcon',
        props: {
            size: {
                type: [Number, String],
                default: 20
            }
        }
    }
</script>
