<template>
    <div
        :class="[
            'w-100',
            dScreen && 'ph-30'
        ]"
    >
        <div class="form-builder-section row-start">
            <div class="row-start row-baseline mb-8">
                <div class="lg-5 md-6 sm-12 column-start">
                    <div class="f-15-darkgrey f-sbold">Date of Request</div>
                </div>
                <div class="lg-7 md-6 sm-12">
                    <form-datepicker
                        v-model="report.dateOfRequest"
                        :isFullWidth="true"
                        keyDatePicker="input_name_date_picker"
                        placeholder="Date of Request"
                    />
                </div>
            </div>
            <div class="row-start row-baseline mb-8">
                <div class="lg-5 md-6 sm-12 column-start">
                    <div class="f-15-darkgrey f-sbold">RAF Reference #</div>
                </div>
                <div class="lg-7 md-6 sm-12">
                    <form-input label="RAF Reference #">
                        <input
                            type="text"
                            name="raf_number"
                            class="form-input-field"
                            placeholder="RAF Reference #"
                            v-input-style="report.rafNumber"
                            v-model="report.rafNumber"
                        />
                    </form-input>
                </div>
            </div>
            <div class="row-start row-baseline mb-8">
                <div class="lg-5 md-6 sm-12 column-start">
                    <div class="f-15-darkgrey f-sbold">Property Name</div>
                </div>
                <div class="lg-7 md-6 sm-12">
                    <form-input label="Property Name">
                        <input
                            type="text"
                            name="name"
                            class="form-input-field"
                            placeholder="Property Name"
                            v-input-style="report.name"
                            v-model="report.name"
                        />
                    </form-input>
                </div>
            </div>
            <div class="row-start row-baseline mb-2 s-align-1 pv-8 bb-grey bt-grey">
                <div class="lg-5 md-6 sm-12 column-start pv-8">
                    <div class="f-15-darkgrey f-sbold">Information</div>
                </div>
                <div class="lg-7 md-6 sm-12 row-start">
                    <div class="row-start">
                        <div class="lg-6 md-6 sm-12 pr-2 pr-sm-0 pb-4">
                            <form-input label="Your Name">
                                <input
                                    type="text"
                                    name="assistant"
                                    class="form-input-field"
                                    placeholder="Your Name"
                                    v-input-style="report.assistant"
                                    v-model="report.assistant"
                                />
                            </form-input>
                        </div>
                        <div class="lg-6 md-6 sm-12 pl-2 pl-sm-0 pb-4">
                            <form-input label="Your Title">
                                <input
                                    type="text"
                                    name="assistant_title"
                                    class="form-input-field"
                                    placeholder="Your Title"
                                    v-input-style="report.assistantTitle"
                                    v-model="report.assistantTitle"
                                />
                            </form-input>
                        </div>
                    </div>
                    <div class="w-100">
                        <form-input label="Your Email">
                            <input
                                type="email"
                                name="assistant_email"
                                class="form-input-field"
                                placeholder="Your Email"
                                v-input-style="report.assistantEmail"
                                v-model="report.assistantEmail"
                            />
                        </form-input>
                    </div>
                </div>
            </div>
            <div class="row-start row-baseline mb-2 s-align-1 mt-2">
                <div class="lg-5 md-6 sm-12 column-start pv-8">
                    <div class="f-15-darkgrey f-sbold">Address</div>
                </div>
                <div class="lg-7 md-6 sm-12 row-start">
                    <address-box
                        type="address"
                        :key="'address'"
                        :existing="report.address"
                        :validate="VALIDATE_NOT_REQUIRED"
                        @address="getAddress"
                    />
                </div>
            </div>
            <div class="row-start row-baseline mb-2 s-align-1 mt-2 bb-grey">
                <div class="lg-5 md-6 sm-12 column-start pv-8">
                    <div class="f-15-darkgrey f-sbold">Payment Amount</div>
                </div>
                <div class="lg-7 md-6 sm-12 row-start">
                    <div class="row-start">
                        <div class="lg-3 sm-12 pb-4 pr-2 pr-sm-0">
                            <form-input
                                label="Payment Amount (Numeric)"
                                maskBefore="$"
                                :maskValue="report.sumNumber"
                                maskFilter="numberWithCommas"
                            >
                                <input
                                    type="text"
                                    name="sum_number"
                                    class="form-input-field"
                                    placeholder="$0.00"
                                    v-input-style="report.sumNumber"
                                    v-model="report.sumNumber"
                                    v-limiter
                                />
                            </form-input>
                        </div>
                        <div class="lg-9 sm-12 pl-2 pl-sm-0">
                            <tooltip
                                class="w-100"
                                direction="top"
                                color="white"
                                :isShow="paymentAmountTextInFocus && report.sumNumber === ''"
                            >
                                <form-input label="Payment Amount (Text)">
                                    <input
                                        type="text"
                                        name="sum_text"
                                        class="form-input-field"
                                        placeholder="Payment Amount (Text)"
                                        v-input-style="report.sumText"
                                        v-model="report.sumText"
                                        @focus="paymentAmountTextInFocus = true"
                                        @blur="paymentAmountTextInFocus = false"
                                    />
                                </form-input>
                                <template v-slot:content>
                                    <p class="f-14-red f-sbold t-center">
                                        Field is auto-populated after Payment amount (Numeric) is filled in
                                    </p>
                                </template>
                            </tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-start row-baseline mb-2 s-align-1 mt-2 bb-grey pb-8">
                <div class="lg-5 md-6 sm-12 column-start pv-8">
                    <div class="f-15-darkgrey f-sbold">Action Required</div>
                </div>
                <div class="lg-7 md-6 sm-12 row-start">
                    <div
                        class="w-100 relative pb-4"
                        v-for="(item, actionIndex) in report.actionRequiredItems"
                        :key="actionIndex"
                    >
                        <input
                            type="text"
                            :name="`action-required-${actionIndex}`"
                            class="input form-builder-input"
                            v-model="item.text"
                        />
                        <button
                            class="icon-light form-builder-remove-icon"
                            v-if="actionIndex >= 1"
                            @click="removeItem(report.actionRequiredItems, actionIndex)"
                        >
                            <trash-icon/>
                        </button>
                    </div>
                    <div class="w-100">
                        <button
                            class="light-blue-button"
                            @click="addItem(report.actionRequiredItems)"
                        >
                            <span class="add-icon s-align-5 mr-8">+</span>
                            Add New Item
                        </button>
                    </div>
                </div>
            </div>
            <div class="row-start row-baseline mb-2 s-align-1 mt-2 mb-30">
                <div class="lg-5 md-6 sm-12 column-start pv-8">
                    <div class="f-15-darkgrey f-sbold">As the Result</div>
                </div>
                <div class="lg-7 md-6 sm-12 row-start">
                    <div
                        class="w-100 relative pb-4"
                        v-for="(item, resultIndex) in report.asResult"
                        :key="resultIndex"
                    >
                        <input
                            type="text"
                            :name="`action-required-${resultIndex}`"
                            class="input form-builder-input"
                            v-model="item.text"
                        />
                        <button
                            class="icon-light form-builder-remove-icon"
                            v-if="resultIndex >= 1"
                            @click="removeItem(report.asResult, resultIndex)"
                        >
                            <trash-icon/>
                        </button>
                    </div>
                    <div class="w-100">
                        <button
                            class="light-blue-button"
                            @click="addItem(report.asResult)"
                        >
                            <span class="add-icon s-align-5 mr-8">+</span>
                            Add New Item
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-builder-custom-section bt-grey">
            <form-builder-custom-fields
                :layoutId="page.id"
                :updateCustomFields="updateCustomFields"
                :entityType="entityType"
                @changeFields="changeCustomFields"
                :clear="clear"
            />
        </div>
    </div>
</template>

<script>
    import { FormInput, FormDatepicker, Tooltip } from '@/ui';
    import { FORM_BUILDER_REPORTS, ENTITY_TYPES } from '@/constants';
    import { TrashIcon } from '@/icons';
    import AddressBox from '@/components/AddressBox.vue';
    import toWord from '@/helpers/numberToWord.js';
    import FormBuilderCustomFields from '@/components/LeadModules/FormBuilder/FormBuilderCustomFields.vue';

    const REPORT_TYPE = 'further';

    export default {
        name: 'FormBuilderFurtherActionReport',
        props: {
            clear: {
                type: Boolean,
                default: false
            },
            get: {
                type: Boolean,
                default: false
            },
            selected: {
                type: Object,
                required: false
            },
            entityType: {
                type: String,
                required: true
            },
            page: {
                type: Object,
                required: false
            }
        },
        components: {
            FormInput,
            FormDatepicker,
            Tooltip,
            AddressBox,
            TrashIcon,
            FormBuilderCustomFields
        },
        data() {
            return {
                report: {},
                updateCustomFields: {},
                paymentAmountTextInFocus: false
            }
        },
        computed: {
            entityAddress() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: {
                        return this.$store.getters['lead/getClippedLeadAddress'];
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_JOB: {
                        return this.$store.getters['commercial/getCommercialJobAddress'];
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return this.$store.getters['commercial/getCommercialLeadAddress'];
                    } break;
                }
            },
            entityId() {
                switch (this.entityType) {
                    case ENTITY_TYPES.RESIDENTIAL_LEAD: {
                        return this.$route.params.lead_id;
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_JOB: {
                        return this.$route.params.job_id;
                    } break;
                    case ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return this.$route.params.lead_id;
                    } break;
                }
            },
        },
        watch: {
            clear: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal) this.createNewReport();
                }
            },
            'report.sumNumber'(newVal) {
                this.report.sumText = toWord(newVal);
            },
            get: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal) this.$emit('receive', this.report);
                }
            },
            selected: {
                immediate: true,
                handler (newVal, oldVal) {
                    if (newVal != null && newVal != oldVal) {
                        this.report = this.selected;
                        this.updateCustomFields = this.selected.customFields;
                    }
                }
            }
        },
        methods: {
            createNewReport(type) {
                this.report = {
                    title: FORM_BUILDER_REPORTS.FURTHER,
                    reportType: REPORT_TYPE,
                    reportName: '',
                    dateOfRequest: null,
                    rafNumber: this.entityId,
                    name: '',
                    address: {
                        address: '',
                        street: '',
                        city: '',
                        state: '',
                        zip: ''
                    },
                    actionRequiredItems: [{ text: '' }],
                    asResult: [{ text: '' }],
                    sumText: '',
                    sumNumber: '',
                    assistant: '',
                    assistantEmail: '',
                    assistantTitle: '',
                    customFields: {}
                };
            },
            getAddress(data) {
                this.report.address = data;
            },
            addItem(arr) {
                arr.push({ text: '' });
            },
            removeItem(arr, index) {
                arr.splice(index, 1);
            },
            changeCustomFields(data) {
                this.report.customFields = data;
            }
        },
        created() {
            this.VALIDATE_NOT_REQUIRED = '';
            this.createNewReport();
            this.report.address = this.entityAddress;
        }
    }
</script>