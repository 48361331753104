<template>
    <div 
        class="column-start pv-30 w-100 bb-grey"
        v-if="representativesLength"
    >
        <button 
            class="transparent-button mb-8"
            @click="representativesIsOpen = !representativesIsOpen"
            :disabled="!representativesActively"
        >
            <arrow-icon 
                class="i-blue mr-1"
                :transform="representativesIsOpen ? 270 : 90"
                size="10"
                v-if="representativesActively"
            />
            <span class="f-16-darkgrey f-sbold pr-4">{{ title }} point of contact</span>
            <span 
                class="count-blue f-11-blue f-bold s-align-5"
                v-if="representativesActively"
            >
                {{ representativesLength }}
            </span>
        </button>
        <div class="column-start w-100">
            <div 
                class="row-start row-baseline w-box"
                v-for="(representative, index) in representativesList"
                :key="`representative-${index}`"
            >
                <div class="lg-8 md-12">
                    <user-box
                        :key="index"
                        :firstName="representative.first_name"
                        :lastName="representative.last_name"
                        :avatarUrl="null"
                        :info="representative.title"
                        :border="false"
                        :marked="!!representative.entities[0].is_primary"
                    />
                </div>
                <div 
                    class="lg-4 column-start pl-6"
                    v-if="dScreen"
                >
                    <contact-list 
                        v-if="representative.phones.length"
                        :items="representative.phones"
                        :entityId="entityId"
                        type="phone"
                    />
                    <contact-list 
                        v-else-if="representative.emails.length"
                        :items="representative.emails"
                        type="email"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ArrowIcon } from '@/icons';
    import { UserBox } from '@/components';
    import { sortRepresentatives } from '@/helpers';
    import ContactList from '@/components/CommercialJobs/ContactList.vue';

    export default {
        name: 'RepresentativesDetailsList',
        props: {
            representatives: {
                type: Array,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            entityId: {
                type: Number,
                required: true
            }
        },
        components: {
            ArrowIcon,
            ContactList,
            UserBox
        },
        data() {
            return {
                representativesIsOpen: false
            }
        },
        computed: {
            representativesLength() {
                return this.representatives.length;
            },
            representativesActively() {
                return this.representativesLength > 3;
            },
            representativesList() {
                const representatives = sortRepresentatives(this.representatives, this.entityId);

                return this.representativesIsOpen 
                    ? representatives
                    : representatives.slice(0, 3);
            }
        }
    }
</script>