<template>
    <div class="modal s-align-5">
        <div class="copy-template-modal cm-fond">
            <h2 class="f-24-black f-sbold t-center">Make a copy</h2>
            <div class="column-start w-100">
                <p class="f-13-darkgrey pb-2">
                    *Create a unique name for every copy of a template.
                </p>
                <form-input 
                    label="Template Name"
                    :class="[{ 'form-input-danger' : errors.has('copied_template_name') }]"
                >
                    <input 
                        type="text" 
                        name="copied_template_name" 
                        class="form-input-field"
                        placeholder="Template Name"
                        v-input-style="copiedTemplateName"
                        v-model="copiedTemplateName"
                        v-validate="'required|min:3|max:250'"
                    />
                </form-input>
            </div>
            <div class="custom-modal-footer row-between">
                <button 
                    class="add-button"
                    @click="$emit('close')"
                >
                    Cancel
                </button>
                <button 
                    class="primary-button"
                    @click="makeCopy"
                    :disabled="requestIsGoing"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { FormInput } from '@/ui';
    import api from '@/headers.js';

    export default {
        name: 'CopyTemplateModal',
        props: {
            template: {
                type: Object,
                required: true
            }
        },
        components: {
            FormInput
        },
        data() {
            return {
                copiedTemplateName: '',
                requestIsGoing: false
            }
        },
        methods: {
            getTemplateName() {
                this.copiedTemplateName = this.template.name + ' Copy';
            },
            makeCopy() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        this.requestIsGoing = true;
                        
                        let params = {
                            template_id: this.template.id,
                            template_name: this.copiedTemplateName
                        };
                        
                        api.post('/templates/copy', params)
                            .then(response => response.data.data)
                            .then(data => {
                                this.notifySuccess(`"${data.name}" has been created!`);

                                this.$router.push({ 
                                    name: 'TemplateEdit', 
                                    params: { template_id: data.id }
                                });                     
                            })
                            .catch(error => { 
                                this.notifyError('The template name has already been taken.');                               
                            })
                            .finally(() => {
                                this.requestIsGoing = false;
                            });
                    } else {
                        this.notifyError('Enter the template name, please.');
                    };
                });
            }
        },
        mounted() {
            this.getTemplateName();
        }
    }
</script>