import moment from 'moment/src/moment';
import { EVENT_CREATORS, CALENDAR_TYPES } from '@/constants.js';

export const eventPopup = {
    data() {
        return {
            isEventPopupShow: false,
            isNewEventPopupShow: false,
            selectedEvent: '',
            popupOffset: {
                offsetTop: '',
                offsetBottom: '',
                offsetLeft: '',
                offsetRight: ''
            }
        }
    },
    computed: {
        isMyCalendar() {
            return this.$route.name === 'CalendarWeek' 
                || this.$route.name === 'CalendarDay' 
                || this.$route.name === 'CalendarMonth' 
                || this.$route.name === 'CalendarAgenda';
        },
        loggedInUserId() {
            return this.$store.getters['user/getUserId'];
        }
    },
    methods: {
        showEventPopup(selectedEvent, parentClass, id) {
            // return if clicked on 'new event placeholder'
            if (selectedEvent.is_event_placeholder) {
                return;
            }
            // close another popup
            this.closeNewEventPopup();

            const event = document.getElementById(id);
            const container = event.closest(parentClass);
            this.selectedEvent = selectedEvent;
            
            // 20, 30 & 40 values - offset to match design
            this.popupOffset.offsetTop = event.getBoundingClientRect().top 
                - container.getBoundingClientRect().top - 20;
            this.popupOffset.offsetBottom = container.getBoundingClientRect().height 
                - this.popupOffset.offsetTop 
                - event.getBoundingClientRect().height - 40;

            this.popupOffset.offsetLeft = event.getBoundingClientRect().left + 30; 
            this.popupOffset.offsetRight = container.getBoundingClientRect().width
                - event.getBoundingClientRect().right + 30; 

            this.isEventPopupShow = true;
        },
        showNewEventPopup(parentClass) {
            // close another popup
            this.isEventPopupShow = false;

            const event = document.querySelector('.event-placeholder');
            const container = event.closest(parentClass);
            
            // 20, 40 & 80 values - offset to match design
            this.popupOffset.offsetTop = event.getBoundingClientRect().top 
                - container.getBoundingClientRect().top - 20;
            this.popupOffset.offsetBottom = container.getBoundingClientRect().height 
                - this.popupOffset.offsetTop 
                - event.getBoundingClientRect().height - 40;

            this.popupOffset.offsetLeft = event.getBoundingClientRect().left + 80; 
            this.popupOffset.offsetRight = container.getBoundingClientRect().width
                - event.getBoundingClientRect().left + 10; 

            this.isNewEventPopupShow = true;
        },
        closeEventPopup() {
            this.isEventPopupShow = false;
        },
        closeNewEventPopup(eventData) {
            // remove placeholder
            this.removeNewEventPlaceholder();
            
            // add new event to array
            if (eventData !== undefined) {
                let { data: newEvent, attendees, main_assignee_fullname } = eventData;
                switch (this.$route.name) {
                    case 'TechnicianCalendar': {
                        let index = this.calendars.findIndex(e => e.id === this.newEventCalendarId);
                        let attendeesId = newEvent.attendees.map(item => item.id);

                        if (index !== -1) {
                            this.calendars[index].events.push(newEvent);
                            if (attendeesId.length) {
                                attendeesId.forEach((item) => {
                                    let userId = this.calendars.findIndex(e => e.id === item);
                                    let eventObj = { ...newEvent };
                                    eventObj.parent_event_id = eventObj.id;
                                    eventObj.main_assignee_fullname = main_assignee_fullname;
                                    eventObj.id = eventObj.id + item;
                                    this.calendars[userId].events.push(eventObj);
                                });
                            }
                        }
                    } break;
                    case 'UserCalendar': {
                        let index = this.events.findIndex(e => e.id === this.newEventUserId);
                        let attendeesId = attendees
                                            .filter(i => i.type === 'user')
                                            .map(item => item.id);

                        if (index !== -1) {
                            this.events[index].events.push(newEvent);
                            if (attendeesId.length) {
                                attendeesId.forEach((item) => {
                                    let userId = this.events.findIndex(e => e.id === item);
                                    let eventObj = { ...newEvent };
                                    eventObj.parent_event_id = eventObj.id;
                                    eventObj.main_assignee_fullname = main_assignee_fullname;
                                    eventObj.id = eventObj.id + item;
                                    this.events[userId].events.push(eventObj);
                                });
                            }
                        }
                    } break;
                    case 'UserCalendarDayView': {
                        let attendeesId = attendees
                                            .filter(i => i.type === 'user')
                                            .map(item => item.id);
                        this.addedEventToParticipants(attendeesId, newEvent, main_assignee_fullname);
                    } break;
                    case 'TechnicianCalendarDayView': {
                        let attendeesId = newEvent.attendees.map(item => item.id);
                        this.addedEventToParticipants(attendeesId, newEvent, main_assignee_fullname);
                    } break;
                    default: {
                        this.events.unshift(newEvent);
                    } break;
                }
            }
            
            // recalculate events positions
            if (this.checkCellOverlappingEvents !== undefined) {
                this.checkCellOverlappingEvents();
            }

            this.isNewEventPopupShow = false;
        },
        addedEventToParticipants(participants, newEvent, main_assignee_fullname) {
            this.userEvent[this.selectedUserId].push(newEvent);

            if (!participants.length) {
                return;
            }

            participants.forEach((item) => {
                let eventObj = { ...newEvent };
                eventObj.parent_event_id = eventObj.id;
                eventObj.main_assignee_fullname = main_assignee_fullname;
                eventObj.id = eventObj.id + item;
                if (this.userEvent[item] !== undefined) {
                    this.userEvent[item].push(eventObj);
                    return;
                }

                this.userEvent[item] = [eventObj];
            });
        },
        // create 'new event' placeholder
        createNewEventPlaceholder(dtstart, dtend, day) {
            const utcOffset = moment(day).utcOffset();
            this.events.unshift({ 
                dtstart: moment.utc(dtstart).subtract(utcOffset, 'm').format('YYYY-MM-DD HH:mm:ss'), 
                dtend: moment(dtend).subtract(utcOffset, 'm').format('YYYY-MM-DD HH:mm:ss'), 
                summary: 'New event', 
                id: 0, // id for event placeholder
                is_event_placeholder: true
            });
        },
        // remove exisitng 'new event' placeholder
        removeNewEventPlaceholder() {
            if (!document.querySelector('.event-placeholder')) {
                return;
            }

            switch (this.$route.name) {
                case 'TechnicianCalendar': {
                    let index = this.calendars.findIndex(e => e.events.some(i => i.is_event_placeholder));
                    if (index !== -1) {
                        this.calendars[index].events.shift();
                    }
                } break;
                case 'UserCalendar': {
                    let index = this.events.findIndex(e => e.events.some(i => i.is_event_placeholder));
                    if (index !== -1) {
                        this.events[index].events.shift();
                    }
                } break;
                case 'UserCalendarDayView':
                case 'TechnicianCalendarDayView': {
                    this.userEvent[this.selectedUserId].shift();
                } break;
                default: {
                    this.events.shift();
                } break;
            }
        },
        deleteEventsInDayView(event) {
            let mainUserId = event.user.id;
            let index = this.userEvent[mainUserId].findIndex(c => c.id === event.id);
            this.userEvent[mainUserId].splice(index, 1);

            if (event.attendees.length) {
                event.attendees.forEach((item) => {
                    let userId = item.id;
                    let eventIndex = this.userEvent[userId].findIndex(c => {
                        return c.parent_id === event.id || c.parent_event_id === event.id;
                    });
                    this.userEvent[userId].splice(eventIndex, 1);
                });
            }
            this.closeEventPopup();
            return;
        },
        onDelete(event, calendarType = CALENDAR_TYPES.USER) {
            let index;
            switch (calendarType) {
                case CALENDAR_TYPES.USER: {
                    if (this.$route.name == 'UserCalendarDayView') {
                        this.deleteEventsInDayView(event);
                    }

                    if (this.isMyCalendar) {
                        index = this.events.findIndex(c => c.id === event.id);
                        if (index > -1) {
                            this.events.splice(index, 1);
                            this.closeEventPopup();
                            return;
                        } 
                        
                        index = this.multiDayEvents.findIndex(el => el.id === event.id);
                        if (index > -1) {
                            this.multiDayEvents.splice(index, 1);
                            this.closeEventPopup();
                            return;
                        }
                        return;
                    }

                    index = this.events.findIndex(c => c.events.some(e => e.id === event.id));
                    if (index > -1) {
                        const eventIndex = this.events[index].events.findIndex(e => e.id === event.id);
                        this.events[index].events.splice(eventIndex, 1);
                        let deletedEvent = event;

                        if (deletedEvent.attendees.length) {
                            deletedEvent.attendees.forEach((item) => {
                                let userId = this.events.findIndex(e => e.id === item.id);
                                let eventId = deletedEvent.id;
                                let event = this.events[userId].events.findIndex(e => {
                                    return e.parent_id === eventId || e.parent_event_id === eventId;
                                });
                                this.events[userId].events.splice(event, 1);
                            });
                        }
                        this.closeEventPopup();
                        return;
                    }

                    index = this.multiDayEvents.findIndex(el => el.id === event.id);
                    if (index > -1) {
                        this.multiDayEvents.splice(index, 1);
                        this.closeEventPopup();
                        return;
                    }
                } break;
                case CALENDAR_TYPES.TECHNICIAN: {
                    if (this.$route.name == 'TechnicianCalendarDayView') {
                        this.deleteEventsInDayView(event);
                    } else {
                        index = this.calendars.findIndex(c => c.events.some(e => e.id === event.id));
                        if (index !== -1) {
                            const eventIndex = this.calendars[index].events.findIndex(e => e.id === event.id);
                            this.calendars[index].events.splice(eventIndex, 1);
                            let deletedEvent = event;

                            if (deletedEvent.attendees.length) {
                                deletedEvent.attendees.forEach((item) => {
                                    let userId = this.calendars.findIndex(e => e.id === item.id);
                                    let eventId = deletedEvent.id;
                                    let event = this.calendars[userId].events.findIndex(e => {
                                        return e.parent_id === eventId || e.parent_event_id === eventId;
                                    });
                                    this.calendars[userId].events.splice(event, 1);
                                });
                            }
                            this.closeEventPopup();
                            return;
                        }
                    }
                } break;
            }
        },
        onUpdate(event, refreshMode, calendarType = CALENDAR_TYPES.USER) {
            let index;
            switch (calendarType) {
                case CALENDAR_TYPES.USER: {
                    if (this.$route.name == 'UserCalendarDayView') {
                        this.getEvents();
                        return;
                    }

                    if (this.isMyCalendar) {
                        index = this.events.findIndex(c => c.id === event.id);
                        if (index > -1) {
                            if (+event.all_day) {
                                this.events.splice(index, 1);
                                this.multiDayEvents.push(event);
                            } else {
                                this.events.splice(index, 1, event);
                            }
                            this.closeEventPopup();
                            return;
                        } 
                        
                        index = this.multiDayEvents.findIndex(el => el.id === event.id);
                        if (index > -1) {
                            this.multiDayEvents.splice(index, 1);
                            this.events.push(event);
                            this.closeEventPopup();
                            return;
                        }

                        return;
                    }

                    index = this.events.findIndex(c => c.events.some(e => e.id === event.id))
                    if (index > -1) {
                        const eventIndex = this.events[index].events.findIndex(e => e.id === event.id);
                        this.events[index].events.splice(eventIndex, 1, event);

                        if (refreshMode) {
                            this.getWeek(CALENDAR_TYPES.USER);
                        }

                        this.closeEventPopup();
                        return;
                    }

                    index = this.multiDayEvents.findIndex(el => el.id === event.id);
                    if (index > -1) {
                        this.multiDayEvents.splice(index, 1, event);
                        this.closeEventPopup();
                        return;
                    }
                } break;
                case CALENDAR_TYPES.TECHNICIAN: {
                    if (this.$route.name == 'TechnicianCalendarDayView') {
                        this.getEvents();
                        return;
                    }

                    index = this.calendars.findIndex(c => c.events.some(e => e.id === event.id))
                    if (index !== -1) {
                        const eventIndex = this.calendars[index].events.findIndex(e => e.id === event.id);
                        this.calendars[index].events.splice(eventIndex, 1, event);

                        if (refreshMode) {
                            this.getWeek(CALENDAR_TYPES.TECHNICIAN);
                        }

                        this.closeEventPopup();
                        return;
                    }
                } break;
            }
            
            if (this.$route.name !== 'TechnicianCalendarDayView') {
                this.checkCellOverlappingEvents();
            }
        },
        // get color class for event
        getEventClasses(event) {
            if (event.is_event_placeholder) {
                return 'event-placeholder';
            }

            if (moment.utc(event.dtend).isBefore(moment())) {
                return 'event-past';
            }

            if (event.created_from === EVENT_CREATORS.TASK) {
                return 'event-orange';
            }

            if (event.is_lead_tech !== undefined && !event.is_lead_tech) {
                return 'event-blue';
            }

            return 'event-violet';
        }
    }
}